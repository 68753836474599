<template>
    <b-card no-body class="bg-default shadow">
        <b-card-header class="bg-transparent border-0">
            <h3 v-if="model.project_name == null" class="mb-0 text-white">Site Listing - Create New Site</h3>
            <h3 v-else class="mb-0 text-white">Site Listing for ({{ model.project_name }})</h3>
            <!-- <div class="text-right">

              <base-button size="sm" type="neutral" @click="newProjectSite()"
              v-if="button_access.site_new == 'YES'">Create New Site</base-button>
            </div> -->
        </b-card-header>

        <el-table class="table-responsive table-dark"
                  header-row-class-name="thead-dark"
                  :data="queriedData">

            <el-table-column label="Name"
                             min-width="200px"
                             prop="site_code"
                             sortable>
                <template v-slot="{row}">
                  <div>
                     <b-media no-body class="align-items-center">
                        <b-media-body>
                            <span class="font-weight-600 name mb-0 text-sm">{{row.site_name}}</span>
                        </b-media-body>
                    </b-media>
                  </div>
                </template>
            </el-table-column>

            <el-table-column label="Group"
                             min-width="140px"
                             prop="group.group_name"
                             sortable>
                <template v-slot="{row}">
                    <b-media no-body class="align-items-center">
                        <b-media-body>
                            <span class="font-weight-600 name mb-0 text-sm">{{row.group.group_name}}</span>
                        </b-media-body>
                    </b-media>
                </template>
              </el-table-column>

              <el-table-column label="Information"
                             min-width="180px"
                             prop="group.group_engineer"
                             sortable>
                <template v-slot="{row}">
                    <div>
                      <b-media no-body class="align-items-center">
                        <b-media-body>
                            <span class="font-weight-600 name mb-0 text-sm">P.Manager: {{row.group.group_engineer.name}}</span>
                        </b-media-body>
                      </b-media>
                    </div>
                    <div>
                      <b-media no-body class="align-items-center">
                        <b-media-body>
                            <span class="font-weight-600 name mb-0 text-sm">Postal.Code: {{row.site_postal_code}}</span>
                        </b-media-body>
                      </b-media>
                    </div>
                    <div>
                      <b-media no-body class="align-items-center">
                        <b-media-body>
                            <span class="font-weight-600 name mb-0 text-sm">Capacity: {{row.site_total_capacity }} KWp</span>
                        </b-media-body>
                      </b-media>
                    </div>
                </template>
              </el-table-column>

              <el-table-column label="Progress"
                           min-width="190px"
                             prop="site_progress"
                             sortable>
                <template v-slot="{row}">
                    <div class="d-flex align-items-center">
                        <div>
                          <span class="font-weight-600 name mb-0 text-sm">{{row.site_progress}}% &nbsp; </span>
                        </div>
                        <div>
                            <base-progress v-if="row.site_progress > 0 && row.site_progress < 50" min-width="30px" type="danger" :value="row.site_progress"/>
                            <base-progress v-else-if="row.site_progress >= 50 && row.site_progress <= 90" min-width="30px" type="info" :value="row.site_progress"/>
                            <base-progress v-else min-width="30px" type="success" :value="row.site_progress"/>
                        </div>
                    </div>
                    <b-media no-body class="align-items-center">
                        <b-media-body>
                            Status:
                            <badge class="badge-dot mr-4" type="">
                              <i v-if="row.status.status_code == 'Delayed'" class="bg-danger"></i>
                              <i v-else-if="row.status.status_code == 'On-Hold'" class="bg-danger"></i>
                              <i v-else-if="row.status.status_code == 'Reviewed'" class="bg-success"></i>
                              <i v-else-if="row.status.status_code == 'Completed'" class="bg-success"></i>
                              <i v-else class="bg-info"></i>
                              <span class=" font-weight-600 name mb-0 text-white ">{{row.status.status_code}}</span>
                            </badge>
                        </b-media-body>
                    </b-media>
                    <div>
                      Est Turn-On Date: <b>{{ row.est_turn_on_date }}</b>
                    </div>
                </template>
              </el-table-column>

              <el-table-column min-width="190px" align="right" label="Actions">
                <div slot-scope="{$index, row}" class="d-flex">
                  <base-button v-if="button_access.site_show == 'YES'"
                    @click="projectSiteDashboard($index, row)" size="sm" type="primary">
                    Dashboard
                  </base-button>

                  <base-button v-if="button_access.site_contractor_listing == 'YES'"
                    @click="linkSiteContractor($index, row)" size="sm" type="primary">
                    Contractor
                  </base-button>
                </div>
              </el-table-column>
        </el-table>

        <b-card-footer class="py-2 d-flex justify-content-end bg-transparent">
              <base-pagination
              class="pagination-no-border"
              :current="pagination.currentPage"
              :per-page="pagination.perPage"
              :total="total"
              @change="paginationChanged($event)"
            >
            </base-pagination>
        </b-card-footer>
    </b-card>
</template>

<script>

  import { Table, TableColumn, DropdownMenu, DropdownItem, Dropdown, Select, Option} from 'element-ui'
  import {homeLink} from '@/assets/js/config.js'
  import RouteBreadCrumb from '@/components/Breadcrumb/RouteBreadcrumb'
  import { BasePagination } from '@/components';
  import clientPaginationMixin from './js/ProjectSitePaginationMix'
  import swal from 'sweetalert2';
  import axios from 'axios'
  import {LoginURL, clientId, clientSecret, apiDomain, getHeader} from '@/assets/js/config.js'

  export default {
    mixins: [clientPaginationMixin],
    components: {
      BasePagination,
      RouteBreadCrumb,
      [Select.name]: Select,
      [Option.name]: Option,
      [Table.name]: Table,
      [TableColumn.name]: TableColumn
    },
    created()
    {
        this.model.project_id = this.$route.params.id

        var project_site_access = JSON.parse(window.localStorage.getItem('project_site_access'))
        this.button_access.site_new = project_site_access.access_create
        this.button_access.site_show = project_site_access.access_show

        var site_contractor_access = JSON.parse(window.localStorage.getItem('site_contractor_access'))
        this.button_access.site_contractor_listing = site_contractor_access.access_listing


        axios.get(apiDomain + 'project/project_site.show_by_project/' + this.model.project_id, { headers: getHeader() })
          .then ( response => {
            if (response.status === 200)
            {
                this.tableData = response.data.data
                this.model.project_name = response.data.data[0].project.project_name
            }
          })
          .catch ( error => {
              console.log(error.response.status)
          } );
    },
    data() {
      return {
        propsToSearch: ['site_code', 'group_template_name', ],
        tableColumns: [],
        tableData: [],
        selectedRows: [],
        model:
        {
          project_id: null,
          project_name: null,
        },
        button_access: {
          site_new: null,
          site_show: null,
          site_contractor_listing: null,
        },
      };
    },
    methods:
    {
      newTask()
      {
        this.$router.push({path: '/taskNewFrProject/' + this.model.project_id })
      },
      paginationChanged(page)
      {
        this.pagination.currentPage = page
      },
      newProjectSite()
      {
        this.$router.push({path: '/projectSiteNewFrProject/' + this.model.project_id })
      },
      notready() {
          this.$notify({
          message:
            '<b>Under Developer : </b> - New Task & Site function is not ready.',
          timeout: 10000,
          icon: 'ni ni-bulb-61',
          type: 'danger',
          });
      },
      projectSiteDashboard(index, row)
      {
          this.$router.push({path: '/projectSiteDashboard/'+row.site_id })
      },
      linkSiteContractor(index, row)
      {
          this.$router.push({path: '/siteContractorListing/'+row.site_id })
      },
    },
  }
</script>
