<template>
  <div>
    <div class="bg-default-dashboard">
      <div class=" py-lg-8 pt-lg-5">
        <b-container fluid style="width: 100%; position: relative;">
          <!-- <div class="header-body "  > -->
          <b-row>
            <b-breadcrumb :items="route_items"></b-breadcrumb>
          </b-row>
          <b-row class="mb-2">
            <b-col>
              <h1 class="text-white">{{ model.name }}</h1>
            </b-col>
          </b-row>
          <br>
          <h3 class="text-white">Document</h3>
          <!-- filter -->
          <b-row>
          <b-col md="1" class="mt-1">
            <base-input>
              <select class="form-control" v-model="selectedCategory1">
                <option v-for="item of categories1" :key="item.id" :value="item.id" :label="item.code"></option>
              </select>
            </base-input>
          </b-col>
          <b-col md="1" class="mt-1">
            <base-input>
              <select class="form-control" v-model="selectedCategory2">
                <option v-for="item of categories2" :key="item.id" :value="item.id" :label="item.code"></option>
              </select>
            </base-input>
          </b-col>

          <b-col md="1" class="mt-1">
            <template v-if="model.category3_enabled">
              <base-input>
                <select class="form-control" v-model="selectedCategory3">
                  <option v-for="item of categories3" :key="item.id" :value="item.id" :label="item.code"></option>
                </select>
              </base-input>
            </template>
          </b-col>




          </b-row>
        <!--Filter end here-->
          <b-row class="mt-4">
            <b-col md="12">
              <!--NEW GRID START -->
              <dataset style="width: 100%;" v-slot="{ ds }" :ds-data="documents"
                :ds-sortby="['category1_code', 'category2_code', 'category3_code', 'title']"
                :ds-search-in="['category1_code', 'category2_code', 'category3_code','title', 'alt_title']">
                <div class="row" style="padding-bottom: 15px;" :data-page-count="ds.dsPagecount">
                  <div class="col-md-2"
                    style="color: #ffffff; font-family: Open Sans, sans-serif !important; font-size: 0.875rem;">
                    <dataset-show class="dataset-style"/>
                  </div>
                  <div class="col-md-8">
                    <dataset-search ds-search-placeholder="Search..." />
                  </div>
                  <div class="col-md-2 text-right">
                    <base-button type="primary" @click="showModal()" v-if="access.create"><i class="fa fa-plus mr-1"></i>Document</base-button>
                  </div>

                </div>
                <div class="row">
                  <div class="col-md-12">
                    <div class="table-responsive">
                      <table style="width: 100%;" id="my-table">
                      <!--<table style="width: 100%;" class="table table-striped d-md-table">-->
                        <thead
                          style="background-color: #23454A; color: white; font-size: small; border-bottom: 20px solid #082A2F;">
                          <tr>
                            <!-- <th scope="col">#</th> -->
                            <th v-for="(th, index) in obj_pagination.cols" :key="th.field" :class="['sort', th.sort]"
                              @click="click($event, index)">
                              {{ th.name }} <i class="gg-select float-right"></i>
                            </th>
                          </tr>
                        </thead>
                        <dataset-item tag="tbody">
                          <template #default="{ row, rowIndex }">
                            <tr
                              style="background-color: #3a656b; color: white; font-size: smaller; border-bottom:  20px solid #082A2F;">
                              <!-- <th scope="row">{{ rowIndex + 1 }}</th> -->
                              <td class="tr-td-table-new" style="min-width: 150px;">{{ row.category1_code }}</td>
                              <td class="tr-td-table-new" style="min-width: 150px;">{{ row.category2_code }}</td>
                              <td class="tr-td-table-new" style="min-width: 150px;">{{ row.category3_code }}</td>
                              <td class="tr-td-table-new" style="min-width: 150px;">{{ row.title }}</td>
                              <td class="tr-td-table-new" style="min-width: 150px;">{{ row.alt_title }}</td>
                              <td class="tr-td-table-new" style="min-width: 100px;">
                                <div>
                                    <b-link :to="`Edit/${row.id}`" class="mr-3 mt-4">
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                          xmlns="http://www.w3.org/2000/svg">
                                    <g clip-path="url(#clip0_1130_20)">
                                    <path
                                      d="M2.81399 15.4667L1.54732 20.9333C1.50363 21.1332 1.50513 21.3403 1.55171 21.5394C1.59829 21.7386 1.68878 21.9249 1.81657 22.0846C1.94435 22.2444 2.10621 22.3735 2.29031 22.4627C2.47441 22.5519 2.6761 22.5988 2.88066 22.6C2.97597 22.6096 3.07201 22.6096 3.16732 22.6L8.66732 21.3333L19.2273 10.8133L13.334 4.93335L2.81399 15.4667Z"
                                      fill="#FF5C00" />
                                    <path
                                      d="M22.5471 5.54667L18.6138 1.61333C18.3552 1.35604 18.0053 1.21161 17.6405 1.21161C17.2757 1.21161 16.9257 1.35604 16.6671 1.61333L14.4805 3.8L20.3671 9.68667L22.5538 7.5C22.6818 7.37139 22.7831 7.2188 22.852 7.05098C22.921 6.88315 22.9561 6.70338 22.9555 6.52195C22.9549 6.34052 22.9185 6.161 22.8484 5.99364C22.7784 5.82629 22.676 5.6744 22.5471 5.54667Z"
                                      fill="#FF5C00" />
                                  </g>
                            <defs>
                              <clipPath id="clip0_1130_20">
                                <rect width="24" height="24" fill="white" />
                              </clipPath>
                            </defs>
                          </svg>
                        </b-link>
                      </div>
                              </td>
                            </tr>
                          </template>
                        </dataset-item>
                      </table>
                    </div>
                  </div>
                </div>
                <div class="d-flex flex-md-row flex-column justify-content-between align-items-center"
                  style="color: #ffffff; font-family: Open Sans, sans-serif !important; font-size: 0.875rem;">
                  <dataset-info class="mb-2 mb-md-0" />
                  <dataset-pager />
                </div>
              </dataset>
              <!--NEW GRID END -->
            </b-col>
          </b-row>
          <b-modal ref="my-modal" centered hide-footer title="Add New Document" class="text-white bg-dark">
            <DemoModal @submitt="onSubmit" @close="hideModal"/>
          </b-modal>
        </b-container>
      </div>
    </div>
  </div>
</template>
<script>
import { Select, Option, Table, TableColumn, } from 'element-ui'
import standardPaginationMix from '@/assets/js/standardPaginationMix.js'
import ErrorFunctionMixin from '@/assets/js/errorFunctionMix.js'
import { BasePagination } from '@/components';
import axios from '@/util/axios'
import { getAccessByModule } from '@/util/access'
import Fuse from 'fuse.js';
import DemoModal from './New.vue'
import Dataset from '@/assets/js/customDataset.js';

import {
  DatasetItem,
  DatasetInfo,
  DatasetPager,
  DatasetSearch,
  DatasetShow
} from 'vue-dataset';


export default {
  // name :"Tracking Template",
  mixins: [standardPaginationMix, ErrorFunctionMixin],
  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    DemoModal,
    Dataset,
    DatasetItem,
    DatasetInfo,
    DatasetPager,
    DatasetSearch,
    DatasetShow
  },
  data() {
    return {
      route_items: [
        {
          text: 'Document Tracking Template',
          href: '../../../Tracking'
        },
        {
          text: 'Document',
          active: true
        }
      ],
      fields: [
        { key: 'name', label: 'Name' },
        { key: 'status', label: 'Status' },
        { key: 'created_at', label: 'Created At' },
        // { key: 'capacity', label: 'Capacity' },
        { key: 'action', label: '' },

      ],
      tableData: [],
      access: {},
      newForm: {},
      template: {},
      categories1: [],
      categories2: [],
      categories3: [],
      documents: [],
      tid: null,
      model: {},
      setting: {},
      selectedCategory1: null,
      selectedCategory2: null,
      selectedCategory3: null,
      obj_pagination: {
        cols: [
          {
            name: 'Cat1',
            field: 'category1_code',
            sort: ''
          },
          {
            name: 'Cat2',
            field: 'category2_code',
            sort: ''
          },
          {
            name: 'Cat3',
            field: 'category3_code',
            sort: ''
          },
          {
            name: 'Title',
            field: 'title',
            sort: ''
          },
          {
            name: 'Alternative Title',
            field: 'alt_title ',
            sort: ''
          },
          {}
        ],
      },
    };
  },
  watch: {
    selectedCategory1() {
      this.loadDocuments()
    },

    selectedCategory2() {
      this.loadDocuments()
    },

    selectedCategory3() {
      this.loadDocuments()
    },
    ['model.category1_id']() {
      this.loadDocuments()
    }
  },
  created() {
    this.tid = this.$route.params.tid
    this.access = getAccessByModule('DOC_REPO')
    this.getData()
    this.loadRecord()
    this.loadCategories()
    this.loadDocuments()
    this.loadTemplate()
    this.loadRoles()
  },

  methods: {
    onSubmit(newForm) {
      newForm.roles = newForm.selectedRoles.map(id => {
        return { role_id: id }
      });

      axios.post(`setup/doc_repo/template/${this.tid}/document`, newForm)
        .then(response => {
          this.$notify({
            message: 'New Document Created',
            timeout: 10000,
            icon: 'ni ni-bell-55',
            type: 'default',
          });

          this.loadDocuments()
          this.hideModal()
        })
        .catch(error => {
          this.errorFunction(error, this.title)
        });
    },
    hideModal() {
      this.$refs['my-modal'].hide()
      this.loadDocuments()
    },
    showModal() {
      this.newForm = {}
      this.$refs['my-modal'].show()
    },
    loadTemplate() {
      axios.get(`setup/doc_repo/template/${this.tid}`)
        .then(response => {
          this.template = response.data.data
        })
        .catch(error => {
          this.errorFunction(error, this.title)
        });
    },
    loadRecord() {
      axios.get(`setup/doc_repo/template/${this.tid}`)
        .then(response => {
          this.model = response.data.data
        })
        .catch(error => {
          this.errorFunction(error, this.title)
        });
    },

    loadCategories() {
      axios.get(`setup/doc_repo/template/${this.tid}/category`)
        .then(response => {
          const categories = response.data.data
          this.categories1 = categories.filter(x => x.level == 1)
          this.categories2 = categories.filter(x => x.level == 2)
          this.categories3 = categories.filter(x => x.level == 3)

          this.categories1 = this.setupAllInsideCategories(this.categories1);
          this.categories2 = this.setupAllInsideCategories(this.categories2);
          this.categories3 = this.setupAllInsideCategories(this.categories3);
        })
        .catch(error => {
          this.errorFunction(error, this.title)
        });
    },

    setupAllInsideCategories(theCategory) {
      return [
        {
          id: null,
          code: "-- ALL --",
          name: "-- ALL --"
        },
        ...theCategory
      ];
    },

    loadDocuments() {
      const options = {
        params: {
          category1_id: this.selectedCategory1,
          category2_id: this.selectedCategory2,
          category3_id: this.selectedCategory3,
        },
      }

      axios.get(`setup/doc_repo/template/${this.tid}/document`, options)
        .then(response => {
          this.documents = response.data.data
          if (this.documents.length > 0) {
            const length = this.documents.length
            this.model.position = this.documents[length - 1].sequence
          } else {
            this.model.position = 0;
          }
        })
        .catch(error => {
          this.errorFunction(error, this.title)
        });
    },
    loadRoles() {
      axios.get('lookup/role_list')
        .then(response => {
          this.roles = response.data.data.map(x => {
            return {
              value: x.id,
              text: x.name,
            }
          })
        })
        .catch(error => {
          this.errorFunction(error, this.title)
        });
    },
    getData() {
      axios.get('setup/doc_repo/template')
        .then(response => {
          this.tableData = response.data.data
          this.fuseData()
        })
        .catch(error => {
          this.errorFunction(error, this.title)
        })
    },
    fuseData() {
      this.fuseSearch = new Fuse(this.tableData, {
        keys: this.propsToSearch,
        threshold: 0.3,
      });
    },

  },
  mounted() {
    this.loadDocuments()
  },

};
</script>
<style scoped>


.modal-title {
  margin-left: auto;
  /* margin-right: auto; */
  margin-top: auto;
}

.row {
  flex-wrap: nowrap
}
</style>
<style scoped>


.card {
  background-color: #082a2f;
}

/* Class For Modal */
.modal-content {
  background-color: #306b74;

}

::placeholder {
  color: red;
  opacity: 1;
  /* Firefox */
}

.breadcrumb {
  background-color: transparent;
}

table thead th {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  font-size: 0.65rem;
  text-transform: uppercase;
  letter-spacing: 1px;
  background-color: #23454A;
  color: white;
  padding: 20px;
  border-bottom: 0px solid #082A2F;
  border-top: 0px solid #e9ecef;
}

.form-control {
  background-color: #2C545A;
  border-color: #2C545A;
  color: white;
}

.th-table-new {
  padding-top: 35px;
  padding-bottom: 35px;
  padding-left: 20px;
}

.tr-td-table-new {
  padding-top: 30px;
  padding-bottom: 30px;
  padding-left: 20px;
}

.el-table th.el-table__cell {

  overflow: hidden;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: #23454A;
  color: white;
  padding: 20px;
  border-top: 0px solid #082A2F;
  border-bottom: 0px solid #082A2F;
}

.el-table td.el-table__cell,
.el-table th.el-table__cell.is-leaf {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  border-bottom: 20px solid #082A2F;
  border-top: 0px solid #082A2F;
}

div.el-table .el-table__row {
  background: #3a656b;
  border-bottom: 0 solid #082A2F;
}

div.el-table .el-table__row:hover {
  background: #3a656b;
}

.el-table--border::after,
.el-table--group::after,
.el-table::before {
  content: '';
  position: absolute;
  background-color: #ebeef500;
  z-index: 1;
}

 .form-control {
  background-color: #2C545A;
  border-color: #2C545A;
  color: white;

  &:focus {
    background-color: #2C545A;
    border-color: #2C545A;
    /* color: white; */
  }

  &::placeholder {
    color: white;
  }
}

 .form-control {
  &:focus {
    &::placeholder {
      color: white;
    }
  }
}
</style>