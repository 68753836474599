var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"content"},[_c('base-header',{staticClass:"pb-6"},[_c('b-row',{staticClass:"py-4",attrs:{"align-v":"center"}},[_c('b-col',{attrs:{"cols":"7","lg":"6"}},[_c('h6',{staticClass:"h2 text-white d-inline-block mb-0"},[_vm._v("My Open Safety Observations")])])],1)],1),_c('b-container',{staticClass:"mt--6",attrs:{"fluid":""}},[_c('div',[_c('card',{staticClass:"no-border-card",attrs:{"body-classes":"px-0 pb-1","footer-classes":"pb-2"}},[_c('div',[_c('b-col',{staticClass:"d-flex justify-content-center justify-content-sm-between flex-wrap",attrs:{"cols":"12"}},[_c('el-select',{staticClass:"select-primary pagination-select",attrs:{"placeholder":"Per page"},model:{value:(_vm.pagination.perPage),callback:function ($$v) {_vm.$set(_vm.pagination, "perPage", $$v)},expression:"pagination.perPage"}},_vm._l((_vm.pagination.perPageOptions),function(item){return _c('el-option',{key:item,staticClass:"select-primary",attrs:{"label":item,"value":item}})}),1),_c('div',[_c('base-input',{attrs:{"prepend-icon":"fas fa-search","placeholder":"Search..."},model:{value:(_vm.searchQuery),callback:function ($$v) {_vm.searchQuery=$$v},expression:"searchQuery"}})],1)],1),_c('el-table',{attrs:{"data":_vm.queriedData,"row-key":"safety_observation_item_id","header-row-class-name":"thead-light"},on:{"row-click":_vm.rowClicked,"sort-change":_vm.sortChange}},[_c('el-table-column',{attrs:{"label":"EPC","min-width":"170px","prop":"contractor.contractor_code","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('b-media',{staticClass:"align-items-center",attrs:{"no-body":""}},[_c('b-media-body',[_c('span',{staticClass:"font-weight-600 name mb-0 text-sm"},[_vm._v(_vm._s(row.contractor.contractor_code))])])],1)]}}])}),_c('el-table-column',{attrs:{"label":"SITE NAME","min-width":"170px","prop":"project_site.site_name","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('b-media',{staticClass:"align-items-center",attrs:{"no-body":""}},[_c('b-media-body',[_c('span',{staticClass:"font-weight-600 name mb-0 text-sm"},[_vm._v(_vm._s(row.project_site.site_name))])])],1)]}}])}),_c('el-table-column',{attrs:{"label":"INSPECTION DATE","min-width":"170px","prop":"safety_observation_create_date_web","sortable":"","sort-method":_vm.sortDateInspectionDate},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('b-media',{staticClass:"align-items-center",attrs:{"no-body":""}},[_c('b-media-body',[_c('span',{staticClass:"font-weight-600 name mb-0 text-sm"},[_vm._v(_vm._s(row.safety_observation_create_date_web))])])],1)]}}])}),_c('el-table-column',{attrs:{"label":"TYPE","min-width":"170px","prop":"safety_observation_type.safety_observation_type_code","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('b-media',{staticClass:"align-items-center",attrs:{"no-body":""}},[_c('b-media-body',[_c('span',{staticClass:"font-weight-600 name mb-0 text-sm"},[_vm._v(_vm._s(row.safety_observation_type.safety_observation_type_code))])])],1)]}}])}),_c('el-table-column',{attrs:{"label":"CATEGORY","min-width":"170px","prop":"safety_observation_category.safety_observation_category","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('b-media',{staticClass:"align-items-center",attrs:{"no-body":""}},[_c('b-media-body',[_c('span',{staticClass:"font-weight-600 name mb-0 text-sm"},[_vm._v(_vm._s(row.safety_observation_category.safety_observation_category))])])],1)]}}])}),_c('el-table-column',{attrs:{"label":"STATUS","min-width":"170px","prop":"safety_observation_status.status_code","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('b-media',{staticClass:"align-items-center",attrs:{"no-body":""}},[_c('b-media-body',[_c('span',{staticClass:"font-weight-600 name mb-0 text-sm"},[_vm._v(_vm._s(row.safety_observation_status.status_code))])])],1)]}}])}),_c('el-table-column',{attrs:{"label":"TARGET DATE","min-width":"170px","prop":"target_date","sortable":"","sort-method":_vm.sortDateTargetDate},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('b-media',{staticClass:"align-items-center",attrs:{"no-body":""}},[_c('b-media-body',[_c('span',{staticClass:"font-weight-600 name mb-0 text-sm"},[_vm._v(_vm._s(row.target_date))])])],1)]}}])}),_c('el-table-column',{attrs:{"min-width":"180px","align":"right","label":"Actions"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var $index = ref.$index;
var row = ref.row;
return _c('div',{staticClass:"d-flex"},[_c('base-button',{attrs:{"size":"sm","type":"primary"},nativeOn:{"click":function($event){return _vm.handleDetails($index, row)}}},[_vm._v(" View/Edit ")])],1)}}])})],1)],1),_c('div',{staticClass:"col-12 d-flex justify-content-center justify-content-sm-between flex-wrap",attrs:{"slot":"footer"},slot:"footer"},[_c('div',{},[_c('p',{staticClass:"card-category"},[_vm._v(" Showing "+_vm._s(_vm.from + 1)+" to "+_vm._s(_vm.to)+" of "+_vm._s(_vm.total)+" entries "),(_vm.selectedRows.length)?_c('span',[_vm._v("     "+_vm._s(_vm.selectedRows.length)+" rows selected ")]):_vm._e()])]),_c('base-pagination',{staticClass:"pagination-no-border",attrs:{"current":_vm.pagination.currentPage,"per-page":_vm.pagination.perPage,"total":_vm.total},on:{"change":function($event){return _vm.paginationChanged($event)}}})],1)])],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }