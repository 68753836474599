<template>
  <div class="content">
    <base-header class="pb-6">
      <b-row align-v="center" class="py-4">
        <b-col cols="7" lg="6">
          <h6 class="h2 text-white d-inline-block mb-0">Toolbox Meeting</h6>
        </b-col>
        <b-col lg="6" cols="5" class="text-right" v-if="button_access.new_button == 'YES'">
          <router-link to="/healthSafety/toolboxMeetingNew">
            <base-button size="sm" type="neutral" >New Toolbox Meeting</base-button>
          </router-link>
        </b-col>
      </b-row>
    </base-header>
    <b-container fluid class="mt--6">
      <div>
        <card class="no-border-card" body-classes="px-0 pb-1" footer-classes="pb-2">
          <div>
            <b-col cols="12" class="d-flex justify-content-center justify-content-sm-between flex-wrap">
              <el-select
                class="select-primary pagination-select"
                v-model="pagination.perPage"
                placeholder="Per page">
                <el-option
                  class="select-primary" 
                  v-for="item in pagination.perPageOptions"
                  :key="item"
                  :label="item"
                  :value="item">
                </el-option>
              </el-select>

              <div>
                <base-input v-model="searchQuery"
                            prepend-icon="fas fa-search"
                            placeholder="Search...">
                </base-input>
              </div>
            </b-col>

            <b-col>
              <div class="form-row">

                <b-col cols="6">
                  <base-input
                    label="From Date"
                    name="From Date"
                    type="date"
                    v-model="filter.from_date"
                  >
                  </base-input>
                </b-col>

                <b-col cols="6">
                  <base-input
                    label="To Date"
                    name="To Date"
                    type="date"
                    v-model="filter.to_date"
                  >
                  </base-input>
                </b-col>

              </div>

              <b-row>
                <b-col class="text-right">
                  <base-button size="sm" type="primary" @click="getToolboxMeetingListingRange()"
                    >Search</base-button
                  >
                </b-col>
              </b-row>
            </b-col>

            <b-col><br></b-col>            

            <el-table :data="queriedData"
                      row-key="toolbox_meeting_id"
                      header-row-class-name="thead-light"
                      @sort-change="sortChange">

              <el-table-column label="TBM Date"
                             min-width="170px"
                             prop="toolbox_meeting_date">
                <template v-slot="{row}">
                    <b-media no-body class="align-items-center">
                        <b-media-body>
                            <span class="font-weight-600 name mb-0 text-sm">{{ row.toolbox_meeting_date }}</span>
                        </b-media-body>
                    </b-media>
                </template>
              </el-table-column>

              <!-- <el-table-column label="TBM Date Original"
                             min-width="170px"
                             prop="toolbox_meeting_date_original"
                             sortable>
                <template v-slot="{row}">
                    <b-media no-body class="align-items-center">
                        <b-media-body>
                            <span class="font-weight-600 name mb-0 text-sm">{{ row.toolbox_meeting_date_original }}</span>
                        </b-media-body>
                    </b-media>
                </template>
              </el-table-column> -->

              <el-table-column label="TBM Sites"
                             min-width="170px"
                             prop="project_sites">
                <template v-slot="{row}">
                    <div v-for="item in row.project_sites" :key="item.toolbox_meeting_site_id">
                      <b-media no-body class="align-items-center" v-if="item.active_status == 1">
                        <b-media-body>
                            <span class="font-weight-600 name mb-0 text-sm">{{ item.project_site.site_name }}</span>
                        </b-media-body>
                      </b-media>
                    </div>
                </template>
              </el-table-column>

              <el-table-column label="SUBMITTED BY"
                             min-width="170px"
                             prop="created_by.name"
                             sortable>
                <template v-slot="{row}">
                    <b-media no-body class="align-items-center">
                        <b-media-body>
                            <span class="font-weight-600 name mb-0 text-sm">{{row.created_by.name}}</span>
                        </b-media-body>
                    </b-media>
                </template>
              </el-table-column>

              <el-table-column min-width="180px" align="right" label="Actions">
                <div slot-scope="{$index, row}" class="d-flex">
                  <base-button
                    @click.native="handleDetails($index, row)" size="sm" type="primary">
                    View/Edit
                  </base-button>
                </div>
              </el-table-column>
            </el-table>
          </div>

          <div
            slot="footer"
            class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap">
            <div class="">
              <p class="card-category">
                Showing {{ from + 1 }} to {{ to }} of {{ total }} entries

                <span v-if="selectedRows.length">
                  &nbsp; &nbsp; {{selectedRows.length}} rows selected
                </span>
              </p>
            </div>
            <base-pagination
              class="pagination-no-border"
              :current="pagination.currentPage"
              :per-page="pagination.perPage"
              :total="total"
              @change="paginationChanged($event)">
            </base-pagination>
             <!-- <div>
              <h5 class="text-danger"> Note :
                  <i>
                  Please Create at least ONE Project Template & ONE Project Template Details before you can proceed POPULATE / IMPORT to prevent any Error. Go to NEW Template Then "Detail Listing" >> "New Template Details".
                  </i>
              </h5>
            </div> -->
          </div>
        </card>
      </div>
    </b-container>
    </div>
</template>
<script>
import { Table, TableColumn, Select, Option } from 'element-ui';
import RouteBreadCrumb from '@/components/Breadcrumb/RouteBreadcrumb'
import { BasePagination } from '@/components';
import standardPaginationMix from '@/assets/js/standardPaginationMix.js'
import Fuse from 'fuse.js';
import axios from 'axios'
import {LoginURL, clientId, clientSecret, apiDomain, getHeader, getcurrentDate} from '@/assets/js/config.js'
import ErrorFunctionMixin from '@/assets/js/errorFunctionMix.js'

export default {
  mixins: [standardPaginationMix, ErrorFunctionMixin],
  components: {
    BasePagination,
    RouteBreadCrumb,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn
  },
  created()
  {
    this.setDefaultMonthRange();

    const LoginUser = JSON.parse(window.localStorage.getItem('loginUser'))
    this.myUserID = LoginUser
    const userInfo = JSON.parse(window.localStorage.getItem('loginUser'))
    this.user_role = userInfo.role_id;
    this.user_id = userInfo.id;
    if (this.user_role == 12) {
      this.getContractorId();
    }

    var toolbox_meeting_access = JSON.parse(window.localStorage.getItem('toolbox_meeting_access'))
    this.button_access.new_button = toolbox_meeting_access.access_create
    
    this.getToolboxMeetingListingRange();

  },
  data() {
    return {
      user_role: null,
      user_id: null,
      contractor_id: null,
      propsToSearch:
      [
        'project_sites.project_site.site_name',
        'created_by.name'
      ],
      tableColumns: [],
      tableData: [],
      selectedRows: [],
      myUserID: null,
      button_access:
      {
        new_button: null,
        project_view: null,
        milestone_listing: null,
      },
      groupId: [],
      groupMembers: [],
      filter: {
        from_date: null,
        to_date: null,
      },
      isFromDateAfterToDate: false,
    };
  },
  methods: {
    setDefaultMonthRange(){
      var currentDate = getcurrentDate();
      var localCurrentDate = new Date(currentDate);

      // get current date in yyyy-mm-dd format
      const currentYear = localCurrentDate.getFullYear();
      const currentMonth = String(localCurrentDate.getMonth() + 1).padStart(2, '0');
      const currentDay = String(localCurrentDate.getDate()).padStart(2, '0');
      this.filter.to_date = currentYear + '-' + currentMonth + '-' + currentDay;

      // get first day of last month in yyyy-mm-dd format
      var fromDate = new Date(localCurrentDate.setMonth(localCurrentDate.getMonth()-1));
      fromDate = new Date(fromDate.setDate(1));
      const fromYear = fromDate.getFullYear();
      const fromMonth = String(fromDate.getMonth() + 1).padStart(2, '0');
      const fromDay = String(fromDate.getDate()).padStart(2, '0');
      this.filter.from_date = fromYear + '-' + fromMonth + '-' + fromDay;
    },
    getToolboxMeetingListingRange() {
      if (this.validateDateTime()) {
        if (!this.isFromDateAfterToDate) {
          axios.get(apiDomain + 'health_safety/toolbox_meeting.show_by_dates' + '?from_date=' + this.filter.from_date + '&to_date=' + this.filter.to_date, { headers: getHeader() })
          .then ( response => {
          if (response.status === 200)
          {
              this.tableData = response.data.data
              this.fuseData()
          }
          })
          .catch ( error => {
          console.log(error.response.status)
          this.errorFunction(error, "Toolbox Meeting Information")
          });
        } else {
          this.redErrorNotify(
            "<b>Invalid Input : From Date is greater than To Date </b>"
          );
        }
      } else {
        this.redErrorNotify(
          "<b>Invalid Input : From Date or To Date is invalid </b>"
        );
      }
    },
    fuseData()
    {
      this.fuseSearch = new Fuse(this.tableData, {
      keys: this.propsToSearch,
      threshold: 0
      });
    },
    handleDetails(index, row)
    {
      this.$router.push({path: 'toolboxMeetingView/' + row.toolbox_meeting_id });
    },
    getContractorId(){
      axios.get(apiDomain + 'lookup/contractor.contractor_id/' + this.myUserID.id, { headers: getHeader() })
        .then ( response => {
        if (response.status === 200)
        {
          this.contractor_id = response.data.contractor_id;
        }
        })
        .catch ( error => {
          this.errorFunction(error, 'Contractor Id')
        });
    },
    paginationChanged(page)
    {
        this.pagination.currentPage = page
    },
    selectionChange(selectedRows)
    {
        this.selectedRows = selectedRows
    },
    validateDateTime() {
      if (this.filter.from_date == '' || this.filter.to_date == '') {
        return false;
      } else {
        const fromDate = new Date(this.filter.from_date);
        const toDate = new Date(this.filter.to_date);
        this.isFromDateAfterToDate = fromDate > toDate;
        return true;
      }
      
    },
  }
};
</script>
<style>
  .no-border-card .card-footer{
    border-top: 0;
  }
</style>
