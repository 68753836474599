import axios from 'axios'
import { apiDomain, getHeader } from '@/assets/js/config.js'

/**
 * An Axios instance with default baseURL and headers.
 */
const apiAxios = axios.create({
  baseURL: apiDomain,
  headers: getHeader(),
});

export default apiAxios
