var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-block text-center"},[_c('div',{staticClass:"text-center text-white mt-3 mb-2"},[_c('div',[_c('validation-observer',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{staticClass:"needs-validation",on:{"submit":function($event){$event.preventDefault();handleSubmit(_vm.$emit('submitt', _vm.newForm))}}},[_c('b-row',[_c('b-col',[_c('b-row',{staticClass:"mb-4"},[_c('b-col',{attrs:{"md":"6"}},[_c('div',{staticClass:"text-white h3 text-left"},[_vm._v(_vm._s(_vm.template.category1_name))]),_c('validation-provider',{attrs:{"name":_vm.template.category1_name,"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"invalid-feedback":errors[0]}},[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.newForm.category1_id),expression:"newForm.category1_id"}],staticClass:"form-control",class:classes,on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.newForm, "category1_id", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},_vm._l((_vm.categories1),function(item){return _c('option',{key:item.id,domProps:{"value":item.id}},[_vm._v(_vm._s(item.code)+" - "+_vm._s(item.name))])}),0)])]}}],null,true)})],1),_c('b-col',{attrs:{"md":"6"}},[_c('div',{staticClass:"ml-2 text-white h3 text-left"},[_vm._v(_vm._s(_vm.template.category2_name))]),_c('validation-provider',{attrs:{"name":_vm.template.category2_name,"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"invalid-feedback":errors[0]}},[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.newForm.category2_id),expression:"newForm.category2_id"}],staticClass:"form-control",class:classes,on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.newForm, "category2_id", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},_vm._l((_vm.categories2),function(item){return _c('option',{key:item.id,domProps:{"value":item.id}},[_vm._v(_vm._s(item.code)+" - "+_vm._s(item.name))])}),0)])]}}],null,true)})],1)],1),_c('b-row',{staticClass:"mb-4"},[(_vm.template.category3_enabled)?_c('b-col',{attrs:{"md":"6"}},[_c('div',{staticClass:"ml-2 text-white h3 text-left"},[_vm._v(_vm._s(_vm.template.category3_name))]),_c('validation-provider',{attrs:{"name":_vm.template.category3_name,"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"invalid-feedback":errors[0]}},[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.newForm.category3_id),expression:"newForm.category3_id"}],staticClass:"form-control",class:classes,on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.newForm, "category3_id", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},_vm._l((_vm.categories3),function(item){return _c('option',{key:item.id,domProps:{"value":item.id}},[_vm._v(_vm._s(item.code)+" - "+_vm._s(item.name))])}),0)])]}}],null,true)})],1):_vm._e(),_c('b-col',{attrs:{"md":"6"}},[_c('div',{staticClass:"ml-2 text-white h3 text-left"},[_vm._v("Position")]),_c('validation-provider',{attrs:{"name":"Position","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"invalid-feedback":errors[0]}},[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.newForm.position),expression:"newForm.position"}],staticClass:"form-control",class:classes,on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.newForm, "position", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{domProps:{"value":0}},[_vm._v("First")]),_vm._l((_vm.documents),function(item){return _c('option',{key:item.id,domProps:{"value":item.sequence}},[_vm._v("After: "+_vm._s(item.title))])})],2)])]}}],null,true)})],1)],1),_c('b-row',{staticClass:"mb-4"},[_c('b-col',{attrs:{"md":"6"}},[_c('div',{staticClass:"ml-2 text-white h3 text-left"},[_vm._v("Title")]),_c('validation-provider',{attrs:{"name":"Title","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"invalid-feedback":errors[0]}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.newForm.title),expression:"newForm.title"}],staticClass:"form-control",class:classes,attrs:{"type":"text","maxlength":"300"},domProps:{"value":(_vm.newForm.title)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.newForm, "title", $event.target.value)}}})])]}}],null,true)})],1),_c('b-col',{attrs:{"md":"6"}},[_c('div',{staticClass:"ml-2 text-white h3 text-left"},[_vm._v("Alternative Title")]),_c('validation-provider',{attrs:{"name":"Alternative Title"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"invalid-feedback":errors[0]}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.newForm.alt_title),expression:"newForm.alt_title"}],staticClass:"form-control",class:classes,attrs:{"type":"text","local":"","maxlength":"300"},domProps:{"value":(_vm.newForm.alt_title)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.newForm, "alt_title", $event.target.value)}}})])]}}],null,true)})],1)],1),_c('b-row',{staticClass:"mb-4"},[_c('b-col',{attrs:{"md":"12"}},[_c('div',{staticClass:"ml-2 text-white h3 text-left"},[_vm._v("Reviewer/Approver Roles")]),_c('validation-provider',{attrs:{"name":"Reviewer/Approver Roles","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"invalid-feedback":errors[0]}},[_c('b-form-checkbox-group',{staticClass:"text-left text-white border rounded p-2",staticStyle:{"background-color":"#2C545A"},attrs:{"options":_vm.roles,"stacked":"","state":errors[0] ? false : null},model:{value:(_vm.newForm.selectedRoles),callback:function ($$v) {_vm.$set(_vm.newForm, "selectedRoles", $$v)},expression:"newForm.selectedRoles"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors[0] ? false : null}},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1)],1),_c('b-button',{staticClass:"btn btn-primary",attrs:{"variant":"outline-danger","type":"button"},on:{"click":function($event){return _vm.close()}}},[_vm._v("Cancel")]),(_vm.access.create)?_c('button',{staticClass:"btn btn-primary"},[_vm._v("Create")]):_vm._e()],1)],1)],1)]}}])})],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }