<template>
  <div>
    <base-header class="pb-6">
      <b-row class="py-4">
        <b-col>
          <h6 class="h2 text-white d-inline-block mb-0">{{ title }}</h6>
        </b-col>
        <b-col class="text-right">
          <router-link to="../../Home" class="btn btn-neutral btn-sm">
            Documents
          </router-link>
        </b-col>
      </b-row>
    </base-header>

    <b-container fluid class="mt--6">
      <div class="card-wrapper">
        <card>
          <!-- Card header -->
          <table slot="header">
            <tr>
              <td class="pr-3">Project</td>
              <td><h4 class="mb-0">{{ setting.project_name }}</h4></td>
            </tr>
            <tr>
              <td class="pr-3">Site</td>
              <td><h4 class="mb-0">{{ setting.site_name }}</h4></td>
            </tr>
            <tr>
              <td class="py-2" colspan="2"></td>
            </tr>
            <tr>
              <td class="pr-3">{{ setting.category1_name }}</td>
              <td><h4 class="mb-0">{{ document.category1_code }}</h4></td>
            </tr>
            <tr>
              <td class="pr-3">{{ setting.category2_name }}</td>
              <td><h4 class="mb-0">{{ document.category2_code }}</h4></td>
            </tr>
            <tr>
              <td class="pr-3">{{ setting.category3_name }}</td>
              <td><h4 class="mb-0">{{ document.category3_code }}</h4></td>
            </tr>
            <tr>
              <td class="pr-3">Document Number</td>
              <td><h4 class="mb-0">{{ document.number }}</h4></td>
            </tr>
            <tr>
              <td class="pr-3">Title</td>
              <td><h4 class="mb-0">{{ document.title }}</h4></td>
            </tr>
            <tr>
              <td class="pr-3">Alternative Title</td>
              <td><h4 class="mb-0">{{ document.alt_title }}</h4></td>
            </tr>
            <tr>
              <td class="pr-3 align-top">Reviewer/Approver Roles</td>
              <td><h4 class="mb-0" style="white-space: pre-wrap">{{ documentRoles }}</h4></td>
            </tr>
            <tr>
              <td class="pr-3">Status</td>
              <td>
                <h4 class="mb-0">
                  <StatusIcon :status="document.status" class="mr-1"/>
                  {{ documentStatusToText(document.status) }}
                </h4>
              </td>
            </tr>
          </table>

          <!-- Card body -->

          <!-- Revision list -->
          <template v-if="uploadAccess.listing">
            <div v-for="revision of revisions" :key="revision.id">

              <!-- Revision header -->
              <div class="d-flex align-items-baseline justify-content-between bg-light p-2">
                <h3 class="mb-0">Revision {{ revision.revision }}</h3>
                <div v-if="canChangeRevision(revision)">
                  <button class="btn btn-success btn-sm" :disabled="revision.uploads.length == 0" @click="submitForReview(revision)">Submit for Review</button>
                  <button class="btn btn-danger btn-sm" @click="deleteRevision(revision)">Delete Revision</button>
                </div>
              </div>

              <!-- Revision forms -->
              <div v-if="canChangeRevision(revision)" class="mt-2 px-2">
                <!-- Comment form -->
                <form v-if="revision.isEditing" @submit.prevent="updateRevision(revision)">
                  <b-form-group label="Comment" class="mb-2">
                    <textarea v-model="revision.comment" class="form-control" rows="3" maxlength="1000" :id="`rev-cmt-${revision.id}`"></textarea>
                  </b-form-group>
                  <button class="btn btn-primary btn-sm">Save Comment</button>
                  <button class="btn btn-light btn-sm" type="button" @click="revertRevision(revision)">Discard</button>
                </form>
                <template v-else>
                  <b-form-group label="Comment" class="mb-2">
                    <div class="border rounded p-2" style="white-space: pre-wrap; font-size: .9rem">
                      <template v-if="revision.comment">{{ revision.comment }}</template>
                      <template v-else>&nbsp;</template>
                    </div>
                  </b-form-group>
                  <button class="btn btn-primary btn-sm" @click="editRevision(revision)">Edit Comment</button>
                </template>

                <!-- Files upload -->
                <b-row class="mt-4">
                  <b-col>
                    <el-table :data="revision.uploads" header-row-class-name="thead-light" cell-class-name="fw-semibold">
                      <el-table-column label="File">
                        <template v-slot="{ row }">
                          <a href="#" @click.prevent="downloadFile(row.id)">{{ row.filename }}</a>
                        </template>
                      </el-table-column>
                      <el-table-column label="Size" width="150px">
                        <template v-slot="{ row }">
                          {{ formatSize(row.size) }}
                        </template>
                      </el-table-column>
                      <el-table-column label="Actions" width="150px">
                        <template v-slot="{ row }">
                          <button class="btn btn-danger btn-sm" @click="deleteUpload(row)">Delete</button>
                        </template>
                      </el-table-column>
                    </el-table>
                  </b-col>
                  <b-col>
                    <div class="border p-2">
                      <!-- eslint-disable-next-line vue/require-v-for-key -->
                      <div v-for="(file, index) of files" class="alert border-bottom mb-2 p-2 d-flex align-items-center">
                        <div class="flex-fill">
                          <div class="fw-semibold">{{ file.name }}</div>
                          {{ formatSize(file.size) }}
                        </div>
                        <button class="btn btn-warning btn-sm" @click="removeFile(index)">&times;</button>
                      </div>
                      <input type="file" class="d-none" :id="`files-${revision.id}`" multiple @change="currentFileChanged($event)">
                      <button class="btn btn-primary btn-sm" @click="showFileDialog(`files-${revision.id}`)">Choose Files...</button>
                    </div>
                    <button class="btn btn-primary btn-sm mt-3" :disabled="files.length == 0" @click="uploadFiles(revision)">Upload</button>
                  </b-col>
                </b-row>
              </div>

              <!-- New revision message -->
              <div v-else-if="revision.isNew" class="p-2">
                A new revision is being prepared by {{ revision.created_by.name }}.
              </div>

              <!-- Revision detail -->
              <template v-else>
                <!-- Comment -->
                <el-table :data="[revision]" header-row-class-name="thead-light" cell-class-name="fw-semibold">
                  <el-table-column label="Comment">
                    <template v-slot="{ row }">
                      <div style="white-space: pre-wrap">{{ row.comment }}</div>
                    </template>
                  </el-table-column>
                  <el-table-column label="Uploaded By" prop="created_by.name"/>
                  <el-table-column label="Uploaded On" prop="created_at"/>
                  <el-table-column label="Status">
                    <template v-slot="{ row }">
                      <StatusIcon :status="row.status" class="mr-1"/>
                      {{ revisionStatusToText(row.status) }}
                    </template>
                  </el-table-column>
                </el-table>

                <!-- Files -->
                <el-table :data="revision.uploads" header-row-class-name="thead-light" cell-class-name="fw-semibold">
                  <el-table-column label="File">
                    <template v-slot="{ row }">
                      <a href="#" @click.prevent="downloadFile(row.id)">{{ row.filename }}</a>
                    </template>
                  </el-table-column>
                  <el-table-column label="Size" width="150px">
                    <template v-slot="{ row }">
                      {{ formatSize(row.size) }}
                    </template>
                  </el-table-column>
                </el-table>
              </template>

              <!-- Review list -->
              <div v-if="reviewAccess.listing" class="px-5">
                <div v-for="review of revision.reviews" :key="review.id" class="mt-4">

                  <!-- Review header -->
                  <div class="d-flex align-items-baseline justify-content-between bg-light p-2">
                    <h4 class="mb-0">Review</h4>
                    <div v-if="canChangeReview(review)">
                      <button class="btn btn-success btn-sm" @click="acceptReview(review)">Accept Revision</button>
                      <button class="btn btn-warning btn-sm" @click="rejectReview(review)">Reject Revision</button>
                      <button class="btn btn-danger btn-sm" @click="deleteReview(review)">Delete Review</button>
                    </div>
                  </div>

                  <!-- Review forms -->
                  <div v-if="canChangeReview(review)" class="mt-2 px-2">
                    <!-- Comment form -->
                    <form v-if="review.isEditing" @submit.prevent="updateReview(review)">
                      <b-form-group label="Comment" class="mb-2">
                        <textarea v-model="review.comment" class="form-control" rows="3" maxlength="1000" :id="`rev-cmt-${review.id}`"></textarea>
                      </b-form-group>
                      <button class="btn btn-primary btn-sm">Save Comment</button>
                      <button class="btn btn-light btn-sm" type="button" @click="revertRevision(review)">Discard</button>
                    </form>
                    <template v-else>
                      <b-form-group label="Comment" class="mb-2">
                        <div class="border rounded p-2" style="white-space: pre-wrap; font-size: .9rem">
                          <template v-if="review.comment">{{ review.comment }}</template>
                          <template v-else>&nbsp;</template>
                        </div>
                      </b-form-group>
                      <button class="btn btn-primary btn-sm" @click="editRevision(review)">Edit Comment</button>
                    </template>

                    <!-- Files upload -->
                    <b-row class="mt-4">
                      <b-col>
                        <el-table :data="review.uploads" header-row-class-name="thead-light" cell-class-name="fw-semibold">
                          <el-table-column label="File">
                            <template v-slot="{ row }">
                              <a href="#" @click.prevent="downloadFile(row.id)">{{ row.filename }}</a>
                            </template>
                          </el-table-column>
                          <el-table-column label="Size" width="150px">
                            <template v-slot="{ row }">
                              {{ formatSize(row.size) }}
                            </template>
                          </el-table-column>
                          <el-table-column label="Actions" width="150px">
                            <template v-slot="{ row }">
                              <button class="btn btn-danger btn-sm" @click="deleteUpload(row)">Delete</button>
                            </template>
                          </el-table-column>
                        </el-table>
                      </b-col>
                      <b-col>
                        <div class="border p-2">
                          <!-- eslint-disable-next-line vue/require-v-for-key -->
                          <div v-for="(file, index) of files" class="alert border-bottom mb-2 p-2 d-flex align-items-center">
                            <div class="flex-fill">
                              <div class="fw-semibold">{{ file.name }}</div>
                              {{ formatSize(file.size) }}
                            </div>
                            <button class="btn btn-warning btn-sm" @click="removeFile(index)">&times;</button>
                          </div>
                          <input type="file" class="d-none" :id="`files-${review.id}`" multiple @change="currentFileChanged($event)">
                          <button class="btn btn-primary btn-sm" @click="showFileDialog(`files-${review.id}`)">Choose Files...</button>
                        </div>
                        <button class="btn btn-primary btn-sm mt-3" :disabled="files.length == 0" @click="uploadFiles(review)">Upload</button>
                      </b-col>
                    </b-row>
                  </div>

                  <!-- New review message -->
                  <div v-else-if="review.isNew" class="p-2">
                    This revision is being reviewed by {{ review.created_by.name }} from {{ getRoleName(review.created_by.role_id) }}.
                  </div>

                  <!-- Review detail -->
                  <template v-else>
                    <!-- Comment -->
                    <el-table :data="[review]" header-row-class-name="thead-light" cell-class-name="fw-semibold">
                      <el-table-column label="Comment">
                        <template v-slot="{ row }">
                          <div style="white-space: pre-wrap">{{ row.comment }}</div>
                        </template>
                      </el-table-column>
                      <el-table-column label="Reviewed By" prop="created_by.name"/>
                      <el-table-column label="Role">
                        <template v-slot="{ row }">
                          {{ getRoleName(row.created_by.role_id) }}
                        </template>
                      </el-table-column>
                      <el-table-column label="Reviewed On" prop="created_at"/>
                      <el-table-column label="Status">
                        <template v-slot="{ row }">
                          <StatusIcon :status="row.status" class="mr-1"/>
                          {{ revisionStatusToText(row.status) }}
                        </template>
                      </el-table-column>
                    </el-table>

                    <!-- Files -->
                    <el-table v-if="review.uploads.length" :data="review.uploads" header-row-class-name="thead-light" cell-class-name="fw-semibold">
                      <el-table-column label="File">
                        <template v-slot="{ row }">
                          <a href="#" @click.prevent="downloadFile(row.id)">{{ row.filename }}</a>
                        </template>
                      </el-table-column>
                      <el-table-column label="Size" width="150px">
                        <template v-slot="{ row }">
                          {{ formatSize(row.size) }}
                        </template>
                      </el-table-column>
                    </el-table>
                  </template>
                </div>

                <!-- Create review -->
                <div v-if="canCreateReview(revision)" class="mt-4 d-flex align-items-baseline justify-content-between bg-light p-2">
                  <h4 class="mb-0">Review</h4>
                  <button class="btn btn-success btn-sm" @click="createReview">Create</button>
                </div>
              </div>

              <br>
            </div>

            <div v-if="revisions.length == 0 && !uploadAccess.create" class="text-center">
              <i>No Revisions</i>
            </div>
          </template>

          <!-- Create revision -->
          <div v-if="canCreateRevision()" class="d-flex align-items-baseline justify-content-between bg-light p-2">
            <h3 class="mb-0">New Revision</h3>
            <button class="btn btn-success btn-sm" @click="createRevision">Create</button>
          </div>
        </card>
      </div>
    </b-container>

    <!-- Confirmation modal dialog -->
    <modal :show.sync="confirmDialogVisible" size="sm" body-classes="p-0">
      <card type="secondary"
            header-classes="bg-transparent pb-5"
            body-classes="px-lg-5 py-lg-5"
            class="border-0 mb-0">
        <div class="text-center text-muted mb-4">
          <h3>{{ confirmDialogMessage }}</h3>
        </div>
        <div class="text-center">
          <button class="btn" :class="`btn-` + confirmDialogKind" @click="confirmDialogAccept">YES</button>
          <button class="btn btn-dark" @click="hideConfirmDialog">NO</button>
        </div>
      </card>
    </modal>
  </div>
</template>

<script>
import ErrorFunctionMixin from '@/assets/js/errorFunctionMix.js'
import { apiDomain, getUserInfo } from '@/assets/js/config'
import { Table, TableColumn } from 'element-ui'
import axios from '@/util/axios'
import { getAccessByModule } from '@/util/access'
import { formatBytesInUnits, documentStatusToText, revisionStatusToText } from './util'
import StatusIcon from './StatusIcon.vue'

export default {
  mixins: [ErrorFunctionMixin],
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    StatusIcon,
  },

  data() {
    return {
      userId: 0,
      roleId: 0,
      title: 'Document Tracking',
      pid: null,
      sid: null,
      id: null,
      uploadAccess: {},
      reviewAccess: {},
      setting: {},
      document: {},
      revisions: [],
      comment: null,
      files: [],

      confirmDialogVisible: false,
      confirmDialogMessage: '',
      confirmDialogKind: '',
    }
  },

  computed: {
    documentRoles() {
      const roles = Array.isArray(this.document.roles) ? this.document.roles : []
      const names = roles.map(x => x.role_name).sort()
      return names.join('\n')
    },
  },

  created() {
    const {id, role_id} = getUserInfo()
    this.userId = id
    this.roleId = role_id

    this.pid = this.$route.params.pid
    this.sid = this.$route.params.sid
    this.id = this.$route.params.id
    this.uploadAccess = getAccessByModule('DOC_REPO_UPLOAD')
    this.reviewAccess = getAccessByModule('DOC_REPO_REVIEW')

    this.loadSetting()
    this.reload()
  },

  methods: {
    documentStatusToText(status) {
      return documentStatusToText(status)
    },

    revisionStatusToText(status) {
      return revisionStatusToText(status)
    },

    formatSize(size) {
      return formatBytesInUnits(size)
    },

    getRoleName(roleId) {
      const roles = Array.isArray(this.document.roles) ? this.document.roles : []
      const [role] = roles.filter(r => r.role_id == roleId)
      return role ? role.role_name : ''
    },

    confirmDialog(message, kind, callback) {
      this.confirmDialogMessage = message
      this.confirmDialogKind = kind
      this.confirmDialogCallback = callback
      this.confirmDialogVisible = true
    },

    hideConfirmDialog() {
      this.confirmDialogVisible = false
    },

    confirmDialogAccept() {
      if (typeof this.confirmDialogCallback === 'function') {
        this.confirmDialogCallback(() => this.hideConfirmDialog())
      }
    },

    loadSetting() {
      axios.get(`doc_repo/p/${this.pid}/s/${this.sid}/setting`)
        .then(response => {
          this.setting = response.data.data
        })
        .catch(error => {
          this.errorFunction(error, this.title)
        })
    },

    loadDocument() {
      axios.get(`doc_repo/p/${this.pid}/s/${this.sid}/document/${this.id}`)
        .then(response => {
          this.document = response.data.data
        })
        .catch(error => {
          this.errorFunction(error, this.title)
        })
    },

    loadRevisions() {
      axios.get(`doc_repo/p/${this.pid}/s/${this.sid}/document/${this.id}/revision`)
        .then(response => {
          const revisions = response.data.data

          // Group reviews into revisions.
          const groups = revisions
            .filter(x => x.version == 0)
            .sort((a, b) => a.revision - b.revision)

          for (const group of groups) {
            group.isNew     = group.status == 'NEW_REVISION'
            group.isEditing = false

            // Reviews.
            group.reviews = revisions
              .filter(x => x.revision == group.revision && x.version > 0)
              .map(review => {
                review.isNew     = review.status == 'NEW_REVIEW'
                review.isEditing = false

                review.isMyReview       = review.created_by.id      == this.userId
                review.isMyRoleReviewed = review.created_by.role_id == this.roleId

                return review
              })
              .sort((a, b) => a.version - b.version)
          }

          this.revisions = groups
        })
        .catch(error => {
          this.errorFunction(error, this.title)
        })
    },

    reload() {
      this.loadDocument()
      this.loadRevisions();
    },

    canCreateRevision() {
      return this.uploadAccess.create && ['EMPTY', 'REJECTED', 'APPROVED'].includes(this.document.status)
    },

    createRevision() {
      axios.post(`doc_repo/p/${this.pid}/s/${this.sid}/document/${this.id}/revision`, {})
        .then(response => {
          this.reload()
        })
        .catch(error => {
          this.errorFunction(error, this.title)
        })
    },

    canChangeRevision(revision) {
      return revision.isNew && this.uploadAccess.create && this.uploadAccess.edit && this.uploadAccess.delete
    },

    editRevision(revision) {
      revision._old_comment = revision.comment
      revision.isEditing    = true

      this.$nextTick(() => {
        document.getElementById(`rev-cmt-${revision.id}`).focus()
      })
    },

    revertRevision(revision) {
      revision.comment   = revision._old_comment
      revision.isEditing = false
    },

    updateRevision(revision) {
      const body = {
        comment: revision.comment,
      }

      axios.put(`doc_repo/p/${this.pid}/s/${this.sid}/document/${this.id}/revision/${revision.id}`, body)
        .then(response => {
          this.loadRevisions()

          this.$notify({
            message: 'Revision Updated',
            timeout: 5000,
            icon: 'ni ni-bell-55',
            type: 'default',
          });
        })
        .catch(error => {
          this.errorFunction(error, this.title)
        })
    },

    deleteRevision(revision) {
      this.confirmDialog('Delete this Revision?', 'danger', (done) => {
        axios.delete(`doc_repo/p/${this.pid}/s/${this.sid}/document/${this.id}/revision/${revision.id}`)
          .then(response => {
            this.reload()

            this.$notify({
              message: 'Revision Deleted',
              timeout: 5000,
              icon: 'ni ni-bell-55',
              type: 'default',
            });
          })
          .catch(error => {
            this.errorFunction(error, this.title)
          })
          .then(done)
      })
    },

    submitForReview(revision) {
      this.confirmDialog('Submit this Revision for review?', 'success', (done) => {
        const body = {
          comment: revision.comment,
        }

        axios.put(`doc_repo/p/${this.pid}/s/${this.sid}/document/${this.id}/revision/${revision.id}/submit`, body)
          .then(response => {
            this.reload()

            this.$notify({
              message: 'Revision Submitted',
              timeout: 5000,
              icon: 'ni ni-bell-55',
              type: 'default',
            });
          })
          .catch(error => {
            this.errorFunction(error, this.title)
          })
          .then(done)
      })
    },

    showFileDialog(id) {
      document.getElementById(id).click()
    },

    currentFileChanged(event) {
      const input = event.target
      this.files.push(...input.files)
      input.value = ''
    },

    removeFile(index) {
      this.files.splice(index, 1)
    },

    uploadFiles(revision) {
      const data = new FormData()
      this.files.forEach(file => data.append('files[]', file))

      axios.post(`doc_repo/p/${this.pid}/s/${this.sid}/document/${this.id}/revision/${revision.id}/upload`, data)
        .then(response => {
          this.files.splice(0)
          this.loadRevisions()

          this.$notify({
            message: 'Files Uploaded',
            timeout: 5000,
            icon: 'ni ni-bell-55',
            type: 'default',
          });
        })
        .catch(error => {
          this.errorFunction(error, this.title)
        })
    },

    deleteUpload(upload) {
      this.confirmDialog('Delete this File?', 'danger', (done) => {
        const { revision_id, id } = upload

        axios.delete(`doc_repo/p/${this.pid}/s/${this.sid}/document/${this.id}/revision/${revision_id}/upload/${id}`)
          .then(response => {
            this.loadRevisions()

            this.$notify({
              message: 'File Deleted',
              timeout: 5000,
              icon: 'ni ni-bell-55',
              type: 'default',
            });
          })
          .catch(error => {
            this.errorFunction(error, this.title)
          })
          .then(done)
      })
    },

    downloadFile(id) {
      axios.post(`doc_repo/token?value=${id}`)
        .then(response => {
          const token = response.data
          const url = `${apiDomain}doc_repo/download/${id}?token=${token}`

          window.open(url, '_blank')
        })
        .catch(error => {
          this.errorFunction(error, this.title)
        })
    },

    canCreateReview(revision) {
      return (revision.status == 'PENDING_REVIEW' || revision.status == 'ACCEPTED') && !revision.reviews.some(r => r.isMyRoleReviewed) && this.reviewAccess.create
    },

    createReview() {
      axios.post(`doc_repo/p/${this.pid}/s/${this.sid}/document/${this.id}/review`, {})
        .then(response => {
          this.reload()
        })
        .catch(error => {
          this.errorFunction(error, this.title)
        })
    },

    canChangeReview(review) {
      return review.isNew && review.isMyReview && this.reviewAccess.edit && this.reviewAccess.delete
    },

    updateReview(review) {
      const body = {
        comment: review.comment,
      }

      axios.put(`doc_repo/p/${this.pid}/s/${this.sid}/document/${this.id}/review/${review.id}`, body)
        .then(response => {
          this.loadRevisions()

          this.$notify({
            message: 'Review Updated',
            timeout: 5000,
            icon: 'ni ni-bell-55',
            type: 'default',
          });
        })
        .catch(error => {
          this.errorFunction(error, this.title)
        })
    },

    deleteReview(review) {
      this.confirmDialog('Delete this Review?', 'danger', (done) => {
        axios.delete(`doc_repo/p/${this.pid}/s/${this.sid}/document/${this.id}/review/${review.id}`)
          .then(response => {
            this.reload()

            this.$notify({
              message: 'Review Deleted',
              timeout: 5000,
              icon: 'ni ni-bell-55',
              type: 'default',
            });
          })
          .catch(error => {
            this.errorFunction(error, this.title)
          })
          .then(done)
      })
    },

    acceptReview(review) {
      this.confirmDialog('Accept this Revision?', 'success', (done) => {
        axios.put(`doc_repo/p/${this.pid}/s/${this.sid}/document/${this.id}/review/${review.id}/accept`)
          .then(response => {
            this.reload()

            this.$notify({
              message: 'Revision Accepted',
              timeout: 5000,
              icon: 'ni ni-bell-55',
              type: 'default',
            });
          })
          .catch(error => {
            this.errorFunction(error, this.title)
          })
          .then(done)
      })
    },

    rejectReview(review) {
      this.confirmDialog('Reject this Revision?', 'warning', (done) => {
        axios.put(`doc_repo/p/${this.pid}/s/${this.sid}/document/${this.id}/review/${review.id}/reject`)
          .then(response => {
            this.reload()

            this.$notify({
              message: 'Revision Rejected',
              timeout: 5000,
              icon: 'ni ni-bell-55',
              type: 'default',
            });
          })
          .catch(error => {
            this.errorFunction(error, this.title)
          })
          .then(done)
      })
    },
  },
}
</script>
