<template>
  <div class="bg-default-dashboard">
    <div class=" py-lg-8 pt-lg-5">
      <b-container fluid style="width: 90%; position: relative;">
        <!-- <div class="header-body "  > -->
        <b-row class="mb-2">
          <b-col>
            <h1 class="text-white">Safety Inspection</h1>
          </b-col>
        </b-row>

        <b-row class="mt-4">
          <b-col>
            <div class="row">
              <dataset style="width: 100%" v-slot="{ ds }" :ds-data="obj_data_grid.data"
                :ds-sortby="['-safety_observation_create_date_web', 'project_name', 'site_name', 'contractor_code', 'created_by',]"
                :ds-search-in="['project_name', 'contractor_code', 'site_name', 'created_by',]">
                <div class="row" style="padding-bottom: 15px" :data-page-count="ds.dsPagecount">
                  <!-- data grid - data per-page -->
                  <div class="col-md-2" style="
                      color: #ffffff;
                      font-family: Open Sans, sans-serif !important;
                      font-size: 0.875rem;
                    ">
                    <dataset-show class="dataset-style" />
                  </div>

                  <!-- data grid - filter selection -->
                  <div class="col-md-3">
                    <base-button type="dark" @click="action_on_modal('show', 'modal-filter', false)">
                      <span><i class="icon-filter-search-cs mr-1"></i></span>
                      <span class=""><strong>Filter</strong></span>
                      <span><i class="icon-sort-down-cs ml-6" style="font-size: large;"></i></span>
                    </base-button>
                  </div>

                  <!-- data grid - search-->
                  <div class="col-md-5">
                    <dataset-search ds-search-placeholder="Search..." />
                  </div>

                  <!-- create new safety inspection entry -->
                  <div class="col-md-2 text-right">
                    <base-button type="primary" @click="action_on_modal('show', 'modal-inspection-main')"><i class="icon-plus-cs mr-1"></i> New Inspection</base-button>
                  </div>

                </div>
                <div class="row">
                  <div class="col-md-12">
                    <div class="table-responsive">
                      <table style="width: 100%" class="table table-striped d-md-table">
                        <thead
                          style="background-color: #3a656b; color: white; font-size: small; border-bottom: 20px solid #082A2F;">
                          <tr>
                            <th v-for="(th, index) in obj_data_grid.cols" :key="th.field"
                              :class="['th-table-new', th.sort]">
                              {{ th.name }}
                              <i class="gg-select float-right"></i>
                            </th>
                          </tr>
                        </thead>
                        <dataset-item tag="tbody">
                          <template #default="{ row, rowIndex }">
                            <tr
                              style="background-color: #23454A; color: white; font-size: smaller; border-bottom:  20px solid #082A2F;">
                              <!-- <th scope="row">{{ rowIndex + 1 }}</th> -->
                              <td class="tr-td-table-new" style="min-width: 150px">
                                {{ row.project_name }}
                              </td>
                              <td class="tr-td-table-new" style="min-width: 150px">
                                {{ row.contractor_code }}
                              </td>
                              <td class="tr-td-table-new" style="min-width: 150px">
                                {{ row.site_name }}
                              </td>
                              <td class="tr-td-table-new" style="min-width: 150px">
                                {{ row.safety_observation_create_date_web }}
                              </td>
                              <td class="tr-td-table-new" style="min-width: 50px">
                                {{ getOpenItems(row.safety_observation_items) }}
                              </td>
                              <td class="tr-td-table-new" style="min-width: 50px">
                                {{
                                  getClosedItems(row.safety_observation_items)
                                }}
                              </td>
                              <td class="tr-td-table-new" style="min-width: 150px">
                                <span class="font-weight-600 name mb-0 text-sm" v-if="row.status == 0">Draft</span>
                                <span class="font-weight-600 name mb-0 text-sm" v-if="row.status == 1">Submitted</span>
                              </td>
                              <td class="tr-td-table-new" style="min-width: 150px">
                                {{ row.created_by }}
                              </td>
                              <td class="tr-td-table-new" style="min-width: 150px">
                                <b-link @click="handleDetails(rowIndex, row)"
                                  class="btn btn-sm btn-secondary rounded-pill text-white" target="_blank">
                                  <div class="icon-pencil-cs ml-1 mr-1"></div>
                                </b-link>                                
                                <b-link v-if="user_role == 3" @click="deleteDetails(rowIndex, row)" 
                                  style="color: rgb(255, 92, 0); font-size: large;" 
                                  class="btn btn-sm btn-secondary rounded-pill" target="_blank">
                                  <!-- <div class="fa fa-trash"></div> -->
                                  <div class="icon-trash-bin-cs ml-1 mr-1"></div>
                                </b-link>
                              </td>
                            </tr>
                          </template>
                        </dataset-item>
                      </table>
                    </div>
                  </div>
                </div>
                <div class="d-flex flex-md-row flex-column justify-content-between align-items-center" style="
                    color: #ffffff;
                    font-family: Open Sans, sans-serif !important;
                    font-size: 0.875rem;
                  ">
                  <dataset-info class="mb-2 mb-md-0" />
                  <dataset-pager />
                </div>
              </dataset>
            </div>

          </b-col>
        </b-row>

        
        <!-- Modal inspection -->
        <b-modal ref="modal-inspection-main" size="xl" centered hide-footer hide-header title="">
          <div class="d-block">
            <b-row class="mb-3">
              <b-col>
                <!-- modal top info -->
                <b-row class="mb-3">
                  <b-col> <span class="text-white h3">New Safety Inspections</span></b-col>
                  <b-col class="text-right">
                    <base-input name="Project Status" v-model="model_to_db.project_status" style="display: none;">
                      <select v-model="model_to_db.project_status" class="pl-3"
                        style="border-radius: 20px; border-color: #082A2F solid 0px; background-color: #FF5C0066; font-size: 12px; padding: 5px; color: white">
                        <option value="ongoing">On Going</option>
                        <option v-if="user_role != 12" value="completed">Completed</option>
                      </select>
                    </base-input>
                  </b-col>
                </b-row>
                <!-- site | epc -->
                <b-row>
                  <b-col>
                    <base-input label="">
                      <!-- <el-select v-model="selects.multiple" multiple filterable collapse-tags placeholder="Project Site"> -->
                      <el-select v-model="obj_list.site_selected" clearable collapse-tags value-key filterable remote
                        :remote-method="getSiteByName" :loading="obj_list.site_loading" placeholder="Location (type in)"
                        @change="getContractorFromSite">
                        <el-option v-for="option in obj_list.sites_all" :key="option.site_id" :label="option.site_name"
                          :value="option.site_id"></el-option>
                      </el-select>
                    </base-input>
                  </b-col>
                  <b-col md="5">
                    <b-form-input placeholder="Assignee" v-model="obj_obs_main.contractor_info"></b-form-input>
                  </b-col>
                </b-row>
                <!-- date | time | hour -->
                <b-row>
                  <b-col>
                    <base-input name="Date" type="date" v-model="model_to_db.safety_observation_create_date" @change="inspectionValidateDateTime"></base-input>
                  </b-col>
                  <b-col>
                    <b-form-input name="Time" type="time" v-model="model_to_db.safety_observation_create_time" @change="inspectionValidateDateTime"></b-form-input>
                  </b-col>
                  <b-col>
                    <b-form-group class="mb-0">
                      <b-input-group class="input-group-alternative input-group-merge">
                        <div class="input-group-prepend" style="margin: -1px;">
                          <span class="input-group-text"><i class="icon-clock-cs"></i></span>
                        </div>
                        <b-form-input placeholder="Hour(s)" type="text" min="1" max="50"
                          v-model="model_to_db.time_spent_hours" style="margin: -1px;"> </b-form-input>
                      </b-input-group>
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-col>
              <!-- conducted by -->
              <b-col md="5">
                <b-row class="mb-3">
                  <b-col>
                    <div class="text-white h3">Conducted by</div>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <div style="padding-bottom: 10px;" v-for="(input, index) in obj_obs_main.conducting_users"
                      :key="`reviewerInput-${index}`">
                      <b-list-group style="display: inline;">
                        <select class="form-control" style="width: 75%; display: inline;" v-if="index != 0"
                          v-model="input.userId" @change="removeFromList($event)">
                          <option Selected disabled value="x">
                            -- Please Select --
                          </option>
                          <option v-for="item in obj_obs_main.inspector_users" :key="item.id" v-bind:value="item.id"
                            :disabled="item.disabled" v-show="!item.v_hide">
                            {{ item.name }} - {{ item.email }}
                          </option>
                        </select>
                        <select class="form-control" style="width: 75%; display: inline;" v-else v-model="input.userId"
                          @change="removeFromList($event)" disabled>
                          <option Selected disabled value="x">
                            -- Please Select --
                          </option>
                          <option v-for="item in obj_obs_main.inspector_users" :key="item.id" v-bind:value="item.id"
                            :disabled="item.disabled" v-show="!item.v_hide">
                            {{ item.name }} - {{ item.email }}
                          </option>
                        </select>
                        <!-- <button class="btn-success">EDIT</button>  <button class="btn-danger">DELETE</button> -->
                        <b-list-group-item class="bg-cyan text-green text-center"
                          style="width: 15px; display: inline; margin: 5px 5px 5px 10px; padding: 10px; border-radius: 0.5rem;"
                          @click="addField(input, obj_obs_main.conducting_users)">
                          <i class="icon-plus-green-cs"></i>
                        </b-list-group-item>
                        <b-list-group-item class="bg-cyan text-red text-center"
                          style="width: 15px; display: inline; margin: 5px; padding: 10px; border-radius: 0.5rem;"
                          @click="removeField(index, obj_obs_main.conducting_users)"
                          v-show="obj_obs_main.conducting_users.length > 1 && index != 0">
                          <i class="icon-minus-red-cs"></i>
                        </b-list-group-item>
                      </b-list-group>
                    </div>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
            <!-- title | add new item -->
            <b-row class="mt-5 mb-3">
              <b-col>
                <div class="text-white h3">Observation Item</div>
              </b-col>
              <b-col class="text-right">
                <!-- <base-button type="success" @click="action_on_sub_modal('show', 'modal-inspection-item', 'modal-inspection-main')"> -->
                <base-button type="success" @click="showNewObservationItemFromAdd()">
                  <i class="icon-plus-cs mr-1"></i> Add New Item
                </base-button>
              </b-col>
            </b-row>
            <!-- items list -->
            <b-row>
              <b-col>
                <b-table ref="grid_observation_item" class="grid-obs-item" :items="model_to_db.safety_observation_items" :fields="obj_inspection.obs_item_fields" :tbody-tr-class="rowClass">
                  <template #cell(no.)="data">
                    {{ data.index + 1 }}
                  </template>

                  <template #cell(actions)="row">
                    <b-link @click="viewObservationItem(row.item, row.index, $event.target)"
                      class="btn btn-sm btn-secondary rounded-pill text-white">
                      <div class="icon-pencil-cs ml-1 mr-1"></div>
                    </b-link>
                    <b-link @click="deleteObservationItem(row.item, row.index, $event.target)"
                      style="color: rgb(255, 92, 0); font-size: large;" class="btn btn-sm btn-secondary rounded-pill"
                      target="_blank">
                      <div class="icon-trash-bin-cs"></div>
                    </b-link>
                  </template>
                </b-table>
              </b-col>
            </b-row>

            <!-- buttons - cancel | draft | save -->
            <b-row>
              <b-col class="">
                <base-button type="danger"
                  @click="action_on_modal('hide', 'modal-inspection-main')">Cancel</base-button>
              </b-col>
              <b-col class="text-right">
                <base-button v-if="!obj_obs_main.submit_clicked" type="primary" @click="saveAsDraft()">Save As Draft</base-button>
                <base-button v-if="!obj_obs_main.submit_clicked" type="success" @click="submitData()">Submit</base-button>
                <base-button v-if="obj_obs_main.submit_clicked" type="primary" @click="saveAsDraft()" disabled>Save As Draft</base-button>
                <base-button v-if="obj_obs_main.submit_clicked" type="success" @click="submitData()" disabled>Submit</base-button>
              </b-col>
            </b-row>
          </div>
        </b-modal>

        <!-- Modal observation item -->
        <modal :show.sync="obj_obs_item.isNewObservationItemVisible" size="xl" centered hide-footer hide-header title="New Observation Item" style="background-color: #306b74 !important;">
          <b-row>
            <b-col>
              <div class="text-white h3">New Observation Items</div>
            </b-col>
          </b-row>
          <!-- desc observation -->
          <b-row>
            <b-col>
              <base-input label="">
                <textarea class="form-control" placeholder="Observations" rows="3"
                  v-model="new_observation.observation_description"></textarea>
              </base-input>
            </b-col>
          </b-row>
          <!-- observation type | category -->
          <b-row>
            <b-col>
              <base-input name="Type" v-model="new_observation.safety_observation_type_id">
                <select class="form-control" v-model="new_observation.safety_observation_type_id"
                  @change="updateObservationCondition()">
                  <option Selected disabled value="x">-- Please Select A Safety Observation Type --</option>
                  <option v-for="item in safety_observation_type" :key="item.safety_observation_type_id"
                    v-bind:value="item.safety_observation_type_id">{{ item.safety_observation_type_code }}</option>
                </select>
              </base-input>
            </b-col>
            <b-col>
              <base-input name="Category" v-model="new_observation.safety_observation_category_id">
                <select class="form-control" v-model="new_observation.safety_observation_category_id">
                  <option Selected disabled value="x">-- Please Select A Safety Observation Category --</option>
                  <option v-for="item in safety_observation_category" :key="item.safety_observation_category_id"
                    v-bind:value="item.safety_observation_category_id">{{ item.safety_observation_category }}</option>
                </select>
              </base-input>
            </b-col>
          </b-row>
          <!-- observation priority -->
          <b-row>
            <b-col>
              <base-input name="Priority" v-model="new_observation.safety_observation_priority_id">
                <select class="form-control" v-model="new_observation.safety_observation_priority_id"
                  v-if="new_observation.safety_observation_type_id == 1 || new_observation.safety_observation_type_id == 3"
                  disabled>
                  <option Selected disabled value="x">-- Please Select A Priority --</option>
                  <option Selected disabled value=null>-- None --</option>
                  <option v-for="item in safety_observation_priority" :key="item.safety_observation_priority_id"
                    v-bind:value="item.safety_observation_priority_id">{{ item.safety_observation_priority_name }} -
                    {{ item.safety_observation_priority_duration }} Days</option>
                </select>
                <select class="form-control" v-model="new_observation.safety_observation_priority_id" v-else>
                  <option Selected disabled value="x">-- Please Select A Priority --</option>
                  <option v-for="item in safety_observation_priority" :key="item.safety_observation_priority_id"
                    v-bind:value="item.safety_observation_priority_id">{{ item.safety_observation_priority_name }} -
                    {{ item.safety_observation_priority_duration }} Days</option>
                </select>
              </base-input>
            </b-col>
          </b-row>
          <!-- repeated observation -->
          <b-row class="mb-3">
            <b-col><label for="" class="text-white">Repeated Observation</label></b-col>
            <b-col>
              <div class="text-right ">
                <!-- <base-switch disabled class="mr-2 " on-text="" off-text=""></base-switch> -->
                <toggle-button class="mr-2" v-model="new_observation.repeated_observation" />
                <!-- https://www.vuescript.com/nice-toggle-button-vue-js/ -->
              </div>
            </b-col>
          </b-row>
          <!-- need correction -->
          <b-row class="mb-3">
            <b-col><label for="" class="text-white">Need Correction Photo</label></b-col>
            <b-col>
              <div class="text-right ">
                <toggle-button class="mr-2" v-model="new_observation.correction_photos_required"
                  v-if="new_observation.safety_observation_type_id == 1 || new_observation.safety_observation_type_id == 3"
                  disabled />
                <toggle-button class="mr-2" v-model="new_observation.correction_photos_required" v-else />
              </div>
            </b-col>
          </b-row>

          <b-row>
            <b-col>
              <label for="" class="text-white">Max photos can be upload into observation item is {{ obj_obs_item.max_uploaded_photos }}</label>
            </b-col>
          </b-row>
          
          <b-row v-if="obj_obs_item.showUploadArea">
            <b-col>
              <dropzone-file-upload id="idDropzone" ref="refDropzone" v-model="inputs.fileMultiple" multiple></dropzone-file-upload>
            </b-col>
          </b-row>
 
          <b-row v-if="inputs.fileMultiple.length > 0" style="padding-bottom: 10px;">
            <b-col>
              <base-button @click="test3()" size="sm" type="danger" outline>Add Photo Remark</base-button>
            </b-col>
          </b-row>

          <div class="list-group dz-preview dz-preview-multiple list-group-lg list-group-flush" v-for="(item, index) in new_observation.safety_observation_photos" :key="index">
            <div class="list-group-item px-1 dz-image-preview" >
              <div class="row align-items-center mb-3" > 
                <div class="text-center col-md-2" >
                  <div><img :src="item.photoPreviewSrc" style="max-width:100px;max-height:100px; border-radius: 0.375rem;" v-if="item.photoFile" /></div>
                  <div><img :src="item.imageDataSrc" style="max-width:100px;max-height:100px; border-radius: 0.375rem;" v-if="item.image" /></div>
                </div>
                <div class="ml--3 mb-3 col-md-9" >
                  <h4 class="mb-1 text-white" v-if="item.photoFile">{{ item.photoFile.name }}</h4>
                  <h4 class="mb-1 text-white" v-if="item.image">{{ item.safety_observation_item_file_name }}</h4>
                  <b-form-input name="Observation Photo Remarks" placeholder="Observation Photo Remarks" v-model="item.photoRemarks" class="form-control"></b-form-input>
                </div>
                <div class="text-right col-md-1" >
                  <b-link class="btn btn-sm btn-secondary rounded-pill text-white" @click="deletePhoto(index)">
                    <span style="padding: 10px 5px 5px 5px">
                      <div class="icon-trash-bin-cs ml-1 mr-1"></div>
                    </span>
                  </b-link>                    
                </div>
              </div>
            </div>
          </div>



          <b-row style="padding-top: 20px;">
            <b-col class="text-right">
              <!-- <base-button @click="action_on_sub_modal('hide', 'modal-inspection-item', 'modal-inspection-main')"type="danger" outline>Cancel</base-button> -->
              <base-button @click="closeNewObservationItem()" type="danger" outline>Cancel</base-button>
              <base-button @click="saveNewObservationItem()" type="primary">Save</base-button>

            </b-col>
          </b-row>
        </modal>
        <!-- </b-modal> -->

        <!-- Modal submission -->
        <modal :show.sync="obj_obs_main.isSubmissionWarningVisible" modal-classes="in-progress-modal-lg" :no-close-on-backdrop="true" size="lg" body-classes="p-0" title="Submission Warning">
          <card type="secondary" header-classes="bg-transparent pb-5" body-classes="px-lg-5 py-lg-5" class="border-0 mb-0">
            <template>
              <div class="text-center text-muted mb-4">
                <h1 class="text-white"><b>Submission in Progress</b></h1>
              </div>
              <div class="text-center">
                <div style="color: #ffffff; padding-bottom: 15px;">
                  <!-- <span class="fa fa-spinner fa-spin fa-3x"></span> -->
                  <span class="icon-spinner-cs"></span>
                  <br>
                </div>
                <div style="color: #ffffff;">
                  Please be patient while we process your submission.<br>
                  Do not navigate away from this page or refresh the page until the submission is complete.<br>
                  You will be automatically redirected to another page once the process is finished.
                </div>
              </div>
            </template>
          </card>
        </modal>

        <!-- Modal filter -->
        <b-modal ref="modal-filter" size="lg" centered hide-footer hide-header title="">
          <div class="d-block">
            <!-- date | time | project name | site name -->
            <b-row class="mb-3">
              <b-col>
                <!-- <b-form-datepicker placeholder="Form Date" v-model="valuePMN" class="text-white mb-2"></b-form-datepicker> -->
                <base-input name="From Date" type="date" v-model="filter.from_date"></base-input>
              </b-col>
              <b-col>
                <!-- <b-form-datepicker placeholder="Form Date" v-model="valuePMN" class="text-white mb-2"></b-form-datepicker> -->
                <base-input name="To Date" type="date" v-model="filter.to_date"></base-input>
              </b-col>
              <b-col>
                <b-form-group class="mb-0">
                  <b-input-group class="input-group-alternative input-group-merge">
                    <div class="input-group-prepend">
                      <span class="input-group-text"><i class="icon-document-cs"></i></span>
                    </div>
                    <b-form-input placeholder="Project Name" type="text" v-model="filter.project_name"> </b-form-input>
                  </b-input-group>
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group class="mb-0">
                  <b-input-group class="input-group-alternative input-group-merge">
                    <div class="input-group-prepend">
                      <span class="input-group-text"><i class="icon-map-marker-cs"></i></span>
                    </div>
                    <b-form-input placeholder="Site Name" type="text" v-model="filter.site_name"> </b-form-input>
                  </b-input-group>
                </b-form-group>
              </b-col>
            </b-row>
            <!-- detail filter selection -->
            <b-row>
              <!-- <b-col> -->
              <b-card no-body style="background-color: transparent; width: 100%;">
                <b-tabs pills card vertical>
                  <!-- filter - developer -->
                  <!-- <b-tab title="Developer">
                    <b-card-text class="text-white">
                      <b-row class="mb-3">
                        <b-col>
                          <el-select ref="filter_developers" v-model="filter.developers" clearable multiple value-key filterable :remote-method="getDeveloperList" :loading=false placeholder="Please, make selection" style="width: 100%">
                            <el-option v-for="option in developers" :key="option.developer_id" :label="option.developer_code" :value="option.developer_id"></el-option>
                          </el-select>
                        </b-col>
                      </b-row>
                    </b-card-text>
                  </b-tab> -->
                  <!-- filter - epc / contractor -->
                  <b-tab title="EPC">
                    <b-card-text class="text-white">
                      <b-row class="mb-3">
                        <b-col>
                          <el-select v-model="filter.project_site_contractors" clearable multiple value-key filterable :remote-method="getContractorList" :loading=false reserve-keyword placeholder="Please, make selection" style="width: 100%">
                            <el-option v-for="option in contractors" :key="option.contractor_id" :label="option.contractor_code" :value="option.contractor_id"></el-option>
                          </el-select>
                        </b-col>
                      </b-row>
                    </b-card-text>
                  </b-tab>
                  <!-- filter - safety officer -->
                  <b-tab title="Safety Officer">
                    <b-card-text class="text-white">
                      <b-row class="mb-3">
                        <b-col>
                          <el-select v-model="filter.project_safety" clearable multiple value-key filterable :remote-method="getSafetyOfficerListing" :loading=false reserve-keyword placeholder="Please, make selection" style="width: 100%">
                            <el-option v-for="option in project_safety" :key="option.id" :label="option.name" :value="option.id"></el-option>
                          </el-select>
                        </b-col>
                      </b-row>
                    </b-card-text>
                  </b-tab>
                  <!-- filter - safety inspection submitter -->
                  <b-tab title="Safety Inspection Submitter">
                    <b-card-text class="text-white">
                      <b-row class="mb-3">
                        <b-col>
                          <el-select v-model="filter.safety_inspection_submitter" clearable multiple value-key filterable :remote-method="getSafetyInspectionSubmitterList" :loading=false reserve-keyword placeholder="Please, make selection" style="width: 100%">
                            <el-option v-for="option in safety_inspection_submitters" :key="option.id" :label="option.name" :value="option.id"></el-option>
                          </el-select>
                        </b-col>
                      </b-row>
                    </b-card-text>
                  </b-tab>
                  <!-- filter - safety observation type -->
                  <b-tab title="Safety Observation Type">
                    <b-card-text class="text-white">
                      <b-row class="mb-3">
                        <b-col>
                          <el-select v-model="filter.safety_observation_type" clearable multiple value-key filterable :remote-method="getSafetyObservationTypes" :loading=false reserve-keyword placeholder="Please, make selection" style="width: 100%">
                            <el-option v-for="option in safety_observation_type" :key="option.safety_observation_type_id" :label="option.safety_observation_type_code" :value="option.safety_observation_type_id"></el-option>
                          </el-select>
                        </b-col>
                      </b-row>
                    </b-card-text>
                  </b-tab>
                  <!-- filter - safety observation category -->
                  <b-tab title="Safety Observation Category">
                    <b-card-text class="text-white">
                      <b-row class="mb-3">
                        <b-col>
                          <el-select v-model="filter.safety_observation_category" clearable multiple value-key filterable :remote-method="getSafetyObservationCategories" :loading=false reserve-keyword placeholder="Please, make selection" style="width: 100%">
                            <el-option v-for="option in safety_observation_category" :key="option.safety_observation_category_id" :label="option.safety_observation_category" :value="option.safety_observation_category_id"></el-option>
                          </el-select>
                        </b-col>
                      </b-row>
                    </b-card-text>
                  </b-tab>
                  <!-- filter - safety observation priority -->
                  <b-tab title="Safety Observation Priority">
                    <b-card-text class="text-white">
                      <b-row class="mb-3">
                        <b-col>
                          <el-select v-model="filter.safety_observation_priority" clearable multiple value-key filterable :remote-method="getSafetyObservationPriorities" :loading=false reserve-keyword placeholder="Please, make selection" style="width: 100%">
                            <el-option v-for="option in safety_observation_priority" :key="option.safety_observation_priority_id" :label="option.safety_observation_priority_duration" :value="option.safety_observation_priority_id">
                              {{ option.safety_observation_priority_name }} - {{ option.safety_observation_priority_duration }} Days
                            </el-option>
                          </el-select>
                        </b-col>
                      </b-row>
                    </b-card-text>
                  </b-tab>
                </b-tabs>
              </b-card>
              <!-- </b-col> -->
            </b-row>
            <b-row>
              <b-col class="text-right">
                <!-- <base-button type="success">Search</base-button> -->
                <base-button type="success" @click="clearSelectedFilter()">Clear Filter Selection</base-button>
                <base-button type="success" @click="searchSafetyObservationListingFilter()">Search</base-button>
              </b-col>
            </b-row>
          </div>
        </b-modal>

        <!-- Modal confirmation deletion 1 -->
        <modal :show.sync="isDeleteSafetyObservationModalVisible" size="xl" body-classes="p-0"
          title="Delete Safety Inspection">
          <card type="secondary" header-classes="bg-transparent pb-5" body-classes="px-lg-5 py-lg-5"
            class="border-0 mb-0">
            <template>
              <div class="text-center text-muted mb-4">
                <h1 class="text-danger">Delete Safety Inspection</h1>
              </div>
              <div class="text-center">
                <div style="color: #ffffff;">
                  <br />Are you sure you want to permanently delete this safety inspection?
                </div>
                <div>
                  <base-button type="danger" class="my-4"
                    @click="showDeleteSafetyObservationModalInner">Delete</base-button>
                  <base-button type="primary" class="my-4" @click="closeDeleteSafetyObservationModal">Cancel</base-button>
                </div>
              </div>
            </template>
          </card>
        </modal>

        <!-- Modal confirmation deletion 2 -->
        <modal :show.sync="isDeleteSafetyObservationModalVisibleInner" size="xl" body-classes="p-0"
          title="Confirm Delete Safety Inspection">
          <card type="secondary" header-classes="bg-transparent pb-5" body-classes="px-lg-5 py-lg-5"
            class="border-0 mb-0">
            <template>
              <div class="text-center text-muted mb-4">
                <h1 class="text-danger"><b>Confirm Delete Safety Inspection</b></h1>
              </div>
              <div class="text-center">
                <div style="color: #ffffff;">
                  <br />Are you REALLY sure you want to permanently delete this
                  safety inspection?
                </div>
                <div>
                  <base-button type="primary" class="my-4"
                    @click="closeDeleteSafetyObservationModalInner">Cancel</base-button>
                  <base-button type="danger" class="my-4" @click="deleteSafetyInspection">Delete</base-button>
                </div>
              </div>
            </template>
          </card>
        </modal>

        <!-- Modal loading / in progress -->
        <!--  body-classes="in-progress-modal-lg"  no-close-on-backdrop=false -->
        <modal :show.sync="isLoadingInProgress" ref="modal-loading" size="lg" modal-classes="in-progress-modal-lg" :no-close-on-backdrop="true" body-classes="p-0" >
          <card type="secondary" header-classes="bg-transparent pb-5" body-classes="px-lg-5 py-lg-5" class="border-0 mb-0">
            <template>
              <div class="text-center text-muted mb-4">
                <h1 class="text-white"><b>Loading in progress</b></h1>
              </div>
              <div class="text-center">
                <div style="color: #ffffff;">
                  <!-- <span class="fa fa-spinner fa-spin fa-3x"></span> -->
                  <span class="icon-spinner-cs"></span>
                </div>
              </div>
            </template>
          </card>
        </modal>

      </b-container>     
    </div>


  </div>
</template>

<script>
import { Table, TableColumn, Select, Option } from "element-ui";
import Fuse from "fuse.js";
import axios from "axios";
import { LoginURL, clientId, clientSecret, apiDomain, getHeader, getcurrentDate } from "@/assets/js/config.js";
import ErrorFunctionMixin from "@/assets/js/errorFunctionMix.js";
import Dataset from '@/assets/js/customDataset.js';
import { DatasetItem, DatasetInfo, DatasetPager, DatasetSearch, DatasetShow } from "vue-dataset";
import DropzoneFileUpload from '@/components/Inputs/DropzoneFileUploadObs'
import { ToggleButton } from 'vue-js-toggle-button'
import jsonFormData from 'json-form-data';
// import {cloneDeep, pick } from 'lodash/cloneDeep';
// import cloneDeep from 'lodash/cloneDeep';
import { cloneDeep, pick } from 'lodash';

export default {
  mixins: [ErrorFunctionMixin],
  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    Dataset,
    DatasetItem,
    DatasetInfo,
    DatasetPager,
    DatasetSearch,
    DatasetShow,
    DropzoneFileUpload,
    ToggleButton,
  },
  created() {
    this.setDefaultMonthRange();

    var safety_observation_access = JSON.parse(
      window.localStorage.getItem("safety_observation_access")
    );

    // [UNUSE] - plz check
    this.button_access.safety_observation_delete = safety_observation_access.access_delete;

    const LoginUser = JSON.parse(window.localStorage.getItem("loginUser"));
    this.myUserID = LoginUser;
    const userInfo = JSON.parse(window.localStorage.getItem("loginUser"));
    this.user_role = userInfo.role_id;
    this.user_id = userInfo.id;
    if (this.user_role == 12) {
      this.getContractorId();
    } else {
      this.getUserList();
    }

    var safety_observation_access = JSON.parse(
      window.localStorage.getItem("safety_observation_access")
    );
    this.button_access.new_button = safety_observation_access.access_create;

    // check if user is a project manager
    if (this.myUserID.role_id == 9) {
      // check if user is group leader
      axios
        .get(apiDomain + "setup/project_engineer_group", {
          headers: getHeader(),
        })
        .then((response) => {
          if (response.status === 200) {
            let group_listing_response = response.data.data;
            group_listing_response.forEach((e) => {
              if (e["engineer_group_leader"]["id"] == this.myUserID.id) {
                this.groupId.push(e["engineer_group_id"]);
              }
            });
            if (this.groupId != []) {
              this.groupMembers.push(this.myUserID.id);
              this.groupId.forEach((e_inner) => {
                axios
                  .get(
                    apiDomain +
                    "setup/project_engineer_group.show_by_group/" +
                    e_inner,
                    { headers: getHeader() }
                  )
                  .then((response) => {
                    if (response.status === 200) {
                      let group_member_response = response.data.data;
                      group_member_response.forEach((e_member) => {
                        let checkExist = this.groupMembers.includes(
                          e_member["engineer_group_member"]["id"]
                        );
                        if (!checkExist) {
                          this.groupMembers.push(
                            e_member["engineer_group_member"]["id"]
                          );
                        }
                      });
                    }
                  })
                  .catch((error) => {
                    console.log(error.response.status);
                    this.errorFunction(error, "Project Engineer Group Member");
                  });
              });
            } else {
              this.groupMembers.push(this.myUserID.id);
            }
          }
        })
        .catch((error) => {
          this.errorFunction(error, "Project Engineer Groups Listing");
        });
    }

    // if (this.user_role !== 12 && this.user_role !== null) {
    //   this.getDeveloperList();
    //   this.getContractorList();
    //   this.getSafetyOfficerListing();
    //   this.getSafetyInspectionSubmitterList();

    //   this.getSafetyObservationTypes();
    //   this.getSafetyObservationCategories();
    //   this.getSafetyObservationPriorities();
    //   this.getSafetyObservationStatus();
    // }

    this.getDeveloperList();
    this.getContractorList();
    this.getSafetyOfficerListing();
    this.getSafetyInspectionSubmitterList();

    this.getSafetyObservationTypes();
    this.getSafetyObservationCategories();
    this.getSafetyObservationPriorities();
    this.getSafetyObservationStatus();

    // this.getSafetyObservationListingRange();
    this.searchSafetyObservationListingFilter();

    var currentDate = getcurrentDate();
    this.model_to_db.safety_observation_create_date = currentDate;

    const today = new Date();
    this.model_to_db.safety_observation_create_time = today.getHours() + ":" + today.getMinutes();
  },
  data() {
    return {
      inputs: {
        tags: [],
        fileSingle: [],
        fileMultiple: [],
      },
      user_role: null,
      user_id: null,
      contractor_id: null,
      propsToSearch: [
        // 'project_code',
        // 'project_name',
        // 'project_manager.name',
        // 'project_progress',
        // 'status.status_code',
        "project.project_name",
        "contractor.contractor_code",
        "site_name",
        "safety_observation_create_date_web",
        "created_by.name",
      ],
      tableData: [],
      myUserID: null,

      groupId: [],
      groupMembers: [],
      filter_month_year_data: [
        // { text: 'CSL', value: 'CSL' }
        { text: "N/A", value: "" },
      ],
      filter: {
        developers: [3],
        project_name: null,
        project_site_contractors: [],
        site_name: null,
        project_safety: [],
        safety_inspection_submitter: [],

        from_date: null,
        to_date: null,

        safety_observation_type: [],
        safety_observation_category: [],
        safety_observation_priority: [],

        target_date_from_date: null,
        target_date_to_date: null,
      },
      developers: [],
      contractors: [],
      project_safety: [],
      safety_inspection_submitters: [],
      safety_observation_type: [],
      safety_observation_category: [],
      safety_observation_priority: [],
      safety_observation_status: [],
      isFromDateAfterToDate: false,
      isDeleteSafetyObservationModalVisible: false,
      isDeleteSafetyObservationModalVisibleInner: false,
      isLoadingInProgress: false,
      obj_data_grid: {
        data: [],
        cols: [
          {
            name: "Project Name",
            field: "project.project_name",
          },
          {
            name: "EPC",
            field: "contractor.contractor_code",
          },
          {
            name: "Site Name",
            field: "site_name",
          },
          {
            name: "Inspection Date",
            field: "safety_observation_create_date_web",
          },
          {
            name: "Open",
            field: "open",
          },
          {
            name: "Close",
            field: "close",
          },
          {
            name: "Status",
            field: "status",
          },
          {
            name: "Submitted By",
            field: "created_by.name",
          },
          {},
          // {
          //   name: 'Actions',
          //   field: '',
          // },
        ],
      },
      obj_list: {
        sites_all: [],
        site_info: [],
        site_loading: false,
        site_selected: null,
      },
      filter_site: {
        developers: [3],
        project_name: null,
        project_ppa_sign_date_from_date: null,
        project_ppa_sign_date_to_date: null,
        project_ppa_capacity_greater_equal: null,
        project_ppa_capacity_lesser_equal: null,

        project_type: [],
        project_managers: [],
        project_engineers: [],
        project_business_development: [],
        project_safety: [],

        site_name: null,
        project_site_contractors: [],
        project_site_statuses: [],
        site_postal_code: null,
        site_code: null,
        roof_handover_date_from_date: null,
        roof_handover_date_to_date: null,
        site_cod_date_from_date: null,
        site_cod_date_to_date: null,
        site_target_turn_on_date_from_date: null,
        site_target_turn_on_date_to_date: null,
        site_actual_turn_on_date_from_date: null,
        site_actual_turn_on_date_to_date: null,
        site_total_capacity_greater_equal: null,
        site_total_capacity_lesser_equal: null,
        site_as_built_capacity_greater_equal: null,
        site_as_built_capacity_lesser_equal: null,
        site_module_brand_ids: [],
        site_module_quantity_greater_equal: null,
        site_module_quantity_lesser_equal: null,
        site_module_capacity_greater_equal: null,
        site_module_capacity_lesser_equal: null,
        site_module_allocation_total_capacity_greater_equal: null,
        site_module_allocation_total_capacity_lesser_equal: null,
        site_drawing_module_brand_ids: [],
        site_drawing_module_quantity_greater_equal: null,
        site_drawing_module_quantity_lesser_equal: null,
        site_drawing_module_capacity_greater_equal: null,
        site_drawing_module_capacity_lesser_equal: null,
        site_drawing_total_capacity_greater_equal: null,
        site_drawing_total_capacity_lesser_equal: null,
        site_drawing_ifa_date_from_date: null,
        site_drawing_ifa_date_to_date: null,
        site_drawing_ifc_date_from_date: null,
        site_drawing_ifc_date_to_date: null,
        ntp_applicable: null,
        ntp_issued: null,
        ntp_issue_date_from_date: null,
        ntp_issue_date_to_date: null,
        internal_project_handover_completed: null,
        internal_project_handover_date_from_date: null,
        internal_project_handover_date_to_date: null,
        kick_off_meeting_completed: null,
        kick_off_meeting_date_from_date: null,
        kick_off_meeting_date_to_date: null,
      },
      obj_inspection: {
        is_editing_inspection: false,
        safety_observation_id: null,
        previous_status: null,
        obs_item_fields: [
          // 'no.',
          // {
          //   key: index,
          //   label: 'no.',
          //   class: "th-table-new-mdl "
          // },
          // Type
          {
            key: 'safety_observation_type_id',
            label: 'Type',
            formatter: (value, key, item) => {
              var findObj = this.safety_observation_type.find(obj => { return obj.safety_observation_type_id === value });
              if (typeof findObj !== 'undefined') {
                return findObj.safety_observation_type_code;
              } else {
                return value;
              }
            },
            class: "th-table-new-mdl"
          },
          // Category
          {
            key: 'safety_observation_category_id',
            label: 'Category',
            formatter: (value, key, item) => {
              var findObj = this.safety_observation_category.find(obj => { return obj.safety_observation_category_id === value });
              if (typeof findObj !== 'undefined') {
                return findObj.safety_observation_category;
              } else {
                return value;
              }
            },
            class: "th-table-new-mdl"
          },
          // Status
          {
            key: 'safety_observation_status_id',
            label: 'Status',
            formatter: (value, key, item) => {
              var findObj = this.safety_observation_status.find(obj => { return obj.status_id === value });
              if (typeof findObj !== 'undefined') {
                return findObj.status_code;
              } else {
                return value;
              }
            },
            class: "th-table-new-mdl",
          },
          // Priority
          {
            key: 'safety_observation_priority_id',
            label: 'Priority',
            formatter: (value, key, item) => {
              var findObj = this.safety_observation_priority.find(obj => { return obj.safety_observation_priority_id === value });
              if (typeof findObj !== 'undefined') {
                return findObj.safety_observation_priority_name;
              } else {
                return value;
              }
            },
            class: "th-table-new-mdl",
          },
          // Actions
          { key: 'actions', label: 'Actions', class: "th-table-new-mdl", },
        ],
      },
      obj_obs_main: {
        users: [],
        conducting_users: [],
        contractor_info: null,
        isFutureDate: false,
        inspector_users: [],
        submit_clicked: false,
        isSubmissionWarningVisible: false,
      },      
      obj_obs_item: {
        isNewObservationItemVisible: false,
        safety_observation_item_mode: 'create',
        current_item_index: null,
        max_uploaded_photos: 5,     // maxFiles in src\components\Inputs\DropzoneFileUploadObs.vue also need to be adjust
        signal_reset_file_upload: false,
        showUploadArea: true,
      },
      model_to_db: {
        project_id: 'x',
        group_id: 'x',
        site_id: 'x',
        contractor_id: 'x',
        project_status: 'ongoing',
        safety_observation_create_date: null,
        safety_observation_create_time: null,
        time_spent_hours: 1,
        safety_observation_items: [],
        conducting_users: null,
        status: null,
      },
      new_observation: {
        observation_description: null,
        safety_observation_type_id: "x",
        safety_observation_category_id: "x",
        // correction_photos_required: "x",
        correction_photos_required: true,
        safety_observation_priority_id: "x",
        safety_observation_photos: [],
        safety_observation_status_id: 1,
        // repeated_observation: 0,
        repeated_observation: false,
      },

      //_ below might not be use, need to be check before deleting it
      valuePMN: '',
      tableColumns: [],
      obs_items: [
        {
          id: 1,
          type: "Good Practice",
          category: "12 Life Save Rules",
          status: "On Going",
          proruty: "30 Days"
        }
      ],
      items: [
        {
          id: 0,
          project_name: "PLTS IKN",
          epc: "EHP",
          site_name: "Site 01",
          date: "19-July-2024",
          submitted_by: "Robaan",
        },
        {
          id: 1,
          project_name: "PLTS IKN",
          epc: "EHP",
          site_name: "Site 01",
          date: "19-July-2024",
          submitted_by: "Robaan",
        }
      ],
      filterOption: [
        {
          label: 5,
          value: 5
        },
        {
          label: 10,
          value: 10
        },
        {
          label: 25,
          value: 25
        },
        {
          label: 50,
          value: 50
        },
      ],
      // submittedTableData: [],
      // draftTableData: [],
      selectedRows: [],
      button_access: {
        new_button: null,
        project_view: null,
        milestone_listing: null,
        safety_observation_delete: null,
      },
      showFilters: false,
    };
  },
  methods: {
    rowClass(item, type) {
      return "grid-tr-new";
    },
    /* [method] add new safety observation | inspection */
    getSiteByName(siteName) {
      //_ get site_id by site_name that type into the dropdown selection

      this.filter_site.site_name = siteName;
      axios.post(apiDomain + "project/project_site.show_by_filter", this.filter_site, {
        headers: getHeader(),
      })
        .then(response => {
          if (response.status === 200) {
            this.obj_list.sites_all = response.data.data;
          }
        })
        .catch(error => {
          this.errorFunction(error, "Search Project Site Listing")
        });
    },
    getContractorFromSite(site_id) {
      //_ on selected site, it passes site_id to get the site info, the needed data: group, project, epc
      if ( site_id != '' ) {
        axios.get(apiDomain + 'project/site_contractor.show_by_site/' + site_id + '?client=mobile', { headers: getHeader() })
        .then(response => {
          if (response.status === 200) {
            this.model_to_db.project_id = response.data.data[0].project.project_id;
            this.model_to_db.group_id = response.data.data[0].group.group_id;
            this.model_to_db.site_id = response.data.data[0].site.site_id;
            this.model_to_db.contractor_id = response.data.data[0].contractor.contractor_id;
            this.obj_obs_main.contractor_info = response.data.data[0].contractor.contractor_code + ' - ' + response.data.data[0].contractor.contractor_name;
          }
        })
        .catch(error => {
          if (typeof error.response !== "undefined") {
            this.errorFunction(error, "Site Contractor Information")
          }
        });
      } else {
        this.obj_obs_main.contractor_info = null;
      }
    },
    action_on_modal(what_action, what_modal, isReset = true) {
      // this.isLoadingInProgress = true;

      if ( isReset ) this.reset_obj_inspection();

      if (what_action == 'show') {
        this.$refs[what_modal].show()
      } else {
        this.$refs[what_modal].hide();
      };
    },
    showNewObservationItemFromAdd() {
      this.obj_obs_item.safety_observation_item_mode = 'create';
      this.reset_obj_observation_item();
      this.obj_obs_item.isNewObservationItemVisible = true;
    },
    closeNewObservationItem() {
      this.reset_obj_observation_item();
      this.obj_obs_item.isNewObservationItemVisible = false;
    },
    showNewObservationItem() {
      this.obj_obs_item.isNewObservationItemVisible = true;
      // this.action_on_sub_modal('show', 'modal-inspection-item', 'modal-inspection-main');
    },
    saveNewObservationItem() {
      // console.log('func: saveNewObservaionItem');
      if (
        !this.new_observation.observation_description ||
        this.new_observation.safety_observation_type_id == "x" ||
        this.new_observation.safety_observation_category_id == "x" ||
        // this.new_observation.correction_photos_required == true ||
        this.new_observation.safety_observation_priority_id == "x"
      ) {
        if (!this.new_observation.observation_description) {
          this.redErrorNotify(
            "<b>Invalid Input : Observation Remarks is a Mandatory Field in Safety Observation </b>"
          );
        } else if (this.new_observation.safety_observation_type_id == "x") {
          this.redErrorNotify(
            "<b>Invalid Input : Safety Observation Type is a Mandatory Field in Safety Observation </b>"
          );
        } else if (this.new_observation.safety_observation_category_id == "x") {
          this.redErrorNotify(
            "<b>Invalid Input : Safety Observation Category is a Mandatory Field in Safety Observation </b>"
          );
        // } else if (this.new_observation.correction_photos_required == true) {
        //   this.redErrorNotify(
        //     "<b>Invalid Input : Need Correction Photos is a Mandatory Field in Safety Observation </b>"
        //   );
        } else if (this.new_observation.safety_observation_priority_id == "x") {
          this.redErrorNotify(
            "<b>Invalid Input : Safety Observation Priority is a Mandatory Field in Safety Observation </b>"
          );
        } else {
          this.redErrorNotify(
            "<b>Invalid Input : Please fill in all the Mandatory Fields in Safety Observation </b>"
          );
        }
      } else {
        // let tmpObsItem = this.new_observation;
 
        this.handleImageFileUpload();

        if (this.obj_obs_item.safety_observation_item_mode == 'create') {
          // console.log('-create');
          // console.log(this.new_observation);
          // console.log('   before: ');
          // console.log(this.model_to_db.safety_observation_items);

          this.model_to_db.safety_observation_items.push(this.new_observation);
          // console.log('   after: ');
          // console.log(this.model_to_db.safety_observation_items);
        } else if (this.obj_obs_item.safety_observation_item_mode == 'edit') {
          // console.log('-edit');
          // console.log(this.new_observation);
          // console.log('   before: ');
          // console.log(this.model_to_db.safety_observation_items);

          this.model_to_db.safety_observation_items[this.obj_obs_item.current_item_index] = this.new_observation;
          this.obj_obs_item.current_item_index = null;
          // console.log('   after: ');
          // console.log(this.model_to_db.safety_observation_items);
        }

        // // console.log('reset');
        this.reset_obj_observation_item();
        this.obj_obs_item.safety_observation_item_mode = 'create';
        this.obj_obs_item.isNewObservationItemVisible = false;
      }
    },
    handleImageFileUpload_v1() {
      // console.log(DropzoneFileUpload.files);
      // console.log('handleImageFileUpload');

      if ( this.inputs.fileMultiple.length > 0 ) {
        this.inputs.fileMultiple.forEach( (item, index) => {
          //_ why like this, cause the dropZone maxFiles setting when using multiple, not rejected the upload process, it only marking the 'accepted' into false if it already exceeded the maxFiles setting
          if (item.accepted) {
            //_ max only xx images allowed (based on setting obj_obs_item.max_uploaded_photos )
            if (this.new_observation.safety_observation_photos.length < this.obj_obs_item.max_uploaded_photos ) {
              this.new_observation.safety_observation_photos.push({
                photoFile: item,
                photoPreviewSrc: item.dataURL,
                photoRemarks: "",
                },
              );
            }
          }
        });
 
      }

      this.inputs.fileMultiple = [];

      this.showUploadArea = false;
    },
    handleImageFileUpload() {
      if ( this.inputs.fileMultiple.length > 0 ) {
        // console.log('new image uploaded ?')
        // console.log(this.inputs.fileMultiple);

        this.inputs.fileMultiple.forEach( (item, index) => {
          //_ why like this, cause the dropZone maxFiles setting when using multiple, not rejected the upload process, it only marking the 'accepted' into false if it already exceeded the maxFiles setting
          if (item.accepted) {

            //_ max only xx images allowed (based on setting obj_obs_item.max_uploaded_photos )
            if (this.new_observation.safety_observation_photos.length < this.obj_obs_item.max_uploaded_photos ) {
              this.new_observation.safety_observation_photos.push({
                photoFile: item,
                photoPreviewSrc: item.dataURL,
                photoRemarks: "",
                },
              );
            }
          }
        });

        // this.obj_obs_item.signal_reset_file_upload = true;
      }
      this.inputs.fileMultiple = [];

      this.obj_obs_item.showUploadArea = false;
      setInterval(() => {
        this.obj_obs_item.showUploadArea = true;
      }, 500);
      
    },
    deletePhoto(index) {
      this.new_observation.safety_observation_photos.splice(index, 1);
    },
    reset_obj_inspection() {
      this.model_to_db = {
        project_id: 'x',
        group_id: 'x',
        site_id: 'x',
        contractor_id: 'x',
        project_status: 'ongoing',
        safety_observation_create_date: null,
        safety_observation_create_time: null,
        time_spent_hours: 1,
        safety_observation_items: [],
        conducting_users: null,
        status: null,
      };

      this.obj_list.site_selected = null;
      this.obj_obs_main.contractor_info = null;
      
      if (this.obj_obs_main.conducting_users.length > 1) {
        this.obj_obs_main.conducting_users.splice(1, this.obj_obs_main.conducting_users.length);
      }
    },
    reset_obj_observation_item() {
      this.inputs.fileMultiple = [];

      this.new_observation = {
        observation_description: null,
        safety_observation_type_id: "x",
        safety_observation_category_id: "x",
        // correction_photos_required: "x",
        correction_photos_required: true,
        safety_observation_priority_id: "x",
        safety_observation_photos: [],
        safety_observation_status_id: 1,
        // repeated_observation: 0,
        repeated_observation: false,
      };
    },
    updateObservationCondition() {
      // IF POSITIVE OR GOOD PRACTICE, DO NOT REQUIRE CORRECTION PHOTOS, SET PRIORITY TO NULL
      if (this.new_observation.safety_observation_type_id == 1 || this.new_observation.safety_observation_type_id == 3) {
        // this.new_observation.correction_photos_required = 0;
        this.new_observation.correction_photos_required = false;
        this.new_observation.safety_observation_priority_id = null;
      } else {
        this.new_observation.safety_observation_priority_id = "x";
      }
    },
    viewObservationItem(item, index, button) {
      this.new_observation = cloneDeep(pick(this.model_to_db.safety_observation_items[index], ['observation_description', 'safety_observation_type_id', 'safety_observation_category_id', 'correction_photos_required', 'safety_observation_priority_id', 'safety_observation_photos', 'safety_observation_status_id', 'repeated_observation']));
     
      this.obj_obs_item.safety_observation_item_mode = 'edit';
      this.obj_obs_item.current_item_index = index;
      this.showNewObservationItem();
    },
    deleteObservationItem(item, index, button) {
      this.model_to_db.safety_observation_items.splice(index, 1);
    },
    addField(object, fieldType) {
      let newObject = {};
      for (const [key, value] of Object.entries(object)) {
        newObject[key] = "";
      }
      fieldType.push(newObject);
    },
    removeField(index, fieldType) {
      if (fieldType[index].userId != "") {
        this.obj_obs_main.inspector_users.map((e_rol) => {
          if (e_rol.id == fieldType[index].userId) {
            e_rol["disabled"] = false;
            e_rol["v_hide"] = false;
          }
        });
      }
      fieldType.splice(index, 1);
    },
    addUserToConducting() {
      // console.log('addUserToConducting');
      this.obj_obs_main.conducting_users.push({ userId: this.user_id, previousSelection: this.user_id });
      var tempUserId = this.user_id;
      tempUserId = tempUserId.toString();
      var tempEvent = {
        target: {
          value: tempUserId
        }
      };

      this.removeFromList(tempEvent);
    },
    getUserList() {
      if (this.user_role == 12) {
        if (this.contractor_id != null) {
          this.getUserListContractor();
        }
      } else {
        this.getUserListNormal();
      }
    },
    getUserListNormal() {
      axios.get(apiDomain + 'lookup/user_list', { headers: getHeader() })
        .then(response => {
          if (response.status === 200) {
            this.obj_obs_main.inspector_users = response.data.data;
            this.addUserToConducting();
          }
        })
        .catch(error => {
          this.errorFunction(error, 'User Listing')
        });
    },
    getUserListContractor() {
      axios.get(apiDomain + 'lookup/user_list.contractor/' + this.contractor_id, { headers: getHeader() })
        .then(response => {
          if (response.status === 200) {
            this.obj_obs_main.inspector_users = response.data.data;
            this.addUserToConducting();
          }
        })
        .catch(error => {
          this.errorFunction(error, 'User Listing')
        });
    },
    removeFromList(event) {
      this.obj_obs_main.conducting_users.map((e_rev) => {
        if (e_rev.userId == event.target.value) {
          if (e_rev.previousSelection != "") {
            this.obj_obs_main.inspector_users.map((e_rol) => {
              if (e_rol.id == e_rev.previousSelection) {
                e_rol["disabled"] = false;
                e_rol["v_hide"] = false;
              }
            });
          }
        }
        this.obj_obs_main.inspector_users.map((e_rol) => {
          if (e_rol.id == event.target.value) {
            e_rol["disabled"] = true;
            e_rol["v_hide"] = true;
          }
        });
        e_rev.previousSelection = e_rev.userId;
      });
    },
    checkAndCreateConductingUsers() {
      // console.log('checkAndCreateConductingUsers');
      // console.log(this.obj_obs_main.conducting_users);

      let tempConductingUsers = [];
      this.obj_obs_main.conducting_users.forEach((item) => {
        if (item.userId != "") {
          tempConductingUsers.push({ userId: item.userId });
        }
      });
      this.model_to_db.conducting_users = tempConductingUsers;

      // console.log(this.obj_obs_main.conducting_users);
    },
    inspectionValidateDateTime() {
      // console.log('inspectionValidateDateTime');
      const currentDate = new Date();
      const enteredDate = new Date(`${this.model_to_db.safety_observation_create_date}T${this.model_to_db.safety_observation_create_time}`);

      this.obj_obs_main.isFutureDate = enteredDate > currentDate;
    },
    checkForRequiredData() {
      this.checkAndCreateConductingUsers();
      this.inspectionValidateDateTime();
      var checker = false;
      if (
        this.model_to_db.project_id == 'x' ||
        this.model_to_db.group_id == 'x' ||
        this.model_to_db.site_id == 'x' ||
        this.model_to_db.contractor_id == 'x' ||
        !this.model_to_db.project_status ||
        !this.model_to_db.safety_observation_create_date ||
        !this.model_to_db.safety_observation_create_time ||
        this.model_to_db.safety_observation_items.length < 1 ||
        this.obj_obs_main.isFutureDate
      ) {
        if (this.model_to_db.project_id == 'x') {
          this.redErrorNotify(
            "<b>Invalid Input : Project is a Mandatory Field in Safety Observation </b>"
          );
        } else if (this.model_to_db.group_id == 'x') {
          this.redErrorNotify(
            "<b>Invalid Input : Project Group is a Mandatory Field in Safety Observation </b>"
          );
        } else if (this.model_to_db.site_id == 'x') {
          this.redErrorNotify(
            "<b>Invalid Input : Project Site is a Mandatory Field in Safety Observation </b>"
          );
        } else if (this.model_to_db.contractor_id == 'x') {
          this.redErrorNotify(
            "<b>Invalid Input : EPC (Project Site Contractor) is a Mandatory Field in Safety Observation </b>"
          );
        } else if (!this.model_to_db.project_status) {
          this.redErrorNotify(
            "<b>Invalid Input : Project Status is a Mandatory Field in Safety Observation </b>"
          );
        } else if (!this.model_to_db.safety_observation_create_date) {
          this.redErrorNotify(
            "<b>Invalid Input : Date is a Mandatory Field in Safety Observation </b>"
          );
        } else if (!this.model_to_db.safety_observation_create_time) {
          this.redErrorNotify(
            "<b>Invalid Input : Time is a Mandatory Field in Safety Observation </b>"
          );
        } else if (this.obj_obs_main.isFutureDate) {
          this.redErrorNotify(
            "<b>Invalid Input : Date and Time cannot be in the future. </b>"
          );
        } else if (this.model_to_db.safety_observation_items.length < 1) {
          this.redErrorNotify(
            "<b>Invalid Input : Safety Observation Item is a Mandatory Field in Safety Observation </b>"
          );
        } else {
          this.redErrorNotify(
            "<b>Invalid Input : Please fill in all the Mandatory Fields in Safety Observation </b>"
          );
        }
      } else {
        console.log('passed all checks');
        checker = true;
        this.obj_obs_main.submit_clicked = true;
        this.obj_obs_main.isSubmissionWarningVisible = true;
      }
      return checker;
    },
    
    /* [method] edit safety observation | inspection */
    getSafetyObservationListingById( safety_observation_id ) {
      // console.log('func: getSafetyObservationListingById');
      // console.log('safety_observation_id: ' + safety_observation_id);

      axios.get(apiDomain + 'health_safety/safety_observation/' + safety_observation_id, { headers: getHeader() })
        .then(response => {
          if (response.status === 200) {
            // console.log(response);

            let safetyObservationResponse = response.data.data;
            this.model_to_db.project_id = response.data.data[0].project_id;
            this.model_to_db.group_id = response.data.data[0].group_id;
            this.model_to_db.site_id = response.data.data[0].site_id;
            this.model_to_db.contractor_id = response.data.data[0].contractor.contractor_id;
            this.model_to_db.project_status = response.data.data[0].project_status;
            this.model_to_db.safety_observation_create_date = this.convertDateFormat(response.data.data[0].safety_observation_create_date_web);
            this.model_to_db.safety_observation_create_time = response.data.data[0].safety_observation_create_time;
            this.model_to_db.time_spent_hours = response.data.data[0].time_spent_hours;
            this.populateConductingUsers(response.data.data[0].conducting_users);
            // console.log('after: populateConductingUsers');
            this.model_to_db.safety_observation_items = response.data.data[0].safety_observation_items;
       
            this.model_to_db.safety_observation_items.forEach( (item, index) => {
              ( item.correction_photos_required == 0 ) ? item.correction_photos_required = false : item.correction_photos_required = true;
              ( item.repeated_observation == 0 ) ? item.repeated_observation = false : item.repeated_observation = true;
            })

            this.loadAllSafetyObservationPhotos();

            this.model_to_db.status = response.data.data[0].status;

            this.obj_inspection.safety_observation_id = safety_observation_id;
            this.is_editing_inspection = true;
            this.obj_list.site_selected = response.data.data[0].site_name;
            this.obj_obs_main.contractor_info = response.data.data[0].contractor.contractor_code + ' - ' + response.data.data[0].contractor.contractor_name;
            this.action_on_modal('show', 'modal-inspection-main', false);
          }
        })
        .catch(error => {
          // console.log(error.response.status);
          console.log(error.response);
          this.errorFunction(error, "Safety Observation Information");
        });
    },
    populateConductingUsers(conducting_users_array) {
      // console.log('func: populateConductingUsers');

      // safety_observation_conducting_user SORT THIS ARRAY BY safety_observation_conducting_user_id
      this.obj_obs_main.conducting_users = [];
      conducting_users_array.forEach((item) => {
        this.obj_obs_main.conducting_users.push({ userId: item.safety_observation_conducting_user.id });
        this.obj_obs_main.users.map((e_rol) => {
          if (e_rol.id == item.safety_observation_conducting_user.id) {
            e_rol["disabled"] = true;
            e_rol["v_hide"] = true;
          }
        });
      });
    },
    loadAllSafetyObservationPhotos() {
      this.model_to_db.safety_observation_items.forEach((e) => {
        e['safety_observation_photos'].forEach((el) => {
          if (el['image']['url']) {
            axios.get(apiDomain + el['image']['url'], { headers: getHeader() })
              .then(response => {
                if (response.status === 200) {
                  if (response.data.data.image && response.data.data.image) {
                    el['imageDataSrc'] = `data:${response.data.data.image};base64,${response.data.data.image}`;
                  } else {
                    el['imageDataSrc'] = null;
                  }
                }
              })
              .catch(error => {
                console.log(error.response.status)
              });
          }
        });
      });
    },
    convertDateFormat(inputDateString) {
      // inputDateString format e.g. "25-May-2023"

      // Helper function to map month name to month number
      const getMonthNumber = (monthName) => {
        const monthNames = [
          "Jan", "Feb", "Mar", "Apr", "May", "Jun",
          "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
        ];
        return monthNames.indexOf(monthName);
      };

      // Split the date string by the hyphen (-) to extract the day, month, and year
      const parts = inputDateString.split("-");
      const day = parseInt(parts[0], 10);
      const monthName = parts[1];
      const year = parseInt(parts[2], 10);

      const month = getMonthNumber(monthName);

      // Create a new Date object by specifying the year, month (zero-based), and day
      const dateObject = new Date(year, month, day);

      const newDay = ("0" + dateObject.getDate()).slice(-2);
      const newMonth = ("0" + (dateObject.getMonth() + 1)).slice(-2);
      const newYear = dateObject.getFullYear();

      // Format the date to the desired format "YYYY-MM-DD"
      const formattedDate = newYear + '-' + newMonth + '-' + newDay;

      return formattedDate;
    },

    /* [method] save draft and submit, use by add new and update */
    saveAsDraft() {
      if (this.checkForRequiredData()) {
        // console.log('save as draft');
        // console.log(this.model_to_db);

        //_ convert boolean to 0 | 1, adjusting to backend API
        this.model_to_db.safety_observation_items.forEach( (item, index) => {
          ( item.correction_photos_required == false ) ? item.correction_photos_required = 0 : item.correction_photos_required = 1;
          ( item.repeated_observation == false ) ? item.repeated_observation = 0 : item.repeated_observation = 1;
        })
        
        const tokenData = JSON.parse(window.localStorage.getItem("authUser"));
        const headers = { Authorization: "Bearer " + tokenData.access_token, };

        // this.isLoadingInProgress = true;
        if ( this.is_editing_inspection ) {
          this.model_to_db.safety_observation_id = this.obj_inspection.safety_observation_id;
          this.obj_inspection.previous_status = this.model_to_db.status;
          this.model_to_db.status = 0;

          let formData = jsonFormData(this.model_to_db);
          formData.append("_method", "put");

          axios.post(apiDomain + 'health_safety/safety_observation/' + this.obj_inspection.safety_observation_id, formData, { headers })
            .then((response) => {
              if (response.status === 200) {
                this.$notify({
                  message:
                    '<b>Safety Observation Successfully Updated and Saved As Draft.</b>',
                  timeout: 10000,
                  icon: 'ni ni-bell-55',
                  type: 'default',
                });
                // this.$router.back()
                this.is_editing_inspection = false;
                this.action_on_modal('hide', 'modal-inspection-main');
              }
            })
            .catch((error) => {
              if (error.response) {
                // Handle error with response
                this.errorFunction(error, "Update Safety Observation Draft");
              } else {
                // Handle error without response
                // console.log(error)
                this.$notify({
                  message:
                    // '<b>System Warning : </b> - The System is Currently Busy or Un-Available.',
                    '<b>System Warning : </b> - There is an error with your submission. Please contact Sembcorp staff you know or email to sembsolar.momens@sembcorp.com',
                  timeout: 15000,
                  icon: 'ni ni-bulb-61',
                  type: 'warning',
                });

                this.model_to_db.status = this.obj_inspection.previous_status;
              }
            })
            .finally (() => {
              this.isLoadingInProgress = false;
              this.obj_obs_main.submit_clicked = false;
              this.obj_obs_main.isSubmissionWarningVisible = false;
              this.searchSafetyObservationListingFilter();
            });

        } else {
          // console.log ('add new');
          this.model_to_db.status = 0;
          let formData = jsonFormData(this.model_to_db);

          axios
            .post( apiDomain + "health_safety/safety_observation", formData, { headers } )
            .then((response) => {
              if (response.status === 200) {
                this.$notify({
                  message:
                    '<b>Safety Observation Successfully Created and Saved As Draft.</b>',
                  timeout: 10000,
                  icon: 'ni ni-bell-55',
                  type: 'default',
                });
                // this.$router.back()
                this.action_on_modal('hide', 'modal-inspection-main');
              }
            })
            .catch((error) => {
              if (error.response) {
                // Handle error with response
                this.errorFunction(error, "Safety Observation Save As Draft");
              } else {
                // Handle error without response
                // console.log(error)
                this.$notify({
                  message:
                    // '<b>System Warning : </b> - The System is Currently Busy or Un-Available.',
                    '<b>System Warning : </b> - There is an error with your submission. Please contact Sembcorp staff you know or email to sembsolar.momens@sembcorp.com',
                  timeout: 15000,
                  icon: 'ni ni-bulb-61',
                  type: 'warning',
                });
              }
            })
            .finally (() => {
              this.isLoadingInProgress = false;
              this.obj_obs_main.submit_clicked = false;
              this.obj_obs_main.isSubmissionWarningVisible = false;
              this.is_editing_inspection = false;

              this.searchSafetyObservationListingFilter();
            });
        }         
      }
    },
    submitData() {
      if (this.checkForRequiredData()) {
        // console.log('submit data');

        //_ convert boolean to 0 | 1, adjusting to backend API
        this.model_to_db.safety_observation_items.forEach( (item, index) => {
          ( item.correction_photos_required == false ) ? item.correction_photos_required = 0 : item.correction_photos_required = 1;
          ( item.repeated_observation == false ) ? item.repeated_observation = 0 : item.repeated_observation = 1;
        });

        const tokenData = JSON.parse(window.localStorage.getItem("authUser"));
        const headers = {
          Authorization: "Bearer " + tokenData.access_token,
        };

        //_ flag to know if it's new entry or submit from draft
        if ( this.is_editing_inspection ) {
          // console.log ('submit with id');

          this.model_to_db.safety_observation_id = this.obj_inspection.safety_observation_id;
          this.obj_inspection.previous_status = this.model_to_db.status;
          this.model_to_db.status = 1;

          let formData = jsonFormData(this.model_to_db);
          formData.append("_method", "put");

          axios.post(apiDomain + 'health_safety/safety_observation/' + this.obj_inspection.safety_observation_id, formData, { headers })
            .then((response) => {
              if (response.status === 200) {
                this.$notify({
                  message:
                    '<b>Safety Observation Successfully Created and Submitted.</b>',
                  timeout: 10000,
                  icon: 'ni ni-bell-55',
                  type: 'default',
                });
                // this.$router.back()
                this.is_editing_inspection = false;
                this.action_on_modal('hide', 'modal-inspection-main');
              }
            })
            .catch((error) => {
              if (error.response) {
                // Handle error with response
                this.errorFunction(error, "Safety Observation Submit");
              } else {
                // Handle error without response
                // console.log(error)
                this.$notify({
                  message:
                    // '<b>System Warning : </b> - The System is Currently Busy or Un-Available.',
                    '<b>System Warning : </b> - There is an error with your submission. Please contact Sembcorp staff you know or email to sembsolar.momens@sembcorp.com',
                  timeout: 15000,
                  icon: 'ni ni-bulb-61',
                  type: 'warning',
                });
              }
              // this.submit_clicked = false;
              // this.isSubmissionWarningVisible = false;
              this.model_to_db.status = this.obj_inspection.previous_status;
            })
            .finally (() => {
              this.isLoadingInProgress = false;
              this.obj_obs_main.submit_clicked = false;
              this.obj_obs_main.isSubmissionWarningVisible = false;
              this.is_editing_inspection = false;

              this.searchSafetyObservationListingFilter();
            });  
        } else {
          // console.log ('submit new');

          this.model_to_db.status = 1;

          let formData = jsonFormData(this.model_to_db);

          axios
            .post(
              apiDomain + "health_safety/safety_observation",
              formData,
              { headers }
            )
            .then((response) => {
              if (response.status === 200) {
                this.$notify({
                  message:
                    '<b>Safety Observation Successfully Created and Submitted.</b>',
                  timeout: 10000,
                  icon: 'ni ni-bell-55',
                  type: 'default',
                });
                // this.$router.back()
                this.is_editing_inspection = false;
                this.action_on_modal('hide', 'modal-inspection-main');
              }
            })
            .catch((error) => {
              if (error.response) {
                // Handle error with response
                this.errorFunction(error, "Safety Observation Submit");
              } else {
                // Handle error without response
                console.log(error)
                this.$notify({
                  message:
                    // '<b>System Warning : </b> - The System is Currently Busy or Un-Available.',
                    '<b>System Warning : </b> - There is an error with your submission. Please contact Sembcorp staff you know or email to sembsolar.momens@sembcorp.com',
                  timeout: 15000,
                  icon: 'ni ni-bulb-61',
                  type: 'warning',
                });
              }
              // this.submit_clicked = false;
              // this.isSubmissionWarningVisible = false;
            })
            .finally (() => {
              this.isLoadingInProgress = false;
              this.obj_obs_main.submit_clicked = false;
              this.obj_obs_main.isSubmissionWarningVisible = false;
              this.is_editing_inspection = false;

              this.searchSafetyObservationListingFilter();
            });
        }
      }
    },

    /* [method] index */
    clearSelectedFilter() {
      this.filter.project_name = null;
      this.filter.site_name= null,
      // this.filter.developers = [3];
      this.filter.project_site_contractors = [];
      this.filter.project_safety = [];
      this.filter.safety_inspection_submitter = [];
      this.filter.safety_observation_type = [];
      this.filter.safety_observation_category = [];
      this.filter.safety_observation_priority = [];
    },    
    gridSearchProjectName: function (value, searchString, rowData) {
      return rowData.project.project_name;
    },
    getDeveloperList() {
      axios
        .get(apiDomain + "setup/developer", { headers: getHeader() })
        .then((response) => {
          if (response.status === 200) {
            this.developers = response.data.data;
          }
        })
        .catch((error) => {
          console.log(error.response.status);
          this.errorFunction(error, "Developer Listing");
        });
    },
    getContractorList() {
      axios
        .get(apiDomain + "setup/contractor", { headers: getHeader() })
        .then((response) => {
          if (response.status === 200) {
            this.contractors = response.data.data;
          }
        })
        .catch((error) => {
          console.log(error.response.status);
          this.errorFunction(error, "Contractor Listing");
        });
    },
    getSafetyOfficerListing() {
      axios
        .get(apiDomain + "lookup/user_list/Safety", { headers: getHeader() })
        .then((response) => {
          if (response.status === 200) {
            this.project_safety = response.data.data;
          }
        })
        .catch((error) => {
          console.log(error.response.status);
          this.errorFunction(error, "Safety Officer Information");
        });
    },
    getSafetyInspectionSubmitterList() {
      axios
        .get(apiDomain + "lookup/user_list.safety_observation_creator", {
          headers: getHeader(),
        })
        .then((response) => {
          if (response.status === 200) {
            this.safety_inspection_submitters = response.data.data;
          }
        })
        .catch((error) => {
          console.log(error.response.status);
          this.errorFunction(error, "Safety Inspection Submitter Information");
        });
    },
    getSafetyObservationTypes() {
      axios
        .get(apiDomain + "lookup/safety_observation_type", {
          headers: getHeader(),
        })
        .then((response) => {
          if (response.status === 200) {
            this.safety_observation_type = response.data.data;
          }
        })
        .catch((error) => {
          console.log(error.response.status);
          this.errorFunction(error, "Safety Observation Type Information");
        });
    },
    getSafetyObservationCategories() {
      axios
        .get(apiDomain + "setup/safety_observation_category_active", {
          headers: getHeader(),
        })
        .then((response) => {
          if (response.status === 200) {
            console.log(response.data.data)
            
            let tmpWord = 'UAM - '

            var filteredArray = response.data.data.filter(function (el) {
              return el.safety_observation_category.substring(0, tmpWord.length) == tmpWord
            });

            //_ now removing the prefix word, but even though we do this for input, later on when edit, it will display as is in the database
            //  so for now, no need to remove the prefix word
            // filteredArray.forEach(function(d,i){
            //   Object.keys(d).forEach(function(dd,ii){
            //       if (dd == 'safety_observation_category' ) {
            //         console.log(d[dd] + '>' + d[dd].length + ' = ' + (d[dd].length - tmpWord.length))
            //         d[dd] = d[dd].substring(tmpWord.length, d[dd].length)
            //       }
            //   })
            // })

            this.safety_observation_category = filteredArray;
          }
        })
        .catch((error) => {
          console.log(error.response.status);
          this.errorFunction(error, "Safety Observation Category");
        });
    },
    getSafetyObservationPriorities() {
      axios
        .get(apiDomain + "setup/safety_observation_priority_active", {
          headers: getHeader(),
        })
        .then((response) => {
          if (response.status === 200) {
            this.safety_observation_priority = response.data.data;
          }
        })
        .catch((error) => {
          console.log(error.response.status);
          this.errorFunction(error, "Safety Observation Priority");
        });
    },
    getSafetyObservationStatus() {
      axios
      .get(apiDomain + "lookup/safety_observation_status", { headers: getHeader() })
      .then((response) => {
        if (response.status === 200) {
          this.safety_observation_status = response.data.data;
        }
      })
      .catch((error) => {
        console.log(error.response.status);
        this.errorFunction(error, "Safety Observation Status");
      });
    },
    getOpenItems(items) {
      var newRow = items.filter(function (e) {
        if (e.safety_observation_status_id != 3) {
          return e;
        }
      });
      return newRow.length;
    },
    getClosedItems(items) {
      var newRow = items.filter(function (e) {
        if (e.safety_observation_status_id == 3) {
          return e;
        }
      });
      return newRow.length;
    },
    setDefaultMonthRange() {
      var currentDate = getcurrentDate();
      var localCurrentDate = new Date(currentDate);

      // get current date in yyyy-mm-dd format
      const currentYear = localCurrentDate.getFullYear();
      const currentMonth = String(localCurrentDate.getMonth() + 1).padStart(2, "0");
      const currentDay = String(localCurrentDate.getDate()).padStart(2, "0");
      this.filter.to_date = currentYear + "-" + currentMonth + "-" + currentDay;

      // get first day of last month in yyyy-mm-dd format
      var fromDate = new Date(localCurrentDate.setMonth(localCurrentDate.getMonth()));
      fromDate = new Date(fromDate.setDate(1));
      const fromYear = fromDate.getFullYear();
      const fromMonth = String(fromDate.getMonth() + 1).padStart(2, "0");
      const fromDay = String(fromDate.getDate()).padStart(2, "0");
      this.filter.from_date = fromYear + "-" + fromMonth + "-" + fromDay;
    },
    validateDateTime() {
      if (this.filter.from_date == "" || this.filter.to_date == "") {
        return false;
      } else {
        const fromDate = new Date(this.filter.from_date);
        const toDate = new Date(this.filter.to_date);
        this.isFromDateAfterToDate = fromDate > toDate;
        return true;
      }
    },
    searchSafetyObservationListingFilter() {
      // console.log("func: searchSafetyObservationListingFilter");
      // console.log(this.filter)

      // this.filter.from_date = "2024-08-26";
      // this.filter.to_date = "2024-08-26";

      this.isLoadingInProgress = true;

      this.tempButtonStatus = true;
      if (this.user_role == 12) {
        this.getSafetyObservationListingRange();
        // this.searchSafetyObservationListingFilter();
      } else {
        axios
          .post(apiDomain + "health_safety/safety_observation.show_by_filter", this.filter, { headers: getHeader(), } )
          .then((response) => {
            if (response.status === 200) {
              this.tableData = response.data.data;
              this.populateDSSafety();
              this.computeOpenClosedItems();
              this.fuseData();
            }
          })
          .catch((error) => {
            this.errorFunction(error, "Search Safety Inspection Listing");
          })
          .finally(() => { 
            this.isLoadingInProgress = false;
          });
      }
    },
    getSafetyObservationListingRange() {
      // console.log("func: getSafetyObservationListingRange");

      if (this.validateDateTime()) {
        if (!this.isFromDateAfterToDate) {
          
          if (this.user_role == 12) {
            if (this.contractor_id != null) {
              axios
                .get( apiDomain + "health_safety/safety_observation.show_by_contractor/" + this.contractor_id + "?from_date=" + this.filter.from_date + "&to_date=" + this.filter.to_date, { headers: getHeader() } )
                .then((response) => {
                  if (response.status === 200) {
                    this.tableData = response.data.data;
                    this.populateDSSafety();
                    this.computeOpenClosedItems();
                    this.fuseData();
                  }
                })
                .catch((error) => {
                  console.log(error.response.status);
                  this.errorFunction(
                    error,
                    "Safety Inspection Listing Information"
                  );
                })
                .finally(()=>{ 
                  this.isLoadingInProgress = false;
                });
            }
          } else {
            axios
              .get( apiDomain + "health_safety/safety_observation.show_by_dates" + "?from_date=" + this.filter.from_date + "&to_date=" + this.filter.to_date, { headers: getHeader() } )
              .then((response) => {
                if (response.status === 200) {
                  // console.log(response);
                  this.tableData = response.data.data;
                  this.populateDSSafety();
                  this.computeOpenClosedItems();
                  // this.filterByContractor()
                  // this.filterByONM()
                  this.fuseData();
                  // console.log(this.tableData);
                }
              })
              .catch((error) => {
                console.log(error.response.status);
                this.errorFunction(
                  error,
                  "Safety Inspection Listing Information"
                );
              })
              .finally(()=>{ 
                this.isLoadingInProgress = false;
              });
          }
        } else {
          this.redErrorNotify(
            "<b>Invalid Input : From Date is greater than To Date </b>"
          );
        }
      } else {
        this.redErrorNotify(
          "<b>Invalid Input : From Date or To Date is invalid </b>"
        );
      }
    },
    getSafetyObservationListing() {
      axios
        .get(apiDomain + "health_safety/safety_observation", {
          headers: getHeader(),
        })
        .then((response) => {
          if (response.status === 200) {
            this.tableData = response.data.data;
            this.computeOpenClosedItems();
            this.filterByContractor();
            this.filterByONM();
            this.fuseData();
            this.updateFilterMonthYear();
            // this.createNewTable();
          }
        })
        .catch((error) => {
          console.log(error.response.status);
          this.errorFunction(error, "Project Information");
        });
    },
    computeOpenClosedItems() {
      this.tableData.forEach((e) => {
        var openCounter = 0;
        var closedCounter = 0;
        e.safety_observation_items.forEach((e_inner) => {
          if (e_inner.safety_observation_status_id != 3) {
            openCounter++;
          } else {
            closedCounter++;
          }
        });
        e.safety_observation_items_open = openCounter;
        e.safety_observation_items_closed = closedCounter;
      });
    },
    updateFilterMonthYear() {
      this.tableData.forEach((e) => {
        var month_year = e["safety_observation_submitted_at"].split(/-(.*)/s);
        if (month_year[1]) {
          const valueExists = this.filter_month_year_data.find(
            (o) => o.value === month_year[1]
          );
          if (!valueExists) {
            this.filter_month_year_data.push({
              text: String(month_year[1]),
              value: month_year[1],
            });
          }
        }
      });
    },
    filterMonthYear(value, row) {
      var month_year = row["safety_observation_submitted_at"].split(/-(.*)/s);
      if (month_year[1]) {
        if (month_year[1] == value) {
          return true;
        }
        return false;
      }
      return row["safety_observation_submitted_at"] === value;
    },
    fuseData() {
      // this.fuseSearch = new Fuse(this.tableData, {
      // keys: this.propsToSearch,
      // threshold: 0.3
      // });
      if (this.myUserID.role_id == 9) {
        this.filterByUser();
      }
      this.fuseSearch = new Fuse(this.tableData, {
        keys: this.propsToSearch,
        threshold: 0.3,
      });
    },
    filterByContractor() {
      if (this.myUserID.role_id == 12) {
        var tempTable = [];
        tempTable = this.tableData.filter((e) => {
          if (e["contractor"]["contractor_id"] == this.contractor_id) {
            return e;
          }
        });
        this.tableData = tempTable;
      }
    },
    filterByONM() {
      if (this.myUserID.role_id == 18) {
        var tempTable = [];
        tempTable = this.tableData.filter((e) => {
          if (e["project_status_id"] == 5 || e["project_status_id"] == 7) {
            return e;
          }
        });
        this.tableData = tempTable;
      }
    },
    getContractorId() {
      axios
        .get(
          apiDomain + "lookup/contractor.contractor_id/" + this.myUserID.id,
          { headers: getHeader() }
        )
        .then((response) => {
          if (response.status === 200) {
            this.contractor_id = response.data.contractor_id;
            // this.getSafetyObservationListingRange();
            this.searchSafetyObservationListingFilter();
          }
        })
        .catch((error) => {
          this.errorFunction(error, "Contractor Id");
        })
        .finally(() => {
          this.getUserList();
        });
    },
    filterByUser() {
      var tempTableData = [];
      this.groupMembers.forEach((e) => {
        this.tableData.forEach((e_inner) => {
          if (e_inner["project_manager"]["id"] == e) {
            tempTableData.push(e_inner);
          }
        });
      });
      this.tableData = tempTableData;
    },
    paginationChanged(page) {
      this.pagination.currentPage = page;
    },
    handleDetails(index, row) {
      // if draft and creator redirect to edit
      // else redirect to view items page

      if (row.status == 0 && row.created_by == this.myUserID.name) {
        this.getSafetyObservationListingById(row.safety_observation_id);
      } else {
        window.open( "HSEView/" + row.safety_observation_id, "_blank" );
      }
    },
    deleteDetails(index, row) {
      this.obj_inspection.safety_observation_id = row.safety_observation_id;
      this.isDeleteSafetyObservationModalVisible = true;
    },
    deleteSafetyInspection() {
      const empty_request = {
        empty_request: null,
      };
      // console.log(this.obj_inspection.safety_observation_id);
      axios
        .post(
          apiDomain +
          "health_safety/delete_safety_inspection/" +
          this.obj_inspection.safety_observation_id,
          empty_request,
          { headers: getHeader() }
        )
        .then((response) => {
          // console.log(response);
          if (response.status === 200) {
            this.$notify({
              message:
                "<b> Safety Observation : " +
                this.obj_inspection.safety_observation_id +
                "</b> - Successfully Deleted.",
              timeout: 10000,
              icon: "ni ni-bell-55",
              type: "default",
            });
            //                            this.$router.push({path: '/healthSafety/safetyObservationListing'})
          }
        })
        .catch((error) => {
          // console.log(error.response.data.errors);
          this.errorFunction(
            error,
            "Delete Safety Inspection Details",
            this.obj_inspection.safety_observation_id
          );
        })
        .finally ( () => {
          this.closeDeleteSafetyObservationModalInner();
          // this.getSafetyObservationListingRange();          
          this.searchSafetyObservationListingFilter();
        });
    },
    closeDeleteSafetyObservationModal() {
      this.isDeleteSafetyObservationModalVisible = false;
    },
    showDeleteSafetyObservationModalInner() {
      this.isDeleteSafetyObservationModalVisible = false;
      this.isDeleteSafetyObservationModalVisibleInner = true;
    },
    closeDeleteSafetyObservationModalInner() {
      this.isDeleteSafetyObservationModalVisibleInner = false;
    },
    //_ populate data grid safety
    populateDSSafety() {
      //_ populate data grid
      //  why not just use this.tableData ? because the search not working if the data is nested, e.x project.project_name
      this.obj_data_grid.data = this.tableData.reduce((res, item) => {
        res.push({
          safety_observation_id: item.safety_observation_id,
          project_name: item.project.project_name,
          contractor_code: item.contractor.contractor_code,
          site_name: item.site_name,
          safety_observation_create_date_web: item.safety_observation_create_date_web,
          safety_observation_items: item.safety_observation_items,
          status: item.status,
          created_by: item.created_by.name,
        });
        return res;
      }, []);
    },

    // >>>>>>>>> UNUSE start
    selectionChange(selectedRows) {
      this.selectedRows = selectedRows;
    },
    toggleShowFilters() {
      this.showFilters = !this.showFilters;
    },
    //_ [UNUSE] - re-check first
    action_on_sub_modal(what_sub_action, what_sub_modal, what_main_modal) {
      this.reset_obj_observation_item();

      if (what_sub_action == 'show') {
        this.obj_obs_item.safety_observation_item_mode = 'create';
        this.$refs[what_sub_modal].show();
        // this.$refs[what_main_modal].hide();
      } else {
        this.$refs[what_sub_modal].hide();
        this.$refs[what_main_modal].show();
      }
    },
    // [NOT USE] this getAllSite can be delete, not use cause it's took quite long to load, and kind a overkill to get all those data just for getting site id and name
    getAllSite() {
      // console.log('getAllSite');
      axios.post(apiDomain + "project/project_site.show_by_filter", this.filter_site, {
        headers: getHeader(),
      })
        .then(response => {
          if (response.status === 200) {
            this.obj_list.sites_all = response.data.data;
            // console.log(this.obj_list.sites_all);
          }
        })
        .catch(error => {
          this.errorFunction(error, "Search Project Site Listing")
        });
    },
    //_ [DEBUG & TESTING]
  },
  mounted() {
    this.isLoadingInProgress = true;
  },
  computed: {

  },
  watch: {

  }
};
</script>

<style scoped>
/deep/ .dz-error {
  display: none;
}

/deep/ .el-popper .popper__arrow,
.el-popper .popper__arrow::after {
  border-style: hidden;
}

/deep/ .list-group-item {
  border: 1px solid #e9ecef00;
}

/deep/ select option {
  margin: 40px;
  background-color: #306b74 !important;
  color: #fff;
  text-shadow: 0 1px 0 rgba(0, 0, 0, 0.4);
}

/deep/ select option:disabled {
  color: #fff;
}

/deep/ .btn {
  color: white;

  &:hover {
    &::placeholder {
      color: white;
    }
  }
}

/deep/ .btn-outline-danger {
  background-color: #f5365c;
}

/deep/ .card-header {
  background-color: #3d6971d9;
  padding: 20px 0 20px 0;

}

</style>

<style scoped>
/deep/ input::-webkit-input-placeholder {
  color: #fff;
}

/deep/ input::-moz-input-placeholder {
  color: #fff;
}

/deep/ input::-ms-input-placeholder {
  color: #fff;
}

/deep/ .el-input .el-input__inner {
  background-color: #2C545A;
  color: #fff;
  border: 0px;
  border-radius: 10px;
  padding-top: 3px;
  padding-bottom: 2px;

  /* &::placeholder {
    color: white;
  } */
}

/deep/ .el-select-dropdown__item.hover,
.el-select-dropdown__item:hover {
  background-color: #23504e;
}

/deep/ .el-select-dropdown {
  background-color: #306b74;
  margin: 0px;
}

/deep/ .el-select-dropdown__item {
  color: white;
  background-color: #183034;
  
  ::hover {
    background-color: #2C545A
  }

}

/deep/ .el-select {
  .el-input .el-input__inner {
    &::placeholder {
      color: white;
    }
  }

}

/deep/ .el-select__input {
  color: white;
  background-color: #2C545A;
}

/deep/ .el-select__input.hover,
.el-select__input:hover {
  background-color: #23504e;
}


/deep/ .nav-pills .nav-link {
  /* padding: 0.75rem 1rem; */
  color: #cdcfdb;
  font-weight: 500;
  font-size: 0.875rem;
  -webkit-box-shadow: 0 0px 0px rgba(50, 50, 93, 0.11), 0 0px 0px rgba(0, 0, 0, 0.08);
  box-shadow: 0 0px 0px rgba(50, 50, 93, 0.11), 0 0px 0px rgba(0, 0, 0, 0.08);
  background-color: #3d697100;
  -webkit-transition: all 0.15s ease;
  transition: all 0.15s ease;

  &:hover {
    color: white;
  }
}

/deep/ .nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
  color: #fff;
  background-color: #175f6be3;
  border-left: 3px solid #85BCCE;
  width: 100%;

}

/deep/ .nav-pills .nav-item:not(:last-child) {
  padding-right: 0rem;
}

/deep/ .grid-tr-new {
  background-color: #23454A; color: white; font-size: smaller; border-bottom:  20px solid #306b74;
}
</style>

<style scoped>
/* Class For Modal */

.th-table-new {
  padding-top: 35px;
  padding-bottom: 35px;
  padding-left: 20px;
  font-size: small; 
  /*0.65rem;*/
}

.tr-td-table-new {
  padding-top: 30px;
  padding-bottom: 30px;
  padding-left: 20px;
}
/* 
.th-table-new-mdl {
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 20px;
  font-size: small;
} */

.tr-td-table-new-mdl {
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 20px;
}

/deep/ .form-control {
  background-color: #2C545A;
  border-color: #2C545A;
  color: white;

  &:focus {
    background-color: #2C545A;
    border-color: #2C545A;
    /* color: white; */
  }

  &::placeholder {
    color: rgb(163, 163, 163);
  }
}

/deep/ .form-control {
  &:focus {
    &::placeholder {
      color: rgb(163, 163, 163);
    }
  }
}

.input-group-text {
  background-color: #2C545A;
  ;
  color: white;

  &:focus {
    background-color: #2C545A;
    ;
    border-color: #1D4147;
    color: white;
  }
}

.focused {
  .input-group-text {
    background-color: #2C545A;
  }
}

.navbar-search-dark .input-group {
  background-color: #23454A;
  color: black;
  border-color: rgba(255, 255, 255, 0.6);
}

.navbar-search .input-group {
  border-radius: 7px;
}

select {
  background: url("data:image/svg+xml,<svg width='10' height='7' viewBox='0 0 10 7' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M5.02552 6.35057L0.320068 0.46875H9.73098L5.02552 6.35057Z' fill='white'/></svg>") no-repeat;
  background-position: calc(100% - 0.75rem) center !important;
  -moz-appearance: none !important;
  -webkit-appearance: none !important;
  appearance: none !important;
  padding-right: 2rem !important;
}

option {
  width: 100px !important;
}

/deep/ #__BVID__46___BV_modal_content_ {
  background-color: #306b74;
}

/deep/ .modal-content {
  background-color: #306b74 !important;
}

/deep/ .modal-body {
  background-color: #306b74 !important;
}
</style>

<!-- el-select custom -->
<style scoped>
/deep/ .el-select .el-input.is-focus .el-input__inner {
  border-color: transparent !important;
}

/deep/ .el-select .el-input .el-input__inner:focus {
  border-color: transparent !important;
}

/deep/ .el-select .el-select__tags .el-tag {
  background-color: #183034;
}

/deep/ .el-select-dropdown.is-multiple .el-select-dropdown__item.selected {
  background-color: #183034;
  color: #ffffff;
}

/deep/ .el-select-dropdown.el-popper .el-select-dropdown__item.selected, .el-select-dropdown.el-popper.is-multiple .el-select-dropdown__item.selected {
  background-color: #183034;
  color: #ffffff;
}
</style>

<!-- dataset -->
<style scoped>
/deep/ div.dataset-style select {
  color: #ffffff;
  background-color: #2c545a;
  border-color: #2c545a;
}

/deep/ div.dataset-style select.form-control {
  color: #ffffff;
  background-color: #2c545a;
  border-color: #2c545a;
}

/deep/ div.dataset-style select>option {
  color: #ffffff;
  background-color: #2c545a;
  border-color: #2c545a;
}
</style>

<!-- grid observation item -->
<style scoped>
/deep/ .grid-obs-item thead {
  background-color: rgb(82 146 155); color: white; font-size: small; border-bottom: 20px solid #306b74;
}

/deep/ .th-table-new-mdl {
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 20px;
  font-size: small;
}

.list-group-item {
  background-color: #23454A;
  border-bottom: 1px solid black;
}
</style>

<!-- pagination style -->
<style scoped>
/deep/ .pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.25rem;
  color: black;
}

/deep/ .page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.65rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #345f66;
  background-color: #fff;
  border: 1px solid #dee2e6;
}

/deep/ .page-link:hover {
  z-index: 2;
  color: #345f66;
  text-decoration: none;
  background-color: #e9ecef;
  border-color: #dee2e6;
}

/deep/ .page-link:focus {
  z-index: 3;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(62, 175, 124, 0.25);
}

/deep/ .page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

/deep/ .page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}

/deep/ .page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #345f66;
  border-color: #3a656b;
}

/deep/ .page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #dee2e6;
}

/deep/ .pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.5;
}

/deep/ .pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem;
  width: 60px;
}

/deep/ .pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
}

/deep/ .pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
}

/deep/ .pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem;
}

/deep/ .pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem;
}

/deep/ .b-pagination-pills .page-item .page-link {
  border-radius: 50rem !important;
  margin-left: 0.25rem;
  line-height: 1;
}

/deep/ .b-pagination-pills .page-item:first-child .page-link {
  margin-left: 0;
}
</style>

<!-- modal - loading in progress -->
<style scoped>
/deep/ .in-progress-modal-lg .modal-dialog {
    width: 100% !important;
    max-width: none !important;
    height: 100% !important;
    margin: 0 !important;
  }
  
/deep/ .in-progress-modal-lg .modal-dialog .modal-content {
    height: 100% !important;
    border: 0 !important;
    border-radius: 0 !important;
    /* background-color: transparent !important; */
    background: rgba(0,0,0,0.5);
    border: none !important;
  }
</style>
