<template>
    <card>
        <!-- Card header -->
        <h3 slot="header" class="mb-0">Update Project Site Daily Progress</h3>
        <div class="text-right">
        </div>
        <form class="needs-validation"
              @submit.prevent="submit">

            <div class="form-row">

                <b-col md="8">
                    <label>Remarks</label>
                    <b-form-textarea
                            id="textarea"
                            v-model="model.remarks"
                            placeholder=""
                            rows="5"
                            class="border"
                    ></b-form-textarea>
                </b-col>
            </div>

            <br>

            <div class="form-row form-group">
                <div class="col-md-8">
                    <label>File/Image Attachment:</label>
                    <br >
                    <div v-for="(file, fileindex) of model.file_attachment" class="alert border-bottom mb-2 p-2 d-flex align-items-center">
                        <div class="flex-fill">
                            <div class="fw-semibold">{{ file.uploaded_file.name }}</div>
                        </div>
                        <button class="btn btn-warning btn-sm" @click="model.file_attachment.splice(fileindex, 1)">&times;</button>
                        <br >
                    </div>

                    <div v-for="(itemAttachment, indexAttachment) of model.attachments" class="alert border-bottom mb-2 p-2 d-flex align-items-center">
                        <div class="flex-fill">
                            <a href="#" @click.prevent="downloadFile(itemAttachment.id)">{{ itemAttachment.filename }}</a>
                            <!--<div class="fw-semibold">{{ itemAttachment.attachment.filename }}</div>-->
                        </div>
                        <button class="btn btn-warning btn-sm" @click="removeAttachment(itemAttachment.id,itemAttachment.filename,indexAttachment)">&times;</button>

                    </div>

                    <div>
                        <base-button size="md" type="primary" @click.prevent="uploadAttachment('files-upload')">Add Attachment</base-button>
                        <input
                                v-show="false"
                                id="files-upload"
                                type="file"
                                ref="file"
                                style="display:none"
                                @change="fileChange($event)"
                        />
                    </div>
                </div>


            </div>
            <br>


            <base-button type="primary" @click="createData()">Update</base-button>
            <base-button type="primary" @click="$router.back()">Cancel</base-button>
        </form>
    </card>
</template>

<script>
    import ErrorFunctionMixin from '@/assets/js/errorFunctionMix.js'
    import {LoginURL, clientId, clientSecret, apiDomain, getHeader, getcurrentDate} from '@/assets/js/config.js'
    import axios from '@/util/axios'
    import jsonFormData from 'json-form-data';

    export default {
        mixins: [ErrorFunctionMixin],

        data() {
            return {
                model: {
                    remarks: null,
                    file_attachment: [],
                    attachments:null,
                },
                documents: [],
            }
        },

        created() {
            this.getData();
        },

        methods: {
            async submit() {

            },
            getData(){
                axios.get('project/get_project_site_daily_progress/'+this.$route.params.id)
                    .then ( response => {
                        this.model.remarks= response.data.data.remarks
                        this.model.attachments= response.data.data.attachments
                    })
                    .catch ( error => {
                        console.log(error.response.status)
                        this.errorFunction(error, "Edit Data Manipulation")
                    });
            },
            createData() {
                if (!this.model.remarks )
                {
                    this.redErrorNotify( '<b>Invalid Input : Remarks is a Mandatory Column.</b>')
                }
                else
                {
                    this.model.attachment = this.documents;
                    let formData = jsonFormData(this.model);
                    axios.post('project/edit_project_site_daily_progress/'+this.$route.params.id+'', formData)
                        .then ( response => {
                            this.$notify({
                                message:
                                '<b>Update Site Daily Progress: ' + this.$route.params.id + '</b> - Successfully Updated.',
                                timeout: 10000,
                                icon: 'ni ni-bell-55',
                                type: 'default',
                            });
                            this.$router.back()
                        })
                        .catch ( error => {
                            this.errorFunction(error, "Update")
                        });
                }
            },
            fileChange(event) {
                let file = event.target.files[0];

                // Remove type validation, allow any file type
                let newArray = {uploaded_file: file};

                // Add file to the model and documents
                this.model.file_attachment.push(newArray);
                this.documents.push({ file: file});

            },
            uploadAttachment(id){
                document.getElementById(id).click()
            },
            downloadFile(id) {
                axios.post(`project/project_site_token?value=${id}`)
                    .then(response => {
                        const token = response.data
                        const url = `${apiDomain}project/download/${id}?token=${token}`
                        console.log(url);
                        window.open(url, '_blank')
                    })
                    .catch(error => {
                        console.log(error)
                        this.errorFunction(error, this.title)
                    })
            },
            removeAttachment(id,filename,index){
                axios.post(apiDomain + 'project/delete_project_site_daily_progress_attachment/'+id, { headers: getHeader() })
                    .then ( response => {
                        this.$notify({
                            message: '<b>Delete Attachment : ' + filename+ '</b> - Successfully Deleted.',
                            timeout: 10000,
                            icon: 'ni ni-bell-55',
                            type: 'default',
                        });

                        this.model.attachments.splice(index, 1);
                        console.log(this.model.attachments);
                      //  window.location.reload();

                    })
                    .catch ( error => {
                        this.errorFunction(error, "Delete Project Site Daily Progress")
                    });
            }
        }
    }
</script>
