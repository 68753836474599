<template>
  <div>
    <base-header class="pb-6">
      <b-row class="py-4">
        <b-col lg="12" cols="7">
          <h6
            v-if="!isSolarNovaProject"
            class="h2 text-white d-inline-block mb-0"
          >
            Project Group Dashboard
          </h6>
          <h6 v-else class="h2 text-white d-inline-block mb-0">
            Town Council Dashboard
          </h6>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <route-bread-crumb></route-bread-crumb>
          </nav>
        </b-col>

        <b-col lg="6" cols="7"> </b-col>

        <b-col lg="6" cols="7">
          <card gradient="default" no-body="">
            <b-card-body>
              <div class="mb-2">
                <div>
                  <span v-if="!isSolarNovaProject" class="h2 text-white"
                    >Group Name : {{ model.group_name }}
                  </span>
                  <span v-else class="h2 text-white"
                    >Town Council Name : {{ model.group_name }}
                  </span>
                </div>

                <div v-if="!isSolarNovaProject" class="text-light mt-2 text-sm">
                  Current Group Status :
                  <span class="text-success font-weight-600">
                    {{ model.status.status_code }}
                  </span>
                </div>
                <div v-else class="text-light mt-2 text-sm">
                  Current Town Council Status :
                  <span class="text-success font-weight-600">
                    {{ model.status.status_code }}
                  </span>
                </div>
                <div class="text-light mt-2 text-sm">
                  Project Engineer :
                  <span class="text-success font-weight-600">
                    {{ model.group_engineer.name }}
                  </span>
                </div>
                <!-- <div class="text-light mt-2 text-sm">
                  Project Manager :
                  <span class="text-success font-weight-600">
                    {{ model.group_engineer.name }}
                  </span>
                </div> -->
              </div>
            </b-card-body>
          </card>
        </b-col>
        <b-col lg="12" cols="6" class="text-right">
          <!-- <base-button size="sm" type="neutral" @click="newTask()"
           v-if="button_access.site_task_new == 'YES'">Create New Site Task</base-button>

          <base-button size="sm" type="neutral" @click="newSiteDocument()"
          v-if="button_access.site_document_new == 'YES'">Create New Site Document</base-button> -->

          <base-button
            size="sm"
            type="neutral"
            @click="newProjectSite()"
            v-if="
              button_access.site_new == 'YES' &&
              ( myUserID.email == model.group_engineer.email || myUserID.role_id == 3 )
            "
            >Create New Site</base-button
          >

          <base-button size="sm" type="neutral" @click="$router.back()"
            >Back</base-button
          >
        </b-col>
      </b-row>

      <b-row class="justify-content-end">
        <b-col v-if="!isSolarNovaProject" xl="4" md="6">
          <stats-card
            title="Completed / Total TASK"
            type="gradient-red"
            :sub-title="`${model.group_completed_task} / ${model.group_total_task}`"
            icon="ni ni-pin-3"
          >
            <template slot="footer"> </template>
          </stats-card>
        </b-col>
        <b-col xl="4" md="6">
          <stats-card
            title="Turn On / Total (kWP)"
            type="gradient-orange"
            :sub-title="`${model.group_completed_capacity} / ${model.group_total_capacity} `"
            icon="ni ni-cloud-upload-96"
          >
            <template slot="footer"> </template>
          </stats-card>
        </b-col>
        <!-- <b-col xl="3" md="6">
          <stats-card title="Completed / Total DOCUMENT"
                      type="gradient-green"
                      :sub-title="`${model.group_completed_document} / ${model.group_total_document}`"
                      icon="ni ni-bullet-list-67">

            <template slot="footer">

            </template>
          </stats-card>

        </b-col> -->
        <b-col xl="4" md="6">
          <stats-card
            title="Group Progress"
            type="gradient-info"
            :sub-title="`${model.group_progress} %`"
            icon="ni ni-chart-bar-32"
          >
            <template slot="footer"> </template>
          </stats-card>
        </b-col>
      </b-row>
    </base-header>

    <b-container fluid class="mt--6">
      <b-row>
        <b-col xl="12">
          <project-group-view-form></project-group-view-form>
        </b-col>
      </b-row>
      <b-row
        ><b-col
          ><project-group-management-remarks></project-group-management-remarks></b-col
      ></b-row>
      <b-row align-h="end">
        <!-- <b-col xl="6">
          <card>
            <h5 class="h3 mb-0" slot="header">Team Members</h5>
            <user-list></user-list>
          </card>
          </b-col> -->
        <b-col xl="6" v-if="!isSolarNovaProject">
          <card>
            <h5 v-if="!isSolarNovaProject" class="h3 mb-0" slot="header">
              Group Contractor List for ({{ model.group_code }})
            </h5>
            <h5 v-else class="h3 mb-0" slot="header">
              Town Council Contractor List for ({{ model.group_code }})
            </h5>
            <group-contractor-list></group-contractor-list>
          </card>
        </b-col>
      </b-row>

      <!-- <b-row
        ><b-col
          ><div>GROUP PROGRESS CHART HERE</div>
          <div>{{ tempGroupMilestoneResponse }}</div>
          <div>{{ chartOptionsGroupIncomplete }}</div>
          <div>{{ seriesGroupIncomplete }}</div>
        </b-col></b-row
      > -->
      <card>
        <b-row v-if="isSolarNovaProject"
          ><b-col xl="6" 
            ><div>Group Turn On / In Progress Chart</div>
            <!-- <div>{{tempTurnOnInProgressKwpResponse}}</div> -->
            <apexchart
              ref="TurnOnInProgressKwp"
              type="pie"
              height="200px"
              :options="chartOptionsTurnOnInProgressKwp"
              :series="seriesTurnOnInProgressKwp"
              @dataPointSelection="dataPointSelectionHandlerTurnOnInProgressKwp"
            ></apexchart></b-col
          ><b-col xl="6"
            ><div>Group In Progress Chart</div>
            <apexchart
              ref="groupIncompleteKwp"
              type="pie"
              height="200px"
              :options="chartOptionsGroupIncomplete"
              :series="seriesGroupIncomplete"
            ></apexchart></b-col></b-row
      ></card>
      <!-- @dataPointSelection="dataPointSelectionHandlerPieCNI" -->

      <b-row v-if="button_access.site_listing == 'YES'">
        <b-col lg="12">
          <project-group-site-listing-table></project-group-site-listing-table>
        </b-col>
      </b-row>

      <b-row
        v-if="button_access.site_task_listing == 'YES' && !isSolarNovaProject"
      >
        <b-col lg="12">
          <project-group-task-listing-table
            @EventTaskListUpdate="afterUpdateTaskList()"
          ></project-group-task-listing-table>
        </b-col>
      </b-row>

      <!-- <b-row v-if="button_access.site_document_listing == 'YES'">
        <b-col lg="12">
          <project-group-document-listing-table @EventDocumentListUpdate="afterUpdateTaskList()"></project-group-document-listing-table>
        </b-col>
      </b-row> -->
    </b-container>

    <modal :show.sync="modals.formTask" size="lg" body-classes="p-0">
      <card
        type="secondary"
        header-classes="bg-transparent pb-5"
        body-classes="px-lg-5 py-lg-5"
        class="border-0 mb-0"
      >
        <template>
          <div class="text-center text-muted mb-4">
            <h3>
              Populate / Import Task & Milestone Template into ( TRTP-2398 -
              Tengeh Reservoir Tender Project )
            </h3>
          </div>
          <b-form role="form">
            <base-input label="Populate To Target Site" name="Task Site">
              <select class="form-control" v-model="formModal.task_site">
                <option value="" disabled>
                  -- Please Select A Target Site --
                </option>
                <option>SN-NT - Tengeh Reservoir North Site</option>
                <option>SN-SH - Tengeh Reservoir South Site</option>
                <option>SN-ET - Tengeh Reservoir East Site</option>
                <option>SN-WT - Tengeh Reservoir West Site</option>
              </select>
            </base-input>
            <base-input label="Task Template" name="Task Template">
              <select class="form-control" v-model="formModal.task_template">
                <option value="" disabled>
                  -- Please Select A Task Template --
                </option>
                <option value="Solar Panel Project Template">
                  TS-SL-Temp : Solar Panel Project (No. of Task : 40)
                </option>
                <option value="Power Plant Project Template">
                  TS-PL-Temp :Power Plant Project (No. of Task : 120)
                </option>
              </select>
            </base-input>
            <base-input label="Task Template" name="Document Template">
              <select class="form-control">
                <option value="">
                  -- Please Select A Document Template --
                </option>
                <option value="Solar Panel Project Template">
                  DT-SL-Temp : Solar Panel Document Project Template (No. of
                  Classfication : 10)
                </option>
                <option value="Power Plant Project Template">
                  DT-PL-Temp : Power Plant Document Project Template (No. of
                  Classfication : 12)
                </option>
              </select>
            </base-input>
            <h5 class="text-danger">
              Note : <br />
              <i>
                1. Please Create a New Site before Import / Populate Task &
                Milestone into the Site (We Recommend to Import / Populate into
                a New Site).
                <br />
                2. Import / Populate Task into existing site will only create
                new task (All existing TASK will still be remain intact, But
                import twice might cause duplication).
                <br />
                3. All the Milestone will be Import / Populate TOGETHER
                according with the "Task Template".
                <br />
                4. To Change Milestone please proceed to Menu >> Template >>
                Milestone Template.
                <br />
                5. To Change Task please proceed to Menu >> Template >> Task
                Template.
                <br />
                6. Task Details in Template will not have the following column :
                <br />
                &nbsp;&nbsp;&nbsp; a) Project & Site.
                <br />
                &nbsp;&nbsp;&nbsp; b) Contractor.
                <br />
                &nbsp;&nbsp;&nbsp; c) Progress & Status.
                <br />
                &nbsp;&nbsp;&nbsp; d) Est Start , End Date & Start , End Date.
                <br />
                7. Only Column (a) & (b) can be selected during import. Please
                change column of (c) & (d) on each task later.
                <br />
                8. System will auto generate NEW TASK ID for all the TASK import
                / populate from Template. But Milestone CODE will remain the
                same.
              </i>
            </h5>
            <div class="text-center">
              <base-button
                type="primary"
                class="my-4"
                @click="notifyVueFormTask()"
                >Populate / Import Task Template</base-button
              >
              <base-button type="primary" class="my-4" @click="clearModal()"
                >Cancel</base-button
              >
            </div>
          </b-form>
        </template>
      </card>
    </modal>

    <modal :show.sync="modals.formMilestone" size="xl" body-classes="p-0">
      <card
        type="secondary"
        header-classes="bg-transparent pb-5"
        body-classes="px-lg-5 py-lg-5"
        class="border-0 mb-0"
      >
        <template>
          <div class="text-center text-muted mb-4">
            <h3>
              Milestone Template Import For ( TRTP-2398 - Tengeh Reservoir
              Tender Project )
            </h3>
          </div>
          <b-form role="form">
            <base-input label="Milestone Template" name="Milestone Template">
              <select
                class="form-control"
                v-model="formModal.milestone_template"
              >
                <option value="" disabled>
                  -- Please Select A Milestone Template --
                </option>
                <option value="Solar Panel Project Template">
                  MS-SL-Temp : Solar Panel Project (No. of Milestone : 6)
                </option>
                <option value="Power Plant Project Template">
                  MS-PL-Temp : Power Plant Project (No. of Milestone : 14)
                </option>
              </select>
            </base-input>
            <div class="text-center">
              <base-button
                type="primary"
                class="my-4"
                @click="notifyVueFormMilestone()"
                >Import Milestone Template</base-button
              >
              <base-button type="primary" class="my-4" @click="clearModal()"
                >Cancel</base-button
              >
            </div>
          </b-form>
        </template>
      </card>
    </modal>
  </div>
</template>

<script>
import RouteBreadCrumb from "@/components/Breadcrumb/RouteBreadcrumb";
import BaseHeader from "@/components/BaseHeader";
import ProjectGroupViewForm from "./ProjectGroupViewForm";
import ProjectGroupManagementRemarks from "./ProjectGroupManagementRemarks";

import LineChart from "@/components/Charts/LineChart";
import * as chartConfigs from "@/components/Charts/config";
import ProjectGroupSiteListingTable from "./ProjectGroupSiteListingTable";
import ProjectGroupTaskListingTable from "./ProjectGroupTaskListingTable";
import ProjectGroupDocumentListingTable from "./ProjectGroupDocumentListingTable";

import UserList from "./../Project/AdditionalTable/UserTable.vue";
import ContractorList from "./AdditionalTable/GroupContractorTable";

import ProjectGroupActivityTable from "./AdditionalTable/ProjectGroupActivityTable";
import ProjectGroupMilestoneProgressTrackList from "./AdditionalTable/ProjectGroupMilestoneProgressTrackList";

import {
  LoginURL,
  clientId,
  clientSecret,
  apiDomain,
  getHeader,
  getcurrentDate,
} from "@/assets/js/config.js";
import axios from "axios";
import VueApexCharts from "vue-apexcharts";

import { Modal } from "@/components";

export default {
  components: {
    BaseHeader,
    RouteBreadCrumb,
    ProjectGroupViewForm,
    LineChart,
    UserList,
    "group-contractor-list": ContractorList,
    "project-group-task-listing-table": ProjectGroupTaskListingTable,
    ProjectGroupActivityTable,
    ProjectGroupMilestoneProgressTrackList,
    "project-group-site-listing-table": ProjectGroupSiteListingTable,
    "project-group-document-listing-table": ProjectGroupDocumentListingTable,
    "project-group-management-remarks": ProjectGroupManagementRemarks,
    Modal,
    apexchart: VueApexCharts,
  },
  created() {
    var site_task_access = JSON.parse(
      window.localStorage.getItem("site_task_access")
    );
    this.button_access.site_task_listing = site_task_access.access_listing;
    this.button_access.site_task_new = site_task_access.access_create;

    var site_document_access = JSON.parse(
      window.localStorage.getItem("site_document_access")
    );
    this.button_access.site_document_listing =
      site_document_access.access_listing;
    this.button_access.site_document_new = site_document_access.access_create;

    var project_site_access = JSON.parse(
      window.localStorage.getItem("project_site_access")
    );
    this.button_access.site_listing = project_site_access.access_listing;
    this.button_access.site_new = project_site_access.access_create;

    const LoginUser = JSON.parse(window.localStorage.getItem("loginUser"));
    this.myUserID = LoginUser;

    this.getdashboardData();

    // this.getDataListing();
  },
  data() {
    return {
      button_access: {
        site_task_listing: null,
        site_task_new: null,
        site_document_listing: null,
        site_document_new: null,
        site_listing: null,
        site_new: null,
      },
      myUserID: null,
      model: {
        group_id: null,
        group_code: null,
        project_id: null,
        project_code: null,
        group_name: null,
        group_total_installation: 0,
        group_total_task: 0,
        group_completed_task: 0,
        group_total_document: 0,
        group_completed_document: 0,
        group_progress: 0,
        group_completed_capacity: 0,
        group_total_capacity: 0,
        status: {
          status_id: null,
          status_code: null,
        },
        group_engineer: {
          name: null,
          email: null,
        },
      },
      modals: {
        formTask: false,
        formMilestone: false,
      },
      formModal: {
        task_template: "",
        task_site: "",
        milestone_template: "",
      },
      bigLineChart: {
        allData: [
          [0, 20, 10, 30, 15, 40, 20, 60, 60],
          [0, 20, 5, 25, 10, 30, 15, 40, 40],
        ],
        activeIndex: 0,
        chartData: {
          datasets: [
            {
              label: "Performance",
              data: [
                0, 100, 250, 500, 1000, 1500, 2000, 2500, 2900, 3200, 3300,
                3300,
              ],
            },
          ],
          labels: [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec",
          ],
        },
        extraOptions: chartConfigs.blueChartOptions,
      },
      isSolarNovaProject: false,
      tempGroupMilestoneResponse: null,
      chartOptionsGroupIncomplete: {
        chart: {
          type: "pie",
        },
        theme: {
          mode: "dark",
        },
        labels: [],
        colors: [
          "#84acb6",
          "#3494ba",
          "#75bda7",
          "#7a8c8e",
          "#84acb6",
          "#2683c6de",
        ],
        responsive: [
          {
            options: {
              chart: {
                width: 200,
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
        tooltip: {
          y: {
            formatter: function (val) {
              return val + " kWp";
            },
          },
        },
      },
      seriesGroupIncomplete: [],
      chartOptionsTurnOnInProgressKwp: {
        chart: {
          type: "pie",
        },
        theme: {
          mode: "dark",
        },
        labels: ["Turn On", "In Progress"],
        responsive: [
          {
            options: {
              chart: {
                width: 200,
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
        tooltip: {
          y: {
            formatter: function (val) {
              return val + " kWp";
            },
          },
        },
      },
      seriesTurnOnInProgressKwp: [],
    };
  },
  methods: {
    dataPointSelectionHandlerTurnOnInProgressKwp(e, chartContext, config) {
      if (config.dataPointIndex == 0) {
        // 0 = Turn On
        let route = this.$router.resolve({
          path:
            "/projectSiteListingByProjectGroupTurnOn/" + this.model.group_id,
        });
        window.open(route.href, "_blank");
      } else if (config.dataPointIndex == 1) {
        // 1 = In Progress
        let route = this.$router.resolve({
          path:
            "/projectSiteListingByProjectGroupInProgress/" +
            this.model.group_id,
        });
        window.open(route.href, "_blank");
      }
    },
    getTurnOnInProgressKwp() {
      this.model.group_id = this.$route.params.id;

      axios
        .get(
          apiDomain +
            "project/project_group.turn_on_in_progress_kwp/" +
            this.model.group_id,
          { headers: getHeader() }
        )
        .then((response) => {
          if (response.status === 200) {
            var tempSeriesArray = [];
            this.tempTurnOnInProgressKwpResponse = response.data.data;
            tempSeriesArray.push(
              this.tempTurnOnInProgressKwpResponse[0]["turn_on_kwp_in_project"]
            );
            tempSeriesArray.push(
              this.tempTurnOnInProgressKwpResponse[0][
                "in_progress_kwp_in_project"
              ]
            );
            this.seriesTurnOnInProgressKwp = tempSeriesArray;
          }
        })
        .catch((error) => {
          console.log(error.response.status);
        });
    },
    updateGroupIncompletePieChart() {
      var tempLabelsArray = [];
      var tempSeriesArray = [];
      this.tempGroupMilestoneResponse.forEach((e) => {
        tempLabelsArray.push(e["milestone_template_title"]);
        tempSeriesArray.push(e["site_milestone_kwp_in_progress"]);
      });
      this.chartOptionsGroupIncomplete = {
        labels: tempLabelsArray,
      };
      this.seriesGroupIncomplete = tempSeriesArray;
    },
    getDataListing() {
      this.model.group_id = this.$route.params.id;

      axios
        .get(
          apiDomain +
            "project/project_site_milestone.show_by_group/" +
            this.model.group_id,
          { headers: getHeader() }
        )
        .then((response) => {
          if (response.status === 200) {
            this.tempGroupMilestoneResponse = response.data.data;
            this.updateGroupIncompletePieChart();
            // this.updateSiteIncompletePieChart();
          }
        })
        .catch((error) => {
          console.log(error.response.status);
        });
    },
    afterUpdateTaskList() {
      this.getdashboardData();
    },
    // Get Project Type ID and Check if Solar Nova Project
    getProjectTypeCheckSolar() {
      var project_type_id = null;
      axios
        .get(apiDomain + "project/project/" + this.model.project_id, {
          headers: getHeader(),
        })
        .then((response) => {
          if (response.status === 200) {
            project_type_id =
              response.data.data[0].project_type.project_type_id;
            if (
              project_type_id != null &&
              // (project_type_id == 2 ||
              //   project_type_id == 3 ||
              //   project_type_id == 4)
              ([2, 3, 4, 9, 10, 11, 12, 13, 14].includes(project_type_id))
            ) {
              this.isSolarNovaProject = true;
              this.getDataListing();
              this.getTurnOnInProgressKwp();
            }
          }
        })
        .catch((error) => {
          console.log(error.response.status);
        });
    },
    getdashboardData() {
      this.model.group_id = this.$route.params.id;

      axios
        .get(apiDomain + "project/project_group/" + this.model.group_id, {
          headers: getHeader(),
        })
        .then((response) => {
          if (response.status === 200) {
            this.model.group_total_task =
              response.data.data[0].group_total_task;
            this.model.group_total_installation =
              response.data.data[0].group_total_installation;
            this.model.group_total_document =
              response.data.data[0].group_total_document;
            this.model.group_progress = response.data.data[0].group_progress;
            this.model.project_code = response.data.data[0].project_name;
            this.model.group_code = response.data.data[0].group_name;
            this.model.group_name = response.data.data[0].group_name;
            this.model.status.status_code =
              response.data.data[0].status.status_code;
            this.model.group_engineer.name =
              response.data.data[0].group_engineer.name;
            this.model.group_engineer.email =
              response.data.data[0].group_engineer.email;
            this.model.group_completed_capacity =
              response.data.data[0].group_completed_capacity;
            this.model.group_total_capacity =
              response.data.data[0].group_total_capacity;
            this.model.group_completed_task =
              response.data.data[0].group_completed_task;
            this.model.group_completed_document =
              response.data.data[0].group_completed_document;
            this.model.project_id = response.data.data[0].project.project_id;
            this.getProjectTypeCheckSolar();
          }
        })
        .catch((error) => {
          console.log(error.response.status);
        });
    },
    newSiteDocument() {
      this.$router.push({
        path: "/siteDocumentNewFrGroup/" + this.model.group_id,
      });
    },
    newTask() {
      this.$router.push({ path: "/taskNewFrGroup/" + this.model.group_id });
    },
    newProjectSite() {
      this.$router.push({
        path: "/projectSiteNewFrGroup/" + this.model.group_id,
      });
    },
    backToProject() {
      this.$router.push({ path: "/projectListing" });
    },
    newImport() {
      this.$router.push({ path: "/importNewFrGroup/" + this.model.group_id });
    },
    notready() {
      this.$notify({
        message:
          "<b>Under Developer : </b> - New Task, Site & Populate / Import From Template function is not ready.",
        timeout: 10000,
        icon: "ni ni-bulb-61",
        type: "danger",
      });
    },
    notifyVueFormTask() {
      if (
        this.formModal.task_template === "" ||
        this.formModal.task_site === ""
      ) {
        this.$notify({
          message:
            "<b>Task Template Input Error : </b> - Please Select A Task Template & Target Site OR press <b>CANCEL</b> to <b>EXIT</b>.",
          timeout: 10000,
          icon: "ni ni-bulb-61",
          type: "danger",
        });
      } else {
        this.$notify({
          message:
            "<b>Task Template (" +
            this.formModal.task_template +
            ") : </b> - Successfully Imported into ( TRTP-2398 - Tengeh Reservoir Tender Project )",
          timeout: 10000,
          icon: "ni ni-bell-55",
          type: "default",
        });
        this.clearModal();
      }
    },
    notifyVueFormMilestone() {
      if (this.formModal.milestone_template === "") {
        this.$notify({
          message:
            "<b>Milestone Template Input Error : </b> - Please Select A Milestone Template Or Cancel to Exit.",
          timeout: 10000,
          icon: "ni ni-bulb-61",
          type: "danger",
        });
      } else {
        this.$notify({
          message:
            "<b>Milestone Template (" +
            this.formModal.milestone_template +
            ") : </b> - Successfully Imported into ( TRTP-2398 - Tengeh Reservoir Tender Project )",
          timeout: 10000,
          icon: "ni ni-bell-55",
          type: "default",
        });
        this.clearModal();
      }
    },
    clearModal() {
      this.modals.formTask = false;
      this.modals.formMilestone = false;
      this.formModal.task_template = "";
      this.formModal.task_site = "";
      this.formModal.milestone_template = "";
    },
  },
};
</script>
<style></style>
