<template>
  <div class="content">
    <base-header class="pb-6">
      <b-row align-v="center" class="py-4">
        <b-col cols="7" lg="6">
          <h6 class="h2 text-white d-inline-block mb-0">Audit Log Safety Inspection Listing</h6>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <route-bread-crumb></route-bread-crumb>
          </nav>
        </b-col>
      </b-row>
    </base-header>

    <b-container fluid class="mt--6">
      <div>
        <card class="no-border-card" body-classes="px-0 pb-1" footer-classes="pb-2">
          <template slot="header">
            <h3 class="mb-0">Audit Log Safety Inspection Listing</h3>
          </template>
          <div>
            <b-col cols="12" class="d-flex justify-content-center justify-content-sm-between flex-wrap">
              <el-select
                class="select-primary pagination-select"
                v-model="pagination.perPage"
                placeholder="Per page">
                <el-option
                  class="select-primary"
                  v-for="item in pagination.perPageOptions"
                  :key="item"
                  :label="item"
                  :value="item">
                </el-option>
              </el-select>
              <div>
                <base-input v-model="searchQuery"
                            prepend-icon="fas fa-search"
                            placeholder="Search...">
                </base-input>
              </div>
            </b-col>
            <b-col cols="12">
              <h3>Filters</h3>

              <div class="form-row">

                <b-col cols="6">
                  <!-- <base-input
                    label="Audit Table"
                    name="Audit Table"
                    v-model="filter.audit_table"
                  >
                    <select class="form-control" v-model="filter.audit_table">
                      <option Selected disabled value="x">
                        -- Please Select An Audit Table --
                      </option>
                      <option :value=null>All</option>
                      <option
                        v-for="item in uniqueTable"
                        :key="item"
                        v-bind:value="item"
                      >
                        {{ item }}
                      </option>
                    </select>
                  </base-input> -->
                </b-col>

                <b-col cols="6">
                  <base-input
                    label="User"
                    name="User"
                    v-model="filter.user_id"
                  >
                    <select class="form-control" v-model="filter.user_id">
                      <option Selected disabled value="x">
                        -- Please Select A User --
                      </option>
                      <option :value=null>All</option>
                      <option
                        v-for="item in uniqueUser"
                        :key="item.id"
                        v-bind:value="item.id"
                      >
                        {{ item.name }} - {{ item.email }}
                      </option>
                    </select>
                  </base-input>
                </b-col>
                
              </div>

              <div class="form-row">

                <b-col cols="6">
                  <base-input
                    label="From Date"
                    name="From Date"
                    type="date"
                    v-model="filter.from_date"
                  >
                  </base-input>
                </b-col>

                <b-col cols="6">
                  <base-input
                    label="To Date"
                    name="To Date"
                    type="date"
                    v-model="filter.to_date"
                  >
                  </base-input>
                </b-col>

              </div>

              <b-row>
                <b-col class="text-right">
                  <base-button size="sm" type="primary" @click="searchAuditLogSafetyInspection()"
                    >Search</base-button
                  >
                </b-col>
              </b-row>

            </b-col>
            <b-col><br></b-col>
            <!-- row-key="developer_template_id" -->
            <el-table :data="queriedData"
                      header-row-class-name="thead-light"
                      @sort-change="sortChange">

              <!-- <el-table-column label="Audit Log ID"
                             min-width="140px"
                             prop="audit_log_id"
                             sortable>
                <template v-slot="{row}">
                    <b-media no-body class="align-items-center">
                        <b-media-body>
                            <span class="font-weight-600  mb-0 text-sm">{{row.audit_log_id}}</span>
                        </b-media-body>
                    </b-media>
                </template>
              </el-table-column> -->

              <el-table-column label="Audit Table"
                             min-width="140px"
                             prop="audit_table"
                             >
                <template v-slot="{row}">
                    <b-media no-body class="align-items-center">
                        <b-media-body>
                            <span class="font-weight-600  mb-0 text-sm">{{row.audit_table}}</span>
                        </b-media-body>
                    </b-media>
                </template>
              </el-table-column>

              <!-- <el-table-column label="Audit Column"
                             min-width="140px"
                             prop="audit_column"
                             sortable>
                <template v-slot="{row}">
                    <b-media no-body class="align-items-center">
                        <b-media-body>
                            <span class="font-weight-600  mb-0 text-sm">{{row.audit_column}}</span>
                        </b-media-body>
                    </b-media>
                </template>
              </el-table-column> -->

              <el-table-column label="Audit Data ID"
                             min-width="100px"
                             prop="audit_data_id"
                             >
                <template v-slot="{row}">
                    <b-media no-body class="align-items-center">
                        <b-media-body>
                            <span class="font-weight-600  mb-0 text-sm">{{row.audit_data_id}}</span>
                        </b-media-body>
                    </b-media>
                </template>
              </el-table-column>

              <el-table-column label="Audit Action"
                             min-width="140px"
                             prop="action"
                             >
                <template v-slot="{row}">
                    <b-media no-body class="align-items-center">
                        <b-media-body>
                            <span class="font-weight-600  mb-0 text-sm">{{row.action}}</span>
                        </b-media-body>
                    </b-media>
                </template>
              </el-table-column>

              <el-table-column label="Audit Changed Date"
                             min-width="140px"
                             prop="action_done_at"
                             >
                <template v-slot="{row}">
                    <b-media no-body class="align-items-center">
                        <b-media-body>
                            <span class="font-weight-600  mb-0 text-sm">{{row.action_done_at}}</span>
                        </b-media-body>
                    </b-media>
                </template>
              </el-table-column>

              <el-table-column label="Audit Changed By"
                             min-width="140px"
                             prop="action_done_by"
                             >
                <template v-slot="{row}">
                    <b-media no-body class="align-items-center">
                        <b-media-body>
                            <span class="font-weight-600  mb-0 text-sm">{{row.action_done_by.name}}</span>
                        </b-media-body>
                    </b-media>
                </template>
              </el-table-column>

              <el-table-column label="Parent Table"
                             min-width="140px"
                             prop="parent_table"
                             >
                <template v-slot="{row}">
                    <b-media no-body class="align-items-center">
                        <b-media-body>
                            <span class="font-weight-600  mb-0 text-sm">{{row.parent_table}}</span>
                        </b-media-body>
                    </b-media>
                </template>
              </el-table-column>

              <el-table-column label="Parent ID"
                             min-width="100px"
                             prop="parent_id"
                             >
                <template v-slot="{row}">
                    <b-media no-body class="align-items-center">
                        <b-media-body>
                            <span class="font-weight-600  mb-0 text-sm">{{row.parent_id}}</span>
                        </b-media-body>
                    </b-media>
                </template>
              </el-table-column>
            </el-table>
          </div>
          <div
            slot="footer"
            class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap">
            <div class="">
              <p class="card-category">
                Showing {{ from + 1 }} to {{ to }} of {{ total }} entries

                <span v-if="selectedRows.length">
                  &nbsp; &nbsp; {{selectedRows.length}} rows selected
                </span>
              </p>
            </div>
            <base-pagination
              class="pagination-no-border"
              :current="pagination.currentPage"
              :per-page="pagination.perPage"
              :total="total"
              @change="paginationChanged($event)">
            </base-pagination>
          </div>
        </card>
      </div>
    </b-container>
    </div>
</template>
<script>
import { Table, TableColumn, Select, Option } from 'element-ui';
import RouteBreadCrumb from '@/components/Breadcrumb/RouteBreadcrumb'
import { BasePagination } from '@/components';
import standardPaginationMix from '@/assets/js/standardPaginationMix.js'
import axios from 'axios'
import Fuse from 'fuse.js';
import {LoginURL, clientId, clientSecret, apiDomain, getHeader, getcurrentDate, parseDateToUTC} from '@/assets/js/config.js'
import ErrorFunctionMixin from '@/assets/js/errorFunctionMix.js'

export default {
  mixins: [standardPaginationMix, ErrorFunctionMixin],
  components: {
    BasePagination,
    RouteBreadCrumb,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn
  },
  created()
  {
    this.setDefaultMonthRange();
    this.getAuditLogSafetyInspection()
  },
  data() {
    return {
      propsToSearch:
      [
        'audit_table',
        'audit_data_id',
        'action',
        'action_done_at',
        'action_done_by.name',
        'parent_table',
        'parent_table'
      ],
      tableColumns: [],
      tableData: [],
      selectedRows: [],
      button_access:{
        new_button: null,
        show_button: null,
      },
      filter: {
        from_date: null,
        to_date: null,
        // audit_table: null,
        user_id: null,
      },
      tempButtonStatus: false,
      uniqueTable: null,
      uniqueUser: [],
    };
  },
  methods: {
    setDefaultMonthRange(){
      var currentDate = getcurrentDate();
      var localCurrentDate = new Date(currentDate);

      // get current date in yyyy-mm-dd format
      const currentYear = localCurrentDate.getFullYear();
      const currentMonth = String(localCurrentDate.getMonth() + 1).padStart(2, '0');
      const currentDay = String(localCurrentDate.getDate()).padStart(2, '0');
      this.filter.to_date = currentYear + '-' + currentMonth + '-' + currentDay;

      // get first day of last month in yyyy-mm-dd format
      var fromDate = new Date(localCurrentDate.setMonth(localCurrentDate.getMonth()-3));
      fromDate = new Date(fromDate.setDate(1));
      const fromYear = fromDate.getFullYear();
      const fromMonth = String(fromDate.getMonth() + 1).padStart(2, '0');
      const fromDay = String(fromDate.getDate()).padStart(2, '0');
      this.filter.from_date = fromYear + '-' + fromMonth + '-' + fromDay;
    },
    fuseData() {
        this.fuseSearch = new Fuse(this.tableData, {
        keys: this.propsToSearch,
        // threshold: 0.3
        threshold: 0
        });
    },
    paginationChanged(page) {
        this.pagination.currentPage = page
    },
    getAuditLogSafetyInspection() {
      axios.get(apiDomain + 'audit_log/audit_log_safety_inspection', { headers: getHeader() })
        .then ( response => {
        if (response.status === 200)
        {
            this.tableData = response.data.data;
            this.tableData.forEach((e)=>{
                let utcDate = parseDateToUTC(e.audit_changed_date);
                const changedDate = new Date(utcDate);
                const convertedDate = changedDate.toLocaleString('en-US', options);

                const [month, day, year] = convertedDate.split(',')[0].split('/');
                const time = convertedDate.split(', ')[1];
                // Format the date as YYYY-MM-DD
                const formattedDate = `${year}-${month}-${day} ${time}`;

                e.audit_changed_date = formattedDate;
            });
            this.fuseData();
            // this.getUniqueTable();
            this.getUniqueUser();
        }
        })
        .catch ( error => {
          console.log(error);
          // this.errorFunction(error, "Audit Log Safety Inspection Listing")
        });
    },
    searchAuditLogSafetyInspection() {
      const tokenData = JSON.parse(window.localStorage.getItem("authUser"));
      const headers = {
        Authorization: "Bearer " + tokenData.access_token,
      };

      axios.post(apiDomain + "audit_log/audit_log_safety_inspection_search", this.filter, { headers })
        .then ( response => {
        if (response.status === 200)
        {
          this.tableData = response.data.data;
          this.fuseData();
        }
        })
        .catch ( error => {
          this.errorFunction(error, "Search Audit Log Safety Inspection Listing")
        });
    },
    getUniqueTable() {
      this.uniqueTable = this.tableData
        .map(item => item.audit_table)
        .filter((value, index, self) => self.indexOf(value) === index);
    },
    getUniqueUser() {
      let tempUsers = [];
      // put users for every row of data into tempUsers
      this.tableData.forEach((e) => {
        if (e['action_done_by'] != null) {
          tempUsers.push(e['action_done_by']);
        }
      });
      tempUsers = tempUsers.filter((obj, index, self) =>
        obj.hasOwnProperty('name') && index === self.findIndex((o) =>
          o.hasOwnProperty('name') && o['name'] === obj['name']
        )
      );
      this.uniqueUser = tempUsers;
      // sort by name alphabetically
      this.uniqueUser.sort((a, b) => (a.name > b.name) ? 1 : -1);
    },
  }
};
</script>
<style>
  .no-border-card .card-footer{
    border-top: 0;
  }
</style>
