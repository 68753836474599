<template>
    <b-card no-body class="bg-default shadow">
        <b-card-header class="bg-transparent border-0">
            <h3 class="mb-0 text-white">Current Sites Listing in (TRTP-2398 - Tengeh Reservoir Tender Project)</h3>
            <div class="text-right">
              <router-link to="/taskNew">
                <base-button size="sm" type="neutral">Create New Task</base-button>
              </router-link>
              &nbsp;
              <router-link to="/siteNew">
                <base-button size="sm" type="neutral">Create New Site</base-button>
              </router-link>
            </div>
        </b-card-header>

        <el-table class="table-responsive table-dark"
                  header-row-class-name="thead-dark"
                  :data="sites">
            <el-table-column label="Code"
                             min-width="90px"
                             prop="name"
                             sortable>
                <template v-slot="{row}">
                    <b-media no-body class="align-items-center">
                        <b-media-body>
                            <span class="font-weight-600 name mb-0 text-sm text-white">{{row.code}}</span>
                        </b-media-body>
                    </b-media>
                </template>
            </el-table-column>

            <el-table-column label="Site Name"
                             min-width="190px"
                             prop="name"
                             sortable>
                <template v-slot="{row}">
                    <b-media no-body class="align-items-center">
                        <b-media-body>
                            <span class="font-weight-600 name mb-0 text-sm text-white">{{row.title}}</span>
                        </b-media-body>
                    </b-media>
                </template>
            </el-table-column>

            <el-table-column label="Status"
                             min-width="110px"
                             prop="status"
                             sortable>
                <template v-slot="{row}">
                    <badge class="badge-dot mr-4" type="">
                        <i :class="`bg-${row.statusType}`"></i>
                        <span class="status text-white">{{row.status}}</span>
                    </badge>
                </template>
            </el-table-column>

            <el-table-column label="At Milestone" min-width="130px">
                <template v-slot="{row}">
                    <b-media no-body class="align-items-center">

                        <b-media-body>
                            <span class="font-weight-600 name mb-0 text-sm text-white">{{row.milestone}}</span>
                        </b-media-body>
                    </b-media>
                </template>
            </el-table-column>

            <el-table-column label="Progress"
                             prop="completion"
                             min-width="160px"
                             sortable>
                <template v-slot="{row}">
                    <div class="d-flex align-items-center">
                        <span class="completion mr-0 text-white">{{row.completion}}%</span>
                        <div>
                            <base-progress :type="row.statusType" :value="row.completion"/>
                        </div>
                    </div>
                </template>
            </el-table-column>

            <el-table-column label="Action" min-width="150px">
               <template v-slot="">
                    <div class="d-flex align-items-center">
                        <div>
                          <router-link to="/siteDashboard">
                            <base-button size="sm" type="primary">Site Dashboard</base-button>
                          </router-link>
                          &nbsp;
                          <router-link to="/siteEdit">
                            <base-button size="sm" type="primary">Edit</base-button>
                          </router-link>
                        </div>
                    </div>
                </template>
            </el-table-column>
            <!-- <el-table-column min-width="180px">
                <template v-slot="">
                    <el-dropdown trigger="click" class="dropdown">
                    <span class="btn btn-sm btn-icon-only text-light">
                      <i class="fas fa-ellipsis-v mt-2"></i>
                    </span>
                        <el-dropdown-menu class="dropdown-menu dropdown-menu-arrow show" slot="dropdown">
                            <b-dropdown-item>Action</b-dropdown-item>
                            <b-dropdown-item>Another action</b-dropdown-item>
                            <b-dropdown-item>Something else here</b-dropdown-item>
                        </el-dropdown-menu>
                    </el-dropdown>
                </template>
            </el-table-column> -->
        </el-table>

        <b-card-footer class="py-2 d-flex justify-content-end bg-transparent">
            <base-pagination v-model="currentPage" :per-page="10" :total="50"></base-pagination>
        </b-card-footer>
    </b-card>
</template>

<script>
  import sites from './js/sites.js'
  import { Table, TableColumn, DropdownMenu, DropdownItem, Dropdown} from 'element-ui'
  import {homeLink} from '@/assets/js/config.js'

  export default {
    components: {
      [Table.name]: Table,
      [TableColumn.name]: TableColumn,
      [Dropdown.name]: Dropdown,
      [DropdownItem.name]: DropdownItem,
      [DropdownMenu.name]: DropdownMenu,
    },
    data() {
      return {
        sites,
        currentPage: 1,
        img1: homeLink+'/img/theme/team-1.jpg',
      };
    }
  }
</script>
