
export default [
  {
    code: 'SN-NT',
    title: 'Tengeh Reservoir North Site',
    budget: '$2500 USD',
    status: 'pending',
    statusType: 'warning',
    milestone: 'Design',
    completion: 34
  },
  {
    code: 'SN-SH',
    title: 'Tengeh Reservoir South Site',
    budget: '$1800 USD',
    status: 'completed',
    statusType: 'success',
    milestone: 'Inspection',
    completion: 100
  },
  {
    code: 'SN-ET',
    title: 'Tengeh Reservoir East Site',
    budget: '$3150 USD',
    status: 'delayed',
    statusType: 'danger',
    milestone: 'Kick-Off',
    completion: 72
  },
  {
    code: 'SN-WT',
    title: 'Tengeh Reservoir West Site',
    budget: '$4400 USD',
    status: 'on schedule',
    statusType: 'info',
    milestone: 'Installation',
    completion: 90
  },
  {
    code: 'SN-01',
    title: 'Tengeh Reservoir 01',
    budget: '$4400 USD',
    status: 'completed',
    statusType: 'success',
    milestone: 'Inspection',
    completion: 100
  },
  {
    code: 'SN-02',
    title: 'Tengeh Reservoir 02',
    budget: '$4400 USD',
    status: 'pending',
    statusType: 'warning',
    milestone: 'Design',
    completion: 10
  },
  {
    code: 'SN-03',
    title: 'Tengeh Reservoir 03',
    budget: '$4400 USD',
    status: 'on schedule',
    statusType: 'info',
    milestone: 'Installation',
    completion: 80
  },
  {
    code: 'SN-04',
    title: 'Tengeh Reservoir 04',
    budget: '$4400 USD',
    status: 'pending',
    statusType: 'warning',
    milestone: 'Design',
    completion: 20
  },
  {
    code: 'SN-05',
    title: 'Tengeh Reservoir 05',
    budget: '$4400 USD',
    status: 'on schedule',
    statusType: 'info',
    milestone: 'Installation',
    completion: 70
  },


]
