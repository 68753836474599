<template>
  <card>
    <!-- Card header -->
    <h3 slot="header" class="mb-0">Edit Contractor for ({{ model.contractor_code }})</h3>
    <div class="text-right">
    </div>
    <form class="needs-validation"
          @submit.prevent="submit">

      <div class="form-row">
        <b-col md="4">
          <base-input label="Contractor Code"
                      name="Contractor Code"
                      placeholder="Contractor Code"
                      v-model="model.contractor_code" required>
          </base-input>
        </b-col>

        <b-col md="8">
          <base-input label="Contractor Name"
                      name="Contractor Name"
                      placeholder="Contractor Name"
                      v-model="model.contractor_name" required>
          </base-input>
        </b-col>
      </div>

      <div class="form-row">
        <b-col md="4">
          <base-input label="Contact Person"
                      name="Contact Person"
                      placeholder="Contact Person"
                      v-model="model.contractor_contact_person" required>
          </base-input>
        </b-col>

        <b-col md="4">
          <base-input label="Contact Number"
                      name="Contact Number"
                      placeholder="Contact Number"
                      v-model="model.contractor_contact_number" required>
          </base-input>
        </b-col>

        <b-col md="4">
          <base-input label="Email"
                      name="Email"
                      placeholder="Email"
                      v-model="model.contractor_contact_email" required>
          </base-input>
        </b-col>
      </div>

      <div class="form-row">
        <b-col md="4">
          <b-form-checkbox
            id="Active-Status"
            v-model="model.active_status"
            name="Active-Status"
            value="1"
            unchecked-value="0">
            <b>Active Status</b>
          </b-form-checkbox>
        </b-col>
      </div>
      <br>

      <base-button type="primary" @click="createData()" v-if="button_access.edit_button == 'YES'">Update Contractor</base-button>
      <base-button type="primary" @click="$router.back()">Cancel</base-button>
      <!-- <div>
        <br>
        <h5 class="text-danger"> Note : <br><br>
                <i>
                1. By Default all Date is set to "TODAY.
                </i>
                <br>
                <i>
                2. Contractor Progress % is set to "0 %" By Default.
                </i>
        </h5>
      </div> -->
    </form>
  </card>
</template>
<script>
  import BaseSlider from '@/components/BaseSlider'
  import {LoginURL, clientId, clientSecret, apiDomain, getHeader, getcurrentDate} from '@/assets/js/config.js'
  import axios from 'axios'
  import ErrorFunctionMixin from '@/assets/js/errorFunctionMix.js'

  export default {
    mixins: [ErrorFunctionMixin],
    components: {
      BaseSlider
    },
    created()
    {
      var currentDate = getcurrentDate()
      var contractor_access = JSON.parse(window.localStorage.getItem('contractor_access'))
      this.button_access.edit_button = contractor_access.access_edit

      this.model.contractor_id = this.$route.params.id
      axios.get(apiDomain + 'setup/contractor/'+this.model.contractor_id, { headers: getHeader() })
      .then ( response => {
      if (response.status === 200)
      {
          this.model.contractor_code = response.data.data[0].contractor_code
          this.model.contractor_name = response.data.data[0].contractor_name
          this.model.contractor_contact_person = response.data.data[0].contractor_contact_person
          this.model.contractor_contact_number = response.data.data[0].contractor_contact_number
          this.model.contractor_contact_email = response.data.data[0].contractor_contact_email
          if ( response.data.data[0].active_status == 'ACTIVE' )
          {
            this.model.active_status = 1
          }
          else
          {
            this.model.active_status = 0
          }
      }
      })
      .catch ( error => {
          console.log(error.response.status)
          this.errorFunction(error, "Contractor Information")
      });
    },
    data() {
      return {
        model: {
          contractor_id: null,
          contractor_code: null,
          contractor_name: null,
          contractor_contact_person: null,
          contractor_contact_number: null,
          contractor_contact_email: null,
          active_status: null,
        },
        button_access: {
          edit_button: null,
        },
      }
    },
    methods: {
      createData(){
        if ( !this.model.contractor_code || !this.model.contractor_name || !this.model.contractor_contact_person
        || !this.model.contractor_contact_number || !this.model.contractor_contact_email )
        {
          if ( !this.model.contractor_code )
          {
            this.redErrorNotify( '<b>Invalid Input : Contractor Code is a Mandatory Column in Contractor.</b>')
          }
          else if ( !this.model.contractor_name  )
          {
            this.redErrorNotify( '<b>Invalid Input : Developer Name is a Mandatory Column in Contractor.</b>')
          }
          else if ( !this.model.contractor_contact_person  )
          {
            this.redErrorNotify( '<b>Invalid Input : Contact Person is a Mandatory Column in Contractor.</b>')
          }
          else if ( !this.model.contractor_contact_number  )
          {
            this.redErrorNotify( '<b>Invalid Input : Contact Number is a Mandatory Column in Contractor.</b>')
          }
          else if ( !this.model.contractor_contact_email  )
          {
            this.redErrorNotify( '<b>Invalid Input : Email is a Mandatory Column in Contractor.</b>')
          }
          else
          {
            this.redErrorNotify( '<b>Invalid Input : Please fill in all the Mandatory Column in Task </b>' )
          }
        }
        else
        {
          axios.put(apiDomain + 'setup/contractor/'+this.model.contractor_id, this.model, { headers: getHeader() })
          .then ( response => {
            if (response.status === 200)
            {
                this.$notify({
                message:
                  '<b>Update Contractor : ' + this.model.contractor_code + '</b> - Successfully Updated.',
                timeout: 10000,
                icon: 'ni ni-bell-55',
                type: 'default',
                });
                this.$router.back()
            }
          })
          .catch ( error => {
            this.errorFunction(error, "Update Contractor")
          });
        }
      },
    }
  }
</script>
<style>
</style>
