<template>
  <card>
    <!-- Card header -->
      <h3 slot="header" class="mb-3.5">
        Project Information : {{ model.project_name }}
      </h3>
      <div class="text-right">
        <router-link :to="'/projectEdit/' + model.project_id">
          <base-button
            size="sm"
            type="primary"
            v-if="
              button_access.edit_button == 'YES' && ( myUserID.email == model.project_manager.email || myUserID.role_id == 3 )
            "
          >
            Edit</base-button
          >
        </router-link>
      </div>
    </div>
    <br />
    <form v-if="!isSolarNovaProject" class="needs-validation" @submit.prevent="submit">
      <div class="form-row">
        <b-col md="8">
          <base-input
            label="Project Name"
            name="Project Name"
            placeholder="Project Name"
            v-model="model.project_name"
            disabled
          >
          </base-input>
        </b-col>

        <b-col md="4">
          <base-input
            label="Country"
            name="Country"
            v-model="model.project_country"
            disabled
          >
          </base-input>
        </b-col>
      </div>

      <div class="form-row">
        <b-col md="4">
          <base-input
            label="Project Manager"
            name="Project Manager"
            v-model="model.project_manager.name"
            disabled
          >
          </base-input>
        </b-col>
        <b-col md="4">
          <base-input
            label="Engineer"
            name="Engineer"
            v-model="model.project_engineer.name"
            disabled
          >
          </base-input>
        </b-col>
        <b-col md="4">
          <base-input
            label="Safety Officer"
            name="Safety Officer"
            v-model="model.project_safety.name"
            disabled
          >
          </base-input>
        </b-col>
      </div>

      <div class="form-row">
        <b-col md="3">
          <base-input
            label="PPA Approved Capacity (kWp)"
            name="PPA Approved Capacity (kWp)"
            v-model="model.project_ppa_approved_capacity"
            disabled
          >
          </base-input>
        </b-col>

        <!-- <b-col md="3">
          <base-input
            label="Project PV Capacity (kWP)"
            name="Project PV Capacity (kWP)"
            v-model="model.project_pv_capacity"
            disabled
          >
          </base-input>
        </b-col> -->
        
        <b-col md="3">
          <base-input
            label="Project PV Capacity (kWP)"
            name="Project PV Capacity (kWP)"
            v-model="model.project_total_capacity"
            disabled
          >
          </base-input>
        </b-col>

        <b-col md="3">
          <base-input
            label="Project Type"
            name="Project Type"
            v-model="model.project_type"
            disabled
          >
          </base-input>
        </b-col>

        <b-col md="3">
          <base-input
            label="Project Category"
            name="Project Category"
            v-model="model.project_category"
            disabled
          >
          </base-input>
        </b-col>
      </div>

      <div class="form-row">
        <b-col md="4">
          <base-input
            label="Project Developer"
            name="Project Developer"
            v-model="model.developer.developer_name"
            disabled
          >
          </base-input>
        </b-col>
        <b-col md="4">
          <base-input
            label="Status"
            name="Status"
            v-model="model.status.status_code"
            disabled
          >
          </base-input>
        </b-col>
        <b-col md="4">
          <base-input
            label="Progress %"
            name="Progress"
            placeholder="Progress"
            v-model="model.project_progress"
            disabled
          >
          </base-input>
        </b-col>
      </div>

      <div class="form-row">
        <b-col md="6">
          <base-input
            label="EPC Award Date"
            name="EPC Award Date"
            type="date"
            v-model="model.project_start_date"
            disabled
          >
          </base-input>
        </b-col>
        <b-col md="6">
          <base-input
            label="EPC Scheduled PAC Date"
            name="EPC Scheduled PAC Date"
            type="date"
            v-model="model.project_end_date"
            disabled
          >
          </base-input>
        </b-col>
      </div>

      <div class="form-row">
        <b-col  md="6">
          <base-input
            label="PPA Start Date"
            name="PPAStart Date"
            type="date"
            v-model="model.project_ppa_start_date"
            disabled
          >
          </base-input>
        </b-col>
        <b-col md="6">
          <base-input
            label="PPA Contractual COD"
            name="PPA Contractual COD"
            type="date"
            v-model="model.project_ppa_contractual_cod_date"
            disabled
          >
          </base-input>
        </b-col>
      </div>

      <div class="form-row">
        <b-col  md="6">
        </b-col>
        <b-col md="6">
          <base-input
            label="Site Handover Date"
            name="Site Handover Date"
            type="date"
            v-model="model.project_site_handover_date"
            disabled
            >
          </base-input>
        </b-col>
      </div>
    </form>
    
    <form v-else class="needs-validation" @submit.prevent="submit">
      <div class="form-row">
        <b-col md="8">
          <base-input
            label="Project Name"
            name="Project Name"
            placeholder="Project Name"
            v-model="model.project_name"
            disabled
          >
          </base-input>
        </b-col>

        <b-col md="4">
          <base-input
            label="Country"
            name="Country"
            v-model="model.project_country"
            disabled
          >
          </base-input>
        </b-col>
      </div>

      <div class="form-row">
        <b-col md="12">
          <base-input
            label="Project Manager"
            name="Project Manager"
            v-model="model.project_manager.name"
            disabled
          >
          </base-input>
        </b-col>
        <!-- <b-col md="4">
          <base-input
            label="Engineer"
            name="Engineer"
            v-model="model.project_engineer.name"
            disabled
          >
          </base-input>
        </b-col> -->
        <!-- <b-col md="4">
          <base-input
            label="Safety Officer"
            name="Safety Officer"
            v-model="model.project_safety.name"
            disabled
          >
          </base-input>
        </b-col> -->
      </div>

      <div class="form-row">
        <!-- <b-col md="4">
          <base-input
            label="Project PV Capacity (kWP)"
            name="Project PV Capacity (kWP)"
            v-model="model.project_pv_capacity"
            disabled
          >
          </base-input>
        </b-col> -->

        <b-col md="4">
          <base-input
            label="Project PV Capacity (kWP)"
            name="Project PV Capacity (kWP)"
            v-model="model.project_total_capacity"
            disabled
          >
          </base-input>
        </b-col>

        <b-col md="4">
          <base-input
            label="Project Type"
            name="Project Type"
            v-model="model.project_type"
            disabled
          >
          </base-input>
        </b-col>

        <b-col md="4">
          <base-input
            label="Project Category"
            name="Project Category"
            v-model="model.project_category"
            disabled
          >
          </base-input>
        </b-col>
      </div>

      <div class="form-row">
        <b-col md="4">
          <base-input
            label="Project Developer"
            name="Project Developer"
            v-model="model.developer.developer_name"
            disabled
          >
          </base-input>
        </b-col>
        <b-col md="4">
          <base-input
            label="Status"
            name="Status"
            v-model="model.status.status_code"
            disabled
          >
          </base-input>
        </b-col>
        <b-col md="4">
          <base-input
            label="Progress %"
            name="Progress"
            placeholder="Progress"
            v-model="model.project_progress"
            disabled
          >
          </base-input>
        </b-col>
      </div>

      <div class="form-row">
        <!-- <b-col md="6">
          <base-input
            label="EPC Award Date"
            name="EPC Award Date"
            type="date"
            v-model="model.project_start_date"
            disabled
          >
          </base-input>
        </b-col> -->
        <!-- <b-col md="6">
          <base-input
            label="EPC Scheduled PAC Date"
            name="EPC Scheduled PAC Date"
            type="date"
            v-model="model.project_end_date"
            disabled
          >
          </base-input>
        </b-col> -->
      </div>

      <div class="form-row">
        <!-- <b-col  md="6">
          <base-input
            label="PPA Start Date"
            name="PPAStart Date"
            type="date"
            v-model="model.project_ppa_start_date"
            disabled
          >
          </base-input>
        </b-col> -->
        <!-- <b-col md="6">
          <base-input
            label="PPA Contractual COD"
            name="PPA Contractual COD"
            type="date"
            v-model="model.project_ppa_contractual_cod_date"
            disabled
          >
          </base-input>
        </b-col> -->
      </div>

      <div class="form-row">
        <b-col  md="4">
        </b-col>
        <b-col  md="4">
        </b-col>
        <b-col md="4">
          <base-input
            label="Site Handover Date"
            name="Site Handover Date"
            type="date"
            v-model="model.project_site_handover_date"
            disabled
            >
          </base-input>
        </b-col>
      </div>
    </form>
  </card>
</template>
<script>
import BaseSlider from "@/components/BaseSlider";
import {
  LoginURL,
  clientId,
  clientSecret,
  apiDomain,
  getHeader,
  getcurrentDate,
} from "@/assets/js/config.js";
import axios from "axios";
export default {
  components: {
    BaseSlider,
  },
  created() {
    this.model.project_id = this.$route.params.id;

    var project_access = JSON.parse(
      window.localStorage.getItem("project_access")
    );
    this.button_access.edit_button = project_access.access_edit;

    const LoginUser = JSON.parse(window.localStorage.getItem("loginUser"));
    this.myUserID = LoginUser;

    axios
      .get(apiDomain + "project/project/" + this.model.project_id, {
        headers: getHeader(),
      })
      .then((response) => {
        if (response.status === 200) {
          this.model.project_code = response.data.data[0].project_code;
          this.model.project_name = response.data.data[0].project_name;
          this.model.project_country = response.data.data[0].project_country;
          this.model.project_manager.name =
            response.data.data[0].project_manager.name;
          this.model.project_manager.email =
            response.data.data[0].project_manager.email;
          this.model.project_engineer.name =
            response.data.data[0].project_engineer.name;
          this.model.project_safety.name =
            response.data.data[0].project_safety.name;
          this.model.project_type =
            response.data.data[0].project_type.project_type;
          this.model.project_pv_capacity =
            response.data.data[0].project_pv_capacity;
          this.model.project_total_capacity =
              response.data.data[0].project_total_capacity;
          this.model.project_category =
            response.data.data[0].project_category.project_category;
          this.model.developer.developer_name =
            response.data.data[0].developer.developer_name;
          this.model.status.status_code =
            response.data.data[0].status.status_code;
          this.model.project_start_date =
            response.data.data[0].project_start_date;
          this.model.project_end_date = response.data.data[0].project_end_date;
          this.model.project_ppa_start_date =
            response.data.data[0].project_ppa_start_date;
          this.model.project_ppa_contractual_cod_date =
            response.data.data[0].project_ppa_contractual_cod_date;
          this.model.project_site_handover_date =
            response.data.data[0].project_site_handover_date;
          this.model.project_progress = response.data.data[0].project_progress;
          this.model.project_type_id =
            response.data.data[0].project_type.project_type_id;
          this.model.project_ppa_approved_capacity =
            response.data.data[0].project_ppa_approved_capacity;
          this.checkIfSolarNovaProject();
        }
      })
      .catch((error) => {
        console.log(error.response.status);
      });
  },
  data() {
    return {
      isSolarNovaProject: false,
      myUserID: null,
      model: {
        project_id: null,
        project_code: null,
        project_name: null,
        project_country: null,
        project_start_date: null,
        project_pv_capacity: 0,
        project_total_capacity: 0,
        project_end_date: null,
        project_ppa_start_date: null,
        project_ppa_contractual_cod_date: null,
        project_site_handover_date: null,
        project_progress: 0,
        project_type: null,
        project_category: null,
        status: {
          status_id: null,
          status_code: null,
        },
        project_manager: {
          name: null,
          email: null,
        },
        project_engineer: {
          name: null,
          email: null,
        },
        project_safety: {
          name: null,
          email: null,
        },
        developer: {
          developer_name: null,
        },
        project_type_id: null,
        project_ppa_approved_capacity: 0,
      },
      button_access: {
        edit_button: null,
      },
    };
  },
  methods: {
    checkIfSolarNovaProject() {
      this.tempChecker = "entered";
      if (
        this.model.project_type_id != null &&
        // (this.model.project_type_id == 2 ||
        //   this.model.project_type_id == 3 ||
        //   this.model.project_type_id == 4)
        ([2, 3, 4, 9, 10, 11, 12, 13, 14].includes(this.model.project_type_id))
      ) {
        this.isSolarNovaProject = true;
      }
    },
    async submit() {
      this.$router.push("project");
    },
    notifyVue(type = "default") {
      // console.log(type)
      this.$notify({
        message:
          "<b>Update Project : (" +
          this.model.project_code +
          " - " +
          this.model.project_name +
          ")</b> - Successfully Updated.",
        timeout: 10000,
        icon: "ni ni-bell-55",
        type,
      });
    },
  },
};
</script>
<style>
</style>
