<template>
  <div class="bg-default-dashboard" >
    <div class=" py-lg-8 pt-lg-5">
      <b-container fluid style="width: 90%;" >
        <!-- <div class="header-body "  > -->
        <b-row class="mb-2">
          <b-col>
            <h1 class="text-white">Utility Scale Project List</h1>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="3" class="mt-1">
            <base-input >
              <select v-model="selectedFilter" class="form-control" @change="generateData(selectedFilter)">
                <option v-for="item in filterOption" :key="item.value" :value="item.value">Rows per page : {{ item.label }}</option>
              </select>
            </base-input>
          </b-col>
          <b-col md="6" xs="12" sm="12" class="mt-1">
            <!-- <b-form class="navbar-search navbar-search-dark"> -->
            <b-form-group class="mb-0">
              <b-input-group class="input-group-alternative input-group-merge">
                <div class="input-group-prepend">
                  <span class="input-group-text"><i class="fas fa-search"></i></span>
                </div>
                <b-form-input placeholder="Search...." type="text"> </b-form-input>
              </b-input-group>
            </b-form-group>

            <!-- </b-form> -->
          </b-col>
          <b-col md="3" sm="6" class="mt-1">
            <b-link class="btn btn-dark " style="width: 35%;">C&I</b-link>
            <b-link class="btn btn-dark" style="width: 60%;">Solarnova</b-link>
          </b-col>
        </b-row>
        <!-- {{ data }} -->
        <b-row >
          <b-col
                  v-for="raw in tableData"
                  :key="raw.project_id"
                  class="mt-1"  md="6" xs="12" >
            <card class="bg-card-dashboard" >
              <b-row class="" align-v="center" >
                <b-col cols="8">
                  <h3 class="mb-0 text-white">{{ raw.project_name }} </h3>
                </b-col>
                <b-col cols="4" class="text-right">
                  <a v-if="raw.status.status_id == '8'"  class="btn btn-sm btn-warning rounded-pill text-default"> <i class="fa fa-xs fa-circle mr-1"></i>In Progress</a>
                  <a v-if="raw.status.status_id != '8'"  class="btn btn-sm btn-light rounded-pill text-white"> <i class="fa fa-xs fa-circle mr-1"></i>Turn On</a>
                </b-col>
              </b-row>
              <b-row class="mt-2">
                <b-col>
                    <span class="text-white">
                      <svg  width="18" height="18" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M12 14V16C8.68629 16 6 18.6863 6 22H4C4 17.5817 7.58172 14 12 14ZM12 13C8.685 13 6 10.315 6 7C6 3.685 8.685 1 12 1C15.315 1 18 3.685 18 7C18 10.315 15.315 13 12 13ZM12 11C14.21 11 16 9.21 16 7C16 4.79 14.21 3 12 3C9.79 3 8 4.79 8 7C8 9.21 9.79 11 12 11ZM14.5946 18.8115C14.5327 18.5511 14.5 18.2794 14.5 18C14.5 17.7207 14.5327 17.449 14.5945 17.1886L13.6029 16.6161L14.6029 14.884L15.5952 15.4569C15.9883 15.0851 16.4676 14.8034 17 14.6449V13.5H19V14.6449C19.5324 14.8034 20.0116 15.0851 20.4047 15.4569L21.3971 14.8839L22.3972 16.616L21.4055 17.1885C21.4673 17.449 21.5 17.7207 21.5 18C21.5 18.2793 21.4673 18.551 21.4055 18.8114L22.3972 19.3839L21.3972 21.116L20.4048 20.543C20.0117 20.9149 19.5325 21.1966 19.0001 21.355V22.5H17.0001V21.3551C16.4677 21.1967 15.9884 20.915 15.5953 20.5431L14.603 21.1161L13.6029 19.384L14.5946 18.8115ZM18 19.5C18.8284 19.5 19.5 18.8284 19.5 18C19.5 17.1716 18.8284 16.5 18 16.5C17.1716 16.5 16.5 17.1716 16.5 18C16.5 18.8284 17.1716 19.5 18 19.5Z" fill="#D4D4D5"/>
                      </svg>
                      <small class="ml-1">{{ raw.developer.developer_code }}</small></span>
                </b-col>
                <b-col class="text-right">
                    <span class="text-white ">
                      <svg width="18" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M9 16V6H2V16H9ZM9 4V1C9 0.44772 9.4477 0 10 0H19C19.5523 0 20 0.44772 20 1V17C20 17.5523 19.5523 18 19 18H1C0.44772 18 0 17.5523 0 17V5C0 4.44772 0.44772 4 1 4H9ZM11 2V16H18V2H11ZM3 13H8V15H3V13ZM12 13H17V15H12V13ZM12 10H17V12H12V10ZM12 7H17V9H12V7ZM3 10H8V12H3V10Z" fill="#D4D4D5"/>
                      </svg>
                      <small class="ml-2">PPA Signed Capacity: <strong>{{ raw.project_ppa_approved_capacity }}</strong></small></span>
                </b-col>
              </b-row>
              <hr class="line-custom">
              <b-row>
                <b-col>
                  <b-link :to="`/projectUtilitiesDashboard/${raw.project_id}`" class="btn btn-sm btn-secondary rounded-pill text-white" target="_blank">
                    <div class="icon-dashboard-cs"></div>
                    <small class="small-button-cs mr-2">Dashboard</small>
                  </b-link>
                </b-col>
                <b-col class="text-right">
                  <b-link :to="`/ProjectDocumentDetail/${raw.project_id}`" class="btn btn-sm btn-secondary rounded-pill text-white" target="_blank">
                    <div class="icon-file-line-cs ml-1 mr-1"></div>
                  </b-link>
                  <!-- <b-link class="btn btn-sm btn-secondary rounded-pill text-white" >
                    <div class="icon-box-line-cs ml-1 mr-1"></div>
                  </b-link> -->
                  <b-link :to="`/projectUtilitiesEdit/${raw.project_id}`" class="btn btn-sm btn-secondary rounded-pill text-white" target="_blank">
                    <div class="icon-pencil-cs ml-1 mr-1"></div>
                  </b-link>

                </b-col>
              </b-row>

            </card>
          </b-col>


        </b-row>

        <!-- </div> -->
      </b-container>

    </div>


  </div>
</template>
<script>
    import { Table, TableColumn, Select, Option } from "element-ui";
    import RouteBreadCrumb from "@/components/Breadcrumb/RouteBreadcrumb";
    import { BasePagination } from "@/components";
    import standardPaginationMix from "@/assets/js/standardPaginationMix.js";
    import Fuse from "fuse.js";
    import axios from "axios";
    //import { Select, Option } from 'element-ui'
    import {
        LoginURL,
        clientId,
        clientSecret,
        apiDomain,
        getHeader,
    } from "@/assets/js/config.js";
    import ErrorFunctionMixin from "@/assets/js/errorFunctionMix.js";


    export default {
        mixins: [standardPaginationMix, ErrorFunctionMixin],
        components: {
            BasePagination,
            RouteBreadCrumb,
            [Select.name]: Select,
            [Option.name]: Option,
            [Table.name]: Table,
            [TableColumn.name]: TableColumn,
        },
        name :'index',
        components: {
            [Select.name]: Select,
            [Option.name]: Option,
        },
        created()
        {
            document.title = 'MOMENS-Utility Scale Projects';
            const LoginUser = JSON.parse(window.localStorage.getItem("loginUser"));
            this.myUserID = LoginUser;

            // check if user is a project manager
            if (this.myUserID.role_id == 9) {
                // check if user is group leader
                axios
                    .get(apiDomain + 'setup/project_engineer_group', { headers: getHeader() })
                    .then (response =>
                    {
                        if (response.status === 200)
                        {
                            let group_listing_response = response.data.data;
                            group_listing_response.forEach((e) => {
                                if (e['engineer_group_leader']['id'] == this.myUserID.id)
                                {
                                    this.groupId.push(e['engineer_group_id']);
                                }
                            });
                            if (this.groupId != [])
                            {
                                this.groupMembers.push(this.myUserID.id);
                                this.groupId.forEach((e_inner) =>
                                {
                                    axios
                                        .get(apiDomain + 'setup/project_engineer_group.show_by_group/' + e_inner, { headers: getHeader() })
                                        .then ( response => {
                                            if (response.status === 200)
                                            {
                                                let group_member_response = response.data.data;
                                                group_member_response.forEach((e_member) => {
                                                    let checkExist = this.groupMembers.includes(e_member['engineer_group_member']['id']);
                                                    if (!checkExist) {
                                                        this.groupMembers.push(e_member['engineer_group_member']['id']);
                                                    }
                                                });
                                            }
                                        })
                                        .catch ( error => {
                                            console.log(error.response.status)
                                            this.errorFunction(error, "Project Engineer Group Member")
                                        });
                                });
                            }
                            else
                            {
                                this.groupMembers.push(this.myUserID.id);
                            }
                        }
                    })
                    .catch ( error => {
                        this.errorFunction(error, "Project Engineer Groups Listing")
                    });
            }
            axios
                .get(apiDomain + "user_access/role_access.project_access", {
                    headers: getHeader(),
                })
                .then((response) => {
                    if (response.status === 200) {
                        this.button_access.new_button =
                            response.data.data.project_modules[0].access_create;
                        this.button_access.project_view =
                            response.data.data.project_modules[0].access_show;
                        this.button_access.milestone_listing =
                            response.data.data.project_modules[2].access_listing;
                        this.getprojectListing();
                    }
                })
                .catch((error) => {
                    console.log(error.response.status);
                    this.errorFunction(error, "Project Information");
                });
        },
        data() {
            return {
                propsToSearch: [
                    "project_name",
                    "developer.developer_code",
                    "project_manager.name"
                ],
                tableColumns: [],
                tableData: [],
                selectedRows: [],
                myUserID: null,
                button_access: {
                    new_button: null,
                    project_view: null,
                    milestone_listing: null,
                },
                groupId: [],
                groupMembers: [],
            };
        },

        methods: {
            getprojectListing()
            {
                axios
                    .get(apiDomain + "project/project", { headers: getHeader() })
                    .then((response) => {
                        if (response.status === 200) {
                            console.log(response.data.data);
                            let data = response.data.data;
                            data.forEach(item => {
                                if(item.project_ppa_start_date !== null){

                                    const signDate = new Date(item.project_ppa_start_date);
                                    const year = signDate.getFullYear();
                                    const month = signDate.toLocaleString('en-US', { month: 'short' });
                                    const day = signDate.getDate();

                                    item.project_ppa_start_date = `${day}-${month}-${year}`;
                                }else{
                                    item.project_ppa_start_date = "N/A";
                                }

                            });
                            this.tableData = data;
                            this.fuseData();
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                        this.errorFunction(error, "Project Information");
                    });
            },
            fuseData() {
                // this.fuseSearch = new Fuse(this.tableData, {
                //   keys: this.propsToSearch,
                //   threshold: 0.3,
                // });
                this.filterOutCRM();
                this.filterUtilitiesProjects();
                if (this.myUserID.role_id == 9) {
                    this.filterByUser();
                }
                this.fuseSearch = new Fuse(this.tableData, {
                    keys: this.propsToSearch,
                    threshold: 0.3,
                });
            },
            filterOutCRM(){
                var tempTableData = [];
                this.tableData.forEach((e) => {
                    if ((e['project_source_status'] === null || e['project_source_status'] === 'CRM Won')) {
                        tempTableData.push(e);
                    }
                });
                this.tableData = tempTableData;
            },
            filterUtilitiesProjects(){
                var tempTableData = [];
                this.tableData.forEach((e) => {
                    if (e['project_type']['project_type_id'] == 17){
                        tempTableData.push(e);
                    }
                });
                this.tableData = tempTableData;
            },
            filterByUser(){
                var tempTableData = [];
                this.groupMembers.forEach((e) => {
                    this.tableData.forEach((e_inner) => {
                        if (e_inner['project_manager']['id'] == e) {
                            tempTableData.push(e_inner);
                        }
                    });
                });
                this.tableData = tempTableData;
            },
            paginationChanged(page) {
                this.pagination.currentPage = page;
            },
            handleDetails(index, row) {

                const project_data = this.tableData.find(obj => obj.project_id === row.project_id);
                this.$router.push({path: 'projectDashboardV3/'+row.project_id })

            },
            handleMilestone(index, row) {
                this.$router.push({ path: "projectMilestoneListing/" + row.project_id });
            },
            selectionChange(selectedRows) {
                this.selectedRows = selectedRows;
            },
            rowClicked(row) {
                this.$router.push({
                    path: "/projectDashboardV3/" + row.project_id,
                });
            },
        },

    }
</script>
<style scoped>
  /* .form-control {
    background-color: #23454A;
    border-color: #1D4147;
    color: white;
    &:focus {
            background-color: #23454A;
            border-color: #1D4147;
            color: white;
          }
    &::placeholder {
        color: white;
    }
  }
  .input-group-text {
    background-color: #23454A;
    color: white;
  } */
  select {
    background: url("data:image/svg+xml,<svg width='10' height='7' viewBox='0 0 10 7' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M5.02552 6.35057L0.320068 0.46875H9.73098L5.02552 6.35057Z' fill='white'/></svg>") no-repeat;
    background-position: calc(100% - 0.75rem) center !important;
    -moz-appearance:none !important;
    -webkit-appearance: none !important;
    appearance: none !important;
    padding-right: 2rem !important;
  }
  .bg-card-dashboard {
    background-color: #23454A;
  }
  .bg-default-dashboard {
    background-color: #082A2F;
    /* background-size: cover; */
    /* position:fixed; */
    padding:0;
    margin:0;
    top:0;
    left:0;
    width: 100%;
    height: 100%;
  }
  .form-control {
    background-color: #2C545A;
    border-color: #2C545A;
    color: white;
  &:focus {
     background-color: #2C545A;
     border-color: #2C545A;
     color: white;
   }
  &::placeholder {
     color: white;
   }
  }
  .form-control {
  &:focus {
  &::placeholder {
     color: white;
   }
  }
  }
  .input-group-text {
    background-color: #2C545A;;
    color: white;
  &:focus {
     background-color: #2C545A;;
     border-color: #1D4147;
     color: white;
   }
  }
  .focused {
  .input-group-text {
    background-color: #2C545A;
  }
  }
  .navbar-search-dark .input-group {
    background-color: #23454A;
    color: black;
    border-color: rgba(255, 255, 255, 0.6);
  }
  .navbar-search .input-group {
    border-radius: 7px;
  }
  .el-input__inner, .el-select-dropdown__item.is-disabled:hover {
    background-color: #23454A;
  }
  .line-custom {
    background-color: #356168; margin-top: 15px; margin-bottom: 15px;
  }

  /* .el-table th.el-table__cell {

    overflow: hidden;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-color: #23454A;
    color: white;
    padding: 20px;
    border-top: 0px solid #082A2F;
    border-bottom: 0px solid #082A2F;
  }

  .el-table td.el-table__cell, .el-table th.el-table__cell.is-leaf {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    border-bottom: 20px solid #082A2F;
    border-top: 0px solid #082A2F;
  }

  div.el-table .el-table__row {
    background: #3a656b;;
    border-bottom: 0 solid #082A2F;

  }
  div.el-table .el-table__row:hover {
    background: #0d2f33;
  }

  .el-table--border::after, .el-table--group::after, .el-table::before {
    content: '';
    position: absolute;
    background-color: #ebeef500;
    z-index: 1;
  } */

</style>

