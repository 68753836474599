<template>
  <div @click="popUp()">
    <card type="default" header-classes="bg-transparent" class="border border-white;">
      <b-row align-v="center" slot="header">
      &nbsp;&nbsp;<h5 class="h3 mb-0 text-white">CONSOLIDATED OVERDUE PROJECT</h5>
      </b-row>
      <b-row>
        <b-col xl="8">
          <apexchart type="donut" width="100%" height="300px" :options="ChartOptions" :series="series"></apexchart>
        </b-col>
        <b-col xl="4">
          <b-row>
          <div style="color:white; font-size: 12px;"><b>Total Overdue Site</b><br><h1 style="color:white;">56</h1></div>
          </b-row>
          <br>
          <br>
          <b-row>
            <div style="color:white; font-size: 12px;"><b>Highest Overdue : More Than 90 Days</b></div>
          </b-row>
        </b-col>
      </b-row>
    </card>

    <modal :show.sync="modals.formOverdueProject"
      size="xl"
      body-classes="p-0">
      <card type="secondary"
            header-classes="bg-transparent pb-5"
            body-classes="px-lg-5 py-lg-5"
            class="border-0 mb-0">
        <template>
           <H2 class="text-center"><b>Top 5 Overdue CONSOLIDATED Project</b></H2>
        <br>
        <b-row>
           <b-col md="1">
           </b-col>
          <b-col md="2">
            <b-card no-body class="bg-gradient-danger border-0" >
            <b-card-body>
                    <b-card-title title-tag="h5" class="text-uppercase text-muted mb-0 text-white text-center">SN2_680751 with <br><b>16.8 MWp</b></b-card-title>
              </b-card-body>
            </b-card>
          </b-col>
          <b-col md="2">
            <b-card no-body class="bg-gradient-danger border-0" >
            <b-card-body>
                    <b-card-title title-tag="h5" class="text-uppercase text-muted mb-0 text-white text-center">SN3100062 with <br><b>17.4 MWp</b></b-card-title>
              </b-card-body>
            </b-card>
          </b-col>
          <b-col md="2">
            <b-card no-body class="bg-gradient-danger border-0" >
            <b-card-body>
                    <b-card-title title-tag="h5" class="text-uppercase text-muted mb-0 text-white text-center">SN3_100051 with <br><b>11.2 MWp</b></b-card-title>
              </b-card-body>
            </b-card>
          </b-col>
          <b-col md="2">
            <b-card no-body class="bg-gradient-danger border-0" >
            <b-card-body>
                    <b-card-title title-tag="h5" class="text-uppercase text-muted mb-0 text-white text-center">SN3_100057 with <br><b>10.7 MWp</b></b-card-title>
              </b-card-body>
            </b-card>
          </b-col>
          <b-col md="2">
            <b-card no-body class="bg-gradient-danger border-0" >
            <b-card-body>
                    <b-card-title title-tag="h5" class="text-uppercase text-muted mb-0 text-white text-center">SN2_680754 with <br><b>8.7 MWp</b></b-card-title>
              </b-card-body>
            </b-card>
          </b-col>
          <b-col md="1">
           </b-col>
        </b-row>
        <b-form role="form">
          <div class="text-center">
            <base-button type="primary" class="my-4" @click="clearModal()">Close</base-button>
          </div>
        </b-form>
        </template>
      </card>
    </modal>

  </div>
</template>
<script>
  import { BaseProgress } from '@/components';
  import VueApexCharts from 'vue-apexcharts';
  import { Table, TableColumn, DropdownMenu, DropdownItem, Dropdown} from 'element-ui';
  import { Modal } from '@/components';
  import {LoginURL, clientId, clientSecret, apiDomain, getHeader, getcurrentDate} from '@/assets/js/config.js'
  import axios from 'axios';
  export default {

    components: {
      Modal,
      BaseProgress,
      "apexchart": VueApexCharts,
      [Table.name]: Table,
      [TableColumn.name]: TableColumn,
      [Dropdown.name]: Dropdown,
      [DropdownItem.name]: DropdownItem,
      [DropdownMenu.name]: DropdownMenu,
    },
    created()
    {
      var currentDate = getcurrentDate()
      this.getData(0,currentDate)
    },
    data() {
      return {
        modals: {
          formOverdueProject: false,
          formOverdueDocument: false,
        },

      }
    },
    methods:
    {
      getProjectOverdueData(project_type_id,date)
      {
        //axios.get(apiDomain + 'dashboard/over_due_project/' + project_type_id + '/' + date, { headers: getHeader() })
        axios.get(apiDomain + 'dashboard/over_due_project/' + project_type_id + '/' + date, { headers: getHeader() })
        .then ( response => {
          if (response.status === 200)
          {
            var abc = [ response.data.data[0].total_overdue_below_30, response.data.data[0].total_overdue_30_to_60, response.data.data[0].total_overdue_60_to_90, response.data.data[0].total_overdue_above_90 ]
            console.log(response.data.data)

            this.series = abc
            console.log(this.series)
            // this.ChartOptions = {
            //   labels : response.data.data.label
            // }
          }
        })
        .catch ( error => {
          console.log(error.response.status)
          this.errorFunction(error, "Overdue Project")
        } );
      },
      popUp(){
        this.modals.formOverdueProject = true
        console.log('testing')
      },
      clearModal(){
        this.modals.formOverdueProject = false
        this.modals.formOverdueDocument = false
      },
    },
  }
</script>
<style>
</style>
