<template>
  <div class = "project_edit">
    <div class="bg-default-dashboard" >
      <div class=" py-lg-8 pt-lg-5">
        <b-container fluid style="width: 90%; position: relative;">
          <!-- <div class="header-body "  > -->
          <b-row class="mb-3">
            <b-col md="8">
              <span class="text-white display-2 mr-3 " >{{ model.project_name }}</span></b-col>
          </b-row>
          <b-row class="mb-0">
            <b-col md="7">
              <b-row class="mb-5">
                <b-col md="3">
                  <div class="text-white lead mt-1">Developer</div>
                </b-col>
                <b-col md="1" style="width: 3px; margin: 0px; top: 10px;" class="text-right text-white">
                  :
                </b-col>
                <b-col>
                    <span>
                      <b-form-input name="Developer" v-model="model.developer_code"></b-form-input>
                    </span>
                </b-col>
              </b-row>
              <b-row  class="mb-5">
                <b-col md="3">
                  <div class="text-white lead mt-1">Total Capacity</div>
                </b-col>
                <b-col md="1" style="width: 3px; margin: 0px; top: 10px;" class="text-right text-white">
                  :
                </b-col>
                <b-col>
                    <span>
                      <b-form-input name="PPA Total Capacity kWp" v-model="model.project_ppa_approved_capacity"></b-form-input>
                    </span>
                </b-col>
              </b-row>
              <b-row  class="mb-5">
                <b-col md="3">
                  <div class="text-white lead mt-1">Watt Class</div>
                </b-col>
                <b-col md="1" style="width: 3px; margin: 0px; top: 10px;" class="text-right text-white">
                  :
                </b-col>
                <b-col>
                    <span>
                       <b-form-input name="Watt Class" v-model="model.project_watt_class"></b-form-input>
                    </span>
                </b-col>
              </b-row>
              <b-row  class="mb-0">
                <b-col md="3">
                  <div class="text-white lead mt-1">PPA Counter Party</div>
                </b-col>
                <b-col md="1" style="width: 3px; margin: 0px; top: 10px;" class="text-right text-white">
                  :
                </b-col>
                <b-col>
                    <span>
                       <b-form-input  name ="Project Status" v-model="model.project_ppa_counter_party"></b-form-input>
                    </span>
                  <!--<base-input >
                    <select class="form-control">
                      <option>In Progres</option>
                      <option>Turn On</option>
                    </select>
                  </base-input>-->
                </b-col>
              </b-row>

            </b-col>
            <b-col>
              <card class="bg-card-dashboard">
                <b-row class="mb-3">
                  <b-col class="text-white">
                    <div class=" display-4 pb-3">Key Contact</div>
                  </b-col>
                </b-row>
                <b-row class="mb-1">
                  <b-col>
                    <div class="pb-2">
                      <span class="text-muted">Project Engineer</span>
                    </div>
                    <base-input >
                      <select class="form-control" v-model="model.project_manager">
                        <option Selected disabled value="x">-- Please Select A Project Engineer --</option>
                        <option v-for="item in project_managers" :key="item.id" v-bind:value="item.id">{{ item.name }}</option>
                      </select>
                    </base-input>
                  </b-col>
                  <b-col>
                    <div class="pb-2">
                      <span class="text-muted">Engineer</span>
                    </div>
                    <base-input >
                      <select class="form-control" v-model="model.project_engineer">
                        <option Selected disabled value="x">-- Please Select A Engineer --</option>
                        <option v-for="item in project_engineers" :key="item.id" v-bind:value="item.id">{{ item.name }}</option>
                      </select>
                    </base-input>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <div class="pb-2">
                      <span class="text-muted">Bussiness Development</span>
                    </div>
                    <base-input >
                      <select class="form-control" v-model="model.project_business_development">
                        <option Selected disabled value="x">-- Please Select A Business Development User --</option>
                        <option v-for="item in project_business_development" :key="item.id" v-bind:value="item.id">{{ item.name }}</option>
                      </select>
                    </base-input>
                  </b-col>
                  <b-col>
                    <div class="pb-2">
                      <span class="text-muted">Safety Officer</span>
                    </div>
                    <base-input >
                      <select class="form-control" v-model="model.project_safety">
                        <option Selected disabled value="x">-- Please Select A Safety Officer --</option>
                        <option v-for="item in project_safety" :key="item.id" v-bind:value="item.id">{{ item.name }}</option>
                      </select>
                    </base-input>
                  </b-col>
                </b-row>
              </card>
            </b-col>
          </b-row>
          <b-row class="mb-5">
            <b-col class="text-right">
              <base-button type="primary" native-type="submit" @click="createData()">Save</base-button>
            </b-col>
          </b-row>

          <b-row class="mt-4">
            <b-col>
              <table  style="width: 100%;">
                <thead style="background-color: #23454A; color: white; font-size: small; border-bottom: 20px solid #082A2F;">
                <th class="th-table-new ">Site Name</th>
                <th class="th-table-new ">Address</th>
                <th class="th-table-new ">Capacity</th>
                <th class="th-table-new ">Status</th>
                <th class="th-table-new "></th>
                </thead>
                <tbody>
                <tr v-for="item in siteListingData" :key="item.site_id" style="background-color: #3a656b; color: white; font-size: smaller; border-bottom:  20px solid #082A2F;">
                  <td class="tr-td-table-new">{{ item.site_name }}</td>
                  <td class="tr-td-table-new">{{ item.site_address }}</td>
                  <td class="tr-td-table-new">{{ item.site_total_capacity }}</td>
                  <td class="tr-td-table-new">{{ item.status.status_code }}</td>
                  <td class="tr-td-table-new">
                    <div  style="margin-left: 40px">
                      <b-link :to="`/siteUtilitiesEdit/${item.site_id}`" style="color: #FF5C00; font-size:large;" target="_blank">
                        <i class="fa  fa-pencil-alt"></i>
                      </b-link>
                    </div>
                  </td>
                </tr>


                </tbody>
              </table>
              <b-link>
                <img src="img/theme/general/plus-circle.png" width="35px" alt="">
              </b-link>
            </b-col>
          </b-row>
        </b-container>

      </div>


    </div>
  </div>
</template>
<script>
    import { Select, Option,  Table, TableColumn, } from 'element-ui'
    import {
        LoginURL,
        clientId,
        clientSecret,
        apiDomain,
        getHeader,
        getcurrentDate,
    }from "@/assets/js/config.js";
    import axios from "axios";
    import ErrorFunctionMixin from "@/assets/js/errorFunctionMix.js";



    export default {
        name : 'Edit',
        mixins: [ErrorFunctionMixin],
        components: {
            [Select.name]: Select,
            [Option.name]: Option,
            [Table.name]: Table,
            [TableColumn.name]: TableColumn,
        },
        created() {
            var project_access = JSON.parse(
                window.localStorage.getItem("project_access")
            );
            this.button_access.project_delete = project_access.access_delete;

            const LoginUser = JSON.parse(window.localStorage.getItem("loginUser"));
            this.myUserID = LoginUser;

            this.getDeveloperListing();
            this.getProjectTypeListing();
            this.getProjectManagerListing();
            this.getProjectEngineerListing();
            this.getBusinessDevelopmentListing();
            this.getSafetyOfficerListing();
            this.getProjectSiteStatusListing();
            this.getIndustryCategoryListing();
            this.getDashboardData();

        },
        name :"Project Landing",
        components: {
            [Select.name]: Select,
            [Option.name]: Option,
            [Table.name]: Table,
            [TableColumn.name]: TableColumn,

        },
        data() {
            return {
                button_access: {
                    project_delete: null,
                },
                model: {
                    project_id: null,
                    developer_id: "x",
                    developer_code: null,
                    developer_name: null,
                    project_type_id: "x",
                    project_type_name: null,
                    project_manager: "x",
                    project_engineer: "x",
                    project_business_development: "x",
                    project_safety: "x",
                    project_name: null,
                    project_ppa_counter_party: null,
                    project_ppa_start_date: null,
                    project_ppa_approved_capacity: null,
                    project_watt_class: null,
                    project_tag: null,
                    project_industry_category_id: "x",
                },
                developers: [],
                project_type: [],
                project_managers: [],
                project_engineers: [],
                project_business_development: [],
                project_safety: [],
                site_status: [],
                industry_category: [],
                siteListingData: [],
                columnSites: [
                    {
                        key: "site_name",
                        label: "Site Name",
                        thClass: "max-width-column-header",
                        tdClass: "max-width-column-data",
                    },
                    {
                        key: "site_address",
                        label: "Address",
                    },
                    {
                        key: "site_postal_code",
                        label: "Postal Code",
                    },
                    {
                        key: "contractor_code",
                        label: "EPC",
                    },
                    {
                        key: "site_total_capacity",
                        label: "Capacity (kWp)",
                        formatter: (value) => {
                            if (typeof value === "string") {
                                return parseFloat(value.replace(/,/g, ""));
                            } else {
                                return value;
                            }
                        },
                    },
                    {
                        key: "status.status_id",
                        label: "Status",
                        formatter: (value) => {
                            const project_site_status = this.site_status.find(item => item.status_id == value);
                            return project_site_status ? project_site_status.status_code : null;
                        },
                    },
                ],
                groupListingData: [],
                columnGroups: [
                    {
                        key: "group_name",
                        label: "Town Council Name",
                        thClass: "max-width-column-header",
                        tdClass: "max-width-column-data",
                    },
                    {
                        key: "group_engineer.name",
                        label: "Engineer",
                    },
                ],
                isDeleteProjectModalVisible: false,
                isDeleteProjectModalVisibleInner: false,
                isSolarNovaProject: false,
            };
        },

        methods: {
            redirectToDashboardV3(){
                this.$router.push({path: '/projectDashboardV3/' + this.model.project_id})
            },
            redirectToOldDashboard() {
                this.$router.push({path: '/projectDashboard/' + this.model.project_id})
            },
            scrollToProfile() {
                const profileSection = document.getElementById("project-profile");
                profileSection.scrollIntoView({ behavior: "smooth" });
            },
            scrollToSite() {
                const siteSection = document.getElementById("site");
                siteSection.scrollIntoView({ behavior: "smooth" });
            },
            scrollToGroup() {
                const siteSection = document.getElementById("group");
                siteSection.scrollIntoView({ behavior: "smooth" });
            },
            getDeveloperListing() {
                axios
                    .get(apiDomain + "setup/developer", { headers: getHeader() })
                    .then((response) => {
                        if (response.status === 200) {
                            this.developers = response.data.data;
                            // default to sembcorp solar || developer_id = 1
                            // this.model.developer_id = 1;
                        }
                    })
                    .catch((error) => {
                        console.log(error.response.status);
                        this.errorFunction(error, "Developer Information");
                    });
            },
            getProjectTypeListing() {
                axios
                    .get(apiDomain + "setup/project_type", { headers: getHeader() })
                    .then((response) => {
                        if (response.status === 200) {
                            this.project_type = response.data.data;
                        }
                    })
                    .catch((error) => {
                        console.log(error.response.status);
                        this.errorFunction(error, "Project Type Information");
                    });
            },
            getProjectManagerListing() {
                axios.get(apiDomain + 'lookup/user_list/Project_Manager', { headers: getHeader() })
                    .then ( response => {
                        if (response.status === 200)
                        {
                            this.project_managers = response.data.data
                        }
                    })
                    .catch ( error => {
                        console.log(error.response.status)
                        this.errorFunction(error, "Project Manager")
                    } );
            },
            getProjectEngineerListing() {
                axios.get(apiDomain + 'lookup/user_list/Engineer', { headers: getHeader() })
                    .then ( response => {
                        if (response.status === 200)
                        {
                            this.project_engineers = response.data.data
                        }
                    })
                    .catch ( error => {
                        console.log(error.response.status)
                        this.errorFunction(error, "Engineer Information")
                    } );
            },
            getBusinessDevelopmentListing() {
                axios.get(apiDomain + 'lookup/user_list/Business_Development', { headers: getHeader() })
                    .then ( response => {
                        if (response.status === 200)
                        {
                            this.project_business_development = response.data.data
                        }
                    })
                    .catch ( error => {
                        console.log(error.response.status)
                        this.errorFunction(error, "Safety Officer Information")
                    } );
            },
            getSafetyOfficerListing() {
                axios.get(apiDomain + 'lookup/user_list/Safety', { headers: getHeader() })
                    .then ( response => {
                        if (response.status === 200)
                        {
                            this.project_safety = response.data.data
                        }
                    })
                    .catch ( error => {
                        console.log(error.response.status)
                        this.errorFunction(error, "Safety Officer Information")
                    } );
            },
            getProjectSiteStatusListing() {
                // axios.get(apiDomain + 'lookup/site_status', { headers: getHeader() })
                axios.get(apiDomain + 'lookup/site_status.singapore_v2', { headers: getHeader() })
                    .then ( response => {
                        if (response.status === 200)
                        {
                            this.site_status = response.data.data
                        }
                    })
                    .catch ( error => {
                        console.log(error.response.status)
                        this.errorFunction(error, "Site Status Information")
                    } );
            },
            getIndustryCategoryListing() {
                axios.get(apiDomain + 'lookup/industry_category', { headers: getHeader() })
                    .then ( response => {
                        if (response.status === 200)
                        {
                            this.industry_category = response.data.data
                        }
                    })
                    .catch ( error => {
                        console.log(error.response.status)
                        this.errorFunction(error, "Industry Category Information")
                    } );
            },
            getDashboardData() {
                this.model.project_id = this.$route.params.id;
                axios
                    .get(apiDomain + "project/project/" + this.model.project_id, {
                        headers: getHeader(),
                    })
                    .then((response) => {
                        if (response.status === 200) {
                            document.title = 'MOMENS-' + response.data.data[0].project_name;
                            this.model.project_name = response.data.data[0].project_name;
                            this.model.developer_id = response.data.data[0].developer.developer_id;
                            this.model.developer_code = response.data.data[0].developer.developer_code;
                            this.model.developer_name = response.data.data[0].developer.developer_name;
                            this.model.project_type_id = response.data.data[0].project_type.project_type_id;
                            if ( this.model.project_type_id != null &&
                                ([2, 3, 4, 9, 10, 11, 12, 13, 14].includes(this.model.project_type_id))
                            ) {
                                this.isSolarNovaProject = true;
                                this.getGroupListing();
                            } else {
                                this.isSolarNovaProject = false;
                                this.getSiteListing();
                            }
                            this.model.project_type_name = response.data.data[0].project_type.project_type;
                            this.model.project_ppa_counter_party = response.data.data[0].project_ppa_counter_party;
                            this.model.project_ppa_start_date = response.data.data[0].project_ppa_start_date;
                            this.model.project_ppa_approved_capacity = parseFloat(response.data.data[0].project_ppa_approved_capacity.replace(/,/g, ""));
                            // this.model.project_watt_class = parseFloat(response.data.data[0].project_watt_class.replace(/,/g, ""));
                            if (response.data.data[0].project_watt_class !== null) {
                                this.model.project_watt_class = parseFloat(response.data.data[0].project_watt_class.replace(/,/g, ""));
                            }
                            this.model.project_manager = response.data.data[0].project_manager.id;
                            this.model.project_engineer = response.data.data[0].project_engineer.id;
                            this.model.project_business_development = response.data.data[0].project_business_development.id;
                            this.model.project_safety = response.data.data[0].project_safety.id;
                            this.model.project_tag = response.data.data[0].project_tag;
                            this.model.project_industry_category_id = response.data.data[0].project_industry_category_id;
                        }
                    })
                    .catch((error) => {
                        // console.log(error.response.status);
                        // if (error.response.status == 401) {
                        // this.$router.push("/login");
                        // }
                        if (typeof error.response !== "undefined") {
                            this.errorFunction(error, "Project Information");
                        } else {
                            console.log(error);
                        }
                    });
            },
            getSiteListing() {
                axios.get(apiDomain + 'project/project_site.show_by_project/' + this.model.project_id, { headers: getHeader() })
                    .then ( response => {
                        if (response.status === 200)
                        {
                            this.siteListingData = response.data.data;
                            this.siteListingData.forEach(item => {
                                this.getContractorFromSite(item.site_id);
                            });
                        }
                    })
                    .catch ( error => {
                        console.log(error.response.status)
                    } );
            },
            // groupListingData
            getGroupListing() {
                axios.get(apiDomain + 'project/project_group.show_by_project/' + this.model.project_id, { headers: getHeader() })
                    .then ( response => {
                        if (response.status === 200)
                        {
                            this.groupListingData = response.data.data;
                            // this.groupListingData.forEach(item => {
                            //     this.getContractorFromSite(item.site_id);
                            // });
                        }
                    })
                    .catch ( error => {
                        console.log(error.response.status)
                    } );
            },
            getContractorFromSite(project_site_id)
            {
                axios.get(apiDomain + 'project/site_contractor.show_by_site/' + project_site_id + '?client=mobile', { headers: getHeader() })
                    .then ( response => {
                        if (response.status === 200)
                        {
                            this.siteListingData.map(item => {
                                if (item.site_id === project_site_id) {
                                    item['contractor_code'] = response.data.data[0].contractor.contractor_code;
                                }
                            });
                            this.siteListingData = [...this.siteListingData];
                        }
                    })
                    .catch ( error => {
                        console.log(error.response.status)
                        this.errorFunction(error, "Site Contractor Information")
                    } );
            },
            createData() {
                axios.put(apiDomain + 'project/project/'+ this.model.project_id, this.model, { headers: getHeader() })
                    .then ( response => {
                        if (response.status === 200)
                        {
                            this.$notify({
                                message:
                                '<b>Update Project : ' + this.model.project_name + '</b> - Successfully Updated.',
                                timeout: 10000,
                                icon: 'ni ni-bell-55',
                                type: 'default',
                            });
                            this.$router.push('../projectUtilitiesDashboard/' + this.model.project_id );
                            //   this.$router.back()
                            //this.getDashboardData();
                        }
                    })
                    .catch ( error => {
                        console.log(error.response.data.errors)
                        this.errorFunction(error, "Project Information")
                    });
            },
            rowClicked(row) {
                this.$router.push({
                    path: "/projectSiteDashboardV2/" + row.site_id,
                });
            },
            // rowClickedGroup
            rowClickedGroup(row) {
                this.$router.push({
                    path: "/projectSiteListingByGroup/" + row.group_id,
                });
            },
            showDeleteProjectModal() {
                this.isDeleteProjectModalVisible = true;
            },
            closeDeleteProjectModal() {
                this.isDeleteProjectModalVisible = false;
            },
            showDeleteProjectModalInner() {
                this.isDeleteProjectModalVisible = false;
                this.isDeleteProjectModalVisibleInner = true;
            },
            closeDeleteProjectModalInner() {
                this.isDeleteProjectModalVisibleInner = false;
            },
            deleteProject() {
                const empty_request = {
                    empty_request: null
                };

                axios
                    .post(apiDomain + "project/delete_project_v2/" + this.model.project_id, empty_request, {
                        headers: getHeader(),
                    })
                    .then((response) => {
                        if (response.status === 200) {
                            this.$notify({
                                message:
                                "<b>Project: " +
                                this.model.project_name +
                                "</b> - Successfully Deleted.",
                                timeout: 10000,
                                icon: "ni ni-bell-55",
                                type: "default",
                            });
                            this.redirectToProjectListing();
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                        this.$notify({
                            message:
                            error.response.data.error,
                            timeout: 10000,
                            icon: "ni ni-bell-55",
                            type: "danger",
                        });
                    });
            },
            redirectToProjectListing() {
                this.$router.push({
                    path: "/projectListingCNI",
                });
            },
        },
        mounted() {
            // this.generateData()
            // console.table(this.data)

        }
    };
</script>
<style scoped>
  .th-table-new {
    padding-top: 35px;
    padding-bottom: 35px;
    padding-left: 20px;
  }
  .tr-td-table-new{
    padding-top: 30px;
    padding-bottom: 30px;
    padding-left: 20px;
  }

  .el-input__inner, .el-select-dropdown__item.is-disabled:hover {
    background-color: #23454A;
  }
  .line-custom {
    background-color: #356168; margin-top: 15px; margin-bottom: 15px;
  }

  .bg-card-dashboard {
    background-color: #23454A;
  }
  .bg-default-dashboard {
    background-color: #082A2F;
    padding:0;
    margin:0;
    top:0;
    left:0;
    width: 100%;
    height: 100%;
  }

  .form-control {
    background-color: #2C545A;
    border-color: #2C545A;
    color: white;
  &:focus {
     background-color: #2C545A;
     border-color: #2C545A;
     color: white;
   }
  &::placeholder {
     color: white;
   }
  }
  .form-control {
  &:focus {
  &::placeholder {
     color: white;
   }
  }
  }
  .input-group-text {
    background-color: #2C545A;;
    color: white;
  &:focus {
     background-color: #2C545A;;
     border-color: #1D4147;
     color: white;
   }
  }
  .focused {
  .input-group-text {
    background-color: #2C545A;
  }
  }
  .navbar-search-dark .input-group {
    background-color: #23454A;
    color: black;
    border-color: rgba(255, 255, 255, 0.6);
  }
  .navbar-search .input-group {
    border-radius: 7px;
  }
  .el-input__inner, .el-select-dropdown__item.is-disabled:hover {
    background-color: #23454A;
  }
  .line-custom {
    background-color: #356168; margin-top: 15px; margin-bottom: 15px;
  }

  .el-table th.el-table__cell {

    overflow: hidden;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-color: #23454A;
    color: white;
    padding: 20px;
    border-top: 0px solid #082A2F;
    border-bottom: 0px solid #082A2F;
  }

  .el-table td.el-table__cell, .el-table th.el-table__cell.is-leaf {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    border-bottom: 20px solid #082A2F;
    border-top: 0px solid #082A2F;
  }

  div.el-table .el-table__row {
    background: #3a656b;;
    border-bottom: 0 solid #082A2F;

  }
  div.el-table .el-table__row:hover {
    background: #0d2f33;
  }

  .el-table--border::after, .el-table--group::after, .el-table::before {
    content: '';
    position: absolute;
    background-color: #ebeef500;
    z-index: 1;
  }


</style>
