<template>
  <card>
    <h3 slot="header" v-if="myUserID.role_id == 3 || myUserID.role_id == 2 || myUserID.role_id == 1" class="mb-0">Edit Task Template Detail for ({{ task_template_name }}) </h3>
    <h3 slot="header" v-else class="mb-0">View Task Template Detail for ({{ task_template_name }}) </h3>
    <h4 slot="header" class="mb-0">(Selected Milestone Template : {{ milestone_template.milestone_template_name }})</h4>
    <div class="text-right">
    </div>
    <form class="needs-validation"
          @submit.prevent="submit">

      <div class="form-row">
        <b-col md="6">
          <base-input label="Task Title"
                      name="Task Title"
                      placeholder="Task Title"
                      v-if="myUserID.role_id == 3 || myUserID.role_id == 2 || myUserID.role_id == 1"
                      v-model="model.task_template_title">
          </base-input>
          <base-input label="Task Title"
                      name="Task Title"
                      placeholder="Task Title"
                      v-else
                      v-model="model.task_template_title" disabled>
          </base-input>
        </b-col>

        <b-col md="5">
          <base-input :label="'Milestone From Template - '+ milestone_template.milestone_template_name"
                      name="Milestone From Template"
                      v-if="myUserID.role_id == 3 || myUserID.role_id == 2 || myUserID.role_id == 1"
                      v-model="model.milestone_template_detail_id">
            <select class="form-control" v-model="model.milestone_template_detail_id">
              <option Selected disabled value="x">-- Please Select A Milestone --</option>
              <option v-for="item in milestone_template_detail" :key="item.milestone_template_detail_id" v-bind:value="item.milestone_template_detail_id">
                 {{ item.milestone_template_title }}</option>
            </select>
          </base-input>
          <base-input :label="'Milestone From Template - '+ milestone_template.milestone_template_name"
                      name="Milestone From Template"
                      v-else
                      v-model="model.milestone_template_detail_id">
            <select class="form-control" v-model="model.milestone_template_detail_id" disabled>
              <option Selected disabled value="x">-- Please Select A Milestone --</option>
              <option v-for="item in milestone_template_detail" :key="item.milestone_template_detail_id" v-bind:value="item.milestone_template_detail_id">
                 {{ item.milestone_template_title }}</option>
            </select>
          </base-input>
        </b-col>

        <b-col md="1">
          <br>
          <base-button size="m" type="primary" @click="checkMilestoneDetail()">Milestone Details</base-button>
        </b-col>

        <b-col md="8">
          <base-input label="Task Description"
                      name="Task Description"
                      placeholder="Task Description"
                      v-if="myUserID.role_id == 3 || myUserID.role_id == 2 || myUserID.role_id == 1"
                      v-model="model.task_description">
          </base-input>
          <base-input label="Task Description"
                      name="Task Description"
                      placeholder="Task Description"
                      v-else
                      v-model="model.task_description" disabled>
          </base-input>
        </b-col>

        <b-col md="4">
          <base-input label="Task Status"
                      name="Task Status"
                      v-if="myUserID.role_id == 3 || myUserID.role_id == 2 || myUserID.role_id == 1"
                      v-model="model.status_id">
            <select class="form-control" v-model="model.status_id">
              <option Selected disabled value="x">-- Please Select Task Status --</option>
              <option v-for="item in task_status" :key="item.status_id" v-bind:value="item.status_id">{{ item.status_code }}</option>
            </select>
          </base-input>
          <base-input label="Task Status"
                      name="Task Status"
                      v-else
                      v-model="model.status_id">
            <select class="form-control" v-model="model.status_id" disabled>
              <option Selected disabled value="x">-- Please Select Task Status --</option>
              <option v-for="item in task_status" :key="item.status_id" v-bind:value="item.status_id">{{ item.status_code }}</option>
            </select>
          </base-input>
        </b-col>

        <!-- <b-col md="6">
          <base-input label="Duration from EPC Award Date"
                      name="Duration from EPC Award Date"
                      placeholder="0"
                      v-if="myUserID.role_id == 3 || myUserID.role_id == 2 || myUserID.role_id == 1"
                      v-model="model.duration_from_epc_award_date">
          </base-input>
          <base-input label="Duration from EPC Award Date"
                      name="Duration from EPC Award Date"
                      placeholder="0"
                      v-else
                      v-model="model.duration_from_epc_award_date"
                      disabled>
          </base-input>
        </b-col> -->

        <!-- <b-col md="6">
          <base-input label="Duration from Est Start Date"
                      name="Duration from Est Start Date"
                      placeholder="0"
                      v-if="myUserID.role_id == 3 || myUserID.role_id == 2 || myUserID.role_id == 1"
                      v-model="model.duration_from_est_start_date">
          </base-input>
          <base-input label="Duration from Est Start Date"
                      name="Duration from Est Start Date"
                      placeholder="0"
                      v-else
                      v-model="model.duration_from_est_start_date"
                      disabled>
          </base-input>          
        </b-col> -->

        <!-- <b-col md="12">
          <b-form-checkbox
            id="Reviewer-Required"
            v-if="myUserID.role_id == 3 || myUserID.role_id == 2 || myUserID.role_id == 1"
            v-model="model.reviewer_required"
            name="Revewer Required"
            value="1"
            unchecked-value="0">
            <b>Reviewer Required</b>
          </b-form-checkbox>
          <b-form-checkbox
            id="Reviewer-Required"
            v-else
            v-model="model.reviewer_required"
            name="Revewer Required"
            value="1"
            unchecked-value="0"
            disabled>
            <b>Reviewer Required</b>
          </b-form-checkbox>
        </b-col> -->

        <b-col md="12">
          <div class="form-group" v-if="model.reviewer_required == 1">
            <div
              v-for="(input, index) in reviewers"
              :key="`reviewerInput-${index}`">
              <div class="form-row">
                <b-col md="3">
                  <base-input
                    v-model="input.reviewerRoleId">
                    <select
                      class="form-control"
                      v-if="myUserID.role_id == 3 || myUserID.role_id == 2 || myUserID.role_id == 1"
                      v-model="input.reviewerRoleId"
                      @change="removeFromList($event)">
                      <option Selected disabled value="x">
                        -- Please Select --
                      </option>
                      <option
                        v-for="item in roles"
                        :key="item.id"
                        v-bind:value="item.id"
                        :disabled="item.disabled"
                        v-show="!item.v_hide">
                        {{ item.name }}
                      </option>
                    </select>
                    <select
                      class="form-control"
                      v-else
                      v-model="input.reviewerRoleId"
                      @change="removeFromList($event)"
                      disabled>
                      <option Selected disabled value="x">
                        -- Please Select --
                      </option>
                      <option
                        v-for="item in roles"
                        :key="item.id"
                        v-bind:value="item.id"
                        :disabled="item.disabled"
                        v-show="!item.v_hide">
                        {{ item.name }}
                      </option>
                    </select>
                  </base-input>
                </b-col>
                <b-col md="auto"
                  v-if="myUserID.role_id == 3 || myUserID.role_id == 2 || myUserID.role_id == 1">
                  <base-button
                    size="sm"
                    type="primary"
                    @click="addField(input, reviewers)">
                    Add
                  </base-button>
                </b-col>
                <b-col md="auto"
                  v-if="myUserID.role_id == 3 || myUserID.role_id == 2 || myUserID.role_id == 1">
                  <base-button
                    size="sm"
                    type="primary"
                    @click="removeField(index, reviewers)"
                    v-show="reviewers.length > 1">
                    Remove
                  </base-button>
                </b-col>
              </div>
            </div>
          </div>
        </b-col>

        <b-col md="4">
          <b-form-checkbox
            id="Active-Status"
            v-if="myUserID.role_id == 3 || myUserID.role_id == 2 || myUserID.role_id == 1"
            v-model="model.active_status"
            name="Active-Status"
            value="1"
            unchecked-value="0">
            <b>Active Status</b>
          </b-form-checkbox>
          <b-form-checkbox
            id="Active-Status"
            v-else
            v-model="model.active_status"
            name="Active-Status"
            value="1"
            unchecked-value="0" disabled>
            <b>Active Status</b>
          </b-form-checkbox>
        </b-col>
      </div>
      <br>

      <base-button v-if="myUserID.role_id == 3 || myUserID.role_id == 2 || myUserID.role_id == 1" type="primary" @click="createData()">Update / Save</base-button>
      <base-button type="primary" @click="$router.back()">Cancel</base-button>
    </form>
  </card>
</template>
<script>
  import BaseSlider from '@/components/BaseSlider'
  import {LoginURL, clientId, clientSecret, apiDomain, getHeader} from '@/assets/js/config.js'
  import axios from 'axios'
  import ErrorFunctionMixin from '@/assets/js/errorFunctionMix.js'

  export default {
  mixins: [ErrorFunctionMixin],
    components: {
      BaseSlider
    },
    created()
    {
      const LoginUser = JSON.parse(window.localStorage.getItem('loginUser'))
      this.myUserID = LoginUser

      this.model.task_template_detail_id = this.$route.params.id

      axios
        .get(apiDomain + "lookup/role_list", { headers: getHeader() })
        .then((response) => {
          if (response.status === 200) {
            this.roles = response.data.data;
          }
        })
        .catch((error) => {
          console.log(error.response.status);
          this.errorFunction(error, "Role Listing");
        });

      axios.get(apiDomain + 'template/task_template_details/'+this.model.task_template_detail_id, { headers: getHeader() })
      .then ( response => {
        if (response.status === 200)
        {
          this.model.task_template_detail_id = response.data.data[0].task_template_detail_id
          this.model.task_template_id = response.data.data[0].task_template.task_template_id
          this.model.task_template_title = response.data.data[0].task_template_title
          this.model.task_description = response.data.data[0].task_description
          this.model.status_id = response.data.data[0].status.status_id
          this.model.milestone_template_detail_id = response.data.data[0].milestone_template_detail.milestone_template_detail_id
          this.milestone_template.milestone_template_id = response.data.data[0].milestone_template_detail.milestone_template_id
          this.model.duration_from_epc_award_date = response.data.data[0].duration_from_epc_award_date
          this.model.duration_from_est_start_date = response.data.data[0].duration_from_est_start_date
          this.model.reviewer_required = response.data.data[0].reviewer_required

          if ( response.data.data[0].active_status == 'ACTIVE' )
          {
            this.model.active_status = 1
          }
          else
          {
            this.model.active_status = 0
          }

          this.task_template_name = response.data.data[0].task_template.task_template_name

          axios.get(apiDomain + 'template/milestone_template_details.show_by_template/'+this.milestone_template.milestone_template_id, { headers: getHeader() })
          .then ( response => {
            if (response.status === 200)
            {
              this.milestone_template_detail = response.data.data
              this.milestone_template.milestone_template_name = response.data.data[0].milestone_template.milestone_template_name
            }
          })
          .catch ( error => {
              console.log(error.response.status)
              this.errorFunction(error, "Task Milestone Details")
          });

          if (this.model.reviewer_required == 1) {
            axios
              .get(apiDomain + 'template/task_template_details_reviewers.show_by_template/' + this.model.task_template_detail_id, { headers: getHeader() })
              .then ( response => {
                if (response.status === 200)
                {
                  this.reviewer_response = response.data.data;
                  this.populateReviewers();
                }
              })
              .catch ( error => {
                  console.log(error.response.status)
                  this.errorFunction(error, "Task Template Detail Reviewer")
              });
          } 

        }
      })
      .catch ( error => {
        console.log(error.response.status)
        this.errorFunction(error, "Task Template Details")
      } );

      this.getTaskStatus()
    },
    data() {
      return {
        model: {
          task_template_detail_id: null,
          task_template_id: null,
          task_template_title: null,
          task_template_mandatory: 1,
          task_type_id: null,
          task_description: null,
          status_id: 'x',
          milestone_template_detail_id: null,
          reviewer_required: 0,
          active_status: 1,
          duration_from_epc_award_date: 0,
          duration_from_est_start_date: 0,
          reviewers: null,
        },
        milestone_template_detail: [],
        task_type: [],
        task_status: [],
        task_template_name: null,
        milestone_template: {
          milestone_template_id: null,
          milestone_template_name: null,
        },
        myUserID: null,
        reviewers: [{ reviewerRoleId: "", previousSelection: "" }],
        roles: [],
        reviewer_response: null,
      }
    },
    methods: {
      populateReviewers() {
        this.reviewers = [];
        this.reviewer_response.forEach((e) => {
          this.reviewers.push({ reviewerRoleId: e['role']['role_id'], previousSelection:e['role']['role_id'] });
          this.roles.map((e_rol) => {
            if (e_rol.id == e['role']['role_id']) {
              e_rol["disabled"] = true;
              e_rol["v_hide"] = true;
            }
          });
        });
      },
      removeFromList(event) {
        this.reviewers.map((e_rev) => {
          if (e_rev.reviewerRoleId == event.target.value) {
            if (e_rev.previousSelection != "") {
              this.roles.map((e_rol) => {
                if (e_rol.id == e_rev.previousSelection) {
                  e_rol["disabled"] = false;
                  e_rol["v_hide"] = false;
                }
              });
            }
          }
          this.roles.map((e_rol) => {
            if (e_rol.id == event.target.value) {
              e_rol["disabled"] = true;
              e_rol["v_hide"] = true;
            }
          });
          e_rev.previousSelection = e_rev.reviewerRoleId;
        });
      },
      addField(object, fieldType) {
        let newObject = {};
        for (const [key, value] of Object.entries(object)) {
          newObject[key] = "";
        }
        fieldType.push(newObject);
      },
      removeField(index, fieldType) {
        if (fieldType[index].reviewerRoleId != "") {
          this.roles.map((e_rol) => {
            if (e_rol.id == fieldType[index].reviewerRoleId) {
              e_rol["disabled"] = false;
              e_rol["v_hide"] = false;
            }
          });
        }
        fieldType.splice(index, 1);
      },
      getTaskStatus()
      {
        axios.get(apiDomain + 'lookup/task_status', { headers: getHeader() })
        .then ( response => {
          if (response.status === 200)
          {
            this.task_status = response.data.data
          }
        })
        .catch ( error => {
          console.log(error.response.status)
          this.errorFunction(error, "Task Status")
        } );
      },
      checkMilestoneDetail()
      {
        if (this.milestone_template.milestone_template_id == 'x')
        {
          this.$notify({
            message:
              '<b>Invalid Input : Milestone Template </b> - Please select a Milestone Template before dive into the Details',
            timeout: 10000,
            icon: 'ni ni-bulb-61',
            type: 'danger',
          });
        }
        else
        {
          this.$router.push({path: '/templates/milestoneTemplateDetailListing/'+this.milestone_template.milestone_template_id })
        }
      },
      checkAndCreateReviewers() {
        if (this.model.reviewer_required == 1) {
          let tempReviewers = [];
          this.reviewers.forEach((item) => {
            if (item.reviewerRoleId != "") {
              tempReviewers.push({ reviewerRoleId: item.reviewerRoleId });
            }
          });
          this.model.reviewers = tempReviewers;
        } else {
          // clear reviewers if reviewers not required
          this.model.reviewers = [];
        }
      },
      createData(){
        this.checkAndCreateReviewers();
        if ( !this.model.task_template_title || this.model.status_id == 'x' || this.model.milestone_template_detail_id == 'x' || !this.model.task_description )
        {
          this.$notify({
            message:
              '<b>Invalid Input : All Column are Mandatory in Task Template Details</b> - These field are required.',
            timeout: 10000,
            icon: 'ni ni-bulb-61',
            type: 'danger',
          });
        } else if (
          this.model.reviewer_required == 1 &&
          this.model.reviewers.length < 1
        ) {
          this.redErrorNotify(
            "<b>Invalid Input : Reviewer(s) </b> - Requires Reviewer(s) but no Reviewer(s) selected."
          );
        }
        else
        {
          axios.put(apiDomain + 'template/task_template_details/'+this.model.task_template_detail_id, this.model, { headers: getHeader() })
          .then ( response => {
            if (response.status === 200)
            {
                this.$notify({
                message:
                  '<b>New Task Template Details : ' + this.model.task_template_title + '</b> - Successfully Created.',
                timeout: 10000,
                icon: 'ni ni-bell-55',
                type: 'default',
                });
                this.$router.back()
            }
          })
          .catch ( error => {
            console.log(error.response.data.errors)
            this.errorFunction(error, "Task Template Details")
          });
        }
      },
    }
  }
</script>
<style>
</style>
