<template>
  <div>
    <base-header class="pb-6">
      <b-row class="py-4">
        <b-col>
          <h6 class="h2 text-white d-inline-block mb-0">{{ title }}</h6>
        </b-col>
        <b-col class="text-right">
          <base-button size="sm" type="neutral" @click="$router.replace(`../Listing/${level}`)">Back</base-button>
        </b-col>
      </b-row>
    </base-header>

    <b-container fluid class="mt--6">
      <div class="card-wrapper">
        <card>
          <!-- Card header -->
          <h3 slot="header" class="mb-0">New {{ category_name }}</h3>
          <validation-observer v-slot="{ handleSubmit }">
            <form class="needs-validation" @submit.prevent="handleSubmit(onSubmit)">

              <b-form-row>
                <b-col>
                  <validation-provider name="Code" rules="required" v-slot="{ classes, errors }">
                    <b-form-group label="Code" :invalid-feedback="errors[0]">
                      <input type="text" class="form-control" :class="classes" v-model="model.code" maxlength="20">
                    </b-form-group>
                  </validation-provider>
                </b-col>
              </b-form-row>

              <b-form-row>
                <b-col>
                  <validation-provider name="Name" rules="required" v-slot="{ classes, errors }">
                    <b-form-group label="Name" :invalid-feedback="errors[0]">
                      <input type="text" class="form-control" :class="classes" v-model="model.name" maxlength="100">
                    </b-form-group>
                  </validation-provider>
                </b-col>
              </b-form-row>

              <button class="btn btn-primary" v-if="access.create">Create</button>
              <button class="btn btn-primary" type="button" @click="$router.replace(`../Listing/${level}`)">Cancel</button>
            </form>
          </validation-observer>
        </card>
      </div>
    </b-container>
  </div>
</template>

<script>
import ErrorFunctionMixin from '@/assets/js/errorFunctionMix.js'
import axios from '@/util/axios'
import { getAccessByModule } from '@/util/access'

export default {
  mixins: [ErrorFunctionMixin],

  data() {
    return {
      title: 'Document Tracking',
      pid: null,
      sid: null,
      level: null,
      setting: {},
      category_name: null,
      model: {},
      access: {},
    }
  },

  created() {
    this.pid = this.$route.params.pid
    this.sid = this.$route.params.sid
    this.level = this.$route.params.level
    this.model.level = this.level
    this.access = getAccessByModule('DOC_REPO_REVIEW')

    this.loadSetting()
  },

  methods: {
    loadSetting() {
      axios.get(`doc_repo/p/${this.pid}/s/${this.sid}/setting`)
        .then(response => {
          this.setting = response.data.data
          this.category_name = this.setting[`category${this.level}_name`]
        })
        .catch(error => {
          this.errorFunction(error, this.title)
        })
    },

    onSubmit() {
      axios.post(`doc_repo/p/${this.pid}/s/${this.sid}/category`, this.model)
        .then(response => {
          this.$notify({
            message: 'New Category Created',
            timeout: 10000,
            icon: 'ni ni-bell-55',
            type: 'default',
          });
          this.$router.replace(`../Listing/${this.level}`)
        })
        .catch(error => {
          this.errorFunction(error, this.title)
        });
    },
  },
}
</script>
