<template>
  <card>
    <!-- Card header -->
    <h3 slot="header" class="mb-0">
      Edit Project Group for ({{ model.group_name }})
    </h3>
    <div class="text-right"></div>
    <form class="needs-validation" @submit.prevent="submit">
      <div v-if="projects.length == 0" class="form-row">
        <b-col md="12">
          <h5 class="text-danger">
            !!! Warning :
            <i>
              Please Create
              <router-link to="/developerNew"> New Developer </router-link>
              at Menu >> Setup >> Developer. System required at least ONE
              Developer before you can proceed to create New Project Group.
            </i>
          </h5>
        </b-col>
      </div>

      <div class="form-row">
        <b-col md="6">
          <base-input
            label="Project Group Name"
            name="Project Group Name"
            placeholder="Project Group Name"
            v-model="model.group_name"
          >
          </base-input>
        </b-col>

        <b-col md="6">
          <base-input label="Project" name="Project" v-model="model.project_id">
            <select class="form-control" v-model="model.project_id" disabled>
              <option Selected disabled value="x">
                -- Please Select A Project --
              </option>
              <option
                v-for="item in projects"
                :key="item.project_id"
                v-bind:value="item.project_id"
              >
                {{ item.project_name }}
              </option>
            </select>
          </base-input>
        </b-col>

        <!-- <b-col md="1">
          <br>
          <base-button size="sm" type="primary" @click="checkProject()">Project Infomation</base-button>
        </b-col> -->
      </div>

      <div class="form-row">
        <b-col md="12">
          <base-input
            label="Project Group Information"
            name="Project Group Information"
            placeholder="Project Group Information"
            v-model="model.group_information"
          >
          </base-input>
        </b-col>
      </div>

      <div class="form-row">
        <!-- <b-col md="6">
          <base-input
            label="Group PV Capacity (KWp)"
            name="Group PV Capacity (KWp)"
            placeholder="Group PV Capacity (KWp)"
            v-model="model.group_pv_capacity"
            type="number"
            step="any"
          >
          </base-input>
        </b-col> -->

        <b-col md="12">
          <!-- <base-input label="Project Manager"
                      name="Project Manager"
                      v-model="model.group_engineer"> -->
          <base-input
            label="Project Engineer"
            name="Project Engineer"
            v-model="model.group_engineer"
          >
            <select class="form-control" v-model="model.group_engineer">
              <option Selected disabled value="x">
                -- Please Select A Group Manager --
              </option>
              <option
                v-for="item in group_managers"
                :key="item.id"
                v-bind:value="item.id"
              >
                {{ item.name }} - {{ item.email }}
              </option>
            </select>
          </base-input>
        </b-col>
      </div>

      <div class="form-row">
        <b-col md="12">
          <base-input
            label="Project Group Status"
            name="Project Group Status"
            v-model="model.status_id"
          >
            <select class="form-control" v-model="model.status_id">
              <option Selected disabled value="x">
                -- Please Select Group Status --
              </option>
              <option
                v-for="item in group_status"
                :key="item.status_id"
                v-bind:value="item.status_id"
              >
                {{ item.status_code }}
              </option>
            </select>
          </base-input>
        </b-col>

        <!-- <b-col md="6">
          <base-input
            label="Project Group Progress % "
            name="Project Group Progress"
            placeholder="Project Group Progress"
            v-model="model.group_progress"
            type="number"
            min="0"
            max="100"
          >
          </base-input>
        </b-col> -->
      </div>

      <base-button type="primary" @click="createData()"
        >Update / Save</base-button
      >
      <base-button type="primary" @click="$router.back()">Cancel</base-button>
      <!-- <div>
        <br>
        <h5 class="text-danger"> Note : <br><br>
                <i>
                1. By Default all Date is set to "TODAY.
                </i>
                <br>
                <i>
                2. Project Group Progress % is set to "0 %" By Default.
                </i>
        </h5>
      </div> -->
    </form>
  </card>
</template>
<script>
import BaseSlider from "@/components/BaseSlider";
import {
  LoginURL,
  clientId,
  clientSecret,
  apiDomain,
  getHeader,
  getcurrentDate,
} from "@/assets/js/config.js";
import axios from "axios";
import ErrorFunctionMixin from "@/assets/js/errorFunctionMix.js";

export default {
  mixins: [ErrorFunctionMixin],
  components: {
    BaseSlider,
  },
  created() {
    var currentDate = getcurrentDate();

    axios
      .get(apiDomain + "project/project", { headers: getHeader() })
      .then((response) => {
        if (response.status === 200) {
          this.projects = response.data.data;
        }
      })
      .catch((error) => {
        console.log(error.response.status);
        this.errorFunction(error, "Project Information");
      });

    axios
      .get(apiDomain + "lookup/group_status", { headers: getHeader() })
      .then((response) => {
        if (response.status === 200) {
          this.group_status = response.data.data;
        }
      })
      .catch((error) => {
        console.log(error.response.status);
        this.errorFunction(error, "Project Status");
      });

    axios
      .get(apiDomain + "lookup/user_list/Project_Manager", {
        headers: getHeader(),
      })
      .then((response) => {
        if (response.status === 200) {
          this.group_managers = response.data.data;
        }
      })
      .catch((error) => {
        console.log(error.response.status);
        this.errorFunction(error, "Project Engineer Information");
      });

    this.model.group_id = this.$route.params.id;

    axios
      .get(apiDomain + "project/project_group/" + this.model.group_id, {
        headers: getHeader(),
      })
      .then((response) => {
        if (response.status === 200) {
          this.model.group_name = response.data.data[0].group_name;
          this.model.project_id = response.data.data[0].project.project_id;
          this.model.project_name = response.data.data[0].project.project_name;
          this.model.group_name = response.data.data[0].group_name;
          this.model.group_information =
            response.data.data[0].group_information;
          this.model.group_pv_capacity = parseFloat(
            response.data.data[0].group_pv_capacity.replace(/,/g, "")
          );
          this.model.group_progress = response.data.data[0].group_progress;
          this.model.group_engineer = response.data.data[0].group_engineer.id; // this retrieve as manager
          this.model.status_id = response.data.data[0].status.status_id;
        }
      })
      .catch((error) => {
        console.log(error.response.status);
        this.errorFunction(error, "Project Group Information");
      });
  },
  data() {
    return {
      model: {
        group_id: null,
        project_id: "x",
        project_name: null,
        group_name: null,
        group_name: null,
        group_information: null,
        group_location: null,
        group_pv_capacity: "0.1",
        status_id: "x",
        group_engineer: "x",
        group_progress: 0,
      },
      projects: [],
      group_status: [],
      group_managers: [],
    };
  },
  methods: {
    checkProject() {
      if (this.model.project_id == "x") {
        this.$notify({
          message:
            "<b>Invalid Input : Project </b> - Please select a Project before dive into the Details",
          timeout: 10000,
          icon: "ni ni-bulb-61",
          type: "danger",
        });
      } else {
        this.$router.push({ path: "/projectEdit/" + this.model.project_id });
      }
    },
    createData() {
      if (
        !this.model.group_name ||
        !this.model.group_name ||
        this.model.project_id == "x" ||
        this.model.status_id == "x" ||
        this.model.group_engineer == "x" ||
        !this.model.group_information
        // ||
        // !this.model.group_pv_capacity
      ) {
        // if (!this.model.group_pv_capacity) {
        //   this.redErrorNotify(
        //     "<b>Invalid Input : Group Capacity Column is a Mandatory in Project Group </b>"
        //   );
        // } else
        if (this.model.group_name == "x") {
          this.redErrorNotify(
            "<b>Invalid Input : Group Name Column is a Mandatory in Project Group </b>"
          );
        } else if (this.model.project_id == "x") {
          this.redErrorNotify(
            "<b>Invalid Input : Project is a Mandatory in Project Group </b>"
          );
        } else if (this.model.status_id == "x") {
          this.redErrorNotify(
            "<b>Invalid Input : Group Status is a Mandatory in Project Group </b>"
          );
        } else if (this.model.group_engineer == "x") {
          this.redErrorNotify(
            "<b>Invalid Input : Project Engineer is a Mandatory in Project Group </b>"
          );
        } else if (this.model.group_information == "x") {
          this.redErrorNotify(
            "<b>Invalid Input : Safety Officer is a Mandatory in Project Group </b>"
          );
        } else {
          this.redErrorNotify(
            "<b>Invalid Input : Please fill in all the Mandatory Column in Project Group </b>"
          );
        }
      } else if (
        this.model.group_progress < 0 ||
        this.model.group_progress > 100
      ) {
        this.redErrorNotify(
          "<b>Invalid Input : Project Group Progress </b> - Input Must be between 0 - 100"
        );
      } else {
        axios
          .put(
            apiDomain + "project/project_group/" + this.model.group_id,
            this.model,
            { headers: getHeader() }
          )
          .then((response) => {
            if (response.status === 200) {
              this.$notify({
                message:
                  "<b>Edit Project Group : " +
                  this.model.group_name +
                  "</b> - Successfully Updated.",
                timeout: 10000,
                icon: "ni ni-bell-55",
                type: "default",
              });
              this.$router.back();
            }
          })
          .catch((error) => {
            console.log(error.response.data.errors);
            this.errorFunction(error, "Update Project");
          });
      }
    },
  },
};
</script>
<style>
</style>
