<template>
  <card>
    <h3 slot="header" class="mb-0">Task Template for ({{ model.task_template_name }}) </h3>
    <div class="text-right" v-if="button_access.edit_template_button == 'YES'">
      <router-link :to="'/templates/taskTemplateEdit/' + model.task_template_id">
        <base-button v-if="myUserID.role_id == 3" size="sm" type="primary">Edit</base-button>
      </router-link>
    </div>
    <form class="needs-validation"
          @submit.prevent="submit">

      <div class="form-row">
        <b-col md="6">
          <base-input label="Template Name"
                      name="Template Name"
                      placeholder="Template Name"
                      v-model="model.task_template_name" disabled>
          </base-input>
        </b-col>

        <b-col md="6">
          <base-input label="Milestone Template"
                      name="Milestone Template"
                      v-model="model.milestone_template_name" disabled>
          </base-input>
        </b-col>

      </div>
    </form>
  </card>
</template>
<script>
  import BaseSlider from '@/components/BaseSlider'
  import {LoginURL, clientId, clientSecret, apiDomain, getHeader} from '@/assets/js/config.js'
  import axios from 'axios'
  import ErrorFunctionMixin from '@/assets/js/errorFunctionMix.js'

  export default {
    mixins: [ErrorFunctionMixin],
    components: {
      BaseSlider
    },
    created()
    {
      var site_task_template_access = JSON.parse(window.localStorage.getItem('site_task_template_access'))
      this.button_access.edit_template_button = site_task_template_access.access_edit

      const LoginUser = JSON.parse(window.localStorage.getItem('loginUser'))
      this.myUserID = LoginUser

      this.model.task_template_id = this.$route.params.id

      axios.get(apiDomain + 'template/task_template/'+this.model.task_template_id, { headers: getHeader() })
      .then ( response => {
        if (response.status === 200)
        {
            this.model.task_template_id = response.data.data[0].task_template_id
            this.model.task_template_code = response.data.data[0].task_template_code
            this.model.task_template_name = response.data.data[0].task_template_name
            this.model.milestone_template_name = response.data.data[0].milestone_template.milestone_template_name
        }
      })
      .catch ( error => {
        console.log(error.response.status)
        this.errorFunction(error, "Task Template Information")
      } );

      axios.get(apiDomain + 'template/milestone_template', { headers: getHeader() })
      .then ( response => {
        if (response.status === 200)
        {
          this.milestone_template = response.data.data
        }
      })
      .catch ( error => {
        console.log(error.response.status)
        this.errorFunction(error, "Milestone Template Information")
      } );
    },
    data() {
      return {
        model: {
          task_template_code: null,
          task_template_name: null,
          task_template_id: null,
          milestone_template_id: null,
          milestone_template_name : null,
        },
        milestone_template: [],
        button_access:{
          edit_template_button: null,
        },
        myUserID: null,
      }
    },
    methods: {
      async submit() {

      },

    }
  }
</script>
<style>
</style>
