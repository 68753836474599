<template>
  <div class="bg-default-dashboard">
    <div class=" py-lg-8 pt-lg-5">
      <b-container fluid style="width: 90%; position: relative;">
        <!-- <div class="header-body "  > -->
        <b-row class="mb-1">
          <b-col md="12">
            <card class="bg-card-dashboard">
              <b-row>
                <b-col md="6">
                  <div class="mb-3">
                    <span class="text-white lead text-muted">Site Name : <strong class="h1 text-white">{{
                        siteListingData.site_name }}</strong></span>
                  </div>
                  <div class="mb-0">
                    <span>
                      <img src="/img/theme/general/map.png" alt="" class="mr-3">
                      <span class="text-white lead"><strong>{{ siteListingData.site_address }}</strong></span>
                    </span>
                  </div>
                  <b-row>
                    <b-col class="text-white lead">Document Title : <strong>{{document.title}}</strong></b-col>
                  </b-row>
                  <b-row>
                    <b-col v-if="document.status=='APPROVED'" class="text-white lead">Document Status : <strong>COMPLETELY ACKNOWLEDGED</strong></b-col>
                    <b-col v-if="document.status=='NEW_REVISION'" class="text-white lead">Document Status : <strong>EMPTY</strong></b-col>
                    <b-col v-if="document.status=='PENDING_REVIEW' || document.status=='ACCEPTED'" class="text-white lead">Document Status : <strong>PENDING TO BE ACKNOWLEDGED</strong></b-col>
                    <b-col v-if="document.status=='REJECTED' || document.status=='EMPTY'" class="text-white lead">Document Status : <strong>{{document.status}}</strong></b-col>
                  </b-row>
                </b-col>

                <b-col>
                  <div class="mb-3">
                    <span class="text-white lead text-muted">Document No. : <strong class="h1 text-white">{{document.number}}</strong></span>
                  </div>
                  <div>
                    <b-row class="mb-2">
                      <b-col class="text-white lead">{{ setting.category1_name }} : <strong>{{document.category1_code}}</strong></b-col>
                    </b-row>
                  </div>
                  <b-row>
                    <b-col class="text-white lead">{{ setting.category2_name }} : <strong>{{document.category2_code}}</strong></b-col>
                  </b-row>
                  <b-row v-if="setting.category3_enabled">
                    <b-col class="text-white lead">{{ setting.category3_name }} : <strong>{{document.category3_code}}</strong></b-col>
                  </b-row>
                </b-col>

              </b-row>
            </card>
          </b-col>

        </b-row>
        <!-- grid data -->
        <b-row class="mt-0">
          <b-col md="12">
            <div class="row">
              <dataset style="width: 100%;" v-slot="{ ds }" :ds-data="revision_history"
                       :ds-sortby="['revision_id', 'id']"
                       :ds-search-in="['revision_id', 'id', 'revision_number', 'type', 'submitted_by','submission_date','status']">
                <div class="row" style="padding-bottom: 15px;" :data-page-count="ds.dsPagecount">
                  <div class="col-md-6"
                       style="color: #ffffff; font-family: Open Sans, sans-serif !important; font-size: 0.875rem;">
                    <dataset-show class="dataset-style" />
                  </div>
                  <div class="col-md-6">
                    <template class="text-right"><dataset-search ds-search-placeholder="Search..." /></template>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <div class="table-responsive">
                      <table style="width: 100%;" class="table table-striped d-md-table">
                        <thead
                                style="background-color: #23454A; color: white; font-size: small; border-bottom: 10px solid #082A2F;">
                        <tr>
                          <!-- <th scope="col">#</th> -->
                          <th v-for="th in obj_data_history_grid.cols" :key="th.field" :class="['sort', th.sort]">
                            {{ th.name }} <i class="gg-select float-right"></i>
                          </th>
                        </tr>
                        </thead>
                        <dataset-item tag="tbody">
                          <template #default="{ row }">
                            <tr
                                    style="background-color: #23454A; color: white; font-size: smaller; border-bottom:  5px solid #082A2F;">
                              <!-- <th scope="row">{{ rowIndex + 1 }}</th> -->
                              <td class="tr-td-table-new" style="min-width: 150px;">{{ row.revision_id }}</td>
                              <td class="tr-td-table-new" style="min-width: 150px;">{{ row.id }}</td>
                              <td class="tr-td-table-new" style="min-width: 150px;">{{ row.revision_number }}</td>
                              <td class="tr-td-table-new" style="min-width: 150px;">{{ row.type }}</td>
                              <td v-if="row.status == 'APPROVED'" class="tr-td-table-new" style="min-width: 100px;">
                                <i class="fa fa-xs fa-circle mr-1" style="color: #FF9A3D;"></i>
                                COMPLETELY ACKNOWLEDGED
                              </td>
                              <td v-if="row.status == 'ACCEPTED' && row.type == 'First Submission'" class="tr-td-table-new" style="min-width: 100px;">
                                <i class="fa fa-xs fa-circle mr-1" style="color: #FF9A3D;"></i>
                                PENDING TO BE ACKNOWLEDGED
                              </td>
                              <td v-if="row.status == 'ACCEPTED' && row.type != 'First Submission'" class="tr-td-table-new" style="min-width: 100px;">
                                <i class="fa fa-xs fa-circle mr-1" style="color: #46D275;"></i>
                                ACKNOWLEDGED
                              </td>
                              <td v-if="row.status == 'PENDING_REVIEW'" class="tr-td-table-new" style="min-width: 100px;">
                                <i class="fa fa-xs fa-circle mr-1" style="color: #FF9A3D;"></i>
                                PENDING TO BE ACKNOWLEDGED
                              </td>
                              <td v-if="row.status == 'NEW_REVIEW'" class="tr-td-table-new" style="min-width: 100px;">
                                <i class="fa fa-xs fa-circle mr-1" style="color: #11CDEF;"></i>
                                BEING REVIEWED
                              </td>
                              <td v-if="row.status == 'REJECTED'" class="tr-td-table-new" style="min-width: 100px;">
                                <i class="fa fa-xs fa-circle mr-1" style="color: #FF3232;"></i>
                                {{ row.status }}
                              </td>
                              <td v-if="row.status == 'NEW_REVISION'" class="tr-td-table-new" style="min-width: 100px;">
                                <i class="fa fa-xs fa-circle mr-1" style="color: white;"></i>
                                EMPTY
                              </td>
                              <td v-if="row.status == 'EMPTY'" class="tr-td-table-new" style="min-width: 100px;">
                                <i class="fa fa-xs fa-circle mr-1" style="color: white;"></i>
                                {{ row.status }}
                              </td>
                              <td class="tr-td-table-new" style="min-width: 150px;">{{ row.submitted_by }}</td>
                              <td class="tr-td-table-new" style="min-width: 150px;">{{ row.submission_date }}</td>
                              <td class="tr-td-table-new" style="min-width: 100px;">
                                <div>
                                  <template v-if="row.rev_status == 'NEW_REVISION' || row.rev_status == 'EMPTY'">
                                    <b-link @click="showModal(row.id, row.status)"
                                            style="color: #9adada; font-size:large;">
                                      <svg width="23" height="25" viewBox="0 0 28 30" fill="none"
                                           xmlns="http://www.w3.org/2000/svg">
                                        <path
                                                d="M21.2396 9.71982L21.857 9.10116C22.3485 8.60944 23.0152 8.33313 23.7105 8.33301C24.4058 8.33288 25.0726 8.60895 25.5643 9.10049C26.056 9.59202 26.3323 10.2588 26.3325 10.954C26.3326 11.6493 26.0565 12.3161 25.565 12.8078L24.9476 13.4265M21.2396 9.71982C21.2396 9.71982 21.317 11.0318 22.4756 12.1905C23.6343 13.3492 24.9476 13.4265 24.9476 13.4265M21.2396 9.71982L15.5596 15.3998C15.173 15.7838 14.981 15.9772 14.8156 16.1892C14.621 16.4398 14.4543 16.7092 14.317 16.9972C14.201 17.2398 14.1156 17.4972 13.9436 18.0132L13.393 19.6665L13.2143 20.2012M24.9476 13.4265L19.2676 19.1065C18.881 19.4932 18.689 19.6852 18.477 19.8505C18.2263 20.0452 17.957 20.2118 17.669 20.3492C17.4263 20.4652 17.169 20.5505 16.653 20.7225L14.9996 21.2732L14.465 21.4518M13.2143 20.2012L13.037 20.7372C12.9957 20.8616 12.9898 20.995 13.02 21.1226C13.0502 21.2502 13.1153 21.3668 13.208 21.4595C13.3007 21.5522 13.4173 21.6173 13.5449 21.6475C13.6724 21.6777 13.8059 21.6718 13.9303 21.6305L14.465 21.4518M13.2143 20.2012L14.465 21.4518"
                                                stroke="#9adada" stroke-width="1.5" />
                                        <path
                                                d="M7.66667 16.3332H11M7.66667 10.9998H16.3333M7.66667 21.6665H9.66667M23.4373 3.22917C21.876 1.6665 19.3613 1.6665 14.3333 1.6665H11.6667C6.63867 1.6665 4.124 1.6665 2.56267 3.22917C1 4.7905 1 7.30517 1 12.3332V17.6665C1 22.6945 1 25.2092 2.56267 26.7705C4.124 28.3332 6.63867 28.3332 11.6667 28.3332H14.3333C19.3613 28.3332 21.876 28.3332 23.4373 26.7705C24.696 25.5132 24.94 23.6398 24.988 20.3332"
                                                stroke="#9adada" stroke-width="1.5" stroke-linecap="round" />
                                      </svg>
                                    </b-link>
                                  </template>
                                  <template v-else-if="row.rev_status == 'REJECTED' || row.rev_status == 'APPROVED'">
                                    <b-link @click="showCompleteModal(row.revision_id, row.rev_status)"
                                            style="color: #9adada; font-size:large;">
                                      <svg width="23" height="25" viewBox="0 0 28 30" fill="none"
                                           xmlns="http://www.w3.org/2000/svg">
                                        <path
                                                d="M21.2396 9.71982L21.857 9.10116C22.3485 8.60944 23.0152 8.33313 23.7105 8.33301C24.4058 8.33288 25.0726 8.60895 25.5643 9.10049C26.056 9.59202 26.3323 10.2588 26.3325 10.954C26.3326 11.6493 26.0565 12.3161 25.565 12.8078L24.9476 13.4265M21.2396 9.71982C21.2396 9.71982 21.317 11.0318 22.4756 12.1905C23.6343 13.3492 24.9476 13.4265 24.9476 13.4265M21.2396 9.71982L15.5596 15.3998C15.173 15.7838 14.981 15.9772 14.8156 16.1892C14.621 16.4398 14.4543 16.7092 14.317 16.9972C14.201 17.2398 14.1156 17.4972 13.9436 18.0132L13.393 19.6665L13.2143 20.2012M24.9476 13.4265L19.2676 19.1065C18.881 19.4932 18.689 19.6852 18.477 19.8505C18.2263 20.0452 17.957 20.2118 17.669 20.3492C17.4263 20.4652 17.169 20.5505 16.653 20.7225L14.9996 21.2732L14.465 21.4518M13.2143 20.2012L13.037 20.7372C12.9957 20.8616 12.9898 20.995 13.02 21.1226C13.0502 21.2502 13.1153 21.3668 13.208 21.4595C13.3007 21.5522 13.4173 21.6173 13.5449 21.6475C13.6724 21.6777 13.8059 21.6718 13.9303 21.6305L14.465 21.4518M13.2143 20.2012L14.465 21.4518"
                                                stroke="#9adada" stroke-width="1.5" />
                                        <path
                                                d="M7.66667 16.3332H11M7.66667 10.9998H16.3333M7.66667 21.6665H9.66667M23.4373 3.22917C21.876 1.6665 19.3613 1.6665 14.3333 1.6665H11.6667C6.63867 1.6665 4.124 1.6665 2.56267 3.22917C1 4.7905 1 7.30517 1 12.3332V17.6665C1 22.6945 1 25.2092 2.56267 26.7705C4.124 28.3332 6.63867 28.3332 11.6667 28.3332H14.3333C19.3613 28.3332 21.876 28.3332 23.4373 26.7705C24.696 25.5132 24.94 23.6398 24.988 20.3332"
                                                stroke="#9adada" stroke-width="1.5" stroke-linecap="round" />
                                      </svg>
                                    </b-link>
                                  </template>
                                  <template v-else>
                                    <b-link @click="showCompleteModal(row.revision_id, row.rev_status)"
                                            style="color: #9adada; font-size:large;">
                                      <svg width="23" height="25" viewBox="0 0 28 30" fill="none"
                                           xmlns="http://www.w3.org/2000/svg">
                                        <path
                                                d="M21.2396 9.71982L21.857 9.10116C22.3485 8.60944 23.0152 8.33313 23.7105 8.33301C24.4058 8.33288 25.0726 8.60895 25.5643 9.10049C26.056 9.59202 26.3323 10.2588 26.3325 10.954C26.3326 11.6493 26.0565 12.3161 25.565 12.8078L24.9476 13.4265M21.2396 9.71982C21.2396 9.71982 21.317 11.0318 22.4756 12.1905C23.6343 13.3492 24.9476 13.4265 24.9476 13.4265M21.2396 9.71982L15.5596 15.3998C15.173 15.7838 14.981 15.9772 14.8156 16.1892C14.621 16.4398 14.4543 16.7092 14.317 16.9972C14.201 17.2398 14.1156 17.4972 13.9436 18.0132L13.393 19.6665L13.2143 20.2012M24.9476 13.4265L19.2676 19.1065C18.881 19.4932 18.689 19.6852 18.477 19.8505C18.2263 20.0452 17.957 20.2118 17.669 20.3492C17.4263 20.4652 17.169 20.5505 16.653 20.7225L14.9996 21.2732L14.465 21.4518M13.2143 20.2012L13.037 20.7372C12.9957 20.8616 12.9898 20.995 13.02 21.1226C13.0502 21.2502 13.1153 21.3668 13.208 21.4595C13.3007 21.5522 13.4173 21.6173 13.5449 21.6475C13.6724 21.6777 13.8059 21.6718 13.9303 21.6305L14.465 21.4518M13.2143 20.2012L14.465 21.4518"
                                                stroke="#9adada" stroke-width="1.5" />
                                        <path
                                                d="M7.66667 16.3332H11M7.66667 10.9998H16.3333M7.66667 21.6665H9.66667M23.4373 3.22917C21.876 1.6665 19.3613 1.6665 14.3333 1.6665H11.6667C6.63867 1.6665 4.124 1.6665 2.56267 3.22917C1 4.7905 1 7.30517 1 12.3332V17.6665C1 22.6945 1 25.2092 2.56267 26.7705C4.124 28.3332 6.63867 28.3332 11.6667 28.3332H14.3333C19.3613 28.3332 21.876 28.3332 23.4373 26.7705C24.696 25.5132 24.94 23.6398 24.988 20.3332"
                                                stroke="#9adada" stroke-width="1.5" stroke-linecap="round" />
                                      </svg>
                                    </b-link>
                                  </template>
                                </div>
                              </td>
                            </tr>
                          </template>
                        </dataset-item>
                      </table>
                    </div>
                  </div>
                </div>
                <div class="d-flex flex-md-row flex-column justify-content-between align-items-center"
                     style="color: #ffffff; font-family: Open Sans, sans-serif !important; font-size: 0.875rem;">
                  <dataset-info class="mb-2 mb-md-0" />
                  <dataset-pager />
                </div>
              </dataset>
            </div>
          </b-col>
        </b-row>


        <!-- MODAL WHEN DOCUMENT ALREADY REJECTED OR APPROVED -->
        <b-modal ref="complete-pasif-modal" size="xl" centered hide-footer title="Revision - Review" class="text-white">
          <b-row>
            <b-col>
              <label class="text-white" for="">Document Revision no.{{ this.revision_number }}</label>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <label class="text-white" for="">Submitted by {{ this.created_by.name}} on {{ this.revision_created_at }}</label>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <label class="text-white" for="">Comment</label>
              <b-form-textarea v-model="revision_comment" :disabled="!isActive" id="textarea-no-resize" placeholder=""
                               rows="3" no-resize></b-form-textarea>
            </b-col>
          </b-row>
          <br>
          <b-row>
            <b-col columns="6">
              <b-row>
                <b-col columns ="12">
                  <table style="width: 100%;">
                    <template v-if="uploadedFiles.length">
                      <thead
                              style="background-color: #23454A; color: white; font-size: small; border-bottom: 10px solid  #306b74;">
                      <th class="th-table-modal-new ">REVISION File(s) </th>
                      <th class="th-table-modal-new ">Size</th>
                      <th class="th-table-modal-new "></th>
                      </thead>
                      <tbody>
                      <tr v-for="(file, index) of uploadedFiles" :key="index"
                          style="background-color: #3a656b; color: white; font-size: smaller; border-bottom:  5px solid  #306b74;">
                        <td class="tr-td-table-modal-new"><i class="fa fa-file mr-1"></i> {{ file.filename }}</td>
                        <td class="tr-td-table-modal-new">{{ formatSize(file.size) }}</td>
                        <td class="tr-td-table-modal-new">
                          <div style="">
                            <a href="#" @click.prevent="downloadFile(file.id)">Download</a>
                          </div>
                        </td>
                      </tr>
                      </tbody>
                    </template>

                    <template v-if="review_uploadedFiles.length">
                      <thead
                              style="background-color: #23454A; color: white; font-size: small; border-bottom: 10px solid  #306b74;">
                      <th class="th-table-modal-new ">REVIEW File(s) </th>
                      <th class="th-table-modal-new ">Size</th>
                      <th class="th-table-modal-new "></th>
                      </thead>
                      <tbody>
                      <tr v-for="(file, index) of review_uploadedFiles" :key="index"
                          style="background-color: #3a656b; color: white; font-size: smaller; border-bottom:  5px solid  #306b74;">
                        <td class="tr-td-table-modal-new"><i class="fa fa-file mr-1"></i> {{ file.filename }}</td>
                        <td class="tr-td-table-modal-new">{{ formatSize(file.size) }}</td>
                        <td class="tr-td-table-modal-new">
                          <div style="">
                            <a href="#" @click.prevent="downloadFile(file.id)">Download</a>
                          </div>
                        </td>
                      </tr>




                      </tbody>
                    </template>

                  </table>
                </b-col>
              </b-row>
            </b-col>
            <b-col columns="6">
              <b-row>
                <b-col cols ="12">
                  <table style="width: 100%;">
                    <template>
                      <thead
                              style="background-color: #23454A; color: white; font-size: small; border-bottom: 10px solid  #306b74;">
                      <th class="th-table-modal-new ">Reviewer(s)</th>
                      <th class="th-table-modal-new ">Status</th>
                      <th class="th-table-modal-new ">&nbsp;
                      </th>
                      </thead>
                      <tbody>
                      <tr v-for="(role,index) in revision_reviewer_list" :key="index"
                          style="background-color: #3a656b; color: white; font-size: smaller; border-bottom:  5px solid  #306b74;">
                        <td class="tr-td-table-modal-new">
                          <i v-if="role.status == 'NEW_REVIEW' || role.status == 'APPROVED' || role.status == 'ACCEPTED'" class="fa fa-xs fa-circle mr-1" style="color: #46D275;"></i>
                          <i v-else-if="role.status == 'REJECTED'" class="fa fa-xs fa-circle mr-1" style="color: #FF3232;"></i>
                          <i v-else class="fa fa-xs fa-circle mr-1" style="color: white;"></i>
                          {{ role.roles.role_name }}</td>

                        <td v-if="role.status == 'NEW_REVIEW' || role.status == 'APPROVED' || role.status == 'ACCEPTED'" class="tr-td-table-modal-new">ACK. by {{ role.reviewed_by.name }} on {{ role.reviewed_at }}</td>
                        <td v-else-if="role.status == 'REJECTED'" class="tr-td-table-modal-new">REJECTED by {{ role.reviewed_by.name }} on {{ role.reviewed_at }}</td>
                        <td v-else class="tr-td-table-modal-new">NOT ACKNOWLEDGE</td>
                        <td class="tr-td-table-modal-new">&nbsp;</td>
                      </tr>
                      </tbody>
                    </template>
                  </table>
                </b-col>
                <b-col>
                </b-col>
              </b-row>
            </b-col>
          </b-row>

          <b-row class="mt-3">
            <b-col class="text-right">
              <b-button size="md" variant="outline-danger" @click="hideCompleteModal()">Cancel</b-button>
            </b-col>
          </b-row>
        </b-modal>
        <!-- MODAL WHEN DOCUMENT ALREADY REJECTED OR APPROVED -->
      </b-container>
    </div>


  </div>
</template>

<script>
    import { Table, TableColumn, Select, Option } from 'element-ui'
    import {
        LoginURL,
        clientId,
        clientSecret,
        apiDomain,
        getHeader,
        getcurrentDate,
    } from "@/assets/js/config.js";
    //import axios from "axios";
    import ErrorFunctionMixin from "@/assets/js/errorFunctionMix.js";
    import moment from "moment";
    import ProjectSiteTemplateViewForm from '@/views/SemCorp/Template/Project/ProjectSiteTemplateViewForm.vue';
    import axios from '@/util/axios';
    import { getAccessByModule } from '@/util/access';
    import { formatBytesInUnits, documentStatusToText, revisionStatusToText } from '../Contractor/util';

    import Dataset from '@/assets/js/customDataset.js';
    // import DarkStyle from '@/views/SemCorp/PMN/Style/Dark.vue';

    import {
        // Dataset,
        DatasetItem,
        DatasetInfo,
        DatasetPager,
        DatasetSearch,
        DatasetShow
    } from 'vue-dataset';

    export default {
        name: "Project Landing",
        mixins: [ErrorFunctionMixin],
        components: {
            // DarkStyle,
            [Select.name]: Select,
            [Option.name]: Option,
            [Table.name]: Table,
            [TableColumn.name]: TableColumn,
            // CustomDataset,
            Dataset,
            DatasetItem,
            DatasetInfo,
            DatasetPager,
            DatasetSearch,
            DatasetShow
        },
        watch: {
        },
        created() {
            const LoginUser = JSON.parse(window.localStorage.getItem("loginUser"));
            this.myUserID = LoginUser;
            this.pid = this.$route.query.proj_id;
            this.sid = this.$route.query.site_id;
            this.selectedDocID = this.$route.params.id;

            this.getDashboardData();
            // this.getSiteListing();
            this.uploadAccess = getAccessByModule('DOC_REPO_UPLOAD');
            this.reviewAccess = getAccessByModule('DOC_REPO_REVIEW');
            this.revision_history=[];
            this.loadRevisionsHistory();

        },
        data() {
            return {
                obj_data_grid: {
                    cols: [
                        {
                            name: 'Cat1',
                            field: 'category1_code',
                            sort: ''
                        },
                        {
                            name: 'Cat2',
                            field: 'category2_code',
                            sort: ''
                        },
                        {
                            name: 'Cat3',
                            field: 'category3_code',
                            sort: ''
                        },
                        {
                            name: 'Number',
                            field: 'number',
                            sort: ''
                        },
                        {
                            name: 'Title',
                            field: 'title',
                            sort: ''
                        },
                        {
                            name: 'Status',
                            field: 'status',
                            sort: ''
                        },
                        {}
                    ],
                },
                obj_data_history_grid: {
                    cols: [
                        {
                            name: 'REV ID',
                            field: 'revision_id',
                            sort: ''
                        },
                        {
                            name: 'ID',
                            field: 'id',
                            sort: ''
                        },
                        {
                            name: 'Rev.No',
                            field: 'revision_number',
                            sort: ''
                        },
                        {
                            name: 'Type',
                            field: 'type',
                            sort: ''
                        },
                        {
                            name: 'Status',
                            field: 'status',
                            sort: ''
                        },
                        {
                            name: 'Submitted By',
                            field: 'submitted_by',
                            sort: ''
                        },
                        {
                            name: 'Submission Date',
                            field: 'submission_date',
                            sort: ''
                        },
                        {}
                    ],
                },
                perPage: 5,
                currentPage: 1,

                items: [],
                items_file: [
                    {
                        id: 0,
                        file: "Testing",
                        Uploaded_by: "PNM",
                        Uploaded_at: "9 July 2024",
                        size: "1.32 MB",
                        status: "Pending",
                    },
                ],
                sites: [
                    { id: 1, name: "Site 1" },
                    { id: 2, name: "Site 2" },
                ],
                // role_user :"user_side", // contractor or user_side
                role_user: "contractor", // contractor or user_side
                filterOption: [
                    {
                        label: 5,
                        value: 5
                    },
                    {
                        label: 10,
                        value: 10
                    },
                    {
                        label: 25,
                        value: 25
                    },
                    {
                        label: 50,
                        value: 50
                    },
                ],
                all_data: [],
                selectedFilter: 5,
                file_uploads: false,
                rows: 15,
                model: {
                    project_id: null,
                    developer_id: "x",
                    project_type_id: "x",
                    project_manager: "x",
                    project_manager_name: "x",
                    project_engineer: "x",
                    project_engineer_name: "x",
                    project_business_development: "x",
                    project_business_development_name: "x",
                    project_safety: "x",
                    project_safety_name: "x",
                    project_name: null,
                    project_ppa_approved_capacity: null,
                    project_ppa_start_date: null,
                    project_watt_class: null,
                    roofed_handover_date: null,
                    project_tag: null,
                    site_id: null,
                    developers: [],
                    project_type: [],
                    project_managers: [],
                    project_engineers: [],
                    project_business_development: [],
                    project_safety: [],
                    site_status: [],
                    isSolarNovaProject: false,
                    items: [],
                },
                siteListingData: null,
                title: 'Document Tracking',
                pid: null,
                sid: null,
                selectedDocID: null,
                setting: {},
                categories1: [],
                categories2: [],
                categories3: [],
                selectedCategory1: null,
                selectedCategory2: null,
                selectedCategory3: null,
                obj_dd_list: {},
                obj_flag: {
                    docTemplateApplied: false,
                },
                obj_model: {
                    selected_template: null,
                },
                uploadAccess: {},
                reviewAccess: {},
                documents: [],
                access: {},
                document: {},
                revisions: [],
                revision: null,
                review: null,
                comment: null,
                files: [],
                uploadedFiles: [],
                review_uploadedFiles: [],
                revision_comment: null,
                review_comment: null,
                review_detail: null,
                revision_history:[],
                revision_number: null,
                revision_created_at: null,
                revision_reviewer:false,
                revision_reviewer_list:[],
                reviewer_id:null,
                review_id:null,
                reviewer_time:null,
                reviewer_status:null,
                reviewer_role_id:null,
                reviewer_final:false,
                created_by: {
                    id: null,
                    name:null,
                    email:null,
                },
                confirmDialogVisible: false,
                confirmDialogMessage: '',
                confirmDialogKind: '',
            };
        },
        methods: {

            //01. LOAD THE SITE INFORMATION
            getDashboardData() {
                axios
                    .get(apiDomain + "project/project_site/" + this.sid, {
                        headers: getHeader(),
                    })
                    .then((response) => {


                        if (response.status === 200) {

                            this.siteListingData = response.data.data[0];
                            this.access = getAccessByModule('DOC_REPO_REVIEW');
                            this.pid = this.siteListingData.project.project_id;
                            this.loadSetting();
                            this.loadCategories();
                            this.loadDocument();

                        }
                    })
                    .catch((error) => {
                        console.log(error.response.status);
                        if (error.response.status == 401) {
                            this.$router.push("/login");
                        }
                    });
            },

            //02. LOAD THE DOCUMENT DETAIL
            loadDocument() {
                axios.get(`doc_repo/p/${this.pid}/s/${this.sid}/document/${this.selectedDocID}`)
                    .then(response => {
                        this.document = response.data.data;
                        this.revision_reviewer_list = [];
                        for (let index = 0; index < this.document.roles.length; index++)
                        {
                            this.revision_reviewer_list.push({roles:this.document.roles[index],reviewed_by:null,reviewed_at:null,status:'PENDING REVIEW'});
                            //console.log(this.revision_reviewer);
                            if(this.myUserID.role_id == this.document.roles[index].role_id)
                            {
                                this.revision_reviewer = true;
                            }
                        }
                    })
                    .catch(error => {
                        this.errorFunction(error, this.title)
                    })
            },


            //03 LOAD REVISION LIST
            loadRevisionsHistory() {
                axios.get(`doc_repo/p/${this.pid}/s/${this.sid}/document/${this.selectedDocID}/revision`)
                    .then(response => {

                        const revisions = response.data.data;
                        //console.log("BEFORE");
                        //console.log(revisions);
                        // Group reviews into revisions.
                        const groups = revisions
                            .filter(x => x.version == 0)
                            .sort((a, b) => a.revision - b.revision)

                        for (const group of groups) {
                            group.isNew = group.status == 'NEW_REVISION'
                            group.isEditing = false

                            // Reviews.
                            group.reviews = revisions
                                .filter(x => x.revision == group.revision && x.version > 0)
                                .map(review => {
                                    review.isNew = review.status == 'NEW_REVIEW'
                                    review.isEditing = false

                                    return review
                                })
                                .sort((a, b) => a.version - b.version)
                        }

                        this.revisions = groups;
                        if(this.revisions.length>0)
                        {
                            this.revisions.forEach(item => {
                                this.revision_history.push({
                                    revision_id:item.id,
                                    id:item.id,
                                    revision_number:item.revision,
                                    type:"First Submission",
                                    status:item.status,
                                    rev_status:item.status,
                                    submitted_by:item.created_by.name,
                                    submission_date:item.created_at,
                                })
                                if(item.reviews.length>0)
                                {
                                    item.reviews.forEach(itemreview => {
                                        this.revision_history.push({
                                            revision_id:item.id,
                                            id:itemreview.id,
                                            revision_number:itemreview.revision,
                                            type:"Review",
                                            status:itemreview.status,
                                            rev_status:item.status,
                                            submitted_by:itemreview.created_by.name,
                                            submission_date:itemreview.created_at,
                                        })

                                    });
                                }
                            });
                        }

                        //console.log("REVISION HISTORY");
                        //console.log(this.revision_history);
                        this.revision = this.revisions.at(-1);
                        //console.log("REVISION");
                        //console.log(this.revision);
                        //console.log("UPLOADED FILES");
                        this.uploadedFiles = this.revision.uploads;
                        //console.log("REVIEWS");
                        //console.log(this.revision.reviews);
                        //console.log(this.uploadFiles);
                        this.revision_comment = this.revision.comment;
                        if (this.revision.reviews != null) {
                            this.review = this.revision.reviews[0];
                            this.review_uploadedFiles = this.review.uploads;
                            this.review_comment = this.review.comment;
                            this.review_detail = this.revision.status + " by " + this.review.created_by.name + " on " + this.review.created_at;
                        }
                        else this.review = null;
                        //console.log("current review");
                        //console.log(this.review);
                    })
                    .catch(error => {
                        this.errorFunction(error, this.title)
                    })
            },

//----------------------------------------------




            //02.SEE DOCUMENT REVISION WITH STATUS = PENDING REVIEW

            //02.01 OPEN POP UP WINDOW TO DISPLAY THE REVISION


            //02.02 GET THE REVIEWERS LIST FROM DOC
            listReviewers_loadDocument() {
                this.revision_reviewer_list = []; //EMPTY THE LIST
                this.revision_reviewer = false; //DISABLE ALL THE REVIEW BUTTON
                axios.get(`doc_repo/p/${this.pid}/s/${this.sid}/document/${this.selectedDocID}`)
                    .then(response => {
                        this.document = response.data.data;
                        for (let index = 0; index < this.document.roles.length; index++)
                        {
                            this.revision_reviewer_list.push({roles:this.document.roles[index],reviewed_by:null,reviewed_at:null,status:'PENDING REVIEW',review_id:null});
                            //console.log(this.revision_reviewer);
                            if(this.myUserID.role_id == this.document.roles[index].role_id)
                            {
                                this.revision_reviewer = true;
                            }
                        }
                        //console.log("REVIEWER List :");
                        //console.log(this.revision_reviewer_list);
                    })
                    .catch(error => {
                        this.errorFunction(error, this.title)
                    })
            },

            //02.03 GET THE REVISION TO PUT INSISDE THE POP UP FROM
            getRevisionData_loadRevisions(revID) {
                this.revisions.forEach(item => {
                    if(item.id == revID)
                    {
                        this.revision = item;
                    }
                });
                this.revision = this.revisions.at(-1);
                this.uploadedFiles = this.revision.uploads;
                this.revision_comment = this.revision.comment;
                this.revision_number = this.revision.revision;
                this.revision_created_at = this.revision.created_at;
                this.created_by = this.revision.created_by;
                if (this.revision.reviews != null)
                {
                    this.review = this.revision.reviews[0];
                    this.review_uploadedFiles = this.review.uploads;
                    this.review_comment = this.review.comment;
                    this.review_detail = this.revision.status   + " by " + this.review.created_by.name + " on " + this.review.created_at;
                    this.checkReviewerStatus();
                    this.checkFinalReviewer();
                }
                else this.review = null;
            },

            //02.04 CHECK THE REVIEW LIST AND COMPARE WITH CURRENT USER ID
            checkReviewerStatus()
            {
                this.revision.reviews.forEach(item => {
                    this.reviewer_id = item.created_by;
                    this.reviewer_time = item.created_at;
                    this.reviewer_status = item.status;
                    this.review_id = item.id;
                    this.getUserRoles();
                })
            },

            //02.05 CHECK IF LEFT WITH THE FINAL REVIEW
            checkFinalReviewer()
            {
                this.reviewer_final = true;
                this.revision_reviewer_list.forEach(item => {
                    if(item.status = "PENDING REVIEW")
                    {
                        this.reviewer_final = false;
                    }
                })
            },

            //02.06 GET THE USER ROLE
            getUserRoles() {
                //console.log("CHECK ROLE");

                axios.get(`setup/user/${this.reviewer_id.id}`,{} )
                    .then(response => {
                        const user_data = response.data.data[0];
                        this.revision_reviewer_list.forEach(item => {
                            if(user_data.role.role_id == item.roles.role_id)
                            {
                                item.status = this.reviewer_status;
                                item.reviewed_by = this.reviewer_id;
                                item.review_id = this.review_id;
                                item.reviewed_at = this.reviewer_time;
                                if(this.myUserID.role_id == item.roles.role_id)
                                {
                                    this.revision_reviewer = false;
                                }
                            }
                        })
                        //console.log();
                    })

                    .catch(error => {
                        this.errorFunction(error, this.title);

                    })

                    .finally(() => {
                        // Optionally, execute code that should run regardless of success or failure
                        //console.log('Request completed.');
                    });
            },





            checklastApprover()
            {
                let counter = 0;
                let role_id = 0;
                this.revision_reviewer_list.forEach(item => {
                    if(item.status = "PENDING REVIEW")
                    {
                        counter = counter + 1;
                        role_id = item.roles.role_id;
                    }
                });
                if(counter==1)
                {
                    if(this.myUserID.role_id == role_id)
                    {
                        this.approveRevision();
                    }
                }
                else
                {
                    this.approveRevision();
                }
                this.hidePasifModal();
            },

            //02.09 REJECT BUTTON IS CLICKED TO APPROVE THE DOCUMENT WITHOUT GIVING AND REVIEW FILE
            createReviewRejectWithoutAnyFeedback()
            {
                const body = {
                    comment: `No comment is given`,
                };
                axios.post(`doc_repo/p/${this.pid}/s/${this.sid}/document/${this.id}/review`, body)
                    .then(response => {
                        this.rejectRevision();
                        this.hidePasifModal();
                    })

                    .catch(error => {
                        this.errorFunction(error, this.title)
                    })

                    .finally(() => {
                        // Optionally, execute code that should run regardless of success or failure

                        //console.log('Request completed.');
                    });
            },

            //02.10 CREATE REVIEW BUTTON IS CLICKED TO APPROVE/REJECT THE DOCUMENT WITH REVIEW FILE

            //02.11 APPROVE/REJECT BUTTON IS CLICKED TO APPROVE/REJECT THE DOCUMENT WITH REVIEW FILE
            submitNewReview(action) {
                const body = {
                    comment: this.review_comment,
                }
                //console.log ("REVISION COMMENT before save");
                //console.log (this.revision_comment);
                axios.post(`doc_repo/p/${this.pid}/s/${this.sid}/document/${this.id}/review`, body)
                    .then(response => {
                        this.loadNewlyCreatedReview(action);
                    })
                    .catch(error => {
                        this.errorFunction(error, this.title)
                    })

                    .finally(() => {
                        //console.log('CREATE NEW REVIEW DONE');
                    });
            },

            //02.12.TO GET THE NEWLY CREATED REVIIEW
            loadNewlyCreatedReview(action) {
                //console.log ("REVISION COMMENT before search");
                //console.log (this.revision_comment);
                axios.get(`doc_repo/p/${this.pid}/s/${this.sid}/document/${this.id}/revision`)
                    .then(response => {
                        const revisions = response.data.data;
                        const groups = revisions
                            .filter(x => x.version == 0)
                            .sort((a, b) => a.revision - b.revision)

                        for (const group of groups) {
                            group.isNew = group.status == 'NEW_REVISION'
                            group.isEditing = false

                            // Reviews.
                            group.reviews = revisions
                                .filter(x => x.revision == group.revision && x.version > 0)
                                .map(review => {
                                    review.isNew = review.status == 'NEW_REVIEW'
                                    review.isEditing = false

                                    return review
                                })
                                .sort((a, b) => a.version - b.version)
                        }
                        this.revisions = groups;
                        this.revision = this.revisions.at(-1);
                        if (this.revision.reviews != null)
                        {
                            this.review = this.revision.reviews.at(-1);
                            this.review_uploadedFiles = this.review.uploads;
                            this.review_comment = this.review.comment;
                            //this.revision_comment = this.review.comment;
                            this.review_detail = this.revision.status   + " by " + this.review.created_by.name + " on " + this.review.created_at;
                        }
                        else this.review = null;
                        //console.log(this.revision);
                        //console.log ("REVISION COMMENT after save");
                        //console.log (this.revision_comment);
                        if(this.files.length > 0)
                        {
                            this.uploadNewReviewFiles(action);
                        }
                        else
                        {
                            this.updateReview(action);
                        }
                    })
                    .catch(error => {
                        this.errorFunction(error, this.title)
                    })

                    .finally(() => {
                        //console.log('GET NEW REVISION & UPLOAD THE FILES DONE');

                    });
            },

            //02.13.TO SAVE THE FILES IN NEWLY CREATED REVISION
            uploadNewReviewFiles(action)
            {
                //console.log("NEW REVIEW");
                //console.log(this.review);
                const data = new FormData();
                //console.log("List of files");
                //console.log(this.files);
                this.files.forEach(file => data.append('files[]', file))
                //console.log("List of data");
                //console.log(data);
                axios.post(`doc_repo/p/${this.pid}/s/${this.sid}/document/${this.id}/revision/${this.review.id}/upload`, data)
                    .then(response => {
                        this.files.splice(0)
                        //this.loadRevisions()

                        this.$notify({
                            message: 'Files Uploaded',
                            timeout: 5000,
                            icon: 'ni ni-bell-55',
                            type: 'default',
                        });

                        //this.updateRevision();

                        //console.log("UPDATE REVIEW");
                        //this.submitForReview();
                        //console.log ("REVISION COMMENT Upload");
                        //console.log (this.revision_comment);
                        this.updateReview(action);

                    })
                    .catch(error => {
                        this.errorFunction(error, this.title)
                    })

                    .finally(() => {
                        // Optionally, execute code that should run regardless of success or failure
                        //console.log('Request completed.');
                    });
            },

            //02.14 Update Review
            updateReview(action) {
                //console.log ("REVISION COMMENT LAST");
                //console.log (this.review_comment);
                const body = {
                    comment: this.review_comment,
                }

                axios.put(`doc_repo/p/${this.pid}/s/${this.sid}/document/${this.id}/review/${this.review.id}`, body)
                    .then(response => {
                        //this.loadRevisions()

                        this.$notify({
                            message: 'Review Updated',
                            timeout: 5000,
                            icon: 'ni ni-bell-55',
                            type: 'default',
                        });

                        if (action == "APPROVE") {
                            this.approveRevision();
                        }
                        else {
                            this.rejectRevision();
                        }
                        this.hideReviewModal();

                    })
                    .catch(error => {
                        this.errorFunction(error, this.title)
                    })

                    .finally(() => {
                        // Optionally, execute code that should run regardless of success or failure
                        //console.log('Request completed.');
                    });
            },


            //03. TO OPEN MODAL FOR APPROVE/REJECTED REVISION


            //03.01 OPEN THE MODAL
            showCompleteModal(revID, docStatus) {
                this.$refs['complete-pasif-modal'].show();
                this.getCompleteRevisionData_loadRevisions(revID);
                this.files = [];
            },




            //03.02 LOAD REVISION/REVIEWS
            getCompleteRevisionData_loadRevisions(revID) {
                this.revisions.forEach(item => {
                    if(item.id == revID)
                    {
                        this.revision = item;
                    }
                });
                this.uploadedFiles = this.revision.uploads;
                this.revision_comment = this.revision.created_at + " - " + this.revision.created_by.name + ": \n" + this.revision.comment;
                this.revision_number = this.revision.revision;
                this.revision_created_at = this.revision.created_at;
                this.created_by = this.revision.created_by;
                if (this.revision.reviews.length > 0)
                {
                    this.review_uploadedFiles = [];
                    this.review = this.revision.reviews[0];
                    for (let i = 0; i < this.revision.reviews.length; i++) {
                        for (let j = 0; j < this.revision.reviews[i].uploads.length; j++) {
                            this.review_uploadedFiles.push(this.revision.reviews[i].uploads[j]);
                        }
                    }
                    this.review_comment = this.review.comment;
                    this.review_detail = this.revision.status   + " by " + this.review.created_by.name + " on " + this.review.created_at;
                    this.checkReviewerStatusPlusComment();
                }
                else this.review = null;
            },


            //03.03 CHECK REVIEWERS STATUS AND COMBINE COMMMENTS
            checkReviewerStatusPlusComment()
            {
                this.revision.reviews.forEach(item => {
                    this.reviewer_id = item.created_by;
                    this.reviewer_time = item.created_at;
                    this.reviewer_status = item.status;
                    if(item.status == "ACCEPTED")
                    {
                        this.revision_comment = this.revision_comment + "\n \n" + item.created_at + " - ACKNOWLEDGEMENT comment by " + item.created_by.name + ": \n" + item.comment;
                    }
                    else
                    {
                        this.revision_comment = this.revision_comment + "\n \n" + item.created_at + " - " + item.status + " comment by " + item.created_by.name + ": \n" + item.comment;
                    }
                    this.compareUserRoles();
                })
            },

            compareUserRoles() {
                //console.log("come here");
                //console.log(this.revision_reviewer_list);
                this.revision_reviewer_list.forEach(item => {
                    if(item.roles.role_id == this.reviewer_id.role_id)
                    {
                        //console.log("yes got the same");
                        item.status = this.reviewer_status;
                        item.reviewed_by = this.reviewer_id;
                        item.review_id = this.review_id;
                        item.reviewed_at = this.reviewer_time;
                        if(this.myUserID.role_id == item.roles.role_id)
                        {
                            this.revision_reviewer = false;
                        }
                    }
                })
            },

            //03.04 CREATE NEW REVISION AFTER APPROVED OR REJECTED
            showModalNewRevisionAfterComplete() {
                this.$refs['complete-pasif-modal'].hide();
                this.$refs['contractor-modal'].show()
                //this.id = docID;
                this.revision_comment = null;
                this.files=[];
                this.uploadedFiles = [];
                this.uploadedFilesReview = [];
                this.loadDocument();
                this.files = [];
            },




//---------------------------------------------




            checkDocStatus() {
                if (this.revision.status == "REJECTED") {
                    return false;
                }
                else {
                    return true;
                }
            },


            removeFile(index) {
                this.files.splice(index, 1)
            },
            //_ load setting document and initiate data grid columns
            loadSetting() {
                axios.get(`doc_repo/p/${this.pid}/s/${this.sid}/setting`)
                    .then(response => {
                        this.setting = response.data.data

                        //_ initiate data grid columns
                        this.obj_data_grid.cols[0].name = this.setting.category1_name;
                        this.obj_data_grid.cols[1].name = this.setting.category2_name;
                        if (this.setting.category3_enabled) {
                            this.obj_data_grid.cols[2].name = this.setting.category3_name;
                        } else {
                            this.$delete(this.obj_data_grid.cols, 2);
                        }
                    })
                    .catch(error => {
                        this.errorFunction(error, this.title)
                    })
            },

            loadCategories() {
                axios.get(`doc_repo/p/${this.pid}/s/${this.sid}/category`)
                    .then(response => {
                        const categories = response.data.data
                        this.categories1 = categories.filter(x => x.level == 1)
                        this.categories2 = categories.filter(x => x.level == 2)
                        this.categories3 = categories.filter(x => x.level == 3)

                        //_ add filter -- ALL --
                        this.categories1 = this.setupAllInsideCategories(this.categories1);
                        this.categories2 = this.setupAllInsideCategories(this.categories2);
                        this.categories3 = this.setupAllInsideCategories(this.categories3);
                    })
                    .catch(error => {
                        this.errorFunction(error, this.title)
                    })
            },

            loadDocuments() {
                const options = {
                    params: {
                        category1_id: this.selectedCategory1,
                        category2_id: this.selectedCategory2,
                        category3_id: this.selectedCategory3,
                    },
                }

                axios.get(`doc_repo/p/${this.pid}/s/${this.sid}/document`, options)
                    .then(response => {
                        this.documents = response.data.data;

                        this.obj_flag.docTemplateApplied =
                            this.documents.length > 0 ? true : false;
                    })
                    .catch(error => {
                        this.errorFunction(error, this.title)
                    })
            },





            confirmDialog(message, kind, callback) {
                //console.log("1");
                this.confirmDialogMessage = message
                //console.log("2");
                this.confirmDialogKind = kind
                //console.log("3");
                this.confirmDialogCallback = callback
                //console.log("4");
                this.confirmDialogVisible = true
                //console.log("5");
            },

            hideConfirmDialog() {
                this.confirmDialogVisible = false
            },

            confirmDialogAccept() {
                if (typeof this.confirmDialogCallback === 'function') {
                    this.confirmDialogCallback(() => this.hideConfirmDialog())
                }
            },

            loadRevisions() {
                axios.get(`doc_repo/p/${this.pid}/s/${this.sid}/document/${this.id}/revision`)
                    .then(response => {

                        const revisions = response.data.data;
                        //console.log("BEFORE");
                        //console.log(revisions);
                        // Group reviews into revisions.
                        const groups = revisions
                            .filter(x => x.version == 0)
                            .sort((a, b) => a.revision - b.revision)

                        for (const group of groups) {
                            group.isNew = group.status == 'NEW_REVISION'
                            group.isEditing = false

                            // Reviews.
                            group.reviews = revisions
                                .filter(x => x.revision == group.revision && x.version > 0)
                                .map(review => {
                                    review.isNew = review.status == 'NEW_REVIEW'
                                    review.isEditing = false

                                    return review
                                })
                                .sort((a, b) => a.version - b.version)
                        }

                        this.revisions = groups;
                        //console.log("AFTER");
                        //console.log(this.revisions);
                        this.revision = this.revisions.at(-1);
                        //console.log("REVISION");
                        //console.log(this.revision);
                        //console.log("UPLOADED FILES");
                        this.uploadedFiles = this.revision.uploads;
                        //console.log("REVIEWS");
                        //console.log(this.revision.reviews);
                        //console.log(this.uploadFiles);
                        this.revision_comment = this.revision.comment;
                        if (this.revision.reviews != null) {
                            this.review = this.revision.reviews[0];
                            this.review_uploadedFiles = this.review.uploads;
                            this.review_comment = this.review.comment;
                            this.review_detail = this.revision.status + " by " + this.review.created_by.name + " on " + this.review.created_at;
                        }
                        else this.review = null;
                        //console.log("current review");
                        //console.log(this.review);
                    })
                    .catch(error => {
                        this.errorFunction(error, this.title)
                    })
            },

            formatSize(size) {
                return formatBytesInUnits(size)
            },

            showFileDialog() {
                //document.getElementById("file_upload").click();
                this.$refs.file_upload.click();
            },

            currentFileChanged(event) {
                const input = event.target
                this.files.push(...input.files)
                input.value = ''
            },

            //_ custom methods
            //  > de-structure the array and insert at the beginning an -- ALL -- option
            setupAllInsideCategories(theCategory) {
                return [
                    {
                        id: null,
                        code: "-- ALL --",
                        name: "-- ALL --"
                    },
                    ...theCategory
                ];
            },
            customFormatNumber(theData) {
                let retVal = parseFloat(theData.replace(/,/g, ""));

                return retVal;
            },
            customFormatDate(theData) {
                let checkDate = theData !== null ? theData : "N/A";
                if (checkDate !== "N/A") {
                    let convertCheckDate = new Date(checkDate);
                    const year = convertCheckDate.getFullYear();
                    const month = convertCheckDate.toLocaleString(
                        "en-US",
                        { month: "short" }
                    );
                    const day = convertCheckDate.toLocaleString("en-US", {
                        day: "2-digit",
                    });

                    return `${day}-${month}-${year}`;
                }
            },

            getSiteListing() {
                axios.get(apiDomain + 'project/project_site.show_by_project/' + this.model.project_id, { headers: getHeader() })
                    .then(response => {
                        // console.log('getSiteListing');
                        // console.log(response.data);
                        if (response.status === 200) {
                            let data = response.data.data;
                            //data.forEach(item => {
                            //    item.visible = false;
                            //});
                            this.siteListingData = data;
                            this.pid = this.$route.params.id;
                            this.sid = this.siteListingData[0].site_id;
                            this.access = getAccessByModule('DOC_REPO_REVIEW');
                            //this.getSiteListing();

                            this.loadSetting();
                            this.loadCategories();
                            this.loadDocuments();
                        }
                    })
                    .catch(error => {
                        console.log(error.response.status)
                    });
            },

            getDataTemplate() {
                // console.log('getDataTemplate');

                axios.get(apiDomain + 'setup/doc_repo/template', { headers: getHeader() })
                    .then(response => {
                        if (response.status === 200) {
                            this.obj_dd_list.doc_template = response.data.data;
                        }
                    })
                    .catch(error => {
                        console.log(error.response.status)
                    });
            },
            modalTemplateSubmit() {
                if (this.obj_model.selected_template === null) {
                    this.$notify({
                        message:
                            '<b>Invalid Input : </b> Please select a Document Template.',
                        timeout: 10000,
                        icon: 'ni ni-bulb-61',
                        type: 'danger',
                    });
                } else {
                    // console.log(this.pid + ' <vs> ' + this.sid);
                    // console.log(this.obj_model.selected_template);

                    const prm_body = {
                        template_id: this.obj_model.selected_template,
                    }

                    axios.post(`doc_repo/p/${this.pid}/s/${this.sid}/setting`, prm_body)
                        .then(response => {
                            this.$router.go()
                        })
                        .catch(error => {
                            this.errorFunction(error, this.title)
                        })
                }
            },
            //_ modal --------
            showModal(docID, docStatus) {
                this.$refs['contractor-modal'].show()
                this.id = docID;
                this.loadDocument();
                if (docStatus == "EMPTY") {
                    this.createRevision();
                }
                else this.loadRevisions();
                this.files = [];
            },

            showModalAfterComplete() {
                this.hideCompleteModal();
                this.$refs['contractor-modal'].show()
                this.loadDocument();
                this.createRevision();
                this.loadRevisions();
                this.files = [];
            },

            hideModal() {
                this.$refs['contractor-modal'].hide()
                this.reload();
            },

            hideCompleteModal() {
                this.$refs['complete-pasif-modal'].hide()
                this.reload();
            },

            //_ workflow
            reload() {
                this.loadDocuments();
                //this.loadRevisions();
            },

            generateData() {
                this.all_data = [];
                var status = ["Approve", "Pending", "New", "Rejected", "Being Reviewed", "Empty"];
                for (let index = 1; index <= this.rows; index++) {
                    var status_num = 0;
                    if (index % 2 == 0) {
                        status_num = 1;
                    }
                    var name = "Test " + index.toString()
                    this.all_data.push({
                        id: index,
                        cat_a: "Eng",
                        cat_b: "DR",
                        cat_c: "DR",
                        number: "XXX-00-ENG-DR-0001",
                        title: name,
                        alt_title: "UI/UX",
                        status: status[Math.floor(Math.random() * 5)],
                        created_at: "24 Juni 2024"
                    })
                }
            },



            createRevision() {
                axios.post(`doc_repo/p/${this.pid}/s/${this.sid}/document/${this.id}/revision`, {})
                    .then(response => {
                        this.loadRevisions();
                    })
                    .catch(error => {
                        this.errorFunction(error, this.title)
                    })
            },

            canCreateReview() {
                //console.log("Check Can Create Review");
                //console.log("Review Access : ");
                //console.log(this.reviewAccess.create);
                //console.log("Revision Status : ");
                //console.log(this.revision.status);
                return this.reviewAccess.create && this.revision.status == 'PENDING_REVIEW'
            },


            updateRevision() {

                const body = {
                    comment: this.revision_comment,
                }

                axios.put(`doc_repo/p/${this.pid}/s/${this.sid}/document/${this.id}/revision/${this.revision.id}`, body)
                    .then(response => {
                        //this.loadRevisions()

                        this.$notify({
                            message: 'Review Updated',
                            timeout: 5000,
                            icon: 'ni ni-bell-55',
                            type: 'default',
                        });

                        this.submitForReview();
                    })
                    .catch(error => {
                        this.errorFunction(error, this.title)
                    })

                    .finally(() => {
                        // Optionally, execute code that should run regardless of success or failure
                        //console.log('Request completed.');
                    });

            },

            submitForReview() {

                //console.log(this.revision_comment);
                const body = {
                    comment: "Test comment here",
                }

                axios.put(`doc_repo/p/${this.pid}/s/${this.sid}/document/${this.id}/revision/${this.revision.id}/submit`, body)
                    .then(response => {
                        this.reload()

                        this.$notify({
                            message: 'Revision Submitted',
                            timeout: 5000,
                            icon: 'ni ni-bell-55',
                            type: 'default',
                        });
                    })
                    .catch(error => {
                        this.errorFunction(error, this.title)
                    })
                this.hideModal();
            },


            downloadFile(id) {
                axios.post(`doc_repo/token?value=${id}`)
                    .then(response => {
                        const token = response.data
                        const url = `${apiDomain}doc_repo/download/${id}?token=${token}`

                        window.open(url, '_blank')
                    })
                    .catch(error => {
                        this.errorFunction(error, this.title)
                    })
            },

            uploadFiles() {
                const data = new FormData();
                //console.log("List of files");
                //console.log(this.files);
                this.files.forEach(file => data.append('files[]', file))
                //console.log("List of data");
                //console.log(data);
                axios.post(`doc_repo/p/${this.pid}/s/${this.sid}/document/${this.id}/revision/${this.revision.id}/upload`, data)
                    .then(response => {
                        this.files.splice(0)
                        this.loadRevisions()

                        this.$notify({
                            message: 'Files Uploaded',
                            timeout: 5000,
                            icon: 'ni ni-bell-55',
                            type: 'default',
                        });

                        this.updateRevision();

                    })
                    .catch(error => {
                        this.errorFunction(error, this.title)
                    })

                    .finally(() => {
                        // Optionally, execute code that should run regardless of success or failure
                        //console.log('Request completed.');
                    });
            },

            uploadFilesReview(action) {
                const data = new FormData();
                //console.log("List of files");
                //console.log(this.files);
                this.files.forEach(file => data.append('files[]', file))
                //console.log("List of data");
                //console.log(data);
                //console.log("SHOW DOC ID");
                //console.log(this.id);
                //console.log("SHOW REVISION");
                //console.log(this.revision);
                this.review = this.revision.reviews[0];
                //console.log("SHOW REVIEW ID");
                //console.log(this.review);
                axios.post(`doc_repo/p/${this.pid}/s/${this.sid}/document/${this.id}/revision/${this.review.id}/upload`, data)
                    .then(response => {
                        this.files.splice(0)
                        this.loadRevisions()

                        this.$notify({
                            message: 'Files Uploaded',
                            timeout: 5000,
                            icon: 'ni ni-bell-55',
                            type: 'default',
                        });

                        this.updateReview(action);

                    })
                    .catch(error => {
                        this.errorFunction(error, this.title)
                    })

                    .finally(() => {
                        // Optionally, execute code that should run regardless of success or failure
                        //console.log('Request completed.');
                    });
            },

            approveRevision() {
                //console.log("APPROVE REVISION");
                //console.log(this.pid);
                //console.log(this.sid);
                //console.log(this.id);
                //console.log(this.revision);
                axios.put(`doc_repo/p/${this.pid}/s/${this.sid}/document/${this.id}/revision/${this.revision.id}/approve`)
                    .then(response => {
                        this.reload()

                        this.$notify({
                            message: 'Revision Approved',
                            timeout: 5000,
                            icon: 'ni ni-bell-55',
                            type: 'default',
                        });
                    })
                    .catch(error => {
                        this.errorFunction(error, this.title)
                    })
            },

            approveRevisionWOReview() {
                this.loadRevisions();
                this.approveRevision();
                this.hidePasifModal();
            },

            rejectRevisionWOReview() {
                this.loadRevisions();
                this.rejectRevision();
                this.hidePasifModal();
            },

            rejectRevision() {
                //console.log("REJECT REVISION");
                //console.log(this.pid);
                //console.log(this.sid);
                //console.log(this.id);
                //console.log(this.revision);
                axios.put(`doc_repo/p/${this.pid}/s/${this.sid}/document/${this.id}/revision/${this.revision.id}/reject`)
                    .then(response => {
                        this.reload()

                        this.$notify({
                            message: 'Revision Rejected',
                            timeout: 5000,
                            icon: 'ni ni-bell-55',
                            type: 'default',
                        });
                    })
                    .catch(error => {
                        this.errorFunction(error, this.title)
                    })
            },

        },
        mounted() {
            this.getDataTemplate();
        }
    };
    //mounted() {
    //this.generateData();
    // this.perPage;
    //this.getPagination();

    //}
    //};
</script>


<style scoped>
  .card {
    background-color: #082a2f;
  }
</style>

<style>
  .modal-content {
    background-color: #306b74;
  }

  .modal-header {
    padding-bottom: 0px;
  }

</style>

<style scoped>
  table thead th {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    font-size: 0.65rem;
    text-transform: uppercase;
    letter-spacing: 1px;
    background-color: #3a656b;
    color: white;
    padding: 20px;
    border-bottom: 0px solid #082A2F;
    border-top: 0px solid #e9ecef;
  }

  .th-table-modal-new {
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 10px;
  }

  .tr-td-table-modal-new {
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 10px;
  }

  .th-table-new {
    padding-top: 30px;
    padding-bottom: 30px;
    padding-left: 20px;
  }

  .tr-td-table-new {
    padding-top: 25px;
    padding-bottom: 25px;
    padding-left: 20px;
  }

  .form-control {
    background-color: #2C545A;
    border-color: #2C545A;
    color: white;

  &:focus {
     background-color: #2C545A;
     border-color: #2C545A;
     color: white;
   }

  &::placeholder {
     color: white;
   }
  }

  .form-control {
  &:focus {
  &::placeholder {
     color: white;
   }
  }
  }

  /deep/ .form-control {
    background-color: #2C545A;
    border-color: #2C545A;
    color: white;

  &:focus {
     background-color: #2C545A;
     border-color: #2C545A;
     /* color: white; */
   }

  &::placeholder {
     color: white;
   }
  }

  /deep/ .form-control {
  &:focus {
  &::placeholder {
     color: white;
   }
  }
  }

  select {
    background: url("data:image/svg+xml,<svg width='10' height='7' viewBox='0 0 10 7' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M5.02552 6.35057L0.320068 0.46875H9.73098L5.02552 6.35057Z' fill='white'/></svg>") no-repeat;
    background-position: calc(100% - 0.75rem) center !important;
    -moz-appearance: none !important;
    -webkit-appearance: none !important;
    appearance: none !important;
    padding-right: 2rem !important;
  }
</style>


<style scoped>
  .pagination {
    display: flex;
    padding-left: 0;
    list-style: none;
    border-radius: 0.25rem;
    color: black;
  }

  .page-link {
    position: relative;
    display: block;
    padding: 0.5rem 0.75rem;
    margin-left: -1px;
    line-height: 1.25;
    color: #345f66;
    background-color: #fff;
    border: 1px solid #dee2e6;
  }

  .page-link:hover {
    z-index: 2;
    color: #345f66;
    text-decoration: none;
    background-color: #e9ecef;
    border-color: #dee2e6;
  }

  .page-link:focus {
    z-index: 3;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(62, 175, 124, 0.25);
  }

  .page-item:first-child .page-link {
    margin-left: 0;
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
  }

  .page-item:last-child .page-link {
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
  }

  .page-item.active .page-link {
    z-index: 3;
    color: #fff;
    background-color: #345f66;
    border-color: #3a656b;
  }

  .page-item.disabled .page-link {
    color: #6c757d;
    pointer-events: none;
    cursor: auto;
    background-color: #fff;
    border-color: #dee2e6;
  }

  .pagination-lg .page-link {
    padding: 0.75rem 1.5rem;
    font-size: 1.25rem;
    line-height: 1.5;
  }

  .pagination-lg .page-item:first-child .page-link {
    border-top-left-radius: 0.3rem;
    border-bottom-left-radius: 0.3rem;
    width: 60px;
  }

  .pagination-lg .page-item:last-child .page-link {
    border-top-right-radius: 0.3rem;
    border-bottom-right-radius: 0.3rem;
  }

  .pagination-sm .page-link {
    padding: 0.25rem 0.5rem;
    font-size: 0.875rem;
    line-height: 1.5;
  }

  .pagination-sm .page-item:first-child .page-link {
    border-top-left-radius: 0.2rem;
    border-bottom-left-radius: 0.2rem;
  }

  .pagination-sm .page-item:last-child .page-link {
    border-top-right-radius: 0.2rem;
    border-bottom-right-radius: 0.2rem;
  }

  .b-pagination-pills .page-item .page-link {
    border-radius: 50rem !important;
    margin-left: 0.25rem;
    line-height: 1;
  }

  .b-pagination-pills .page-item:first-child .page-link {
    margin-left: 0;
  }
</style>

<style scoped>
  div.dataset-style select {
    color: #ffffff;
    background-color: #2C545A;
    border-color: #2C545A;
  }

  div.dataset-style select.form-control {
    color: #ffffff;
    background-color: #2C545A;
    border-color: #2C545A;
  }

  div.dataset-style select>option {
    color: #ffffff;
    background-color: #2C545A;
    border-color: #2C545A;
  }

  h5 {
    color: #ffffff;
  }
</style>