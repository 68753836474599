/**
 * Returns the access object specified by key.
 * @param {string} key Key to read.
 * @returns {object} Access object.
 */
export function getAccessByKey(key) {
  const str = localStorage.getItem(key)
  const access = JSON.parse(str)

  // Set boolean flags for easier usage in UI.
  access.listing = access.access_listing == 'YES'
  access.create  = access.access_create  == 'YES'
  access.show    = access.access_show    == 'YES'
  access.edit    = access.access_edit    == 'YES'
  access.delete  = access.access_delete  == 'YES'

  return access
}

/**
 * Returns the specified modules' access object.
 * @param {...string} codes Module codes.
 * @returns {object} Access object.
 */
export function getAccessByModule(...codes) {
  const accesses = codes.map(code => {
    const key = code.toLowerCase() + '_access'
    return getAccessByKey(key)
  })

  return mergeAccess(...accesses)
}

/**
 * Merges specified access objects into a new object.
 * Accesses are merged using || (or) operator.
 * @param {...object} accesses Access objects to merge.
 * @returns {object} Access object. The newly merged access object has only boolean flags.
 */
function mergeAccess(...accesses) {
  const access = accesses.reduce((previous, current) => {
    return {
      listing: previous.listing || current.listing,
      create:  previous.create  || current.create,
      show:    previous.show    || current.show,
      edit:    previous.edit    || current.edit,
      delete:  previous.delete  || current.delete,
    }
  }, {})

  return access
}
