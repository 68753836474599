<template>
  <div>
    <base-header class="pb-6">
      <b-row class="py-4">
        <b-col cols="6">
          <h6 class="h2 text-white d-inline-block mb-0">{{ title }}</h6>
        </b-col>
        <b-col cols="6" class="text-right">
          <base-button size="sm" type="neutral" @click="$router.replace('../Listing')">Back</base-button>
        </b-col>
      </b-row>
    </base-header>

    <b-container fluid class="mt--6">
      <div class="card-wrapper">
        <card>
          <!-- Card header -->
          <h3 slot="header" class="mb-0">Edit Document</h3>
          <validation-observer v-slot="{ handleSubmit }">
            <form class="needs-validation" @submit.prevent="handleSubmit(onSubmit)">

              <b-form-row>
                <b-col>
                  <validation-provider :name="template.category1_name" rules="required" v-slot="{ classes, errors }">
                    <b-form-group :label="template.category1_name" :invalid-feedback="errors[0]">
                      <select class="form-control" :class="classes" v-model="model.category1_id">
                        <option v-for="item of categories1" :key="item.id" :value="item.id">{{ item.code }} - {{ item.name }}</option>
                      </select>
                    </b-form-group>
                  </validation-provider>
                </b-col>

                <b-col>
                  <validation-provider :name="template.category2_name" rules="required" v-slot="{ classes, errors }">
                    <b-form-group :label="template.category2_name" :invalid-feedback="errors[0]">
                      <select class="form-control" :class="classes" v-model="model.category2_id">
                        <option v-for="item of categories2" :key="item.id" :value="item.id">{{ item.code }} - {{ item.name }}</option>
                      </select>
                    </b-form-group>
                  </validation-provider>
                </b-col>
              </b-form-row>

              <b-form-row v-if="template.category3_enabled">
                <b-col>
                  <validation-provider :name="template.category3_name" rules="required" v-slot="{ classes, errors }">
                    <b-form-group :label="template.category3_name" :invalid-feedback="errors[0]">
                      <select class="form-control" :class="classes" v-model="model.category3_id">
                        <option v-for="item of categories3" :key="item.id" :value="item.id">{{ item.code }} - {{ item.name }}</option>
                      </select>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col></b-col>
              </b-form-row>

              <b-form-row>
                <b-col>
                  <validation-provider name="Title" rules="required" v-slot="{ classes, errors }">
                    <b-form-group label="Title" :invalid-feedback="errors[0]">
                      <input type="text" class="form-control" :class="classes" v-model="model.title" maxlength="300">
                    </b-form-group>
                  </validation-provider>
                </b-col>

                <b-col>
                  <validation-provider name="Alternative Title" v-slot="{ classes, errors }">
                    <b-form-group label="Alternative Title" :invalid-feedback="errors[0]">
                      <input type="text" class="form-control" :class="classes" v-model="model.alt_title" maxlength="300">
                    </b-form-group>
                  </validation-provider>
                </b-col>
              </b-form-row>

              <b-form-row>
                <b-col>
                  <validation-provider name="Position" rules="required" v-slot="{ classes, errors }">
                    <b-form-group label="Position" :invalid-feedback="errors[0]">
                      <select class="form-control" :class="classes" v-model="model.position">
                        <option :value="0">First</option>
                        <option v-for="item in documents" :key="item.id" :value="item.sequence">After: {{ item.title }}</option>
                      </select>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col>
                  <validation-provider name="Reviewer/Approver Roles" rules="required" v-slot="{ errors }">
                    <b-form-group label="Reviewer/Approver Roles" :invalid-feedback="errors[0]">
                      <b-form-checkbox-group v-model="selectedRoles" :options="roles" stacked :state="errors[0] ? false : null" class="border rounded p-2" />
                      <b-form-invalid-feedback :state="errors[0] ? false : null">{{ errors[0] }}</b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
              </b-form-row>

              <div class="d-flex align-items-baseline justify-content-between">
                <div>
                  <button class="btn btn-primary" v-if="access.edit">Update</button>
                  <button class="btn btn-primary" type="button" @click="$router.replace('../Listing')">Cancel</button>
                </div>
                <button class="btn btn-danger" type="button" v-if="access.delete" @click="showConfirmDelete = true">Delete</button>
              </div>
            </form>
          </validation-observer>
        </card>
      </div>
    </b-container>

    <modal :show.sync="showConfirmDelete" size="sm" body-classes="p-0">
      <card type="secondary"
            header-classes="bg-transparent pb-5"
            body-classes="px-lg-5 py-lg-5"
            class="border-0 mb-0">
        <div class="text-center text-muted mb-4">
          <h3>Delete this Document?</h3>
        </div>
        <div class="text-center">
          <button class="btn btn-danger" @click="deleteRecord">YES</button>
          <button class="btn btn-dark" @click="showConfirmDelete = false">NO</button>
        </div>
      </card>
    </modal>
  </div>
</template>

<script>
import ErrorFunctionMixin from '@/assets/js/errorFunctionMix.js'
import axios from '@/util/axios'
import { getAccessByModule } from '@/util/access'

export default {
  mixins: [ErrorFunctionMixin],

  data() {
    return {
      title: 'Document Tracking Templates',
      template: {},
      categories1: [],
      categories2: [],
      categories3: [],
      documents: [],
      roles: [],
      selectedRoles: [],
      model: {},
      access: {},
      showConfirmDelete: false,
    }
  },

  watch: {
    ['model.category1_id']() {
      this.loadDocuments()
    },
  },

  created() {
    this.tid = this.$route.params.tid
    this.id = this.$route.params.id
    this.access = getAccessByModule('DOC_REPO')

    this.loadTemplate()
    this.loadCategories()
    this.loadRoles()
    this.loadRecord()
  },

  methods: {
    loadRecord() {
      axios.get(`setup/doc_repo/template/${this.tid}/document/${this.id}`)
        .then(response => {
          this.model = response.data.data
          this.model.position = this.model.sequence - 1
          this.selectedRoles = this.model.roles.map(x => x.role_id)
        })
        .catch(error => {
          this.errorFunction(error, this.title)
        });
    },

    loadTemplate() {
      axios.get(`setup/doc_repo/template/${this.tid}`)
        .then(response => {
          this.template = response.data.data
        })
        .catch(error => {
          this.errorFunction(error, this.title)
        });
    },

    loadCategories() {
      axios.get(`setup/doc_repo/template/${this.tid}/category`)
        .then(response => {
          const categories = response.data.data
          this.categories1 = categories.filter(x => x.level == 1)
          this.categories2 = categories.filter(x => x.level == 2)
          this.categories3 = categories.filter(x => x.level == 3)
        })
        .catch(error => {
          this.errorFunction(error, this.title)
        });
    },

    loadDocuments() {
      const options = {
        params: {
          category1_id: this.model.category1_id,
        },
      }

      axios.get(`setup/doc_repo/template/${this.tid}/document`, options)
        .then(response => {
          this.documents = response.data.data.filter(x => x.id != this.id)

          // This is to prevent changing the initial model.position value.
          if (!this.documentsInit) {
            this.documentsInit = true
            return
          }

          if (this.documents.length > 0) {
            const length = this.documents.length
            this.model.position = this.documents[length - 1].sequence
          } else {
            this.model.position = 0;
          }
        })
        .catch(error => {
          this.errorFunction(error, this.title)
        });
    },

    loadRoles() {
      axios.get('lookup/role_list')
        .then(response => {
          this.roles = response.data.data.map(x => {
            return {
              value: x.id,
              text: x.name,
            }
          })
        })
        .catch(error => {
          this.errorFunction(error, this.title)
        });
    },

    onSubmit() {
      this.model.roles = this.selectedRoles.map(id => {
        return { role_id: id }
      })

      axios.put(`setup/doc_repo/template/${this.tid}/document/${this.id}`, this.model)
        .then(response => {
          this.$notify({
            message: 'Document Updated',
            timeout: 10000,
            icon: 'ni ni-bell-55',
            type: 'default',
          });
          this.$router.replace('../Listing')
        })
        .catch(error => {
          this.errorFunction(error, this.title)
        });
    },

    deleteRecord() {
      axios.delete(`setup/doc_repo/template/${this.tid}/document/${this.id}`)
        .then(response => {
          this.$notify({
            message: 'Document Deleted',
            timeout: 10000,
            icon: 'ni ni-bell-55',
            type: 'default',
          });
          this.$router.replace('../Listing')
        })
        .catch(error => {
          this.errorFunction(error, this.title)
        })
        .then(() => {
          this.showConfirmDelete = false
        })
    },
  },
}
</script>
