<template>
  <div class="content">
    <base-header class="pb-6">
      <b-row align-v="center" class="py-4">
        <b-col cols="7" lg="12">
          <h6 class="h2 text-white d-inline-block mb-0">Milestone Template Detail Listing</h6>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <route-bread-crumb></route-bread-crumb>
          </nav>
        </b-col>
        <b-col lg="12" cols="5" class="text-right" >
          <router-link :to="'/templates/milestoneTemplateDetailNew/' + milestone_template_id">
            <base-button size="sm" type="neutral" v-if="button_access.new_button == 'YES'">New Template Detail</base-button>
          </router-link>
          &nbsp;
        <base-button size="sm" type="neutral" @click="$router.go(-1)">Back</base-button>
        </b-col>
      </b-row>
    </base-header>

    <b-container fluid class="mt--6">
      <div>
      <b-row>
        <b-col>
          <div class="card-wrapper">
           <milestone-template-view-form></milestone-template-view-form>
          </div>
        </b-col>
      </b-row>
      </div>

      <div>
        <card class="no-border-card" body-classes="px-0 pb-1" footer-classes="pb-2">
          <template slot="header">
            <h3 class="mb-0">
              Milestone Template Detail Listing for ({{ milestone_template_name }})
              <base-button v-if="myUserID.role_id == 3 && showImportButton==true && allowed_import==true"
                           @click.native="openModal()"
                           size="sm" type="primary" class="text-right mr-0">
                Import Milestone Task
              </base-button>
            </h3>

          </template>

          <div>
            <b-col cols="12" class="d-flex justify-content-center justify-content-sm-between flex-wrap">
              <el-select
                class="select-primary pagination-select"
                v-model="pagination.perPage"
                placeholder="Per page">
                <el-option
                  class="select-primary"
                  v-for="item in pagination.perPageOptions"
                  :key="item"
                  :label="item"
                  :value="item">
                </el-option>
              </el-select>

              <div>
              </div>
            </b-col>
            <b-col><br></b-col>
            <el-table :data="queriedData"
                      row-key="milestone_template_detail_id"
                      header-row-class-name="thead-light"
                      @sort-change="sortChange">
              <el-table-column
                v-for="column in tableColumns"
                :key="column.label"
                v-bind="column">
              </el-table-column>
              <el-table-column min-width="110px" align="right" label="Actions">
                <div slot-scope="{$index, row}" class="d-flex">
                  <base-button v-if="button_access.edit_button == 'YES' && (myUserID.role_id == 3 || myUserID.role_id == 2 || myUserID.role_id == 1)"
                    @click.native="handleLike($index, row)" size="sm" type="primary">
                    Edit
                  </base-button>
                  <base-button v-else-if="button_access.edit_button == 'YES'"
                    @click.native="handleLike($index, row)" size="sm" type="primary">
                    View
                  </base-button>
                  <base-button v-if="myUserID.email == row.created_by || myUserID.role_id == 3 || myUserID.role_id == 2 || myUserID.role_id == 1"
                    @click.native="handleDelete($index, row)" size="sm" type="primary">
                    Delete
                  </base-button>
                </div>
              </el-table-column>
            </el-table>
          </div>
          <div
            slot="footer"
            class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap">
            <div class="">
              <p class="card-category">
                Showing {{ from + 1 }} to {{ to }} of {{ total }} entries
                <span v-if="selectedRows.length">
                  &nbsp; &nbsp; {{selectedRows.length}} rows selected
                </span>
              </p>
            </div>
            <base-pagination
              class="pagination-no-border"
              :current="pagination.currentPage"
              :per-page="pagination.perPage"
              :total="total"
              @change="paginationChanged($event)">
            </base-pagination>
          </div>
        </card>
      </div>
    </b-container>

    <modal :show.sync="modals.delete_forms"
                size="lg"
                body-classes="p-0">
      <card type="secondary"
            header-classes="bg-transparent pb-5"
            body-classes="px-lg-5 py-lg-5"
            class="border-0 mb-0">
        <template>
          <div class="text-center text-muted mb-4" >
            <h3>Are You SURE you want to delete this Template Details ? </h3>
            <h3>with Title : {{delete_title}} </h3>
          </div>
          <b-form role="form">

            <h5 class="text-danger"> Note : <br>
              <i>
              1. Once Delete You cannot reverse the process.

              </i>
            </h5>
            <div class="text-center">
              <base-button type="danger" class="my-4" @click="deleteRow()">YES</base-button>
              <base-button type="default" class="my-4" @click="cancelDelete()">NO</base-button>
            </div>
          </b-form>
        </template>
      </card>
    </modal>


    <modal :show.sync="modals.import_forms"
           size="lg"
           body-classes="p-0">
      <card
            header-classes="bg-transparent pb-5"
            body-classes="px-lg-5 py-lg-5"
            class="border-0 mb-0">
        <template>
          <div class="text-center text-muted mb-4" >
            <h3>Import Milestone Task</h3>
          </div>
          <b-form role="form">
            <card class="bg-transparent" md="12">
              <b-row>
                <b-col md="6">
                  Select the file to upload:
                </b-col>

                <b-col md="6">
                  <base-button size="md" class="p-1 m-2" style="background-color: #375623; width: 100%;" @click="$refs.file1.click()">Select File</base-button>
                  <!--<base-button size="md" class="p-1 m-2" style="background-color: #375623; width: 100%;" @click="onChangeMilestoneSiteTask($event,1)">Select File</base-button>-->
                  <input
                          type="file"
                          ref="file1"
                          style="display:none"
                          @change="onChangeMilestoneSiteTask"
                  />
                </b-col>
              </b-row>

              <b-row>
                <b-col md="6">
                  <div v-if="!milestone_task_file">No file selected.</div>
                  <div v-else>
                    <base-input label="Sheet To Import"
                                name="Sheet To Import"
                                v-model="selected_milestone_task_sheet">
                      <select class="form-control" v-model="selected_milestone_task_sheet" @click="loadMilestoneTaskSheetNames" @change="updateMilestoneTaskRangeToSelect(); showUploadingMilestoneTaskData();">
                        <option Selected disabled value="x">-- Please Select A Sheet To Import --</option>
                        <option v-for="sheet1 in milestoneTaskSheets" :key="sheet1.sheetId" v-bind:value="sheet1.name">{{ sheet1.name }}</option>
                      </select>
                    </base-input>
                  </div>
                </b-col>
                <b-col md="6">
                  <base-button size="md" class="p-1 m-2" style="background-color: #375623; width: 100%;" @click="uploadMilestoneTask" v-if="milestoneTaskTempData"
                  >Upload File</base-button
                  >
                  <base-button size="md" class="p-1 m-2" style="background-color: #375623; width: 100%;" @click="uploadMilestoneTask" v-else disabled
                  >Upload File</base-button
                  >
                </b-col>
              </b-row>

              <div>
                <xlsx-read ref="excelRead1" :file="milestone_task_file">
                  <xlsx-json ref="excelJson1" :sheet="selected_milestone_task_sheet" :options="{ range: selected_milestone_task_range, blankrows: false }">
                  </xlsx-json>
                </xlsx-read>
              </div>
            </card>
          </b-form>
        </template>
        </card>
    </modal>
    </div>
</template>
<script>

import { Table, TableColumn, Select, Option } from 'element-ui';
import RouteBreadCrumb from '@/components/Breadcrumb/RouteBreadcrumb'
import { BasePagination } from '@/components';
import clientPaginationMixin from './js/MilestoneDetailPaginationMix'
import swal from 'sweetalert2';
import axios from 'axios'
import {LoginURL, clientId, clientSecret, apiDomain, getHeader} from '@/assets/js/config.js'
import MilestoneTemplateViewForm from './MilestoneTemplateViewForm.vue';
import ErrorFunctionMixin from '@/assets/js/errorFunctionMix.js'
import { Modal } from '@/components';
import XlsxRead from "vue-xlsx/dist/components/XlsxRead";
import XlsxJson from "vue-xlsx/dist/components/XlsxJson";

export default {
  mixins: [clientPaginationMixin, ErrorFunctionMixin],
  components: {
    RouteBreadCrumb,
    BasePagination,
    MilestoneTemplateViewForm,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    Modal,
    XlsxRead,
    XlsxJson,
  },
  created()
  {
      var milestone_template_detail_access = JSON.parse(window.localStorage.getItem('milestone_template_detail_access'))

      this.button_access.new_button = milestone_template_detail_access.access_create
      this.button_access.edit_button = milestone_template_detail_access.access_edit

      const LoginUser = JSON.parse(window.localStorage.getItem('loginUser'))
      this.myUserID = LoginUser
      this.milestone_template_id = this.$route.params.id
      this.checkAllowedImportMilestoneTask();
      this.getListingData()
  },
  data() {
    return {
      propsToSearch: ['milestone_template_title', 'milestone_template', 'created_by', 'created_at'],
      tableColumns: [
        {
          prop: 'milestone_template_title',
          label: 'title',
          minWidth: 250,
          sortable: true
        },
        {
          prop: 'weightage',
          label: 'weightage (%)',
          minWidth: 170,
          sortable: true
        },
        {
          prop: 'milestone_template_sequence',
          label: 'sequence',
          minWidth: 150,
          sortable: true
        },
        {
          prop: 'created_by',
          label: 'Created By',
          minWidth: 200,
          sortable: true
        },
        {
          prop: 'created_at',
          label: 'Created Date',
          minWidth: 150,
          sortable: true
        },
        {
          prop: 'active_status',
          label: 'Status',
          minWidth: 110,
          sortable: true
        },
      ],
      tableData: [],
      selectedRows: [],
      milestone_template_id : null,
      id: 0,
      milestone_template_name: "testing",
      button_access:{
        new_button: null,
        edit_button: null,
        edit_template_button: null,
      },
      modals: {
        delete_forms: false,
        import_forms:false,
      },
      delete_title: null,
      delete_id : null,
      myUserID: null,
      showImportButton: false, 
      milestone_task_file: null, 
      milestoneTaskSheets:[],
      selected_milestone_task_sheet:'x',
      selected_milestone_task_range:'',
      milestoneTaskTempData: null,
      converted_temp_project_task_data: null,
      allowed_import:null,
    };
  },
  methods: {
    getListingData()
    {
      axios.get(apiDomain + 'template/milestone_template_details.show_by_template/'+ this.milestone_template_id , { headers: getHeader() })
      .then ( response => {
        if (response.status === 200)
        {
          this.tableData = response.data.data
          this.milestone_template_name = response.data.dataTitle

            if(this.tableData.length>0){
              this.showImportButton = true;
            }

        }
      })
      .catch ( error => {
          this.errorFunction(error, 'Milestone Template Detail Listing')
      } );
    },
    paginationChanged(page) {
      this.pagination.currentPage = page
    },
    handleLike(index, row) {
       this.$router.push({path: '/templates/milestoneTemplateDetailEdit/'+row.milestone_template_detail_id })
    },
    handleEdit(index, row) {
      swal({
        title: `You want to edit ${row.name}`,
        buttonsStyling: false,
        confirmButtonClass: 'btn btn-info btn-fill'
      });
    },
    handleDelete(index, row)
    {
      this.delete_title = row.milestone_template_title
      this.delete_id = row.milestone_template_detail_id
      this.modals.delete_forms = true
    },
    cancelDelete()
    {
      this.delete_title = null
      this.delete_id = null
      this.modals.delete_forms = false
    },
    deleteRow()
    {
      axios.delete(apiDomain + 'template/milestone_template_details/'+ this.delete_id , { headers: getHeader() })
      .then ( response => {
        if (response.status === 200)
        {
          this.$notify({
          message:
            '<b>Milestone Template Details : </b> - Successfully DELETED.',
          timeout: 10000,
          icon: 'ni ni-bell-55',
          type: 'default',
          });
          this.getListingData()
        }
      })
      .catch ( error => {
          this.errorFunction(error, 'Delete Milestone Template Detail Listing')
      } );
       this.modals.delete_forms = false
    },
    selectionChange(selectedRows) {
      this.selectedRows = selectedRows
    },
    openModal() {
        this.modals.import_forms = true;
    },
    clearModal() {
        this.modals.import_forms = false;
    },
    checkAllowedImportMilestoneTask(){
        axios.get(apiDomain + 'template/check_allowed_import_milestone_task/'+ this.milestone_template_id , { headers: getHeader() })
            .then ( response => {
                if (response.status === 200)
                {
                    this.allowed_import = response.data.allowed;

                }
            })
            .catch ( error => {
                this.errorFunction(error, 'Milestone Template Detail Listing')
            } );
    },
    onChangeMilestoneSiteTask(event){
        this.milestone_task_file = event.target.files ? event.target.files[0] : null;

    },
    loadMilestoneTaskSheetNames() {
        if (typeof(this.$refs.excelRead1._workbook) !== "undefined") {
            // this.sheets = this.$refs.excelRead1._workbook.Workbook.Sheets;
            this.milestoneTaskSheets = this.$refs.excelRead1._workbook.Workbook.Sheets;
        }
    },
    updateMilestoneTaskRangeToSelect () {
        if (typeof(this.$refs.excelRead1._workbook) !== "undefined" && this.selected_milestone_task_sheet != 'x') {
            const original_range = this.$refs.excelRead1._workbook.Sheets[this.selected_milestone_task_sheet]['!ref'];
            this.selected_milestone_task_range = 'A1:' + original_range.substring(3);
            this.selected_milestone_task_range = this.selected_milestone_task_range.replace(/:(.*?)(?=\d)/, ':J');
        }

    },
    showUploadingMilestoneTaskData(){
        this.milestoneTaskTemplate = null;
        this.milestoneTaskTempData = this.$refs.excelJson1.$data.collection;
    },
    excelDateToJSDate(serial) {
        var utc_days = Math.floor(serial - 25569);
        var utc_value = utc_days * 86400;
        var date_info = new Date(utc_value * 1000);

        var fractional_day = serial - Math.floor(serial) + 0.0000001;

        var total_seconds = Math.floor(86400 * fractional_day);

        var seconds = total_seconds % 60;

        total_seconds -= seconds;

        var hours = Math.floor(total_seconds / (60 * 60));
        var minutes = Math.floor(total_seconds / 60) % 60;

        return new Date(
            date_info.getFullYear(),
            date_info.getMonth(),
            date_info.getDate(),
            hours,
            minutes,
            seconds
        );
    },
    convertToDateOnly(inputDateTime) {
        const current = inputDateTime;
        const currentMonth = String(current.getMonth() + 1).padStart(2, "0");
        const currentDay = String(current.getDate()).padStart(2, "0");
        const currentDate =
            current.getFullYear() + "-" + currentMonth + "-" + currentDay;
        return currentDate;
    },
    convertExcelDateTime() {
        this.milestoneTaskTempData.forEach((e) => {
            e["Plan Start Date"] = this.excelDateToJSDate(e["Plan Start Date"]);
            e["Plan Start Date"] = this.convertToDateOnly(e["Plan Start Date"]);

            e["Plan End Date"] = this.excelDateToJSDate(e["Plan End Date"]);
            e["Plan End Date"] = this.convertToDateOnly(e["Plan End Date"]);

            e["Actual Start Date"] = (e["Actual Start Date"] != "NA" && e["Actual Start Date"] != undefined) ? this.excelDateToJSDate(e["Actual Start Date"]) : e["Actual Start Date"];
            e["Actual Start Date"] = (e["Actual Start Date"] != "NA" && e["Actual Start Date"] != undefined) ? this.convertToDateOnly(e["Actual Start Date"]) : e["Actual Start Date"];

            e["Actual End Date"] = (e["Actual End Date"] != "NA" && e["Actual Start Date"] != undefined) ? this.excelDateToJSDate(e["Actual End Date"]) : e["Actual End Date"];
            e["Actual End Date"] = (e["Actual End Date"] != "NA" && e["Actual Start Date"] != undefined) ? this.convertToDateOnly(e["Actual End Date"]) : e["Actual End Date"];
        });
    },
    uploadMilestoneTask() {
        this.updateMilestoneTaskRangeToSelect();
        this.showUploadingMilestoneTaskData();
        this.convertExcelDateTime();

        const post_data = {
            data: this.milestoneTaskTempData
        }


        const tokenData = JSON.parse(window.localStorage.getItem("authUser"));
        const headers = {
            Authorization: "Bearer " + tokenData.access_token,
        };

        axios
            .post(
                apiDomain + "task/import_milestone_task/"+ this.milestone_template_id,
                post_data,
                { headers }
            )
            .then((response) => {
                if (response.status === 200) {
                    this.file = null;
                    this.milestone_task_file = null;

                    this.clearModal();

                    this.$notify({
                        message:
                        '<b>Import Milestone Task : ' + this.milestone_template_name + '</b> - Successfully Imported.',
                        timeout: 10000,
                        icon: 'ni ni-bell-55',
                        type: 'default',
                    });

                }

            })
            .catch((error) => {
                this.$notify({
                    message: "</b>Import Milestone Task",
                    timeout: 10000,
                    icon: "ni ni-bulb-61",
                    type: "danger",
                });
                this.errorFunction(error);
            });

    },
  }
};
</script>
<style>
  .no-border-card .card-footer{
    border-top: 0;
  }
</style>
