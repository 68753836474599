<template>
  <div>
    <base-header class="pb-6">
      <b-row class="py-4">
        <b-col>
          <h6 class="h2 text-white d-inline-block mb-0">{{ title }}</h6>
        </b-col>
      </b-row>
    </base-header>

    <b-container fluid class="mt--6">
      <div class="card-wrapper">
        <card>
          <!-- Card header -->
          <template v-slot:header>
            <table>
              <tr>
                <td class="pr-3">Project</td>
                <td><h4 class="mb-0">{{ setting.project_name }}</h4></td>
              </tr>
              <tr>
                <td class="pr-3">Site</td>
                <td><h4 class="mb-0">{{ setting.site_name }}</h4></td>
              </tr>
            </table>
          </template>

          <!-- Card body -->
          <h3>Setting Up</h3>
          <validation-observer v-slot="{ handleSubmit }">
            <form class="needs-validation" @submit.prevent="handleSubmit(onSubmit)">

              <b-form-row>
                <b-col>
                  <validation-provider name="Template" rules="required" v-slot="{ classes, errors }">
                    <b-form-group label="Select a Template" :invalid-feedback="errors[0]">
                      <select class="form-control" size="5" :class="classes" v-model="selectedTemplate">
                        <option v-for="item of templates" :key="item.id" :value="item.id">{{ item.name }}</option>
                      </select>
                    </b-form-group>
                  </validation-provider>
                </b-col>
              </b-form-row>

              <button v-if="access.create" class="btn btn-primary">Select</button>
            </form>
          </validation-observer>
        </card>
      </div>
    </b-container>
  </div>
</template>

<script>
import ErrorFunctionMixin from '@/assets/js/errorFunctionMix.js'
import axios from '@/util/axios'
import { getAccessByModule } from '@/util/access'

export default {
  mixins: [ErrorFunctionMixin],

  data() {
    return {
      title: 'Document Tracking',
      pid: null,
      sid: null,
      setting: {},
      templates: [],
      selectedTemplate: null,
      access: {},
    }
  },

  created() {
    this.pid = this.$route.params.pid
    this.sid = this.$route.params.sid
    this.access = getAccessByModule('DOC_REPO_REVIEW')

    this.loadSetting()
    this.loadTemplates()
  },

  methods: {
    loadSetting() {
      axios.get(`doc_repo/p/${this.pid}/s/${this.sid}/setting`)
        .then(response => {
          // Already init. Go back to home.
          if (response.data.data) {
            this.$router.replace('Home')
            return
          }

          this.loadSite()
        })
        .catch(error => {
          this.errorFunction(error, this.title)
        })
    },

    loadSite() {
      axios.get(`project/project_site_short/${this.sid}`)
        .then(response => {
          const [site] = response.data.data
          this.setting = {
            project_name: site.project.project_name,
            site_name: site.site_name,
          }
        })
        .catch(error => {
          this.errorFunction(error, this.title)
        })
    },

    loadTemplates() {
      axios.get('doc_repo/template')
        .then(response => {
          this.templates = response.data.data
        })
        .catch(error => {
          this.errorFunction(error, this.title)
        })
    },

    onSubmit() {
      const body = {
        template_id: this.selectedTemplate,
      }

      axios.post(`doc_repo/p/${this.pid}/s/${this.sid}/setting`, body)
        .then(response => {
          this.$router.replace('Home')
        })
        .catch(error => {
          this.errorFunction(error, this.title)
        })
    },
  },
}
</script>
