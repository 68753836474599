<template>
  <div>
    <div class="bg-default-dashboard">
      <div class=" py-lg-8 pt-lg-5">
        <b-container fluid style="width: 100%; position: relative;">
          <b-row>
            <b-breadcrumb :items="route_items"></b-breadcrumb>
          </b-row>
          <b-row class="mb-2">
            <b-col>
              <h1 class="text-white">{{ model.name }}</h1>
            </b-col>
          </b-row>
          <br>
          <h2 class="text-white">Categories</h2>

          <b-row class="mt-3">
            <b-col md="12">
              <div class="d-flex align-items-baseline justify-content-between">
                <h4 class="text-white">{{ model.category1_name }}</h4>
                  <base-button type="primary" @click="showModal(1,model.category1_name)" v-if="access.create"><i class="fa fa-plus mr-1"></i>{{ model.category1_name }}</base-button>
              </div>
              <table style="width: 100%;" id="my-table" :data="categories1" header-row-class-name="thead-light"
                cell-class-name="fw-semibold" class="mt-2">
                <thead
                  style="background-color: #23454A; color: white; font-size: small; border-bottom: 20px solid #082A2F;">
                  <th class="th-table-new ">Code</th>
                  <th class="th-table-new ">Name </th>
                  <th class="th-table-new "> Actions </th>
                  <el-table-column label="Actions">
                    <template v-slot="{ row }">
                      <router-link v-if="access.show" :to="getEditCategoryRoute(row)" class="btn btn-primary btn-sm">
                        View / Edit
                      </router-link>
                    </template>
                  </el-table-column>
                </thead>
                <tbody>
                  <tr v-for="item in categories1" :key="item.id"
                    style="background-color: #3a656b; color: white; font-size: smaller; border-bottom:  20px solid #082A2F;">
                    <td class="tr-td-table-new" style="min-width: 150px;">{{ item.code }}</td>
                    <td class="tr-td-table-new" style="min-width: 150px;">{{ item.name }}</td>
                    <td class="tr-td-table-new">
                      <div>
                        <b-link :to="`Edit/${item.id}`" class="mr-3 mt-4">
                          <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <g clip-path="url(#clip0_1130_20)">
                              <path
                                d="M2.81399 15.4667L1.54732 20.9333C1.50363 21.1332 1.50513 21.3403 1.55171 21.5394C1.59829 21.7386 1.68878 21.9249 1.81657 22.0846C1.94435 22.2444 2.10621 22.3735 2.29031 22.4627C2.47441 22.5519 2.6761 22.5988 2.88066 22.6C2.97597 22.6096 3.07201 22.6096 3.16732 22.6L8.66732 21.3333L19.2273 10.8133L13.334 4.93335L2.81399 15.4667Z"
                                fill="#FF5C00" />
                              <path
                                d="M22.5471 5.54667L18.6138 1.61333C18.3552 1.35604 18.0053 1.21161 17.6405 1.21161C17.2757 1.21161 16.9257 1.35604 16.6671 1.61333L14.4805 3.8L20.3671 9.68667L22.5538 7.5C22.6818 7.37139 22.7831 7.2188 22.852 7.05098C22.921 6.88315 22.9561 6.70338 22.9555 6.52195C22.9549 6.34052 22.9185 6.161 22.8484 5.99364C22.7784 5.82629 22.676 5.6744 22.5471 5.54667Z"
                                fill="#FF5C00" />
                            </g>
                            <defs>
                              <clipPath id="clip0_1130_20">
                                <rect width="24" height="24" fill="white" />
                              </clipPath>
                            </defs>
                          </svg>
                        </b-link>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
              <br><br>
              <div class="d-flex align-items-baseline justify-content-between">
                <h4 class="text-white">{{ model.category2_name }}</h4>
                <base-button type="primary" @click="showModal(2,model.category2_name)" v-if="access.create"><i class="fa fa-plus mr-1"></i>{{ model.category2_name }}</base-button>
                <!-- <button @click="openModal">Open</button> -->
                <!-- <DemoModal v-show="isModalVisible" @close="closeModal" /> -->
              </div>
              <table style="width: 100%;" :data="categories2" header-row-class-name="thead-light"
                cell-class-name="fw-semibold" class="mt-2">
                <thead
                  style="background-color: #23454A; color: white; font-size: small; border-bottom: 20px solid #082A2F;">
                  <th class="th-table-new ">Code</th>
                  <th class="th-table-new ">Name </th>
                  <th class="th-table-new "> Actions </th>
                  <el-table-column label="Actions">
                    <template v-slot="{ row }">
                      <router-link v-if="access.show" :to="getEditCategoryRoute(row)" class="btn btn-primary btn-sm">
                        View / Edit
                      </router-link>
                    </template>
                  </el-table-column>
                </thead>
                <tbody>
                  <tr v-for="item in categories2" :key="item.id"
                    style="background-color: #3a656b; color: white; font-size: smaller; border-bottom:  20px solid #082A2F;">
                    <td class="tr-td-table-new" style="min-width: 150px;">{{ item.code }}</td>
                    <td class="tr-td-table-new" style="min-width: 150px;">{{ item.name }}</td>
                    <td class="tr-td-table-new">
                      <div>
                        <b-link :to="`Edit/${item.id}`" class="mr-3 mt-4">
                          <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <g clip-path="url(#clip0_1130_20)">
                              <path
                                d="M2.81399 15.4667L1.54732 20.9333C1.50363 21.1332 1.50513 21.3403 1.55171 21.5394C1.59829 21.7386 1.68878 21.9249 1.81657 22.0846C1.94435 22.2444 2.10621 22.3735 2.29031 22.4627C2.47441 22.5519 2.6761 22.5988 2.88066 22.6C2.97597 22.6096 3.07201 22.6096 3.16732 22.6L8.66732 21.3333L19.2273 10.8133L13.334 4.93335L2.81399 15.4667Z"
                                fill="#FF5C00" />
                              <path
                                d="M22.5471 5.54667L18.6138 1.61333C18.3552 1.35604 18.0053 1.21161 17.6405 1.21161C17.2757 1.21161 16.9257 1.35604 16.6671 1.61333L14.4805 3.8L20.3671 9.68667L22.5538 7.5C22.6818 7.37139 22.7831 7.2188 22.852 7.05098C22.921 6.88315 22.9561 6.70338 22.9555 6.52195C22.9549 6.34052 22.9185 6.161 22.8484 5.99364C22.7784 5.82629 22.676 5.6744 22.5471 5.54667Z"
                                fill="#FF5C00" />
                            </g>
                            <defs>
                              <clipPath id="clip0_1130_20">
                                <rect width="24" height="24" fill="white" />
                              </clipPath>
                            </defs>
                          </svg>
                        </b-link>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
              <br><br>
              <template v-if="model.category3_enabled">
                <div class="d-flex align-items-baseline justify-content-between">
                  <h4 class="text-white">{{ model.category3_name }}</h4>
                  <b-link @click="showModal(3, model.category3_name)" v-if="access.create" class="btn btn-dark ">
                    <i class="fas fa-plus"></i>
                    {{ model.category3_name }}</b-link>
                  <!-- <button @click="openModal">Open</button> -->
                  <!-- <DemoModal v-show="isModalVisible" @close="closeModal" /> -->
                </div>
                <table style="width: 100%;" :data="categories3" header-row-class-name="thead-light"
                  cell-class-name="fw-semibold" class="mt-2">
                  <thead
                    style="background-color: #23454A; color: white; font-size: small; border-bottom: 20px solid #082A2F;">
                    <th class="th-table-new ">Code</th>
                    <th class="th-table-new ">Name </th>
                    <th class="th-table-new ">Actions </th>
                    <el-table-column label="Actions">
                      <template v-slot="{ row }">
                        <router-link v-if="access.show" :to="getEditCategoryRoute(row)" class="btn btn-primary btn-sm">
                          View / Edit
                        </router-link>
                      </template>
                    </el-table-column>
                  </thead>
                  <tbody>
                    <tr v-for="item in categories3" :key="item.id"
                      style="background-color: #3a656b; color: white; font-size: smaller; border-bottom:  20px solid #082A2F;">
                      <td class="tr-td-table-new" style="min-width: 150px;">{{ item.code }}</td>
                      <td class="tr-td-table-new" style="min-width: 150px;">{{ item.name }}</td>

                      <td class="tr-td-table-new">
                        <div>
                          <b-link :to="`Edit/${item.id}`" class="mr-3 mt-4">
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                              xmlns="http://www.w3.org/2000/svg">
                              <g clip-path="url(#clip0_1130_20)">
                                <path
                                  d="M2.81399 15.4667L1.54732 20.9333C1.50363 21.1332 1.50513 21.3403 1.55171 21.5394C1.59829 21.7386 1.68878 21.9249 1.81657 22.0846C1.94435 22.2444 2.10621 22.3735 2.29031 22.4627C2.47441 22.5519 2.6761 22.5988 2.88066 22.6C2.97597 22.6096 3.07201 22.6096 3.16732 22.6L8.66732 21.3333L19.2273 10.8133L13.334 4.93335L2.81399 15.4667Z"
                                  fill="#FF5C00" />
                                <path
                                  d="M22.5471 5.54667L18.6138 1.61333C18.3552 1.35604 18.0053 1.21161 17.6405 1.21161C17.2757 1.21161 16.9257 1.35604 16.6671 1.61333L14.4805 3.8L20.3671 9.68667L22.5538 7.5C22.6818 7.37139 22.7831 7.2188 22.852 7.05098C22.921 6.88315 22.9561 6.70338 22.9555 6.52195C22.9549 6.34052 22.9185 6.161 22.8484 5.99364C22.7784 5.82629 22.676 5.6744 22.5471 5.54667Z"
                                  fill="#FF5C00" />
                              </g>
                              <defs>
                                <clipPath id="clip0_1130_20">
                                  <rect width="24" height="24" fill="white" />
                                </clipPath>
                              </defs>
                            </svg>
                          </b-link>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <br><br>
              </template>
            </b-col>
          </b-row>

          <b-modal ref="my-modal" centered hide-footer title="Add New Category" class="bg-dark">
            <DemoModal @submitt="onSubmit" @close="hideModal" />
          </b-modal>
        </b-container>
      </div>

    </div>

  </div>
</template>

<script>
import ErrorFunctionMixin from '@/assets/js/errorFunctionMix.js'
import { Table, TableColumn } from 'element-ui';
import axios from '@/util/axios'
import { getAccessByModule } from '@/util/access';
import DemoModal from './New.vue'
export default {
  mixins: [ErrorFunctionMixin],
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    DemoModal,
  },

  data() {
    return {
      title: 'Document Tracking Templates',
      tid: null,
      route_items: [
        {
          text: 'Document Tracking Template',
          href: '../../../Tracking'
        },
        {
          text: 'Categories',
          active: true
        }
      ],
      model: {},
      test: '',
      newForm: {},
      isModalVisible: false,
      categories1: [],
      categories2: [],
      categories3: [],
      access: {}
    }
  },

  created() {
    this.tid = this.$route.params.tid
    this.access = getAccessByModule('DOC_REPO')
    // this.newForm.level = 1
    this.loadRecord()
    this.loadCategories()
  },

  methods: {
    showModal(level, codeN) {
      this.newForm = {}
      this.newForm.level = level
      this.test = level
      this.newForm.codeName = codeN
      this.$refs['my-modal'].show()
    },
    hideModal() {
      this.$refs['my-modal'].hide()
      this.newForm.level = ''
      this.newForm.codeName = ''
      this.newForm.code = ''
      this.loadCategories()
    },
    onSubmit(newForm) {
      newForm.level = this.test
      axios.post(`setup/doc_repo/template/${this.tid}/category`, newForm)
        .then(response => {
          this.$notify({
            message: 'New Category Created',
            timeout: 10000,
            icon: 'ni ni-bell-55',
            type: 'default',
          });

          this.loadCategories()
          this.hideModal()

        })
        .catch(error => {
          this.errorFunction(error, this.title)
        });
    },
    loadRecord() {
      axios.get(`setup/doc_repo/template/${this.tid}`)
        .then(response => {
          this.model = response.data.data
        })
        .catch(error => {
          this.errorFunction(error, this.title)
        });
    },

    loadCategories() {
      axios.get(`setup/doc_repo/template/${this.tid}/category`)
        .then(response => {
          const categories = response.data.data
          this.categories1 = categories.filter(x => x.level == 1)
          this.categories2 = categories.filter(x => x.level == 2)
          this.categories3 = categories.filter(x => x.level == 3)
        })
        .catch(error => {
          this.errorFunction(error, this.title)
        });
    },

    getEditCategoryRoute(category) {
      const key = `category${category.level}_name`
      const name = this.model[key]

      return {
        path: `Edit/${category.id}`,
        query: { name },
      }
    },
  },
}
</script>
<style>
.modal-title {
  margin-left: auto;
  margin-top: auto;
  /* margin-right: auto; */
}
</style>
<style scoped>
/* Class For Modal */
.modal-content {
  background-color: #306b74;

}

.breadcrumb {
  background-color: transparent;
}

.custom-control-label-tmp {
  margin-bottom: 0;
}

.custom-control-label-tmp {
  position: relative;
  margin-bottom: 0;
  vertical-align: top;
}

.modal-content {
  background-color: #306b74;

}

label {
  display: inline-block;
  margin-bottom: 0.5rem;
}

table thead th {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  font-size: 0.65rem;
  text-transform: uppercase;
  letter-spacing: 1px;
  background-color: #23454A;
  color: white;
  padding: 20px;
  border-bottom: 0px solid #082A2F;
  border-top: 0px solid #e9ecef;
}

.th-table-new {
  padding-top: 35px;
  padding-bottom: 35px;
  padding-left: 20px;
}

.tr-td-table-new {
  padding-top: 30px;
  padding-bottom: 30px;
  padding-left: 20px;
}

.form-control {
  background-color: #2C545A;
  border-color: #2C545A;
  color: white;

  &:focus {
    background-color: #2C545A;
    border-color: #2C545A;
    color: white;
  }

  &::placeholder {
    color: white;
  }
}

.form-control {
  &:focus {
    &::placeholder {
      color: white;
    }
  }
}

.input-group-text {
  background-color: #2C545A;
  ;
  color: white;

  &:focus {
    background-color: #2C545A;
    ;
    border-color: #1D4147;
    color: white;
  }
}

.focused {
  .input-group-text {
    background-color: #2C545A;
  }
}

.navbar-search-dark .input-group {
  background-color: #23454A;
  color: black;
  border-color: rgba(255, 255, 255, 0.6);
}

.navbar-search .input-group {
  border-radius: 7px;
}

.el-input__inner,
.el-select-dropdown__item.is-disabled:hover {
  background-color: #23454A;
}

.el-table th.el-table__cell {

  overflow: hidden;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: #23454A;
  color: white;
  padding: 20px;
  border-top: 0px solid #082A2F;
  border-bottom: 0px solid #082A2F;
}

.el-table td.el-table__cell,
.el-table th.el-table__cell.is-leaf {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  border-bottom: 20px solid #082A2F;
  border-top: 0px solid #082A2F;
}

div.el-table .el-table__row {
  background: #3a656b;
  border-bottom: 0 solid #082A2F;


}

div.el-table .el-table__row:hover {
  background: #3a656b;
}

.el-table--border::after,
.el-table--group::after,
.el-table::before {
  content: '';
  position: absolute;
  background-color: #ebeef500;
  z-index: 1;
}
</style>