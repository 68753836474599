<template>
    <div>
        <base-header class="pb-6">
            <b-row class="py-4">
                <b-col cols="6">
                    <h6 class="h2 text-white d-inline-block mb-0">{{ title }}</h6>
                </b-col>
                <b-col cols="6" class="text-right">
                    <base-button size="sm" type="neutral" @click="navigateToListing()">Back</base-button>
                </b-col>
            </b-row>
        </base-header>

        <b-container fluid class="mt--6">
            <div class="card-wrapper">
                <card>
                    <div v-if="showError" :value="true" class="alert alert-primary" role="alert">
                        <strong>Error!</strong>  Please fill in all compulsory fields in Other Information.
                    </div>
                    <div v-if="rejectError" :value="true" class="alert alert-primary" role="alert">
                        <strong>Reject Reason : </strong>  {{model.reject_reason}}
                    </div>
                    <!-- Card header -->
                    <h3 slot="header" class="mb-0">Update Eform</h3>

                    <validation-observer v-slot="{ handleSubmit }">
                        <form class="needs-validation" @submit.prevent="handleSubmit(onSubmit)">
                            <div class="table-responsive">
                                <table class="table align-items-center mb-0 text-dark">
                                    <thead>
                                        <th>No.</th>
                                        <th>Title</th>
                                        <th></th>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(item, index) in model.eform_answer" :key="index">
                                            <td width="8%" style="vertical-align: top;">
                                                <base-input
                                                        v-model="item.form_criteria.order_no"
                                                        label="No."
                                                        placeholder=" "
                                                        class="border"
                                                        readonly
                                                ></base-input>
                                            </td>
                                            <td width="42%" style="vertical-align: top;">
                                                <base-input
                                                        v-model="item.form_criteria.title"
                                                        label="No."
                                                        placeholder=" "
                                                        class="border"
                                                        readonly
                                                ></base-input>
                                                <b-form-textarea
                                                        id="textarea"
                                                        v-model="item.form_criteria.description"
                                                        placeholder=""
                                                        rows="5"
                                                        readonly
                                                        class="border"
                                                ></b-form-textarea>
                                            </td>
                                            <td width="50%" align="center">
                                                <span class="text-danger" v-html="showRequired(item.form_criteria)"></span>
                                                <div class="form-group text-dark" v-if="item.form_criteria.input_type=='radio'">
                                                    <b-form-radio-group v-model="item.value">
                                                        <template v-for="(itemData, indexData) in item.form_criteria.items">
                                                            <b-form-radio :value="itemData.id" :name="itemData.name" class="mb-3">
                                                                <b>{{itemData.name}}</b>
                                                            </b-form-radio>
                                                            <br >
                                                        </template>
                                                    </b-form-radio-group>
                                                </div>
                                                <div class="form-group text-dark" v-if="item.form_criteria.input_type=='checkbox'">
                                                    <template>
                                                        <div>
                                                            <label v-for="(itemData,indexData) in item.form_criteria.items" :key="indexData">
                                                                <input
                                                                        type="checkbox"
                                                                        :value="itemData.id"
                                                                        v-model="item.items"
                                                                />
                                                                {{ itemData.name }}
                                                            </label>
                                                        </div>
                                                    </template>

                                                </div>
                                                <div class="form-group text-dark" v-if="item.form_criteria.input_type=='select'">
                                                    <select class="form-control text-dark" v-model="item.value">
                                                        <option v-for="itemData in item.form_criteria.items" :key="itemData.id" v-bind:value="itemData.id">{{ itemData.name }}</option>
                                                    </select>
                                                </div>
                                                <div class="form-group text-dark" v-if="item.form_criteria.input_type=='text-field'">
                                                    <base-input
                                                            v-model="item.value"
                                                            placeholder=" "
                                                            class="border"
                                                    ></base-input>
                                                </div>
                                                <div class="form-group text-dark" v-if="item.form_criteria.input_type=='textarea'">
                                                    <b-form-textarea
                                                            id="textarea"
                                                            v-model="item.value"
                                                            placeholder=""
                                                            rows="5"
                                                            class="border"
                                                    ></b-form-textarea>
                                                </div>
                                                <div class="form-group text-dark" v-if="item.form_criteria.input_type=='date'">
                                                    <base-input
                                                            type="date"
                                                            v-model="item.value_date">
                                                    </base-input>
                                                </div>
                                                <div class="form-group text-dark" v-if="item.form_criteria.input_type=='file'">
                                                    <div>
                                                        * File Type : PDF / PNG / JPEG <br >
                                                        <base-button size="md" type="primary" @click.prevent="uploadAttachment('files-upload')">Add Attachment</base-button>
                                                        <input
                                                                v-show="false"
                                                                id="files-upload"
                                                                type="file"
                                                                ref="file"
                                                                style="display:none"
                                                                @change="fileChange($event, index, item.index)"
                                                        />
                                                    </div>
                                                    <div v-for="(file, fileindex) of item.items" class="alert border-bottom mb-2 p-2 d-flex align-items-center">
                                                        <div class="flex-fill">
                                                            <div class="fw-semibold">{{ file.uploaded_file.name }}</div>
                                                        </div>
                                                        <button class="btn btn-warning btn-sm" @click="item.items.splice(indexFile, 1)">&times;</button>
                                                    </div>

                                                    <div v-for="(itemAttachment, indexAttachment) of item.item_attachments" class="alert border-bottom mb-2 p-2 d-flex align-items-center">
                                                        <div class="flex-fill">
                                                            <div class="fw-semibold">{{ itemAttachment.attachment.filename }}</div>
                                                        </div>
                                                        <button class="btn btn-warning btn-sm" @click="item.item_attachments.splice(indexAttachment, 1)">&times;</button>
                                                    </div>
                                                </div>
                                                <div class="form-group text-dark" v-if="item.form_criteria.input_type=='table'">
                                                    <template v-if="item.form_criteria.table_type == 'simple' && item.form_criteria.tables !=null">
                                                        <div>
                                                            <base-button
                                                                    color="info"
                                                                    @click="addRow(item.form_criteria)"
                                                            >
                                                               Add More
                                                            </base-button>
                                                        </div>
                                                        <table class="table-responsive">
                                                            <thead>
                                                            <tr>
                                                                <th
                                                                        v-for="(column, columnIndex) in item.form_criteria.tables"
                                                                        :key="columnIndex"
                                                                >
                                                                    {{ column.label }}
                                                                </th>
                                                            </tr>
                                                            </thead>
                                                            <tbody>
                                                            <tr v-for="(column, columnIndex) in item.form_criteria.table_items"
                                                                :key="columnIndex">
                                                                <td
                                                                        v-for="(valueItem, columnItem) in column"
                                                                        :key="columnItem"
                                                                >

                                                                    <base-input
                                                                        v-model="valueItem.label"
                                                                    ></base-input>
                                                                </td>
                                                                <td>
                                                                    <base-button
                                                                            type="danger"
                                                                            @click="deleteItem(item.form_criteria, columnIndex)"
                                                                    >
                                                                       delete
                                                                    </base-button>
                                                                </td>
                                                            </tr>
                                                            </tbody>
                                                        </table>
                                                    </template>
                                                    <template v-if="item.form_criteria.table_type == 'complex' && item.form_criteria.tables != null">
                                                        <table border="1"class="table-responsive">
                                                            <thead>
                                                            <tr v-for="(col, colIndex) in item.form_criteria.tables"
                                                                :key="'header-' + colIndex" v-if="colIndex == 0">
                                                                <th v-for="(col1, colIndex1) in col"
                                                                    :key="'header1-' + colIndex1"
                                                                    class="text-dark"
                                                                >
                                                                    {{ col1 }}
                                                                </th>
                                                            </tr>
                                                            </thead>
                                                            <tbody>
                                                            <tr
                                                                    v-for="(row, rowIndex) in item.form_criteria.tables"
                                                                    :key="rowIndex"
                                                            >
                                                                <template v-if="rowIndex !== 0">
                                                                    <td
                                                                            v-for="(col, colIndex) in row"
                                                                            :key="colIndex"
                                                                            class="text-dark"
                                                                    >
                                                                        <template v-if="colIndex !== 0">
                                                                            <base-input
                                                                                    v-model="item.form_criteria.tables[rowIndex][colIndex]"
                                                                                    outline
                                                                            ></base-input>
                                                                        </template>
                                                                        <template v-else>
                                                                            {{ col }}
                                                                        </template>
                                                                    </td>
                                                                </template>
                                                            </tr>
                                                            </tbody>
                                                        </table>
                                                    </template>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            <br>

                            <button class="btn btn-primary" v-if="access.edit && model.status!='COMPLETED' && model.status !='APPROVED'">Submit Form</button>
                            <button class="btn btn-primary" type="button" @click="navigateToListing()">Cancel</button>
                        </form>
                    </validation-observer>
                </card>

                <card >


                </card>

            </div>
        </b-container>
    </div>
</template>

<script>
    import ErrorFunctionMixin from '@/assets/js/errorFunctionMix.js'
    import axios from '@/util/axios'
    import { getAccessByModule } from '@/util/access'
    import BaseInput from "../../../../components/Inputs/BaseInput.vue";
    import jsonFormData from 'json-form-data';

    export default {
        components: {BaseInput},
        mixins: [ErrorFunctionMixin],

        data() {
            return {
                title: 'Eform Submission',
                type: null,
                model: {
                },
                access: {},
                templates: [],
                roles: [],
                criterias: [],
                documents: [],
                showError : false,
                rejectError : false,
            }
        },

        created() {
            this.id = this.$route.params.id
            this.type = this.$route.params.type
            this.access = getAccessByModule('EFORM_SUBMITTER')
            this.getTemplateOptions();
            this.getAllRoles();
            this.getData()
        },

        methods: {
            onSubmit() {
                this.showError = false;
                let err = [];
                let arr = ['text-field', 'radio', 'select', 'textarea'];
                let arr2 = ['checkbox', 'file'];

                this.model.eform_answer.forEach((item, index) => {
                    if(item.form_criteria)
                    {
                        if(arr.includes(item.form_criteria.input_type) && item.form_criteria.is_required && !item.value)
                        {
                            err.push(index);
                        }

                        let attachmentLength = item.items.length + item.item_attachments.length;

                        if(arr2.includes(item.form_criteria.input_type) && item.form_criteria.is_required && attachmentLength == 0)
                        {
                            err.push(index);
                        }

                        if(item.form_criteria.input_type == 'table' && item.form_criteria.table_type == 'simple' && item.form_criteria.is_required && item.form_criteria.table_items.lengthv == 0)
                        {
                            err.push(index);
                        }

                        if(item.form_criteria.input_type == 'table' && item.form_criteria.table_type == 'complex' && item.form_criteria.is_required && item.form_criteria.tables.length > 0)
                        {
                            item.form_criteria.tables.forEach((item1, index1) => {
                                item1.forEach((item2, index2) => {
                                    if(item2 == null || item2 == '')
                                    {
                                        this.$set(this.record.form_answers[index].form_criteria.tables[index1], index2, '-');
                                        err.push(index);
                                    }
                                });
                            });
                        }
                    }
                });

                if(err.length > 0)
                {
                    this.showError = true;
                }

                if(this.showError == false){
                    this.model.attachments = this.documents;
                    let formData = jsonFormData(this.model);

                    axios.post('e_forms/eform_submission/'+this.type+'/'+this.id , formData)
                        .then(response => {
                            this.$notify({
                                message: 'Eform Updated',
                                timeout: 10000,
                                icon: 'ni ni-bell-55',
                                type: 'default',
                            });

                            this.navigateToListing();
                        })
                        .catch(error => {
                            this.errorFunction(error, this.title)
                        });
                }

            },
            getTemplateOptions(){
                axios.get('e_forms/get_type_options')
                    .then ( response => {
                        if (response.status === 200)
                        {
                            this.templates = response.data.data
                        }
                    })
                    .catch ( error => {
                        this.errorFunction(error, this.title)
                    } );
            },
            getData(){
                axios.get('e_forms/eform_submission/'+this.type+'/'+this.id)
                    .then ( response => {
                        if (response.status === 200)
                        {
                            this.model = response.data.data
                            console.log(this.model.status)
                            if(this.model.status == 'REJECTED'){
                                this.rejectError = true;
                            }
                        }
                    })
                    .catch ( error => {
                        this.errorFunction(error, this.title)
                    } );
            },
            getAllRoles(){
                axios.get('lookup/role_list')
                    .then ( response => {
                        if (response.status === 200)
                        {
                            this.roles = response.data.data
                        }
                    })
                    .catch ( error => {
                        console.log(error.response.status)
                        this.errorFunction(error, "Role Listing")
                    } );
            },
            navigateToListing(){
                this.$router.replace('../../SubmitterListing/'+this.type);
            },
            showRequired(item)
            {
                if(item)
                {
                    if(item.is_required)
                    {
                        return 'field is compulsory (<font color="red">*</font>)';
                    }
                    else
                    {
                        return '';
                    }
                }
            },
            fileChange(event, index, item) {
                const data = new FormData()
                let file = event.target.files[0];
                const allowedTypes = ['application/pdf', 'image/png', 'image/jpeg']
                if (allowedTypes.includes(file["type"])) {
//                    let newArray = {uploaded_file: file};
                    let newArray = {uploaded_file: file};
//                    this.$set(newArray, "uploaded_file", file);
                    this.model.eform_answer[index].items.push(newArray);
                    this.documents.push({ file: file, index: item});
                }
                else
                {
                    alert('Please select a file type PDF or PNG/JPEG image file.');
                }

                console.log(this.model);
                // this.$set(this.record.form_answers[index].items, "uploaded_file", file);
            },
            removeFile(index) {
                this.files.splice(index, 1)
            },
            uploadAttachment(id){
                document.getElementById(id).click()
            },
            deleteItem(item, index) {
                item.table_items.splice(index, 1);;
            },
//            addRow(item) {
//                let tableLength = item.tables.length;
//                let length = (item.table_items !== undefined) ? item.table_items.length : 0;
//
//                if(length)
//                {
//                    // length++;
//                }
//                else
//                {
//                    length = 0;
//                }
//
//                if (item['table_items'] instanceof Array == false) {
//                    this.$set(item, "table_items", []);
//                }
//
//                this.$set(item.table_items, length, []);
//
//                for (let i = 0; i < tableLength; i++) {
//                    this.$set(item.table_items[length], i, []);
////                    item.table_items[length][i]['col_number'] = i+1;
////                    item.table_items[length][i]['label'] = "";
//                    this.$set(item.table_items[length][i], "col_number", i+1);
//                    this.$set(item.table_items[length][i], "label", '');
//                }
//
//                console.log(item.table_items);
//            },
            addRow(item) {
                let tableLength = item.tables.length;
                let length = (item.table_items !== undefined) ? item.table_items.length : 0;

                if(length === 0) {
                    length = 0;
                }

                if (!(item['table_items'] instanceof Array)) {
                    this.$set(item, "table_items", []);
                }

                // Create a new object instead of an array for the new row
                let newRow = {};

                // Iterate over the columns to create key-value pairs in the object
                for (let i = 0; i < tableLength; i++) {
                    // Create a unique key for each column (you can customize this as needed)
                    let columnKey = `col_${i+1}`;
                    newRow[columnKey] = {
                        col_number: i + 1,
                        label: ''
                    };
                }

                // Add the new object to the table_items array
                this.$set(item.table_items, length, newRow);

                console.log(item.table_items);
            },
        },
    }
</script>
<style scoped>
    table {
        border-collapse: collapse; /* Ensures borders between cells are merged */
        width: 100%; /* Optional: Sets the table width */
    }

    th, td {
        border: 1px solid black; /* Applies 1px solid black border to cells */
        padding: 8px; /* Optional: Adds padding for spacing */
        text-align: left; /* Optional: Aligns text to the left */
    }

    th {
        background-color: #f2f2f2; /* Optional: Adds a background color to headers */
    }

    .table thead th {
        color:black;
    }
</style>