<template>
  <div class="bg-default-dashboard">
    <div class=" py-lg-8 pt-lg-5">
      <b-container fluid style="width: 90%;">
        <b-row class="mb-2">
          <b-col>
            <h1 class="text-white">Project Site List</h1>
          </b-col>
        </b-row>
        <b-row>
          <div class="d-flex flex-column h-100 w-100 p-2">
            <div class="d-flex flex-column h-100 w-100 flex-grow-1 flex-shrink-1">
              <div style="padding: 4px;">
                <div style="float: right" class="d-flex">
                  <input
                    class="mr-2 form-control d-inline-block"
                    @keyup="onQuickFilterChanged"
                    type="text"
                    id="quickFilterInput"
                    placeholder="Type text to filter..."
                  />
                </div>
              </div>
              <div style="clear: both;"></div>
              <div v-if="showGrid" class="d-flex flex-column flex-grow-1 flex-shrink-1">
                <div style="padding: 4px" class="btn-toolbar">
                  <span>
                    <button
                      class="btn btn-primary mx-1"
                      @click="api.selectAll()"
                    >
                      Select All
                    </button>
                    <button
                      class="btn btn-primary mx-1"
                      @click="api.deselectAll()"
                    >
                      Clear Selection
                    </button>
                    <button
                      class="btn btn-primary mx-1"
                      v-on:click="toggleSideBar()"
                    >
                      Show Side Bar
                    </button>
                    <b-button size="md" variant="success" @click="openPlannerProject">Project List</b-button>
                    <b-button size="md" variant="success" @click="openPlannerLandingPage">Main Menu</b-button>
                  </span>
                </div>
                <ag-grid-vue style="width: 100%; height: 100%" class="flex-grow-1 flex-shrink-1 ag-theme-alpine"
                  :columnDefs="columnDefs" :rowData="rowData" :sideBar="sideBar" :modules="modules" :defaultColDef="{
                    sortable: true,
                    resizable: true,
                    filter: true,
                    floatingFilter: true,
                  }" 
                  :groupHeaders="true" 
                  :suppressRowClickSelection="true"
                  :allowContextMenuWithControlKey="true" rowSelection="multiple" @grid-ready="onReady"
                  @cell-clicked="onCellClicked" @cell-double-clicked="onCellDoubleClicked"
                  @cell-context-menu="onCellContextMenu" @cell-value-changed="onCellValueChanged"
                  @cell-focused="onCellFocused" @row-selected="onRowSelected" @selection-changed="onSelectionChanged"
                  @filter-modified="onFilterModified" @virtual-row-removed="onVirtualRowRemoved"
                  @row-clicked="onRowClicked" @column-everything-changed="onColumnEvent"
                  @column-row-group-changed="onColumnEvent" @column-value-Changed="onColumnEvent"
                  @column-moved="onColumnEvent" @column-visible="onColumnEvent" @column-group-Opened="onColumnEvent"
                  @column-resized="onColumnEvent" @column-pinned-count-changed="onColumnEvent"
                  :detailRowHeight=100
                  :detailRowAutoHeight="true"
                  :masterDetail="true"
                  :detailCellRendererParams="detailCellRendererParams_milestone">
                </ag-grid-vue>
              </div>
            </div>
          </div>
        </b-row>
      </b-container>

    </div>


  </div>
</template>

<script>
import { AgGridVue } from "@ag-grid-community/vue";
import HeaderGroupComponent from './HeaderGroupComponent.vue';
import axios from '@/util/axios'
import moment from "moment";

import { ClientSideRowModelModule } from "@ag-grid-community/client-side-row-model";
import { ColumnsToolPanelModule } from "@ag-grid-enterprise/column-tool-panel";
import { ExcelExportModule } from "@ag-grid-enterprise/excel-export";
import { FiltersToolPanelModule } from "@ag-grid-enterprise/filter-tool-panel";
import { SparklinesModule } from "@ag-grid-enterprise/sparklines";
import { GridChartsModule } from "@ag-grid-enterprise/charts";
import { MasterDetailModule } from "@ag-grid-enterprise/master-detail";
import { MenuModule } from "@ag-grid-enterprise/menu";
import { MultiFilterModule } from "@ag-grid-enterprise/multi-filter";
import { RangeSelectionModule } from "@ag-grid-enterprise/range-selection";
import { RichSelectModule } from "@ag-grid-enterprise/rich-select";
import { RowGroupingModule } from "@ag-grid-enterprise/row-grouping";
import { ServerSideRowModelModule } from "@ag-grid-enterprise/server-side-row-model";
import { SetFilterModule } from "@ag-grid-enterprise/set-filter";
import { SideBarModule } from "@ag-grid-enterprise/side-bar";
import { StatusBarModule } from "@ag-grid-enterprise/status-bar";
import { ViewportRowModelModule } from "@ag-grid-enterprise/viewport-row-model";
import { ClipboardModule } from "@ag-grid-enterprise/clipboard";
import { apiDomain, getHeader } from '@/assets/js/config.js';
import "@ag-grid-enterprise/core";

// import DisplaySiteDetailRenderer from "./cell-renderers/displaySiteDetailRenderer.vue";
// import CustomButtonComponent from './cell-renderers/customButtonComponentVue.js';


export default {
  data() {
    return {
      myUserID : null,
      detailCellRendererParams_milestone: null,
      obj_selection: {
        //_ we can change this later, if there is an API for get parameterize setting value
        ms_critical: ['Yes', 'No'],
        ms_status: ['NOT_STARTED', 'IN_PROGRESS', 'COMPLETED'],
      },
      obj_project: {
        id_project: null,
        tx_project: null,
        id_site: null,
        tx_site: null,
        id_milestone: null,
        id_milestone_task: null,
      },
      obj_user: {
        info: null,
        is_pm_leader: false,
        pm_group_id: null,
        pm_members: [],
      },
      obj_hit_api: {
        id_site: null,
        id_task: null,
        // selected_row_data: null,   //_ not needed anymore, it was use when using my own script (when cannot use onCellValueChanged )
        // updated_field: null,       //_ not needed anymore, it was use when using my own script (when cannot use onCellValueChanged )
        to_be_update_data: {},
      },
      model: {
        developers: [],
        project_name: null,
        project_ppa_sign_date_from_date: null,
        project_ppa_sign_date_to_date: null,
        project_ppa_capacity_greater_equal: null,
        project_ppa_capacity_lesser_equal: null,

        project_type: [],
        project_managers: [],
        project_engineers: [],
        project_business_development: [],
        project_safety: [],

        site_name: null,
        project_site_contractors: [],
        project_site_statuses: [],
        site_postal_code: null,
        site_code: null,
        roof_handover_date_from_date: null,
        roof_handover_date_to_date: null,
        site_cod_date_from_date: null,
        site_cod_date_to_date: null,
        site_target_turn_on_date_from_date: null,
        site_target_turn_on_date_to_date: null,
        site_actual_turn_on_date_from_date: null,
        site_actual_turn_on_date_to_date: null,
        site_total_capacity_greater_equal: null,
        site_total_capacity_lesser_equal: null,
        site_as_built_capacity_greater_equal: null,
        site_as_built_capacity_lesser_equal: null,
        site_module_brand_ids: [],
        site_module_quantity_greater_equal: null,
        site_module_quantity_lesser_equal: null,
        site_module_capacity_greater_equal: null,
        site_module_capacity_lesser_equal: null,
        site_module_allocation_total_capacity_greater_equal: null,
        site_module_allocation_total_capacity_lesser_equal: null,
        site_drawing_module_brand_ids: [],
        site_drawing_module_quantity_greater_equal: null,
        site_drawing_module_quantity_lesser_equal: null,
        site_drawing_module_capacity_greater_equal: null,
        site_drawing_module_capacity_lesser_equal: null,
        site_drawing_total_capacity_greater_equal: null,
        site_drawing_total_capacity_lesser_equal: null,
        site_drawing_ifa_date_from_date: null,
        site_drawing_ifa_date_to_date: null,
        site_drawing_ifc_date_from_date: null,
        site_drawing_ifc_date_to_date: null,
        ntp_applicable: null,
        ntp_issued: null,
        ntp_issue_date_from_date: null,
        ntp_issue_date_to_date: null,
        internal_project_handover_completed: null,
        internal_project_handover_date_from_date: null,
        internal_project_handover_date_to_date: null,
        kick_off_meeting_completed: null,
        kick_off_meeting_date_from_date: null,
        kick_off_meeting_date_to_date: null,
      },
      showFilters: false,
      api: null,
      columnDefs: null,
      showGrid: false,
      sideBar: false,
      rowCount: null,
      modules: [
        ClientSideRowModelModule,
        ColumnsToolPanelModule,
        ExcelExportModule,
        FiltersToolPanelModule,
        SparklinesModule,
        GridChartsModule,
        MasterDetailModule,
        MenuModule,
        MultiFilterModule,
        RangeSelectionModule,
        RichSelectModule,
        RowGroupingModule,
        ServerSideRowModelModule,
        SetFilterModule,
        SideBarModule,
        StatusBarModule,
        ViewportRowModelModule,
        ClipboardModule
      ],
      rowData: [],
      site_status:[],
      site_status_index:[],
      contractors:[],
      contractors_index:[],
      module_brands :[],
      module_brands_index:[],
      filter: {
        developers: [],
        project_managers: [],
        project_type: [],
        project_name:null,
      },
      status_bar:
          {
            statusPanels: [
              { statusPanel: "agTotalAndFilteredRowCountComponent" },
              {statusPanel: "agTotalRowCountComponent" },
              {statusPanel: "agFilteredRowCountComponent" },
              {statusPanel: "agSelectedRowCountComponent" },
              {statusPanel: "agAggregationComponent" },
            ],
          },
      site_milestones: [],
      myUserDetail : null,
    }
  },
  
created() {
    const LoginUser = JSON.parse(window.localStorage.getItem("loginUser"));

    this.myUserID = LoginUser;
    var project_site_access = JSON.parse(window.localStorage.getItem("project_site_access"));
    this.obj_project.id_site = '7384';

    var project_site_access = JSON.parse(
    window.localStorage.getItem("project_site_access")
);

},  
  components: {
    AgGridVue,
    HeaderGroupComponent,
  },
  setup() { },
  methods: {
    openPlannerLandingPage()
        {
            this.$router.push('plannerLandingPage');
        },
    openPlannerProject()
        {
            this.$router.push('plannerproject');
        },
    openWebPageView()
    {
      this.$router.push('CNISiteList');
    },
    testingLogUser(theUser = 0) {
      switch (theUser) {
        case 1:
          //_ user: planner
          this.obj_user.info = {
            email: "mylinh.bui@sembcorp.com",
            id: 82,
            name: "Bui My Linh",
            role_id: 19
          };
          break;
        case 2:
          //_ user: project manager + leader
          this.obj_user.info = {
            email: "janet.so@sembcorp.com",
            id: 11,
            name: "Janet So",
            role_id: 9
          };
          break;
        case 3:
          //_ user: project manager
          this.obj_user.info = {
            email: "kishen.s@sembcorp.com",
            id: 346,
            name: "Kishen S.",
            role_id: 9
          };
          break;
        case 4:
          //_ user: project manager + leader
          this.obj_user.info = {
            email: "eddie.tan@sembcorp.com",
            id: 4,
            name: "Eddie Tan",
            role_id: 9
          };
          break;
        case 5:
          //_ user: project manager
          this.obj_user.info = {
            email: "robertus.soeyono@sembcorp.com",
            id: 281,
            name: "Robertus Sidiq Soeyono",
            role_id: 9
          };
          break;
        case 6:
          //_ user: project manager
          this.obj_user.info = {
            email: "compe@sembcorp.com",
            id: 218,
            name: "COMPLETED - PE",
            role_id: 9
          };
          break;
        default:
          this.obj_user.info = JSON.parse(window.localStorage.getItem("loginUser"));
      }
    },

    errorFunction(err,info) {
            this.error = `An error occurred: ${err.message}`;
            console.error(err, info);
            return false; // prevents the error from propagating further
        },

    getDataToShow() {
      //_ role planner display all project and sites
      //_ role project manager leader - list all project and site for all project manager under him/her
      //_ role project manager - list all project and site only for his/her own
      // console.log('>>> func getDataToShow');
      // console.log(this.obj_user.info);

      axios.get(apiDomain+'api/get_user_project_developer/'+this.myUserID.id,{headers: getHeader()})
            .then ( response => {
                this.myUserDetail = response.data.data;
                for(let i = 0; i < this.myUserDetail.developers.length; i++)
                {
                  this.filter.developers.push(this.myUserDetail.developers[i].developer.developer_id);
                }
                console.log('Filter developer');
                console.log(this.filter.developers);

              })

            .catch(error => {
              this.errorFunction(error, this.title)
            })

            .finally(() => {

              this.filter.project_type.push(1);

              if (this.myUserID.role_id == 19) 
              {
                this.createRowData();
              } else if (this.obj_user.info.role_id == 9) {
                this.obj_user.pm_members.push(this.obj_user.info.id);
                this.getPMLeader();
              } else 
              {
                this.createRowData();
              }
        });
    },
    getPMLeader() {
      //_ check the user is project manager leader or not
      let arr_engineer_group = [];
      axios
        .get(apiDomain + 'setup/project_engineer_group', { headers: getHeader() })
        .then(response => {
          if (response.status === 200) {
            arr_engineer_group = response.data.data;
          }
        })
        .catch(error => {
          this.errorFunction(error, "Project Engineer Groups Listing")
        })
        .finally(() => {
          // console.log('>>> arr_engineer_group');
          // console.log(arr_engineer_group);

          //_ simplify using filter
          let tmpID = this.obj_user.info.id;
          
          var arr_filtered = arr_engineer_group.filter(function (value, index, arr) {
            return value.engineer_group_leader.id === tmpID;
          });

          // if ( arr_engineer_group.length > 0 ) {
          if (arr_filtered.length > 0) {
            this.obj_user.is_pm_leader = true;
            this.obj_user.pm_group_id = arr_filtered[0].engineer_group_id;
          }

          if (this.obj_user.is_pm_leader) 
          {
            this.getPMMembers();
          } 
          else 
          {
            this.filter.project_managers.push(this.obj_user.info.id);
            this.createRowData();
          }
        });
    },

    getPMMembers() {
      //_ get project members
      let arr_pm_members = [];
      axios
        .get(apiDomain + 'setup/project_engineer_group.show_by_group/' + this.obj_user.pm_group_id, { headers: getHeader() })
        .then(response => {
          if (response.status === 200) {
            arr_pm_members = response.data.data.map((item) => {
              return item.engineer_group_member.id;
            });
          }
        })
        .catch(error => {
          console.log(error.response.status)
          this.errorFunction(error, "Project Engineer Group Member")
        })
        .finally(() => {
          this.obj_user.pm_members = this.obj_user.pm_members.concat(arr_pm_members);
          this.filter.project_managers = this.obj_user.pm_members;
          this.createRowData();
        });
    },

    getContractorListing() {
                axios.get(apiDomain + 'setup/contractor', { headers: getHeader() })
                    .then ( response => {
                        if (response.status === 200)
                        {
                              for(let i = 0;i<response.data.data.length;i++)
                              {
                                if(response.data.data[i].active_status == 'ACTIVE' && response.data.data[i].contractor_name.includes('UAM') == false && response.data.data[i].contractor_name.includes('CCP3') == false)
                                {

                                  this.contractors.push(response.data.data[i].contractor_name);
                                  this.contractors_index.push({id:response.data.data[i].contractor_id,value:response.data.data[i].contractor_name});                                
                              
                                }
                              }
                        }
                    })
                    
                    .catch ( error => {
                        console.log(error.response.status)
                    })

                    .finally(() => {
                      //console.log("EPC:");
                      //console.log(this.contractors);
                      //console.log(this.contractors_index);
                    })                    
            },

    getProjectSiteStatusListing() 
    {
                axios.get(apiDomain + 'lookup/site_status.singapore_v2', { headers: getHeader() })
                    .then ( response => {
                        if (response.status === 200)
                        {
                            for(let i = 0;i<response.data.data.length;i++)
                            {
                              this.site_status.push(response.data.data[i].status_code);
                              this.site_status_index.push({id:response.data.data[i].status_id,value:response.data.data[i].status_code});                                
                            }
                            // console.log("SITE STATUS");
                            // console.log(this.site_status);
                            // console.log(this.site_status_index);
                        }
                    })
                    .catch ( error => {
                        if (typeof error.response !== "undefined") {
                            this.errorFunction(error, "Site Status Information")
                        }
                    } );
    },

    getModuleBrandListing(){
                axios.get(apiDomain + "setup/module_brand", { headers: getHeader() })
                    .then((response) => {
                        if (response.status === 200) {
                            for(let i = 0;i<response.data.data.length;i++)
                            {
                              this.module_brands.push(response.data.data[i].module_brand_id_code);
                              this.module_brands_index.push({id:response.data.data[i].module_brand_id,value:response.data.data[i].module_brand_id_code,other:response.data.data[i].watt_class});                                
                            }
                            // console.log("Module Brand");
                            // console.log(this.module_brands);
                            // console.log(this.module_brands_index);
                        }
                    })
                    .catch((error) => {
                        this.errorFunction(error, "Module Brand Information");
                    });
            },

    testingFilter() {
      //console.log('testingFilter');
      //console.log(this.obj_user);

      this.filter.project_managers = this.obj_user.pm_members;
      // this.filter.project_name = 'sharp';

      //console.log(this.filter);

      //_ project listing
      // axios.post(apiDomain + "project/get_project_listing", this.filter, { headers: getHeader() })
      // .then(response => {
      //     if (response.status === 200) {
      //         console.log('getDataToShow');
      //         console.log(response);
      //     }
      // })
      // .catch(error => {
      //     this.errorFunction(error, "Search Project Site Listing")
      // });

      //_ site listing
      axios
        .post('project/project_site.show_by_filter', this.filter, { headers: getHeader(), })
        .then(response => {
          this.rowData = response.data.data
          //console.log(this.rowData);
        })
        .catch(error => {
          this.errorFunction(error, this.title)
        })
    },    

    //_ original code
    createRowData() {
      //console.log('createRowData');

      //this.model.project_name = 'repo';

      axios
        .post('project/project_site.show_by_filter', this.filter, {
        headers: getHeader(),
        })
        .then(response => {
          this.rowData = response.data.data
          //console.log(this.rowData);
        })
        .catch(error => {
          this.errorFunction(error, this.title)
        })
        .finally(() => {
          this.getMilestone();
        })
    },

    getMilestone()
    {
      //console.log('TOTAL RECORD');
      //console.log(this.newrowData.length);
            
      for(let i=0; i < this.rowData.length; i++)
        { 
          //console.log("musk sini : i");
          axios.get(apiDomain + 'project/project_site_milestone.show_by_site/' + this.rowData[i].site_id, { headers: getHeader() })
            .then ( result => {
                if (result.status === 200)
                {
                    //console.log(result.data.data[0].contractor.contractor_name);
                    //this.contractor = result.data.data[0].contractor.contractor_name;
                    this.rowData[i] = {...this.rowData[i], milestone_tasks:result.data.data};
                    this.api.updateGridOptions({rowData:this.rowData});
                    //console.log("DATA NO:" + i);
                    //console.log(this.newrowData);       
                }
            })
            .catch ( error => {
                if (typeof error.response !== "undefined") {
                    this.errorFunction(error, "Site Contractor Information")
                }
            })                   
        }       
    },    


    searchData() {
      axios.post('project/project_site.show_by_filter', this.model, {
        headers: getHeader(),
      })
        .then(response => {
          this.rowData = response.data.data

        })
        .catch(error => {
          this.errorFunction(error, this.title)
        })
    },
    createColumnDefsTroy() {
      this.columnDefs = [
        {
          headerName: '#', minWidth: 60, width: 60, checkboxSelection: true, sortable: false,
          pinned: true, suppressHeaderMenuButton: true,
        },
        {
          headerName: 'Project Details',
          headerGroupComponent: 'HeaderGroupComponent',
          children: [
            {
              headerName: "Project Name", field: "project.project_name",
              width: 200, pinned: true, floatingFilter: true,
              cellRenderer: "agGroupCellRenderer",
            },
            { headerName: "Created At", field: "created_at", width: 150, filter: 'agDateColumnFilter', floatingFilter: true, suppressHeaderMenuButton: true, },
            { headerName: "Updated At ", field: "updated_at", width: 150, filter: 'agDateColumnFilter', floatingFilter: true, suppressHeaderMenuButton: true, },
          ]
        },
        {
          headerName: 'Project Manager',
          children: [
            { headerName: "Name", field: "project_manager.name", width: 150, floatingFilter: true, suppressHeaderMenuButton: true, },
            { headerName: "Email", field: "project_manager.email", width: 150, floatingFilter: true, suppressHeaderMenuButton: true, },
          ]
        },
        {
          headerName: 'Group',
          headerGroupComponent: 'HeaderGroupComponent',
          children: [
            { headerName: "Name", field: "group.group_name", width: 150, suppressHeaderMenuButton: true, },
            {
              headerName: "Engineer", headerGroupComponent: 'HeaderGroupComponent',
              children: [
                { headerName: "Name", field: "created_by.name", width: 150, floatingFilter: true, suppressHeaderMenuButton: true, },
                { headerName: "Email", field: "created_by.email", width: 150, floatingFilter: true, suppressHeaderMenuButton: true, }
              ],
            },
          ]
        },
        {
          headerName: 'Site',
          children: [
            { headerName: "Names", field: "site_name", width: 150, floatingFilter: true, suppressHeaderMenuButton: true, },
            { headerName: "Postal Code", field: "site_postal_code", width: 150, floatingFilter: true, suppressHeaderMenuButton: true, },
            { headerName: "Address", field: "site_address", width: 150, editable: true, floatingFilter: true, suppressHeaderMenuButton: true, },
            { headerName: "Total Installation", field: "site_total_installation", width: 150, floatingFilter: true, suppressHeaderMenuButton: true, },
            { headerName: "Total Task", field: "site_total_task", width: 150, suppressHeaderMenuButton: true, },
            { headerName: "Total Document", field: "site_total_document", width: 150, suppressHeaderMenuButton: true, },
            { headerName: "Completed Document", field: "site_completed_document", width: 150, suppressHeaderMenuButton: true, },
            { headerName: "Completed Task", field: "site_completed_task", width: 150, suppressHeaderMenuButton: true, },
            { headerName: "Progress", field: "site_progress", width: 150, suppressHeaderMenuButton: true, },
            { headerName: "Module Quantity", field: "site_module_quantity", width: 150, suppressHeaderMenuButton: true, },
            { headerName: "Module Capacity", field: "site_module_capacity", width: 150, suppressHeaderMenuButton: true, },
            { headerName: "Total Capacity", field: "site_total_capacity", width: 150, suppressHeaderMenuButton: true, },
            { headerName: "As Built Capacity", field: "site_as_built_capacity", width: 150, suppressHeaderMenuButton: true, },
            { headerName: "Completed Capacity", field: "site_completed_capacity", width: 150, suppressHeaderMenuButton: true, },
            { headerName: "PR Capacity", field: "site_pr_capacity", width: 150, suppressHeaderMenuButton: true, },
            { headerName: "Type of Tension", field: "site_type_of_tension", width: 150, suppressHeaderMenuButton: true, },
            { headerName: "Approved Load", field: "site_approved_load", width: 150, suppressHeaderMenuButton: true, },
            { headerName: "MSSL Account Number", field: "site_mssl_account_number", width: 150, suppressHeaderMenuButton: true, },
            { headerName: "Plant Name", field: "site_plant_name", width: 150, suppressHeaderMenuButton: true, },
            { headerName: "Folder", field: "site_folder_in_sftp_server", width: 150, suppressHeaderMenuButton: true, },
            { headerName: "Progress", field: "site_monitoring_application", width: 150, suppressHeaderMenuButton: true, },
            { headerName: "Inverter Type", field: "site_inverter_type", width: 150, suppressHeaderMenuButton: true, },
            { headerName: "Inverter Qty", field: "site_inverter_qty", width: 150, suppressHeaderMenuButton: true, },
            { headerName: "Total Inverter", field: "site_total_inverter", width: 150, suppressHeaderMenuButton: true, },
            { headerName: "Logger Type", field: "site_logger_type", width: 150, suppressHeaderMenuButton: true, },
            { headerName: "Total Data Logger", field: "site_total_data_logger", width: 150, suppressHeaderMenuButton: true, },
            { headerName: "Start Date", field: "site_start_date", width: 150, suppressHeaderMenuButton: true, },
            { headerName: "End Date", field: "site_end_date", width: 150, suppressHeaderMenuButton: true, },
            { headerName: "Turn On Date", field: "est_turn_on_date", width: 150, suppressHeaderMenuButton: true, },
            { headerName: "Contractor Start Date", field: "site_contractor_start_date", width: 150, suppressHeaderMenuButton: true, },
            { headerName: "Contractor End Date", field: "site_contractor_end_date", width: 150, suppressHeaderMenuButton: true, },
            { headerName: "Elu End Date", field: "site_elu_end_date", width: 150, suppressHeaderMenuButton: true, },
            { headerName: "El Expiry Date", field: "site_el_expiry_date", width: 150, suppressHeaderMenuButton: true, },
            { headerName: "Target Turn On Date", field: "site_target_turn_on_date", width: 150, suppressHeaderMenuButton: true, },
            { headerName: "Actual Turn On Date", field: "site_actual_turn_on_date", width: 150, suppressHeaderMenuButton: true, },
            { headerName: "Elu license number", field: "site_elu_license_number", width: 150, suppressHeaderMenuButton: true, },
            { headerName: "Elu status", field: "site_elu_status", width: 150, suppressHeaderMenuButton: true, },
            { headerName: "Service Location ID", field: "site_service_location_id", width: 150, suppressHeaderMenuButton: true, },
            { headerName: "Power premise type", field: "site_power_premise_type", width: 150, suppressHeaderMenuButton: true, },
            { headerName: "PPA Approved Capacity", field: "site_ppa_approved_capacity", width: 150, suppressHeaderMenuButton: true, },
            { headerName: "Country", field: "site_country", width: 150, suppressHeaderMenuButton: true, },
            { headerName: "Code", field: "site_code", width: 150, suppressHeaderMenuButton: true, },
            { headerName: "Latitude", field: "site_latitude", width: 150, suppressHeaderMenuButton: true, },
            { headerName: "Longtitude", field: "site_longitude", width: 150, suppressHeaderMenuButton: true, },
            { headerName: "Cod Date", field: "site_cod_date", width: 150, suppressHeaderMenuButton: true, },
            { headerName: "Elu status", field: "site_elu_status", width: 150, suppressHeaderMenuButton: true, },

          ]
        },
        {
          headerName: 'Site Drawing',
          children: [
            {
              headerName: "Module Brand", headerGroupComponent: 'HeaderGroupComponent',
              children: [
                { headerName: "Code", field: "site_module_brand.module_brand_id_code", width: 150, suppressHeaderMenuButton: true, },
                { headerName: "Name", field: "site_module_brand.module_brand_id_name", width: 150, suppressHeaderMenuButton: true, }
              ],

            },
            { headerName: "Module Quantity", field: "site_drawing_module_quantity", width: 150, suppressHeaderMenuButton: true, },
            { headerName: "Module Capacity", field: "site_drawing_module_capacity", width: 150, suppressHeaderMenuButton: true, },
            { headerName: "Total Capacity", field: "site_drawing_total_capacity", width: 150, suppressHeaderMenuButton: true, },
            { headerName: "IFA Date", field: "site_drawing_ifa_date", width: 150, suppressHeaderMenuButton: true, },
            { headerName: "IFC Date", field: "site_drawing_ifc_date", width: 150, suppressHeaderMenuButton: true, },
            { headerName: "Allocation Total Capacity", field: "site_module_allocation_total_capacity", width: 150, suppressHeaderMenuButton: true, },
            { headerName: "IFA Module Remarks", field: "site_ifc_ifa_module_remarks", width: 150, suppressHeaderMenuButton: true, },

          ]
        },
        {
          headerName: 'Status',
          children: [
            { headerName: "Code", field: "status.status_code", width: 150, suppressHeaderMenuButton: true, },
            { headerName: "Info", field: "status.status_info", width: 150, suppressHeaderMenuButton: true, },
          ]
        },
        {
          headerName: "Created by", headerGroupComponent: 'HeaderGroupComponent',
          children: [
            { headerName: "Email", field: "created_by.email", width: 150, suppressHeaderMenuButton: true, },
            { headerName: "Name", field: "created_by.name", width: 150, suppressHeaderMenuButton: true, }
          ],

        },     
      ];
    },

  //01.01 BUILD THE GRID - CONFIGURE THE COLUMN
  createColumnDefs() {
      this.columnDefs = [
        {
          headerName: '#', minWidth: 60, width: 60, checkboxSelection: true, sortable: false,
          pinned: true, suppressHeaderMenuButton: true,
        },
        {
          headerName: 'Project Details',
          headerGroupComponent: 'HeaderGroupComponent',
          children: [
              {
                headerName: "Project Name",
                field: "project.project_name",
                flex: 1,
                // width: 250,
                cellRenderer: "agGroupCellRenderer",
                pinned: true,
                floatingFilter: true,
                cellRenderer: "agGroupCellRenderer",
              },
              { 
                headerName: "Project Manager", 
                field: "project.project_manager.name", 
                width: 150, 
                floatingFilter: true, 
                pinned: true, 
                suppressHeaderMenuButton: true, 
            },
          ]
        },
        {
          headerName: 'Site Profile',
          children: [
            {
              headerName: "Project Site",
              field: "site_name",
              width: 300,
              editable: true,
              // floatingFilter: true,
              // suppressHeaderMenuButton: true,
            },
            { 
              headerName: "Site Status", 
              field: "status.status_code", 
              width: 150, 
              floatingFilter: true, 
              suppressHeaderMenuButton: true,
              cellEditor: "agSelectCellEditor", 
              cellEditorParams: { values: this.site_status }, 
              filter: 'agSetColumnFilter', 
              editable: true, 
            },
            { headerName: "Address", field: "site_address", width: 150, floatingFilter: true, suppressHeaderMenuButton: true,editable: true,  hide:true,},
            { headerName: "Postal Code", field: "site_postal_code", width: 150, floatingFilter: true, suppressHeaderMenuButton: true,editable: true, hide:true, },
            { headerName: "Latitude", field: "site_latitude", width: 150, suppressHeaderMenuButton:true,editable:true,hide:true,},
            { headerName: "Longtitude", field: "site_longitude", width: 150, suppressHeaderMenuButton:true,editable:true,hide:true,},
            { 
              headerName: "EPC", 
              field: "site_contractor.contractor.contractor_name", 
              width: 150, suppressHeaderMenuButton:true,
              floatingFilter: true, 
              suppressHeaderMenuButton: true,
              cellEditor: "agSelectCellEditor", 
              cellEditorParams: { values: this.contractors }, 
              filter: 'agSetColumnFilter', 
              editable: true, 
            },
            { headerName: "Site Code", field: "site_code", width: 150, suppressHeaderMenuButton:true,editable:true,},
            { headerName: "Roof Handover Date", field: "", width: 150, suppressHeaderMenuButton:true,editable:true,hide:true,},
            { headerName: "COD", field: "site_cod_date", width: 150, suppressHeaderMenuButton: true,editable:true,cellRenderer: (data) => { return (data.value) ? moment(data.value).format('DD MMM YYYY') : '' } },
            { headerName: "Planned Turn On Date", field: "site_target_turn_on_date", width: 150,editable:true,suppressHeaderMenuButton: true,cellRenderer: (data) => { return (data.value) ? moment(data.value).format('DD MMM YYYY') : '' } },
            { headerName: "Actual Turn On Date", field: "site_actual_turn_on_date", width: 150,editable:true,suppressHeaderMenuButton: true,cellRenderer: (data) => { return (data.value) ? moment(data.value).format('DD MMM YYYY') : '' } },      
            { headerName: "Site PPA Total Capacity(kWp)", field: "", width: 150, suppressHeaderMenuButton: true,hide:true,},
            { headerName: "As Built Capacity(kWp)", field: "site_as_built_capacity", width: 150, suppressHeaderMenuButton: true,editable:true},

          ],
        },
        {
          headerName: 'IFA/IFC/Module Allocation',
          children: [
            {
              headerName: 'Module Allocation',
                children: [
                { 
                  headerName: "Brand", 
                  field: "site_module_brand.module_brand_id_name", 
                  width: 150, 
                  floatingFilter: true, 
                  suppressHeaderMenuButton: true,
                  cellEditor: "agSelectCellEditor", 
                  cellEditorParams: { values: this.module_brands }, 
                  filter: 'agSetColumnFilter', 
                  editable: true, 
                  hide:true,
                },
                { 
                  headerName: "Number of Module", 
                  field: "site_module_quantity", 
                  width: 150, 
                  floatingFilter: true, 
                  suppressHeaderMenuButton: true,
                  cellRenderer: data => {
                    return Math.floor(data.value);
                  },
                  editable:true,
                  hide:true,
                },
                {
                  headerName: "Watt Class", 
                  field: "site_module_capacity", 
                  width: 150, 
                  floatingFilter: true, 
                  suppressHeaderMenuButton: true,
                  cellRenderer: data => {
                    return Math.floor(data.value);
                  },
                  hide:true,
                },
                {
                  headerName: "Total Capacity(kWp)", 
                  field: "site_module_allocation_total_capacity", 
                  width: 150, 
                  floatingFilter: true, 
                  suppressHeaderMenuButton: true,
                  hide:true,
                },

                ]
            },
          {
            headerName: 'Drawing',
                children: [
                { 
                  headerName: "Brand", 
                  field: "site_drawing_module_brand.module_brand_id_name", 
                  width: 150, 
                  floatingFilter: true, 
                  suppressHeaderMenuButton: true,
                  cellEditor: "agSelectCellEditor", 
                  cellEditorParams: { values: this.module_brands }, 
                  filter: 'agSetColumnFilter', 
                  editable: true, 
                  hide:true,
                },
                { 
                  headerName: "Number of Module", 
                  field: "site_drawing_module_quantity", 
                  width: 150, 
                  floatingFilter: true, 
                  suppressHeaderMenuButton: true,
                  cellRenderer: data => {
                    return Math.floor(data.value);
                  },
                  editable:true,
                  hide:true,
                },
                {
                  headerName: "Watt Class", 
                  field: "site_drawing_module_capacity", 
                  width: 150, 
                  floatingFilter: true, 
                  suppressHeaderMenuButton: true,
                  cellRenderer: data => {
                    return Math.floor(data.value);
                  },
                  hide:true,
                },
                {
                  headerName: "Total Capacity(kWp)", 
                  field: "site_drawing_total_capacity", 
                  width: 150, 
                  floatingFilter: true, 
                  suppressHeaderMenuButton: true,
                  hide:true,
                },

                ]
          },
          { headerName: "Remarks", field: "site_ifc_ifa_module_remarks", width: 150, floatingFilter: true, suppressHeaderMenuButton: true,editable:true, hide:true,},
          ]
        },

      ];
    },

    initiateSubGrid_milestone() {
      this.detailCellRendererParams_milestone = {
        detailGridOptions: {
          columnDefs: [
            { headerName: "Task Name", field: "site_milestone_code", flex: 1.5, cellRenderer: "agGroupCellRenderer"  },
            { headerName: "Planned Start Date", field: "planned_start_date", width: 200,
              editable: true, headerClass: 'agisEditable', 
              cellDataType: 'dateString',
              cellEditor: "agDateStringCellEditor",
              cellRenderer: (params) => { return this.format_datetime(params.data.planned_start_date) },
            },
            { headerName: "Planned End Date", field: "planned_end_date", width: 200,
              editable: true, headerClass: 'agisEditable',
              // cellEditor: "agDateCellEditor",
              cellDataType: 'dateString',
              cellEditor: "agDateStringCellEditor",
              cellRenderer: (params) => { return this.format_datetime(params.data.planned_end_date) },
            },
            { headerName: "Actual Start Date", field: "actual_start_date", width: 200,
              editable: true, headerClass: 'agisEditable', 
              cellDataType: 'dateString',
              cellEditor: "agDateStringCellEditor",
              cellRenderer: (params) => { return this.format_datetime(params.data.actual_start_date) },
            },
            { headerName: "Actual End Date", field: "actual_end_date", width: 200,
              editable: true, headerClass: 'agisEditable',
              // cellEditor: "agDateCellEditor",
              cellDataType: 'dateString',
              cellEditor: "agDateStringCellEditor",
              cellRenderer: (params) => { return this.format_datetime(params.data.actual_end_date) },
            },
            { headerName: "Remarks", headerClass: 'agisEditable',field: "remarks", width: 200,  editable: true },
          ],
          headerHeight: 38,
          onCellValueChanged: ($event) => {
            //_ this one is for sub grid milestone 
            this.populate_data_milestone_to_be_submit_into_db($event);
          },
          defaultColDef: {
            flex: 1,
          },
          // groupDefaultExpanded: 1,
          detailRowHeight: 50,
          detailRowAutoHeight: true,
          masterDetail: true,
          detailCellRendererParams: (params) => {
            var task = {};
            task.detailGridOptions = {
              columnDefs: [
                { headerName: "Task Name", field: "site_milestone_task_name", flex: 1.5, cellRenderer: "agGroupCellRenderer" },
                {
                  headerName: "Planned Start Date", field: "site_milestone_task_planned_start_date", width: 200,
                  cellRenderer: (params) => { return this.format_datetime(params.data.site_milestone_task_planned_start_date) }
                },
                {
                  headerName: "Planned End Date", field: "site_milestone_task_planned_end_date", width: 200,
                  cellRenderer: (params) => { return this.format_datetime(params.data.site_milestone_task_planned_end_date) }
                },
                {
                  headerName: "Actual Start Date", field: "site_milestone_task_actual_start_date", width: 200,
                  editable: true, headerClass: 'agisEditable',
                  cellDataType: 'dateString',
                  cellEditor: "agDateStringCellEditor",
                  cellRenderer: (params) => { return this.format_datetime(params.data.site_milestone_task_actual_start_date) },
                },
                {
                  headerName: "Actual End Date", field: "site_milestone_task_actual_end_date", width: 200,
                  editable: true, headerClass: 'agisEditable',
                  // cellEditor: "agDateCellEditor",
                  cellDataType: 'dateString',
                  cellEditor: "agDateStringCellEditor",
                  cellRenderer: (params) => { return this.format_datetime(params.data.site_milestone_task_actual_end_date) },
                },
                {
                  headerName: "Critical", field: "site_milestone_task_critical", width: 150,
                  editable: true, headerClass: 'agisEditable',
                  cellEditor: "agSelectCellEditor",
                  cellEditorParams: { values: this.obj_selection.ms_critical },
                  // cellEditorParams: { values: this.bind_selection_field_critical() }
                },
                {
                  headerName: "Status", field: "site_milestone_task_status", width: 200,
                  editable: true, headerClass: 'agisEditable',
                  cellEditor: "agSelectCellEditor",
                  cellEditorParams: { values: this.obj_selection.ms_status },
                },
              ],
              context: {
                params: params,   // parent grid reference
              },
              headerHeight: 38,
              onCellValueChanged: ($event) => {
                this.populate_data_task_to_be_submit_into_db($event);
              }
            };
            task.getDetailRowData = (params) => {
              //_ this one is sub grid for task
              // console.log('child2');
              // console.log(params);
              params.successCallback(params.data.milestone_tasks);
            };
            
            return task;
          }
        },
        getDetailRowData: (params) => {
          //_ this one is sub grid for milestone
          // console.log('child1');
          // console.log(params);
                    
          params.successCallback(params.data.milestone_tasks);

          // this.sub_data_milestone = this.get_milestone_by_site(params.data.site_id);
          // console.log(tmpData);
          // params.successCallback(tmpData);
        },
      };
    },

    populate_data_milestone_to_be_submit_into_db( AgEvent ) {
      //console.log('populate_data_milestone_to_be_submit_into_db');
      //console.log(AgEvent);
      //console.log(this.rowData);

      const site_data = this.rowData.find(obj => obj.site_id === AgEvent.data.site_id);
      let id_site = AgEvent.data.site_id;
      let tx_site = site_data.site_name;
      let tx_milestone = AgEvent.data.site_milestone_info;
      // console.log('Find the row data index');
      // console.log(site_data.milestone_tasks);


      this.site_milestones = site_data.milestone_tasks;

      // console.log('Transfer to this.site_milestones');
      // console.log(this.site_milestones);
      
      // console.log('Check the changes is in whichrow');
      // console.log(AgEvent.rowIndex);

      const updated_index = AgEvent.rowIndex;

      // console.log('show the new set of data');
      // console.log(AgEvent.data);

      

      this.site_milestones[updated_index] = AgEvent.data;

      // console.log ("New Milestone");
      // console.log (this.site_milestones);

      axios
        .post(apiDomain + 'project/project_site_milestone.update_site_milestones/' + id_site, this.site_milestones, { headers: getHeader() })
        .then(response => {
            if (response.status === 200) {
              this.$notify({
                message:
                  '<b>Update Project Site :' + tx_site + '<br>Milestone: ' + tx_milestone + '</b><br><br> Successfully Updated.',
                timeout: 10000,
                icon: 'ni ni-bell-55',
                type: 'default',
              });
              //   this.$router.back()

            }
          })
        .catch(error => {
          console.log(error.response.data.errors)
          this.errorFunction(error, "Project Information")
        });
    },

    populate_data_task_to_be_submit_into_db(AgEvent) {
      console.log('populate_data_task_to_be_submit_into_db');
      console.log(AgEvent);
      console.log(this.rowData);
      
      const site_data = this.rowData.find(obj => obj.site_id === AgEvent.context.params.data.site_id);
      let id_site = site_data.site_id;
      let tx_site = site_data.site_name;
      let tx_milestone = AgEvent.context.params.data.site_milestone_info;
      let id_task = AgEvent.data.site_milestone_task_id;
      let tx_task = AgEvent.data.site_milestone_task_name;

      console.log(site_data);
      console.log('id_site: ' + id_site);
      console.log('tx_site: ' + tx_site);
      console.log('id_task: ' + id_task);
      

      //_ initiate un-change required field
      let obj_update = {
        site_milestone_task_name: AgEvent.data.site_milestone_task_name,
        site_milestone_task_planned_start_date: this.format_datetime(AgEvent.data.site_milestone_task_planned_start_date, 'db'),
        site_milestone_task_planned_end_date: this.format_datetime(AgEvent.data.site_milestone_task_planned_end_date, 'db'),
      };

      //_ is status the one that being update or not ? because it's one of required field need to be post to API
      //  if it not status, then we use the status as is from the db (or what we got from the API)
      let updated_field = AgEvent.colDef.field;
      let updated_value = '';
      if (updated_field == 'site_milestone_task_actual_start_date' || updated_field == 'site_milestone_task_actual_end_date') {
        updated_value = this.format_datetime(AgEvent.newValue, 'db');
      } else {
        updated_value = AgEvent.newValue
      }
      if (updated_field != 'site_milestone_task_status') {
        obj_update = { ...obj_update, site_milestone_task_status: AgEvent.data.site_milestone_task_status, [updated_field]: updated_value }
      } else {
        obj_update = { ...obj_update, site_milestone_task_status: AgEvent.data.site_milestone_task_status }
      }

      // this.obj_hit_api.to_be_update_data = { ...obj_update }

      console.log(obj_update);

      axios
        .post(apiDomain + 'task/edit_site_milestone_task/' + id_site + "/" + id_task, obj_update, { headers: getHeader() })
        .then(response => {
          if (response.status === 200) {
            this.$notify({
              message:
                '<b>Update Project Site :' + tx_site + '<br>Milestone: ' + tx_milestone + '<br>Task: ' + tx_task + '</b><br><br> Successfully Updated.',
              timeout: 10000,
              icon: 'ni ni-bell-55',
              type: 'default',
            });
          }
        })
        .catch(error => {
          console.log(error.response.data.errors)
          this.errorFunction(error, "Project Information")
        });      
    },

    get_milestone_by_site(id_site=0) {
      console.log('get_milestone_by_site');
      console.log(id_site);

      axios
        .get(apiDomain + 'project/project_site_milestone.show_by_site/' + id_site, { headers: getHeader() })
        .then ( result => {
            if (result.status === 200) {
                //console.log(result.data.data[0].contractor.contractor_name);
                //this.contractor = result.data.data[0].contractor.contractor_name;
              // this.rowData[i] = {...this.rowData[i], milestone_tasks:result.data.data};
              // this.api.updateGridOptions({rowData:this.rowData});
              //console.log("DATA NO:" + i);
              //console.log(this.newrowData);       

              console.log(result.data.data);
              return result.data.data;
            }
        })
        .catch ( error => {
          if (typeof error.response !== "undefined") {
              this.errorFunction(error, "get_milestone_by_site")
          }
        })
    },


    pad(num, totalStringSize) {
      let asString = num + "";
      while (asString.length < totalStringSize) asString = "0" + asString;
      return asString;
    },
    onReady(params) {
      console.log('onReady');

      this.api = params.api;
      this.api.sizeColumnsToFit();
    },
    onCellValueChanged(event) {
      if(event.colDef.field = 'site_contractor.contractor.contractor_name')
      {
        console.log('Update contractors');
        this.onCellValueChangedContractor(event);
      }
      else
      {
        this.onCellValueChangedSite(event);
      }
    },

    onCellValueChangedContractor(event)
    {
        const site_contractor_model = {
        contractor_id: this.searchKeyword(event.data.site_contractor.contractor.contractor_name,this.contractors_index),
        site_id: event.data.site_id,
        active_status: 1
        };
        console.log("SELECTED CONTRACTOR");
        console.log(site_contractor_model);
        axios.post(apiDomain + 'project/site_contractor.set_active_contractor/' + event.data.site_id, site_contractor_model, { headers: getHeader() })
          .then(response => {
            if (response.status === 200) {
              this.$notify({
                message:
                  '<b>Update Project Site : ' + this.model.site_name + '</b> - Successfully Updated.',
                timeout: 10000,
                icon: 'ni ni-bell-55',
                type: 'default',
              });
            }
          })
          .catch(error => {
            console.log(error.response.data.errors)
            this.errorFunction(error, "Project Information")
          });
    },

    onCellValueChangedSite(event) {
      this.model.site_id = event.data.site_id;
      this.model.status_id = this.searchKeyword(event.data.status.status_code,this.site_status_index);
      this.model.site_name = event.data.site_name;
      this.model.site_latitude = event.data.site_latitude;
      this.model.site_longitude = event.data.site_longitude;
      this.model.site_code = event.data.site_code;
      this.model.site_address = event.data.site_address;
      this.model.site_postal_code = event.data.site_postal_code;
      this.model.site_cod_date = event.data.site_cod_date;
      this.model.site_actual_turn_on_date = event.data.site_actual_turn_on_date;
      this.model.site_target_turn_on_date = event.data.site_target_turn_on_date;
      this.model.site_as_built_capacity = event.data.site_as_built_capacity;
      this.model.site_module_brand_id = this.searchKeyword(event.data.site_module_brand.module_brand_id_name,this.module_brands_index);
      this.model.site_module_capacity = this.searchKeywordOther(event.data.site_module_brand.module_brand_id_name,this.module_brands_index);
      this.model.site_module_quantity = event.data.site_module_quantity;
      this.model.site_module_allocation_total_capacity = (this.model.site_module_capacity * this.model.site_module_quantity)/1000
      this.model.site_drawing_module_brand_id = this.searchKeyword(event.data.site_drawing_module_brand.module_brand_id_name,this.module_brands_index);
      this.model.site_drawing_module_capacity = this.searchKeywordOther(event.data.site_drawing_module_brand.module_brand_id_name,this.module_brands_index);
      this.model.site_drawing_module_quantity = event.data.site_drawing_module_quantity;
      this.model.site_drawing_total_capacity = (this.model.site_drawing_module_capacity * this.model.site_drawing_module_quantity)/1000

      axios.post(apiDomain + 'project/update_project_site_details/' + event.data.site_id, this.model, { headers: getHeader() })
      //axios.put(apiDomain + 'project/project_site/' + event.data.site_id, this.model, { headers: getHeader() })
        .then(response => {
          if (response.status === 200) {
            this.$notify({
              message:
                '<b>Update Project Site : ' + this.model.site_name + '</b> - Successfully Updated.',
              timeout: 10000,
              icon: 'ni ni-bell-55',
              type: 'default',
            });
            //   this.$router.back()

          }
        })
        .catch(error => {
          console.log(error.response.data.errors)
          this.errorFunction(error, "Project Information")
        });

    },

    searchKeyword(strSearch,arrToSearch) 
      {
        //console.log("keyword : " + strSearch);
        //console.log(arrToSearch);
        const result = arrToSearch.find(item =>
          item.value.includes(strSearch)
        );
        let foundIndex = result ? result.id : null;
        //console.log("foundIndex : " + foundIndex);
        return foundIndex;
      },

      searchKeywordOther(strSearch,arrToSearch) 
      {
        //console.log("keyword : " + strSearch);
        //console.log(arrToSearch);
        const result = arrToSearch.find(item =>
          item.value.includes(strSearch)
        );
        let foundIndex = result ? result.other : null;
        //console.log("foundIndex : " + foundIndex);
        return foundIndex;
      },


    onCellDoubleClicked(event) {
      // this.$router.push({path: '/RichGridExample/Edit/' + event.data.project_id})
      console.log(event.data.project_id);
      console.log('onCellDoubleClicked: ' + event.rowIndex + ' ' + event.colDef.field);
    },
    onCellContextMenu(event) {
      console.log('onCellContextMenu: ' + event.rowIndex + ' ' + event.colDef.field);
    },
    onCellFocused(event) {
      console.log('onCellFocused: (' + event.rowIndex + ',' + event.colIndex + ')');
    },
    // taking out, as when we 'select all', it prints to much to the console!!
    // eslint-disable-next-line
    onRowSelected(event) {
      // console.log('onRowSelected: ' + event.node.data.name);
    },
    onSelectionChanged() {
      console.log('selectionChanged');
    },
    onFilterModified() {
      console.log('onFilterModified');
    },
    // eslint-disable-next-line
    onVirtualRowRemoved(event) {
      // because this event gets fired LOTS of times, we don't print it to the
      // console. if you want to see it, just uncomment out this line
      // console.log('onVirtualRowRemoved: ' + event.rowIndex);
    },
    onRowClicked(event) {
      console.log('onRowClicked: ' + event.node.data.name);
    },
    onQuickFilterChanged(event) {
      this.api.setQuickFilter(event.target.value);
    },

    // here we use one generic event to handle all the column type events.
    // the method just prints the event name
    onColumnEvent(event) {
      console.log('onColumnEvent: ' + event);
    },
    toggleSideBar() {
      this.sideBar = !this.sideBar;
      // this.api.setSideBar(this.sideBar);
      this.api.setSideBarVisible(this.sideBar);
    },

    /* CUSTOM library, make it DRY and clean code */
    format_datetime( tmp_value, isFor='ui' ) {
      //_ default from db/api datetime format in iso8601, ex.2024-06-08T00:00:00.000000Z
      //  so isFor='ui' we format it into readable format DD Mmm YYYY
      //  the default is for frontend UI, if we didn't pass the 2nd parameter
      //  to save into db, change isFor='db', so it's formatted into YYYY-MM-DD H:i:s  <- this is the value accepted, tried YYYY-MM-DD only, and failed
      if ( isFor == 'ui' ) {
        return ( tmp_value === null ) ? '' : moment(tmp_value).format('DD MMM YYYY');
      } else if ( isFor == 'db' ) {
        return ( tmp_value === null ) ? '' : moment(tmp_value).format('YYYY-MM-DD HH:mm:ss');
      } else {
        return '';
      }
      
    },

  },
  beforeMount() {
    this.api = {};
    this.obj_user.info = this.myUserID;
    this.getProjectSiteStatusListing();
    this.getContractorListing();
    this.getModuleBrandListing();
    this.getDataToShow();
    this.createColumnDefs();
    this.initiateSubGrid_milestone();
    // this.initiateSubGrid_task();

    this.showGrid = true;
  }
}
</script>

<style>
.ag-root-wrapper-body.ag-layout-normal {
  height: 700px
}

.ag-cell {
  padding-top: 2px !important;
  padding-bottom: 2px !important;
}

label {
  font-weight: normal !important;
}

.bg-default-dashboard {
  background-color: #082A2F;
  padding: 0;
  margin: 0;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.div-percent-bar {
  display: inline-block;
  height: 100%;
  position: relative;
  z-index: 0;
}

.div-percent-value {
  position: absolute;
  padding-left: 4px;
  font-weight: bold;
  font-size: 13px;
  z-index: 100;
}

.div-outer-div {
  display: inline-block;
  height: 100%;
  width: 100%;
}

.ag-menu {
  z-index: 200;
}

.toolbar button {
  margin-left: 5px;
  margin-right: 5px;
  padding: 2px;
}
</style>