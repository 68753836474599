<template>
  <div class="content">
    <base-header class="pb-6">
      <b-row align-v="center" class="py-4">
        <b-col cols="7" lg="6">
          <h6 class="h2 text-white d-inline-block mb-0">Project Site Listing Filter</h6>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <route-bread-crumb></route-bread-crumb>
          </nav>
        </b-col>
        <b-col lg="6" cols="5" class="text-right">
          <!-- <router-link to="/ProjectSiteNew">
            <base-button size="sm" type="neutral"
            v-if="button_access.new_button == 'YES'">New Project Site</base-button>
          </router-link> -->
        </b-col>
      </b-row>
    </base-header>
    <b-container fluid class="mt--6">
      <div>
        <card class="no-border-card" body-classes="px-0 pb-1" footer-classes="pb-2">
          <template slot="header">
            <h3 class="mb-0">Project Site Listing Filter</h3>
          </template>
          <div>
            <card>
              <!-- <h3>Filters + (icon)</h3>
              <div @click="toggleShowFilters">
                <i class="ni ni-shop"></i>
              </div> -->
              <h3 @click="toggleShowFilters">
                Filters
                <i v-if="!showFilters" class="ni ni-fat-add"></i>
                <i v-else class="ni ni-fat-delete"></i>
              </h3>
              <card v-if="showFilters">

              
              <div class="form-row">
                <b-col cols="8">
                  <label for="developersSelect" class="form-control-label">Developer</label>
                  <select multiple class="form-control" id="developersSelect" v-model="filter.developers" size="7">
                    <option 
                        v-for="item in developers"
                        :key="item.developer_id"
                        v-bind:value="item.developer_id">
                        {{ item.developer_code }}                        
                      </option>
                  </select>
                  <br>
                </b-col>
              </div>

              <div class="form-row">
                <b-col md="8">
                  <base-input
                    label="Project Name"
                    name="Project Name"
                    placeholder="Project Name"
                    v-model="filter.project_name"
                  >
                  </base-input>
                </b-col>
              </div>

              <div class="form-row">
                <b-col md="4">
                  <base-input
                    label="PPA Sign Date From Date"
                    name="PPA Sign Date From Date"
                    type="date"
                    v-model="filter.project_ppa_sign_date_from_date"
                  >
                  </base-input>
                </b-col>
                <b-col md="4">
                  <base-input
                    label="PPA Sign Date To Date"
                    name="PPA Sign Date To Date"
                    type="date"
                    v-model="filter.project_ppa_sign_date_to_date"
                  >
                  </base-input>
                </b-col>
              </div>

              <div class="form-row">
                <b-col md="4">
                  <base-input
                    label="PPA Capacity >="
                    name="PPA Capacity >="
                    placeholder="0.0"
                    v-model="filter.project_ppa_capacity_greater_equal"
                  >
                  </base-input>
                </b-col>
                <b-col md="4">
                  <base-input
                    label="PPA Capacity <="
                    name="PPA Capacity <="
                    placeholder="0.0"
                    v-model="filter.project_ppa_capacity_lesser_equal"
                  >
                  </base-input>
                </b-col>
              </div>

              <div class="form-row">
                <b-col cols="8">
                  <label for="projectTypeSelect" class="form-control-label">Project Type</label>
                  <select multiple class="form-control" id="projectTypeSelect" v-model="filter.project_type" size="7">
                    <option 
                        v-for="item in project_type"
                        :key="item.project_type_id"
                        v-bind:value="item.project_type_id">
                        {{ item.project_type }}                        
                      </option>
                  </select>
                  <br>
                </b-col>
              </div>

              <div class="form-row">
                <b-col cols="8">
                  <label for="projectEngineerSelect" class="form-control-label">Project Engineer</label>
                  <select multiple class="form-control" id="projectEngineerSelect" v-model="filter.project_managers" size="7">
                    <option 
                        v-for="item in project_managers"
                        :key="item.id"
                        v-bind:value="item.id">
                        {{ item.name }}                        
                      </option>
                  </select>
                  <br>
                </b-col>
              </div>

              <div class="form-row">
                <b-col cols="8">
                  <label for="engineerSelect" class="form-control-label">Engineer</label>
                  <select multiple class="form-control" id="engineerSelect" v-model="filter.project_engineers" size="7">
                    <option 
                        v-for="item in project_engineers"
                        :key="item.id"
                        v-bind:value="item.id">
                        {{ item.name }}                        
                      </option>
                  </select>
                  <br>
                </b-col>
              </div>

              <div class="form-row">
                <b-col cols="8">
                  <label for="businessDevelopmentSelect" class="form-control-label">Business Development</label>
                  <select multiple class="form-control" id="businessDevelopmentSelect" v-model="filter.project_business_development" size="7">
                    <option 
                        v-for="item in project_business_development"
                        :key="item.id"
                        v-bind:value="item.id">
                        {{ item.name }}                        
                      </option>
                  </select>
                  <br>
                </b-col>
              </div>

              <div class="form-row">
                <b-col cols="8">
                  <label for="safetyOfficerSelect" class="form-control-label">Safety Officer</label>
                  <select multiple class="form-control" id="safetyOfficerSelect" v-model="filter.project_safety" size="7">
                    <option 
                        v-for="item in project_safety"
                        :key="item.id"
                        v-bind:value="item.id">
                        {{ item.name }}                        
                      </option>
                  </select>
                  <br>
                </b-col>
              </div>

              <div class="form-row">
                <b-col md="8">
                  <base-input
                    label="Site Name"
                    name="Site Name"
                    placeholder="Site Name"
                    v-model="filter.site_name"
                  >
                  </base-input>
                </b-col>
              </div>

              <div class="form-row">
                <b-col cols="8">
                  <label for="contractorsSelect" class="form-control-label">EPC</label>
                  <select multiple class="form-control" id="contractorsSelect" v-model="filter.project_site_contractors" size="7">
                    <option 
                        v-for="item in contractors"
                        :key="item.contractor_id"
                        v-bind:value="item.contractor_id">
                        {{ item.contractor_code }}                        
                      </option>
                  </select>
                  <!-- filter.project_site_contractors: {{ filter.project_site_contractors}} -->
                  <br>
                </b-col>
              </div>

              <div class="form-row">
                <b-col cols="8">
                  <label for="siteStatusSelect" class="form-control-label">Site Status</label>
                  <select multiple class="form-control" id="siteStatusSelect" v-model="filter.project_site_statuses" size="7">
                    <option 
                        v-for="item in site_status"
                        :key="item.status_id"
                        v-bind:value="item.status_id">
                        {{ item.status_code }}                        
                      </option>
                  </select>
                  <!-- filter.project_site_statuses: {{ filter.project_site_statuses}} -->
                  <br>
                </b-col>
              </div>

              <div class="form-row">
                <b-col md="8">
                  <base-input
                    label="Site Postal Code"
                    name="Site Postal Code"
                    placeholder="Site Postal Code"
                    v-model="filter.site_postal_code"
                  >
                  </base-input>
                </b-col>
              </div>

              <div class="form-row">
                <b-col md="8">
                  <base-input
                    label="Site Code"
                    name="Site Code"
                    placeholder="Site Code"
                    v-model="filter.site_code"
                  >
                  </base-input>
                </b-col>
              </div>

              <div class="form-row">
                <b-col md="4">
                  <base-input
                    label="Roof Handover Date From Date"
                    name="Roof Handover Date From Date"
                    type="date"
                    v-model="filter.roof_handover_date_from_date"
                  >
                  </base-input>
                </b-col>
                <b-col md="4">
                  <base-input
                    label="Roof Handover Date To Date"
                    name="Roof Handover Date To Date"
                    type="date"
                    v-model="filter.roof_handover_date_to_date"
                  >
                  </base-input>
                </b-col>
              </div>

              <div class="form-row">
                <b-col md="4">
                  <base-input
                    label="COD From Date"
                    name="COD From Date"
                    type="date"
                    v-model="filter.site_cod_date_from_date"
                  >
                  </base-input>
                </b-col>
                <b-col md="4">
                  <base-input
                    label="COD To Date"
                    name="COD To Date"
                    type="date"
                    v-model="filter.site_cod_date_to_date"
                  >
                  </base-input>
                </b-col>
              </div>

              <div class="form-row">
                <b-col md="4">
                  <base-input
                    label="Planned Turn On From Date"
                    name="Planned Turn On From Date"
                    type="date"
                    v-model="filter.site_target_turn_on_date_from_date"
                  >
                  </base-input>
                </b-col>
                <b-col md="4">
                  <base-input
                    label="Planned Turn On To Date"
                    name="Planned Turn On To Date"
                    type="date"
                    v-model="filter.site_target_turn_on_date_to_date"
                  >
                  </base-input>
                </b-col>
              </div>

              <div class="form-row">
                <b-col md="4">
                  <base-input
                    label="Actual Turn On Date From Date"
                    name="Actual Turn On Date From Date"
                    type="date"
                    v-model="filter.site_actual_turn_on_date_from_date"
                  >
                  </base-input>
                </b-col>
                <b-col md="4">
                  <base-input
                    label="Actual Turn On Date To Date"
                    name="Actual Turn On Date To Date"
                    type="date"
                    v-model="filter.site_actual_turn_on_date_to_date"
                  >
                  </base-input>
                </b-col>
              </div>

              <!-- <div class="form-row">
                <b-col md="4">
                  <base-input
                    label="As Built Capacity (kWp) >="
                    name="As Built Capacity (kWp) >="
                    placeholder="0.0"
                    v-model="filter.site_total_capacity_greater_equal"
                  >
                  </base-input>
                </b-col>
                <b-col md="4">
                  <base-input
                    label="As Built Capacity (kWp) <="
                    name="As Built Capacity (kWp) <="
                    placeholder="0.0"
                    v-model="filter.site_total_capacity_lesser_equal"
                  >
                  </base-input>
                </b-col>
              </div> -->

              <div class="form-row">
                <b-col md="4">
                  <base-input
                    label="As Built Capacity (kWp) >="
                    name="As Built Capacity (kWp) >="
                    placeholder="0.0"
                    v-model="filter.site_as_built_capacity_greater_equal"
                  >
                  </base-input>
                </b-col>
                <b-col md="4">
                  <base-input
                    label="As Built Capacity (kWp) <="
                    name="As Built Capacity (kWp) <="
                    placeholder="0.0"
                    v-model="filter.site_as_built_capacity_lesser_equal"
                  >
                  </base-input>
                </b-col>
              </div>

              <div class="form-row">
                <b-col cols="8">
                  <label for="moduleAllocationBrandSelect" class="form-control-label">Module Allocation - Brand</label>
                  <select multiple class="form-control" id="moduleAllocationBrandSelect" v-model="filter.site_module_brand_ids" size="7">
                    <option 
                        v-for="item in module_brands"
                        :key="item.module_brand_id"
                        v-bind:value="item.module_brand_id">
                        {{ item.module_brand_id_code }}                        
                      </option>
                  </select>
                  <!-- filter.site_module_brand_ids: {{ filter.site_module_brand_ids}} -->
                  <br>
                </b-col>
              </div>

              <div class="form-row">
                <b-col md="4">
                  <base-input
                    label="Module Allocation - Number of Module >="
                    name="Module Allocation - Number of Module >="
                    placeholder="0"
                    v-model="filter.site_module_quantity_greater_equal"
                  >
                  </base-input>
                </b-col>
                <b-col md="4">
                  <base-input
                    label="Module Allocation - Number of Module <="
                    name="Module Allocation - Number of Module <="
                    placeholder="0"
                    v-model="filter.site_module_quantity_lesser_equal"
                  >
                  </base-input>
                </b-col>
              </div>

              <div class="form-row">
                <b-col md="4">
                  <base-input
                    label="Module Allocation - Watt Class >="
                    name="Module Allocation - Watt Class >="
                    placeholder="0.0"
                    v-model="filter.site_module_capacity_greater_equal"
                  >
                  </base-input>
                </b-col>
                <b-col md="4">
                  <base-input
                    label="Module Allocation - Watt Class <="
                    name="Module Allocation - Watt Class <="
                    placeholder="0.0"
                    v-model="filter.site_module_capacity_lesser_equal"
                  >
                  </base-input>
                </b-col>
              </div>

              <div class="form-row">
                <b-col md="4">
                  <base-input
                    label="Module Allocation - Capacity >="
                    name="Module Allocation - Capacity >="
                    placeholder="0.0"
                    v-model="filter.site_module_allocation_total_capacity_greater_equal"
                  >
                  </base-input>
                </b-col>
                <b-col md="4">
                  <base-input
                    label="Module Allocation - Capacity <="
                    name="Module Allocation - Capacity <="
                    placeholder="0.0"
                    v-model="filter.site_module_allocation_total_capacity_lesser_equal"
                  >
                  </base-input>
                </b-col>
              </div>

              <div class="form-row">
                <b-col cols="8">
                  <label for="drawingBrandSelect" class="form-control-label">Drawing - Brand</label>
                  <select multiple class="form-control" id="drawingBrandSelect" v-model="filter.site_drawing_module_brand_ids" size="7">
                    <option 
                        v-for="item in module_brands"
                        :key="item.module_brand_id"
                        v-bind:value="item.module_brand_id">
                        {{ item.module_brand_id_code }}                        
                      </option>
                  </select>
                  <!-- filter.site_drawing_module_brand_ids: {{ filter.site_drawing_module_brand_ids}} -->
                  <br>
                </b-col>
              </div>

              <div class="form-row">
                <b-col md="4">
                  <base-input
                    label="Drawing - Number of Module >="
                    name="Drawing - Number of Module >="
                    placeholder="0"
                    v-model="filter.site_drawing_module_quantity_greater_equal"
                  >
                  </base-input>
                </b-col>
                <b-col md="4">
                  <base-input
                    label="Drawing - Number of Module <="
                    name="Drawing - Number of Module <="
                    placeholder="0"
                    v-model="filter.site_drawing_module_quantity_lesser_equal"
                  >
                  </base-input>
                </b-col>
              </div>

              <div class="form-row">
                <b-col md="4">
                  <base-input
                    label="Drawing - Watt Class >="
                    name="Drawing - Watt Class >="
                    placeholder="0.0"
                    v-model="filter.site_drawing_module_capacity_greater_equal"
                  >
                  </base-input>
                </b-col>
                <b-col md="4">
                  <base-input
                    label="Drawing - Watt Class <="
                    name="Drawing - Watt Class <="
                    placeholder="0.0"
                    v-model="filter.site_drawing_module_capacity_lesser_equal"
                  >
                  </base-input>
                </b-col>
              </div>

              <div class="form-row">
                <b-col md="4">
                  <base-input
                    label="Drawing - Capacity >="
                    name="Drawing - Capacity >="
                    placeholder="0.0"
                    v-model="filter.site_drawing_total_capacity_greater_equal"
                  >
                  </base-input>
                </b-col>
                <b-col md="4">
                  <base-input
                    label="Drawing - Capacity <="
                    name="Drawing - Capacity <="
                    placeholder="0.0"
                    v-model="filter.site_drawing_total_capacity_lesser_equal"
                  >
                  </base-input>
                </b-col>
              </div>

              <div class="form-row">
                <b-col md="4">
                  <base-input
                    label="IFA Date From Date"
                    name="IFA Date From Date"
                    type="date"
                    v-model="filter.site_drawing_ifa_date_from_date"
                  >
                  </base-input>
                </b-col>
                <b-col md="4">
                  <base-input
                    label="IFA Date To Date"
                    name="IFA Date To Date"
                    type="date"
                    v-model="filter.site_drawing_ifa_date_to_date"
                  >
                  </base-input>
                </b-col>
              </div>

              <div class="form-row">
                <b-col md="4">
                  <base-input
                    label="IFC Date From Date"
                    name="IFC Date From Date"
                    type="date"
                    v-model="filter.site_drawing_ifc_date_from_date"
                  >
                  </base-input>
                </b-col>
                <b-col md="4">
                  <base-input
                    label="IFC Date To Date"
                    name="IFC Date To Date"
                    type="date"
                    v-model="filter.site_drawing_ifc_date_to_date"
                  >
                  </base-input>
                </b-col>
              </div>

              <div class="form-row">
                <b-col md="4">
                    <base-input label="NTP Applicable"
                                name="NTP Applicable"
                                v-model="filter.ntp_applicable">
                        <select class="form-control" v-model="filter.ntp_applicable">
                            <option selected :value=null>None</option>
                            <option value="1">YES</option>
                            <option value="0">NO</option>
                        </select>
                    </base-input>
                </b-col>
              </div>

              <div class="form-row">
                <b-col md="4">
                    <base-input label="NTP Issued"
                                name="NTP Issued"
                                v-model="filter.ntp_issued">
                        <select class="form-control" v-model="filter.ntp_issued">
                            <option selected :value=null>None</option>
                            <option value="1">YES</option>
                            <option value="0">NO</option>
                        </select>
                    </base-input>
                </b-col>
              </div>

              <div class="form-row">
                <b-col md="4">
                  <base-input
                    label="NTP Issue Date From Date"
                    name="NTP Issue Date From Date"
                    type="date"
                    v-model="filter.ntp_issue_date_from_date"
                  >
                  </base-input>
                </b-col>
                <b-col md="4">
                  <base-input
                    label="NTP Issue Date To Date"
                    name="NTP Issue Date To Date"
                    type="date"
                    v-model="filter.ntp_issue_date_to_date"
                  >
                  </base-input>
                </b-col>
              </div>

              <div class="form-row">
                <b-col md="4">
                    <base-input label="Internal Project Handover Completed?"
                                name="Internal Project Handover Completed?"
                                v-model="filter.internal_project_handover_completed">
                        <select class="form-control" v-model="filter.internal_project_handover_completed">
                            <option selected :value=null>None</option>
                            <option value="1">YES</option>
                            <option value="0">NO</option>
                        </select>
                    </base-input>
                </b-col>
              </div>

              <div class="form-row">
                <b-col md="4">
                  <base-input
                    label="Internal Project Handover Date From Date"
                    name="Internal Project Handover Date From Date"
                    type="date"
                    v-model="filter.internal_project_handover_date_from_date"
                  >
                  </base-input>
                </b-col>
                <b-col md="4">
                  <base-input
                    label="Internal Project Handover Date To Date"
                    name="Internal Project Handover Date To Date"
                    type="date"
                    v-model="filter.internal_project_handover_date_to_date"
                  >
                  </base-input>
                </b-col>
              </div>

              <div class="form-row">
                <b-col md="4">
                    <base-input label="Kick Off Meeting with Client Conducted?"
                                name="Kick Off Meeting with Client Conducted?"
                                v-model="filter.kick_off_meeting_completed">
                        <select class="form-control" v-model="filter.kick_off_meeting_completed">
                            <option selected :value=null>None</option>
                            <option value="1">YES</option>
                            <option value="0">NO</option>
                        </select>
                    </base-input>
                </b-col>
              </div>

              <div class="form-row">
                <b-col md="4">
                  <base-input
                    label="Kick Off Meeting with Client Date From Date"
                    name="Kick Off Meeting with Client Date From Date"
                    type="date"
                    v-model="filter.kick_off_meeting_date_from_date"
                  >
                  </base-input>
                </b-col>
                <b-col md="4">
                  <base-input
                    label="Kick Off Meeting with Client Date To Date"
                    name="Kick Off Meeting with Client Date To Date"
                    type="date"
                    v-model="filter.kick_off_meeting_date_to_date"
                  >
                  </base-input>
                </b-col>
              </div>
              
              </card>
              <b-row class="justify-content-end">
                <b-col class="text-right" cols="auto">
                  <base-button size="sm" type="primary" @click="searchProjectSiteFilter()"
                    >Search</base-button
                  >
                </b-col>

                <b-col class="text-right" cols="auto">
                  <base-button size="sm" type="primary" @click="generateExcelProjectSiteFilter()"
                    >Generate Excel</base-button
                  >
                </b-col>
              </b-row>
            </card>
          </div>
          <div>
            <b-col cols="12" class="d-flex justify-content-center justify-content-sm-between flex-wrap">
              <el-select
                class="select-primary pagination-select"
                v-model="pagination.perPage"
                placeholder="Per page">
                <el-option
                  class="select-primary"
                  v-for="item in pagination.perPageOptions"
                  :key="item"
                  :label="item"
                  :value="item">
                </el-option>
              </el-select>
              <div>
                <base-input v-model="searchQuery"
                            prepend-icon="fas fa-search"
                            placeholder="Search...">
                </base-input>
              </div>
            </b-col>
            <b-col><br></b-col>
            <el-table :data="queriedData"
                      row-key="site_id"
                      header-row-class-name="thead-light"
                      @sort-change="sortChange"
                      @row-click="rowClicked">

              <el-table-column label="Project Name"
                              min-width="200px"
                              prop="project.project_name"
                              sortable>
                  <template v-slot="{row}">
                    <div>
                      <b-media no-body class="align-items-center">
                          <b-media-body>
                              <span class="font-weight-600 name mb-0 text-sm">{{row.project.project_name}}</span>
                          </b-media-body>
                      </b-media>
                    </div>
                  </template>
              </el-table-column>

              <el-table-column label="Site Name"
                              min-width="200px"
                              prop="site_name"
                              sortable>
                  <template v-slot="{row}">
                    <div>
                      <b-media no-body class="align-items-center">
                          <b-media-body>
                              <span class="font-weight-600 name mb-0 text-sm">{{row.site_name}}</span>
                          </b-media-body>
                      </b-media>
                    </div>
                  </template>
              </el-table-column>

              <el-table-column label="Status"
                              min-width="200px"
                              prop="status.status_code"
                              sortable>
                  <template v-slot="{row}">
                    <div>
                      <b-media no-body class="align-items-center">
                          <b-media-body>
                              <span class="font-weight-600 name mb-0 text-sm">{{row.status.status_code}}</span>
                          </b-media-body>
                      </b-media>
                    </div>
                  </template>
              </el-table-column>

              <el-table-column label="Capacity"
                              min-width="200px"
                              prop="site_total_capacity"
                              sortable>
                  <template v-slot="{row}">
                    <div>
                      <b-media no-body class="align-items-center">
                          <b-media-body>
                              <span class="font-weight-600 name mb-0 text-sm">{{row.site_total_capacity}}</span>
                          </b-media-body>
                      </b-media>
                    </div>
                  </template>
              </el-table-column>

















             <!-- <el-table-column label="Site Name"
                             min-width="200px"
                             prop="site_code"
                             sortable>
                <template v-slot="{row}">
                  <div>
                     <b-media no-body class="align-items-center">
                        <b-media-body>
                            <span class="font-weight-600 name mb-0 text-sm">{{row.site_name}}</span>
                        </b-media-body>
                    </b-media>
                  </div>

                </template>
            </el-table-column> -->

              <!-- <el-table-column label="Project"
                             min-width="140px"
                             prop="project.project_code"
                             sortable>
                <template v-slot="{row}">
                    <b-media no-body class="align-items-center">
                        <b-media-body>
                            <span class="font-weight-600 name mb-0 text-sm">{{row.project.project_name}}</span>
                        </b-media-body>
                    </b-media>
                </template>
              </el-table-column> -->

              <!-- <el-table-column label="Group"
                             min-width="140px"
                             prop="group.group_code"
                             sortable>
                <template v-slot="{row}">
                    <b-media no-body class="align-items-center">
                        <b-media-body>
                            <span class="font-weight-600 name mb-0 text-sm">{{row.group.group_name}}</span>
                        </b-media-body>
                    </b-media>
                </template>
              </el-table-column> -->

              <!-- <el-table-column label="Information"
                             min-width="180px"
                             prop="site_leader"
                             sortable>
                <template v-slot="{row}">
                    <div>
                      <b-media no-body class="align-items-center">
                        <b-media-body>
                            <span class="font-weight-600 name mb-0 text-sm">P.Manager: {{row.group.group_engineer.name}}</span>
                        </b-media-body>
                      </b-media>
                    </div>
                    <div>
                      <b-media no-body class="align-items-center">
                        <b-media-body>
                            <span class="font-weight-600 name mb-0 text-sm">Postal.Code: {{row.site_postal_code}}</span>
                        </b-media-body>
                      </b-media>
                    </div>
                    <div>
                      <b-media no-body class="align-items-center">
                        <b-media-body>
                            <span class="font-weight-600 name mb-0 text-sm">Capacity: {{row.site_total_capacity }} KWp</span>
                        </b-media-body>
                      </b-media>
                    </div>
                </template>
              </el-table-column> -->

              <el-table-column label="Progress"
                           min-width="210px"
                             prop="site_progress"
                             sortable>
                <template v-slot="{row}">
                    <div class="d-flex align-items-center">
                        <div>
                          <span class="font-weight-600 name mb-0 text-sm">{{row.site_progress}}% &nbsp; </span>
                        </div>
                        <div>
                            <base-progress v-if="row.site_progress > 0 && row.site_progress < 50" min-width="30px" type="danger" :value="row.site_progress"/>
                            <base-progress v-else-if="row.site_progress >= 50 && row.site_progress <= 90" min-width="30px" type="info" :value="row.site_progress"/>
                            <base-progress v-else min-width="30px" type="success" :value="row.site_progress"/>
                        </div>
                    </div>
                    <b-media no-body class="align-items-center">
                        <b-media-body>
                            <badge class="badge-dot mr-4" type="">
                              <i v-if="row.status.status_code == 'Delayed'" class="bg-danger"></i>
                              <i v-else-if="row.status.status_code == 'On-Hold'" class="bg-danger"></i>
                              <i v-else-if="row.status.status_code == 'Reviewed'" class="bg-success"></i>
                              <i v-else-if="row.status.status_code == 'Completed'" class="bg-success"></i>
                              <i v-else class="bg-info"></i>
                              <span class="font-weight-600 name mb-0 text-sm">{{row.status.status_code}}</span>
                            </badge>
                        </b-media-body>
                    </b-media>
                    <div>
                      Est Turn-On Date: <b>{{ row.est_turn_on_date }}</b>
                    </div>
                </template>
              </el-table-column>

              <!-- <el-table-column min-width="140px" align="right" label="Actions">
                <div slot-scope="{$index, row}" class="d-flex">
                  <base-button v-if="button_access.view_button == 'YES'"
                    @click.native="handleDetails($index, row)" size="sm" type="primary">
                    Dashboard
                  </base-button>
                </div>
              </el-table-column> -->
            </el-table>
          </div>
          <div
            slot="footer"
            class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap" >
            <div class="">
              <p class="card-category">
                Showing {{ from + 1 }} to {{ to }} of {{ total }} entries
                <span v-if="selectedRows.length">
                  &nbsp; &nbsp; {{selectedRows.length}} rows selected
                </span>
              </p>
            </div>
            <base-pagination
              class="pagination-no-border"
              :current="pagination.currentPage"
              :per-page="pagination.perPage"
              :total="total"
              @change="paginationChanged($event)">
            </base-pagination>
             <!-- <div>
              <h5 class="text-danger"> Note :
                  <i>
                  Please Create at least ONE Group Template & ONE Group Template Details before you can proceed POPULATE / IMPORT to prevent any Error. Go to NEW Template Then "Detail Listing" >> "New Template Details".
                  </i>
              </h5>
            </div> -->
          </div>
        </card>
      </div>
    </b-container>
    </div>
</template>
<script>
import { Table, TableColumn, Select, Option } from 'element-ui';
import RouteBreadCrumb from '@/components/Breadcrumb/RouteBreadcrumb'
import { BasePagination } from '@/components';
import standardPaginationMix from '@/assets/js/standardPaginationMix.js'
import Fuse from 'fuse.js';
import axios from 'axios'
import {LoginURL, clientId, clientSecret, apiDomain, getHeader} from '@/assets/js/config.js'
import Card from '../../../components/Cards/Card.vue';

export default {
  mixins: [standardPaginationMix],
  components: {
    BasePagination,
    RouteBreadCrumb,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn
  },
  created()
  {
      var project_site_access = JSON.parse(window.localStorage.getItem('project_site_access'))
      this.button_access.new_button = project_site_access.access_create
      this.button_access.view_button = project_site_access.access_show
      // this.getStartUpData()

      this.getDeveloperList();
      this.getProjectTypeListing();
      this.getProjectManagerListing();
      this.getProjectEngineerListing();
      this.getBusinessDevelopmentListing();
      this.getSafetyOfficerListing();
      this.getContractorList();
      this.getProjectSiteStatusListing();
      this.getModuleBrandListing();
  },
  data() {
    return {
      propsToSearch:
      [
          // // 'site_code',
          // 'site_name',
          // 'project.project_code',
          // 'site_total_capacity',
          // // 'group.group_code',
          // // 'site_leader.name',
          // // 'site_progress',
          // 'status.status_code',
          'project.project_name',
          'site_name',
          'status.status_code',
          'site_total_capacity',
      ],
      tableColumns: [],
      tableData: [],
      selectedRows: [],
      button_access: {
        new_button: null,
        view_button: null,
      },
      filter: {
        developers: [],
        project_name: null,
        project_ppa_sign_date_from_date: null,
        project_ppa_sign_date_to_date: null,
        project_ppa_capacity_greater_equal: null,
        project_ppa_capacity_lesser_equal: null,

        project_type: [],
        project_managers: [],
        project_engineers: [],
        project_business_development: [],
        project_safety: [],

        site_name: null,
        project_site_contractors: [],
        project_site_statuses: [],
        site_postal_code: null,
        site_code: null,
        roof_handover_date_from_date: null,
        roof_handover_date_to_date: null,
        site_cod_date_from_date: null,
        site_cod_date_to_date: null,
        site_target_turn_on_date_from_date: null,
        site_target_turn_on_date_to_date: null,
        site_actual_turn_on_date_from_date: null,
        site_actual_turn_on_date_to_date: null,
        site_total_capacity_greater_equal: null,
        site_total_capacity_lesser_equal: null,
        site_as_built_capacity_greater_equal: null,
        site_as_built_capacity_lesser_equal: null,
        site_module_brand_ids: [],
        site_module_quantity_greater_equal: null,
        site_module_quantity_lesser_equal: null,
        site_module_capacity_greater_equal: null,
        site_module_capacity_lesser_equal: null,
        site_module_allocation_total_capacity_greater_equal: null,
        site_module_allocation_total_capacity_lesser_equal: null,
        site_drawing_module_brand_ids: [],
        site_drawing_module_quantity_greater_equal: null,
        site_drawing_module_quantity_lesser_equal: null,
        site_drawing_module_capacity_greater_equal: null,
        site_drawing_module_capacity_lesser_equal: null,
        site_drawing_total_capacity_greater_equal: null,
        site_drawing_total_capacity_lesser_equal: null,
        site_drawing_ifa_date_from_date: null,
        site_drawing_ifa_date_to_date: null,
        site_drawing_ifc_date_from_date: null,
        site_drawing_ifc_date_to_date: null,
        ntp_applicable: null,
        ntp_issued: null,
        ntp_issue_date_from_date: null,
        ntp_issue_date_to_date: null,
        internal_project_handover_completed: null,
        internal_project_handover_date_from_date: null,
        internal_project_handover_date_to_date: null,
        kick_off_meeting_completed: null,
        kick_off_meeting_date_from_date: null,
        kick_off_meeting_date_to_date: null,
      },
      developers: [],
      project_type: [],
      project_managers: [],
      project_engineers: [],
      project_business_development: [],
      project_safety: [],
      contractors: [],
      site_status: [],
      module_brands: [],
      showFilters: false,
    };
  },
  methods: {
    toggleShowFilters() {
      this.showFilters = !this.showFilters;
    },
    searchProjectSiteFilter() {
      this.tempButtonStatus = true;
         axios.post(apiDomain + "project/project_site.show_by_filter", this.filter, {
          headers: getHeader(),
        })
        .then ( response => {
        if (response.status === 200)
        {
          this.tableData = response.data.data;
          this.fuseData();
        }
        })
        .catch ( error => {
          this.errorFunction(error, "Search Project Site Listing")
        });
    },
    
    generateExcelProjectSiteFilter() {
      // axios.get(apiDomain + 'project/project_site.generate_excel_by_filter', { headers: getHeader(), responseType: 'blob' })
      //   .then ( response => {
      //     if (response.status === 200) {
      //       // this.generate_report_response = response.data;
            
      //       // create a temporary URL to download the blob response
      //       const url = window.URL.createObjectURL(new Blob([response.data]));
            
      //       // create a link element and click it to download the file
      //       const link = document.createElement('a');
      //       link.href = url;
      //       link.setAttribute('download', 'project_sites_filtered.xlsx');
      //       document.body.appendChild(link);
      //       link.click();
      //     }
      //   })
      //   .catch ( error => {
      //     this.errorFunction(error, "Generate Excel Project Site")
      //   });

      axios.post(apiDomain + 'project/project_site.generate_excel_by_filter', this.filter, { headers: getHeader(), responseType: 'blob' })
        .then ( response => {
          if (response.status === 200) {
            // this.generate_report_response = response.data;
            
            // create a temporary URL to download the blob response
            const url = window.URL.createObjectURL(new Blob([response.data]));
            
            // create a link element and click it to download the file
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'project_sites_filtered.xlsx');
            document.body.appendChild(link);
            link.click();
          }

        })
        .catch ( error => {
          if (error.response.status === 404) {
            this.redErrorNotify( '<b>No data found for this filter.</b>' )
          } else {
            this.errorFunction(error, "Generate Excel Report Filtered")
          }          
        });
    },

    getDeveloperList() {
      axios.get(apiDomain + 'setup/developer', { headers: getHeader() })
      .then ( response => {
        if (response.status === 200)
        {
          this.developers = response.data.data
        }
      })
      .catch ( error => {
        console.log(error.response.status)
        this.errorFunction(error, "Developer Listing")
      } );
    },

    getProjectTypeListing() {
      axios
      .get(apiDomain + "setup/project_type", { headers: getHeader() })
      .then((response) => {
        if (response.status === 200) {
          this.project_type = response.data.data;
        }
      })
      .catch((error) => {
        console.log(error.response.status);
        this.errorFunction(error, "Project Type Information");
      });
    },
    getProjectManagerListing() {
        axios.get(apiDomain + 'lookup/user_list/Project_Manager', { headers: getHeader() })
        .then ( response => {
            if (response.status === 200)
            {
            this.project_managers = response.data.data
            }
        })
        .catch ( error => {
            console.log(error.response.status)
            this.errorFunction(error, "Project Manager")
        } );      
    },
    getProjectEngineerListing() {
        axios.get(apiDomain + 'lookup/user_list/Engineer', { headers: getHeader() })
        .then ( response => {
            if (response.status === 200)
            {
            this.project_engineers = response.data.data
            }
        })
        .catch ( error => {
            console.log(error.response.status)
            this.errorFunction(error, "Engineer Information")
        } );
    },
    getBusinessDevelopmentListing() {
      axios.get(apiDomain + 'lookup/user_list/Business_Development', { headers: getHeader() })
        .then ( response => {
            if (response.status === 200)
            {
            this.project_business_development = response.data.data
            }
        })
        .catch ( error => {
            console.log(error.response.status)
            this.errorFunction(error, "Safety Officer Information")
        } );
    },
    getSafetyOfficerListing() {
        axios.get(apiDomain + 'lookup/user_list/Safety', { headers: getHeader() })
        .then ( response => {
            if (response.status === 200)
            {
            this.project_safety = response.data.data
            }
        })
        .catch ( error => {
            console.log(error.response.status)
            this.errorFunction(error, "Safety Officer Information")
        } );
    },

    getContractorList() {
      axios.get(apiDomain + 'setup/contractor', { headers: getHeader() })
      .then ( response => {
        if (response.status === 200)
        {
          this.contractors = response.data.data
        }
      })
      .catch ( error => {
        console.log(error.response.status)
        this.errorFunction(error, "Contractor Listing")
      } );
    },

    getProjectSiteStatusListing() {
      axios.get(apiDomain + 'lookup/site_status.all', { headers: getHeader() })
      .then ( response => {
        if (response.status === 200)
        {
          this.site_status = response.data.data
        }
      })
      .catch ( error => {
        console.log(error.response.status)
        this.errorFunction(error, "Site Status Information")
      } );
    },

    getModuleBrandListing(){
        axios.get(apiDomain + "setup/module_brand", { headers: getHeader() })
            .then((response) => {
                if (response.status === 200) {
                    this.module_brands = response.data.data;
                }
            })
            .catch((error) => {
                this.errorFunction(error, "Module Brand Information");
            });
    },

    getStartUpData()
    {
        axios.get(apiDomain + 'project/project_site', { headers: getHeader() })
        .then ( response => {
        if (response.status === 200)
        {
            this.tableData = response.data.data
            this.fuseData()
        }
        })
        .catch ( error => {
            console.log(error.response.status)
        } );
    },
    fuseData()
    {
        this.fuseSearch = new Fuse(this.tableData, {
        keys: this.propsToSearch,
        threshold: 0.3
        });
    },
    paginationChanged(page)
    {
        this.pagination.currentPage = page
    },
    handleDetails(index, row)
    {
        this.$router.push({path: 'projectSiteDashboardV2/'+row.site_id })
    },
    selectionChange(selectedRows)
    {
        this.selectedRows = selectedRows
    },
    rowClicked(row) {
      if(row.project.project_type_id != 17){
          window.open('/projectSiteDashboardV2/' + row.site_id, '_blank');
      }else{
          window.open('/projectSiteDashboardUtilities/' + row.site_id, '_blank');
      }
    },
  }
};
</script>
<style>
  .no-border-card .card-footer{
    border-top: 0;
  }
</style>
