<template>
  <div class = "edit_site">
    <div class="bg-default-dashboard" >
      <div class=" py-lg-8 pt-lg-5">
        <b-container fluid style="width: 90%; position: relative;">
          <!-- <div class="header-body "  > -->
          <b-row class="mb-1" >
            <b-col md="9" >
              <card class="bg-card-dashboard" >
                <b-row class="mb-6">
                  <b-col md="8">
                    <span class="text-white display-2 mr-3 " >{{projects_model.project_name}}</span>
                    <a class="btn btn-sm btn-warning rounded-pill text-default " style="position: relative; top: -10px;" > <i class="fa fa-xs fa-circle mr-1"></i>In Progress</a>
                  </b-col>
                  <b-col md="4" class="text-right" >
                    <span class="text-white" style="position: relative; top: 20px;"> PPA Sign Date : <strong>{{ projects_model.project_ppa_start_date }}</strong></span>
                  </b-col>
                </b-row>
                <b-row class="mb-6">
                  <b-col md="4">
                    <div class="text-left">
                      <span>
                        <img src="/img/theme/general/capacity.png" class="mr-2" alt="" >
                        <span class="text-white lead text-muted">Total Capacity</span>
                      </span>
                      <br>
                      <span class="text-white display-4 ml-3 " >{{projects_model.project_ppa_approved_capacity}}</span>
                    </div>
                  </b-col>
                  <b-col md="4">
                    <div class="text-center">
                      <span>
                        <img src="/img/theme/general/developer.png" class="mr-2" alt="" >
                        <span class="text-white lead text-muted">Developer</span>
                      </span>
                      <br>
                      <span class="text-white display-4 ml-3 " >{{projects_model.project_developer_name}}</span>
                    </div>
                  </b-col>
                  <b-col md="4">
                    <div class="text-right">
                      <span>
                        <img src="/img/theme/general/watt.png" class="mr-2" alt="" >
                        <span class="text-white lead text-muted">Watt Class</span>
                      </span>
                      <br>
                      <span class="text-white display-4 mr-2 " >{{ this.projects_model.project_watt_class }}</span>
                    </div>
                  </b-col>
                </b-row>
              </card>
            </b-col>
            <b-col>
              <card class="bg-card-dashboard">
                <b-row class="mb-2">
                  <b-col class="text-white">
                    <div class=" display-4 pb-3">Key Contact</div>
                    <div class="pb-2">
                      <span class="text-muted">Project Engineer</span> <br>
                      <span class="lead"><strong>{{ contacts_model.project_manager }} </strong></span>
                    </div>
                    <div class="pb-2">
                      <span class="text-muted">Engineer</span> <br>
                      <span class="lead"><strong>{{contacts_model.project_engineer}}</strong></span>
                    </div>
                    <div class="pb-2">
                      <span class="text-muted">Bussiness Development</span> <br>
                      <span class="lead"><strong>{{contacts_model.project_business_development}}</strong></span>
                    </div>
                    <div class="pb-0">
                      <span class="text-muted">Safety Officer</span> <br>
                      <span class="lead"><strong>{{contacts_model.project_safety}}</strong></span>
                    </div>
                  </b-col>

                </b-row>
              </card>
            </b-col>
          </b-row>
          <b-row class="mb-3">
            <b-col>
              <div class="">
                <span class="text-green h2">Site Profile</span>
              </div>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <card class="bg-card-dashboard">
                <b-row>
                  <b-col>
                    <div class="text-muted"> Site Name</div>
                    <div class="text-white h3">{{ model.site_name }}</div>
                  </b-col>
                  <b-col>
                    <div class="text-muted"> Address</div>
                    <div class="text-white h3">{{ model.site_address }}</div>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <div class="text-muted"> Postalcode</div>
                    <div class="text-white h3">{{ model.site_postal_code }}</div>
                  </b-col>
                  <b-col>
                    <div class="text-muted mb-2">Site Status</div>
                    <base-input name="Site Status" v-model="model.status_id">
                      <select class="form-control" v-model="model.status_id">
                        <option Selected disabled value="x">-- Please Select Site Status --</option>
                        <option v-for="item in site_status" :key="item.status_id" v-bind:value="item.status_id" :disabled="item.disabled">{{ item.status_code }}</option>
                      </select>
                    </base-input>
                  </b-col>
                </b-row>
                <b-row class="mb-4">
                  <b-col>
                    <div class="text-muted mb-2">Latitude</div>
                    <b-form-input  placeholder="..." name="Latitude" v-model="model.site_latitude"></b-form-input>
                  </b-col>
                  <b-col>
                    <div class="text-muted mb-2">Longtidue</div>
                    <b-form-input  placeholder="..." name="Longitude" v-model="model.site_longitude"></b-form-input>
                  </b-col>
                </b-row>
                <b-row class="mb-1">
                  <b-col>
                    <div class="text-muted mb-2">EPC</div>
                    <base-input name="EPC" v-model="model.contractor_id">
                      <select class="form-control" v-model="model.contractor_id">
                        <option Selected disabled value="x">-- Please Select A Contractor --</option>
                        <option v-for="item in contractors" :key="item.contractor_id" v-bind:value="item.contractor_id">{{ item.contractor_code }}</option>
                      </select>
                    </base-input>
                  </b-col>
                  <b-col>
                    <div class="text-muted mb-2">Site Code</div>
                    <b-form-input  placeholder="..." v-model="model.site_code"></b-form-input>
                  </b-col>
                </b-row>
                <b-row class="mb-1">
                  <b-col>
                    <div class="text-muted mb-2">Planned turn on</div>
                    <base-input name="Target Turn On Date" type="date" v-model="model.site_target_turn_on_date"></base-input>
                  </b-col>
                  <b-col>
                    <div class="text-muted mb-2">Actual turn on date</div>
                    <base-input name="Actual Turn On Date" type="date" v-model="model.site_actual_turn_on_date"></base-input>
                  </b-col>
                </b-row>
                <b-row class="mb-1">
                  <b-col>
                    <div class="text-muted mb-2">COD</div>
                    <base-input name="COD" type="date" v-model="model.site_cod_date"></base-input>
                  </b-col>
                  <b-col>
                    <div class="text-muted mb-2"></div>

                  </b-col>
                </b-row>
                <b-row class="mb-1">
                  <b-col>
                    <div class="text-muted mb-2">Site PPA total capacity</div>
                    <b-form-input  placeholder="..." name="Site PPA Total Capacity" v-model="model.site_total_capacity"></b-form-input>
                  </b-col>
                  <b-col>
                    <div class="text-muted mb-2">As built capacity (kWp)</div>
                    <b-form-input  placeholder="..." name="As Built Capacity (kWp)" v-model="model.site_as_built_capacity"></b-form-input>
                  </b-col>
                </b-row>
                <b-row class="mb-1">
                  <b-col class="text-right mt-3">
                    <base-button type="primary" native-type="submit" @click="saveSiteData(); setActiveSiteContractor();">Save</base-button>
                  </b-col>
                </b-row>
              </card>
            </b-col>
          </b-row>
          <b-row class="mb-3">
            <b-col>
              <div class="">
                <span class="text-green h2">Upload Project Task Update</span>
              </div>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <card class="bg-card-dashboard">
                <div class="content-dash">
                  <div class="text-center text-white mt-3 mb-2" >
                    <img src="img/theme/general/upload.png" alt="">
                    <div class="mt-2">Upload Project Timeline Update</div>
                    <div class="mt-1"><small>Files Supported: XSLS</small></div>
                    <div class="mt-2">
                      <base-button type="primary" style="width: 150px;" native-type="submit" @click="triggerFileInput">Select File</base-button>
                      <input
                              type="file"
                              ref="file1"
                              style="display:none"
                              @change="onChangeProjectSiteTask"
                      />
                      <!--  <base-button style="background-color:#7A7A7A99 ; color: #BEC0C9; width: 150px; border-color: transparent;" native-type="submit" @click="uploadProjectSiteTask">Save</base-button> -->
                      <base-button style="background-color:#7A7A7A99 ; color: #BEC0C9; width: 150px; border-color: transparent;" native-type="submit" @click="uploadProjectSiteTask" v-if="projectTaskTempData">Save</base-button>
                      <base-button style="background-color:#7A7A7A99 ; color: #BEC0C9; width: 150px; border-color: transparent;" native-type="submit" @click="uploadProjectSiteTask" v-else disabled>Save</base-button>
                    </div>
                  </div>
                  <b-row class="justify-content-md-center" v-if="!project_task_file">
                    <b-col md="4">
                      <div class="text-white text-center">
                        No file selected
                      </div>
                    </b-col>
                  </b-row>
                  <b-row class="justify-content-md-center" v-else>
                    <b-col md="4">
                      <base-input name="Sheet To Import" v-model="selected_project_task_sheet" label="Sheet To Import">
                        <select class="form-control" v-model="selected_project_task_sheet" @click="loadProjectTaskSheetNames" @change="updateProjectTaskRangeToSelect(); showUploadingProjectTaskData();">
                          <option Selected disabled value="x">-- Please Select A Sheet To Import --</option>
                          <option v-for="sheet1 in projectTaskSheets" :key="sheet1.sheetId" v-bind:value="sheet1.name">{{ sheet1.name }}</option>
                        </select>
                      </base-input>
                    </b-col>
                  </b-row>
                  <div>
                    <xlsx-read ref="excelRead1" :file="project_task_file">
                      <xlsx-json ref="excelJson1" :sheet="selected_project_task_sheet" :options="{ range: selected_project_task_range, blankrows: false }">
                      </xlsx-json>
                    </xlsx-read>
                  </div>
                </div>
              </card>
            </b-col>
          </b-row>
        </b-container>

      </div>


    </div>
  </div>
</template>
<script>
    //import { Select, Option } from 'element-ui'
    import {
        LoginURL,
        clientId,
        clientSecret,
        apiDomain,
        getHeader,
        getcurrentDate,
    } from "@/assets/js/config.js";
    import axios from "axios";
    import ErrorFunctionMixin from "@/assets/js/errorFunctionMix.js";
    import jsonFormData from 'json-form-data';
    import VueApexCharts from "vue-apexcharts";
    import moment from "moment";
    import XlsxRead from "vue-xlsx/dist/components/XlsxRead";
    import XlsxTable from "vue-xlsx/dist/components/XlsxTable";
    import XlsxJson from "vue-xlsx/dist/components/XlsxJson";
    export default {
        name: 'EditSite',
        components: {
            apexchart: VueApexCharts,
            XlsxRead,
            XlsxTable,
            XlsxJson,
        },
        created() {
            this.currentDate = getcurrentDate();

            const LoginUser = JSON.parse(window.localStorage.getItem("loginUser"));
            this.myUserID = LoginUser;

            this.getProjectSiteStatusListing();
            this.getContractorListing();
            this.getModuleBrandListing();
            this.getMilestoneTemplateListing();
            this.getDashboardData();
            this.getDashboardAdditionalInfo();
            this.getProjectSiteMilestoneData();
            this.getContractorFromSite();
            this.getSiteAttachmentListing();
        },
        data() {
            return {
                model: {
                    site_id: null,
                    project_id: null,
                    group_id: null,
                    site_name: null,
                    status_id: 'x',
                    site_address: null,
                    site_postal_code: null,
                    contractor_id: 'x',
                    site_code: null,
                    site_latitude: null,
                    site_longitude: null,
                    roof_handover_date: null, // CHECK WITH ANDREW
                    site_cod_date: null,
                    site_target_turn_on_date: null,
                    site_actual_turn_on_date: null,
                    site_total_capacity: null, // CHECK WITH ANDREW
                    site_as_built_capacity: null,
                    site_drawing_module_brand_id: 'x',
                    site_drawing_module_quantity: 0,
                    site_drawing_module_capacity: 0,
                    site_drawing_total_capacity: 0,
                    site_drawing_ifa_date: null,
                    site_drawing_ifc_date: null,
                    site_module_brand_id: 'x',
                    site_module_quantity: 0,
                    site_module_capacity: 0,
                    site_module_allocation_total_capacity: 0,
                    site_ifc_ifa_module_remarks: null,
                    // site_name: null,
                    // status_code: null,
                    // site_leader: null,
                    // site_total_installation: 0,
                    // site_total_task: 0,
                    // site_completed_task: 0,
                    // site_total_document: 0,
                    // site_completed_document: 0,
                    // site_progress: 0,
                    // site_total_capacity: 0,
                    // site_completed_capacity: 0,
                    // group_engineer: {
                    // name: null,
                    // email: null,
                    // rows: [
                    //     { milestone: "Applicaton to Authorities", startDate: "", endDate: "", remarks: "" },
                    //     { milestone: "Mobilization to Site", startDate: "", endDate: "", remarks: "" },
                    //     { milestone: "Hoisting", startDate: "", endDate: "", remarks: "" },
                    //     // MAKE DYNAMIC BASED ON API CALL
                    // ],
                    milestone_template_id: 'x',
                },
                site_status: [],
                contractors: [],
                module_brands: [],
                milestone_template: [],
                site_attachment: {
                    file: null,
                },
                cif_file: null,
                project_task_file: null,
                tempData: null,
                projectTaskTempData: null,
                converted_temp_data: null,
                converted_temp_project_task_data: null,
                sheets: [
                    // {
                    //     name: "Stage_1 Technical Info (ENG)",
                    // },
                    // {
                    //     name: "Stage_1 Project Info (BD)",
                    // },
                ],
                projectTaskSheets:[],
                selected_project_task_sheet:'x',
                selected_project_task_range:'',
                selected_sheet: 'x',
                selected_range: '',
                postResponse: null,
                site_attachments: [],
                additional_model: {
                    project_site_additional_info_id: null,
                    site_id: null,
                    ntp_applicable: 0,
                    ntp_issued: 0,
                    ntp_issue_date: null,
                    internal_project_handover_completed: 0,
                    internal_project_handover_date: null,
                    kick_off_meeting_completed: 0,
                    kick_off_meeting_date: null,
                    roof_handover_completed: 0,
                    roof_handover_date: null,
                    cops_tnc_checked: 0,
                    cops_tnc_issue: 0,
                    cops_tnc_remarks: null,
                    onm_handover_checked: 0,
                    onm_handover_issue: 0,
                    onm_handover_remarks: null,
                    turn_on_checking_approved: 0,
                    turn_on_checking_approval_date: null,
                    turn_on_checking_approver: null,
                    cif_technical_info: null,
                    cif_project_info: null,
                },
                site_milestones: [],
                ms_upload_task: [],
                series: [
                    {
                        name: 'Planned',
                        data: [
                            {
                                x: 'Application to Authorities',
                                y: [
                                    new Date('2019-03-05').getTime(),
                                    new Date('2019-03-08').getTime()
                                ]
                            },
                            {
                                x: 'Mobilization to Site',
                                y: [
                                    new Date('2019-03-08').getTime(),
                                    new Date('2019-03-11').getTime()
                                ]
                            },
                            {
                                x: 'Hoisting',
                                y: [
                                    new Date('2019-03-11').getTime(),
                                    new Date('2019-03-16').getTime()
                                ]
                            }
                        ]
                    },
                    {
                        name: 'Actual',
                        data: [
                            {
                                x: 'Application to Authorities',
                                y: [
                                    new Date('2019-03-02').getTime(),
                                    new Date('2019-03-05').getTime()
                                ]
                            },
                            {
                                x: 'Mobilization to Site',
                                y: [
                                    new Date('2019-03-06').getTime(),
                                    new Date('2019-03-09').getTime()
                                ]
                            },
                            {
                                x: 'Hoisting',
                                y: [
                                    new Date('2019-03-10').getTime(),
                                    new Date('2019-03-19').getTime()
                                ]
                            }
                        ]
                    }
                ],
                site_milestones: [],
                chartOptions: {
                    chart: {
                        height: 350,
                        type: 'rangeBar',
                        background: "#DDEED2",
                    },
                    // colors: ["#2E75B5", "#00B050"], // #FF0000
                    colors: [
                        "#2E75B5",
                        ({ value, dataPointIndex }) => {
                            if (this.site_milestones.length > 0 ) {
                                if (this.site_milestones[dataPointIndex].planned_end_date) {
                                    const plannedEndDate = new Date(this.site_milestones[dataPointIndex].planned_end_date).getTime();
                                    console.log(plannedEndDate);
                                    if (value > plannedEndDate) {
                                        return '#FF0000'
                                    } else {
                                        return '#00B050'
                                    }
                                } else {
                                    return '#00B050'
                                }
                            } else {
                                return '#00B050'
                            }
                        }
                    ],
                    plotOptions: {
                        bar: {
                            horizontal: true,
                        }
                    },
                    dataLabels: {
                        //   enabled: true,
                        //   formatter: function(val) {
                        //     var a = moment(val[0])
                        //     var b = moment(val[1])
                        //     var diff = b.diff(a, 'days')
                        //     return diff + (diff > 1 ? ' days' : ' day')
                        //   }
                    },
                    fill: {
                        //   type: 'gradient',
                        //   gradient: {
                        //     shade: 'light',
                        //     type: 'vertical',
                        //     shadeIntensity: 0.25,
                        //     gradientToColors: undefined,
                        //     inverseColors: true,
                        //     opacityFrom: 1,
                        //     opacityTo: 1,
                        //     stops: [50, 0, 100, 100]
                        //   }
                    },
                    xaxis: {
                        type: 'datetime',
                        // labels: {
                        //     format: 'MMM yyyy', // Format for month labels
                        // },
                        // min: new Date('2023-01-01').getTime(),
                        // max: new Date('2023-12-31').getTime(),
                    },
                    legend: {
                        position: 'top',
                        markers: {
                            fillColors: ["#2E75B5", "#00B050"],
                        },
                    }
                },
                isSiteMilestoneVisible: false,
                selectedDataPointIndex: 0,
                actual_total_day : 0,
                contacts_model: {
                    project_manager: null,
                    project_engineer: null,
                    project_business_development: null,
                    project_safety: null,
                },
                projects_model: {
                    project_ppa_start_date: null,
                    project_ppa_approved_capacity: null,
                    project_name : 'x',
                    project_developer_id : 0,
                    project_developer_name : 'x',
                    project_watt_class:0,
                },
            };
        },
        filters: {
            formatDate(value) {
                if (value) {
                    const dateParts = value.split("-");
                    const formattedDate = `${dateParts[2]}/${dateParts[1]}/${dateParts[0]}`;
                    return formattedDate;
                }
                return "N/A"; // Return an empty string if the date is not available or invalid
            },
        },
        watch: {
            'additional_model.ntp_applicable': function (newVal) {
                if (newVal === "0") {
                    this.additional_model.ntp_issued = "0";
                    this.additional_model.ntp_issue_date = null;
                }
            },
            'additional_model.ntp_issued': function (newVal) {
                if (newVal === "0") {
                    this.additional_model.ntp_issue_date = null;
                } else if (newVal === "1") {
                    this.additional_model.ntp_issue_date = this.currentDate;
                }
            },
            'additional_model.internal_project_handover_completed': function (newVal) {
                if (newVal === "0") {
                    this.additional_model.internal_project_handover_date = null;
                } else if (newVal === "1") {
                    this.additional_model.internal_project_handover_date = this.currentDate;
                }
            },
            'additional_model.kick_off_meeting_completed': function (newVal) {
                if (newVal === "0") {
                    this.additional_model.kick_off_meeting_date = null;
                } else if (newVal === "1") {
                    this.additional_model.kick_off_meeting_date = this.currentDate;
                }
            },
            'additional_model.roof_handover_completed': function (newVal) {
                if (newVal === "0") {
                    this.additional_model.roof_handover_date = null;
                } else if (newVal === "1") {
                    this.additional_model.roof_handover_date = this.currentDate;
                }
            },
            'additional_model.cops_tnc_checked': function (newVal) {
                if (newVal === "0") {
                    this.additional_model.cops_tnc_issue = "0";
                    this.additional_model.cops_tnc_remarks = null;
                }
            },
            'additional_model.onm_handover_checked': function (newVal) {
                if (newVal === "0") {
                    this.additional_model.onm_handover_issue = "0";
                    this.additional_model.onm_handover_remarks = null;
                }
            },
        },
        computed:{

        },
        methods: {
            triggerFileInput() {
                // Trigger a click on the file input element
                this.$refs.file1.click();
            },
            redirectToProjectDashboard(){
                this.$router.push({path: '/projectDashboardV2/' + this.model.project_id})
            },
            scrollToSite() {
                const siteSection = document.getElementById("site-profile");
                siteSection.scrollIntoView({ behavior: "smooth" });
            },
            scrollToIfaIfcModule() {
                const ifaIfacModuleSection = document.getElementById("ifc-ifa-module");
                ifaIfacModuleSection.scrollIntoView({ behavior: "smooth" });
            },
            scrollToProjectPlanning() {
                const projectPlanningSection = document.getElementById("project-planning");
                projectPlanningSection.scrollIntoView({ behavior: "smooth" });
            },
            scrollToGanttChart() {
                const ganttChartSection = document.getElementById("gantt-chart");
                ganttChartSection.scrollIntoView({ behavior: "smooth" });
            },
            scrollToTurnOnPlanning() {
                const turnOnPlanningSection = document.getElementById("turn-on-checking");
                turnOnPlanningSection.scrollIntoView({ behavior: "smooth" });
            },
            scrollToUploadCif() {
                const uploadCifSection = document.getElementById("upload-cif");
                uploadCifSection.scrollIntoView({ behavior: "smooth" });
            },
            scrollToDisplayCif() {
                const displayCifSection = document.getElementById("display-cif");
                displayCifSection.scrollIntoView({ behavior: "smooth" });
            },
            getProjectSiteStatusListing() {
                // axios.get(apiDomain + 'lookup/site_status', { headers: getHeader() })
                axios.get(apiDomain + 'lookup/site_status.singapore_v2', { headers: getHeader() })
                    .then ( response => {
                        if (response.status === 200)
                        {
                            this.site_status = response.data.data;
                            this.updateAvailableProjectSiteStatus();
                        }
                    })
                    .catch ( error => {
                        if (typeof error.response !== "undefined") {
                            this.errorFunction(error, "Site Status Information")
                        }
                    } );
            },
            updateAvailableProjectSiteStatus() {
                // 20240109 andrew request to enable all site status selection
                // // set all to disabled initially, slowly allow selection based on validation
                // this.site_status = this.site_status.map(item => ({
                //     ...item,
                //     disabled: true
                // }));

                // const objIndex = this.site_status.findIndex((obj => obj.status_sequence == 1));
                // this.site_status[objIndex].disabled = false;

                // const conditions = [
                //     {
                //         condition: (this.projects_model.project_ppa_start_date != null
                //             && this.projects_model.project_ppa_approved_capacity != null
                //             && (this.model.contractor_id != null && this.model.contractor_id != "x")),
                //         statusSequence: 2
                //     },
                //     {
                //         condition: ((this.model.site_module_brand_id != null && this.model.site_module_brand_id != "x")
                //             && (this.model.site_module_quantity != null && this.model.site_module_quantity != 0)),
                //         statusSequence: 3
                //     },
                //     {
                //         condition: true,
                //         statusSequence: 4
                //     },
                //     {
                //         condition: (this.site_milestones.length > 0
                //             && this.site_milestones.every(obj => obj.planned_start_date !== null && obj.planned_end_date !== null)),
                //         statusSequence: 5
                //     },
                //     {
                //         condition: this.getSiteMilestoneActualDates("Installation"),
                //         statusSequence: 6
                //     },
                //     {
                //         condition: true,
                //         statusSequence: 7
                //     },
                //     {
                //         condition: true,
                //         statusSequence: 8
                //     },
                //     {
                //         condition: this.getSiteMilestoneActualDates("Turn On"),
                //         statusSequence: 9
                //     },
                //     {
                //         condition: true, // TO BE DONE, HOW TO CHECK IF ALL FIELDS ARE FILLED?
                //         statusSequence: 10
                //     },
                // ];

                // conditions.every(({ condition, statusSequence }) => {
                //     if (!condition) {
                //         return false; // Exit the loop if the condition is false
                //     }

                //     const objIndex = this.site_status.findIndex(obj => obj.status_sequence === statusSequence);
                //     this.site_status[objIndex].disabled = false;

                //     return true; // Continue to the next iteration if the condition is true
                // });
            },
            getSiteMilestoneActualDates(milestone_name) {
                if (this.site_milestones.length == 0) {
                    return false;
                }

                const site_milestone_data = this.site_milestones.find(obj => obj.site_milestone_code === milestone_name);

                if (typeof site_milestone_data !== "undefined") {
                    if (site_milestone_data.actual_start_date != null && site_milestone_data.actual_end_date != null) {
                        return true;
                    } else {
                        return false;
                    }
                } else {
                    return false;
                }

            },
            getContractorListing() {
                axios.get(apiDomain + 'setup/contractor', { headers: getHeader() })
                    .then ( response => {
                        if (response.status === 200)
                        {
                            this.contractors = response.data.data
                        }
                    })
                    .catch ( error => {
                        console.log(error.response.status)
                    } );
            },
            getModuleBrandListing(){
                axios.get(apiDomain + "setup/module_brand", { headers: getHeader() })
                    .then((response) => {
                        if (response.status === 200) {
                            this.module_brands = response.data.data;
                        }
                    })
                    .catch((error) => {
                        this.errorFunction(error, "Module Brand Information");
                    });
            },
            updateDrawingWattClass() {
                const module_brand_data = this.module_brands.find(obj => obj.module_brand_id === this.model.site_drawing_module_brand_id);
                this.model.site_drawing_module_capacity = parseFloat(module_brand_data.watt_class.replace(/,/g, ""));;
                this.calculateDrawingCapacity();
            },
            calculateDrawingCapacity() {
                this.model.site_drawing_total_capacity = ((this.model.site_drawing_module_quantity * this.model.site_drawing_module_capacity) / 1000)
            },
            updateModuleAllocationWattClass() {
                const module_brand_data = this.module_brands.find(obj => obj.module_brand_id === this.model.site_module_brand_id);
                this.model.site_module_capacity = module_brand_data.watt_class;
                this.calculateModuleAllocationCapacity();
            },
            calculateModuleAllocationCapacity() {
                this.model.site_module_allocation_total_capacity = ((this.model.site_module_quantity * this.model.site_module_capacity) / 1000);
            },
            getMilestoneTemplateListing() {
                axios.get(apiDomain + 'template/milestone_template', { headers: getHeader() })
                    .then ( response => {
                        if (response.status === 200)
                        {
                            this.milestone_template = response.data.data;
                        }
                    })
                    .catch ( error => {
                        this.errorFunction(error, "Milestone Template Information")
                    } );
            },
            checkMilestoneDetail()
            {
                if (this.model.milestone_template_id == 'x')
                {
                    this.$notify({
                        message:
                            '<b>Invalid Input : Milestone Template </b> - Please select a Milestone Template before dive into the Details',
                        timeout: 10000,
                        icon: 'ni ni-bulb-61',
                        type: 'danger',
                    });
                }
                else
                {
                    window.open('/templates/milestoneTemplateDetailListing/' + this.model.milestone_template_id, '_blank');
                }
            },
            importSiteMilestones() {
                if (this.model.milestone_template_id == 'x')
                {
                    this.$notify({
                        message:
                            '<b>Invalid Input : Milestone Template </b> - Please select a Milestone Template',
                        timeout: 10000,
                        icon: 'ni ni-bulb-61',
                        type: 'danger',
                    });
                }
                else
                {
                    // project_site.import_site_milestones/{site_id}
                    // call sp
                    const temp_model = {
                        milestone_template_id: this.model.milestone_template_id
                    };

                    const tokenData = JSON.parse(window.localStorage.getItem("authUser"));
                    const headers = {
                        Authorization: "Bearer " + tokenData.access_token,
                    };

                    axios
                        .post(
                            apiDomain + "project/project_site.import_site_milestones/" + this.model.site_id,
                            temp_model,
                            { headers }
                        )
                        .then((response) => {
                            console.log(response);
                            this.getProjectSiteMilestoneData();
                        })
                        .catch((error) => {
                            this.errorFunction(error, "Import Site Milestones");
                        });
                }
            },
            getDashboardData() {
                this.model.site_id = this.$route.params.id;
                axios
                    .get(apiDomain + "project/project_site/" + this.model.site_id, {
                        headers: getHeader(),
                    })
                    .then((response) => {
                        if (response.status === 200) {
                            this.model.project_id = response.data.data[0].project.project_id;
                            this.model.group_id = response.data.data[0].group.group_id;
                            document.title = 'MOMENS-' + response.data.data[0].site_name;
                            this.model.site_name = response.data.data[0].site_name;
                            this.model.status_id = response.data.data[0].status.status_id;
                            this.model.site_address = response.data.data[0].site_address;
                            this.model.site_postal_code = response.data.data[0].site_postal_code;
                            this.model.site_code = response.data.data[0].site_code;
                            this.model.site_latitude = response.data.data[0].site_latitude;
                            this.model.site_longitude = response.data.data[0].site_longitude;
                            this.model.site_cod_date = response.data.data[0].site_cod_date;
                            this.getProjectData();
                            this.model.site_target_turn_on_date = response.data.data[0].site_target_turn_on_date;
                            this.model.site_actual_turn_on_date = response.data.data[0].site_actual_turn_on_date;
                            // this.model.site_total_capacity = response.data.data[0].site_total_capacity;
                            this.model.site_total_capacity = parseFloat(response.data.data[0].site_total_capacity.replace(/,/g, ""));
                            this.model.site_as_built_capacity = parseFloat(response.data.data[0].site_as_built_capacity.replace(/,/g, ""));

                            this.model.site_drawing_module_brand_id = response.data.data[0].site_drawing_module_brand.module_brand_id;
                            this.model.site_drawing_module_quantity = parseFloat(response.data.data[0].site_drawing_module_quantity.replace(/,/g, ""));
                            this.model.site_drawing_module_capacity = parseFloat(response.data.data[0].site_drawing_module_capacity.replace(/,/g, ""));
                            this.model.site_drawing_total_capacity = parseFloat(response.data.data[0].site_drawing_total_capacity.replace(/,/g, ""));
                            this.model.site_drawing_ifa_date = response.data.data[0].site_drawing_ifa_date;
                            this.model.site_drawing_ifc_date = response.data.data[0].site_drawing_ifc_date;
                            this.model.site_module_brand_id = response.data.data[0].site_module_brand.module_brand_id;
                            this.model.site_module_quantity = parseFloat(response.data.data[0].site_module_quantity.replace(/,/g, ""));
                            this.model.site_module_capacity = parseFloat(response.data.data[0].site_module_capacity.replace(/,/g, ""));
                            this.model.site_module_allocation_total_capacity = parseFloat(response.data.data[0].site_module_allocation_total_capacity.replace(/,/g, ""));
                            this.model.site_ifc_ifa_module_remarks = response.data.data[0].site_ifc_ifa_module_remarks;
                        }
                    })
                    .catch((error) => {
                        this.errorFunction(error, "Site Information");
                    });
            },
            getProjectData() {
                axios
                    .get(apiDomain + "project/project/" + this.model.project_id, {
                        headers: getHeader(),
                    })
                    .then((response) => {
                        if (response.status === 200) {
                            this.contacts_model.project_manager = response.data.data[0].project_manager.name;
                            this.contacts_model.project_engineer = response.data.data[0].project_engineer.name;
                            this.contacts_model.project_business_development = response.data.data[0].project_business_development.name;
                            this.contacts_model.project_safety = response.data.data[0].project_safety.name;

                            this.projects_model.project_ppa_start_date = response.data.data[0].project_ppa_start_date;
                            this.projects_model.project_ppa_approved_capacity = response.data.data[0].project_ppa_approved_capacity;
                            this.projects_model.project_name = response.data.data[0].project_name;
                            this.projects_model.project_developer_name = response.data.data[0].developer.developer_name;
                            this.projects_model.project_watt_class = response.data.data[0].project_watt_class;
                            // this.updateAvailableProjectSiteStatus();

                            // default cod date = ppa signed date + 180 days
                            const project_ppa_start_date = response.data.data[0].project_ppa_start_date;
                            console.log('this.model.site_cod_date');
                            console.log(this.model.site_cod_date);
                            if (project_ppa_start_date && this.model.site_cod_date == null) {
                                const ppaCod = new Date(project_ppa_start_date);
                                ppaCod.setDate(ppaCod.getDate() + 180);
                                const ppaCodMonth = String((ppaCod.getMonth()+1)).padStart(2, '0');
                                const ppaCodDay = String(ppaCod.getDate()).padStart(2, '0');
                                const ppaCodDate = ppaCod.getFullYear() + '-' + ppaCodMonth + '-' + ppaCodDay;
                                this.model.site_cod_date = ppaCodDate;
                            }

                            this.updateAvailableProjectSiteStatus();
                        }
                    })
                    .catch((error) => {
                        if (typeof error.response !== "undefined") {
                            this.errorFunction(error, "Project Information");
                        }
                    });
            },
            getDashboardAdditionalInfo() {
                axios
                    .get(apiDomain + "project/project_site_additional_info/" + this.model.site_id, {
                        headers: getHeader(),
                    })
                    .then((response) => {
                        if (response.status === 200) {
                            this.additional_model.project_site_additional_info_id = response.data.data[0].project_site_additional_info_id;
                            this.additional_model.site_id = response.data.data[0].site_id;
                            this.additional_model.ntp_applicable = response.data.data[0].ntp_applicable;
                            this.additional_model.ntp_issued = response.data.data[0].ntp_issued;
                            this.additional_model.ntp_issue_date = response.data.data[0].ntp_issue_date;
                            this.additional_model.internal_project_handover_completed = response.data.data[0].internal_project_handover_completed;
                            this.additional_model.internal_project_handover_date = response.data.data[0].internal_project_handover_date;
                            this.additional_model.kick_off_meeting_completed = response.data.data[0].kick_off_meeting_completed;
                            this.additional_model.kick_off_meeting_date = response.data.data[0].kick_off_meeting_date;
                            this.additional_model.roof_handover_completed = response.data.data[0].roof_handover_completed;
                            this.additional_model.roof_handover_date = response.data.data[0].roof_handover_date;
                            this.additional_model.cops_tnc_checked = response.data.data[0].cops_tnc_checked;
                            this.additional_model.cops_tnc_issue = response.data.data[0].cops_tnc_issue;
                            this.additional_model.cops_tnc_remarks = response.data.data[0].cops_tnc_remarks;
                            this.additional_model.onm_handover_checked = response.data.data[0].onm_handover_checked;
                            this.additional_model.onm_handover_issue = response.data.data[0].onm_handover_issue;
                            this.additional_model.onm_handover_remarks = response.data.data[0].onm_handover_remarks;
                            this.additional_model.turn_on_checking_approved = response.data.data[0].turn_on_checking_approved;
                            this.additional_model.turn_on_checking_approval_date = response.data.data[0].turn_on_checking_approval_date;
                            this.additional_model.turn_on_checking_approver = response.data.data[0].turn_on_checking_approver;
                            this.additional_model.cif_technical_info = JSON.parse(response.data.data[0].cif_technical_info);
                            this.additional_model.cif_project_info = JSON.parse(response.data.data[0].cif_project_info);
                            if (this.additional_model.cif_project_info) {
                                this.addIsEmailAttribute(this.additional_model.cif_project_info);
                            }
                        }
                    })
                    .catch((error) => {
                        this.errorFunction(error, "Site Additional Information");
                    });
            },
            addIsEmailAttribute(data) {
                for (let i = 0; i < data.length; i++) {
                    if (data[i].column_4 === 'Email / Phone Number') {
                        for (let j = i + 1; j <= i + 4 && j < data.length; j++) {
                            data[j].isEmail = true;
                        }
                    }
                }
            },
            getProjectSiteMilestoneData() {
                axios
                    .get(
                        apiDomain +
                        "project/project_site_milestone.show_by_site/" +
                        this.model.site_id,
                        { headers: getHeader() }
                    )
                    .then((response) => {
                        if (response.status === 200) {
                            let data = response.data.data;
                            data.forEach(item => {
                                item.total_planned_day = 0;
                                item.total_actual_day = 0;
                                if(item.planned_start_date !== null && item.planned_end_date !== null){
                                    item.total_planned_day  = this.getDaysDifference(item.planned_start_date,item.planned_end_date)
                                }

                                if(item.actual_start_date !== null && item.actual_end_date !== null){
                                    item.total_actual_day  = this.getDaysDifference(item.actual_start_date,item.actual_end_date)
                                }
                            });
                            this.site_milestones = data;
                            this.updateGanttChartSeries();

                            // get and update Planned Turn On (site_target_turn_on_date) based on "Turn On" planned_end_date
                            // get and update Actual Turn On based on "Turn On" actual_end_date if value is null
                            // ONE WAY!!!
                            // changing planned_end_date of "Turn On" will change site_target_turn_on_date
                            // changing site_target_turn_on_date will not change planned_end_date of "Turn On"

                            const turn_on_milestone_data = this.site_milestones.find(obj => obj.site_milestone_code === "Turn On");

                            if (turn_on_milestone_data != undefined) {
                                // update Planned Turn On (site_target_turn_on_date) based on "Turn On" planned_end_date
                                if (turn_on_milestone_data.planned_end_date) {
                                    this.model.site_target_turn_on_date = turn_on_milestone_data.planned_end_date;
                                }
                                // update Actual Turn On based on "Turn On" actual_end_date if value is null
                                if (turn_on_milestone_data.actual_end_date && (this.model.site_actual_turn_on_date == null)) {
                                    this.model.site_actual_turn_on_date = turn_on_milestone_data.actual_end_date;
                                }
                            }
                        }
                    })
                    .catch((error) => {
                        console.log(error.response.status);
                    });
            },
            updateGanttChartSeries() {
                const temp_series = [
                    {
                        name: 'Planned',
                        data: this.site_milestones.map(item => ({
                            x: item.site_milestone_code,
                            y: [
                                item.planned_start_date ? new Date(item.planned_start_date).getTime() : null,
                                item.planned_end_date ? new Date(item.planned_end_date).getTime() : null
                            ]
                        }))
                    },
                    {
                        name: 'Actual',
                        data: this.site_milestones.map(item => ({
                            x: item.site_milestone_code,
                            y: [
                                item.actual_start_date ? new Date(item.actual_start_date).getTime() : null,
                                item.actual_end_date ? new Date(item.actual_end_date).getTime() : null
                            ]
                        }))
                    }
                ];

                this.series = [];
                this.series = temp_series;

                // this.chartOptions.xaxis.min = new Date('2023-07-01').getTime();
                // this.chartOptions.xaxis.max = new Date('2023-12-01').getTime();
            },
            getContractorFromSite()
            {
                axios.get(apiDomain + 'project/site_contractor.show_by_site/' + this.model.site_id + '?client=mobile', { headers: getHeader() })
                    .then ( response => {
                        if (response.status === 200)
                        {
                            this.model.contractor_id = response.data.data[0].contractor.contractor_id;
                            this.updateAvailableProjectSiteStatus();
                        }
                    })
                    .catch ( error => {
                        if (typeof error.response !== "undefined") {
                            this.errorFunction(error, "Site Contractor Information")
                        }
                    } );
            },
            saveSiteData() {
                // CAREFUL WITH PUT METHOD, MIGHT NEED TO CHANGE TO POST WITH _put

                axios.put(apiDomain + 'project/project_site/' + this.model.site_id, this.model, { headers: getHeader() })
                    .then ( response => {
                        if (response.status === 200)
                        {
                            this.$notify({
                                message:
                                '<b>Update Project Site : ' + this.model.site_name + '</b> - Successfully Updated.',
                                timeout: 10000,
                                icon: 'ni ni-bell-55',
                                type: 'default',
                            });
                            window.close();
                        }
                    })
                    .catch ( error => {
                        this.errorFunction(error, "Update Project Site Information")
                    });
            },
            setActiveSiteContractor() {
                if ( this.model.contractor_id == 'x' ) {
                    this.$notify({
                        message:
                            '<b>Invalid Contractor : </b> - Please Select A Contractor to proceed.',
                        timeout: 10000,
                        icon: 'ni ni-bulb-61',
                        type: 'danger',
                    });
                } else {
                    const site_contractor_model = {
                        site_id: this.model.site_id,
                        contractor_id: this.model.contractor_id,
                        active_status: 1
                    };

                    const tokenData = JSON.parse(window.localStorage.getItem("authUser"));
                    const headers = {
                        Authorization: "Bearer " + tokenData.access_token,
                    };

                    axios
                        .post(
                            apiDomain + "project/site_contractor.set_active_contractor/" + this.model.site_id,
                            site_contractor_model,
                            { headers }
                        )
                        .then((response) => {
                            this.$notify({
                                message:
                                    '<b>Site Contractor Successfully Updated.',
                                timeout: 10000,
                                icon: 'ni ni-bell-55',
                                type: 'default',
                            });
                        })
                        .catch((error) => {
                            this.errorFunction(error, "Set Active Site Contractor")
                        });
                }
            },
            saveSiteAdditionalInfo() {
                // CAREFUL WITH PUT METHOD, MIGHT NEED TO CHANGE TO POST WITH _put
                axios.put(apiDomain + 'project/project_site_additional_info/' + this.model.site_id, this.additional_model, { headers: getHeader() })
                    .then ( response => {
                        if (response.status === 200)
                        {
                            this.$notify({
                                message:
                                '<b>Update Project Site : ' + this.model.site_name + '</b> - Successfully Updated.',
                                timeout: 10000,
                                icon: 'ni ni-bell-55',
                                type: 'default',
                            });
                            this.getDashboardAdditionalInfo();
                            // this.$router.back()
                        }
                    })
                    .catch ( error => {
                        this.errorFunction(error, "Update Project Site Additional Information")
                    });

            },
            approveToCloseTurnOnChecking()
            {
                const dummy_model = {
                    dummy_data: 0
                };

                const tokenData = JSON.parse(window.localStorage.getItem("authUser"));
                const headers = {
                    Authorization: "Bearer " + tokenData.access_token,
                };

                axios
                    .post(
                        apiDomain + "project/project_site_additional_info.approve_to_close/" + this.model.site_id,
                        dummy_model,
                        { headers }
                    )
                    .then((response) => {
                        this.$notify({
                            message:
                                '<b>Turn On Checking Approval To Close Successfully Approved.</b>',
                            timeout: 10000,
                            icon: 'ni ni-bell-55',
                            type: 'default',
                        });
                        //   this.$router.back()
                        this.getDashboardAdditionalInfo();
                    })
                    .catch((error) => {
                        this.errorFunction(error, "Approve To Close Turn On Checking")
                    });
            },
            saveSiteMilestoneData() {
                // this.site_milestones

                const tokenData = JSON.parse(window.localStorage.getItem("authUser"));
                const headers = {
                    Authorization: "Bearer " + tokenData.access_token,
                };

                axios
                    .post(
                        apiDomain + "project/project_site_milestone.update_site_milestones/" + this.model.site_id,
                        this.site_milestones,
                        { headers }
                    )
                    .then((response) => {
                        this.getProjectSiteMilestoneData();
                    })
                    .catch((error) => {
                        this.errorFunction(error, "Update Site Milestones Attachment");
                    });

            },
            dataPointSelectionHandler(e, chartContext, config) {
                //   this.tempPieDataCNI = [config.seriesIndex, config.dataPointIndex];
                this.selectedDataPointIndex = config.dataPointIndex;
                this.total_actual_date = this.site_milestones[config.dataPointIndex].total_actual_day;
                this.isSiteMilestoneVisible = true;
            },
            closeAndUpdateGanttChart() {
                this.updateGanttChartSeries();
                this.saveSiteMilestoneData();
                this.isSiteMilestoneVisible = false;
            },
            getSiteAttachmentListing() {
                axios
                    .get(apiDomain + "project/project_site.site_attachment/" + this.model.site_id, {
                        headers: getHeader(),
                    })
                    .then((response) => {
                        if (response.status === 200) {
                            this.site_attachments = response.data.data;
                        }
                    })
                    .catch((error) => {
                        this.errorFunction(error, "Site Attachment Listing");
                    });
            },
            onChange(event) {
                this.site_attachment.file = event.target.files ? event.target.files[0] : null;
            },
            uploadFile() {
                let formData = jsonFormData(this.site_attachment);

                const tokenData = JSON.parse(window.localStorage.getItem("authUser"));
                const headers = {
                    Authorization: "Bearer " + tokenData.access_token,
                };

                axios
                    .post(
                        apiDomain + "project/project_site.upload_site_attachment/" + this.model.site_id,
                        formData,
                        { headers }
                    )
                    .then((response) => {
                        // NOTIFY SUCCESSFUL UPLOAD HERE
                        this.getSiteAttachmentListing();
                        this.site_attachment.file = null;
                    })
                    .catch((error) => {
                        this.errorFunction(error, "Upload Site Attachment");
                    });
            },
            downloadFile(apiEndpoint, filename) {
                axios.get(apiDomain + apiEndpoint, { headers: getHeader(),  responseType: 'blob'})
                    .then((response) => {
                        var fileURL = window.URL.createObjectURL(new Blob([response.data]));
                        var fileLink = document.createElement('a');

                        fileLink.href = fileURL;
                        fileLink.setAttribute('download', filename);
                        document.body.appendChild(fileLink);

                        fileLink.click();
                    });
            },
            deleteFile(site_file_id) {
                axios.get(apiDomain  + "project/project_site.delete_site_attachment/" + site_file_id, { headers: getHeader()})
                    .then((response) => {
                        this.getSiteAttachmentListing();
                    })
                    .catch((error) => {
                        this.errorFunction(error, "Delete Site Attachment");
                    });
            },
            onChangeCif(event) {
                this.cif_file = event.target.files ? event.target.files[0] : null;
            },
            loadSheetNames() {
                if (typeof(this.$refs.excelRead._workbook) !== "undefined") {
                    // this.sheets = this.$refs.excelRead._workbook.Workbook.Sheets;
                    this.sheets = this.$refs.excelRead._workbook.Workbook.Sheets.filter(item => item.name == "Stage_1 Technical Info (ENG)" || item.name == "Stage_1 Project Info (BD)");
                }
            },
            updateRangeToSelect () {
                if (typeof(this.$refs.excelRead._workbook) !== "undefined" && this.selected_sheet == "Stage_1 Technical Info (ENG)") {
                    this.selected_range = 'B8:D63';
                } else if (typeof(this.$refs.excelRead._workbook) !== "undefined" && this.selected_sheet == "Stage_1 Project Info (BD)") {
                    this.selected_range = 'B7:E66';
                }

            },
            showUploadingData () {
                this.tempData = this.$refs.excelJson.$data.collection;
                if (typeof(this.$refs.excelRead._workbook) !== "undefined" && this.selected_sheet == "Stage_1 Technical Info (ENG)") {
                    this.converted_temp_data = this.$refs.excelJson.$data.collection.map((item) => {
                        return {
                            column_name: item.__EMPTY,
                            unit: item.__EMPTY_1,
                            value: item["Building 1"],
                        };
                    });
                } else if (typeof(this.$refs.excelRead._workbook) !== "undefined" && this.selected_sheet == "Stage_1 Project Info (BD)") {
                    this.converted_temp_data = this.$refs.excelJson.$data.collection.map((item) => {
                        return {
                            column_1: item.Information,
                            column_2: item.Response,
                            column_3: item.__EMPTY,
                            column_4: item.Remarks,
                        };
                    });
                }
            },
            uploadCif() {
                this.updateRangeToSelect();
                this.showUploadingData();
                if (this.selected_sheet == "Stage_1 Technical Info (ENG)") {
                    this.uploadCifTechnicalInfo();
                } else if (this.selected_sheet == "Stage_1 Project Info (BD)") {
                    this.uploadCifProjectInfo();
                }
            },
            uploadCifTechnicalInfo () {
                const post_data = {
                    data: this.converted_temp_data
                }

                const tokenData = JSON.parse(window.localStorage.getItem("authUser"));
                const headers = {
                    Authorization: "Bearer " + tokenData.access_token,
                };

                axios
                    .post(
                        apiDomain + "project/project_site_additional_info.cif_technical_info/" + this.model.site_id,
                        post_data,
                        { headers }
                    )
                    .then((response) => {
                        // NOTIFY SUCCESSFUL UPLOAD HERE
                        this.selected_sheet = 'x';
                        this.selected_range = '';
                        this.getDashboardAdditionalInfo();
                    })
                    .catch((error) => {
                        this.errorFunction(error, "Upload CIF Technical Info");
                    });
            },
            uploadCifProjectInfo () {
                const post_data = {
                    data: this.converted_temp_data
                }

                const tokenData = JSON.parse(window.localStorage.getItem("authUser"));
                const headers = {
                    Authorization: "Bearer " + tokenData.access_token,
                };

                axios
                    .post(
                        apiDomain + "project/project_site_additional_info.cif_project_info/" + this.model.site_id,
                        post_data,
                        { headers }
                    )
                    .then((response) => {
                        // NOTIFY SUCCESSFUL UPLOAD HERE
                        this.selected_sheet = 'x';
                        this.selected_range = '';
                        this.getDashboardAdditionalInfo();
                    })
                    .catch((error) => {
                        this.errorFunction(error, "Upload CIF Project Info");
                    });
            },
            getDaysDifference(startDate, endDate) {

                if(startDate != "N/A" || endDate != "N/A"){
                    // Convert the date strings to Date objects
                    const startDateObj = new Date(startDate);
                    const endDateObj = new Date(endDate);

                    // Calculate the difference in milliseconds between the two dates
                    const differenceInMilliseconds = endDateObj - startDateObj;

                    // Convert milliseconds to days
                    const millisecondsInOneDay = 1000 * 60 * 60 * 24;
                    const differenceInDays = differenceInMilliseconds / millisecondsInOneDay;

                    // Round the difference to remove decimal places
                    return Math.round(differenceInDays)+1;
                }else{
                    return 0;
                }
            },
            calculateTotalPlannedDay(index,field,inputValue = null){

                let plannedStartDate = this.site_milestones[index].planned_start_date;
                let plannedEndDate = this.site_milestones[index].planned_end_date;
                let totalPlannedDate = this.site_milestones[index].total_planned_day;

                let actualStartDate = this.site_milestones[index].actual_start_date;
                let actualEndDate = this.site_milestones[index].actual_end_date;
                let totalActualDate =  (inputValue !== null) ? inputValue : this.total_actual_date;

                let newDate = null;
                let convertDate = null;

                console.log(plannedStartDate)
                console.log(plannedEndDate)
                console.log(totalPlannedDate)

                console.log(actualStartDate)
                console.log(actualEndDate)
                console.log(totalActualDate)

                const millisecondsInOneDay = 1000 * 60 * 60 * 24;

                if(field == 'total_planned_day'){
                    if(plannedStartDate !== null && totalPlannedDate !== null){
                        plannedStartDate  = new Date(plannedStartDate);
                        newDate = new Date(plannedStartDate.getTime() + totalPlannedDate*millisecondsInOneDay);
                        convertDate = this.convertDateTime(newDate);
                        this.site_milestones[index].planned_end_date = convertDate;
                    }else if(plannedStartDate == null && plannedEndDate !== null && totalPlannedDate !== null){
                        plannedEndDate  = new Date(plannedEndDate);
                        newDate = new Date(plannedEndDate.getTime() - totalPlannedDate*millisecondsInOneDay);
                        convertDate = this.convertDateTime(newDate);
                        this.site_milestones[index].planned_start_date = convertDate;
                    }

                }else if(field == 'planned_start_date'){
                    if(plannedStartDate !== null && totalPlannedDate > 0){
                        plannedStartDate  = new Date(plannedStartDate);
                        newDate = new Date(plannedStartDate.getTime() + totalPlannedDate*millisecondsInOneDay);
                        convertDate = this.convertDateTime(newDate);
                        this.site_milestones[index].planned_end_date = convertDate;
                    }else if(plannedStartDate !== null && plannedEndDate !== null){
                        totalPlannedDate = this.getDaysDifference(plannedStartDate,plannedEndDate);
                        this.site_milestones[index].total_planned_day = totalPlannedDate;
                    }
                }else if(field == 'planned_end_date'){
                    if(plannedEndDate !== null && totalPlannedDate > 0 && plannedStartDate == null){
                        plannedEndDate  = new Date(plannedEndDate);
                        newDate = new Date(plannedEndDate.getTime() - totalPlannedDate*millisecondsInOneDay);
                        convertDate = this.convertDateTime(newDate);
                        this.site_milestones[index].planned_start_date = convertDate;
                    }else if(plannedStartDate !== null && plannedEndDate !== null){
                        totalPlannedDate = this.getDaysDifference(plannedStartDate,plannedEndDate);
                        this.site_milestones[index].total_planned_day = totalPlannedDate;
                    }
                }else if(field == 'total_actual_day'){
                    console.log(actualStartDate);
                    console.log(totalActualDate);
                    console.log(actualEndDate);
                    if(actualStartDate  != '<empty string>' && actualStartDate !== null && totalActualDate !== null){
                        console.log('is here')
                        actualStartDate  = new Date(actualStartDate);
                        newDate = new Date(actualStartDate.getTime() + totalActualDate*millisecondsInOneDay);
                        convertDate = this.convertDateTime(newDate);
                        console.log(convertDate);
                        this.site_milestones[index].actual_end_date = convertDate;
                    }else if(actualStartDate  == '<empty string>' && actualStartDate == null && actualEndDate !== null && totalActualDate !== null){
                        actualEndDate  = new Date(actualEndDate);
                        newDate = new Date(actualEndDate.getTime() - totalActualDate*millisecondsInOneDay);
                        convertDate = this.convertDateTime(newDate);
                        this.site_milestones[index].actual_start_date = convertDate;
                    }

                }else if(field == 'actual_start_date'){
                    if(actualStartDate  != '<empty string>' && actualStartDate !== null && totalActualDate > 0){
                        actualStartDate  = new Date(actualStartDate);
                        newDate = new Date(actualStartDate.getTime() + totalActualDate*millisecondsInOneDay);
                        convertDate = this.convertDateTime(newDate);
                        this.site_milestones[index].actual_end_date = convertDate;
                    }else if(actualStartDate !== null && actualEndDate !== null){
                        totalActualDate = this.getDaysDifference(actualStartDate,actualEndDate);
                        this.site_milestones[index].total_actual_day = totalActualDate;
                    }
                }else if(field == 'actual_end_date'){
                    if(actualEndDate !== null && totalActualDate > 0 && (actualStartDate == null  || actualStartDate  == '<empty string>')){
                        actualEndDate  = new Date(actualEndDate);
                        newDate = new Date(actualEndDate.getTime() - totalActualDate*millisecondsInOneDay);
                        convertDate = this.convertDateTime(newDate);
                        this.site_milestones[index].actual_start_date = convertDate;
                    }else if(actualStartDate !== '<empty string>' && actualStartDate !== null && actualEndDate !== null){
                        totalActualDate = this.getDaysDifference(actualStartDate,actualEndDate);
                        this.site_milestones[index].total_actual_day = totalActualDate;
                    }
                }

            },
            specificCalculateActualDate(event){
                this.site_milestones[this.selectedDataPointIndex].total_actual_day = event;
                this.calculateTotalPlannedDay(this.selectedDataPointIndex,'total_actual_day',event);
            },
            convertDateTime(date){
                let convertDate = new Date(date);
                const year = convertDate.getFullYear();
                const month = convertDate.getMonth();
                const day = convertDate.getDate();

                return convertDate.toISOString().slice(0,10);
            },
            onChangeProjectSiteTask(event){
                this.project_task_file = event.target.files ? event.target.files[0] : null;

            },
            loadProjectTaskSheetNames() {
                if (typeof(this.$refs.excelRead1._workbook) !== "undefined") {
                    this.projectTaskSheets = this.$refs.excelRead1._workbook.Workbook.Sheets;
                    //this.projectTaskSheets = this.$refs.excelRead1._workbook.Workbook.Sheets.filter(item => item.name == "Task_Table1");
                }
            },
            updateProjectTaskRangeToSelect () {
                //this.selected_project_task_sheet = "Task_Table1";
                if (typeof(this.$refs.excelRead1._workbook) !== "undefined" && this.selected_project_task_sheet != 'x') {
                    const original_range = this.$refs.excelRead1._workbook.Sheets[this.selected_project_task_sheet]['!ref'];
                    this.selected_project_task_range = 'A1:' + original_range.substring(3);
                    this.selected_project_task_range = this.selected_project_task_range.replace(/:(.*?)(?=\d)/, ':J');
                }

            },
            showUploadingProjectTaskData(){
                this.projectTaskTempData = this.$refs.excelJson1.$data.collection;
            },
            uploadProjectSiteTask() {
                this.updateProjectTaskRangeToSelect();
                this.showUploadingProjectTaskData();
                const post_data = {
                    data: this.projectTaskTempData
                }


                const tokenData = JSON.parse(window.localStorage.getItem("authUser"));
                const headers = {
                    Authorization: "Bearer " + tokenData.access_token,
                };
                axios
                    .post(
                        apiDomain + "project/ms_project_import_from_upload.from_json/"+ this.model.site_id,
                        post_data,
                        { headers }
                    )
                    .then((response) => {
                        if (response.status === 200) {
                            //this.getGanttChartData();
                            this.$notify({
                                message:
                                '<b>Update Project Site Task : ' + this.model.site_name + '</b> - Successfully Updated.',
                                timeout: 10000,
                                icon: 'ni ni-bell-55',
                                type: 'default',
                            });
                            window.close();
                        }

                    })
                    .catch((error) => {
                        this.$notify({
                            message: "</b>Import New Project File",
                            timeout: 10000,
                            icon: "ni ni-bulb-61",
                            type: "danger",
                        });
                        this.errorFunction(error);
                    });
            },
            getDaysDifference(startDate, endDate) {

                if(startDate != "N/A" || endDate != "N/A"){
                    // Convert the date strings to Date objects
                    const startDateObj = new Date(startDate);
                    const endDateObj = new Date(endDate);

                    // Calculate the difference in milliseconds between the two dates
                    const differenceInMilliseconds = endDateObj - startDateObj;

                    // Convert milliseconds to days
                    const millisecondsInOneDay = 1000 * 60 * 60 * 24;
                    const differenceInDays = differenceInMilliseconds / millisecondsInOneDay;

                    // Round the difference to remove decimal places
                    return Math.round(differenceInDays)+1;
                }else{
                    return 0;
                }
            },
            calculateTotalPlannedDay(index,field,inputValue = null){

                let plannedStartDate = this.site_milestones[index].planned_start_date;
                let plannedEndDate = this.site_milestones[index].planned_end_date;
                let totalPlannedDate = this.site_milestones[index].total_planned_day;

                let actualStartDate = this.site_milestones[index].actual_start_date;
                let actualEndDate = this.site_milestones[index].actual_end_date;
                let totalActualDate =  (inputValue !== null) ? inputValue : this.total_actual_date;

                let newDate = null;
                let convertDate = null;

                const millisecondsInOneDay = 1000 * 60 * 60 * 24;

                if(field == 'total_planned_day'){
                    if(plannedStartDate !== null && totalPlannedDate !== null){
                        plannedStartDate  = new Date(plannedStartDate);
                        newDate = new Date(plannedStartDate.getTime() + totalPlannedDate*millisecondsInOneDay);
                        convertDate = this.convertDateTime(newDate);
                        this.site_milestones[index].planned_end_date = convertDate;
                    }else if(plannedStartDate == null && plannedEndDate !== null && totalPlannedDate !== null){
                        plannedEndDate  = new Date(plannedEndDate);
                        newDate = new Date(plannedEndDate.getTime() - totalPlannedDate*millisecondsInOneDay);
                        convertDate = this.convertDateTime(newDate);
                        this.site_milestones[index].planned_start_date = convertDate;
                    }

                }else if(field == 'planned_start_date'){
                    if(plannedStartDate !== null && totalPlannedDate > 0){
                        plannedStartDate  = new Date(plannedStartDate);
                        newDate = new Date(plannedStartDate.getTime() + totalPlannedDate*millisecondsInOneDay);
                        convertDate = this.convertDateTime(newDate);
                        this.site_milestones[index].planned_end_date = convertDate;
                    }else if(plannedStartDate !== null && plannedEndDate !== null){
                        totalPlannedDate = this.getDaysDifference(plannedStartDate,plannedEndDate);
                        this.site_milestones[index].total_planned_day = totalPlannedDate;
                    }
                }else if(field == 'planned_end_date'){
                    if(plannedEndDate !== null && totalPlannedDate > 0 && plannedStartDate == null){
                        plannedEndDate  = new Date(plannedEndDate);
                        newDate = new Date(plannedEndDate.getTime() - totalPlannedDate*millisecondsInOneDay);
                        convertDate = this.convertDateTime(newDate);
                        this.site_milestones[index].planned_start_date = convertDate;
                    }else if(plannedStartDate !== null && plannedEndDate !== null){
                        totalPlannedDate = this.getDaysDifference(plannedStartDate,plannedEndDate);
                        this.site_milestones[index].total_planned_day = totalPlannedDate;
                    }
                }else if(field == 'total_actual_day'){
                    console.log(actualStartDate);
                    console.log(totalActualDate);
                    console.log(actualEndDate);
                    if(actualStartDate  != '<empty string>' && actualStartDate !== null && totalActualDate !== null){
                        console.log('is here')
                        actualStartDate  = new Date(actualStartDate);
                        newDate = new Date(actualStartDate.getTime() + totalActualDate*millisecondsInOneDay);
                        convertDate = this.convertDateTime(newDate);
                        console.log(convertDate);
                        this.site_milestones[index].actual_end_date = convertDate;
                    }else if(actualStartDate  == '<empty string>' && actualStartDate == null && actualEndDate !== null && totalActualDate !== null){
                        actualEndDate  = new Date(actualEndDate);
                        newDate = new Date(actualEndDate.getTime() - totalActualDate*millisecondsInOneDay);
                        convertDate = this.convertDateTime(newDate);
                        this.site_milestones[index].actual_start_date = convertDate;
                    }

                }else if(field == 'actual_start_date'){
                    if(actualStartDate  != '<empty string>' && actualStartDate !== null && totalActualDate > 0){
                        actualStartDate  = new Date(actualStartDate);
                        newDate = new Date(actualStartDate.getTime() + totalActualDate*millisecondsInOneDay);
                        convertDate = this.convertDateTime(newDate);
                        this.site_milestones[index].actual_end_date = convertDate;
                    }else if(actualStartDate !== null && actualEndDate !== null){
                        totalActualDate = this.getDaysDifference(actualStartDate,actualEndDate);
                        this.site_milestones[index].total_actual_day = totalActualDate;
                    }
                }else if(field == 'actual_end_date'){
                    if(actualEndDate !== null && totalActualDate > 0 && (actualStartDate == null  || actualStartDate  == '<empty string>')){
                        actualEndDate  = new Date(actualEndDate);
                        newDate = new Date(actualEndDate.getTime() - totalActualDate*millisecondsInOneDay);
                        convertDate = this.convertDateTime(newDate);
                        this.site_milestones[index].actual_start_date = convertDate;
                    }else if(actualStartDate !== '<empty string>' && actualStartDate !== null && actualEndDate !== null){
                        totalActualDate = this.getDaysDifference(actualStartDate,actualEndDate);
                        this.site_milestones[index].total_actual_day = totalActualDate;
                    }
                }

            },
            specificCalculateActualDate(event){
                this.site_milestones[this.selectedDataPointIndex].total_actual_day = event;
                this.calculateTotalPlannedDay(this.selectedDataPointIndex,'total_actual_day',event);
            },
            convertDateTime(date){
                let convertDate = new Date(date);
                const year = convertDate.getFullYear();
                const month = convertDate.getMonth();
                const day = convertDate.getDate();

                return convertDate.toISOString().slice(0,10);
            },
            excelDateToJSDate(serial) {
                var utc_days = Math.floor(serial - 25569);
                var utc_value = utc_days * 86400;
                var date_info = new Date(utc_value * 1000);

                var fractional_day = serial - Math.floor(serial) + 0.0000001;

                var total_seconds = Math.floor(86400 * fractional_day);

                var seconds = total_seconds % 60;

                total_seconds -= seconds;

                var hours = Math.floor(total_seconds / (60 * 60));
                var minutes = Math.floor(total_seconds / 60) % 60;

                return new Date(
                    date_info.getFullYear(),
                    date_info.getMonth(),
                    date_info.getDate(),
                    hours,
                    minutes,
                    seconds
                );
            },
            convertToDateOnly(inputDateTime) {
                const current = inputDateTime;
                const currentMonth = String(current.getMonth() + 1).padStart(2, "0");
                const currentDay = String(current.getDate()).padStart(2, "0");
                const currentDate =
                    current.getFullYear() + "-" + currentMonth + "-" + currentDay;
                return currentDate;
            },
            convertExcelDateTime() {
                this.projectTaskTempData.forEach((e) => {
                    e["Start_Date"] = this.excelDateToJSDate(e["Start_Date"]);
                    e["Start_Date"] = this.convertToDateOnly(e["Start_Date"]);

                    e["Finish_Date"] = this.excelDateToJSDate(e["Finish_Date"]);
                    e["Finish_Date"] = this.convertToDateOnly(e["Finish_Date"]);

                    e["Actual_Start"] = (e["Actual_Start"] != "NA") ? this.excelDateToJSDate(e["Actual_Start"]) : e["Actual_Start"];
                    e["Actual_Start"] = (e["Actual_Start"] != "NA") ? this.convertToDateOnly(e["Actual_Start"]) : e["Actual_Start"];

                    e["Actual_Finish"] = (e["Actual_Finish"] != "NA") ? this.excelDateToJSDate(e["Actual_Finish"]) : e["Actual_Finish"];
                    e["Actual_Finish"] = (e["Actual_Finish"] != "NA") ? this.convertToDateOnly(e["Actual_Finish"]) : e["Actual_Finish"];
                });
            },

        }

    };


</script>
<style scoped>
  .content-dash {
    width: 100%;
    height: 100%;
    border: 2px dashed #336C6C;
  }

  select {
    background: url("data:image/svg+xml,<svg width='10' height='7' viewBox='0 0 10 7' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M5.02552 6.35057L0.320068 0.46875H9.73098L5.02552 6.35057Z' fill='white'/></svg>") no-repeat;
    background-position: calc(100% - 0.75rem) center !important;
    -moz-appearance:none !important;
    -webkit-appearance: none !important;
    appearance: none !important;
    padding-right: 2rem !important;
  }

  .bg-card-dashboard {
    background-color: #23454A;
  }
  .bg-default-dashboard {
    background-color: #082A2F;
    /* background-size: cover; */
    /* position:fixed; */
    padding:0;
    margin:0;
    top:0;
    left:0;
    width: 100%;
    height: 100%;
  }
  .form-control {
    background-color: #2C545A;;
    border-color: #2C545A;
    color: white;
  &:focus {
     background-color: #2C545A;
     border-color: #2C545A;
     color: white;
   }
  &::placeholder {
     color: white;
   }
  }
  .form-control {
  &:focus {
  &::placeholder {
     color: white;
   }
  }
  }
  .input-group-text {
    background-color: #2C545A;;
    color: white;
  &:focus {
     background-color: #2C545A;;
     border-color: #1D4147;
     color: white;
   }
  }
  .focused {
  .input-group-text {
    background-color: #2C545A;
  }
  }
  .navbar-search-dark .input-group {
    background-color: #23454A;
    color: black;
    border-color: rgba(255, 255, 255, 0.6);
  }
  .navbar-search .input-group {
    border-radius: 7px;
  }
  .el-input__inner, .el-select-dropdown__item.is-disabled:hover {
    background-color: #23454A;
  }
  .line-custom {
    background-color: #356168; margin-top: 15px; margin-bottom: 15px;
  }

  /* .el-table th.el-table__cell {

    overflow: hidden;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-color: #23454A;
    color: white;
    padding: 20px;
    border-top: 0px solid #082A2F;
    border-bottom: 0px solid #082A2F;
  }

  .el-table td.el-table__cell, .el-table th.el-table__cell.is-leaf {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    border-bottom: 20px solid #082A2F;
    border-top: 0px solid #082A2F;
  }

  div.el-table .el-table__row {
    background: #3a656b;;
    border-bottom: 0 solid #082A2F;

  }
  div.el-table .el-table__row:hover {
    background: #0d2f33;
  }

  .el-table--border::after, .el-table--group::after, .el-table::before {
    content: '';
    position: absolute;
    background-color: #ebeef500;
    z-index: 1;
  } */

</style>

