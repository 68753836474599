<template>
  <b-card body-class="p-0" header-class="border-0">
    <template v-slot:header>
      <b-row align-v="center">
        <b-col>
          <h5 class="mb-0">C & I Project Status</h5>
        </b-col>
        <b-col class="text-right">
          <!-- <base-button size="sm" type="primary">See all</base-button> -->
           <h5 class="mb-0">Total : 52.2 MWp</h5>

        </b-col>
      </b-row>
    </template>


    <el-table
      class="table-responsive table"
      :data="tableData"
      stripe
      header-row-class-name="thead-light">
      <el-table-column label="project" min-width="100px" prop="project" >
         <template v-slot="{row}">
          <div class="d-flex align-items-center">
              <span style="font-size: 11px;">{{row.project}}</span>
          </div>
        </template>
      </el-table-column>

      <el-table-column label="MWp" min-width="55px" prop="capacity">
          <template v-slot="{row}">
          <div class="d-flex align-items-center">
              <span style="font-size: 11px;">{{row.capacity}}</span>
          </div>
        </template>
      </el-table-column>

      <el-table-column label="Target COD" min-width="60px" prop="target_cod">
         <template v-slot="{row}">
          <div class="d-flex align-items-center">
              <span style="font-size: 11px;">{{row.target_cod}}</span>
          </div>
        </template>
      </el-table-column>

      <el-table-column label="Forcast COD" min-width="60px" prop="forecast_cod">
         <template v-slot="{row}">
          <div class="d-flex align-items-center">
              <span style="font-size: 11px;">{{row.forecast_cod}}</span>
          </div>
        </template>
      </el-table-column>

      <el-table-column label="#Days" min-width="55px" prop="days">
         <template v-slot="{row}">
          <div class="d-flex align-items-center">
            <!-- <span class="mr-2">{{row.days}}</span> -->
                <badge class="badge-dot mr-4" type="">
                    <i :class="row.progressType"></i>
                       <span style="font-size: 11px;">{{row.days}}</span>
                </badge>
          </div>
        </template>
      </el-table-column>

      <el-table-column label="$ IMPACT" min-width="60px" prop="impact">
          <template v-slot="{row}">
          <div class="d-flex align-items-center">
              <span style="font-size: 11px;">{{row.impact}}</span>
          </div>
        </template>
      </el-table-column>
      <!-- <el-table-column label="Referral" min-width="115px" prop="name">
        <template v-slot="{row}">
          <div class="font-weight-600">{{row.name}}</div>
        </template>
      </el-table-column>

      <el-table-column label="Visitors" min-width="110px" prop="visitors">
      </el-table-column>

      <el-table-column min-width="220px" prop="progress">
        <template v-slot="{row}">
          <div class="d-flex align-items-center">
            <span class="mr-2">{{row.progress}}%</span>
            <base-progress :type="row.progressType" :value="row.progress" />
          </div>
        </template>
      </el-table-column> -->
    </el-table>
  </b-card>
</template>
<script>
  import { BaseProgress } from '@/components';
  import { Table, TableColumn, DropdownMenu, DropdownItem, Dropdown} from 'element-ui'
  export default {
    name: 'cni-project-status',
    components: {
      BaseProgress,
      [Table.name]: Table,
      [TableColumn.name]: TableColumn,
      [Dropdown.name]: Dropdown,
      [DropdownItem.name]: DropdownItem,
      [DropdownMenu.name]: DropdownMenu,
    },
    data() {
      return {
        tableData: [
          {
            project: 'DNATA (45AC & 50AC)',
            capacity: 3.7,
            target_cod: '30/10/21',
            forecast_cod: '15/08/21',
            days: 76,
            impact: 0,
            progressType: 'bg-success',
          },
          {
            project: 'Galmon (BLK 52)',
            capacity: 0.2,
            target_cod: '30/09/21',
            forecast_cod: '09/09/ 21',
            days: 21,
            impact: '1,242',
            progressType: 'bg-success',
          },
          {
            project: 'Givaudan',
            capacity: 0.9,
            target_cod: '30/09/21',
            forecast_cod: '09/09/21',
            days: 0,
            impact: 0,
            progressType: 'bg-success',
          },
          {
            project: 'JTC SL3 (Tuas South-TBL)',
            capacity: 16.2,
            target_cod: '29/10/21',
            forecast_cod: '29/10/21',
            days: 0,
            impact: 0,
            progressType: 'bg-success',
          },
          // {
          //   project: 'JTC SL3 (Tuas West-TWD)',
          //   capacity: 1.5,
          //   target_cod: '29/10/21',
          //   forecast_cod: '29/10/21',
          //   days: 0,
          //   impact: 0,
          //   progressType: 'bg-success',
          // },
          // {
          //   project: 'JTC SR2',
          //   capacity: 16.8,
          //   target_cod: '31/08/21',
          //   forecast_cod: '01/01/21',
          //   days: -123,
          //   impact: 0,
          //   progressType: 'bg-danger',
          // },
          // {
          //   project: 'JURONG ISLAND (COGEN,SUT,EFW)',
          //   capacity: 0.5,
          //   target_cod: '30/10/21',
          //   forecast_cod: '30/01/21',
          //   days: 0,
          //   impact: 0,
          //   progressType: 'bg-sucess',
          // },
          {
            project: 'LF (5 Tuas View Lane)',
            capacity: 1.9,
            target_cod: '30/09/21',
            forecast_cod: '30/09/21',
            days: 0,
            impact: 0,
            progressType: 'bg-sucess',
          },
          // {
          //   project: 'MAPLETREE (25 Pandan Cresent)',
          //   capacity: 0.4,
          //   target_cod: '11/08/2021',
          //   forecast_cod: '11/08/2021',
          //   days: 0,
          //   impact: 0,
          //   progressType: 'bg-sucess',
          // },
          {
            project: 'Micron F10W (Carpark C)',
            capacity: 0.2,
            target_cod: '30/04/22',
            forecast_cod: '30/04/22',
            days: 0,
            impact: 0,
            progressType: 'bg-success',
          },
          {
            project: 'Prima Evolue',
            capacity: 3.2,
            target_cod: '22/10/21',
            forecast_cod: '22/10/21',
            days: 0,
            impact: 0,
            progressType: 'bg-success',
          },
          // {
          //   project: 'SATs (AFT 1-4)',
          //   capacity: 5.7,
          //   target_cod: '15/09/2021',
          //   forecast_cod: '15/09/2021',
          //   days: 0,
          //   impact: 0,
          //   progressType: 'bg-sucess',
          // },
          // {
          //   project: 'SATs (ICC1 & ICC2)',
          //   capacity: 1.0,
          //   target_cod: '30/09/2021',
          //   forecast_cod: '30/09/2021',
          //   days: 0,
          //   impact: 0,
          //   progressType: 'bg-sucess',
          // },
          // {
          //   project: 'SATs (PVT)',
          //   capacity: 0.0,
          //   target_cod: '30/08/2021',
          //   forecast_cod: '30/08/2021',
          //   days: 0,
          //   impact: 0,
          //   progressType: 'bg-sucess',
          // },
        ]
      }
    }

  }
</script>
<style>
</style>
