<template>
  <section>
    <div class="content">
      <base-header class="pb-6">
        <b-row align-v="center" class="py-4">
          <b-col cols="7" lg="6">
            <h6 class="h2 text-white d-inline-block mb-0">
              Project
            </h6>
            <nav
              aria-label="breadcrumb"
              class="d-none d-md-inline-block ml-md-4"
            >
            </nav>
          </b-col>
        </b-row>
      </base-header>
      <b-container fluid class="mt--6">
        <div>
          <card
            class="card-border-diagonal p-0"
            style="border: 1px solid black;"
          >
            <b-row class="my--4">
              <b-col md="2" class="b-col-centered">
                <div class="scroll-follower">
                    <base-button size="md" class="p-2 m-2 button-css" @click="redirectToDashboardV3">Dashboard</base-button><br>
                    <base-button size="md" class="p-2 m-2 button-css" @click="scrollToProfile">Project Profile</base-button><br>
                    <base-button v-if="!isSolarNovaProject || !isUtilitiesProject" size="md" class="p-2 m-2 button-css" @click="scrollToSite">Site</base-button><br>
                    <base-button v-if="isSolarNovaProject && !isUtilitiesProject" size="md" class="p-2 m-2 button-css" @click="scrollToGroup">Town Council</base-button><br>
                    <base-button v-if="model.project_tag == 'legacy'" size="md" class="p-2 m-2 button-css" @click="redirectToOldDashboard">Legacy Dashboard</base-button>
                    <base-button v-if="button_access.project_delete == 'YES' && myUserID.role_id == 3" size="md" class="p-2 m-2 button-css" style="color: #C00000;" @click="showDeleteProjectModal">Delete Project</base-button>
                </div>
              </b-col>

              <b-col md="8" class="bg-custom">
                <div id="project-profile">
                    <h3 class="mt-4">Project Profile</h3>
                    <card class="card-border-inner bg-transparent form-custom-css">
                    <b-row>
                        <b-col>
                            <div class="form-row">
                                <b-col md="12">
                                    <!-- <base-input label="Project Name"
                                                name="Project Name"
                                                v-model="model.project_name"
                                                disabled>
                                    </base-input> -->
                                    <p class="form-label">Project Name</p>
                                    <p class="form-content">{{ model.project_name }}</p>
                                </b-col>
                            </div>

                            <div class="form-row">
                                <b-col md="12">
                                    <!-- <base-input
                                        label="Developer"
                                        name="Developer"
                                        v-model="model.developer_id"
                                    >
                                        <select class="form-control" v-model="model.developer_id" disabled>
                                        <option Selected disabled value="x">
                                            -- Please Select A Developer --
                                        </option>
                                        <option
                                            v-for="item in developers"
                                            :key="item.developer_id"
                                            v-bind:value="item.developer_id"
                                        >
                                            {{ item.developer_code }} - {{ item.developer_name }}
                                        </option>
                                        </select>
                                    </base-input> -->
                                    <p class="form-label">Developer</p>
                                    <p class="form-content">{{ model.developer_code }}</p>
                                </b-col>
                            </div>                     

                            <div class="form-row">
                                <b-col md="12">
                                    <!-- <base-input
                                        label="Project Type"
                                        name="Project Type"
                                        v-model="model.project_type_id"
                                        >
                                        <select class="form-control" v-model="model.project_type_id" disabled>
                                        <option Selected disabled value="x">
                                            -- Please Select A Type --
                                        </option>
                                        <option
                                            v-for="item in project_type"
                                            :key="item.project_type_id"
                                            v-bind:value="item.project_type_id"
                                        >
                                            {{ item.project_type }}
                                        </option>
                                        </select>
                                    </base-input> -->
                                    <p class="form-label">Project Type</p>
                                    <p class="form-content">{{ model.project_type_name }}</p>
                                </b-col>
                            </div>

                            <div class="form-row">
                                <b-col md="12">
                                    <base-input label="PPA Counter Party"
                                                name="PPA Counter Party"
                                                v-model="model.project_ppa_counter_party">
                                    </base-input>
                                    <!-- <p class="form-label">PPA Counter Party</p>
                                    <p class="form-content">{{ model.project_ppa_counter_party }}</p> -->
                                </b-col>
                            </div>

                            <div class="form-row">
                              <b-col md="12">
                                  <base-input label="Type of Industry"
                                              name="Type of Industry"
                                              v-model="model.project_industry_category_id">
                                      <select class="form-control" v-model="model.project_industry_category_id">
                                      <option Selected disabled value="x">-- Please Select A Type of Industry --</option>
                                      <option v-for="item in industry_category" :key="item.id" v-bind:value="item.id">{{ item.name }}</option>
                                      </select>
                                  </base-input>
                              </b-col>
                            </div>

                            <div class="form-row">
                                <b-col md="12">
                                    <base-input label="PPA Sign Date"
                                                name="PPA Sign Date"
                                                type="date"
                                                v-model="model.project_ppa_start_date">
                                    </base-input>
                                </b-col>
                            </div>

                            <div class="form-row">
                                <b-col md="12">
                                    <base-input label="PPA Total Capacity kWp"
                                                name="PPA Total Capacity kWp"
                                                v-model="model.project_ppa_approved_capacity">
                                    </base-input>
                                </b-col>
                            </div>

                            <div class="form-row">
                                <b-col md="12">
                                    <base-input label="Watt Class"
                                                name="Watt Class"
                                                v-model="model.project_watt_class">
                                    </base-input>
                                </b-col>
                            </div>
                        </b-col>
                    </b-row>

                    <b-row>
                        <b-col md="10" class="text-right">
                            <!-- save project data and key contacts -->
                        </b-col>
                        <b-col md="2">                                
                            <base-button size="md" class="p-1 m-2" style="background-color: #375623; width: 100%" @click="createData()">Save</base-button>
                        </b-col>
                    </b-row>                   
                    </card>
                </div>

                <div id="site" v-if="!isSolarNovaProject && !isUtilitiesProject">
                    <h3>Site</h3>
                    <card class="card-border-inner bg-transparent">
                        <b-row>
                            <b-col md="12">
                                <b-table
                                sticky-header="1000px"
                                striped
                                hover
                                :items="siteListingData"
                                :fields="columnSites"
                                class="pointer-cursor"
                                @row-clicked="rowClicked">
                                </b-table>
                            </b-col>
                        </b-row>
                    </card>
                </div>

              <div id="sites" v-if="isUtilitiesProject">
                  <h3>Site</h3>
                  <card class="card-border-inner bg-transparent">
                      <b-row>
                          <b-col md="12">
                              <b-table
                                      sticky-header="1000px"
                                      striped
                                      hover
                                      :items="siteListingData"
                                      :fields="columnSites"
                                      class="pointer-cursor"
                                      @row-clicked="utitlitiesRowClicked">
                              </b-table>
                          </b-col>
                      </b-row>
                  </card>
              </div>

                <div id="group" v-if="isSolarNovaProject">
                    <h3>Town Council</h3>
                    <card class="card-border-inner bg-transparent">
                        <b-row>
                            <b-col md="12">
                                <b-table
                                sticky-header="1000px"
                                striped
                                hover
                                class="pointer-cursor"
                                :items="groupListingData"
                                :fields="columnGroups"
                                @row-clicked="rowClickedGroup">
                                </b-table>
                            </b-col>
                        </b-row>
                    </card>
                </div>
              </b-col>

              <b-col md="2">
                <!-- empty for side key contents -->
                <div class="scroll-follower-right form-custom-css-key-contacts">
                     <!-- <card class="bg-transparent form-custom-css"> -->
                        <h2 class="h2-key-contacts">Key Contacts</h2>
                        <br>
                        <div class="form-row">
                            <b-col md="12">
                                <base-input label="Project Engineer"
                                            name="Project Engineer"
                                            v-model="model.project_manager">
                                    <select class="form-control" v-model="model.project_manager">
                                    <option Selected disabled value="x">-- Please Select A Project Engineer --</option>
                                    <option v-for="item in project_managers" :key="item.id" v-bind:value="item.id">{{ item.name }}</option>
                                    </select>
                                </base-input>
                            </b-col>

                            <b-col md="12">
                                <base-input label="Engineer"
                                            name="Engineer"
                                            v-model="model.project_engineer">
                                    <select class="form-control" v-model="model.project_engineer">
                                    <option Selected disabled value="x">-- Please Select A Engineer --</option>
                                    <option v-for="item in project_engineers" :key="item.id" v-bind:value="item.id">{{ item.name }}</option>
                                    </select>
                                </base-input>
                            </b-col>

                            <b-col md="12">
                                <base-input label="Business Development"
                                            name="Business Development"
                                            v-model="model.project_business_development">
                                    <select class="form-control" v-model="model.project_business_development">
                                    <option Selected disabled value="x">-- Please Select A Business Development User --</option>
                                    <option v-for="item in project_business_development" :key="item.id" v-bind:value="item.id">{{ item.name }}</option>
                                    </select>
                                </base-input>
                            </b-col>

                            <b-col md="12">
                                <base-input label="Safety Officer"
                                            name="Safety Office"
                                            v-model="model.project_safety">
                                    <select class="form-control" v-model="model.project_safety">
                                    <option Selected disabled value="x">-- Please Select A Safety Officer --</option>
                                    <option v-for="item in project_safety" :key="item.id" v-bind:value="item.id">{{ item.name }}</option>
                                    </select>
                                </base-input>
                            </b-col>
                        </div>
                    <!-- </card> -->
                </div>
              </b-col>
            </b-row>
          </card>
        </div>
      </b-container
      >

      <modal
        :show.sync="isDeleteProjectModalVisible"
        size="xl"
        body-classes="p-0"
        title="Delete Project"
      >
        <card
          type="secondary"
          header-classes="bg-transparent pb-5"
          body-classes="px-lg-5 py-lg-5"
          class="border-0 mb-0"
        >
          <template>
            <div class="text-center text-muted mb-4">
              <h1>Delete Project</h1>
            </div>
            <div class="text-center">
              <div>
                <br />Are you sure you want to permanently delete this project?
              </div>
              <div>
                <base-button
                  type="danger"
                  class="my-4"
                  @click="showDeleteProjectModalInner"
                  >Delete</base-button
                >
                <base-button
                  type="primary"
                  class="my-4"
                  @click="closeDeleteProjectModal"
                  >Cancel</base-button
                >
              </div>
            </div>
          </template>
        </card>
      </modal>

      <modal
        :show.sync="isDeleteProjectModalVisibleInner"
        size="xl"
        body-classes="p-0"
        title="Confirm Delete Project"
      >
        <card
          type="secondary"
          header-classes="bg-transparent pb-5"
          body-classes="px-lg-5 py-lg-5"
          class="border-0 mb-0"
        >
          <template>
            <div class="text-center text-muted mb-4">
              <h1>Confirm Delete Project</h1>
            </div>
            <div class="text-center">
              <div>
                <br />Are you REALLY sure you want to permanently delete this
                project?
              </div>
              <div>
                <base-button
                  type="primary"
                  class="my-4"
                  @click="closeDeleteProjectModalInner"
                  >Cancel</base-button
                >
                <base-button type="danger" class="my-4" @click="deleteProject"
                  >Delete</base-button
                >
              </div>
            </div>
          </template>
        </card>
      </modal>

    </div>
  </section>
</template>
<script>
import {
  LoginURL,
  clientId,
  clientSecret,
  apiDomain,
  getHeader,
  getcurrentDate,
} from "@/assets/js/config.js";
import axios from "axios";
import ErrorFunctionMixin from "@/assets/js/errorFunctionMix.js";

export default {
  mixins: [ErrorFunctionMixin],
  components: {
  },
  created() {
    var project_access = JSON.parse(
      window.localStorage.getItem("project_access")
    );
    this.button_access.project_delete = project_access.access_delete;

    const LoginUser = JSON.parse(window.localStorage.getItem("loginUser"));
    this.myUserID = LoginUser;

    this.getDeveloperListing();
    this.getProjectTypeListing();
    this.getProjectManagerListing();
    this.getProjectEngineerListing();
    this.getBusinessDevelopmentListing();
    this.getSafetyOfficerListing();
    this.getProjectSiteStatusListing();
    this.getIndustryCategoryListing();
    this.getDashboardData();
    // this.getSiteListing();
  },
  data() {
    return {
      button_access: {
        project_delete: null,
      },
      model: {
          project_id: null,
          developer_id: "x",
          developer_code: null,
          developer_name: null,
          project_type_id: "x",
          project_type_name: null,
          project_manager: "x",
          project_engineer: "x",
          project_business_development: "x",
          project_safety: "x",
          project_name: null,
          project_ppa_counter_party: null,
          project_ppa_start_date: null,
          project_ppa_approved_capacity: null,
          project_watt_class: null,
          project_tag: null,
          project_industry_category_id: "x",
        },
        developers: [],
        project_type: [],
        project_managers: [],
        project_engineers: [],
        project_business_development: [],
        project_safety: [],
        site_status: [],
        industry_category: [],
        siteListingData: [],
        columnSites: [
          {
            key: "site_name",
            label: "Site Name",
            thClass: "max-width-column-header",
            tdClass: "max-width-column-data",
          },
          {
            key: "site_address",
            label: "Address",
          },
          {
            key: "site_postal_code",
            label: "Postal Code",
          },
          {
            key: "contractor_code",
            label: "EPC",
          },
          {
            key: "site_total_capacity",
            label: "Capacity (kWp)",
            formatter: (value) => {
              if (typeof value === "string") {
                return parseFloat(value.replace(/,/g, ""));
              } else {
                return value;
              }
            },
          },
          {
            key: "status.status_id",
            label: "Status",
            formatter: (value) => {
              const project_site_status = this.site_status.find(item => item.status_id == value);
              return project_site_status ? project_site_status.status_code : null;
            },
          },
        ],
        groupListingData: [],
        columnGroups: [
          {
            key: "group_name",
            label: "Town Council Name",
            thClass: "max-width-column-header",
            tdClass: "max-width-column-data",
          },
          {
            key: "group_engineer.name",
            label: "Engineer",
          },
        ],
        isDeleteProjectModalVisible: false,
        isDeleteProjectModalVisibleInner: false,
        isSolarNovaProject: false,
        isUtilitiesProject:false,
    };
  },
  methods: {
    redirectToDashboardV3(){
        this.$router.push({path: '/projectDashboardV3/' + this.model.project_id})
    },
    redirectToOldDashboard() {
      this.$router.push({path: '/projectDashboard/' + this.model.project_id})
    },
    scrollToProfile() {
      const profileSection = document.getElementById("project-profile");
      profileSection.scrollIntoView({ behavior: "smooth" });
    },
    scrollToSite() {
      const siteSection = document.getElementById("site");
      siteSection.scrollIntoView({ behavior: "smooth" });
    },
    scrollToGroup() {
      const siteSection = document.getElementById("group");
      siteSection.scrollIntoView({ behavior: "smooth" });
    },
    getDeveloperListing() {
      axios
      .get(apiDomain + "setup/developer", { headers: getHeader() })
      .then((response) => {
        if (response.status === 200) {
          this.developers = response.data.data;
          // default to sembcorp solar || developer_id = 1
          // this.model.developer_id = 1;
        }
      })
      .catch((error) => {
        console.log(error.response.status);
        this.errorFunction(error, "Developer Information");
      });
    },
    getProjectTypeListing() {
      axios
      .get(apiDomain + "setup/project_type", { headers: getHeader() })
      .then((response) => {
        if (response.status === 200) {
          this.project_type = response.data.data;
        }
      })
      .catch((error) => {
        console.log(error.response.status);
        this.errorFunction(error, "Project Type Information");
      });
    },
    getProjectManagerListing() {
        axios.get(apiDomain + 'lookup/user_list/Project_Manager', { headers: getHeader() })
        .then ( response => {
            if (response.status === 200)
            {
            this.project_managers = response.data.data
            }
        })
        .catch ( error => {
            console.log(error.response.status)
            this.errorFunction(error, "Project Manager")
        } );      
    },
    getProjectEngineerListing() {
        axios.get(apiDomain + 'lookup/user_list/Engineer', { headers: getHeader() })
        .then ( response => {
            if (response.status === 200)
            {
            this.project_engineers = response.data.data
            }
        })
        .catch ( error => {
            console.log(error.response.status)
            this.errorFunction(error, "Engineer Information")
        } );
    },
    getBusinessDevelopmentListing() {
      axios.get(apiDomain + 'lookup/user_list/Business_Development', { headers: getHeader() })
        .then ( response => {
            if (response.status === 200)
            {
            this.project_business_development = response.data.data
            }
        })
        .catch ( error => {
            console.log(error.response.status)
            this.errorFunction(error, "Safety Officer Information")
        } );
    },
    getSafetyOfficerListing() {
        axios.get(apiDomain + 'lookup/user_list/Safety', { headers: getHeader() })
        .then ( response => {
            if (response.status === 200)
            {
            this.project_safety = response.data.data
            }
        })
        .catch ( error => {
            console.log(error.response.status)
            this.errorFunction(error, "Safety Officer Information")
        } );
    },
    getProjectSiteStatusListing() {
      // axios.get(apiDomain + 'lookup/site_status', { headers: getHeader() })
      axios.get(apiDomain + 'lookup/site_status.singapore_v2', { headers: getHeader() })
      .then ( response => {
        if (response.status === 200)
        {
          this.site_status = response.data.data
        }
      })
      .catch ( error => {
        console.log(error.response.status)
        this.errorFunction(error, "Site Status Information")
      } );
    },
    getIndustryCategoryListing() {
      axios.get(apiDomain + 'lookup/industry_category', { headers: getHeader() })
      .then ( response => {
        if (response.status === 200)
        {
          this.industry_category = response.data.data
        }
      })
      .catch ( error => {
        console.log(error.response.status)
        this.errorFunction(error, "Industry Category Information")
      } );    
    },
    getDashboardData() {
      this.model.project_id = this.$route.params.id;
      axios
        .get(apiDomain + "project/project/" + this.model.project_id, {
            headers: getHeader(),
        })
        .then((response) => {
            if (response.status === 200) {
            this.model.project_name = response.data.data[0].project_name;
            this.model.developer_id = response.data.data[0].developer.developer_id;
            this.model.developer_code = response.data.data[0].developer.developer_code;
            this.model.developer_name = response.data.data[0].developer.developer_name;
            this.model.project_type_id = response.data.data[0].project_type.project_type_id;
            if ( this.model.project_type_id != null &&
                ([2, 3, 4, 9, 10, 11, 12, 13, 14].includes(this.model.project_type_id))
                ) {
              this.isSolarNovaProject = true;
              this.getGroupListing();
            } else if( this.model.project_type_id != null && this.model.project_type_id  == 17)
            {
                this.isUtilitiesProject = true;
                this.getSiteListing();
            }else {
              this.isSolarNovaProject = false;
              this.getSiteListing();
            }
            this.model.project_type_name = response.data.data[0].project_type.project_type;
            this.model.project_ppa_counter_party = response.data.data[0].project_ppa_counter_party;
            this.model.project_ppa_start_date = response.data.data[0].project_ppa_start_date;
            this.model.project_ppa_approved_capacity = parseFloat(response.data.data[0].project_ppa_approved_capacity.replace(/,/g, ""));
            // this.model.project_watt_class = parseFloat(response.data.data[0].project_watt_class.replace(/,/g, ""));
            if (response.data.data[0].project_watt_class !== null) {
              this.model.project_watt_class = parseFloat(response.data.data[0].project_watt_class.replace(/,/g, ""));
            }
            this.model.project_manager = response.data.data[0].project_manager.id;
            this.model.project_engineer = response.data.data[0].project_engineer.id;
            this.model.project_business_development = response.data.data[0].project_business_development.id;
            this.model.project_safety = response.data.data[0].project_safety.id;
            this.model.project_tag = response.data.data[0].project_tag;
            this.model.project_industry_category_id = response.data.data[0].project_industry_category_id;
            }
        })
        .catch((error) => {
            // console.log(error.response.status);
            // if (error.response.status == 401) {
            // this.$router.push("/login");
            // }
            if (typeof error.response !== "undefined") {
              this.errorFunction(error, "Project Information");
            } else {
              console.log(error);
            }            
        });
    },
    getSiteListing() {
        axios.get(apiDomain + 'project/project_site.show_by_project/' + this.model.project_id, { headers: getHeader() })
          .then ( response => {
            if (response.status === 200)
            {
                this.siteListingData = response.data.data;
                this.siteListingData.forEach(item => {
                    this.getContractorFromSite(item.site_id);
                });
            }
          })
          .catch ( error => {
              console.log(error.response.status)
          } );
    },
    // groupListingData
    getGroupListing() {
        axios.get(apiDomain + 'project/project_group.show_by_project/' + this.model.project_id, { headers: getHeader() })
          .then ( response => {
            if (response.status === 200)
            {
                this.groupListingData = response.data.data;
                // this.groupListingData.forEach(item => {
                //     this.getContractorFromSite(item.site_id);
                // });
            }
          })
          .catch ( error => {
              console.log(error.response.status)
          } );
    },
    getContractorFromSite(project_site_id)
    {
      axios.get(apiDomain + 'project/site_contractor.show_by_site/' + project_site_id + '?client=mobile', { headers: getHeader() })
      .then ( response => {
        if (response.status === 200)
        {
            this.siteListingData.map(item => {
                if (item.site_id === project_site_id) {
                    item['contractor_code'] = response.data.data[0].contractor.contractor_code;
                }
            });
            this.siteListingData = [...this.siteListingData];            
        }
      })
      .catch ( error => {
        console.log(error.response.status)
        this.errorFunction(error, "Site Contractor Information")
      } );
    },
    createData() {
        axios.put(apiDomain + 'project/project/'+ this.model.project_id, this.model, { headers: getHeader() })
        .then ( response => {
            if (response.status === 200)
            {
              this.$notify({
              message:
                '<b>Update Project : ' + this.model.project_name + '</b> - Successfully Updated.',
              timeout: 10000,
              icon: 'ni ni-bell-55',
              type: 'default',
              });
            //   this.$router.back()
            this.getDashboardData();
            }
        })
        .catch ( error => {
            console.log(error.response.data.errors)
            this.errorFunction(error, "Project Information")
        });
    },
    rowClicked(row) {
        this.$router.push({
          path: "/projectSiteDashboardV2/" + row.site_id,
        });
    },
    utitlitiesRowClicked(row){
        this.$router.push({
            path: "/projectSiteDashboardUtilities/" + row.site_id,
        });
    },
    // rowClickedGroup
    rowClickedGroup(row) {
        this.$router.push({
          path: "/projectSiteListingByGroup/" + row.group_id,
        });
    },
    showDeleteProjectModal() {
      this.isDeleteProjectModalVisible = true;
    },
    closeDeleteProjectModal() {
      this.isDeleteProjectModalVisible = false;
    },
    showDeleteProjectModalInner() {
      this.isDeleteProjectModalVisible = false;
      this.isDeleteProjectModalVisibleInner = true;
    },
    closeDeleteProjectModalInner() {
      this.isDeleteProjectModalVisibleInner = false;
    },
    deleteProject() {
      const empty_request = {
          empty_request: null
      };

      axios
        .post(apiDomain + "project/delete_project_v2/" + this.model.project_id, empty_request, {
          headers: getHeader(),
        })
        .then((response) => {
          if (response.status === 200) {
            this.$notify({
              message:
                "<b>Project: " +
                this.model.project_name +
                "</b> - Successfully Deleted.",
              timeout: 10000,
              icon: "ni ni-bell-55",
              type: "default",
            });
            this.redirectToProjectListing();
          }
        })
        .catch((error) => {
          console.log(error);
          this.$notify({
              message:
                error.response.data.error,
              timeout: 10000,
              icon: "ni ni-bell-55",
              type: "danger",
            });
        });
    },
    redirectToProjectListing() {
      this.$router.push({
        path: "/projectListingCNI",
      });
    },
  },
};
</script>
<style>

.bg-custom {
  background-color: #EFF7EA;
}

.card-border-diagonal {
  /* border: 2px solid #BDDBA9; */
  border-radius: 100px 0 100px 0;
  /* margin: 20px; */
  padding: 10px;
  /* max-width: 300px; */
}

.card-border-inner {
  border: 1px solid #ADB5BD;
  border-radius: 50px 50px 50px 50px;
  /* margin: 20px; */
  padding: 10px;
  /* max-width: 300px; */
}

.form-custom-css .form-control:disabled {
  border: none !important;
  font-weight: bold;
  background-color: transparent !important;
  appearance: none !important;
  -webkit-appearance: none;
  background-image: none !important;
}

.form-custom-css .form-control {
  border: 2px solid #BDDBA9 !important;
  border-radius: 0 !important;
  color: black !important;
}

.form-custom-css .form-control-label {
  color: #525f7f !important;
  font-size: .875rem !important;
  font-weight: 600 !important;
}

.form-custom-css-key-contacts .form-control {
  border: 2px solid #BDDBA9 !important;
  border-radius: 0 !important;
  color: black !important;
}

.form-custom-css-key-contacts .form-control-label {
  font-size: 16px !important;
  font-weight: 600 !important;
  color: black !important;
  margin-bottom: 5px !important;
}

.form-label {
  color: #525f7f !important;
  font-size: 14px !important;
  font-weight: 600 !important;
  margin-bottom: 5px !important;
}

.form-content {
  font-size: 16px !important;
  font-weight: bold !important;
  color: black !important;
  margin-top: 5px !important;
}

.max-width-column-header {
  width: 50%;
}

.max-width-column-data {
  width: 50%;
  white-space: normal !important;
}

.b-col-centered {
  display: flex;
  justify-content: center;
}

.scroll-follower{
  position: fixed;
  transform: translateY(20%);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.scroll-follower .base-button {
  width: 100%;
  text-align: center;
}

.scroll-follower-right{
  position: fixed;
  width: 13%;
  transform: translateY(5%);
  /* display: flex; */
  /* flex-direction: column; */
  /* align-items: center; */
}

.h2-key-contacts {
  font-weight: 700 !important;
  color: black !important;
}

.button-css {
  border-radius: 10px;
  background: transparent;
  color: #3E5C2B;
}

.pointer-cursor tbody tr:hover {
    cursor: pointer;
}
</style>
