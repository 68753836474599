<template>
  <div class="content">
    <base-header class="pb-6">
      <b-row align-v="center" class="py-4">
        <b-col cols="7" lg="6">
          <h6 class="h2 text-white d-inline-block mb-0">Site Contractor Listing</h6>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <route-bread-crumb></route-bread-crumb>
          </nav>
        </b-col>
        <b-col lg="6" cols="5" class="text-right">
          <base-button size="sm" type="neutral" @click="newContractor()">New Contractor</base-button>
          <base-button size="sm" type="neutral" @click="$router.back()">Back</base-button>
        </b-col>
      </b-row>
    </base-header>
    <b-container fluid class="mt--6">
      <div>
        <card class="no-border-card" body-classes="px-0 pb-1" footer-classes="pb-2">
          <template slot="header">
            <h3 class="mb-0">Site Contractor Listing for ({{ sites.site_name }})</h3>

          </template>
          <div>
            <b-col cols="12" class="d-flex justify-content-center justify-content-sm-between flex-wrap">
              <el-select
                class="select-primary pagination-select"
                v-model="pagination.perPage"
                placeholder="Per page">
                <el-option
                  class="select-primary"
                  v-for="item in pagination.perPageOptions"
                  :key="item"
                  :label="item"
                  :value="item">
                </el-option>
              </el-select>

              <div>

              </div>
            </b-col>
            <b-col><br></b-col>
            <el-table :data="queriedData"
                      row-key="site_contractor_id"
                      header-row-class-name="thead-light"
                      @sort-change="sortChange">
              <el-table-column label="Project"
                             min-width="150px"
                             prop="project_name"
                             sortable>
                <template v-slot="{row}">
                    <b-media no-body class="align-items-center">
                        <b-media-body>
                            <span class="font-weight-600 name mb-0 text-sm">{{row.project.project_name}}</span>
                        </b-media-body>
                    </b-media>
                </template>
              </el-table-column>

              <el-table-column label="Group"
                             min-width="150px"
                             prop="group_name"
                             sortable>
                <template v-slot="{row}">
                    <b-media no-body class="align-items-center">
                        <b-media-body>
                            <span class="font-weight-600 name mb-0 text-sm">{{row.group.group_name}}</span>
                        </b-media-body>
                    </b-media>
                </template>
              </el-table-column>

              <el-table-column label="Site"
                             min-width="150px"
                             prop="site_name"
                             sortable>
                <template v-slot="{row}">
                    <b-media no-body class="align-items-center">
                        <b-media-body>
                            <span class="font-weight-600 name mb-0 text-sm">{{row.site.site_name}}</span>
                        </b-media-body>
                    </b-media>
                </template>
              </el-table-column>

              <el-table-column label="Contractor"
                             min-width="150px"
                             prop="contractor_code"
                             sortable>
                <template v-slot="{row}">
                    <b-media no-body class="align-items-center">
                        <b-media-body>
                            <span class="font-weight-600 name mb-0 text-sm">{{row.contractor.contractor_code}}</span>
                        </b-media-body>
                    </b-media>
                </template>
              </el-table-column>

              <el-table-column label="Status"
                             min-width="160px"
                             prop="status"
                             sortable>
                <template v-slot="{row}">
                    <b-media no-body class="align-items-center">
                        <b-media-body>
                            <badge class="badge-dot mr-4" type="">
                              <i v-if="row.active_status == 'SUSPENDED'" class="bg-danger"></i>
                              <i v-else class="bg-success"></i>
                              <span class="font-weight-600 name mb-0 text-sm">{{row.active_status}}</span>
                            </badge>
                        </b-media-body>
                    </b-media>
                </template>
              </el-table-column>

              <el-table-column min-width="150px" align="right" label="Actions">
                <div slot-scope="{$index, row}" class="d-flex">
                  <base-button
                    @click.native="updateContractor($index, row)" size="sm" type="primary">
                    Active / Suspend
                  </base-button>
                </div>
              </el-table-column>
            </el-table>
          </div>
          <div
            slot="footer"
            class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap">
            <div class="">
              <p class="card-category">
                Showing {{ from + 1 }} to {{ to }} of {{ total }} entries
                <span v-if="selectedRows.length">
                  &nbsp; &nbsp; {{selectedRows.length}} rows selected
                </span>
              </p>
            </div>
            <base-pagination
              class="pagination-no-border"
              :current="pagination.currentPage"
              :per-page="pagination.perPage"
              :total="total"
              @change="paginationChanged($event)">
            </base-pagination>
             <!-- <div>
              <h5 class="text-danger"> Note :
                  <i>
                  Please Create at least ONE Project Template & ONE Project Template Details before you can proceed POPULATE / IMPORT to prevent any Error. Go to NEW Template Then "Detail Listing" >> "New Template Details".
                  </i>
              </h5>
            </div> -->
          </div>
        </card>
      </div>
    </b-container>
    </div>
</template>
<script>
import { Table, TableColumn, Select, Option } from 'element-ui';
import RouteBreadCrumb from '@/components/Breadcrumb/RouteBreadcrumb'
import { BasePagination } from '@/components';
import clientPaginationMixin from './js/SiteContractorPaginationMix'
import swal from 'sweetalert2';
import axios from 'axios'
import {LoginURL, clientId, clientSecret, apiDomain, getHeader} from '@/assets/js/config.js'
import ErrorFunctionMixin from '@/assets/js/errorFunctionMix.js'

export default {
  mixins: [clientPaginationMixin, ErrorFunctionMixin],
  components: {
    BasePagination,
    RouteBreadCrumb,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn
  },
  created()
  {
      this.getData()
  },
  data() {
    return {
      model:{
        site_contractor_id: null,
        site_id: null,
        contractor_id: null,
        active_status: null,
      },
      sites:{
            site_id: null,
            site_name: null,
            site_name: null,
      },
      propsToSearch: ['project_name', 'project_template_name', ],
      tableColumns: [],
      tableData: [],
      selectedRows: [],
    };
  },
  methods: {
    getData()
    {
        this.sites.site_id = this.$route.params.id

        axios.get(apiDomain + 'project/project_site/' + this.sites.site_id, { headers: getHeader() })
        .then ( response => {
          if (response.status === 200)
          {
              this.sites.site_id   = response.data.data[0].site_id
              this.sites.site_name = response.data.data[0].site_name
              this.sites.site_name = response.data.data[0].site_name
          }
        })
        .catch ( error => {
            console.log(error.response.status)
            this.errorFunction(error, "Project Site Information")
        } );

        axios.get(apiDomain + 'project/site_contractor.show_by_site/' + this.sites.site_id, { headers: getHeader() })
        .then ( response => {
          if (response.status === 200)
          {
              this.tableData = response.data.data
          }
        })
        .catch ( error => {
            console.log(error.response.status)
            this.errorFunction(error, "Site Contractor Information")
        } );
    },
    paginationChanged(page) {
      this.pagination.currentPage = page
    },
    updateContractor(index, row)
    {

        this.model.site_contractor_id = row.site_contractor_id
        this.model.site_id = row.site.site_id
        this.model.contractor_id = row.contractor.contractor_id

        if ( row.active_status == 'ACTIVE')
        {
          this.model.active_status = 0
        }
        else
        {
          this.model.active_status = 1
        }
        axios.put(apiDomain + 'project/site_contractor/' + row.site_contractor_id, this.model, { headers: getHeader() })
        .then ( response => {
          if (response.status === 200)
          {
              this.$notify({
              message:
                '<b>Site Contractor : </b> - Site Contractor successfully Updated.',
              timeout: 10000,
              icon: 'ni ni-bulb-61',
              type: 'default',
              });
              this.getData()
          }
        })
        .catch ( error => {
              this.$notify({
              message:
                '<b>Error : </b> - Site Contractor Update Error.',
              timeout: 10000,
              icon: 'ni ni-bulb-61',
              type: 'danger',
              });
              this.getData()
            console.log(error.response.status)
        } );
    },
    handleMilestone(index, row)
    {
      this.$router.push({path: 'projectMilestoneListing/' + row.project_id })
    },
    newContractor()
    {
      this.$router.push({path: '/siteContractorNew/' + this.sites.site_id })
    },
    handleEdit(index, row) {
      swal({
        title: `You want to edit ${row.name}`,
        buttonsStyling: false,
        confirmButtonClass: 'btn btn-info btn-fill'
      });
    },
    handleDelete(index, row) {
      swal({
        title: 'Are you sure?',
        text: `You won't be able to revert this!`,
        type: 'warning',
        showCancelButton: true,
        confirmButtonClass: 'btn btn-success btn-fill',
        cancelButtonClass: 'btn btn-danger btn-fill',
        confirmButtonText: 'Yes, delete it!',
        buttonsStyling: false
      }).then(result => {
        if (result.value) {
          this.deleteRow(row);
          swal({
            title: 'Deleted!',
            text: `You deleted ${row.name}`,
            type: 'success',
            confirmButtonClass: 'btn btn-success btn-fill',
            buttonsStyling: false
          });
        }
      });
    },
    deleteRow(row) {
      let indexToDelete = this.tableData.findIndex(
        tableRow => tableRow.id === row.id
      );
      if (indexToDelete >= 0) {
        this.tableData.splice(indexToDelete, 1);
      }
    },
    selectionChange(selectedRows) {
      this.selectedRows = selectedRows
    }
  }
};
</script>
<style>
  .no-border-card .card-footer{
    border-top: 0;
  }
</style>
