<template>
  <section>
    <div class="content">
      <base-header class="pb-6">
        <b-row align-v="center" class="py-4">
          <b-col cols="7" lg="6">
            <h6 class="h2 text-white d-inline-block mb-0">
              Project Requirements Management
            </h6>
            <nav
              aria-label="breadcrumb"
              class="d-none d-md-inline-block ml-md-4"
            >
              <!-- <route-bread-crumb></route-bread-crumb> -->
            </nav>
          </b-col>
        </b-row>
      </base-header>
      <b-container fluid class="mt--6">
        <div>
          <card
            class="card-border-diagonal p-0"
            style="border: 1px solid black;"
          >
          <!-- <card
            class="card-border"
            body-classes="px-0 pb-1"
            footer-classes="pb-2"
          > -->
          <!-- <card class="card-border form-custom-css"> -->
            <b-row class="my--4">
              <b-col md="2">
                <!-- empty for side "jump to div"
                <br><br><br><br><br><br><br><br><br><br> -->
              </b-col>

              <b-col md="8" class="bg-custom">
                <h3 class="mt-4">
                <!-- <h3 class="mb-0"> -->
                  Import PRM Records
                </h3>
                <card class="card-border-inner bg-transparent">
                  <b-row>
                    <b-col md="3">
                      Select the file to upload:
                    </b-col>

                    <!-- <b-col md="auto">
                      <input type="file" @change="onChange"/>
                    </b-col> -->
                    <b-col md="2">
                        <base-button size="md" class="p-1 m-2" style="background-color: #375623; width: 100%;" @click.prevent="$refs.file.click()">Select File</base-button>
                        <input
                            type="file"
                            ref="file"
                            style="display:none"
                            @change="onChange"
                        />
                    </b-col>
                  </b-row>

                  <b-row>
                    <b-col md="3">
                      <div v-if="!file">No file selected.</div>
                      <div v-else>
                        <base-input label="Sheet To Import"
                                    name="Sheet To Import"
                                    v-model="selected_sheet">
                          <select class="form-control" v-model="selected_sheet" @click="loadSheetNames" @change="updateRangeToSelect(); showUploadingData();">
                            <option Selected disabled value="x">-- Please Select A Sheet To Import --</option>
                            <option v-for="sheet in sheets" :key="sheet.sheetId" v-bind:value="sheet.name">{{ sheet.name }}</option>
                          </select>
                        </base-input>
                      </div>
                    </b-col>

                    <b-col md="2">
                      <base-button size="md" class="p-1 m-2" style="background-color: #375623; width: 100%;" @click="uploadFile" v-if="tempData"
                        >Upload File</base-button
                      >
                      <base-button size="md" class="p-1 m-2" style="background-color: #375623; width: 100%;" @click="uploadFile" v-else disabled
                        >Upload File</base-button
                      >
                    </b-col>
                  </b-row>
                </card>
              </b-col>

              <b-col md="2">
                <!-- empty for side key contents -->
              </b-col>
            </b-row>

            <div>
              <!-- <br> -->
              <!-- <b-table :items="tempData"></b-table> -->
              <!-- {{ $refs.excelJson.$data.collection }} -->
              <xlsx-read ref="excelRead" :file="file">
                <xlsx-json ref="excelJson" :sheet="selected_sheet" :options="{ range: selected_range, blankrows: false }">
                </xlsx-json>
              </xlsx-read>
            </div>

            <!-- <template slot="header">
              <h3 v-if="project_name == null" class="mb-0">
                Import New Project Site (Project)
              </h3>
              <h3 v-else class="mb-0">
                Import New Project Site for ({{ project_name }})
              </h3>
            </template> -->
            <!-- <div class="px-4">
              <b-row
                ><b-col
                  ><base-button class="mb-2" @click="uploadFile"
                    >Upload File</base-button
                  ></b-col
                >
                <b-col class="text-right">
                  <base-button size="sm" type="primary" @click="$router.back()"
                    >Back</base-button
                  >
                </b-col>
              </b-row>
              <b-row
                ><b-col><input type="file" @change="onChange" /> </b-col
              ></b-row>
              <b-row
                ><b-col
                  ><h5 class="text-danger" v-if="postResponse.length > 0">
                    Flags :<br />
                    <i
                      >&nbsp&nbsp&nbsp&nbsp1: town council does not exist<br />
                      &nbsp&nbsp&nbsp&nbsp2: contractor does not exist<br />
                      &nbsp&nbsp&nbsp&nbsp3: milestone template does not
                      exist<br />
                      &nbsp&nbsp&nbsp&nbsp4: postal code already exists<br
                    /></i>
                    Number of rows with error: {{ postResponse.length }}
                  </h5>
                  <b-table
                    sticky-header="540px"
                    striped
                    hover
                    :items="postResponse"
                  ></b-table>
                  <xlsx-read ref="excelRead" :file="file">
                    <xlsx-json ref="excelJson" :sheet="selectedSheet">
                    </xlsx-json> </xlsx-read></b-col
              ></b-row>
            </div> -->
          </card>
        </div>
      </b-container
      >
    </div>
  </section>
</template>
<script>
import XlsxRead from "../../../../node_modules/vue-xlsx/dist/components/XlsxRead";
import XlsxTable from "../../../../node_modules/vue-xlsx/dist/components/XlsxTable";
import XlsxJson from "../../../../node_modules/vue-xlsx/dist/components/XlsxJson";
import {
  LoginURL,
  clientId,
  clientSecret,
  apiDomain,
  getHeader,
  getcurrentDate,
} from "@/assets/js/config.js";
import axios from "axios";

export default {
  components: {
    XlsxRead,
    XlsxTable,
    XlsxJson,
  },
  created() {
    const LoginUser = JSON.parse(window.localStorage.getItem("loginUser"));
    this.myUserID = LoginUser;
  },
  data() {
    return {
      file: null,
      tempData: null,
      sheets: [],
      selected_sheet: 'x',
      excelReadData: null,
      selected_range: '',
      postResponse: null,
    };
  },
  methods: {
    onChange(event) {
      this.file = event.target.files ? event.target.files[0] : null;
    },
    loadSheetNames() {
      if (typeof(this.$refs.excelRead._workbook) !== "undefined") {
        this.sheets = this.$refs.excelRead._workbook.Workbook.Sheets;
      }
    },
    updateRangeToSelect () {
      console.log('updateRangeToSelect () {');
      if (typeof(this.$refs.excelRead._workbook) !== "undefined" && this.selected_sheet != 'x') {
        const original_range = this.$refs.excelRead._workbook.Sheets[this.selected_sheet]['!ref'];
        console.log('original_range', original_range);
        this.selected_range = 'A2:' + original_range.substring(3);
        this.selected_range = this.selected_range.replace(/:(.*?)(?=\d)/, ':AC');
        console.log('selected_range', this.selected_range);
      }
    },
    showUploadingData () {
      this.tempData = this.$refs.excelJson.$data.collection;
    },
    updateTempData() {
      // this.excelReadData = this.$refs.excelRead._workbook.Workbook.Sheets;
      // this.sheets = this.$refs.excelRead._workbook.Workbook.Sheets;
      this.tempData = this.$refs.excelJson.$data.collection;

      this.tempData = this.tempData.filter((item) => {
        const no = item["No."];
        return typeof no === "number" && !isNaN(no);
      });
    },

    uploadFile() {
      this.updateTempData();
      this.convertExcelDateTime();
      this.filenameWithTimestamp =
        Math.round(+new Date() / 1000).toString() + "_" + this.file.name;
      this.tempData.forEach((e) => {
        e["timestamped_filename"] = this.filenameWithTimestamp;
      });

      axios
        .post(
          apiDomain + "project/prm_import_from_upload.from_json",
          this.tempData,
          {
            headers: getHeader(),
          }
        )
        .then((response) => {
          if (response.status === 200) {
            this.postResponse = response.data.data;
            // this.$notify({
            //   message:
            //     "</b>Post Request Succeded<br/> Site Import Succeded If Table Does Not Show Up Below 'Choose File'",
            //   timeout: 10000,
            //   icon: "ni ni-bell-55",
            //   type: "default",
            // });

            this.$router.push({path: 'createProjectsFromPrm/'});
          }
        })
        .catch((error) => {
          this.$notify({
            message: "</b>Import New Site Failed",
            timeout: 10000,
            icon: "ni ni-bulb-61",
            type: "danger",
          });
          console.log(error.response.data.errors);
          this.errorFunction(error);
        });
    },














    excelDateToJSDate(serial) {
      var utc_days = Math.floor(serial - 25569);
      var utc_value = utc_days * 86400;
      var date_info = new Date(utc_value * 1000);

      var fractional_day = serial - Math.floor(serial) + 0.0000001;

      var total_seconds = Math.floor(86400 * fractional_day);

      var seconds = total_seconds % 60;

      total_seconds -= seconds;

      var hours = Math.floor(total_seconds / (60 * 60));
      var minutes = Math.floor(total_seconds / 60) % 60;

      return new Date(
        date_info.getFullYear(),
        date_info.getMonth(),
        date_info.getDate(),
        hours,
        minutes,
        seconds
      );
    },
    convertToDateOnly(inputDateTime) {
      const current = inputDateTime;
      const currentMonth = String(current.getMonth() + 1).padStart(2, "0");
      const currentDay = String(current.getDate()).padStart(2, "0");
      const currentDate =
        current.getFullYear() + "-" + currentMonth + "-" + currentDay;
      return currentDate;
    },
    convertExcelDateTime() {
      this.tempData.forEach((e) => {
        e["Entry Date"] = this.excelDateToJSDate(e["Entry Date"]);
        e["Entry Date"] = this.convertToDateOnly(e["Entry Date"]);

        e["PPA Sign Month"] = this.excelDateToJSDate(e["PPA Sign Month"]);
        e["PPA Sign Month"] = this.convertToDateOnly(e["PPA Sign Month"]);

        e["PPA Actual Signed Date"] = this.excelDateToJSDate(e["PPA Actual Signed Date"]);
        e["PPA Actual Signed Date"] = this.convertToDateOnly(e["PPA Actual Signed Date"]);

        e["Specified Site Handover Month"] = this.excelDateToJSDate(e["Specified Site Handover Month"]);
        e["Specified Site Handover Month"] = this.convertToDateOnly(e["Specified Site Handover Month"]);

        // var tempExcelDateEPC = e["EPC START DATE"];
        // var tempConvertedDateEPC = this.excelDateToJSDate(tempExcelDateEPC);
        // var convertedDateOnlyEPC = this.convertToDateOnly(tempConvertedDateEPC);
        // e["EPC START DATE"] = convertedDateOnlyEPC;
        // var tempExcelDateTARGET = e["TARGET TURN ON DATE"];
        // var tempConvertedDateTARGET =
        //   this.ExcelDateToJSDate(tempExcelDateTARGET);
        // var convertedDateOnlyTARGET = this.convertToDateOnly(
        //   tempConvertedDateTARGET
        // );
        // e["TARGET TURN ON DATE"] = convertedDateOnlyTARGET;
        // e["created_by"] = this.myUserID.id;
      });
    },
  },
};
</script>
<style>

.bg-custom {
  background-color: #EFF7EA;
}

.card-border-diagonal {
  /* border: 2px solid #BDDBA9; */
  border-radius: 100px 0 100px 0;
  /* margin: 20px; */
  padding: 10px;
  /* max-width: 300px; */
}

.card-border-inner {
  border: 1px solid #ADB5BD;
  border-radius: 50px 50px 50px 50px;
  /* margin: 20px; */
  padding: 10px;
  /* max-width: 300px; */
}

</style>
