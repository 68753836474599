<template>
    <div>
        <base-header class="pb-6">
            <b-row class="py-4">
                <b-col cols="6">
                    <h6 class="h2 text-white d-inline-block mb-0">{{ title }}</h6>
                </b-col>
            </b-row>
        </base-header>

        <b-container fluid class="mt--6">
            <div class="card-wrapper">
                <card>
                    <!-- Card header -->
                    <h3 slot="header" class="mb-0">Approver Settings</h3>
                    <validation-observer v-slot="{ handleSubmit }">
                        <form class="needs-validation" @submit.prevent="handleSubmit(saveData)">
                            <div class="table-responsive">
                                <table class="table align-items-center mb-0 text-dark">
                                    <thead>
                                        <td class="text-dark">Level</td>
                                        <td class="text-dark">Approver</td>
                                        <td class="text-dark">

                                            <base-button
                                                    type="primary"
                                                    @click="addMoreItem"
                                            >
                                               Add More
                                            </base-button>
                                        </td>
                                    </thead>
                                    <tbody>
                                        <tr class="align-items-center" v-for="(item, index) in approvers" :key="index">
                                            <td>
                                                <validation-provider name="Level" rules="required" v-slot="{ classes, errors }">
                                                    <b-form-group label="Level" :invalid-feedback="errors[0]">
                                                        <input type="text" class="form-control" :class="classes" v-model="item.level" maxlength="100">
                                                    </b-form-group>
                                                </validation-provider>
                                            </td>
                                            <td>
                                                <validation-provider name="Approver Name" rules="required" v-slot="{ classes, errors }">
                                                    <b-form-group label="Approver Name" :invalid-feedback="errors[0]">
                                                        <select class="form-control" v-model="item.user_id">
                                                            <option v-for="item in approverOptions" :key="item.id" v-bind:value="item.id">{{ item.name }} - {{ item.email }}</option>
                                                        </select>
                                                    </b-form-group>
                                                </validation-provider>
                                            </td>
                                            <td>
                                                <b-form-group>
                                                    <br>
                                                    <base-button size="sm" type="danger" @click="deleteItems(index)">Remove</base-button>
                                                </b-form-group>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <br>

                            <button class="btn btn-primary" v-if="access.create && mode == 'new'">Create Approver</button>
                            <button class="btn btn-primary" v-if="access.create && mode == 'edit'">Update Approver</button>

                        </form>
                    </validation-observer>
                </card>
            </div>
        </b-container>



    </div>
</template>

<script>
    import ErrorFunctionMixin from '@/assets/js/errorFunctionMix.js'
    import axios from '@/util/axios'
    import { getAccessByModule } from '@/util/access'
    import BaseInput from "../../../../../components/Inputs/BaseInput.vue";
    import BaseButton from "../../../../../components/BaseButton.vue";

    export default {
        components: {
            BaseButton,
            BaseInput},
        mixins: [ErrorFunctionMixin],

        data() {
            return {
                mode : 'new',
                title: 'Eform Approvers',
                model: {
                },
                record: {
                    approvers: []
                },

                // default
                defaultRecord: {
                    approvers: []
                },

                approvers: [],
                access: {},
                approverOptions: [],

                errorMessages: null,
                showItemError: false,
                showLevelError: false,
                showApproverError: false,
                showDuplicateError: false,
                showApproverNumberError: false,
                showDiffLevelDuplicateError: false,

                headers: [
                    {
                        text: "Level",
                        sortable: false,
                    },
                    {
                        text: "Approver",
                        sortable: false,
                    },
                    {
                        text: "",
                        sortable: false,
                    },
                ],
            }
        },

        created() {
            this.access = getAccessByModule('EFORM_TEMPLATE')
            this.getUserOptions()
            this.getData()
        },

        methods: {
            isNumeric(evt) {
                var inp = String.fromCharCode(evt.keyCode);
                if (/[0-9]/.test(inp)) {
                    return true;
                } else {
                    evt.preventDefault();
                }
            },
            addMore() {
                let length = this.templateItems.length;
                let previousIndex = length - 1;
                let nextNumber = '';

                if(previousIndex >= 0)
                {
                    let value = this.templateItems[previousIndex];
                    if(value)
                    {
                        nextNumber = parseInt(value.order_no) + 1;
                    }
                }
                else
                {
                    nextNumber = 1;
                }

                this.templateItems.push(Object.assign({}, {
                    criteria:{
                        title:'',
                        id:'',
                        type:'',
                    },
                    order_no: nextNumber
                }));
            },
            // receive child criteria data and append it to the parent




            deleteItem(item) {
                this.showLevelError = false;
                this.showDuplicateError = false;
                this.confirmDeleteCriteriaModal = true
                this.deleteCriteriaModalData = item
            },

            saveData() {

                let levels = [];
                let missingNumbers = [];
                let duplicatesArr = [];
                let approversArr = [];

                let error = 0;
                let errorNumber = 0;
                this.approvers.forEach((item, index) => {
                    let getLevel = parseInt(item.level);
                    if(getLevel == 0)
                    {
                        errorNumber++;
                    }

                    if(getLevel && item.user_id)
                    {
                        duplicatesArr.push(getLevel+'-'+item.user_id);
                        approversArr.push(item.user_id);
                    }

                    if(!levels.includes(getLevel))
                    {
                        levels.push(getLevel);
                    }

                    if(!item.user_id)
                    {
                        error++;
                    }
                });

                if(duplicatesArr.length > 0)
                {
                    this.showDuplicateError = new Set(duplicatesArr).size !== duplicatesArr.length;
                }

                if(approversArr.length > 0 && this.showDuplicateError == false)
                {
                    this.showDiffLevelDuplicateError = new Set(approversArr).size !== approversArr.length;
                }

                const maxNumber = Math.max(...levels);
                const minNumber = 1;

                for (let i = minNumber; i <= maxNumber; i++) {
                    if (!levels.includes(i)) {
                        missingNumbers.push(i);
                    }
                }

                if(missingNumbers.length > 0)
                {
                    this.showLevelError = true;
                }
                else
                {
                    this.showLevelError = false;
                }

                if(error > 0)
                {
                    this.showApproverError = true;
                }
                else
                {
                    this.showApproverError = false;
                }

                if(errorNumber > 0)
                {
                    this.showApproverNumberError = true;
                }
                else
                {
                    this.showApproverNumberError = false;
                }
                console.log("showLevelError:"+this.showLevelError);
                console.log("showApproverError:"+this.showApproverError);
                console.log("showDuplicateError:"+this.showDuplicateError);
                console.log("showApproverNumberError:"+this.showApproverNumberError);
                console.log("showDiffLevelDuplicateError:"+this.showDiffLevelDuplicateError);
                if(this.showLevelError == false && this.showApproverError == false && this.showDuplicateError == false && this.showApproverNumberError == false && this.showDiffLevelDuplicateError == false)
                {

                    this.record.module = 'EFORM';
                    this.record.approvers = this.approvers;
                    console.log(this.record);
                    axios.post('e_forms/approver', this.record)
                        .then(response => {

                            //clear the new input. since its in the db
                            //console.log('clear new item')
                            this.newTemplateItems = []

                            this.$notify({
                                message: 'Approver Created/Updated Successfull.',
                                timeout: 10000,
                                icon: 'ni ni-bell-55',
                                type: 'default',
                            });
                        })
                        .catch(error => {
                            this.errorFunction(error, this.title)
                        });
                }
            },
            getUserOptions(){
                axios.get('lookup/user_list')
                    .then ( response => {
                        if (response.status === 200)
                        {
                            this.approverOptions = response.data.data
                        }
                    })
                    .catch ( error => {
                        console.log(error.response.status)
                        this.errorFunction(error, "User Lookup")
                    } );
            },
            getData() {
                this.record = Object.assign({}, this.defaultRecord);

                axios
                    .get("e_forms/approver", {
                        params: {
                            module: 'EFORM',
                        },
                    })
                    .then((response) => {
                        this.approvers = response.data.data
                        console.log(this.approvers.length);
                        if(this.approvers.length>0) {
                            this.mode = 'edit';
                        }
                    })
                    .catch((error) => {
                        this.errorFunction(error, "Approver Setting")
                    })

            },
            deleteItems(index) {
                this.approvers.splice(index,1);
            },
            addMoreItem() {
                let length = this.approvers.length;
                let previousIndex = length - 1;
                let nextNumber = '';

                if(previousIndex >= 0)
                {
                    let value = this.approvers[previousIndex];
                    if(value)
                    {
                        nextNumber = parseInt(value.level) + 1;
                    }
                }
                else
                {
                    nextNumber = 1;
                }

                this.approvers.push(Object.assign({}, {
                    level: nextNumber,
                    is_new: true
                }));
                // console.log('records', this.approvers);
            },
        }

    }
</script>
<style scoped>
    table {
        border-collapse: collapse; /* Ensures borders between cells are merged */
        width: 100%; /* Optional: Sets the table width */
    }

    th, td {
        /*border: 1px solid black; !* Applies 1px solid black border to cells *!*/
        padding: 8px; /* Optional: Adds padding for spacing */
        text-align: left; /* Optional: Aligns text to the left */
    }

    th {
        background-color: #f2f2f2; /* Optional: Adds a background color to headers */
    }

    .table thead th {
        color:black;
    }
</style>