<template>
  <div class="content">
    <base-header class="pb-6">
      <b-row align-v="center" class="py-4">
        <b-col cols="7" lg="12">
          <h6 class="h2 text-white d-inline-block mb-0">Project Task Template Detail Listing</h6>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <route-bread-crumb></route-bread-crumb>
          </nav>
        </b-col>
        <b-col lg="12" cols="5" class="text-right">
          <base-button size="sm" type="neutral" @click="newTaskTemplateDetail()"
          v-if="button_access.new_button == 'YES'">New Template Detail</base-button>
        <base-button size="sm" type="neutral" @click="$router.back()">Back</base-button>
        </b-col>
      </b-row>
    </base-header>

    <b-container fluid class="mt--6">
      <div>
      <b-row>
        <b-col>
          <div class="card-wrapper">
           <project-task-template-view-form/>
          </div>
        </b-col>
      </b-row>
      </div>

      <div>
        <card class="no-border-card" body-classes="px-0 pb-1" footer-classes="pb-2">
          <template slot="header">
            <h3 class="mb-0">Project Task Template Detail Listing for ({{ project_task_title }})</h3>

          </template>
          <div>
            <b-col cols="12" class="d-flex justify-content-center justify-content-sm-between flex-wrap">
              <el-select
                class="select-primary pagination-select"
                v-model="pagination.perPage"
                placeholder="Per page">
                <el-option
                  class="select-primary"
                  v-for="item in pagination.perPageOptions"
                  :key="item"
                  :label="item"
                  :value="item">
                </el-option>
              </el-select>

              <div>
              </div>
            </b-col>
            <b-col><br></b-col>
            <el-table :data="queriedData"
                      row-key="document_template_detail_id"
                      header-row-class-name="thead-light"
                      @sort-change="sortChange">
              <el-table-column
                v-for="column in tableColumns"
                :key="column.label"
                v-bind="column">
              </el-table-column>
              <el-table-column min-width="110px" align="right" label="Actions">
                <div slot-scope="{$index, row}" class="d-flex">
                  <base-button v-if="button_access.edit_button == 'YES' && (myUserID.role_id == 3 || myUserID.role_id == 2 || myUserID.role_id == 1)"
                    @click.native="handleLike($index, row)" size="sm" type="primary">
                    Edit
                  </base-button>
                  <base-button v-else-if="button_access.edit_button == 'YES'"
                    @click.native="handleLike($index, row)" size="sm" type="primary">
                    View
                  </base-button>
                  <base-button v-if="myUserID.email == row.created_by.email || myUserID.role_id == 3 || myUserID.role_id == 2 || myUserID.role_id == 1"
                    @click.native="handleDelete($index, row)" size="sm" type="primary">
                    Delete
                  </base-button>
                </div>
              </el-table-column>
            </el-table>
          </div>
          <div
            slot="footer"
            class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap">
            <div class="">
              <p class="card-category">
                Showing {{ from + 1 }} to {{ to }} of {{ total }} entries
                <span v-if="selectedRows.length">
                  &nbsp; &nbsp; {{selectedRows.length}} rows selected
                </span>
              </p>

            </div>
            <base-pagination
              class="pagination-no-border"
              :current="pagination.currentPage"
              :per-page="pagination.perPage"
              :total="total"
              @change="paginationChanged($event)" >
            </base-pagination>
          </div>
        </card>
      </div>
    </b-container>

    <modal :show.sync="modals.delete_forms"
                size="lg"
                body-classes="p-0">
      <card type="secondary"
            header-classes="bg-transparent pb-5"
            body-classes="px-lg-5 py-lg-5"
            class="border-0 mb-0">
        <template>
          <div class="text-center text-muted mb-4" >
            <h3>Are You SURE you want to delete this Template Details ? </h3>
            <h3>with Title : {{delete_title}} </h3>
          </div>
          <b-form role="form">

            <h5 class="text-danger"> Note : <br>
              <i>
              1. Once Delete You cannot reverse the process.

              </i>
            </h5>
            <div class="text-center">
              <base-button type="danger" class="my-4" @click="deleteRow()">YES</base-button>
              <base-button type="default" class="my-4" @click="cancelDelete()">NO</base-button>
            </div>
          </b-form>
        </template>
      </card>
    </modal>

    </div>
</template>
<script>

import { Table, TableColumn, Select, Option } from 'element-ui';
import RouteBreadCrumb from '@/components/Breadcrumb/RouteBreadcrumb'
import { BasePagination } from '@/components';
import clientPaginationMixin from '@/assets/js/standardPaginationMix.js'
import swal from 'sweetalert2';
import axios from 'axios'
import {LoginURL, clientId, clientSecret, apiDomain, getHeader} from '@/assets/js/config.js'
import ProjectTaskTemplateViewForm from './ProjectTaskTemplateViewForm.vue';
import ErrorFunctionMixin from '@/assets/js/errorFunctionMix.js'
import { Modal } from '@/components'

export default {
  mixins: [clientPaginationMixin, ErrorFunctionMixin],
  components: {
    RouteBreadCrumb,
    BasePagination,
    "project-task-template-view-form" : ProjectTaskTemplateViewForm,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn
  },
  created()
  {
    var project_task_template_detail_access = JSON.parse(window.localStorage.getItem('project_task_template_detail_access'))
    this.button_access.new_button = project_task_template_detail_access.access_create
    this.button_access.edit_button = project_task_template_detail_access.access_edit

    const LoginUser = JSON.parse(window.localStorage.getItem('loginUser'))
    this.myUserID = LoginUser

    this.project_task_template_id = this.$route.params.id
    this.getListingData()
  },
  data() {
    return {
      propsToSearch: ['project_task_title', 'status.status_id', 'created_by', 'created_at'],
      tableColumns: [
        {
          prop: 'project_task_title',
          label: 'Title',
          minWidth: 200,
          sortable: true
        },
        {
          prop: 'status.status_code',
          label: 'Default Status',
          minWidth: 110,
          sortable: true
        },
        {
          prop: 'project_task_description',
          label: 'Description',
          minWidth: 140,
          sortable: true
        },
        {
          prop: 'created_at',
          label: 'Created Date',
          minWidth: 140,
          sortable: true
        },
        {
          prop: 'active_status',
          label: 'Status',
          minWidth: 110,
          sortable: true
        },
      ],
      tableData: [],
      selectedRows: [],
      project_task_template_id : null,
      id: 0,
      project_task_title: null,
      milestone_detail_count: 0,
      button_access:{
        new_button: null,
        edit_button: null,
      },
      modals: {
        delete_forms: false,
      },
      delete_title: null,
      delete_id : null,
      myUserID : null,
    };
  },
  methods: {
    newTaskTemplateDetail()
    {
      this.$router.push({path: '/templates/projectTaskTemplateDetailNew/'+this.project_task_template_id })
    },
    getListingData()
    {
      axios.get(apiDomain + 'template/project_task_template_details.show_by_template/'+ this.project_task_template_id , { headers: getHeader() })
      .then ( response => {
        if (response.status === 200)
        {
          this.tableData = response.data.data
          this.project_task_title = response.data.dataTitle
        }
      })
      .catch ( error => {
          console.log(error.response.status)
          this.errorFunction(error, "Project Task Template Details Listing")
      } );
    },
    paginationChanged(page) {
      this.pagination.currentPage = page
    },
    handleLike(index, row) {
       this.$router.push({path: '/templates/projectTaskTemplateDetailEdit/' + row.project_task_template_detail_id })
    },
    handleDelete(index, row)
    {
      this.delete_title = row.project_task_title
      this.delete_id = row.project_task_template_detail_id
      this.modals.delete_forms = true
    },
    cancelDelete()
    {
      this.delete_title = null
      this.delete_id = null
      this.modals.delete_forms = false
    },
    deleteRow()
    {
      axios.delete(apiDomain + 'template/project_task_template_details/'+ this.delete_id , { headers: getHeader() })
      .then ( response => {
        console.log(response)
        if (response.status === 200)
        {
          this.$notify({
          message:
            '<b>Project Task Template Details : </b> - Successfully DELETED.',
          timeout: 10000,
          icon: 'ni ni-bell-55',
          type: 'default',
          });
          this.getListingData()
        }
      })
      .catch ( error => {
          console.log(error.response.status)
          this.errorFunction(error, 'Delete Task Template Detail Listing')
      } );
       this.modals.delete_forms = false
    },
    selectionChange(selectedRows) {
      this.selectedRows = selectedRows
    }
  }
};
</script>
<style>
  .no-border-card .card-footer{
    border-top: 0;
  }
</style>
