var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-card',{staticClass:"bg-default shadow",attrs:{"no-body":""}},[_c('b-card-header',{staticClass:"bg-transparent border-0"},[(_vm.model.project_name == null)?_c('h3',{staticClass:"mb-0 text-white"},[_vm._v("Project Task Listing - Create Project Task")]):_c('h3',{staticClass:"mb-0 text-white"},[_vm._v("Project Task Listing for ("+_vm._s(_vm.model.project_name)+")")])]),_c('b-col',{staticClass:"d-flex justify-content-center justify-content-sm-between flex-wrap",attrs:{"cols":"12"}},[_c('div'),_c('div',{staticClass:"text-right"},[(((_vm.button_access.project_task_new == 'YES') && (_vm.myUserID.email == _vm.model.project_manager.email)))?_c('base-button',{attrs:{"size":"sm","type":"neutral"},on:{"click":function($event){return _vm.newTask()}}},[_vm._v(" Create New Project Task")]):_vm._e(),_c('base-button',{attrs:{"size":"sm","type":"neutral"},on:{"click":function($event){return _vm.getCreateData()}}},[_vm._v("Refresh")]),_c('base-button',{attrs:{"size":"sm","type":"neutral"},on:{"click":function($event){return _vm.showAllListing()}}},[_vm._v("Show All")])],1)]),_c('el-table',{staticClass:"table-responsive table-dark",attrs:{"header-row-class-name":"thead-dark","data":_vm.queriedData}},[_c('el-table-column',{attrs:{"label":"Code","min-width":"280px","prop":"task_title","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('b-media',{staticClass:"align-items-center",attrs:{"no-body":""}},[_c('b-media-body',[_c('span',{staticClass:"font-weight-600 name mb-0 text-sm"},[_vm._v(_vm._s(row.task_title))])])],1)]}}])}),_c('el-table-column',{attrs:{"label":"Project","min-width":"150px","prop":"project.project_name","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('b-media',{staticClass:"align-items-center",attrs:{"no-body":""}},[_c('b-media-body',[_c('span',{staticClass:"font-weight-600 name mb-0 text-sm"},[_vm._v(_vm._s(row.project.project_name))])])],1)]}}])}),_c('el-table-column',{attrs:{"label":"Assign To","min-width":"140px","prop":"assign_to_user.name","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('b-media',{staticClass:"align-items-center",attrs:{"no-body":""}},[_c('b-media-body',[_c('span',{staticClass:"font-weight-600 name mb-0 text-sm"},[_vm._v(_vm._s(row.assign_to_user.name))])])],1)]}}])}),_c('el-table-column',{attrs:{"label":"Dates","min-width":"200px","prop":"task_est_start_date","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('b-media',{staticClass:"align-items-center",attrs:{"no-body":""}},[_c('b-media-body',[_c('span',{staticClass:"font-weight-600 name mb-0 text-sm"},[_vm._v("Est Start Date : "+_vm._s(row.task_est_start_date))])])],1),_c('b-media',{staticClass:"align-items-center",attrs:{"no-body":""}},[_c('b-media-body',[_c('span',{staticClass:"font-weight-600 name mb-0 text-sm"},[_vm._v("Act Start Date : "+_vm._s(row.task_start_date))])])],1)]}}])}),_c('el-table-column',{attrs:{"label":"Progress","min-width":"200px","prop":"task_progress","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"d-flex align-items-center"},[_c('div',[_c('span',{staticClass:"font-weight-600 name mb-0 text-sm"},[_vm._v(_vm._s(row.task_progress)+"%   ")])]),_c('div',[(row.task_progress > 0 && row.task_progress < 50)?_c('base-progress',{attrs:{"min-width":"30px","type":"danger","value":row.task_progress}}):(row.task_progress >= 50 && row.task_progress <= 90)?_c('base-progress',{attrs:{"min-width":"30px","type":"info","value":row.task_progress}}):_c('base-progress',{attrs:{"min-width":"30px","type":"success","value":row.task_progress}})],1)]),_c('b-media',{staticClass:"align-items-center",attrs:{"no-body":""}},[_c('b-media-body',[_c('badge',{staticClass:"badge-dot mr-4",attrs:{"type":""}},[(row.status.status_code == 'Delayed')?_c('i',{staticClass:"bg-danger"}):(row.status.status_code == 'On-Hold')?_c('i',{staticClass:"bg-danger"}):(row.status.status_code == 'Reviewed')?_c('i',{staticClass:"bg-success"}):(row.status.status_code == 'Completed')?_c('i',{staticClass:"bg-success"}):_c('i',{staticClass:"bg-info"}),_c('span',{staticClass:"font-weight-600 name mb-0 text-sm text-white"},[_vm._v(_vm._s(row.status.status_code))])])],1)],1)]}}])}),_c('el-table-column',{attrs:{"min-width":"140px","align":"right","label":"Actions"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var $index = ref.$index;
var row = ref.row;
return _c('div',{staticClass:"d-flex"},[(_vm.button_access.project_task_view == 'YES')?_c('base-button',{attrs:{"size":"sm","type":"primary"},nativeOn:{"click":function($event){return _vm.handleDetails($index, row)}}},[_vm._v(" View "),_c('br'),_vm._v("/ Edit ")]):_vm._e(),(((_vm.button_access.project_task_delete == 'YES' && row.project.project_manager.email == _vm.myUserID.email) || _vm.myUserID.role_id == 3 || _vm.myUserID.role_id == 1) && row.status.status_code != 'Approved')?_c('base-button',{attrs:{"size":"sm","type":"primary"},nativeOn:{"click":function($event){return _vm.handleDelete($index, row)}}},[_vm._v(" Delete ")]):_vm._e()],1)}}])})],1),_c('b-card-footer',{staticClass:"py-2 d-flex justify-content-end bg-transparent"},[_c('base-pagination',{staticClass:"pagination-no-border",attrs:{"current":_vm.pagination.currentPage,"per-page":_vm.pagination.perPage,"total":_vm.total},on:{"change":function($event){return _vm.paginationChanged($event)}}})],1)],1),_c('modal',{attrs:{"show":_vm.modals.delete_forms,"size":"lg","body-classes":"p-0"},on:{"update:show":function($event){return _vm.$set(_vm.modals, "delete_forms", $event)}}},[_c('card',{staticClass:"border-0 mb-0",attrs:{"type":"secondary","header-classes":"bg-transparent pb-5","body-classes":"px-lg-5 py-lg-5"}},[[_c('div',{staticClass:"text-center text-muted mb-4"},[_c('h3',[_vm._v("Are You SURE you want to delete this Task ? ")]),_c('h3',[_vm._v("with Title : "+_vm._s(_vm.modals.delete_title)+" ")])]),_c('b-form',{attrs:{"role":"form"}},[_c('h5',{staticClass:"text-danger"},[_vm._v(" Note : "),_c('br'),_c('i',[_vm._v(" 1. Once Delete You cannot reverse the process. ")])]),_c('div',{staticClass:"text-center"},[_c('base-button',{staticClass:"my-4",attrs:{"type":"danger"},on:{"click":function($event){return _vm.deleteRow()}}},[_vm._v("YES")]),_c('base-button',{staticClass:"my-4",attrs:{"type":"default"},on:{"click":function($event){return _vm.cancelDelete()}}},[_vm._v("NO")])],1)])]],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }