<template>
    <b-card no-body class="bg-default shadow">
        <b-card-header class="bg-transparent border-0">
            <h3 class="mb-0 text-white">Task Listing in SN-NT - Tengeh Reservoir North Site for (TRTP-2398 - Tengeh Reservoir Tender Project)</h3>
            <div class="text-right">
              <router-link to="/taskNew">
                <base-button size="sm" type="neutral">Create New Task</base-button>
              </router-link>
            </div>
        </b-card-header>

        <el-table class="table-responsive table-dark"
                  header-row-class-name="thead-dark"
                  :data="tasks">
            <el-table-column label="Code"
                             min-width="80px"
                             prop="name"
                             sortable>
                <template v-slot="{row}">
                    <b-media no-body class="align-items-center">
                        <b-media-body>
                            <span class="font-weight-600 name mb-0 text-sm text-white">{{row.taskId}}</span>
                        </b-media-body>
                    </b-media>
                </template>
            </el-table-column>

            <el-table-column label="Task Name"
                             min-width="200px"
                             prop="name"
                             sortable>
                <template v-slot="{row}">
                    <b-media no-body class="align-items-center">
                        <b-media-body>
                            <span class="font-weight-600 name mb-0 text-sm text-white">{{row.taskName}}</span>
                        </b-media-body>
                    </b-media>
                </template>
            </el-table-column>

            <el-table-column label="Status"
                             min-width="70px"
                             prop="status"
                             sortable>
                <template v-slot="{row}">
                    <badge class="badge-dot mr-4" type="">

                        <span class="status text-white">{{row.taskStatus}}</span>
                    </badge>
                </template>
            </el-table-column>

            <el-table-column label="Milestone" min-width="90px">
                <template v-slot="{row}">
                    <b-media no-body class="align-items-center">

                        <b-media-body>
                            <span class="font-weight-600 name mb-0 text-sm text-white">{{row.taskMilestone}}</span>
                        </b-media-body>
                    </b-media>
                </template>
            </el-table-column>

            <el-table-column label="Progress"
                             prop="completion"
                             min-width="120px"
                             sortable>
                <template v-slot="{row}">
                    <div class="d-flex align-items-center">
                        <div>
                          <span class="completion mr-0 text-white">{{row.taskProgress}}% </span>
                        </div>
                        <div>
                            <base-progress min-width="80px" :type="row.taskStatusType" :value="row.taskProgress"/>
                        </div>
                    </div>
                </template>
            </el-table-column>

            <el-table-column label="Action" min-width="130px">
               <template v-slot="">
                    <div class="d-flex align-items-center">
                        <div>
                            <base-button type="primary" size="sm" @click="modals.form = true">Assign Contractor</base-button>
                        </div>
                        &nbsp;
                        <div>
                           <router-link to="/taskEdit">
                            <base-button size="sm" type="primary">Edit</base-button>
                          </router-link>
                        </div>
                    </div>
                </template>
            </el-table-column>
        </el-table>

        <b-card-footer class="py-2 d-flex justify-content-end bg-transparent">
            <base-pagination v-model="currentPage" :per-page="10" :total="50"></base-pagination>
        </b-card-footer>

        <modal :show.sync="modals.form"
                   size="lg"
                   body-classes="p-0">
              <card type="secondary"
                    header-classes="bg-transparent pb-5"
                    body-classes="px-lg-5 py-lg-5"
                    class="border-0 mb-0">
                <template>
                  <div class="text-center text-muted mb-4">
                    <h2>Assign Contractor To ( TRTP-000001 - Handover Requesition Document For North )</h2>
                  </div>
                  <b-form role="form">
                    <base-input label="Contractor" name="Contractor">
                      <select class="form-control" v-model="formModal.contactor">
                        <option value="" disabled>-- Please Select A Contractor --</option>
                        <option value="Solar Ex">Solar Ex</option>
                        <option value="Long Seng">Long Seng</option>
                      </select>
                    </base-input>
                    <div class="text-center">
                      <base-button type="primary" class="my-4" @click="notifyVue()">Assign Task to Contractor</base-button>
                      <base-button type="primary" class="my-4" @click="clearModal()">Cancel</base-button>
                    </div>
                  </b-form>
                </template>
              </card>
        </modal>
    </b-card>

</template>

<script>

  import tasks from './js/tasks.js'
  import { Table, TableColumn, DropdownMenu, DropdownItem, Dropdown} from 'element-ui'
  import {homeLink} from '@/assets/js/config.js'
  import { Modal } from '@/components';

  export default {
    name: 'task-table',
    components: {
      Modal,
      [Table.name]: Table,
      [TableColumn.name]: TableColumn,
      [Dropdown.name]: Dropdown,
      [DropdownItem.name]: DropdownItem,
      [DropdownMenu.name]: DropdownMenu,
    },
    data() {
      return {
        modals: {
          form: false
        },
        formModal: {
          contactor: '',
        },
        tasks,
        currentPage: 1,
        img1: homeLink+'/img/theme/team-1.jpg',
      };
    },
    methods: {
      notifyVue() {
        if ( this.formModal.contactor === '' )
        {
          this.$notify({
          message:
            '<b>Contractor Input Error : </b> - Please Select A Contractor Or Cancel to Exit.',
          timeout: 10000,
          icon: 'ni ni-bulb-61',
          type: 'danger',
          });
        }
        else
        {
          this.$notify({
          message:
            '<b>Contractor (' + this.formModal.contactor + ') : </b> - Successfully Assign to Task ( TRTP-000001 - Prepare Requesition Form )',
          timeout: 10000,
          icon: 'ni ni-bell-55',
          type: 'default',
          });
          this.clearModal()
        }
      },
      clearModal()
      {
        this.modals.form = false
        this.formModal.contactor = ''
      }
    }
  }
</script>
