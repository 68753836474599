var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"content"},[_c('base-header',{staticClass:"pb-6 bg-default shadow"},[_c('b-row',{staticClass:"py-4",attrs:{"aling-v":"center"}},[_c('b-col',{attrs:{"cols":"7","lg":"6"}},[_c('h6',{staticClass:"h2 text-white d-inline-block mb-0"},[_vm._v(" Cycle Time Report ")]),_c('nav',{staticClass:"d-none d-md-inline-block ml-md-4",attrs:{"aria-label":"breadcrumb"}},[_c('route-bread-crumb')],1)]),_c('b-col',{staticClass:"text-right",attrs:{"cols":"5","lg":"6"}})],1)],1),_c('b-container',{staticClass:"mt--6 bg-default shadow",attrs:{"fluid":""}},[_c('div',[_c('card',{staticClass:"no-border-card",attrs:{"body-classes":"px-0 pb-1","footer-classes":"pb-2"}},[_c('template',{slot:"header"},[_c('h3',{staticClass:"mb-0"},[_vm._v("Cycle Time Report")])]),_c('div',[_c('b-col',{staticClass:"d-flex justify-content-center justify-content-sm-between flex-wrap",attrs:{"cols":"12"}},[_c('h5',{staticClass:"text"},[_vm._v("Note: "),_c('br'),_vm._v("Filter EPC, YEAR and CYCLETIME by clicking on the down-arrow "),_c('br'),_vm._v("Click on the row to go to the corresponding project dashboard ")]),_c('div',[_c('base-input',{attrs:{"prepend-icon":"fas fa-search","placeholder":"Search..."},model:{value:(_vm.searchQuery),callback:function ($$v) {_vm.searchQuery=$$v},expression:"searchQuery"}})],1)]),_c('b-col',[_c('br')]),_c('el-table',{ref:"filterTable",attrs:{"data":_vm.queriedData,"row-key":"project_id","header-row-class-name":"thead-light","border":"","cell-style":{padding: '3px', height: '20px'}},on:{"row-click":_vm.rowClicked,"sort-change":_vm.sortChange}},[_c('el-table-column',{attrs:{"label":"Project Name","min-width":"300px","prop":"project_name","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('b-media',{staticClass:"align-items-center",attrs:{"no-body":""}},[_c('b-media-body',[_c('span',{staticClass:"font-weight-600 name mb-0 text-sm"},[_vm._v(_vm._s(row.project_name))])])],1)]}}])}),_c('el-table-column',{attrs:{"label":"EPC","min-width":"80px","prop":"contractor_codes","sortable":"","filters":_vm.filter_epc_data,"filter-method":_vm.filterEpc},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('b-media',{staticClass:"align-items-center",attrs:{"no-body":""}},[_c('b-media-body',{staticStyle:{"text-align":"center"}},[_c('span',{staticClass:"font-weight-600 name mb-0 text-sm"},[_vm._v(_vm._s(row.contractor_codes))])])],1)]}}])}),_c('el-table-column',{attrs:{"label":"Year","min-width":"80px","prop":"project_ppa_start_year","sortable":"","filters":_vm.filter_year_data,"filter-method":_vm.filterYear},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('b-media',{staticClass:"align-items-center",attrs:{"no-body":""}},[_c('b-media-body',{staticStyle:{"text-align":"center"}},[_c('span',{staticClass:"font-weight-600 name mb-0 text-sm"},[_vm._v(_vm._s(row.project_ppa_start_year))])])],1)]}}])}),_c('el-table-column',{attrs:{"label":"Capacity (MW)","min-width":"100px","prop":"project_ppa_approved_capacity_mw","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('b-media',{staticClass:"align-items-center",attrs:{"no-body":""}},[_c('b-media-body',{staticStyle:{"text-align":"center"}},[_c('span',{staticClass:"font-weight-600 name mb-0 text-sm"},[_vm._v(_vm._s(row.project_ppa_approved_capacity_mw))])])],1)]}}])}),_c('el-table-column',{attrs:{"label":"PPA to Site Handover","min-width":"100px","prop":"ppa_to_site_handover_days","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('b-media',{staticClass:"align-items-center",attrs:{"no-body":""}},[_c('b-media-body',{staticStyle:{"text-align":"center"}},[_c('span',{staticClass:"font-weight-600 name mb-0 text-sm"},[_vm._v(_vm._s(row.ppa_to_site_handover_days))])])],1)]}}])}),_c('el-table-column',{attrs:{"label":"PPA to EPC Award","min-width":"100px","prop":"ppa_to_epc_award_days","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('b-media',{staticClass:"align-items-center",attrs:{"no-body":""}},[_c('b-media-body',{staticStyle:{"text-align":"center"}},[_c('span',{staticClass:"font-weight-600 name mb-0 text-sm"},[_vm._v(_vm._s(row.ppa_to_epc_award_days))])])],1)]}}])}),_c('el-table-column',{attrs:{"label":"EPC Award to Hoisting","min-width":"100px","prop":"epc_award_to_hoisting_days","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('b-media',{staticClass:"align-items-center",attrs:{"no-body":""}},[_c('b-media-body',{staticStyle:{"text-align":"center"}},[_c('span',{staticClass:"font-weight-600 name mb-0 text-sm"},[_vm._v(_vm._s(row.epc_award_to_hoisting_days))])])],1)]}}])}),_c('el-table-column',{attrs:{"label":"Hoisting to Turn On","min-width":"100px","prop":"hoisting_to_turn_on_days","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('b-media',{staticClass:"align-items-center",attrs:{"no-body":""}},[_c('b-media-body',{staticStyle:{"text-align":"center"}},[_c('span',{staticClass:"font-weight-600 name mb-0 text-sm"},[_vm._v(_vm._s(row.hoisting_to_turn_on_days))])])],1)]}}])}),_c('el-table-column',{attrs:{"label":"Cycletime (Days)","min-width":"100px","prop":"cycle_time_days","sortable":"","filters":_vm.filter_cycle_time_data,"filter-method":_vm.filterCycleTime},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('b-media',{staticClass:"align-items-center",attrs:{"no-body":""}},[_c('b-media-body',{staticStyle:{"text-align":"center"}},[_c('span',{staticClass:"font-weight-600 name mb-0 text-sm"},[_vm._v(_vm._s(row.cycle_time_days))])])],1)]}}])}),_c('el-table-column',{attrs:{"label":"Target (Days)","min-width":"80px","prop":"target_days","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('b-media',{staticClass:"align-items-center",attrs:{"no-body":""}},[_c('b-media-body',{staticStyle:{"text-align":"center"}},[_c('span',{staticClass:"font-weight-600 name mb-0 text-sm"},[_vm._v(_vm._s(row.target_days))])])],1)]}}])})],1)],1)],2)],1),_c('br')])],1)}
var staticRenderFns = []

export { render, staticRenderFns }