var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('card',{staticClass:"no-border-card",attrs:{"body-classes":"px-0 pb-1","footer-classes":"pb-2"}},[_c('template',{slot:"header"},[_c('h3',{staticClass:"mb-0"},[_vm._v("Document Recurring Listing for ("+_vm._s(_vm.model.document_title)+")")])]),_c('div',[_c('b-col',{staticClass:"d-flex justify-content-center justify-content-sm-between flex-wrap",attrs:{"cols":"12"}}),_c('el-table',{attrs:{"data":_vm.queriedData,"row-key":"project_id","header-row-class-name":"thead-light"},on:{"sort-change":_vm.sortChange}},[_c('el-table-column',{attrs:{"label":"Recurring Date","min-width":"130px","prop":"document_version","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('b-media',{staticClass:"align-items-center",attrs:{"no-body":""}},[_c('b-media-body',[_c('span',{staticClass:"font-weight-600 name mb-0 text-sm"},[_vm._v(_vm._s(row.show_document_recurring_date))])])],1)]}}])}),_c('el-table-column',{attrs:{"label":"Total Uploaded Document","min-width":"120px","prop":"total_upload","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('b-media',{staticClass:"align-items-center",attrs:{"no-body":""}},[_c('b-media-body',[_c('span',{staticClass:"font-weight-600 name mb-0 text-sm"},[_vm._v(_vm._s(row.total_upload))])])],1)]}}])}),_c('el-table-column',{attrs:{"label":"Last Updated","min-width":"120px","prop":"upload_time","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('b-media',{staticClass:"align-items-center",attrs:{"no-body":""}},[_c('b-media-body',[_c('span',{staticClass:"font-weight-600 name mb-0 text-sm"},[_vm._v(" "+_vm._s(row.updated_at))])])],1)]}}])}),_c('el-table-column',{attrs:{"min-width":"100px","align":"right","label":"Actions"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var $index = ref.$index;
var row = ref.row;
return _c('div',{staticClass:"d-flex"},[(_vm.button_access.upload_listing == 'YES')?_c('base-button',{attrs:{"size":"sm","type":"primary"},nativeOn:{"click":function($event){return _vm.documentDetails($index, row)}}},[_vm._v(" Doc. Details ")]):_vm._e()],1)}}])})],1)],1),_c('div',{staticClass:"col-12 d-flex justify-content-center justify-content-sm-between flex-wrap",attrs:{"slot":"footer"},slot:"footer"},[_c('div',{},[_c('p',{staticClass:"card-category"},[_vm._v(" Showing "+_vm._s(_vm.from + 1)+" to "+_vm._s(_vm.to)+" of "+_vm._s(_vm.total)+" entries "),(_vm.selectedRows.length)?_c('span',[_vm._v("     "+_vm._s(_vm.selectedRows.length)+" rows selected ")]):_vm._e()])]),_c('base-pagination',{staticClass:"pagination-no-border",attrs:{"current":_vm.pagination.currentPage,"per-page":_vm.pagination.perPage,"total":_vm.total},on:{"change":function($event){return _vm.paginationChanged($event)}}})],1)],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }