<template>
  <card>
    <h3 slot="header" class="mb-0">New Project Document Template Detail for ({{ document_template_name }}) </h3>
    <div class="text-right">
    </div>
    <form class="needs-validation"
          @submit.prevent="submit">

      <div class="form-row">
        <b-col md="12">
          <base-input label="Document Title"
                      name="Document Title"
                      placeholder="Document Title"
                      v-model="model.project_document_template_title" required>
          </base-input>
        </b-col>

        <b-col md="6">
          <base-input label="Document Category"
                      name="Document Category"
                      placeholder="Document Category"
                      v-model="model.document_category_id">
            <select class="form-control" v-model="model.document_category_id">
              <option Selected disabled value="x">-- Please Select A Document Category --</option>
              <option v-for="item in document_category" :key="item.document_category_id" v-bind:value="item.document_category_id">{{ item.document_category }}</option>
            </select>
          </base-input>
        </b-col>

        <b-col md="6">
          <base-input label="Document Type (Only ONE-TIME Type for IFI Document) *"
                      name="Document Type"
                      required
                      v-model="model.document_type_id" v-if="model.document_category_id == '2'">
            <select class="form-control" v-model="model.document_type_id" >
              <option Selected disabled value="x">-- Please Select A Document Type --</option>
              <option value="1">ONE-TIME</option>
            </select>
          </base-input>
          <base-input label="Document Type"
                      name="Document Type"
                      v-model="model.document_type_id" v-else>
            <select class="form-control" v-model="model.document_type_id">
              <option Selected disabled value="x">-- Please Select A Document Type --</option>
              <option v-for="item in document_type" :key="item.document_type_id" v-bind:value="item.document_type_id">{{ item.document_type_code }} - {{ item.document_type_info }}</option>
            </select>
          </base-input>
        </b-col>
      </div>

      <div v-if="model.document_type_id == '2'" class="form-row">
        <b-col md="6">
          <base-input label="Recurring Interval"
                      name="Recurring Interval"
                      v-model="model.recurring_interval_id">
            <select class="form-control" v-model="model.recurring_interval_id">
              <option Selected disabled value="x">-- Please Select A Recurring Interval --</option>
              <option value="1">Daily</option>
              <option value="2">Weekly</option>
              <option value="3">Month</option>
            </select>
          </base-input>
        </b-col>
      </div>

      <div class="form-row">
         <b-col md="4">
          <b-form-checkbox
            id="Mandatory"
            v-model="model.project_document_template_mandatory"
            name="Mandatory"
            value="1"
            unchecked-value="0">
            <b>Document Mandatory</b>
          </b-form-checkbox>
        </b-col>

        <b-col md="4">
          <b-form-checkbox
            id="Active-Status"
            v-model="model.active_status"
            name="Active-Status"
            value="1"
            unchecked-value="0">
            <b>Active Status</b>
          </b-form-checkbox>
        </b-col>
      </div>
      <hr>
      <div class="form-row">
         <b-col md="12">
            <b>Require Approval From</b>
        </b-col>
      </div>
      <br>
      <div class="form-row">
        <b-col md="1.5">
          <b-form-checkbox
            id="req_approval_project_owner"
            v-model="model.req_approval_project_owner"
            name="req_approval_project_owner"
            value="1"
            unchecked-value="0">
            <b>Project Owner</b>
          </b-form-checkbox>
        </b-col>

        <b-col md="1.5">
          <b-form-checkbox
            id="req_approval_project_manager"
            v-model="model.req_approval_project_manager"
            name="req_approval_project_manager"
            value="1"
            unchecked-value="0">
            <b>Project Manager</b>
          </b-form-checkbox>
        </b-col>

        <b-col md="1.5">
          <b-form-checkbox
            id="req_approval_project_engineer"
            v-model="model.req_approval_project_engineer"
            name="req_approval_project_engineer"
            value="1"
            unchecked-value="0">
            <b>Project Engineer</b>
          </b-form-checkbox>
        </b-col>

        <b-col md="1.5">
          <b-form-checkbox
            id="req_approval_engineer"
            v-model="model.req_approval_engineer"
            name="req_approval_engineer"
            value="1"
            unchecked-value="0">
            <b>Engineer</b>
          </b-form-checkbox>
        </b-col>

        <b-col md="1.5">
          <b-form-checkbox
            id="req_approval_qa_qc"
            v-model="model.req_approval_qa_qc"
            name="req_approval_qa_qc"
            value="1"
            unchecked-value="0">
          <b>QA / QC</b>
          </b-form-checkbox>
        </b-col>

        <b-col md="1.5">
          <b-form-checkbox
            id="req_approval_onm"
            v-model="model.req_approval_onm"
            name="req_approval_onm"
            value="1"
            unchecked-value="0">
          <b>O & M</b>
          </b-form-checkbox>
        </b-col>

        <b-col md="1.5">
          <b-form-checkbox
            id="req_approval_safety"
            v-model="model.req_approval_safety"
            name="req_approval_safety"
            value="1"
            unchecked-value="0">
          <b>Safety</b>
          </b-form-checkbox>
        </b-col>

        <b-col md="1.5">
          <b-form-checkbox
            id="req_approval_planner"
            v-model="model.req_approval_planner"
            name="req_approval_planner"
            value="1"
            unchecked-value="0">
          <b>Planner</b>
          </b-form-checkbox>
        </b-col>

        <b-col md="1.5">
          <b-form-checkbox
            id="req_approval_purchasing"
            v-model="model.req_approval_purchasing"
            name="req_approval_purchasing"
            value="1"
            unchecked-value="0">
          <b>Purchasing</b>
          </b-form-checkbox>
        </b-col>

        <b-col md="1.5">
          <b-form-checkbox
            id="req_approval_admin"
            v-model="model.req_approval_admin"
            name="req_approval_admin"
            value="1"
            unchecked-value="0">
          <b>Admin</b>
          </b-form-checkbox>
        </b-col>
      </div>
      <br><br>
      <base-button type="primary" @click="createData()">Create / Save</base-button>
      <base-button type="primary" @click="$router.back()">Cancel</base-button>
    </form>
  </card>
</template>
<script>
  import BaseSlider from '@/components/BaseSlider'
  import {LoginURL, clientId, clientSecret, apiDomain, getHeader} from '@/assets/js/config.js'
  import axios from 'axios'
  import ErrorFunctionMixin from '@/assets/js/errorFunctionMix.js'

  export default {
    mixins: [ErrorFunctionMixin],
    components: {
      BaseSlider
    },
    created()
    {
      this.model.project_document_template_id = this.$route.params.id
      axios.get(apiDomain + 'template/project_document_template/'+this.model.project_document_template_id, { headers: getHeader() })
      .then ( response => {
        if (response.status === 200)
        {
          this.document_template_name = response.data.data[0].document_template_name
        }
      })
      .catch ( error => {
          console.log(error.response.status)
          this.errorFunction(error, "Project Document Template Information")
      });

      axios.get(apiDomain + 'lookup/document_type', { headers: getHeader() })
      .then ( response => {
        if (response.status === 200)
        {
          this.document_type = response.data.data
        }
      })
      .catch ( error => {
          console.log(error.response.status)
      });

      axios.get(apiDomain + 'lookup/document_category', { headers: getHeader() })
      .then ( response => {
        if (response.status === 200)
        {
          this.document_category = response.data.data
        }
      })
      .catch ( error => {
          console.log(error.response.status)
          this.errorFunction(error, "Document Category Information")
      });

    },
    data() {
      return {
        model: {
          project_document_template_detail_id: null,
          project_document_template_id: null,
          project_document_template_title: null,
          project_document_template_mandatory: 1,
          document_type_id: 'x',
          document_category_id: 'x',
          recurring_interval_id: 'x',
          active_status: 1,
          req_approval_project_owner: 1,
          req_approval_project_manager: 1,
          req_approval_project_engineer: 1,
          req_approval_engineer: 1,
          req_approval_qa_qc: 1,
          req_approval_onm : 1,
          req_approval_safety: 1,
          req_approval_planner: 1,
          req_approval_purchasing: 1,
          req_approval_admin: 1,
        },
        document_type: [],
        document_category: [],
        document_template_name: null,
      }
    },
    methods: {
      createData()
      {
        if ( !this.model.project_document_template_title ||  this.model.document_type_id == 'x' || this.model.document_category_id == 'x')
        {
          if ( !this.model.project_document_template_title )
          {
            this.redErrorNotify( '<b>Invalid Input : Document Title is a Mandatory in Project Document </b>')
          }
          else if (this.model.document_category_id == 'x')
          {
            this.redErrorNotify( '<b>Invalid Input : Document Category is a Mandatory in Project Document. Please Select A Category </b>' )
          }
            else if (this.model.document_type_id == 'x')
          {
            this.redErrorNotify( '<b>Invalid Input : Document Type is a Mandatory in Project Document. Please Select A Type </b>' )
          }
          else
          {
            this.redErrorNotify( '<b>Invalid Input : Please fill in all the Mandatory Column in Project Document </b>' )
          }
        }
        else if ( this.model.document_type_id == '2')
        {
            if(this.model.recurring_interval_id == 'x')
            {
               this.redErrorNotify( '<b>Invalid Input : Recurring Interval is a Mandatory in Project Document. Please Select A Interval </b>' )
            }
            else
            {
              this.insertIntoAPI()
            }
        }
        else
        {
          if ( this.model.document_type_id == '1')
          {
            this.model.recurring_interval_id = null
          }

          this.insertIntoAPI()
        }
      },

      insertIntoAPI()
      {
        axios.post(apiDomain + 'template/project_document_template_details', this.model, { headers: getHeader() })
        .then ( response => {
          if (response.status === 200)
          {
              this.$notify({
              message:
                '<b>New Project Document Template Details : ' + this.model.project_document_template_title + '</b> - Successfully Created.',
              timeout: 10000,
              icon: 'ni ni-bell-55',
              type: 'default',
              });
              this.$router.back()
          }
        })
        .catch ( error => {
          console.log(error.response.data.errors)
          this.errorFunction(error, "Create New Project Document Template Details", this.model.project_document_template_title)
        });
      },
    }
  }
</script>
<style>
</style>
