<template>
  <card>
    <!-- Card header -->
    <h3 slot="header" class="mb-0">New Site Contractor for ({{ model.site_name }})</h3>
    <div class="text-right">
    </div>
    <form class="needs-validation"
          @submit.prevent="submit">

      <!-- <div v-if="projects.length == 0" class="form-row">
        <b-col md="12">
        <h5 class="text-danger"> !!! Warning :
            <i>
            Please Create
            <router-link to="/ProjectNew">
                New Project
            </router-link>
            at Menu >> Project-Group-Site >> Project. System required at least ONE Project before you can proceed to create New Milestone.
            </i>
        </h5>
        </b-col>
      </div> -->

      <div class="form-row">
        <b-col md="4">
          <base-input label="Project"
                      name="Project"
                      v-model="model.project_name" disabled>
          </base-input>
        </b-col>

        <b-col md="4">
          <base-input label="Group"
                      name="Group"
                      v-model="model.group_name" disabled>
          </base-input>
        </b-col>

        <b-col md="4">
          <base-input label="Site"
                      name="Site"
                      v-model="model.site_name" disabled>
          </base-input>
        </b-col>
      </div>

      <div class="form-row">
        <b-col md="4">
          <base-input label="Contractor"
                      name="Contractor"
                      placeholder="Contractor"
                      v-model="model.contractor_id" required>
            <select class="form-control" v-model="model.contractor_id" @change="getContractorDetail()">
              <option Selected disabled value="x">-- Please Select A Contractor --</option>
              <option v-for="item in contractors" :key="item.contractor_id" v-bind:value="item.contractor_id">{{ item.contractor_code }} - {{ item.contractor_name }}</option>
            </select>
          </base-input>
        </b-col>

        <b-col md="4">
          <base-input label="Contractor Name"
                      name="Contractor Name"
                      placeholder="Contractor Name"
                      v-model="model.contractor_name" disabled>
          </base-input>
        </b-col>

        <b-col md="4">
          <base-input label="Contact Person"
                      name="Contact Person"
                      placeholder="Contact Person"
                      v-model="model.contractor_contact_person" disabled>
          </base-input>
        </b-col>
      </div>

      <div class="form-row">
        <b-col md="4">
          <base-input label="Contact Number"
                      name="Contact Number"
                      placeholder="Contact Number"
                      v-model="model.contractor_contact_number" disabled>
          </base-input>
        </b-col>

        <b-col md="4">
          <base-input label="Email"
                      name="Email"
                      placeholder="Email"
                      v-model="model.contractor_contact_email" disabled>
          </base-input>
        </b-col>

        <b-col md="4">
          <base-input label="Status"
                      name="Status"
                      placeholder="Status"
                      v-model="model.active_status_show" disabled>
          </base-input>
        </b-col>
      </div>

      <base-button type="primary" @click="createData()">Add New Contractor To Site</base-button>
      <base-button type="primary" @click="$router.back()">Cancel</base-button>
      <!-- <div>
        <br>
        <h5 class="text-danger"> Note : <br><br>
                <i>
                1. By Default sequence is set to "1" you can set to any number you want.
                </i>
                <br>
                <i>
                2. The display and System Auto Change Milestone will follow sequence.
                </i>
        </h5>
      </div> -->
    </form>
  </card>
</template>
<script>
  import BaseSlider from '@/components/BaseSlider'
  import {LoginURL, clientId, clientSecret, apiDomain, getHeader, getcurrentDate} from '@/assets/js/config.js'
  import axios from 'axios'

  export default {
    components: {
      BaseSlider
    },
    created()
    {
      var currentDate = getcurrentDate()
      this.model.site_id = this.$route.params.id

      axios.get(apiDomain + 'project/project_site/' + this.model.site_id, { headers: getHeader() })
      .then ( response => {
        if (response.status === 200)
        {
          this.model.site_id = response.data.data[0].site_id
          this.model.site_name = response.data.data[0].site_name
          this.model.project_id = response.data.data[0].project.project_id
          this.model.project_name = response.data.data[0].project.project_name
          this.model.group_id = response.data.data[0].group.group_id
          this.model.group_name = response.data.data[0].group.group_name
        }
      })
      .catch ( error => {
        console.log(error.response.status)
        if ( error.response.status == 401)
        {
            this.$router.push('/login')
        }
      } );

      axios.get(apiDomain + 'setup/contractor.not_in_site_contractor/' + this.model.site_id, { headers: getHeader() })
      .then ( response => {
        if (response.status === 200)
        {
          this.contractors = response.data.data
        }
      })
      .catch ( error => {
        console.log(error.response.status)
        if ( error.response.status == 401)
        {
            this.$router.push('/login')
        }
      } );

    },
    data() {
      return {
        model: {
          project_id: null,
          project_name: null,
          group_id: null,
          group_name: null,
          site_id: null,
          site_name: null,
          site_info: null,
          contractor_id: 'x',
          contractor_code: null,
          contractor_name: null,
          contractor_contact_person: null,
          contractor_contact_number: null,
          contractor_contact_email: null,
          active_status_show: null,
          active_status: null,
        },
        contractors: [],
      }
    },
    methods: {
      getContractorDetail()
      {
          axios.get(apiDomain + 'setup/contractor/' + this.model.contractor_id, { headers: getHeader() })
          .then ( response => {
            if (response.status === 200)
            {
              this.model.contractor_code = response.data.data[0].contractor_code
              this.model.contractor_name = response.data.data[0].contractor_name
              this.model.contractor_contact_person = response.data.data[0].contractor_contact_person
              this.model.contractor_contact_number = response.data.data[0].contractor_contact_number
              this.model.contractor_contact_email = response.data.data[0].contractor_contact_email
              this.model.active_status_show = response.data.data[0].active_status
              if ( response.data.data[0].active_status == 'ACTIVE' )
              {
                  this.model.active_status = 1
              }
              else
              {
                  this.model.active_status = 0
              }
            }
          })
          .catch ( error => {
            console.log(error.response.status)
          } );
      },
      createData(){
        if ( this.model.contractor_id == 'x' )
        {
          this.$notify({
            message:
              '<b>Invalid Contractor : </b> - Please Select A Contractor to proceed.',
            timeout: 10000,
            icon: 'ni ni-bulb-61',
            type: 'danger',
          });
        }
        else
        {
          axios.post(apiDomain + 'project/site_contractor', this.model, { headers: getHeader() })
          .then ( response => {
            if (response.status === 200)
            {
                this.$notify({
                message:
                  '<b>New Site Contractor : ' + this.model.contractor_code + '</b> - Successfully Created.',
                timeout: 10000,
                icon: 'ni ni-bell-55',
                type: 'default',
                });
                this.$router.back()
            }
          })
          .catch ( error => {
              console.log(error.response.data.errors)
              this.$notify({
                message:
                  '<b>Invalid Input : ' + this.model.site_name + ' </b> - The Code already exists in the system, Please choose a different Code.',
                timeout: 10000,
                icon: 'ni ni-bulb-61',
                type: 'danger',
              });
          });
        }
      },
    }
  }
</script>
<style>
</style>
