<template>
  <div class="content">
    <base-header class="pb-6">
      <b-row align-v="center" class="py-4">
        <b-col cols="12">
          <h6 class="h2 text-white d-inline-block mb-0">{{ title }}</h6>
        </b-col>
      </b-row>
    </base-header>
    <b-container fluid class="mt--6">
      <card class="no-border-card" body-classes="px-0 pb-1" footer-classes="pb-2">
        <template slot="header">
          <h3 class="mb-0">{{ title }}</h3>
        </template>
        <b-col>
          <LoadingPanel v-if="loadingRoles"/>
          <template v-else>
            <div class="form-group">
              <label class="form-label">Role</label>
              <select class="form-control" v-model="roleId">
                <option v-for="role of roles" :key="role.id" :value="role.id">{{ role.id }} - {{ role.name }}</option>
              </select>
            </div>
            <LoadingPanel v-if="loadingModules"/>
            <template v-else>
              <table class="table table-bordered table-striped table-hover table-sm">
                <thead>
                  <tr>
                    <th>Modules</th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item of modules" :key="item.module_code">
                    <td>{{ item.module_code }}</td>
                    <td v-for="action of actions" :key="action">
                      <el-switch
                        v-model="item[action]"
                        :active-value="1"
                        :inactive-value="0"
                        :active-text="action">
                      </el-switch>
                    </td>
                    <td style="width: 1px">
                      <button class="btn btn-primary btn-sm" :disabled="updating" @click="toggleAll(item, 1)">Enable All</button>
                      &nbsp;
                      <button class="btn btn-light btn-sm" :disabled="updating" @click="toggleAll(item, 0)">Disable All</button>
                    </td>
                  </tr>
                </tbody>
              </table>
              <div class="my-3">
                <button class="btn btn-success" :disabled="updating" @click="update">
                  <template v-if="updating">Updating...</template>
                  <template v-else>Update</template>
                </button>
              </div>
            </template>
          </template>
        </b-col>
      </card>
    </b-container>
  </div>
</template>

<script>
import axios from '@/util/axios'
import ErrorFunctionMixin from '@/assets/js/errorFunctionMix.js'
import LoadingPanel from '@/components/LoadingPanel'
import { Switch } from 'element-ui'

export default {
  mixins: [ErrorFunctionMixin],
  components: {
    LoadingPanel,
    [Switch.name]: Switch,
  },

  data() {
    return {
      title: 'Role Access Listing',

      loadingRoles: false,
      roles: [],
      roleId: null,

      loadingModules: false,
      modules: [],
      actions: ['listing', 'create', 'show', 'edit', 'delete'],

      updating: false,
    }
  },

  watch: {
    roleId() {
      this.loadModules()
    },
  },

  created() {
    const userInfo = JSON.parse(localStorage.getItem("loginUser"))

    // Only admins are allowed.
    if (![1, 3].includes(userInfo.role_id)) {
      this.$router.replace('/')
      return
    }

    this.loadRoles(userInfo.role_id)
  },

  methods: {
    loadRoles(roleId) {
      this.loadingRoles = true

      axios.get('setup/role')
        .then(response => {
          this.roles = response.data.data.sort((a, b) => a.id - b.id)

          if (this.roles.length > 0) {
            this.roleId = roleId
          }
        })
        .catch(error => {
          this.errorFunction(error, this.title)
        })
        .then(() => this.loadingRoles = false)
    },

    loadModules() {
      if (!this.roleId) {
        this.modules = []
        return
      }

      this.loadingModules = true

      axios.get('setup/role_access/modules/' + this.roleId)
        .then(response => {
          this.modules = response.data.data.sort((a, b) => a.module_code.localeCompare(b.module_code))
        })
        .catch(error => {
          this.errorFunction(error, this.title)
        })
        .then(() => this.loadingModules = false)
    },

    toggleAll(module, value) {
      for (const action of this.actions)
        module[action] = value
    },

    update() {
      this.updating = true

      axios.post('setup/role_access/modules/' + this.roleId, this.modules)
        .then(response => {
          this.$notify({
            message: 'Updated',
            timeout: 3000,
            type: 'success',
          });
        })
        .catch(error => {
          this.errorFunction(error, this.title)
        })
        .then(() => this.updating = false)
    },
  },
}
</script>
