<template>
  <card>
    <h3 slot="header" v-if="myUserID.role_id == 3 || myUserID.role_id == 2 || myUserID.role_id == 1" class="mb-0">Edit Project Task Template Detail for ({{ task_template_name }}) </h3>
    <h3 slot="header" v-else class="mb-0">Edit Project Task Template Detail for ({{ task_template_name }}) </h3>
    <div class="text-right">
    </div>
    <form class="needs-validation"
          @submit.prevent="submit">

      <div class="form-row">
        <b-col md="8">
          <base-input label="Task Title"
                      name="Task Title"
                      placeholder="Task Title"
                      v-if="myUserID.role_id == 3 || myUserID.role_id == 2 || myUserID.role_id == 1"
                      v-model="model.project_task_title" required>
          </base-input>
          <base-input label="Task Title"
                      name="Task Title"
                      placeholder="Task Title"
                      v-else
                      v-model="model.project_task_title" required disabled>
          </base-input>
        </b-col>

        <b-col md="4">
          <base-input label="Task Status"
                      name="Task Status"
                      v-if="myUserID.role_id == 3 || myUserID.role_id == 2 || myUserID.role_id == 1"
                      v-model="model.status_id">
            <select class="form-control" v-model="model.status_id">
              <option Selected disabled value="x">-- Please Select Task Status --</option>
              <option v-for="item in task_status" :key="item.status_id" v-bind:value="item.status_id">{{ item.status_code }}</option>
            </select>
          </base-input>
          <base-input label="Task Status"
                      name="Task Status"
                      v-else
                      v-model="model.status_id">
            <select class="form-control" v-model="model.status_id" disabled>
              <option Selected disabled value="x">-- Please Select Task Status --</option>
              <option v-for="item in task_status" :key="item.status_id" v-bind:value="item.status_id">{{ item.status_code }}</option>
            </select>
          </base-input>
        </b-col>

        <b-col md="12">
          <base-input label="Description"
                      name="Title"
                      placeholder="Task Description"
                      v-if="myUserID.role_id == 3 || myUserID.role_id == 2 || myUserID.role_id == 1"
                      v-model="model.project_task_description" required>
          </base-input>
          <base-input label="Description"
                      name="Title"
                      placeholder="Task Description"
                      v-else
                      v-model="model.project_task_description" required disabled>
          </base-input>
        </b-col>
      </div>
      <div class="form-row">
        <b-col md="4">
          <b-form-checkbox
            id="Active-Status"
            v-if="myUserID.role_id == 3 || myUserID.role_id == 2 || myUserID.role_id == 1"
            v-model="model.active_status"
            name="Active-Status"
            value="1"
            unchecked-value="0">
            <b>Active Status</b>
          </b-form-checkbox>
          <b-form-checkbox
            id="Active-Status"
            v-else
            v-model="model.active_status"
            name="Active-Status"
            value="1"
            unchecked-value="0" disabled>
            <b>Active Status</b>
          </b-form-checkbox>
        </b-col>
      </div>
      <br>
      <base-button v-if="myUserID.role_id == 3 || myUserID.role_id == 2 || myUserID.role_id == 1" type="primary" @click="createData()">Update / Save</base-button>
      <base-button type="primary" @click="$router.back()">Cancel</base-button>
    </form>
  </card>
</template>
<script>
  import BaseSlider from '@/components/BaseSlider'
  import {LoginURL, clientId, clientSecret, apiDomain, getHeader} from '@/assets/js/config.js'
  import axios from 'axios'
  import ErrorFunctionMixin from '@/assets/js/errorFunctionMix.js'

  export default {
    mixins: [ErrorFunctionMixin],
    components: {
      BaseSlider
    },
    created()
    {
      const LoginUser = JSON.parse(window.localStorage.getItem('loginUser'))
      this.myUserID = LoginUser

      this.model.project_task_template_detail_id = this.$route.params.id
      axios.get(apiDomain + 'template/project_task_template_details/'+this.model.project_task_template_detail_id, { headers: getHeader() })
      .then ( response => {
        if (response.status === 200)
        {
          this.task_template_name = response.data.data[0].project_task_template.task_template_name
          this.model.project_task_template_id = response.data.data[0].project_task_template.project_task_template_id
          this.model.project_task_title = response.data.data[0].project_task_title
          this.model.status_id = response.data.data[0].status.status_id
          this.model.project_task_description = response.data.data[0].project_task_description

          if (response.data.data[0].active_status == "ACTIVE")
          {
              this.model.active_status = 1
          }
          else
          {
              this.model.active_status = 0
          }
        }
      })
      .catch ( error => {
          console.log(error.response.status)
          this.errorFunction(error, "Project Task Template Details Information")
      });

      this.getTaskStatus()

    },
    data() {
      return {
        model: {
          project_task_template_detail_id: null,
          project_task_template_id: null,
          project_task_title: null,
          project_task_description: null,
          document_type_id: 'x',
          status_id: 'x',
          active_status: 1,
        },
        task_status: [],
        task_template_name: null,
        myUserID: null,
      }
    },
    methods: {
      getTaskStatus()
      {
        axios.get(apiDomain + 'lookup/task_status', { headers: getHeader() })
        .then ( response => {
          if (response.status === 200)
          {
            this.task_status = response.data.data
          }
        })
        .catch ( error => {
          console.log(error.response.status)
          this.errorFunction(error, "Task Status Code", "Task Status")
        } );
      },
      createData(){
        if ( !this.model.project_task_title || this.model.status_id == 'x' )
        {
          this.$notify({
            message:
              '<b>Invalid Input : All Column are Mandatory in Project Task Template Details </b> - These field are required.',
            timeout: 10000,
            icon: 'ni ni-bulb-61',
            type: 'danger',
          });
        }
        else
        {
          axios.put(apiDomain + 'template/project_task_template_details/' + this.model.project_task_template_detail_id, this.model, { headers: getHeader() })
          .then ( response => {
            if (response.status === 200)
            {
                this.$notify({
                message:
                  '<b>Edit Project Task Template Details : ' + this.model.project_task_title + '</b> - Successfully Updated.',
                timeout: 10000,
                icon: 'ni ni-bell-55',
                type: 'default',
                });
                this.$router.back()
            }
          })
          .catch ( error => {
            console.log(error.response.data.errors)
            this.errorFunction(error, "Edit Project Task Template Details", this.model.project_task_title)
          });
        }
      },
    }
  }
</script>
<style>
</style>
