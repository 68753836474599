<template>
  <card>
    <!-- Card header -->
    <h3 slot="header" class="mb-0">Edit Project for ({{ model.project_name }})</h3>
    <div class="text-right">
    </div>
    <form class="needs-validation"
          @submit.prevent="submit">

      <div class="form-row">
        <b-col md="12">
          <base-input label="Project Name"
                      name="Project Name"
                      placeholder="Project Name"
                      v-model="model.project_name">
          </base-input>
        </b-col>
      </div>

      <div class="form-row">
        <b-col md="4">
          <base-input label="Project Manager"
                      name="Project Manager"
                      v-model="model.project_manager">
            <select class="form-control" v-model="model.project_manager">
              <option Selected disabled value="x">-- Please Select A Project Manager --</option>
              <option v-for="item in project_managers" :key="item.id" v-bind:value="item.id">{{ item.name }} - {{ item.email }}</option>
            </select>
          </base-input>
        </b-col>

        <b-col md="4">
          <base-input label="Engineer"
                      name="Engineer"
                      v-model="model.project_engineer">
            <select class="form-control" v-model="model.project_engineer">
              <option Selected disabled value="x">-- Please Select A Engineer --</option>
              <option v-for="item in project_engineer" :key="item.id" v-bind:value="item.id">{{ item.name }} - {{ item.email }}</option>
            </select>
          </base-input>
        </b-col>

        <b-col md="4">
          <base-input label="Safety Officer"
                      name="Safety Office"
                      v-model="model.project_safety">
            <select class="form-control" v-model="model.project_safety">
              <option Selected disabled value="x">-- Please Select A Safety Office --</option>
              <option v-for="item in project_safety" :key="item.id" v-bind:value="item.id">{{ item.name }} - {{ item.email }}</option>
            </select>
          </base-input>
        </b-col>
      </div>

      <div class="form-row">
        <b-col md="4">
          <base-input label="Country"
                      name="Country"
                      placeholder="Country"
                      v-model="model.project_country">
          </base-input>
        </b-col>

        <b-col md="4">
          <base-input label="Project Type"
                      name="Project Type"
                      v-model="model.project_type_id">
            <select class="form-control" v-model="model.project_type_id">
              <option Selected disabled value="x">-- Please Select A Type --</option>
              <option v-for="item in project_type" :key="item.project_type_id" v-bind:value="item.project_type_id">{{ item.project_type }}</option>
            </select>
          </base-input>
        </b-col>

        <b-col md="4">
          <base-input label="Project Category"
                      name="Project Category"
                      v-model="model.project_category_id">
            <select class="form-control" v-model="model.project_category_id">
              <option Selected disabled value="x">-- Please Select A Project Category --</option>
              <option v-for="item in project_category" :key="item.project_category_id" v-bind:value="item.project_category_id">{{ item.project_category }}</option>
            </select>
          </base-input>
        </b-col>
      </div>

      <div class="form-row">
        <b-col md="3">
          <base-input label="PPA Approved Capacity (kWp)"
                      name="PPA Approved Capacity (kWp)"
                      placeholder="PPA Approved Capacity (kWp)"
                      v-model="model.project_ppa_approved_capacity"
                      disabled>
          </base-input>
        </b-col>

        <!-- <b-col md="3">
          <base-input label="Project PV Capacity (kWP) [COMPUTED]"
                      name="Project PV Capacity (kWP)"
                      placeholder="Project PV Capacity (kWP)"
                      v-model="model.project_pv_capacity" type="number" step="any"
                      disabled>
          </base-input>
        </b-col> -->
        
        <b-col md="3">
          <base-input label="Project PV Capacity (kWP) [COMPUTED]"
                      name="Project PV Capacity (kWP)"
                      placeholder="Project PV Capacity (kWP)"
                      v-model="model.project_total_capacity" type="number" step="any"
                      disabled>
          </base-input>
        </b-col>

        <b-col md="5">
          <base-input label="Developer"
                      name="Developer"
                      v-model="model.developer_id">
            <select class="form-control" v-model="model.developer_id">
              <option Selected disabled value="x">-- Please Select A Developer --</option>
              <option v-for="item in developers" :key="item.developer_id" v-bind:value="item.developer_id">{{ item.developer_code }} - {{ item.developer_name }}</option>
            </select>
          </base-input>
        </b-col>

        <b-col md="1">
          <br>
          <base-button size="sm" type="primary" @click="checkDeveloper()">Developer Info</base-button>
        </b-col>
      </div>

      <div class="form-row">
        <b-col md="4">
          <base-input label="Project Status"
                      name="Project Status"
                      v-model="model.status_id">
            <select class="form-control" v-model="model.status_id">
              <option Selected disabled value="x">-- Please Select Project Status --</option>
              <option v-for="item in project_status" :key="item.status_id" v-bind:value="item.status_id">{{ item.status_code }}</option>
            </select>
          </base-input>
        </b-col>

        <b-col md="4">
          <base-input label="Project Progress % "
                      name="Project Progress"
                      placeholder="Project Progress"
                      v-model="model.project_progress" type="number" min="0" max="100">
          </base-input>
        </b-col>

        <b-col md="4">
          <base-input label="PPA Start Date"
                      name="PPA Start Date"
                      type="date"
                      v-model="model.project_ppa_start_date"
                      @change="changePpaCod()">
          </base-input>
        </b-col>
      </div>

      <div class="form-row">
        <b-col md="4">
          <base-input label="EPC Award Date"
                      name="EPC Award Date"
                      type="date"
                      v-model="model.project_start_date"
                      @change="changeEpcPac()">
          </base-input>
        </b-col>
        <b-col md="4">
          <base-input label="EPC Scheduled PAC Date"
                      name="EPC Scheduled PAC Date"
                      type="date"
                      v-model="model.project_end_date">
          </base-input>
        </b-col>

        <b-col md="4">
          <base-input label="PPA Contractual COD"
                      name="PPA Contractual COD"
                      type="date"
                      v-model="model.project_ppa_contractual_cod_date">
          </base-input>
        </b-col>
      </div>

      <div class="form-row">
        <b-col md="4">
        </b-col>

        <b-col md="4">
        </b-col>

        <b-col md="4">
          <base-input
            label="Site Handover Date"
            name="Site Handover Date"
            type="date"
            v-model="model.project_site_handover_date"
          >
          </base-input>
        </b-col>
      </div>

      <div class="form-row">
        <b-col md="5" v-if="task_drop_list == 'EMPTY'">
          <base-input label="Import From Project Task Template"
                      name="Project Task Template"
                      v-model="model.project_task_template_id">
            <select class="form-control" v-model="model.project_task_template_id">
              <option Selected disabled value="x">-- Please Select A Project Task Template --</option>
              <option v-for="item in task_templates" :key="item.project_task_template_id" v-bind:value="item.project_task_template_id"> {{ item.task_template_name }} (No of Task : {{ item.task_details_no }})</option>
            </select>
          </base-input>
        </b-col>
        <b-col md="5" v-else>
          <base-input label="Import From Project Task Template"
                      name="Project Task Template"
                      v-model="model.project_task_template_id">
            <select class="form-control" v-model="model.project_task_template_id" disabled>
              <option Selected disabled value="x">-- Please Select A Project Task Template --</option>
              <option v-for="item in task_templates" :key="item.project_task_template_id" v-bind:value="item.project_task_template_id"> {{ item.task_template_name }} (No of Task : {{ item.task_details_no }})</option>
            </select>
          </base-input>
        </b-col>

        <b-col md="1">
          <br>
          <base-button size="sm" type="primary" @click="checkTask()">Project Task Template</base-button>
        </b-col>

        <!-- <b-col md="5" v-if="document_drop_list == 'EMPTY'">
           <base-input label="Import From Project Document Template"
                      name="Project Document Template"
                      v-model="model.project_document_template_id">
            <select class="form-control" v-model="model.project_document_template_id">
              <option Selected disabled value="x">-- Please Select A Project Document Template --</option>
              <option v-for="item in document_templates" :key="item.project_document_template_id" v-bind:value="item.project_document_template_id">{{ item.document_template_name }} (No of Task : {{ item.document_details_no }})</option>
            </select>
          </base-input>
        </b-col>
        <b-col md="5" v-else>
           <base-input label="Import From Project Document Template"
                      name="Project Document Template"
                      v-model="model.project_document_template_id">
            <select class="form-control" v-model="model.project_document_template_id" disabled>
              <option Selected disabled value="x">-- Please Select A Project Document Template --</option>
              <option v-for="item in document_templates" :key="item.project_document_template_id" v-bind:value="item.project_document_template_id">{{ item.document_template_name }} (No of Task : {{ item.document_details_no }})</option>
            </select>
          </base-input>
        </b-col>

        <b-col md="1">
          <br>
          <base-button size="sm" type="primary" @click="checkDocument()">Project Doc. Template</base-button>
        </b-col> -->
      </div>


      <base-button type="primary" @click="createData()">Update / Save</base-button>
      <base-button type="primary" @click="$router.back()">Cancel</base-button>
      <div>
        <br>
        <h5 class="text-danger"> Note : <br><br>
                <i>
                1. By Default all Date is set to "TODAY.
                </i>
                <br>
                <i>
                2. Project Progress % is set to "0 %" By Default.
                </i>
                <br>
                <i>
                3. No of Task / Document could be different from the actual. (Owner of Template could make CHANGES anytime, Number show current No. of Task / Document).
                </i>
        </h5>
      </div>
    </form>
  </card>
</template>
<script>
  import BaseSlider from '@/components/BaseSlider'
  import {LoginURL, clientId, clientSecret, apiDomain, getHeader, getcurrentDate} from '@/assets/js/config.js'
  import axios from 'axios'
  import ErrorFunctionMixin from '@/assets/js/errorFunctionMix.js'

  export default {
    mixins: [ErrorFunctionMixin],
    components: {
      BaseSlider
    },
    created()
    {
      var currentDate = getcurrentDate()

      this.model.project_id = this.$route.params.id

      axios.get(apiDomain + 'project/project/'+this.model.project_id, { headers: getHeader() })
      .then ( response => {
      if (response.status === 200)
      {
          this.model.project_name = response.data.data[0].project_name
          this.model.project_name = response.data.data[0].project_name
          this.model.project_country = response.data.data[0].project_country
          this.model.project_manager = response.data.data[0].project_manager.id
          this.model.project_engineer = response.data.data[0].project_engineer.id
          this.model.project_safety = response.data.data[0].project_safety.id
          this.model.developer_id = response.data.data[0].developer.developer_id
          this.model.status_id = response.data.data[0].status.status_id
          this.model.project_category_id = response.data.data[0].project_category.project_category_id
          // this.model.project_pv_capacity = response.data.data[0].project_pv_capacity
          this.model.project_pv_capacity = parseFloat(response.data.data[0].project_pv_capacity.replace(/,/g, ""))
          this.model.project_total_capacity = response.data.data[0].project_total_capacity
          this.model.project_type_id = response.data.data[0].project_type.project_type_id
          this.model.project_start_date = response.data.data[0].project_start_date
          this.model.project_end_date = response.data.data[0].project_end_date

          this.model.project_ppa_start_date = response.data.data[0].project_ppa_start_date
          this.model.project_ppa_contractual_cod_date = response.data.data[0].project_ppa_contractual_cod_date
          this.model.project_site_handover_date = response.data.data[0].project_site_handover_date
          
          this.model.project_progress = response.data.data[0].project_progress

          this.model.project_ppa_approved_capacity = parseFloat(response.data.data[0].project_ppa_approved_capacity.replace(/,/g, ""))
          if ( response.data.data[0].project_document_template_id == null )
          {
            this.model.project_document_template_id = 'x'
            this.document_drop_list = 'EMPTY'
          }
          else
          {
            this.model.project_document_template_id = response.data.data[0].project_document_template_id
            this.document_drop_list = 'NO'
          }

          if ( response.data.data[0].project_task_template_id == null )
          {
            this.model.project_task_template_id = 'x'
            this.task_drop_list = 'EMPTY'
          }
          else
          {
             this.model.project_task_template_id = response.data.data[0].project_task_template_id
             this.task_drop_list = 'NO'
          }
          this.getProjectTaskList()
          this.getProjectDocumentList()

      }
      })
      .catch ( error => {
          console.log(error.response.status)
      });

      axios.get(apiDomain + 'setup/developer', { headers: getHeader() })
      .then ( response => {
        if (response.status === 200)
        {
          this.developers = response.data.data
        }
      })
      .catch ( error => {
        console.log(error.response.status)
        this.errorFunction(error, "Developer Information")
      } );

      axios.get(apiDomain + 'lookup/project_status', { headers: getHeader() })
      .then ( response => {
        if (response.status === 200)
        {
          this.project_status = response.data.data
        }
      })
      .catch ( error => {
        console.log(error.response.status)
        this.errorFunction(error, "Project Status")
      } );

      axios.get(apiDomain + 'lookup/user_list/Project_Manager', { headers: getHeader() })
      .then ( response => {
        if (response.status === 200)
        {
          this.project_managers = response.data.data
        }
      })
      .catch ( error => {
        console.log(error.response.status)
        this.errorFunction(error, "Project Manager")
      } );

      axios.get(apiDomain + 'lookup/user_list/Engineer', { headers: getHeader() })
      .then ( response => {
        if (response.status === 200)
        {
          this.project_engineer = response.data.data
        }
      })
      .catch ( error => {
        console.log(error.response.status)
        this.errorFunction(error, "Engineer Information")
      } );

      axios.get(apiDomain + 'lookup/user_list/Safety', { headers: getHeader() })
      .then ( response => {
        if (response.status === 200)
        {
          this.project_safety = response.data.data
        }
      })
      .catch ( error => {
        console.log(error.response.status)
        this.errorFunction(error, "Safety Officer Information")
      } );

      axios.get(apiDomain + 'setup/project_category', { headers: getHeader() })
      .then ( response => {
        if (response.status === 200)
        {
          this.project_category = response.data.data
        }
      })
      .catch ( error => {
        console.log(error.response.status)
        this.errorFunction(error, "Project Category")
      } );

      axios.get(apiDomain + 'setup/project_type', { headers: getHeader() })
      .then ( response => {
        if (response.status === 200)
        {
          this.project_type = response.data.data
        }
      })
      .catch ( error => {
        console.log(error.response.status)
        this.errorFunction(error, "Project Type")
      } );

    },
    data() {
      return {
        model: {
          project_id: null,
          project_name: null,
          project_name: null,
          project_country: null,
          project_category_id: 'x',
          project_pv_capacity: '0.0',
          project_total_capacity: '0.0',
          project_type_id: 'x',
          developer_id: 'x',
          status_id: 'x',
          project_manager: 'x',
          project_engineer: 'x',
          project_safety: 'x',
          project_progress: 0,
          project_start_date: null,
          project_end_date: null,
          project_ppa_start_date: null,
          project_ppa_contractual_cod_date: null,
          project_site_handover_date: null,
          project_task_template_id: 'x',
          project_document_template_id: 'x',

          project_ppa_approved_capacity: '0.0',
        },
        developers: [],
        project_status: [],
        project_managers: [],
        project_category: [],
        project_engineer: [],
        project_safety: [],
        project_type: [],
        task_templates: [],
        document_templates: [],
        task_drop_list: 'EMPTY',
        document_drop_list: 'EMPTY',
      }
    },
    methods: {
      checkTask()
      {
        if ( this.model.project_task_template_id == 'x' || this.model.project_task_template_id == 'y' )
        {
          this.redErrorNotify( '<b>Invalid Input : Project Task Template </b> - Please select a Template before dive into the Details' )
        }
        else
        {
          this.$router.push({path: '/templates/projectTaskTemplateDetailListing/'+this.model.project_task_template_id })
        }
      },
      checkDocument()
      {
        if ( this.model.document_template_id == 'x' || this.model.document_template_id == 'y' )
        {
          this.redErrorNotify('<b>Invalid Input : Project Document Template </b> - Please select a Template before dive into the Details')
        }
        else
        {
          this.$router.push({path: '/templates/projectDocumentTemplateDetailListing/'+this.model.project_document_template_id })
        }
      },
      getProjectTaskList()
      {
          axios.get(apiDomain + 'template/project_task_template', { headers: getHeader() })
          .then ( response => {
            if (response.status === 200)
            {

                this.task_templates = response.data.data
            }
          })
          .catch ( error => {
            console.log(error.response.status)
            this.errorFunction(error, "Project Task Template")
          } );
      },
      getProjectDocumentList()
      {
          axios.get(apiDomain + 'template/project_document_template', { headers: getHeader() })
          .then ( response => {
            if (response.status === 200)
            {
                this.document_templates = response.data.data
            }
          })
          .catch ( error => {
            console.log(error.response.status)
            this.errorFunction(error, "Project Document Template")
          } );
      },
      checkDeveloper()
      {
        if (this.model.developer_id == 'x')
        {
          this.$notify({
            message:
              '<b>Invalid Input : Developer </b> - Please select a Developer before dive into the Details',
            timeout: 10000,
            icon: 'ni ni-bulb-61',
            type: 'danger',
          });
        }
        else
        {
          this.$router.push({path: '/developerEdit/'+this.model.developer_id })
        }
      },
      createData(){
        if ( !this.model.project_name || this.model.developer_id == 'x' || this.model.status_id == 'x'
        || this.model.project_manager == 'x' )
        {
          if ( !this.model.project_name )
          {
            this.redErrorNotify( '<b>Invalid Input : Project Name Column is a Mandatory in Project </b>')
          }
          else if (this.model.developer_id == 'x' )
          {
            this.redErrorNotify( '<b>Invalid Input : Developer Column is a Mandatory in Project </b>' )
          }
          else if (this.model.status_id == 'x')
          {
            this.redErrorNotify( '<b>Invalid Input : Project Status is a Mandatory in Project </b>' )
          }
          else if (this.model.project_manager == 'x')
          {
            this.redErrorNotify( '<b>Invalid Input : Project Manager is a Mandatory in Project </b>' )
          }
          else if (this.model.project_engineer == 'x')
          {
            this.redErrorNotify( '<b>Invalid Input : Project Engineer is a Mandatory in Project </b>' )
          }
          else if (this.model.project_safety == 'x')
          {
            this.redErrorNotify( '<b>Invalid Input : Safety Officer is a Mandatory in Project </b>' )
          }
          else
          {
            this.redErrorNotify( '<b>Invalid Input : Please fill in all the Mandatory Column in Project </b>' )
          }
        }
        else if( this.model.project_progress < 0 || this.model.project_progress > 100 )
        {
          this.redErrorNotify('<b>Invalid Input : Project Progress </b> - Input Must be between 0 - 100')
        }
        else
        {
          if ( this.model.project_document_template_id == 'x' )
          {
            this.model.project_document_template_id = null
          }

          if ( this.model.project_task_template_id == 'x' )
          {
            this.model.project_task_template_id = null
          }

          axios.put(apiDomain + 'project/project/'+ this.model.project_id, this.model, { headers: getHeader() })
          .then ( response => {
            if (response.status === 200)
            {
              this.$notify({
              message:
                '<b>Update Project : ' + this.model.project_name + '</b> - Successfully Updated.',
              timeout: 10000,
              icon: 'ni ni-bell-55',
              type: 'default',
              });
              this.$router.back()
            }
          })
          .catch ( error => {
              console.log(error.response.data.errors)
              this.errorFunction(error, "Project Information")
          });
        }
      },
      changePpaCod() {
        const ppaCod = new Date(this.model.project_ppa_start_date);
        // ppaCod.setDate(ppaCod.getDate() + 270);
        // updated in change request 2 package 1 to 180 days
        ppaCod.setDate(ppaCod.getDate() + 180);
        const ppaCodMonth = String((ppaCod.getMonth()+1)).padStart(2, '0');
        const ppaCodDay = String(ppaCod.getDate()).padStart(2, '0');
        const ppaCodDate = ppaCod.getFullYear() + '-' + ppaCodMonth + '-' + ppaCodDay;
        this.model.project_ppa_contractual_cod_date = ppaCodDate;
      },
      changeEpcPac() {
        const epcPac = new Date(this.model.project_start_date);
        // epcPac.setDate(epcPac.getDate() + 180);
        // updated in change request 2 package 1 to 150 days
        epcPac.setDate(epcPac.getDate() + 150);
        const epcPacMonth = String((epcPac.getMonth()+1)).padStart(2, '0');
        const epcPacDay = String(epcPac.getDate()).padStart(2, '0');
        const epcPacDate = epcPac.getFullYear() + '-' + epcPacMonth + '-' + epcPacDay;
        this.model.project_end_date = epcPacDate;
      },
    }
  }
</script>
<style>
</style>
