
export default [
  {
    taskId: 'Action Item',
    taskName: 'Solar Ex',
    taskMilestone: 'Kick-Off',
    taskStatus: 'Waiting For Approval',
    taskDate: '01-Jan-2021',
    taskMandatory: 'YES',
    taskType: 'success',
    taskRevisionNo : 'V1.0'
  },
  {
    taskId: 'Tool Box Meeting',
    taskName: 'Solar Ex',
    taskMilestone: 'Kick-Off',
    taskStatus: 'Waiting For Approval',
    taskDate: '01-Jan-2021',
    taskMandatory: 'NO',
    taskType: 'danger',
    taskRevisionNo : 'V3.0'
  },
  {
    taskId: 'Site Document',
    taskName: 'Long Seng',
    taskMilestone: 'Design',
    taskStatus: 'Required Revision',
    taskDate: '01-Jan-2021',
    taskMandatory: 'YES',
    taskType: 'success',
    taskRevisionNo : 'V3.0'
  },
  {
    taskId: 'E-Permit To Work',
    taskName: 'Long Seng',
    taskMilestone: 'Installation',
    taskStatus: 'Approved',
    taskDate: '05-Jan-2021',
    taskMandatory: 'YES',
    taskType: 'success',
    taskRevisionNo : 'V2.0'
  },
  {
    taskId: 'Contractor Checklist',
    taskName: 'Solar Ex',
    taskMilestone: 'Installation',
    taskStatus: 'Approved',
    taskDate: '05-Jan-2021',
    taskMandatory: 'NO',
    taskType: 'danger',
    taskRevisionNo : 'V2.0'
  },


]
