var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',{staticClass:"bg-default shadow",attrs:{"no-body":""}},[_c('b-card-header',{staticClass:"bg-transparent border-0"},[(_vm.model.project_name == null)?_c('h3',{staticClass:"mb-0 text-white"},[_vm._v("Site Listing - Create New Site")]):_c('h3',{staticClass:"mb-0 text-white"},[_vm._v("Site Listing for ("+_vm._s(_vm.model.project_name)+")")])]),_c('el-table',{staticClass:"table-responsive table-dark",attrs:{"header-row-class-name":"thead-dark","data":_vm.queriedData}},[_c('el-table-column',{attrs:{"label":"Name","min-width":"200px","prop":"site_code","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',[_c('b-media',{staticClass:"align-items-center",attrs:{"no-body":""}},[_c('b-media-body',[_c('span',{staticClass:"font-weight-600 name mb-0 text-sm"},[_vm._v(_vm._s(row.site_name))])])],1)],1)]}}])}),_c('el-table-column',{attrs:{"label":"Group","min-width":"140px","prop":"group.group_name","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('b-media',{staticClass:"align-items-center",attrs:{"no-body":""}},[_c('b-media-body',[_c('span',{staticClass:"font-weight-600 name mb-0 text-sm"},[_vm._v(_vm._s(row.group.group_name))])])],1)]}}])}),_c('el-table-column',{attrs:{"label":"Information","min-width":"180px","prop":"group.group_engineer","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',[_c('b-media',{staticClass:"align-items-center",attrs:{"no-body":""}},[_c('b-media-body',[_c('span',{staticClass:"font-weight-600 name mb-0 text-sm"},[_vm._v("P.Manager: "+_vm._s(row.group.group_engineer.name))])])],1)],1),_c('div',[_c('b-media',{staticClass:"align-items-center",attrs:{"no-body":""}},[_c('b-media-body',[_c('span',{staticClass:"font-weight-600 name mb-0 text-sm"},[_vm._v("Postal.Code: "+_vm._s(row.site_postal_code))])])],1)],1),_c('div',[_c('b-media',{staticClass:"align-items-center",attrs:{"no-body":""}},[_c('b-media-body',[_c('span',{staticClass:"font-weight-600 name mb-0 text-sm"},[_vm._v("Capacity: "+_vm._s(row.site_total_capacity)+" KWp")])])],1)],1)]}}])}),_c('el-table-column',{attrs:{"label":"Progress","min-width":"190px","prop":"site_progress","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"d-flex align-items-center"},[_c('div',[_c('span',{staticClass:"font-weight-600 name mb-0 text-sm"},[_vm._v(_vm._s(row.site_progress)+"%   ")])]),_c('div',[(row.site_progress > 0 && row.site_progress < 50)?_c('base-progress',{attrs:{"min-width":"30px","type":"danger","value":row.site_progress}}):(row.site_progress >= 50 && row.site_progress <= 90)?_c('base-progress',{attrs:{"min-width":"30px","type":"info","value":row.site_progress}}):_c('base-progress',{attrs:{"min-width":"30px","type":"success","value":row.site_progress}})],1)]),_c('b-media',{staticClass:"align-items-center",attrs:{"no-body":""}},[_c('b-media-body',[_vm._v(" Status: "),_c('badge',{staticClass:"badge-dot mr-4",attrs:{"type":""}},[(row.status.status_code == 'Delayed')?_c('i',{staticClass:"bg-danger"}):(row.status.status_code == 'On-Hold')?_c('i',{staticClass:"bg-danger"}):(row.status.status_code == 'Reviewed')?_c('i',{staticClass:"bg-success"}):(row.status.status_code == 'Completed')?_c('i',{staticClass:"bg-success"}):_c('i',{staticClass:"bg-info"}),_c('span',{staticClass:" font-weight-600 name mb-0 text-white "},[_vm._v(_vm._s(row.status.status_code))])])],1)],1),_c('div',[_vm._v(" Est Turn-On Date: "),_c('b',[_vm._v(_vm._s(row.est_turn_on_date))])])]}}])}),_c('el-table-column',{attrs:{"min-width":"190px","align":"right","label":"Actions"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var $index = ref.$index;
var row = ref.row;
return _c('div',{staticClass:"d-flex"},[(_vm.button_access.site_show == 'YES')?_c('base-button',{attrs:{"size":"sm","type":"primary"},on:{"click":function($event){return _vm.projectSiteDashboard($index, row)}}},[_vm._v(" Dashboard ")]):_vm._e(),(_vm.button_access.site_contractor_listing == 'YES')?_c('base-button',{attrs:{"size":"sm","type":"primary"},on:{"click":function($event){return _vm.linkSiteContractor($index, row)}}},[_vm._v(" Contractor ")]):_vm._e()],1)}}])})],1),_c('b-card-footer',{staticClass:"py-2 d-flex justify-content-end bg-transparent"},[_c('base-pagination',{staticClass:"pagination-no-border",attrs:{"current":_vm.pagination.currentPage,"per-page":_vm.pagination.perPage,"total":_vm.total},on:{"change":function($event){return _vm.paginationChanged($event)}}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }