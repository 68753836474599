
export default [
  {
    taskId: 'Daily Manpower Update',
    taskName: 'Solar Ex',
    taskMilestone: 'Kick-Off',
    taskStatus: 'Waiting For Approval',
    taskFrequency: 'Daily',
    taskTotal: '4',
    taskDate: '28-Dec-2020'
  },
  {
    taskId: 'Daily PTW',
    taskName: 'Solar Ex',
    taskMilestone: 'Design',
    taskStatus: 'Waiting For Approval',
    taskFrequency: 'Daily',
    taskTotal: '4',
    taskDate: '29-Dec-2020'
  },
  {
    taskId: 'Daily Safety Checklist',
    taskName: 'Solar Ex',
    taskMilestone: 'Installation',
    taskStatus: 'Approved',
    taskFrequency: 'Daily',
    taskTotal: '9',
    taskDate: '30-Dec-2020'
  },
  {
    taskId: 'Weekly Progres Report',
    taskName: 'Solar Ex',
    taskMilestone: 'Installation',
    taskStatus: 'Approved',
    taskFrequency: 'Weekly',
    taskTotal: '1',
    taskDate: '01-Jan-2021'
  },

]
