<template>
<div>
    <b-card no-body class="bg-default shadow">
        <b-card-header class="bg-transparent border-0">
            <h3 v-if="model.project_name == null" class="mb-0 text-white">Site Document Listing - Create New Site Document</h3>
            <h3 v-else class="mb-0 text-white">Site Document Listing for ({{ model.project_name }})</h3>
        </b-card-header>

             <b-col cols="12" class="d-flex justify-content-center justify-content-sm-between flex-wrap">
              <div>
                <!-- <base-input v-model="searchQuery"
                            prepend-icon="fas fa-search"
                            placeholder="Search..." size="sm">
                </base-input> -->

              </div>
              <div class="text-right">
                <!-- <base-button size="sm" type="neutral" @click="newDocument()"
                v-if="button_access.new_button == 'YES'">Create New Site Document </base-button> -->
                <base-button size="sm" type="neutral" @click="getCreateData()">Refresh</base-button>
                <base-button size="sm" type="neutral" @click="showAllListing()">Show All</base-button>
              </div>
            </b-col>

        <el-table class="table-responsive table-dark"
                  header-row-class-name="thead-dark"
                  :data="queriedData">

              <el-table-column label="Title"
                             min-width="130px"
                             prop="document_title"
                             sortable>
                <template v-slot="{row}">
                    <b-media no-body class="align-items-center">
                        <b-media-body>
                            <span class="font-weight-600 name mb-0 text-sm">{{row.document_title}}</span>
                        </b-media-body>
                    </b-media>
                </template>
              </el-table-column>

              <el-table-column label="Site"
                             min-width="180px"
                             prop="site.site_name"
                             sortable>
                <template v-slot="{row}">
                    <b-media no-body class="align-items-center">
                        <b-media-body>
                            <span class="font-weight-600 name mb-0 text-sm">P: {{row.site.project.project_name}}</span>
                        </b-media-body>
                    </b-media>
                    <b-media no-body class="align-items-center">
                        <b-media-body>
                            <span class="font-weight-600 name mb-0 text-sm">G: {{row.site.group.group_name}}</span>
                        </b-media-body>
                    </b-media>
                    <b-media no-body class="align-items-center">
                        <b-media-body>
                            <span class="font-weight-600 name mb-0 text-sm">S: {{row.site.site_name}}</span>
                        </b-media-body>
                    </b-media>
                </template>
              </el-table-column>

              <el-table-column label="Document"
                             min-width="120px"
                             prop="uploaded_flag"
                             sortable>
                <template v-slot="{row}">
                    <b-media no-body class="align-items-center">
                        <b-media-body>
                            <span class="font-weight-600 name mb-0 text-sm">Upload: {{row.uploaded_flag}}</span>
                        </b-media-body>
                    </b-media>
                    <b-media no-body class="align-items-center">
                        <b-media-body>
                            <span class="font-weight-600 name mb-0 text-sm">{{row.document_progress}}</span>
                        </b-media-body>
                    </b-media>
                </template>
              </el-table-column>

              <el-table-column label="DOC. TYPE"
                             min-width="160px"
                             prop="document_type.document_type_code"
                             sortable>
                <template v-slot="{row}">
                    <b-media no-body class="align-items-center">
                        <b-media-body>
                            <span class="font-weight-600 name mb-0 text-sm">Type : {{row.document_type.document_type_code}}</span>
                        </b-media-body>
                    </b-media>
                    <b-media no-body class="align-items-center">
                        <b-media-body>
                            <span class="font-weight-600 name mb-0 text-sm">Category : {{row.document_category.document_category}}</span>
                        </b-media-body>
                    </b-media>
                    <b-media no-body class="align-items-center">
                        <b-media-body>
                            <span class="font-weight-600 name mb-0 text-sm">Assign : {{row.assign_to_user.name}}</span>
                        </b-media-body>
                    </b-media>
                </template>
              </el-table-column>

              <el-table-column label="Milestone"
                             min-width="140px"
                             prop="milestone.milestone_code"
                             sortable>
                <template v-slot="{row}">
                    <b-media no-body class="align-items-center">
                        <b-media-body>
                            <span class="font-weight-600 name mb-0 text-sm">{{row.milestone.milestone_code}}</span>
                        </b-media-body>
                    </b-media>
                </template>
              </el-table-column>

              <el-table-column label="Last Update & Status"
                           min-width="195px"
                             prop="updated_at"
                             sortable>
                <template v-slot="{row}">
                   <div class="d-flex align-items-center">
                        <div>
                          <span class="font-weight-600 name mb-0 text-sm">Mandatory: {{row.show_document_mandatory}} </span>
                        </div>
                    </div>
                    <b-media no-body class="align-items-center">
                        <b-media-body>
                              <span class="font-weight-600 name mb-0 text-sm text-white">Status : {{row.document_status.status_code}}</span>
                        </b-media-body>
                    </b-media>
                    <div class="d-flex align-items-center">
                        <div>
                          <span class="font-weight-600 name mb-0 text-sm">Last Update : {{row.updated_at}} </span>
                        </div>
                    </div>
                </template>
              </el-table-column>

              <el-table-column min-width="180px" align="right" label="Actions">
                <div slot-scope="{$index, row}" class="d-flex">
                  <div>
                  <base-button v-if="button_access.show_button == 'YES'"
                    @click.native="handleDetails($index, row)" size="sm" type="primary">
                    View / <br>Edit
                  </base-button>
                  </div>
                  &nbsp;
                  <div v-if="button_access.upload_listing == 'YES'">
                   <base-button v-if="row.document_type.document_type_id == '1'"
                    @click.native="handleDocDetails($index, row)" size="sm" type="primary">
                    Doc.<br>Details
                    </base-button>
                  </div>
                  &nbsp;
                  <div v-if="button_access.recurring_listing == 'YES'">
                  <base-button v-if="row.document_type.document_type_id == '2'"
                    @click.native="handleRecurringDetails($index, row)" size="sm" type="primary">
                    Recur.<br>Details
                  </base-button>
                  </div>
                  &nbsp;
                  <div>
                  <base-button v-if="((button_access.delete_button == 'YES' && row.site.group.group_engineer.email == myUserID.email && row.uploaded_flag == 'NO') || myUserID.role_id == 3 || myUserID.role_id == 1)"
                    @click.native="handleDelete($index, row)" size="sm" type="primary">
                    Delete<br> Doc.
                  </base-button>
                  </div>
                </div>
              </el-table-column>
        </el-table>

        <b-card-footer class="py-2 d-flex justify-content-end bg-transparent">
              <base-pagination
              class="pagination-no-border"
              :current="pagination.currentPage"
              :per-page="pagination.perPage"
              :total="total"
              @change="paginationChanged($event)">
            </base-pagination>
        </b-card-footer>
    </b-card>
    <modal :show.sync="modals.delete_forms"
                size="lg"
                body-classes="p-0">
      <card type="secondary"
            header-classes="bg-transparent pb-5"
            body-classes="px-lg-5 py-lg-5"
            class="border-0 mb-0">
        <template>
          <div class="text-center text-muted mb-4" >
            <h3>Are You SURE you want to delete this Document ? </h3>
            <h3>with Title : {{modals.delete_title}} </h3>
          </div>
          <b-form role="form">

            <h5 class="text-danger"> Note : <br>
              <i>
              1. Once Delete You cannot reverse the process.

              </i>
            </h5>
            <div class="text-center">
              <base-button type="danger" class="my-4" @click="deleteRow()">YES</base-button>
              <base-button type="default" class="my-4" @click="cancelDelete()">NO</base-button>
            </div>
          </b-form>
        </template>
      </card>
    </modal>
</div>

</template>

<script>

  import { Table, TableColumn, DropdownMenu, DropdownItem, Dropdown, Select, Option} from 'element-ui'

  import RouteBreadCrumb from '@/components/Breadcrumb/RouteBreadcrumb'
  import { BasePagination } from '@/components';
  import clientPaginationMixin from './js/ProjectSitePaginationMix'
  import Fuse from 'fuse.js';
  import axios from 'axios';
  import { Modal } from '@/components';
  import {homeLink, LoginURL, clientId, clientSecret, apiDomain, getHeader} from '@/assets/js/config.js'
  import ErrorFunctionMixin from '@/assets/js/errorFunctionMix.js'

  export default {
    mixins: [clientPaginationMixin, ErrorFunctionMixin],
    components: {
      BasePagination,
      RouteBreadCrumb,
      Modal,
      [Select.name]: Select,
      [Option.name]: Option,
      [Table.name]: Table,
      [TableColumn.name]: TableColumn
    },
    created()
    {
        this.model.project_id = this.$route.params.id
        var site_document_access = JSON.parse(window.localStorage.getItem('site_document_access'))
        this.button_access.new_button = site_document_access.access_create
        this.button_access.show_button = site_document_access.access_show
        this.button_access.delete_button = site_document_access.access_delete

        var site_document_upload_access = JSON.parse(window.localStorage.getItem('site_document_upload_access'))
        var site_document_recurring_access = JSON.parse(window.localStorage.getItem('site_document_recurring_access'))

        this.button_access.upload_listing = site_document_upload_access.access_listing
        this.button_access.recurring_listing = site_document_recurring_access.access_listing
        const LoginUser = JSON.parse(window.localStorage.getItem('loginUser'))
        this.myUserID = LoginUser

        this.getCreateData()
    },
    data() {
      return {
        propsToSearch:
        [
          'document_title',
          'site.project.project_name',
          'site.group.group_name',
          'site.site_name',
          'uploaded_flag',
          'milestone.milestone_code',
          'document_type.document_type_code',
          'document_mandatory',
          'status.status_code',
          'updated_at',
        ],
        tableColumns: [],
        tableData: [],
        selectedRows: [],
        model:
        {
          project_id: null,
          project_name: null,
        },
        myUserID: null,
        button_access:{
          new_button: null,
          show_button: null,
          delete_button: null,
          upload_listing: null,
          recurring_listing: null,
        },
        modals: 
        {
          delete_id: null,
          delete_title: null,
          delete_forms: false,
        },
      };
    },
    methods:
    {
      handleDelete(index, row)
      {
        this.modals.delete_title = row.document_title
        this.modals.delete_id = row.site_document_id
        this.modals.delete_forms = true
      },
      cancelDelete()
      {
        this.modals.delete_title = null
        this.modals.delete_id = null
        this.modals.delete_forms = false
      },
      deleteRow(index, row)
      {
        axios.delete(apiDomain + 'document/site_document/'+ this.modals.delete_id , { headers: getHeader() })
        .then ( response => {
          console.log(response)
          if (response.status === 200)
          {
            this.$notify({
            message:
              '<b>Site Document : </b> - Successfully DELETED.',
            timeout: 10000,
            icon: 'ni ni-bell-55',
            type: 'default',
            });
            this.getCreateData()
            this.$emit("EventDocumentListUpdate")
          }
        })
        .catch ( error => {
            console.log(error.response.status)
            this.errorFunction(error, 'Delete Site Task')
        } );
        this.modals.delete_forms = false
      },
      fuseData()
      {
          this.fuseSearch = new Fuse(this.tableData, {
          keys: this.propsToSearch,
          threshold: 0.3
          });
      },
      getCreateData()
      {
          axios.get(apiDomain + 'document/site_document.show_by_project/' + this.model.project_id, { headers: getHeader() })
          .then ( response => {
          if (response.status === 200)
          {
              this.tableData = response.data.data
              this.model.project_name = response.data.data[0].site.project.project_name
              this.fuseData()
          }
          })
          .catch ( error => {
          console.log(error.response.status)
          this.errorFunction(error, "Document Listing")
          });
      },
      newDocument()
      {
        this.$router.push({path: '/siteDocumentNewFrProject/' + this.model.project_id })
      },
      documentDetails(index, row)
      {
          this.$router.push({path: '/documentDetailsListing/' + row.document_id })
      },
      paginationChanged(page)
      {
        this.pagination.currentPage = page
      },
      showAllListing()
      {
        let route = this.$router.resolve({path: '/siteDocumentListingAll/' + this.model.project_id })
        window.open(route.href, '_blank');
      },
      handleDetails(index, row)
      {
        let route = this.$router.resolve({path: '/siteDocumentEdit/' + row.site_document_id })
        window.open(route.href, '_blank');
      },
      handleDocDetails(index, row)
      {
        let route = this.$router.resolve({path: '/siteDocumentDetailsListing/' + row.site_document_id + '/' + 'ONE-TIME' })
        window.open(route.href, '_blank');
      },
      handleRecurringDetails(index, row)
      {
        let route = this.$router.resolve({path: '/siteDocumentRecurringListing/' + row.site_document_id })
        window.open(route.href, '_blank');
      },
      notready() {
          this.$notify({
          message:
            '<b>Under Developer : </b> - This function is not is currently Under Developer - Work in Progress.',
          timeout: 10000,
          icon: 'ni ni-bulb-61',
          type: 'danger',
          });
      },
    },
  }
</script>
