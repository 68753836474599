<template>
  <div>
    <card>
      <h3 slot="header" class="mb-0">
        Edit Site for ({{ model.project_name }} - {{ model.group_name }}) -
        General
      </h3>
      <div class="text-right"></div>
      <form class="needs-validation" @submit.prevent="submit">
        <div class="form-row">
          <b-col md="5">
            <base-input
              label="Project"
              name="Project"
              v-model="model.project_id"
            >
              <select
                class="form-control"
                v-model="model.project_id"
                @change="getGroupFromProject()"
                disabled
              >
                <option Selected disabled value="x">
                  -- Please Select A Project --
                </option>
                <option
                  v-for="item in projects"
                  :key="item.project_id"
                  v-bind:value="item.project_id"
                >
                  {{ item.project_name }}
                </option>
              </select>
            </base-input>
          </b-col>

          <b-col md="1">
            <br />
            <base-button size="sm" type="primary" @click="checkProject()"
              >Project Infomation</base-button
            >
          </b-col>

          <b-col md="5">
            <base-input
              label="Town Council"
              name="Town Council"
              v-model="model.group_id"
            >
              <select
                class="form-control"
                v-model="model.group_id"
                @change="getGroupProjectEngineer()"
                disabled
              >
                <option Selected disabled value="x">
                  -- Please Select A Project To Show Group --
                </option>
                <option Selected disabled value="y">
                  -- Please Select A Group --
                </option>
                <option
                  v-for="item in project_groups"
                  :key="item.group_id"
                  v-bind:value="item.group_id"
                >
                  {{ item.group_name }}
                </option>
              </select>
            </base-input>
          </b-col>

          <b-col md="1">
            <br />
            <base-button size="sm" type="primary" @click="checkGroup()"
              >Group Infomation</base-button
            >
          </b-col>
        </div>

        <div class="form-row">
          <b-col md="6">
            <base-input
              label="Site Name"
              name="Site Name"
              placeholder="Site Name"
              v-model="model.site_name"
            >
            </base-input>
          </b-col>

          <b-col md="6">
            <base-input
              label="Site Type"
              name="Site Type"
              placeholder="Site Type"
              v-model="model.site_type"
            >
            </base-input>
          </b-col>
        </div>

        <div class="form-row">
          <b-col md="6">
            <base-input
              label="Project Engineer (P.I.C)"
              name="Project Engineer (P.I.C)"
              v-model="model.created_by.name"
              readonly
            >
            </base-input>
          </b-col>

          <b-col md="6">
          </b-col>
        </div>

        <div class="form-row">
          <b-col md="6">
            <base-input
              label="Target Turn-On Date"
              name="Target Turn-On Date"
              type="date"
              v-model="model.site_target_turn_on_date"
            >
            </base-input>
          </b-col>

          <b-col md="6">
            <base-input label="Actual Turn-On Date"
                        name="Actual Turn-On Date"
                        type="date"
                        v-model="model.site_actual_turn_on_date"
                        v-if="model.status_id != 5 && model.status_id != 7"               
                        disabled>
            </base-input>
            <base-input label="Actual Turn-On Date"
                        name="Actual Turn-On Date"
                        type="date"
                        v-model="model.site_actual_turn_on_date"
                        v-else>
            </base-input>
          </b-col>
        </div>

        <div class="form-row">
          <b-col md="6"
            ><base-input
              label="Precinct"
              name="Precinct"
              placeholder="Precinct"
              v-model="model.site_precinct"
            >
            </base-input
          ></b-col>
          <b-col md="6"
            ><base-input
              label="Postal Code"
              name="Postal Code"
              placeholder="Postal Code"
              v-model="model.site_postal_code"
            >
            </base-input
          ></b-col>
        </div>

        <div class="form-row">
          <b-col md="4"
            ><base-input
              label="Block Number"
              name="Block Number"
              placeholder="Block Number"
              v-model="model.site_block"
            >
            </base-input
          ></b-col>
          <b-col md="4"
            ><base-input
              label="Height Level (Storey)"
              name="Height Level (Storey)"
              placeholder="Height Level (Storey)"
              v-model="model.site_height_level_storey"
            >
            </base-input
          ></b-col>
          <b-col md="4"
            ><base-input
              label="Address"
              name="Address"
              placeholder="Address"
              v-model="model.site_address"
            >
            </base-input
          ></b-col>
        </div>

        <div class="form-row">
          <b-col md="6">
            <base-input label="Site Status"
                      name="Site Status"
                      v-model="model.status_id">
            <div v-if="model.status_id == '7'">
              <select class="form-control" v-model="model.status_id" disabled>
                <option Selected disabled value="x">-- Please Select Site Status --</option>
                <option v-for="item in site_status_approved" :key="item.status_id" v-bind:value="item.status_id">{{ item.status_code }}</option>
              </select>
            </div>
            <div v-else-if="model.status_id == '5'">
              <select class="form-control" v-model="model.status_id">
                <option Selected disabled value="x">-- Please Select Site Status --</option>
                <option v-for="item in site_status_completed_approved" :key="item.status_id" v-bind:value="item.status_id">{{ item.status_code }}</option>
              </select>
            </div>
            <div v-else>
              <select class="form-control" v-model="model.status_id">
                <option Selected disabled value="x">-- Please Select Site Status --</option>
                <option v-for="item in site_status" :key="item.status_id" v-bind:value="item.status_id">{{ item.status_code }}</option>
              </select>
            </div>

          </base-input>
          </b-col>

          <b-col md="6">
            <base-input
              label="Site Progress % "
              name="Site Progress"
              placeholder="Site Progress"
              v-model="model.site_progress"
              type="number"
              min="0"
              max="100"
              disabled
            >
            </base-input>
          </b-col>
        </div>

        <div class="form-row">
          <b-col md="6">
            <base-input
              label="EPC Site Start Date"
              name="EPC Site Start Date"
              type="date"
              v-model="model.site_contractor_start_date"
            >
            </base-input>
          </b-col>

          <b-col md="6">
            <base-input
              label="EPC Site End Date"
              name="EPC Site End Date"
              type="date"
              v-model="model.site_contractor_end_date"
            >
            </base-input>
          </b-col>
        </div>

        <div class="form-row">
          <b-col md="6">
            <base-input
              label="Site Total Capacity (KWp)"
              name="Site Total Capacity (KWp)"
              placeholder="Site Total Capacity (KWp)"
              v-model="model.site_total_capacity"
            >
            <!-- readonly -->
            </base-input>
          </b-col>

          <b-col md="6">
            <base-input
              label="Module Brand Name"
              name="Module Brand Name"
              v-model="model.site_module_brand_id"
            >
              <select class="form-control" v-model="model.site_module_brand_id">
                <option Selected disabled value=null>
                  -- Please Select A Module Brand --
                </option>
                <option
                  v-for="item in module_brands"
                  :key="item.module_brand_id"
                  v-bind:value="item.module_brand_id"
                >
                  {{ item.module_brand_id_name }}
                </option>
              </select>
            </base-input>
          </b-col>
        </div>

        <base-button type="primary" @click="createData()"
          >Update / Save</base-button
        >
        <base-button type="primary" @click="$router.back()">Cancel</base-button>
        <div>
          <br />
          <h5 class="text-danger">
            Note : <br /><br />
            <i>
              1. By Default all Date is set to "TODAY, and Default date format
              will me DD/MM/YYYY.
            </i>
            <br />
            <i> 2. Project Site Progress % is set to calculated based on site progress and weightage. </i>
            <br />
            <i>
              3. No of Task / Document could be different from the actual.
              (Owner of Template could make CHANGES anytime, Number show current
              No. of Task / Document).
            </i>
          </h5>
        </div>
      </form>
    </card>
  </div>
</template>
<script>
import BaseSlider from "@/components/BaseSlider";
import {
  LoginURL,
  clientId,
  clientSecret,
  apiDomain,
  getHeader,
  getcurrentDate,
} from "@/assets/js/config.js";
import axios from "axios";
import ErrorFunctionMixin from "@/assets/js/errorFunctionMix.js";

export default {
  mixins: [ErrorFunctionMixin],
  components: {
    BaseSlider,
  },
  created() {
    // this.calculateSiteTotalCapacity();
    var currentDate = getcurrentDate();

    // this.model.site_start_date = currentDate;
    // this.model.site_end_date = currentDate;
    this.model.site_contractor_start_date = currentDate;
    this.model.site_contractor_end_date = currentDate;
    // this.model.site_elu_end_date = currentDate;
    // this.model.site_el_expiry_date = currentDate;

    axios
      .get(apiDomain + "project/project", { headers: getHeader() })
      .then((response) => {
        if (response.status === 200) {
          this.projects = response.data.data;
        }
      })
      .catch((error) => {
        console.log(error.response.status);
        this.errorFunction(error, "Project Information");
      });

    axios
      .get(apiDomain + "lookup/site_status", { headers: getHeader() })
      .then((response) => {
        if (response.status === 200) {
          this.site_status = response.data.data;
          this.site_status_completed_approved = this.site_status.filter(function (el)
          {
            return (el.status_id == 5 || el.status_id == 7);
          });
          this.site_status_approved = this.site_status.filter(function (el)
          {
            return (el.status_id == 7);
          });
        }
      })
      .catch((error) => {
        console.log(error.response.status);
        this.errorFunction(error, "Site Status Information");
      });

    axios
      .get(apiDomain + "lookup/user_list/Project_Lead", {
        headers: getHeader(),
      })
      .then((response) => {
        if (response.status === 200) {
          this.site_leads = response.data.data;
        }
      })
      .catch((error) => {
        console.log(error.response.status);
        this.errorFunction(error, "Project User Information");
      });

    this.model.site_id = this.$route.params.id;

    this.getProjectSiteSNData();
  },
  data() {
    return {
      model: {
        site_id: null,
        project_id: "x",
        project_name: null,
        group_id: "x",
        group_name: null,
        site_name: null,
        site_leader: "-- Please Select A Project & Group --",
        site_postal_code: null,
        site_precinct: null,
        site_block: null,
        site_height_level_storey: null,
        site_address: null,
        site_progress: 1,
        milestone_id: "x",
        site_total_capacity: 1,
        site_mssl_account_number: null,
        site_plant_name: null,
        site_folder_in_sftp_server: null,
        site_inverter_type: null,
        site_inverter_qty: 1,
        site_contractor_start_date: null,
        site_contractor_end_date: null,
        site_target_turn_on_date: null,
        site_actual_turn_on_date: null,
        site_elu_license_number: null,
        site_elu_status: null,
        status_id: 1,
        task_template_id: "x",
        document_template_id: "x",
        created_by: null,
        site_module_brand_id: null,
        site_module_brand: {
          module_brand_id_name: null,
        },
      },
      module_brands: [],
      projects: [],
      project_groups: [],
      site_status: [],
      site_status_completed_approved: [],
      site_status_approved: [],
      site_leads: [],
      project_milestones: [],
      task_templates: [],
      document_templates: [],
      task_drop_list: "EMPTY",
      document_drop_list: "EMPTY",
      tempData: null,
    };
  },
  methods: {
    getModuleBrandList(){
      axios.get(apiDomain + "setup/module_brand", { headers: getHeader() })
      .then((response) => {
        if (response.status === 200) {
          this.module_brands = response.data.data;
        }
      })
      .catch((error) => {
        console.log(error.response.status);
        this.errorFunction(error, "Module Brand Information");
      });
    },
    getProjectGroupByProject() {
      axios
        .get(
          apiDomain +
            "project/project_group.show_by_project/" +
            this.model.project_id,
          { headers: getHeader() }
        )
        .then((response) => {
          if (response.status === 200) {
            this.project_groups = response.data.data;
          }
        })
        .catch((error) => {
          console.log(error.response.status);
          this.errorFunction(error, "Project Group Information");
        });
    },
    getProjectSiteSNData() {
      axios
        .get(apiDomain + "project/project_site_sn/" + this.model.site_id, {
          headers: getHeader(),
        })
        .then((response) => {
          if (response.status === 200) {
            this.tempData = response.data.data;
            this.model.project_id = response.data.data[0].project.project_id;
            this.model.project_name =
              response.data.data[0].project.project_name;

            this.model.site_name = response.data.data[0].site_name;
            this.model.site_type = response.data.data[0].site_type;
            this.model.created_by = response.data.data[0].created_by;
            this.model.group_id = response.data.data[0].group.group_id;
            this.model.group_name = response.data.data[0].group.group_name;
            this.model.site_precinct = response.data.data[0].site_precinct;
            this.model.site_postal_code =
              response.data.data[0].site_postal_code;
            this.model.site_block = response.data.data[0].site_block;
            this.model.site_height_level_storey =
              response.data.data[0].site_height_level_storey;
            this.model.site_address = response.data.data[0].site_address;
            this.model.site_progress = response.data.data[0].site_progress;
            this.model.site_total_capacity =
              response.data.data[0].site_total_capacity;
            this.model.status_id = response.data.data[0].status.status_id;
            this.model.site_contractor_start_date =
              response.data.data[0].site_contractor_start_date;
            this.model.site_contractor_end_date =
              response.data.data[0].site_contractor_end_date;
            this.model.site_target_turn_on_date =
              response.data.data[0].site_target_turn_on_date;
            this.model.site_actual_turn_on_date =
              response.data.data[0].site_actual_turn_on_date;              
            this.model.site_leader =
              response.data.data[0].group.group_engineer.name;
            this.model.created_by = response.data.data[0].created_by;
            this.model.site_module_brand_id =
              response.data.data[0].site_module_brand.module_brand_id;
            this.model.site_module_brand.module_brand_id_name =
              response.data.data[0].site_module_brand.module_brand_id_name;
            this.getProjectGroupByProject();
            this.getModuleBrandList();
          }
        })
        .catch((error) => {
          console.log(error.response.status);
          this.errorFunction(error, "Project Site SN");
        });
    },
    getProjectTaskList() {
      axios
        .get(apiDomain + "template/task_template", { headers: getHeader() })
        .then((response) => {
          if (response.status === 200) {
            this.task_templates = response.data.data;
          }
        })
        .catch((error) => {
          console.log(error.response.status);
          this.errorFunction(error, "Site Task Template");
        });
    },
    getProjectDocumentList() {
      axios
        .get(apiDomain + "template/document_template", { headers: getHeader() })
        .then((response) => {
          if (response.status === 200) {
            this.document_templates = response.data.data;
          }
        })
        .catch((error) => {
          console.log(error.response.status);
          this.errorFunction(error, "Site Document Template");
        });
    },
    checkTask() {
      if (
        this.model.task_template_id == "x" ||
        this.model.task_template_id == "y"
      ) {
        this.redErrorNotify(
          "<b>Invalid Input : Task Template </b> - Please select a Template before dive into the Details"
        );
      } else {
        this.$router.push({
          path:
            "/templates/taskTemplateDetailListing/" +
            this.model.task_template_id,
        });
      }
    },
    checkDocument() {
      if (
        this.model.document_template_id == "x" ||
        this.model.document_template_id == "y"
      ) {
        this.redErrorNotify(
          "<b>Invalid Input : Document Template </b> - Please select a Template before dive into the Details"
        );
      } else {
        this.$router.push({
          path:
            "/templates/documentTemplateDetailListing/" +
            this.model.document_template_id,
        });
      }
    },
    calculateSiteTotalCapacity() {
      this.model.site_total_capacity =
        (this.model.site_module_quantity * this.model.site_module_capacity) /
        1000;
    },
    getGroupFromProject() {
      axios
        .get(
          apiDomain +
            "project/project_group.show_by_project/" +
            this.model.project_id,
          { headers: getHeader() }
        )
        .then((response) => {
          if (response.status === 200) {
            this.project_groups = response.data.data;
            this.model.group_id = "y";
          }
        })
        .catch((error) => {
          console.log(error.response.status);
        });

      axios
        .get(
          apiDomain +
            "project/project_milestone.show_by_project/" +
            this.model.project_id,
          { headers: getHeader() }
        )
        .then((response) => {
          if (response.status === 200) {
            this.project_milestones = response.data.data;
            this.model.milestone_id = "y";
          }
        })
        .catch((error) => {
          console.log(error.response.status);
        });
    },
    checkProject() {
      if (this.model.project_id == "x") {
        this.$notify({
          message:
            "<b>Invalid Input : Project </b> - Please select a Project before dive into the Details",
          timeout: 10000,
          icon: "ni ni-bulb-61",
          type: "danger",
        });
      } else {
        this.$router.push({ path: "/projectEdit/" + this.model.project_id });
      }
    },
    checkGroup() {
      if (this.model.group_id == "x" || this.model.group_id == "y") {
        this.$notify({
          message:
            "<b>Invalid Input : Project Group </b> - Please select a Project Group before dive into the Details",
          timeout: 10000,
          icon: "ni ni-bulb-61",
          type: "danger",
        });
      } else {
        this.$router.push({ path: "/projectGroupEdit/" + this.model.group_id });
      }
    },
    checkMilestone() {
      if (this.model.milestone_id == "x" || this.model.milestone_id == "y") {
        this.$notify({
          message:
            "<b>Invalid Input : Project Milestone </b> - Please select a Project Milestone before dive into the Details",
          timeout: 10000,
          icon: "ni ni-bulb-61",
          type: "danger",
        });
      } else {
        this.$router.push({
          path: "/projectMilestoneEdit/" + this.model.milestone_id,
        });
      }
    },
    createData() {
      if (
        !this.model.site_name ||
        this.model.project_id == "x" ||
        this.model.status_id == "x" ||
        this.model.group_id == "x" ||
        this.model.group_id == "y" ||
        !this.model.site_postal_code ||
        !this.model.site_address ||
        !this.model.site_total_capacity
      ) {
        if (
          this.model.project_id == "x" ||
          this.model.group_id == "x" ||
          this.model.group_id == "y"
        ) {
          this.redErrorNotify(
            "<b>Invalid Input : Project & Group Column is a Mandatory in Project Site </b>"
          );
        } else if (!this.model.site_name) {
          this.redErrorNotify(
            "<b>Invalid Input : Site Name Column is a Mandatory in Project Site </b>"
          );
        } else if (!this.model.site_postal_code) {
          this.redErrorNotify(
            "<b>Invalid Input : Site Postal Column is a Mandatory in Project Site </b>"
          );
        } else if (!this.model.site_address) {
          this.redErrorNotify(
            "<b>Invalid Input : Site Address Column is a Mandatory in Project Site </b>"
          );
        } else {
          this.redErrorNotify(
            "<b>Invalid Input : All Column is a Mandatory in Project Site </b>"
          );
        }
      } else {
        if (this.model.task_template_id == "x") {
          this.model.task_template_id = null;
        }

        if (this.model.document_template_id == "x") {
          this.model.document_template_id = null;
        }

        axios
          .put(
            apiDomain + "project/project_site/" + this.model.site_id,
            this.model,
            { headers: getHeader() }
          )
          .then((response) => {
            if (response.status === 200) {
              this.$notify({
                message:
                  "<b>Update Project Site : " +
                  this.model.site_name +
                  "</b> - Successfully Created.",
                timeout: 10000,
                icon: "ni ni-bell-55",
                type: "default",
              });
              this.$router.back();
            }
          })
          .catch((error) => {
            console.log(error.response.data.errors);
            this.errorFunction(error, "Update Project Site Information");
          });
      }
    },
  },
};
</script>
<style>
</style>
