<template>
  <card>
    <h3 slot="header" class="mb-0">Document Template - {{ model.document_template_name }} </h3>
    <div class="text-right">
      <router-link :to="'/templates/documentTemplateEdit/' + model.document_template_id">
        <base-button size="sm" type="primary" v-if="button_access.edit_template_button == 'YES'">Edit</base-button>
      </router-link>
    </div>
    <form class="needs-validation"
          @submit.prevent="submit">

      <div class="form-row">
        <b-col md="6">
          <base-input label="Template Name"
                      name="Template Name"
                      placeholder="Template Name"
                      v-model="model.document_template_name" disabled>
          </base-input>
        </b-col>

        <b-col md="6">
          <base-input label="Milestone Template"
                      name="Milestone Template"
                      v-model="model.milestone_template_id" >
            <select class="form-control" v-model="model.milestone_template_id" disabled>
              <option Selected disabled value="x">-- Please Select A Milestone Template --</option>
              <option v-for="item in milestone_template" :key="item.milestone_template_id" v-bind:value="item.milestone_template_id">{{ item.milestone_template_name }}</option>
            </select>
          </base-input>
        </b-col>


      </div>
    </form>
  </card>
</template>
<script>
  import BaseSlider from '@/components/BaseSlider'
  import {LoginURL, clientId, clientSecret, apiDomain, getHeader} from '@/assets/js/config.js'
  import axios from 'axios'
  import ErrorFunctionMixin from '@/assets/js/errorFunctionMix.js'

  export default {
    mixins: [ErrorFunctionMixin],
    components: {
      BaseSlider
    },
    created()
    {
      var site_document_template_access = JSON.parse(window.localStorage.getItem('site_document_template_access'))
      this.button_access.edit_template_button = site_document_template_access.access_edit
      this.model.document_template_id = this.$route.params.id

      axios.get(apiDomain + 'template/document_template/'+this.model.document_template_id, { headers: getHeader() })
      .then ( response => {
        if (response.status === 200)
        {
          this.model.document_template_id = response.data.data[0].document_template_id
          this.model.document_template_name = response.data.data[0].document_template_name
          this.model.milestone_template_id = response.data.data[0].milestone_template.milestone_template_id
        }
      })
      .catch ( error => {
          console.log(error.response.status)
          this.errorFunction(error, "Document Template Information")
      } );

      axios.get(apiDomain + 'template/milestone_template', { headers: getHeader() })
      .then ( response => {
        if (response.status === 200)
        {
          this.milestone_template = response.data.data
        }
      })
      .catch ( error => {
        console.log(error.response.status)
        this.errorFunction(error, "Milestone Template Information")
      } );

    },
    data() {
      return {
        model: {
          document_template_code: null,
          document_template_name: null,
          document_template_id: null,
          milestone_template_id: null,
        },
        milestone_template: [],
        button_access:{
          edit_template_button: null,
        },
      }
    },
    methods: {


    }
  }
</script>
<style>
</style>
