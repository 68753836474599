
export default [
  {
    taskId: 'TRTP-000001',
    taskName: 'Prepare Requesition Form',
    taskMilestone: 'Kick-Off',
    taskStatus: 'W.I.P',
    taskProgress: 50,
    taskStatusType: 'info'
  },
  {
    taskId: 'TRTP-000002',
    taskName: 'Prepare Checklist Form',
    taskMilestone: 'Kick-Off',
    taskStatus: 'Complete',
    taskProgress: 100,
    taskStatusType: 'success'
  },
  {
    taskId: 'TRTP-000003',
    taskName: 'Prepare Contractor List',
    taskMilestone: 'Kick-Off',
    taskStatus: 'W.I.P',
    taskProgress: 20,
    taskStatusType: 'danger'
  },
  {
    taskId: 'TRTP-000004',
    taskName: 'Solar Panel Drawing',
    taskMilestone: 'Design',
    taskStatus: 'Complete',
    taskProgress: 100,
    taskStatusType: 'success'
  },

  {
    taskId: 'TRTP-000005',
    taskName: 'Solar Panel BOM',
    taskMilestone: 'Design',
    taskStatus: 'W.I.P',
    taskProgress: 50,
    taskStatusType: 'info'
  },
  {
    taskId: 'TRTP-000006',
    taskName: 'Site Delivery Checklist',
    taskMilestone: 'Installation',
    taskStatus: 'Complete',
    taskProgress: 100,
    taskStatusType: 'success'
  },
  {
    taskId: 'TRTP-000007',
    taskName: 'Installation Plan',
    taskMilestone: 'Installation',
    taskStatus: 'W.I.P',
    taskProgress: 20,
    taskStatusType: 'danger'
  },
  {
    taskId: 'TRTP-000008',
    taskName: 'Contractor Checklist',
    taskMilestone: 'Installation',
    taskStatus: 'Complete',
    taskProgress: 100,
    taskStatusType: 'success'
  },


]
