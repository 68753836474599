<template>
    <div>
        <!-- Header -->
        <div class="header  py-7 py-lg-8 pt-lg-9">
            <b-container>
                <div class="header-body text-center mb-3">
                    <h1 class="text-white text-weight-bold">MOMENS 2.0</h1>

                </div>
            </b-container>

        </div>
        <!-- Page content -->
        <b-container class="mt--8 pb-5">
            <b-row class="justify-content-center">
                <b-col lg="5" md="7">
                    <b-card no-body class="bg-black-transparant border-0 mb-0">
                        <b-card-body class="px-lg-5 py-lg-5">
                            <div class="text-center">
                                <div v-if='isError==true' role="alert" aria-live="polite" aria-atomic="true" class="alert alert-danger alert-dismissible">
                                    <!---->
                                    <span class="alert-text"><strong>Error!</strong> {{errorMsg}} </span>
                                    <button  @click="closeAlert" type="button" data-dismiss="alert" aria-label="Close" class="close">
                                        <span aria-hidden="true">×</span>
                                    </button>
                                </div>
                            </div>

                            <div class="text-center text-muted mb-4">
                                <!-- <small></small> -->
                                <img src="/img/theme/user-login.png">
                            </div>
                            <validation-observer v-slot="{handleSubmit}" ref="formValidator">
                                <b-form role="form" @submit.prevent="handleSubmit(onSubmit)">
                                    <base-input alternative
                                                class="mb-3"
                                                name="OTPCode"
                                                :rules="{required: true, numeric: true}"
                                                prepend-icon="ni ni-lock-circle-open"
                                                placeholder="OTP Code"
                                                v-model="model.otp_number">
                                    </base-input>

                                    <div class="text-center">
                                        <base-button type="primary" native-type="submit" class="my-4">Verify</base-button><br>
                                        <router-link to="/login" class="text-primary "><em>Back To Login ?</em></router-link>
                                    </div>

                                </b-form>
                            </validation-observer>
                        </b-card-body>
                    </b-card>
                    <!-- <b-row class="mt-3">
                      <b-col cols="6">
                        <router-link to="/dashboard" class="text-light"><small>Forgot password?</small></router-link>
                      </b-col>
                      <b-col cols="6" class="text-right">
                        <router-link to="/register" class="text-light"><small>Create new account</small></router-link>
                      </b-col>
                    </b-row> -->
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>
<script src="{{ asset('js/app.js?version='.date("ymdhis").'') }}"></script>
<script>
import {LoginURL, clientId, clientSecret, apiDomain, getHeader} from '@/assets/js/config.js'
import axios from 'axios'
import loginFunctionMix from '@/assets/js/loginFunctionMix.js'
import ErrorFunctionMixin from "@/assets/js/errorFunctionMix.js";
import Notifications from '@/components/NotificationPlugin';

export default {
    components: {
        Notifications
    },
    mixins: [loginFunctionMix,ErrorFunctionMixin],
    data() {
        return {
            model: {
                otp_number: '',
                temp_token: this.$route.tempToken,
            },
            isSuccess : false,
            isError : false,
            myUserID : null,
            isUAM : false,
            myUserDetail : null,
        };
    },
    methods: {

        errorFunction(err,info) {
            this.error = `An error occurred: ${err.message}`;
            console.error(err, info);
            return false; // prevents the error from propagating further
        },

        getDeveloper(){
            this.isUAM = false;
            axios.get(apiDomain+'api/get_user_project_developer/'+this.myUserID.id,{headers: getHeader()})
            .then ( response => {
                console.log("DEVELOPER :")
                console.log(response.data);
                this.myUserDetail = response.data.data;
                console.log("NAME :")
                console.log(this.myUserDetail.name);
                for(let i = 0; i < this.myUserDetail.developers.length; i++)
                {
                    console.log ('Developer-' + i + ' : ' +  this.myUserDetail.developers[i].developer.developer_id);
                    if(this.myUserDetail.developers[i].developer.developer_id == 3) 
                    {
                        this.isUAM = true;
                    }
                }
                if(this.isUAM)
                {
                    this.$router.push('../UAMLandingPage');
                }
                else
                {
                    if(this.myUserDetail.role.role_id == '12')
                    {
                        if (this.myUserDetail.role.role_id == '35')
                        {
                            this.$router.push('../mapSesmoforExternal');
                        }
                        else
                        {
                            this.$router.push('../contractorLandingPage');
                        }
                    }
                    else if  (this.myUserDetail.role.role_id == '9')
                    {
                        this.$router.push('../ProjectLandingPage');
                    }  
                    else if  (this.myUserDetail.role.role_id== '19')
                    {
                        this.$router.push('../PlannerLandingPage');
                    }                                      
                        else
                    {   
                        this.$router.push('../mainLandingPage');
                    }
                }
            })

            .catch(error => {
          this.errorFunction(error, this.title)
        })

        },

        onSubmit() {
            const authUser = {}
            const loginUser = {}
            const defaultDashboard = {}

            this.isSuccess = false;
            this.isError = false;
            const postData = {
                otp : this.model.otp_number,
                temp_token: this.$route.params.tempToken
            }

            axios.post(apiDomain+'verify_otp', postData)
                .then ( response => {
                    if (response.status == 200)
                    {
                        this.isSuccess = true;

                        authUser.access_token = response.data.data
                        //authUser.refresh_token = response.data.data.refresh_token

                        window.localStorage.setItem('authUser', JSON.stringify(authUser))

                        // --- temp comment for testing 2fa code start --- //
                        axios.get(apiDomain + 'api/me', { headers: getHeader() })
                            .then(response => {
                                loginUser.id = response.data.id
                                loginUser.role_id = response.data.role_id
                                loginUser.name = response.data.name
                                loginUser.email = response.data.email
                                window.localStorage.setItem('loginUser', JSON.stringify(loginUser))
                                //this.getLoginUserRoleAccessNew(response.data.role_id)
                                this.getLoginUserRoleAccess()
                                let redirectUrl = window.localStorage.getItem('my_current_url')
                                console.log(redirectUrl);
                                window.localStorage.removeItem('my_current_url')
                                if (redirectUrl) {
                                    console.log('if (redirectUrl) {');
                                    this.$router.push({path: redirectUrl})
                                }
                                else  
                                {
                                    this.myUserID = response.data;
                                    console.log('Current User ID :' + this.myUserID)
                                    this.getDeveloper();
                                    console.log('UAM:' + this.isUAM);
                                } 

                            })
                    }
                })
                .catch ( error => {
                    this.isError = true;
                    let errorMessage = error.response.message;
                    console.log(error);
                    Object.keys(errorMessage).forEach(key => {
                        this.errorMsg = errorMessage[key]; // Use key to access value in error message
                    });
                    this.isError = true;
                } )
        },
        closeAlert() {
            this.isSuccess = false;
            this.isError = false;
            this.errorMsg = null;
        }
    },
    beforeMount() {
        document.body.classList.add('bg-default-moment');
    },
    beforeDestroy() {
        document.body.classList.remove('bg-default-moment');
    }
};
</script>
<style scoped>
    .bg-black-transparant {
        background-color:#3E3E3E99;
    }
    .bg-card-dashboard {
        background-color: #23454A;
    }
    .bg-default-dashboard {
        background-color: #082A2F;
        /* background-size: cover; */
        /* position:fixed; */
        padding:0;
        margin:0;
        top:0;
        left:0;
        width: 100%;
        height: 100%;
    }
    .form-control {
        background-color: #2C545A;
        border-color: #2C545A;
        color: white;
    &:focus {
         background-color: #2C545A;
         border-color: #2C545A;
         color: white;
     }
    &::placeholder {
         color: white;
     }
    }
    .form-control {
    &:focus {
    &::placeholder {
         color: white;
     }
    }
    }
    .input-group-text {
        background-color: #2C545A;;
        color: white;
    &:focus {
         background-color: #2C545A;;
         border-color: #1D4147;
         color: white;
     }
    }
    .focused {
    .input-group-text {
        background-color: #2C545A;
    }
    }
    .navbar-search-dark .input-group {
        background-color: #23454A;
        color: black;
        border-color: rgba(255, 255, 255, 0.6);
    }
    .navbar-search .input-group {
        border-radius: 7px;
    }
    .el-input__inner, .el-select-dropdown__item.is-disabled:hover {
        background-color: #23454A;
    }
    .line-custom {
        background-color: #356168; margin-top: 15px; margin-bottom: 15px;
    }
</style>
