<template>
    <div class="content">
        <base-header class="pb-6">
            <b-row align-v="center" class="py-4">
                <b-col cols="7" lg="6">
                    <h6 class="h2 text-white d-inline-block mb-0">Project Site Daily Progress</h6>
                    <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
                        <route-bread-crumb></route-bread-crumb>
                    </nav>
                </b-col>
                <b-col lg="6" cols="5" class="text-right">
                    <router-link :to="'/ProjectSiteDailyProgressNew/'+siteId">
                        <base-button size="sm" type="neutral"
                                     v-if="button_access.new_button == 'YES'">New Daily Progress</base-button>
                    </router-link>
                </b-col>
            </b-row>
        </base-header>

        <b-container fluid class="mt--6">
            <div>
                <card class="no-border-card" body-classes="px-0 pb-1" footer-classes="pb-2">
                    <template slot="header">
                        <b-row>
                            <h3 class="mb-0">Project Site Daily Progress Listing</h3>
                            <b-col class="text-right">
                                <base-button size="sm" type="primary" @click="routerBack()"
                                >Back</base-button
                                >
                            </b-col>
                        </b-row>


                    </template>
                    <div>
                        <b-col cols="12" class="d-flex justify-content-center justify-content-sm-between flex-wrap">
                            <el-select
                                    class="select-primary pagination-select"
                                    v-model="pagination.perPage"
                                    placeholder="Per page">
                                <el-option
                                        class="select-primary"
                                        v-for="item in pagination.perPageOptions"
                                        :key="item"
                                        :label="item"
                                        :value="item">
                                </el-option>
                            </el-select>
                            <div>
                                <base-input v-model="searchQuery"
                                            prepend-icon="fas fa-search"
                                            placeholder="Search...">
                                </base-input>
                            </div>
                        </b-col>

                        <b-col><br></b-col>
                        <el-table :data="queriedData"
                                  row-key="creation_date"
                                  header-row-class-name="thead-light"
                                  @sort-change="sortChange">

                            <el-table-column label="Date"
                                             min-width="140px"
                                             prop="creation_date"
                                             sortable>
                                <template v-slot="{row}">
                                    <b-media no-body class="align-items-center">
                                        <b-media-body>
                                            <span class="font-weight-600  mb-0 text-sm">{{row.creation_date}}</span>
                                        </b-media-body>
                                    </b-media>
                                </template>
                            </el-table-column>

                            <el-table-column label="Remarks"
                                             min-width="140px"
                                             prop="remarks"
                                             sortable>
                                <template v-slot="{row}">
                                    <b-media no-body class="align-items-center">
                                        <b-media-body>
                                            <span class="font-weight-600  mb-0 text-sm">{{truncateRemarks(row.remarks)}}</span>
                                        </b-media-body>
                                    </b-media>
                                </template>
                            </el-table-column>

                            <el-table-column label="Created By"
                                             min-width="140px"
                                             prop="created_by"
                                             sortable>
                                <template v-slot="{row}">
                                    <b-media no-body class="align-items-center">
                                        <b-media-body>
                                            <span class="font-weight-600  mb-0 text-sm">{{truncateRemarks(row.created_by.name)}}</span>
                                        </b-media-body>
                                    </b-media>
                                </template>
                            </el-table-column>


                            <el-table-column min-width="110px" align="right" label="Actions">
                                <div slot-scope="{$index, row}" class="d-flex">
                                    <base-button
                                            @click.native="handleDetails($index, row)" size="sm" type="primary">
                                        View / Edit
                                    </base-button>

                                    <base-button
                                            @click.native="removeDetails($index, row)" size="sm" type="danger">
                                        Delete
                                    </base-button>
                                </div>
                            </el-table-column>
                        </el-table>
                    </div>
                    <div
                            slot="footer"
                            class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap">
                        <div class="">
                            <p class="card-category">
                                Showing {{ from + 1 }} to {{ to }} of {{ total }} entries

                                <span v-if="selectedRows.length">
                                  &nbsp; &nbsp; {{selectedRows.length}} rows selected
                                </span>
                            </p>
                        </div>
                        <base-pagination
                                class="pagination-no-border"
                                :current="pagination.currentPage"
                                :per-page="pagination.perPage"
                                :total="total"
                                @change="paginationChanged($event)">
                        </base-pagination>
                    </div>
                </card>
            </div>
        </b-container>

        <modal :show.sync="modals"
               size="lg"
               body-classes="p-0">
            <card type="secondary"
                  header-classes="bg-transparent pb-5"
                  body-classes="px-lg-5 py-lg-5"
                  class="border-0 mb-0">
                <template>
                    <div class="text-center text-muted mb-4">
                        <h3>Remove Action</h3>
                    </div>
                    <b-form role="form">
                        <div class="text-center">
                            <span>Do you want to delete this record?</span>
                        </div>
                        <div class="text-center">
                            <base-button class="my-4 remarks-button-css" type="danger" @click="deleteRecord()">Delete</base-button>
                            <base-button class="my-4 remarks-button-css" @click="clearModal()">Close</base-button>
                        </div>
                    </b-form>
                </template>
            </card>
        </modal>
    </div>
</template>
<script>
    import { Table, TableColumn, Select, Option } from 'element-ui';
    import RouteBreadCrumb from '@/components/Breadcrumb/RouteBreadcrumb'
    import { BasePagination } from '@/components';
    import standardPaginationMix from '@/assets/js/standardPaginationMix.js'
    import axios from 'axios'
    import Fuse from 'fuse.js';
    import {LoginURL, clientId, clientSecret, apiDomain, getHeader, parseDateToUTC} from '@/assets/js/config.js'
    import ErrorFunctionMixin from '@/assets/js/errorFunctionMix.js'

    export default {
        mixins: [standardPaginationMix, ErrorFunctionMixin],
        components: {
            BasePagination,
            RouteBreadCrumb,
            [Select.name]: Select,
            [Option.name]: Option,
            [Table.name]: Table,
            [TableColumn.name]: TableColumn
        },
        created()
        {
            this.siteId = this.$route.params.id
            var developer_access = JSON.parse(window.localStorage.getItem('developer_access'))
            this.button_access.new_button = developer_access.access_create
            this.button_access.show_button = developer_access.access_show
            this.getDailyProgressListing()
        },
        data() {
            return {
                propsToSearch:
                    [
                        'creation_date',
                        'remarks',
                        'created_at'
                    ],
                tableColumns: [],
                tableData: [],
                selectedRows: [],
                button_access:{
                    new_button: null,
                    show_button: null,
                },
                filter: {
                    creation_date: null,
                },
                tempButtonStatus: false,
                uniqueTable: null,
                uniqueUser: null,
                siteId: null,
                modals: false,
                recordId : null,
                models: {
                    id : null
                },
            };
        },
        methods: {
            routerBack(){
               // this.$router.go(-1);
            },
            fuseData() {
                this.fuseSearch = new Fuse(this.tableData, {
                    keys: this.propsToSearch,
                    threshold: 0.3
                });
            },
            paginationChanged(page) {
                this.pagination.currentPage = page
            },
            getDailyProgressListing() {
                axios.get(apiDomain + 'project/project_site_daily_progress_list/'+this.$route.params.id, { headers: getHeader() })
                    .then ( response => {
                        if (response.status === 200)
                        {
                            this.tableData = response.data.data;
                            this.fuseData();
                        }
                    })
                    .catch ( error => {
                        this.errorFunction(error, "Project Site Daily Progress Listing")
                    });
            },
            searchDailyProgress() {
                this.tempButtonStatus = true;
                axios.post(apiDomain + 'project/search_project_site_daily_progress_list/'+this.$route.params.id, this.filter, {
                    headers: getHeader(),
                })
                    .then ( response => {
                        if (response.status === 200)
                        {
                            this.tableData = response.data.data;
                            this.fuseData();
                        }
                    })
                    .catch ( error => {
                        this.errorFunction(error, "Search Project Site Daily Progress Listing")
                    });
            },
            handleDetails(index, row)
            {
                this.$router.push({path: '/ProjectSiteDailyProgressEdit/' + row.id })
            },
            removeDetails(index,row){

                this.recordId = row.id;
                this.modals = true;
            },
            deleteRecord(){
                axios.post(apiDomain + 'project/delete_project_site_daily_progress/'+this.recordId, this.models, { headers: getHeader() })
                    .then ( response => {
                        this.$notify({
                            message: '<b>Delete Project Site Daily Progress : ' + this.recordId + '</b> - Successfully Deleted.',
                            timeout: 10000,
                            icon: 'ni ni-bell-55',
                            type: 'default',
                        });
                        this.clearModal();
                        this.getDailyProgressListing();

                    })
                    .catch ( error => {
                        this.errorFunction(error, "Delete Project Site Daily Progress")
                    });
            },
            clearModal(){
                this.modals= false
            },
            truncateRemarks(text) {
                let length = 100;
                if (text.length > length) {
                    return text.substring(0, length) + "......"; // Truncate and append '...'
                }
                return text;
            }
        }
    };
</script>
<style>
    .no-border-card .card-footer{
        border-top: 0;
    }
</style>
