<template>
  <section>
    <div class="content">
      <base-header class="pb-6">
        <b-row align-v="center" class="py-4">
          <b-col cols="7" lg="6">
            <h6 class="h2 text-white d-inline-block mb-0">
              Project
            </h6>
            <nav
              aria-label="breadcrumb"
              class="d-none d-md-inline-block ml-md-4"
            >
            </nav>
          </b-col>
        </b-row>
      </base-header>
      <b-container fluid class="mt--6">
        <div>
          <card
            class="card-border-diagonal p-0"
            style="border: 1px solid black;"
          >
            <b-row class="my--4">
              <b-col md="2" class="b-col-centered">
                <div class="scroll-follower">
                    <base-button size="md" class="p-2 m-2 button-css" @click="scrollToProfile">Dashboard</base-button><br>
                    <base-button size="md" class="p-2 m-2 button-css" @click="redirectToDashboardV2">Edit</base-button><br>
                    <base-button v-if="model.project_tag == 'legacy'" size="md" class="p-2 m-2 button-css" @click="redirectToOldDashboard">Legacy Dashboard</base-button>
                </div>
              </b-col>

              <b-col md="8" class="bg-custom">
                <div class="row">
                    <div class="col-md-12">
                        <base-button size="md" class="p-2 m-2 rounded-pill btn-sm w-auto developer-fonts font-type" disabled>{{model.developer_name}}</base-button>
                        <base-button size="md" class="p-2 m-2 rounded-pill btn-sm w-auto project-type-custom-css font-type" disabled>{{model.project_type_name}}</base-button>
                    </div>
                    <div id="project-profile" class="mt-1 col-md-7 col-xl-7">
                       <card class="card-border-inner bg-transparent form-custom-css card-custom-height">
                            <b-row>
                                <div class="form-group col-md-12">
                                    <p class='form-label font-type'>Project Name</p>
                                    <h1 class='font-weight-bold font-type' style="font-size:20px;">{{model.project_name}}</h1>
                                </div>

                                <div class="form-group col-md-12">
                                    <p class='form-label font-type'>PPA Counter Party</p>
                                    <p class='form-content font-type'>{{model.project_ppa_counter_party}}</p>
                                </div>
                            </b-row>
                        </card>
                    </div>

                    <div class='mt-1 col-md-5 col-xl-5'>
                        <div class="card card-border-inner-v2 bg-transparent form-custom-css card-custom-height">
                            <div class="card-body">
                                <b-row>
                                    <div class="form-group col-md-12" style="margin-left:1px;">
                                        <p class='form-label font-type'>PPA Total Capacity</p>
                                        <h1 class='font-weight-bold text-center total-capital-font-custom-css font-type'>{{model.project_ppa_approved_capacity}} kWp</h1>
                                    </div>

                                    <div class="row form-group col-md-12" style="margin-top:-10px;">
                                        <div class="col-md-6 text-left">
                                            <p class="form-label font-type">PPA Signed Date</p>
                                            <p class="form-content font-type">{{model.project_ppa_start_date}}</p>
                                        </div>
                                        <div class="col-md-6 text-right">
                                            <div style="margin-right:-30px;">
                                                <p class="form-label font-type">Watt Class</p>
                                                <p class="form-content font-type">{{model.project_watt_class}}</p>
                                            </div>
                                        </div>
                                    </div>
                                </b-row>
                            </div>
                        </div>
                    </div>
                </div>

                <div id="site">
                    <template v-for="item in siteListingData">
                        <div class="row">
                            <div class="col-md-6 text-start" @click="siteClicked(item)">
                                <div class="cursor text-decoration-underline w-20 h-20 cursor-pointer rounded-lg flex justify-center items-center transition ease-out duration-300 border-2 border-solid border-transparent hover:border-gray-400 dark:hover:border-gray-500">
                                    <p class="form-content font-type" style="margin-top:15px;">
                                        <!--<svg class="ov-icon" aria-hidden="true" width="19.2" height="19.2" viewBox="0.48 0.48 23.04 23.04" fill="#222F3D" style="font-size: 1.2em;"><path fill="none" d="M0 0h24v24H0V0z"></path><path d="M8 5v14l11-7L8 5z"></path></svg>-->
                                        <i v-if="!item.visible" class="fa fa-arrow-right" style="margin-right:5px;"></i>
                                        <i v-else class="fa fa-arrow-down" style="margin-right:5px;"></i>
                                          SITE - {{item.site_name}}</p>
                                </div>
                            </div>

                            <div class="col-md-6 text-right">
                                <base-button size="md" class="p-2 m-2 rounded-pill btn-sm w-auto project-status-custom-css font-type" disabled>{{item.status.status_code}}</base-button>
                            </div>
                        </div>
                        <div class="row">
                            <div v-if="item.visible" class="col-md-12">
                                <ProjectSiteTemplateViewForm :site-id="item.site_id"/>
                            </div>
                        </div>
                    </template>
                </div>
              </b-col>

              <b-col md="2">
                <!-- empty for side key contents -->
                <div class="scroll-follower-right form-custom-css">
                     <!-- <card class="bg-transparent form-custom-css"> -->
                        <h2 class="h2-key-contacts">Key Contacts</h2>
                        <div class="form-row">
                            <b-col md="12">
                                <p class="form-label">Project Engineer</p>
                                <p class="form-content">{{model.project_manager_name}}</p>
                            </b-col>

                            <b-col md="12" class='mt-1'>
                                <p class="form-label">Engineer</p>
                                <p class="form-content">{{model.project_engineer_name}}</p>
                            </b-col>

                            <b-col md="12" class='mt-1'>
                                <p class="form-label">Business Development</p>
                                <p class="form-content">{{model.project_business_development_name}}</p>
                            </b-col>

                            <b-col md="12" class='mt-1'>
                                <p class="form-label">Safety Officer</p>
                                <p class="form-content">{{model.project_safety_name}}</p>
                            </b-col>
                        </div>
                    <!-- </card> -->
                </div>
              </b-col>
            </b-row>
          </card>
        </div>
      </b-container
      >
    </div>
  </section>
</template>


<script>
import {
  LoginURL,
  clientId,
  clientSecret,
  apiDomain,
  getHeader,
  getcurrentDate,
} from "@/assets/js/config.js";
import axios from "axios";
import ErrorFunctionMixin from "@/assets/js/errorFunctionMix.js";
import jsonFormData from 'json-form-data';
import VueApexCharts from "vue-apexcharts";
import moment from "moment";
import ProjectSiteTemplateViewForm from '@/views/SemCorp/Template/Project/ProjectSiteTemplateViewForm.vue'

export default {
  mixins: [ErrorFunctionMixin],
  components: {
      apexchart: VueApexCharts,
      ProjectSiteTemplateViewForm,
  },
  created() {
    const LoginUser = JSON.parse(window.localStorage.getItem("loginUser"));
    this.myUserID = LoginUser;

    this.getDeveloperListing();
    this.getProjectTypeListing();
    this.getProjectManagerListing();
    this.getProjectManagerDetails();
    this.getProjectEngineerListing();
    this.getBusinessDevelopmentListing();
    this.getSafetyOfficerListing();
    this.getProjectSiteStatusListing();
    this.getDashboardData();
    // this.getSiteListing();

  },
  data() {
    return {
        model: {
            project_id: null,
            developer_id: "x",
            project_type_id: "x",
            project_manager: "x",
            project_manager_name: "x",
            project_engineer: "x",
            project_engineer_name: "x",
            project_business_development: "x",
            project_business_development_name: "x",
            project_safety: "x",
            project_safety_name: "x",
            project_name: null,
            project_ppa_approved_capacity: null,
            project_ppa_start_date: null,
            project_watt_class: null,
            roofed_handover_date: null,
            project_tag: null,
        },
        developers: [],
        project_type: [],
        project_managers: [],
        project_engineers: [],
        project_business_development: [],
        project_safety: [],
        site_status: [],
        siteListingData: [],
        isSolarNovaProject: false,
    };
  },
  methods: {
    redirectToDashboardV2(){
        this.$router.push({path: '/projectDashboardV2/' + this.model.project_id})
    },
    redirectToOldDashboard() {
        this.$router.push({path: '/projectDashboard/' + this.model.project_id})
    },
    scrollToProfile() {
      const profileSection = document.getElementById("project-profile");
      profileSection.scrollIntoView({ behavior: "smooth" });
    },
    scrollToSite() {
      const siteSection = document.getElementById("site");
      siteSection.scrollIntoView({ behavior: "smooth" });
    },
    getDeveloperListing() {
      axios
      .get(apiDomain + "setup/developer", { headers: getHeader() })
      .then((response) => {
        if (response.status === 200) {
          this.developers = response.data.data;
          // default to sembcorp solar || developer_id = 1
          // this.model.developer_id = 1;
        }
      })
      .catch((error) => {
        console.log(error.response.status);
        this.errorFunction(error, "Developer Information");
      });
    },
    getProjectTypeListing() {
      axios
      .get(apiDomain + "setup/project_type", { headers: getHeader() })
      .then((response) => {
        if (response.status === 200) {
          this.project_type = response.data.data;
        }
      })
      .catch((error) => {
        console.log(error.response.status);
        this.errorFunction(error, "Project Type Information");
      });
    },
    getProjectManagerListing() {
        axios.get(apiDomain + 'lookup/user_list/Project_Manager', { headers: getHeader() })
        .then ( response => {
            if (response.status === 200)
            {
            this.project_managers = response.data.data
            }
        })
        .catch ( error => {
            console.log(error.response.status)
            this.errorFunction(error, "Project Manager")
        } );
    },
    getProjectManagerDetails(){
        axios.get(apiDomain + 'lookup/user_list/Project_Manager', { headers: getHeader() })
                .then ( response => {
                    if (response.status === 200)
                    {
                    this.project_managers = response.data.data
                    }
                })
                .catch ( error => {
                    console.log(error.response.status)
                    this.errorFunction(error, "Project Manager")
                } );
    },
    getProjectEngineerListing() {
        axios.get(apiDomain + 'lookup/user_list/Engineer', { headers: getHeader() })
        .then ( response => {
            if (response.status === 200)
            {
            this.project_engineers = response.data.data
            }
        })
        .catch ( error => {
            console.log(error.response.status)
            this.errorFunction(error, "Engineer Information")
        } );
    },
    getBusinessDevelopmentListing() {
      axios.get(apiDomain + 'lookup/user_list/Business_Development', { headers: getHeader() })
        .then ( response => {
            if (response.status === 200)
            {
            this.project_business_development = response.data.data
            }
        })
        .catch ( error => {
            console.log(error.response.status)
            this.errorFunction(error, "Safety Officer Information")
        } );
    },
    getSafetyOfficerListing() {
        axios.get(apiDomain + 'lookup/user_list/Safety', { headers: getHeader() })
        .then ( response => {
            if (response.status === 200)
            {
            this.project_safety = response.data.data
            }
        })
        .catch ( error => {
            console.log(error.response.status)
            this.errorFunction(error, "Safety Officer Information")
        } );
    },
    getProjectSiteStatusListing() {
      // axios.get(apiDomain + 'lookup/site_status', { headers: getHeader() })
      axios.get(apiDomain + 'lookup/site_status.singapore_v2', { headers: getHeader() })
      .then ( response => {
        if (response.status === 200)
        {
          this.site_status = response.data.data
        }
      })
      .catch ( error => {
        console.log(error.response.status)
        this.errorFunction(error, "Site Status Information")
      } );
    },
    getDashboardData() {
      this.model.project_id = this.$route.params.id;
      axios
        .get(apiDomain + "project/project/" + this.model.project_id, {
            headers: getHeader(),
        })
        .then((response) => {
            if (response.status === 200) {
                this.model.project_name = response.data.data[0].project_name;
                this.model.developer_id = response.data.data[0].developer.developer_id;
                this.model.developer_name = response.data.data[0].developer.developer_name;
                this.model.project_type_id = response.data.data[0].project_type.project_type_id;
                if ( this.model.project_type_id != null &&
                    ([2, 3, 4, 9, 10, 11, 12, 13, 14].includes(this.model.project_type_id))
                    ) {
                  this.isSolarNovaProject = true;
                } else {
                  this.isSolarNovaProject = false;
                  this.getSiteListing();
                }
                this.model.project_type_name = response.data.data[0].project_type.project_type;
                this.model.project_ppa_counter_party = response.data.data[0].project_ppa_counter_party;
                let this_project_ppa_start_date = (response.data.data[0].project_ppa_start_date !== null) ? response.data.data[0].project_ppa_start_date : "N/A";
                if(this_project_ppa_start_date !== "N/A"){
                    let convertProjectStartDateDate = new Date(this_project_ppa_start_date);
                    const year = convertProjectStartDateDate.getFullYear();
                    const month = convertProjectStartDateDate.toLocaleString('en-US', { month: 'short' });
                    const day = convertProjectStartDateDate.toLocaleString('en-US',{day:'2-digit'});
                    this_project_ppa_start_date = `${day}-${month}-${year}`;
                }

                this.model.project_ppa_start_date = this_project_ppa_start_date;
                this.model.project_ppa_approved_capacity = parseFloat(response.data.data[0].project_ppa_approved_capacity.replace(/,/g, ""));
                if (response.data.data[0].project_watt_class !== null) {
                    this.model.project_watt_class = parseFloat(response.data.data[0].project_watt_class.replace(/,/g, ""));
                }
                this.model.project_manager = response.data.data[0].project_manager.id;
                this.model.project_manager_name = response.data.data[0].project_manager.name;
                this.model.project_engineer = response.data.data[0].project_engineer.id;
                this.model.project_engineer_name = response.data.data[0].project_engineer.name;
                this.model.project_business_development = response.data.data[0].project_business_development.id;
                this.model.project_business_development_name = response.data.data[0].project_business_development.name;
                this.model.project_safety = response.data.data[0].project_safety.id;
                this.model.project_safety_name = response.data.data[0].project_safety.name;
                this.model.project_tag = response.data.data[0].project_tag;
            }
        })
        .catch((error) => {
            console.log(error.response.status);
            if (error.response.status == 401) {
            this.$router.push("/login");
            }
        });
    },
    getSiteListing() {
        axios.get(apiDomain + 'project/project_site.show_by_project/' + this.model.project_id, { headers: getHeader() })
          .then ( response => {
              console.log(response.data);
            if (response.status === 200)
            {
                let data = response.data.data;
                data.forEach(item => {
                    item.visible = false;
//                    this.getContractorFromSite(item.site_id);
                });

                this.siteListingData = data;
            }
          })
          .catch ( error => {
              console.log(error.response.status)
          } );
    },
//    getContractorFromSite(project_site_id)
//    {
//      axios.get(apiDomain + 'project/site_contractor.show_by_site/' + project_site_id + '?client=mobile', { headers: getHeader() })
//      .then ( response => {
//        if (response.status === 200)
//        {
//            this.siteListingData.map(item => {
//                if (item.site_id === project_site_id) {
//                    item['contractor_code'] = response.data.data[0].contractor.contractor_code;
//                }
//            });
//            this.siteListingData = [...this.siteListingData];
//        }
//      })
//      .catch ( error => {
//        console.log(error)
//        this.errorFunction(error, "Site Contractor Information")
//      } );
//    },
    createData() {
        axios.put(apiDomain + 'project/project/'+ this.model.project_id, this.model, { headers: getHeader() })
        .then ( response => {
            if (response.status === 200)
            {
              this.$notify({
              message:
                '<b>Update Project : ' + this.model.project_name + '</b> - Successfully Updated.',
              timeout: 10000,
              icon: 'ni ni-bell-55',
              type: 'default',
              });
            //   this.$router.back()
            this.getDashboardData();
            }
        })
        .catch ( error => {
            console.log(error)
            this.errorFunction(error, "Project Information")
        });
    },
    rowClicked(row) {
        this.$router.push({
          path: "/projectSiteDashboardV2/" + row.site_id,
        });
    },
    siteClicked(item){
        item.visible = !item.visible;
    }
  },
};
</script>
<style>

.bg-custom {
  background-color: #EFF7EA;
}

.card-border-diagonal {
  /* border: 2px solid #BDDBA9; */
  border-radius: 100px 0 100px 0;
  /* margin: 20px; */
  padding: 10px;
  /* max-width: 300px; */
}

.card-border-inner {
  border: 1px solid #ADB5BD;
  border-radius: 50px 50px 50px 50px;
  /* margin: 20px; */
  padding: 10px;
  /* max-width: 300px; */
}

.form-custom-css .form-control:disabled {
  border: none !important;
  font-weight: bold;
  background-color: transparent !important;
  appearance: none !important;
  -webkit-appearance: none;
  background-image: none !important;
}

.form-custom-css .form-control {
  border: 2px solid #BDDBA9 !important;
  border-radius: 0 !important;
  color: black !important;
}

.form-custom-css .form-control-label {
  color: #525f7f !important;
  font-size: .875rem !important;
  font-weight: 600 !important;
}

.form-label {
  color: #777777 !important;
  font-size: 14px !important;
  font-weight: 600 !important;
  margin-bottom: 5px;
}

.form-content {
  font-size: 14px !important;
  font-weight: bold !important;
  color: black !important;
  margin-top: 5px;
}

.b-col-centered {
  display: flex;
  justify-content: center;
}

.scroll-follower{
  position: fixed;
  transform: translateY(20%);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.scroll-follower .base-button {
  width: 100%;
  text-align: center;
}

.scroll-follower-right{
  position: fixed;
  width: 13%;
  transform: translateY(5%);
  /* display: flex; */
  /* flex-direction: column; */
  /* align-items: center; */
}

.button-css {
  border-radius: 10px;
  background: transparent;
  color: #3E5C2B;
}

.h2-key-contacts {
  font-weight: 700 !important;
  color: black !important;
}

.card-custom-height {
  max-height:230px;
}

.cursor {
    cursor : pointer;
}

.developer-fonts{
    background-color: #00B0F0 !important;
    font-size: 14px;
    min-width:100px;
}

.project-type-custom-css{
    background-color: #7030A0 !important;
    font-size: 14px;
    min-width:100px;
}

.total-capital-font-custom-css{
    font-size: 55px !important;
}

.project-status-custom-css{
    background-color: #7F7F7F !important;
}

.font-type{
    font-family: "Calibri", sans-serif;
}
</style>
