<template>
  <div class="bg-default-dashboard">
    <div class=" py-lg-8 pt-lg-5">
      <b-container fluid style="width: 90%; position: relative;">
        <!-- <div class="header-body "  > -->
        <b-row class="mb-2">
          <b-col>
            <h1 class="text-white">Observation Item</h1>
          </b-col>
          <base-button type="primary" @click="$router.back()">< Back</base-button>
        </b-row>
        <b-row class="mb-2">
          <b-col>
            <span class="text-white mr-3 h2 "> {{oDisplay.project_name}} </span> <span>
              <b-badge pill style="background-color:#FF5C0066 "><i class="fa fa-circle"
                  style="color:#FF9A3D ; font-size: smaller;"></i> {{ getSafetyObservationStatus(model.safety_observation_status_id) }}</b-badge></span>
          </b-col>
        </b-row>
        <!-- <b-row class="mb-2">
          <b-col>
            <span class="text-white h3 "> {{oDisplay.project_address}} </span>
          </b-col>
        </b-row> -->

        <!-- Main Data Observation -->
        <b-row class="mb-5">
          <b-col md="4" style="padding-top: 30px;" v-if="slide_items.observation.length > 0">
            <vueper-slides class="" :touchable="false" fade :autoplay="true" :duration="slide_items.speed" :bullets="false" fixed-height="300px">
              <vueper-slide v-for="(entry, index) in slide_items.observation" :key="index" :image="entry.image" :content="entry.remark">
                <div slot="content">
                  <button v-on:click="showLargeImage(entry.image)">{{entry.remark}}</button>
                </div>                
              </vueper-slide>
            </vueper-slides>

            <!-- <cat-carousel :items="slide_items.observation" :item-per-page="1"
              :indicators-config="{ activeColor: '#000', size: 10, color: '#d1d1d1', hideIndicators: true }">
              <template slot="item" slot-scope="{data}">
                <div class="item text-center">
                  <img :src="data.image" style="max-width: 510px; max-height: 450px; text-align: center; border-radius: 10px;" @click="showLargeImage(data.image)" alt="">
                  <br>
                  <div>
                    <span class="text-white ">{{ data.remark  }}</span>
                  </div>
                </div>
              </template>
            </cat-carousel> -->
          </b-col>
          <b-col>
            <b-row>
              <b-col>
                <label for="" class="text-white">Remarks</label>
                <base-input label="">
                  <b-form-textarea id="obs_desc" class="form-control" placeholder="Observations" v-model="model.observation_description" rows="5" disabled></b-form-textarea>
                </base-input>
              </b-col>
            </b-row>
            <!-- [OBSERVATION] type | category -->
            <b-row>
              <!-- observation type -->
              <b-col>
                <label for="" class="text-white">Type</label>
                <base-input name="Type" v-model="model.safety_observation_type_id">
                  <select class="form-control" v-model="model.safety_observation_type_id" disabled>
                    <option Selected disabled value="x">
                      -- Please Select A Safety Observation Type --
                    </option>
                    <option v-for="item in safety_observation_type" :key="item.safety_observation_type_id"
                      v-bind:value="item.safety_observation_type_id">
                      {{ item.safety_observation_type_code }}
                    </option>
                  </select>
                </base-input>
              </b-col>
              <!-- observation category -->
              <b-col>
                <label for="" class="text-white">Category</label>
                <base-input name="Category" v-model="model.safety_observation_category_id">
                  <select class="form-control" v-model="model.safety_observation_category_id" disabled>
                    <option Selected disabled value="x">
                      -- Please Select A Safety Observation Category --
                    </option>
                    <option v-for="item in safety_observation_category" :key="item.safety_observation_category_id"
                      v-bind:value="item.safety_observation_category_id">
                      {{ item.safety_observation_category }}
                    </option>
                  </select>
                </base-input>
              </b-col>
            </b-row>
            <!-- [OBSERVATION] target date | repeated observation -->
            <b-row>
              <!-- target date -->
              <b-col v-if="model.target_date">
                <label for="" class="text-white">Target Date</label>
                <b-form-input name="Target Date" v-model="model.target_date" disabled type="text"> </b-form-input>
              </b-col>
              <!-- repeated observation  -->
              <b-col>
                <label for="" class="text-white">Repeated Observation</label>
                <div>
                  <!-- <strong class="text-white mt-1">Yes</strong> -->
                  <base-input name="Repeated Observation" v-model="model.repeated_observation">
                    <select class="form-control" v-model="model.repeated_observation" disabled>
                      <option value="0">No</option>
                      <option value="1">Yes</option>
                    </select>
                  </base-input>
                </div>
              </b-col>
            </b-row>
            <!-- [OBSERVATION] priority | need correction photo -->
            <b-row>
              <!-- priority -->
              <b-col>
                <label for="" class="text-white">Priority</label>
                <base-input name="Priority" v-model="model.safety_observation_priority_id">
                  <select class="form-control" v-model="model.safety_observation_priority_id" disabled>
                    <option Selected disabled value="x">
                      -- Please Select A Priority --
                    </option>
                    <option Selected disabled value="null">-- None --</option>
                    <option v-for="item in safety_observation_priority" :key="item.safety_observation_priority_id"
                      v-bind:value="item.safety_observation_priority_id">
                      {{ item.safety_observation_priority_name }} -
                      {{ item.safety_observation_priority_duration }} Days
                    </option>
                  </select>
                </base-input>
              </b-col>
              <!-- need correction photo -->
              <b-col>
                <label for="" class="text-white">Need Correction Photo</label>
                <div>
                  <!-- <strong class="text-white mt-1">Yes</strong> -->
                  <base-input name="Need Correction Photos" v-model="model.correction_photos_required">
                    <select class="form-control" v-model="model.correction_photos_required" disabled>
                      <option Selected disabled value="x">
                        -- Please Select A Value --
                      </option>
                      <option value="1">Yes</option>
                      <option value="0">No</option>
                    </select>
                  </base-input>
                </div>
              </b-col>
            </b-row>
          </b-col>
        </b-row>

        <!-- TABS - for corrections action -->
        <!-- <b-row class="mb-5"  v-if="model.correction_photos_required == 1 && model.correction_description"> -->
        <b-row class="mb-5" v-if="model.correction_photos_required == 1">
          <div style="width: 100%;">
            <b-card no-body>
              <b-tabs card>
                <!-- tab: item correction -->
                <b-tab title="Action" v-if="model.correction_photos_required == 1 && model.correction_description">
                  <!-- <b-row class="mb-12" v-if="model.correction_photos_required == 1 && model.correction_description"> -->
                  <b-row class="mb-12">
                    <!-- correction photo -->
                    <b-col md="4" style="padding-top: 30px;" v-if="slide_items.correction.length > 0">
                      <vueper-slides class="no-shadow" :touchable="false" fade :autoplay="true" :duration="slide_items.speed" :bullets="false" fixed-height="300px">
                        <vueper-slide v-for="(entry, index) in slide_items.correction" :key="index" :image="entry.image" :content="entry.remark">
                          <div slot="content">
                            <button v-on:click="showLargeImage(entry.image)">{{entry.remark}}</button>
                          </div>                                
                        </vueper-slide>
                      </vueper-slides>
                    </b-col>

                    <!-- [CORRECTION] submitted by & date | preventive action | approve by & date & remark  -->
                    <b-col md="8">
                      <!-- submitted by & date -->
                      <b-row>
                        <b-col>
                          <label for="" class="text-white">Correction Submitted By</label>
                          <base-input><b-form-input name="Correction Submitted By" v-model="model.correction_submitted_by.name" disabled type="text"> </b-form-input></base-input>
                        </b-col>
                        <b-col>
                          <label for="" class="text-white">Correction Submitted Date</label>
                          <base-input><b-form-input name="Correction Submitted Date" v-model="model.correction_submitted_at" disabled type="text"> </b-form-input></base-input>
                        </b-col>
                      </b-row>
                      <!-- corrective / prevention action -->
                      <b-row v-if="model.safety_observation_type_id != 1 && model.correction_description">
                        <b-col>
                          <label for="" class="text-white">Action Taken</label>
                          <base-input>
                            <b-form-textarea id="textarea" v-model="model.correction_description" placeholder="" rows="3" v-if="model.safety_observation_status_id == 4" disabled></b-form-textarea>
                            <b-form-textarea id="textarea" v-model="model.correction_description" placeholder="" rows="3" v-else disabled></b-form-textarea>
                          </base-input>
                        </b-col>
                      </b-row>
                      <!-- approved by & date -->
                      <div v-if="model.resolved_at">
                        <b-row>
                          <b-col>
                            <label for="" class="text-white">Approved By</label>
                            <base-input><b-form-input name="Approved by" v-model="model.approved_by.name" disabled type="text"> </b-form-input></base-input>
                          </b-col>
                          <b-col>
                            <label for="" class="text-white">Approved Date</label>
                            <base-input><b-form-input name="Resolved Date"  v-model="model.resolved_at" disabled type="text"> </b-form-input></base-input>
                          </b-col>
                        </b-row>
                        <b-row>
                          <b-col>
                            <label for="" class="text-white">Approval Remarks</label>
                            <base-input><b-form-textarea id="textarea" v-model="model.approval_remarks" placeholder="" rows="3" disabled></b-form-textarea></base-input>
                          </b-col>
                        </b-row>
                      </div>
                    </b-col>
                  </b-row>
                </b-tab>

                <!-- tab: rejected item -->
                <template v-if="slide_items.rejection.length > 0">
                  <b-tab title="Rejected Correction" v-for="(item, index) in slide_items.rejection" :key="index">
                    <b-row class="mb-12">
                      <!-- photo -->
                      <b-col md="4" style="padding-top: 30px;" v-if="item.photos.length > 0">
                        <vueper-slides :touchable="false" fade :autoplay="true" :duration="slide_items.speed" :bullets="false" fixed-height="300px">
                          <vueper-slide v-for="(entry, index) in item.photos" :key="index" :image="entry.photo_image" :content="entry.photo_remark">
                            <div slot="content">
                              <button v-on:click="showLargeImage(entry.photo_image)">{{entry.photo_remark}}</button>
                            </div>
                          </vueper-slide>
                        </vueper-slides>
                      </b-col>

                      <!-- information  -->
                      <b-col md="8">
                        <!-- rejected by & date -->
                        <b-row>
                          <b-col>
                            <label for="" class="text-white">Rejected By</label>
                            <base-input><b-form-input name="Rejected By" v-model="item.rejected_by" disabled type="text"> </b-form-input></base-input>
                          </b-col>
                          <b-col>
                            <label for="" class="text-white">Rejection Date</label>
                            <base-input><b-form-input name="Rejection Date" v-model="item.rejected_at" disabled type="text"> </b-form-input></base-input>
                          </b-col>
                        </b-row>

                        <!-- rejected reason -->
                        <b-row>
                          <b-col>
                            <label for="" class="text-white">Reason</label>
                            <base-input>
                              <b-form-textarea id="textarea" v-model="item.rejected_reason" placeholder="" rows="5" disabled></b-form-textarea>
                            </base-input>
                          </b-col>
                        </b-row>
                                      
                        <!-- submitted by & date -->
                        <div v-if="model.resolved_at">
                          <b-row>
                            <b-col>
                              <label for="" class="text-white">Correction Submitted By</label>
                              <base-input><b-form-input name="Correction Submitted By" v-model="item.submitted_by" disabled type="text"> </b-form-input></base-input>
                            </b-col>
                            <b-col>
                              <label for="" class="text-white">Correction Submitted Date</label>
                              <base-input><b-form-input name="Correction Submitted Date"  v-model="item.submitted_at" disabled type="text"> </b-form-input></base-input>
                            </b-col>
                          </b-row>
                          <b-row>
                            <b-col>
                              <label for="" class="text-white">Action Taken</label>
                              <base-input><b-form-textarea id="textarea" v-model="item.correction_remark" placeholder="" rows="3" disabled></b-form-textarea></base-input>
                            </b-col>
                          </b-row>
                        </div>
                      </b-col>
                    </b-row>
                  </b-tab>
                </template>

              </b-tabs>
            </b-card>
          </div>
        </b-row>

        <modal :show.sync="isLargeImageVisible" size="xl" body-classes="p-0" title="Image">
          <card type="secondary" header-classes="bg-transparent pb-5" body-classes="px-lg-5 py-lg-5" class="border-0 mb-0">
            <!-- style="max-width:250px;max-height:250px;" -->
            <div class="text-center">
              <img :src="largeImageSrc" style="max-width: 100%" />
            </div>
          </card>
        </modal>

        <modal :show.sync="isRejectCorrectionVisible" size="xl" body-classes="p-0"
          title="Reject Safety Observation Item Correction">
          <card type="secondary" header-classes="bg-transparent pb-5" body-classes="px-lg-5 py-lg-5" class="border-0 mb-0">
            <template>
              <h1>Reject Safety Observation Item Correction</h1>

              <card>
                <div class="form-row">
                  <b-col md="12">
                    <label for="textarea">Reason:</label>
                    <b-form-textarea id="textarea" v-model="approval_model.reject_correction_reason" placeholder=""
                      rows="5"></b-form-textarea>
                  </b-col>
                </div>

                <div>
                  <base-button type="primary" class="my-4" @click="closeRejectCorrection()">Cancel</base-button>
                  <base-button v-if="!reject_clicked" type="danger" class="my-4"
                    @click="rejectCorrection()">Reject</base-button>
                  <base-button v-if="reject_clicked" type="danger" class="my-4" @click="rejectCorrection()"
                    disabled>Reject</base-button>
                </div>
              </card>
            </template>
          </card>
        </modal>

        <modal :show.sync="isApproveCorrectionVisible" size="xl" body-classes="p-0"
          title="Approve Safety Observation Item Correction">
          <card type="secondary" header-classes="bg-transparent pb-5" body-classes="px-lg-5 py-lg-5" class="border-0 mb-0">
            <template>
              <h1>Approve Safety Observation Item Correction</h1>

              <card>
                <div class="form-row">
                  <b-col md="12">
                    <label for="textarea">Remarks:</label>
                    <b-form-textarea id="textarea" v-model="approval_model.approve_correction_remarks" placeholder=""
                      rows="5"></b-form-textarea>
                  </b-col>
                </div>

                <div>
                  <base-button type="primary" class="my-4" @click="closeApproveCorrection()">Cancel</base-button>
                  <base-button v-if="!approve_clicked" type="success" class="my-4"
                    @click="approveCorrection()">Approve</base-button>
                  <base-button v-if="approve_clicked" type="success" class="my-4" @click="approveCorrection()"
                    disabled>Approve</base-button>
                </div>
              </card>
            </template>
          </card>
        </modal>

        <modal :show.sync="isSubmissionWarningVisible" size="xl" body-classes="p-0" title="Submission Warning">
          <card>
            <template>
              <h1 class="text-center">Submission in Progress</h1>
              <div>
                Please be patient while we process your submission.<br />
                Do not navigate away from this page or refresh the page until the
                submission is complete.<br />
                You will be automatically redirected to another page once the
                process is finished.
              </div>
            </template>
          </card>
        </modal>

      </b-container>

    </div>


  </div>
</template>



<script>
import {
  apiDomain,
  getHeader,
} from "@/assets/js/config.js";
import axios from "axios";
import ErrorFunctionMixin from "@/assets/js/errorFunctionMix.js";
import jsonFormData from "json-form-data";
import { VueperSlides, VueperSlide } from 'vueperslides'
import 'vueperslides/dist/vueperslides.css'

export default {
  components: {
    VueperSlides,
    VueperSlide,
  },
  mixins: [ErrorFunctionMixin],
  created() {
    var userInfo = JSON.parse(window.localStorage.getItem("loginUser"));
    this.user_role = userInfo.role_id;
    this.user_id = userInfo.id;
    if (this.user_role == 12) {
      this.getContractorId();
    }
    this.safety_observation_item_id = this.$route.params.id;
    this.getSafetyObservationItem();
    // GET SAFETY OBSERVATION ASSIGNED CONTRACTOR
    this.getProjectListing();
    this.getUserList();
    this.getSafetyObservationTypes();
    this.getSafetyObservationCategories();
    this.getSafetyObservationPriorities();
    this.getSafetyObservationStatusNames();
    // this.getSafetyObservationListing();
  },
  data() {
    return {
      /* new */
      oDisplay: {
        project_name: '',
        project_address: '',
        site_name: ''
      },
      check_revision: 1,
      inputs: {
        fileMultiple: []
      },
      slide_items: {
        speed: 5000,        //_ change this value for slide duration per-image
        observation: [],
        correction: [],
        rejection: [],
      },
      /* original */
      user_role: null,
      user_id: null,
      contractor_id: null,
      isLargeImageVisible: false,
      largeImageSrc: null,
      isRejectCorrectionVisible: false,
      approval_model: {
        reject_correction_reason: null,
      },
      safetyObservationItemResponse: null,
      safety_observation_item_id: null,
      model: {
        safety_observation_item_id: null,
        safety_observation_id: null,
        safety_observation_status_id: null,
        observation_description: null,
        safety_observation_type_id: "x",
        safety_observation_category_id: "x",
        safety_observation_photos: [],
        correction_photos_required: "x",
        safety_observation_priority_id: "x",
        target_date: null,
        correction_description: null,
        correction_submitted_by: null,
        correction_submitted_at: null,
        rejected_corrections: [],
        approval_remarks: null,
        approved_by: null,
        resolved_at: null,
        repeated_observation: null,
      },
      new_model: {
        observation_description: null,
        safety_observation_type_id: "x",
        safety_observation_category_id: "x",
        correction_photos_required: "x",
        safety_observation_priority_id: "x",
        safety_observation_photos: [],
        safety_observation_status_id: 1,
      },
      projects: [],
      project_groups: [],
      project_sites: [],
      site_contractors: [],
      users: [],
      conducting_users: [],
      safety_observation_type: [],
      safety_observation_category: [],
      safety_observation_priority: [],
      safety_observation_status: [],

      submit_clicked: false,
      isNewObservationItemVisible: false,
      isSubmissionWarningVisible: false,
      isRejectCorrectionVisible: false,
      isApproveCorrectionVisible: false,
      reject_clicked: false,
      approve_clicked: false,
      safety_observation_item_mode: "create",
      current_item_index: null,
      approval_model: {
        reject_correction_reason: null,
        approve_correction_remarks: null,
      },
      
      /* re-check and delete if it not use anymore */
      obs_items: [
        {
          id: 1,
          type: "Good Practice",
          category: "12 Life Save Rules",
          status: "On Going",
          proruty: "30 Days"
        }
      ],
      items: [
        {
          id: 0,
          project_name: "PLTS IKN",
          epc: "EHP",
          site_name: "Site 01",
          date: "19-July-2024",
          submitted_by: "Robaan",
        },
        {
          id: 1,
          project_name: "PLTS IKN",
          epc: "EHP",
          site_name: "Site 01",
          date: "19-July-2024",
          submitted_by: "Robaan",
        }
      ],
      fields: [
        "no.",
        // Type
        {
          key: "safety_observation_type_id",
          label: "Type",
          formatter: (value, key, item) => {
            var findObj = this.safety_observation_type.find((obj) => {
              return obj.safety_observation_type_id === value;
            });
            if (typeof findObj !== "undefined") {
              return findObj.safety_observation_type_code;
            } else {
              return value;
            }
          },
        },
        // Category
        {
          key: "safety_observation_category_id",
          label: "Category",
          formatter: (value, key, item) => {
            var findObj = this.safety_observation_category.find((obj) => {
              return obj.safety_observation_category_id === value;
            });
            if (typeof findObj !== "undefined") {
              return findObj.safety_observation_category;
            } else {
              return value;
            }
          },
        },
        // Status
        {
          key: "safety_observation_status_id",
          label: "Status",
          formatter: (value, key, item) => {
            var findObj = this.safety_observation_status.find((obj) => {
              return obj.status_id === value;
            });
            if (typeof findObj !== "undefined") {
              return findObj.status_code;
            } else {
              return value;
            }
          },
        },
        // Priority
        {
          key: "safety_observation_priority_id",
          label: "Priority",
          formatter: (value, key, item) => {
            var findObj = this.safety_observation_priority.find((obj) => {
              return obj.safety_observation_priority_id === value;
            });
            if (typeof findObj !== "undefined") {
              return findObj.safety_observation_priority_name;
            } else {
              return value;
            }
          },
        },
        // Actions
        { key: "actions", label: "Actions" },
      ],
      file_uploads: true,
    };
  },
  methods: {
    getCorrectionPhotosLength() {
      return this.model.safety_observation_photos.filter(
        (x) =>
          x["safety_observation_item_file_type_id"] == 2 &&
          !x["safety_observation_items_rejected_id"]
      ).length;
    },    
    showLargeImage(imageSrc) {
      this.largeImageSrc = imageSrc;
      this.isLargeImageVisible = true;
    },
    insertImageDataSrc() {
      this.model.safety_observation_photos.forEach((e) => {
        e["imageDataSrc"] = null;
      });
      this.loadSafetyObservationPhotos();
    },
    loadSafetyObservationPhotos() {
      //_ observation and non rejected entries      
      this.model.safety_observation_photos.forEach((e, index) => {
        if ( (e.safety_observation_item_file_type_id == 1) || (e.safety_observation_item_file_type_id == 2 && !e.safety_observation_items_rejected_id) ) {
          axios
            .get(apiDomain + e["image"]["url"], { headers: getHeader() })
            .then((response) => {
              if (response.status === 200) {
                e["imageDataSrc"] = `data:${response.data.data.mime};base64,${response.data.data.image}`;
                this.model.safety_observation_photos.push("");
                this.model.safety_observation_photos.splice(-1);

                var tmpObj = {};
                tmpObj.remark = (e.photoRemarks == '') ? '--no remark--' : e.photoRemarks;
                tmpObj.image = `data:${response.data.data.mime};base64,${response.data.data.image}`;

                if ( e.safety_observation_item_file_type_id == 1 ) {
                  // console.log('observation');
                  this.slide_items.observation.push(tmpObj);
                }
                if ( e.safety_observation_item_file_type_id == 2 && !e.safety_observation_items_rejected_id ) {
                  // console.log('correction');
                  this.slide_items.correction.push(tmpObj);
                }
              }
            })
            .catch((error) => {
              console.log(error.response.status);
            });
        }
      });

      //_ rejected entries
      // console.log(this.model.rejected_corrections.length);
      if (this.model.rejected_corrections.length > 0) {
        this.model.rejected_corrections.forEach((e, index) => {
          let tmpRejected_container = {};

          tmpRejected_container = {
            rejected_by: e.rejected_by.name,
            rejected_at: e.created_at,
            rejected_reason: e.safety_observation_reject_reason,
            submitted_by: e.correction_submitted_by.name,
            submitted_at: e.correction_submitted_at,
            correction_remark: e.correction_remarks,
            photos: []
          };

          this.model.safety_observation_photos.forEach((photoEntry, photoIndex) => {
            if ( photoEntry.safety_observation_items_rejected_id == e.safety_observation_items_rejected_id ){
              // console.log(photoEntry.photoRemarks);
              // console.log(photoEntry.safety_observation_item_file_name)
              let tmpPhoto = null;
              axios
                .get(apiDomain + photoEntry["image"]["url"], { headers: getHeader() })
                .then((response) => {
                  if (response.status === 200) {
                    tmpPhoto = {
                      photo_image: `data:${response.data.data.mime};base64,${response.data.data.image}`,
                      photo_remark: (photoEntry.photoRemarks == '') ? '--no remark--' : photoEntry.photoRemarks
                    }
                  }

                  tmpRejected_container.photos.push(tmpPhoto);
                })
                .catch((error) => {
                  console.log(error.response.status);
                });
            }
          });

          this.slide_items.rejection.push(tmpRejected_container);
        });
      }      
    },
    getSafetyObservationItem() {
      // console.log('getSafetyObservationItem');
      axios
        .get(
          apiDomain +
          "health_safety/safety_observation_item/" +
          this.safety_observation_item_id,
          { headers: getHeader() }
        )
        .then((response) => {
          if (response.status === 200) {
            // console.log(response.data.data);

            this.safetyObservationItemResponse = response.data.data;
            this.model.safety_observation_status_id = response.data.data[0].safety_observation_status_id;
            this.model.safety_observation_id = response.data.data[0].safety_observation_id;
            // this.getAssignedContractorId();
            this.model.observation_description = response.data.data[0].observation_description;
            this.model.safety_observation_type_id = response.data.data[0].safety_observation_type_id;
            this.model.safety_observation_category_id = response.data.data[0].safety_observation_category_id;
            this.model.correction_photos_required = response.data.data[0].correction_photos_required;
            this.model.safety_observation_priority_id = response.data.data[0].safety_observation_priority_id;
            this.model.safety_observation_photos = response.data.data[0].safety_observation_photos;
            this.model.target_date = response.data.data[0].target_date;
            this.model.correction_description = response.data.data[0].correction_remarks;
            this.model.correction_submitted_by = response.data.data[0].correction_submitted_by;
            this.model.correction_submitted_at = response.data.data[0].correction_submitted_at;
            this.model.rejected_corrections = response.data.data[0].rejected_corrections;
            this.model.approval_remarks = response.data.data[0].approval_remarks;
            this.model.approved_by = response.data.data[0].approved_by;
            this.model.resolved_at = response.data.data[0].resolved_at;
            this.model.repeated_observation = response.data.data[0].repeated_observation;
            this.insertImageDataSrc();

            // console.log('model: ');
            // console.log(this.model);
          }
        })
        .catch((error) => {
          console.log(error.response.status);
          this.errorFunction(error, "Safety Observation Item Information");
        })
        .finally(() => {
          this.getSafetyObservationListing();
        });
    },
    getCorrectionPhotosCount() {
      // console.log("getCorrectionPhotosCount(){");
      if (this.model.correction_photos_required == 1) {
        var counter = 0;
        this.model.safety_observation_photos.forEach((e) => {
          if (
            e["safety_observation_item_file_type_id"] == 2 &&
            !e["safety_observation_items_rejected_id"]
          ) {
            counter++;
          }
        });
        // console.log(counter);
        if (counter > 0) {
          return true;
        } else {
          return false;
        }
      }
      return true;
    },
    checkForRequiredData() {
      var checker = false;
      if (
        !this.model.correction_description ||
        !this.getCorrectionPhotosCount()
      ) {
        if (!this.model.correction_description) {
          this.redErrorNotify(
            "<b>Invalid Input : Action Taken is a Mandatory Field in Safety Observation Item Correction </b>"
          );
        } else if (!this.getCorrectionPhotosCount()) {
          this.redErrorNotify(
            "<b>Invalid Input : Correction Photos Required but None Has Been Added </b>"
          );
        }
      } else {
        console.log("passed all checks");
        checker = true;
      }
      return checker;
    },
    submitData() {
      // console.log("submitData(){");
      if (this.checkForRequiredData()) {
        // console.log("submit data");

        this.model.safety_observation_item_id = this.safety_observation_item_id;
        this.model.safety_observation_status_id = 4;

        let formData = jsonFormData(this.model);
        formData.append("_method", "put");

        // console.log(typeof formData);

        const tokenData = JSON.parse(window.localStorage.getItem("authUser"));
        const headers = {
          Authorization: "Bearer " + tokenData.access_token,
        };

        axios
          .post(
            apiDomain +
            "health_safety/safety_observation_item/" +
            this.safety_observation_item_id,
            formData,
            { headers }
          )
          .then((response) => {
            if (response.status === 200) {
              this.$notify({
                message:
                  "<b>Safety Observation Item Successfully Updated and Submitted.</b>",
                timeout: 10000,
                icon: "ni ni-bell-55",
                type: "default",
              });
              this.$router.back();
            }
          })
          .catch((error) => {
            this.errorFunction(error, "Update Safety Observation Item Submit");
          });
      }
    },
    getProjectListing() {
      if (this.user_role == 12) {
        if (this.contractor_id != null) {
          this.getProjectListingContractor();
        }
      } else {
        this.getProjectListingNormal();
      }

      // console.log('getProjectListing');
      // console.log(this.projects);
    },
    getProjectListingNormal() {
      axios
        .get(apiDomain + "project/project", { headers: getHeader() })
        .then((response) => {
          if (response.status === 200) {
            this.projects = response.data.data;
          }
        })
        .catch((error) => {
          console.log(error.response.status);
          this.errorFunction(error, "Project Listing");
        });
    },
    getProjectListingContractor() {
      axios
        .get(
          apiDomain +
          "project/project.show_by_contractor/" +
          this.contractor_id,
          { headers: getHeader() }
        )
        .then((response) => {
          if (response.status === 200) {
            this.projects = response.data.data;
          }
        })
        .catch((error) => {
          console.log(error.response.status);
          this.errorFunction(error, "Project Listing Contractor");
        });
    },
    getGroupFromProject() {
      if (this.user_role == 12) {
        this.getGroupFromProjectContractor();
      } else {
        this.getGroupFromProjectNormal();
      }
    },
    getGroupFromProjectNormal() {
      axios
        .get(
          apiDomain +
          "project/project_group.show_by_project/" +
          this.model.project_id,
          { headers: getHeader() }
        )
        .then((response) => {
          if (response.status === 200) {
            this.project_groups = response.data.data;
            // this.model.group_id = 'y';
            // this.model.site_id = 'x';
            this.project_sites = [];
          }
        })
        .catch((error) => {
          console.log(error.response.status);
        });
    },
    getGroupFromProjectContractor() {
      axios
        .get(
          apiDomain +
          "project/project_group.show_by_project_by_contractor/" +
          this.model.project_id +
          "/" +
          this.contractor_id,
          { headers: getHeader() }
        )
        .then((response) => {
          if (response.status === 200) {
            this.project_groups = response.data.data;
            // this.model.group_id = 'y';
            // this.model.site_id = 'x';
            this.project_sites = [];
          }
        })
        .catch((error) => {
          console.log(error.response.status);
        });
    },
    getSiteFromGroup() {
      if (this.user_role == 12) {
        this.getSiteFromGroupContractor();
      } else {
        this.getSiteFromGroupNormal();
      }
    },
    getSiteFromGroupNormal() {
      axios
        .get(
          apiDomain +
          "project/project_site.show_by_group/" +
          this.model.group_id,
          { headers: getHeader() }
        )
        .then((response) => {
          if (response.status === 200) {
            this.project_sites = response.data.data;
            // this.model.site_id = 'y';
          }
        })
        .catch((error) => {
          console.log(error.response.status);
        });
    },
    getSiteFromGroupContractor() {
      axios
        .get(
          apiDomain +
          "project/project_site.show_by_group_by_contractor/" +
          this.model.group_id +
          "/" +
          this.contractor_id,
          { headers: getHeader() }
        )
        .then((response) => {
          if (response.status === 200) {
            this.project_sites = response.data.data;
            // this.model.site_id = 'y';
          }
        })
        .catch((error) => {
          console.log(error.response.status);
        });
    },
    getContractorFromSite() {
      axios
        .get(
          apiDomain +
          "project/site_contractor.show_by_site/" +
          this.model.site_id,
          { headers: getHeader() }
        )
        .then((response) => {
          if (response.status === 200) {
            this.site_contractors = response.data.data;
            var index = this.site_contractors.findIndex(
              (x) => x.active_status === "ACTIVE"
            );
            this.model.contractor_id =
              this.site_contractors[index].contractor.contractor_id;
          }
        })
        .catch((error) => {
          console.log(error.response.status);
          this.errorFunction(error, "Site Contractor Information");
        });
    },
    getUserList() {
      if (this.user_role == 12) {
        this.getUserListContractor();
      } else {
        this.getUserListNormal();
      }
    },
    getUserListNormal() {
      axios
        .get(apiDomain + "lookup/user_list", { headers: getHeader() })
        .then((response) => {
          if (response.status === 200) {
            //_ REMARKING this entry, because the API result is undefined
            
            // this.users = response.data.data;
            // this.addUserToConducting();
          }
        })
        .catch((error) => {
          this.errorFunction(error, "User Listing");
        });
    },
    getUserListContractor() {
      axios
        .get(apiDomain + "lookup/user_list.contractor/" + this.contractor_id, {
          headers: getHeader(),
        })
        .then((response) => {
          if (response.status === 200) {
            this.users = response.data.data;
            this.addUserToConducting();
          }
        })
        .catch((error) => {
          this.errorFunction(error, "User Listing");
        });
    },
    getSafetyObservationTypes() {
      axios
        .get(apiDomain + "lookup/safety_observation_type", {
          headers: getHeader(),
        })
        .then((response) => {
          if (response.status === 200) {
            this.safety_observation_type = response.data.data;
          }
        })
        .catch((error) => {
          console.log(error.response.status);
          this.errorFunction(error, "Safety Observation Type Information");
        });
    },
    getSafetyObservationCategories() {
      axios
        .get(apiDomain + "setup/safety_observation_category_active", {
          headers: getHeader(),
        })
        .then((response) => {
          if (response.status === 200) {
            this.safety_observation_category = response.data.data;
          }
        })
        .catch((error) => {
          console.log(error.response.status);
          this.errorFunction(error, "Safety Observation Category");
        });
    },
    getSafetyObservationPriorities() {
      axios
        .get(apiDomain + "setup/safety_observation_priority_active", {
          headers: getHeader(),
        })
        .then((response) => {
          if (response.status === 200) {
            this.safety_observation_priority = response.data.data;
          }
        })
        .catch((error) => {
          console.log(error.response.status);
          this.errorFunction(error, "Safety Observation Priority");
        });
    },
    getSafetyObservationStatusNames() {
      axios
        .get(apiDomain + "lookup/safety_observation_status", {
          headers: getHeader(),
        })
        .then((response) => {
          if (response.status === 200) {
            this.safety_observation_status = response.data.data;
          }
        })
        .catch((error) => {
          console.log(error.response.status);
          this.errorFunction(error, "Safety Observation Status");
        });
    },
    populateConductingUsers(conducting_users_array) {
      // safety_observation_conducting_user SORT THIS ARRAY BY safety_observation_conducting_user_id
      this.conducting_users = [];
      conducting_users_array.forEach((item) => {
        this.conducting_users.push({
          userId: item.safety_observation_conducting_user.id,
        });
        this.users.map((e_rol) => {
          if (e_rol.id == item.safety_observation_conducting_user.id) {
            e_rol["disabled"] = true;
            e_rol["v_hide"] = true;
          }
        });
      });
    },
    getSafetyObservationStatus(status_id) {
      var findObj = this.safety_observation_status.find((obj) => {
        return obj.status_id === status_id;
      });
      if (typeof findObj !== "undefined") {
        return findObj.status_code;
      } else {
        return status_id;
      }
    },
    loadAllSafetyObservationPhotos() {
      this.model.safety_observation_items.forEach((e) => {
        e["safety_observation_photos"].forEach((el) => {
          if (el["image"]["url"]) {
            axios
              .get(apiDomain + el["image"]["url"], { headers: getHeader() })
              .then((response) => {
                if (response.status === 200) {
                  if (response.data.data.image && response.data.data.image) {
                    el[
                      "imageDataSrc"
                    ] = `data:${response.data.data.image};base64,${response.data.data.image}`;
                  } else {
                    el["imageDataSrc"] = null;
                  }
                }
              })
              .catch((error) => {
                console.log(error.response.status);
              });
          }
        });
      });
    },
    getContractorId() {
      axios
        .get(apiDomain + "lookup/contractor.contractor_id/" + this.user_id, {
          headers: getHeader(),
        })
        .then((response) => {
          if (response.status === 200) {
            this.contractor_id = response.data.contractor_id;
            this.getProjectListing();
          }
        })
        .catch((error) => {
          this.errorFunction(error, "Contractor Id");
        });
    },
    getSafetyObservationListing() {
      // console.log("getSafetyObservationListing");
      // console.log(this.model.safety_observation_id)
      axios
        .get(
          apiDomain +
          "health_safety/safety_observation/" +
          this.model.safety_observation_id,
          { headers: getHeader() }
        )
        .then((response) => {
          if (response.status === 200) {
            // console.log(response)
            this.oDisplay.project_name = response.data.data[0].project.project_name;
            this.oDisplay.site_name = response.data.data[0].site.site_name;

            // console.log(this.oDisplay)
          }
        })
        .catch((error) => {
          console.log(error.response.status);
          this.errorFunction(error, "Safety Observation Information");
        });
    },
  },
};
</script>


<style scoped>
/* Class For Modal */

.th-table-new {
  padding-top: 35px;
  padding-bottom: 35px;
  padding-left: 20px;
  font-size: small; 
  /*0.65rem;*/
}

.tr-td-table-new {
  padding-top: 30px;
  padding-bottom: 30px;
  padding-left: 20px;
}

.tr-td-table-new-mdl {
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 20px;
}

/deep/ .form-control {
  background-color: #2C545A;
  border-color: #2C545A;
  color: white;

  &:focus {
    background-color: #2C545A;
    border-color: #2C545A;
    /* color: white; */
  }

  &::placeholder {
    color: rgb(163, 163, 163);
  }
}

/deep/ .form-control {
  &:focus {
    &::placeholder {
      color: rgb(163, 163, 163);
    }
  }
}

.input-group-text {
  background-color: #2C545A;
  ;
  color: white;

  &:focus {
    background-color: #2C545A;
    ;
    border-color: #1D4147;
    color: white;
  }
}

.focused {
  .input-group-text {
    background-color: #2C545A;
  }
}

.navbar-search-dark .input-group {
  background-color: #23454A;
  color: black;
  border-color: rgba(255, 255, 255, 0.6);
}

.navbar-search .input-group {
  border-radius: 7px;
}

select {
  background: url("data:image/svg+xml,<svg width='10' height='7' viewBox='0 0 10 7' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M5.02552 6.35057L0.320068 0.46875H9.73098L5.02552 6.35057Z' fill='white'/></svg>") no-repeat;
  background-position: calc(100% - 0.75rem) center !important;
  -moz-appearance: none !important;
  -webkit-appearance: none !important;
  appearance: none !important;
  padding-right: 2rem !important;
}

option {
  width: 100px !important;
}

</style>

<style scoped>
/deep/ h1, h2, h3, h4, h5 {
  color: #ffffff;
}

/deep/ .card-body {
  background-color: #0f2827;
}
</style>


<!-- el-select custom -->
<style scoped>
/deep/ .el-select .el-input.is-focus .el-input__inner {
  border-color: transparent !important;
}

/deep/ .el-select .el-input .el-input__inner:focus {
  border-color: transparent !important;
}

/deep/ .el-select .el-select__tags .el-tag {
  background-color: #183034;
}

/deep/ .el-select-dropdown.is-multiple .el-select-dropdown__item.selected {
  background-color: #183034;
  color: #ffffff;
}

/deep/ .el-select-dropdown.el-popper .el-select-dropdown__item.selected, .el-select-dropdown.el-popper.is-multiple .el-select-dropdown__item.selected {
  background-color: #183034;
  color: #ffffff;
}
</style>

<!-- dataset -->
<style scoped>
/deep/ div.dataset-style select {
  color: #ffffff;
  background-color: #2c545a;
  border-color: #2c545a;
}

/deep/ div.dataset-style select.form-control {
  color: #ffffff;
  background-color: #2c545a;
  border-color: #2c545a;
}

/deep/ div.dataset-style select>option {
  color: #ffffff;
  background-color: #2c545a;
  border-color: #2c545a;
}
</style>

<!-- grid observation item -->
<style scoped>
/deep/ .grid-obs-item thead {
  background-color: rgb(82 146 155); color: white; font-size: small; border-bottom: 20px solid #306b74;
}

/deep/ .th-table-new-mdl {
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 20px;
  font-size: small;
}

.list-group-item {
  background-color: #23454A;
  border-bottom: 1px solid black;
}
</style>

<!-- pagination style -->
<style scoped>
/deep/ .pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.25rem;
  color: black;
}

/deep/ .page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.65rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #345f66;
  background-color: #fff;
  border: 1px solid #dee2e6;
}

/deep/ .page-link:hover {
  z-index: 2;
  color: #345f66;
  text-decoration: none;
  background-color: #e9ecef;
  border-color: #dee2e6;
}

/deep/ .page-link:focus {
  z-index: 3;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(62, 175, 124, 0.25);
}

/deep/ .page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

/deep/ .page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}

/deep/ .page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #345f66;
  border-color: #3a656b;
}

/deep/ .page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #dee2e6;
}

/deep/ .pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.5;
}

/deep/ .pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem;
  width: 60px;
}

/deep/ .pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
}

/deep/ .pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
}

/deep/ .pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem;
}

/deep/ .pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem;
}

/deep/ .b-pagination-pills .page-item .page-link {
  border-radius: 50rem !important;
  margin-left: 0.25rem;
  line-height: 1;
}

/deep/ .b-pagination-pills .page-item:first-child .page-link {
  margin-left: 0;
}
</style>

<!-- modal - loading in progress -->
<style scoped>
/deep/ .in-progress-modal-lg .modal-dialog {
    width: 100% !important;
    max-width: none !important;
    height: 100% !important;
    margin: 0 !important;
  }
  
/deep/ .in-progress-modal-lg .modal-dialog .modal-content {
    height: 100% !important;
    border: 0 !important;
    border-radius: 0 !important;
    /* background-color: transparent !important; */
    background: rgba(0,0,0,0.5);
    border: none !important;
  }
</style>

<!-- tabs -->
<style scoped>
/deep/ .card .tabs .card-header {
  background-color: #2c545a !important; 
}

/deep/ .nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link {
  background-color: #0f2827;
  border-color: #2c545a #2c545a #0f2827;
  color: white;
}

/deep/ .nav-item .nav-link {
  color: white;
}
</style>

<!-- vueperslide -->
<style scoped>
/deep/ .vueperslide__content {
  font-size: 24px;
  color: white;
  text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
}

/deep/ .vueperslides__arrow svg {
  /* color: white;
  stroke: black;
  stroke-width: 2px; */
  filter: drop-shadow(0 0 2px black);
}

</style>

<!-- https://jsonviewer.stack.hu/
{
  "data": [
    {
      "safety_observation_item_id": 25790,
      "safety_observation_id": 8278,
      "observation_description": "test 8",
      "safety_observation_type_id": 4,
      "safety_observation_type": {
        "safety_observation_type_id": 4,
        "safety_observation_type_code": "Unsafe Act"
      },
      "safety_observation_category_id": 17,
      "safety_observation_category": {
        "safety_observation_category_id": 17,
        "safety_observation_category": "Electrical and Power Operated Tools"
      },
      "correction_photos_required": 1,
      "safety_observation_priority_id": 2,
      "safety_observation_priority": {
        "safety_observation_priority_id": 2,
        "safety_observation_priority_name": "2",
        "safety_observation_priority_duration": 7
      },
      "safety_observation_status_id": 3,
      "safety_observation_status": {
        "status_id": 3,
        "status_code": "Closed"
      },
      "target_date": "02-Sep-2024",
      "correction_remarks": "new ui test correction #4",
      "approval_remarks": "ok",
      "correction_submitted_by": {
        "id": 404,
        "name": "Troy HSE",
        "email": "troyspd@gmail.com",
        "role_id": 16
      },
      "correction_submitted_at": "02-Sep-2024",
      "approved_by": {
        "id": 404,
        "name": "Troy HSE",
        "email": "troyspd@gmail.com",
        "role_id": 16
      },
      "resolved_at": "02-Sep-2024",
      "safety_observation_photos": [
        {
          "safety_observation_item_file_id": 51555,
          "safety_observation_item_id": 25790,
          "safety_observation_item_file_type_id": 1,
          "safety_observation_item_file_type": {
            "safety_observation_item_file_type_id": 1,
            "safety_observation_type_code": "Observation"
          },
          "safety_observation_item_file_name": "hsse_oip.jpg",
          "safety_observation_item_file_hash_name": "NrEuuBiFLSq6YIXMehtU17tvYXA2cduYy8xr4Thf.jpg",
          "photoRemarks": "",
          "safety_observation_items_rejected_id": null,
          "created_by": null,
          "created_at": "02-Sep-2024",
          "updated_at": "02-Sep-2024",
          "image": {
            "hash_name": "NrEuuBiFLSq6YIXMehtU17tvYXA2cduYy8xr4Thf.jpg",
            "url": "health_safety/file_image/NrEuuBiFLSq6YIXMehtU17tvYXA2cduYy8xr4Thf.jpg"
          }
        },
        {
          "safety_observation_item_file_id": 51563,
          "safety_observation_item_id": 25790,
          "safety_observation_item_file_type_id": 2,
          "safety_observation_item_file_type": {
            "safety_observation_item_file_type_id": 2,
            "safety_observation_type_code": "Correction"
          },
          "safety_observation_item_file_name": "hsse_harness.jpg",
          "safety_observation_item_file_hash_name": "I5khMnm1R4TzWNtTz3yVj5tbZyFLmlYfXv7uxoB4.jpg",
          "photoRemarks": "testing",
          "safety_observation_items_rejected_id": 281,
          "created_by": null,
          "created_at": "02-Sep-2024",
          "updated_at": "02-Sep-2024",
          "image": {
            "hash_name": "I5khMnm1R4TzWNtTz3yVj5tbZyFLmlYfXv7uxoB4.jpg",
            "url": "health_safety/file_image/I5khMnm1R4TzWNtTz3yVj5tbZyFLmlYfXv7uxoB4.jpg"
          }
        },
        {
          "safety_observation_item_file_id": 51564,
          "safety_observation_item_id": 25790,
          "safety_observation_item_file_type_id": 2,
          "safety_observation_item_file_type": {
            "safety_observation_item_file_type_id": 2,
            "safety_observation_type_code": "Correction"
          },
          "safety_observation_item_file_name": "hsse_disposable_gloves.jpg",
          "safety_observation_item_file_hash_name": "6FGZMvTnyn3UB5wrtN1CFZueR45oymAJ92yvO8Zf.jpg",
          "photoRemarks": "test correction #2",
          "safety_observation_items_rejected_id": 282,
          "created_by": null,
          "created_at": "02-Sep-2024",
          "updated_at": "02-Sep-2024",
          "image": {
            "hash_name": "6FGZMvTnyn3UB5wrtN1CFZueR45oymAJ92yvO8Zf.jpg",
            "url": "health_safety/file_image/6FGZMvTnyn3UB5wrtN1CFZueR45oymAJ92yvO8Zf.jpg"
          }
        },
        {
          "safety_observation_item_file_id": 51605,
          "safety_observation_item_id": 25790,
          "safety_observation_item_file_type_id": 2,
          "safety_observation_item_file_type": {
            "safety_observation_item_file_type_id": 2,
            "safety_observation_type_code": "Correction"
          },
          "safety_observation_item_file_name": "hsse_disposable_gloves.jpg",
          "safety_observation_item_file_hash_name": "uO4yTOMtkI5HCywoadGD8xnnWpaQvpGoFCgGYz5w.jpg",
          "photoRemarks": "test 1",
          "safety_observation_items_rejected_id": 283,
          "created_by": null,
          "created_at": "02-Sep-2024",
          "updated_at": "02-Sep-2024",
          "image": {
            "hash_name": "uO4yTOMtkI5HCywoadGD8xnnWpaQvpGoFCgGYz5w.jpg",
            "url": "health_safety/file_image/uO4yTOMtkI5HCywoadGD8xnnWpaQvpGoFCgGYz5w.jpg"
          }
        },
        {
          "safety_observation_item_file_id": 51606,
          "safety_observation_item_id": 25790,
          "safety_observation_item_file_type_id": 2,
          "safety_observation_item_file_type": {
            "safety_observation_item_file_type_id": 2,
            "safety_observation_type_code": "Correction"
          },
          "safety_observation_item_file_name": "hsse_OIP_tools.jpg",
          "safety_observation_item_file_hash_name": "vSpPYmrzUMrhl9zQMIxRqUnFuZoDS321vMvhiHTR.jpg",
          "photoRemarks": "test 2",
          "safety_observation_items_rejected_id": 283,
          "created_by": null,
          "created_at": "02-Sep-2024",
          "updated_at": "02-Sep-2024",
          "image": {
            "hash_name": "vSpPYmrzUMrhl9zQMIxRqUnFuZoDS321vMvhiHTR.jpg",
            "url": "health_safety/file_image/vSpPYmrzUMrhl9zQMIxRqUnFuZoDS321vMvhiHTR.jpg"
          }
        },
        {
          "safety_observation_item_file_id": 51607,
          "safety_observation_item_id": 25790,
          "safety_observation_item_file_type_id": 2,
          "safety_observation_item_file_type": {
            "safety_observation_item_file_type_id": 2,
            "safety_observation_type_code": "Correction"
          },
          "safety_observation_item_file_name": "hsse_mask.jpg",
          "safety_observation_item_file_hash_name": "V9bb8GaEjZAEJ6bNyHSsnYBrpKeu4m4WyHiVvOYQ.jpg",
          "photoRemarks": "test 3",
          "safety_observation_items_rejected_id": 283,
          "created_by": null,
          "created_at": "02-Sep-2024",
          "updated_at": "02-Sep-2024",
          "image": {
            "hash_name": "V9bb8GaEjZAEJ6bNyHSsnYBrpKeu4m4WyHiVvOYQ.jpg",
            "url": "health_safety/file_image/V9bb8GaEjZAEJ6bNyHSsnYBrpKeu4m4WyHiVvOYQ.jpg"
          }
        },
        {
          "safety_observation_item_file_id": 51608,
          "safety_observation_item_id": 25790,
          "safety_observation_item_file_type_id": 2,
          "safety_observation_item_file_type": {
            "safety_observation_item_file_type_id": 2,
            "safety_observation_type_code": "Correction"
          },
          "safety_observation_item_file_name": "hsse_workplace-safety-tips.jpg",
          "safety_observation_item_file_hash_name": "jwQDClEa05lX996IpXEjqmYVCJbaL85YDxN0OWIm.jpg",
          "photoRemarks": "test 4.1",
          "safety_observation_items_rejected_id": null,
          "created_by": null,
          "created_at": "02-Sep-2024",
          "updated_at": "02-Sep-2024",
          "image": {
            "hash_name": "jwQDClEa05lX996IpXEjqmYVCJbaL85YDxN0OWIm.jpg",
            "url": "health_safety/file_image/jwQDClEa05lX996IpXEjqmYVCJbaL85YDxN0OWIm.jpg"
          }
        },
        {
          "safety_observation_item_file_id": 51609,
          "safety_observation_item_id": 25790,
          "safety_observation_item_file_type_id": 2,
          "safety_observation_item_file_type": {
            "safety_observation_item_file_type_id": 2,
            "safety_observation_type_code": "Correction"
          },
          "safety_observation_item_file_name": "hsse_oip.jpg",
          "safety_observation_item_file_hash_name": "GFgqkfF7MswEWbxRp2kQR0c5UkxvbEaVt5FWoST7.jpg",
          "photoRemarks": "test 42",
          "safety_observation_items_rejected_id": null,
          "created_by": null,
          "created_at": "02-Sep-2024",
          "updated_at": "02-Sep-2024",
          "image": {
            "hash_name": "GFgqkfF7MswEWbxRp2kQR0c5UkxvbEaVt5FWoST7.jpg",
            "url": "health_safety/file_image/GFgqkfF7MswEWbxRp2kQR0c5UkxvbEaVt5FWoST7.jpg"
          }
        }
      ],
      "rejected_corrections": [
        {
          "safety_observation_items_rejected_id": 281,
          "safety_observation_item_id": 25790,
          "safety_observation_reject_reason": "test rejection",
          "correction_photos_required": 1,
          "correction_remarks": "testing new ui",
          "correction_submitted_by": {
            "id": 405,
            "name": "Troy EPC",
            "email": "troyspd@outlook.com",
            "role_id": 12
          },
          "correction_submitted_at": "02-Sep-2024",
          "rejected_by": {
            "id": 404,
            "name": "Troy HSE",
            "email": "troyspd@gmail.com",
            "role_id": 16
          },
          "created_by": {
            "id": 404,
            "name": "Troy HSE",
            "email": "troyspd@gmail.com",
            "role_id": 16
          },
          "created_at": "02-Sep-2024",
          "updated_at": "02-Sep-2024"
        },
        {
          "safety_observation_items_rejected_id": 282,
          "safety_observation_item_id": 25790,
          "safety_observation_reject_reason": "rejected again",
          "correction_photos_required": 1,
          "correction_remarks": "testing corrective new ui #2",
          "correction_submitted_by": {
            "id": 404,
            "name": "Troy HSE",
            "email": "troyspd@gmail.com",
            "role_id": 16
          },
          "correction_submitted_at": "02-Sep-2024",
          "rejected_by": {
            "id": 404,
            "name": "Troy HSE",
            "email": "troyspd@gmail.com",
            "role_id": 16
          },
          "created_by": {
            "id": 404,
            "name": "Troy HSE",
            "email": "troyspd@gmail.com",
            "role_id": 16
          },
          "created_at": "02-Sep-2024",
          "updated_at": "02-Sep-2024"
        },
        {
          "safety_observation_items_rejected_id": 283,
          "safety_observation_item_id": 25790,
          "safety_observation_reject_reason": "reject #3",
          "correction_photos_required": 1,
          "correction_remarks": "new UI correction #3",
          "correction_submitted_by": {
            "id": 404,
            "name": "Troy HSE",
            "email": "troyspd@gmail.com",
            "role_id": 16
          },
          "correction_submitted_at": "02-Sep-2024",
          "rejected_by": {
            "id": 404,
            "name": "Troy HSE",
            "email": "troyspd@gmail.com",
            "role_id": 16
          },
          "created_by": {
            "id": 404,
            "name": "Troy HSE",
            "email": "troyspd@gmail.com",
            "role_id": 16
          },
          "created_at": "02-Sep-2024",
          "updated_at": "02-Sep-2024"
        }
      ],
      "created_by": {
        "id": 404,
        "name": "Troy HSE",
        "email": "troyspd@gmail.com",
        "role_id": 16
      },
      "created_by_contractor_id": null,
      "created_at": "02-Sep-2024",
      "updated_at": "02-Sep-2024",
      "repeated_observation": 0,
      "project_status": "ongoing"
    }
  ],
  "message": "Data Retrieved successfully"
}


-->