<template>
   <div>
        <card class="no-border-card" body-classes="px-0 pb-1" footer-classes="pb-2">
          <template slot="header">
            <h3 class="mb-0">Document Recurring Listing for ({{ model.document_title }})</h3>
          </template>
          <div>
            <b-col cols="12" class="d-flex justify-content-center justify-content-sm-between flex-wrap">
            </b-col>

            <el-table :data="queriedData"
                      row-key="project_id"
                      header-row-class-name="thead-light"
                      @sort-change="sortChange">

              <el-table-column label="Recurring Date"
                             min-width="130px"
                             prop="document_version"
                             sortable>
                <template v-slot="{row}">
                    <b-media no-body class="align-items-center">
                        <b-media-body>
                            <span class="font-weight-600 name mb-0 text-sm">{{ row.show_document_recurring_date }}</span>
                        </b-media-body>
                    </b-media>
                </template>
              </el-table-column>

              <el-table-column label="Current Status"
                             min-width="120px"
                             prop="Upload By"
                             sortable>
                <template v-slot="{row}">
                    <b-media no-body class="align-items-center">
                        <b-media-body>
                            <span class="font-weight-600 name mb-0 text-sm">{{ row.current_status.status_code}}</span>
                        </b-media-body>
                    </b-media>
                </template>
              </el-table-column>

              <el-table-column label="Last Updated"
                             min-width="120px"
                             prop="upload_time"
                             sortable>
                <template v-slot="{row}">
                    <b-media no-body class="align-items-center">
                        <b-media-body>
                            <span class="font-weight-600 name mb-0 text-sm"> {{ row.updated_at }}</span>
                        </b-media-body>
                    </b-media>
                </template>
              </el-table-column>

              <el-table-column min-width="100px" align="right" label="Actions">
                <div slot-scope="{$index, row}" class="d-flex">
                  <base-button v-if="button_access.upload_listing == 'YES'"
                    @click.native="documentDetails($index, row)" size="sm" type="primary">
                    Doc. Details
                  </base-button>
                </div>
              </el-table-column>
            </el-table>
          </div>
          <div
            slot="footer"
            class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap">
            <div class="">
              <p class="card-category">
                Showing {{ from + 1 }} to {{ to }} of {{ total }} entries

                <span v-if="selectedRows.length">
                  &nbsp; &nbsp; {{selectedRows.length}} rows selected
                </span>
              </p>
            </div>
            <base-pagination
              class="pagination-no-border"
              :current="pagination.currentPage"
              :per-page="pagination.perPage"
              :total="total"
              @change="paginationChanged($event)">
            </base-pagination>
             <!-- <div>
              <br>
              <h5 class="text-danger"> Note :
                  <i>
                    During this Demo, we will upload the file to our demo server, not SEMCORP file server and we only accept file size below around 10 MB and these are the file type we accept : jpeg, jpg, png, gif, pdf, dwg, doc, docx, xlsx, ppt, pptx.
                  </i>
              </h5>
            </div> -->
          </div>
        </card>
      </div>
</template>

<script>
  //import projects from './projects'
  import { Table, TableColumn, Select, Option } from 'element-ui';
  import RouteBreadCrumb from '@/components/Breadcrumb/RouteBreadcrumb'
  import { BasePagination } from '@/components';
  import {LoginURL, clientId, clientSecret, apiDomain, getHeader, fuseData} from '@/assets/js/config.js'
  import standardPaginationMix from '@/assets/js/standardPaginationMix.js'
  import ErrorFunctionMixin from '@/assets/js/errorFunctionMix.js'
  import Fuse from 'fuse.js';
  import axios from 'axios'

  export default {
    mixins: [standardPaginationMix, ErrorFunctionMixin],
    components: {
      BasePagination,
      RouteBreadCrumb,
      [Select.name]: Select,
      [Option.name]: Option,
      [Table.name]: Table,
      [TableColumn.name]: TableColumn,
    },
    created()
    {
      var site_document_upload_access = JSON.parse(window.localStorage.getItem('site_document_upload_access'))
      this.button_access.upload_listing = site_document_upload_access.access_listing

      this.getCreateData()
    },
    data() {
      return {

        propsToSearch:
        [
            'updated_at',
            'current_status.status_code',
            'show_document_recurring_date'
        ],
        model:{
          site_document_id: null,
          document_title: null,
          document_category: null,
          document_recurring_interval: null,
          show_document_recurring_date: null,
          current_status: null,
        },
        tableColumns: [],
        tableData: [],
        selectedRows: [],
        button_access:{
        new_button: null,
        show_button: null,
        upload_listing: null,
        recurring_listing: null,
      },
      };
    },
    methods:{
      getCreateData()
      {
        this.model.site_document_id = this.$route.params.id

        axios.get(apiDomain + 'document/site_document_recurring.show_by_document/' + this.model.site_document_id, { headers: getHeader() })
        .then ( response => {
        if (response.status === 200)
        {
            this.tableData = response.data.data
            this.model.document_title = response.data.data[0].site_document.document_title
            this.fuseData()
        }
        })
        .catch ( error => {
          this.errorFunction(error, "Site Document Recurring Information")
        });
      },
      paginationChanged(page)
      {
          this.pagination.currentPage = page
      },
      fuseData()
      {
          this.fuseSearch = new Fuse(this.tableData, {
          keys: this.propsToSearch,
          threshold: 0.3
          });
      },
      documentDetails(index, row)
      {
        let route = this.$router.resolve({path: '/siteDocumentDetailsListing/' + row.site_document_recurring_id + '/' + 'RECURRING' })
        window.open(route.href, '_blank');
      },
      selectionChange(selectedRows)
      {
          this.selectedRows = selectedRows
      }
    }
  }
</script>
