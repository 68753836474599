
<template>
  <b-card no-body class="bg-default shadow" header-class="border-0">
    <template v-slot:header>
      <b-row align-v="center">
        <b-col>
          <h5 class="bg-transparent border-0">Latest turn On, MWp</h5>
        </b-col>
        <b-col class="text-right">
          <h5 class="bg-transparent border-0">Total : 0.72 MWp</h5>
        </b-col>
      </b-row>
    </template>

    <el-table
      class="table-responsive table-dark"
      :data="tableData"
      header-row-class-name="thead-dark">
      <el-table-column label="Site" min-width="100px" prop="site">
         <template v-slot="{row}">
          <div class="d-flex align-items-center">
              <span style="font-size: 11px;">{{row.site}}</span>
          </div>
        </template>

      </el-table-column>

      <el-table-column label="MWp" min-width="85px" prop="capacity">
         <template v-slot="{row}">
          <div class="d-flex align-items-center">
              <span style="font-size: 11px;">{{row.capacity}}</span>
          </div>
        </template>
      </el-table-column>

      <el-table-column label="Month" min-width="95px" prop="month">
         <template v-slot="{row}">
          <div class="d-flex align-items-center">
              <span style="font-size: 11px;">{{row.month}}</span>
          </div>
        </template>
      </el-table-column>

    </el-table>
  </b-card>
</template>
<script>
  import { BaseProgress } from '@/components';
  import { Table, TableColumn, DropdownMenu, DropdownItem, Dropdown} from 'element-ui'
  export default {
    name: 'latest-turn-on-table',
    components: {
      BaseProgress,
      [Table.name]: Table,
      [TableColumn.name]: TableColumn,
      [Dropdown.name]: Dropdown,
      [DropdownItem.name]: DropdownItem,
      [DropdownMenu.name]: DropdownMenu,
    },
    data() {
      return {
        dates:
        {
          start_date: '2021-05-21',
          end_date: '2021-08-20',
        },
        tableData: [
          // {
          //   site: 'Anglican High School',
          //   month: 'June',
          //   capacity: 0.12,
          //   progressType: 'gradient-danger',
          // },
          {
            site: 'Block 121',
            month: 'June',
            capacity: 0.11,
            progressType: 'gradient-success',
          },
          {
            site: 'Block 122',
            month: 'June',
            capacity: 0.11,
            progressType: 'gradient-success',
          },
   {
            site: 'Block 133',
            month: 'June',
            capacity: 0.09,
            progressType: 'gradient-success',
          },
          {
            site: 'Block 134',
            month: 'July',
            capacity: 0.09,
            progressType: 'gradient-info',
          },
          {
            site: 'Block 137',
            month: 'July',
            capacity: 0.11,
            progressType: 'gradient-warning',
          },
          {
            site: 'Block 162',
            month: 'June',
            capacity: 0.06,
            progressType: 'gradient-warning',
          },
          {
            site: 'Block 207',
            month: 'July',
            capacity: 0.06,
            progressType: 'gradient-warning',
          },
          // {
          //   site: 'Block 208',
          //   month: 'July',
          //   capacity: 0.06,
          //   progressType: 'gradient-warning',
          // },
        ]
      }
    }

  }
</script>
<style>
</style>
