<template>
  <div class="content">
    <base-header class="pb-6">
      <b-row align-v="center" class="py-4">
        <b-col cols="7" lg="6">
          <h6 class="h2 text-white d-inline-block mb-0">Document Approval Status </h6>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <route-bread-crumb></route-bread-crumb>
          </nav>
        </b-col>
        <b-col lg="6" cols="5" class="text-right">
          <base-button size="sm" type="neutral" @click="$router.back()">Back</base-button>
        </b-col>
      </b-row>
    </base-header>
    <b-container fluid class="mt--6">
      <b-card no-body>
          <b-card-header class="border-0">
              <h3 class="mb-0">Document Approval Status for ({{ title }})</h3>
          </b-card-header>

          <el-table class="table-responsive table-flush"
                    header-row-class-name="thead-light"
                    :data="Approvals">
              <el-table-column label="Approved By"
                              min-width="100px"
                              prop="name"
                              sortable>
                  <template v-slot="{row}">
                     <b-media no-body class="align-items-center">
                          <b-media-body>
                              <span class="font-weight-600 name mb-0 text-sm">Role : {{row.role}}</span>
                          </b-media-body>
                      </b-media>
                      <b-media no-body class="align-items-center">
                          <b-media-body>
                              <span class="font-weight-600 name mb-0 text-sm">Name : {{row.approve_by}}</span>
                          </b-media-body>
                      </b-media>
                  </template>
              </el-table-column>

              <el-table-column label="Status"
                              min-width="50px"
                              prop="name"
                              sortable>
                  <template v-slot="{row}">
                      <b-media no-body class="align-items-center">
                          <b-media-body>
                              <span class="font-weight-600 name mb-0 text-sm">{{row.status}}</span>
                          </b-media-body>
                      </b-media>
                  </template>
              </el-table-column>

              <el-table-column label="Code" min-width="50px">
                  <template v-slot="{row}">
                      <b-media no-body class="align-items-center">

                          <b-media-body>
                              <span class="font-weight-600 name mb-0 text-sm">{{row.code}}</span>
                          </b-media-body>
                      </b-media>
                  </template>
              </el-table-column>

              <el-table-column label="Comment"
                              min-width="180px"
                              prop="status"
                              sortable>
                  <template v-slot="{row}">
                         <b-media no-body class="align-items-center">

                          <b-media-body>
                              <span class="font-weight-600 name mb-0 text-sm">{{row.comment}}</span>
                          </b-media-body>
                      </b-media>
                  </template>
              </el-table-column>

              <el-table-column label="Action" min-width="80px">
                <template v-slot="{row}">
                      <div class="d-flex align-items-center">
                          <div>
                              <base-button size="sm" type="primary"
                              v-if="row.code == 'Code 2' || row.code == 'Code 3'">
                              Attachment</base-button>
                              <base-button size="sm" type="primary" @click="modals.approval_reject = true"
                              v-if="row.code == 'Pending'">
                              Approval / Reject
                              </base-button>
                          </div>
                      </div>
                  </template>
              </el-table-column>

          </el-table>

          <b-card-footer class="py-4 d-flex justify-content-end">
              <base-pagination v-model="currentPage" :per-page="3" :total="6"></base-pagination>
          </b-card-footer>
      </b-card>
    </b-container>

    <modal :show.sync="modals.approval_reject"
                size="lg"
                body-classes="p-0">
      <card type="secondary"
            header-classes="bg-transparent pb-5"
            body-classes="px-lg-5 py-lg-5"
            class="border-0 mb-0">
        <template>
          <div class="text-center text-muted mb-4">
            <h3>Approval / Reject ( PV Layout ) by Project Manager ( Cookie Monster) </h3>
            <h3>Status : IFA Status</h3>
          </div>
          <b-form role="form">
            <base-input label="Code For Status" name="Task Site" v-model="code_type">
              <select class="form-control" v-model="code_type">
                <option value='0' disabled>-- Please Select A Code --</option>
                <option value='1'>Code 1 : Approved</option>
                <option value='2'>Code 2 : Approved With Comment</option>
                <option value='3'>Code 3 : Require Revision With Comment`</option>
                <option value='4'>Code 4 : Reject</option>
              </select>
            </base-input>
            <div v-if="code_type == '2' || code_type == '3'">
              <base-input label="Comment" placeholder="Status Comment" name="Task Template"
               v-model="comment">
              </base-input>
              <label>
                <input type="file" id="file" ref="file" v-on:change="handleFileUpload()"/>
              </label>
            </div>

            <h5 class="text-danger"> Note : <br>
              <i>
              1. Only Code 2 & 3 allow to comment & Attachment on the status.
              <br>
              2. You can attach 1 document with maximum size of 500MB.
              </i>
            </h5>
            <div class="text-center">
              <base-button type="primary" class="my-4" @click="notifyVueFormTask()">Update / Save</base-button>
              <base-button type="primary" class="my-4" @click="modals.approval_reject = false">Cancel</base-button>
            </div>
          </b-form>
        </template>
      </card>
    </modal>

  </div>
</template>


<script>
  import Approvals from './Approval'
  import { Table, TableColumn, Select, Option } from 'element-ui';
  import RouteBreadCrumb from '@/components/Breadcrumb/RouteBreadcrumb';
  import BaseHeader from '@/components/BaseHeader';
  import {LoginURL, clientId, clientSecret, apiDomain, getHeader, getcurrentDate} from '@/assets/js/config.js'
  import axios from 'axios'
  import { Modal } from '@/components';

  export default {
    components: {
      BaseHeader,
      [Select.name]: Select,
      [Option.name]: Option,
      [Table.name]: Table,
      [TableColumn.name]: TableColumn,
      RouteBreadCrumb,
      Modal,
    },
    created()
    {
      this.title = this.$route.params.id
    },
    data() {
      return {
        Approvals,
        code_type: 0,
        comment: null,
        currentPage: 1,
        title: null,
        modals: {
          approval_reject: false,
      },
      };
    }
  };
</script>
<style></style>
