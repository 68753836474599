<template>
  <card>
    <!-- Card header -->
    <h3 slot="header" class="mb-0">Document Details Information for ({{ model.document_classification_code }} - Version : {{ model.detail.document_version}})</h3>
    <div class="text-right">
       <base-button size="sm" type="primary">Download Document</base-button>
      <router-link :to="'/documentEdit/' + model.document_id">
        <base-button size="sm" type="primary">Edit</base-button>
      </router-link>
      <!-- &nbsp;
      <router-link to="/siteNew">
        <base-button size="sm" type="neutral">Create New Site</base-button>
      </router-link> -->
    </div>
    <form class="needs-validation"
          @submit.prevent="submit">

      <div class="form-row">
        <b-col md="4">
          <base-input label="Document Classification Code"
                      name="Document Classification Code"
                      placeholder="Document Classification Code"
                      v-model="model.document_classification_code" disabled>
          </base-input>
        </b-col>

        <b-col md="8">
          <base-input label="Document Information"
                      name="Document Information"
                      placeholder="Document Information"
                      v-model="model.document_information" disabled>
          </base-input>
        </b-col>
      </div>

      <!-- <div class="form-row">
        <b-col md="4">
          <base-input label="Project"
                      name="Project"
                      v-model="model.project_code" disabled>
          </base-input>
        </b-col>

        <b-col md="4">
          <base-input label="Group"
                      name="Group"
                      v-model="model.group_code" disabled>
          </base-input>
        </b-col>

        <b-col md="4">
          <base-input label="Site"
                      name="Site"
                      v-model="model.site_code" disabled>
          </base-input>
        </b-col>
      </div> -->

      <div class="form-row">
        <b-col md="3">
          <base-input label="Document Type"
                      name="Document Type"
                      v-model="model.document_type_code" disabled>
          </base-input>
        </b-col>

        <b-col md="3">
          <base-input label="Document Current Milestone"
                      name="Document Current Milestone"
                      v-model="model.milestone_code" disabled>
          </base-input>
        </b-col>

        <b-col md="3">
          <base-input label="Document Status"
                      name="Document Status"
                      v-model="model.status_code" disabled>
          </base-input>
        </b-col>

        <b-col md="3">
          <base-input label="Document Mandatory"
                      name="Document Mandatory"
                      v-model="model.document_mandatory" disabled>
          </base-input>
        </b-col>
      </div>

      <div class="form-row">
        <b-col md="3">
          <base-input label="Document Version"
                      name="Document Version"
                      v-model="model.detail.document_version" disabled>
          </base-input>
        </b-col>

        <b-col md="3">
          <base-input label="No of Comment in Version"
                      name="No of Comment in Version"
                      v-model="model.detail.document_comment_no" disabled>
          </base-input>
        </b-col>

        <b-col md="6">
          <base-input label="Version Information"
                      name="Version Information"
                      v-model="model.detail.document_information" disabled>
          </base-input>
        </b-col>

      </div>

      <!-- <base-button type="primary" native-type="submit" @click="notifyVueNew('default')" >Update Milestone Template</base-button>
      <base-button type="primary" @click="$router.go(-1)">Cancel</base-button> -->
    </form>
  </card>
</template>
<script>
  import BaseSlider from '@/components/BaseSlider'
  import {LoginURL, clientId, clientSecret, apiDomain, getHeader} from '@/assets/js/config.js'
  import axios from 'axios'

  export default {
    components: {
      BaseSlider
    },
    created()
    {
        this.model.document_detail_id = this.$route.params.id

        axios.get(apiDomain + 'document/document_detail/'+this.model.document_detail_id, { headers: getHeader() })
          .then ( response => {
            if (response.status === 200)
            {
                console.log(response.data.data[0])
                this.model.document_id = response.data.data[0].document.document_id
                this.model.document_classification_code = response.data.data[0].document.document_classification_code
                this.model.document_information = response.data.data[0].document.document_information
                // this.model.project_code = response.data.data[0].document.project.project_code
                // this.model.group_code = response.data.data[0].document.group.group_code
                // this.model.site_code = response.data.data[0].document.site.site_code
                this.model.document_type_code = response.data.data[0].document.document_type.document_type_code
                this.model.milestone_code = response.data.data[0].document.milestone.milestone_code
                this.model.status_code = response.data.data[0].document.status.status_code
                this.model.document_mandatory = response.data.data[0].document.document_mandatory
                this.model.detail.document_version = response.data.data[0].document_version
                this.model.detail.document_comment_no = response.data.data[0].document_comment_no
                this.model.detail.document_information = response.data.data[0].document_information
            }
          })
          .catch ( error => {
              console.log(error.response.status)
              if ( error.response.status == 401)
              {
                  this.$router.push('/login')
              }
          } );

      axios.get(apiDomain + 'template/milestone_template', { headers: getHeader() })
      .then ( response => {
        if (response.status === 200)
        {
          this.milestone_template = response.data.data
        }
      })
      .catch ( error => {
        console.log(error.response.status)
        if ( error.response.status == 401)
        {
          this.$router.push('/login')
        }
      } );

    },
    data() {
      return {
        model: {
          document_id: null,
          document_detail_id: null,
          document_classification_code: null,
          document_information: null,
          project_code: null,
          group_code: null,
          site_code: null,
          document_type_code: null,
          milestone_code: null,
          status_code: null,
          document_mandatory: null,
          detail: {
            document_version: 0,
            document_comment_no: 0,
            document_information: null,
          },
        },
        milestone_template: [],
      }
    },
    methods: {
      async submit() {

      },

    }
  }
</script>
<style>
</style>
