<template>
  <card>
    <!-- Card header -->
    <h3 slot="header" class="mb-0">New Document for ({{ model.project_name }} - {{ model.group_name }})</h3>
    <div class="text-right">
    </div>
    <form class="needs-validation"
          @submit.prevent="submit">

      <div v-if="projects.length == 0" class="form-row">
        <b-col md="12">
        <h5 class="text-danger"> !!! Warning :
            <i>
            Please Create
            <router-link to="/ProjectNew">
                New Project
            </router-link>
            at Menu >> Project-Group-Site >> Project. System required at least ONE Project & One Group & One Site before you can proceed to create New Task.
            </i>
        </h5>
        </b-col>
      </div>

      <div v-if="project_milestones.length == 0 && model.project_id != 'x'" class="form-row">
        <b-col md="12">
        <h5 class="text-danger"> !!! Warning :
            <i>
            Please Create
            <router-link :to="`/projectMilestoneListing/${model.project_id}`">
                New Project Milestone
            </router-link>
            at Menu >> Project-Group-Site >> Project >> Project Dashboard. System required at least ONE Milestone before you can proceed to create New Task.
            </i>
        </h5>
        </b-col>
      </div>

      <div v-if="project_groups.length == 0 && model.project_id != 'x'" class="form-row">
        <b-col md="12">
        <h5 class="text-danger"> !!! Warning :
            <i>
            Please Create
            <router-link :to="`/projectGroupNewFrProject/${model.project_id}`">
                New Group
            </router-link>
            at Menu >> Project-Group-Site >> Project Group. System required at least ONE Project & One Group & One Site before you can proceed to create New Task.
            </i>
        </h5>
        </b-col>
      </div>

      <div v-if="project_sites.length == 0 && (model.group_id != 'x' && model.group_id != 'y')" class="form-row">
        <b-col md="12">
        <h5 class="text-danger"> !!! Warning :
            <i>
            Please Create
            <router-link :to="`/projectSiteNewFrGroup/${model.group_id}`">
                New Site
            </router-link>
            at Menu >> Project-Group-Site >> Project Site. System required at least ONE Project & One Group & One Site before you can proceed to create New Task.
            </i>
        </h5>
        </b-col>
      </div>

      <div class="form-row">
        <b-col md="12">
          <base-input label="Document Information"
                      name="Document Information"
                      placeholder="Document Information"
                      v-model="model.document_information">
          </base-input>
        </b-col>
      </div>

      <div class="form-row">
        <b-col md="5">
          <base-input label="Project"
                      name="Project"
                      v-model="model.project_id">
            <select class="form-control" v-model="model.project_id" disabled>
              <option Selected disabled value="x">-- Please Select A Project --</option>
              <option v-for="item in projects" :key="item.project_id" v-bind:value="item.project_id">{{ item.project_name }}</option>
            </select>
          </base-input>
        </b-col>

        <b-col md="1">
          <br>
          <base-button size="sm" type="primary" @click="checkProject()">Project Infomation</base-button>
        </b-col>

        <b-col md="5">
          <base-input label="Group"
                      name="Group"
                      v-model="model.group_id">
            <select class="form-control" v-model="model.group_id" disabled>
              <option Selected disabled value="x">-- Please Select A Project To Show Group --</option>
              <option Selected disabled value="y">-- Please Select A Group --</option>
              <option v-for="item in project_groups" :key="item.group_id" v-bind:value="item.group_id">{{ item.group_name }}</option>
            </select>
          </base-input>
        </b-col>

        <b-col md="1">
          <br>
          <base-button size="sm" type="primary" @click="checkGroup()">Group Infomation</base-button>
        </b-col>
      </div>

      <div class="form-row">
        <b-col md="5">
          <base-input label="Site"
                      name="Site"
                      v-model="model.site_id">
            <select class="form-control" v-model="model.site_id">
              <option Selected disabled value="x">-- Please Select A Group To Show Site --</option>
              <option Selected disabled value="y">-- Please Select A Site --</option>
              <option v-for="item in project_sites" :key="item.site_id" v-bind:value="item.site_id">{{ item.site_name }}</option>
            </select>
          </base-input>
        </b-col>

        <b-col md="1">
          <br>
          <base-button size="sm" type="primary" @click="checkSite()">Site Infomation</base-button>
        </b-col>

        <b-col md="6">
          <base-input label="Document Category"
                      name="Document Category"
                      placeholder="Document Category"
                      v-model="model.document_category_id">
            <select class="form-control" v-model="model.document_category_id">
              <option Selected disabled value="x">-- Please Select A Document Category --</option>
              <option v-for="item in document_category" :key="item.document_category_id" v-bind:value="item.document_category_id">{{ item.document_category }}</option>
            </select>
          </base-input>
        </b-col>
      </div>

      <div class="form-row">
         <b-col md="6">
          <base-input label="Document Current Milestone"
                      name="Document Current Milestone"
                      v-model="model.milestone_id">
            <select class="form-control" v-model="model.milestone_id">
              <option Selected disabled value="x">-- Please Select A Project To Show Milestone --</option>
              <option Selected disabled value="y">-- Please Select A Milestone --</option>
              <option v-for="item in project_milestones" :key="item.milestone_id" v-bind:value="item.milestone_id">{{ item.milestone_code }} - {{ item.milestone_info }}</option>
            </select>
          </base-input>
        </b-col>

        <b-col md="6">
          <base-input label="Document Type"
                      name="Document Type"
                      v-model="model.document_type_id">
            <select class="form-control" v-model="model.document_type_id">
              <option Selected disabled value="x">-- Please Select A Document Type --</option>
              <option v-for="item in document_types" :key="item.document_type_id" v-bind:value="item.document_type_id">{{ item.document_type_code }}</option>
            </select>
          </base-input>
        </b-col>
      </div>

      <div v-if="model.document_type_id == '2'" class="form-row">
        <b-col md="4">
          <base-input label="Recurring Interval"
                      name="Recurring Interval">
            <select class="form-control" >
              <option Selected >-- Please Select A Recurring Interval --</option>
              <option >Daily</option>
              <option >Weekly</option>
              <option >Month</option>
            </select>
          </base-input>
        </b-col>

        <b-col md="4">
          <base-input label="Start Date"
                      name="Start Date"
                      type="date">
          </base-input>
        </b-col>

        <b-col md="4">
          <base-input label="End Date"
                      name="End Date"
                      type="date">
          </base-input>
        </b-col>
      </div>

      <div class="form-row">
        <b-col md="4">
          <b-form-checkbox
            id="Mandatory"
            v-model="model.document_mandatory"
            name="Mandatory"
            value="1"
            unchecked-value="0">
            <b>Document Mandatory</b>
          </b-form-checkbox>
        </b-col>
        <b-col md="4">
          <b-form-checkbox
            id="Active-Status"
            v-model="model.active_status"
            name="Active-Status"
            value="1"
            unchecked-value="0">
            <b>Active Status</b>
          </b-form-checkbox>
        </b-col>
      </div>
      <hr>
      <div class="form-row">
         <b-col md="12">
            <b>Require Approval From</b>
        </b-col>
      </div>
      <br>
      <div class="form-row">
        <b-col md="1.5">
          <b-form-checkbox
            id="req_approval_project_owner"
            v-model="model.req_approval_project_owner"
            name="req_approval_project_owner"
            value="1"
            unchecked-value="0">
            <b>Project Owner</b>
          </b-form-checkbox>
        </b-col>

        <b-col md="1.5">
          <b-form-checkbox
            id="req_approval_project_manager"
            v-model="model.req_approval_project_manager"
            name="req_approval_project_manager"
            value="1"
            unchecked-value="0">
            <b>Project Manager</b>
          </b-form-checkbox>
        </b-col>

        <b-col md="1.5">
          <b-form-checkbox
            id="req_approval_project_engineer"
            v-model="model.req_approval_project_engineer"
            name="req_approval_project_engineer"
            value="1"
            unchecked-value="0">
            <b>Project Engineer</b>
          </b-form-checkbox>
        </b-col>

        <b-col md="1.5">
          <b-form-checkbox
            id="req_approval_engineer"
            v-model="model.req_approval_engineer"
            name="req_approval_engineer"
            value="1"
            unchecked-value="0">
            <b>Engineer</b>
          </b-form-checkbox>
        </b-col>

        <b-col md="1.5">
          <b-form-checkbox
            id="req_approval_qa_qc"
            v-model="model.req_approval_qa_qc"
            name="req_approval_qa_qc"
            value="1"
            unchecked-value="0">
          <b>QA / QC</b>
          </b-form-checkbox>
        </b-col>

        <b-col md="1.5">
          <b-form-checkbox
            id="req_approval_safety"
            v-model="model.req_approval_safety"
            name="req_approval_safety"
            value="1"
            unchecked-value="0">
          <b>Safety</b>
          </b-form-checkbox>
        </b-col>

        <b-col md="1.5">
          <b-form-checkbox
            id="req_approval_planner"
            v-model="model.req_approval_planner"
            name="req_approval_planner"
            value="1"
            unchecked-value="0">
          <b>Planner</b>
          </b-form-checkbox>
        </b-col>

        <b-col md="1.5">
          <b-form-checkbox
            id="req_approval_purchasing"
            v-model="model.req_approval_purchasing"
            name="req_approval_purchasing"
            value="1"
            unchecked-value="0">
          <b>Purchasing</b>
          </b-form-checkbox>
        </b-col>

        <b-col md="1.5">
          <b-form-checkbox
            id="req_approval_admin"
            v-model="model.req_approval_admin"
            name="req_approval_admin"
            value="1"
            unchecked-value="0">
          <b>Admin</b>
          </b-form-checkbox>
        </b-col>
      </div>
      <br><br>

      <base-button type="primary" @click="createData()">Create / Save</base-button>
      <base-button type="primary" @click="$router.back()">Cancel</base-button>
      <div>
        <!-- <br>
        <h5 class="text-danger"> Note : <br>
          <i>
          1. By Default all Date is set to "TODAY.
          </i>
          <br>
          <i>
          2. Task Progress % is set to "0 %" By Default.
          </i>
          <br>
          <i>
          3. Default contractor will be based on contractor assign to Site. Please assign a Contractor to a Site before Create a Task.
          </i>
          <br>
          <i>
          3. Est End Date must be Greater or same as Est Start Date. Same with End Date and Start Date.
          </i>
          <br>
          <i>
          3. All the column / field are mandatory. (Require discussion to determine which columns are Non-Mandatory under what condition).
          </i>
        </h5> -->
      </div>
    </form>
  </card>
</template>
<script>
  import BaseSlider from '@/components/BaseSlider'
  import {LoginURL, clientId, clientSecret, apiDomain, getHeader, getcurrentDate} from '@/assets/js/config.js'
  import axios from 'axios'
  import ErrorFunctionMixin from '@/assets/js/errorFunctionMix.js'

  export default {
    mixins: [ErrorFunctionMixin],
    components: {
      BaseSlider
    },
    created()
    {
        var currentDate = getcurrentDate()

        this.model.group_id = this.$route.params.id
        this.getProject()

        axios.get(apiDomain + 'project/project_group/' + this.model.group_id, { headers: getHeader() })
        .then ( response => {
          if (response.status === 200)
          {
            this.model.project_id = response.data.data[0].project.project_id
            this.model.project_name = response.data.data[0].project.project_name
            this.model.group_name = response.data.data[0].group_name
            this.getGroupFromProject()
            this.getSiteFromGroup()
          }
        })
        .catch ( error => {
          console.log(error.response.status)
          this.errorFunction(error, "Project Group Information")
        } );

        axios.get(apiDomain + 'lookup/document_category', { headers: getHeader() })
        .then ( response => {
          if (response.status === 200)
          {
            this.document_category = response.data.data
          }
        })
        .catch ( error => {
          console.log(error.response.status)
          this.errorFunction(error, "Document Category Information")
        });


        this.getDocumentStatus()
        this.getDocumentType()
    },
    data() {
      return {
        model: {
          document_classification_code: null,
          document_information: null,
          document_mandatory: 1,
          project_id: 'x',
          project_name: null,
          group_id: 'x',
          group_name: null,
          site_id: 'x',
          milestone_id: 'x',
          document_type_id: 'x',
          document_category_id: 'x',
          status_id: 'x',
          active_status: 1,
          req_approval_project_owner: 1,
          req_approval_project_manager: 1,
          req_approval_project_engineer: 1,
          req_approval_engineer: 1,
          req_approval_qa_qc: 1,
          req_approval_safety: 1,
          req_approval_planner: 1,
          req_approval_purchasing: 1,
          req_approval_admin: 1,
        },
        projects: [],
        project_groups: [],
        project_sites: [],
        project_milestones: [],
        document_status: [],
        document_types: [],
        document_category: [],
      }
    },
    methods: {
      getProject()
      {
        axios.get(apiDomain + 'project/project', { headers: getHeader() })
        .then ( response => {
          if (response.status === 200)
          {
            this.projects = response.data.data
          }
        })
        .catch ( error => {
          console.log(error.response.status)
          this.errorFunction(error, "Project Information")
        });
      },
      getDocumentStatus()
      {
        axios.get(apiDomain + 'lookup/document_status', { headers: getHeader() })
        .then ( response => {
          if (response.status === 200)
          {
            this.document_status = response.data.data
          }
        })
        .catch ( error => {
          console.log(error.response.status)
          this.errorFunction(error, "Document Status Information")
        } );
      },
      getGroupFromProject()
      {
        axios.get(apiDomain + 'project/project_group.show_by_project/' + this.model.project_id , { headers: getHeader() })
        .then ( response => {
          if (response.status === 200)
          {
            this.project_groups = response.data.data

          }
        })
        .catch ( error => {
          console.log(error.response.status)
          this.errorFunction(error, "Project Group Information")
        } );

        axios.get(apiDomain + 'project/project_milestone.show_by_project/' + this.model.project_id , { headers: getHeader() })
        .then ( response => {
          if (response.status === 200)
          {
            this.project_milestones = response.data.data
            this.model.milestone_id = 'y'
          }
        })
        .catch ( error => {
          console.log(error.response.status)
          this.errorFunction(error, "Project Milestone Information")
        } );
        this.project_sites = []
        this.model.site_id = 'x'
      },
      getSiteFromGroup()
      {
        axios.get(apiDomain + 'project/project_site.show_by_group/' + this.model.group_id , { headers: getHeader() })
        .then ( response => {
          if (response.status === 200)
          {
            this.project_sites = response.data.data

          }
        })
        .catch ( error => {
          console.log(error.response.status)
          this.errorFunction(error, "Project Site Information")
        } );
      },
      getDocumentType()
      {
        axios.get(apiDomain + 'lookup/document_type' , { headers: getHeader() })
        .then ( response => {
          if (response.status === 200)
          {
            this.document_types = response.data.data
          }
        })
        .catch ( error => {
          console.log(error.response.status)
          this.errorFunction(error, "Document Type Information")
        } );
      },
      checkProject()
      {
        if ( this.model.project_id == 'x' || this.model.project_id == 'y' )
        {
          this.redErrorNotify('<b>Invalid Input : Project </b> - Please select a Project before dive into the Details')
        }
        else
        {
          this.$router.push({path: '/projectEdit/'+this.model.project_id })
        }
      },
      checkGroup()
      {
        if ( this.model.group_id == 'x' || this.model.group_id == 'y' )
        {
          this.redErrorNotify('<b>Invalid Input : Group </b> - Please select a Group before dive into the Details')
        }
        else
        {
          this.$router.push({path: '/projectGroupEdit/'+this.model.group_id })
        }
      },
      checkSite()
      {
        if ( this.model.site_id == 'x' || this.model.site_id == 'y' )
        {
          this.redErrorNotify('<b>Invalid Input : Site </b> - Please select a Site before dive into the Details')
        }
        else
        {
          this.$router.push({path: '/projectSiteEdit/'+this.model.site_id })
        }
      },
      createData()
      {
        if ( !this.model.document_classification_code || !this.model.document_information || this.model.site_id == 'x' ||  this.model.site_id == 'y' || this.model.status_id == 'x'
        || this.model.milestone_id == 'x' || this.model.milestone_id == 'y' || this.model.document_type_id == 'x' )
        {
          this.redErrorNotify('<b>Invalid Input : All Columns </b> - All the columns are temporary set to Mandatory. (Require discussion to determine which columns are Non-Mandatory under what condition).')
        }
        else
        {
          axios.post(apiDomain + 'document/document', this.model, { headers: getHeader() })
          .then ( response => {
            if (response.status === 200)
            {
                this.$notify({
                message:
                  '<b>New Document : ' + this.model.document_classification_code + '</b> - Successfully Created.',
                timeout: 10000,
                icon: 'ni ni-bell-55',
                type: 'default',
                });
                this.$router.back()
            }
          })
          .catch ( error => {
              console.log(error.response.data.errors)
              this.errorFunction(error, "Create New Document Information")
          });
        }
      },
    }
  }
</script>
<style>
</style>
