<template>
  <card>
    <!-- Card header -->
    <h3 slot="header" class="mb-0">Edit Safety Observation Category - {{ model.safety_observation_category }}</h3>
    <div class="text-right">
    </div>
    <form class="needs-validation"
          @submit.prevent="submit">

      <div class="form-row">
        <b-col md="12">
          <base-input label="Safety Observation Category"
                      name="Safety Observation Category"
                      placeholder="Safety Observation Category"
                      v-model="model.safety_observation_category" required>
          </base-input>
        </b-col>
      </div>

      <div class="form-row">
        <b-col md="4">
          <b-form-checkbox
            id="Active-Status"
            v-model="model.active_status"
            name="Active-Status"
            value="1"
            unchecked-value="0">
            <b>Active Status</b>
          </b-form-checkbox>
        </b-col>
      </div>
      <br>

      <base-button type="primary" @click="createData()" v-if="button_access.edit_button == 'YES'">Update Safety Observation Category</base-button>
      <base-button type="primary" @click="$router.back()">Cancel</base-button>
      <!-- <div>
        <br>
        <h5 class="text-danger"> Note : <br><br>
                <i>
                1. By Default all Date is set to "TODAY.
                </i>
                <br>
                <i>
                2. Developer Progress % is set to "0 %" By Default.
                </i>
        </h5>
      </div> -->
    </form>
  </card>
</template>
<script>
  import BaseSlider from '@/components/BaseSlider'
  import {LoginURL, clientId, clientSecret, apiDomain, getHeader, getcurrentDate} from '@/assets/js/config.js'
  import axios from 'axios'
  import ErrorFunctionMixin from '@/assets/js/errorFunctionMix.js'

  export default {
    mixins: [ErrorFunctionMixin],
    components: {
      BaseSlider
    },
    created()
    {
      var currentDate = getcurrentDate()
      var safety_observation_category_access = JSON.parse(window.localStorage.getItem('safety_observation_category_access'))
      this.button_access.edit_button = safety_observation_category_access.access_edit
      this.getData()
    },
    data() {
      return {
        model: {
          safety_observation_category_id: null,
          safety_observation_category: null,
          active_status: 1,
        },
        abc: '100',
         button_access: {
          edit_button: null,
        },
      }
    },
    methods: {
      getData()
      {
        this.model.safety_observation_category_id = this.$route.params.id
        axios.get(apiDomain + 'setup/safety_observation_category/' + this.model.safety_observation_category_id,  { headers: getHeader() })
        .then ( response => {
          console.log(response.data)
          if (response.status === 200)
          {
              this.model.safety_observation_category = response.data.data[0].safety_observation_category
              this.model.active_status = response.data.data[0].active_status
          }
        })
        .catch ( error => {
          console.log(error.response.data.errors)
          this.errorFunction(error, "Create New Safety Observation Category")
        });
      },
      createData(){
        if ( !this.model.safety_observation_category )
        {
          this.$notify({
            message:
              '<b>Invalid Input : All Column are Mandatory in Create New Safety Observation Category </b> - These field are required.',
            timeout: 10000,
            icon: 'ni ni-bulb-61',
            type: 'danger',
          });
        }
        else
        {
          axios.put(apiDomain + 'setup/safety_observation_category/' + this.model.safety_observation_category_id, this.model, { headers: getHeader() })
          .then ( response => {
            if (response.status === 200)
            {
                this.$notify({
                message:
                  '<b>Edit Safety Observation Category : ' + this.model.safety_observation_category + '</b> - Successfully Updated.',
                timeout: 10000,
                icon: 'ni ni-bell-55',
                type: 'default',
                });
                this.$router.back()
            }
          })
          .catch ( error => {
            console.log(error.response.data.errors)
            this.errorFunction(error, "Create New Safety Observation Category")
          });
        }
      },
    }
  }
</script>
<style>
</style>
