<template>
  <section>
    <div class="content">
      <base-header class="pb-6">
        <b-row align-v="center" class="py-4">
          <b-col cols="7" lg="6">
            <h6 class="h2 text-white d-inline-block mb-0">
              Import New Project Site
            </h6>
            <nav
              aria-label="breadcrumb"
              class="d-none d-md-inline-block ml-md-4"
            >
              <route-bread-crumb></route-bread-crumb>
            </nav>
          </b-col>
        </b-row>
      </base-header>
      <b-container fluid class="mt--6">
        <div>
          <card
            class="no-border-card"
            body-classes="px-0 pb-1"
            footer-classes="pb-2"
          >
            <template slot="header">
              <h3 v-if="project_name == null" class="mb-0">
                Import New Project Site (Project)
              </h3>
              <h3 v-else class="mb-0">
                Import New Project Site for ({{ project_name }})
              </h3>
            </template>
            <!-- <div>
              Login User ID: {{ myUserID }}<br />
              File Name with Timestamp: {{ filenameWithTimestamp }} <br />
              Status: {{ postRequestStatus }}<br />
            </div> -->
            <!-- <div>{{ postRequestStatus }}</div> -->
            <div class="px-4">
              <b-row
                ><b-col
                  ><base-button class="mb-2" @click="uploadFile"
                    >Upload File</base-button
                  ></b-col
                >
                <b-col class="text-right">
                  <base-button size="sm" type="primary" @click="$router.back()"
                    >Back</base-button
                  >
                </b-col>
              </b-row>
              <b-row
                ><b-col><input type="file" @change="onChange" /> </b-col
              ></b-row>
              <b-row
                ><b-col
                  ><h5 class="text-danger" v-if="postResponse.length > 0">
                    Flags :<br />
                    <i
                      >&nbsp&nbsp&nbsp&nbsp1: town council does not exist<br />
                      &nbsp&nbsp&nbsp&nbsp2: contractor does not exist<br />
                      &nbsp&nbsp&nbsp&nbsp3: milestone template does not
                      exist<br />
                      &nbsp&nbsp&nbsp&nbsp4: postal code already exists<br
                    /></i>
                    Number of rows with error: {{ postResponse.length }}
                  </h5>
                  <b-table
                    sticky-header="540px"
                    striped
                    hover
                    :items="postResponse"
                  ></b-table>
                  <xlsx-read ref="excelRead" :file="file">
                    <xlsx-json ref="excelJson" :sheet="selectedSheet">
                    </xlsx-json> </xlsx-read></b-col
              ></b-row>
            </div>
            <!-- <div>{{ postResponse.length }}</div>
            <div>{{ typeof postResponse[0] }}</div>
            <div>{{ postResponse }}</div> -->
          </card>
        </div></b-container
      >
    </div>
  </section>
</template>
<script>
import XlsxRead from "../../../../node_modules/vue-xlsx/dist/components/XlsxRead";
import XlsxTable from "../../../../node_modules/vue-xlsx/dist/components/XlsxTable";
import XlsxJson from "../../../../node_modules/vue-xlsx/dist/components/XlsxJson";
import {
  LoginURL,
  clientId,
  clientSecret,
  apiDomain,
  getHeader,
  getcurrentDate,
} from "@/assets/js/config.js";
import axios from "axios";

export default {
  components: {
    XlsxRead,
    XlsxTable,
    XlsxJson,
  },
  created() {
    this.project_id = this.$route.params.id;
    this.getProjectName();

    const LoginUser = JSON.parse(window.localStorage.getItem("loginUser"));
    this.myUserID = LoginUser;
  },
  data() {
    return {
      file: null,
      filenameWithTimestamp: null,
      tempData: [{ "EPC START DATE": 0, "TARGET TURN ON DATE": 0 }],
      tempCurrentDate: null,
      // postRequestStatus: "not posted yet",
      postRequestStatus: "",
      model: {
        epc_start_date: 0,
        target_turn_on_date: 0,
      },
      project_id: null,
      project_name: null,
      myUserID: null,
      filenameWithTimestamp: null,
      postResponse: [],
    };
  },
  methods: {
    getProjectName() {
      axios
        .get(apiDomain + "project/project/" + this.project_id, {
          headers: getHeader(),
        })
        .then((response) => {
          if (response.status === 200) {
            this.project_name = response.data.data[0].project_name;
          }
        })
        .catch((error) => {
          console.log(error.response.status);
        });
    },
    onChange(event) {
      this.file = event.target.files ? event.target.files[0] : null;
    },
    updateTempData() {
      this.tempData = this.$refs.excelJson.$data.collection;
    },
    convertExcelDateTime() {
      this.tempData.forEach((e) => {
        var tempExcelDateEPC = e["EPC START DATE"];
        var tempConvertedDateEPC = this.ExcelDateToJSDate(tempExcelDateEPC);
        var convertedDateOnlyEPC = this.convertToDateOnly(tempConvertedDateEPC);
        e["EPC START DATE"] = convertedDateOnlyEPC;
        var tempExcelDateTARGET = e["TARGET TURN ON DATE"];
        var tempConvertedDateTARGET =
          this.ExcelDateToJSDate(tempExcelDateTARGET);
        var convertedDateOnlyTARGET = this.convertToDateOnly(
          tempConvertedDateTARGET
        );
        e["TARGET TURN ON DATE"] = convertedDateOnlyTARGET;
        e["created_by"] = this.myUserID.id;
      });
    },
    convertToDateOnly(inputDateTime) {
      const current = inputDateTime;
      const currentMonth = String(current.getMonth() + 1).padStart(2, "0");
      const currentDay = String(current.getDate()).padStart(2, "0");
      const currentDate =
        current.getFullYear() + "-" + currentMonth + "-" + currentDay;
      return currentDate;
    },
    ExcelDateToJSDate(serial) {
      var utc_days = Math.floor(serial - 25569);
      var utc_value = utc_days * 86400;
      var date_info = new Date(utc_value * 1000);

      var fractional_day = serial - Math.floor(serial) + 0.0000001;

      var total_seconds = Math.floor(86400 * fractional_day);

      var seconds = total_seconds % 60;

      total_seconds -= seconds;

      var hours = Math.floor(total_seconds / (60 * 60));
      var minutes = Math.floor(total_seconds / 60) % 60;

      return new Date(
        date_info.getFullYear(),
        date_info.getMonth(),
        date_info.getDate(),
        hours,
        minutes,
        seconds
      );
    },
    uploadFile() {
      this.project_id = this.$route.params.id;
      this.updateTempData();
      this.convertExcelDateTime();
      this.filenameWithTimestamp =
        Math.round(+new Date() / 1000).toString() + "_" + this.file.name;
      this.tempData.forEach((e) => {
        e["timestamped_filename"] = this.filenameWithTimestamp;
      });

      axios
        .post(
          apiDomain + "project/site_import_from_upload/" + this.project_id,
          this.tempData,
          {
            headers: getHeader(),
          }
        )
        .then((response) => {
          if (response.status === 200) {
            // this.postRequestStatus = "post request succeeded, site import has succeeded if table does not show up below";
            this.postResponse = response.data.data;
            this.$notify({
              message:
                "</b>Post Request Succeded<br/> Site Import Succeded If Table Does Not Show Up Below 'Choose File'",
              timeout: 10000,
              icon: "ni ni-bell-55",
              type: "default",
            });

            // if (this.postResponse.length === 0) {
            // if (typeof postResponse[0] == "object") {
            // if (!postResponse.length) {
            //   this.$notify({
            //     message: "</b>Successfully Imported New Sites",
            //     timeout: 10000,
            //     icon: "ni ni-bell-55",
            //     type: "default",
            //   });
            // } else {
            //   this.$notify({
            //     message: "</b>Import New Site Failed",
            //     timeout: 10000,
            //     icon: "ni ni-bulb-61",
            //     type: "danger",
            //   });
            // }

            // this.postRequestStatus = "post request succeeded";
            // this.postResponse = response.data.data;
          }
        })
        .catch((error) => {
          this.$notify({
            message: "</b>Import New Site Failed",
            timeout: 10000,
            icon: "ni ni-bulb-61",
            type: "danger",
          });
          // this.postRequestStatus = "post request failed";
          console.log(error.response.data.errors);
          this.errorFunction(error);
        });
    },
  },
};
</script>

