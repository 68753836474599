<template>
  <div>
    <base-header class="pb-6">
      <b-row class="py-4">
        <b-col cols="6">
          <h6 class="h2 text-white d-inline-block mb-0">{{ title }}</h6>
        </b-col>
        <b-col cols="6" class="text-right">
          <base-button size="sm" type="neutral" @click="$router.replace('../Listing')">Back</base-button>
        </b-col>
      </b-row>
    </base-header>

    <b-container fluid class="mt--6">
      <div class="card-wrapper">
        <card>
          <!-- Card header -->
          <h3 slot="header" class="mb-0">Edit {{ $route.query.name }}</h3>
          <validation-observer v-slot="{ handleSubmit }">
            <form class="needs-validation" @submit.prevent="handleSubmit(onSubmit)">

              <b-form-row>
                <b-col>
                  <validation-provider name="Code" rules="required" v-slot="{ classes, errors }">
                    <b-form-group label="Code" :invalid-feedback="errors[0]">
                      <input type="text" class="form-control" :class="classes" v-model="model.code" maxlength="20">
                    </b-form-group>
                  </validation-provider>
                </b-col>
              </b-form-row>

              <b-form-row>
                <b-col>
                  <validation-provider name="Name" rules="required" v-slot="{ classes, errors }">
                    <b-form-group label="Name" :invalid-feedback="errors[0]">
                      <input type="text" class="form-control" :class="classes" v-model="model.name" maxlength="100">
                    </b-form-group>
                  </validation-provider>
                </b-col>
              </b-form-row>

              <div class="d-flex align-items-baseline justify-content-between">
                <div>
                  <button class="btn btn-primary" v-if="access.edit">Update</button>
                  <button class="btn btn-primary" type="button" @click="$router.replace('../Listing')">Cancel</button>
                </div>
                <button class="btn btn-danger" type="button" v-if="access.delete" @click="showConfirmDelete = true">Delete</button>
              </div>
            </form>
          </validation-observer>
        </card>
      </div>
    </b-container>

    <modal :show.sync="showConfirmDelete" size="sm" body-classes="p-0">
      <card type="secondary"
            header-classes="bg-transparent pb-5"
            body-classes="px-lg-5 py-lg-5"
            class="border-0 mb-0">
        <div class="text-center text-muted mb-4">
          <h3>Delete this Category?</h3>
        </div>
        <div class="text-center">
          <button class="btn btn-danger" @click="deleteRecord">YES</button>
          <button class="btn btn-dark" @click="showConfirmDelete = false">NO</button>
        </div>
      </card>
    </modal>
  </div>
</template>

<script>
import ErrorFunctionMixin from '@/assets/js/errorFunctionMix.js'
import axios from '@/util/axios'
import { getAccessByModule } from '@/util/access'

export default {
  mixins: [ErrorFunctionMixin],

  data() {
    return {
      title: 'Document Tracking Templates',
      model: {},
      access: {},
      showConfirmDelete: false,
    }
  },

  created() {
    this.tid = this.$route.params.tid
    this.id = this.$route.params.id
    this.url = `setup/doc_repo/template/${this.tid}/category/${this.id}`
    this.access = getAccessByModule('DOC_REPO')

    this.loadRecord()
  },

  methods: {
    loadRecord() {
      axios.get(this.url)
        .then(response => {
          this.model = response.data.data
        })
        .catch(error => {
          this.errorFunction(error, this.title)
        });
    },

    onSubmit() {
      axios.put(this.url, this.model)
        .then(response => {
          this.$notify({
            message: 'Category Updated',
            timeout: 10000,
            icon: 'ni ni-bell-55',
            type: 'default',
          });
          this.$router.replace('../Listing')
        })
        .catch(error => {
          this.errorFunction(error, this.title)
        });
    },

    deleteRecord() {
      axios.delete(this.url)
        .then(response => {
          this.$notify({
            message: 'Category Deleted',
            timeout: 10000,
            icon: 'ni ni-bell-55',
            type: 'default',
          });
          this.$router.replace('../Listing')
        })
        .catch(error => {
          this.errorFunction(error, this.title)
        })
        .then(() => {
          this.showConfirmDelete = false
        })
    },
  },
}
</script>
