<template>
  <div class="bg-default-dashboard" >
    <div class=" py-lg-8 pt-lg-5">
      <b-container fluid style="width: 90%; position: relative;">
        <!-- <div class="header-body "  > -->
        <b-row class="mb-2">
          <b-col>
            <h1 class="text-white">Calendar Daily Progress</h1>
          </b-col>
        </b-row>

        <!-- Calendar -->
        <b-row class="mt-0">
          <b-col md="12">

            <div>
              <v-calendar class="custom-calendar max-w-full" :masks="masks" :attributes="attributes" disable-page-swipe
                is-expanded :is-dark="false">
                <template v-slot:day-content="{ day, attributes }">
                  <div class="d-flex flex-column h-100 position-relative overflow-hidden">
                    <span style="padding-left: 5px; font-weight: bold; background-color: #a5c4f1" class="display-5 text-dark" @click="navigateShowDailyProgress(day.date)">{{ day.day }}</span>
                    <div style="display: flex; overflow: auto;">
                      <!-- <div v-for="attr in attributes" class="h2 rounded-sm p-1 my-1"> -->
                      <div v-for="attr in attributes" class="h2 rounded-sm" style="padding-left: 2px; padding-right: 2px;">
                        <img :src="attr.customData" alt="" style="height:30px; width:30px"
                          @click="navigateShowDailyProgress(day.date, attr.key)" />
                      </div>
                    </div>
                  </div>
                </template>
              </v-calendar>

              <!-- modal daily progress -->
              <b-modal ref="mdlDisplay" size="lg" centered hide-footer title="Daily Progress" style="color: white !important;">
                <div>
                  <!-- /** compare the date user clicked with the date in the attributes list, if it matches then display the list of images*/ -->
                  <!-- :bullets="true"  -->
                  <vueper-slides :touchable="false" fade :autoplay="true" :duration="2000" fixed-height="400px"
                    v-if="oSlides.selected.length > 0">
                    <vueper-slide v-for="(entry, index) in oSlides.selected[0].images" :key="index" :image="entry">
                      <div slot="content">
                        <button v-on:click="showLargeImage(entry)">{{oSlides.selected[0].remark}}</button>
                      </div>     
                    </vueper-slide>
                  </vueper-slides>
                </div>
                <div container class="button-container">
                  <b-button @click="newDailyProgress()">Add New Daily Progress</b-button>
                  <b-button @click="editDailyProgress(oSlides.selected[0].key)" v-if="oSlides.selected.length > 0">Edit Current Daily Progress</b-button>        
                </div>
              </b-modal>

              <!-- modal content add / edit daily progress -->
              <b-modal ref="mdlContentDetail" centered hide-footer title="Daily Progress Entry" style="color: white !important;">
                <div class="d-block text-center">
                  <div class="w-full max-w-sm">
                    <!-- <validation-observer v-slot="{ handleSubmit }"> -->
                    <!-- <validation-observer> -->
                      <!-- <form class="needs-validation pl-4 pr-4" @submit.prevent="handleSubmit(createData)"> -->
                      <!-- <form class="needs-validation" @submit.prevent="submit"></form> -->
                      <form class="needs-validation pl-4 pr-4" @submit.prevent="submit">
                        <!-- Remark Input -->
                        <b-form-row>
                          <b-col>
                            <b-form-group label="Add Remark" class="text-white">
                              <span style="color: red; font-weight: bold;" v-if="oDailyContent.isErrDispRemark">Remark is mandatory</span>
                              <textarea id="remark" class="form-control mb-4" v-model="model.remarks" rows="3" placeholder="Enter your remarks here..."></textarea>
                            </b-form-group>
                          </b-col>
                        </b-form-row>

                        <!-- File Upload Section -->
                        <b-form-row>
                          <b-col>
                            <b-form-group label="Upload Files Here" class="text-white">
                              <div class="border border-dark p-3" style="background-color: #2c545a; color: white; border-radius:10px">
                                <div class="text-center text-black mt-3 mb-2">
                                  <!-- this one for image(s) that newly added from the UI, basically this one add new entry -->
                                  <div v-for="(file, fileindex) of model.file_attachment" class="alert border-bottom mb-2 p-2 d-flex align-items-center">
                                    <div class="flex-fill">
                                      <div class="fw-semibold">{{ file.uploaded_file.name }}</div>
                                    </div>
                                    <button class="btn btn-warning btn-sm" @click="deleteAttachment('new', fileindex)">&times;</button>
                                    <br>
                                  </div>

                                  <!-- this one for image(s) that already stored in the db, and this basically editing saved entry -->
                                  <!-- <div v-for="(itemAttachment, indexAttachment) of model.attachments" :ref="setUploadedImageWithID(oSlides.selected, indexAttachment)" class="alert border-bottom mb-2 p-2 d-flex align-items-center"> -->
                                  <!-- <div v-for="(itemAttachment, indexAttachment) of model.attachments" class="alert border-bottom mb-2 p-2 d-flex align-items-center"> -->
                                  <div v-for="(itemAttachment, indexAttachment) of model.attachments" class="alert border-bottom mb-2 p-2 d-flex align-items-center">
                                    <div class="flex-fill">
                                        <a href="#" @click.prevent="downloadFile(itemAttachment.id)">{{ itemAttachment.filename }}</a>
                                    </div>
                                    <button class="btn btn-warning btn-sm" @click="deleteAttachment(itemAttachment.id, indexAttachment)">&times;</button>
                                  </div>
                                  <div style="display: flex; justify-content: space-evenly; flex-wrap: nowrap;">
                                    <img src="/img/theme/general/upload.png" alt="">
                                    <div class="mt-2">
                                      <input v-show="false" id="files-upload" type="file" ref="file" style="display:none" accept="image/*" @change="fileChange($event)" />
                                      <base-button size="md" type="primary" @click.prevent="uploadAttachment('files-upload')">Add Attachment</base-button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </b-form-group>
                          </b-col>
                        </b-form-row>

                        <!-- Buttons for navigation and submission -->
                        <div class="d-flex justify-content-center mt-3">
                          <b-button @click="redirectToModal('mdlDisplay')" class="me-2">Back</b-button>
                          <base-button type="primary" @click="createData()">Submit</base-button>
                        </div>
                      </form>
                    <!-- </validation-observer> -->
                  </div>
                </div>
              </b-modal>

              <!-- Modal view large image -->
              <modal :show.sync="oSlides.isLargeImageVisible" size="xl" body-classes="p-0" title="Image">
                <card type="secondary" header-classes="bg-transparent pb-5" body-classes="px-lg-5 py-lg-5" class="border-0 mb-0">
                  <!-- style="max-width:250px;max-height:250px;" -->
                  <div class="text-center">
                    <img :src="oSlides.largeImageSrc" style="max-width: 100%" />
                  </div>
                </card>
              </modal>

              <!-- Modal loading / in progress -->
              <modal :show.sync="oModal.isLoadingInProgress" ref="modal-loading" size="lg" modal-classes="in-progress-modal-lg" :no-close-on-backdrop="true" body-classes="p-0" >
                <card type="secondary" header-classes="bg-transparent pb-5" body-classes="px-lg-5 py-lg-5" class="border-0 mb-0">
                  <template>
                    <div class="text-center text-muted mb-4">
                      <h1 class="text-white"><b>{{oModal.msgLoadingInProgress}}</b></h1>
                    </div>
                    <div class="text-center">
                      <div style="color: #ffffff;">
                        <!-- <span class="fa fa-spinner fa-spin fa-3x"></span> -->
                        <span class="icon-spinner-cs"></span>
                      </div>
                    </div>
                  </template>
                </card>
              </modal>    

            </div>

          </b-col>
        </b-row>
  
      </b-container>
    </div>
  </div>      

</template>

<script>
import { Table, TableColumn, Select, Option } from "element-ui";
import { LoginURL, clientId, clientSecret, apiDomain, getHeader, getcurrentDate } from "@/assets/js/config.js";

import ErrorFunctionMixin from '@/assets/js/errorFunctionMix.js';
import { VueperSlides, VueperSlide } from 'vueperslides';
import 'vueperslides/dist/vueperslides.css';
import axios from '@/util/axios';
import jsonFormData from 'json-form-data';

export default {
  name: "Detail",
  mixins: [ErrorFunctionMixin],
  components: {
    // DemoModal,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    VueperSlides,
    VueperSlide,
  },
  computed: {
  },
  data() {
    return {
      oModal: {
        isLoadingInProgress: false,
        msgLoadingInProgress: 'Loading in progress',
      },
      oSlides: {
        selected: [],
        largeImageSrc: null,
        isLargeImageVisible: false,
      },
      oDailyContent: {
        isErrDispRemark: false,
      },
      model: {
        remarks: null,
        file_attachment: [],
        attachments:[],
      },
      documents: [],
      attributes: [],
      date: getcurrentDate(),
      masks: {
        weekdays: 'WWW',
      },
    };
  },
  mounted() {
    this.getDailyProgressListing();
  },
  created() {

  },
  methods: {
    async submit() {

    },
    setUploadedImageWithID(selected_daily, indexAttachment) {
      let id_daily = selected_daily[0].key;
      return 'uploadedImage_' + id_daily + '_' + indexAttachment;
    },
    navigateShowDailyProgress(day, selected_id = '0') {
      // console.log('navigateShowDailyProgress')

      this.oSlides.selected = [];   //_ reset it to empty, so nothing to display on the modal if there is no entry selected AND no image
      if (selected_id != '0') {
        //_ repopulate the selected slides 
        this.oSlides.selected = this.attributes.filter(function (selected) {
          return selected.key === selected_id
        });
        // console.log(filtered)
        // this.oSlides.selected = this.attributes
      } else {
        //_ reset fields for new entry
        this.resetContentFields();
      }

      // console.log('this.oSlides.selected')
      // console.log(this.oSlides.selected)

      this.$refs['mdlDisplay'].show()
    },
    newDailyProgress() {
      this.resetContentFields();
      this.oSlides.selected = [];

      this.redirectToModal('mdlContentDetail');
    },
    editDailyProgress(id_selected = '') {
      // console.log(id_selected)
      if (id_selected == '') {
        // alert ('no id')
      } else {
        this.resetContentFields();
        this.actionModalInProgress('show', 'load');

        axios
          .get('project/get_project_site_daily_progress/' + id_selected)
          .then ( response => {
            // console.log('200 editDailyProgress')
            // console.log(response)

            this.model.remarks = response.data.data.remarks
            this.model.attachments = response.data.data.attachments

            // console.log(this.model)
            // console.log(this.oSlides.selected)

            this.redirectToModal('mdlContentDetail');
          })
          .catch ( error => {
            console.log(error.response.status)
            this.errorFunction(error, "Edit Data Manipulation")
          })
          .finally(() => {
            this.actionModalInProgress('hide');
          });  
      }
    },
    showLargeImage(imageSrc) {
      this.oSlides.largeImageSrc = imageSrc;
      this.oSlides.isLargeImageVisible = true;
    },
    getDailyProgressListing() {
      axios.get(apiDomain + 'project/project_site_daily_progress_list/' + this.$route.params.id, { headers: getHeader() })
        .then(response => {
          if (response.status === 200) {
            // console.log('=================================================')
            // console.log('func getDailyProgressListing> main')
            // console.log(response); // Log final attributes with image URLs
            //img\default\img_not_available.png

            let tmp_mainContainer = response.data.data;
            console.log(tmp_mainContainer);

            //_ this is the container that going to be use by the UI
            this.attributes = response.data.data.map(progressItem => ({
              remark: progressItem.remarks,
              key: progressItem.id,

              //**to store image url later, you can combine customData and imageId to under an array list if u want */
              customData: "/img/default/loading_v1.gif",

              //**store ImageId to pass into api below for the image to load */
              // imageId: progressItem.attachments[0].id!==undefined?progressItem.attachments[0].id:"",
              dates: new Date(progressItem.creation_date), // Convert creation date to Date object
              datesValidate: new Date(progressItem.creation_date).setHours(8, 0, 0, 0),
              // attachments: progressItem.attachments,
              images: [],
            }));

            // console.log('attributes');
            // console.log(this.attributes);

            // Loop through attributes and fetch images for each
            const promises = this.attributes.map((attribute, index) => {
              // Fetch token and image for each progress item by its key (ID)
              // console.log('func getDailyProgressListing> nested main')
              // console.log(attribute);
              // console.log(tmp_mainContainer[index])

              if (tmp_mainContainer[index].attachments.length > 0) {
                //_ loop through the attachments on each of entry
                tmp_mainContainer[index].attachments.forEach(element => {
                  // console.log('element')
                  // console.log(element)

                  //** the imageId is supposed to be passed in here, but i set as 5 temporary to see if the retriving of images work*/
                  // let imageId = attribute.key
                  let imageId = element.id
                  // return axios.post(`project/project_site_token?value=${tmp_id}`)
                  return axios
                    .post(`project/project_site_token?value=${imageId}`)
                    .then(tokenResponse => {
                      const token = tokenResponse.data;

                      /** same for here, need to replace 5 with imageId */
                      const url = `${apiDomain}project/download/${imageId}?token=${token}`;

                      // Fetch the image as a blob
                      return axios.get(url, { responseType: 'blob' });
                    })
                    .then(blobResponse => {
                      // Store the image blob inside customData for each attribute
                      // console.log(index)
                      const imageUrl = URL.createObjectURL(blobResponse.data);

                      // Store the image URL inside customData for each attribute
                      this.attributes[index].customData = imageUrl;
                      this.attributes[index].images.push(imageUrl)
                    });
                });
              } else {
                let noImg = "/img/default/img_not_available.png";
                this.attributes[index].customData = noImg
                this.attributes[index].images.push(noImg)
              }
            });

            // Wait for all image downloads to complete
            return Promise.all(promises);
          }
        })
        .then(() => {
          // console.log('func getDailyProgressListing> .then')
          // console.log(this.attributes); // Log final attributes with image URLs
        })
        .catch(error => {
          this.errorFunction(error, "Project Site Daily Progress Listing")
        });
    },
    createData() {
      // console.log('createData')
      // console.log(this.model)
      if (this.model.remarks == null || this.model.remarks == '') {
        // console.log('error null')
        // this.redErrorNotify('<b>Invalid Input : Remarks is a Mandatory Column.</b>')
        this.oDailyContent.isErrDispRemark = true;
      }
      else {
        this.actionModalInProgress('show', 'save');

        this.model.attachment = this.documents;
        let formData = jsonFormData(this.model);

        let target_url = '';
        if ( this.oSlides.selected.length > 0 ) {
          target_url = 'project/edit_project_site_daily_progress/' + this.oSlides.selected[0].key
        } else {
          target_url = 'project/create_project_site_daily_progress/' + this.$route.params.id
        }
        axios
          .post(target_url, formData)
          .then(response => {
            //_ notify not working, not sure why, but mostlikely cause by the modal z-index maybe ?
            // this.$notify({
            //   message: '<b>New Project Site Daily Progress : </b> - Successfully Created.',
            //   timeout: 10000,
            //   icon: 'ni ni-bell-55',
            //   type: 'default',
            // });

            this.$refs['mdlContentDetail'].hide()
            this.getDailyProgressListing();
            // this.$router.back()
            this.closeAllModals()
          })
          .catch(error => {
            this.errorFunction(error, "Create New Project Site Daily Progress")
          }).
          finally (() => {
            this.actionModalInProgress('hide');
          });
      }
    },
    fileChange(event) {
      console.log('fileChange')
      const data = new FormData();
      let file = event.target.files[0];

      // Remove type validation, allow any file type
      let newArray = { uploaded_file: file };

      // Add file to the model and documents
      this.model.file_attachment.push(newArray);
      this.documents.push(newArray);
    },
    uploadAttachment(id) {
      // console.log('uploadAttachment')
      document.getElementById(id).click()
    },
    deleteAttachment(id_att='new', index_deleted) {
      if ( id_att == 'new' ) {
        this.model.file_attachment.splice(index_deleted, 1)
        this.documents.splice(index_deleted, 1)
      } else {
        // console.log(document.getElementById('uploadedImage_' + id_att + '_' + index_deleted).innerHTML);
        // console.log (this.$refs['uploadedImage_' + id_att + '_' + index_deleted])
        // console.log (document.getElementById('uploadedImage_' + id_att + '_' + index_deleted))

        axios
          .post(apiDomain + 'project/delete_project_site_daily_progress_attachment/'+id_att, { headers: getHeader() })
          .then ( response => {
            //_ notify not working, not sure why, but mostlikely cause by the modal z-index maybe ?
            // this.$notify({
            //   message: '<b>Delete Attachment Successfully Deleted.',
            //   timeout: 10000,
            //   icon: 'ni ni-bell-55',
            //   type: 'default',
            // });

            this.model.attachments.splice(index_deleted, 1);
          })
          .catch ( error => {
              this.errorFunction(error, "Delete Project Site Daily Progress")
          });
      }

    },
    downloadFile(id) {
      axios.post(`project/project_site_token?value=${id}`)
        .then(response => {
          const token = response.data
          const url = `${apiDomain}project/download/${id}?token=${token}`
          // console.log(url);
          window.open(url, '_blank')
        })
        .catch(error => {
          console.log(error)
          this.errorFunction(error, this.title)
        })
    },
    actionModalInProgress(whatAction='hide', whatProgress='load') {
      if (whatProgress == 'save') {
        this.oModal.msgLoadingInProgress = 'Saving in progress.';
      } else {
        this.oModal.msgLoadingInProgress = 'Loading in progress.';
      }

      if ( whatAction == 'show' ) {
        this.oModal.isLoadingInProgress = true;
      } else {
        this.oModal.isLoadingInProgress = false;
      }
    },
    redirectToModal(targetModal) {
      this.$refs['mdlDisplay'].hide()
      this.$refs['mdlContentDetail'].hide()
      // Then open the target modal after the current modal is closed
      this.$nextTick(() => {
        if (targetModal === 'mdlDisplay') {
          this.$refs['mdlDisplay'].show()
        } else if (targetModal === 'mdlContentDetail') {
          this.date = getcurrentDate(),
          this.$refs['mdlContentDetail'].show()
        }
      });
    },
    closeAllModals() {
      this.$refs['mdlDisplay'].hide()
      this.$refs['mdlContentDetail'].hide()
    },
    resetContentFields() {
      this.oDailyContent.isErrDispRemark = false;

      this.model.remarks = null
      this.model.file_attachment = []
      this.model.attachments = []
      this.documents = [];

      // this.oSlides.selected = [];      //_ do not reset the selected
    },
  },
};
</script>

<style scoped>
/* @import '~carbon/charts-vue/styles.css'; */
/*
  .table thead th {
      padding-top: 0.5rem;
      padding-bottom: 1.5rem;
      font-size: 0.9rem; 
      text-transform: uppercase;
      letter-spacing: 1px;
      border-bottom: 0px solid #e9ecef;
      border-top: 0px solid #e9ecef;
      color: white;
  }
  .table td {
    color: white;
  }*/


.bg-card-dashboard {
  /* background-color: #23454a; */
  background-image: linear-gradient(to bottom right, #23454a, #406B71);
}

.bg-card-dashboard-solid {
  background-color: #23454a;
  border-radius: 30px;
}

.bg-card-dashboard-border {
  border-color: #23454a;
  border-style: solid;
  border-radius: 10px;
  border-width: 2px;
}

.bg-default-dashboard {
  background-color: #082a2f;
  /* background-size: cover; */
  /* position:fixed; */
  padding: 0;
  margin: 0;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.form-control {
  background-color: #2c545a;
  border-color: #2c545a;
  color: white;

  &:focus {
    background-color: #2c545a;
    border-color: #2c545a;
    color: white;
  }

  &::placeholder {
    color: rgb(163, 163, 163);
  }
}

.form-control {
  &:focus {
    &::placeholder {
      color: rgb(163, 163, 163);
    }
  }
}

.input-group-text {
  background-color: #2c545a;
  color: white;

  &:focus {
    background-color: #2c545a;
    border-color: #1d4147;
    color: white;
  }
}

.focused {
  .input-group-text {
    background-color: #2c545a;
  }
}

.navbar-search-dark .input-group {
  background-color: #23454a;
  color: black;
  border-color: rgba(255, 255, 255, 0.6);
}

.navbar-search .input-group {
  border-radius: 7px;
}

.el-input__inner,
.el-select-dropdown__item.is-disabled:hover {
  background-color: #23454a;
}

.line-custom {
  background-color: #356168;
  margin-top: 15px;
  margin-bottom: 15px;
}

/* <!--style for flip card--> */
ul {
  padding-left: 0;
  display: flex;
  flex-flow: row wrap;
}

li {
  list-style-type: none;
  padding: 10px 10px;
  transition: all 0.3s ease;
}

label {
  font-weight: 400;
  color: #333;
  margin-right: 10px;
}

input {
  border-radius: 5px;
  border: 2px solid #eaeaea;
  padding: 10px;
  outline: none;
}
</style>

<style>
.modal-header {
  border-bottom: 1px solid #264247;
}

.modal-content {
  background-color: #306b74 !important;
}
</style>

<!-- CSS for image slides -->
<style scoped>
/deep/ .custom-calendar.vc-container {
  --day-border: 1px solid #b8c2cc;
  --day-border-highlight: 1px solid #b8c2cc;
  --day-width: 90px;
  --day-height: 150px;
  --weekday-bg: #f8fafc;
  --weekday-border: 1px solid #eaeaea;

  border-radius: 30px;
  width: 100%;

  & .vc-header {
    background-color: #f1f5f8;
    padding: 10px 0;
    border-radius: 30px 30px 0 0;
  }

  & .vc-weeks {
    padding: 0;
    border-radius: 30px;
  }

  & .vc-weekday {
    background-color: var(--weekday-bg);
    border-bottom: var(--weekday-border);
    border-top: var(--weekday-border);
    padding: 5px 0;
    border-radius: 30px;
  }

  & .vc-day {
    /* border-radius:30px; */
    padding: 0 5px 3px 5px;
    text-align: left;
    height: var(--day-height);
    min-width: var(--day-width);
    background-color: white;

    &.weekday-1,
    &.weekday-7 {
      background-color: #eff8ff;
    }

    &:not(.on-bottom) {
      border-bottom: var(--day-border);

      &.weekday-1 {
        border-bottom: var(--day-border-highlight);
      }
    }

    &:not(.on-right) {
      border-right: var(--day-border);
    }
  }

  & .vc-day-dots {
    margin-bottom: 5px;
  }
}

.button-container {
  display: flex;
  justify-content: center;
  gap: 15px;
  /* Optional: space between the buttons */
  margin-top: 20px;
  /* Optional: add space between the text and buttons */
}

.flex {
  display: flex;
}

.image-gallery {
  display: flex;
  flex-wrap: wrap;
  /* gap: 20px; */

}

.image-card {
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 10px;
  margin: 10px;
  width: 150px;
  text-align: center;
}

.uploaded-image {
  width: 80px;
  height: 80px;
  border-radius: 5px;
}
</style>

<!-- custom calendar -->
<style scoped>
/deep/ .vc-day {
  height: 80px !important;
}
</style>

<!-- vueperslide -->
<style scoped>
/deep/ .vueperslide__content {
  font-size: 24px;
  color: white;
  text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
}

/deep/ .vueperslides__arrow svg {
  /* color: white;
  stroke: black;
  stroke-width: 2px; */
  filter: drop-shadow(0 0 2px black);
}

/deep/ .vueperslides__bullets button {
  filter: drop-shadow(0 0 2px black);
}
</style>