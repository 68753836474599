<template>
  <div class="content">
    <base-header class="pb-6">
      <b-row align-v="center" class="py-4">
        <b-col cols="7" lg="6">
          <h6 class="h2 text-white d-inline-block mb-0">All Project Task Listing</h6>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <route-bread-crumb></route-bread-crumb>
          </nav>
        </b-col>
        <b-col lg="6" cols="5" class="text-right">
          <base-button size="sm" type="neutral" @click="newTask()"
          v-if="((button_access.project_task_new == 'YES') && (myUserID.email == model.project_manager.email))">
          Create New Project Task</base-button>

          <base-button size="sm" type="neutral" @click="getCreateData()">Refresh</base-button>
        </b-col>
      </b-row>
    </base-header>
    <b-container fluid class="mt--6">
      <div>
        <card class="no-border-card" body-classes="px-0 pb-1" footer-classes="pb-2">
          <template slot="header">
            <h3 v-if="model.project_name == null" class="mb-0">All Project Task Listing (Project)</h3>
            <h3 v-else class="mb-0">All Project Task Listing for ({{ model.project_name }})</h3>
          </template>
          <div>
            <b-col cols="12" class="d-flex justify-content-center justify-content-sm-between flex-wrap">
              <div>
                <base-input v-model="searchQuery"
                            prepend-icon="fas fa-search"
                            placeholder="Search...">
                </base-input>
              </div>
            </b-col>
            <b-col><br></b-col>
            <el-table :data="queriedData"
                      header-row-class-name="thead-light"
                      @sort-change="sortChange">

              <el-table-column label="Code"
                             min-width="280px"
                             prop="task_title"
                             sortable>
                <template v-slot="{row}">
                    <b-media no-body class="align-items-center">
                        <b-media-body>
                            <span class="font-weight-600 name mb-0 text-sm">{{row.task_title}}</span>
                        </b-media-body>
                    </b-media>
                </template>
              </el-table-column>

              <el-table-column label="Project"
                             min-width="150px"
                             prop="project.project_name"
                             sortable>
                <template v-slot="{row}">
                    <b-media no-body class="align-items-center">
                        <b-media-body>
                            <span class="font-weight-600 name mb-0 text-sm">{{row.project.project_name}}</span>
                        </b-media-body>
                    </b-media>
                </template>
              </el-table-column>

              <el-table-column label="Assign To"
                             min-width="140px"
                             prop="assign_to_user.name"
                             sortable>
                <template v-slot="{row}">
                    <b-media no-body class="align-items-center">
                        <b-media-body>
                            <span class="font-weight-600 name mb-0 text-sm">{{row.assign_to_user.name}}</span>
                        </b-media-body>
                    </b-media>
                </template>
              </el-table-column>

              <el-table-column label="Dates"
                             min-width="200px"
                             prop="task_est_start_date"
                             sortable>
                <template v-slot="{row}">
                    <b-media no-body class="align-items-center">
                        <b-media-body>
                            <span class="font-weight-600 name mb-0 text-sm">Est Start Date : {{row.task_est_start_date}}</span>
                        </b-media-body>
                    </b-media>
                    <b-media no-body class="align-items-center">
                        <b-media-body>
                            <span class="font-weight-600 name mb-0 text-sm">Act Start Date : {{row.task_start_date}}</span>
                        </b-media-body>
                    </b-media>
                </template>
              </el-table-column>

              <el-table-column label="Progress"
                           min-width="200px"
                             prop="task_progress"
                             sortable>
                <template v-slot="{row}">
                    <div class="d-flex align-items-center">
                        <div>
                          <span class="font-weight-600 name mb-0 text-sm">{{row.task_progress}}% &nbsp; </span>
                        </div>
                        <div>
                            <base-progress v-if="row.task_progress > 0 && row.task_progress < 50" min-width="30px" type="danger" :value="row.task_progress"/>
                            <base-progress v-else-if="row.task_progress >= 50 && row.task_progress <= 90" min-width="30px" type="info" :value="row.task_progress"/>
                            <base-progress v-else min-width="30px" type="success" :value="row.task_progress"/>
                        </div>
                    </div>
                    <b-media no-body class="align-items-center">
                        <b-media-body>
                            <badge class="badge-dot mr-4" type="">
                              <i v-if="row.status.status_code == 'Delayed'" class="bg-danger"></i>
                              <i v-else-if="row.status.status_code == 'On-Hold'" class="bg-danger"></i>
                              <i v-else-if="row.status.status_code == 'Reviewed'" class="bg-success"></i>
                              <i v-else-if="row.status.status_code == 'Completed'" class="bg-success"></i>
                              <i v-else class="bg-info"></i>
                              <span class="font-weight-600 name mb-0 text-sm">{{row.status.status_code}}</span>
                            </badge>
                        </b-media-body>
                    </b-media>
                </template>
              </el-table-column>

              <el-table-column min-width="140px" align="right" label="Actions">
                <div slot-scope="{$index, row}" class="d-flex">
                  <base-button v-if="button_access.project_task_view == 'YES'"
                    @click.native="handleDetails($index, row)" size="sm" type="primary">
                    View <br>/ Edit 
                  </base-button>
                  <base-button v-if="((button_access.project_task_delete == 'YES' && row.project.project_manager.email == myUserID.email) || myUserID.role_id == 3 || myUserID.role_id == 1) && row.status.status_code != 'Approved'"
                    @click.native="handleDelete($index, row)" size="sm" type="primary">
                    Delete  
                  </base-button>
                </div>
              </el-table-column>
            </el-table>
          </div>
          <div
            slot="footer"
            class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap">
            <div class="">
              <p class="card-category">
                Showing {{ from + 1 }} to {{ to }} of {{ total }} entries
              </p>
            </div>
            <base-pagination
              class="pagination-no-border"
              :current="pagination.currentPage"
              :per-page="pagination.perPage"
              :total="total"
              @change="paginationChanged($event)">
            </base-pagination>
          </div>
        </card>

        <modal :show.sync="modals.delete_forms"
                size="lg"
                body-classes="p-0">
          <card type="secondary"
                header-classes="bg-transparent pb-5"
                body-classes="px-lg-5 py-lg-5"
                class="border-0 mb-0">
            <template>
              <div class="text-center text-muted mb-4" >
                <h3>Are You SURE you want to delete this Task ? </h3>
                <h3>with Title : {{modals.delete_title}} </h3>
              </div>
              <b-form role="form">

                <h5 class="text-danger"> Note : <br>
                  <i>
                  1. Once Delete You cannot reverse the process.

                  </i>
                </h5>
                <div class="text-center">
                  <base-button type="danger" class="my-4" @click="deleteRow()">YES</base-button>
                  <base-button type="default" class="my-4" @click="cancelDelete()">NO</base-button>
                </div>
              </b-form>
            </template>
          </card>
        </modal>

      </div>
    </b-container>
    </div>
</template>
<script>
import { Table, TableColumn, Select, Option } from 'element-ui';
import RouteBreadCrumb from '@/components/Breadcrumb/RouteBreadcrumb'
import { BasePagination } from '@/components';
import listingAllPaginationMix from '@/assets/js/listingAllPaginationMix.js'
import Fuse from 'fuse.js';
import axios from 'axios'
import { Modal } from '@/components';
import {LoginURL, clientId, clientSecret, apiDomain, getHeader} from '@/assets/js/config.js'

export default {
  mixins: [listingAllPaginationMix],
  components: {
    BasePagination,
    RouteBreadCrumb,
    Modal,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn
  },
  created()
  {
      this.model.project_id = this.$route.params.id
      var project_task_access = JSON.parse(window.localStorage.getItem('project_task_access'))
      this.button_access.project_task_listing = project_task_access.access_listing
      this.button_access.project_task_new = project_task_access.access_create
      this.button_access.project_task_view = project_task_access.access_show
      this.button_access.project_task_delete = project_task_access.access_delete
      
      const LoginUser = JSON.parse(window.localStorage.getItem('loginUser'))
      this.myUserID = LoginUser
      this.getCreateData()
  },
  data() {
    return {
      propsToSearch:
      [
        'task_title',
        'project.project_name',
        'task_progress',
        'status.status_code',
      ],
      tableColumns: [],
      tableData: [],
      selectedRows: [],
      model:
      {
        project_id: null,
        project_name: null,
        project_manager:
        {
            name: null,
            email: null,
        },
      },
      myUserID: null,
      button_access: {
        project_task_listing: null,
        project_task_new: null,
        project_task_view: null,
        project_task_delete: null,
      },
      modals: 
      {
        delete_id: null,
        delete_title: null,
        delete_forms: false,
      },
    };
  },
  methods: {
    handleDelete(index, row)
    {
      this.modals.delete_title = row.task_title
      this.modals.delete_id = row.task_id
      this.modals.delete_forms = true
    },
    cancelDelete()
    {
      this.modals.delete_title = null
      this.modals.delete_id = null
      this.modals.delete_forms = false
    },
    deleteRow(index, row)
    {
      axios.delete(apiDomain + 'task/project_task/'+ this.modals.delete_id , { headers: getHeader() })
      .then ( response => {
        console.log(response)
        if (response.status === 200)
        {
          this.$notify({
          message:
            '<b>Project Task : </b> - Successfully DELETED.',
          timeout: 10000,
          icon: 'ni ni-bell-55',
          type: 'default',
          });
          this.getCreateData()
          this.$emit("EventTaskListUpdate")
        }
      })
      .catch ( error => {
          console.log(error.response.status)
          this.errorFunction(error, 'Delete Project Task')
      } );
      this.modals.delete_forms = false
    },
    fuseData()
    {
        this.fuseSearch = new Fuse(this.tableData, {
        keys: this.propsToSearch,
        threshold: 0.3
        });
    },
    getCreateData()
    {
        axios.get(apiDomain + 'task/project_task.show_by_project/' + this.model.project_id, { headers: getHeader() })
        .then ( response => {
        if (response.status === 200)
        {
            this.tableData = response.data.data
            this.model.project_id = response.data.data[0].project.project_id
            this.model.project_name = response.data.data[0].project.project_name
            this.model.project_manager.name = response.data.data[0].project.project_manager.name
            this.model.project_manager.email = response.data.data[0].project.project_manager.email
            this.fuseData()
        }
        })
        .catch ( error => {
        console.log(error.response.status)
        if ( error.response.status == 401)
        {
            this.$router.push('/login')
        }
        });
    },
    newTask()
    {
      this.$router.push({path: '/projectTaskNewFrProject/' + this.model.project_id })
    },
    paginationChanged(page)
    {
      this.pagination.currentPage = page
    },
    handleDetails(index, row)
    {
      let route = this.$router.resolve({path: '/projectTaskEditFrProject/' + row.task_id })
      window.open(route.href, '_blank');
    },
  }
};
</script>
<style>
  .no-border-card .card-footer{
    border-top: 0;
  }
</style>
