<template>
  <div>
    <base-header class="pb-6" type="">
      <b-row align-v="center" class="py-4">
        <b-col lg="12" cols="7">
          <h6 class="h2 d-inline-block mb-0">Site Dashboard</h6>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <b-breadcrumb class="breadcrumb-links">
              <b-breadcrumb-item
                ><router-link to="/"><i class="fas fa-home"></i></router-link
              ></b-breadcrumb-item>
              <b-breadcrumb-item>Dashboards Layout </b-breadcrumb-item>
              <b-breadcrumb-item active aria-current="page"
                >Site Dashboard</b-breadcrumb-item
              >
            </b-breadcrumb>
          </nav>
        </b-col>

        <b-col lg="6" cols="7"> </b-col>

        <!-- <div>{{ tempData }}</div> -->

        <b-col lg="6" cols="7">
          <card gradient="default" no-body="">
            <b-card-body>
              <div class="mb-2">
                <div>
                  <span class="h2 text-white"
                    >Site Name : {{ model.site_name }}
                  </span>
                </div>
                <div class="text-light mt-2 text-sm">
                  Current Site Status :
                  <span class="text-success font-weight-600">
                    {{ model.status_code }}
                  </span>
                </div>
                <!-- <div class="text-light mt-2 text-sm">
                  Project Manager :
                  <span class="text-success font-weight-600">
                    {{ model.site_leader }}
                  </span>
                </div> -->
                <div class="text-light mt-2 text-sm">
                  Project Engineer :
                  <span class="text-success font-weight-600">
                    {{ model.created_by.name }}
                  </span>
                </div>
              </div>
            </b-card-body>
          </card>
        </b-col>
        <b-col lg="12" cols="6" class="text-right">
          <base-button
            v-if="project_id != null"
            size="sm"
            type="primary"
            @click="routeToProjectDashboard()"
            >Project Dashboard</base-button
          >
          <!-- <base-button
            size="sm"
            type="primary"
            @click="newTask()"
            v-if="
              button_access.site_task_new == 'YES' &&
              myUserID.email == model.group_engineer.email
            "
            >Create New Site Task</base-button
          > -->
          <base-button
            size="sm"
            type="primary"
            @click="newContractor()"
            v-if="
              (button_access.site_contractor_listing == 'YES' && myUserID.email == model.group_engineer.email)
              || myUserID.role_id == 3
            "
            >Site Contractor</base-button
          >

          <!-- <base-button size="sm" type="primary" @click="$router.back()">Back</base-button> -->
          <base-button size="sm" type="primary" @click="$router.go(-2)"
            >Back</base-button
          >
        </b-col>
      </b-row>

      <b-row>
        <b-col xl="4" md="6">
          <stats-card
            title="Completed / Total Task"
            type="gradient-red"
            :sub-title="`${model.site_completed_task} / ${model.site_total_task}`"
            icon="ni ni-pin-3"
          >
            <template slot="footer"> </template>
          </stats-card>
        </b-col>
        <b-col xl="4" md="6">
          <stats-card
            title="Turn On / Total (kWP)"
            type="gradient-orange"
            :sub-title="`${model.site_completed_capacity} / ${model.site_total_capacity} `"
            icon="ni ni-cloud-upload-96"
          >
            <template slot="footer"> </template>
          </stats-card>
        </b-col>
        <b-col xl="4" md="6">
          <stats-card
            title="Site Progress"
            type="gradient-info"
            :sub-title="`${model.site_progress} %`"
            icon="ni ni-chart-bar-32"
          >
            <template slot="footer"> </template>
          </stats-card>
        </b-col>
      </b-row>
    </base-header>

    <b-container fluid class="mt--6">
      <b-row>
        <b-col xl="12">
          <project-site-view-form-sn></project-site-view-form-sn>
        </b-col>
      </b-row>

      <b-row
        ><b-col
          ><project-site-management-remarks></project-site-management-remarks></b-col
      ></b-row>

      <b-row>
        <b-col xl="12">
          <b-card no-body>
            <b-card-header>
              <h5 class="h3 mb-0" slot="header">
                Site Contractor List for ({{ model.site_name }})
              </h5>
              <div class="text-right">
                <base-button
                  size="sm"
                  type="primary"
                  v-if="
                    (button_access.site_contractor_listing == 'YES' && myUserID.email == model.group_engineer.email)
                    || myUserID.role_id == 3
                  "
                  @click="newContractor()"
                  >Site Contractor</base-button
                >
              </div>
            </b-card-header>
            <b-card-body>
              <site-contractor-table></site-contractor-table>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>

      <b-row
        ><b-col
          ><card>
            <!-- <div>{{ tempSiteMilestoneResponse }}</div> -->
            <b-table
              sticky-header="540px"
              striped
              hover
              :items="tempSiteMilestoneResponse"
              :fields="column"
            ></b-table></card></b-col
      ></b-row>

      <b-row v-if="displaySiteMilestoneProgressChart"
        ><b-col>
          <apexchart
            ref="cniPieChart"
            type="pie"
            height="400px"
            :options="chartOptionsSiteIncomplete"
            :series="seriesSiteIncomplete"
          ></apexchart></b-col
      ></b-row>
      <b-row v-else
        ><b-col
          ><div>All Site Milestones Progress are at 100%</div></b-col
        ></b-row
      >
      <!-- @dataPointSelection="dataPointSelectionHandlerPieCNI" -->

      <!-- <b-row v-if="button_access.site_task_listing == 'YES'">
        <b-col lg="12">
          <project-site-task-listing-table
            @EventTaskListUpdate="afterUpdateTaskList()"
          ></project-site-task-listing-table>
        </b-col>
      </b-row> -->
    </b-container>
  </div>
</template>

<script>
import RouteBreadCrumb from "@/components/Breadcrumb/RouteBreadcrumb";
import BaseHeader from "@/components/BaseHeader";
import ProjectSiteViewFormSN from "./ProjectSiteViewFormSN";
import ProjectSiteManagementRemarks from "./ProjectSiteManagementRemarks";

import LineChart from "@/components/Charts/LineChart";
import * as chartConfigs from "@/components/Charts/config";
import ProjectSiteTaskListingTable from "./ProjectSiteTaskListingTable";
import ProjectSiteDocumentListingTable from "./ProjectSiteDocumentListingTable";
import SiteContractorTable from "./AdditionalTable/SiteContractorTable";

import { Modal } from "@/components";
import {
  LoginURL,
  clientId,
  clientSecret,
  apiDomain,
  getHeader,
  getcurrentDate,
} from "@/assets/js/config.js";
import axios from "axios";
import ErrorFunctionMixin from "@/assets/js/errorFunctionMix.js";
import VueApexCharts from "vue-apexcharts";

export default {
  mixins: [ErrorFunctionMixin],
  components: {
    BaseHeader,
    RouteBreadCrumb,
    LineChart,
    "project-site-task-listing-table": ProjectSiteTaskListingTable,
    "site-contractor-table": SiteContractorTable,
    "project-site-document-listing-table": ProjectSiteDocumentListingTable,
    "project-site-management-remarks": ProjectSiteManagementRemarks,
    Modal,
    "project-site-view-form-sn": ProjectSiteViewFormSN,
    apexchart: VueApexCharts,
  },
  created() {
    var site_task_access = JSON.parse(
      window.localStorage.getItem("site_task_access")
    );
    this.button_access.site_task_listing = site_task_access.access_listing;
    this.button_access.site_task_new = site_task_access.access_create;

    var site_document_access = JSON.parse(
      window.localStorage.getItem("site_document_access")
    );
    this.button_access.site_document_listing =
      site_document_access.access_listing;
    this.button_access.site_document_new = site_document_access.access_create;

    var site_contractor_access = JSON.parse(
      window.localStorage.getItem("site_contractor_access")
    );
    this.button_access.site_contractor_listing =
      site_contractor_access.access_listing;

    const LoginUser = JSON.parse(window.localStorage.getItem("loginUser"));
    this.myUserID = LoginUser;

    this.getProjectId();

    // this.getdashboardData();
    this.getdashboardDataShort();

    // this.getDataListing();
  },
  data() {
    return {
      column: [
        {
          key: "site_milestone_sequence",
          sortable: true,
          label: "Sequence",
        },
        {
          key: "site_milestone_code",
          sortable: true,
          label: "Name",
        },
        {
          key: "site_milestone_weightage",
          sortable: true,
          label: "Weightage",
        },
        {
          key: "site_milestone_progress",
          sortable: true,
          label: "Progress",
        },
      ],
      project_id: null,
      button_access: {
        site_task_listing: null,
        site_task_new: null,
        site_document_listing: null,
        site_document_new: null,
        site_contractor_listing: null,
      },
      myUserID: null,
      model: {
        site_id: null,
        site_name: null,
        site_name: null,
        status_code: null,
        site_leader: null,
        created_by: null,
        site_total_installation: 0,
        site_total_task: 0,
        site_completed_task: 0,
        site_total_document: 0,
        site_completed_document: 0,
        site_progress: 0,
        site_total_capacity: 0,
        site_completed_capacity: 0,
        group_engineer: {
          name: null,
          email: null,
        },
      },
      modals: {
        formTask: false,
        formMilestone: false,
      },

      formModal: {
        task_template: "",
        task_site: "",
        milestone_template: "",
      },
      bigLineChart: {
        allData: [
          [0, 20, 10, 30, 15, 40, 20, 60, 60],
          [0, 20, 5, 25, 10, 30, 15, 40, 40],
        ],
        activeIndex: 0,
        chartData: {
          datasets: [
            {
              label: "Performance",
              data: [
                0, 100, 250, 500, 1000, 1500, 2000, 2500, 2900, 3200, 3300,
                3300,
              ],
            },
          ],
          labels: [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec",
          ],
        },
        extraOptions: chartConfigs.blueChartOptions,
      },
      chartOptionsSiteIncomplete: {
        chart: {
          type: "pie",
        },
        labels: [],
        responsive: [
          {
            options: {
              chart: {
                width: 200,
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
        tooltip: {
          y: {
            formatter: function (val) {
              return val + " kWp";
            },
          },
        },
      },
      seriesSiteIncomplete: [],
      // REMOVE THIS LATER
      chartOptionsPieCNI: {
        chart: {
          // width: 380,
          type: "pie",
        },
        labels: [
          "Design & Engineering",
          "Authority Submission",
          "Hoisting",
          "Installation",
          "Turn On",
          "Others",
        ],
        legend: {
          labels: {
            colors: "#FFFFFF",
          },
        },
        // colors: [
        //   "#549e39",
        //   "#c0cf3a",
        //   "#4ab5c4",
        //   "#325f22",
        //   "#76801f",
        //   "#28707a",
        // ],
        responsive: [
          {
            // breakpoint: 1,
            options: {
              chart: {
                width: 200,
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
        tooltip: {
          y: {
            formatter: function (val) {
              return val + " kWp";
            },
          },
        },
      },
      seriesPieCNI: [2, 10, 45, 10, 10],
      tempData: null,
      isSolarNovaProject: false,
      tempSiteMilestoneResponse: false,
      displaySiteMilestoneProgressChart: true,
      tempSiteProgressArray: null,
    };
  },
  methods: {
    // MOVE OUT BOTTOM PART
    convertToKwpIncomplete(weightage, progress) {
      var capacityIncomplete =
        ((100 - progress) * weightage * this.model.site_total_capacity) / 10000;
      return capacityIncomplete;
    },
    // HAS TO BE AFTER GETTING TOTAL CAPACITY
    updateSiteIncompletePieChart() {
      var tempLabelsArray = [];
      var tempSeriesArray = [];
      var tempSiteMilestoneProgressArray = [];
      this.tempSiteMilestoneResponse.forEach((e) => {
        tempSiteMilestoneProgressArray.push(e["site_milestone_progress"]);
        tempLabelsArray.push(e["site_milestone_code"]);
        var tempCapacity = this.convertToKwpIncomplete(
          e["site_milestone_weightage"],
          e["site_milestone_progress"]
        );
        tempSeriesArray.push(tempCapacity);
      });
      this.chartOptionsSiteIncomplete = {
        labels: tempLabelsArray,
      };
      var completedCounter = 0;
      tempSiteMilestoneProgressArray.forEach((e) => {
        if (e == 100) {
          completedCounter = completedCounter + 1;
        }
      });
      if (tempSiteMilestoneProgressArray.length == completedCounter) {
        this.displaySiteMilestoneProgressChart = false;
      }
      // this.tempSiteProgressArray = tempSiteMilestoneProgressArray;
      this.seriesSiteIncomplete = tempSeriesArray;
    },
    getDataListing() {
      axios
        .get(
          apiDomain +
            "project/project_site_milestone.show_by_site/" +
            this.model.site_id,
          { headers: getHeader() }
        )
        .then((response) => {
          if (response.status === 200) {
            this.tempSiteMilestoneResponse = response.data.data;
            this.updateSiteIncompletePieChart();
          }
        })
        .catch((error) => {
          console.log(error.response.status);
        });
    },
    // MOVE OUT TOP PART
    routeToProjectDashboard() {
      this.$router.push({ path: "/projectDashboard/" + this.project_id });
    },
    // Get Project Type ID and Check if Solar Nova Project
    getProjectTypeCheckSolar() {
      var project_type_id = null;
      axios
        .get(apiDomain + "project/project/" + this.project_id, {
          headers: getHeader(),
        })
        .then((response) => {
          if (response.status === 200) {
            project_type_id =
              response.data.data[0].project_type.project_type_id;
            if (
              project_type_id != null &&
              // (project_type_id == 2 ||
              //   project_type_id == 3 ||
              //   project_type_id == 4)
              ([2, 3, 4, 9, 10, 11, 12, 13, 14].includes(project_type_id))
            ) {
              this.isSolarNovaProject = true;
            } else {
              this.getdashboardData();
            }
          }
        })
        .catch((error) => {
          console.log(error.response.status);
        });
    },
    getProjectId() {
      axios
        .get(
          apiDomain + "project/project_site_short/" + this.$route.params.id,
          {
            headers: getHeader(),
          }
        )
        .then((response) => {
          if (response.status === 200) {
            this.project_id = response.data.data[0].project.project_id;
            this.getProjectTypeCheckSolar();
          }
        })
        .catch((error) => {
          console.log(error.response.status);
          this.errorFunction(error, "Project Site Information");
        });
    },
    afterUpdateTaskList() {
      this.getdashboardData();
    },
    getdashboardData() {
      this.model.site_id = this.$route.params.id;
      axios
        .get(apiDomain + "project/project_site/" + this.model.site_id, {
          headers: getHeader(),
        })
        .then((response) => {
          if (response.status === 200) {
            this.model.site_name = response.data.data[0].site_name;
            this.model.status_code = response.data.data[0].status.status_code;
            this.model.site_leader =
              response.data.data[0].group.group_engineer.name;
            this.model.site_total_installation =
              response.data.data[0].site_total_installation;
            this.model.site_total_task = response.data.data[0].site_total_task;
            this.model.site_total_document =
              response.data.data[0].site_total_document;
            this.model.site_progress = response.data.data[0].site_progress;
            this.model.site_completed_capacity =
              response.data.data[0].site_completed_capacity;
            this.model.site_total_capacity =
              response.data.data[0].site_total_capacity;
            this.model.site_completed_task =
              response.data.data[0].site_completed_task;
            this.model.site_completed_document =
              response.data.data[0].site_completed_document;
            this.model.group_engineer.email =
              response.data.data[0].group.group_engineer.email;
          }
        })
        .catch((error) => {
          console.log(error.response.status);
          this.errorFunction(error, "Site Information");
        });
    },
    getdashboardDataShort() {
      this.model.site_id = this.$route.params.id;
      axios
        .get(apiDomain + "project/project_site_short/" + this.model.site_id, {
          headers: getHeader(),
        })
        .then((response) => {
          if (response.status === 200) {
            this.tempData = response.data.data;
            this.model.site_name = response.data.data[0].site_name;
            this.model.status_code = response.data.data[0].status.status_code;
            this.model.site_leader =
              response.data.data[0].group.group_engineer.name;
            this.model.site_total_installation =
              response.data.data[0].site_total_installation;
            this.model.site_total_task = response.data.data[0].site_total_task;
            this.model.site_total_document =
              response.data.data[0].site_total_document;
            this.model.site_progress = response.data.data[0].site_progress;
            this.model.site_completed_capacity =
              response.data.data[0].site_completed_capacity;
            this.model.site_total_capacity =
              response.data.data[0].site_total_capacity;
            this.model.site_completed_task =
              response.data.data[0].site_completed_task;
            this.model.site_completed_document =
              response.data.data[0].site_completed_document;
            this.model.group_engineer.email =
              response.data.data[0].group.group_engineer.email;
            this.model.created_by = response.data.data[0].created_by;
            this.getDataListing();
          }
        })
        .catch((error) => {
          console.log(error.response.status);
          this.errorFunction(error, "Site Information");
        });
    },
    newSiteDocument() {
      this.$router.push({
        path: "/siteDocumentNewFrSite/" + this.model.site_id,
      });
    },
    newTask() {
      this.$router.push({ path: "/taskNewFrSite/" + this.model.site_id });
    },
    newImport() {
      this.$router.push({ path: "/importNewFrSite/" + this.model.site_id });
    },
    newContractor() {
      this.$router.push({
        path: "/siteContractorListing/" + this.model.site_id,
      });
    },
    notready() {
      this.$notify({
        message:
          "<b>Under Developer : </b> - New Task, Site Contractor & Populate / Import From Template function is not ready.",
        timeout: 10000,
        icon: "ni ni-bulb-61",
        type: "danger",
      });
    },
    notifyVueFormTask() {
      if (this.formModal.task_template === "") {
        this.$notify({
          message:
            "<b>Task Template Input Error : </b> - Please Select A Task Template & Target Site OR press <b>CANCEL</b> to <b>EXIT</b>.",
          timeout: 10000,
          icon: "ni ni-bulb-61",
          type: "danger",
        });
      } else {
        this.$notify({
          message:
            "<b>Task Template (" +
            this.formModal.task_template +
            ") : </b> - Successfully Imported into ( TRTP-2398 - Tengeh Reservoir Tender Project )",
          timeout: 10000,
          icon: "ni ni-bell-55",
          type: "default",
        });
        this.clearModal();
      }
    },
    notifyVueFormMilestone() {
      if (this.formModal.milestone_template === "") {
        this.$notify({
          message:
            "<b>Milestone Template Input Error : </b> - Please Select A Milestone Template Or Cancel to Exit.",
          timeout: 10000,
          icon: "ni ni-bulb-61",
          type: "danger",
        });
      } else {
        this.$notify({
          message:
            "<b>Milestone Template (" +
            this.formModal.milestone_template +
            ") : </b> - Successfully Imported into ( TRTP-2398 - Tengeh Reservoir Tender Project )",
          timeout: 10000,
          icon: "ni ni-bell-55",
          type: "default",
        });
        this.clearModal();
      }
    },
    clearModal() {
      this.modals.formTask = false;
      this.modals.formMilestone = false;
      this.formModal.task_template = "";
      this.formModal.task_site = "";
      this.formModal.milestone_template = "";
    },
  },
};
</script>
<style></style>
