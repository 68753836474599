<template>
  <div>
    <base-header class="pb-6">
      <b-row class="py-4">
        <b-col>
          <h6 class="h2 text-white d-inline-block mb-0">{{ title }}</h6>
        </b-col>
      </b-row>
    </base-header>

    <b-container fluid class="mt--6">
      <div class="card-wrapper">
        <card>
          <!-- Card header -->
          <table slot="header">
            <tr>
              <td class="pr-3">Project</td>
              <td><h4 class="mb-0">{{ setting.project_name }}</h4></td>
            </tr>
            <tr>
              <td class="pr-3">Site</td>
              <td><h4 class="mb-0">{{ setting.site_name }}</h4></td>
            </tr>
          </table>

          <!-- Card body -->
          <template v-if="setting.id">
            <div class="d-flex align-items-baseline justify-content-between mb-1">
              <h3>Documents</h3>
              <!-- Document -->
              <router-link to="Document/Listing" class="btn btn-primary btn-sm ml-3" :class="{ 'invisible': !canManage }" title="Manage Documents">
                <i class="ni ni-bullet-list-67"></i>
              </router-link>
            </div>
            <div class="d-flex align-items-baseline justify-content-between">
              <div>
                <!-- Category 1 -->
                <el-select class="ml-2" v-model="selectedCategory1" clearable :placeholder="setting.category1_name">
                  <el-option v-for="item of categories1" :key="item.id" :value="item.id" :label="item.code"/>
                </el-select>
                <router-link to="Category/Listing/1" class="btn btn-primary btn-sm ml-2" :class="{ 'invisible': !canManage }" :title="`Manage ${setting.category1_name}`">
                  <i class="ni ni-settings-gear-65"></i>
                </router-link>
                <!-- Category 2 -->
                <el-select class="ml-2" v-model="selectedCategory2" clearable :placeholder="setting.category2_name">
                  <el-option v-for="item of categories2" :key="item.id" :value="item.id" :label="item.code"/>
                </el-select>
                <router-link to="Category/Listing/2" class="btn btn-primary btn-sm ml-2" :class="{ 'invisible': !canManage }" :title="`Manage ${setting.category2_name}`">
                  <i class="ni ni-settings-gear-65"></i>
                </router-link>
                <!-- Category 3 -->
                <template v-if="setting.category3_enabled">
                  <el-select class="ml-2" v-model="selectedCategory3" clearable :placeholder="setting.category3_name">
                    <el-option v-for="item of categories3" :key="item.id" :value="item.id" :label="item.code"/>
                  </el-select>
                  <router-link to="Category/Listing/3" class="btn btn-primary btn-sm ml-2" :class="{ 'invisible': !canManage }" :title="`Manage ${setting.category3_name}`">
                    <i class="ni ni-settings-gear-65"></i>
                  </router-link>
                </template>
              </div>
              <!-- Status -->
              <el-select v-model="selectedStatus" clearable placeholder="Status">
                <el-option v-for="item of statuses" :key="item" :value="item" :label="documentStatusToText(item)"/>
              </el-select>
            </div>
            <el-table v-if="setting.id" :data="documents" header-row-class-name="thead-light" cell-class-name="fw-semibold" class="mt-2">
              <el-table-column :label="setting.category1_name" prop="category1_code" width="260px"/>
              <el-table-column :label="setting.category2_name" prop="category2_code" width="260px"/>
              <el-table-column :label="setting.category3_name" prop="category3_code" width="260px" v-if="setting.category3_enabled"/>
              <el-table-column label="Number" prop="number"/>
              <el-table-column label="Title" prop="title"/>
              <el-table-column label="Alternative Title" prop="alt_title"/>
              <el-table-column label="Status" prop="status">
                <template v-slot="{ row }">
                  <StatusIcon :status="row.status" class="mr-1"/>
                  {{ documentStatusToText(row.status) }}
                </template>
              </el-table-column>
              <el-table-column label="Actions">
                <template v-slot="{ row }">
                  <router-link :to="`D/${row.id}/Revision`" class="btn btn-primary btn-sm" v-if="uploadAccess.listing">Revisions</router-link>
                </template>
              </el-table-column>
            </el-table>
          </template>

          <!-- Document repo not setup -->
          <template v-if="noSetting">
            <div class="fw-semibold">{{ title }} Has Not Been Setup</div>
            <div class="mt-2">Please contact the respective personnel from Sembcorp for more information.</div>
          </template>
        </card>
      </div>
    </b-container>
  </div>
</template>

<script>
import ErrorFunctionMixin from '@/assets/js/errorFunctionMix.js'
import { Table, TableColumn, Select, Option } from 'element-ui';
import axios from '@/util/axios'
import { getAccessByModule } from '@/util/access'
import { documentStatusToText } from './util'
import StatusIcon from './StatusIcon.vue'

export default {
  mixins: [ErrorFunctionMixin],
  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    StatusIcon,
  },

  data() {
    return {
      title: 'Document Tracking',
      pid: null,
      sid: null,
      noSetting: false,
      setting: {},
      categories1: [],
      categories2: [],
      categories3: [],
      selectedCategory1: null,
      selectedCategory2: null,
      selectedCategory3: null,
      statuses: [
        'EMPTY',
        'NEW_REVISION',
        'PENDING_REVIEW',
        'NEW_REVIEW',
        'ACCEPTED',
        'REJECTED',
        'APPROVED',
      ],
      selectedStatus: null,
      documents: [],
      uploadAccess: {},
      reviewAccess: {},
    }
  },

  computed: {
    canManage() {
      return this.reviewAccess.create || this.reviewAccess.edit || this.reviewAccess.delete
    },
  },

  watch: {
    selectedCategory1() {
      this.loadDocuments()
    },

    selectedCategory2() {
      this.loadDocuments()
    },

    selectedCategory3() {
      this.loadDocuments()
    },

    selectedStatus() {
      this.loadDocuments()
    },
  },

  created() {
    this.pid = this.$route.params.pid
    this.sid = this.$route.params.sid
    this.uploadAccess = getAccessByModule('DOC_REPO_UPLOAD')
    this.reviewAccess = getAccessByModule('DOC_REPO_REVIEW')

    this.loadSetting()
    this.loadCategories()
    this.loadDocuments()
  },

  methods: {
    documentStatusToText(status) {
      return documentStatusToText(status)
    },

    loadSetting() {
      axios.get(`doc_repo/p/${this.pid}/s/${this.sid}/setting`)
        .then(response => {
          const data = response.data.data

          if (data) {
            this.setting = data
            return
          }

          // Redirect to Init page.
          if (this.reviewAccess.create) {
            this.$router.replace('Init')
            return
          }

          // No permission to init doc repo.
          this.loadSite()
          this.noSetting = true
        })
        .catch(error => {
          this.errorFunction(error, this.title)
        })
    },

    loadSite() {
      axios.get(`project/project_site_short/${this.sid}`)
        .then(response => {
          const [site] = response.data.data
          this.setting = {
            project_name: site.project.project_name,
            site_name: site.site_name,
          }
        })
        .catch(error => {
          this.errorFunction(error, this.title)
        })
    },

    loadCategories() {
      axios.get(`doc_repo/p/${this.pid}/s/${this.sid}/category`)
        .then(response => {
          const categories = response.data.data
          this.categories1 = categories.filter(x => x.level == 1)
          this.categories2 = categories.filter(x => x.level == 2)
          this.categories3 = categories.filter(x => x.level == 3)
        })
        .catch(error => {
          this.errorFunction(error, this.title)
        })
    },

    loadDocuments() {
      const options = {
        params: {
          category1_id: this.selectedCategory1,
          category2_id: this.selectedCategory2,
          category3_id: this.selectedCategory3,
          status: this.selectedStatus,
        },
      }

      axios.get(`doc_repo/p/${this.pid}/s/${this.sid}/document`, options)
        .then(response => {
          this.documents = response.data.data
        })
        .catch(error => {
          this.errorFunction(error, this.title)
        })
    },
  },
}
</script>
