<template>
    <b-card no-body class="bg-default shadow">
        <b-card-header class="bg-transparent border-0">
            <h3 v-if="model.project_name == null " class="mb-0 text-white">Group Listing - Create New Group</h3>
            <h3 v-else class="mb-0 text-white">Group Listing for ({{ model.project_name }})</h3>
            <div class="text-right">

              <base-button size="sm" type="neutral" @click="newProjectGroup()"
              v-if="((button_access.group_new == 'YES') && (myUserID.email == model.project_manager.email))">Create New Group</base-button>
            </div>
        </b-card-header>

        <el-table class="table-responsive table-dark"
                  header-row-class-name="thead-dark"
                  :data="queriedData">

            <el-table-column label="Group Name"
                             min-width="180px"
                             prop="group_name"
                             sortable>
                <template v-slot="{row}">
                    <b-media no-body class="align-items-center">
                        <b-media-body>
                            <span class="font-weight-600 name mb-0 text-sm">{{row.group_name}}</span>
                        </b-media-body>
                    </b-media>
                </template>
              </el-table-column>

              <el-table-column label="Project"
                             min-width="140px"
                             prop="project.project_name"
                             sortable>
                <template v-slot="{row}">
                    <b-media no-body class="align-items-center">
                        <b-media-body>
                            <span class="font-weight-600 name mb-0 text-sm">{{row.project.project_name}}</span>
                        </b-media-body>
                    </b-media>
                </template>
              </el-table-column>

              <el-table-column label="Engineer"
                             min-width="150px"
                             prop="group_engineer"
                             sortable>
                <template v-slot="{row}">
                    <b-media no-body class="align-items-center">
                        <b-media-body>
                            <span class="font-weight-600 name mb-0 text-sm">{{row.group_engineer.name}}</span>
                        </b-media-body>
                    </b-media>
                </template>
              </el-table-column>

              <el-table-column label="Progress"
                           min-width="210px"
                             prop="group_progress"
                             sortable>
                <template v-slot="{row}">
                    <div class="d-flex align-items-center">
                        <div>
                          <span class="font-weight-600 name mb-0 text-sm">{{row.group_progress}}% &nbsp; </span>
                        </div>
                        <div>
                            <base-progress v-if="row.group_progress > 0 && row.group_progress < 50" min-width="30px" type="danger" :value="row.group_progress"/>
                            <base-progress v-else-if="row.group_progress >= 50 && row.group_progress <= 90" min-width="30px" type="info" :value="row.group_progress"/>
                            <base-progress v-else min-width="30px" type="success" :value="row.group_progress"/>
                        </div>
                    </div>
                    <b-media no-body class="align-items-center">
                        <b-media-body>
                            <badge class="badge-dot mr-4" type="">
                              <i v-if="row.status.status_code == 'Delayed'" class="bg-danger"></i>
                              <i v-else-if="row.status.status_code == 'On-Hold'" class="bg-danger"></i>
                              <i v-else-if="row.status.status_code == 'Reviewed'" class="bg-success"></i>
                              <i v-else-if="row.status.status_code == 'Completed'" class="bg-success"></i>
                              <i v-else class="bg-info"></i>
                              <span class=" font-weight-600 name mb-0 text-white ">{{row.status.status_code}}</span>
                            </badge>
                        </b-media-body>
                    </b-media>
                </template>
              </el-table-column>

              <el-table-column min-width="140px" align="right" label="Actions">
                <div slot-scope="{$index, row}" class="d-flex">
                  <base-button v-if="button_access.group_show == 'YES'"
                    @click="projectGroupDashboard($index, row)" size="sm" type="primary">
                    Dashboard
                  </base-button>
                </div>
              </el-table-column>
        </el-table>

        <b-card-footer class="py-2 d-flex justify-content-end bg-transparent">
              <base-pagination
              class="pagination-no-border"
              :current="pagination.currentPage"
              :per-page="pagination.perPage"
              :total="total"
              @change="paginationChanged($event)"
            >
            </base-pagination>
        </b-card-footer>
    </b-card>
</template>

<script>

  import { Table, TableColumn, DropdownMenu, DropdownItem, Dropdown, Select, Option} from 'element-ui'
  import {homeLink} from '@/assets/js/config.js'
  import RouteBreadCrumb from '@/components/Breadcrumb/RouteBreadcrumb'
  import { BasePagination } from '@/components';
  import clientPaginationMixin from './js/ProjectGroupPaginationMix'
  import swal from 'sweetalert2';
  import axios from 'axios'
  import {LoginURL, clientId, clientSecret, apiDomain, getHeader} from '@/assets/js/config.js'

  export default {
    mixins: [clientPaginationMixin],
    components: {
      BasePagination,
      RouteBreadCrumb,
      [Select.name]: Select,
      [Option.name]: Option,
      [Table.name]: Table,
      [TableColumn.name]: TableColumn
    },
    created()
    {
        this.model.project_id = this.$route.params.id

        var project_group_access = JSON.parse(window.localStorage.getItem('project_group_access'))
        this.button_access.group_new = project_group_access.access_create
        this.button_access.group_show = project_group_access.access_show

        const LoginUser = JSON.parse(window.localStorage.getItem('loginUser'))
        this.myUserID = LoginUser

        axios.get(apiDomain + 'project/project_group.show_by_project/' + this.model.project_id, { headers: getHeader() })
        .then ( response => {
          if (response.status === 200)
          {
            this.tableData = response.data.data
            this.model.project_name = response.data.data[0].project.project_name
            this.model.project_manager.name = response.data.data[0].project.project_manager.name
            this.model.project_manager.email = response.data.data[0].project.project_manager.email
          }
        })
        .catch ( error => {
            console.log(error.response.status)
        } );
    },
    data() {
      return {
        propsToSearch: ['group_code', 'group_template_name', ],
        tableColumns: [],
        tableData: [],
        selectedRows: [],
        myUserID: null,
        model:
        {
          project_id: null,
          project_name: null,
          project_manager:
          {
              name: null,
              email: null,
          },
        },
        button_access: {
          group_new: null,
          group_show: null,
        },
      };
    },
    methods:
    {
      newTask()
      {
        this.$router.push({path: '/taskNewFrProject/' + this.model.project_id })
      },
      paginationChanged(page)
      {
        this.pagination.currentPage = page
      },
      newProjectGroup()
      {
        this.$router.push({path: '/projectGroupNewFrProject/' + this.model.project_id })
      },
      notready() {
          this.$notify({
          message:
            '<b>Under Developer : </b> - New Task & Site function is not ready.',
          timeout: 10000,
          icon: 'ni ni-bulb-61',
          type: 'danger',
          });
      },
      projectGroupDashboard(index, row)
      {
          this.$router.push({path: '/projectGroupDashboard/'+row.group_id })
      },
    },
  }
</script>
