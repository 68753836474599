<template>
  <card>
    <!-- Card header -->
    <h3 slot="header" class="mb-0">New Module Brand</h3>
    <div class="text-right">
    </div>
    <form class="needs-validation"
          @submit.prevent="submit">

      <div class="form-row">
        <b-col md="4">
          <base-input label="Module Brand Code"
                      name="Module Brand Code"
                      placeholder="Module Brand Code"
                      v-model="model.module_brand_id_code" required>
          </base-input>
        </b-col>

        <b-col md="8">
          <base-input label="Module Brand Name"
                      name="Module Brand Name"
                      placeholder="Module Brand Name"
                      v-model="model.module_brand_id_name" required>
          </base-input>
        </b-col>
      </div>

      <div class="form-row">
        <b-col md="4">
          <base-input label="Watt Class"
                      name="Watt Class"
                      placeholder="Watt Class"
                      v-model="model.watt_class" required>
          </base-input>
        </b-col>
      </div>

      <div class="form-row">
        <b-col md="4">
          <b-form-checkbox
            id="Active-Status"
            v-model="model.active_status"
            name="Active-Status"
            value="1"
            unchecked-value="0">
            <b>Active Status</b>
          </b-form-checkbox>
        </b-col>
      </div>
      <br>

      <base-button type="primary" @click="createData()">Create New Module Brand</base-button>
      <base-button type="primary" @click="$router.back()">Cancel</base-button>
      <!-- <div>
        <br>
        <h5 class="text-danger"> Note : <br><br>
                <i>
                1. By Default all Date is set to "TODAY.
                </i>
                <br>
                <i>
                2. Module Brand Progress % is set to "0 %" By Default.
                </i>
        </h5>
      </div> -->
    </form>
  </card>
</template>
<script>
  import BaseSlider from '@/components/BaseSlider'
  import {LoginURL, clientId, clientSecret, apiDomain, getHeader, getcurrentDate} from '@/assets/js/config.js'
  import axios from 'axios'
  import ErrorFunctionMixin from '@/assets/js/errorFunctionMix.js'

  export default {
    mixins: [ErrorFunctionMixin],
    components: {
      BaseSlider
    },
    created()
    {
      var currentDate = getcurrentDate()
    },
    data() {
      return {
        model: {
          module_brand_id_code: null,
          module_brand_id_name: null,
          watt_class: null,
          active_status: 1,
        },
      }
    },
    methods: {
      createData(){
        if ( !this.model.module_brand_id_code || !this.model.module_brand_id_name || !this.model.watt_class )
        {
          if ( !this.model.module_brand_id_code )
          {
            this.redErrorNotify( '<b>Invalid Input : Module Brand Code is a Mandatory Column in Module Brand.</b>')
          }
          else if ( !this.model.module_brand_id_name  )
          {
            this.redErrorNotify( '<b>Invalid Input : Module Brand Name is a Mandatory Column in Module Brand.</b>')
          }
          else if ( !this.model.watt_class  )
          {
            this.redErrorNotify( '<b>Invalid Input : Watt Class is a Mandatory Column in Module Brand.</b>')
          }
          else
          {
            this.redErrorNotify( '<b>Invalid Input : Please fill in all the Mandatory Column in Task </b>' )
          }
        }
        else
        {
          axios.post(apiDomain + 'setup/module_brand', this.model, { headers: getHeader() })
          .then ( response => {
            if (response.status === 200)
            {
                this.$notify({
                message:
                  '<b>New Module Brand : ' + this.model.module_brand_id_code + '</b> - Successfully Created.',
                timeout: 10000,
                icon: 'ni ni-bell-55',
                type: 'default',
                });
                this.$router.back()
            }
          })
          .catch ( error => {
            this.errorFunction(error, "Create New Module Brand")
          });
        }
      },
    }
  }
</script>
<style>
</style>
