const dataProposeTLRoute = [
  {
    desc: "proposedTLRoute",
    coord: [
      { lat: -2.844615288030678, lng: 122.0808162215605 },
      { lat: -2.843626065557394, lng: 122.0808581604368 },
      { lat: -2.842561916832862, lng: 122.0835809703182 },
      { lat: -2.841683869208673, lng: 122.0870592412189 },
      { lat: -2.842930810426423, lng: 122.0954786352646 },
      { lat: -2.837476413586116, lng: 122.1013152926669 },
      { lat: -2.828609591574998, lng: 122.108910416868 },
      { lat: -2.827088504528595, lng: 122.1202308164921 },
      { lat: -2.829230982549955, lng: 122.1328643341851 },
      { lat: -2.829537642082105, lng: 122.1429807751129 },
      { lat: -2.838550870239782, lng: 122.1429912846695 },
      { lat: -2.842895570101015, lng: 122.1519798356979 },
    ],
  },
];

export default dataProposeTLRoute;
