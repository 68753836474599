<template>
    <div class="d-block text-center">
           
             <p class="my-4">This is the first modal.</p>
     
             <b-button @click="redirectToModal('secondModal')">Go to Second Modal</b-button>
      <!-- <b-button @click="redirectToModal('thirdModal')">Go to Third Modal</b-button> -->
   
             </div>
 </template>
 
 <script>
 import { Select, Option, Table, TableColumn, } from 'element-ui'
 import standardPaginationMix from '@/assets/js/standardPaginationMix.js'
 import ErrorFunctionMixin from '@/assets/js/errorFunctionMix.js'
 import { BasePagination } from '@/components';
 import axios from '@/util/axios'
 import { getAccessByModule } from '@/util/access'
 import Fuse from 'fuse.js';
 
//  import SecondModal from './New2.vue';

 export default {
   // name :"Tracking Template",
   mixins: [standardPaginationMix, ErrorFunctionMixin],
   components: {
     [Select.name]: Select,
     [Option.name]: Option,
     [Table.name]: Table,
     [TableColumn.name]: TableColumn,
 
   },
   data() {
     return {
      isFirstModalVisible: false,
      isSecondModalVisible: false,
      isThirdModalVisible: false,
       all_data: [],
       items: [],
       tableData: [],
       access: {},
       newForm: {},
       template: {},
       categories1: [],
       categories2: [],
       categories3: [],
       documents: [],
       roles: [],
       selectedRoles: [],
       tid: null,
       model: {},
     
 
     };
   },
   watch: {
  
   },
   created() {
   
   },
 
   methods: {
 
     close() {
 
 this.$emit('close')

 
 },
 
     fuseData() {
       this.fuseSearch = new Fuse(this.tableData, {
         keys: this.propsToSearch,
         threshold: 0.3,
       });
     },
     showModal(modalId) {
      if (modalId === 'firstModal') {
        this.isFirstModalVisible = true;
      }
    },
    redirectToModal(targetModal) {
      // Close all modals first
      this.isFirstModalVisible = false;
      this.isSecondModalVisible = false;
      this.isThirdModalVisible = false;

      // Then open the target modal after the current modal is closed
      this.$nextTick(() => {
        if (targetModal === 'firstModal') {
          this.isFirstModalVisible = true;
        } else if (targetModal === 'secondModal') {
          this.isSecondModalVisible = true;
      
        this.$refs['secondModal'].show()

        } else if (targetModal === 'thirdModal') {
          this.isThirdModalVisible = true;
        }
      });
    },
    closeAllModals() {
      this.isFirstModalVisible = false;
      this.isSecondModalVisible = false;
      this.isThirdModalVisible = false;
    },
   },
   mounted() {
     //this.getPagination();
     //this.loadDocuments()
   },
 
 };
 </script>

 <style scoped>


/deep/ .form-control {
  background-color: #2C545A;
  border-color: #2C545A;
  color: white;

  &:focus {
    background-color: #2C545A;
    border-color: #2C545A;
    /* color: white; */
  }

  &::placeholder {
    color: white;
  }
}

/deep/ .form-control {
  &:focus {
    &::placeholder {
      color: white;
    }
  }
}
</style>