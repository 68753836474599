<template>
  <card>
    <!-- Card header -->
    <h3 slot="header" class="mb-0">New Project Group</h3>
    <div class="text-right">
    </div>
    <form class="needs-validation"
          @submit.prevent="submit">

      <div v-if="projects.length == 0" class="form-row">
        <b-col md="12">
        <h5 class="text-danger"> !!! Warning :
            <i>
            Please Create
            <router-link to="/ProjectNew">
                New Project
            </router-link>
            at Menu >> Project-Group-Site >> Project. System required at least ONE Project & One Group before you can proceed to create New Project Site.
            </i>
        </h5>
        </b-col>
      </div>

      <div class="form-row">
        <b-col md="6">
          <base-input label="Project Group Name"
                      name="Project Group Name"
                      placeholder="Project Group Name"
                      v-model="model.group_name">
          </base-input>
        </b-col>

        <b-col md="5">
          <base-input label="Project"
                      name="Project"
                      v-model="model.project_id">
            <select class="form-control" v-model="model.project_id">
              <option Selected disabled value="x">-- Please Select A Project --</option>
              <option v-for="item in projects" :key="item.project_id" v-bind:value="item.project_id">{{ item.project_name }}</option>
            </select>
          </base-input>
        </b-col>

        <b-col md="1">
          <br>
          <base-button size="sm" type="primary" @click="checkProject()">Project Infomation</base-button>
        </b-col>

      </div>

      <div class="form-row">
         <b-col md="12">
          <base-input label="Project Group Information"
                      name="Project Group Information"
                      placeholder="Project Group Information"
                      v-model="model.group_information">
          </base-input>
        </b-col>

        <!-- <b-col md="6">
          <base-input label="Group PV Capacity (KWp)"
                      name="Group PV Capacity (KWp)"
                      placeholder="Group PV Capacity (KWp)"
                      v-model="model.group_pv_capacity" type="number" step="any">
          </base-input>
        </b-col> -->

        <b-col md="12">
          <base-input label="Project Manager"
                      name="Project Manager"
                      v-model="model.group_engineer">
            <select class="form-control" v-model="model.group_engineer">
              <option Selected disabled value="x">-- Please Select A Group Manager --</option>
              <option v-for="item in group_engineerss" :key="item.id" v-bind:value="item.id">{{ item.name }} - {{ item.email }}</option>
            </select>
          </base-input>
        </b-col>
      </div>

      <div class="form-row">
        <b-col md="12">
          <base-input label="Project Group Status"
                      name="Project Group Status"
                      v-model="model.status_id">
            <select class="form-control" v-model="model.status_id">
              <option Selected disabled value="x">-- Please Select Group Status --</option>
              <option v-for="item in group_status" :key="item.status_id" v-bind:value="item.status_id">{{ item.status_code }}</option>
            </select>
          </base-input>
        </b-col>

        <!-- <b-col md="6">
          <base-input label="Project Group Progress % "
                      name="Project Group Progress"
                      placeholder="Project Group Progress"
                      v-model="model.group_progress" type="number" min="0" max="100">
          </base-input>
        </b-col> -->
      </div>

      <base-button type="primary" @click="createData()">Create / Save</base-button>
      <base-button type="primary" @click="$router.back()">Cancel</base-button>
      <!-- <div>
        <br>
        <h5 class="text-danger"> Note : <br><br>
                <i>
                1. By Default all Date is set to "TODAY.
                </i>
                <br>
                <i>
                2. Project Group Progress % is set to "0 %" By Default.
                </i>
        </h5>
      </div> -->
    </form>
  </card>
</template>
<script>
  import BaseSlider from '@/components/BaseSlider'
  import {LoginURL, clientId, clientSecret, apiDomain, getHeader, getcurrentDate} from '@/assets/js/config.js'
  import axios from 'axios'
  import ErrorFunctionMixin from '@/assets/js/errorFunctionMix.js'

  export default {
    mixins: [ErrorFunctionMixin],
    components: {
      BaseSlider
    },
    created()
    {
      var currentDate = getcurrentDate()


      axios.get(apiDomain + 'project/project', { headers: getHeader() })
      .then ( response => {
        if (response.status === 200)
        {
          this.projects = response.data.data
        }
      })
      .catch ( error => {
        console.log(error.response.status)
        this.errorFunction(error, "Project Information")
      } );

      axios.get(apiDomain + 'lookup/group_status', { headers: getHeader() })
      .then ( response => {
        if (response.status === 200)
        {
          this.group_status = response.data.data
        }
      })
      .catch ( error => {
        console.log(error.response.status)
        this.errorFunction(error, "Group Status")
      } );

      axios.get(apiDomain + 'lookup/user_list.project_group', { headers: getHeader() })
      .then ( response => {
        if (response.status === 200)
        {
          this.group_engineerss = response.data.data
        }
      })
      .catch ( error => {
        console.log(error.response.status)
        this.errorFunction(error, "Project Engineer Information")
      } );

    },
    data() {
      return {
        model: {
          project_id: 'x',
          group_code: null,
          group_name: null,
          group_information: null,
          group_location: null,
          group_pv_capacity: '0.1',
          status_id: 'x',
          group_engineer: 'x',
          group_progress: 0,
        },
        projects: [],
        group_status: [],
        group_engineerss: [],
      }
    },
    methods: {
      checkProject()
      {
        if (this.model.project_id == 'x')
        {
          this.$notify({
            message:
              '<b>Invalid Input : Project </b> - Please select a Project before dive into the Details',
            timeout: 10000,
            icon: 'ni ni-bulb-61',
            type: 'danger',
          });
        }
        else
        {
          this.$router.push({path: '/projectEdit/'+this.model.project_id })
        }
      },
      createData(){
        if ( !this.model.group_pv_capacity || !this.model.group_name || this.model.project_id == 'x' || this.model.status_id == 'x'
        || this.model.group_engineer == 'x' || !this.model.group_information)
        {
          if ( !this.model.group_pv_capacity )
          {
            this.redErrorNotify( '<b>Invalid Input : Group Capacity Column is a Mandatory in Project Group </b>')
          }
          else if (this.model.group_name == 'x' )
          {
            this.redErrorNotify( '<b>Invalid Input : Group Name Column is a Mandatory in Project Group </b>' )
          }
          else if (this.model.project_id == 'x')
          {
            this.redErrorNotify( '<b>Invalid Input : Project is a Mandatory in Project Group </b>' )
          }
          else if (this.model.status_id == 'x')
          {
            this.redErrorNotify( '<b>Invalid Input : Group Status is a Mandatory in Project Group </b>' )
          }
          else if (this.model.group_engineer == 'x')
          {
            this.redErrorNotify( '<b>Invalid Input : Project Engineer is a Mandatory in Project Group </b>' )
          }
          else if (this.model.group_information == 'x')
          {
            this.redErrorNotify( '<b>Invalid Input : Safety Officer is a Mandatory in Project Group </b>' )
          }
          else
          {
            this.redErrorNotify( '<b>Invalid Input : Please fill in all the Mandatory Column in Project Group </b>' )
          }
        }
        else if( this.model.group_progress < 0 || this.model.group_progress > 100 )
        {
          this.redErrorNotify( '<b>Invalid Input : Project Group Progress </b> - Input Must be between 0 - 100' )
        }
        else
        {
          axios.post(apiDomain + 'project/project_group', this.model, { headers: getHeader() })
          .then ( response => {
            if (response.status === 200)
            {
                this.$notify({
                message:
                  '<b>New Project Group : ' + this.model.group_code + '</b> - Successfully Created.',
                timeout: 10000,
                icon: 'ni ni-bell-55',
                type: 'default',
                });
                this.$router.back()
            }
          })
          .catch ( error => {
              console.log(error.response.data.errors)
              this.errorFunction(error, "Project Group Information")
          });
        }
      },
    }
  }
</script>
<style>
</style>
