var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('base-header',{staticClass:"pb-6"},[_c('b-row',{staticClass:"py-4"},[_c('b-col',[_c('h6',{staticClass:"h2 text-white d-inline-block mb-0"},[_vm._v(_vm._s(_vm.title))])]),_c('b-col',{staticClass:"text-right"},[_c('base-button',{attrs:{"size":"sm","type":"neutral"},on:{"click":function($event){return _vm.$router.replace('../Listing')}}},[_vm._v("Back")])],1)],1)],1),_c('b-container',{staticClass:"mt--6",attrs:{"fluid":""}},[_c('div',{staticClass:"card-wrapper"},[_c('card',[_c('h3',{staticClass:"mb-0",attrs:{"slot":"header"},slot:"header"},[_vm._v("Edit Document")]),_c('validation-observer',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{staticClass:"needs-validation",on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)}}},[_c('b-form-row',[_c('b-col',[_c('validation-provider',{attrs:{"name":_vm.setting.category1_name,"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.setting.category1_name,"invalid-feedback":errors[0]}},[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.model.category1_id),expression:"model.category1_id"}],staticClass:"form-control",class:classes,on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.model, "category1_id", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},_vm._l((_vm.categories1),function(item){return _c('option',{key:item.id,domProps:{"value":item.id}},[_vm._v(_vm._s(item.code)+" - "+_vm._s(item.name))])}),0)])]}}],null,true)})],1),_c('b-col',[_c('validation-provider',{attrs:{"name":_vm.setting.category2_name,"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.setting.category2_name,"invalid-feedback":errors[0]}},[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.model.category2_id),expression:"model.category2_id"}],staticClass:"form-control",class:classes,on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.model, "category2_id", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},_vm._l((_vm.categories2),function(item){return _c('option',{key:item.id,domProps:{"value":item.id}},[_vm._v(_vm._s(item.code)+" - "+_vm._s(item.name))])}),0)])]}}],null,true)})],1)],1),(_vm.setting.category3_enabled)?_c('b-form-row',[_c('b-col',[_c('validation-provider',{attrs:{"name":_vm.setting.category3_name,"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.setting.category3_name,"invalid-feedback":errors[0]}},[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.model.category3_id),expression:"model.category3_id"}],staticClass:"form-control",class:classes,on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.model, "category3_id", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},_vm._l((_vm.categories3),function(item){return _c('option',{key:item.id,domProps:{"value":item.id}},[_vm._v(_vm._s(item.code)+" - "+_vm._s(item.name))])}),0)])]}}],null,true)})],1),_c('b-col')],1):_vm._e(),_c('b-form-row',[_c('b-col',[_c('validation-provider',{attrs:{"name":"Title","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Title","invalid-feedback":errors[0]}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.model.title),expression:"model.title"}],staticClass:"form-control",class:classes,attrs:{"type":"text","maxlength":"300"},domProps:{"value":(_vm.model.title)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.model, "title", $event.target.value)}}})])]}}],null,true)})],1),_c('b-col',[_c('validation-provider',{attrs:{"name":"Alternative Title"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Alternative Title","invalid-feedback":errors[0]}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.model.alt_title),expression:"model.alt_title"}],staticClass:"form-control",class:classes,attrs:{"type":"text","maxlength":"300"},domProps:{"value":(_vm.model.alt_title)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.model, "alt_title", $event.target.value)}}})])]}}],null,true)})],1)],1),_c('b-form-row',[_c('b-col',[_c('validation-provider',{attrs:{"name":"Position","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Position","invalid-feedback":errors[0]}},[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.model.position),expression:"model.position"}],staticClass:"form-control",class:classes,on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.model, "position", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{domProps:{"value":0}},[_vm._v("First")]),_vm._l((_vm.documents),function(item){return _c('option',{key:item.id,domProps:{"value":item.sequence}},[_vm._v("After: "+_vm._s(item.title))])})],2)])]}}],null,true)})],1),_c('b-col',[_c('validation-provider',{attrs:{"name":"Reviewer/Approver Roles","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Reviewer/Approver Roles","invalid-feedback":errors[0]}},[_c('b-form-checkbox-group',{staticClass:"border rounded p-2",attrs:{"options":_vm.roles,"stacked":"","state":errors[0] ? false : null},model:{value:(_vm.selectedRoles),callback:function ($$v) {_vm.selectedRoles=$$v},expression:"selectedRoles"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors[0] ? false : null}},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1)],1),_c('div',{staticClass:"d-flex align-items-baseline justify-content-between"},[_c('div',[(_vm.access.edit)?_c('button',{staticClass:"btn btn-primary"},[_vm._v("Update")]):_vm._e(),_c('button',{staticClass:"btn btn-primary",attrs:{"type":"button"},on:{"click":function($event){return _vm.$router.replace('../Listing')}}},[_vm._v("Cancel")])]),(_vm.access.delete)?_c('button',{staticClass:"btn btn-danger",attrs:{"type":"button"},on:{"click":function($event){_vm.showConfirmDelete = true}}},[_vm._v("Delete")]):_vm._e()])],1)]}}])})],1)],1)]),_c('modal',{attrs:{"show":_vm.showConfirmDelete,"size":"sm","body-classes":"p-0"},on:{"update:show":function($event){_vm.showConfirmDelete=$event}}},[_c('card',{staticClass:"border-0 mb-0",attrs:{"type":"secondary","header-classes":"bg-transparent pb-5","body-classes":"px-lg-5 py-lg-5"}},[_c('div',{staticClass:"text-center text-muted mb-4"},[_c('h3',[_vm._v("Delete this Document?")])]),_c('div',{staticClass:"text-center"},[_c('button',{staticClass:"btn btn-danger",on:{"click":_vm.deleteRecord}},[_vm._v("YES")]),_c('button',{staticClass:"btn btn-dark",on:{"click":function($event){_vm.showConfirmDelete = false}}},[_vm._v("NO")])])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }