<template>
<div>
  <card>
    <!-- Card header -->
    <div>
    <h3 slot="header" class="mb-3.5">Project Site Information : {{ model.site_name }} for ( {{ model.project_name }} - {{ model.group_name }} ) - General </h3>
       <div class="text-right">
          <router-link :to="'/projectSiteEdit/' + model.site_id">
              <base-button size="sm" type="primary"
              v-if="((button_access.site_edit == 'YES') && ( myUserID.email == model.group_engineer_email || myUserID.role_id == 3 ))">
              Edit</base-button>
          </router-link>
       </div>
    </div>
    <br>
    <form class="needs-validation" @submit.prevent="submit">

      <div class="form-row">
        <b-col md="6">
          <base-input label="Project"
                      name="Project"
                      v-model="model.project_name" disabled>
          </base-input>
        </b-col>

        <b-col md="6">
          <base-input label="Group"
                      name="Group"
                      v-model="model.group_name" disabled>
          </base-input>
        </b-col>
      </div>

      <div class="form-row">
        <b-col md="8">
          <base-input label="Site Name"
                      name="Site Name"
                      placeholder="Site Name"
                      v-model="model.site_name" disabled>
          </base-input>
        </b-col>

        <b-col md="4">
          <!-- <base-input label="Project Manager (P.I.C)"
                      name="Project Manager (P.I.C)"
                      v-model="model.site_leader" disabled>
          </base-input> -->
          <base-input label="Project Engineer (P.I.C)"
                      name="Project Engineer (P.I.C)"
                      v-model="model.site_leader" disabled>
          </base-input>
        </b-col>
      </div>

      <div class="form-row">
        <b-col md="6">
          <base-input label="Target Turn-on Date"
                      name="Target Turn-on Date"
                      type="date"
                      v-model="model.site_target_turn_on_date" disabled>
          </base-input>
        </b-col>

        <b-col md="6">
          <base-input label="Actual Turn-on Date"
                      name="Actual Turn-on Date"
                      type="date"
                      v-model="model.site_actual_turn_on_date" disabled>
          </base-input>
        </b-col>
      </div>

      <div class="form-row">
        <b-col md="6">
          <base-input label="Site Postal Code"
                      name="Site Postal Code"
                      placeholder="Site Postal Code"
                      v-model="model.site_postal_code" disabled>
          </base-input>
        </b-col>

        <b-col md="6">
          <base-input label="Site Address"
                      name="Site Address"
                      placeholder="Site Address"
                      v-model="model.site_address" disabled>
          </base-input>
        </b-col>
      </div>

      <div class="form-row">
        <b-col md="6">
          <base-input label="Site Status"
                      name="Site Status"
                      v-model="model.status_id" disabled>
          </base-input>
        </b-col>

        <b-col md="6">
          <base-input label="Site Progress % "
                      name="Site Progress"
                      placeholder="Project Site Progress"
                      v-model="model.site_progress" disabled>
          </base-input>
        </b-col>
      </div>

      <div class="form-row">
        <b-col md="3">
          <base-input label="EPC Site Start Date"
                      name="EPC Site Start Date"
                      type="date"
                      v-model="model.site_start_date" disabled>
          </base-input>
        </b-col>
        <b-col md="3">
          <base-input label="EPC Site End Date"
                      name="EPC Site End Date"
                      type="date"
                      v-model="model.site_end_date" disabled>
          </base-input>
        </b-col>

        <b-col md="3">
          <base-input label="Client Agreed Site Start Date"
                      name="Client Agreed Site Start Date"
                      type="date"
                      v-model="model.site_contractor_start_date" disabled>
          </base-input>
        </b-col>
        <b-col md="3">
          <base-input label="Client Agreed Site End Date"
                      name="Client Agreed Site End Date"
                      type="date"
                      v-model="model.site_contractor_end_date" disabled>
          </base-input>
        </b-col>
      </div>
    </form>
  </card>

   <card>
    <!-- Card header -->
    <div>
    <h3 slot="header" class="mb-3.5">Project Site Information : {{ model.site_name }} for ( {{ model.project_name }} - {{ model.group_name }} ) - Site Details</h3>
       <div class="text-right">
          <router-link :to="'/projectSiteEdit/' + model.site_id">
              <base-button size="sm" type="primary"
              v-if="((button_access.site_edit == 'YES') && ( myUserID.email == model.group_engineer_email || myUserID.role_id == 3 ))">
              Edit</base-button>
          </router-link>
       </div>
    </div>
    <br>
    <form class="needs-validation" @submit.prevent="submit">
   
      <div class="form-row">
        <b-col md="6">
          <base-input label="EL License Number"
                      name="EL License Number"
                      placeholder="EL License Number"
                      v-model="model.site_elu_license_number" disabled>
          </base-input>
        </b-col>
        <b-col md="6">
          <base-input label="Site Tension Level"
                      name="Site Tension Level"
                      placeholder="Site Tension Level"
                      v-model="model.site_type_of_tension" disabled>
          </base-input>
        </b-col>

        <b-col md="6">
          <base-input label="Site Approved Load"
                      name="Site Approved Load"
                      placeholder="Site Approved Load"
                      v-model="model.site_approved_load" disabled>
          </base-input>
        </b-col>

        <b-col md="6">
          <base-input label="Site MSSL Acc Number"
                      name="Site MSSL Acc Number"
                      placeholder=" Site MSSL Acc Number"
                      v-model="model.site_mssl_account_number" disabled>
          </base-input>
        </b-col>
      </div>
    </form>
  </card>

   <card>
    <!-- Card header -->
    <div>
    <h3 slot="header" class="mb-3.5">Project Site Information : {{ model.site_name }} for ( {{ model.project_name }} - {{ model.group_name }} ) - PV System Details </h3>
       <div class="text-right">
          <router-link :to="'/projectSiteEdit/' + model.site_id">
              <base-button size="sm" type="primary"
              v-if="((button_access.site_edit == 'YES') && ( myUserID.email == model.group_engineer_email || myUserID.role_id == 3 ))">
              Edit</base-button>
          </router-link>
       </div>
    </div>
    <br>
    <form class="needs-validation" @submit.prevent="submit">



      <div class="form-row">
        <b-col md="3">
          <base-input label="Site Module Quantity (PCS)"
                      name="Site Module Quantity (PCS)"
                      placeholder="Site Module Quantity (PCS)"
                      v-model="model.site_module_quantity" disabled>
          </base-input>
        </b-col>

        <b-col md="3">
          <base-input label="Site Module Watt Class (Wp)"
                      name="Site Module Watt Class"
                      placeholder="Site Module Watt Class"
                      v-model="model.site_module_capacity" disabled>
          </base-input>
        </b-col>

        <b-col md="3">
          <base-input label="Site Total Capacity (KWp)"
                      name="Site Total Capacity"
                      placeholder="Site Total Capacity"
                      v-model="model.site_total_capacity" disabled>
          </base-input>
        </b-col>

        <b-col md="3">
          <base-input label="Site Performance Ratio (%)"
                      name="Site Performance Ratio (%)"
                      placeholder="Site Performance Ratio (%)"
                      v-model="model.site_pr_capacity" disabled>
          </base-input>
        </b-col>
      </div>

      <div class="form-row">
        <b-col md="3">
          <base-input
            label="Module Brand Name"
            name="Module Brand Name"
            v-model="model.site_module_brand.module_brand_id_name"
            disabled
          >
          </base-input>
        </b-col>

        <b-col md="3">
          <base-input label="Site Monitoring Application"
                      name="Site Monitoring Application"
                      placeholder="Site Monitoring Application"
                      v-model="model.site_monitoring_application" disabled>
          </base-input>
        </b-col>

        <b-col md="3">
          <base-input label="Site Inverter Type & Pcs"
                      name="Site Inverter Type & Pcs"
                      placeholder="Site Inverter Type & Pcs"
                      v-model="model.site_inverter_type" disabled>
          </base-input>
        </b-col>

        <b-col md="3">
          <base-input label="Site Logger Type/Model"
                      name="Site Logger Type/Model"
                      placeholder="Site Logger Type/Model"
                      v-model="model.site_logger_type" disabled>
          </base-input>
        </b-col>
      </div>

    </form>
  </card>
</div>
</template>
<script>
  import BaseSlider from '@/components/BaseSlider'
  import {LoginURL, clientId, clientSecret, apiDomain, getHeader, getcurrentDate} from '@/assets/js/config.js'
  import axios from 'axios'
  import ErrorFunctionMixin from '@/assets/js/errorFunctionMix.js'

  export default {
    mixins: [ErrorFunctionMixin],
    components: {
      BaseSlider
    },
    created() {

      var project_site_access = JSON.parse(window.localStorage.getItem('project_site_access'))
      this.button_access.site_edit = project_site_access.access_edit

      const LoginUser = JSON.parse(window.localStorage.getItem('loginUser'))
      this.myUserID = LoginUser

      this.model.site_id = this.$route.params.id

      axios.get(apiDomain + 'project/project_site/' + this.model.site_id, { headers: getHeader() })
      .then ( response => {
        if (response.status === 200)
        {
            this.model.site_id            = response.data.data[0].site_id
            this.model.site_code          = response.data.data[0].site_code
            this.model.site_name          = response.data.data[0].site_name
            this.model.project_name         = response.data.data[0].project.project_name
            this.model.group_name           = response.data.data[0].group.group_name
            this.model.site_postal_code       = response.data.data[0].site_postal_code
            this.model.site_leader        = response.data.data[0].group.group_engineer.name
            this.model.site_lead_contact  = response.data.data[0].site_lead_contact
            this.model.site_address       = response.data.data[0].site_address
            this.model.status_id          = response.data.data[0].status.status_code
            this.model.site_progress      = response.data.data[0].site_progress
            this.model.site_elu_license_number    = response.data.data[0].site_elu_license_number
            this.model.site_elu_status            = response.data.data[0].site_elu_status
            this.model.site_module_quantity       = response.data.data[0].site_module_quantity
            this.model.site_module_capacity       = response.data.data[0].site_module_capacity
            this.model.site_total_capacity        = response.data.data[0].site_total_capacity
            this.model.site_pr_capacity           = response.data.data[0].site_pr_capacity
            this.model.site_type_of_tension       = response.data.data[0].site_type_of_tension
            this.model.site_approved_load         = response.data.data[0].site_approved_load
            this.model.site_mssl_account_number   = response.data.data[0].site_mssl_account_number
            this.model.site_target_turn_on_date   = response.data.data[0].site_target_turn_on_date
            this.model.site_actual_turn_on_date   = response.data.data[0].site_actual_turn_on_date

            this.model.site_plant_name              = response.data.data[0].site_plant_name
            this.model.site_folder_in_sftp_server   = response.data.data[0].site_folder_in_sftp_server
            this.model.site_monitoring_application  = response.data.data[0].site_monitoring_application
            this.model.site_inverter_type           = response.data.data[0].site_inverter_type
            this.model.site_inverter_qty            = response.data.data[0].site_inverter_qty
            this.model.site_total_inverter          = response.data.data[0].site_total_inverter
            this.model.site_total_data_logger       = response.data.data[0].site_total_data_logger
            this.model.site_logger_type             = response.data.data[0].site_logger_type

            this.model.site_start_date              = response.data.data[0].site_start_date
            this.model.site_end_date                = response.data.data[0].site_end_date
            this.model.site_contractor_start_date   = response.data.data[0].site_contractor_start_date
            this.model.site_contractor_end_date     = response.data.data[0].site_contractor_end_date
            this.model.site_elu_end_date            = response.data.data[0].site_elu_end_date
            this.model.site_el_expiry_date          = response.data.data[0].site_el_expiry_date

            this.model.group_engineer_email        = response.data.data[0].group.group_engineer.email

            this.model.site_module_brand.module_brand_id_name          = response.data.data[0].site_module_brand.module_brand_id_name
        }
      })
      .catch ( error => {
        console.log(error.response.status)
        this.errorFunction(error, "Project Site Information")
      } );
    },
    data() {
      return {
        button_access: {
          site_edit: null,
        },
        myUserID: null,
        model: {
          site_id: null,
          project_name: null,
          project_code: null,
          group_name: 'x',
          site_code: null,
          site_name: null,
          site_leader: 'x',
          site_postal_code: null,
          site_address: null,
          site_lead_contact: null,
          site_progress: 0,
          site_target_turn_on_date: null,
          site_actual_turn_on_date: null,
          milestone_id: 'x',
          site_module_quantity: 0,
          site_module_capacity: 0,
          site_total_capacity: 0,
          site_pr_capacity: 0,
          site_type_of_tension: null,
          site_approved_load: null,
          site_mssl_account_number: null,
          site_switchroom_tie: null,
          site_plant_name: null,
          site_folder_in_sftp_server: null,
          site_monitoring_application: 'Fusion Solar',
          site_inverter_type: null,
          site_inverter_qty: null,
          site_total_inverter: 1,
          site_total_data_logger: 0,
          site_logger_type: 'Huawei',
          site_start_date: null,
          site_end_date: null,
          site_contractor_start_date: null,
          site_contractor_end_date: null,
          site_elu_end_date: null,
          site_el_expiry_date: null,
          site_elu_license_number: null,
          site_elu_status: null,
          status_id: 'x',
          group_engineer_email: null,
          site_module_brand: {
            module_brand_id_name: null,
          },
        },
        projects: [],
        project_groups: [],
        site_status: [],
        site_leads: [],
        project_milestones: [],
      }
    },
    methods: {
      async submit() {
          this.$router.push('project')
      },

    }
  }
</script>
<style>
</style>
