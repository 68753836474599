<template>
  <div class="content">
    <base-header class="pb-6">
      <b-row align-v="center" class="py-4">
        <b-col cols="7" lg="8">
          <h6 class="h2 text-white d-inline-block mb-0">
            Project Document Approval Status
          </h6>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <route-bread-crumb></route-bread-crumb>
          </nav>
        </b-col>
        <b-col lg="4" cols="5" class="text-right">
          <base-button size="sm" type="neutral" @click="$router.back()"
            >Back</base-button
          >
        </b-col>
      </b-row>
    </base-header>
    <b-container fluid class="mt--6">
      <b-card no-body>
        <b-card-header class="border-0">
          <h3 class="mb-0" v-if="document_recurring_date == null">
            Document Approval Status for ( {{ title }} in
            {{ project_document }} )
          </h3>
          <h3 class="mb-0" v-else>
            Document Approval Status for ( {{ title }} in
            {{ project_document }} ), &nbsp;Recurring Date :
            {{ document_recurring_date }}
          </h3>
        </b-card-header>

        <el-table
          class="table-responsive table-flush"
          header-row-class-name="thead-light"
          :data="queriedData"
        >
          <el-table-column
            label="Require Approved From"
            min-width="100px"
            prop="name"
            sortable
          >
            <template v-slot="{ row }">
              <b-media no-body class="align-items-center">
                <b-media-body>
                  <span class="font-weight-600 name mb-0 text-sm">{{
                    row.approval_role.role_name
                  }}</span>
                </b-media-body>
              </b-media>
            </template>
          </el-table-column>

          <el-table-column label="Status" min-width="50px" prop="name" sortable>
            <template v-slot="{ row }">
              <b-media no-body class="align-items-center">
                <b-media-body>
                  <span class="font-weight-600 name mb-0 text-sm">{{
                    row.for_status.status_code
                  }}</span>
                </b-media-body>
              </b-media>
            </template>
          </el-table-column>

          <el-table-column
            label="Approved By"
            min-width="100px"
            prop="name"
            sortable
          >
            <template v-slot="{ row }">
              <b-media no-body class="align-items-center">
                <b-media-body>
                  <span class="font-weight-600 name mb-0 text-sm">{{
                    row.approval_by.name
                  }}</span>
                </b-media-body>
              </b-media>
            </template>
          </el-table-column>

          <el-table-column label="Code" min-width="100px">
            <template v-slot="{ row }">
              <b-media no-body class="align-items-center">
                <b-media-body>
                  <span class="font-weight-600 name mb-0 text-sm"
                    >Code:<br />{{ getCodeName(row.code_by_approval) }}</span
                  >
                </b-media-body>
              </b-media>
            </template>
          </el-table-column>

          <el-table-column
            label="Comment"
            min-width="180px"
            prop="status"
            sortable
          >
            <template v-slot="{ row }">
              <b-media no-body class="align-items-center">
                <b-media-body>
                  <span class="font-weight-600 name mb-0 text-sm">{{
                    row.approval_comments
                  }}</span>
                </b-media-body>
              </b-media>
            </template>
          </el-table-column>

          <el-table-column label="Action" min-width="80px">
            <div
              slot-scope="{ $index, row }"
              class="d-flex"
              v-if="login_user_role == row.approval_role.role_id"
            >
              <base-button
                size="sm"
                type="primary"
                @click.native="donwloadFile($index, row)"
                v-if="row.approval_attachments != null"
              >
                Attachment
              </base-button>

              <base-button
                size="sm"
                type="primary"
                @click.native="openModal($index, row)"
                v-if="row.code_by_approval == null"
              >
                Approval / Reject
              </base-button>
            </div>
            <div slot-scope="{ $index, row }" class="d-flex" v-else>
              <base-button
                size="sm"
                type="primary"
                @click.native="donwloadFile($index, row)"
                v-if="row.approval_attachments != null"
              >
                Attachment
              </base-button>
            </div>

            <!-- <div slot-scope="{$index, row}" class="d-flex" >
                    <base-button size="sm" type="primary" @click.native="donwloadFile($index, row)"
                    v-if="row.approval_attachments != null">
                    Attachment
                    </base-button>

                    <base-button size="sm" type="primary" @click.native="openModal($index, row)"
                    v-if="row.code_by_approval == null">
                    Approval / Reject
                    </base-button>
                </div> -->
          </el-table-column>
        </el-table>

        <!-- <b-card-footer class="py-4 d-flex justify-content-end">
              <base-pagination v-model="currentPage" :per-page="3" :total="6"></base-pagination>
          </b-card-footer> -->
      </b-card>
    </b-container>

    <modal :show.sync="modals.approval_reject" size="lg" body-classes="p-0">
      <card
        type="secondary"
        header-classes="bg-transparent pb-5"
        body-classes="px-lg-5 py-lg-5"
        class="border-0 mb-0"
      >
        <template>
          <div class="text-center text-muted mb-4">
            <h3>
              Approval / Reject ( {{ title }} ) by {{ model.approval_role }}
            </h3>
            <h3>Status : {{ model.status_code }}</h3>
          </div>
          <b-form role="form">
            <base-input
              label="Code For Status"
              name="Task Site"
              v-model="model.code_by_approval"
            >
              <select class="form-control" v-model="model.code_by_approval">
                <option value="0" disabled>-- Please Select A Code --</option>
                <option value="1">Code 1 : Approved</option>
                <option value="2">Code 2 : Approved With Comment</option>
                <option value="3">
                  Code 3 : Require Revision With Comment
                </option>
                <option value="4">Code 4 : Reject</option>
              </select>
            </base-input>
            <div
              v-if="
                model.code_by_approval == '2' || model.code_by_approval == '3'
              "
            >
              <base-input
                label="Comment"
                placeholder="Status Comment"
                name="Task Template"
                v-model="model.approval_comments"
              >
              </base-input>
              <label>
                <input
                  type="file"
                  id="file"
                  ref="file"
                  v-on:change="handleFileUpload()"
                />
              </label>
            </div>

            <h5 class="text-danger">
              Note : <br />
              <i>
                1. Only Code 2 & 3 allow to comment & Attachment on the status.
                <br />
                2. You can attach 1 document with maximum size of 500MB.
              </i>
            </h5>
            <div class="text-center">
              <base-button type="primary" class="my-4" @click="updateApproval()"
                >Update / Save</base-button
              >
              <base-button type="primary" class="my-4" @click="clearModal()"
                >Cancel</base-button
              >
            </div>
          </b-form>
        </template>
      </card>
    </modal>
  </div>
</template>


<script>
//import Approvals from './Approval'
import { Table, TableColumn, Select, Option } from "element-ui";
import RouteBreadCrumb from "@/components/Breadcrumb/RouteBreadcrumb";
import BaseHeader from "@/components/BaseHeader";
import standardPaginationMix from "@/assets/js/standardPaginationMix.js";
import {
  LoginURL,
  clientId,
  clientSecret,
  apiDomain,
  getHeader,
  getcurrentDate,
  getUserInfo,
  fileServer,
} from "@/assets/js/config.js";
import axios from "axios";
import { Modal } from "@/components";
import ErrorFunctionMixin from "@/assets/js/errorFunctionMix.js";

export default {
  mixins: [standardPaginationMix, ErrorFunctionMixin],
  components: {
    BaseHeader,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    RouteBreadCrumb,
    Modal,
  },
  created() {
    this.project_document_upload_id = this.$route.params.id;

    this.createData();
  },
  data() {
    return {
      tableColumns: [],
      tableData: [],
      selectedRows: [],
      code_type: 1,
      comment: null,
      currentPage: 1,
      title: null,
      login_user_role: null,
      project_document: null,
      project_document_upload_id: null,
      project_document_recurring_id: null,
      document_recurring_date: null,
      modals: {
        approval_reject: false,
      },
      model: {
        project_document_approval_id: null,
        project_document_upload_id: null,
        approval_role: null,
        status_code: null,
        code_by_approval: 1,
        approval_comments: null,
        approval_attachments: null,
      },
    };
  },
  methods: {
    getCodeName(code_by_approval_input) {
      var code_by_approval_output = "";
      if (code_by_approval_input == 1) {
        code_by_approval_output = "Approved";
      } else if (code_by_approval_input == 2) {
        code_by_approval_output = "Approved With Comment";
      } else if (code_by_approval_input == 3) {
        code_by_approval_output = "Require Revision With Comment";
      } else if (code_by_approval_input == 4) {
        code_by_approval_output = "Reject";
      } else {
        code_by_approval_output = code_by_approval_input;
      }
      return code_by_approval_output;
    },
    handleFileUpload() {
      this.model.approval_attachments = this.$refs.file.files[0];
    },
    createData() {
      this.model.project_document_upload_id = this.$route.params.id;
      var userInfo = getUserInfo();
      this.login_user_role = userInfo.role_id;

      axios
        .get(
          apiDomain +
            "document/project_document_approval.show_by_upload/" +
            this.model.project_document_upload_id,
          { headers: getHeader() }
        )
        .then((response) => {
          if (response.status === 200) {
            this.tableData = response.data.data;
            this.title =
              response.data.data[0].project_document_upload.document_version;
            this.project_document =
              response.data.data[0].project_document.document_title;
            if (
              response.data.data[0].project_document_recurring
                .project_document_recurring_id != null
            ) {
              this.project_document_recurring_id =
                response.data.data[0].project_document_recurring.project_document_recurring_id;
              axios
                .get(
                  apiDomain +
                    "document/project_document_recurring/" +
                    this.project_document_recurring_id,
                  { headers: getHeader() }
                )
                .then((response) => {
                  if (response.status === 200) {
                    this.document_recurring_date =
                      response.data.data[0].show_document_recurring_date;
                  }
                })
                .catch((error) => {
                  this.errorFunction(
                    error,
                    "Project Document Recurring Information"
                  );
                });
            }
          }
        })
        .catch((error) => {
          this.errorFunction(error, "Project Document Approval Listing");
        });
    },
    openModal(index, row) {
      this.model.project_document_approval_id =
        row.project_document_approval_id;
      this.model.approval_role = row.approval_role.role_name;
      this.model.status_code = row.for_status.status_code;
      this.modals.approval_reject = true;
    },
    updateApproval() {
      const formDataAttach = new FormData();

      if (this.model.approval_attachments != null) {
        formDataAttach.append(
          "approval_attachments",
          this.model.approval_attachments
        );
      }

      if (this.model.approval_comments != null) {
        formDataAttach.append(
          "approval_comments",
          this.model.approval_comments
        );
      }

      formDataAttach.append(
        "project_document_approval_id",
        this.model.project_document_approval_id
      );
      formDataAttach.append("code_by_approval", this.model.code_by_approval);

      const tokenData = JSON.parse(window.localStorage.getItem("authUser"));
      const headers = {
        Accept: "application/json",
        "Content-Type": "multipart/form-data",
        Authorization: "Bearer " + tokenData.access_token,
      };

      axios
        .post(
          apiDomain + "document/project_document_approval",
          formDataAttach,
          { headers }
        )
        .then((response) => {
          if (response.status === 200) {
            this.modals.approval_reject = false;
            this.$notify({
              message:
                "<b>Update Project Document Status : </b> - Successfully Updated.",
              timeout: 10000,
              icon: "ni ni-bell-55",
              type: "default",
            });
            this.clearModal();
            this.createData();
          } else {
            this.$notify({
              message:
                "<b>Failed to save uploaded file</b> - Please repair your file and reupload.",
              timeout: 10000,
              icon: "ni ni-bulb-61",
              type: "danger",
            });
          }
        })
        .catch((error) => {
          this.errorFunction(error, "Update Project Document Approval Status");
        });
    },
    donwloadFile(index, row) {
      // console.log(row.project_document_approval_id)
      axios
        .get(
          fileServer +
            "document/project_document_approval.download/" +
            row.project_document_approval_id,
          { headers: getHeader(), responseType: "blob" }
        )
        .then((response) => {
          var fileURL = window.URL.createObjectURL(new Blob([response.data]));
          var fileLink = document.createElement("a");

          fileLink.href = fileURL;
          fileLink.setAttribute("download", row.approval_attachments);
          document.body.appendChild(fileLink);

          fileLink.click();
        });
    },
    clearModal() {
      this.model.project_document_approval_id = null;
      this.model.project_document_upload_id = null;
      this.model.code_by_approval = 1;
      this.model.approval_comments = null;
      this.model.approval_attachments = null;
      this.model.approval_role = null;
      this.modals.approval_reject = false;
    },
  },
};
</script>
<style></style>
