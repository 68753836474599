<template>
  <div>
    <base-header class="pb-6">
      <b-row class="py-4">
        <b-col cols="6">
          <h6 class="h2 text-white d-inline-block mb-0">{{ title }}</h6>
        </b-col>
        <b-col cols="6" class="text-right">
          <base-button size="sm" type="neutral" @click="$router.replace('Listing')">Back</base-button>
        </b-col>
      </b-row>
    </base-header>

    <b-container fluid class="mt--6">
      <div class="card-wrapper">
        <card>
          <!-- Card header -->
          <h3 slot="header" class="mb-0">New Template</h3>
          <validation-observer v-slot="{ handleSubmit }">
            <form class="needs-validation" @submit.prevent="handleSubmit(onSubmit)">

              <b-form-row>
                <b-col>
                  <validation-provider name="Name" rules="required" v-slot="{ classes, errors }">
                    <b-form-group label="Name" :invalid-feedback="errors[0]">
                      <input type="text" class="form-control" :class="classes" v-model="model.name" maxlength="100">
                    </b-form-group>
                  </validation-provider>
                </b-col>
              </b-form-row>

              <b-form-row>
                <b-col>
                  <br>
                  <validation-provider name="Main Category Name" rules="required" v-slot="{ classes, errors }">
                    <b-form-group label="Main Category Name" :invalid-feedback="errors[0]">
                      <input type="text" class="form-control" :class="classes" v-model="model.category1_name" maxlength="100">
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col>
                  <br>
                  <validation-provider name="Sub Category Name" rules="required" v-slot="{ classes, errors }">
                    <b-form-group label="Sub Category Name" :invalid-feedback="errors[0]">
                      <input type="text" class="form-control" :class="classes" v-model="model.category2_name" maxlength="100">
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col>
                  <validation-provider name="category3_enabled">
                    <b-form-checkbox v-model="model.category3_enabled" :value="1" :unchecked-value="0">Enable Third Category</b-form-checkbox>
                  </validation-provider>
                  <validation-provider name="Third Category Name" rules="required_if:category3_enabled,1" v-slot="{ classes, errors }" v-show="model.category3_enabled">
                    <b-form-group label="Third Category Name" :invalid-feedback="errors[0]">
                      <input type="text" class="form-control" :class="classes" v-model="model.category3_name" maxlength="100">
                    </b-form-group>
                  </validation-provider>
                </b-col>
              </b-form-row>
              <br>

              <b-form-row>
                <b-col>
                  <b-form-checkbox v-model="model.status" :value="1" :unchecked-value="0">
                    <b>Active Status</b>
                  </b-form-checkbox>
                </b-col>
              </b-form-row>
              <br>

              <button class="btn btn-primary" v-if="access.create">Create</button>
              <button class="btn btn-primary" type="button" @click="$router.replace('Tracking')">Cancel</button>
            </form>
          </validation-observer>
        </card>
      </div>
    </b-container>
  </div>
</template>

<script>
import ErrorFunctionMixin from '@/assets/js/errorFunctionMix.js'
import axios from '@/util/axios'
import { getAccessByModule } from '@/util/access'

export default {
  mixins: [ErrorFunctionMixin],

  data() {
    return {
      title: 'Document Tracking Templates',
      model: {
        category3_enabled: 0,
        status: 1,
      },
      access: {},
    }
  },

  created() {
    this.access = getAccessByModule('DOC_REPO')
  },

  methods: {
    onSubmit() {
 
      axios.post('setup/doc_repo/template', this.model)
        .then(response => {
          this.$notify({
            message: 'New Template Created',
            timeout: 10000,
            icon: 'ni ni-bell-55',
            type: 'default',
          });
          
          this.$router.replace('Tracking')
        })
        .catch(error => {
          this.errorFunction(error, this.title)
        });
    },
  },
}
</script>
