<template>
  <div class="content">
    <base-header class="pb-6">
      <b-row align-v="center" class="py-4">
        <b-col cols="7" lg="6">
          <h6 class="h2 text-white d-inline-block mb-0">Document Listing</h6>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <route-bread-crumb></route-bread-crumb>
          </nav>
        </b-col>
        <b-col lg="6" cols="5" class="text-right">
          <router-link to="/documentNew">
            <base-button size="sm" type="neutral" >New Document</base-button>
          </router-link>
        </b-col>
      </b-row>
    </base-header>
    <b-container fluid class="mt--6">
      <div>
        <card class="no-border-card" body-classes="px-0 pb-1" footer-classes="pb-2">
          <template slot="header">
            <h3 class="mb-0">Document Listing</h3>
          </template>
          <div>
            <b-col cols="12" class="d-flex justify-content-center justify-content-sm-between flex-wrap">
              <el-select
                class="select-primary pagination-select"
                v-model="pagination.perPage"
                placeholder="Per page">
                <el-option
                  class="select-primary"
                  v-for="item in pagination.perPageOptions"
                  :key="item"
                  :label="item"
                  :value="item">
                </el-option>
              </el-select>

              <div>
                <base-input v-model="searchQuery"
                            prepend-icon="fas fa-search"
                            placeholder="Search...">
                </base-input>
              </div>
            </b-col>
            <b-col><br></b-col>
            <el-table :data="queriedData"
                      row-key="project_id"
                      header-row-class-name="thead-light"
                      @sort-change="sortChange">

              <el-table-column label="Information"
                             min-width="160px"
                             prop="document_classification_code"
                             sortable>
                <template v-slot="{row}">
                    <b-media no-body class="align-items-center">
                        <b-media-body>
                            <span class="font-weight-600 name mb-0 text-sm">{{row.document_information}}</span>
                        </b-media-body>
                    </b-media>
                </template>
              </el-table-column>

              <!-- <el-table-column label="Title"
                             min-width="180px"
                             prop="document_title"
                             sortable>
                <template v-slot="{row}">
                    <b-media no-body class="align-items-center">
                        <b-media-body>
                            <span class="font-weight-600 name mb-0 text-sm">{{row.document_title}}</span>
                        </b-media-body>
                    </b-media>
                </template>
              </el-table-column> -->

              <el-table-column label="Site"
                             min-width="130px"
                             prop="site.site_name"
                             sortable>
                <template v-slot="{row}">
                    <b-media no-body class="align-items-center">
                        <b-media-body>
                            <span class="font-weight-600 name mb-0 text-sm">P: {{row.project.project_name}}</span>
                        </b-media-body>
                    </b-media>
                    <b-media no-body class="align-items-center">
                        <b-media-body>
                            <span class="font-weight-600 name mb-0 text-sm">G: {{row.group.group_name}}</span>
                        </b-media-body>
                    </b-media>
                    <b-media no-body class="align-items-center">
                        <b-media-body>
                            <span class="font-weight-600 name mb-0 text-sm">S: {{row.site.site_name}}</span>
                        </b-media-body>
                    </b-media>
                </template>
              </el-table-column>

              <el-table-column label="Doc. Type"
                             min-width="110px"
                             prop="document_type.document_type_code"
                             sortable>
                <template v-slot="{row}">
                    <b-media no-body class="align-items-center">
                        <b-media-body>
                            <span class="font-weight-600 name mb-0 text-sm">{{row.document_type.document_type_code}}</span>
                        </b-media-body>
                    </b-media>
                </template>
              </el-table-column>

              <el-table-column label="Milestone"
                             min-width="120px"
                             prop="milestone.milestone_code"
                             sortable>
                <template v-slot="{row}">
                    <b-media no-body class="align-items-center">
                        <b-media-body>
                            <span class="font-weight-600 name mb-0 text-sm">{{row.milestone.milestone_code}}</span>
                        </b-media-body>
                    </b-media>
                </template>
              </el-table-column>

              <el-table-column label="Last Update & Status"
                           min-width="170px"
                             prop="updated_at"
                             sortable>
                <template v-slot="{row}">
                   <div class="d-flex align-items-center">
                        <div>
                          <span class="font-weight-600 name mb-0 text-sm">Mandatory: {{row.document_mandatory}} </span>
                        </div>
                    </div>
                    <div class="d-flex align-items-center">
                        <div>
                          <span class="font-weight-600 name mb-0 text-sm">Version: V-{{row.document_version}} </span>
                        </div>
                    </div>
                    <b-media no-body class="align-items-center">
                        <b-media-body>
                            <badge class="badge-dot mr-4" type="">
                              <i v-if="row.status.status_code == 'Pending-Upload'" class="bg-danger"></i>
                              <i v-else-if="row.status.status_code == 'Require-Revision'" class="bg-danger"></i>
                              <i v-else-if="row.status.status_code == 'Pending-Approval'" class="bg-success"></i>
                              <i v-else-if="row.status.status_code == 'Approved'" class="bg-success"></i>
                              <i v-else class="bg-info"></i>
                              <span class="font-weight-600 name mb-0 text-sm">{{row.status.status_code}}</span>
                            </badge>
                        </b-media-body>
                    </b-media>
                    <div class="d-flex align-items-center">
                        <div>
                          <span class="font-weight-600 name mb-0 text-sm">Last Update : {{row.updated_at}} </span>
                        </div>
                    </div>
                </template>
              </el-table-column>

              <el-table-column min-width="145px" align="right" label="Actions">
                <div slot-scope="{$index, row}" class="d-flex">
                  <base-button
                    @click.native="handleDetails($index, row)" size="sm" type="primary">
                    View / Edit
                  </base-button>
                  <base-button
                    @click.native="documentDetails(index, row)" size="sm" type="primary">
                    Doc. Details
                  </base-button>
                </div>
              </el-table-column>
            </el-table>
          </div>
          <div
            slot="footer"
            class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap">
            <div class="">
              <p class="card-category">
                Showing {{ from + 1 }} to {{ to }} of {{ total }} entries

                <span v-if="selectedRows.length">
                  &nbsp; &nbsp; {{selectedRows.length}} rows selected
                </span>
              </p>
            </div>
            <base-pagination
              class="pagination-no-border"
              :current="pagination.currentPage"
              :per-page="pagination.perPage"
              :total="total"
              @change="paginationChanged($event)">
            </base-pagination>
             <!-- <div>
              <h5 class="text-danger"> Note :
                  <i>
                  Please Create at least ONE Project Template & ONE Project Template Details before you can proceed POPULATE / IMPORT to prevent any Error. Go to NEW Template Then "Detail Listing" >> "New Template Details".
                  </i>
              </h5>
            </div> -->
          </div>
        </card>
      </div>
    </b-container>
    </div>
</template>
<script>
import { Table, TableColumn, Select, Option } from 'element-ui';
import RouteBreadCrumb from '@/components/Breadcrumb/RouteBreadcrumb'
import { BasePagination } from '@/components';
import standardPaginationMix from '@/assets/js/standardPaginationMix.js'
import Fuse from 'fuse.js';
import axios from 'axios'
import {LoginURL, clientId, clientSecret, apiDomain, getHeader} from '@/assets/js/config.js'
import ErrorFunctionMixin from '@/assets/js/errorFunctionMix.js'

export default {
  mixins: [standardPaginationMix, ErrorFunctionMixin],
  components: {
    BasePagination,
    RouteBreadCrumb,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn
  },
  created()
  {
      this.getCreateData()
  },
  data() {
    return {
      propsToSearch:
      [
        'document_classification_code',
        'project.project_name',
        'group.group_name',
        'site.site_name',
        'milestone.milestone_code',
        'document_type.document_type_code',
        'document_version',
        'document_mandatory',
        'status.status_code',
        'updated_at',
      ],
      tableColumns: [],
      tableData: [],
      selectedRows: []
    };
  },
  methods: {
    getCreateData()
    {
        axios.get(apiDomain + 'document/document', { headers: getHeader() })
        .then ( response => {
        if (response.status === 200)
        {
            this.tableData = response.data.data
            this.fuseData()
        }
        })
        .catch ( error => {
        console.log(error.response.status)
        this.errorFunction(error, "Document Information")
        });
    },
    fuseData()
    {
        this.fuseSearch = new Fuse(this.tableData, {
        keys: this.propsToSearch,
        threshold: 0.3
        });
    },
    paginationChanged(page)
    {
        this.pagination.currentPage = page
    },
    handleDetails(index, row)
    {
        this.$router.push({path: 'documentEdit/' + row.document_id })
    },
    documentDetails(index, row)
    {
        this.$router.push({path: 'documentDetailsListing/' + row.document_id })
    },
    selectionChange(selectedRows)
    {
        this.selectedRows = selectedRows
    }
  }
};
</script>
<style>
  .no-border-card .card-footer{
    border-top: 0;
  }
</style>
