<!-- 
https://docs.dropzone.dev/configuration/basics/configuration-options 
-->

<template>
  <div class="dropzone mb-3 dz-clickable" :class="[multiple ? 'dropzone-multiple' : 'dropzone-single']">
    <div class="fallback">
      <div class="custom-file">
        <input type="file" class="custom-file-input" id="projectCoverUploads" :multiple="multiple">
        <label class="custom-file-label" for="projectCoverUploads">Choose file</label>
      </div>
    </div>
    <br>
    <div class="dz-preview dz-preview-single" v-if="!multiple" :class="previewClasses" ref="previewSingle">
      <div class="dz-preview-cover">
        <img class="dz-preview-img" data-dz-thumbnail>
      </div>
    </div>

    <b-list-group v-else flush="" class="dz-preview dz-preview-multiple list-group-lg " :class="previewClasses"
      ref="previewMultiple" style="display: flex; flex-direction: row; justify-content: center; border-radius: 5px; background-color: #23454a;">
      <!-- style="display: none;"  -->
      <b-list-group-item class="px-1">
        <b-row class="align-items-center mb-3">
          <b-col md="12" class="text-center">
            <div>
              <b-img rounded width="100px" height="100px" data-dz-thumbnail />
            </div>
          </b-col>
          <!-- <b-col md="9" class="ml--3 mb-3">
            <h4 class="mb-1 text-white" data-dz-name>...</h4>
            <b-form-input placeholder="Observation Photo Remarks"></b-form-input>

          </b-col> -->
          <b-col md="12" class="text-center" style="padding-top: 5px;">
            <!-- <b-link class="btn btn-sm btn-secondary rounded-pill text-white">
              <div class="icon-pencil-cs ml-1 mr-1"></div>
            </b-link> -->
            <b-link class="btn btn-sm btn-secondary rounded-pill text-white" data-dz-remove="true">
              <span style="padding: 10px 5px 5px 5px">
                <svg width="20" height="26" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M17 6H22V8H20V21C20 21.5523 19.5523 22 19 22H5C4.44772 22 4 21.5523 4 21V8H2V6H7V3C7 2.44772 7.44772 2 8 2H16C16.5523 2 17 2.44772 17 3V6ZM9 11V17H11V11H9ZM13 11V17H15V11H13ZM9 4V6H15V4H9Z"
                    fill="#FF5C00" />
                </svg>
              </span>
            </b-link>
            <!-- <b-button data-dz-remove="true" variant="danger" size="sm">
              <i class="fas fa-trash"></i>
            </b-button> -->
          </b-col>
          <br>
        </b-row>
      </b-list-group-item>
    </b-list-group>
  </div>
</template>
<script>
export default {
  name: 'dropzone-file-upload',
  props: {
    options: {
      type: Object,
      default: () => ({})
    },
    value: [String, Object, Array],
    url: {
      type: String,
      default: 'http://localhost',
      // default: null,
    },
    multiple: Boolean,
    previewClasses: [String, Object, Array]
  },
  model: {
    prop: 'value',
    event: 'change'
  },
  data() {
    return {
      currentFile: null,
      files: [],
      remarks: [],
      showList: false,
    }
  },
  expose: ['customTest'],
  methods: {
    async initDropzone() {
      let Dropzone = await import('dropzone')
      Dropzone = Dropzone.default || Dropzone
      Dropzone.autoDiscover = false
      let preview = this.multiple ? this.$refs.previewMultiple : this.$refs.previewSingle;
      let self = this
      let finalOptions = {
        ...this.options,
        id: 'customDropzone', 
        autoProcessQueue: false,
        url: this.url,
        // url: null,
        thumbnailWidth: null,
        thumbnailHeight: null,
        previewsContainer: preview,
        previewTemplate: preview.innerHTML,
        // maxFiles: (!this.multiple) ? 1 : null,
        maxFiles: 5,
        paramName: 'file',
        // acceptedFiles: (!this.multiple) ? 'image/*' : null,
        acceptedFiles: 'image/*',
        init: function () {
          this.on("addedfile", function (file) {
            // console.log('this')
            if (!self.multiple && self.currentFile) {
              // this.removeFile(this.currentFile);
            }
            self.currentFile = file;
          }),
          this.on("resetFiles", function () {
            this.removeAllFiles(true);
            return this.emit("reset");
          }),
          this.on("maxfilesexceeded", function () {
            // console.log('maxfilesexceeded');
            // console.log(this.files);
            // console.log(this.files.length);
            // let iStart = finalOptions.maxFiles;
            // let iEnd = this.files.length;
            // console.log(iStart + ' <> ' + iEnd)

            // for (var i = iStart; i <= iEnd; i++) {
            //   console.log('pos: ' + i)
            //   var f = this.files[i];
            //   // this.files.splice(i, 1);
            //   // console.log(f);
            //   // this.removeFile(f)
            // }

            // console.log(this.files);
            // this.removeAllFiles(true);
            // return this.emit("reset");
          });
        }
      }
      this.dropzone = new Dropzone(this.$el, finalOptions)
      preview.innerHTML = ''
      let evtList = ['drop', 'dragstart', 'dragend', 'dragenter', 'dragover', 'addedfile', 'removedfile', 'thumbnail', 'error', 'processing', 'uploadprogress', 'sending', 'success', 'complete', 'canceled', 'maxfilesreached', 'maxfilesexceeded', 'maxfiles', 'processingmultiple', 'sendingmultiple', 'successmultiple', 'completemultiple', 'canceledmultiple', 'totaluploadprogress', 'reset', 'queuecomplete']
      evtList.forEach(evt => {
        this.dropzone.on(evt, (data) => {
          this.$emit(evt, data);

          // console.log(evt);
          // console.log(data);

          if (evt === 'addedfile') {
            // console.log('that')

            // console.log(this.files);

            // if ( this.files.length <= finalOptions.maxFiles) {
            //   console.log('here')
            //   this.files.push(data);
            //   this.$emit('change', this.files);
            // } else {
            //   console.log('there')
            //   for (var i = this.files.length - finalOptions.maxFiles -1; i >= 0; i--) {
            //     var f = this.files[i];
            //     if (f.upload.uuid !== data.upload.uuid)
            //     this.files.splice(i, 1);
            //       // this.files.removeFile(f);
            //   }
            // }

            
            // console.log(finalOptions.maxFiles);

            this.files.push(data);
            this.remarks.push([]);
            this.$emit('change', this.files);
          } else if (evt === 'removedfile') {
            let index = this.files.findIndex(f => f.upload.uuid === data.upload.uuid)
            if (index !== -1) {
              this.files.splice(index, 1);
            }
            this.$emit('change', this.files);
          } else if (evt === 'maxfilesreached') {
            // console.log('max neh')
          }
        })
      })
    },
    // async customTest() {
    //   console.log('customTest dari child');
    //   // this.$refs.previewMultiple.removeAllFiles(true);
    // }
  },
  async mounted() {
    this.initDropzone()
  }
}
</script>

<style>
.dz-message {
  background-color: #23454A;
}
</style>

<style scoped>
.list-group-item {
  background-color: #23454A;
  border-bottom: 1px solid black;
}

.form-control {
  background-color: #2C545A !important;
  border-color: #326168;
  color: white;

  &:focus {
    background-color: #2C545A;
    border-color: #2b525800;
    color: white;
  }

  &::placeholder {
    color: white;
  }
}

.form-control {
  &:focus {
    &::placeholder {
      color: white;
    }
  }
}

.input-group-text {
  background-color: #2C545A;
  ;
  color: white;

  &:focus {
    background-color: #2C545A;
    ;
    border-color: #1D4147;
    color: white;
  }
}

.focused {
  .input-group-text {
    background-color: #2C545A;
  }
}

.bg-card-dashboard {
  background-color: #23454A;

}
</style>
