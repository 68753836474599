<!-- NOTES -->
<!-- [done] 1. for now using old separator that is , (comma) instead of | between date and time, cause it's coded like that, we'll going to change it to new UI later -->
<!-- [done] 2. google maps still using the old way too, we'll look into it later on for using the new one that propose by pmn, cause it's using different addon, so we might need to recode to make it work. so right now change the map type into ROADMAP, instead of HYBRID -->
<!-- [done] 3. icon for Total Site Turn On need to be replace with proper one -->
<!-- [done] 4. recode mid dashboard (total capacity | total turn on | total site turn on) -->
<!-- [done] 5. recode bottom dashboard (observation | finding) -->
<!-- 6. note: that the way populating the graph data right now using api and get list data, and then calculating it according to the needs, it suppose to be hit an api that supplying summarize data and then use and display it on the dashboard -->
<!-- [done] 7. try using darker theme for google map -->
<!-- 8. click on "Dashboard" in the marker, still going no where 
    note: temporary fix (hardcoded), currently the site in the marker not providing the project code
    so this method tmp_getProjectCodeByName() providing the project code based on the project title.

    !important need to be fix permanently, by updating the API dashboard/map_dashboard_site_data, aside get the site data also need to return project_code
-->
<!-- [done] 9. updated the marker info window to open 1 instance only -->

<!-- 
changelog: added markers cluster
    dependency: npm install --save @googlemaps/markerclusterer	
-->

<template>
    <div class="bg-default-dashboard">
        <!-- <div class=" py-lg-8 pt-lg-5"> -->
        <div class=" py-7 py-lg-8 pt-lg-5">
            <b-container fluid style="width: 90%; position: relative;">
                <!-- filters -->
                <b-row>
                    <b-col md="3">
                        <span class="h2 text-white"><strong>{{ time }}</strong> </span>
                        <!-- <span class="h1 text-muted">|</span><span class="lead text-white"> 13:32:51</span> -->
                    </b-col>
                    <b-col md="2" xs="12" sm="12" style="margin-top: 2px;">
                        <b-row>
                            <select class="form-control" v-model="selectedGmapSiteStatus" @change="updateGmapSiteStatus()">
                                <option value="all">-- All --</option>
                                <option value="in_progress">In Progress</option>
                                <option value="completed">Completed</option>
                            </select>
                        </b-row>
                    </b-col>
                    <b-col md="4" xs="12" sm="12" style="margin-top: 2px;">
                        <b-row>
                            <b-col md="7" offset="5">
                                <b-form-datepicker id="filter-datepicker" v-model="model.data_date" name="Data Date"
                                    type="date" @input="changeAllChart()" style="color: #6d96a5 !important;">
                                </b-form-datepicker>
                            </b-col>
                        </b-row>
                    </b-col>
                    <b-col md="3" sm="6" class="mt-0">
                        <base-input name="Developer" v-model="model.developer_id">
                            <select class="form-control" v-model="model.developer_id" @change="changeAllChart()">
                                <option v-for="item in developers" :key="item.developer_id" :value="item.developer_id">
                                    {{ item.developer_name }}
                                </option>
                            </select>
                        </base-input>
                    </b-col>
                </b-row>
                <!-- maps -->
                <b-row>
                    <b-col>
                        <b-card no-body class="border-0">
                            <div id="map-custom" class="map-canvas" style="height: 600px;"></div>
                        </b-card>
                    </b-col>
                </b-row>
                <!-- dashboard total capacity | total turn on | total site turn on -->
                <b-row>
                    <b-col>
                        <card class="bg-card-dashboard">
                            <b-row>
                                <b-col>
                                    <b-row>
                                        <b-col class="text-right">
                                            <img src="img/theme/dashboard/solar-panel.png" width="70px" alt="">
                                        </b-col>
                                        <b-col>
                                            <div class="text-white h4 mb-1"> Total Capacity</div>
                                            <span class="text-teal h1">{{ total_data.total_capacity_mwp }}</span> <span
                                                class="text-white"><small>Mwp</small></span>
                                        </b-col>
                                    </b-row>
                                </b-col>
                                <b-col>
                                    <b-row style="border-left: 1px solid #939393; border-right: 1px solid #939393;">
                                        <b-col class="text-right">
                                            <img src="img/theme/dashboard/sun-energy.png" width="70px" alt="">
                                        </b-col>
                                        <b-col>
                                            <div class="text-white h5"> Total Turn On</div>
                                            <span class="text-teal h1">{{ total_data.total_turn_on_capacity_mwp
                                                }}</span> <span class="text-white"><small>Mwp</small></span>
                                        </b-col>
                                    </b-row>
                                </b-col>
                                <b-col>
                                    <b-row>
                                        <b-col class="text-right">
                                            <img src="img/theme/dashboard/location.png" width="70px" alt="">
                                        </b-col>
                                        <b-col>
                                            <div class="text-white h5"> Total Site Turn On</div>
                                            <span class="text-teal h1">{{ total_data.total_site_turn_on }}</span>
                                        </b-col>
                                    </b-row>
                                </b-col>
                            </b-row>
                        </card>
                    </b-col>
                </b-row>
                <!-- charts: negative/positive observations | open closed findings -->
                <b-row>
                    <b-col xl="6">
                        <card class="bg-card-dashboard">
                            <div class="text-center text-white h3"> Negative/Positive Observations</div>
                            <b-row class="justify-content-md-center mt-3">
                                <b-col md="8" offset="2">
                                    <div class="d-flex text-white">
                                        <div
                                            style="width: 30px; height: 20px; background-color: #5ABAB9; border-radius: 8px;">
                                        </div>
                                        <div class="ml-1 mr-3">Positives (ObsTypes)</div>
                                        <div
                                            style="width: 30px; height: 20px; background-color: #3F92D3; border-radius: 8px;">
                                        </div>
                                        <div class="ml-1">Negative (ObsTypes)</div>
                                    </div>
                                </b-col>
                            </b-row>
                            <!-- <highcharts :options="chartObservations"></highcharts> -->
                            <vue-highcharts :options="chartOptionObservations" ref="chartObservations"></vue-highcharts>
                        </card>
                    </b-col>
                    <b-col xl="6">
                        <card class="bg-card-dashboard">
                            <div class="text-center text-white h3"> Open Closed Findings</div>
                            <b-row class="justify-content-md-center mt-3">
                                <b-col md="8">
                                    <div class="d-flex ml-6 text-white">
                                        <div
                                            style="width: 30px; height: 20px; background-color: #5ABAB9; border-radius: 8px;">
                                        </div>
                                        <div class="ml-1 mr-3">Findings Close</div>
                                        <div
                                            style="width: 30px; height: 20px; background-color: #3F92D3; border-radius: 8px;">
                                        </div>
                                        <div class="ml-1">Findings Open</div>
                                    </div>
                                </b-col>
                            </b-row>
                            <!-- <highcharts :options="chartOptionsFinding"></highcharts> -->
                            <vue-highcharts :options="chartOptionsFinding"></vue-highcharts>
                        </card>
                    </b-col>
                </b-row>
            </b-container>
        </div>
    </div>
</template>

<script>
import { Table, TableColumn, Select, Option } from 'element-ui';

import {
    apiDomain,
    getHeader,
    getcurrentDate,
} from "@/assets/js/config.js";
import axios from "axios";
import ErrorFunctionMixin from "@/assets/js/errorFunctionMix.js";
import Card from "../../../components/Cards/Card.vue";

import { API_KEY } from '@/views/Maps/API_KEY';
import GoogleMapsLoader from 'google-maps';
import { MarkerClusterer } from "@googlemaps/markerclusterer";
GoogleMapsLoader.KEY = API_KEY;

import VueHighcharts from 'vue2-highcharts'
import moment from 'moment'
import { ToggleButton } from 'vue-js-toggle-button'

export default {
    mixins: [ErrorFunctionMixin],
    components: {
        [Select.name]: Select,
        [Option.name]: Option,
        [Table.name]: Table,
        [TableColumn.name]: TableColumn,
        VueHighcharts,
    },
    beforeDestroy() {
        // prevent memory leak
        clearInterval(this.interval);
        clearInterval(this.dataDateInterval);
    },
    created() {
        // update the time every second
        this.interval = setInterval(() => {
            // Concise way to format time according to system locale.
            // In my case this returns "3:48:00 am"
            this.time = Intl.DateTimeFormat('en-GB', {
                day: '2-digit',
                month: 'short',
                year: 'numeric',
                hour: '2-digit',
                minute: '2-digit',
                second: '2-digit',
                hour12: false,
            }).format().replace(', ', ' | ');
        }, 1000)

        // Schedule checkAndUpdateDataDate every 10 minutes
        this.dataDateInterval = setInterval(() => {
            this.checkAndUpdateDataDate();
        }, 600000); // 10 minutes in milliseconds

        this.getDeveloperOptions();
        var currentDate = getcurrentDate();
        this.model.data_date = currentDate;
        this.changeAllChart();
    },
    computed: {
    },
    data() {
        return {
            interval: null,
            time: null,
            dataDateInterval: null,
            developers: [],
            model: {
                // developer_id: null,
                developer_id: 1,
                data_date: null,
            },
            // modalTitle: "On-Going C&I Projects Detailed",
            gmapMarkers: [],
            gmapLastInfoWindow: null,
            total_data: {
                total_capacity_mwp: null,
                total_turn_on_capacity_mwp: null,
                total_site: null,
            },
            chartOptionObservations: null,
            chartOptionsFinding: null,
            selectedFilter: 5,
            file_uploads: true,
            selectedGmapSiteStatus: 'all',
        };
    },
    methods: {
        updateGmapSiteStatus() {
            // console.log('selectedGmapSiteStatus');
            // console.log(this.selectedGmapSiteStatus);
            this.reloadMap();
        },
        checkAndUpdateDataDate() {
            const currentDate = getcurrentDate();
            if (this.model.data_date !== currentDate) {
                this.model.data_date = currentDate;
                this.changeAllChart();
            }
        },
        loadGoogleMap() {
            GoogleMapsLoader.load(google => {
                this.initMap(google);
            });
        },
        initMap(google) {
            let map, lat = 1.350037, lng = 103.809228, color = "#5e72e4", zoomLevel = 12;
            if (this.model.developer_id == 1) {
                lat = 1.350037;
                lng = 103.809228;
                zoomLevel = 12;
            } else if (this.model.developer_id == 2) {
                lat = -2.381462;
                lng = 107.231926;
                zoomLevel = 5;
            }
            map = document.getElementById('map-custom');

            let myLatlng = new google.maps.LatLng(lat, lng);
            let mapOptions = {
                //   zoom: 12,
                zoom: zoomLevel,
                center: myLatlng,
                //   mapTypeId: google.maps.MapTypeId.HYBRID,
                mapTypeId: google.maps.MapTypeId.ROADMAP,
                styles: [
                    {
                        "elementType": "geometry",
                        "stylers": [
                            {
                                "color": "#242f3e"
                            }
                        ]
                    },
                    {
                        "elementType": "labels.text.fill",
                        "stylers": [
                            {
                                "color": "#746855"
                            }
                        ]
                    },
                    {
                        "elementType": "labels.text.stroke",
                        "stylers": [
                            {
                                "color": "#242f3e"
                            }
                        ]
                    },
                    {
                        "featureType": "administrative.locality",
                        "elementType": "labels.text.fill",
                        "stylers": [
                            {
                                "color": "#d59563"
                            }
                        ]
                    },
                    {
                        "featureType": "poi",
                        "elementType": "labels.text.fill",
                        "stylers": [
                            {
                                "color": "#d59563"
                            }
                        ]
                    },
                    {
                        "featureType": "poi.park",
                        "elementType": "geometry",
                        "stylers": [
                            {
                                "color": "#263c3f"
                            }
                        ]
                    },
                    {
                        "featureType": "poi.park",
                        "elementType": "labels.text.fill",
                        "stylers": [
                            {
                                "color": "#6b9a76"
                            }
                        ]
                    },
                    {
                        "featureType": "road",
                        "elementType": "geometry",
                        "stylers": [
                            {
                                "color": "#38414e"
                            }
                        ]
                    },
                    {
                        "featureType": "road",
                        "elementType": "geometry.stroke",
                        "stylers": [
                            {
                                "color": "#212a37"
                            }
                        ]
                    },
                    {
                        "featureType": "road",
                        "elementType": "labels.text.fill",
                        "stylers": [
                            {
                                "color": "#9ca5b3"
                            }
                        ]
                    },
                    {
                        "featureType": "road.highway",
                        "elementType": "geometry",
                        "stylers": [
                            {
                                "color": "#746855"
                            }
                        ]
                    },
                    {
                        "featureType": "road.highway",
                        "elementType": "geometry.stroke",
                        "stylers": [
                            {
                                "color": "#1f2835"
                            }
                        ]
                    },
                    {
                        "featureType": "road.highway",
                        "elementType": "labels.text.fill",
                        "stylers": [
                            {
                                "color": "#f3d19c"
                            }
                        ]
                    },
                    {
                        "featureType": "transit",
                        "elementType": "geometry",
                        "stylers": [
                            {
                                "color": "#2f3948"
                            }
                        ]
                    },
                    {
                        "featureType": "transit.station",
                        "elementType": "labels.text.fill",
                        "stylers": [
                            {
                                "color": "#d59563"
                            }
                        ]
                    },
                    {
                        "featureType": "water",
                        "elementType": "geometry",
                        "stylers": [
                            {
                                "color": "#17263c"
                            }
                        ]
                    },
                    {
                        "featureType": "water",
                        "elementType": "labels.text.fill",
                        "stylers": [
                            {
                                "color": "#515c6d"
                            }
                        ]
                    },
                    {
                        "featureType": "water",
                        "elementType": "labels.text.stroke",
                        "stylers": [
                            {
                                "color": "#17263c"
                            }
                        ]
                    }
                ],
                disableDefaultUI: true,
                scrollwheel: true,
                zoomControl: false,
                fullscreenControl: false,
                keyboardShortcuts: false,
                // mapId: "SEMBCORP_MAP_DASHBOARD",
            }

            map = new google.maps.Map(map, mapOptions);

            // const inProgressIcon = 'http://maps.google.com/mapfiles/ms/icons/red-dot.png';
            const inProgressIcon = 'img/theme/general/pin-progress.png';
            const completedIcon = 'img/theme/general/pin-complete.png';

            /*
            //_ old way to populate markers
            this.gmapMarkers.forEach(item => {
                // console.log(item);
                
                const position = { lat: item.latitude, lng: item.longitude }; // Replace with your API data

                let markerIcon = null;

                let contentString = `
                    <div style="max-width: 500px; width: 400px; max-height: 450px;">
                        <h3 style="color: white;">${item.title}</h3>
                        <span style="color: white;">${item.address}</span>
                        <hr style="background-color: #28a9bd; margin-top: 15px; margin-bottom: 15px;">
                        <div style="position: relative; display: inline-block;">
                            <a href="${this.tmp_getProjectCodeByName(item.title)}"
                                style="background-color: #FF9A3D99; padding:15px 10px 5px 10px; border-radius: 20px; color: white;">
                                <div class="icon-dashboard-cs"></div>
                                <small class="small-button-cs mr-2">Dashboard</small>
                            </a>
                        </div>
                        <div style="position: relative; display: inline-block; float: right; top: -8px;">
                            <h4 style="color:#FF9A3D99; text-align: right;">${item.kwp} kWp</h4>
                `;

                if (item.status == "IN PROGRESS") {
                    contentString = contentString + `
                            <span style="color: white; text-align: right">&nbsp;</span>
                        </div>
                    </div>
                    `;
                    markerIcon = inProgressIcon;
                } else if (item.status == "COMPLETED") {
                    contentString = contentString + `
                            <span style="color: white; text-align: right">COD: ${item.cod}</span>
                        </div>
                    </div>
                    `;
                    markerIcon = completedIcon;
                }


                const marker = new google.maps.Marker({
                    // const marker = new google.maps.marker.AdvancedMarkerElement({
                    position: position,
                    title: "Regular Map!",
                    icon: markerIcon,
                });

                const infowindow = new google.maps.InfoWindow({
                    content: contentString,
                    maxWidth: 500,
                    ariaLabel: "Regular Map!",
                });

                marker.addListener("click", () => {
                    if (this.gmapLastInfoWindow != null) this.gmapLastInfoWindow.close();

                    //Open new window
                    infowindow.open(map, marker);

                    //Store new window in global variable
                    this.gmapLastInfoWindow = infowindow;
                });
                marker.setMap(map);
            });
            */

            // console.log(this.gmapMarkers);
            //_ let's simplify the code, and make the UI lot less clutter by using cluster of markers
            // let locations = this.gmapMarkers.map(item => ({
            //     lat: item.latitude,
            //     lng: item.longitude
            // }));

            //_ change the key, cause google using lat and lng, instead of latitute and longitude
            const keyMap = {
                latitude: "lat",
                longitude: "lng"
            };
            const tmp_locations = this.gmapMarkers.map(obj => 
                Object.keys(obj).reduce((acc, key) => {
                    const newKey = keyMap[key] || key;
                    acc[newKey] = obj[key];
                    return acc;
                }, {})
            );

            let locations = null;
            //_ let's remove those sites based on the category selected
            if ( this.selectedGmapSiteStatus == 'all' ) {
                locations = tmp_locations;
            } else {
                if ( this.selectedGmapSiteStatus == 'completed' ) {
                    locations = tmp_locations.filter(item => item.status == 'COMPLETED');
                } else {
                    locations = tmp_locations.filter(item => item.status !== 'COMPLETED');
                }
            }

            // console.log(locations);

            const infoWindow = new google.maps.InfoWindow({
                content: "",
                disableAutoPan: true,
            });

            // console.log(locations);

            const markers = locations.map((position, i) => {
                let markerIcon = null;

                let contentString = `
                    <div style="max-width: 500px; width: 400px; max-height: 450px;">
                        <h3 style="color: white;">${position.title}</h3>
                        <span style="color: white;">${position.address}</span>
                        <hr style="background-color: #28a9bd; margin-top: 15px; margin-bottom: 15px;">
                        <div style="position: relative; display: inline-block;">
                            <a href="/mainDashboardSiteDetail/${position.site_id}" target="_blank" style="background-color: #FF9A3D99; padding:15px 10px 5px 10px; border-radius: 20px; color: white;">
                                <div class="icon-dashboard-cs"></div>
                                <small class="small-button-cs mr-2">Dashboard</small>
                            </a>
                        </div>
                        <div style="position: relative; display: inline-block; float: right; top: -8px;">
                            <h4 style="color:#FF9A3D99; text-align: right;">${position.kwp} kWp</h4>
                `;

                //                          <a href="${this.tmp_getProjectCodeByName(position.title)}"
                if (position.status == "COMPLETED") {
                    contentString = contentString + `
                            <span style="color: white; text-align: right">COD: ${position.cod}</span>
                        </div>
                    </div>
                    `;
                    markerIcon = completedIcon;
                } else {
                    //  (position.status == "IN PROGRESS") {
                    contentString = contentString + `
                            <span style="color: white; text-align: right">&nbsp;</span>
                        </div>
                    </div>
                    `;
                    markerIcon = inProgressIcon;
                }

                const marker = new google.maps.Marker({
                    // const marker = new google.maps.marker.AdvancedMarkerElement({
                    position: position,
                    // title: "Regular Map!",
                    icon: markerIcon,
                });

                marker.addListener("click", () => {
                    map.panTo(marker.position);
                    infoWindow.setContent(contentString);
                    infoWindow.open(map, marker);
                });
                return marker;
            });
            
            //_ customizing clusters-nya neh -_-"
            var renderer = {
                render: ({ count, position }, stats, map) => {
                    // change color if this cluster has more markers than the mean cluster
                    const color = count > Math.max(10, stats.clusters.markers.mean) ? "#2ba164" : "#0000ff";
                    //_ orane: #f5bf36 | yellow: #f7eb40 | biru: #0000ff
                    // create svg literal with fill color
                    const svg = `<svg fill="${color}" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 240 240" width="50" height="50">
                        <circle cx="120" cy="120" opacity=".6" r="70" />
                        <circle cx="120" cy="120" opacity=".3" r="90" />
                        <circle cx="120" cy="120" opacity=".2" r="110" />
                        <text x="50%" y="50%" style="fill:#fff" text-anchor="middle" font-size="50" dominant-baseline="middle" font-family="roboto,arial,sans-serif">${count}</text>
                        </svg>`;
                    const title = `Cluster of ${count} markers`, 
                    // adjust zIndex to be above other markers
                    zIndex = Number(google.maps.Marker.MAX_ZINDEX) + count;
                    const clusterOptions = {
                        position,
                        zIndex,
                        title,
                        icon: {
                            url: `data:image/svg+xml;base64,${btoa(svg)}`,
                            anchor: new google.maps.Point(25, 25),
                        },
                    };
                    return new google.maps.Marker(clusterOptions);
                }
            };

            // Add a marker clusterer to manage the markers.
            new MarkerClusterer({ markers, map, renderer });
        },
        openSelectedSiteDashboard(){            
            // console.log('openSelectedSiteDashboard');
        },
        tmp_getProjectCodeByName(tmpTitle) {
            let tmpReturn = '/projectUtilitiesDashboard/';
            /*
                PLTS IKN                             662
                Helio - Bakrie Pipe Industries  491
                Cipta Mortar Utama - Jawilan      489
                Cipta Mortar Utama - Cibitung    488
                Cipta Mortar Utama - Semarang     486
                Cipta Mortar Utama - Gresik       484
                Pradha Karya Perkasa            485
                Alumindo Light Metal Industri ( Maspion)    490
            */
            if (tmpTitle == 'PLTS IKN') {
                tmpReturn += '662';
            } else if (tmpTitle == 'Helio - Bakrie Pipe Industries') {
                tmpReturn += '491';
            } else if (tmpTitle == 'Cipta Mortar Utama - Jawilan') {
                tmpReturn += '489';
            } else if (tmpTitle == 'Cipta Mortar Utama - Cibitung') {
                tmpReturn += '488';
            } else if (tmpTitle == 'Cipta Mortar Utama - Semarang') {
                tmpReturn += '486';
            } else if (tmpTitle == 'Cipta Mortar Utama - Gresik') {
                tmpReturn += '484';
            } else if (tmpTitle == 'Pradha Karya Perkasa') {
                tmpReturn += '485';
            } else if (tmpTitle == 'Alumindo Light Metal Industri ( Maspion)') {
                tmpReturn += '490';
            } else {
                tmpReturn = '#';
            }

            return tmpReturn;
        },
        reloadMap() {
            this.loadGoogleMap();
        },
        getDeveloperOptions() {
            axios
                .get(apiDomain + "setup/developer", { headers: getHeader() })
                .then((response) => {
                    if (response.status === 200) {
                        console.log(response.data.data);

                        // this.developers = response.data.data;
                        this.developers = response.data.data.filter(object => {
                            return object.developer_id !== 3;
                        });

                        console.log(this.developers)

                        // default to sembcorp solar || developer_id = 1
                        this.model.developer_id = 1;
                        this.getMapDashboardData();
                    }
                })
                .catch((error) => {
                    console.log(error.response.status);
                    this.errorFunction(error, "Developer Information");
                });
        },
        //_ method for get and calculating data for total capacity | turn on capacity | total turn on  
        getMapDashboardData() {
            axios
                .get(apiDomain + "dashboard/map_dashboard_data/" + this.model.developer_id + "/" + this.model.data_date, { headers: getHeader() })
                .then((response) => {
                    if (response.status === 200) {
                        this.map_dashboard_data_response = response.data.data[0];
                        this.total_data.total_capacity_mwp = (response.data.data[0].total_capacity_kwp / 1000).toFixed(2);
                        this.total_data.total_turn_on_capacity_mwp = (response.data.data[0].total_turn_on_capacity_kwp / 1000).toFixed(2);
                        this.total_data.total_site = response.data.data[0].total_site;
                        this.total_data.total_site_turn_on = response.data.data[0].total_site_turn_on;
                    }
                })
                .catch((error) => {
                    console.log(error.response.status);
                    this.errorFunction(error, "Map Dashboard Information");
                });
        },
        //_ method to get the data + draw the graph for observations & findings graph.
        getMapDashboardDataNew() {
            /*
                developers [1, 2]
                    1: sembcorp sg
                    2: sembcorp id
                    
                safety_observation_type [1, 3]
                    1: positive
                    3: good practice
                    4: unsafe act
                    5: unsafe condition
                    6: near miss
            */

            let filter = {
                from_date: moment(this.model.data_date).startOf('month').format('YYYY-MM-DD'), //"2024-07-01",
                to_date: this.model.data_date, //"2024-07-22",
                developers: [this.model.developer_id],
                safety_observation_type: [1, 3, 4, 5, 6],

                project_name: null,
                project_site_contractors: [],
                site_name: null,
                project_safety: [],
                safety_inspection_submitter: [],
                safety_observation_category: [],
                safety_observation_priority: [],
                target_date_from_date: null,
                target_date_to_date: null,
            };

            axios
                .post(apiDomain + "health_safety/safety_observation.show_by_filter", filter, {
                    headers: getHeader(),
                })
                .then(response => {
                    if (response.status === 200) {
                        let objResp = response.data.data;
                        // console.log(objResp);

                        //_> draw & populate observations graph

                        // get the data to be use in the graph
                        let obs_positive = this.filteringDataBySafetyObservation(objResp, 1);
                        let obs_good_practice = this.filteringDataBySafetyObservation(objResp, 3);
                        let obs_unsafe_act = this.filteringDataBySafetyObservation(objResp, 4);
                        let obs_unsafe_condition = this.filteringDataBySafetyObservation(objResp, 5);
                        let obs_near_miss = this.filteringDataBySafetyObservation(objResp, 6);

                        // console.log('positive: ' + obs_positive.length);
                        // console.log('good practice: ' + obs_good_practice.length);
                        // console.log('unsafe act:' + obs_unsafe_act.length);
                        // console.log('unsafe condition: ' + obs_unsafe_condition.length);
                        // console.log('near miss: ' + obs_near_miss.length);

                        let chart_template_observations = {
                            credits: {
                                enabled: false,
                            },
                            series: [
                                {
                                    name: "Total",
                                    colorByPoint: true,
                                    dataLabels: {
                                        enabled: true,
                                        style: {
                                            textOutline: 'none',
                                            fontSize: '14px',
                                            fontFamily: "Open Sans, sans-serif",
                                            fontWeight: "bold",
                                            color: '#ffffff'
                                        }
                                    },
                                    data: [],
                                },
                            ],
                            chart: {
                                type: "pie",
                                backgroundColor: "#23454A",
                            },
                            title: {
                                text: "",
                                style: {
                                    color: "white",
                                    fontWeight: "bold",
                                },
                            },
                        };
                        this.chartOptionObservations = chart_template_observations;
                        let chart_data_observation = [
                            {
                                name: "Positive",
                                y: obs_positive.length,
                                color: "#5ABAB9",
                            },
                            {
                                name: "Good Practice",
                                y: obs_good_practice.length,
                                color: "#5ABAB9",
                            },
                            {
                                name: "Unsafe Condition",
                                y: obs_unsafe_condition.length,
                                color: "#3F92D3",
                            },
                            {
                                name: "Unsafe Act",
                                y: obs_unsafe_act.length,
                                color: "#3F92D3",
                            },
                            {
                                name: "Near Miss",
                                y: obs_near_miss.length,
                                color: "#3F92D3",
                            },
                        ];
                        this.chartOptionObservations.series[0].data = [];
                        for (let index = 0; index < chart_data_observation.length; index++) {
                            this.chartOptionObservations.series[0].data.push({
                                name: chart_data_observation[index].name,
                                y: chart_data_observation[index].y,
                                color: chart_data_observation[index].color,
                            });
                        }

                        //_> draw & populate finding graph

                        //_ count how many open and close
                        //  NOTE: we use this way right now, but I don't like the way we get the result
                        //          it should be hit the api and get the final result / count, instead of get the data list and processing it.
                        var iOpen = 0, iClose = 0;
                        objResp.forEach(function (data_entry) {
                            data_entry.safety_observation_items.forEach(function (entry_observation) {
                                if (entry_observation.safety_observation_status.status_id == 2) {
                                    iOpen++;
                                }

                                if (entry_observation.safety_observation_status.status_id == 3) {
                                    iClose++;
                                }
                            });
                        });
                        // console.log('open: ' + iOpen + ' vs close: ' + iClose);

                        let chart_template_findings = {
                            credits: {
                                enabled: false,
                            },
                            series: [
                                {
                                    name: "Total",
                                    colorByPoint: true,
                                    dataLabels: {
                                        enabled: true,
                                        style: {
                                            textOutline: 'none',
                                            fontSize: '14px',
                                            fontFamily: "Open Sans, sans-serif",
                                            fontWeight: "bold",
                                            color: '#ffffff'
                                        }
                                    },
                                    data: [],
                                },
                            ],
                            chart: {
                                type: "pie",
                                backgroundColor: "#23454A",
                            },
                            title: {
                                text: "",
                                style: {
                                    color: "white",
                                    fontWeight: "bold",
                                },
                            },
                        };
                        this.chartOptionsFinding = chart_template_findings;
                        let chart_data_finding = [
                            {
                                name: 'Findings Close',
                                y: iClose,
                                color: '#5ABAB9'
                            },
                            {
                                name: 'Findings Open',
                                y: iOpen,
                                color: '#3F92D3'
                            },
                        ];
                        this.chartOptionsFinding.series[0].data = [];
                        for (let index = 0; index < chart_data_finding.length; index++) {
                            this.chartOptionsFinding.series[0].data.push({
                                name: chart_data_finding[index].name,
                                y: chart_data_finding[index].y,
                                color: chart_data_finding[index].color,
                            });
                        }

                        // console.log(this.chartOptionsFinding);
                    }
                })
                .catch(error => {
                    this.errorFunction(error, "Search Safety Inspection Listing")
                });
        },
        //_ method that we use to calculate how many data based on the observation type that is given.
        filteringDataBySafetyObservation(objData, safety_observation_type) {
            /*
                1: positive
                3: good practice
                4: unsafe act
                5: unsafe condition
                6: near miss
            */
            var filteredArray = objData
                .filter(element => element.safety_observation_items
                    .some(subElement => subElement.safety_observation_type.safety_observation_type_id === safety_observation_type)
                    // .some(subElement => subElement.safety_observation_type.safety_observation_type_code === value)
                )
                .map(element => {
                    let n = Object.assign({}, element, {
                        'subElements': element.safety_observation_items.filter(
                            subElement => subElement.safety_observation_type.safety_observation_type_id === safety_observation_type
                            // subElement => subElement.safety_observation_type.safety_observation_type_code === value
                        )
                    })
                    return n;
                })

            return filteredArray;
        },
        getMapDashboardSiteData() {
            // console.log('getMapDashboardSiteData');
            axios
                .get(apiDomain + "dashboard/map_dashboard_site_data/" + this.model.developer_id, { headers: getHeader() })
                .then((response) => {
                    if (response.status === 200) {

                        let tempData = response.data.data;
                        let tempArray = [];

                        // console.log('getMapDashboardSiteData');
                        // console.log(response.data);
                        // console.log(response.data.data);
                        tempData.forEach(item => {
                            // check if project considered completed

                            // if ( item.status == "IN PROGRESS" ) {
                            // } else if ( item.status == "COMPLETED" ) {

                            let status_name = ""
                            let site_kwp = 0

                            // console.log(item);

                            if (item.project_tag == "legacy") {
                                if (item.status_id == 5 || item.status_id == 7 || item.status_id == 16 || item.status_id == 17) {
                                    status_name = "COMPLETED"
                                } else {
                                    status_name = "IN PROGRESS"
                                }
                            } else if (item.project_tag == "singapore_v2") {
                                if (item.status_id == 16 || item.status_id == 17) {
                                    status_name = "COMPLETED"
                                } else {
                                    status_name = "IN PROGRESS"
                                }
                            }

                            if (status_name == "COMPLETED") {
                                site_kwp = item.site_as_built_capacity.toFixed(2)
                            } else {
                                // site_kwp = item.site_module_allocation_total_capacity.toFixed(2)24
                                site_kwp = item.site_total_capacity.toFixed(2)
                            }


                            // if completed show turn on date
                            // if completed show as built capacity, else show site module allocation capacity
                            let tempItem = {
                                // project_id: this.getProjectIdBySelectedSite(item.site_id),
                                site_id: item.site_id,
                                latitude: item.site_latitude,
                                longitude: item.site_longitude,
                                title: item.site_name,
                                address: item.site_address + " (" + item.site_postal_code + ")",
                                kwp: site_kwp,
                                status: status_name,
                                cod: item.site_actual_turn_on_date ? item.site_actual_turn_on_date : "N/A",
                            };

                            tempArray.push(tempItem);
                        });

                        this.gmapMarkers = tempArray;
                        this.reloadMap();
                    }
                })
                .catch((error) => {
                    console.log(error.response.status);
                    this.errorFunction(error, "Map Dashboard Site Information");
                });
        },
        getProjectIdBySelectedSite(site_id) {
            // console.log('getProjectIdBySelectedSite');
            axios
                .get(apiDomain + "project/project_site/" + site_id, { headers: getHeader() })
                .then((response) => {
                    if (response.status === 200) {
                        return response.data.data[0].project.project_id;
                    }
                })
                .catch((error) => {
                    console.log(error.response.status);
                    this.errorFunction(error, "Map Dashboard Site Information");
                });
        },
        changeAllChart() {
            this.getMapDashboardData();
            this.getMapDashboardSiteData();
            this.getMapDashboardDataNew();
        },
    },
    mounted() {
        this.loadGoogleMap();
    }
};
</script>

<style scoped>
.card {
    background-color: #082a2f;
}
</style>

<style>
#filter-datepicker__value_ {
    color: #6d96a5 !important;
}

.icon-dashboard-cs {
    position: relative;
    height: 18px;
    width: 18px;
    /* margin: 4px; */
    margin-top: 5px;
    margin-left: 5px;
    display: inline-block;
    background-image: url('data:image/svg+xml;utf8,<svg  viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0 9C0 9.5523 0.44772 10 1 10H7C7.5523 10 8 9.5523 8 9V1C8 0.44772 7.5523 0 7 0H1C0.44772 0 0 0.44772 0 1V9ZM0 17C0 17.5523 0.44772 18 1 18H7C7.5523 18 8 17.5523 8 17V13C8 12.4477 7.5523 12 7 12H1C0.44772 12 0 12.4477 0 13V17ZM10 17C10 17.5523 10.4477 18 11 18H17C17.5523 18 18 17.5523 18 17V9C18 8.4477 17.5523 8 17 8H11C10.4477 8 10 8.4477 10 9V17ZM11 0C10.4477 0 10 0.44772 10 1V5C10 5.55228 10.4477 6 11 6H17C17.5523 6 18 5.55228 18 5V1C18 0.44772 17.5523 0 17 0H11Z" fill="white"/></svg>');
}

.small-button-cs {
    font-size: small;
    font-weight: 600;
    top: -4px;
    left: 7px;
    position: relative;
}

.btn-map {
    position: relative;
    text-transform: none;
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    letter-spacing: 0.025em;
    font-size: 0.875rem;
    will-change: transform;
}

.rounded-pill-map {
    border-radius: 50rem !important;
}

.gm-style .gm-style-iw-d::-webkit-scrollbar-track,
.gm-style .gm-style-iw-d::-webkit-scrollbar-track-piece,
.gm-style .gm-style-iw-c,
.gm-style .gm-style-iw-t::after {
    background: #2E575D;
    opacity: 0.9;
}

.gm-style .gm-style-iw-tc::after {
    background: #2E575D;
    opacity: 0.9;
}

#__BVID__39___BV_modal_content_ {
    background-color: #306b74;
}
</style>

<style scoped>
/* Class For Modal */
/* .modal-content {
  background-color: #306b74;

} */


.th-table-new {
    padding-top: 35px;
    padding-bottom: 35px;
    padding-left: 20px;
}

.tr-td-table-new {
    padding-top: 30px;
    padding-bottom: 30px;
    padding-left: 20px;
}

.el-table th.el-table__cell {

    overflow: hidden;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-color: #23454A;
    color: white;
    padding: 20px;
    border-top: 0px solid #082A2F;
    border-bottom: 0px solid #082A2F;
    /* border-bottom: 20px solid #082A2F; */
}

.el-table td.el-table__cell,
.el-table th.el-table__cell.is-leaf {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    border-bottom: 20px solid #082A2F;
    border-top: 0px solid #082A2F;
}

div.el-table .el-table__row {
    background: #3a656b;
    border-bottom: 0 solid #082A2F;


}

div.el-table .el-table__row:hover {
    background: #3a656b;
}

.el-table--border::after,
.el-table--group::after,
.el-table::before {
    content: '';
    position: absolute;
    background-color: #ebeef500;
    z-index: 1;
}

.form-control {
    background-color: #2C545A;
    border-color: #2C545A;
    color: white;

    &:focus {
        background-color: #2C545A;
        border-color: #2C545A;
        /* color: white; */
    }

    &::placeholder {
        color: white;
    }
}

.form-control {
    &:focus {
        &::placeholder {
            color: white;
        }
    }
}

.form-control,
.form-row,
select option,
textarea {
    color: #6d96a5 !important;
}


.input-group-text {
    background-color: #2C545A;
    ;
    color: white;

    &:focus {
        background-color: #2C545A;
        ;
        border-color: #1D4147;
        color: white;
    }
}

.focused {
    .input-group-text {
        background-color: #2C545A;
    }
}

.navbar-search-dark .input-group {
    background-color: #23454A;
    color: black;
    border-color: rgba(255, 255, 255, 0.6);
}

.navbar-search .input-group {
    border-radius: 7px;
}

select {
    background: url("data:image/svg+xml,<svg width='10' height='7' viewBox='0 0 10 7' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M5.02552 6.35057L0.320068 0.46875H9.73098L5.02552 6.35057Z' fill='white'/></svg>") no-repeat;
    background-position: calc(100% - 0.75rem) center !important;
    -moz-appearance: none !important;
    -webkit-appearance: none !important;
    appearance: none !important;
    padding-right: 2rem !important;
}
</style>
