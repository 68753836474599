<!-- v1. editable grid
  this one is using data that we got from API project/project_site_milestone.show_by_site/<id_site>

  so for this test ui, we populate data by execute method get_project_site_milestone()
  then to construct the grid, execute method createColumnDefs();

  note: check <ag-grid-vue> in <template>, there is :detailCellRendererParams="detailCellRendererParams"
  that we define in data, we define the sub grid there, and to populate it, using getDetailRowData that getting the data from sub array (which is: milestone_tasks) from the api above.

  and one other thing that important is :masterDetail="true"

  populating the detail/sub grid, using initiateSubGrid()
-->

<!-- v2. added gantt chart
  update untuk show gantt chart:
  search aja keyword: BtnChartMilestoneTask :D

  method createColumnDefs, added new column for action button

  lalu new file ada di sub folder cell-renderers
    src\views\SemCorp\ProjectMgmt\AGG\cell-renderers\btn-chart-milestone-task.vue
-->

<template>
  <div class="bg-default-dashboard">
    <div class="py-lg-8 pt-lg-5">
      <b-container fluid style="width: 90%">
        <b-row class="mb-2">
          <b-col>
            <h1 class="text-white">Project Milestone & Task ( {{ obj_project.tx_site }} )</h1>
          </b-col>
        </b-row>
        <b-row>
          <div class="d-flex flex-column h-100 w-100 p-2">
            <div class="d-flex flex-column h-100 w-100 flex-grow-1 flex-shrink-1">
              <div style="padding: 4px">
                <div style="float: right" class="d-flex">
                  <input class="mr-2 form-control d-inline-block" @keyup="onQuickFilterChanged" type="text"
                    id="quickFilterInput" placeholder="Type text to filter..." />
                </div>
              </div>
              <div style="clear: both"></div>
              <div v-if="showGrid" class="d-flex flex-column flex-grow-1 flex-shrink-1">
                <div style="padding: 4px" class="btn-toolbar">
                  <span>
                    <button class="btn btn-primary mx-1" @click="api.selectAll()">
                      Select All
                    </button>
                    <button class="btn btn-primary mx-1" @click="api.deselectAll()">
                      Clear Selection
                    </button>
                    <button class="btn btn-primary mx-1" v-on:click="toggleSideBar()">
                      Show Side Bar
                    </button>
                  </span>
                </div>
                <ag-grid-vue style="width: 100%; height: 100%" class="flex-grow-1 flex-shrink-1 ag-theme-alpine"
                  :columnDefs="columnDefs" :rowData="rowData" :sideBar="sideBar" :modules="modules" :defaultColDef="{
                    sortable: true,
                    resizable: true,
                    filter: true,
                    floatingFilter: true,
                  }" @grid-ready="onReady" :masterDetail="true" :detailCellRendererParams="detailCellRendererParams"
                  @cell-value-changed="onCellValueChanged">
                  <!-- 
                  :groupHeaders="true"
                  :suppressRowClickSelection="true"
                  :allowContextMenuWithControlKey="true"
                  rowSelection="multiple"

                  @cell-clicked="onCellClicked"
                  @cell-double-clicked="onCellDoubleClicked"
                  @cell-context-menu="onCellContextMenu"
                  @cell-value-changed="onCellValueChanged"
                  @cell-focused="onCellFocused"
                  @row-selected="onRowSelected"
                  @selection-changed="onSelectionChanged"
                  @filter-modified="onFilterModified"
                  @virtual-row-removed="onVirtualRowRemoved"
                  @row-clicked="onRowClicked"
                  @column-everything-changed="onColumnEvent"
                  @column-row-group-changed="onColumnEvent"
                  @column-value-Changed="onColumnEvent"
                  @column-moved="onColumnEvent"
                  @column-visible="onColumnEvent"
                  @column-group-Opened="onColumnEvent"
                  @column-resized="onColumnEvent"
                  @column-pinned-count-changed="onColumnEvent"                
                
                -->
                </ag-grid-vue>
              </div>
            </div>
          </div>
        </b-row>

      </b-container>
    </div>

  </div>



</template>

<script>
import ErrorFunctionMixin from "@/assets/js/errorFunctionMix.js";
import { AgGridVue } from "@ag-grid-community/vue";
// import HeaderGroupComponent from "./HeaderGroupComponent.vue";
import axios from "@/util/axios";

import { ClientSideRowModelModule } from "@ag-grid-community/client-side-row-model";
import { ColumnsToolPanelModule } from "@ag-grid-enterprise/column-tool-panel";
import { ExcelExportModule } from "@ag-grid-enterprise/excel-export";
import { FiltersToolPanelModule } from "@ag-grid-enterprise/filter-tool-panel";
import { SparklinesModule } from "@ag-grid-enterprise/sparklines";
import { GridChartsModule } from "@ag-grid-enterprise/charts";
import { MasterDetailModule } from "@ag-grid-enterprise/master-detail";
import { MenuModule } from "@ag-grid-enterprise/menu";
import { MultiFilterModule } from "@ag-grid-enterprise/multi-filter";
import { RangeSelectionModule } from "@ag-grid-enterprise/range-selection";
import { RichSelectModule } from "@ag-grid-enterprise/rich-select";
import { RowGroupingModule } from "@ag-grid-enterprise/row-grouping";
import { ServerSideRowModelModule } from "@ag-grid-enterprise/server-side-row-model";
import { SetFilterModule } from "@ag-grid-enterprise/set-filter";
import { SideBarModule } from "@ag-grid-enterprise/side-bar";
import { StatusBarModule } from "@ag-grid-enterprise/status-bar";
import { ViewportRowModelModule } from "@ag-grid-enterprise/viewport-row-model";
import { ClipboardModule } from "@ag-grid-enterprise/clipboard";
import { apiDomain, getHeader } from "@/assets/js/config.js";
import "@ag-grid-enterprise/core";
import moment from "moment";

export default {
  mixins: [ErrorFunctionMixin],
  data() {
    return {
      obj_project: {
        id_project: null,
        tx_project: null,
        id_site: null,
        tx_site: null,
        id_milestone: null,
        id_milestone_task: null,
      },
      obj_hit_api: {
        id_site: null,
        id_task: null,
        // selected_row_data: null,   //_ not needed anymore, it was use when using my own script (when cannot use onCellValueChanged )
        // updated_field: null,       //_ not needed anymore, it was use when using my own script (when cannot use onCellValueChanged )
        to_be_update_data: {},
      },
      obj_selection: {
        //_ we can change this later, if there is an API for get parameterize setting value
        ms_critical: ['Yes', 'No'],
        ms_status: ['NOT_STARTED', 'IN_PROGRESS', 'COMPLETED'],
      },
      detailCellRendererParams: null,
      //_ NOTE: above from this line is new

      showFilters: false,
      api: null,
      columnDefs: null,
      showGrid: false,
      sideBar: false,
      rowCount: null,
      modules: [
        ClientSideRowModelModule,
        ColumnsToolPanelModule,
        ExcelExportModule,
        FiltersToolPanelModule,
        SparklinesModule,
        GridChartsModule,
        MasterDetailModule,
        MenuModule,
        MultiFilterModule,
        RangeSelectionModule,
        RichSelectModule,
        RowGroupingModule,
        ServerSideRowModelModule,
        SetFilterModule,
        SideBarModule,
        StatusBarModule,
        ViewportRowModelModule,
        ClipboardModule,
      ],
      rowData: [],
    };
  },
  created() {
    const LoginUser = JSON.parse(window.localStorage.getItem("loginUser"));

    this.obj_project.id_site = this.$route.params.id;
    this.get_site_detail_info();

    var project_site_access = JSON.parse(
      window.localStorage.getItem("project_site_access")
    );
  },
  components: {
    AgGridVue,
  },
  setup() { },
  methods: {
    createColumnDefs() {
      this.columnDefs = [
        {
          headerName: "Milestone",
          field: "site_milestone_code",
          flex: 1,
          // width: 250,
          cellRenderer: "agGroupCellRenderer",
          // pinned: true,
          // floatingFilter: true,
          // cellRenderer: "agGroupCellRenderer",
        },
        {
          headerName: "Remark",
          field: "remarks",
          width: 300,
          editable: true,
          // floatingFilter: true,
          // suppressHeaderMenuButton: true,
        },
        // {
        //   headerName: "Status",
        //   field: "active_status",
        //   width: 250,
        //   rowGroup: false,
        //   // floatingFilter: true,
        //   // suppressHeaderMenuButton: true,
        // },
        // {
        //   field: 'button',
        //   headerName: 'Action',
        //   // cellRenderer:'CustomButtonComponent'
        //   cellRenderer: 'btnChartMilestoneTask',
        //   // cellRendererParams: '<child-component></child-component>'
        // },
      ];
    },
    initiateSubGrid() {
      this.detailCellRendererParams = {
        detailGridOptions: {
          columnDefs: [
            { headerName: "Task Name", field: "site_milestone_task_name", flex: 1.5 },
            {
              headerName: "Planned Start Date", field: "site_milestone_task_planned_start_date", width: 200,
              cellRenderer: (params) => { return this.format_datetime(params.data.site_milestone_task_planned_start_date) }
            },
            {
              headerName: "Planned End Date", field: "site_milestone_task_planned_end_date", width: 200,
              cellRenderer: (params) => { return this.format_datetime(params.data.site_milestone_task_planned_end_date) }
            },
            {
              headerName: "Actual Start Date", field: "site_milestone_task_actual_start_date", width: 200,
              editable: true, headerClass: 'agisEditable',
              cellDataType: 'dateString',
              cellEditor: "agDateStringCellEditor",
              cellRenderer: (params) => { return this.format_datetime(params.data.site_milestone_task_actual_start_date) },
            },
            {
              headerName: "Actual End Date", field: "site_milestone_task_actual_end_date", width: 200,
              editable: true, headerClass: 'agisEditable',
              // cellEditor: "agDateCellEditor",
              cellDataType: 'dateString',
              cellEditor: "agDateStringCellEditor",
              cellRenderer: (params) => { return this.format_datetime(params.data.site_milestone_task_actual_end_date) },
            },
            {
              headerName: "Critical", field: "site_milestone_task_critical", width: 150,
              editable: true, headerClass: 'agisEditable',
              cellEditor: "agSelectCellEditor",
              cellEditorParams: { values: this.obj_selection.ms_critical },
              // cellEditorParams: { values: this.bind_selection_field_critical() }
            },
            {
              headerName: "Status", field: "site_milestone_task_status", width: 200,
              editable: true, headerClass: 'agisEditable',
              cellEditor: "agSelectCellEditor",
              cellEditorParams: { values: this.obj_selection.ms_status },
            },
          ],
          headerHeight: 38,
          // onCellValueChanged: function ($event) {    <-- NOTE: geesshhh, spent more than half day figuring it how to access vue method dari onCellValueChanged, baru ingat kalau pakai arrow function, "this"-nya ga ke bind ama object onCellValueChanged, makanya dari tadi pakai function, "this"-nya ngerefer ke onCellValuChanged object, and ga ada class/method populate_data_to_be_submit_into_db di dalam onCellValueChanged
          onCellValueChanged: ($event) => {
            // console.log('onCellValueChanged child grid');
            // console.log($event);
            this.populate_data_to_be_submit_into_db($event);
            this.api_action_milestone_task('get');
            this.api_action_milestone_task('update');
          }
        },
        getDetailRowData: (params) => {
          // supply details records to detail cell renderer (i.e. detail grid)
          params.successCallback(params.data.milestone_tasks);
        },
        // tmpAction: this.populate_data_to_be_submit_into_db.bind(this),
      };
    },
    api_action_milestone_task(whatAction = 'none') {
      console.log('api_action_milestone_task');
      // console.log(this.obj_hit_api);

      if (whatAction == 'get') {
        //_ 1.	Get Single Project Site Milestone API: http://{domain_url} /task/get_site_milestone_task_by_id /{project_site_id}/{site_milestone_task_id}
        console.log("api_action_milestone_task: GET");

        // console.log( this.obj_project.id_site );
        // console.log(this.obj_hit_api);

        axios
          .get(apiDomain + "task/get_site_milestone_task_by_id/" + this.obj_hit_api.id_site + "/" + this.obj_hit_api.id_task, { headers: getHeader() })
          .then((response) => {
            if (response.status === 200) {
              console.log(response);
            }
          })
          .catch((error) => {
            console.log(error.response.status);
            this.errorFunction(error, "Get Single Project Site Milestone");
          })
          .finally(() => {
            //_
          });
      } else if (whatAction == 'update') {
        //_ 3.	Update Project Site Milestone Task API: http://{domain_url} /task/edit_site_milestone_task/{project_site_id}/{site_milestone_task_id}
        console.log("api_action_milestone_task: UPDATE");

        axios
          .post(apiDomain + 'task/edit_site_milestone_task/' + this.obj_hit_api.id_site + "/" + this.obj_hit_api.id_task, this.obj_hit_api.to_be_update_data, { headers: getHeader() })
          .then(response => {
            if (response.status === 200) {
              this.$notify({
                message:
                  '<b>Update Milestone Task: Successfully Updated.</b>',
                timeout: 10000,
                icon: 'ni ni-bell-55',
                type: 'default',
              });
            }
          })
          .catch(error => {
            console.log(error.response.data.errors)
            this.errorFunction(error, "Project Information")
          });
      } else if (whatAction == 'delete') {
        console.log("api_action_milestone_task: DELETE");

      } else {
        this.redErrorNotify("<b>Selected action not valid! </b>");
      }
    },
    get_project_site_milestone(site_id = 0) {
      //https://momensfile.sembcorp.com/project/project_site_milestone.show_by_site/7384
      console.log("get_project_site_milestone");

      axios
        .get(
          apiDomain + "project/project_site_milestone.show_by_site/" + site_id,
          { headers: getHeader() }
        )
        .then((response) => {
          if (response.status === 200) {
            this.rowData = response.data.data;
            console.log(response);
          }
        })
        .catch((error) => {
          console.log(error.response.status);
          this.errorFunction(error, "Get Milestones by Site ID");
        })
        .finally(() => {
          //_
        });
    },
    get_site_detail_info() {
      console.log('get_site_detail_info');
      //_ this one, nothing to do with milestone, just showing info of project / site 
      //  <h1 class="text-white">Project Milestone & Task ( {{ obj_project.tx_site }} )</h1>

      axios
        .get(apiDomain + "project/project_site/" + this.obj_project.id_site, { headers: getHeader(), })
        .then((response) => {
          if (response.status === 200) {
            // console.log(response.data.data);
            this.obj_project.id_project = response.data.data[0].project.project_id
            this.obj_project.tx_project = response.data.data[0].project.project_name
            this.obj_project.id_site = response.data.data[0].site_id
            this.obj_project.tx_site = response.data.data[0].site_name

            // console.log(this.obj_project);
          }
        })
        .catch((error) => {
          this.errorFunction(error, "Site Information");
        });
    },

    /* CUSTOM library, make it DRY and clean code */
    format_datetime(tmp_value, isFor = 'ui') {
      //_ default from db/api datetime format in iso8601, ex.2024-06-08T00:00:00.000000Z
      //  so isFor='ui' we format it into readable format DD Mmm YYYY
      //  the default is for frontend UI, if we didn't pass the 2nd parameter
      //  to save into db, change isFor='db', so it's formatted into YYYY-MM-DD H:i:s  <- this is the value accepted, tried YYYY-MM-DD only, and failed
      if (isFor == 'ui') {
        return (tmp_value === null) ? '' : moment(tmp_value).format('DD MMM YYYY');
      } else if (isFor == 'db') {
        return (tmp_value === null) ? '' : moment(tmp_value).format('YYYY-MM-DD HH:mm:ss');
      } else {
        return '';
      }

    },

    populate_data_to_be_submit_into_db(AgEvent) {
      console.log('populate_data_to_be_submit_into_db');
      // console.log(AgEvent);

      //_ since it's repetition, we use this function to simplify the detail grid instantiation
      //  why ? the API required some fields, such as task name | plan start | plan end | taskstatus

      //  NOTE: task name | plan start | plan end is not suppose to be change, right ?
      //  if those fields allowed to be change, then the logic down here need to be change

      this.obj_hit_api.id_site = this.obj_project.id_site;
      this.obj_hit_api.id_task = AgEvent.data.site_milestone_task_id;

      //_ initiate un-change required field
      let obj_default = {
        site_milestone_task_name: AgEvent.data.site_milestone_task_name,
        site_milestone_task_planned_start_date: this.format_datetime(AgEvent.data.site_milestone_task_planned_start_date, 'db'),
        site_milestone_task_planned_end_date: this.format_datetime(AgEvent.data.site_milestone_task_planned_end_date, 'db'),
      };

      //_ is status the one that being update or not ? because it's one of required field need to be post to API
      //  if it not status, then we use the status as is from the db (or what we got from the API)
      let updated_field = AgEvent.colDef.field;
      let updated_value = '';
      if (updated_field == 'site_milestone_task_actual_start_date' || updated_field == 'site_milestone_task_actual_end_date') {
        updated_value = this.format_datetime(AgEvent.newValue, 'db');
      } else {
        updated_value = AgEvent.newValue
      }
      if (updated_field != 'site_milestone_task_status') {
        obj_default = { ...obj_default, site_milestone_task_status: AgEvent.data.site_milestone_task_status, [updated_field]: updated_value }
      } else {
        obj_default = { ...obj_default, site_milestone_task_status: AgEvent.data.site_milestone_task_status }
      }

      this.obj_hit_api.to_be_update_data = { ...obj_default }

      // console.log(this.obj_hit_api);
    },




    /**
     * Debug and testing
     * 
     */
    action_on_modal(what_action, what_modal, isReset = true) {
      // console.log('action_on_modal');
      this.isLoadingInProgress = true;
    },
    tmp_test() {
      console.log('awaw');
    },
    handleGreetMessage(message) {
      this.greetMessage = `Parent says: ${message}`;
    },
    get_milestones_by_site_id(site_id = 0) {
      console.log("get_milestones_by_site_id");

      axios
        .get(
          apiDomain + "task/get_site_milestone_task_list_by_site_id/" + site_id,
          { headers: getHeader() }
        )
        .then((response) => {
          if (response.status === 200) {
            console.log(response);
          }
        })
        .catch((error) => {
          console.log(error.response.status);
          this.errorFunction(error, "Get Milestones by Site ID");
        })
        .finally(() => {
          //_
        });
    },

    get_milestone_template_detail_task(ms_task_template_id = 0) {
      console.log("get_milestone_template_detail_task");

      axios
        .get(
          apiDomain +
          "task/get_milestone_template_detail_task/" +
          ms_task_template_id,
          { headers: getHeader() }
        )
        .then((response) => {
          if (response.status === 200) {
            console.log(response);
          }
        })
        .catch((error) => {
          console.log(error.response.status);
          this.errorFunction(error, "Get Milestones template detail task");
        })
        .finally(() => {
          //_
        });
    },

    get_milestone_template_task(ms_template_id = 0) {
      console.log("get_milestone_template_task");

      axios
        .get(apiDomain + "task/get_milestone_template_task/" + ms_template_id, {
          headers: getHeader(),
        })
        .then((response) => {
          if (response.status === 200) {
            // this.rowData = response.data.data;
            console.log(response);
          }
        })
        .catch((error) => {
          console.log(error.response.status);
          this.errorFunction(error, "Get Milestones template detail task");
        })
        .finally(() => {
          //_
        });
    },


    //_ original code
    createRowData() {
      console.log("createRowData");

      axios
        .post("project/project_site.show_by_filter", this.model, {
          headers: getHeader(),
        })
        .then((response) => {
          this.rowData = response.data.data;
          console.log(this.rowData);
        })
        .catch((error) => {
          this.errorFunction(error, this.title);
        });
    },
    searchData() {
      axios
        .post("project/project_site.show_by_filter", this.model, {
          headers: getHeader(),
        })
        .then((response) => {
          this.rowData = response.data.data;
        })
        .catch((error) => {
          this.errorFunction(error, this.title);
        });
    },
    pad(num, totalStringSize) {
      let asString = num + "";
      while (asString.length < totalStringSize) asString = "0" + asString;
      return asString;
    },
    onReady(params) {
      console.log("onReady");

      this.api = params.api;
      this.api.sizeColumnsToFit();
    },
    onQuickFilterChanged(event) {
      this.api.setQuickFilter(event.target.value);
    },
    onCellValueChanged(event) {
      console.log('onCellValueChanged: ' + event.oldValue + ' to ' + event.newValue);
    },
    onColumnEvent(event) {
      console.log('onColumnEvent: ' + event);
    },
    onSelectionChanged() {
      console.log('selectionChanged');
    },
  },
  beforeMount() {
    this.api = {};

    this.get_project_site_milestone(this.obj_project.id_site);
    this.createColumnDefs();
    this.initiateSubGrid();

    this.showGrid = true;
  },
  mounted() {

  }
};
</script>

<style>
.agisEditable {
  background-color: #98bcdb;
}

.ag-root-wrapper-body.ag-layout-normal {
  height: 700px;
}

.ag-cell {
  padding-top: 2px !important;
  padding-bottom: 2px !important;
}

label {
  font-weight: normal !important;
}

.bg-default-dashboard {
  background-color: #082a2f;
  padding: 0;
  margin: 0;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.div-percent-bar {
  display: inline-block;
  height: 100%;
  position: relative;
  z-index: 0;
}

.div-percent-value {
  position: absolute;
  padding-left: 4px;
  font-weight: bold;
  font-size: 13px;
  z-index: 100;
}

.div-outer-div {
  display: inline-block;
  height: 100%;
  width: 100%;
}

.ag-menu {
  z-index: 200;
}

.toolbar button {
  margin-left: 5px;
  margin-right: 5px;
  padding: 2px;
}
</style>
