<style>
a:link {
  color: #5e72e4;
  background-color: transparent;
  text-decoration: none;
}
a:visited {
  color: #5e72e4;
  background-color: transparent;
  text-decoration: none;
}
a:hover {
  color: #5e72e4;
  background-color: transparent;
  text-decoration: underline;
}
a:active {
  color: #5e72e4;
  background-color: transparent;
  text-decoration: underline;
}
</style>
<template>
  <card>
    <!-- Card header -->
    <h3 slot="header" class="mb-0">
      New Site Task for ({{ model.project_name }} - {{ model.group_name }} -
      {{ model.site_name }})
    </h3>
    <div class="text-right"></div>
    <form class="needs-validation" @submit.prevent="submit">
      <div v-if="projects.length == 0" class="form-row">
        <b-col md="12">
          <h5 class="text-danger">
            !!! Warning :
            <i>
              Please Create New Project at Menu >> Project-Group-Site >>
              Project. System required at least ONE Project & One Group & One
              Site before you can proceed to create New Task.
            </i>
          </h5>
        </b-col>
      </div>

      <div
        v-if="project_milestones.length == 0 && model.project_id != 'x'"
        class="form-row"
      >
        <b-col md="12">
          <h5 class="text-danger">
            !!! Warning :
            <i>
              Please Create New Project Milestone at Menu >> Project-Group-Site
              >> Project >> Project Dashboard. System required at least ONE
              Milestone before you can proceed to create New Task.
            </i>
          </h5>
        </b-col>
      </div>

      <div
        v-if="project_groups.length == 0 && model.project_id != 'x'"
        class="form-row"
      >
        <b-col md="12">
          <h5 class="text-danger">
            !!! Warning :
            <i>
              Please Create New Group at Menu >> Project-Group-Site >> Project
              Group. System required at least ONE Project & One Group & One Site
              before you can proceed to create New Task.
            </i>
          </h5>
        </b-col>
      </div>

      <div
        v-if="
          project_sites.length == 0 &&
          model.group_id != 'x' &&
          model.group_id != 'y'
        "
        class="form-row"
      >
        <b-col md="12">
          <h5 class="text-danger">
            !!! Warning :
            <i>
              Please Create New Site at Menu >> Project-Group-Site >> Project
              Site. System required at least ONE Project & One Group & One Site
              before you can proceed to create New Task.
            </i>
          </h5>
        </b-col>
      </div>

      <div
        v-if="
          site_contractors.length == 0 &&
          model.site_id != 'x' &&
          model.site_id != 'y'
        "
        class="form-row"
      >
        <b-col md="12">
          <h5 class="text-danger">
            !!! Warning :
            <i>
              If you wish to assign to Contractor Please Create New Site
              Contractor > at Menu >> Project-Group-Site >> Project >> Project
              Dashboard >> Site Listing >> Dashboard >> Contractor.
            </i>
          </h5>
        </b-col>
      </div>

      <div class="form-row">
        <b-col md="12">
          <base-input
            label="Task Title *"
            name="Task Title"
            required
            placeholder="Task Title"
            v-model="model.task_title"
          >
          </base-input>
        </b-col>
      </div>

      <div class="form-row">
        <b-col md="5">
          <base-input
            label="Project *"
            name="Project"
            required
            v-model="model.project_id"
          >
            <select
              class="form-control"
              v-model="model.project_id"
              @change="getGroupFromProject()"
              disabled
            >
              <option Selected disabled value="x">
                -- Please Select A Project --
              </option>
              <option
                v-for="item in projects"
                :key="item.project_id"
                v-bind:value="item.project_id"
              >
                {{ item.project_name }}
              </option>
            </select>
          </base-input>
        </b-col>

        <b-col md="1">
          <br />
          <base-button size="sm" type="primary" @click="checkProject()"
            >Project Infomation</base-button
          >
        </b-col>

        <b-col md="5">
          <base-input
            label="Group *"
            name="Group"
            required
            v-model="model.group_id"
          >
            <select
              class="form-control"
              v-model="model.group_id"
              @change="getSiteFromGroup()"
              disabled
            >
              <option Selected disabled value="x">
                -- Please Select A Project To Show Group --
              </option>
              <option Selected disabled value="y">
                -- Please Select A Group --
              </option>
              <option
                v-for="item in project_groups"
                :key="item.group_id"
                v-bind:value="item.group_id"
              >
                {{ item.group_name }}
              </option>
            </select>
          </base-input>
        </b-col>

        <b-col md="1">
          <br />
          <base-button size="sm" type="primary" @click="checkGroup()"
            >Group Infomation</base-button
          >
        </b-col>
      </div>

      <div class="form-row">
        <b-col md="5">
          <base-input
            label="Site *"
            name="Site"
            required
            v-model="model.site_id"
          >
            <select
              class="form-control"
              v-model="model.site_id"
              @change="getContractorFromSite()"
              disabled
            >
              <option Selected disabled value="x">
                -- Please Select A Group To Show Site --
              </option>
              <option Selected disabled value="y">
                -- Please Select A Site --
              </option>
              <option
                v-for="item in project_sites"
                :key="item.site_id"
                v-bind:value="item.site_id"
              >
                {{ item.site_name }}
              </option>
            </select>
          </base-input>
        </b-col>

        <b-col md="1">
          <br />
          <base-button size="sm" type="primary" @click="checkSite()"
            >Site Infomation</base-button
          >
        </b-col>

        <b-col md="5" v-if="model.project_id != 'x'">
          <base-input
            label="Site Contractor"
            name="Site Contractor"
            v-model="model.contractor_id"
          >
            <select
              class="form-control"
              v-model="model.contractor_id"
              @change="getUserListFromProjectContractor()"
            >
              <option Selected disabled value="x">
                -- Please Select A Site To Show Contrcator --
              </option>
              <option Selected value="y">-- Assign to Internal User --</option>
              <option
                v-for="item in site_contractors"
                :key="item.contractor.contractor_id"
                v-bind:value="item.contractor.contractor_id"
              >
                {{ item.contractor.contractor_code }} -
                {{ item.contractor.contractor_name }}
              </option>
            </select>
          </base-input>
        </b-col>

        <b-col md="5" v-else>
          <base-input
            label="Site Contractor *"
            name="Site Contractor"
            v-model="model.contractor_id"
          >
            <select
              class="form-control"
              v-model="model.contractor_id"
              @change="getUserListFromProjectContractor()"
              disabled
            >
              <option Selected disabled value="x">
                -- Please Select A Site To Show Contrcator --
              </option>
              <option Selected value="y">-- Assign to Internal User --</option>
              <option
                v-for="item in site_contractors"
                :key="item.contractor.contractor_id"
                v-bind:value="item.contractor.contractor_id"
              >
                {{ item.contractor.contractor_code }} -
                {{ item.contractor.contractor_name }}
              </option>
            </select>
          </base-input>
        </b-col>

        <b-col md="1">
          <br />
          <base-button size="sm" type="primary" @click="checkContrator()"
            >Contractor Infomation</base-button
          >
        </b-col>
      </div>

      <div class="form-row">
        <b-col
          md="5"
          v-if="model.contractor_id == 'x' || model.contractor_id == 'y'"
        >
          <base-input
            label="Assign To *"
            name="Assign to"
            required
            v-model="model.assign_to_user"
          >
            <select class="form-control" v-model="model.assign_to_user">
              <option Selected disabled value="x">
                -- Please Assign The Task To --
              </option>
              <option
                v-for="item in user_list"
                :key="item.id"
                v-bind:value="item.id"
              >
                {{ item.role_name }} - {{ item.name }}
              </option>
            </select>
          </base-input>
        </b-col>

        <b-col md="5" v-else>
          <base-input
            label="Assign To (with Contractor) *"
            name="Assign to"
            required
            v-model="model.assign_to_user"
          >
            <select class="form-control" v-model="model.assign_to_user">
              <option Selected disabled value="x">
                -- Please Assign The Task To --
              </option>
              <option
                v-for="item in user_list"
                :key="item.id"
                v-bind:value="item.id"
              >
                {{ item.role_name }} - {{ item.name }}
                <span v-if="item.contractor.contractor_name != null"
                  >({{ item.contractor.contractor_name }})</span
                >
              </option>
            </select>
          </base-input>
        </b-col>

        <b-col md="1">
          <br />
          <base-button size="sm" type="primary" @click="assignToMe()"
            >Assign To Me (Default)</base-button
          >
        </b-col>

        <b-col md="6">
          <base-input
            label="Task Milestone *"
            name="Task Milestone"
            required
            v-model="model.milestone_id"
          >
            <select class="form-control" v-model="model.milestone_id">
              <option Selected disabled value="x">
                -- Please Select A Project To Show Milestone --
              </option>
              <option Selected disabled value="y">
                -- Please Select A Milestone --
              </option>
              <option
                v-for="item in project_milestones"
                :key="item.milestone_id"
                v-bind:value="item.milestone_id"
              >
                {{ item.milestone_code }}
              </option>
            </select>
          </base-input>
        </b-col>
      </div>

      <div class="form-row">
        <b-col md="6">
          <base-input
            label="Task Status *"
            name="Task Status"
            required
            v-model="model.status_id"
          >
            <select class="form-control" v-model="model.status_id">
              <option Selected disabled value="x">
                -- Please Select Task Status --
              </option>
              <option
                v-for="item in task_status"
                :key="item.status_id"
                v-bind:value="item.status_id"
              >
                {{ item.status_code }}
              </option>
            </select>
          </base-input>
        </b-col>

        <b-col md="6">
          <base-input
            label="Task Progress (0 - 100) % *"
            name="Task Progress"
            required
            placeholder="Task Progress"
            v-model="model.task_progress"
            type="number"
            min="0"
            max="100"
          >
          </base-input>
        </b-col>
      </div>

      <div class="form-row">
        <b-col md="3">
          <base-input
            label="Est Start Date *"
            name="Est Start Date"
            type="date"
            required
            v-model="model.task_est_start_date"
          >
          </base-input>
        </b-col>

        <b-col md="3">
          <base-input
            label="Est End Date *"
            name="Est End Date"
            type="date"
            required
            v-model="model.task_est_end_date"
          >
          </base-input>
        </b-col>

        <b-col md="3">
          <base-input
            label="Start Date"
            name="Start Date"
            type="date"
            v-model="model.task_start_date"
          >
          </base-input>
        </b-col>

        <b-col md="3">
          <base-input
            label="End Date"
            name="End Date"
            type="date"
            v-model="model.task_end_date"
          >
          </base-input>
        </b-col>
      </div>

      <div class="form-row">
        <b-col md="12">
          <base-input
            label="Description"
            name="Description"
            placeholder="Description"
            v-model="model.task_description"
          >
          </base-input>
        </b-col>

        <b-col md="12">
          <base-input
            label="Remarks"
            name="Remarks"
            placeholder="Remarks"
            v-model="model.task_remarks"
          >
          </base-input>
        </b-col>
      </div>

      <div>
        <b-row>
          <b-col md="auto"><b>Reviewer Required</b></b-col>

          <b-col md="auto">
            <b-form-checkbox
              id="Reviewer-Required"
              v-model="model.reviewer_required"
              name="Revewer Required"
              value="1"
              unchecked-value="0"
            ></b-form-checkbox>
          </b-col>
        </b-row>
      </div>

      <!-- <div class="form-row" v-if="model.reviewer_required == 1">Reviewer(s)</div> -->

      <div class="form-group" v-if="model.reviewer_required == 1">
        <!-- <div>event: {{ tempEvent }}</div> -->
        <!-- <li v-for="role in roles" :key="role.id">
          role id: {{ role.id }} || role_name: {{ role.name }} || disabled:
          {{ role.disabled }} || v_hide: {{ role.v_hide }}
        </li> -->
        <!-- <div>{{roles}}</div> -->
        <div
          v-for="(input, index) in reviewers"
          :key="`reviewerInput-${index}`"
        >
          <div class="form-row">
            <!-- <base-input label="Reviewer"
                      name="Reviewer"
                      placeholder="Reviewer"
                      type="text">
          </base-input> -->
            <!-- <input 
               v-model="input.reviewerRoleId"
               type="text" 
               placeholder="Reviewer"     
          /> -->
            <b-col md="3">
              <base-input v-model="input.reviewerRoleId">
                <select
                  class="form-control"
                  v-model="input.reviewerRoleId"
                  @change="removeFromList($event)"
                >
                  <option Selected disabled value="x">
                    -- Please Select --
                  </option>
                  <option
                    v-for="item in roles"
                    :key="item.id"
                    v-bind:value="item.id"
                    :disabled="item.disabled"
                    v-show="!item.v_hide"
                  >
                    {{ item.name }}
                  </option>
                </select>
              </base-input>
            </b-col>
            <b-col md="auto"
              ><base-button
                size="sm"
                type="primary"
                @click="addField(input, reviewers)"
                >Add</base-button
              ></b-col
            >
            <b-col md="auto"
              ><base-button
                size="sm"
                type="primary"
                @click="removeField(index, reviewers)"
                v-show="reviewers.length > 1"
                >Remove</base-button
              ></b-col
            >
          </div>
        </div>
        <!-- <div>
          reviewers <br />
          {{ reviewers }}
        </div> -->
        <!-- <div>reviewers count: {{ reviewers.length }}</div> -->
      </div>

      <base-button type="primary" @click="createData()"
        >Create / Save</base-button
      >
      <base-button type="primary" @click="$router.back()">Cancel</base-button>
      <div>
        <br />
        <h5 class="text-danger">
          Note : <br />
          <i> 1. By Default Est Start & End Date is set to "TODAY. </i>
          <br />
          <i> 2. Task Progress % is set to "0 %" By Default. </i>
          <br />
          <i> 3. All Column with * are Mandatory to fill in. </i>
          <br />
          <i>
            4. Est End Date must be Greater or same as Est Start Date. Same with
            End Date and Start Date.
          </i>
          <br />
          <i>
            5. Task Title, Project & Group & Site, Assign To, Task Status, Task
            Progress, Est Start Date, Est End Date are Mandatory columns.
          </i>
        </h5>
      </div>
    </form>
  </card>
</template>
<script>
import BaseSlider from "@/components/BaseSlider";
import {
  LoginURL,
  clientId,
  clientSecret,
  apiDomain,
  getHeader,
  getcurrentDate,
} from "@/assets/js/config.js";
import axios from "axios";
import ErrorFunctionMixin from "@/assets/js/errorFunctionMix.js";

export default {
  mixins: [ErrorFunctionMixin],
  components: {
    BaseSlider,
  },
  created() {
    var currentDate = getcurrentDate();

    axios
      .get(apiDomain + "lookup/role_list", { headers: getHeader() })
      .then((response) => {
        if (response.status === 200) {
          this.roles = response.data.data;
        }
      })
      .catch((error) => {
        console.log(error.response.status);
        this.errorFunction(error, "Role Listing");
      });

    this.model.task_est_start_date = currentDate;
    this.model.task_est_end_date = currentDate;
    // this.model.task_start_date = currentDate
    // this.model.task_end_date = currentDate

    const LoginUser = JSON.parse(window.localStorage.getItem("loginUser"));
    this.myUserID = LoginUser;

    this.model.site_id = this.$route.params.id;

    axios
      .get(apiDomain + "project/project_site/" + this.model.site_id, {
        headers: getHeader(),
      })
      .then((response) => {
        if (response.status === 200) {
          this.model.project_id = response.data.data[0].project.project_id;
          this.model.project_name = response.data.data[0].project.project_name;
          this.model.group_id = response.data.data[0].group.group_id;
          this.model.group_name = response.data.data[0].group.group_name;
          this.model.site_name = response.data.data[0].site_name;
          this.getGroupFromProject();
          this.getSiteFromGroup();
          this.getContractorFromSite();
        }
      })
      .catch((error) => {
        console.log(error.response.status);
        this.errorFunction(error, "Project Information");
      });

    this.assignToMe();

    this.getProject();
    if (LoginUser.role_id == "9" || LoginUser.role_id == "10") {
      this.getTaskStatus();
    } else {
      this.getTaskStatusNormal();
    }
    this.getAssignToUser();
  },
  data() {
    return {
      model: {
        task_title: null,
        project_id: "x",
        project_name: null,
        group_id: "x",
        group_name: null,
        site_id: "x",
        site_name: null,
        milestone_id: "x",
        contractor_id: "x",
        assign_to_user: null,
        task_description: null,
        task_remarks: null,
        task_progress: 0,
        task_est_start_date: null,
        task_est_end_date: null,
        task_start_date: null,
        task_end_date: null,
        status_id: "x",
        reviewer_required: 0,
        reviewers: null,
      },
      myUserID: null,
      projects: [],
      project_groups: [],
      project_sites: [],
      project_milestones: [],
      task_status: [],
      site_contractors: [],
      user_list: [],
      reviewers: [{ reviewerRoleId: "", previousSelection: "" }],
      roles: [],
      tempEvent: null,
    };
  },
  methods: {
    removeFromList(event) {
      this.reviewers.map((e_rev) => {
        if (e_rev.reviewerRoleId == event.target.value) {
          if (e_rev.previousSelection != "") {
            this.roles.map((e_rol) => {
              if (e_rol.id == e_rev.previousSelection) {
                e_rol["disabled"] = false;
                e_rol["v_hide"] = false;
              }
            });
          }
        }
        this.roles.map((e_rol) => {
          if (e_rol.id == event.target.value) {
            e_rol["disabled"] = true;
            e_rol["v_hide"] = true;
          }
        });
        e_rev.previousSelection = e_rev.reviewerRoleId;
      });
    },
    addField(object, fieldType) {
      let newObject = {};
      for (const [key, value] of Object.entries(object)) {
        newObject[key] = "";
      }
      fieldType.push(newObject);
    },
    removeField(index, fieldType) {
      if (fieldType[index].reviewerRoleId != "") {
        this.roles.map((e_rol) => {
          if (e_rol.id == fieldType[index].reviewerRoleId) {
            e_rol["disabled"] = false;
            e_rol["v_hide"] = false;
          }
        });
      }
      fieldType.splice(index, 1);
    },
    getTaskStatusNormal() {
      axios
        .get(apiDomain + "lookup/task_status.normal", { headers: getHeader() })
        .then((response) => {
          if (response.status === 200) {
            this.task_status = response.data.data;
          }
        })
        .catch((error) => {
          console.log(error.response.status);
          this.errorFunction(error, "Task Status Information");
        });
    },
    assignToMe() {
      console.log(this.myUserID.id);
      this.model.assign_to_user = this.myUserID.id;
    },
    getUserListFromProjectContractor() {
      if (this.model.contractor_id == "y") {
        this.getUserListFromProject();
        this.assignToMe();
      } else {
        axios
          .get(
            apiDomain +
              "lookup/user_list_with_project_contractor/" +
              this.model.project_id +
              "/" +
              this.model.contractor_id,
            { headers: getHeader() }
          )
          .then((response) => {
            if (response.status === 200) {
              this.user_list = response.data.data;
              this.assignToMe();
              this.model.assign_to_user = "x";
            }
          })
          .catch((error) => {
            console.log(error.response.status);
            this.errorFunction(error, "Project User with Contractor Listing");
          });
      }
    },
    getUserListFromProject() {
      axios
        .get(
          apiDomain + "lookup/user_list_with_project/" + this.model.project_id,
          { headers: getHeader() }
        )
        .then((response) => {
          if (response.status === 200) {
            this.user_list = response.data.data;
            this.assignToMe();
          }
        })
        .catch((error) => {
          console.log(error.response.status);
          this.errorFunction(error, "Project User Listing");
        });
    },
    getAssignToUser() {
      // This is not a complete listing. After we settle logic get list of user for each project tested properly will come back and change this.
      axios
        .get(apiDomain + "lookup/user_list", { headers: getHeader() })
        .then((response) => {
          if (response.status === 200) {
            this.user_list = response.data.data;
          }
        })
        .catch((error) => {
          console.log(error.response.status);
          this.errorFunction(error, "Project User Listing");
        });
    },
    getProject() {
      axios
        .get(apiDomain + "project/project", { headers: getHeader() })
        .then((response) => {
          if (response.status === 200) {
            this.projects = response.data.data;
          }
        })
        .catch((error) => {
          console.log(error.response.status);
          this.errorFunction(error, "Project Information");
        });
    },
    getTaskStatus() {
      axios
        .get(apiDomain + "lookup/task_status", { headers: getHeader() })
        .then((response) => {
          if (response.status === 200) {
            this.task_status = response.data.data;
          }
        })
        .catch((error) => {
          console.log(error.response.status);
          this.errorFunction(error, "Task Status Information");
        });
    },
    getGroupFromProject() {
      axios
        .get(
          apiDomain +
            "project/project_group.show_by_project/" +
            this.model.project_id,
          { headers: getHeader() }
        )
        .then((response) => {
          if (response.status === 200) {
            this.project_groups = response.data.data;
          }
        })
        .catch((error) => {
          console.log(error.response.status);
          this.errorFunction(error, "Project Group Information");
        });

      axios
        .get(
          apiDomain +
            "project/project_milestone.show_by_project/" +
            this.model.project_id,
          { headers: getHeader() }
        )
        .then((response) => {
          if (response.status === 200) {
            this.project_milestones = response.data.data;
            this.model.milestone_id = "y";
          }
        })
        .catch((error) => {
          console.log(error.response.status);
          this.errorFunction(error, "Project Milestone Information");
        });
      this.project_sites = [];
      this.site_contractors = [];
      this.model.contractor_id = "x";
      this.getUserListFromProject();
    },
    getSiteFromGroup() {
      axios
        .get(
          apiDomain +
            "project/project_site.show_by_group/" +
            this.model.group_id,
          { headers: getHeader() }
        )
        .then((response) => {
          if (response.status === 200) {
            this.project_sites = response.data.data;
          }
        })
        .catch((error) => {
          console.log(error.response.status);
          this.errorFunction(error, "Project Site Information");
        });
    },
    getContractorFromSite() {
      axios
        .get(
          apiDomain +
            "project/site_contractor.show_by_site/" +
            this.model.site_id,
          { headers: getHeader() }
        )
        .then((response) => {
          if (response.status === 200) {
            this.site_contractors = response.data.data;
            this.model.contractor_id = "y";
          }
        })
        .catch((error) => {
          console.log(error.response.status);
          this.errorFunction(error, "Site Contractor Information");
        });
    },
    checkProject() {
      if (this.model.project_id == "x" || this.model.project_id == "y") {
        this.redErrorNotify(
          "<b>Invalid Input : Project </b> - Please select a Project before dive into the Details"
        );
      } else {
        this.$router.push({ path: "/projectEdit/" + this.model.project_id });
      }
    },
    checkGroup() {
      if (this.model.group_id == "x" || this.model.group_id == "y") {
        this.redErrorNotify(
          "<b>Invalid Input : Group </b> - Please select a Group before dive into the Details"
        );
      } else {
        this.$router.push({ path: "/projectGroupEdit/" + this.model.group_id });
      }
    },
    checkSite() {
      if (this.model.site_id == "x" || this.model.site_id == "y") {
        this.redErrorNotify(
          "<b>Invalid Input : Site </b> - Please select a Site before dive into the Details"
        );
      } else {
        this.$router.push({ path: "/projectSiteEdit/" + this.model.site_id });
      }
    },
    checkContrator() {
      if (this.model.contractor_id == "x" || this.model.contractor_id == "y") {
        this.redErrorNotify(
          "<b>Invalid Input : Contractor </b> - Please select a Contractor before dive into the Details"
        );
      } else {
        this.$router.push({
          path: "/contractorEdit/" + this.model.contractor_id,
        });
      }
    },
    checkAndCreateReviewers() {
      if (this.model.reviewer_required == 1) {
        let tempReviewers = [];
        this.reviewers.forEach((item) => {
          if (item.reviewerRoleId != "") {
            tempReviewers.push({ reviewerRoleId: item.reviewerRoleId });
          }
        });
        this.model.reviewers = tempReviewers;
      }
    },
    createData() {
      this.checkAndCreateReviewers();
      if (
        !this.model.task_title ||
        this.model.site_id == "x" ||
        this.model.site_id == "y" ||
        this.model.status_id == "x" ||
        this.model.status_id == "y" ||
        this.model.milestone_id == "x" ||
        this.model.milestone_id == "y"
      ) {
        if (!this.model.task_title) {
          this.redErrorNotify(
            "<b>Invalid Input : Task Title is a Mandatory in Task </b>"
          );
        } else if (
          this.model.project_id == "x" ||
          this.model.group_id == "y" ||
          this.model.site_id == "y"
        ) {
          this.redErrorNotify(
            "<b>Invalid Input : Project, Group & Site is a Mandatory in Task. Please Select A Project, Group & Site </b>"
          );
        } else if (this.model.contractor_id == "x") {
          this.redErrorNotify(
            "<b>Invalid Input : Contractor a Mandatory in Project Task. Please Select A Project, Group & Site </b>"
          );
        } else if (this.model.status_id == "x" || this.model.status_id == "y") {
          this.redErrorNotify(
            "<b>Invalid Input : Task Status is a Mandatory in Task. Please Select A Status </b>"
          );
        } else if (
          this.model.milestone_id == "x" ||
          this.model.milestone_id == "y"
        ) {
          this.redErrorNotify(
            "<b>Invalid Input : Task Milestone is a Mandatory in Task. Please Select A Milestone </b>"
          );
        } else {
          this.redErrorNotify(
            "<b>Invalid Input : Please fill in all the Mandatory Column in Task </b>"
          );
        }
      } else if (
        this.model.task_end_date < this.model.task_start_date ||
        this.model.task_est_end_date < this.model.task_est_start_date
      ) {
        this.redErrorNotify(
          "<b>Invalid Input : End Date </b> - All End Date must be equal or greater than start date."
        );
      } else if (
        this.model.task_progress < 0 ||
        this.model.task_progress > 100
      ) {
        this.redErrorNotify(
          "<b>Invalid Input : Task Progress </b> - Input Must be between 0 - 100"
        );
      } else if (
        this.model.reviewer_required == 1 &&
        this.model.reviewers.length < 1
      ) {
        this.redErrorNotify(
          "<b>Invalid Input : Reviewer(s) </b> - Requires Reviewer(s) but no Reviewer(s) selected."
        );
      } else {
        if (this.model.contractor_id == "y") {
          this.model.contractor_id = null;
        }

        axios
          .post(apiDomain + "task/task", this.model, { headers: getHeader() })
          .then((response) => {
            if (response.status === 200) {
              this.$notify({
                message:
                  "<b>New Task : " +
                  this.model.task_title +
                  "</b> - Successfully Created.",
                timeout: 10000,
                icon: "ni ni-bell-55",
                type: "default",
              });
              this.$router.back();
            }
          })
          .catch((error) => {
            console.log(error.response.data.errors);
            this.errorFunction(error, "New Task Information");
          });
      }
    },
  },
};
</script>
<style>
</style>
