var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"bg-default-dashboard"},[_c('div',{staticClass:" py-lg-8 pt-lg-5"},[_c('b-container',{staticStyle:{"width":"90%","position":"relative"},attrs:{"fluid":""}},[_c('b-row',[_c('b-breadcrumb',{attrs:{"items":_vm.route_items}})],1),_c('validation-observer',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{staticClass:"needs-validation",on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)}}},[_c('h1',{staticClass:"mb-4 text-white"},[_vm._v("Add New Document")]),_c('b-form-row',[_c('b-col',[_c('div',{staticClass:"text-white h3"},[_vm._v(_vm._s(_vm.setting.category1_name))]),_c('validation-provider',{attrs:{"name":_vm.setting.category1_name,"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"invalid-feedback":errors[0]}},[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.model.category1_id),expression:"model.category1_id"}],staticClass:"form-control",class:classes,on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.model, "category1_id", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},_vm._l((_vm.categories1),function(item){return _c('option',{key:item.id,domProps:{"value":item.id}},[_vm._v(_vm._s(item.code)+" - "+_vm._s(item.name))])}),0)])]}}],null,true)})],1),_c('b-col',[_c('div',{staticClass:"text-white h3"},[_vm._v(_vm._s(_vm.setting.category2_name))]),_c('validation-provider',{attrs:{"name":_vm.setting.category2_name,"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"invalid-feedback":errors[0]}},[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.model.category2_id),expression:"model.category2_id"}],staticClass:"form-control",class:classes,on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.model, "category2_id", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},_vm._l((_vm.categories2),function(item){return _c('option',{key:item.id,domProps:{"value":item.id}},[_vm._v(_vm._s(item.code)+" - "+_vm._s(item.name))])}),0)])]}}],null,true)})],1)],1),(_vm.setting.category3_enabled)?_c('b-form-row',[_c('b-col',[_c('div',{staticClass:"text-white h3"},[_vm._v(_vm._s(_vm.setting.category3_name))]),_c('validation-provider',{attrs:{"name":_vm.setting.category3_name,"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"invalid-feedback":errors[0]}},[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.model.category3_id),expression:"model.category3_id"}],staticClass:"form-control",class:classes,on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.model, "category3_id", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},_vm._l((_vm.categories3),function(item){return _c('option',{key:item.id,domProps:{"value":item.id}},[_vm._v(_vm._s(item.code)+" - "+_vm._s(item.name))])}),0)])]}}],null,true)})],1),_c('b-col',[_c('div',{staticClass:"text-white h3"},[_vm._v("Position")]),_c('validation-provider',{attrs:{"name":"Position","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"invalid-feedback":errors[0]}},[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.model.position),expression:"model.position"}],staticClass:"form-control",class:classes,on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.model, "position", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{domProps:{"value":0}},[_vm._v("First")]),_vm._l((_vm.documents),function(item){return _c('option',{key:item.id,domProps:{"value":item.sequence}},[_vm._v("After: "+_vm._s(item.title)+" ")])})],2)])]}}],null,true)})],1)],1):_vm._e(),_c('b-form-row',[_c('b-col',[_c('div',{staticClass:"text-white h3"},[_vm._v("Title")]),_c('validation-provider',{attrs:{"name":"Title","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"invalid-feedback":errors[0]}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.model.title),expression:"model.title"}],staticClass:"form-control",class:classes,attrs:{"type":"text","maxlength":"300"},domProps:{"value":(_vm.model.title)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.model, "title", $event.target.value)}}})])]}}],null,true)})],1),_c('b-col',[_c('div',{staticClass:"text-white h3"},[_vm._v("Alternative Title")]),_c('validation-provider',{attrs:{"name":"Alternative Title"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"invalid-feedback":errors[0]}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.model.alt_title),expression:"model.alt_title"}],staticClass:"form-control",class:classes,attrs:{"type":"text","maxlength":"300"},domProps:{"value":(_vm.model.alt_title)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.model, "alt_title", $event.target.value)}}})])]}}],null,true)})],1)],1),_c('b-form-row',[_c('b-col',[_c('div',{staticClass:"text-white h3"},[_vm._v("Reviewer/Approver Roles")]),_c('validation-provider',{attrs:{"name":"Reviewer/Approver Roles","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"invalid-feedback":errors[0]}},[_c('b-form-checkbox-group',{staticClass:"text-left text-white border rounded p-2",staticStyle:{"background-color":"#2C545A"},attrs:{"options":_vm.roles,"stacked":"","state":errors[0] ? false : null},model:{value:(_vm.selectedRoles),callback:function ($$v) {_vm.selectedRoles=$$v},expression:"selectedRoles"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors[0] ? false : null}},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1),_c('b-col')],1),(_vm.access.create)?_c('button',{staticClass:"btn btn-primary"},[_vm._v("Create")]):_vm._e(),_c('button',{staticClass:"btn btn-primary",attrs:{"type":"button"},on:{"click":function($event){return _vm.onCancel()}}},[_vm._v("Cancel")])],1)]}}])})],1)],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }