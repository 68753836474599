<template>
  <div class="bg-default-dashboard">
    <div class=" py-lg-8 pt-lg-5">
      <b-container fluid style="width: 90%; position: relative;">
        <!-- <div class="header-body "  > -->
        <b-row class="mb-2">
          <b-col md="12">
            <card class="bg-card-dashboard">
              <b-row class="mb-6">
                <b-col md="8">
                  <span class="text-white display-2 mr-3 ">{{ model.project_name }}</span>
                  <a class="btn btn-sm btn-warning rounded-pill text-default " style="position: relative; top: -10px;">
                    <i class="fa fa-xs fa-circle mr-1"></i>In Progress</a>
                </b-col>
                <b-col md="4" class="text-right">
                  <span class="text-white" style="position: relative; top: 20px;"> PPA Signed Date :
                    <strong>{{ model.project_ppa_start_date }}</strong></span>
                </b-col>
              </b-row>
              <b-row class="mb-6">
                <b-col md="4">
                  <div class="text-left">
                    <span>
                      <img src="/img/theme/general/capacity.png" class="mr-2" alt="">
                      <span class="text-white lead text-muted">Total Capacity</span>
                    </span>
                    <br>
                    <span class="text-white display-4 ml-3 ">{{ model.project_ppa_approved_capacity }} kWp</span>
                  </div>
                </b-col>
                <b-col md="4">
                  <div class="text-center">
                    <span>
                      <img src="/img/theme/general/developer.png" class="mr-2" alt="">
                      <span class="text-white lead text-muted">Developer</span>
                    </span>
                    <br>
                    <span class="text-white display-4 ml-3 ">{{ model.developer_name }}</span>
                  </div>
                </b-col>
                <b-col md="4">
                  <div class="text-right">
                    <span>
                      <img src="/img/theme/general/watt.png" class="mr-2" alt="">
                      <span class="text-white lead text-muted">Watt Class</span>
                    </span>
                    <br>
                    <span class="text-white display-4 mr-2 ">{{ model.project_watt_class }} Wp</span>
                  </div>
                </b-col>
              </b-row>
            </card>
          </b-col>
        </b-row>
        <b-row class="mb-4">
          <b-col md="3" class="mb-3">
            <div class="btn-group" role="group" aria-label="Basic example">
              <base-button v-for="site in siteListingData" :key="site.id"
                :type="site.id == 1 ? 'success' : 'secondary'">
                {{ site.site_name }}
              </base-button>
            </div>
          </b-col>
        </b-row>
        <!-- technical information -->
        <b-row class="mb-1">
          <b-col md="12">
            <card class="bg-card-dashboard">
              <b-row>
                <b-col md="6">
                  <div class="mb-3">
                    <span class="text-white lead text-muted">Site Name : <strong class="h1 text-white">{{
                      siteListingData[0].site_name }}</strong></span>
                  </div>
                  <div class="mb-0">
                    <span>
                      <img src="/img/theme/general/map.png" alt="" class="mr-3">
                      <span class="text-white lead"><strong>{{ siteListingData[0].site_address }}</strong></span>
                    </span>
                  </div>
                  <b-row>
                    <b-col class="text-white lead">Site Capacity </b-col>
                    <b-col class="text-white lead"><strong>: {{ siteListingData[0].site_total_capacity }} kWp</strong>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col class="text-white lead">Planned Turn On </b-col>
                    <b-col class="text-white lead"><strong>: {{ siteListingData[0].site_target_turn_on_date }}</strong>
                    </b-col>
                  </b-row>
                </b-col>
                <b-col>
                  <div class="mb-3">
                    <span class="text-white lead text-muted">Site Code : <strong class="h1 text-white">{{
                      siteListingData[0].site_code }}</strong></span>
                  </div>
                  <div>
                    <b-row class="mb-2">
                      <b-col class="text-white lead">Postal code </b-col>
                      <b-col class="text-white lead"><strong>: {{ siteListingData[0].site_target_turn_on_date
                          }}</strong> </b-col>
                    </b-row>
                  </div>
                  <b-row>
                    <b-col class="text-white lead">As Built Capacity </b-col>
                    <b-col class="text-white lead"><strong>: {{ siteListingData[0].site_as_built_capacity }}
                        kWp</strong> </b-col>
                  </b-row>
                  <b-row>
                    <b-col class="text-white lead">Actual Turn On Date </b-col>
                    <b-col class="text-white lead"><strong>: {{ siteListingData[0].site_actual_turn_on_date }}</strong>
                    </b-col>
                  </b-row>
                </b-col>

              </b-row>
            </card>
          </b-col>
        </b-row>
        <!-- filter -->
        <b-row>
          <b-col md="1" class="mt-1">
            <base-input>
              <select class="form-control" v-model="selectedCategory1">
                <option v-for="item of categories1" :key="item.id" :value="item.id" :label="item.code"></option>
              </select>
            </base-input>
          </b-col>
          <b-col md="1" class="mt-1">
            <base-input>
              <select class="form-control" v-model="selectedCategory2">
                <option v-for="item of categories2" :key="item.id" :value="item.id" :label="item.code"></option>
              </select>
            </base-input>
          </b-col>
          <b-col md="1" class="mt-1">
            <template v-if="setting.category3_enabled">
              <base-input>
                <select class="form-control" v-model="selectedCategory3">
                  <option v-for="item of categories3" :key="item.id" :value="item.id" :label="item.code"></option>
                </select>
              </base-input>
            </template>
          </b-col>
          <!--           
          <b-col md="1" class="mt-1">
            <b-link class="btn btn-dark">
              <i class="fa fa-cog" style="font-size: 23px;"></i>
            </b-link>
          </b-col> 
          -->
          <b-col class="text-right" v-if="!obj_flag.docTemplatApplied">
            <b-link class="btn btn-dark" @click="modalTemplateShow">
              <i class="fa fa-upload mr-1" style="font-size: 17px;"></i>
              Document Template
            </b-link>
          </b-col>
        </b-row>
        <b-row class="mt-0">
          <b-col md="12">
            <table style="width: 100%;" id="my-table">
              <thead
                style="background-color: #23454A; color: white; font-size: small; border-bottom: 10px solid #082A2F;">
                <th class="th-table-new ">{{ setting.category1_name }}</th>
                <th class="th-table-new ">{{ setting.category2_name }}</th>
                <th class="th-table-new " v-if="setting.category3_enabled">{{ setting.category3_name }}</th>
                <th class="th-table-new ">Number</th>
                <th class="th-table-new ">Title </th>
                <!--<th class="th-table-new ">Alternative Title </th>-->
                <th class="th-table-new ">Status </th>
                <th class="th-table-new "></th>
              </thead>
              <tbody>
                <tr v-for="item in documents" :key="item.id"
                  style="background-color: #23454A; color: white; font-size: smaller; border-bottom:  5px solid #082A2F;">
                  <td class="tr-td-table-new" style="min-width: 150px;">{{ item.category1_code }}</td>
                  <td class="tr-td-table-new" style="min-width: 150px;">{{ item.category2_code }}</td>
                  <td class="tr-td-table-new" style="min-width: 150px;" v-if="setting.category3_enabled">{{
                    item.category3_code
                    }}</td>
                  <td class="tr-td-table-new" style="min-width: 150px;">{{ item.number }}</td>
                  <td class="tr-td-table-new" style="min-width: 150px;">{{ item.title }}</td>
                  <!--<td class="tr-td-table-new" style="min-width: 150px;">{{ item.alt_title }}</td>-->
                  <!-- <td class="tr-td-table-new" style="min-width: 150px;">{{ item.status }}</td> -->
                  <td class="tr-td-table-new" style="min-width: 100px;">
                    <i class="fa fa-xs fa-circle mr-1" style="color: #46D275;" v-if="item.status == 'Approve'"></i>
                    <i class="fa fa-xs fa-circle mr-1" style="color: #FF9A3D;"
                      v-if="item.status == 'PENDING_REVIEW'"></i>
                    <i class="fa fa-xs fa-circle mr-1" style="color: #11CDEF;" v-if="item.status == 'New'"></i>
                    <i class="fa fa-xs fa-circle mr-1" style="color: #FF3232;" v-if="item.status == 'Rejected'"></i>
                    <i class="fa fa-xs fa-circle mr-1" style="color: #FFD43D;"
                      v-if="item.status == 'Being Reviewed'"></i>
                    <i class="fa fa-xs fa-circle mr-1" style="color: white;" v-if="item.status == 'Empty'"></i>
                    <!-- <i class="fa fa-xs fa-circle mr-1"  v-else></i> -->
                    {{ item.status }}
                  </td>
                  <td class="tr-td-table-new" style="min-width: 100px;">
                    <div>
                      <b-link v-if="role_user == 'contractor'" to="tracking" style="color: #FF5C00; font-size:large;">
                        <svg width="23" height="25" viewBox="0 0 28 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M21.2396 9.71982L21.857 9.10116C22.3485 8.60944 23.0152 8.33313 23.7105 8.33301C24.4058 8.33288 25.0726 8.60895 25.5643 9.10049C26.056 9.59202 26.3323 10.2588 26.3325 10.954C26.3326 11.6493 26.0565 12.3161 25.565 12.8078L24.9476 13.4265M21.2396 9.71982C21.2396 9.71982 21.317 11.0318 22.4756 12.1905C23.6343 13.3492 24.9476 13.4265 24.9476 13.4265M21.2396 9.71982L15.5596 15.3998C15.173 15.7838 14.981 15.9772 14.8156 16.1892C14.621 16.4398 14.4543 16.7092 14.317 16.9972C14.201 17.2398 14.1156 17.4972 13.9436 18.0132L13.393 19.6665L13.2143 20.2012M24.9476 13.4265L19.2676 19.1065C18.881 19.4932 18.689 19.6852 18.477 19.8505C18.2263 20.0452 17.957 20.2118 17.669 20.3492C17.4263 20.4652 17.169 20.5505 16.653 20.7225L14.9996 21.2732L14.465 21.4518M13.2143 20.2012L13.037 20.7372C12.9957 20.8616 12.9898 20.995 13.02 21.1226C13.0502 21.2502 13.1153 21.3668 13.208 21.4595C13.3007 21.5522 13.4173 21.6173 13.5449 21.6475C13.6724 21.6777 13.8059 21.6718 13.9303 21.6305L14.465 21.4518M13.2143 20.2012L14.465 21.4518"
                            stroke="#FF5C00" stroke-width="1.5" />
                          <path
                            d="M7.66667 16.3332H11M7.66667 10.9998H16.3333M7.66667 21.6665H9.66667M23.4373 3.22917C21.876 1.6665 19.3613 1.6665 14.3333 1.6665H11.6667C6.63867 1.6665 4.124 1.6665 2.56267 3.22917C1 4.7905 1 7.30517 1 12.3332V17.6665C1 22.6945 1 25.2092 2.56267 26.7705C4.124 28.3332 6.63867 28.3332 11.6667 28.3332H14.3333C19.3613 28.3332 21.876 28.3332 23.4373 26.7705C24.696 25.5132 24.94 23.6398 24.988 20.3332"
                            stroke="#FF5C00" stroke-width="1.5" stroke-linecap="round" />
                        </svg>
                      </b-link>
                      <b-link v-if="role_user == 'user_side'" @click="modalRevisionShow"
                        style="color: #FF5C00; font-size:large;">
                        <svg width="23" height="25" viewBox="0 0 28 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M21.2396 9.71982L21.857 9.10116C22.3485 8.60944 23.0152 8.33313 23.7105 8.33301C24.4058 8.33288 25.0726 8.60895 25.5643 9.10049C26.056 9.59202 26.3323 10.2588 26.3325 10.954C26.3326 11.6493 26.0565 12.3161 25.565 12.8078L24.9476 13.4265M21.2396 9.71982C21.2396 9.71982 21.317 11.0318 22.4756 12.1905C23.6343 13.3492 24.9476 13.4265 24.9476 13.4265M21.2396 9.71982L15.5596 15.3998C15.173 15.7838 14.981 15.9772 14.8156 16.1892C14.621 16.4398 14.4543 16.7092 14.317 16.9972C14.201 17.2398 14.1156 17.4972 13.9436 18.0132L13.393 19.6665L13.2143 20.2012M24.9476 13.4265L19.2676 19.1065C18.881 19.4932 18.689 19.6852 18.477 19.8505C18.2263 20.0452 17.957 20.2118 17.669 20.3492C17.4263 20.4652 17.169 20.5505 16.653 20.7225L14.9996 21.2732L14.465 21.4518M13.2143 20.2012L13.037 20.7372C12.9957 20.8616 12.9898 20.995 13.02 21.1226C13.0502 21.2502 13.1153 21.3668 13.208 21.4595C13.3007 21.5522 13.4173 21.6173 13.5449 21.6475C13.6724 21.6777 13.8059 21.6718 13.9303 21.6305L14.465 21.4518M13.2143 20.2012L14.465 21.4518"
                            stroke="#FF5C00" stroke-width="1.5" />
                          <path
                            d="M7.66667 16.3332H11M7.66667 10.9998H16.3333M7.66667 21.6665H9.66667M23.4373 3.22917C21.876 1.6665 19.3613 1.6665 14.3333 1.6665H11.6667C6.63867 1.6665 4.124 1.6665 2.56267 3.22917C1 4.7905 1 7.30517 1 12.3332V17.6665C1 22.6945 1 25.2092 2.56267 26.7705C4.124 28.3332 6.63867 28.3332 11.6667 28.3332H14.3333C19.3613 28.3332 21.876 28.3332 23.4373 26.7705C24.696 25.5132 24.94 23.6398 24.988 20.3332"
                            stroke="#FF5C00" stroke-width="1.5" stroke-linecap="round" />
                        </svg>
                      </b-link>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
            <b-row class="mt-2">
              <b-col md="1">
                <!-- Rows per page : -->
                <base-input>
                  <select style="width: 100px;" v-model="perPage" @change="getPagination" class="form-control">
                    <option v-for="item in filterOption" :key="item.value" :value="item.value"> {{ item.label }}
                    </option>
                  </select>
                </base-input>
              </b-col>
              <b-col>
                <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" first-number align="right"
                  @input="getPagination" aria-controls="my-table"></b-pagination>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        <!-- modal for template -->
        <b-modal ref="my-modal-template" centered hide-footer title="">
          <div class="text-center text-white ">
            <div class=""><small>Site Name</small></div>
            <div class="mt-1"><strong>{{ siteListingData[0].site_name }}</strong></div>
            <div class="mb-1"><small>Select Template</small></div>
            <base-input>
              <select class="form-control" v-model="obj_model.selected_template">
                <!-- <option>--please Select a Template--</option> -->
                <option v-for="item of obj_dd_list.doc_template" :key="item.id" :value="item.id" :label="item.name">
                </option>
              </select>
            </base-input>
            <div class="mt-2">
              <!-- <base-button type="primary" style="width: 180px;" native-type="submit">Upload Document</base-button> -->
              <base-button type="primary" style="width: 180px;" @click="modalTemplateSubmit">Upload
                Document</base-button>
              <base-button type="outline-danger" @click="modalTemplateHide">Cancel</base-button>
            </div>
          </div>
        </b-modal>
        <!-- model for revision -->
        <b-modal ref="my-modal-revision" size="xl" centered hide-footer title="Revision" class="text-white">
          <b-row>
            <b-col>
              <label class="text-white" for="">Comment</label>
              <b-form-textarea id="textarea-no-resize" placeholder="" rows="3" no-resize></b-form-textarea>
            </b-col>
          </b-row>
          <br>
          <b-row>
            <b-col>
              <table style="width: 100%;">
                <thead
                  style="background-color: #23454A; color: white; font-size: small; border-bottom: 10px solid  #306b74;">
                  <th class="th-table-modal-new ">File </th>
                  <th class="th-table-modal-new ">Size</th>
                  <th class="th-table-modal-new "></th>
                </thead>
                <tbody>
                  <tr v-for="item in items_file" :key="item.id"
                    style="background-color: #3a656b; color: white; font-size: smaller; border-bottom:  5px solid  #306b74;">
                    <td class="tr-td-table-modal-new"> <i class="fa fa-file mr-1"></i> {{ item.file }}</td>
                    <td class="tr-td-table-modal-new">{{ item.size }}</td>

                    <td class="tr-td-table-modal-new">
                      <div style="">
                        <b-link>
                          <i class="fa fa-trash" style="color: #FF3232; font-size:small;"></i>
                        </b-link>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </b-col>
            <b-col>
              <b-row>
                <b-col class="text-right">
                  <br>
                  <span class="text-white "><i class="fa fa-file mr-2" style="font-size: large;"></i>Testing.jpg <sup
                      style="padding-left: 3px;">
                      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0_1457_587)">
                          <path
                            d="M13.6541 2.34121C12.9119 1.59736 12.03 1.00749 11.0591 0.605461C10.0883 0.203434 9.04757 -0.00282109 7.99676 -0.00145813C3.57809 -0.00145813 -0.00390625 3.58054 -0.00390625 7.99921C-0.00390625 10.2085 0.892094 12.2092 2.34009 13.6572C3.08231 14.4011 3.96418 14.9909 4.93504 15.393C5.9059 15.795 6.94662 16.0012 7.99743 15.9999C12.4161 15.9999 15.9981 12.4179 15.9981 7.99921C15.9981 5.78988 15.1021 3.78921 13.6541 2.34121ZM12.6261 12.6259C12.0189 13.2346 11.2974 13.7174 10.5031 14.0464C9.70875 14.3755 8.85722 14.5443 7.99743 14.5432C4.38209 14.5432 1.45143 11.6125 1.45143 7.99721C1.45035 7.13742 1.61918 6.28589 1.94822 5.49155C2.27726 4.69722 2.76002 3.97573 3.36876 3.36854C3.9758 2.75979 4.69717 2.27702 5.4914 1.94798C6.28563 1.61893 7.13707 1.45011 7.99676 1.45121C11.6114 1.45121 14.5421 4.38188 14.5421 7.99654C14.5432 8.85624 14.3744 9.70769 14.0454 10.5019C13.7163 11.2962 13.2335 12.0175 12.6248 12.6245L12.6261 12.6259Z"
                            fill="#FF6060" />
                          <path
                            d="M9.02503 7.99983L11.595 5.42983C11.7218 5.29165 11.7903 5.10984 11.7862 4.92236C11.7822 4.73488 11.7059 4.55622 11.5732 4.42366C11.4406 4.2911 11.2619 4.2149 11.0744 4.21096C10.8869 4.20701 10.7051 4.27564 10.567 4.4025L10.5677 4.40183L7.99769 6.97183L5.42769 4.40183C5.28951 4.27506 5.10771 4.20656 4.92023 4.21062C4.73275 4.21469 4.55408 4.291 4.42152 4.42365C4.28897 4.55629 4.21276 4.73501 4.20882 4.92249C4.20488 5.10997 4.2735 5.29173 4.40036 5.42983L4.39969 5.42916L6.9697 7.99916L4.39969 10.5692C4.32739 10.6355 4.26925 10.7158 4.22878 10.8052C4.18832 10.8946 4.16636 10.9912 4.16423 11.0893C4.16211 11.1875 4.17985 11.285 4.21641 11.376C4.25296 11.4671 4.30756 11.5498 4.37693 11.6193C4.44629 11.6887 4.52899 11.7433 4.62003 11.7799C4.71107 11.8165 4.80858 11.8344 4.90669 11.8323C5.0048 11.8302 5.10147 11.8083 5.1909 11.7679C5.28032 11.7275 5.36064 11.6694 5.42703 11.5972L5.42769 11.5965L7.99769 9.0265L10.5677 11.5965C10.634 11.6688 10.7143 11.7269 10.8037 11.7674C10.8931 11.8079 10.9898 11.8298 11.0879 11.832C11.186 11.8341 11.2835 11.8163 11.3746 11.7798C11.4656 11.7432 11.5484 11.6886 11.6178 11.6193C11.6872 11.5499 11.7418 11.4672 11.7785 11.3762C11.8151 11.2851 11.8329 11.1876 11.8308 11.0895C11.8288 10.9914 11.8069 10.8947 11.7665 10.8053C11.726 10.7159 11.668 10.6356 11.5957 10.5692L11.595 10.5685L9.02503 7.99983Z"
                            fill="#FF6060" />
                        </g>
                        <defs>
                          <clipPath id="clip0_1457_587">
                            <rect width="16" height="16" fill="white" />
                          </clipPath>
                        </defs>
                      </svg>
                    </sup>
                  </span>
                </b-col>
                <b-col class="text-right">
                  <br>
                  <b-button size="sm" variant="primary">Choose File</b-button>
                  <b-button size="sm" variant="primary">Upload File</b-button>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
          <b-row class="mt-3">
            <b-col class="text-right">
              <b-link size="md" to="tracking" class="btn btn-primary">Doc History</b-link>
              <b-button size="md" variant="warning">Rejected Revision</b-button>
              <b-button size="md" variant="success">Approve Revision</b-button>
            </b-col>
          </b-row>
        </b-modal>
      </b-container>

    </div>


  </div>
</template>
<script>
import { Table, TableColumn, Select, Option } from 'element-ui'
import {
  LoginURL,
  clientId,
  clientSecret,
  apiDomain,
  getHeader,
  getcurrentDate,
} from "@/assets/js/config.js";
//import axios from "axios";
import ErrorFunctionMixin from "@/assets/js/errorFunctionMix.js";
import moment from "moment";
import ProjectSiteTemplateViewForm from '@/views/SemCorp/Template/Project/ProjectSiteTemplateViewForm.vue';
import axios from '@/util/axios';
import { getAccessByModule } from '@/util/access';


export default {
  name: "Project Landing",
  mixins: [ErrorFunctionMixin],
  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
  },
  watch: {
    selectedCategory1() {
      this.loadDocuments()
    },
    selectedCategory2() {
      this.loadDocuments()
    },
    selectedCategory3() {
      this.loadDocuments()
    },
  },
  created() {
    const LoginUser = JSON.parse(window.localStorage.getItem("loginUser"));
    this.myUserID = LoginUser;
    this.getDashboardData();
    this.getSiteListing();

  },
  data() {
    return {
      items: [],
      items_file: [
        {
          id: 0,
          file: "Testing",
          Uploaded_by: "PNM",
          Uploaded_at: "9 July 2024",
          size: "1.32 MB",
          status: "Pending",
        },
      ],
      sites: [
        { id: 1, name: "Site 1" },
        { id: 2, name: "Site 2" },
      ],
      // role_user :"user_side", // contractor or user_side
      role_user: "contractor", // contractor or user_side
      filterOption: [
        {
          label: 5,
          value: 5
        },
        {
          label: 10,
          value: 10
        },
        {
          label: 25,
          value: 25
        },
        {
          label: 50,
          value: 50
        },
      ],
      all_data: [],
      selectedFilter: 5,
      file_uploads: false,
      rows: 15,
      perPage: 5,
      currentPage: 1,
      model: {
        project_id: null,
        developer_id: "x",
        project_type_id: "x",
        project_manager: "x",
        project_manager_name: "x",
        project_engineer: "x",
        project_engineer_name: "x",
        project_business_development: "x",
        project_business_development_name: "x",
        project_safety: "x",
        project_safety_name: "x",
        project_name: null,
        project_ppa_approved_capacity: null,
        project_ppa_start_date: null,
        project_watt_class: null,
        roofed_handover_date: null,
        project_tag: null,
        site_id: null,
        developers: [],
        project_type: [],
        project_managers: [],
        project_engineers: [],
        project_business_development: [],
        project_safety: [],
        site_status: [],
        isSolarNovaProject: false,
        items: [],
      },
      siteListingData: [],
      title: 'Document Tracking',
      pid: null,
      sid: null,
      setting: {},
      categories1: [],
      categories2: [],
      categories3: [],
      selectedCategory1: null,
      selectedCategory2: null,
      selectedCategory3: null,
      documents: [],
      access: {},
      obj_dd_list: {},
      obj_flag: {
        docTemplatApplied: false,
      },
      obj_model: {
        selected_template: null,
      }
    };
  },
  methods: {
    loadSetting() {
      axios.get(`doc_repo/p/${this.pid}/s/${this.sid}/setting`)
        .then(response => {
          this.setting = response.data.data
        })
        .catch(error => {
          this.errorFunction(error, this.title)
        })
    },
    loadCategories() {
      axios.get(`doc_repo/p/${this.pid}/s/${this.sid}/category`)
        .then(response => {
          const categories = response.data.data
          this.categories1 = categories.filter(x => x.level == 1)
          this.categories2 = categories.filter(x => x.level == 2)
          this.categories3 = categories.filter(x => x.level == 3)

          //_ add filter -- ALL --
          this.categories1 = this.setupAllInsideCategories(this.categories1);
          this.categories2 = this.setupAllInsideCategories(this.categories2);
          this.categories3 = this.setupAllInsideCategories(this.categories3);
        })
        .catch(error => {
          this.errorFunction(error, this.title)
        })
    },
    loadDocuments() {
      // console.log('loadDocuments');
      const options = {
        params: {
          category1_id: this.selectedCategory1,
          category2_id: this.selectedCategory2,
          category3_id: this.selectedCategory3,
        },
      }

      axios.get(`doc_repo/p/${this.pid}/s/${this.sid}/document`, options)
        .then(response => {
          this.documents = response.data.data;
          this.obj_flag.docTemplatApplied = (this.documents.length > 0) ? true : false;
          // console.log(this.documents);
          // console.log(this.documents.length);
          // console.log(this.obj_flag.docTemplatApplied);
        })
        .catch(error => {
          this.errorFunction(error, this.title)
        })
    },
    //_ custom methods
    //  > de-structure the array and insert at the beginning an -- ALL -- option
    setupAllInsideCategories(theCategory) {
      return [
        {
          id: null,
          code: "-- ALL --",
          name: "-- ALL --"
        },
        ...theCategory
      ];
    },
    generateData() {
      this.all_data = [];
      var status = ["Approve", "Pending", "New", "Rejected", "Being Reviewed", "Empty"];
      for (let index = 1; index <= this.rows; index++) {
        var status_num = 0;
        if (index % 2 == 0) {
          status_num = 1;
        }
        var name = "Test " + index.toString()
        this.all_data.push({
          id: index,
          cat_a: "Eng",
          cat_b: "DR",
          cat_c: "DR",
          number: "XXX-00-ENG-DR-0001",
          title: name,
          alt_title: "UI/UX",
          status: status[Math.floor(Math.random() * 5)],
          created_at: "24 Juni 2024"
        })
      }
    },
    changePerpage(perPage = 5) {
      this.perPage = perPage;
      // console.log(this.currentPage, this.perPage)
    },
    getPagination() {
      this.items = [];

      let from = 0
      if (this.currentPage == 1) {
        from = (this.currentPage - 1)
      } else {
        from = (this.currentPage - 1) * this.perPage;
      }
      this.items = this.all_data.slice(from, this.perPage * this.currentPage)
      // console.log(this.currentPage, this.perPage)
    },
    getDashboardData() {
      this.model.project_id = this.$route.params.id;
      axios
        .get(apiDomain + "project/project/" + this.model.project_id, {
          headers: getHeader(),
        })
        .then((response) => {
          if (response.status === 200) {
            this.model.project_name = response.data.data[0].project_name;
            document.title = 'MOMENS-' + response.data.data[0].project_name;
            this.model.developer_id = response.data.data[0].developer.developer_id;
            this.model.developer_name = response.data.data[0].developer.developer_name;
            this.model.project_type_id = response.data.data[0].project_type.project_type_id;
            if (this.model.project_type_id != null &&
              ([2, 3, 4, 9, 10, 11, 12, 13, 14].includes(this.model.project_type_id))
            ) {
              this.isSolarNovaProject = true;
            } else {
              this.isSolarNovaProject = false;
            }
            this.model.project_type_name = response.data.data[0].project_type.project_type;
            this.model.project_ppa_counter_party = response.data.data[0].project_ppa_counter_party;
            let this_project_ppa_start_date = (response.data.data[0].project_ppa_start_date !== null) ? response.data.data[0].project_ppa_start_date : "N/A";
            if (this_project_ppa_start_date !== "N/A") {
              let convertProjectStartDateDate = new Date(this_project_ppa_start_date);
              const year = convertProjectStartDateDate.getFullYear();
              const month = convertProjectStartDateDate.toLocaleString('en-US', { month: 'short' });
              const day = convertProjectStartDateDate.toLocaleString('en-US', { day: '2-digit' });
              this_project_ppa_start_date = `${day}-${month}-${year}`;
            }

            this.model.project_ppa_start_date = this_project_ppa_start_date;
            this.model.project_ppa_approved_capacity = parseFloat(response.data.data[0].project_ppa_approved_capacity.replace(/,/g, ""));
            if (response.data.data[0].project_watt_class !== null) {
              this.model.project_watt_class = parseFloat(response.data.data[0].project_watt_class.replace(/,/g, ""));
            }
            this.model.project_manager = response.data.data[0].project_manager.id;
            this.model.project_manager_name = response.data.data[0].project_manager.name;
            this.model.project_engineer = response.data.data[0].project_engineer.id;
            this.model.project_engineer_name = response.data.data[0].project_engineer.name;
            this.model.project_business_development = response.data.data[0].project_business_development.id;
            this.model.project_business_development_name = response.data.data[0].project_business_development.name;
            this.model.project_safety = response.data.data[0].project_safety.id;
            this.model.project_safety_name = response.data.data[0].project_safety.name;
            this.model.project_tag = response.data.data[0].project_tag;
          }
        })
        .catch((error) => {
          // console.log(error.response.status);
          if (error.response.status == 401) {
            this.$router.push("/login");
          }
        });
    },
    getSiteListing() {
      axios.get(apiDomain + 'project/project_site.show_by_project/' + this.model.project_id, { headers: getHeader() })
        .then(response => {
          // console.log('getSiteListing');
          // console.log(response.data);
          if (response.status === 200) {
            let data = response.data.data;
            //data.forEach(item => {
            //    item.visible = false;
            //});
            this.siteListingData = data;
            this.pid = this.$route.params.id;
            this.sid = this.siteListingData[0].site_id;
            this.access = getAccessByModule('DOC_REPO_REVIEW');
            //this.getSiteListing();

            this.loadSetting();
            this.loadCategories();
            this.loadDocuments();
          }
        })
        .catch(error => {
          console.log(error.response.status)
        });
    },
    //_ modal Template
    modalTemplateShow() {
      this.$refs['my-modal-template'].show();
    },
    modalTemplateHide() {
      this.$refs['my-modal-template'].hide();
    },
    getDataTemplate() {
      // console.log('getDataTemplate');

      axios.get(apiDomain + 'setup/doc_repo/template', { headers: getHeader() })
        .then(response => {
          if (response.status === 200) {
            this.obj_dd_list.doc_template = response.data.data;
          }
        })
        .catch(error => {
          console.log(error.response.status)
        });
    },
    modalTemplateSubmit() {
      if (this.obj_model.selected_template === null) {
        this.$notify({
          message:
            '<b>Invalid Input : </b> Please select a Document Template.',
          timeout: 10000,
          icon: 'ni ni-bulb-61',
          type: 'danger',
        });
      } else {
        // console.log(this.pid + ' <vs> ' + this.sid);
        // console.log(this.obj_model.selected_template);

        const prm_body = {
          template_id: this.obj_model.selected_template,
        }

        axios.post(`doc_repo/p/${this.pid}/s/${this.sid}/setting`, prm_body)
          .then(response => {
            this.$router.go()
          })
          .catch(error => {
            this.errorFunction(error, this.title)
          })
      }
    },
    //_ modal Revision
    modalRevisionShow() {
      this.$refs['my-modal-revision'].show();
    },
  },
  mounted() {
    this.getDataTemplate();
  }
};
//mounted() {
//this.generateData();
// this.perPage;
//this.getPagination();

//}
//};
</script>

<style scoped>
.card {
  background-color: #082a2f;
}

/* .modal-content {
  background-color: #306b74 !important;
}

.modal-header {
  padding-bottom: 0px;
} */
</style>

<style>
.modal-content {
  background-color: #306b74;
}

.modal-header {
  padding-bottom: 0px;
}
</style>

<style scoped>
table thead th {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  font-size: 0.65rem;
  text-transform: uppercase;
  letter-spacing: 1px;
  background-color: #3a656b;
  color: white;
  padding: 20px;
  border-bottom: 0px solid #082A2F;
  border-top: 0px solid #e9ecef;
}

.th-table-modal-new {
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 10px;
}

.tr-td-table-modal-new {
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 10px;
}

.th-table-new {
  padding-top: 30px;
  padding-bottom: 30px;
  padding-left: 20px;
}

.tr-td-table-new {
  padding-top: 25px;
  padding-bottom: 25px;
  padding-left: 20px;
}

.form-control {
  background-color: #2C545A;
  border-color: #2C545A;
  color: white;

  &:focus {
    background-color: #2C545A;
    border-color: #2C545A;
    color: white;
  }

  &::placeholder {
    color: white;
  }
}

.form-control {
  &:focus {
    &::placeholder {
      color: white;
    }
  }
}

select {
  background: url("data:image/svg+xml,<svg width='10' height='7' viewBox='0 0 10 7' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M5.02552 6.35057L0.320068 0.46875H9.73098L5.02552 6.35057Z' fill='white'/></svg>") no-repeat;
  background-position: calc(100% - 0.75rem) center !important;
  -moz-appearance: none !important;
  -webkit-appearance: none !important;
  appearance: none !important;
  padding-right: 2rem !important;
}
</style>
