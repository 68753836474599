<template>
  <div>
    <div class="bg-default-dashboard">
      <div class=" py-lg-8 pt-lg-5">
        <b-container fluid style="width: 100%; position: relative;">
          <b-row>
            <b-breadcrumb :items="route_items"></b-breadcrumb>
          </b-row>

          <validation-observer v-slot="{ handleSubmit }">
            <form class="needs-validation" @submit.prevent="handleSubmit(onSubmit)">
              <b-row class="mb-2">
                <b-col>
                  <h1 class="text-white">Edit Category</h1>
                </b-col>

              </b-row>
              <b-row>
                <b-col>


                  <b-row class="mb-4">
                    <b-col>
                      <div class="text-white h3">Code</div>
                      <validation-provider name="Code" rules="required" v-slot="{ classes, errors }">
                        <b-form-group :invalid-feedback="errors[0]">
                          <input type="text" class="form-control" :class="classes" v-model="model.code" maxlength="20">
                        </b-form-group>
                      </validation-provider>
                    </b-col>
                  </b-row>

                  <b-row class="mb-4">
                    <b-col>
                      <div class="text-white h3">Name</div>
                      <validation-provider name="Name" rules="required" v-slot="{ classes, errors }">
                        <b-form-group :invalid-feedback="errors[0]">
                          <input type="text" class="form-control" :class="classes" v-model="model.name" maxlength="100">
                        </b-form-group>
                      </validation-provider>
                    </b-col>
                  </b-row>


                  <b-row class="mt-4">
                    <b-col>
                      <b-button variant="outline-danger" class="btn btn-primary" type="button"
                        @click="$router.replace('../Listing')">Cancel</b-button>
                      <button class="btn btn-primary" v-if="access.edit">Update</button>
                    </b-col>
                    <button class="btn btn-danger" type="button" v-if="access.delete"
                      @click="showConfirmDelete = true">Delete</button>
                  </b-row>


                </b-col>
              </b-row>
            </form>
          </validation-observer>
        </b-container>
        <modal :show.sync="showConfirmDelete" size="sm" body-classes="p-0">
          <card type="secondary" header-classes="bg-transparent pb-5" body-classes="px-lg-5 py-lg-5"
            class="border-0 mb-0">
            <div class="text-center text-muted mb-4">
              <h3>Delete this Category?</h3>
            </div>
            <div class="text-center">
              <button class="btn btn-danger" @click="deleteRecord">YES</button>
              <button class="btn btn-dark" @click="showConfirmDelete = false">NO</button>
            </div>
          </card>
        </modal>
      </div>
    </div>
  </div>
</template>

<script>
import ErrorFunctionMixin from '@/assets/js/errorFunctionMix.js'
import axios from '@/util/axios'
import { getAccessByModule } from '@/util/access'
import { Select, Option, Table, TableColumn, } from 'element-ui'

export default {
  mixins: [ErrorFunctionMixin],
  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,

  },
  data() {
    return {
      title: 'Document Tracking Templates',
      route_items: [
        {
          text: 'Document Tracking Template',
          href: '#'
        },
        {
          text: 'Edit Category',
          active: true
        }
      ],
      model: {


      },
      access: {},
      showConfirmDelete: false,

    }
  },

  created() {
    this.id = this.$route.params.id
    this.tid = this.$route.params.tid
    this.url = `setup/doc_repo/template/${this.tid}/category/${this.id}`
    this.access = getAccessByModule('DOC_REPO');
    this.loadRecord()
  },

  methods: {
    loadRecord() {
      axios.get(this.url)
        .then(response => {
          this.model = response.data.data
        })
        .catch(error => {
          this.errorFunction(error, this.title)
        });
    },
    onSubmit() {
      axios.put(this.url, this.model)
        .then(response => {
          this.$notify({
            message: 'Category Updated',
            timeout: 10000,
            icon: 'ni ni-bell-55',
            type: 'default',
          });
          this.$router.replace('../Listing')
        })
        .catch(error => {
          this.errorFunction(error, this.title)
        });
    },
    deleteRecord() {
      axios.delete(this.url)
        .then(response => {
          this.$notify({
            message: 'Category Deleted',
            timeout: 10000,
            icon: 'ni ni-bell-55',
            type: 'default',
          });
          this.$router.replace('../Listing')
        })
        .catch(error => {
          this.errorFunction(error, this.title)
        })
        .then(() => {
          this.showConfirmDelete = false
        })
    },
    showModal() {
      this.$refs['my-modal'].show()
    },

  },
  mounted() {
    this.generateData();
    // this.perPage;
    this.getPagination();
  }
}
</script>
<style scoped>
/* Class For Modal */
.breadcrumb {
  background-color: transparent;
}

.custom-control-label-tmp {
  margin-bottom: 0;
}

.custom-control-label-tmp {
  position: relative;
  margin-bottom: 0;
  vertical-align: top;
}

.modal-content {
  background-color: #306b74;

}

label {
  display: inline-block;
  margin-bottom: 0.5rem;
}

table thead th {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  font-size: 0.65rem;
  text-transform: uppercase;
  letter-spacing: 1px;
  background-color: #23454A;
  color: white;
  padding: 20px;
  border-bottom: 0px solid #082A2F;
  border-top: 0px solid #e9ecef;
}

.th-table-new {
  padding-top: 35px;
  padding-bottom: 35px;
  padding-left: 20px;
}

.tr-td-table-new {
  padding-top: 30px;
  padding-bottom: 30px;
  padding-left: 20px;
}

.form-control {
  background-color: #2C545A;
  border-color: #2C545A;
  color: white;

  &:focus {
    background-color: #2C545A;
    border-color: #2C545A;
    color: white;
  }

  &::placeholder {
    color: white;
  }
}

.form-control {
  &:focus {
    &::placeholder {
      color: white;
    }
  }
}

.input-group-text {
  background-color: #2C545A;
  ;
  color: white;

  &:focus {
    background-color: #2C545A;
    ;
    border-color: #1D4147;
    color: white;
  }
}

.focused {
  .input-group-text {
    background-color: #2C545A;
  }
}

.navbar-search-dark .input-group {
  background-color: #23454A;
  color: black;
  border-color: rgba(255, 255, 255, 0.6);
}

.navbar-search .input-group {
  border-radius: 7px;
}

.el-input__inner,
.el-select-dropdown__item.is-disabled:hover {
  background-color: #23454A;
}
</style>