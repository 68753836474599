<template>
  <div>
    <div class="bg-default-dashboard">
      <div class=" py-lg-8 pt-lg-5">
        <b-container fluid style="width: 100%; position: relative;">
          <b-row>
            <b-breadcrumb :items="route_items"></b-breadcrumb>
          </b-row>

          <validation-observer v-slot="{ handleSubmit }">
            <form class="needs-validation" @submit.prevent="handleSubmit(onSubmit)">
              <b-row class="mb-2">
                <b-col>
                  <h1 class="text-white">Edit Document</h1>
                </b-col>

              </b-row>
              <b-row>
                <b-col>

                  <b-row class="mb-4">
                    <b-col md="6">
                      <div class="text-white h3">{{ template.category1_name }}</div>
                      <validation-provider :name="template.category1_name" rules="required" v-slot="{ classes, errors }">
                    <b-form-group :invalid-feedback="errors[0]">
                      <select class="form-control" :class="classes" v-model="model.category1_id">
                        <option v-for="item of categories1" :key="item.id" :value="item.id">{{ item.code }} - {{ item.name }}</option>
                      </select>
                    </b-form-group>
                  </validation-provider>
                    </b-col>
                    <b-col md="6">
                      <div class="text-white h3">{{ template.category2_name }}</div>
                      <validation-provider :name="template.category2_name" rules="required" v-slot="{ classes, errors }">
                    <b-form-group :invalid-feedback="errors[0]">
                      <select class="form-control" :class="classes" v-model="model.category2_id">
                        <option v-for="item of categories2" :key="item.id" :value="item.id">{{ item.code }} - {{ item.name }}</option>
                      </select>
                    </b-form-group>
                  </validation-provider>
                    </b-col>
                  </b-row>
   
                  <b-row class="mb-4">
                    <b-col md="6" v-if="template.category3_enabled">
                      <div class="text-white h3">{{ template.category3_name }}</div>
                      <validation-provider :name="template.category3_name" rules="required" v-slot="{ classes, errors }">
                    <b-form-group :invalid-feedback="errors[0]">
                      <select class="form-control" :class="classes" v-model="model.category3_id">
                        <option v-for="item of categories3" :key="item.id" :value="item.id">{{ item.code }} - {{ item.name }}</option>
                      </select>
                    </b-form-group>
                  </validation-provider>
                    </b-col>
                    <b-col md="6">
                      <div class="text-white h3">Position</div>
                      <validation-provider name="Position" rules="required" v-slot="{ classes, errors }">
                    <b-form-group :invalid-feedback="errors[0]">
                      <select class="form-control" :class="classes" v-model="model.position">
                        <option :value="0">First</option>
                        <option v-for="item in documents" :key="item.id" :value="item.sequence">After: {{ item.title }}</option>
                      </select>
                    </b-form-group>
                  </validation-provider>
                    </b-col>
                    
                  </b-row>

                  <b-row class="mb-4">
                    <b-col md="6">
                      <div class="text-white h3">Title</div>
                      <validation-provider name="Title" rules="required" v-slot="{ classes, errors }">
                    <b-form-group :invalid-feedback="errors[0]">
                      <input type="text" class="form-control" :class="classes" v-model="model.title" maxlength="300">
                    </b-form-group>
                  </validation-provider>
                    </b-col>
                    <b-col md="6">
                      <div class="text-white h3">Alternative Title</div>
                       <validation-provider name="Alternative Title" v-slot="{ classes, errors }">
                    <b-form-group :invalid-feedback="errors[0]">
                      <input type="text" class="form-control" :class="classes" v-model="model.alt_title" maxlength="300">
                    </b-form-group>
                  </validation-provider>
                    </b-col>
                    
                  </b-row>
                  
                  <b-row class="mb-4">
                    <b-col md="6">
                      <div class="text-white h3">Reviewer/Approver Roles</div>
                      <validation-provider name="Reviewer/Approver Roles" rules="required" v-slot="{ errors }">
                    <b-form-group :invalid-feedback="errors[0]">
                      <b-form-checkbox-group v-model="selectedRoles" :options="roles" stacked :state="errors[0] ? false : null" class="text-white border rounded p-2" />
                      <b-form-invalid-feedback :state="errors[0] ? false : null">{{ errors[0] }}</b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                    </b-col>
                    <b-col md="6">

</b-col>
                    </b-row>

                  <b-row class="mt-4">
                    <b-col>
                      <b-button variant="outline-danger" class="btn btn-primary" type="button"
                        @click="$router.replace('../Listing')">Cancel</b-button>
                      <button class="btn btn-primary" v-if="access.edit">Update</button>
                    </b-col>
                    <button class="btn btn-danger" type="button" v-if="access.delete"
                      @click="showConfirmDelete = true">Delete</button>
                  </b-row>


                </b-col>
              </b-row>
            </form>
          </validation-observer>
        </b-container>
        <modal :show.sync="showConfirmDelete" size="sm" body-classes="p-0">
          <card type="secondary" header-classes="bg-transparent pb-5" body-classes="px-lg-5 py-lg-5"
            class="border-0 mb-0">
            <div class="text-center text-muted mb-4">
              <h3>Delete this Category?</h3>
            </div>
            <div class="text-center">
              <button class="btn btn-danger" @click="deleteRecord">YES</button>
              <button class="btn btn-dark" @click="showConfirmDelete = false">NO</button>
            </div>
          </card>
        </modal>
      </div>
    </div>
  </div>
</template>

<script>
import ErrorFunctionMixin from '@/assets/js/errorFunctionMix.js'
import axios from '@/util/axios'
import { getAccessByModule } from '@/util/access'
import { Select, Option, Table, TableColumn, } from 'element-ui'

export default {
  mixins: [ErrorFunctionMixin],
  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,

  },
  data() {
    return {
      template: {},
      title: 'Document Tracking Templates',
      route_items: [
        {
          text: 'Document Tracking Template',
          href: '#'
        },
        {
          text: 'Edit Document',
          active: true
        }
      ],
      categories1: [],
      categories2: [],
      categories3: [],
      documents: [],
      roles: [],
      selectedRoles: [],
      model: {},
      access: {},
      showConfirmDelete: false,

    }
  },
  watch: {
    ['model.category1_id']() {
      this.loadDocuments()
    }
  },

  created() {
    this.id = this.$route.params.id
    this.tid = this.$route.params.tid
    this.url = `setup/doc_repo/template/${this.tid}/category/${this.id}`
    this.access = getAccessByModule('DOC_REPO');

    this.loadTemplate()
    this.loadCategories()
    this.loadRoles()
    this.loadRecord()
  },

  methods: {
    loadRecord() {
      axios.get(`setup/doc_repo/template/${this.tid}/document/${this.id}`)
        .then(response => {
          this.model = response.data.data
          this.model.position = this.model.sequence - 1
          this.selectedRoles = this.model.roles.map(x => x.role_id)
        })
        .catch(error => {
          this.errorFunction(error, this.title)
        });
    },
    loadTemplate() {
      axios.get(`setup/doc_repo/template/${this.tid}`)
        .then(response => {
          this.template = response.data.data
        })
        .catch(error => {
          this.errorFunction(error, this.title)
        });
    },
    loadCategories() {
      axios.get(`setup/doc_repo/template/${this.tid}/category`)
        .then(response => {
          const categories = response.data.data
          this.categories1 = categories.filter(x => x.level == 1)
          this.categories2 = categories.filter(x => x.level == 2)
          this.categories3 = categories.filter(x => x.level == 3)
        })
        .catch(error => {
          this.errorFunction(error, this.title)
        });
    },
  
    loadDocuments() {
      const options = {
        params: {
          category1_id: this.model.category1_id,
        },
      }

      axios.get(`setup/doc_repo/template/${this.tid}/document`, options)
        .then(response => {
          this.documents = response.data.data.filter(x => x.id != this.id)

          // This is to prevent changing the initial model.position value.
          if (!this.documentsInit) {
            this.documentsInit = true
            return
          }

          if (this.documents.length > 0) {
            const length = this.documents.length
            this.model.position = this.documents[length - 1].sequence
          } else {
            this.model.position = 0;
          }
        })
        .catch(error => {
          this.errorFunction(error, this.title)
        });
    },

    loadRoles() {
      axios.get('lookup/role_list')
        .then(response => {
          this.roles = response.data.data.map(x => {
            return {
              value: x.id,
              text: x.name,
            }
          })
        })
        .catch(error => {
          this.errorFunction(error, this.title)
        });
    },
    onSubmit() {
      this.model.roles = this.selectedRoles.map(id => {
        return { role_id: id }
      })
      axios.put(`setup/doc_repo/template/${this.tid}/document/${this.id}`, this.model)
        .then(response => {
          this.$notify({
            message: 'Document Updated',
            timeout: 10000,
            icon: 'ni ni-bell-55',
            type: 'default',
          });
          this.$router.replace('../Listing')
        })
        .catch(error => {
          this.errorFunction(error, this.title)
        });
    },
    deleteRecord() {
      axios.delete(`setup/doc_repo/template/${this.tid}/document/${this.id}`)
        .then(response => {
          this.$notify({
            message: 'Document Deleted',
            timeout: 10000,
            icon: 'ni ni-bell-55',
            type: 'default',
          });
          this.$router.replace('../Listing')
        })
        .catch(error => {
          this.errorFunction(error, this.title)
        })
        .then(() => {
          this.showConfirmDelete = false
        })
    },
   

  },
  mounted() {
    // this.perPage;
    this.getPagination();
  }
}
</script>
<style scoped>
/* Class For Modal */
.breadcrumb {
  background-color: transparent;
}

.custom-control-label-tmp {
  margin-bottom: 0;
}

.custom-control-label-tmp {
  position: relative;
  margin-bottom: 0;
  vertical-align: top;
}

.modal-content {
  background-color: #306b74;
}
.modal-title{
    margin-left: auto;
}

label {
  display: inline-block;
  margin-bottom: 0.5rem;
}

table thead th {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  font-size: 0.65rem;
  text-transform: uppercase;
  letter-spacing: 1px;
  background-color: #23454A;
  color: white;
  padding: 20px;
  border-bottom: 0px solid #082A2F;
  border-top: 0px solid #e9ecef;
}

.th-table-new {
  padding-top: 35px;
  padding-bottom: 35px;
  padding-left: 20px;
}

.tr-td-table-new {
  padding-top: 30px;
  padding-bottom: 30px;
  padding-left: 20px;
}

.form-control {
  background-color: #2C545A;
  border-color: #2C545A;
  color: white;

  &:focus {
    background-color: #2C545A;
    border-color: #2C545A;
    color: white;
  }

  &::placeholder {
    color: white;
  }
}

.form-control {
  &:focus {
    &::placeholder {
      color: white;
    }
  }
}

.input-group-text {
  background-color: #2C545A;
  ;
  color: white;

  &:focus {
    background-color: #2C545A;
    ;
    border-color: #1D4147;
    color: white;
  }
}

.focused {
  .input-group-text {
    background-color: #2C545A;
  }
}

.navbar-search-dark .input-group {
  background-color: #23454A;
  color: black;
  border-color: rgba(255, 255, 255, 0.6);
}

.navbar-search .input-group {
  border-radius: 7px;
}

.el-input__inner,
.el-select-dropdown__item.is-disabled:hover {
  background-color: #23454A;
}
</style>