<template>
  <div>
    <!-- <div>{{isSolarNovaProject}}</div> -->
    <base-header class="pb-6 bg-custom" type="">
      <b-row align-v="center" class="py-4 bg-custom">
        <!-- <b-col lg="12" cols="7">
          <h6 class="h2 d-inline-block mb-0">Site Dashboard</h6>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <b-breadcrumb class="breadcrumb-links">
              <b-breadcrumb-item
                ><router-link to="/"><i class="fas fa-home"></i></router-link
              ></b-breadcrumb-item>
              <b-breadcrumb-item>Dashboards Layout </b-breadcrumb-item>
              <b-breadcrumb-item active aria-current="page"
                >Site Dashboard</b-breadcrumb-item
              >
            </b-breadcrumb>
          </nav>
        </b-col> -->

        <b-col lg="12" cols="12">
          <h2 class="text-dark"><strong>Site Information</strong></h2>
          <card class="card-border form-custom-css">
            <div class="form-row">
              <b-col md="6">
                <base-input
                  label="Site Name"
                  name="Site Name"
                  v-model="model.site_name"
                  disabled
                >
                </base-input>
              </b-col>

              <b-col md="6">
                <base-input
                  label="Service Location ID"
                  name="Service Location ID"
                  v-model="model.site_service_location_id"
                  disabled
                >
                </base-input>
              </b-col>
            </div>

            <div class="form-row">
              <b-col md="12">
                <base-input
                  label="Address"
                  name="Address"
                  v-model="model.site_address"
                  disabled
                >
                </base-input>
              </b-col>
            </div>

            <div class="form-row">           
              <b-col md="6">
                <base-input
                  label="Postal Code"
                  name="Postal Code"
                  v-model="model.site_postal_code"
                  disabled
                >
                </base-input>
              </b-col>

              <b-col md="6">
                <base-input
                  label="Country"
                  name="Country"
                  v-model="model.site_country"
                  disabled
                >
                </base-input>
              </b-col>
            </div>

            <div class="form-row">
              <b-col md="6">
                <base-input
                  label="Target Turn On Date"
                  name="Target Turn On Date"
                  type="date"
                  v-model="model.site_target_turn_on_date"
                  disabled
                >
                </base-input>
              </b-col>

              <b-col md="6">
                <base-input
                  label="PPA Approved Capacity"
                  name="PPA Approved Capacity"
                  v-model="model.site_ppa_approved_capacity"
                  disabled
                >
                </base-input>
              </b-col>
            </div>
          </card>
        </b-col>

      </b-row>
    </base-header>
  </div>
</template>

<script>
import RouteBreadCrumb from "@/components/Breadcrumb/RouteBreadcrumb";
import BaseHeader from "@/components/BaseHeader";
import ProjectSiteViewForm from "./ProjectSiteViewForm";

import LineChart from "@/components/Charts/LineChart";
import * as chartConfigs from "@/components/Charts/config";
import ProjectSiteTaskListingTable from "./ProjectSiteTaskListingTable";
import ProjectSiteDocumentListingTable from "./ProjectSiteDocumentListingTable";
import SiteContractorTable from "./AdditionalTable/SiteContractorTable";

import { Modal } from "@/components";
import {
  LoginURL,
  clientId,
  clientSecret,
  apiDomain,
  getHeader,
  getcurrentDate,
} from "@/assets/js/config.js";
import axios from "axios";
import ErrorFunctionMixin from "@/assets/js/errorFunctionMix.js";

export default {
  mixins: [ErrorFunctionMixin],
  components: {
    BaseHeader,
    RouteBreadCrumb,
    LineChart,
    "project-site-task-listing-table": ProjectSiteTaskListingTable,
    "site-contractor-table": SiteContractorTable,
    "project-site-document-listing-table": ProjectSiteDocumentListingTable,
    Modal,
    ProjectSiteViewForm,    
  },
  created() {
    var site_task_access = JSON.parse(
      window.localStorage.getItem("site_task_access")
    );
    this.button_access.site_task_listing = site_task_access.access_listing;
    this.button_access.site_task_new = site_task_access.access_create;

    var site_document_access = JSON.parse(
      window.localStorage.getItem("site_document_access")
    );
    this.button_access.site_document_listing =
      site_document_access.access_listing;
    this.button_access.site_document_new = site_document_access.access_create;

    var site_contractor_access = JSON.parse(
      window.localStorage.getItem("site_contractor_access")
    );
    this.button_access.site_contractor_listing =
      site_contractor_access.access_listing;

    const LoginUser = JSON.parse(window.localStorage.getItem("loginUser"));
    this.myUserID = LoginUser;

    this.getProjectId();

    // this.getdashboardData();
    // this.getdashboardDataShort();
  },
  data() {
    return {
      project_id: null,
      button_access: {
        site_task_listing: null,
        site_task_new: null,
        site_document_listing: null,
        site_document_new: null,
        site_contractor_listing: null,
      },
      myUserID: null,
      model: {
        site_id: null,
        site_name: null,
        // site_name: null,
        status_code: null,
        site_leader: null,
        site_total_installation: 0,
        site_total_task: 0,
        site_completed_task: 0,
        site_total_document: 0,
        site_completed_document: 0,
        site_progress: 0,
        site_total_capacity: 0,
        site_completed_capacity: 0,
        group_engineer: {
          name: null,
          email: null,
        },
        site_service_location_id: null,
        site_address: null,
        site_postal_code: null,
        site_country: null,
        site_target_turn_on_date: null,
        site_ppa_approved_capacity: 0,
      },
      modals: {
        formTask: false,
        formMilestone: false,
      },

      formModal: {
        task_template: "",
        task_site: "",
        milestone_template: "",
      },
      bigLineChart: {
        allData: [
          [0, 20, 10, 30, 15, 40, 20, 60, 60],
          [0, 20, 5, 25, 10, 30, 15, 40, 40],
        ],
        activeIndex: 0,
        chartData: {
          datasets: [
            {
              label: "Performance",
              data: [
                0, 100, 250, 500, 1000, 1500, 2000, 2500, 2900, 3200, 3300,
                3300,
              ],
            },
          ],
          labels: [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec",
          ],
        },
        extraOptions: chartConfigs.blueChartOptions,
      },
      tempData: null,
      isSolarNovaProject: false,
    };
  },
  methods: {
    routeToProjectDashboard() {
      this.$router.push({ path: "/projectDashboard/" + this.project_id });
    },
    // Get Project Type ID and Check if Solar Nova Project
    getProjectTypeCheckSolar() {
      var project_type_id = null;
      axios
        .get(apiDomain + "project/project/" + this.project_id, {
          headers: getHeader(),
        })
        .then((response) => {
          if (response.status === 200) {
            project_type_id =
              response.data.data[0].project_type.project_type_id;
            if (
              project_type_id != null &&
              // (project_type_id == 2 ||
              //   project_type_id == 3 ||
              //   project_type_id == 4)
              ([2, 3, 4, 9, 10, 11, 12, 13, 14].includes(project_type_id))
            ) {
              this.isSolarNovaProject = true;
              this.$router.push({ path: "/projectSiteDashboardSN/" + this.$route.params.id });
            }
            else {
              this.getdashboardData();
            }
          }
        })
        .catch((error) => {
          console.log(error.response.status);
        });
    },
    getProjectId() {
      axios
        .get(apiDomain + "project/project_site/" + this.$route.params.id, {
          headers: getHeader(),
        })
        .then((response) => {
          if (response.status === 200) {
            this.project_id = response.data.data[0].project.project_id;
            this.getProjectTypeCheckSolar();
          }
        })
        .catch((error) => {
          console.log(error.response.status);
          this.errorFunction(error, "Project Site Information");
        });
    },
    afterUpdateTaskList() {
      this.getdashboardData();
    },
    getdashboardData() {
      this.model.site_id = this.$route.params.id;
      axios
        .get(apiDomain + "project/project_site/" + this.model.site_id, {
          headers: getHeader(),
        })
        .then((response) => {
          if (response.status === 200) {
            this.model.site_name = response.data.data[0].site_name;
            this.model.status_code = response.data.data[0].status.status_code;
            this.model.site_leader =
              response.data.data[0].group.group_engineer.name;
            this.model.site_total_installation =
              response.data.data[0].site_total_installation;
            this.model.site_total_task = response.data.data[0].site_total_task;
            this.model.site_total_document =
              response.data.data[0].site_total_document;
            this.model.site_progress = response.data.data[0].site_progress;
            this.model.site_completed_capacity =
              response.data.data[0].site_completed_capacity;
            this.model.site_total_capacity =
              response.data.data[0].site_total_capacity;
            this.model.site_completed_task =
              response.data.data[0].site_completed_task;
            this.model.site_completed_document =
              response.data.data[0].site_completed_document;
            this.model.group_engineer.email =
              response.data.data[0].group.group_engineer.email;
            this.model.site_service_location_id =
              response.data.data[0].site_service_location_id;
            this.model.site_address =
              response.data.data[0].site_address;
            this.model.site_postal_code =
              response.data.data[0].site_postal_code;
            this.model.site_country =
              response.data.data[0].site_country;
            this.model.site_target_turn_on_date =
              response.data.data[0].site_target_turn_on_date;
            this.model.site_ppa_approved_capacity =
              response.data.data[0].site_ppa_approved_capacity;
          }
        })
        .catch((error) => {
          console.log(error.response.status);
          this.errorFunction(error, "Site Information");
        });
    },
    getdashboardDataShort() {
      this.model.site_id = this.$route.params.id;
      axios
        .get(apiDomain + "project/project_site/" + this.model.site_id, {
          headers: getHeader(),
        })
        .then((response) => {
          if (response.status === 200) {
            this.tempData = response.data.data;
            this.model.site_name = response.data.data[0].site_name;
            this.model.status_code = response.data.data[0].status.status_code;
            this.model.site_leader =
              response.data.data[0].group.group_engineer.name;
            this.model.site_total_installation =
              response.data.data[0].site_total_installation;
            this.model.site_total_task = response.data.data[0].site_total_task;
            this.model.site_total_document =
              response.data.data[0].site_total_document;
            this.model.site_progress = response.data.data[0].site_progress;
            this.model.site_completed_capacity =
              response.data.data[0].site_completed_capacity;
            this.model.site_total_capacity =
              response.data.data[0].site_total_capacity;
            this.model.site_completed_task =
              response.data.data[0].site_completed_task;
            this.model.site_completed_document =
              response.data.data[0].site_completed_document;
            this.model.group_engineer.email =
              response.data.data[0].group.group_engineer.email;
          }
        })
        .catch((error) => {
          console.log(error.response.status);
          this.errorFunction(error, "Site Information");
        });
    },
    newSiteDocument() {
      this.$router.push({
        path: "/siteDocumentNewFrSite/" + this.model.site_id,
      });
    },
    newTask() {
      this.$router.push({ path: "/taskNewFrSite/" + this.model.site_id });
    },
    newImport() {
      this.$router.push({ path: "/importNewFrSite/" + this.model.site_id });
    },
    newContractor() {
      this.$router.push({
        path: "/siteContractorListing/" + this.model.site_id,
      });
    },
    notready() {
      this.$notify({
        message:
          "<b>Under Developer : </b> - New Task, Site Contractor & Populate / Import From Template function is not ready.",
        timeout: 10000,
        icon: "ni ni-bulb-61",
        type: "danger",
      });
    },
    notifyVueFormTask() {
      if (this.formModal.task_template === "") {
        this.$notify({
          message:
            "<b>Task Template Input Error : </b> - Please Select A Task Template & Target Site OR press <b>CANCEL</b> to <b>EXIT</b>.",
          timeout: 10000,
          icon: "ni ni-bulb-61",
          type: "danger",
        });
      } else {
        this.$notify({
          message:
            "<b>Task Template (" +
            this.formModal.task_template +
            ") : </b> - Successfully Imported into ( TRTP-2398 - Tengeh Reservoir Tender Project )",
          timeout: 10000,
          icon: "ni ni-bell-55",
          type: "default",
        });
        this.clearModal();
      }
    },
    notifyVueFormMilestone() {
      if (this.formModal.milestone_template === "") {
        this.$notify({
          message:
            "<b>Milestone Template Input Error : </b> - Please Select A Milestone Template Or Cancel to Exit.",
          timeout: 10000,
          icon: "ni ni-bulb-61",
          type: "danger",
        });
      } else {
        this.$notify({
          message:
            "<b>Milestone Template (" +
            this.formModal.milestone_template +
            ") : </b> - Successfully Imported into ( TRTP-2398 - Tengeh Reservoir Tender Project )",
          timeout: 10000,
          icon: "ni ni-bell-55",
          type: "default",
        });
        this.clearModal();
      }
    },
    clearModal() {
      this.modals.formTask = false;
      this.modals.formMilestone = false;
      this.formModal.task_template = "";
      this.formModal.task_site = "";
      this.formModal.milestone_template = "";
    },
  },
};
</script>
<style>

.card-border {
  border: 2px solid #BDDBA9;
  border-radius: 100px 0 100px 0;
  /* margin: 20px; */
  padding: 10px;
  /* max-width: 300px; */
}

.bg-custom {
  background-color: #EFF7EA;
}

.card-content {
  padding: 20px;
}

.form-custom-css .form-control:disabled {
  border: 2px solid #FF0000;
  border-radius: 0;
  background-color: white;
}

.form-custom-css .form-control {
  border: 2px solid #BDDBA9;
  border-radius: 0;
  color: black;
}

.form-custom-css .form-control-label {
  font-size: 1rem;
  font-weight: 700;
  color: black;
}

</style>
