<template>
  <card>
    <!-- Card header -->
    <h3 slot="header" class="mb-0">New Comment for ({{ model.document_classification_code }} - Version : {{ model.detail.document_version}})</h3>
    <div class="text-right">
    </div>
    <form class="needs-validation"
          @submit.prevent="submit">

      <div class="form-row">
        <b-col md="12">
          <base-input label="Comment"
                      name="Comment"
                      placeholder="Comment"
                      v-model="model_new.document_comment">
          </base-input>
        </b-col>
      </div>


      <base-button type="primary" @click="createData()">Create New Comment</base-button>
      <base-button type="primary" @click="$router.back()">Cancel</base-button>
      <!-- <div>
        <br>
        <h5 class="text-danger"> Note : <br><br>
                <i>
                1. By Default all Date is set to "TODAY.
                </i>
                <br>
                <i>
                2. Project Progress % is set to "0 %" By Default.
                </i>
        </h5>
      </div> -->
    </form>
  </card>
</template>
<script>
  import BaseSlider from '@/components/BaseSlider'
  import {LoginURL, clientId, clientSecret, apiDomain, getHeader, getcurrentDate} from '@/assets/js/config.js'
  import axios from 'axios'

  export default {
    components: {
      BaseSlider
    },
    created()
    {
        this.model.document_detail_id = this.$route.params.id

        axios.get(apiDomain + 'document/document_detail/'+this.model.document_detail_id, { headers: getHeader() })
          .then ( response => {
            if (response.status === 200)
            {
                this.model.document_id = response.data.data[0].document.document_id
                this.model.document_classification_code = response.data.data[0].document.document_classification_code
                this.model.document_information = response.data.data[0].document.document_information
                // this.model.project_code = response.data.data[0].document.project.project_code
                // this.model.group_code = response.data.data[0].document.group.group_code
                // this.model.site_code = response.data.data[0].document.site.site_code
                this.model.document_type_code = response.data.data[0].document.document_type.document_type_code
                this.model.milestone_code = response.data.data[0].document.milestone.milestone_code
                this.model.status_code = response.data.data[0].document.status.status_code
                this.model.document_mandatory = response.data.data[0].document.document_mandatory
                this.model.detail.document_version = response.data.data[0].document_version
                this.model.detail.document_comment_no = response.data.data[0].document_comment_no
                this.model.detail.document_information = response.data.data[0].document_information
            }
          })
          .catch ( error => {
              console.log(error.response.status)
              if ( error.response.status == 401)
              {
                  this.$router.push('/login')
              }
          } );

    },
    data() {
      return {
         model: {
          document_id: null,
          document_detail_id: null,
          document_classification_code: null,
          document_information: null,
          project_code: null,
          group_code: null,
          site_code: null,
          document_type_code: null,
          milestone_code: null,
          status_code: null,
          document_mandatory: null,
          detail: {
            document_version: 0,
            document_comment_no: 0,
            document_information: null,
          },
        },
        model_new: {
          document_detail_id: null,
          document_comment: null,
        },
      }
    },
    methods: {
      createData(){
        if ( !this.model_new.document_comment )
        {
          this.$notify({
            message:
              '<b>Invalid Input : All Column are Mandatory in Create New Comment </b> - These field are required.',
            timeout: 10000,
            icon: 'ni ni-bulb-61',
            type: 'danger',
          });
        }
        else
        {
          this.model_new.document_detail_id = this.model.document_detail_id

          axios.post(apiDomain + 'document/document_comment', this.model_new, { headers: getHeader() })
          .then ( response => {
            if (response.status === 200)
            {
                this.$notify({
                message:
                  '<b>New Comment : ' + this.model.document_classification_code + ' Version : ' + this.model.detail.document_version + '</b> - Successfully Created.',
                timeout: 10000,
                icon: 'ni ni-bell-55',
                type: 'default',
                });
                this.$router.back()
            }
          })
          .catch ( error => {
              console.log(error.response.data.errors)
              this.$notify({
                message:
                  '<b>Invalid Input : </b> - Please login to try again if error presistent please contact Admin.',
                timeout: 10000,
                icon: 'ni ni-bulb-61',
                type: 'danger',
              });
          });
        }
      },
    }
  }
</script>
<style>
</style>
