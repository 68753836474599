<template>
  <div>
    <!-- Map -->
    <b-row>
      <b-col md="12">
        <card class="bg-card-dashboard" style="margin-bottom: 15px;">
          <div class="block">
            <!-- google map displaying the site location -->
            <div class="background" id="mapDashboard" ref="mapRef" style="height:550px; width:100%;"></div>

            <!-- info site: PPA signed capacity | PPA Signed Date | Joint Venture With | Contractor -->
            <div class="foreground" v-show="oDashboard.isShowMapOverlay">
              <!-- <div class="dashboard-container-info" style="width: 550px"> -->
              <div class="dashboard-container-info-parent">
                <div class="card dashboard-container-info-card">
                  <div class="row no-gutters dashboard-container-info">
                    <div class="col-md-4" v-show="oProject.info[0].content != 'default'">{{ oProject.info[0].title }}</div>
                    <div class="col-md-8" v-show="oProject.info[0].content != 'default'"><b>{{ oProject.info[0].content }}</b></div>
                    <div class="col-md-12" v-show="oProject.info[0].content == 'default'"><img src="/img/default/loading_v1.gif" style="width: 20px; height: 20px;"></div>
                  </div>
                </div>
              </div>
              <div class="dashboard-container-info-parent">
                <div class="card dashboard-container-info-card">
                  <div class="row no-gutters dashboard-container-info">
                    <div class="col-md-4" v-show="oProject.info[1].content != 'default'">{{ oProject.info[1].title }}</div>
                    <div class="col-md-8" v-show="oProject.info[1].content != 'default'"><b>{{ oProject.info[1].content }}</b></div>
                    <div class="col-md-12" v-show="oProject.info[1].content == 'default'"><img src="/img/default/loading_v1.gif" style="width: 20px; height: 20px;"></div>
                  </div>
                </div>
              </div>
              <div class="dashboard-container-info-parent">
                <div class="card dashboard-container-info-card">
                  <div class="row no-gutters dashboard-container-info">
                    <div class="col-md-4" v-show="oProject.info[2].content != 'default'">{{ oProject.info[2].title }}</div>
                    <div class="col-md-8" v-show="oProject.info[2].content != 'default'"><b>{{ oProject.info[2].content }}</b></div>
                    <div class="col-md-12" v-show="oProject.info[2].content == 'default'"><img src="/img/default/loading_v1.gif" style="width: 20px; height: 20px;"></div>
                  </div>
                </div>
              </div>
              <div class="dashboard-container-info-parent">
                <div class="card dashboard-container-info-card">
                  <div class="row no-gutters dashboard-container-info">
                    <div class="col-md-4" v-show="oProject.info[3].content != 'default'">{{ oProject.info[3].title }}</div>
                    <div class="col-md-8" v-show="oProject.info[3].content != 'default'"><b>{{ oProject.info[3].content }}</b></div>
                    <div class="col-md-12" v-show="oProject.info[3].content == 'default'"><img src="/img/default/loading_v1.gif" style="width: 20px; height: 20px;"></div>
                  </div>
                </div>
              </div>
            </div>

            <!-- show / hide overlay -->
            <div class="foreground">
              <div class="dashboard-container-button-overlay">
                <b-link @click="mapOverlayDisplay()" class="btn btn-map-overlay rounded-pill text-white" target="_blank">
                  <div class="icon-map-search ml-1 mr-1" v-show="!oDashboard.isShowMapOverlay"></div>
                  <div class="icon-map-only ml-1 mr-1" v-show="oDashboard.isShowMapOverlay"></div>
                </b-link>
              </div>
            </div>

            <!-- PIC: project engineer | engineer | business development | safety officer -->
            <div class="foreground dashboard-container-pic-parent" v-show="oDashboard.isShowMapOverlay">
              <div class="dashboard-container-pic-child">
                <div class="card mb-3 dashboard-container-pic-card">
                  <div class="row no-gutters">
                    <div class="col-md-3">
                      <img :src="oProject.pic[0].avatar" class="card-img" alt="avatar">
                    </div>
                    <div class="col-md-9">
                      <div class="card-body">
                        <h5 class="card-title dashboard-pic-title">{{ oProject.pic[0].title }}</h5>
                        <p class="card-text dashboard-pic-name">{{ oProject.pic[0].name }}</p>
                        <p class="card-text dashboard-pic-info"><small class="text-muted">{{ oProject.pic[0].other }}</small></p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="dashboard-container-pic-child">
                <div class="card mb-3 dashboard-container-pic-card">
                  <div class="row no-gutters">
                    <div class="col-md-3">
                      <img :src="oProject.pic[1].avatar" class="card-img" alt="avatar">
                    </div>
                    <div class="col-md-9">
                      <div class="card-body">
                        <h5 class="card-title dashboard-pic-title">{{ oProject.pic[1].title }}</h5>
                        <p class="card-text dashboard-pic-name">{{ oProject.pic[1].name }}</p>
                        <p class="card-text dashboard-pic-info"><small class="text-muted">{{ oProject.pic[1].other }}</small></p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="dashboard-container-pic-child">
                <div class="card mb-3 dashboard-container-pic-card">
                  <div class="row no-gutters">
                    <div class="col-md-3">
                      <img :src="oProject.pic[2].avatar" class="card-img" alt="avatar">
                    </div>
                    <div class="col-md-9">
                      <div class="card-body">
                        <h5 class="card-title dashboard-pic-title">{{ oProject.pic[2].title }}</h5>
                        <p class="card-text dashboard-pic-name">{{ oProject.pic[2].name }}</p>
                        <p class="card-text dashboard-pic-info"><small class="text-muted">{{ oProject.pic[2].other }}</small></p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="dashboard-container-pic-child">
                <div class="card mb-3 dashboard-container-pic-card">
                  <div class="row no-gutters">
                    <div class="col-md-3">
                      <img :src="oProject.pic[3].avatar" class="card-img" alt="avatar">
                    </div>
                    <div class="col-md-9">
                      <div class="card-body">
                        <h5 class="card-title dashboard-pic-title">{{ oProject.pic[3].title }}</h5>
                        <p class="card-text dashboard-pic-name">{{ oProject.pic[3].name }}</p>
                        <p class="card-text dashboard-pic-info"><small class="text-muted">{{ oProject.pic[3].other }}</small></p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- EPC information -->
            <div class="foreground" v-show="oDashboard.isShowMapOverlay">
              <div class="dashboard-container-epc-info-parent">
                <div class="card dashboard-container-epc-info-card">
                  <div class="row no-gutters dashboard-container-epc-info">
                    <div class="col-md-12">
                      <div class="row dashboard-container-epc-info-row-header">
                        <div class="col-md-12"><b>EPC Information</b></div>
                      </div>
                      <div class="row dashboard-container-epc-info-row-content">
                        <div class="col-md-5">Name</div>
                        <div class="col-md-7" v-show="oProject.epc.name != 'default'"><b>{{ oProject.epc.name }}</b></div>
                        <div class="col-md-7" v-show="oProject.epc.name == 'default'"><img src="/img/default/loading_v1.gif" style="width: 20px; height: 20px;"></div>
                      </div>
                      <div class="row dashboard-container-epc-info-row-content">
                        <div class="col-md-5">Contact Person</div>
                        <div class="col-md-7" v-show="oProject.epc.pic != 'default'"><b>{{ oProject.epc.pic }}</b></div>
                        <div class="col-md-7" v-show="oProject.epc.pic == 'default'"><img src="/img/default/loading_v1.gif" style="width: 20px; height: 20px;"></div>
                      </div>
                      <div class="row dashboard-container-epc-info-row-content">
                        <div class="col-md-5">Contact Number</div>
                        <div class="col-md-7" v-show="oProject.epc.contact != 'default'"><b>{{ oProject.epc.contact }}</b></div>
                        <div class="col-md-7" v-show="oProject.epc.contact == 'default'"><img src="/img/default/loading_v1.gif" style="width: 20px; height: 20px;"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </card>
      </b-col>
    </b-row>

    <!-- assets information -->
    <b-row>
      <b-col md="4">
        <div class="background-wrapper">
          <div class="overlay-content">
            <h2 style="color:white">Inverter</h2>
            <h5 style="color:white">Huawei / SUN 2000-330KTL</h5>
            <h5 style="color:white">Huawei / SUN 2000-215KTL-H0</h5>
          </div>
        </div>
      </b-col>
      <b-col md="4">
        <div class="background-wrapper background-wrapper1">
          <div class="overlay-content">
            <h2 style="color:white">Panel</h2>
            <h5 style="color:white">Model: Jinko N Type Bifacial 625Wp</h5>
            <h5 style="color:white">Quantity: 92820 (40MW); 21600 (10MW)</h5>
          </div>
        </div>
      </b-col>
      <b-col md="4">
        <div class="background-wrapper background-wrapper2">
          <div class="overlay-content">
            <h2 style="color:white">BESS 8.3 MWh</h2>
            <h5 style="color:white">Battery Container: HUAWEI's LUNA2000-2.0MWH-1H1</h5>
            <h5 style="color:white">Battery Pack: Huawei's LUNA2000-2.0MWH-1H1/2H1</h5>
          </div>
        </div>
      </b-col>
    </b-row>

    <!-- modal emergency contact numbers -->
    <!-- :no-close-on-backdrop="true"  -->
    <modal :show.sync="oModal.isVisibleEmergencyContact" modal-classes="emergency-contact-modal" size="xl" body-classes="p-0" title="Emergency Contact Numbers">
      <card type="" header-classes="bg-transparent pb-5" body-classes="px-lg-5 py-lg-5" class="border-0 mb-0" style="background-color: #375f65;">
        <template>
          <tab-content-navigation />
          <div class="text-center text-muted mb-4">
            <h1 class="text-white"><b>Emergency Contact Numbers</b></h1>
          </div>
          <div class="emergency-contact-main">
            <table>
              <tr>
                <th>Unit</th>
                <th>PIC</th>
                <th>Contact</th>
                <th>Notes</th>
              </tr>
              <tr v-for="(item, key, index) in oProject.emergency_contacts">
                <td style="width: 30%;">{{ item.title }}</td>
                <td style="width: 10%;">{{ item.pic }}</td>
                <td style="width: 25%;">{{ item.contact }}</td>
                <td style="width: 35%;">{{ item.notes }}</td>
              </tr>
            </table>
          </div>
        </template>
      </card>
    </modal>

    <!-- modal calendar -->
    <modal :show.sync="oModal.isVisibleCalendar" modal-classes="calendar-modal" size="xl" body-classes="p-0" title="Calendar">
      <card type="" header-classes="bg-transparent pb-5" body-classes="px-lg-5 py-lg-5" class="border-0 mb-0" style="background-color: #375f65;">
        <template>
          <tab-content-navigation />
          <tab-content-calendar />
        </template>
      </card>
    </modal>

    <!-- modal gallery -->
    <modal :show.sync="oModal.isVisibleGallery" modal-classes="gallery-modal" size="xl" body-classes="p-0" title="Site Gallery">
      <card type="" header-classes="bg-transparent pb-5" body-classes="px-lg-5 py-lg-5" class="border-0 mb-0" style="background-color: #375f65;">
        <template>
          <tab-content-navigation />
          <tab-content-gallery />
        </template>
      </card>
    </modal>

    <!-- modal cctv -->
    <modal :show.sync="oModal.isVisibleCCTV" modal-classes="cctv-modal" size="xl" body-classes="p-0" title="CCTV">
      <card type="" header-classes="bg-transparent pb-5" body-classes="px-lg-5 py-lg-5" class="border-0 mb-0" style="background-color: #375f65;">
        <template>
          <tab-content-navigation />
          <tab-content-cctv :site-id="oDashboard.code_site"/>
        </template>
      </card>
    </modal>

    <!-- modal gallery -->
    <modal :show.sync="oModal.isVisibleProjectTracking" modal-classes="project-tracking-modal" size="xl" body-classes="p-0" title="Project Tracking">
      <card type="" header-classes="bg-transparent pb-5" body-classes="px-lg-5 py-lg-5" class="border-0 mb-0" style="background-color: #375f65;">
        <template>
          <tab-content-navigation />
          <tab-content-project-tracking :site-id="oDashboard.code_site"/>
        </template>
      </card>
    </modal>

  </div>
</template>

<script>
import { Table, TableColumn, Select, Option } from "element-ui";
import { API_KEY } from '@/views/Maps/API_KEY';
import GoogleMapsLoader from 'google-maps';
GoogleMapsLoader.KEY = API_KEY;
GoogleMapsLoader.LIBRARIES = ['geometry'];
import { LoginURL, clientId, clientSecret, apiDomain, getHeader, getcurrentDate } from "@/assets/js/config.js";
import axios from "axios";
import ErrorFunctionMixin from "@/assets/js/errorFunctionMix.js";
import jsonFormData from "json-form-data";
import moment from "moment";
// import '@/assets/css/nucleo/css/tailwind.css';

import TabContentNavigation from '../MapDashboardSiteDetail/TabContent0_navigation.vue';
import TabContentCalendar from '../MapDashboardSiteDetail/TabContent2_calendar.vue';
import TabContentGallery from '../MapDashboardSiteDetail/TabContent3_gallery.vue';
import TabContentCctv from '../MapDashboardSiteDetail/TabContent4_cctv.vue';
import TabContentProjectTracking from '../MapDashboardSiteDetail/TabContent5_project_tracking.vue';

export default {
  name: "Detail",
  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    TabContentNavigation,
    TabContentCalendar,
    TabContentGallery,
    TabContentCctv,
    TabContentProjectTracking,
  },
  props: ['projectName'],
  data() {
    return {
      oDashboard: {
        code_proj: null,
        code_site: null,
        map: null,
        marker: [],
        isShowMapOverlay: true,
      },     
      oProject: {
        map: {
          lat: 0,
          lng: 0,
          title: ''
        },
        info: [
          { title: 'PPA Signed Capacity', content: 'default' },
          { title: 'PPA Signed Date', content: 'default' },
          { title: 'Joint Venture With', content: 'default' },
          { title: 'Contractor', content: 'default' },
        ],
        pic: [
          { avatar: '/img/users/default_avatar_male.png', title: 'Project Engineer', name: '', other: '' },
          { avatar: '/img/users/default_avatar_male.png', title: 'Engineer', name: '', other: '' },
          { avatar: '/img/users/default_avatar_male.png', title: 'Business Development', name: '', other: '' },
          { avatar: '/img/users/default_avatar_male.png', title: 'Safety Officer', name: '', other: '' },
        ],
        epc: { name: 'default', pic: 'default', contact: 'default' },
        emergency_contacts: [
          { title: 'default ERT Menara Sentraya', pic: '', contact: '(021) 27 881 881', notes: ''},
          { title: 'default Non-emergency building maintenance issue', pic: 'Pak Default', contact: '+62 812 8081 7619', notes: ''},
          { title: 'default Pertamina Hospital', pic: '', contact: '(021) 721 9000 / 721 9214 (ICU)', notes: 'distance: 645 meters, travel time: 4 minutes'},
          { title: 'default Fire Brigade South Jakarta', pic: '', contact: '(021) 7515054', notes: 'distance: 1.2km, travel time: approx 7 minutes'},
          { title: 'default Police Dept. Polda Metro Jaya', pic: '', contact: '(021) 4234262 / 280443351', notes: 'distance: 2.75km, travel time: approx 14 minutes'},
        ],
      },
      oModal: {
        isVisibleEmergencyContact: false,
        isVisibleCalendar: false,
        isVisibleGallery: false,
        isVisibleCCTV: false,
        isVisibleProjectTracking: false,
      },
      oDummyDB: {
        users: [
          { name: 'Robertus Sidiq Soeyono', avatar: '/img/users/a16473213164c87924c29c8__user-avatar-13056_robertus.jpg' },
          { name: 'Bastian Aritra', avatar: '/img/users/a638ae62f715d8__user-avatar-11676_bastian.jpg' },
          { name: 'Adrian Ova Triandi', avatar: '/img/users/a17490994496594ced9dc42b__user-avatar-13738_adrian.jpg' },
          { name: 'Sharon', avatar: '/img/users/a19529149306459cdba77a9f__user-avatar-9645_sharon.jpg' },
          { name: 'Datkita Suranta Tarigan', avatar: '/img/users/a391381798641aee7818728__user-avatar-12233_datkita.jpg' },
          { name: 'Elang Baraka Haris', avatar: '/img/users/a202495548866f2a660135df__user-avatar-14073_elang.jpg' },
        ],
        emergency_contacts: [
          { site_code: '1262', 
            data: [
              { title: 'Emergency Response Team', pic: '', contact: '(+6x) xxx xxxx xxx', notes: ''},
              { title: 'Non-emergency issue', pic: '-', contact: '(+6x) yyy yyyy yyy', notes: ''},
              { title: 'Hospital', pic: '', contact: '(+6x) aaa bbb ccc / aaa bbb ddd (ICU)', notes: 'distance: 250 meters, travel time: 2 minutes'},
              { title: 'Fire Brigade (bomba)', pic: '', contact: '(+6x) ddd ddd ddd', notes: 'distance: 1km, travel time: approx 1 minutes'},
              { title: 'Police Task Force', pic: '', contact: '(+6x) fff fff fff / ggg ggg ggg', notes: 'distance: 1.25km, travel time: approx 2.5 minutes'},
            ]
          },
          { site_code: '7333', 
            data: [
              { title: 'IKN Hospital', pic: '', contact: '(+62) xxx xxxx (ICU)', notes: 'distance: 645 meters, travel time: 4 minutes'},
              { title: 'IKN Fire Brigade', pic: '', contact: '(+62) yyy yyyy', notes: 'distance: 1.2km, travel time: approx 7 minutes'},
              { title: 'IKN Police Station', pic: '', contact: '(+62) zzz zzz', notes: 'distance: 2.75km, travel time: approx 14 minutes'},
              { title: 'IKN Homeland Security', pic: '', contact: '(+62) xyz xyz xyz zzz', notes: 'distance: 12.5km, travel time: approx 8 minutes'},
            ]
          },   
          { site_code: '7384', 
            data: [
              { title: 'DR01 - ERT Menara Repository', pic: '', contact: '(021) 88 888 88', notes: ''},
              { title: 'DR01 - Non-emergency building maintenance issue', pic: 'Mr Helpfull', contact: '+62 810 810 810', notes: ''},
              { title: 'DR01 - Hospital', pic: '', contact: '(021) 721 9000 (ICU)', notes: 'distance: 945 meters, travel time: approx 5 minutes'},
              { title: 'DR01 - Fire Brigade', pic: '', contact: '(021) 7515054', notes: 'distance: 1km, travel time: approx 6 minutes'},
              { title: 'DR01 - Police Department', pic: '', contact: '(021) 4234262 / 280443351', notes: 'distance: 0.75km, travel time: approx 3 minutes'},
            ]
          },  
          { site_code: '7386', 
            data: [
              { title: 'DR03 - ERT Menara Sentraya', pic: '', contact: '(021) 27 881 881', notes: ''},
              { title: 'DR03 - Non-emergency building maintenance issue', pic: 'Pak Saiful', contact: '+62 812 8081 7619', notes: ''},
              { title: 'DR03 - Pertamina Hospital', pic: '', contact: '(021) 721 9000 / 721 9214 (ICU)', notes: 'distance: 645 meters, travel time: 4 minutes'},
              { title: 'DR03 - Fire Brigade South Jakarta', pic: '', contact: '(021) 7515054', notes: 'distance: 1.2km, travel time: approx 7 minutes'},
              { title: 'DR03 - Police Dept. Polda Metro Jaya', pic: '', contact: '(021) 4234262 / 280443351', notes: 'distance: 2.75km, travel time: approx 14 minutes'},
            ]
          },         
        ],
        epc: [
          { site_code: '7333', 
            data: { name: 'PT. Zhejiang Tenaga Pembangunan Indonesia', pic: 'Agus Sriyanto', contact: '+62 818820650' }
          },
          { site_code: '1262', 
            data: { name: 'National Water Agency PUB', pic: 'Mr. John Doe', contact: '+6xx xxx xxx' }
          },
          { site_code: '7384', 
            data: { name: 'EPC-nya DOC Repo 1', pic: 'Mr & Mrs Smith', contact: '+6xx 888 888' }
          },
          { site_code: '7386', 
            data: { name: 'EPC-nya DOC Repo 3', pic: 'Mr. James Bond', contact: '+6xx 007 007' }
          },
        ],
      }
    };
  },
  created() {
    const LoginUser = JSON.parse(window.localStorage.getItem("loginUser"));
    this.myUserID = LoginUser;

    // this.oDashboard.code_proj = this.$route.params.id;
    this.oDashboard.code_site = this.$route.params.id;
  },
  methods: {
    mapOverlayDisplay() {
      this.oDashboard.isShowMapOverlay = !this.oDashboard.isShowMapOverlay;
    },
    loadGoogleMap() {
      GoogleMapsLoader.load(google => {
        this.initMap(google);
      });
    },
    initMap(google) {
      let map, lat = 0.995151, lng = 116.639650, color = "#5e72e4", zoomLevel = 12;

      map = document.getElementById('mapDashboard');
      // let myLatlng = new google.maps.LatLng( lat, lng);
      let myLatlng = new google.maps.LatLng(this.oProject.map.lat, this.oProject.map.lng);
      let mapOptions = {
        zoom: zoomLevel,
        center: myLatlng,
        mapTypeId: google.maps.MapTypeId.SATELLITE,
        mapTypeControl: false,
        rotateControl: true,
        styles: [
          {
            "elementType": "geometry",
            "stylers": [
              {
                "color": "#242f3e"
              }
            ]
          },
          {
            "elementType": "labels.text.fill",
            "stylers": [
              {
                "color": "#746855"
              }
            ]
          },
          {
            "elementType": "labels.text.stroke",
            "stylers": [
              {
                "color": "#242f3e"
              }
            ]
          },
          {
            "featureType": "administrative.locality",
            "elementType": "labels.text.fill",
            "stylers": [
              {
                "color": "#d59563"
              }
            ]
          },
          {
            "featureType": "poi",
            "elementType": "labels.text.fill",
            "stylers": [
              {
                "color": "#d59563"
              }
            ]
          },
          {
            "featureType": "poi.park",
            "elementType": "geometry",
            "stylers": [
              {
                "color": "#263c3f"
              }
            ]
          },
          {
            "featureType": "poi.park",
            "elementType": "labels.text.fill",
            "stylers": [
              {
                "color": "#6b9a76"
              }
            ]
          },
          {
            "featureType": "road",
            "elementType": "geometry",
            "stylers": [
              {
                "color": "#38414e"
              }
            ]
          },
          {
            "featureType": "road",
            "elementType": "geometry.stroke",
            "stylers": [
              {
                "color": "#212a37"
              }
            ]
          },
          {
            "featureType": "road",
            "elementType": "labels.text.fill",
            "stylers": [
              {
                "color": "#9ca5b3"
              }
            ]
          },
          {
            "featureType": "road.highway",
            "elementType": "geometry",
            "stylers": [
              {
                "color": "#746855"
              }
            ]
          },
          {
            "featureType": "road.highway",
            "elementType": "geometry.stroke",
            "stylers": [
              {
                "color": "#1f2835"
              }
            ]
          },
          {
            "featureType": "road.highway",
            "elementType": "labels.text.fill",
            "stylers": [
              {
                "color": "#f3d19c"
              }
            ]
          },
          {
            "featureType": "transit",
            "elementType": "geometry",
            "stylers": [
              {
                "color": "#2f3948"
              }
            ]
          },
          {
            "featureType": "transit.station",
            "elementType": "labels.text.fill",
            "stylers": [
              {
                "color": "#d59563"
              }
            ]
          },
          {
            "featureType": "water",
            "elementType": "geometry",
            "stylers": [
              {
                "color": "#17263c"
              }
            ]
          },
          {
            "featureType": "water",
            "elementType": "labels.text.fill",
            "stylers": [
              {
                "color": "#515c6d"
              }
            ]
          },
          {
            "featureType": "water",
            "elementType": "labels.text.stroke",
            "stylers": [
              {
                "color": "#17263c"
              }
            ]
          }
        ],
        disableDefaultUI: true,
        scrollwheel: true,
        zoomControl: false,
        fullscreenControl: false,
        keyboardShortcuts: false,
        // mapId: "SEMBCORP_MAP_DASHBOARD",
      }

      map = new google.maps.Map(map, mapOptions);
      this.oDashboard.map = map;
      this.drawMarker();
    },
    drawMarker() {
      const inProgressIcon = '/img/theme/general/pin-progress.png';
      const completedIcon = '/img/theme/general/pin-complete.png';

      // const position = { lat: 0.995151, lng: 116.639650 };
      const position = { lat: this.oProject.map.lat, lng: this.oProject.map.lng };
      let markerIcon = inProgressIcon;

      const map = this.oDashboard.map;

      const contentString =
        '<div id="content">' +
        ' <div id="bodyContent">' +
        '   <img id="btn_emergency" src="/img/icons/dashboard/cl_40x40_emergency.png" alt="Emergency Contact">' +
        '   <img id="btn_calendar" src="/img/icons/dashboard/cl_40x40_calendar.png" alt="Calendar">' +
        '   <img id="btn_gallery" src="/img/icons/dashboard/cl_40x40_gallery.png" alt="Gallery">' +
        '   <img id="btn_cctv" src="/img/icons/dashboard/cl_40x40_cctv.png" alt="CCTV">' +
        '   <img id="btn_project_tracking" src="/img/icons/dashboard/cl_40x40_project_tracking.png" alt="Project Progress Tracking">' +
        " </div>" +
        "</div>";
      const infowindow = new google.maps.InfoWindow({
        content: contentString
      });

      const marker = new google.maps.Marker({
        position: position,
        title: this.oProject.map.title,
        icon: markerIcon,
        label: {
          text: this.oProject.map.title,
          color: 'white',
          fontSize: '20px',
          fontWeight: 'bold',
          className: 'custom-marker-label',
          zIndex: 1010,
        },
      });

      marker.addListener("click", () => {
        // this.showEmergencyContactInformation();
        infowindow.open({
          anchor: marker,
          map,
        });
      });

      marker.setMap(this.oDashboard.map);
      this.oDashboard.marker.push(marker);

      document.addEventListener("click", (event) => {
        if (event.target.id && event.target.id === 'btn_emergency') {
          this.showEmergencyContactInformation();
        }
        if (event.target.id && event.target.id === 'btn_calendar') {
          this.showCalendar();
        }
        if (event.target.id && event.target.id === 'btn_gallery') {
          this.showGallery();
        }
        if (event.target.id && event.target.id === 'btn_cctv') {
          this.showCCTV();
        }
        if (event.target.id && event.target.id === 'btn_project_tracking') {
          this.showProjectTracking();
        }
      });

      // google.maps.event.addListener(infowindow, 'domready', () => {
      //   const someButton = document.getElementById('btn_emergency');
      //   if (someButton) {
      //     google.maps.listener.addDomListener(someButton, 'click',() => {
      //       this.showEmergencyContactInformation();
      //     })
      //   }
      // });
    },

    getSiteListing() {
      axios
        .get(apiDomain + 'project/project_site/' + this.oDashboard.code_site, { headers: getHeader() })
        .then ( response => {
          if (response.status === 200) {
            // console.log('getSiteListing');
            // console.log(response);

            // this.oDashboard.code_site = response.data.data[0].site_id
            this.oDashboard.code_proj = response.data.data[0].project.project_id
            this.oProject.map.lat = Number(response.data.data[0].site_latitude)
            this.oProject.map.lng = Number(response.data.data[0].site_longitude)
          }
        })
        .catch ( error => {
          console.log(error.response.status)
        })
        .finally(() => {
          this.getDashboardData();
          this.getContractorFromSite();
        });
    },       
    getDashboardData() {
      axios
        .get(apiDomain + "project/project/" + this.oDashboard.code_proj, { headers: getHeader() })
        .then((response) => {
          if (response.status === 200) {
            // console.log ('getDashboardData');
            // console.log (response);

            // this.oProject.map.title = response.data.data[0].project_name;
            this.oProject.map.title = this.projectName;

            this.oProject.info[0].content = parseFloat( response.data.data[0].project_ppa_approved_capacity.replace( /,/g, "") ) + ' kWp';
            this.oProject.info[1].content = this.libFormatDateTime(response.data.data[0].project_ppa_start_date);
            this.oProject.info[2].content = response.data.data[0].project_ppa_counter_party;
            this.oProject.info[3].content = '';
            
            this.oProject.pic[0].name = this.libFormatPic(response.data.data[0].project_manager.name);
            this.oProject.pic[1].name = this.libFormatPic(response.data.data[0].project_engineer.name);
            this.oProject.pic[2].name = this.libFormatPic(response.data.data[0].project_business_development.name);            
            this.oProject.pic[3].name = this.libFormatPic(response.data.data[0].project_safety.name);

            this.oProject.pic[0].avatar = (this.setUserAvatar(this.oDummyDB.users, this.oProject.pic[0].name));
            this.oProject.pic[1].avatar = (this.setUserAvatar(this.oDummyDB.users, this.oProject.pic[1].name));
            this.oProject.pic[2].avatar = (this.setUserAvatar(this.oDummyDB.users, this.oProject.pic[2].name));
            this.oProject.pic[3].avatar = (this.setUserAvatar(this.oDummyDB.users, this.oProject.pic[3].name));

            //_ simulate emergency contact based on selected site
            // this.setEmergencyContact(this.oDummyDB.emergency_contacts, this.oDashboard.code_proj);
            this.setEmergencyContact(this.oDummyDB.emergency_contacts, this.oDashboard.code_site);

            //_ simulate epc based on selected site
            // this.setEpcInfo(this.oDummyDB.epc, this.oDashboard.code_proj);
            // this.setEpcInfo(this.oDummyDB.epc, this.oDashboard.code_site);
          }
        })
        .catch((error) => {
          console.log(error.response.status);
        })
        .finally(() => {
          this.loadGoogleMap();
        });
    },    
    getContractorFromSite() {
      axios
        .get(apiDomain + 'project/site_contractor.show_by_site/' + this.oDashboard.code_site + '?client=mobile', { headers: getHeader() })
        .then ( response => {
          if (response.status === 200) {
            // console.log('getContractorFromSite');
            console.log(response);
            this.oProject.info[3].content = response.data.data[0].contractor.contractor_name;
            this.setEpcInfo(response.data.data[0].contractor.contractor_id);
          }
        })
        .catch ( error => {
          if (typeof error.response !== "undefined") {
            this.errorFunction(error, "Site Contractor Information")
          }
        })
        .finally(() => {
          // console.log(this.oProject);
        });
    },

    //_ dashboard's modal 
    hideAllModal() {
      this.oModal.isVisibleEmergencyContact = false;
      this.oModal.isVisibleCalendar = false;
      this.oModal.isVisibleGallery = false;
      this.oModal.isVisibleCCTV = false;
      this.oModal.isVisibleProjectTracking = false;
    },
    showEmergencyContactInformation() {
      this.hideAllModal();
      this.oModal.isVisibleEmergencyContact = true;
    },
    showCalendar() {
      // console.log('displaying calendar')
      this.hideAllModal();
      this.oModal.isVisibleCalendar = true;
    },
    showGallery() {
      // console.log('gallery open')
      this.hideAllModal();
      this.oModal.isVisibleGallery = true;

      //_ workaround to display the carousel, recalculate height of the carousel container
      //  the carousel not display when the modal expanded, because the modal was hidden, and the height of carousel set to 0, 
      //  that's why carousel cannot be seen.
      setTimeout(() => {
        window.dispatchEvent(new Event("resize"));
      }, 250);
    },
    showCCTV() {
      // console.log('CCTV information')
      this.hideAllModal();
      this.oModal.isVisibleCCTV = true;
    },
    showProjectTracking() {
      // console.log('project progress tracking')
      this.hideAllModal();
      this.oModal.isVisibleProjectTracking = true;
    },

    //_ custom func, 
    libFormatDateTime( dateSelected ) {
      let this_date = ( dateSelected !== null ) ? dateSelected : "N/A";

      if (this_date !== "N/A") {
        let convert_date = new Date(this_date);
        const year = convert_date.getFullYear();
        const month = convert_date.toLocaleString("en-US", { month: "short" });
        const day = convert_date.toLocaleString("en-US", {day: "2-digit", });
        this_date = `${day}-${month}-${year}`;
      }

      return this_date;
    },
    libFormatPic( pic_name='' ) {
      if ( pic_name == '' || pic_name == null ) {
        return 'N/A';
      } else {
        return pic_name;
      }
    },
    customFormatNumber(theData) {
      console.log("the", theData)
      // let retVal = parseFloat(theData.replace(/,/g, ""));
      let retVal = theData;
      return retVal;
    },
    customFormatDate(theData) {
      let checkDate = theData !== null ? theData : "N/A";
      if (checkDate !== "N/A") {
        let convertCheckDate = new Date(checkDate);
        const year = convertCheckDate.getFullYear();
        const month = convertCheckDate.toLocaleString( "en-US", { month: "short" } );
        const day = convertCheckDate.toLocaleString("en-US", { day: "2-digit", });

        return `${day}-${month}-${year}`;
      }
    },

    //_ assign "dynamic" dummy data on selected site, requirement: set the data in oDummyDB parameter, otherwise it will use the default value
    setUserAvatar(obj, value) {
      let tmpAvatar = Object.keys(obj).find(key => obj[key].name === value );

      return (tmpAvatar === undefined) ? '/img/users/default_avatar_male.png' : obj[tmpAvatar].avatar;
    },
    setEmergencyContact(obj, value) {     
      let indexEC = Object.keys(obj).find(key => obj[key].site_code === value );
      
      if( indexEC !== undefined) {
        this.oProject.emergency_contacts = obj[indexEC].data
      }
    },
    UNUSE_setEpcInfo_JSON(obj, value) {
      // console.log('setEpcInfo')
      // console.log(obj)
      // console.log(value)

      let indexEpcInfo = Object.keys(obj).find(key => obj[key].site_code === value );

      if( indexEpcInfo !== undefined) {
        console.log(obj[indexEpcInfo].data)
        
        this.oProject.epc = obj[indexEpcInfo].data
      }     
    },
    setEpcInfo( id_contractor = '' ) {
      // console.log('setEpcInfo')
      // console.log(obj)
      // console.log(value)
      axios
        .get(apiDomain + 'setup/contractor/'+ id_contractor, { headers: getHeader() })
        .then ( response => {
          if (response.status === 200) {
            this.oProject.epc.name = response.data.data[0].contractor_name
            this.oProject.epc.pic = response.data.data[0].contractor_contact_person
            this.oProject.epc.contact = response.data.data[0].contractor_contact_number
          }
        })
        .catch ( error => {
          console.log(error.response.status)
          this.errorFunction(error, "Contractor Information")
        });
    },

  },
  beforeMount() {
    this.getSiteListing();
  }
};
</script>


<style scoped>
.apexcharts-legend-marker {
  width: 12px !important;
  height: 12px !important;
  left: 0px !important;
  top: 0px !important;
  border-radius: 2px !important;
}

/* .apexcharts-series {
    bottom:  30px !important;
   } */
.apexcharts-rangebar-area {
  margin-bottom: 30px !important;
  padding-bottom: 30px !important;
  top: -80px;
}

/* .apexcharts-rangebar-series {
    padding: 30px !important;
  
  }
  .apexcharts-plot-series {
    padding: 30px !important;
  
  } */
select {
  background: url("data:image/svg+xml,<svg width='10' height='7' viewBox='0 0 10 7' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M5.02552 6.35057L0.320068 0.46875H9.73098L5.02552 6.35057Z' fill='white'/></svg>") no-repeat;
  background-position: calc(100% - 0.75rem) center !important;
  -moz-appearance: none !important;
  -webkit-appearance: none !important;
  appearance: none !important;
  padding-right: 2rem !important;
}

.bg-card-dashboard {
  /* background-color: #23454a; */
  background-image: linear-gradient(to bottom right, #23454a, #406B71);
}

.bg-card-dashboard-solid {
  background-color: #23454a;
  border-radius: 30px;
}

.bg-default-dashboard {
  background-color: #082a2f;
  /* background-size: cover; */
  /* position:fixed; */
  padding: 0;
  margin: 0;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.form-control {
  background-color: #2c545a;
  border-color: #2c545a;
  color: white;

  &:focus {
    background-color: #2c545a;
    border-color: #2c545a;
    color: white;
  }

  &::placeholder {
    color: white;
  }
}

.form-control {
  &:focus {
    &::placeholder {
      color: white;
    }
  }
}

.input-group-text {
  background-color: #2c545a;
  color: white;

  &:focus {
    background-color: #2c545a;
    border-color: #1d4147;
    color: white;
  }
}

.focused {
  .input-group-text {
    background-color: #2c545a;
  }
}

.navbar-search-dark .input-group {
  background-color: #23454a;
  color: black;
  border-color: rgba(255, 255, 255, 0.6);
}

.navbar-search .input-group {
  border-radius: 7px;
}

.el-input__inner,
.el-select-dropdown__item.is-disabled:hover {
  background-color: #23454a;
}

.line-custom {
  background-color: #356168;
  margin-top: 15px;
  margin-bottom: 15px;
}

ul {
  padding-left: 0;
  display: flex;
  flex-flow: row wrap;
}

li {
  list-style-type: none;
  padding: 10px 10px;
  transition: all 0.3s ease;
}


.card2 {
  display: block;
  /* width: 150px; */
  height: 500px;
  padding: 80px 50px;
  background-color: #23454a;
  border-radius: 7px;
  margin: 5px;
  text-align: center;
  line-height: 27px;
  cursor: pointer;
  position: relative;
  color: #fff;
  font-weight: 600;
  font-size: 20px;
  -webkit-box-shadow: 9px 10px 22px -8px rgba(209, 193, 209, .5);
  -moz-box-shadow: 9px 10px 22px -8px rgba(209, 193, 209, .5);
  box-shadow: 9px 10px 22px -8px rgba(209, 193, 209, .5);
  will-change: transform;
}


.flip-enter-active {
  transition: all 0.8s ease;
}

.flip-leave-active {
  display: none;
}

.flip-enter,
.flip-leave {
  transform: rotateY(90deg);
  opacity: 0;

}

/* Form */
.flashcard-form {
  position: relative;
}


label {
  font-weight: 400;
  color: #333;
  margin-right: 10px;
}

input {
  border-radius: 5px;
  border: 2px solid #eaeaea;
  padding: 10px;
  outline: none;
}
</style>

<!-- CSS for map and it's content -->
<style scoped>
/* plz do not tamper required :) it'll mess up the map */
/deep/ .block {
  /* margin: 10px 50px; */
  display: flex;
  /* required */
  flex-flow: row nowrap;
  /* required */
}

/deep/ .block .background,
.block .foreground {
  box-sizing: border-box;
  /* required */
  width: 100%;
  /* required */
  flex: none;
  /* required */
}

/deep/ .block .background {
  /* background: #9ff;
  color: #fff;
  padding: 15px;
  font-size: 30px; */
}

/deep/ .block .foreground {
  padding: 15px;
  border: solid 1px;
  margin-left: -100%;
  /* required */
}

/deep/ .block .foreground .outside {
  position: absolute;
  top: 5px;
  left: 8px;
}

/deep/ #bodyContent:hover > img {
  cursor: pointer;
}
</style>

<!-- [inside map] css for button map overlay -->
<style scoped>
/deep/ .btn-map-overlay {
  background-color: #405e64;
  border: solid 2px;
  padding: 0.4rem 0.8rem;
}

/deep/ .dashboard-container-button-overlay {
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>


<!-- [inside map] css for epc information -->
<style scoped>
/deep/ .dashboard-container-epc-info-parent {
  /* width: 400px; */
  width: 25%;
  /* height: 200px; */
  /* min-height: 300px; */
  float: right;
}

/deep/ .dashboard-container-epc-info {
  background-color: #375f65;
  /* border-color: white; */
  /* width: 402px; */
  width: 100%;
  border: 1px solid white;
  border-radius: 5px;
  /* height: 200px; */
  min-height: 200px;
  align-content: center;
  padding-left: 20px;
  color: white;
  font-size: 15px;
}

/deep/ .dashboard-container-epc-info-card {
  margin-bottom: 10px;
}

/deep/ .dashboard-container-epc-info-row-header {
  margin-top: 10px;
  margin-bottom: 30px;
}

/deep/ .dashboard-container-epc-info-row-content {
  margin-bottom: 8px;
}
</style>

<!-- [inside map] css for main information -->
<style scoped>
/deep/ .dashboard-container-info-parent {
  /* width: 550px; */
  width: 30%;
}

/deep/ .dashboard-container-info {
  background-color: #375f65;
  /* border-color: white; */
  /* width: 552px; */
  width: 100%;
  border: 1px solid white;
  border-radius: 5px;
  /* height: 50px; */
  min-height: 50px;
  align-content: center;
  padding-left: 20px;
  color: white;
  font-size: 15px;
}

/deep/ .dashboard-container-info-card {
  margin-bottom: 10px;
}
</style>

<!-- [inside map] css for pic -->
<style scoped>
/deep/ .dashboard-container-pic-parent {
  /* height: 150px; */
  margin-top: 10px;
  display: flex;
  /* margin-top: auto; */
  align-items: flex-end;
}

/deep/ .dashboard-container-pic-child {
  flex: 1;
  margin-left: 5px;
  margin-right: 5px;
  /* border: 2px solid yellow; */
}

/deep/ .dashboard-container-pic-card {
  background-color: #375f65;
  border: 1px solid white;
  /* border-color: white; */
}

/deep/ .dashboard-container-pic-card img {
  border-radius: 50%;
  /* border: 1px solid #fff; */
  width: 70px;
  height: 70px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 15px;
}

/deep/ .dashboard-pic-title {
  font-size: 15px;
  color: white;
}

/deep/ .dashboard-pic-name {
  font-size: 15px;
  color: white;
}

/deep/ .dashboard-pic-info {
  font-size: 12px;
  color: white;
}
</style>

<!-- css for emergency contact -->
<style scoped>
/deep/ .bg-emergency-contact {
  background-color: #375f65 !important;
}

/deep/ .modal-content {
  border: 1px solid white;
}

/deep/ .emergency-contact-modal .modal-dialog .modal-content {
  height: 100% !important;
  border: 0 !important;
  border-radius: 0 !important;
  /* background-color: transparent !important; */
  /* background: rgba(0,0,0,0.5); */
  border: none !important;
}

/deep/ .emergency-contact-main {
  color: white;
}

/deep/ .emergency-contact-main table, th, td {
  border: 1px solid white;
}

/deep/ .emergency-contact-main th, td {
  padding-left: 8px;
  padding-right: 8px;
}

/deep/ .emergency-contact-main th {
  background-color: #18393e;
}


</style>

<!-- css for assets -->
<style scoped>
/deep/ .background-wrapper {
  border-radius: 30px;
  position: relative;
  width: 100%;
  height: 157px; /* Adjust the height as needed */
  background-image: url('/img/ikn/01.png');
  background-size: cover;
  background-position: center;
  opacity: 0.8; /* Controls the transparency of the image */
}

/deep/ .background-wrapper1 {
  background-image: url('/img/ikn/04.png');
}

/deep/  .background-wrapper2 {
  background-image: url('/img/ikn/06.png');
}

/deep/ .overlay-content {
  width:100%;
  position: absolute;
  top: 40%;
  left: 55%;
  transform: translate(-50%, -50%);
  color: white;
  text-align: left;
  z-index: 1; /* Ensures the content is on top */
}

/deep/ .background-wrapper::before {
  border-radius: 30px;
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5); /* Adds a translucent dark overlay to the image */
  z-index: 0;
}
</style>
