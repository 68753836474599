<template>
  <card>
    <h3 slot="header" class="mb-0">
      New Milestone Template Detail for ({{ milestone_template_name }})
    </h3>

    <div>
      <h5 class="text-danger">
        !!! Reminder:
        <i>
          Please include Weightage if Milestone Template is made for Solar Nova
          Projects.
        </i>
      </h5>
    </div>

    <div class="text-right"></div>
    <form class="needs-validation" @submit.prevent="submit">
      <div class="form-row">
        <b-col md="6">
          <base-input
            label="Milestone Title"
            name="Milestone Title"
            placeholder="Milestone Title"
            v-model="model.milestone_template_title"
          >
          </base-input>
        </b-col>

        <b-col md="3">
          <base-input
            label="Weightage (in %)"
            name="Weigtage"
            placeholder="Weightage (%)"
            v-model="model.weightage"
            type="number"
          >
          </base-input>
        </b-col>

        <b-col md="3">
          <base-input
            label="Milestone Sequence"
            name="Milestone Sequence"
            placeholder="Milestone Sequence"
            v-model="model.milestone_template_sequence"
            type="number"
          >
          </base-input>
        </b-col>
      </div>

      <base-button type="primary" @click="createData()"
        >Create / Save</base-button
      >
      <base-button type="primary" @click="$router.back()">Cancel</base-button>
    </form>
  </card>
</template>
<script>
import BaseSlider from "@/components/BaseSlider";
import {
  LoginURL,
  clientId,
  clientSecret,
  apiDomain,
  getHeader,
} from "@/assets/js/config.js";
import axios from "axios";
import ErrorFunctionMixin from "@/assets/js/errorFunctionMix.js";

export default {
  mixins: [ErrorFunctionMixin],
  components: {
    BaseSlider,
  },
  created() {
    this.model.milestone_template_id = this.$route.params.id;
    axios
      .get(
        apiDomain +
          "template/milestone_template/" +
          this.model.milestone_template_id,
        { headers: getHeader() }
      )
      .then((response) => {
        if (response.status === 200) {
          this.milestone_template_name =
            response.data.data[0].milestone_template_name;
        }
      })
      .catch((error) => {
        console.log(error.response.status);
        this.errorFunction(error, "Milestone Template Information");
      });
  },
  data() {
    return {
      model: {
        milestone_template_id: null,
        milestone_template_title: null,
        milestone_template_sequence: 1,
        weightage: 0,
        active_status: true,
      },
      milestone_template_name: null,
    };
  },
  methods: {
    createData() {
      if (!this.model.milestone_template_title) {
        this.$notify({
          message:
            "<b>Invalid Input : All Column are Mandatory in Milestone Template Details</b> - These field are required.",
          timeout: 10000,
          icon: "ni ni-bulb-61",
          type: "danger",
        });
      } else {
        axios
          .post(apiDomain + "template/milestone_template_details", this.model, {
            headers: getHeader(),
          })
          .then((response) => {
            if (response.status === 200) {
              this.$notify({
                message:
                  "<b>New Milestone Template Details : " +
                  this.model.milestone_template_title +
                  "</b> - Successfully Created.",
                timeout: 10000,
                icon: "ni ni-bell-55",
                type: "default",
              });
              this.$router.back();
            }
          })
          .catch((error) => {
            console.log(error.response.data.errors);
            this.errorFunction(error, "Create Milestone Template Details");
          });
      }
    },
  },
};
</script>
<style>
</style>
