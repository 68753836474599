<template>
  <div>
    <base-header class="pb-6">
      <b-row class="py-4">
        <b-col lg="6" cols="7" >
          <h6 class="h2 text-white d-inline-block mb-0">Project - Site</h6>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <route-bread-crumb></route-bread-crumb>
          </nav>
        </b-col>
        <!-- <b-col lg="6" cols="5" class="text-right">
          <base-button size="sm" type="neutral">New</base-button>
          <base-button size="sm" type="neutral">Filters</base-button>
        </b-col> -->
        <b-col xl="3" md="6">

        </b-col>
        <b-col xl="3" md="6">
          <stats-card title="Project Progress"
                      type="gradient-info"
                      sub-title="34%"
                      icon="ni ni-chart-bar-32">

            <template slot="footer">
              <span class="text-success mr-2"><i class="fa fa-arrow-up"></i> 10.8%</span>
              <span class="text-nowrap">Since last month</span>
            </template>
          </stats-card>
        </b-col>
      </b-row>
      <!-- <b-row >
        <b-col xl="3" md="6">

        </b-col>
        <b-col xl="3" md="6">

        </b-col>
        <b-col xl="3" md="6">

        </b-col>
        <b-col xl="3" md="6">
          <stats-card title="Project Progress"
                      type="gradient-info"
                      sub-title="34%"
                      icon="ni ni-chart-bar-32">

            <template slot="footer">
              <span class="text-success mr-2"><i class="fa fa-arrow-up"></i> 10.8%</span>
              <span class="text-nowrap">Since last month</span>
            </template>
          </stats-card>
        </b-col>
      </b-row> -->
    </base-header>

    <b-container fluid class="mt--6">
      <b-row>
        <b-col>
          <div class="card-wrapper">
            <site-new-form></site-new-form>
          </div>
        </b-col>
      </b-row>
    </b-container>

  </div>
</template>

<script>
  import RouteBreadCrumb from '@/components/Breadcrumb/RouteBreadcrumb';
  import BaseHeader from '@/components/BaseHeader';
  import SiteNewForm from './SiteNewForm'


  export default {
    components: {
      BaseHeader,
      RouteBreadCrumb,
      SiteNewForm
    },
    data() {
      return {

      };
    }
  };
</script>
<style></style>
