<template>
  <section>
    <div>
      Login User ID: {{ myUserID }}<br />
      File Name with Timestamp: {{ filenameWithTimestamp }} <br />
      Status: {{ postRequestStatus }}<br />
      <base-button @click="postDataTest">Test Post Data</base-button>
    </div>
    <input type="file" @change="onChange" />
    <div v-if="postResponse.length > 0">
      1: town council does not exist<br />
      2: contractor does not exist<br />
      3: milestone template does not exist<br />
      4: postal code already exists<br />
      {{ postResponse.length }}<br />
      <b-table
        sticky-header="540px"
        striped
        hover
        :items="postResponse"
      ></b-table
      ><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br />
    </div>
    <xlsx-read ref="excelRead" :file="file">
      <div>
        <!-- <base-button @click="updateTempData">Try Get</base-button>
        <base-button @click="convertExcelDateTime"
          >Convert Excel Date</base-button
        > -->
        <div></div>
        <div>
          original tempDate EPC: <br />
          {{ tempData[0]["EPC START DATE"] }} <br />
          original tempDate TARGET: <br />
          {{ tempData[0]["TARGET TURN ON DATE"] }} <br />
          tempData: <br />
          {{ tempData }}<br />
        </div>
      </div>
      <xlsx-table :sheet="selectedSheet" />
      <xlsx-json ref="excelJson" :sheet="selectedSheet">
        <template #default="{ collection }">
          <div>
            {{ collection }}
          </div>
        </template>
      </xlsx-json>
    </xlsx-read>
  </section>
</template>
<script>
import XlsxRead from "../../../../node_modules/vue-xlsx/dist/components/XlsxRead";
import XlsxTable from "../../../../node_modules/vue-xlsx/dist/components/XlsxTable";
import XlsxJson from "../../../../node_modules/vue-xlsx/dist/components/XlsxJson";
import {
  LoginURL,
  clientId,
  clientSecret,
  apiDomain,
  getHeader,
  getcurrentDate,
} from "@/assets/js/config.js";
import axios from "axios";

export default {
  components: {
    XlsxRead,
    XlsxTable,
    XlsxJson,
  },
  created() {
    const LoginUser = JSON.parse(window.localStorage.getItem("loginUser"));
    this.myUserID = LoginUser;
  },
  data() {
    return {
      file: null,
      filenameWithTimestamp: null,
      tempData: [{ "EPC START DATE": 0, "TARGET TURN ON DATE": 0 }],
      //   tempReadData: null,
      //   convertedTempData: null,
      tempCurrentDate: null,
      postRequestStatus: "not posted yet",
      model: {
        epc_start_date: 0,
        target_turn_on_date: 0,
      },
      myUserID: null,
      filenameWithTimestamp: null,
      postResponse: [],
    };
  },
  methods: {
    onChange(event) {
      this.file = event.target.files ? event.target.files[0] : null;
      //   this.updateTempData();
      //   this.convertExcelDateTime();
    },
    updateTempData() {
      this.tempData = this.$refs.excelJson.$data.collection;
    },
    convertExcelDateTime() {
      this.tempData.forEach((e) => {
        var tempExcelDateEPC = e["EPC START DATE"];
        var tempConvertedDateEPC = this.ExcelDateToJSDate(tempExcelDateEPC);
        var convertedDateOnlyEPC = this.convertToDateOnly(tempConvertedDateEPC);
        e["EPC START DATE"] = convertedDateOnlyEPC;
        var tempExcelDateTARGET = e["TARGET TURN ON DATE"];
        var tempConvertedDateTARGET =
          this.ExcelDateToJSDate(tempExcelDateTARGET);
        var convertedDateOnlyTARGET = this.convertToDateOnly(
          tempConvertedDateTARGET
        );
        e["TARGET TURN ON DATE"] = convertedDateOnlyTARGET;
        e["created_by"] = this.myUserID.id;
        // e["timestamped_filename"] = this.filenameWithTimestamp;
      });
    },
    convertToDateOnly(inputDateTime) {
      const current = inputDateTime;
      const currentMonth = String(current.getMonth() + 1).padStart(2, "0");
      const currentDay = String(current.getDate()).padStart(2, "0");
      const currentDate =
        current.getFullYear() + "-" + currentMonth + "-" + currentDay;
      return currentDate;
    },
    ExcelDateToJSDate(serial) {
      var utc_days = Math.floor(serial - 25569);
      var utc_value = utc_days * 86400;
      var date_info = new Date(utc_value * 1000);

      var fractional_day = serial - Math.floor(serial) + 0.0000001;

      var total_seconds = Math.floor(86400 * fractional_day);

      var seconds = total_seconds % 60;

      total_seconds -= seconds;

      var hours = Math.floor(total_seconds / (60 * 60));
      var minutes = Math.floor(total_seconds / 60) % 60;

      return new Date(
        date_info.getFullYear(),
        date_info.getMonth(),
        date_info.getDate(),
        hours,
        minutes,
        seconds
      );
    },
    postDataTest() {
      this.updateTempData();
      this.convertExcelDateTime();
      this.filenameWithTimestamp =
        Math.round(+new Date() / 1000).toString() + "_" + this.file.name;
      this.tempData.forEach((e) => {
        e["timestamped_filename"] = this.filenameWithTimestamp;
      });

      axios
        .post(apiDomain + "project/site_import_from_upload", this.tempData, {
          headers: getHeader(),
        })
        .then((response) => {
          if (response.status === 200) {
            this.postRequestStatus = "post request succeeded";
            this.postResponse = response.data.data;
          }
        })
        .catch((error) => {
          this.postRequestStatus = "post request failed";
          console.log(error.response.data.errors);
          this.errorFunction(error);
        });
    },
  },
};
</script>

