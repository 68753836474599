<template>
  <div class="content">
    <base-header class="pb-6 bg-default shadow">
      <b-row aling-v="center" class="py-4">
        <b-col cols="7" lg="6">
          <h6 class="h2 text-white d-inline-block mb-0">
            Cycle Time Report
          </h6>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <route-bread-crumb></route-bread-crumb>
          </nav>
        </b-col>
        <b-col cols="5" lg="6" class="text-right"> </b-col>
      </b-row>
    </base-header>

    <b-container fluid class="mt--6 bg-default shadow">
      <div>
        <card class="no-border-card" body-classes="px-0 pb-1" footer-classes="pb-2">
          <template slot="header">
            <h3 class="mb-0">Cycle Time Report</h3>
          </template>
          <div>
            <b-col cols="12" class="d-flex justify-content-center justify-content-sm-between flex-wrap">
              <!-- <el-select
                class="select-primary pagination-select"
                v-model="pagination.perPage"
                placeholder="Per page">
                <el-option
                  class="select-primary"
                  v-for="item in pagination.perPageOptions"
                  :key="item"
                  :label="item"
                  :value="item">
                </el-option>
              </el-select> -->

              <h5 class="text">Note:
                <br>Filter EPC, YEAR and CYCLETIME by clicking on the down-arrow
                <br>Click on the row to go to the corresponding project dashboard
              </h5>

              <div>
                <base-input v-model="searchQuery"
                            prepend-icon="fas fa-search"
                            placeholder="Search...">
                </base-input>
              </div>
              

              
            </b-col>
            <b-col><br></b-col>
            <el-table ref="filterTable"
                      :data="queriedData"
                      @row-click="rowClicked"
                      row-key="project_id"
                      header-row-class-name="thead-light"
                      @sort-change="sortChange"
                      border
                      :cell-style="{padding: '3px', height: '20px'}">

              <el-table-column label="Project Name"
                             min-width="300px"
                             prop="project_name"
                             sortable>
                <template v-slot="{row}">
                    <b-media no-body class="align-items-center">
                        <b-media-body>
                            <span class="font-weight-600 name mb-0 text-sm">{{row.project_name}}</span>
                        </b-media-body>
                    </b-media>
                </template>
              </el-table-column>

              <el-table-column label="EPC"
                             min-width="80px"
                             prop="contractor_codes"
                             sortable
                             :filters="filter_epc_data"
                             :filter-method="filterEpc">
                <template v-slot="{row}">
                    <b-media no-body class="align-items-center">
                        <b-media-body style="text-align:center">
                            <span class="font-weight-600 name mb-0 text-sm">{{row.contractor_codes}}</span>
                        </b-media-body>
                    </b-media>
                </template>
              </el-table-column>

              <el-table-column label="Year"
                             min-width="80px"
                             prop="project_ppa_start_year"
                             sortable
                             :filters="filter_year_data"
                             :filter-method="filterYear">
                <template v-slot="{row}">
                    <b-media no-body class="align-items-center">
                        <b-media-body style="text-align:center">
                            <span class="font-weight-600 name mb-0 text-sm">{{row.project_ppa_start_year}}</span>
                        </b-media-body>
                    </b-media>
                </template>
              </el-table-column>

              <el-table-column label="Capacity (MW)"
                             min-width="100px"
                             prop="project_ppa_approved_capacity_mw"
                             sortable>
                <template v-slot="{row}">
                    <b-media no-body class="align-items-center">
                        <b-media-body style="text-align:center">
                            <span class="font-weight-600 name mb-0 text-sm">{{row.project_ppa_approved_capacity_mw}}</span>
                        </b-media-body>
                    </b-media>
                </template>
              </el-table-column>

              <el-table-column label="PPA to Site Handover"
                             min-width="100px"
                             prop="ppa_to_site_handover_days"
                             sortable>
                <template v-slot="{row}">
                    <b-media no-body class="align-items-center">
                        <b-media-body style="text-align:center">
                            <span class="font-weight-600 name mb-0 text-sm">{{row.ppa_to_site_handover_days}}</span>
                        </b-media-body>
                    </b-media>
                </template>
              </el-table-column>

              <el-table-column label="PPA to EPC Award"
                             min-width="100px"
                             prop="ppa_to_epc_award_days"
                             sortable>
                <template v-slot="{row}">
                    <b-media no-body class="align-items-center">
                        <b-media-body style="text-align:center">
                            <span class="font-weight-600 name mb-0 text-sm">{{row.ppa_to_epc_award_days}}</span>
                        </b-media-body>
                    </b-media>
                </template>
              </el-table-column>

              <el-table-column label="EPC Award to Hoisting"
                             min-width="100px"
                             prop="epc_award_to_hoisting_days"
                             sortable>
                <template v-slot="{row}">
                    <b-media no-body class="align-items-center">
                        <b-media-body style="text-align:center">
                            <span class="font-weight-600 name mb-0 text-sm">{{row.epc_award_to_hoisting_days}}</span>
                        </b-media-body>
                    </b-media>
                </template>
              </el-table-column>

              <el-table-column label="Hoisting to Turn On"
                             min-width="100px"
                             prop="hoisting_to_turn_on_days"
                             sortable>
                <template v-slot="{row}">
                    <b-media no-body class="align-items-center">
                        <b-media-body style="text-align:center">
                            <span class="font-weight-600 name mb-0 text-sm">{{row.hoisting_to_turn_on_days}}</span>
                        </b-media-body>
                    </b-media>
                </template>
              </el-table-column>

              <el-table-column label="Cycletime (Days)"
                             min-width="100px"
                             prop="cycle_time_days"
                             sortable
                             :filters="filter_cycle_time_data"
                             :filter-method="filterCycleTime">
                <template v-slot="{row}">
                    <b-media no-body class="align-items-center">
                        <b-media-body style="text-align:center">
                            <span class="font-weight-600 name mb-0 text-sm">{{row.cycle_time_days}}</span>
                        </b-media-body>
                    </b-media>
                </template>
              </el-table-column>

              <el-table-column label="Target (Days)"
                             min-width="80px"
                             prop="target_days"
                             sortable>
                <template v-slot="{row}">
                    <b-media no-body class="align-items-center">
                        <b-media-body style="text-align:center">
                            <span class="font-weight-600 name mb-0 text-sm">{{row.target_days}}</span>
                        </b-media-body>
                    </b-media>
                </template>
              </el-table-column>

              <!-- <el-table-column min-width="180px" align="right" label="Actions">
                <div slot-scope="{$index, row}" class="d-flex">
                  <base-button v-if="button_access.project_view == 'YES'"
                    @click.native="handleDetails($index, row)" size="sm" type="primary">
                    Dashboard
                  </base-button>
                  <base-button v-if="((button_access.milestone_listing == 'YES') && (myUserID.email == row.project_manager.email))"
                    @click.native="handleMilestone($index, row)" size="sm" type="primary">
                    Milestone
                  </base-button>
                </div>
              </el-table-column> -->
            </el-table>
          </div>
          <!-- <div
            slot="footer"
            class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap">
            <div class="">
              <p class="card-category">
                Showing {{ from + 1 }} to {{ to }} of {{ total }} entries

                <span v-if="selectedRows.length">
                  &nbsp; &nbsp; {{selectedRows.length}} rows selected
                </span>
              </p>
            </div>
            <base-pagination
              class="pagination-no-border"
              :current="pagination.currentPage"
              :per-page="pagination.perPage"
              :total="total"
              @change="paginationChanged($event)">
            </base-pagination>
          </div> -->
        </card>
      </div>
      <br/>
    </b-container>
    </div>
</template>
<script>
import { Table, TableColumn, Select, Option } from 'element-ui';
import RouteBreadCrumb from '@/components/Breadcrumb/RouteBreadcrumb'
import { BasePagination } from '@/components';
import standardPaginationMix from '@/assets/js/standardPaginationMix.js'
import Fuse from 'fuse.js';
import axios from 'axios'
import {LoginURL, clientId, clientSecret, apiDomain, getHeader} from '@/assets/js/config.js'
import ErrorFunctionMixin from '@/assets/js/errorFunctionMix.js'

export default {
  mixins: [standardPaginationMix, ErrorFunctionMixin],
  components: {
    BasePagination,
    RouteBreadCrumb,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn
  },
  created()
  {

    // This code is not correct what you see here is for temporary testing purpose
    // var project_access = JSON.parse(window.localStorage.getItem('project_access'))
    // var project_milestone_access = JSON.parse(window.localStorage.getItem('project_milestone_access'))
    // this.button_access.new_button = project_access.access_create
    // this.button_access.milestone_listing = project_milestone_access.access_listing

    const LoginUser = JSON.parse(window.localStorage.getItem('loginUser'))
    this.myUserID = LoginUser

    axios.get(apiDomain + 'user_access/role_access.project_access' , { headers: getHeader() })
    .then ( response => {
    if (response.status === 200)
    {
        this.button_access.new_button = response.data.data.project_modules[0].access_create
        this.button_access.project_view = response.data.data.project_modules[0].access_show
        this.button_access.milestone_listing = response.data.data.project_modules[2].access_listing
        this.getprojectListing()
    }
    })
    .catch ( error => {
    console.log(error.response.status)
    this.errorFunction(error, "Project Information")
    });

    this.pagination.perPage = 1000;

  },
  data() {
    return {
      propsToSearch:
      [
        // 'project_id',
        'project_name',
        'contractor_codes',
        'project_ppa_start_year',
        // 'project_ppa_approved_capacity_mw',
        // 'ppa_to_site_handover_days',
        // 'ppa_to_epc_award_days',
        // 'epc_award_to_hoisting_days',
        // 'hoisting_to_turn_on_days',
        // 'cycle_time_days',
        // 'target_days',
      ],
      tableColumns: [],
      tableData: [],
      selectedRows: [],
      myUserID: null,
      button_access:
      {
        new_button: null,
        project_view: null,
        milestone_listing: null,
      },
      filter_epc_data: [
        // { text: 'CSL', value: 'CSL' }
        { text: 'None', value: null }
      ],
      filter_year_data: [
        // { text: '2022', value: 2022 }
      ],
      filter_cycle_time_data: [
        { text: 'None', value: null },
        // { text: '798', value: 798 }
        { text: '>180', value: 'greater' },
        { text: '=<180', value: 'less_equal' }
      ],
    };
  },
  methods: {
    getprojectListing()
    {
      axios.get(apiDomain + 'dashboard/cycle_time_report', { headers: getHeader() })
      .then ( response => {
      if (response.status === 200)
      {
          this.tableData = response.data.data
          this.fuseData()
          this.updateFilters();
      }
      })
      .catch ( error => {
      console.log(error.response.status)
      this.errorFunction(error, "Project Information")
      });
    },
    fuseData()
    {
      this.fuseSearch = new Fuse(this.tableData, {
      keys: this.propsToSearch,
      threshold: 0.3
      });
    },
    paginationChanged(page)
    {
        this.pagination.currentPage = page
    },
    handleDetails(index, row)
    {
        this.$router.push({path: 'projectDashboard/'+row.project_id })
    },
    handleMilestone(index, row)
    {
        this.$router.push({path: 'projectMilestoneListing/'+row.project_id })
    },
    rowClicked(row) {
      // if (this.button_access.view_button == "YES") {
        this.buttonClicked = true;
        this.clickedRow = row;
        this.$router.push({
          path: "projectDashboard/" + row.project_id,
        });
      // }
    },
    selectionChange(selectedRows)
    {
        this.selectedRows = selectedRows
    },
    filterEpc(value, row) {
      if (row.contractor_codes != null) {
        var epcArr = row.contractor_codes.split(',');
        if (epcArr.includes(value)) {
          return true;
        }
        return false;
      }
      return row.contractor_codes === value;
    },
    filterYear(value, row) {
        return row.project_ppa_start_year === value;
    },
    filterCycleTime(value, row) {
      if (row.cycle_time_days != null) {
        if (value === 'greater') {
          if (row.cycle_time_days > 180) {
            console.log('if (row.cycle_time_days > 180) {');
            console.log(row.cycle_time_days);
            return true;
          } else {
            return false;
          }
        } else if (value === 'less_equal') {
          if (row.cycle_time_days <= 180) {
            console.log('if (row.cycle_time_days <= 180) {');
            console.log(row.cycle_time_days);
            return true;
          } else {
            return false;
          }
        } else {
          return false;
        }
      }
      return row.cycle_time_days === value;
    },
    updateFilters() {
      this.updateFilterEpc();
      this.updateFilterYear();
    },
    updateFilterEpc() {
      this.tableData.forEach((e) => {
        if (e.contractor_codes != null) {
          var epcArr = e.contractor_codes.split(',');
          epcArr.forEach((e_inner) => {
            const valueExists = this.filter_epc_data.find((o) => o.value === e_inner);
            if (!valueExists) {
              this.filter_epc_data.push({ text: String(e_inner), value: e_inner });
            }
          })
        }        
      })
    },
    updateFilterYear() {
      this.tableData.forEach((e) => {
        const valueExists = this.filter_year_data.find((o) => o.value === e.project_ppa_start_year);
        if (!valueExists) {
          this.filter_year_data.push({ text: String(e.project_ppa_start_year), value: e.project_ppa_start_year });
        }
      })
    },
  }
};
</script>
<style>
  .no-border-card .card-footer{
    border-top: 0;
  }
</style>
