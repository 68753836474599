<template>
  <card>
    <card>
      <div class="form-row">
        <b-col md="3">
          <base-input label="Project"
                      name="Project"
                      v-model="model.project_id">
            <select class="form-control" v-model="model.project_id" @change="checkIfSolarNovaProject(); getGroupFromProject()" :disabled="!isCreator">
              <option Selected disabled value="x">-- Please Select A Project --</option>
              <option v-for="item in projects" :key="item.project_id" v-bind:value="item.project_id">{{ item.project_name }}</option>
            </select>
          </base-input>
        </b-col>

        <b-col md="3">
          <base-input label="Project Group"
                      name="Project Group"
                      v-model="model.group_id">
            <select class="form-control" v-model="model.group_id" @change="getSiteFromGroup()" :disabled="!isCreator">
              <option Selected disabled value="x">-- Please Select A Project To Show Group --</option>
              <option Selected disabled value="y">-- Please Select A Group --</option>
              <option v-for="item in project_groups" :key="item.group_id" v-bind:value="item.group_id">{{ item.group_name }}</option>
            </select>
          </base-input>
        </b-col>
      </div>

      <div class="form-row">
        <b-col md="12">
          <div class="form-group">
            <b-row>
              <b-col md="3"><h5>Project Site(s)</h5></b-col>
              <b-col md="3"><h5>EPC</h5></b-col>
            </b-row>
            <div v-for="(input, index) in selected_project_sites" :key="`projectSiteInput-${index}`">
              <div class="form-row">
                <b-col md="3">
                  <base-input v-model="input.site_id">
                    <select
                      class="form-control"
                      v-model="input.site_id"
                      @change="removeFromList($event); getContractorFromSite($event)"
                      :disabled="!isCreator">
                      <option Selected disabled value="x">
                        -- Please Select A Site --
                      </option>
                      <option
                        v-for="item in project_sites"
                        :key="item.site_id"
                        v-bind:value="item.site_id"
                        :disabled="item.disabled"
                        v-show="!item.v_hide">
                        {{ item.site_name }}
                      </option>
                    </select>
                  </base-input>
                </b-col>

                <b-col md="3">
                  <base-input v-model="input.contractor_info" disabled>
                  </base-input>
                </b-col>

                <b-col md="auto" v-if="isSolarNovaProject && isCreator">
                  <base-button
                    size="sm"
                    type="primary"
                    @click="addField(input, selected_project_sites)">
                    Add
                  </base-button>
                </b-col>

                <b-col md="auto" v-if="isSolarNovaProject && isCreator">
                  <base-button
                    size="sm"
                    type="primary"
                    @click="removeField(index, selected_project_sites)"
                    v-show="selected_project_sites.length > 1">
                    Remove
                  </base-button>
                </b-col>
              </div>
            </div>
          </div>
        </b-col>
      </div>
    </card>

    <card>
      <div class="form-row">
        <b-col md="3">
          <base-input label="Date"
                      name="Date"
                      type="date"
                      v-model="model.toolbox_meeting_date"
                      @change="validateDateTime"
                      :disabled="!isCreator">
          </base-input>
        </b-col>

        <b-col md="3">
          <base-input label="Time"
                      name="Time"
                      type="time"
                      v-model="model.toolbox_meeting_time"
                      @change="validateDateTime"
                      :disabled="!isCreator">
          </base-input>
        </b-col>

        <b-col md="3">
          <base-input label="Conducted By"
                      name="Conducted By"
                      v-model="model.conducted_by.user_name"
                      disabled>
          </base-input>
        </b-col>

        <b-col md="3">
          <base-input label="Shift"
                      name="Shift"
                      v-model="model.shift">
            <select class="form-control" v-model="model.shift" :disabled="!isCreator">
              <option value="0">Day</option> 
              <option value="1">Night</option>
            </select>
          </base-input>
        </b-col>
      </div>
    </card>

    <card>
      <div><h3>Life Saving Rules</h3></div>

      <br>

      <div class="form-row">
        <b-col sm="6" md="2">
          <b-row>
            <b-col style="">
              <img src="/img/brand/lifesavingrules/rule_1.jpg" style="max-width: 100%; height: auto;">
              <input type="checkbox" true-value="1" false-value="0" v-model="model.life_saving_rules.rule_1" class="checkbox-bottom-right" :disabled="!isCreator">
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <span>Obtain authorisation before entering a confined space</span>
            </b-col>
          </b-row>
        </b-col>

        <b-col sm="6" md="2">
          <b-row>
            <b-col style="">
              <img src="/img/brand/lifesavingrules/rule_2.jpg" style="max-width: 100%; height: auto;">
              <input type="checkbox" true-value="1" false-value="0" v-model="model.life_saving_rules.rule_2" class="checkbox-bottom-right" :disabled="!isCreator">
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <span>Protect yourself against a fall when working at height</span>
            </b-col>
          </b-row>
        </b-col>

        <b-col sm="6" md="2">
          <b-row>
            <b-col style="">
              <img src="/img/brand/lifesavingrules/rule_3.jpg" style="max-width: 100%; height: auto;">
              <input type="checkbox" true-value="1" false-value="0" v-model="model.life_saving_rules.rule_3" class="checkbox-bottom-right" :disabled="!isCreator">
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <span>Conduct a gas test when required</span>
            </b-col>
          </b-row>
        </b-col>

        <b-col sm="6" md="2">
          <b-row>
            <b-col style="">
              <img src="/img/brand/lifesavingrules/rule_4.jpg" style="max-width: 100%; height: auto;">
              <input type="checkbox" true-value="1" false-value="0" v-model="model.life_saving_rules.rule_4" class="checkbox-bottom-right" :disabled="!isCreator">
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <span>Work with a valid permit when required</span>
            </b-col>
          </b-row>
        </b-col>

        <b-col sm="6" md="2">
          <b-row>
            <b-col style="">
              <img src="/img/brand/lifesavingrules/rule_5.jpg" style="max-width: 100%; height: auto;">
              <input type="checkbox" true-value="1" false-value="0" v-model="model.life_saving_rules.rule_5" class="checkbox-bottom-right" :disabled="!isCreator">
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <span>Verify isolation before work begins and use specified life protecting equipment</span>
            </b-col>
          </b-row>
        </b-col>

        <b-col sm="6" md="2">
          <b-row>
            <b-col style="">
              <img src="/img/brand/lifesavingrules/rule_6.jpg" style="max-width: 100%; height: auto;">
              <input type="checkbox" true-value="1" false-value="0" v-model="model.life_saving_rules.rule_6" class="checkbox-bottom-right" :disabled="!isCreator">
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <span>Wear a personal flotation device when required</span>
            </b-col>
          </b-row>
        </b-col>
      </div>

      <div class="form-row">
        <b-col sm="6" md="2">
          <b-row>
            <b-col style="">
              <img src="/img/brand/lifesavingrules/rule_7.jpg" style="max-width: 100%; height: auto;">
              <input type="checkbox" true-value="1" false-value="0" v-model="model.life_saving_rules.rule_7" class="checkbox-bottom-right" :disabled="!isCreator">
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <span>Obtain authorisation before overriding or disabling a safety critical equipment</span>
            </b-col>
          </b-row>
        </b-col>

        <b-col sm="6" md="2">
          <b-row>
            <b-col style="">
              <img src="/img/brand/lifesavingrules/rule_8.jpg" style="max-width: 100%; height: auto;">
              <input type="checkbox" true-value="1" false-value="0" v-model="model.life_saving_rules.rule_8" class="checkbox-bottom-right" :disabled="!isCreator">
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <span>Obtain authorisation before excavation</span>
            </b-col>
          </b-row>
        </b-col>

        <b-col sm="6" md="2">
          <b-row>
            <b-col style="">
              <img src="/img/brand/lifesavingrules/rule_9.jpg" style="max-width: 100%; height: auto;">
              <input type="checkbox" true-value="1" false-value="0" v-model="model.life_saving_rules.rule_9" class="checkbox-bottom-right" :disabled="!isCreator">
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <span>Follow prescribed lifting plan</span>
            </b-col>
          </b-row>
        </b-col>

        <b-col sm="6" md="2">
          <b-row>
            <b-col style="">
              <img src="/img/brand/lifesavingrules/rule_10.jpg" style="max-width: 100%; height: auto;">
              <input type="checkbox" true-value="1" false-value="0" v-model="model.life_saving_rules.rule_10" class="checkbox-bottom-right" :disabled="!isCreator">
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <span>No alcohol or drugs while working or driving</span>
            </b-col>
          </b-row>
        </b-col>

        <b-col sm="6" md="2">
          <b-row>
            <b-col style="">
              <img src="/img/brand/lifesavingrules/rule_11.jpg" style="max-width: 100%; height: auto;">
              <input type="checkbox" true-value="1" false-value="0" v-model="model.life_saving_rules.rule_11" class="checkbox-bottom-right" :disabled="!isCreator">
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <span>Do not walk under a suspended load</span>
            </b-col>
          </b-row>
        </b-col>

        <b-col sm="6" md="2">
          <b-row>
            <b-col style="">
              <img src="/img/brand/lifesavingrules/rule_12.jpg" style="max-width: 100%; height: auto;">
              <input type="checkbox" true-value="1" false-value="0" v-model="model.life_saving_rules.rule_12" class="checkbox-bottom-right" :disabled="!isCreator">
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <span>Do not smoke outside designated smoking areas</span>
            </b-col>
          </b-row>
        </b-col>
      </div>
    </card>

    <card>
      <div class="form-row">
        <b-col md="12">
          <label for="textarea">Topics Discussed Before Work Commence:</label>
          <b-form-textarea
            id="textarea"
            v-model="model.topics_discussed"
            placeholder=""
            rows="5"
            :disabled="!isCreator" 
          ></b-form-textarea>
        </b-col>
      </div>
    </card>

    <card>
      <div class="form-row">
        <b-col md="12">
          <label for="textarea">Feedback(s) from Work Crew:</label>
          <b-form-textarea
            id="textarea"
            v-model="model.crew_feedback"
            placeholder=""
            rows="5"
            :disabled="!isCreator"
          ></b-form-textarea>
        </b-col>
      </div>
    </card>

    <card>
      <div class="form-row">
        <b-col md="3">
          <base-input label="Project Manager"
                      name="Project Manager"
                      placeholder="0"
                      v-model="model.manpower.manpower_project_manager" type="number" min="0" @change="calculateManpowerTotal()" :disabled="!isCreator">
          </base-input>
        </b-col>

        <b-col md="3">
          <base-input label="Site Manager"
                      name="Site Manager"
                      placeholder="0"
                      v-model="model.manpower.manpower_site_manager" type="number" min="0" @change="calculateManpowerTotal()" :disabled="!isCreator">
          </base-input>
        </b-col>

        <b-col md="3">
          <base-input label="PV Engineer"
                      name="PV Engineer"
                      placeholder="0"
                      v-model="model.manpower.manpower_pv_engineer" type="number" min="0" @change="calculateManpowerTotal()" :disabled="!isCreator">
          </base-input>
        </b-col>

        <b-col md="3">
          <base-input label="Site In Charge"
                      name="Site In Charge"
                      placeholder="0"
                      v-model="model.manpower.manpower_site_in_charge" type="number" min="0" @change="calculateManpowerTotal()" :disabled="!isCreator">
          </base-input>
        </b-col>
      </div>

      <div class="form-row">
        <b-col md="3">
          <base-input label="WSH Coordinator"
                      name="WSH Coordinator"
                      placeholder="0"
                      v-model="model.manpower.manpower_wsh_coordinator" type="number" min="0" @change="calculateManpowerTotal()" :disabled="!isCreator">
          </base-input>
        </b-col>

        <b-col md="3">
          <base-input label="First Aider"
                      name="First Aider"
                      placeholder="0"
                      v-model="model.manpower.manpower_first_aider" type="number" min="0" @change="calculateManpowerTotal()" :disabled="!isCreator">
          </base-input>
        </b-col>

        <b-col md="3">
          <base-input label="SMO"
                      name="SMO"
                      placeholder="0"
                      v-model="model.manpower.manpower_smo" type="number" min="0" @change="calculateManpowerTotal()" :disabled="!isCreator">
          </base-input>
        </b-col>

        <b-col md="3">
          <base-input label="SDO"
                      name="SDO"
                      placeholder="0"
                      v-model="model.manpower.manpower_sdo" type="number" min="0" @change="calculateManpowerTotal()" :disabled="!isCreator">
          </base-input>
        </b-col>
      </div>

      <div class="form-row">
        <b-col md="3">
          <base-input label="Sembcorp Solar Trained Installer"
                      name="Sembcorp Solar Trained Installer"
                      placeholder="0"
                      v-model="model.manpower.manpower_trained_installer" type="number" min="0" @change="calculateManpowerTotal()" :disabled="!isCreator">
          </base-input>
        </b-col>

        <b-col md="3">
          <base-input label="WAH Supervisor"
                      name="WAH Supervisor"
                      placeholder="0"
                      v-model="model.manpower.manpower_wah_supervisor" type="number" min="0" @change="calculateManpowerTotal()" :disabled="!isCreator">
          </base-input>
        </b-col>

        <b-col md="3">
          <base-input label="Fitter"
                      name="Fitter"
                      placeholder="0"
                      v-model="model.manpower.manpower_fitter" type="number" min="0" @change="calculateManpowerTotal()" :disabled="!isCreator">
          </base-input>
        </b-col>

        <b-col md="3">
          <base-input label="General Worker"
                      name="General Worker"
                      placeholder="0"
                      v-model="model.manpower.manpower_general_worker" type="number" min="0" @change="calculateManpowerTotal()" :disabled="!isCreator">
          </base-input>
        </b-col>
      </div>

      <div class="form-row">
        <b-col md="3">
          <base-input label="Lifting Supervisor"
                      name="Lifting Supervisor"
                      placeholder="0"
                      v-model="model.manpower.manpower_lifting_supervisor" type="number" min="0" @change="calculateManpowerTotal()" :disabled="!isCreator">
          </base-input>
        </b-col>

        <b-col md="3">
          <base-input label="Rigger/Signalman"
                      name="Rigger/Signalman"
                      placeholder="0"
                      v-model="model.manpower.manpower_rigger_signalman" type="number" min="0" @change="calculateManpowerTotal()" :disabled="!isCreator">
          </base-input>
        </b-col>

        <b-col md="3">
          <base-input label="Crane Operator/Lorry Driver"
                      name="Crane Operator/Lorry Driver"
                      placeholder="0"
                      v-model="model.manpower.manpower_crane_lorry_driver" type="number" min="0" @change="calculateManpowerTotal()" :disabled="!isCreator">
          </base-input>
        </b-col>

        <b-col md="3">
          <base-input label="Crane Attendant"
                      name="Crane Attendant"
                      placeholder="0"
                      v-model="model.manpower.manpower_crane_attendant" type="number" min="0" @change="calculateManpowerTotal()" :disabled="!isCreator">
          </base-input>
        </b-col>
      </div>

      <div class="form-row">
        <b-col md="6">
          <base-input label="Total Manpower"
                      name="Total Manpower"
                      placeholder="0"
                      v-model="model.manpower.manpower_total" type="number" min="0" :disabled="!isCreator">
          </base-input>
        </b-col>
      </div>
    </card>

    <card>
      <div class="form-row">
        <b-col md="12">
          <label for="textarea">Work Activity for Today:</label>
          <b-form-textarea
            id="textarea"
            v-model="model.work_activity"
            placeholder=""
            rows="5"
            :disabled="!isCreator"
          ></b-form-textarea>
        </b-col>
      </div>
    </card>

    <card>
      <div><h3>Attachment(s)</h3></div>

      <input
        v-show="false"
        type="file"
        id="imageFile"
        ref="imageFile"
        accept="image/jpeg, image/png, image/gif, image/webp"
        v-on:change="handleImageFileUpload()"
      />

      <div>
        <base-button style="float: right;" type="primary" size="sm" @click="$refs.imageFile.click()" v-if="isCreator">Add Image Attachment</base-button>
      </div>

      <br>
      <br>

      <!-- <div v-for="(item, index) in model.toolbox_meeting_attachments" :key="index + Date.now()"> -->
      <div v-for="(item, index) in model.toolbox_meeting_attachments" :key="index">
        <card v-if="item.active_status != 0">
          <b-row align-v="center">
            <b-col md="4">
              <img
                :src="item.photoPreviewSrc"
                style="max-width:250px;max-height:250px;"
                v-if="item.photoFile"
                @click="showLargeImage(item.photoPreviewSrc)"
              >
              <img
                :src="item.imageDataSrc"
                style="max-width:250px;max-height:250px;"
                v-if="item.imageDataSrc"
                @click="showLargeImage(item.imageDataSrc)"
              >
            </b-col>

            <b-col md="7" v-if="item.photoFile">{{ item.photoFile.name }}</b-col>
            <b-col md="7" v-if="item.imageDataSrc">{{ item.toolbox_meeting_attachment_file_name }}</b-col>

            <b-col md="1">
              <base-button size="sm" type="primary" @click="deletePhoto(index)" v-if="isCreator">
                Delete
              </base-button>
            </b-col>
          </b-row>
        </card>
      </div>
    </card>

    <div>
      <div style="float: right;">
        <base-button type="danger" @click="$router.back()">Cancel</base-button>
        <base-button v-if="!submit_clicked && isCreator" type="success" @click="submitData()">Submit</base-button>
        <base-button v-if="submit_clicked && isCreator" type="success" @click="submitData()" disabled>Submit</base-button>
      </div>
    </div>

    <div>
      Last Modified At: {{ audit_log[0] ? audit_log[0].audit_changed_date : 'N/A' }}<br>
      Last Modified By: {{ audit_log[0] ? audit_log[0].audit_change_by.name : 'N/A' }}<br>
      <base-button size="sm" type="primary" @click="showAuditLog()" v-if="isCreator && audit_log.length > 0">
        Audit Logs
      </base-button>
    </div>

    <modal
      :show.sync="isLargeImageVisible"
      size="xl"
      body-classes="p-0"
      title="Image">
        <card
        type="secondary"
        header-classes="bg-transparent pb-5"
        body-classes="px-lg-5 py-lg-5"
        class="border-0 mb-0">
        <div class="text-center">
          <img
            :src="largeImageSrc"
            style = "max-width:100%;"
          >
        </div>
        </card>
    </modal>

    <modal
      :show.sync="isSubmissionWarningVisible"
      :no-close-on-backdrop="true"
      size="xl"
      body-classes="p-0"
      title="Submission Warning"
    >
      <card>
        <template>
          <h1 class="text-center">Submission in Progress</h1>
          <div>
            Please be patient while we process your submission.<br>
            Do not navigate away from this page or refresh the page until the submission is complete.<br>
            You will be automatically redirected to another page once the process is finished.
          </div>
        </template>
      </card>
    </modal>

    <modal
      :show.sync="isAuditLogVisible"
      size="xl"
      body-classes="p-0"
      title="Audit Logs">
        <card
        type="secondary"
        header-classes="bg-transparent pb-5"
        body-classes="px-lg-5 py-lg-5"
        class="border-0 mb-0">
          <div class="text-center">
            <b-table
            sticky-header="540px"
              :items="audit_log"
              :fields="column">
            </b-table>
          </div>
        </card>
    </modal>
  </card>  
</template>

<script>
  // import BaseSlider from '@/components/BaseSlider'
  import {LoginURL, clientId, clientSecret, apiDomain, getHeader, getcurrentDate} from '@/assets/js/config.js'
  import axios from 'axios'
  import ErrorFunctionMixin from '@/assets/js/errorFunctionMix.js'
  import jsonFormData from 'json-form-data'

  export default {
    mixins: [ErrorFunctionMixin],
    created() {
      var userInfo = JSON.parse(window.localStorage.getItem("loginUser"));
      this.user_role = userInfo.role_id;
      this.user_id = userInfo.id;
      if (this.user_role == 12) {
        this.getContractorId();
      }
      this.toolbox_meeting_id = this.$route.params.id;

      if (this.user_role != 12) {
        this.getToolboxMeeting();
      }

      // this.getToolboxMeeting();
      this.getToolboxMeetingAuditLog();
    },
    data() {
      return {
        user_role: null,
        user_id: null,
        contractor_id: null,
        toolbox_meeting_id: null,
        toolboxMeetingResponse: null,
        model: {
          toolbox_meeting_id: null,
          toolbox_meeting_date: null,
          toolbox_meeting_time: null,
          conducted_by: {
            user_id: null,
            user_name: null,
          },
          shift: 0,
          life_saving_rules: {
            rule_1: 0,
            rule_2: 0,
            rule_3: 0,
            rule_4: 0,
            rule_5: 0,
            rule_6: 0,
            rule_7: 0,
            rule_8: 0,
            rule_9: 0,
            rule_10: 0,
            rule_11: 0,
            rule_12: 0,
          },
          topics_discussed: null,
          crew_feedback: null,
          manpower: {
            manpower_project_manager: 0,
            manpower_site_manager: 0,
            manpower_pv_engineer: 0,
            manpower_site_in_charge: 0,
            manpower_wsh_coordinator: 0,
            manpower_first_aider: 0,
            manpower_smo: 0,
            manpower_sdo: 0,
            manpower_trained_installer: 0,
            manpower_wah_supervisor: 0,
            manpower_fitter: 0,
            manpower_general_worker: 0,
            manpower_lifting_supervisor: 0,
            manpower_rigger_signalman: 0,
            manpower_crane_lorry_driver: 0,
            manpower_crane_attendant: 0,
            manpower_total: 0,
          },

          work_activity: null,
          toolbox_meeting_attachments: [],
          project_id: 'x',
          group_id: 'x',
          project_sites: null,
        },
        projects: [],
        project_groups: [],
        project_sites: [],
        selected_project_sites: [{ site_id: 'x', previousSelection: '', contractor_info: '' }],
        initialLoading: {
          projects: true,
          project_groups: true,
          project_sites: true,
        },
        isSolarNovaProject: false,
        project_type_id: null,
        isLargeImageVisible: false,
        largeImageSrc: null,
        isCreator: false,
        submit_clicked: false,
        isSubmissionWarningVisible: false,
        isFutureDate: false,
        audit_log: null,
        column: [
          {
            key: "audit_table",
            sortable: true,
            label: "Table",
            // tdClass: "text-white",
          },
          {
            key: "audit_column",
            sortable: true,
            label: "Column",
            // tdClass: "text-white",
          },
          {
            key: "audit_data_id",
            sortable: true,
            label: "Audit Data ID",
            // tdClass: "text-white",
          },
          {
            key: "audit_changed_date",
            sortable: true,
            label: "Audit Changed Date",
            // tdClass: "text-white",
          },
          {
            key: "audit_change_by.name",
            sortable: true,
            label: "Audit Changed By",
            // tdClass: "text-white",
          },
          {
            key: "audit_old_value",
            sortable: true,
            label: "Audit Old Value",
            // tdClass: "text-white",
          },
          {
            key: "audit_new_value",
            sortable: true,
            label: "Audit New Value",
            // tdClass: "text-white",
          },
        ],
        isAuditLogVisible: false,
      }
    },
    methods: {
      getToolboxMeeting()
      {
        axios.get(apiDomain + 'health_safety/toolbox_meeting/' + this.toolbox_meeting_id, { headers: getHeader() })
        .then ( response => {
        if (response.status === 200)
        {
          this.toolboxMeetingResponse = response.data.data;

          this.model.toolbox_meeting_id = response.data.data[0].toolbox_meeting_id;

          this.getProjectListing();
          this.model.project_id = response.data.data[0].project_sites[0].project_site.project_id;
          this.getGroupFromProject();
          this.model.group_id = response.data.data[0].project_sites[0].project_site.group_id;
          this.getSiteFromGroup();

          this.model.toolbox_meeting_date = this.convertDateFormat(response.data.data[0].toolbox_meeting_date);
          this.model.toolbox_meeting_time = response.data.data[0].toolbox_meeting_time;
          this.model.conducted_by.user_id = response.data.data[0].created_by.id;
          if (this.model.conducted_by.user_id == this.user_id) {
            this.isCreator = true;
          }
          this.model.conducted_by.user_name = response.data.data[0].created_by.name;
          this.model.shift = response.data.data[0].shift;

          this.model.life_saving_rules.rule_1 = response.data.data[0].life_saving_rule_1;
          this.model.life_saving_rules.rule_2 = response.data.data[0].life_saving_rule_2;
          this.model.life_saving_rules.rule_3 = response.data.data[0].life_saving_rule_3;
          this.model.life_saving_rules.rule_4 = response.data.data[0].life_saving_rule_4;
          this.model.life_saving_rules.rule_5 = response.data.data[0].life_saving_rule_5;
          this.model.life_saving_rules.rule_6 = response.data.data[0].life_saving_rule_6;
          this.model.life_saving_rules.rule_7 = response.data.data[0].life_saving_rule_7;
          this.model.life_saving_rules.rule_8 = response.data.data[0].life_saving_rule_8;
          this.model.life_saving_rules.rule_9 = response.data.data[0].life_saving_rule_9;
          this.model.life_saving_rules.rule_10 = response.data.data[0].life_saving_rule_10;
          this.model.life_saving_rules.rule_11 = response.data.data[0].life_saving_rule_11;
          this.model.life_saving_rules.rule_12 = response.data.data[0].life_saving_rule_12;

          this.model.topics_discussed = response.data.data[0].topics_discussed;
          this.model.crew_feedback = response.data.data[0].crew_feedback;

          this.model.manpower.manpower_project_manager = response.data.data[0].manpower_project_manager;
          this.model.manpower.manpower_site_manager = response.data.data[0].manpower_site_manager;
          this.model.manpower.manpower_pv_engineer = response.data.data[0].manpower_pv_engineer;
          this.model.manpower.manpower_site_in_charge = response.data.data[0].manpower_site_in_charge;
          this.model.manpower.manpower_wsh_coordinator = response.data.data[0].manpower_wsh_coordinator;
          this.model.manpower.manpower_first_aider = response.data.data[0].manpower_first_aider;
          this.model.manpower.manpower_smo = response.data.data[0].manpower_smo;
          this.model.manpower.manpower_sdo = response.data.data[0].manpower_sdo;
          this.model.manpower.manpower_trained_installer = response.data.data[0].manpower_trained_installer;
          this.model.manpower.manpower_wah_supervisor = response.data.data[0].manpower_wah_supervisor;
          this.model.manpower.manpower_fitter = response.data.data[0].manpower_fitter;
          this.model.manpower.manpower_general_worker = response.data.data[0].manpower_general_worker;
          this.model.manpower.manpower_lifting_supervisor = response.data.data[0].manpower_lifting_supervisor;
          this.model.manpower.manpower_rigger_signalman = response.data.data[0].manpower_rigger_signalman;
          this.model.manpower.manpower_crane_lorry_driver = response.data.data[0].manpower_crane_lorry_driver;
          this.model.manpower.manpower_crane_attendant = response.data.data[0].manpower_crane_attendant;
          this.model.manpower.manpower_total = response.data.data[0].manpower_total;

          this.model.work_activity = response.data.data[0].work_activity;

          this.model.toolbox_meeting_attachments = response.data.data[0].toolbox_meeting_attachments;
          this.loadAllToolboxMeetingAttachments();
        }
        })
        .catch ( error => {
          console.log(error.response.status)
          this.errorFunction(error, "Safety Observation Information")
        });
      },
      getToolboxMeetingAuditLog()
      {
        axios.get(apiDomain + 'health_safety/toolbox_meeting.audit_log/' + this.toolbox_meeting_id, { headers: getHeader() })
        .then ( response => {
        if (response.status === 200)
        {
          this.audit_log = response.data.data;
        }})
        .catch ( error => {
          console.log(error.response.status)
          this.errorFunction(error, "Toolbox Meeting Audit Log")
        });
      },
      showAuditLog(){
        this.isAuditLogVisible = true;
      },
      convertDateFormat(inputDateString){
        // inputDateString format e.g. "25-May-2023"
        // console.log('convertDateFormat(inputDateString){');
        // console.log(inputDateString);

        // Helper function to map month name to month number
        const getMonthNumber = (monthName) => {
          const monthNames = [
            "Jan", "Feb", "Mar", "Apr", "May", "Jun",
            "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
          ];
          return monthNames.indexOf(monthName);
        };

        // Split the date string by the hyphen (-) to extract the day, month, and year
        const parts = inputDateString.split("-");
        const day = parseInt(parts[0], 10);
        const monthName  = parts[1];
        const year = parseInt(parts[2], 10);

        const month = getMonthNumber(monthName);

        // Create a new Date object by specifying the year, month (zero-based), and day
        const dateObject = new Date(year, month, day);

        const newDay = ("0" + dateObject.getDate()).slice(-2);
        const newMonth = ("0" + (dateObject.getMonth() + 1)).slice(-2);
        const newYear = dateObject.getFullYear();

        // Format the date to the desired format "YYYY-MM-DD"
        const formattedDate = newYear + '-' + newMonth + '-' + newDay;

        return formattedDate;
      },
      loadAllToolboxMeetingAttachments() {
        this.model.toolbox_meeting_attachments.forEach((e) => {
          console.log('123');
          if (e['image']['url']) {
            axios.get(apiDomain + e['image']['url'], { headers: getHeader() })
            .then ( response => {
              if (response.status === 200)
              {
                if (response.data.data.image && response.data.data.image) {
                  e['imageDataSrc'] = `data:${response.data.data.image};base64,${response.data.data.image}`;
                } else {
                  e['imageDataSrc'] =  null;
                }
              }
            })
            .catch ( error => {
              console.log(error.response.status)
            });
          }
        });
      },
      getProjectListing(){
        if (this.user_role == 12) {
          this.getProjectListingContractor();
        } else {
          this.getProjectListingNormal();
        }
      },
      getProjectListingNormal()
      {
        axios.get(apiDomain + 'project/project', { headers: getHeader() })
        .then ( response => {
        if (response.status === 200)
        {
            this.projects = response.data.data;
            if (this.initialLoading.projects) {
              this.checkIfSolarNovaProject();
              this.initialLoading.projects = false;
            } else {
              this.project_groups = [];
              this.model.group_id = 'y';
              this.project_sites = [];
              this.selected_project_sites = [{ site_id: 'x', previousSelection: '', contractor_info: '' }];
            }
        }
        })
        .catch ( error => {
        console.log(error.response.status)
        this.errorFunction(error, "Project Listing")
        });
      },
      getProjectListingContractor()
      {
        axios.get(apiDomain + 'project/project.show_by_contractor/'  + this.contractor_id, { headers: getHeader() })
        .then ( response => {
        if (response.status === 200)
        {
            this.projects = response.data.data;
            if (this.initialLoading.projects) {
              this.checkIfSolarNovaProject();
              this.initialLoading.projects = false;
            } else {
              this.project_groups = [];
              this.model.group_id = 'y';
              this.project_sites = [];
              this.selected_project_sites = [{ site_id: 'x', previousSelection: '' }];
            }
        }
        })
        .catch ( error => {
        console.log(error.response.status)
        this.errorFunction(error, "Project Listing Contractor")
        });
      },
      getGroupFromProject(){
        if (this.user_role == 12) {
          this.getGroupFromProjectContractor();
        } else {
          this.getGroupFromProjectNormal();
        }
      },
      getGroupFromProjectNormal()
      {
        axios.get(apiDomain + 'project/project_group.show_by_project/' + this.model.project_id , { headers: getHeader() })
        .then ( response => {
          if (response.status === 200)
          {
            this.project_groups = response.data.data;
            if (this.initialLoading.project_groups) {
              this.initialLoading.project_groups = false;
            } else {
              this.model.group_id = 'y';
              this.project_sites = [];
              this.selected_project_sites = [{ site_id: 'x', previousSelection: '' }];
            }            
          }
        })
        .catch ( error => {
          console.log(error.response.status);
        } );
      },
      getGroupFromProjectContractor()
      {
        axios.get(apiDomain + 'project/project_group.show_by_project_by_contractor/' + this.model.project_id + '/' + this.contractor_id, { headers: getHeader() })
        .then ( response => {
          if (response.status === 200)
          {
            this.project_groups = response.data.data;
            if (this.initialLoading.project_groups) {
              this.initialLoading.project_groups = false;
            } else {
              this.model.group_id = 'y';
              this.project_sites = [];
              this.selected_project_sites = [{ site_id: 'x', previousSelection: '' }];
            }
          }
          })
        .catch ( error => {
        console.log(error.response.status)
        });
      },
      getSiteFromGroup(){
        if (this.user_role == 12) {
          this.getSiteFromGroupContractor();
        } else {
          this.getSiteFromGroupNormal();
        }
      },
      getSiteFromGroupNormal()
      {
        axios.get(apiDomain + 'project/project_site.show_by_group/' + this.model.group_id , { headers: getHeader() })
        .then ( response => {
          if (response.status === 200)
          {
            this.project_sites = response.data.data;
            if (this.initialLoading.project_sites) {
              this.populateProjectSites();
              this.initialLoading.project_sites = false;
            }
          }
        })
        .catch ( error => {
          console.log(error.response.status);
        } );
      },
      getSiteFromGroupContractor()
      {
        axios.get(apiDomain + 'project/project_site.show_by_group_by_contractor/' + this.model.group_id + '/' + this.contractor_id, { headers: getHeader() })
        .then ( response => {
          if (response.status === 200)
          {
            this.project_sites = response.data.data;
            if (this.initialLoading.project_sites) {
              this.populateProjectSites();
              this.initialLoading.project_sites = false;
            }
          }
          })
        .catch ( error => {
        console.log(error.response.status)
        });
      },
      populateProjectSites(){
        this.selected_project_sites = [];
        this.toolboxMeetingResponse[0].project_sites.forEach((item) => {
          if (item.active_status == 1) {
            this.selected_project_sites.push({ site_id: item.project_site.site_id});
            this.project_sites.map((e) => {
              if (e.site_id == item.project_site.site_id) {
                e["disabled"] = true;
                e["v_hide"] = true;
              }
            });
          }          
        });
        this.selected_project_sites.forEach((site) => {
          this.getIndividualContractorFromSite(site);
        });
      },
      checkIfSolarNovaProject(){
        this.project_type_id = this.projects.find(project => project.project_id == this.model.project_id).project_type.project_type_id;
        if ( this.project_type_id != null &&
            // ( this.project_type_id == 2 || this.project_type_id == 3 || this.project_type_id == 4 )
            ([2, 3, 4, 9, 10, 11, 12, 13, 14].includes(this.project_type_id))
            ) {
          this.isSolarNovaProject = true;
        } else {
          this.isSolarNovaProject = false;
        }
      },
      addField(object, fieldType) {
        let newObject = {};
        for (const [key, value] of Object.entries(object)) {
          newObject[key] = "";
        }
        fieldType.push(newObject);
      },
      removeField(index, fieldType) {
        if (fieldType[index].site_id != "") {
          this.project_sites.map((project_site) => {
            if (project_site.site_id == fieldType[index].site_id) {
              project_site["disabled"] = false;
              project_site["v_hide"] = false;
            }
          });
        }
        fieldType.splice(index, 1);
      },
      removeFromList(event)
      {
        this.selected_project_sites.map((selected_project_site) => {
          if (selected_project_site.site_id == event.target.value) {
            if (selected_project_site.previousSelection != "") {
              this.project_sites.map((project_site) => {
                if (project_site.site_id == selected_project_site.previousSelection) {
                  project_site["disabled"] = false;
                  project_site["v_hide"] = false;
                }
              });
            }
          }
          this.project_sites.map((project_site) => {
            if (project_site.site_id == event.target.value) {
              project_site["disabled"] = true;
              project_site["v_hide"] = true;
            }
          });
          selected_project_site.previousSelection = selected_project_site.site_id;
        });
      },
      getIndividualContractorFromSite(site) {
        axios.get(apiDomain + 'project/site_contractor.show_by_site/' + site.site_id , { headers: getHeader() })
        .then ( response => {
          if (response.status === 200)
          {
            let site_contractors = response.data.data;
            var index = site_contractors.findIndex(x => x.active_status ==="ACTIVE");

            site.contractor_id = site_contractors[index].contractor.contractor_id;
            site.contractor_info = site_contractors[index].contractor.contractor_code;

            this.selected_project_sites = [...this.selected_project_sites];
          }
        })
        .catch ( error => {
          console.log(error.response.status)
          this.errorFunction(error, "Site Contractor Information")
        } );
      },
      getContractorFromSite(event)
      {
        axios.get(apiDomain + 'project/site_contractor.show_by_site/' + event.target.value , { headers: getHeader() })
        .then ( response => {
          if (response.status === 200)
          {
            let site_contractors = response.data.data;
            var index = site_contractors.findIndex(x => x.active_status ==="ACTIVE");

            this.selected_project_sites.map((selected_project_site) => {
              if (selected_project_site.site_id == event.target.value) {
                selected_project_site["contractor_id"] = site_contractors[index].contractor.contractor_id;
                selected_project_site["contractor_info"] = site_contractors[index].contractor.contractor_code;
              }});
            }
            this.selected_project_sites = [...this.selected_project_sites];
        })
        .catch ( error => {
          console.log(error.response.status)
          this.errorFunction(error, "Site Contractor Information")
        } );
      },
      calculateManpowerTotal() {
        this.model.manpower.manpower_total = parseInt(this.model.manpower.manpower_project_manager) +
                                            parseInt(this.model.manpower.manpower_site_manager) +
                                            parseInt(this.model.manpower.manpower_pv_engineer) +
                                            parseInt(this.model.manpower.manpower_site_in_charge) +
                                            parseInt(this.model.manpower.manpower_wsh_coordinator) +
                                            parseInt(this.model.manpower.manpower_first_aider) +
                                            parseInt(this.model.manpower.manpower_smo) +
                                            parseInt(this.model.manpower.manpower_sdo) +
                                            parseInt(this.model.manpower.manpower_trained_installer) +
                                            parseInt(this.model.manpower.manpower_wah_supervisor) +
                                            parseInt(this.model.manpower.manpower_fitter) +
                                            parseInt(this.model.manpower.manpower_general_worker) +
                                            parseInt(this.model.manpower.manpower_lifting_supervisor) +
                                            parseInt(this.model.manpower.manpower_rigger_signalman) +
                                            parseInt(this.model.manpower.manpower_crane_lorry_driver) +
                                            parseInt(this.model.manpower.manpower_crane_attendant);
      },
      loadAllToolboxMeetingAttachments() {
        this.model.toolbox_meeting_attachments.forEach((e) => {
          if (e['image']['url']) {
            axios.get(apiDomain + e['image']['url'], { headers: getHeader() })
            .then ( response => {
              if (response.status === 200)
              {
                if (response.data.data.image && response.data.data.image) {
                  e['imageDataSrc'] = `data:${response.data.data.image};base64,${response.data.data.image}`;
                } else {
                  e['imageDataSrc'] =  null;
                }
              }
            })
            .catch ( error => {
              console.log(error.response.status)
            });
          }
        });
      },
      handleImageFileUpload() {
        let vm = this;
        this.model.toolbox_meeting_attachments.push(
          {
            photoFile: {},
            photoPreviewSrc: "",
          },
        );
        this.model.toolbox_meeting_attachments.at(-1).photoFile = this.$refs.imageFile.files[0];

        var reader = new FileReader();
        reader.onload = function(e) {
          vm.model.toolbox_meeting_attachments.at(-1).photoPreviewSrc = e.target.result;
        };

        reader.readAsDataURL(this.model.toolbox_meeting_attachments.at(-1).photoFile);
      },
      showLargeImage(imageSrc){
        this.largeImageSrc = imageSrc;
        this.isLargeImageVisible = true;
      },
      deletePhoto(index){
        if (typeof this.model.toolbox_meeting_attachments[index]['toolbox_meeting_attachment_id'] !== "undefined" ) {
          if (typeof this.model.toolbox_meeting_attachments_to_be_deleted == "undefined") {
            this.model.toolbox_meeting_attachments_to_be_deleted = [];
          }
          this.model.toolbox_meeting_attachments_to_be_deleted.push(this.model.toolbox_meeting_attachments[index]['toolbox_meeting_attachment_id']);
        }
        this.model.toolbox_meeting_attachments.splice(index, 1);
      },
      checkAndCreateProjectSites() {
        let tempProjectSites = [];
        this.selected_project_sites.forEach((item) => {
          if (item.site_id != 'x' && item.site_id != "") {
            tempProjectSites.push({ site_id: item.site_id });
          }
        });
        this.model.project_sites = tempProjectSites;
      },
      validateDateTime() {
        const currentDate = new Date();
        const enteredDate = new Date(`${this.model.toolbox_meeting_date}T${this.model.toolbox_meeting_time}`);
        this.isFutureDate = enteredDate > currentDate;
      },
      checkForRequiredData() {
        this.checkAndCreateProjectSites();
        this.validateDateTime();
        var checker = false;
        if (
          this.model.project_id == 'x' ||
          this.model.group_id == 'x' ||
          !this.model.toolbox_meeting_date ||
          !this.model.toolbox_meeting_time ||
          !this.model.conducted_by.user_id ||
          this.model.project_sites.length < 1
        ) {
          if (this.model.project_id == 'x') {
            this.redErrorNotify(
              "<b>Invalid Input : Project is a Mandatory Field in Toolbox Meeting </b>"
            );
          } else if (this.model.group_id == 'x') {
            this.redErrorNotify(
              "<b>Invalid Input : Project Group is a Mandatory Field in Toolbox Meeting </b>"
            );          
          } else if (!this.model.toolbox_meeting_date) {
            this.redErrorNotify(
              "<b>Invalid Input : Date is a Mandatory Field in Toolbox Meeting </b>"
            );          
          } else if (!this.model.toolbox_meeting_time) {
            this.redErrorNotify(
              "<b>Invalid Input : Time is a Mandatory Field in Toolbox Meeting </b>"
            );          
          } else if (!this.model.conducted_by.user_id) {
            this.redErrorNotify(
              "<b>Invalid Input : Conducted By is a Mandatory Field in Toolbox Meeting </b>"
            );          
          } else if (this.model.project_sites.length < 1) {
            this.redErrorNotify(
              "<b>Invalid Input : Project Site is a Mandatory Field in Toolbox Meeting </b>"
            );
          } else if (this.isFutureDate) {
            this.redErrorNotify(
              "<b>Invalid Input : Date and Time cannot be in the future. </b>"
            );
          } else {
            this.redErrorNotify(
              "<b>Invalid Input : Please fill in all the Mandatory Fields in Toolbox Meeting </b>"
            );
          }        
        } else {
          checker = true;
          this.submit_clicked = true;
          this.isSubmissionWarningVisible = true;
        }
        return checker;
      },
      convertTime() {
        const time = this.model.toolbox_meeting_time;

        // Check if the time format is "HH:MM:SS"
        if (time.match(/^\d{2}:\d{2}:\d{2}$/)) {
          // Extract the hours and minutes
          const [hours, minutes] = time.split(':');
          this.model.toolbox_meeting_time = `${hours}:${minutes}`;
        }
      },
      submitData(){
        if (this.checkForRequiredData()) {
          console.log('submit data');
          
          this.convertTime();
          let formData = jsonFormData(this.model);
          formData.append("_method", "put");

          const tokenData = JSON.parse(window.localStorage.getItem("authUser"));
          const headers = {
            Authorization: "Bearer " + tokenData.access_token,
          };

          axios
            .post(
              apiDomain + "health_safety/toolbox_meeting/" + this.toolbox_meeting_id,
              formData,
              { headers }
            )
            .then((response) => {
              if (response.status === 200) {
                this.$notify({
                message:
                  '<b>Toolbox Meeting Successfully Created and Submitted.</b>',
                timeout: 10000,
                icon: 'ni ni-bell-55',
                type: 'default',
                });
                this.$router.back()
              }
            })
            .catch((error) => {
              if (error.response) {
                // Handle error with response
                this.errorFunction(error, "Toolbox Meeting Submit");
              } else {
                // Handle error without response
                console.log(error)
                this.$notify({
                  message:
                  // '<b>System Warning : </b> - The System is Currently Busy or Un-Available.',
                  '<b>System Warning : </b> - There is an error with your submission. Please contact Sembcorp staff you know or email to sembsolar.momens@sembcorp.com',
                  timeout: 15000,
                  icon: 'ni ni-bulb-61',
                  type: 'warning',
                });
              }
              this.submit_clicked = false;
              this.isSubmissionWarningVisible = false;
            });
          }
      },
      getContractorId(){
        axios.get(apiDomain + 'lookup/contractor.contractor_id/' + this.user_id, { headers: getHeader() })
          .then ( response => {
          if (response.status === 200)
          {
            this.contractor_id = response.data.contractor_id;
            // this.getProjectListing();
            this.getToolboxMeeting();
          }
          })
          .catch ( error => {
            this.errorFunction(error, 'Contractor Id')
          });
      },
    },
  }
</script>

<style>
.form-control {
  color: black;
}
.form-row {
  color: black;
}
select option {
  color: black;
}
textarea {
  color: black;
}
.checkbox-bottom-right {
  position: absolute;
  bottom: 0;
}
</style>