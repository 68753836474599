<template>
    <b-card no-body>
        <b-card-header class="border-0">
            <h3 class="mb-0">Document Recurring Listing For (Staff Daily Activity Report)</h3>
        </b-card-header>

        <el-table class="table-responsive table-flush"
                  header-row-class-name="thead-light"
                  :data="projects">
            <el-table-column label="Recurring Date"
                             min-width="110px"
                             prop="name"
                             sortable>
                <template v-slot="{row}">
                    <b-media no-body class="align-items-center">
                        <b-media-body>
                            <span class="font-weight-600 name mb-0 text-sm">{{row.recurring_date}}</span>
                        </b-media-body>
                    </b-media>
                </template>
            </el-table-column>

            <el-table-column label="Interval"
                             min-width="120px"
                             prop="name"
                             sortable>
                <template v-slot="{row}">
                    <b-media no-body class="align-items-center">
                        <b-media-body>
                            <span class="font-weight-600 name mb-0 text-sm">{{row.recurring_interval}}</span>
                        </b-media-body>
                    </b-media>
                </template>
            </el-table-column>

            <el-table-column label="Total File" min-width="110px">
                <template v-slot="{row}">
                    <b-media no-body class="align-items-center">

                        <b-media-body>
                            <span class="font-weight-600 name mb-0 text-sm">{{row.total_file}}</span>
                        </b-media-body>
                    </b-media>
                </template>
            </el-table-column>

            <el-table-column label="Current Status"
                             min-width="110px"
                             prop="status"
                             sortable>
                <template v-slot="{row}">
                    <badge class="badge-dot mr-4" type="">
                        <!-- <i :class="`bg-${row.statusType}`"></i> -->
                        <span class="status">{{row.status}}</span>
                    </badge>
                </template>
            </el-table-column>

            <!-- <el-table-column label="P.Manager" min-width="90px">
                <template v-slot="{row}">
                    <b-media no-body class="align-items-center">

                        <b-media-body>
                            <span class="font-weight-600 name mb-0 text-sm">{{row.manager}}</span>
                        </b-media-body>
                    </b-media>
                </template>
            </el-table-column>

            <el-table-column label="Completion"
                             prop="completion"
                             min-width="160px"
                             sortable>
                <template v-slot="{row}">
                    <div class="d-flex align-items-center">
                        <span class="completion mr-2">{{row.completion}}%</span>
                        <div>
                            <base-progress :type="row.statusType" :value="row.completion"/>
                        </div>
                    </div>
                </template>
            </el-table-column> -->

            <el-table-column label="Action" min-width="110px">
               <template v-slot="">
                    <div class="d-flex align-items-center">
                        <div>
                          <router-link to="/projectDashboard">
                            <base-button size="sm" type="primary">Doc. Details</base-button>
                          </router-link>
                        </div>
                        <!-- &nbsp;
                        <div>
                           <router-link to="/projectedit">
                            <base-button size="sm" type="primary">Edit</base-button>
                          </router-link>
                        </div> -->
                    </div>
                </template>
            </el-table-column>
            <!-- <el-table-column min-width="180px">
                <template v-slot="">
                    <el-dropdown trigger="click" class="dropdown">
                    <span class="btn btn-sm btn-icon-only text-light">
                      <i class="fas fa-ellipsis-v mt-2"></i>
                    </span>
                        <el-dropdown-menu class="dropdown-menu dropdown-menu-arrow show" slot="dropdown">
                            <b-dropdown-item>Action</b-dropdown-item>
                            <b-dropdown-item>Another action</b-dropdown-item>
                            <b-dropdown-item>Something else here</b-dropdown-item>
                        </el-dropdown-menu>
                    </el-dropdown>
                </template>
            </el-table-column> -->
        </el-table>

        <b-card-footer class="py-4 d-flex justify-content-end">
            <base-pagination v-model="currentPage" :per-page="3" :total="6"></base-pagination>
        </b-card-footer>
    </b-card>
</template>

<script>
  import projects from './projects'
  import { Table, TableColumn, DropdownMenu, DropdownItem, Dropdown} from 'element-ui'
  import {homeLink} from '@/assets/js/config.js'

  export default {
    name: 'project-table',
    components: {
      [Table.name]: Table,
      [TableColumn.name]: TableColumn,
      [Dropdown.name]: Dropdown,
      [DropdownItem.name]: DropdownItem,
      [DropdownMenu.name]: DropdownMenu,
    },
    data() {
      return {
        projects,
        currentPage: 1,
        img1: homeLink+'/img/theme/team-1.jpg',
      };
    }
  }
</script>
