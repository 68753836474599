<template>
  <div class="bg-default-dashboard">
    <div class=" py-lg-1 pt-lg-7">
      <b-container fluid style="width: 95%; position: relative;">
        <!-- used `style="height: 100vh;"` because without it in the Firefox 89 and Chrome 91 (June 2021) the `vue-pdf-app` is not rendering on the page, just empty space without any errors (since `vue-pdf-app` does not have height and it is the top tag in the generated markup ) -->
        <!-- or you can just wrap `vue-pdf-app` in <div> tag and set height for it via CSS (like in `Script tag (unpkg)` example below) -->
        <vue-pdf-app style="height: 85vh;" :pdf="oPDF_file"></vue-pdf-app>
      </b-container>
    </div>
  </div>
</template>

<script>
import axios from '@/util/axios';
import { apiDomain } from "@/assets/js/config.js";
import VuePdfApp from "vue-pdf-app";
// import this to use default icons for buttons
import "vue-pdf-app/dist/icons/main.css";

export default {
  components: {
    VuePdfApp
  },
  data() {
    return {
      oPDF_file: null
    }
  },
  created() {
    let id_pdf = this.$route.params.id;
    this.getPDF(id_pdf)
  },
  methods: {
    getPDF(id) {
      axios.post(`doc_repo/token?value=${id}`)
        .then(response => {
          const token = response.data
          this.oPDF_file = `${apiDomain}doc_repo/download/${id}?token=${token}`
        })
        .catch(error => {
          this.errorFunction(error, this.title)
        })
    },
  }
};
</script>