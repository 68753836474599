<template>
  <div>
    <div class="bg-default-dashboard">
      <div class=" py-lg-8 pt-lg-5">
        <b-container fluid style="width: 90%; position: relative;">
          <b-row>
            <b-breadcrumb :items="route_items"></b-breadcrumb>
          </b-row>

          <validation-observer v-slot="{ handleSubmit }">
            <form class="needs-validation" @submit.prevent="handleSubmit(onSubmit)">

              <h1 class="mb-4 text-white">Add New Document</h1>

              <b-form-row>
                <b-col>
                  <div class="text-white h3">{{ setting.category1_name }}</div>
                  <validation-provider :name="setting.category1_name" rules="required" v-slot="{ classes, errors }">
                    <b-form-group :invalid-feedback="errors[0]">
                      <select class="form-control" :class="classes" v-model="model.category1_id">
                        <option v-for="item of categories1" :key="item.id" :value="item.id">{{ item.code }} - {{
                          item.name }}</option>
                      </select>
                    </b-form-group>
                  </validation-provider>
                </b-col>

                <b-col>
                  <div class="text-white h3">{{ setting.category2_name }}</div>
                  <validation-provider :name="setting.category2_name" rules="required" v-slot="{ classes, errors }">
                    <b-form-group :invalid-feedback="errors[0]">
                      <select class="form-control" :class="classes" v-model="model.category2_id">
                        <option v-for="item of categories2" :key="item.id" :value="item.id">{{ item.code }} - {{
                          item.name }}</option>
                      </select>
                    </b-form-group>
                  </validation-provider>
                </b-col>
              </b-form-row>

              <b-form-row v-if="setting.category3_enabled">
                <b-col>
                  <div class="text-white h3">{{ setting.category3_name }}</div>
                  <validation-provider :name="setting.category3_name" rules="required" v-slot="{ classes, errors }">
                    <b-form-group :invalid-feedback="errors[0]">
                      <select class="form-control" :class="classes" v-model="model.category3_id">
                        <option v-for="item of categories3" :key="item.id" :value="item.id">{{ item.code }} - {{
                          item.name }}</option>
                      </select>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col>
                  <div class="text-white h3">Position</div>
                  <validation-provider name="Position" rules="required" v-slot="{ classes, errors }">
                    <b-form-group :invalid-feedback="errors[0]">
                      <select class="form-control" :class="classes" v-model="model.position">
                        <option :value="0">First</option>
                        <option v-for="item in documents" :key="item.id" :value="item.sequence">After: {{ item.title }}
                        </option>
                      </select>
                    </b-form-group>
                  </validation-provider>
                </b-col>
              </b-form-row>

              <b-form-row>
                <b-col>
                  <div class="text-white h3">Title</div>
                  <validation-provider name="Title" rules="required" v-slot="{ classes, errors }">
                    <b-form-group :invalid-feedback="errors[0]">
                      <input type="text" class="form-control" :class="classes" v-model="model.title" maxlength="300">
                    </b-form-group>
                  </validation-provider>
                </b-col>

                <b-col>
                  <div class="text-white h3">Alternative Title</div>
                  <validation-provider name="Alternative Title" v-slot="{ classes, errors }">
                    <b-form-group :invalid-feedback="errors[0]">
                      <input type="text" class="form-control" :class="classes" v-model="model.alt_title"
                        maxlength="300">
                    </b-form-group>
                  </validation-provider>
                </b-col>
              </b-form-row>



              <b-form-row>
                <b-col>
                  <div class="text-white h3">Reviewer/Approver Roles</div>
                  <validation-provider name="Reviewer/Approver Roles" rules="required" v-slot="{ errors }">
                    <b-form-group :invalid-feedback="errors[0]">
                      <b-form-checkbox-group v-model="selectedRoles" :options="roles" stacked
                        :state="errors[0] ? false : null" class="text-left text-white border rounded p-2"
                        style="background-color: #2C545A;" />
                      <b-form-invalid-feedback :state="errors[0] ? false : null">{{ errors[0]
                        }}</b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col></b-col>
              </b-form-row>

              <button class="btn btn-primary" v-if="access.create">Create</button>
              <button class="btn btn-primary" type="button" @click="onCancel()">Cancel</button>
            </form>
          </validation-observer>
        </b-container>
      </div>
    </div>
  </div>
</template>

<script>
import ErrorFunctionMixin from '@/assets/js/errorFunctionMix.js'
import axios from '@/util/axios'
import { getAccessByModule } from '@/util/access'

export default {
  mixins: [ErrorFunctionMixin],

  data() {
    return {
      title: 'Document Tracking',
      pid: null,
      sid: null,
      setting: {},
      model: {},
      categories1: [],
      categories2: [],
      categories3: [],
      selectedCategory1: null,
      selectedCategory2: null,
      selectedCategory3: null,
      documents: [],
      roles: [],
      selectedRoles: [],
      access: {},
    }
  },

  watch: {
    ['model.category1_id']() {
      this.loadDocuments()
    }
  },

  created() {
    this.pid = this.$route.query.proj_id
    this.sid = this.$route.params.id;
    this.access = getAccessByModule('DOC_REPO_REVIEW')

    this.loadSetting()
    this.loadCategories()
    this.loadRoles()
  },

  methods: {
    loadSetting() {
      axios.get(`doc_repo/p/${this.pid}/s/${this.sid}/setting`)
        .then(response => {
          this.setting = response.data.data
        })
        .catch(error => {
          this.errorFunction(error, this.title)
        })
    },
    loadRoles() {
      axios.get('lookup/role_list')
        .then(response => {
          this.roles = response.data.data.map(x => {
            return {
              value: x.id,
              text: x.name,
            }
          })
        })
        .catch(error => {
          this.errorFunction(error, this.title)
        });
    },
    loadCategories() {
      axios.get(`doc_repo/p/${this.pid}/s/${this.sid}/category`)
        .then(response => {
          const categories = response.data.data
          this.categories1 = categories.filter(x => x.level == 1)
          this.categories2 = categories.filter(x => x.level == 2)
          this.categories3 = categories.filter(x => x.level == 3)
        })
        .catch(error => {
          this.errorFunction(error, this.title)
        })
    },

    loadDocuments() {
      const options = {
        params: {
          category1_id: this.model.category1_id,
        },
      }

      axios.get(`doc_repo/p/${this.pid}/s/${this.sid}/document`, options)
        .then(response => {
          this.documents = response.data.data

          if (this.documents.length > 0) {
            const length = this.documents.length
            this.model.position = this.documents[length - 1].sequence
          } else {
            this.model.position = 0;
          }
        })
        .catch(error => {
          this.errorFunction(error, this.title)
        });
    },

    onSubmit() {
      this.model.roles = this.selectedRoles.map(id => {
        return { role_id: id }
      })

      axios.post(`doc_repo/p/${this.pid}/s/${this.sid}/document`, this.model)
        .then(response => {
          this.$notify({
            message: 'New Document Created',
            timeout: 10000,
            icon: 'ni ni-bell-55',
            type: 'default',
          });
          this.$router.replace(`/ReviewerDocumentDetail/${this.sid}`)
        })
        .catch(error => {
          this.errorFunction(error, this.title)
        });
    },

    onCancel(){
      this.$router.replace(`/ReviewerDocumentDetail/${this.sid}`);
    }
  },
}
</script>
<style scoped>
/* Class For Modal */
.breadcrumb {
  background-color: transparent;
}

.custom-control-label-tmp {
  margin-bottom: 0;
}

.custom-control-label-tmp {
  position: relative;
  margin-bottom: 0;
  vertical-align: top;
}

.modal-content {
  background-color: #306b74;

}

label {
  display: inline-block;
  margin-bottom: 0.5rem;
}

table thead th {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  font-size: 0.65rem;
  text-transform: uppercase;
  letter-spacing: 1px;
  background-color: #23454A;
  color: white;
  padding: 20px;
  border-bottom: 0px solid #082A2F;
  border-top: 0px solid #e9ecef;
}

.th-table-new {
  padding-top: 35px;
  padding-bottom: 35px;
  padding-left: 20px;
}

.tr-td-table-new {
  padding-top: 30px;
  padding-bottom: 30px;
  padding-left: 20px;
}

.form-control {
  background-color: #2C545A;
  border-color: #2C545A;
  color: white;

  &:focus {
    background-color: #2C545A;
    border-color: #2C545A;
    color: white;
  }

  &::placeholder {
    color: white;
  }
}

.form-control {
  &:focus {
    &::placeholder {
      color: white;
    }
  }
}

.input-group-text {
  background-color: #2C545A;
  ;
  color: white;

  &:focus {
    background-color: #2C545A;
    ;
    border-color: #1D4147;
    color: white;
  }
}

.focused {
  .input-group-text {
    background-color: #2C545A;
  }
}

.navbar-search-dark .input-group {
  background-color: #23454A;
  color: black;
  border-color: rgba(255, 255, 255, 0.6);
}

.navbar-search .input-group {
  border-radius: 7px;
}

.el-input__inner,
.el-select-dropdown__item.is-disabled:hover {
  background-color: #23454A;
}
</style>