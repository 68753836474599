<template>
  <card>
    <h3 slot="header" class="mb-0">New Document Template</h3>
    <div class="text-right">
    </div>
    <form class="needs-validation"
          @submit.prevent="submit">

      <div v-if="milestone_template.length == 0">
        <h5 class="text-danger"> !!! Warning :
            <i>
            Please Create New Milestone at Menu >> Template >>
            <router-link to="/milestoneTemplateListing">
            Milestone Template
            </router-link>
            with at least ONE Milestone Details before you can proceed to CREATE Document Template
            </i>
        </h5>
      </div>

      <div class="form-row">
        <b-col md="12">
          <base-input label="Template Name"
                      name="Template Name"
                      placeholder="Template Name"
                      v-model="model.document_template_name">
          </base-input>
        </b-col>

        <b-col md="11">
          <base-input label="Set Milestone Template (Once 1st Document is created the milestone cannot be switch/change anymore)."
                      name="Milestone Template"
                      v-model="model.milestone_template_id">
            <select class="form-control" v-model="model.milestone_template_id">
              <option Selected disabled value="x">-- Please Select A Milestone Template --</option>
              <option v-for="item in milestone_template" :key="item.milestone_template_id" v-bind:value="item.milestone_template_id">{{ item.milestone_template_name }}</option>
            </select>
          </base-input>

        </b-col>

        <b-col md="1">
          <br>
          <base-button size="sm" type="primary" @click="checkMilestoneDetail()">Milestone Details</base-button>
        </b-col>
      </div>

      <base-button type="primary" @click="createData()">Create / Save</base-button>
      <base-button type="primary" @click="$router.back()">Cancel</base-button>
      <div>
        <br>
        <h5 class="text-danger"> Note : <br>
                <i>
                1. To Create New / Update Milestone please proceed to Menu >> Template >> Milestone Template OR "Click" on "Milestone Details"
                <br>
                2. Your cannot swtich / change the Milestone Template anymore once you created your 1st Document Template Detail / Classification. But you can still Create/Update the Milestone Template.
                <br>
                3. All Milestone Template Details will follow according to SELECTED Milestone Template.
                <br>
                4. SET Milestone Template DROPBOX will be disabled once a Document Template Detail / Classification is Created, To check the detail MENU >> Template >> Document Template >> Detail Listing.
                </i>
        </h5>
      </div>
    </form>
  </card>
</template>
<script>
  import BaseSlider from '@/components/BaseSlider'
  import {LoginURL, clientId, clientSecret, apiDomain, getHeader} from '@/assets/js/config.js'
  import axios from 'axios'
  import ErrorFunctionMixin from '@/assets/js/errorFunctionMix.js'

  export default {
    mixins: [ErrorFunctionMixin],
    components: {
      BaseSlider
    },
    created()
    {
      axios.get(apiDomain + 'template/milestone_template', { headers: getHeader() })
      .then ( response => {
        if (response.status === 200)
        {
          this.milestone_template = response.data.data
        }
      })
      .catch ( error => {
        console.log(error.response.status)
        this.errorFunction(error, "Milestone Template Information")
      } );
    },
    data() {
      return {
        model: {
          document_template_name: null,
          milestone_template_id: 'x',
        },
        milestone_template: [],
      }
    },
    methods: {
      checkMilestoneDetail()
      {
        if (this.model.milestone_template_id == 'x')
        {
          this.$notify({
            message:
              '<b>Invalid Input : Milestone Template </b> - Please select a Milestone Template before dive into the Details',
            timeout: 10000,
            icon: 'ni ni-bulb-61',
            type: 'danger',
          });
        }
        else
        {
          this.$router.push({path: '/templates/milestoneTemplateDetailListing/'+this.model.milestone_template_id })
        }
      },
      createData(){
        if ( !this.model.document_template_name || this.model.milestone_template_id == 'x')
        {
          this.$notify({
            message:
              '<b>Invalid Input : All Column are Mandatory in Document Template</b> - These field are required.',
            timeout: 10000,
            icon: 'ni ni-bulb-61',
            type: 'danger',
          });
        }
        else
        {
          axios.post(apiDomain + 'template/document_template', this.model, { headers: getHeader() })
          .then ( response => {
            if (response.status === 200)
            {
                this.$notify({
                message:
                  '<b>New Document Template : ' + this.model.document_template_name + '</b> - Successfully Created.',
                timeout: 10000,
                icon: 'ni ni-bell-55',
                type: 'default',
                });
                this.$router.back()
            }
          })
          .catch ( error => {
            console.log(error.response.data.errors)
            this.errorFunction(error, "Create New Document Template", )
          });
        }
      },
    }
  }
</script>
<style>
</style>
