<template>
  <span v-if="!isEmpty" :class="textClass">
    <i v-if="isNewRevision" class="ni ni-fat-add"></i>
    <i v-else-if="isPendingReview" class="ni ni-single-02"></i>
    <i v-else-if="isNewReview" class="ni ni-curved-next"></i>
    <i v-else-if="isAccepted" class="ni ni-like-2"></i>
    <i v-else-if="isRejected" class="ni ni-fat-remove"></i>
    <i v-else-if="isApproved" class="ni ni-check-bold"></i>
  </span>
</template>

<script>
export default {
  props: ['status'],

  computed: {
    isEmpty() {
      return this.status == 'EMPTY'
    },

    isNewRevision() {
      return this.status == 'NEW_REVISION'
    },

    isPendingReview() {
      return this.status == 'PENDING_REVIEW'
    },

    isNewReview() {
      return this.status == 'NEW_REVIEW'
    },

    isAccepted() {
      return this.status == 'ACCEPTED'
    },

    isRejected() {
      return this.status == 'REJECTED'
    },

    isApproved() {
      return this.status == 'APPROVED'
    },

    textClass() {
      if (this.isNewRevision || this.isNewReview)
        return 'text-primary'

      if (this.isPendingReview)
        return 'text-info'

      if (this.isRejected)
        return 'text-warning'

      if (this.isAccepted || this.isApproved)
        return 'text-success'

      return ''
    },
  },
}
</script>
