<template>
  <div class="content">
    <base-header class="pb-6">
      <b-row align-v="center" class="py-4">
        <b-col cols="7" lg="6">
          <h6 class="h2 text-white d-inline-block mb-0">My Open Safety Observations</h6>
          <!-- <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <route-bread-crumb></route-bread-crumb>
          </nav> -->
        </b-col>
      </b-row>
    </base-header>
    <b-container fluid class="mt--6">
      <div>
        <card class="no-border-card" body-classes="px-0 pb-1" footer-classes="pb-2">
          <!-- <template slot="header">
            <h3 class="mb-0">My Open Safety Observations</h3>
          </template> -->
          <div>
            <b-col cols="12" class="d-flex justify-content-center justify-content-sm-between flex-wrap">
              <el-select
                class="select-primary pagination-select"
                v-model="pagination.perPage"
                placeholder="Per page">
                <el-option
                  class="select-primary"
                  v-for="item in pagination.perPageOptions"
                  :key="item"
                  :label="item"
                  :value="item">
                </el-option>
              </el-select>

              <div>
                <base-input v-model="searchQuery"
                            prepend-icon="fas fa-search"
                            placeholder="Search...">
                </base-input>
              </div>
            </b-col>

            <el-table :data="queriedData"
                      @row-click="rowClicked"
                      row-key="safety_observation_item_id"
                      header-row-class-name="thead-light"
                      @sort-change="sortChange">

              <el-table-column label="EPC"
                             min-width="170px"
                             prop="contractor.contractor_code"
                             sortable>
                <template v-slot="{row}">
                    <b-media no-body class="align-items-center">
                        <b-media-body>
                            <span class="font-weight-600 name mb-0 text-sm">{{row.contractor.contractor_code}}</span>
                        </b-media-body>
                    </b-media>
                </template>
              </el-table-column>

              <el-table-column label="SITE NAME"
                             min-width="170px"
                             prop="project_site.site_name"
                             sortable>
                <template v-slot="{row}">
                    <b-media no-body class="align-items-center">
                        <b-media-body>
                            <span class="font-weight-600 name mb-0 text-sm">{{row.project_site.site_name}}</span>
                        </b-media-body>
                    </b-media>
                </template>
              </el-table-column>

              <el-table-column label="INSPECTION DATE"
                             min-width="170px"
                             prop="safety_observation_create_date_web"
                             sortable
                             :sort-method="sortDateInspectionDate">
                <template v-slot="{row}">
                    <b-media no-body class="align-items-center">
                        <b-media-body>
                            <span class="font-weight-600 name mb-0 text-sm">{{row.safety_observation_create_date_web}}</span>
                        </b-media-body>
                    </b-media>
                </template>
              </el-table-column>

              <el-table-column label="TYPE"
                             min-width="170px"
                             prop="safety_observation_type.safety_observation_type_code"
                             sortable="">
                <template v-slot="{row}">
                    <b-media no-body class="align-items-center">
                        <b-media-body>
                            <span class="font-weight-600 name mb-0 text-sm">{{row.safety_observation_type.safety_observation_type_code}}</span>
                        </b-media-body>
                    </b-media>
                </template>
              </el-table-column>

              <el-table-column label="CATEGORY"
                             min-width="170px"
                             prop="safety_observation_category.safety_observation_category"
                             sortable="">
                <template v-slot="{row}">
                    <b-media no-body class="align-items-center">
                        <b-media-body>
                            <span class="font-weight-600 name mb-0 text-sm">{{row.safety_observation_category.safety_observation_category}}</span>
                        </b-media-body>
                    </b-media>
                </template>
              </el-table-column>

              <el-table-column label="STATUS"
                             min-width="170px"
                             prop="safety_observation_status.status_code"
                             sortable="">
                <template v-slot="{row}">
                    <b-media no-body class="align-items-center">
                        <b-media-body>
                            <span class="font-weight-600 name mb-0 text-sm">{{row.safety_observation_status.status_code}}</span>
                        </b-media-body>
                    </b-media>
                </template>
              </el-table-column>

              <el-table-column label="TARGET DATE"
                             min-width="170px"
                             prop="target_date"
                             sortable
                             :sort-method="sortDateTargetDate">
                <template v-slot="{row}">
                    <b-media no-body class="align-items-center">
                        <b-media-body>
                            <span class="font-weight-600 name mb-0 text-sm">{{row.target_date}}</span>
                        </b-media-body>
                    </b-media>
                </template>
              </el-table-column>

              <el-table-column min-width="180px" align="right" label="Actions">
                <div slot-scope="{$index, row}" class="d-flex">
                  <base-button
                    @click.native="handleDetails($index, row)" size="sm" type="primary">
                    View/Edit
                  </base-button>
                </div>
              </el-table-column>
            </el-table>
          </div>

          <div
            slot="footer"
            class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap">
            <div class="">
              <p class="card-category">
                Showing {{ from + 1 }} to {{ to }} of {{ total }} entries

                <span v-if="selectedRows.length">
                  &nbsp; &nbsp; {{selectedRows.length}} rows selected
                </span>
              </p>
            </div>
            <base-pagination
              class="pagination-no-border"
              :current="pagination.currentPage"
              :per-page="pagination.perPage"
              :total="total"
              @change="paginationChanged($event)">
            </base-pagination>
          </div>
        </card>
      </div>
    </b-container>
    </div>
</template>
<script>
import { Table, TableColumn, Select, Option } from 'element-ui';
import RouteBreadCrumb from '@/components/Breadcrumb/RouteBreadcrumb'
import { BasePagination } from '@/components';
import standardPaginationMix from '@/assets/js/standardPaginationMix.js'
import Fuse from 'fuse.js';
import axios from 'axios'
import {LoginURL, clientId, clientSecret, apiDomain, getHeader} from '@/assets/js/config.js'
import ErrorFunctionMixin from '@/assets/js/errorFunctionMix.js'

export default {
  mixins: [standardPaginationMix, ErrorFunctionMixin],
  components: {
    BasePagination,
    RouteBreadCrumb,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn
  },
  created()
  {
    const LoginUser = JSON.parse(window.localStorage.getItem('loginUser'))
    this.myUserID = LoginUser
    var userInfo = JSON.parse(window.localStorage.getItem("loginUser"));
    this.user_role = userInfo.role_id;
    this.user_id = userInfo.id;
    if (this.myUserID.role_id == 12) {
      this.getContractorId();
    } else {
      this.getSafetyObservationItemListing();
    }

    
  },
  data() {
    return {
      user_role: null,
      user_id: null,
      contractor_id: null,
      propsToSearch:
      [
        'contractor.contractor_code',
        'project_site.site_name',
      ],
      tableColumns: [],
      tableData: [],
      selectedRows: [],
      myUserID: null,
      contractor_id: null,
    };
  },
  methods: {
    getSafetyObservationItemListing()
    {
      axios.get(apiDomain + 'health_safety/safety_observation_item.open_pending/' + this.myUserID.id, { headers: getHeader() })
      .then ( response => {
      if (response.status === 200)
      {
          this.tableData = response.data.data
          this.fuseData()
      }
      })
      .catch ( error => {
      console.log(error.response.status)
      this.errorFunction(error, "Project Information")
      });
    },
    getSafetyObservationItemListingContractor()
    {
      axios.get(apiDomain + 'health_safety/safety_observation_item.open_pending.show_by_contractor/' + this.contractor_id, { headers: getHeader() })
      .then ( response => {
      if (response.status === 200)
      {
          this.tableData = response.data.data
          this.fuseData()
      }
      })
      .catch ( error => {
      console.log(error.response.status)
      this.errorFunction(error, "Project Information")
      });
    },
    fuseData()
    {
      this.fuseSearch = new Fuse(this.tableData, {
      keys: this.propsToSearch,
      threshold: 0.3
      });
    },
    paginationChanged(page)
    {
        this.pagination.currentPage = page
    },
    rowClicked(row) {
      this.$router.push({path: 'safetyObservationItemCorrection/'+row.safety_observation_item_id });
    },
    handleDetails(index, row)
    {
      this.$router.push({path: 'safetyObservationItemCorrection/'+row.safety_observation_item_id });        
    },
    selectionChange(selectedRows)
    {
        this.selectedRows = selectedRows
    },
    sortDateInspectionDate(a, b) {
      const months = {
        Jan: 0,
        Feb: 1,
        Mar: 2,
        Apr: 3,
        May: 4,
        Jun: 5,
        Jul: 6,
        Aug: 7,
        Sep: 8,
        Oct: 9,
        Nov: 10,
        Dec: 11,
      };
      

      const getDateValue = (dateStr) => {
        const [day, month, year] = dateStr.split('-');
        const monthValue = months[month];
        return new Date(year, monthValue, day);
      };

      const dateA = getDateValue(a && a.safety_observation_create_date_web);
      const dateB = getDateValue(b && b.safety_observation_create_date_web);

      if (dateA < dateB) {
        return -1;
      } else if (dateA > dateB) {
        return 1;
      }

      return 0;
    },
    sortDateTargetDate(a, b) {
      const months = {
        Jan: 0,
        Feb: 1,
        Mar: 2,
        Apr: 3,
        May: 4,
        Jun: 5,
        Jul: 6,
        Aug: 7,
        Sep: 8,
        Oct: 9,
        Nov: 10,
        Dec: 11,
      };
      

      const getDateValue = (dateStr) => {
        const [day, month, year] = dateStr.split('-');
        const monthValue = months[month];
        return new Date(year, monthValue, day);
      };

      const dateA = getDateValue(a && a.target_date);
      const dateB = getDateValue(b && b.target_date);

      if (dateA < dateB) {
        return -1;
      } else if (dateA > dateB) {
        return 1;
      }

      return 0;
    },
    getContractorId(){
      axios.get(apiDomain + 'lookup/contractor.contractor_id/' + this.user_id, { headers: getHeader() })
        .then ( response => {
        if (response.status === 200)
        {
          this.contractor_id = response.data.contractor_id;
          this.getSafetyObservationItemListingContractor();
        }
        })
        .catch ( error => {
          this.errorFunction(error, 'Contractor Id')
        });
    },
  }
};
</script>
<style>
  .no-border-card .card-footer{
    border-top: 0;
  }
</style>