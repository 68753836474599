<template>
  <div class="bg-default-dashboard">
    <div class=" py-lg-8 pt-lg-5">
      <b-container fluid style="width: 90%; position: relative;">

        <!-- <div class="header-body "  > -->
        <b-row class="mb-2">
          <b-col>
            <h1 class="text-white">Safety Inspection </h1>
          </b-col>
        </b-row>

        <b-row class="mt-4">
          <!-- project info -->
          <b-col md="7">
            <div class="row">
              <!-- project site -->
              <b-col md="4">
                <base-input label="Project Site" name="Project Site" v-model="model.site_id">
                  <!-- <select class="form-control" v-model="model.site_id" @change="getContractorFromSite()" disabled> -->
                  <select class="form-control" v-model="model.site_id" disabled>
                    <option Selected disabled value="x">
                      -- Please Select A Project Group To Show Project Site --
                    </option>
                    <option Selected disabled value="y">
                      -- Please Select A Project Site --
                    </option>
                    <option v-for="item in project_sites" :key="item.site_id" v-bind:value="item.site_id">
                      {{ item.site_name }}
                    </option>
                  </select>
                </base-input>
              </b-col>
              <!-- project status -->
              <b-col md="4">
                <base-input label="Project Status" name="Project Status" v-model="model.project_status">
                  <select class="form-control" v-model="model.project_status" disabled>
                    <option selected disabled :value="null">
                      -- Please Select A Project Status --
                    </option>
                    <option value="ongoing">On Going</option>
                    <option value="completed">Completed</option>
                  </select>
                </base-input>
              </b-col>
              <!-- epc -->
              <b-col md="4">
                <base-input label="EPC" name="EPC" v-model="model.contractor_id">
                  <select class="form-control" v-model="model.contractor_id" disabled>
                    <option Selected disabled value="x">
                      -- Please Select A Project Site To Show EPC --
                    </option>
                    <option v-for="item in site_contractors" :key="item.site_contractor_id"
                      v-bind:value="item.contractor.contractor_id">
                      {{ item.contractor.contractor_code }} -
                      {{ item.contractor.contractor_name }}
                    </option>
                  </select>
                </base-input>
              </b-col>
              <!-- date -->
              <b-col md="4">
                <base-input label="Date" name="Date" type="date" v-model="model.safety_observation_create_date"
                  disabled>
                </base-input>
              </b-col>
              <!-- time -->
              <b-col md="4">
                <base-input label="Time" name="Time" type="time" v-model="model.safety_observation_create_time"
                  disabled>
                </base-input>
              </b-col>
              <!-- hour -->
              <b-col md="4">
                <base-input label="Hour(s)" name="Hour(s)" placeholder="1" v-model="model.time_spent_hours"
                  type="number" min="1" max="50" disabled>
                </base-input>
              </b-col>
            </div>
          </b-col>

          <!-- hsse officer -->
          <b-col md="5">
            <div class="row">
              <b-col md="12">
                <b-row>
                  <b-col>
                    <div class="text-white">
                      <h5>Conducted By</h5>
                    </div>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <b-list-group>
                      <b-list-group-item class="bg-dark text-muted">
                        <div v-for="(input, index) in conducting_users" :key="`reviewerInput-${index}`">
                          <i class="icon-person-cs mr-1"></i> {{ input.desc }}
                        </div>
                      </b-list-group-item>
                    </b-list-group>
                  </b-col>
                </b-row>
              </b-col>
            </div>
          </b-col>

          <!-- inspection list -->
          <b-col md="12">
            <div class="row" style="margin-top: 30px;">
              <dataset style="width: 100%" v-slot="{ ds }" :ds-data="obj_data_grid.data"
                :ds-sortby="['observation_type', 'observation_category', 'observation_status', 'target_date',]"
                :ds-search-in="['observation_type', 'observation_category', 'observation_status', 'target_date',]">
                <div class="row" style="padding-bottom: 15px" :data-page-count="ds.dsPagecount">
                  <!-- data grid - data per-page -->
                  <div class="col-md-2"
                    style="color: #ffffff; font-family: Open Sans, sans-serif !important; font-size: 0.875rem; ">
                    <dataset-show class="dataset-style" />
                  </div>

                  <div class="col-md-5">
                    &nbsp;
                  </div>

                  <!-- data grid - search-->
                  <div class="col-md-5">
                    <dataset-search ds-search-placeholder="Search..." />
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <div class="table-responsive">
                      <table style="width: 100%" class="table table-striped d-md-table">
                        <thead
                          style="background-color: #3a656b; color: white; font-size: small; border-bottom: 20px solid #082A2F;">
                          <tr>
                            <th v-for="(th, index) in obj_data_grid.cols" :key="th.field"
                              :class="['th-table-new', th.sort]">
                              {{ th.name }}
                              <i class="gg-select float-right"></i>
                            </th>
                          </tr>
                        </thead>
                        <dataset-item tag="tbody">
                          <template #default="{ row, rowIndex }">
                            <tr
                              style="background-color: #23454A; color: white; font-size: smaller; border-bottom:  20px solid #082A2F;">
                              <!-- <th scope="row">{{ rowIndex + 1 }}</th> -->
                              <td class="tr-td-table-new" style="min-width: 150px">
                                {{ row.observation_type }}
                              </td>
                              <td class="tr-td-table-new" style="min-width: 150px">
                                {{ row.observation_category }}
                              </td>
                              <td class="tr-td-table-new" style="min-width: 150px">
                                {{ row.observation_status }}
                              </td>
                              <td class="tr-td-table-new" style="min-width: 150px">
                                {{ row.observation_priority }}
                              </td>
                              <td class="tr-td-table-new" style="min-width: 150px">
                                {{ row.target_date }}
                              </td>
                              <td class="tr-td-table-new" style="min-width: 150px">
                                <b-link @click="viewObservationItem(row)"
                                  class="btn btn-sm btn-secondary rounded-pill text-white" target="_blank">
                                  <div class="icon-pencil-cs ml-1 mr-1"></div>
                                </b-link>
                                <b-link v-if="user_role == 3" @click="deleteDetails(rowIndex, row)" style="
                                    color: rgb(255, 92, 0);
                                    font-size: large;
                                  " class="btn btn-sm btn-secondary rounded-pill" target="_blank">
                                  <div class="icon-trash-bin-cs"></div>
                                </b-link>
                              </td>
                            </tr>
                          </template>
                        </dataset-item>
                      </table>
                    </div>
                  </div>
                </div>
                <div class="d-flex flex-md-row flex-column justify-content-between align-items-center"
                  style=" color: #ffffff; font-family: Open Sans, sans-serif !important; font-size: 0.875rem; ">
                  <dataset-info class="mb-2 mb-md-0" />
                  <dataset-pager />
                </div>
              </dataset>
            </div>
          </b-col>
        </b-row>

        <!-- modal observation item -->
        <b-modal ref="modal-observation-item" size="xl" centered hide-footer hide-header title="">
          <b-row>
            <b-col>
              <div class="text-white h3">Add Correction</div>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <base-input label="">
                <textarea class="form-control" placeholder="Observations" id="exampleFormControlTextarea3"
                  rows="3"></textarea>
              </base-input>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <base-input>
                <select class="form-control">
                  <option>Correction By</option>
                </select>
              </base-input>
            </b-col>
            <b-col>
              <b-form-datepicker placeholder="Correction Date" v-model="value"
                class="text-white mb-2"></b-form-datepicker>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <base-input>
                <select class="form-control">
                  <option>Approved By</option>
                </select>
              </base-input>
            </b-col>
            <b-col>
              <b-form-datepicker placeholder="Approved Date" v-model="value"
                class="text-white mb-2"></b-form-datepicker>

            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <base-input label="">
                <textarea class="form-control" placeholder="Corrective Action & Preventive Action" rows="3"></textarea>
              </base-input>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <dropzone-file-upload v-model="inputs.fileMultiple" multiple></dropzone-file-upload>

            </b-col>
          </b-row>
          <b-row>
            <b-col class="text-right">
              <base-button @click="action_on_modal('hide', 'modal-observation-item')" type="danger" outline>Cancel</base-button>
              <base-button type="primary">Save</base-button>

            </b-col>
          </b-row>
        </b-modal>

      </b-container>

    </div>
  </div>
</template>


<script>
import { LoginURL, clientId, clientSecret, apiDomain, getHeader, getcurrentDate } from "@/assets/js/config.js";
import axios from "axios";
import jsonFormData from "json-form-data";
import Dataset from '@/assets/js/customDataset.js';
import { DatasetItem, DatasetInfo, DatasetPager, DatasetSearch, DatasetShow } from "vue-dataset";
import DropzoneFileUpload from '@/components/Inputs/DropzoneFileUploadObs'
import { CatCarousel } from 'vue-cat-carousel'

export default {
  components: {
    Dataset,
    DatasetItem,
    DatasetInfo,
    DatasetPager,
    DatasetSearch,
    DatasetShow,
    DropzoneFileUpload,
    CatCarousel
  },
  created() {
    var userInfo = JSON.parse(window.localStorage.getItem("loginUser"));
    this.user_role = userInfo.role_id;
    this.user_id = userInfo.id;
    if (this.user_role == 12) {
      this.getContractorId();
    }
    this.safety_observation_id = this.$route.params.id;
    // this.getProjectListing();
    this.getUserList();
    this.getSafetyObservationTypes();
    this.getSafetyObservationCategories();
    this.getSafetyObservationPriorities();
    this.getSafetyObservationStatusNames();
    this.getSafetyObservationListing();
  },
  data() {
    return {
      user_role: null,
      user_id: null,
      contractor_id: null,
      safetyObservationResponse: null,
      model: {
        safety_observation_id: null,
        project_id: "x",
        group_id: "x",
        site_id: "x",
        contractor_id: "x",
        project_status: null,
        safety_observation_create_date: null,
        safety_observation_create_time: null,
        time_spent_hours: 1,
        safety_observation_items: [],
        status: null,
      },
      new_model: {
        observation_description: null,
        safety_observation_type_id: "x",
        safety_observation_category_id: "x",
        correction_photos_required: "x",
        safety_observation_priority_id: "x",
        safety_observation_photos: [],
        safety_observation_status_id: 1,
      },
      projects: [],
      project_groups: [],
      project_sites: [],
      site_contractors: [],
      users: [],
      conducting_users: [],
      safety_observation_type: [],
      safety_observation_category: [],
      safety_observation_priority: [],
      safety_observation_status: [],
      isNewObservationItemVisible: false,
      safety_observation_item_mode: "create",
      current_item_index: null,
      obj_data_grid: {
        data: [],
        cols: [
          {
            name: "Type",
            field: "observation_type",
          },
          {
            name: "Category",
            field: "observation_category",
          },
          {
            name: "Status",
            field: "observation_status",
          },
          {
            name: "Priority",
            field: "observation_priority",
          },
          {
            name: "Target Date",
            field: "target_date",
          },
          {},
          // {
          //   name: 'Actions',
          //   field: '',
          // },
        ],
      },
      inputs: {
        fileMultiple: []
      },

      /* check and delete. if not use anymore */
      fields: [
        "no.",
        // Type
        {
          key: "safety_observation_type_id",
          label: "Type",
          formatter: (value, key, item) => {
            var findObj = this.safety_observation_type.find((obj) => {
              return obj.safety_observation_type_id === value;
            });
            if (typeof findObj !== "undefined") {
              return findObj.safety_observation_type_code;
            } else {
              return value;
            }
          },
          thClass: "th-table-new",
          tdClass: "tr-td-table-new",
        },
        // Category
        {
          key: "safety_observation_category_id",
          label: "Category",
          formatter: (value, key, item) => {
            var findObj = this.safety_observation_category.find((obj) => {
              return obj.safety_observation_category_id == value;
            });
            if (typeof findObj !== "undefined") {
              return findObj.safety_observation_category;
            } else {
              return value;
            }
          },
          thClass: "th-table-new",
          tdClass: "tr-td-table-new",
        },
        // Status
        {
          key: "safety_observation_status_id",
          label: "Status",
          formatter: (value, key, item) => {
            var findObj = this.safety_observation_status.find((obj) => {
              return obj.status_id === value;
            });
            if (typeof findObj !== "undefined") {
              return findObj.status_code;
            } else {
              return value;
            }
          },
          tdClass: "tr-td-table-new",
        },
        // Priority
        {
          key: "safety_observation_priority_id",
          label: "Priority",
          formatter: (value, key, item) => {
            var findObj = this.safety_observation_priority.find((obj) => {
              return obj.safety_observation_priority_id === value;
            });
            if (typeof findObj !== "undefined") {
              return findObj.safety_observation_priority_name;
            } else {
              return value;
            }
          },
          tdClass: "tr-td-table-new",
        },
        // Target Date
        {
          key: "target_date",
          label: "Target Date",
          formatter: (value, key, item) => {
            if (value == "") {
              return "N/A";
            } else {
              return value;
            }
          },
          tdClass: "tr-td-table-new",
        },
        // Actions
        { key: "actions", label: "Actions" },
      ],
    };
  },
  methods: {
    rowClass(item, type) {
      return "grid-tr-new";
    },
    getProjectListing() {
      if (this.user_role == 12) {
        this.getProjectListingContractor();
      } else {
        this.getProjectListingNormal();
      }
    },
    getProjectListingNormal() {
      axios
        .get(apiDomain + "project/project", { headers: getHeader() })
        .then((response) => {
          if (response.status === 200) {
            this.projects = response.data.data;
          }
        })
        .catch((error) => {
          console.log(error.response.status);
          this.errorFunction(error, "Project Listing");
        });
    },
    getProjectListingContractor() {
      axios
        .get(
          apiDomain +
          "project/project.show_by_contractor/" +
          this.contractor_id,
          { headers: getHeader() }
        )
        .then((response) => {
          if (response.status === 200) {
            this.projects = response.data.data;
          }
        })
        .catch((error) => {
          console.log(error.response.status);
          this.errorFunction(error, "Project Listing Contractor");
        });
    },
    getGroupFromProject() {
      if (this.user_role == 12) {
        this.getGroupFromProjectContractor();
      } else {
        this.getGroupFromProjectNormal();
      }
    },
    getGroupFromProjectNormal() {
      axios
        .get(
          apiDomain +
          "project/project_group.show_by_project/" +
          this.model.project_id,
          { headers: getHeader() }
        )
        .then((response) => {
          if (response.status === 200) {
            this.project_groups = response.data.data;
            // this.model.group_id = 'y';
            // this.model.site_id = 'x';
            // this.project_sites = [];
          }
        })
        .catch((error) => {
          console.log(error.response.status);
        });
    },
    getGroupFromProjectContractor() {
      axios
        .get(
          apiDomain +
          "project/project_group.show_by_project_by_contractor/" +
          this.model.project_id +
          "/" +
          this.contractor_id,
          { headers: getHeader() }
        )
        .then((response) => {
          if (response.status === 200) {
            this.project_groups = response.data.data;
            // this.model.group_id = 'y';
            // this.model.site_id = 'x';
            // this.project_sites = [];
          }
        })
        .catch((error) => {
          console.log(error.response.status);
        });
    },
    getSiteFromGroup() {
      if (this.user_role == 12) {
        this.getSiteFromGroupContractor();
      } else {
        this.getSiteFromGroupNormal();
      }
    },
    getSiteFromGroupNormal() {
      axios
        .get(
          apiDomain +
          "project/project_site.show_by_group/" +
          this.model.group_id,
          { headers: getHeader() }
        )
        .then((response) => {
          if (response.status === 200) {
            this.project_sites = response.data.data;
            // this.model.site_id = 'y';
          }
        })
        .catch((error) => {
          console.log(error.response.status);
        });
    },
    getSiteFromGroupContractor() {
      axios
        .get(
          apiDomain +
          "project/project_site.show_by_group_by_contractor/" +
          this.model.group_id +
          "/" +
          this.contractor_id,
          { headers: getHeader() }
        )
        .then((response) => {
          if (response.status === 200) {
            this.project_sites = response.data.data;
            // this.model.site_id = 'y';
          }
        })
        .catch((error) => {
          console.log(error.response.status);
        });
    },
    getContractorFromSite() {
      axios
        .get(
          apiDomain +
          "project/site_contractor.show_by_site/" +
          this.model.site_id,
          { headers: getHeader() }
        )
        .then((response) => {
          if (response.status === 200) {
            this.site_contractors = response.data.data;
            var index = this.site_contractors.findIndex(
              (x) => x.active_status === "ACTIVE"
            );
            this.model.contractor_id =
              this.site_contractors[index].contractor.contractor_id;
          }
        })
        .catch((error) => {
          console.log(error.response.status);
          this.errorFunction(error, "Site Contractor Information");
        });
    },
    getUserList() {
      // if (this.user_role == 12) {
      //   this.getUserListContractor();
      // } else {
      this.getUserListNormal();
      // }
    },
    getUserListNormal() {
      axios
        .get(apiDomain + "lookup/user_list", { headers: getHeader() })
        .then((response) => {
          if (response.status === 200) {
            this.users = response.data.data;
            this.addUserToConducting();
          }
        })
        .catch((error) => {
          this.errorFunction(error, "User Listing");
        });
    },
    getUserListContractor() {
      axios
        .get(apiDomain + "lookup/user_list.contractor/" + this.contractor_id, {
          headers: getHeader(),
        })
        .then((response) => {
          if (response.status === 200) {
            this.users = response.data.data;
            this.addUserToConducting();
          }
        })
        .catch((error) => {
          this.errorFunction(error, "User Listing");
        });
    },
    getSafetyObservationTypes() {
      axios
        .get(apiDomain + "lookup/safety_observation_type", {
          headers: getHeader(),
        })
        .then((response) => {
          if (response.status === 200) {
            this.safety_observation_type = response.data.data;
          }
        })
        .catch((error) => {
          console.log(error.response.status);
          this.errorFunction(error, "Safety Observation Type Information");
        });
    },
    getSafetyObservationCategories() {
      axios
        .get(apiDomain + "setup/safety_observation_category_active", {
          headers: getHeader(),
        })
        .then((response) => {
          if (response.status === 200) {
            this.safety_observation_category = response.data.data;
          }
        })
        .catch((error) => {
          console.log(error.response.status);
          this.errorFunction(error, "Safety Observation Category");
        });
    },
    getSafetyObservationPriorities() {
      axios
        .get(apiDomain + "setup/safety_observation_priority_active", {
          headers: getHeader(),
        })
        .then((response) => {
          if (response.status === 200) {
            this.safety_observation_priority = response.data.data;
          }
        })
        .catch((error) => {
          console.log(error.response.status);
          this.errorFunction(error, "Safety Observation Priority");
        });
    },
    getSafetyObservationStatusNames() {
      axios
        .get(apiDomain + "lookup/safety_observation_status", {
          headers: getHeader(),
        })
        .then((response) => {
          if (response.status === 200) {
            this.safety_observation_status = response.data.data;
          }
        })
        .catch((error) => {
          console.log(error.response.status);
          this.errorFunction(error, "Safety Observation Status");
        });
    },
    populateConductingUsers(conducting_users_array) {
      // safety_observation_conducting_user SORT THIS ARRAY BY safety_observation_conducting_user_id
      // console.log('populateConductingUsers');

      this.conducting_users = [];
      conducting_users_array.forEach((item) => {
        // console.log(item);

        this.conducting_users.push({
          userId: item.safety_observation_conducting_user.id,
          desc: item.safety_observation_conducting_user.name + ' - ' + item.safety_observation_conducting_user.email
        });
        this.users.map((e_rol) => {
          if (e_rol.id == item.safety_observation_conducting_user.id) {
            e_rol["disabled"] = true;
            e_rol["v_hide"] = true;
          }
        });
      });

      // console.log(this.conducting_users);
    },
    removeFromList(event) {
      this.conducting_users.map((e_rev) => {
        if (e_rev.userId == event.target.value) {
          if (e_rev.previousSelection != "") {
            this.users.map((e_rol) => {
              if (e_rol.id == e_rev.previousSelection) {
                e_rol["disabled"] = false;
                e_rol["v_hide"] = false;
              }
            });
          }
        }
        this.users.map((e_rol) => {
          if (e_rol.id == event.target.value) {
            e_rol["disabled"] = true;
            e_rol["v_hide"] = true;
          }
        });
        e_rev.previousSelection = e_rev.userId;
      });
    },
    addField(object, fieldType) {
      let newObject = {};
      for (const [key, value] of Object.entries(object)) {
        newObject[key] = "";
      }
      fieldType.push(newObject);
    },
    removeField(index, fieldType) {
      if (fieldType[index].userId != "") {
        this.users.map((e_rol) => {
          if (e_rol.id == fieldType[index].userId) {
            e_rol["disabled"] = false;
            e_rol["v_hide"] = false;
          }
        });
      }
      fieldType.splice(index, 1);
    },
    convertDateFormat(inputDateString) {
      // console.log('convertDateFormat');
      // inputDateString format e.g. "25-May-2023"
      // console.log('convertDateFormat(inputDateString){');
      // console.log(inputDateString);

      // Helper function to map month name to month number
      const getMonthNumber = (monthName) => {
        const monthNames = [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ];
        return monthNames.indexOf(monthName);
      };

      // Split the date string by the hyphen (-) to extract the day, month, and year
      const parts = inputDateString.split("-");
      const day = parseInt(parts[0], 10);
      const monthName = parts[1];
      const year = parseInt(parts[2], 10);

      const month = getMonthNumber(monthName);

      // console.log(parts, day, month, year);

      // Create a new Date object by specifying the year, month (zero-based), and day
      const dateObject = new Date(year, month, day);

      const newDay = ("0" + dateObject.getDate()).slice(-2);
      const newMonth = ("0" + (dateObject.getMonth() + 1)).slice(-2);
      const newYear = dateObject.getFullYear();

      // Format the date to the desired format "YYYY-MM-DD"
      const formattedDate = newYear + "-" + newMonth + "-" + newDay;

      // console.log(formattedDate);

      return formattedDate;
    },
    getSafetyObservationListing() {
      // console.log("getSafetyObservationListing");
      axios
        .get(
          apiDomain +
          "health_safety/safety_observation/" +
          this.safety_observation_id,
          { headers: getHeader() }
        )
        .then((response) => {
          if (response.status === 200) {
            this.safetyObservationResponse = response.data.data;
            this.model.project_id = response.data.data[0].project_id;
            this.model.group_id = response.data.data[0].group_id;
            this.model.site_id = response.data.data[0].site_id;
            this.model.contractor_id =
              response.data.data[0].contractor.contractor_id;
            this.model.project_status = response.data.data[0].project_status;
            // console.log(response.data.data[0].safety_observation_create_date);
            // console.log(typeof response.data.data[0].safety_observation_create_date);
            this.model.safety_observation_create_date = this.convertDateFormat(
              response.data.data[0].safety_observation_create_date_web
            );
            this.model.safety_observation_create_time =
              response.data.data[0].safety_observation_create_time;
            this.model.time_spent_hours =
              response.data.data[0].time_spent_hours;
            this.populateConductingUsers(
              response.data.data[0].conducting_users
            );
            this.model.safety_observation_items =
              response.data.data[0].safety_observation_items;

            this.populateDSSafetyObservationItems(this.model.safety_observation_items);
            this.loadAllSafetyObservationPhotos();
            this.getProjectListing();
            this.getGroupFromProject();
            this.getSiteFromGroup();
            this.getContractorFromSite();
            this.model.status = response.data.data[0].status;
          }
        })
        .catch((error) => {
          console.log(error.response.status);
          this.errorFunction(error, "Safety Observation Information");
        });
    },
    populateDSSafetyObservationItems(safety_observation_items) {

      // console.log(safety_observation_items);

      this.obj_data_grid.data = safety_observation_items.reduce((res, item) => {
        // console.log(res);
        // console.log(item.safety_observation_category.safety_observation_category);
        // console.log('   > ' + item.safety_observation_type.safety_observation_type_code);
        // console.log('   > ' + item.safety_observation_category.safety_observation_category);
        // console.log('   > ' + item.safety_observation_status.status_code);
        // console.log('   > ' + item.safety_observation_priority.safety_observation_priority_name);
        // console.log('   > ' + item.target_date);
        let tmp_obs_priority = '', tmp_target_date = '';

        if (item.safety_observation_priority === null) {
          tmp_obs_priority = "N/A";
        } else {
          tmp_obs_priority = item.safety_observation_priority.safety_observation_priority_name;
        };

        if (item.target_date == "") {
          tmp_target_date = "N/A";
        } else {
          tmp_target_date = item.target_date;
        };

        res.push({
          project_id: item.project_id,
          safety_observation_status_id: item.safety_observation_status_id,
          safety_observation_item_id: item.safety_observation_item_id,
          observation_type: item.safety_observation_type.safety_observation_type_code,
          observation_category: item.safety_observation_category.safety_observation_category,
          observation_status: item.safety_observation_status.status_code,
          // observation_priority: item.safety_observation_priority.safety_observation_priority_name,
          observation_priority: tmp_obs_priority,
          target_date: tmp_target_date,
        });
        return res;
      }, []);

      // console.log(this.obj_data_grid.data);
    },
    showNewObservationItem() {
      this.isNewObservationItemVisible = true;
    },
    closeNewObservationItem() {
      (this.new_model = {
        observation_description: null,
        safety_observation_type_id: "x",
        safety_observation_category_id: "x",
        correction_photos_required: "x",
        safety_observation_priority_id: "x",
        safety_observation_photos: [],
        safety_observation_status_id: 1,
      }),
        (this.isNewObservationItemVisible = false);
    },
    getContractorId() {
      axios
        .get(apiDomain + "lookup/contractor.contractor_id/" + this.user_id, {
          headers: getHeader(),
        })
        .then((response) => {
          if (response.status === 200) {
            this.contractor_id = response.data.contractor_id;
          }
        })
        .catch((error) => {
          this.errorFunction(error, "Contractor Id");
        });
    },
    viewObservationItem(item, index, button) {
      // 20230426 REQUIREMENT CHANGE, ALLOW SAFETY OFFICER TO SUBMIT CORRECTION

      // IF STATUS IS OPEN
      //   IF PROJECT IS COMPLETED OR APPROVED
      //     IF USER IS ONM OPEN PAGE TO SUBMIT CORRECTION
      //     ELSE VIEW DETAILS
      //   ELSE
      //     IF USER IS EPC OPEN PAGE TO SUBMIT CORRECTION
      //     ELSE VIEW DETAILS

      // ELSE IF PENDING APPROVAL
      // IF USER IS SAFETY OFFICER OPEN PAGE FOR APPROVAL
      // IF USER IS EPC OPEN PAGE TO EDIT CORRECTION
      // ELSE IF STATUS IS CLOSED
      // VIEW ONLY
      // ELSE VIEW ONLY?

      // GET PROJECT STATUS
      
      // console.log('viewObservationItem');
      // console.log(item);

      var project_index = this.projects.findIndex(
        (item) => item.project_id == this.model.project_id
      );
      var project_status_id = this.projects[project_index].status.status_id;

      // IF STATUS IS OPEN
      if (item.safety_observation_status_id == 2) {
        // IF PROJECT IS COMPLETED OR APPROVED
        if (project_status_id == 5 || project_status_id == 7) {
          // IF USER IS ONM OPEN PAGE TO SUBMIT CORRECTION | NEW REQUIREMENT, OR SAFETY OFFICER
          if (this.user_role == 18 || this.user_role == 16) {
            this.$router.push({
              path: "/UAM/HseItemCorrection/" + item.safety_observation_item_id,
            });
          } else {
            this.$router.push({
              path: "/UAM/HseItemView/" + item.safety_observation_item_id,
            });
          }
        } else {
          // IF USER IS EPC OPEN PAGE TO SUBMIT CORRECTION
          if (this.user_role == 12) {
            // IF USER BELONGS TO ASSIGNED CONTRACTOR | NEW REQUIREMENT, OR SAFETY OFFICER
            if (this.contractor_id == this.model.contractor_id) {
              this.$router.push({
                path: "HseItemCorrection/" + item.safety_observation_item_id,
              });
            } else {
              this.$router.push({
                path: "/UAM/HseItemView/" + item.safety_observation_item_id,
              });
            }
          } else if (this.user_role == 16) {
            this.$router.push({
              path: "/UAM/HseItemCorrection/" + item.safety_observation_item_id,
            });
          } else {
            this.$router.push({
              path: "/UAM/HseItemView/" + item.safety_observation_item_id,
            });
          }
        }
      } else if (item.safety_observation_status_id == 4) {
        this.$router.push({
          path: "/UAM/HseItemCorrection/" + item.safety_observation_item_id,
        });
      } else {
        this.$router.push({
          path: "/UAM/HseItemView/" + item.safety_observation_item_id,
        });
      }
    },
    saveNewObservationItem() {
      if (
        !this.new_model.observation_description ||
        this.new_model.safety_observation_type_id == "x" ||
        this.new_model.safety_observation_category_id == "x" ||
        this.new_model.correction_photos_required == "x" ||
        this.new_model.safety_observation_priority_id == "x"
      ) {
        if (!this.new_model.observation_description) {
          this.redErrorNotify(
            "<b>Invalid Input : Observation Remarks is a Mandatory Field in Safety Observation </b>"
          );
        } else if (this.new_model.safety_observation_type_id == "x") {
          this.redErrorNotify(
            "<b>Invalid Input : Safety Observation Type is a Mandatory Field in Safety Observation </b>"
          );
        } else if (this.new_model.safety_observation_category_id == "x") {
          this.redErrorNotify(
            "<b>Invalid Input : Safety Observation Category is a Mandatory Field in Safety Observation </b>"
          );
        } else if (this.new_model.correction_photos_required == "x") {
          this.redErrorNotify(
            "<b>Invalid Input : Need Correction Photos is a Mandatory Field in Safety Observation </b>"
          );
        } else if (this.new_model.safety_observation_priority_id == "x") {
          this.redErrorNotify(
            "<b>Invalid Input : Safety Observation Priority is a Mandatory Field in Safety Observation </b>"
          );
        } else {
          this.redErrorNotify(
            "<b>Invalid Input : Please fill in all the Mandatory Fields in Safety Observation </b>"
          );
        }
      } else {
        if (this.safety_observation_item_mode == "create") {
          this.model.safety_observation_items.push(this.new_model);
        } else if (this.safety_observation_item_mode == "edit") {
          this.model.safety_observation_items[this.current_item_index] =
            this.new_model;
          this.current_item_index = null;
        }

        this.safety_observation_item_mode = "create";

        this.closeNewObservationItem();
      }
    },
    getSafetyObservationStatus(status_id) {
      var findObj = this.safety_observation_status.find((obj) => {
        return obj.status_id === status_id;
      });
      if (typeof findObj !== "undefined") {
        return findObj.status_code;
      } else {
        return status_id;
      }
    },
    loadAllSafetyObservationPhotos() {
      this.model.safety_observation_items.forEach((e) => {
        e["safety_observation_photos"].forEach((el) => {
          if (el["image"]["url"]) {
            axios
              .get(apiDomain + el["image"]["url"], { headers: getHeader() })
              .then((response) => {
                if (response.status === 200) {
                  if (response.data.data.image && response.data.data.image) {
                    el[
                      "imageDataSrc"
                    ] = `data:${response.data.data.image};base64,${response.data.data.image}`;
                  } else {
                    el["imageDataSrc"] = null;
                  }
                }
              })
              .catch((error) => {
                console.log(error.response.status);
              });
          }
        });
      });
    },
    handleImageFileUpload() {
      // console.log("handleImageFileUpload");
      let vm = this;
      this.new_model.safety_observation_photos.push({
        photoFile: {},
        photoPreviewSrc: "",
        photoRemarks: "",
      });
      // console.log(this.$refs.imageFile.files[0]);
      this.new_model.safety_observation_photos.at(-1).photoFile =
        this.$refs.imageFile.files[0];
      // console.log(this.new_model.safety_observation_photos.at(-1).photoFile);

      var reader = new FileReader();
      reader.onload = function (e) {
        vm.new_model.safety_observation_photos.at(-1).photoPreviewSrc =
          e.target.result;
      };

      reader.readAsDataURL(
        this.new_model.safety_observation_photos.at(-1).photoFile
      );
    },
    deletePhoto(index) {
      if (
        typeof this.new_model.safety_observation_photos[index][
        "safety_observation_item_file_id"
        ] !== "undefined"
      ) {
        if (
          typeof this.new_model.safety_observation_item_files_to_be_deleted ==
          "undefined"
        ) {
          this.new_model.safety_observation_item_files_to_be_deleted = [];
        }
        this.new_model.safety_observation_item_files_to_be_deleted.push(
          this.new_model.safety_observation_photos[index][
          "safety_observation_item_file_id"
          ]
        );
      }
      this.new_model.safety_observation_photos.splice(index, 1);
    },
    deleteObservationItem(item, index, button) {
      if (
        typeof this.model.safety_observation_items[index]
          .safety_observation_item_id !== "undefined"
      ) {
        if (
          typeof this.model.safety_observation_items_to_be_deleted ==
          "undefined"
        ) {
          this.model.safety_observation_items_to_be_deleted = [];
        }
        this.model.safety_observation_items_to_be_deleted.push(
          this.model.safety_observation_items[index].safety_observation_item_id
        );
      }
      this.model.safety_observation_items.splice(index, 1);
    },
    checkAndCreateConductingUsers() {
      let tempConductingUsers = [];
      this.conducting_users.forEach((item) => {
        if (item.userId != "") {
          tempConductingUsers.push({ userId: item.userId });
        }
      });
      this.model.conducting_users = tempConductingUsers;
    },
    checkForRequiredData() {
      this.checkAndCreateConductingUsers();
      var checker = false;
      if (
        this.model.project_id == "x" ||
        this.model.group_id == "x" ||
        this.model.site_id == "x" ||
        this.model.contractor_id == "x" ||
        !this.model.safety_observation_create_date ||
        !this.model.safety_observation_create_time ||
        this.model.safety_observation_items.length < 1
      ) {
        if (this.model.project_id == "x") {
          this.redErrorNotify(
            "<b>Invalid Input : Project is a Mandatory Field in Safety Observation </b>"
          );
        } else if (this.model.group_id == "x") {
          this.redErrorNotify(
            "<b>Invalid Input : Project Group is a Mandatory Field in Safety Observation </b>"
          );
        } else if (this.model.site_id == "x") {
          this.redErrorNotify(
            "<b>Invalid Input : Project Site is a Mandatory Field in Safety Observation </b>"
          );
        } else if (this.model.contractor_id == "x") {
          this.redErrorNotify(
            "<b>Invalid Input : EPC (Project Site Contractor) is a Mandatory Field in Safety Observation </b>"
          );
        } else if (!this.model.safety_observation_create_date) {
          this.redErrorNotify(
            "<b>Invalid Input : Date is a Mandatory Field in Safety Observation </b>"
          );
        } else if (!this.model.safety_observation_create_time) {
          this.redErrorNotify(
            "<b>Invalid Input : Time is a Mandatory Field in Safety Observation </b>"
          );
        } else if (this.model.safety_observation_items.length < 1) {
          this.redErrorNotify(
            "<b>Invalid Input : Safety Observation Item is a Mandatory Field in Safety Observation </b>"
          );
        } else {
          this.redErrorNotify(
            "<b>Invalid Input : Please fill in all the Mandatory Fields in Safety Observation </b>"
          );
        }
      } else {
        console.log("passed all checks");
        checker = true;
      }
      return checker;
    },
    saveAsDraft() {
      if (this.checkForRequiredData()) {
        // console.log("save as draft");

        this.model.safety_observation_id = this.safety_observation_id;
        this.model.status = 0;

        let formData = jsonFormData(this.model);
        formData.append("_method", "put");

        // console.log(typeof formData);

        const tokenData = JSON.parse(window.localStorage.getItem("authUser"));
        const headers = {
          Authorization: "Bearer " + tokenData.access_token,
        };

        axios
          .post(
            apiDomain +
            "health_safety/safety_observation/" +
            this.safety_observation_id,
            formData,
            { headers }
          )
          .then((response) => {
            if (response.status === 200) {
              this.$notify({
                message:
                  "<b>Safety Observation Successfully Updated and Saved As Draft.</b>",
                timeout: 10000,
                icon: "ni ni-bell-55",
                type: "default",
              });
              this.$router.back();
            }
          })
          .catch((error) => {
            this.errorFunction(error, "Update Safety Observation Draft");
          });
      }
    },
    submitData() {
      if (this.checkForRequiredData()) {
        // console.log("submit data");

        this.model.safety_observation_id = this.safety_observation_id;
        this.model.status = 1;

        let formData = jsonFormData(this.model);
        formData.append("_method", "put");

        // console.log(typeof formData);

        const tokenData = JSON.parse(window.localStorage.getItem("authUser"));
        const headers = {
          Authorization: "Bearer " + tokenData.access_token,
        };

        axios
          .post(
            apiDomain +
            "health_safety/safety_observation/" +
            this.safety_observation_id,
            formData,
            { headers }
          )
          .then((response) => {
            if (response.status === 200) {
              this.$notify({
                message:
                  "<b>Safety Observation Successfully Updated and Submitted.</b>",
                timeout: 10000,
                icon: "ni ni-bell-55",
                type: "default",
              });
              this.$router.back();
            }
          })
          .catch((error) => {
            this.errorFunction(error, "Update Safety Observation Submit");
          });
      }
    },
    action_on_modal(what_action, what_modal) {
      ( what_action == 'show' ) ? this.$refs[what_modal].show() : this.$refs[what_modal].hide();
    },
  },
};
</script>

<!-- 
<style>
input::-webkit-input-placeholder {
  color: #fff;
}

input::-moz-input-placeholder {
  color: #fff;
}

input::-ms-input-placeholder {
  color: #fff;
}

.el-input,
.el-input__inner {
  background-color: #2C545A;
  color: #fff;
  border: 0px;
  border-radius: 10px;
  padding-top: 3px;
  padding-bottom: 2px;

  &::placeholder {
    color: white;
  }
}

.el-select {
  .el-input .el-input__inner {
    &::placeholder {
      color: white;
    }
  }

}

.el-select__input {
  color: white;

}

.el-select-dropdown__item.hover,
.el-select-dropdown__item:hover {
  background-color: #23504e;
}

.el-select-dropdown {
  background-color: #306b74;
  margin: 0px;
}

.el-select-dropdown__item {
  color: white;

  ::hover {
    background-color: #2C545A
  }

}

.el-popper .popper__arrow,
.el-popper .popper__arrow::after {
  border-style: hidden;
}

#__BVID__39___BV_modal_content_ {
  background-color: #306b74;
}

.modal-content {
  background-color: #306b74;

}

.list-group-item {
  border: 1px solid #e9ecef00;
}

select option {
  margin: 40px;
  background-color: #306b74 !important;
  color: #fff;
  text-shadow: 0 1px 0 rgba(0, 0, 0, 0.4);
}

.btn {
  color: white;

  &:hover {
    &::placeholder {
      color: white;
    }
  }
}

h5 {
  color: #ffffff;
}

label {
  color: #ffffff;
}

.form-control-label {
  color: #ffffff;
}

.form-control {
  background-color: #2c545a;
  border-color: #2c545a;
  color: #ffffff;
}

.form-control:focus {
  background-color: #2c545a;
  border-color: #2c545a;
  color: #ffffff;
}

.form-control:disabled {
  background-color: #2c545a;
  border-color: #2c545a;
  color: #ffffff;
}
</style> -->

<!-- \* Class for table data */ -->
<!-- <style>
table thead th {
  background-color: rgb(58, 101, 107);
  color: white;
  font-size: small;
  border-bottom: 20px solid rgb(8, 42, 47);
}

.th-table-modal-new {
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 10px;
}

.grid-tr-new {
  background-color: #23454A;
  color: white;
  font-size: smaller;
  border-bottom: 20px solid #082A2F;
}

.tr-td-table-modal-new {
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 10px;
}

.th-table-new {
  padding-top: 30px;
  padding-bottom: 30px;
  padding-left: 20px;
}

.tr-td-table-new {
  padding-top: 25px;
  padding-bottom: 25px;
  padding-left: 20px;
}
</style> -->

<style scoped>
/* Class For Modal */
.th-table-new {
  padding-top: 35px;
  padding-bottom: 35px;
  padding-left: 20px;
}

.tr-td-table-new {
  padding-top: 30px;
  padding-bottom: 30px;
  padding-left: 20px;
}

.th-table-new-mdl {
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 20px;
}

.tr-td-table-new-mdl {
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 20px;
}

.form-control {
  background-color: #2C545A;
  border-color: #2C545A;
  color: white;

  &:focus {
    background-color: #2C545A;
    border-color: #2C545A;
    /* color: white; */
  }

  &::placeholder {
    color: white;
  }
}

.form-control {
  &:focus {
    &::placeholder {
      color: white;
    }
  }
}

.input-group-text {
  background-color: #2C545A;
  ;
  color: white;

  &:focus {
    background-color: #2C545A;
    ;
    border-color: #1D4147;
    color: white;
  }
}

.focused {
  .input-group-text {
    background-color: #2C545A;
  }
}

.navbar-search-dark .input-group {
  background-color: #23454A;
  color: black;
  border-color: rgba(255, 255, 255, 0.6);
}

.navbar-search .input-group {
  border-radius: 7px;
}

select {
  background: url("data:image/svg+xml,<svg width='10' height='7' viewBox='0 0 10 7' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M5.02552 6.35057L0.320068 0.46875H9.73098L5.02552 6.35057Z' fill='white'/></svg>") no-repeat;
  background-position: calc(100% - 0.75rem) center !important;
  -moz-appearance: none !important;
  -webkit-appearance: none !important;
  appearance: none !important;
  padding-right: 2rem !important;
}
</style>
