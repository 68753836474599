<template>
  <div>
    <base-header class="pb-6" type="">
      <b-row align-v="center" class="py-4">
        <b-col lg="6" cols="7">
          <h6 class="h2 d-inline-block mb-0">Site Dashboard</h6>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <b-breadcrumb class="breadcrumb-links">
              <b-breadcrumb-item><router-link to="/"><i class="fas fa-home"></i></router-link></b-breadcrumb-item>
              <b-breadcrumb-item><a href="#">Dashboards</a></b-breadcrumb-item>
              <b-breadcrumb-item active aria-current="page">Alternative</b-breadcrumb-item>
            </b-breadcrumb>
          </nav>
        </b-col>
        <b-col lg="12" cols="6" class="text-right">
          <router-link to="/TaskNew">
            <base-button size="sm" type="neutral">Create New Task</base-button>
          </router-link>
          &nbsp;
            <base-button type="neutral" size="sm" @click="modals.formTask = true">Populate Site From Template</base-button>
            <!-- <base-button type="neutral" size="sm" @click="modals.formMilestone = true">Import Milestone Template</base-button> -->
            <base-button size="sm" type="neutral" @click="$router.go(-1)">Back</base-button>
        </b-col>
      </b-row>

       <b-row >
        <b-col xl="3" md="6">
          <stats-card title="Approved Document"
                      type="gradient-red"
                      sub-title="15 / 110"
                      icon="ni ni-briefcase-24" >

            <template slot="footer">
              <span class="text-success mr-2"><i class="fa fa-arrow-up"></i> 1</span>
              <span class="text-nowrap">Added last month</span>
            </template>
          </stats-card>
        </b-col>
        <b-col xl="3" md="6">
          <stats-card title="Installation"
                      type="gradient-orange"
                      sub-title="168"
                      icon="ni ni-cloud-upload-96">

            <template slot="footer">
              <span class="text-success mr-2"><i class="fa fa-arrow-up"></i> 12</span>
              <span class="text-nowrap">This month</span>
            </template>
          </stats-card>
        </b-col>
        <b-col xl="3" md="6">
          <stats-card title="Approved Task"
                      type="gradient-green"
                      sub-title="15 / 205"
                      icon="ni ni-bullet-list-67">

            <template slot="footer">
              <span class="text-success mr-2"><i class="fa fa-arrow-up"></i> 1</span>
              <span class="text-nowrap">Added This month</span>
            </template>
          </stats-card>

        </b-col>
        <b-col xl="3" md="6">
          <stats-card title="Site Progress"
                      type="gradient-info"
                      sub-title="34%"
                      icon="ni ni-chart-bar-32">

            <template slot="footer">
              <span class="text-success mr-2"><i class="fa fa-arrow-up"></i> 10.8%</span>
              <span class="text-nowrap">Since last month</span>
            </template>
          </stats-card>
        </b-col>
      </b-row>
    </base-header>

    <b-container fluid class="mt--6">
      <b-row>
        <b-col xl="12">
            <site-view-form></site-view-form>
        </b-col>
      </b-row>
      <b-row>
          <b-col xl="6">
          <card type="default" header-classes="bg-transparent">
            <b-row align-v="center" slot="header">
              <b-col>
                <h5 class="h3 text-white mb-0">Site Task</h5>
              </b-col>
              <b-col>
                <b-nav class="nav-pills justify-content-end">
                  <b-nav-item
                       class="mr-1 mr-md-0"
                       :active="bigLineChart.activeIndex === 0"
                       link-classes="py-2 px-3"
                       @click.prevent="initBigChart(0)">
                      <span class="d-none d-md-block">Month</span>
                      <span class="d-md-none">M</span>
                  </b-nav-item>

                </b-nav>
              </b-col>
            </b-row>
            <line-chart
              :height="350"
              ref="bigChart"
              :chart-data="bigLineChart.chartData"
              :extra-options="bigLineChart.extraOptions">
            </line-chart>
          </card>
          </b-col>
          <b-col xl="3">
            <site-task-status></site-task-status>
          </b-col>
          <b-col xl="3">
            <site-document-status></site-document-status>
            <!-- <site-approval-status></site-approval-status> -->
          </b-col>

      </b-row>
      <b-row>

        <b-col lg="12">
            <!-- <site-table></site-table> -->
            <task-table></task-table>
        </b-col>
      </b-row>

      <b-row>

        <b-col lg="12">
            <!-- <site-table></site-table> -->
            <site-document-table></site-document-table>
        </b-col>

        <b-col lg="12">
            <!-- <site-table></site-table> -->
            <site-recurring-document-table></site-recurring-document-table>
        </b-col>
      </b-row>

    </b-container>

        <modal :show.sync="modals.formTask"
                   size="xl"
                   body-classes="p-0">
              <card type="secondary"
                    header-classes="bg-transparent pb-5"
                    body-classes="px-lg-5 py-lg-5"
                    class="border-0 mb-0">
                <template>
                  <div class="text-center text-muted mb-4">
                    <h3>Populate Task & Milestone Template Import into Site : SN-NT - Tengeh Reservoir North Site <br>for ( TRTP-2398 - Tengeh Reservoir Tender Project )</h3>
                  </div>
                  <b-form role="form">
                    <base-input label="Task Template" name="Task Template">
                      <select class="form-control" v-model="formModal.task_template">
                        <option value="" disabled>-- Please Select A Task Template --</option>
                        <option value="Solar Panel Project Template">TS-SL-Temp : Solar Panel Project (No. of Task : 40)</option>
                        <option value="Power Plant Project Template">TS-PL-Temp :Power Plant Project (No. of Task : 120)</option>
                      </select>
                    </base-input>
                    <base-input label="Task Template" name="Document Template">
                      <select class="form-control">
                        <option value="" >-- Please Select A Document Template --</option>
                        <option value="Solar Panel Project Template">DT-SL-Temp : Solar Panel Document Project Template (No. of Classfication : 10)</option>
                        <option value="Power Plant Project Template">DT-PL-Temp : Power Plant Document Project Template (No. of Classfication : 12)</option>
                      </select>
                    </base-input>
                     <h5 class="text-danger"> Note : <br>
                      <i>
                      1. Please Create a New Site before Import / Populate Task & Milestone into the Site (We Recommend to Import / Populate into a New Site).
                      <br>
                      2. Import / Populate Task into existing site will only create new task (All existing TASK will still be remain intact, But import twice might cause duplication).
                      <br>
                      3. All the Milestone will be Import / Populate TOGETHER according with the "Task Template".
                      <br>
                      4. To Change Milestone please proceed to Menu >> Template >> Milestone Template.
                      <br>
                      5. To Change Task please proceed to Menu >> Template >> Task Template.
                      <br>
                      6. Task Details in Template will not have the following column :
                      <br>
                      &nbsp;&nbsp;&nbsp; a) Project & Site.
                      <br>
                      &nbsp;&nbsp;&nbsp; b) Contractor.
                      <br>
                      &nbsp;&nbsp;&nbsp; c) Progress & Status.
                      <br>
                      &nbsp;&nbsp;&nbsp; d) Est Start , End Date & Start , End Date.
                      <br>
                      7. Only Column (a) & (b) can be selected during import. Please change column of (c) & (d) on each task later.
                      <br>
                      8. System will auto generate NEW TASK ID for all the TASK import / populate from Template. But Milestone CODE will remain the same.
                      </i>
                    </h5>
                    <div class="text-center">
                      <base-button type="primary" class="my-4" @click="notifyVueFormTask()">Import Task Template</base-button>
                      <base-button type="primary" class="my-4" @click="clearModal()">Cancel</base-button>
                    </div>
                  </b-form>
                </template>
              </card>
        </modal>

  </div>
</template>

<script>
  import RouteBreadCrumb from '@/components/Breadcrumb/RouteBreadcrumb';
  import BaseHeader from '@/components/BaseHeader';
  import SiteViewForm from './SiteViewForm';

  import LineChart from '@/components/Charts/LineChart';
  import * as chartConfigs from '@/components/Charts/config';

  // import UserList from './../AdditionalTable/UserTable';
  // import ContractorList from './../AdditionalTable/ContractorTable';
  // import ActivityTable from './../AdditionalTable/ActivityTable';
  import SiteTaskStatus from './../AdditionalTable/SiteTaskStatus';
  import SiteDocumentStatus from './../AdditionalTable/SiteDocumentStatus';
  import SiteApprovalStatus from './../AdditionalTable/SiteApprovalStatus';

  import TaskTable from './TaskTable';
  import SiteTable from './SiteTable';
  import SiteDocumentTable from './SiteDocumentTable';

  import { Modal } from '@/components';
  import SiteRecurringDocumentTable from './SiteRecurringDocumentTable';

  export default {
    components: {
      BaseHeader,
      RouteBreadCrumb,
      LineChart,
      // UserList,
      // ContractorList,
      // ActivityTable,
      SiteTaskStatus,
      SiteDocumentStatus,
      SiteApprovalStatus,
      SiteDocumentTable,
      TaskTable,
      SiteViewForm,
      SiteTable,
      Modal,
      SiteRecurringDocumentTable,
    },
    data() {
        return {
        modals: {
          formTask: false,
          formMilestone: false,
        },
        formModal: {
          task_template: '',
          task_site: '',
          milestone_template: '',
        },
        bigLineChart: {
          allData: [
            [0, 20, 10, 30, 15, 40, 20, 60, 60],
            [0, 20, 5, 25, 10, 30, 15, 40, 40]
          ],
          activeIndex: 0,
          chartData: {
            datasets: [
              {
                label: 'Performance',
                data: [0, 20, 10, 30, 15, 40, 20, 60, 60],
              }
            ],
            labels: ['May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
          },
          extraOptions: chartConfigs.blueChartOptions,
        },
      };
    },
    methods: {
      notifyVueFormTask() {
        if ( this.formModal.task_template === '')
        {
          this.$notify({
          message:
            '<b>Task Template Input Error : </b> - Please Select A Task Template & Target Site OR press <b>CANCEL</b> to <b>EXIT</b>.',
          timeout: 10000,
          icon: 'ni ni-bulb-61',
          type: 'danger',
          });
        }
        else
        {
          this.$notify({
          message:
            '<b>Task Template (' + this.formModal.task_template + ') : </b> - Successfully Imported into ( TRTP-2398 - Tengeh Reservoir Tender Project )',
          timeout: 10000,
          icon: 'ni ni-bell-55',
          type: 'default',
          });
          this.clearModal()
        }
      },
      notifyVueFormMilestone() {
        if ( this.formModal.milestone_template === '' )
        {
          this.$notify({
          message:
            '<b>Milestone Template Input Error : </b> - Please Select A Milestone Template Or Cancel to Exit.',
          timeout: 10000,
          icon: 'ni ni-bulb-61',
          type: 'danger',
          });
        }
        else
        {
          this.$notify({
          message:
            '<b>Milestone Template (' + this.formModal.milestone_template + ') : </b> - Successfully Imported into ( TRTP-2398 - Tengeh Reservoir Tender Project )',
          timeout: 10000,
          icon: 'ni ni-bell-55',
          type: 'default',
          });
          this.clearModal()
        }
      },
      clearModal()
      {
        this.modals.formTask = false
        this.modals.formMilestone = false
        this.formModal.task_template = ''
        this.formModal.task_site = ''
        this.formModal.milestone_template = ''
      }
    }
  };
</script>
<style></style>
