<template>
  <div class="content">
    <!-- <div>{{tempChecker}}</div>
    <div>{{myUserID}}</div> -->
    <!-- <div>{{tableData}}</div>
    <div>{{indexToBeDeleted}}</div> -->
    <base-header class="pb-6">
      <b-row align-v="center" class="py-4">
        <b-col cols="7" lg="6">
          <h6 class="h2 text-white d-inline-block mb-0">
            Project Site Listing
          </h6>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <route-bread-crumb></route-bread-crumb>
          </nav>
        </b-col>
        <b-col lg="6" cols="5" class="text-right"> </b-col>
      </b-row>
    </base-header>
    <b-container fluid class="mt--6">
      <div>
        <card
          class="no-border-card"
          body-classes="px-0 pb-1"
          footer-classes="pb-2"
        >
          <template slot="header">
            <h3 class="mb-0">Project Site Listing</h3>
          </template>
          <div>
            <!-- <div>{{ button_access.view_button }}</div>
            <div>{{ buttonClicked }}</div>
            <div>{{ clickedRow }}</div> -->
            <b-col
              cols="12"
              class="
                d-flex
                justify-content-center justify-content-sm-between
                flex-wrap
              "
            >
              <el-select
                class="select-primary pagination-select"
                v-model="pagination.perPage"
                placeholder="Per page"
              >
                <el-option
                  class="select-primary"
                  v-for="item in pagination.perPageOptions"
                  :key="item"
                  :label="item"
                  :value="item"
                >
                </el-option>
              </el-select>
              <div>
                <base-input
                  v-model="searchQuery"
                  prepend-icon="fas fa-search"
                  placeholder="Search..."
                >
                </base-input>
              </div>
            </b-col>
            <b-col><br /></b-col>
            <el-table
              :data="queriedData"
              @row-click="rowClicked"
              row-key="site_id"
              header-row-class-name="thead-light"
              @sort-change="sortChange"
            >
              <el-table-column
                label="Site Name"
                min-width="140px"
                prop="site_code"
                sortable
              >
                <template v-slot="{ row }">
                  <div>
                    <b-media no-body class="align-items-center">
                      <b-media-body>
                        <span class="font-weight-600 name mb-0 text-sm">{{
                          row.site_name
                        }}</span>
                      </b-media-body>
                    </b-media>
                  </div>
                </template>
              </el-table-column>

              <el-table-column
                label="Project"
                min-width="140px"
                prop="project.project_code"
                sortable
              >
                <template v-slot="{ row }">
                  <b-media no-body class="align-items-center">
                    <b-media-body>
                      <span class="font-weight-600 name mb-0 text-sm">{{
                        row.project.project_name
                      }}</span>
                    </b-media-body>
                  </b-media>
                </template>
              </el-table-column>

              <!-- <el-table-column
                label="Group"
                min-width="120px"
                prop="group.group_code"
                sortable
              >
                <template v-slot="{ row }">
                  <b-media no-body class="align-items-center">
                    <b-media-body>
                      <span class="font-weight-600 name mb-0 text-sm">{{
                        row.group.group_name
                      }}</span>
                    </b-media-body>
                  </b-media>
                </template>
              </el-table-column> -->

              <!-- <el-table-column
                label="Information"
                min-width="180px"
                prop="site_leader"
                sortable
              >
                <template v-slot="{ row }">
                  <div>
                    <b-media no-body class="align-items-center">
                      <b-media-body>
                        <span class="font-weight-600 name mb-0 text-sm"
                          >P.Manager: {{ row.group.group_engineer.name }}</span
                        >
                      </b-media-body>
                    </b-media>
                  </div>
                  <div>
                    <b-media no-body class="align-items-center">
                      <b-media-body>
                        <span class="font-weight-600 name mb-0 text-sm"
                          >Postal.Code: {{ row.site_postal_code }}</span
                        >
                      </b-media-body>
                    </b-media>
                  </div>
                  <div>
                    <b-media no-body class="align-items-center">
                      <b-media-body>
                        <span class="font-weight-600 name mb-0 text-sm"
                          >Capacity: {{ row.site_total_capacity }} KWp</span
                        >
                      </b-media-body>
                    </b-media>
                  </div>
                </template>
              </el-table-column> -->

              <el-table-column
                label="Progress"
                min-width="210px"
                prop="site_progress"
                sortable
              >
                <template v-slot="{ row }">
                  <div class="d-flex align-items-center">
                    <div>
                      <span class="font-weight-600 name mb-0 text-sm"
                        >{{ row.site_progress }}%
                         <!-- &nbsp; -->
                      </span>
                    </div>
                    <!-- <div>
                      <base-progress
                        v-if="row.site_progress > 0 && row.site_progress < 50"
                        min-width="30px"
                        type="danger"
                        :value="row.site_progress"
                      />
                      <base-progress
                        v-else-if="
                          row.site_progress >= 50 && row.site_progress <= 90
                        "
                        min-width="30px"
                        type="info"
                        :value="row.site_progress"
                      />
                      <base-progress
                        v-else
                        min-width="30px"
                        type="success"
                        :value="row.site_progress"
                      />
                    </div> -->
                  </div>
                  <!-- <b-media no-body class="align-items-center">
                    <b-media-body>
                      <badge class="badge-dot mr-4" type="">
                        <i
                          v-if="row.status.status_code == 'Delayed'"
                          class="bg-danger"
                        ></i>
                        <i
                          v-else-if="row.status.status_code == 'On-Hold'"
                          class="bg-danger"
                        ></i>
                        <i
                          v-else-if="row.status.status_code == 'Reviewed'"
                          class="bg-success"
                        ></i>
                        <i
                          v-else-if="row.status.status_code == 'Completed'"
                          class="bg-success"
                        ></i>
                        <i v-else class="bg-info"></i>
                        <span class="font-weight-600 name mb-0 text-sm">{{
                          row.status.status_code
                        }}</span>
                      </badge>
                    </b-media-body>
                  </b-media> -->
                  <!-- <div>
                    Est Turn-On Date: <b>{{ row.site_target_turn_on_date }}</b>
                  </div> -->
                </template>
              </el-table-column>

              <el-table-column min-width="140px" align="right" label="Actions">
                <div slot-scope="{ $index, row }" class="d-flex">
                  <base-button
                    v-if="button_access.view_button == 'YES'"
                    @click.native="handleDetails($index, row)"
                    size="sm"
                    type="primary"
                  >
                    Dashboard
                  </base-button>
                </div>
              </el-table-column>
            </el-table>
          </div>
          <div
            slot="footer"
            class="
              col-12
              d-flex
              justify-content-center justify-content-sm-between
              flex-wrap
            "
          >
            <div class="">
              <p class="card-category">
                Showing {{ from + 1 }} to {{ to }} of {{ total }} entries
                <span v-if="selectedRows.length">
                  &nbsp; &nbsp; {{ selectedRows.length }} rows selected
                </span>
              </p>
            </div>
            <base-pagination
              class="pagination-no-border"
              :current="pagination.currentPage"
              :per-page="pagination.perPage"
              :total="total"
              @change="paginationChanged($event)"
            >
            </base-pagination>
          </div>
        </card>
      </div>
    </b-container>
  </div>
</template>
<script>
import { Table, TableColumn, Select, Option } from "element-ui";
import RouteBreadCrumb from "@/components/Breadcrumb/RouteBreadcrumb";
import { BasePagination } from "@/components";
import standardPaginationMix from "@/assets/js/standardPaginationMix.js";
import Fuse from "fuse.js";
import axios from "axios";
import {
  LoginURL,
  clientId,
  clientSecret,
  apiDomain,
  getHeader,
} from "@/assets/js/config.js";

export default {
  mixins: [standardPaginationMix],
  components: {
    BasePagination,
    RouteBreadCrumb,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
  },
  created() {
    const LoginUser = JSON.parse(window.localStorage.getItem("loginUser"));
    this.myUserID = LoginUser;
    var project_site_access = JSON.parse(
      window.localStorage.getItem("project_site_access")
    );
    this.tempChecker = project_site_access;
    this.button_access.new_button = project_site_access.access_create;
    this.button_access.view_button = project_site_access.access_show;
    if (this.myUserID.role_id == 12) {
      this.button_access.view_button = "YES";
    }
    this.getStartUpData();
  },
  data() {
    return {
      tempChecker: null,
      myUserID: null,
      propsToSearch: [
        "site_code",
        "site_name",
        "project.project_code",
        "group.group_code",
        "site_leader.name",
        "site_progress",
        "status.status_code",
      ],
      tableColumns: [],
      tableData: [],
      selectedRows: [],
      button_access: {
        new_button: null,
        view_button: null,
      },
      indexToBeDeleted: [],
      buttonClicked: false,
      clickedRow: null,
    };
  },
  methods: {
    getStartUpData() {
      axios
        .get(
          apiDomain +
            "project/project_site.show_by_contractor_short/" +
            this.myUserID.id,
          { headers: getHeader() }
        )
        .then((response) => {
          if (response.status === 200) {
            this.tableData = response.data.data;
            this.fuseData();
            this.deleteApprovedListing();
          }
        })
        .catch((error) => {
          console.log(error.response.status);
        });
    },
    fuseData() {
      this.fuseSearch = new Fuse(this.tableData, {
        keys: this.propsToSearch,
        threshold: 0.3,
      });
    },
    paginationChanged(page) {
      this.pagination.currentPage = page;
    },
    handleDetails(index, row) {
      this.$router.push({
        path: "projectSiteDashboardContractor/" + row.site_id,
      });
    },
    rowClicked(row) {
      if (this.button_access.view_button == "YES") {
        this.buttonClicked = true;
        this.clickedRow = row;
        this.$router.push({
          path: "projectSiteDashboardContractor/" + row.site_id,
        });
      }
    },
    selectionChange(selectedRows) {
      this.selectedRows = selectedRows;
    },
    deleteApprovedListing() {
      this.tableData.forEach((item) => {
        if (item.status.status_id == 7) {
          this.indexToBeDeleted.push(this.tableData.indexOf(item));
        }
      });
      this.indexToBeDeleted.reverse();
      this.indexToBeDeleted.forEach((item) => {
        this.tableData.splice(item, 1);
      });
    },
  },
};
</script>
<style>
.no-border-card .card-footer {
  border-top: 0;
}
</style>
