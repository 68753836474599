<template>
  <card>
    <!-- Card header -->
    <h3 slot="header" class="mb-0">Edit Developer for ({{ model.developer_code }})</h3>
    <div class="text-right">
    </div>
    <form class="needs-validation"
          @submit.prevent="submit">

      <div class="form-row">
        <b-col md="4">
          <base-input label="Developer Code"
                      name="Developer Code"
                      placeholder="Developer Code"
                      v-model="model.developer_code" required>
          </base-input>
        </b-col>

        <b-col md="8">
          <base-input label="Developer Name"
                      name="Developer Name"
                      placeholder="Developer Name"
                      v-model="model.developer_name" required>
          </base-input>
        </b-col>
      </div>

      <div class="form-row">
        <b-col md="4">
          <base-input label="Contact Person"
                      name="Contact Person"
                      placeholder="Contact Person"
                      v-model="model.developer_contact_person" required>
          </base-input>
        </b-col>

        <b-col md="4">
          <base-input label="Contact Number"
                      name="Contact Number"
                      placeholder="Contact Number"
                      v-model="model.developer_contact_number" required>
          </base-input>
        </b-col>

        <b-col md="4">
          <base-input label="Email"
                      name="Email"
                      placeholder="Email"
                      v-model="model.developer_contact_email" required>
          </base-input>
        </b-col>
      </div>

      <div class="form-row">
        <b-col md="4">
          <b-form-checkbox
            id="Active-Status"
            v-model="model.active_status"
            name="Active-Status"
            value="1"
            unchecked-value="0">
            <b>Active Status</b>
          </b-form-checkbox>
        </b-col>
      </div>
      <br>

      <base-button type="primary" @click="createData()" v-if="button_access.edit_button == 'YES'">Update Developer</base-button>
      <base-button type="primary" @click="$router.back()">Cancel</base-button>
      <!-- <div>
        <br>
        <h5 class="text-danger"> Note : <br><br>
                <i>
                1. By Default all Date is set to "TODAY.
                </i>
                <br>
                <i>
                2. Developer Progress % is set to "0 %" By Default.
                </i>
        </h5>
      </div> -->
    </form>
  </card>
</template>
<script>
  import BaseSlider from '@/components/BaseSlider'
  import {LoginURL, clientId, clientSecret, apiDomain, getHeader, getcurrentDate} from '@/assets/js/config.js'
  import axios from 'axios'
  import ErrorFunctionMixin from '@/assets/js/errorFunctionMix.js'

  export default {
    mixins: [ErrorFunctionMixin],
    components: {
      BaseSlider
    },
    created()
    {
      var currentDate = getcurrentDate()
      var developer_access = JSON.parse(window.localStorage.getItem('developer_access'))
      this.button_access.edit_button = developer_access.access_edit

      this.model.developer_id = this.$route.params.id
      axios.get(apiDomain + 'setup/developer/'+this.model.developer_id, { headers: getHeader() })
      .then ( response => {
      if (response.status === 200)
      {
          this.model.developer_code = response.data.data[0].developer_code
          this.model.developer_name = response.data.data[0].developer_name
          this.model.developer_contact_person = response.data.data[0].developer_contact_person
          this.model.developer_contact_number = response.data.data[0].developer_contact_number
          this.model.developer_contact_email = response.data.data[0].developer_contact_email
          if ( response.data.data[0].active_status == 'ACTIVE' )
          {
            this.model.active_status = 1
          }
          else
          {
            this.model.active_status = 0
          }
      }
      })
      .catch ( error => {
          console.log(error.response.status)
          this.errorFunction(error, "Developer Information")
      });
    },
    data() {
      return {
        model: {
          developer_id: null,
          developer_code: null,
          developer_name: null,
          developer_contact_person: null,
          developer_contact_number: null,
          developer_contact_email: null,
          active_status: null,
        },
        button_access: {
          edit_button: null,
        },
      }
    },
    methods: {
      createData(){
        if ( !this.model.developer_code || !this.model.developer_name || !this.model.developer_contact_person
        || !this.model.developer_contact_number || !this.model.developer_contact_email )
        {
          if ( !this.model.developer_code )
          {
            this.redErrorNotify( '<b>Invalid Input : Developer Code is a Mandatory Column in Developer.</b>')
          }
          else if ( !this.model.developer_name  )
          {
            this.redErrorNotify( '<b>Invalid Input : Developer Name is a Mandatory Column in Developer.</b>')
          }
          else if ( !this.model.developer_contact_person  )
          {
            this.redErrorNotify( '<b>Invalid Input : Contact Person is a Mandatory Column in Developer.</b>')
          }
          else if ( !this.model.developer_contact_number  )
          {
            this.redErrorNotify( '<b>Invalid Input : Contact Number is a Mandatory Column in Developer.</b>')
          }
          else if ( !this.model.developer_contact_email  )
          {
            this.redErrorNotify( '<b>Invalid Input : Email is a Mandatory Column in Developer.</b>')
          }
          else
          {
            this.redErrorNotify( '<b>Invalid Input : Please fill in all the Mandatory Column in Task </b>' )
          }
        }
        else
        {
          axios.put(apiDomain + 'setup/developer/'+this.model.developer_id, this.model, { headers: getHeader() })
          .then ( response => {
            if (response.status === 200)
            {
                this.$notify({
                message:
                  '<b>Update Developer : ' + this.model.developer_code + '</b> - Successfully Updated.',
                timeout: 10000,
                icon: 'ni ni-bell-55',
                type: 'default',
                });
                this.$router.back()
            }
          })
          .catch ( error => {
            this.errorFunction(error, "Update Developer")
          });
        }
      },
    }
  }
</script>
<style>
</style>
