<template>
  <b-list-group flush data-toggle="checklist">
    <b-list-group-item class="px-0"
        v-for="item in items"
        :key="item.title">
      <div class="checklist-item">
        <div class="checklist-info">
          <h5 class="checklist-title mb-0">{{item.title}}</h5>
        </div>
        <div>
          <b-form-checkbox v-model="item.done" :class="`custom-checkbox-${item.checkType}`" />
        </div>
      </div>
    </b-list-group-item>
  </b-list-group>
</template>
<script>
export default {
  data() {
    return {
      items: [
        {
          title: 'C & I Project',
          description: '10:30 AM',
          done: false,
          checkType: 'success'
        },
        {
          title: 'EPC Project',
          description: '10:30 AM',
          done: false,
          checkType: 'success'
        },
        {
          title: 'SN2 Project',
          description: '10:30 AM',
          done: false,
          checkType: 'success'
        },
        {
          title: 'SN3 Project',
          description: '10:30 AM',
          done: true,
          checkType: 'success'
        }
      ]
    };
  }
};
</script>
