import { Dataset } from 'vue-dataset';

export default {
  extends: Dataset,
  data() {
    return {
      // Provide the translated texts here
      datasetI18n: {
        show: 'Show',
        entries: 'entries',
        previous: '<',
        next: '>',
        showing: 'Showing',
        showingTo: 'to',
        showingOf: 'of',
        showingEntries: 'entries'
      }
    }
  }
}