<template>
  <b-list-group flush class="my--3">
    <b-list-group-item class="px-0"
        v-for="user in userx"
        :key="user.id">
      <b-row align-v="center">
        <b-col cols="auto">
          <!-- Avatar -->
        </b-col>
        <b-col class="ml--2">
          <h4 class="mb-0">
            {{user.role_name}} - {{user.name}}
          </h4>
          <span :class="`text-success`" v-if="user.active_status = 'active'">● </span>
          <span :class="`text-danger`" v-else>● </span>
          <span class="text-uppercase">{{user.active_status}}</span>
        </b-col>
      </b-row>
    </b-list-group-item>
  </b-list-group>
</template>
<script>
import { BaseProgress } from '@/components';
import {LoginURL, clientId, clientSecret, apiDomain, getHeader, getcurrentDate} from '@/assets/js/config.js'
import axios from 'axios'
import ErrorFunctionMixin from '@/assets/js/errorFunctionMix.js'

export default {
  mixins: [ErrorFunctionMixin],
  name: 'user-list',
  components: {
    BaseProgress
  },
  created(){
    this.project_id = this.$route.params.id

    axios.get(apiDomain + 'lookup/user_list.team_member/' + this.project_id, { headers: getHeader() })
    .then ( response => {
      if (response.status === 200)
      {
          console.log(response.data.data)
          this.userx = response.data.data
      }
    })
    .catch ( error => {
      this.ErrorFunction(error, "Project Team Member")
    } );
  },
  data() {
    return {
      project_id: null,
      users: [
        {
          id: 1,
          img: 'img/theme/team-1.jpg',
          name: 'Project Manager - Andrew Ha',
          status: 'Active',
          statusType: 'success'
        },
        {
          id: 2,
          img: 'img/theme/team-2.jpg',
          name: 'Engineer - Alex Smith',
          status: 'Active',
          statusType: 'success'
        },
        {
          id: 3,
          img: 'img/theme/team-3.jpg',
          name: 'Safety Officer - James',
          status: 'Active',
          statusType: 'success'
        },
        {
          id: 4,
          img: 'img/theme/team-4.jpg',
          name: 'Project Engineer - Kenzo',
          status: 'Active',
          statusType: 'success'
        },
        {
          id: 4,
          img: 'img/theme/team-4.jpg',
          name: 'Project Engineer - Karen',
          status: 'Active',
          statusType: 'success'
        },

      ],
      userx: [],
    };
  }
};
</script>
<style></style>
