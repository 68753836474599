<template>
  <div>
    <base-header class="pb-6" type="">
      <b-row align-v="center" class="py-4">
        <b-col lg="12" cols="7">
          <!-- <b-col cols="7"> -->
          <h6 class="h2 d-inline-block mb-0">Site Dashboard</h6>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <b-breadcrumb class="breadcrumb-links">
              <b-breadcrumb-item
                ><router-link to="/"><i class="fas fa-home"></i></router-link
              ></b-breadcrumb-item>
              <b-breadcrumb-item>Dashboards Layout </b-breadcrumb-item>
              <b-breadcrumb-item active aria-current="page"
                >Site Dashboard</b-breadcrumb-item
              >
            </b-breadcrumb>
          </nav>
        </b-col>

        <!-- <b-col lg="6" cols="7"> </b-col> -->

        <!-- <b-col lg="6" cols="7">
          <card gradient="default" no-body="">
            <b-card-body>
              <div class="mb-2">
                <div>
                  <span class="h2 text-white"
                    >Site Name : {{ model.site_name }}
                  </span>
                </div>
                <div class="text-light mt-2 text-sm">
                  Current Site Status :
                  <span class="text-success font-weight-600">
                    {{ model.status_code }}
                  </span>
                </div>
                <div class="text-light mt-2 text-sm">
                  Project Manager :
                  <span class="text-success font-weight-600">
                    {{ model.site_leader }}
                  </span>
                </div>
              </div>
            </b-card-body>
          </card>
        </b-col> -->
        <!-- <b-col lg="12" cols="6" class="text-right">
          <base-button
            v-if="project_id != null"
            size="sm"
            type="primary"
            @click="routeToProjectDashboard()"
            >Project Dashboard</base-button
          >
          <base-button
            size="sm"
            type="primary"
            @click="newTask()"
            v-if="
              button_access.site_task_new == 'YES' &&
              myUserID.email == model.group_engineer.email
            "
            >Create New Site Task</base-button
          >

          <base-button
            size="sm"
            type="primary"
            @click="newContractor()"
            v-if="
              button_access.site_contractor_listing == 'YES' &&
              myUserID.email == model.group_engineer.email
            "
            >Site Contractor</base-button
          >

          <base-button size="sm" type="primary" @click="$router.back()"
            >Back</base-button
          >
        </b-col> -->
      </b-row>

      <!-- BACKUP START -->

      <!-- <b-row
        ><b-col
          ><card gradient="default">
            <div>
              <span class="h2 text-white"
                >Site Name/Postal Code : {{ model.site_name }}
              </span>
            </div>
            <div class="text-white mt-2">
              Project Engineer :
              <span class="text-success font-weight-600">
                {{ model.created_by.name }}
              </span>
            </div>
            <div class="text-white mt-2">
              Contractor :
              <span v-if="contractorResponseData.contractor.contractor_name" class="text-success font-weight-600">
                {{contractorResponseData.contractor.contractor_name}}
              </span>
              <span v-else class="text-success font-weight-600">
                Contractor Not Found
              </span>
            </div>
            <div class="text-white mt-2 mb-4">
              Site Progress :
              <span class="text-success font-weight-600">
                {{ model.site_progress }} %
              </span>
            </div>
            <div>
              <b-table
                sticky-header="540px"
                striped
                :items="tempSiteMilestoneResponse"
                :fields="column"
              >
                <template
                  v-for="field in editableFields"
                  v-slot:[`cell(${field.key})`]="{ item }"
                >
                  <b-input v-model="item[field.key]" :key="field.key" />
                </template>
              </b-table>
            </div>
            <div class="text-right">
              <base-button size="sm" type="primary" @click="handleSaveChanges()"
                >Save Changes</base-button
              >
            </div></card
          ></b-col
        ></b-row
      > -->

      <!-- BACKUP END -->

      <!-- EDIT START -->

      <b-row
        ><b-col
          ><card gradient="default">
            <div>
              <span class="h2 text-white"
                >Site Name/Postal Code : {{ model.site_name }}
              </span>
            </div>
            <div class="text-white mt-2">
              Project Engineer :
              <span class="text-success font-weight-600">
                {{ model.created_by.name }}
              </span>
            </div>
            <div class="text-white mt-2">
              Contractor :
              <span
                v-if="contractorResponseData.contractor.contractor_name"
                class="text-success font-weight-600"
              >
                {{ contractorResponseData.contractor.contractor_name }}
              </span>
              <span v-else class="text-success font-weight-600">
                Contractor Not Found
              </span>
            </div>
            <div class="text-white mt-2 mb-4">
              Site Progress :
              <span class="text-success font-weight-600">
                {{ model.site_progress }} %
              </span>
            </div>
            <!-- <div>{{ tempSiteMilestoneResponse }}</div> -->
            <div>
              <!-- hover -->
              <b-table
                sticky-header="540px"
                striped
                :items="tempSiteMilestoneResponse"
                :fields="column"
              >
                <template
                  v-for="field in editableFields"
                  v-slot:[`cell(${field.key})`]="{ item }"
                >
                  <base-input v-model="item[field.key]" :key="field.key">
                  <select
                  v-model="item[field.key]"
                  >
                  <option Selected value="0">Not Started</option>
                  <option Selected value="50">In Progress</option>
                  <option Selected value="100">Completed</option>
                  <!-- <option Selected value="0">Not Started (0%)</option>
                  <option Selected value="50">In Progress (50%)</option>
                  <option Selected value="100">Completed (100%)</option> -->
                  </select>
                  </base-input>
                </template>
              </b-table>
            </div>
            <div class="text-right">
              <base-button size="sm" type="primary" @click="handleSaveChanges()"
                >Save Changes</base-button
              >
            </div></card
          ></b-col
        ></b-row
      >

      <!-- <base-input
        label="Site Contractor"
        name="Site Contractor"
        v-model="model.contractor_id"
      >
        <select
          class="form-control"
          v-model="model.contractor_id"
          @change="getUserListFromProjectContractor()"
        >
          <option Selected disabled value="x">
            -- Please Select A Site To Show Contrcator --
          </option>
          <option Selected value="y">-- Assign to Internal User --</option>
          <option
            v-for="item in site_contractors"
            :key="item.contractor.contractor_id"
            v-bind:value="item.contractor.contractor_id"
          >
            {{ item.contractor.contractor_code }} -
            {{ item.contractor.contractor_name }}
          </option>
        </select>
      </base-input> -->

      <!-- EDIT END -->

      <!-- <b-row
        ><b-col
          ><div>{{ contractorResponseData }}</div>
          <div>{{ tempData }}</div></b-col
        ></b-row
      > -->

      <!-- <b-row
        ><b-col
          ><div>{{ modelArray }}</div></b-col
        ></b-row
      >

      <b-row
        ><b-col
          ><div>{{ tempSiteMilestoneResponse }}</div></b-col
        ></b-row
      > -->

      <!-- <b-row
        ><b-col
          ><div>{{ tempSiteMilestoneResponse }}</div></b-col
        ></b-row
      > -->

      <!-- <b-row>
        <b-col xl="4" md="6">
          <stats-card
            title="Completed / Total Task"
            type="gradient-red"
            :sub-title="`${model.site_completed_task} / ${model.site_total_task}`"
            icon="ni ni-pin-3"
          >
            <template slot="footer"> </template>
          </stats-card>
        </b-col>
        <b-col xl="4" md="6">
          <stats-card
            title="Turn On / Total (kWP)"
            type="gradient-orange"
            :sub-title="`${model.site_completed_capacity} / ${model.site_total_capacity} `"
            icon="ni ni-cloud-upload-96"
          >
            <template slot="footer"> </template>
          </stats-card>
        </b-col>
        <b-col xl="4" md="6">
          <stats-card
            title="Site Progress"
            type="gradient-info"
            :sub-title="`${model.site_progress} %`"
            icon="ni ni-chart-bar-32"
          >
            <template slot="footer"> </template>
          </stats-card>
        </b-col>
      </b-row> -->
    </base-header>

    <!-- <b-container fluid class="mt--6">
      <b-row>
        <b-col xl="12">
          <project-site-view-form></project-site-view-form>
        </b-col>
      </b-row>

      <b-row>
        <b-col xl="12">
          <b-card no-body>
            <b-card-header>
              <h5 class="h3 mb-0" slot="header">
                Site Contractor List for ({{ model.site_name }})
              </h5>
              <div class="text-right">
                <base-button
                  size="sm"
                  type="primary"
                  v-if="
                    button_access.site_contractor_listing == 'YES' &&
                    myUserID.email == model.group_engineer.email
                  "
                  @click="newContractor()"
                  >Site Contractor</base-button
                >
              </div>
            </b-card-header>
            <b-card-body>
              <site-contractor-table></site-contractor-table>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>

      <b-row v-if="button_access.site_task_listing == 'YES'">
        <b-col lg="12">
          <project-site-task-listing-table
            @EventTaskListUpdate="afterUpdateTaskList()"
          ></project-site-task-listing-table>
        </b-col>
      </b-row>
    </b-container> -->

    <!-- <modal :show.sync="modals.formTask" size="xl" body-classes="p-0">
      <card
        type="secondary"
        header-classes="bg-transparent pb-5"
        body-classes="px-lg-5 py-lg-5"
        class="border-0 mb-0"
      >
        <template>
          <div class="text-center text-muted mb-4">
            <h3>
              Populate Task & Milestone Template Import into Site : SN-NT -
              Tengeh Reservoir North Site <br />for ( TRTP-2398 - Tengeh
              Reservoir Tender Project )
            </h3>
          </div>
          <b-form role="form">
            <base-input label="Task Template" name="Task Template">
              <select class="form-control" v-model="formModal.task_template">
                <option value="" disabled>
                  -- Please Select A Task Template --
                </option>
                <option value="Solar Panel Project Template">
                  TS-SL-Temp : Solar Panel Project (No. of Task : 40)
                </option>
                <option value="Power Plant Project Template">
                  TS-PL-Temp :Power Plant Project (No. of Task : 120)
                </option>
              </select>
            </base-input>
            <base-input label="Task Template" name="Document Template">
              <select class="form-control">
                <option value="">
                  -- Please Select A Document Template --
                </option>
                <option value="Solar Panel Project Template">
                  DT-SL-Temp : Solar Panel Document Project Template (No. of
                  Classfication : 10)
                </option>
                <option value="Power Plant Project Template">
                  DT-PL-Temp : Power Plant Document Project Template (No. of
                  Classfication : 12)
                </option>
              </select>
            </base-input>
            <h5 class="text-danger">
              Note : <br />
              <i>
                1. Please Create a New Site before Import / Populate Task &
                Milestone into the Site (We Recommend to Import / Populate into
                a New Site).
                <br />
                2. Import / Populate Task into existing site will only create
                new task (All existing TASK will still be remain intact, But
                import twice might cause duplication).
                <br />
                3. All the Milestone will be Import / Populate TOGETHER
                according with the "Task Template".
                <br />
                4. To Change Milestone please proceed to Menu >> Template >>
                Milestone Template.
                <br />
                5. To Change Task please proceed to Menu >> Template >> Task
                Template.
                <br />
                6. Task Details in Template will not have the following column :
                <br />
                &nbsp;&nbsp;&nbsp; a) Project & Site.
                <br />
                &nbsp;&nbsp;&nbsp; b) Contractor.
                <br />
                &nbsp;&nbsp;&nbsp; c) Progress & Status.
                <br />
                &nbsp;&nbsp;&nbsp; d) Est Start , End Date & Start , End Date.
                <br />
                7. Only Column (a) & (b) can be selected during import. Please
                change column of (c) & (d) on each task later.
                <br />
                8. System will auto generate NEW TASK ID for all the TASK import
                / populate from Template. But Milestone CODE will remain the
                same.
              </i>
            </h5>
            <div class="text-center">
              <base-button
                type="primary"
                class="my-4"
                @click="notifyVueFormTask()"
                >Import Task Template</base-button
              >
              <base-button type="primary" class="my-4" @click="clearModal()"
                >Cancel</base-button
              >
            </div>
          </b-form>
        </template>
      </card>
    </modal> -->
  </div>
</template>

<script>
import RouteBreadCrumb from "@/components/Breadcrumb/RouteBreadcrumb";
import BaseHeader from "@/components/BaseHeader";
import ProjectSiteViewForm from "./ProjectSiteViewForm";

import LineChart from "@/components/Charts/LineChart";
import * as chartConfigs from "@/components/Charts/config";
import ProjectSiteTaskListingTable from "./ProjectSiteTaskListingTable";
import ProjectSiteDocumentListingTable from "./ProjectSiteDocumentListingTable";
import SiteContractorTable from "./AdditionalTable/SiteContractorTable";

import { Modal } from "@/components";
import {
  LoginURL,
  clientId,
  clientSecret,
  apiDomain,
  getHeader,
  getcurrentDate,
} from "@/assets/js/config.js";
import axios from "axios";
import ErrorFunctionMixin from "@/assets/js/errorFunctionMix.js";

export default {
  mixins: [ErrorFunctionMixin],
  components: {
    BaseHeader,
    RouteBreadCrumb,
    LineChart,
    "project-site-task-listing-table": ProjectSiteTaskListingTable,
    "site-contractor-table": SiteContractorTable,
    "project-site-document-listing-table": ProjectSiteDocumentListingTable,
    Modal,
    ProjectSiteViewForm,
  },
  created() {
    var site_task_access = JSON.parse(
      window.localStorage.getItem("site_task_access")
    );
    this.button_access.site_task_listing = site_task_access.access_listing;
    this.button_access.site_task_new = site_task_access.access_create;

    var site_document_access = JSON.parse(
      window.localStorage.getItem("site_document_access")
    );
    this.button_access.site_document_listing =
      site_document_access.access_listing;
    this.button_access.site_document_new = site_document_access.access_create;

    var site_contractor_access = JSON.parse(
      window.localStorage.getItem("site_contractor_access")
    );
    this.button_access.site_contractor_listing =
      site_contractor_access.access_listing;

    const LoginUser = JSON.parse(window.localStorage.getItem("loginUser"));
    this.myUserID = LoginUser;

    this.model.site_id = this.$route.params.id;

    axios
      .get(
        apiDomain +
          "project/site_contractor.show_by_site/" +
          this.model.site_id,
        { headers: getHeader() }
      )
      .then((response) => {
        if (response.status === 200) {
          this.contractorResponseData = response.data.data[0];
          // console.log(response.data.data)
        }
      })
      .catch((error) => {
        console.log(error.response.status);
      });

    this.getProjectId();

    // this.getdashboardData();
    this.getdashboardDataShort();
    this.getDataListing();
  },
  data() {
    return {
      project_id: null,
      button_access: {
        site_task_listing: null,
        site_task_new: null,
        site_document_listing: null,
        site_document_new: null,
        site_contractor_listing: null,
      },
      myUserID: null,
      model: {
        site_id: null,
        site_name: null,
        site_name: null,
        status_code: null,
        site_leader: null,
        site_total_installation: 0,
        site_total_task: 0,
        site_completed_task: 0,
        site_total_document: 0,
        site_completed_document: 0,
        site_progress: 0,
        site_total_capacity: 0,
        site_completed_capacity: 0,
        group_engineer: {
          name: null,
          email: null,
        },
        project_manager: null,
        site_contractor: null,
        created_by: null,
      },
      modals: {
        formTask: false,
        formMilestone: false,
      },

      formModal: {
        task_template: "",
        task_site: "",
        milestone_template: "",
      },
      bigLineChart: {
        allData: [
          [0, 20, 10, 30, 15, 40, 20, 60, 60],
          [0, 20, 5, 25, 10, 30, 15, 40, 40],
        ],
        activeIndex: 0,
        chartData: {
          datasets: [
            {
              label: "Performance",
              data: [
                0, 100, 250, 500, 1000, 1500, 2000, 2500, 2900, 3200, 3300,
                3300,
              ],
            },
          ],
          labels: [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec",
          ],
        },
        extraOptions: chartConfigs.blueChartOptions,
      },
      tempData: null,
      // isSolarNovaProject: false,
      tempSiteMilestoneResponse: null,
      column: [
        // {
        //   key: "site_milestone_sequence",
        //   sortable: true,
        //   label: "Sequence",
        // },
        {
          key: "site_milestone_code",
          sortable: true,
          label: "Milestones",
          tdClass: "text-white",
        },
        // {
        //   key: "site_milestone_weightage",
        //   sortable: true,
        //   label: "Weightage",
        // },
        {
          key: "site_milestone_progress",
          sortable: true,
          editable: true,
          label: "Progress (%)",
          tdClass: "text-white",
        },
      ],
      modelArray: [],
      // completedSiteProgress: null,
      contractorResponseData: null,
      tempProgress: null,
    };
  },
  computed: {
    editableFields() {
      return this.column.filter((field) => field.editable);
    },
  },
  methods: {
    routeToProjectDashboard() {
      this.$router.push({ path: "/projectDashboard/" + this.project_id });
    },
    // // Get Project Type ID and Check if Solar Nova Project
    // getProjectTypeCheckSolar() {
    //   var project_type_id = null;
    //   axios
    //     .get(apiDomain + "project/project/" + this.project_id, {
    //       headers: getHeader(),
    //     })
    //     .then((response) => {
    //       if (response.status === 200) {
    //         project_type_id =
    //           response.data.data[0].project_type.project_type_id;
    //         if (
    //           project_type_id != null &&
    //           (project_type_id == 2 ||
    //             project_type_id == 3 ||
    //             project_type_id == 4)
    //         ) {
    //           this.isSolarNovaProject = true;
    //           this.$router.push({ path: "/projectSiteDashboardSN/" + this.$route.params.id });
    //         }
    //         else {
    //           this.getdashboardData();
    //         }
    //       }
    //     })
    //     .catch((error) => {
    //       console.log(error.response.status);
    //     });
    // },
    getProjectId() {
      axios
        .get(
          apiDomain + "project/project_site_short/" + this.$route.params.id,
          {
            headers: getHeader(),
          }
        )
        .then((response) => {
          if (response.status === 200) {
            this.project_id = response.data.data[0].project.project_id;
            this.getProjectTypeCheckSolar();
          }
        })
        .catch((error) => {
          console.log(error.response.status);
          this.errorFunction(error, "Project Site Information");
        });
    },
    afterUpdateTaskList() {
      this.getdashboardData();
    },
    getdashboardData() {
      this.model.site_id = this.$route.params.id;
      axios
        .get(apiDomain + "project/project_site/" + this.model.site_id, {
          headers: getHeader(),
        })
        .then((response) => {
          if (response.status === 200) {
            this.model.site_name = response.data.data[0].site_name;
            this.model.status_code = response.data.data[0].status.status_code;
            this.model.site_leader =
              response.data.data[0].group.group_engineer.name;
            this.model.site_total_installation =
              response.data.data[0].site_total_installation;
            this.model.site_total_task = response.data.data[0].site_total_task;
            this.model.site_total_document =
              response.data.data[0].site_total_document;
            this.model.site_progress = response.data.data[0].site_progress;
            this.model.site_completed_capacity =
              response.data.data[0].site_completed_capacity;
            this.model.site_total_capacity =
              response.data.data[0].site_total_capacity;
            this.model.site_completed_task =
              response.data.data[0].site_completed_task;
            this.model.site_completed_document =
              response.data.data[0].site_completed_document;
            this.model.group_engineer.email =
              response.data.data[0].group.group_engineer.email;
          }
        })
        .catch((error) => {
          console.log(error.response.status);
          this.errorFunction(error, "Site Information");
        });
    },
    // calculateCompletedSiteProgress(){

    // },
    getdashboardDataShort() {
      this.model.site_id = this.$route.params.id;
      axios
        .get(apiDomain + "project/project_site_short/" + this.model.site_id, {
          headers: getHeader(),
        })
        .then((response) => {
          if (response.status === 200) {
            this.tempData = response.data.data;
            this.model.site_name = response.data.data[0].site_name;
            this.model.status_code = response.data.data[0].status.status_code;
            this.model.site_leader =
              response.data.data[0].group.group_engineer.name;
            this.model.site_total_installation =
              response.data.data[0].site_total_installation;
            this.model.site_total_task = response.data.data[0].site_total_task;
            this.model.site_total_document =
              response.data.data[0].site_total_document;
            this.model.site_progress = response.data.data[0].site_progress;
            this.model.site_completed_capacity =
              response.data.data[0].site_completed_capacity;
            this.model.site_total_capacity =
              response.data.data[0].site_total_capacity;
            this.model.site_completed_task =
              response.data.data[0].site_completed_task;
            this.model.site_completed_document =
              response.data.data[0].site_completed_document;
            this.model.group_engineer.email =
              response.data.data[0].group.group_engineer.email;
            this.model.project_manager =
              response.data.data[0].project.project_manager.name;
            this.model.created_by = response.data.data[0].created_by;
          }
        })
        .catch((error) => {
          console.log(error.response.status);
          this.errorFunction(error, "Site Information");
        });
    },
    getDataListing() {
      axios
        .get(
          apiDomain +
            "project/project_site_milestone.show_by_site/" +
            this.model.site_id,
          { headers: getHeader() }
        )
        .then((response) => {
          if (response.status === 200) {
            this.tempSiteMilestoneResponse = response.data.data;
            this.updateSiteIncompletePieChart();
          }
        })
        .catch((error) => {
          console.log(error.response.status);
        });
    },
    handleSaveChanges() {
      var flag = true;
      this.tempSiteMilestoneResponse.forEach((e) => {
        if (
          e["site_milestone_progress"] < 0 ||
          e["site_milestone_progress"] > 100
        ) {
          flag = false;
        }
      });
      if (flag) {
        this.modelArray = [];
        this.tempSiteMilestoneResponse.forEach((e) => {
          var tempModel = {
            site_milestone_id: e["site_milestone_id"],
            site_id: e["site_id"],
            site_milestone_code: e["site_milestone_code"],
            site_milestone_progress: e["site_milestone_progress"],
          };

          axios
            .put(
              apiDomain +
                "project/project_site_milestone/" +
                e["site_milestone_id"],
              tempModel,
              { headers: getHeader() }
            )
            .then((response) => {
              if (response.status === 200) {
                this.$notify({
                  message:
                    "<b>Update Site Milestone Progress : " +
                    e["site_milestone_code"] +
                    "</b> - Successfully Updated.",
                  timeout: 10000,
                  icon: "ni ni-bell-55",
                  type: "default",
                });
                // this.$router.back();
                this.getdashboardDataShort();
              }
            })
            .catch((error) => {
              console.log(error.response.data.errors);
              this.errorFunction(error, "Update Site Milestone Progress");
            });

          this.modelArray.push(tempModel);
        });
      } else {
        this.redErrorNotify(
          "<b>Invalid Input : Progress </b> - Input Must be between 0 - 100"
        );
      }

      // this.tempSiteMilestoneResponse.forEach((e) => {
      //       tempLabelsArray.push(e["site_milestone_code"]);
      //       var tempCapacity = this.convertToKwpIncomplete(e["site_milestone_weightage"], e["site_milestone_progress"]);
      //       tempSeriesArray.push(tempCapacity);
      //   });
    },
    updateMilestoneProgress() {
      // CHANGE THIS ACCORDING TO PHP API
      axios
        .put(
          apiDomain +
            "project/project_site_milestone/" +
            this.model.milestone_template_detail_id,
          this.model,
          { headers: getHeader() }
        )
        .then((response) => {
          if (response.status === 200) {
            this.$notify({
              message:
                "<b>Update Milestone Template Details : " +
                this.model.milestone_template_title +
                "</b> - Successfully Updated.",
              timeout: 10000,
              icon: "ni ni-bell-55",
              type: "default",
            });
            this.$router.back();
          }
        })
        .catch((error) => {
          console.log(error.response.data.errors);
          this.errorFunction(error, "Update Milestone Template Details");
        });
    },
    newSiteDocument() {
      this.$router.push({
        path: "/siteDocumentNewFrSite/" + this.model.site_id,
      });
    },
    newTask() {
      this.$router.push({ path: "/taskNewFrSite/" + this.model.site_id });
    },
    newImport() {
      this.$router.push({ path: "/importNewFrSite/" + this.model.site_id });
    },
    newContractor() {
      this.$router.push({
        path: "/siteContractorListing/" + this.model.site_id,
      });
    },
    notready() {
      this.$notify({
        message:
          "<b>Under Developer : </b> - New Task, Site Contractor & Populate / Import From Template function is not ready.",
        timeout: 10000,
        icon: "ni ni-bulb-61",
        type: "danger",
      });
    },
    notifyVueFormTask() {
      if (this.formModal.task_template === "") {
        this.$notify({
          message:
            "<b>Task Template Input Error : </b> - Please Select A Task Template & Target Site OR press <b>CANCEL</b> to <b>EXIT</b>.",
          timeout: 10000,
          icon: "ni ni-bulb-61",
          type: "danger",
        });
      } else {
        this.$notify({
          message:
            "<b>Task Template (" +
            this.formModal.task_template +
            ") : </b> - Successfully Imported into ( TRTP-2398 - Tengeh Reservoir Tender Project )",
          timeout: 10000,
          icon: "ni ni-bell-55",
          type: "default",
        });
        this.clearModal();
      }
    },
    notifyVueFormMilestone() {
      if (this.formModal.milestone_template === "") {
        this.$notify({
          message:
            "<b>Milestone Template Input Error : </b> - Please Select A Milestone Template Or Cancel to Exit.",
          timeout: 10000,
          icon: "ni ni-bulb-61",
          type: "danger",
        });
      } else {
        this.$notify({
          message:
            "<b>Milestone Template (" +
            this.formModal.milestone_template +
            ") : </b> - Successfully Imported into ( TRTP-2398 - Tengeh Reservoir Tender Project )",
          timeout: 10000,
          icon: "ni ni-bell-55",
          type: "default",
        });
        this.clearModal();
      }
    },
    clearModal() {
      this.modals.formTask = false;
      this.modals.formMilestone = false;
      this.formModal.task_template = "";
      this.formModal.task_site = "";
      this.formModal.milestone_template = "";
    },
  },
};
</script>
<style></style>
