<template>
  <card>
    <!-- Card header -->
    <h3 slot="header" class="mb-0">Edit Project Task for ({{ model.project_name }})</h3>
    <div class="text-right">
    </div>
    <form class="needs-validation"
          @submit.prevent="submit">

      <div class="form-row">
        <b-col md="8">
          <base-input label="Task Title *"
                      name="Task Title"
                      required
                      placeholder="Task Title"
                      v-model="model.task_title">
          </base-input>
        </b-col>

        <b-col md="3">
          <base-input label="Project *"
                      name="Project"
                      required
                      v-model="model.project_id">
            <select class="form-control" v-model="model.project_id" disabled>
              <option Selected disabled value="x">-- Please Select A Project --</option>
              <option v-for="item in projects" :key="item.project_id" v-bind:value="item.project_id"> {{ item.project_name }}</option>
            </select>
          </base-input>
        </b-col>

        <b-col md="1">
          <br>
          <base-button size="sm" type="primary" @click="checkProject()">Project Infomation</base-button>
        </b-col>
      </div>

      <div class="form-row">
        <b-col md="4">
          <base-input label="Assign To *"
                      name="Assign to"
                      required
                      v-model="model.assign_to_user">
            <select class="form-control" v-model="model.assign_to_user" >
              <option Selected disabled value="x">-- Please Assign The Task To --</option>
              <option v-for="item in user_list" :key="item.id" v-bind:value="item.id">{{ item.role_name }} - {{ item.name }}</option>
            </select>
          </base-input>
        </b-col>

        <b-col md="1">
          <br>
          <base-button size="sm" type="primary" @click="assignToMe()">Assign To Me (Default)</base-button>
        </b-col>

        <b-col md="4">
          <base-input label="Task Status *"
                      name="Task Status"
                      v-model="model.status_id">
            <select class="form-control" v-model="model.status_id">
              <option Selected disabled value="x">-- Please Select Task Status --</option>
              <option v-for="item in task_status" :key="item.status_id" v-bind:value="item.status_id" >{{ item.status_code }}</option>
            </select>
          </base-input>
        </b-col>

        <b-col md="3">
          <base-input label="Task Progress (0 - 100) % *"
                      name="Task Progress"
                      required
                      placeholder="Task Progress"
                      v-model="model.task_progress" type="number" min="0" max="100">
          </base-input>
        </b-col>
      </div>

      <div class="form-row">
        <b-col md="3">
          <base-input label="Est Start Date *"
                      name="Est Start Date"
                      required
                      type="date"
                      v-model="model.task_est_start_date">
          </base-input>
        </b-col>

        <b-col md="3">
          <base-input label="Est End Date *"
                      name="Est End Date"
                      required
                      type="date"
                      v-model="model.task_est_end_date">
          </base-input>
        </b-col>

        <b-col md="3">
          <base-input label="Start Date"
                      name="Start Date"
                      type="date"
                      v-model="model.task_start_date">
          </base-input>
        </b-col>

        <b-col md="3">
          <base-input label="End Date"
                      name="End Date"
                      type="date"
                      v-model="model.task_end_date">
          </base-input>
        </b-col>
      </div>

      <div class="form-row">
        <b-col md="12">
          <base-input label="Description"
                      name="Description"
                      placeholder="Description"
                      v-model="model.task_description">
          </base-input>
        </b-col>

        <b-col md="12">
          <base-input label="Remarks"
                      name="Remarks"
                      placeholder="Remarks"
                      v-model="model.task_remarks">
          </base-input>
        </b-col>
      </div>

      <div class="form-row" v-if="((model.status_id == 3) || model.status_id == 4) && myUserID.id == model.assign_to_user">
        <b-col md="8">
            <label>
              <b>Upload Attachment : </b> <br><br><input type="file" id="file" ref="file" v-on:change="handleFileUpload()"/>
            </label>
        </b-col>
      </div>
      <br>
      <div class="form-row" v-if="show_download == 'YES'">
        <b-col md="8">
            <label>
              <b>Attachment : </b> &nbsp;<base-button type="primary" @click="donwloadFile()">Download File</base-button>
            </label>
        </b-col>
      </div>
      <br>
      <base-button type="primary" @click="createData()" v-if="button_access.project_task_edit == 'YES'">Update / Save</base-button>
      <base-button type="primary" @click="closeWindow()">Cancel / Close</base-button>
      <div>
        <br>
        <h5 class="text-danger"> Note : <br>
                <i>
                1. By Default Est Start & End Date is set to "TODAY.
                </i>
                <br>
                <i>
                2. Task Progress % is set to "0 %" By Default.
                </i>
                <br>
                <i>
                3. All Column with * are Mandatory to fill in.
                </i>
                <br>
                <i>
                4. Est End Date must be Greater or same as Est Start Date. Same with End Date and Start Date.
                </i>
                <br>
                <i>
                5. Task Title, Project, Assign To, Task Status, Task Progress, Est Start Date, Est End Date are Mandatory columns.
                </i>
        </h5>
      </div>
    </form>
  </card>
</template>
<script>
  import BaseSlider from '@/components/BaseSlider'
  import {LoginURL, clientId, clientSecret, apiDomain, getHeader, getcurrentDate, fileServer} from '@/assets/js/config.js'
  import axios from 'axios'
  import ErrorFunctionMixin from '@/assets/js/errorFunctionMix.js'

  export default {
    mixins: [ErrorFunctionMixin],
    components: {
      BaseSlider
    },

    created()
    {
        var currentDate = getcurrentDate()
        const LoginUser = JSON.parse(window.localStorage.getItem('loginUser'))
        this.myUserID = LoginUser
        this.assignToMe()

        this.model.task_est_start_date = currentDate
        this.model.task_est_end_date = currentDate
        // this.model.task_start_date = currentDate
        // this.model.task_end_date = currentDate

        var project_task_access = JSON.parse(window.localStorage.getItem('project_task_access'))
        this.button_access.project_task_edit = project_task_access.access_edit

        this.model.task_id = this.$route.params.id

        axios.get(apiDomain + 'task/project_task/' + this.model.task_id, { headers: getHeader() })
        .then ( response => {
          if (response.status === 200)
          {
            this.model.project_id = response.data.data[0].project.project_id
            this.model.project_name = response.data.data[0].project.project_name
            this.model.task_title = response.data.data[0].task_title
            this.model.assign_to_user = response.data.data[0].assign_to_user.id
            this.model.status_id = response.data.data[0].status.status_id
            if (response.data.data[0].upload_attachment != null)
            {
              this.show_attachment = response.data.data[0].upload_attachment
              this.show_download = 'YES'
            }
            //console.log(response.data.data[0].project.project_manager.id+'-'+this.model.assign_to_user+'-'+LoginUser.id)
            if ( ( response.data.data[0].project.project_manager.id == LoginUser.id ) || (this.model.assign_to_user == LoginUser.id)  )
            {
              this.button_access.project_task_edit = 'YES'
            }
            else
            {
              this.button_access.project_task_edit = 'NO'
            }
            if ( this.model.status_id == '4')
            {
              this.button_access.project_task_edit = 'NO'
            }
            if (( LoginUser.role_id == '9' || LoginUser.role_id == '1' ) && (this.model.status_id == '3' || this.model.status_id == '4'))
            {
              this.getTaskStatus()
            }
            else if ((LoginUser.role_id == '9') && (LoginUser.id == this.model.assign_to_user))
            {
              this.getTaskStatus()
            }
            else
            {
              this.getTaskStatusNormal()
            }
            this.model.task_progress = response.data.data[0].task_progress
            this.model.task_est_start_date = response.data.data[0].task_est_start_date
            this.model.task_est_end_date = response.data.data[0].task_est_end_date
            this.model.task_start_date = response.data.data[0].task_start_date
            this.model.task_end_date = response.data.data[0].task_end_date
            this.model.task_description = response.data.data[0].task_description
            this.model.task_remarks = response.data.data[0].task_remarks

            this.getProject()

            this.getAssignToUser()
          }
        })
        .catch ( error => {
          console.log(error.response.status)
          this.errorFunction(error, "Project Information")
        } );

    },
    data() {
      return {
        model: {
          task_id: null,
          task_title: null,
          project_id: 'x',
          project_name: null,
          assign_to_user: 'x',
          task_description: null,
          task_remarks: null,
          task_progress: 0,
          task_est_start_date: null,
          task_est_end_date: null,
          task_start_date: null,
          task_end_date: null,
          status_id: 'x',
          upload_attachment: null,
        },
        show_attachment: null,
        show_download: 'NO',
        myUserID : null,
        projects: [],
        project_groups: [],
        project_sites: [],
        project_milestones: [],
        task_status: [],
        site_contractors: [],
        user_list: [],
        button_access: {
          project_task_edit: null,
        },
      }
    },
    methods: {
      donwloadFile()
      {
        axios.get(fileServer + 'task/project_task.download/' + this.model.task_id, { headers: getHeader(),  responseType: 'blob'})
        .then((response) => {
          var fileURL = window.URL.createObjectURL(new Blob([response.data]));
          var fileLink = document.createElement('a');

          fileLink.href = fileURL;
          fileLink.setAttribute('download', this.show_attachment);
          document.body.appendChild(fileLink);

          fileLink.click();
        });
      },
      handleFileUpload()
      {
         this.model.upload_attachment = this.$refs.file.files[0];
      },
      updatechange()
      {
          console.log(this.model.assign_to_user)
      },
      assignToMe()
      {

          this.model.assign_to_user = this.myUserID.id
      },
      getAssignToUser()
      {
        // This is not a complete listing. After we settle logic get list of user for each project tested properly will come back and change this.
        axios.get(apiDomain + 'lookup/user_list', { headers: getHeader() })
        .then ( response => {
          if (response.status === 200)
          {
            this.user_list = response.data.data
          }
        })
        .catch ( error => {
          console.log(error.response.status)
          this.errorFunction(error, "Project User Listing")
        });
      },
      getProject()
      {
        axios.get(apiDomain + 'project/project', { headers: getHeader() })
        .then ( response => {
          if (response.status === 200)
          {
            this.projects = response.data.data
          }
        })
        .catch ( error => {
          console.log(error.response.status)
          this.errorFunction(error, "Project Information")
        });
      },
      getTaskStatus()
      {
        axios.get(apiDomain + 'lookup/task_status', { headers: getHeader() })
        .then ( response => {
          if (response.status === 200)
          {
            this.task_status = response.data.data
          }
        })
        .catch ( error => {
          console.log(error.response.status)
          this.errorFunction(error, "Task Status Information")
        } );
      },
      getTaskStatusNormal()
      {
        axios.get(apiDomain + 'lookup/task_status.normal', { headers: getHeader() })
        .then ( response => {
          if (response.status === 200)
          {
            this.task_status = response.data.data
          }
        })
        .catch ( error => {
          console.log(error.response.status)
          this.errorFunction(error, "Task Status Information")
        } );
      },
      getGroupFromProject()
      {
        axios.get(apiDomain + 'project/project_group.show_by_project/' + this.model.project_id , { headers: getHeader() })
        .then ( response => {
          if (response.status === 200)
          {
            this.project_groups = response.data.data
            this.model.group_id = 'y'
          }
        })
        .catch ( error => {
          console.log(error.response.status)
          this.errorFunction(error, "Project Group Information")
        } );

        axios.get(apiDomain + 'project/project_milestone.show_by_project/' + this.model.project_id , { headers: getHeader() })
        .then ( response => {
          if (response.status === 200)
          {
            this.project_milestones = response.data.data
            this.model.milestone_id = 'y'
          }
        })
        .catch ( error => {
          console.log(error.response.status)
          this.errorFunction(error, "Project Milestone Information")
        } );
      },
      getSiteFromGroup()
      {
        axios.get(apiDomain + 'project/project_site.show_by_group/' + this.model.group_id , { headers: getHeader() })
        .then ( response => {
          if (response.status === 200)
          {
            this.project_sites = response.data.data
            this.model.site_id = 'y'
          }
        })
        .catch ( error => {
          console.log(error.response.status)
          this.errorFunction(error, "Project Site Information")
        } );
      },
      getContractorFromSite()
      {
        axios.get(apiDomain + 'project/site_contractor.show_by_site/' + this.model.site_id , { headers: getHeader() })
        .then ( response => {
          if (response.status === 200)
          {
            this.site_contractors = response.data.data
            this.model.contractor_id = 'y'

          }
        })
        .catch ( error => {
          console.log(error.response.status)
          this.errorFunction(error, "Site Contractor Information")
        } );
      },
      checkProject()
      {
        if ( this.model.project_id == 'x' || this.model.project_id == 'y' )
        {
          this.redErrorNotify('<b>Invalid Input : Project </b> - Please select a Project before dive into the Details')
        }
        else
        {
          this.$router.push({path: '/projectEdit/'+this.model.project_id })
        }
      },
      checkContrator()
      {
        if ( this.model.contractor_id == 'x' || this.model.contractor_id == 'y' )
        {
          this.redErrorNotify('<b>Invalid Input : Contractor </b> - Please select a Contractor before dive into the Details')
        }
        else
        {
          this.$router.push({path: '/contractorEdit/'+this.model.contractor_id })
        }
      },
      createData()
      {
        if ( !this.model.task_title ||  this.model.status_id == 'x' ||  this.model.status_id == 'y')
        {
          if ( !this.model.task_title )
          {
            this.redErrorNotify( '<b>Invalid Input : Task Title is a Mandatory in Project Task </b>')
          }
          else if (this.model.status_id == 'x' ||  this.model.status_id == 'y')
          {
            this.redErrorNotify( '<b>Invalid Input : Task Status is a Mandatory in Project Task. Please Select A Status </b>' )
          }
          else
          {
            this.redErrorNotify( '<b>Invalid Input : Please fill in all the Mandatory Column in Project Task </b>' )
          }
        }
        else if( this.model.task_end_date < this.model.task_start_date || this.model.task_est_end_date < this.model.task_est_start_date )
        {
          this.redErrorNotify('<b>Invalid Input : End Date </b> - All End Date must be equal or greater than start date.')
        }
        else if( this.model.task_progress < 0 || this.model.task_progress > 100 )
        {
          this.redErrorNotify( '<b>Invalid Input : Task Progress </b> - Input Must be between 0 - 100' )
        }
        else if ( (this.model.status_id == '3' || this.model.status_id == '4') && this.model.task_progress < 100 )
        {
          this.redErrorNotify( '<b>Invalid Input : Task Progress </b> - For COMPLETED / APPROVED, Progress must be 100%' )
        }
        else if ((this.model.status_id == '3' || this.model.status_id == '4') && this.model.task_start_date  == null)
        {
         this.redErrorNotify( '<b>Invalid Input : Start Date </b> - For COMPLETED / APPROVED, Task Start Date is mandatory.' )
        }
        else if ((this.model.status_id == '3' || this.model.status_id == '4') && this.model.task_end_date  == null )
        {
         this.redErrorNotify( '<b>Invalid Input : End Date </b> - For COMPLETED / APPROVED, Task End Date is mandatory.' )
        }
        else
        {
          const formData = new FormData()

          formData.append('task_id', this.model.task_id);
          formData.append('task_title', this.model.task_title);
          formData.append('project_id', this.model.project_id);
          formData.append('project_name', this.model.project_name);

          formData.append('assign_to_user', this.model.assign_to_user);
          if (this.model.task_description != null)
          {
            formData.append('task_description', this.model.task_description);
          }
          
          if (this.model.task_remarks)
          {
             formData.append('task_remarks', this.model.task_remarks);
          }

          formData.append('task_progress', this.model.task_progress);

          formData.append('task_est_start_date', this.model.task_est_start_date);
          formData.append('task_est_end_date', this.model.task_est_end_date);
          if (this.model.task_start_date != null)
          {
            formData.append('task_start_date', this.model.task_start_date);
          }
          
          if (this.model.task_end_date != null)
          {
             formData.append('task_end_date', this.model.task_end_date);
          }

          formData.append('status_id', this.model.status_id);

          if (this.model.upload_attachment != null)
          {
            formData.append('upload_attachment', this.model.upload_attachment);
          }
          
          const tokenData = JSON.parse(window.localStorage.getItem('authUser'))
          const putHeader = { 'Accept' : 'application/json', 'Content-Type': 'multipart/form-data',   'Authorization' : 'Bearer ' + tokenData.access_token, };

          axios.post(apiDomain + 'task/project_task.update/' + this.model.task_id, formData, { headers : putHeader })
          .then ( response => {
            if (response.status === 200)
            {
                this.$notify({
                message:
                  '<b>Update Task : ' + this.model.task_title + '</b> - Successfully Updated.',
                timeout: 10000,
                icon: 'ni ni-bell-55',
                type: 'default',
                });
                this.$router.back()
            }
          })
          .catch ( error => {
              console.log(error.response.data.errors)
              this.errorFunction(error, "Update Project Task Information")
          });
        }
      },
    }
  }
</script>
<style>
</style>
