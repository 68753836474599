<template>
  <card>
    <h3 slot="header" class="mb-0">New Project Document Template</h3>
    <div class="text-right">
    </div>
    <form class="needs-validation"
          @submit.prevent="submit">

      <div class="form-row">
        <b-col md="12">
          <base-input label="Template Name"
                      name="Template Name"
                      placeholder="Template Name"
                      v-model="model.document_template_name" required>
          </base-input>
        </b-col>
      </div>

      <base-button type="primary" @click="createData()">Create / Save</base-button>
      <base-button type="primary" @click="$router.back()">Cancel</base-button>
      <!-- <div>
        <br>
        <h5 class="text-danger"> Note : <br>
                <i>
                1. To Create New / Update Milestone please proceed to Menu >> Template >> Milestone Template OR "Click" on "Milestone Details"
                <br>
                2. Your cannot swtich / change the Milestone Template anymore once you created your 1st Document Template Detail / Classification. But you can still Create/Update the Milestone Template.
                <br>
                3. All Milestone Template Details will follow according to SELECTED Milestone Template.
                <br>
                4. SET Milestone Template DROPBOX will be disabled once a Document Template Detail / Classification is Created, To check the detail MENU >> Template >> Document Template >> Detail Listing.
                </i>
        </h5>
      </div> -->
    </form>
  </card>
</template>
<script>
  import BaseSlider from '@/components/BaseSlider'
  import {LoginURL, clientId, clientSecret, apiDomain, getHeader} from '@/assets/js/config.js'
  import axios from 'axios'
  import ErrorFunctionMixin from '@/assets/js/errorFunctionMix.js'

  export default {
    mixins: [ErrorFunctionMixin],
    components: {
      BaseSlider
    },
    created()
    {

    },
    data() {
      return {
        model: {
          document_template_name: null,
        },
      }
    },
    methods: {
      createData(){
        if ( !this.model.document_template_name )
        {
          this.$notify({
            message:
              '<b>Invalid Input : All Column are Mandatory in Project Document Template</b> - These field are required.',
            timeout: 10000,
            icon: 'ni ni-bulb-61',
            type: 'danger',
          });
        }
        else
        {
          axios.post(apiDomain + 'template/project_document_template', this.model, { headers: getHeader() })
          .then ( response => {
            if (response.status === 200)
            {
                this.$notify({
                message:
                  '<b>New Project Document Template : ' + this.model.document_template_name + '</b> - Successfully Created.',
                timeout: 10000,
                icon: 'ni ni-bell-55',
                type: 'default',
                });
                this.$router.back()
            }
          })
          .catch ( error => {
            console.log(error.response.data.errors)
            this.errorFunction(error, "Create New Project Document Template", this.model.document_template_name)
          });
        }
      },
    }
  }
</script>
<style>
</style>
