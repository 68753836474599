const dataProposeLayout = [
  {
    desc: "proposeSolarLayout",
    coord: [
      { lat: -2.822552739890628, lng: 122.0867432121615 },
      { lat: -2.822362310835779, lng: 122.0856041221343 },
      { lat: -2.822773248515726, lng: 122.0850140875967 },
      { lat: -2.82370337298395, lng: 122.0842489309904 },
      { lat: -2.82486666268451, lng: 122.0838161174511 },
      { lat: -2.826883952952243, lng: 122.0839036744312 },
      { lat: -2.827030664484873, lng: 122.0833241214557 },
      { lat: -2.827457490472653, lng: 122.0821247810059 },
      { lat: -2.828018014104696, lng: 122.0810863980957 },
      { lat: -2.829021834936924, lng: 122.0803122233434 },
      { lat: -2.83169858333332, lng: 122.079932388852 },
      { lat: -2.832304471375188, lng: 122.0790465512444 },
      { lat: -2.833039677904773, lng: 122.0782910154967 },
      { lat: -2.833961056971653, lng: 122.0780190337071 },
      { lat: -2.834429262228067, lng: 122.0777879048788 },
      { lat: -2.835690250301321, lng: 122.078030851966 },
      { lat: -2.836189171066139, lng: 122.0775067974488 },
      { lat: -2.836777130587225, lng: 122.0772319015545 },
      { lat: -2.837015462818346, lng: 122.0759401558097 },
      { lat: -2.837687107685348, lng: 122.0751820645203 },
      { lat: -2.837744092875642, lng: 122.0738393728193 },
      { lat: -2.838802741216331, lng: 122.0733694139123 },
      { lat: -2.83916067019346, lng: 122.0725773068909 },
      { lat: -2.839362715143741, lng: 122.0718111276066 },
      { lat: -2.839890350353913, lng: 122.0716251012124 },
      { lat: -2.840220874090511, lng: 122.0713161709522 },
      { lat: -2.840809492479471, lng: 122.0711623170773 },
      { lat: -2.841602213699736, lng: 122.0699993883306 },
      { lat: -2.841951559982776, lng: 122.0684637452693 },
      { lat: -2.843454895995181, lng: 122.0651404403449 },
      { lat: -2.843578302394707, lng: 122.0645197809518 },
      { lat: -2.844045127796437, lng: 122.0643443894422 },
      { lat: -2.84425476450724, lng: 122.0630759825917 },
      { lat: -2.844275590619958, lng: 122.062526735623 },
      { lat: -2.846440161608713, lng: 122.0616977780876 },
      { lat: -2.84672974399836, lng: 122.0617239045561 },
      { lat: -2.847761430628802, lng: 122.0626229159163 },
      { lat: -2.848761953368314, lng: 122.0635937799706 },
      { lat: -2.848787290180395, lng: 122.0647047422773 },
      { lat: -2.849650377373347, lng: 122.0656306376299 },
      { lat: -2.849656519617781, lng: 122.0667699200202 },
      { lat: -2.850249874702149, lng: 122.0672701063642 },
      { lat: -2.850281545540704, lng: 122.0690692935104 },
      { lat: -2.850930701180622, lng: 122.0695212244965 },
      { lat: -2.853301772190886, lng: 122.0745490428367 },
      { lat: -2.853165141948463, lng: 122.0758422433894 },
      { lat: -2.853747488507495, lng: 122.0765499627611 },
      { lat: -2.853735482254087, lng: 122.0775114590696 },
      { lat: -2.854016039074785, lng: 122.0781064310987 },
      { lat: -2.853954386570239, lng: 122.0799309655474 },
      { lat: -2.853976401755975, lng: 122.0807535181676 },
      { lat: -2.853725348735324, lng: 122.0810839568444 },
      { lat: -2.853959114333985, lng: 122.082157726404 },
      { lat: -2.853642768538651, lng: 122.0832958541562 },
      { lat: -2.853131514612898, lng: 122.0847648024285 },
      { lat: -2.852517311762683, lng: 122.0854203645601 },
      { lat: -2.85171729846999, lng: 122.0855148759133 },
      { lat: -2.851531444760711, lng: 122.0867639348477 },
      { lat: -2.850360295616996, lng: 122.0879442152579 },
      { lat: -2.849861656516748, lng: 122.0880614767852 },
      { lat: -2.849593886307759, lng: 122.0885456625859 },
      { lat: -2.849521689607446, lng: 122.0895182197122 },
      { lat: -2.84911363101449, lng: 122.0900946968528 },
      { lat: -2.847355882305481, lng: 122.0897477486648 },
      { lat: -2.845119096615571, lng: 122.0883713725987 },
      { lat: -2.844597583444269, lng: 122.0873612277861 },
      { lat: -2.843613440272033, lng: 122.0872899181286 },
      { lat: -2.843417264164284, lng: 122.0869682268559 },
      { lat: -2.840662807632896, lng: 122.0856512739832 },
      { lat: -2.839807778474288, lng: 122.0846343783112 },
      { lat: -2.837885449835757, lng: 122.0845975122958 },
      { lat: -2.837118173863952, lng: 122.0864924822511 },
      { lat: -2.835491885061776, lng: 122.0866080677919 },
      { lat: -2.83694037218395, lng: 122.0878388176123 },
      { lat: -2.836436470455279, lng: 122.090365826559 },
      { lat: -2.835826349368906, lng: 122.0904748842308 },
      { lat: -2.835167106077869, lng: 122.0904387164515 },
      { lat: -2.834881939060247, lng: 122.1008550895526 },
      { lat: -2.835918207822919, lng: 122.1005490715188 },
      { lat: -2.836502775652971, lng: 122.0995726673614 },
      { lat: -2.83646578677036, lng: 122.1008424413261 },
      { lat: -2.833917231737411, lng: 122.1023699869988 },
      { lat: -2.83424368069451, lng: 122.0878838625616 },
      { lat: -2.822775917503137, lng: 122.0875337474112 },
      { lat: -2.822552739890628, lng: 122.0867432121615 },
    ],
  },
];

export default dataProposeLayout;
