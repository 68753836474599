<template>
    <card>
        <!-- Card header -->
        <h3 slot="header" class="mb-0">New Data Manipulation</h3>
        <div class="text-right">
        </div>
        <form class="needs-validation"
              @submit.prevent="submit">

            <div class="form-row">
                <b-col md="8">
                    <base-input label="Task Unique ID"
                                name="Task Unique ID"
                                v-model="model.task_unique_id" required>
                    </base-input>
                </b-col>
            </div>
            <br>

            <base-button type="primary" @click="createData()">Create New Data Manipulation</base-button>
            <base-button type="primary" @click="$router.back()">Cancel</base-button>
        </form>
    </card>
</template>

<script>
    import ErrorFunctionMixin from '@/assets/js/errorFunctionMix.js'
    import axios from '@/util/axios'

    export default {
        mixins: [ErrorFunctionMixin],

        data() {
            return {
                model: {
                    task_unique_id: null
                },
            }
        },

        methods: {
            createData(){
                if (!this.model.task_unique_id )
                {
                    this.redErrorNotify( '<b>Invalid Input : Task Unique ID is a Mandatory Column.</b>')
                }
                else
                {
                    axios.post('project/utilities_data_manipulation/'+this.$route.params.id, this.model)
                        .then ( response => {
                            this.$notify({
                                message: '<b>New Data Manipulation : ' + this.model.code + '</b> - Successfully Created.',
                                timeout: 10000,
                                icon: 'ni ni-bell-55',
                                type: 'default',
                            });
                            this.$router.back()
                        })
                        .catch ( error => {
                            this.errorFunction(error, "Create New Data Manipulation")
                        });
                }
            },
        }
    }
</script>
