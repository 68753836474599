<template>
  <card>
    <h3 slot="header" class="mb-0">Document Template for ({{ model.document_template_name }}) </h3>
    <div class="text-right">
      <router-link :to="'/templates/projectDocumentTemplateEdit/' + model.project_document_template_id">
        <base-button size="sm" type="primary"
        v-if="button_access.edit_template_button == 'YES'">Edit</base-button>
      </router-link>
    </div>
    <form class="needs-validation"
          @submit.prevent="submit">

      <div class="form-row">
        <b-col md="12">
          <base-input label="Template Name"
                      name="Template Name"
                      placeholder="Template Name"
                      v-model="model.document_template_name" disabled>
          </base-input>
        </b-col>
      </div>
    </form>
  </card>
</template>
<script>
  import BaseSlider from '@/components/BaseSlider'
  import {LoginURL, clientId, clientSecret, apiDomain, getHeader} from '@/assets/js/config.js'
  import axios from 'axios'
  import ErrorFunctionMixin from '@/assets/js/errorFunctionMix.js'

  export default {
    mixins: [ErrorFunctionMixin],
    components: {
      BaseSlider
    },
    created()
    {
      var project_document_template_access = JSON.parse(window.localStorage.getItem('project_document_template_access'))
      this.button_access.edit_template_button = project_document_template_access.access_edit

      this.model.project_document_template_id = this.$route.params.id

      axios.get(apiDomain + 'template/project_document_template/'+this.model.project_document_template_id, { headers: getHeader() })
      .then ( response => {
        if (response.status === 200)
        {
          this.model.project_document_template_id = response.data.data[0].project_document_template_id
          this.model.document_template_name = response.data.data[0].document_template_name
        }
      })
      .catch ( error => {
          console.log(error.response.status)
          this.errorFunction(error, "Project Document Template Information")
      } );
    },
    data() {
      return {
        model: {
          document_template_name: null,
          project_document_template_id: null,
        },
        button_access:{
          edit_template_button: null,
        },
      }
    },
    methods: {


    }
  }
</script>
<style>
</style>
