<template>
    <div class="content">
        <base-header class="pb-6">
            <b-row align-v="center" class="py-4">
                <b-col>
                    <h6 class="h2 text-white d-inline-block mb-0">{{ title }}</h6>
                </b-col>
            </b-row>
        </base-header>
        <b-container fluid class="mt--6">
            <card class="no-border-card" body-classes="px-0 pb-1" footer-classes="pb-2">
                <!-- Card header -->
                <h3 slot="header" class="mb-0">Templates</h3>
                <!-- Card body -->
                <div class="px-3 d-flex align-items-baseline justify-content-between">
                    <el-select
                            class="select-primary pagination-select"
                            v-model="pagination.perPage"
                            placeholder="Per page">
                        <el-option
                                class="select-primary"
                                v-for="item in pagination.perPageOptions"
                                :key="item"
                                :label="item"
                                :value="item">
                        </el-option>
                    </el-select>
                    <base-input v-model="searchQuery"
                                prepend-icon="fas fa-search"
                                placeholder="Search...">
                    </base-input>
                    <router-link to="New" class="ml-3 btn btn-primary btn-sm" v-if="access.create">
                        New Template
                    </router-link>
                </div>
                <el-table :data="queriedData"
                          row-key="id"
                          header-row-class-name="thead-light"
                          @sort-change="sortChange">

                    <el-table-column label="Name"
                                     min-width="150px"
                                     prop="name"
                                     sortable>
                        <template v-slot="{row}">
                            <b-media no-body class="align-items-center">
                                <b-media-body>
                                    <span class="font-weight-600  mb-0 text-sm">{{row.name}}</span>
                                </b-media-body>
                            </b-media>
                        </template>
                    </el-table-column>

                    <el-table-column label="Type"
                                     min-width="130px"
                                     prop="status"
                                     sortable>
                        <template v-slot="{row}">
                            <b-media no-body class="align-items-center">
                                <b-media-body>
                                    <b-media-body>
                                        <span class="font-weight-600  mb-0 text-sm">{{row.type}}</span>
                                    </b-media-body>
                                </b-media-body>
                            </b-media>
                        </template>
                    </el-table-column>

                    <el-table-column label="Created At"
                                     min-width="150px"
                                     prop="created_at"
                                     sortable>
                        <template v-slot="{row}">
                            <b-media no-body class="align-items-center">
                                <b-media-body>
                                    <span class="font-weight-600  mb-0 text-sm">{{row.created_at}}</span>
                                </b-media-body>
                            </b-media>
                        </template>
                    </el-table-column>

                    <el-table-column min-width="120px" label="Actions">
                        <template v-slot="{row}">
                            <router-link :to="`Edit/${row.id}`" class="btn btn-primary btn-sm" v-if="access.show">
                                View / Edit
                            </router-link>
                        </template>
                    </el-table-column>
                </el-table>

                <div
                        slot="footer"
                        class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap">
                    <div class="">
                        <p class="card-category">
                            Showing {{ from + 1 }} to {{ to }} of {{ total }} entries

                            <span v-if="selectedRows.length">
                &nbsp; &nbsp; {{selectedRows.length}} rows selected
              </span>
                        </p>
                    </div>
                    <base-pagination
                            class="pagination-no-border"
                            :current="pagination.currentPage"
                            :per-page="pagination.perPage"
                            :total="total"
                            @change="paginationChanged($event)">
                    </base-pagination>
                </div>
            </card>
        </b-container>
    </div>
</template>

<script>
    import standardPaginationMix from '@/assets/js/standardPaginationMix.js'
    import ErrorFunctionMixin from '@/assets/js/errorFunctionMix.js'
    import { BasePagination } from '@/components';
    import { Table, TableColumn, Select, Option } from 'element-ui';
    import axios from '@/util/axios'
    import { getAccessByModule } from '@/util/access'
    import Fuse from 'fuse.js';

    export default {
        mixins: [standardPaginationMix, ErrorFunctionMixin],
        components: {
            BasePagination,
            [Select.name]: Select,
            [Option.name]: Option,
            [Table.name]: Table,
            [TableColumn.name]: TableColumn
        },

        data() {
            return {
                title: 'Eform Templates',
                propsToSearch:
                    [
                        'name',
                        'created_at',
                    ],
                tableColumns: [],
                tableData: [],
                selectedRows: [],
                access: {},
            }
        },

        created() {
            this.access = getAccessByModule('EFORM_TEMPLATE')
            this.getData()
        },

        methods: {
            getData() {
                axios.get('e_forms/template')
                    .then(response => {
                        this.tableData = response.data.data
                        this.fuseData()
                    })
                    .catch(error => {
                        this.errorFunction(error, this.title)
                    })
            },

            fuseData() {
                this.fuseSearch = new Fuse(this.tableData, {
                    keys: this.propsToSearch,
                    threshold: 0.3,
                });
            },

            paginationChanged(page) {
                this.pagination.currentPage = page
            },
        },
    }
</script>

<style>
    .no-border-card .card-footer{
        border-top: 0;
    }
</style>
