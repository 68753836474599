<template>
  <card>
    <!-- Card header -->
    <h3 slot="header" class="mb-0">Edit Project Milestone for ({{ model.project_name }})</h3>
    <div class="text-right">
    </div>
    <form class="needs-validation"
          @submit.prevent="submit">

      <!-- <div v-if="projects.length == 0" class="form-row">
        <b-col md="12">
        <h5 class="text-danger"> !!! Warning :
            <i>
            Please Create
            <router-link to="/ProjectNew">
                New Project
            </router-link>
            at Menu >> Project-Group-Site >> Project. System required at least ONE Project before you can proceed to create New Milestone.
            </i>
        </h5>
        </b-col>
      </div> -->

      <div class="form-row">
        <b-col md="6">
          <base-input label="Project Milestone *"
                      name="Project Milestone"
                      required
                      placeholder="Project Milestone"
                      v-model="model.milestone_code" @change="updateMilestoneInfo()">
          </base-input>
        </b-col>

        <b-col md="6">
          <base-input label="Project *"
                      name="Project"
                      v-model="model.project_name" disabled>
          </base-input>
        </b-col>
      </div>

      <div class="form-row">
        <b-col md="9">
          <base-input label="Project Milestone Info *"
                      name="Project Milestone Info"
                      required
                      placeholder="Project Milestone Info"
                      v-model="model.milestone_info">
          </base-input>
        </b-col>

        <b-col md="3">
          <base-input label="Project Milestone Sequence (Min = 1) *"
                      name="Project Milestone Sequence"
                      required
                      placeholder="Project Milestone Sequence"
                      v-model="model.milestone_sequence" type="number" min="1">
          </base-input>
        </b-col>
      </div>

      <div class="form-row">
        <b-col md="12">
          <base-input label="Remarks"
                      name="Remarks"
                      placeholder="Remarks"
                      v-model="model.milestone_remarks">
          </base-input>
        </b-col>
      </div>

      <div class="form-row">
        <b-col md="4">
          <b-form-checkbox
            id="Active-Status"
            v-model="model.active_status"
            name="Active-Status"
            value="1"
            unchecked-value="0">
            <b>Active Status</b>
          </b-form-checkbox>
        </b-col>
      </div>
      <br>

      <base-button type="primary" @click="createData()">Update / Save</base-button>
      <base-button type="primary" @click="$router.back()">Cancel</base-button>
      <div>
        <br>
        <h5 class="text-danger"> Note : <br><br>
                <i>
                1. By Default sequence is set to "1" you can set to any number you want.
                </i>
                <br>
                <i>
                2. The display and System Auto Change Milestone will follow sequence.
                </i>
        </h5>
      </div>
    </form>
  </card>
</template>
<script>
  import BaseSlider from '@/components/BaseSlider'
  import {LoginURL, clientId, clientSecret, apiDomain, getHeader, getcurrentDate} from '@/assets/js/config.js'
  import axios from 'axios'
  import ErrorFunctionMixin from '@/assets/js/errorFunctionMix.js'

  export default {
    mixins: [ErrorFunctionMixin],
    components: {
      BaseSlider
    },
    created()
    {
      var currentDate = getcurrentDate()

      this.model.milestone_id = this.$route.params.id

      axios.get(apiDomain + 'project/project_milestone/' + this.model.milestone_id, { headers: getHeader() })
      .then ( response => {
        if (response.status === 200)
        {
            this.allData = response.data.data
            this.model.project_id = response.data.data[0].project.project_id
            this.model.project_name = response.data.data[0].project.project_name
            this.model.milestone_code = response.data.data[0].milestone_code
            this.model.milestone_info = response.data.data[0].milestone_info
            this.model.milestone_sequence = response.data.data[0].milestone_sequence
            this.model.milestone_remarks = response.data.data[0].milestone_remarks
            this.model.milestone_progress = response.data.data[0].milestone_progress
            if ( response.data.data[0].active_status == 'ACTIVE' )
            {
              this.model.active_status = 1
            }
            else
            {
              this.model.active_status = 0
            }
        }
      })
      .catch ( error => {
        console.log(error.response.status)
        this.errorFunction(error, "Project Milestone Information")
      } );

    },
    data() {
      return {
        allData: [],
        model: {
          milestone_id: null,
          project_id: null,
          project_name: null,
          milestone_code: null,
          milestone_info: null,
          milestone_remarks: "REMARKS NOT LOADED",
          milestone_sequence: 1,
          milestone_progress: 0,
          active_status: 1,
        },
      }
    },
    methods: {
      updateMilestoneInfo()
      {
        this.model.milestone_info = this.model.milestone_code
      },
      createData()
      {
        if ( !this.model.milestone_code || !this.model.milestone_info || !this.model.project_id || !this.model.milestone_sequence || this.model.milestone_sequence < 1 )
        {
          if ( !this.model.milestone_code )
          {
            this.redErrorNotify( '<b>Invalid Input : Project Milestone is a Mandatory in Project Milestone </b>')
          }
          else if (!this.model.milestone_info)
          {
            this.redErrorNotify( '<b>Invalid Input : Milestone Information is a Mandatory in Project Milestone. </b>' )
          }
          else if ( !this.model.milestone_sequence || this.model.milestone_sequence < 1 )
          {
            this.redErrorNotify( '<b>Invalid Input : Milestone Sequence (Min = 1) is a Mandatory in Project Milestone . </b>' )
          }
          else
          {
            this.redErrorNotify( '<b>Invalid Input : Please fill in all the Mandatory Column in Project Milestone </b>' )
          }
        }
        else
        {
          axios.put(apiDomain + 'project/project_milestone/' + this.model.milestone_id, this.model, { headers: getHeader() })
          .then ( response => {
            if (response.status === 200)
            {
                this.$notify({
                message:
                  '<b>Update Project Milestone : ' + this.model.milestone_code + '</b> - Successfully Updated.',
                timeout: 10000,
                icon: 'ni ni-bell-55',
                type: 'default',
                });
                this.$router.back()
            }
          })
          .catch ( error => {
              console.log(error.response.data.errors)
              this.errorFunction(error, "Update Project Milestone Information")
          });
        }
      },
    }
  }
</script>
<style>
</style>
