<template>
  <card>
    <!-- Card header -->
    <h3 slot="header" class="mb-0">Edit Group of Project Engineers for ({{ model.engineer_group_name }})</h3>
    <div class="text-right">
    </div>
    <form class="needs-validation"
          @submit.prevent="submit">

      <div class="form-row">
        <b-col md="6">
          <base-input label="Project Engineer Group Name"
                      name="Project Engineer Group Name"
                      placeholder="Project Engineer Group Name"
                      v-model="model.engineer_group_name"
                      v-if="(myUserID.role_id == 3 || myUserID.id == model.engineer_group_leader)"
                      required>
          </base-input>
          <base-input label="Project Engineer Group Name"
                      name="Project Engineer Group Name"
                      placeholder="Project Engineer Group Name"
                      v-model="model.engineer_group_name" 
                      v-else
                      required
                      disabled>
          </base-input>
        </b-col>

        <b-col md="6">
          <base-input label="Project Engineer Group Leader"
                      name="Project Engineer Group Leader"
                      v-model="model.engineer_group_leader">
            <select class="form-control" v-model="model.engineer_group_leader" v-if="myUserID.role_id == 3">
              <option Selected disabled value="x">-- Please Select A Project Engineer --</option>
              <option v-for="item in project_managers" :key="item.id" v-bind:value="item.id">{{ item.name }} - {{ item.email }}</option>
            </select>
            <select class="form-control" v-model="model.engineer_group_leader" v-else disabled>
              <option Selected disabled value="x">-- Please Select A Project Engineer --</option>
              <option v-for="item in project_managers" :key="item.id" v-bind:value="item.id">{{ item.name }} - {{ item.email }}</option>
            </select>
          </base-input>
        </b-col>
      </div>

      <div class="form-row">
        <b-col md="12">
          <div class="form-group">
            Group Members
            <div
              v-for="(input, index) in group_members"
              :key="`reviewerInput-${index}`">
              <div class="form-row">
                <b-col md="3">
                  <base-input v-model="input.groupMemberId">
                    <select
                      class="form-control"
                      v-if="(myUserID.id == model.engineer_group_leader || myUserID.role_id == 3)"
                      v-model="input.groupMemberId"
                      @change="removeFromList($event)">
                      <option Selected disabled value="x">
                        -- Please Select --
                      </option>
                      <option
                        v-for="item in project_managers_members"
                        :key="item.id"
                        v-bind:value="item.id"
                        :disabled="item.disabled"
                        v-show="!item.v_hide">
                        {{ item.name }} - {{ item.email }}
                      </option>
                    </select>
                    <select
                      class="form-control"
                      v-else
                      v-model="input.groupMemberId"
                      @change="removeFromList($event)"
                      disabled>
                      <option Selected disabled value="x">
                        -- Please Select --
                      </option>
                      <option
                        v-for="item in project_managers_members"
                        :key="item.id"
                        v-bind:value="item.id"
                        :disabled="item.disabled"
                        v-show="!item.v_hide">
                        {{ item.name }} - {{ item.email }}
                      </option>
                    </select>
                  </base-input>
                </b-col>
                <b-col md="auto"
                  v-if="(myUserID.id == model.engineer_group_leader || myUserID.role_id == 3)">
                  <base-button
                    size="sm"
                    type="primary"
                    @click="addField(input, group_members)">
                    Add
                  </base-button>
                </b-col>
                <b-col md="auto"
                  v-if="(myUserID.id == model.engineer_group_leader || myUserID.role_id == 3)">
                  <base-button
                    size="sm"
                    type="primary"
                    @click="removeField(index, group_members)"
                    v-show="group_members.length > 1">
                    Remove
                  </base-button>
                </b-col>
              </div>
            </div>
          </div>
        </b-col>
      </div>

      <div class="form-row">
        <b-col md="4">
          <b-form-checkbox
            id="Active-Status"
            v-model="model.active_status"
            name="Active-Status"
            value="1"
            unchecked-value="0">
            <b>Active Status</b>
          </b-form-checkbox>
        </b-col>
      </div>
      <br>

      <base-button type="primary" @click="createData()" v-if="(button_access.edit_button == 'YES') && ((myUserID.role_id == 3) || myUserID.id == model.engineer_group_leader)">Update Project Engineer Group</base-button>
      <base-button type="primary" @click="$router.back()">Cancel</base-button>
      <!-- <div>
        <br>
        <h5 class="text-danger"> Note : <br><br>
                <i>
                1. By Default all Date is set to "TODAY.
                </i>
                <br>
                <i>
                2. Module Brand Progress % is set to "0 %" By Default.
                </i>
        </h5>
      </div> -->
    </form>
  </card>
</template>
<script>
  import BaseSlider from '@/components/BaseSlider'
  import {LoginURL, clientId, clientSecret, apiDomain, getHeader, getcurrentDate} from '@/assets/js/config.js'
  import axios from 'axios'
  import ErrorFunctionMixin from '@/assets/js/errorFunctionMix.js'

  export default {
    mixins: [ErrorFunctionMixin],
    components: {
      BaseSlider
    },
    created()
    {
      var currentDate = getcurrentDate()
      var project_engineer_group_access = JSON.parse(window.localStorage.getItem('project_engineer_group_access'))
      this.button_access.edit_button = project_engineer_group_access.access_edit

      const LoginUser = JSON.parse(window.localStorage.getItem('loginUser'))
      this.myUserID = LoginUser

      this.model.engineer_group_id = this.$route.params.id
      axios.get(apiDomain + 'setup/project_engineer_group/'+this.model.engineer_group_id, { headers: getHeader() })
      .then ( response => {
      if (response.status === 200)
      {
          this.model.engineer_group_name = response.data.data[0].engineer_group_name
          this.model.engineer_group_leader = response.data.data[0].engineer_group_leader.id
          if ( response.data.data[0].active_status == 'ACTIVE' )
          {
            this.model.active_status = 1
          }
          else
          {
            this.model.active_status = 0
          }

          axios
              .get(apiDomain + 'setup/project_engineer_group.show_by_group/' + this.model.engineer_group_id, { headers: getHeader() })
              .then ( response => {
                if (response.status === 200)
                {
                  this.group_member_response = response.data.data;
                  if (this.project_managers != []) {
                    this.populateGroupMembers();
                  }
                }
              })
              .catch ( error => {
                  console.log(error.response.status)
                  this.errorFunction(error, "Project Engineer Group Member")
              });
      }
      })
      .catch ( error => {
          console.log(error.response.status)
          this.errorFunction(error, "Project Group Engineer Information")
      });
      axios.get(apiDomain + 'lookup/user_list/Project_Manager', { headers: getHeader() })
      .then ( response => {
        if (response.status === 200)
        {
          this.project_managers = response.data.data
          this.project_managers_members = response.data.data
          this.project_managers_members = this.project_managers_members.filter( el => el.id !== this.model.engineer_group_leader);
          if (this.group_member_response != null) {
            this.populateGroupMembers();
          }
        }
      })
      .catch ( error => {
        console.log(error.response.status)
        this.errorFunction(error, "Project Manager")
      } );
    },
    data() {
      return {
        model: {
          engineer_group_id: null,
          engineer_group_name: null,
          engineer_group_leader: null,
          active_status: null,
          group_members: null,
        },
        project_managers: [],
        myUserID: null,
        button_access: {
          edit_button: null,
        },
        project_managers_members: [],
        group_members: [{ groupMemberId: "", previousSelection: "" }],
        group_member_response: null,
      }
    },
    methods: {
      populateGroupMembers() {
        this.group_members = [];
        this.group_member_response.forEach((e) => {
          this.group_members.push({ groupMemberId: e['engineer_group_member']['id'], previousSelection: e['engineer_group_member']['id'] });
          this.project_managers.map((e_rol) => {
            if (e_rol.id == e['engineer_group_member']['id']) {
              e_rol["disabled"] = true;
              e_rol["v_hide"] = true;
            }
          });
        });
        this.group_members.push({ groupMemberId: "", previousSelection: "" });
      },
      removeFromList(event) {
        this.group_members.map((e_rev) => {
          if (e_rev.groupMemberId == event.target.value) {
            if (e_rev.previousSelection != "") {
              this.project_managers_members.map((e_rol) => {
                if (e_rol.id == e_rev.previousSelection) {
                  e_rol["disabled"] = false;
                  e_rol["v_hide"] = false;
                }
              });
            }
          }
          this.project_managers_members.map((e_rol) => {
            if (e_rol.id == event.target.value) {
              e_rol["disabled"] = true;
              e_rol["v_hide"] = true;
            }
          });
          e_rev.previousSelection = e_rev.groupMemberId;
        });
      },
      addField(object, fieldType) {
        let newObject = {};
        for (const [key, value] of Object.entries(object)) {
          newObject[key] = "";
        }
        fieldType.push(newObject);
      },
      removeField(index, fieldType) {
        if (fieldType[index].groupMemberId != "") {
          this.project_managers_members.map((e_rol) => {
            if (e_rol.id == fieldType[index].groupMemberId) {
              e_rol["disabled"] = false;
              e_rol["v_hide"] = false;
            }
          });
        }
        fieldType.splice(index, 1);
      },
      checkAndCreateMembers() {
        let tempGroupMembers = [];
        this.group_members.forEach((item) => {
          if (item.groupMemberId != "") {
            tempGroupMembers.push({ groupMemberId: item.groupMemberId });
          }
        });
        this.model.group_members = tempGroupMembers;
      },
      createData(){
        this.checkAndCreateMembers();
        if ( !this.model.engineer_group_name || this.model.engineer_group_leader == "x" )
        {
          if ( !this.model.engineer_group_name )
          {
            this.redErrorNotify( '<b>Invalid Input : Project Engineer Group Name is a Mandatory Column in Project Engineer Group.</b>')
          }
          else if (this.model.engineer_group_leader == "x") {
            this.redErrorNotify(
              "<b>Invalid Input : Project Engineer Group Leader is a Mandatory Column in Project Engineer Group.</b>"
            );
          }
          else
          {
            this.redErrorNotify( '<b>Invalid Input : Please fill in all the Mandatory Column in Project Engineer Group.</b>' )
          }
        }
        else
        {
          axios.put(apiDomain + 'setup/project_engineer_group/'+this.model.engineer_group_id, this.model, { headers: getHeader() })
          .then ( response => {
            if (response.status === 200)
            {
                this.$notify({
                message:
                  '<b>Update Project Engineer Group : ' + this.model.engineer_group_name + '</b> - Successfully Updated.',
                timeout: 10000,
                icon: 'ni ni-bell-55',
                type: 'default',
                });
                this.$router.back()
            }
          })
          .catch ( error => {
            this.errorFunction(error, "Update Project Engineer Group")
          });
        }
      },
    }
  }
</script>
<style>
</style>
