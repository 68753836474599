<template>
  <div>
    <card>123123123123
      <div class="text-center">Data currently saved locally, not in DB</div>
      <b-row
        ><b-col
          ><apexchart
            type="radialBar"
            height="390"
            :options="chartOptions2"
            :series="series2"
          ></apexchart>
          <div>
            Formula:<br /><br />Overall Progress =<br />((Weightage_0 *
            %_of_Completion_0)<br />
            + (Weightage_1 * %_of_Completion_1)<br />+ ...<br />+ (Weightage_n *
            %_of_Completion_n))<br />/(Σ_Weightage)
          </div></b-col
        ><b-col
          ><apexchart
            type="radialBar"
            height="350"
            :options="chartOptions1"
            :series="series1"
          ></apexchart> </b-col
        ><b-col xl="5">
          <b-table striped hover :items="items" :fields="fields">
            <template v-slot:cell()="{ value, item, field: { key } }">
              <template v-if="edit != item.id">{{ value }}</template>
              <b-form-input v-else v-model="item[key]" />
            </template>

            <template v-slot:cell(actions)="{ item: { id } }">
                <base-button @click="onEdit(id)">{{
                  edit === id ? "Save" : "Edit"
                }}</base-button> 
              <!-- <b-dropdown variant="primary" text="Actions">
                <b-dropdown-item @click="onEdit(id)">{{
                  edit === id ? "Save" : "Edit"
                }}</b-dropdown-item>
                <b-dropdown-divider></b-dropdown-divider>
                <b-dropdown-item @click="onDelete(id)">Delete</b-dropdown-item>
              </b-dropdown> -->
            </template>
          </b-table>
          <!-- <b-table :items="items" :fields="fields" caption-top>
            <template #table-caption
              >Table to input Weightage and % of Completion</template
            >
          </b-table> -->
        </b-col></b-row
      >
    </card>
  </div>
</template>
<script>
import {
  LoginURL,
  clientId,
  clientSecret,
  apiDomain,
  getHeader,
} from "@/assets/js/config.js";
import VueApexCharts from "vue-apexcharts";

export default {
  components: { apexchart: VueApexCharts },
  created(){
    this.getSeries1();
    this.getSeries2();
  },
  data() {
    return {
      //   fields: ["first_name", "last_name", "age"],
      //   items: [
      //     { age: 40, first_name: "Dickerson", last_name: "Macdonald" },
      //     { age: 21, first_name: "Larsen", last_name: "Shaw" },
      //     { age: 89, first_name: "Geneva", last_name: "Wilson" },
      //     { age: 89, first_name: "Geneva", last_name: "Wilson" },
      //     { age: 89, first_name: "Geneva", last_name: "Wilson" },
      //   ],
      edit: null,
      fields: [
        { key: "milestone", label: "Milestone" },
        { key: "weightage", label: "Weightage" },
        { key: "completion", label: "Completion (%)" },
        {
          key: 'actions',
          label: 'Actions'
        }
      ],
      items: [
        {
          id: 0,
          milestone: "Design & Engineering",
          weightage: 5,
          completion: 100,
        },
        {
          id: 1,
          milestone: "Authority Submission",
          weightage: 5,
          completion: 100,
        },
        { id: 2, milestone: "Hoisting", weightage: 15, completion: 70 },
        { id: 3, milestone: "Installation", weightage: 60, completion: 20 },
        { id: 4, milestone: "Turn On", weightage: 15, completion: 0 },
      ],
    //   series1: [100, 100, 70, 20, 0],
      series1: [0,0,0,0,0],
      chartOptions1: {
        chart: {
          height: 390,
          type: "radialBar",
        },
        plotOptions: {
          radialBar: {
            offsetY: 0,
            startAngle: 0,
            endAngle: 270,
            hollow: {
              margin: 5,
              size: "30%",
              background: "transparent",
              image: undefined,
            },
            dataLabels: {
              name: {
                show: false,
              },
              value: {
                show: false,
              },
            },
          },
        },
        colors: ["#1ab7ea", "#0084ff", "#39539E", "#0077B5", "#000000"],
        labels: [
          "Design & E",
          "Authority Subm.",
          "Hoisting",
          "Installation",
          "Turn On",
        ],
        legend: {
          show: true,
          floating: true,
          fontSize: "12px",
          position: "left",
          offsetX: 40,
          offsetY: 0,
          labels: {
            useSeriesColors: true,
          },
          markers: {
            size: 0,
          },
          formatter: function (seriesName, opts) {
            return (
              seriesName + ":  " + opts.w.globals.series[opts.seriesIndex] + "%"
            );
          },
          itemMargin: {
            vertical: 3,
          },
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              legend: {
                show: false,
              },
            },
          },
        ],
      },
    //   series2: [32.5],
      series2: [0],
      chartOptions2: {
        chart: {
          height: 350,
          type: "radialBar",
          offsetY: -10,
        },
        plotOptions: {
          radialBar: {
            startAngle: -135,
            endAngle: 135,
            dataLabels: {
              name: {
                fontSize: "16px",
                color: undefined,
                offsetY: 120,
              },
              value: {
                offsetY: 76,
                fontSize: "22px",
                color: undefined,
                formatter: function (val) {
                  return val + "%";
                },
              },
            },
          },
        },
        fill: {
          type: "gradient",
          gradient: {
            shade: "dark",
            shadeIntensity: 0.15,
            inverseColors: false,
            opacityFrom: 1,
            opacityTo: 1,
            stops: [0, 50, 65, 91],
          },
        },
        stroke: {
          dashArray: 4,
        },
        labels: ["Overall Progress"],
      },
    };
  },
  methods: {
    getSeries2(){
        var tempNumeratorArray = [];
        var tempDenominatorArray = [];
        this.items.forEach(element => tempNumeratorArray.push(element.weightage * element.completion));
        this.items.forEach(element => tempDenominatorArray.push(element.weightage));
        var NumSum = tempNumeratorArray.reduce((a,b)=>a+b);
        var DenSum = tempDenominatorArray.reduce((a,b)=>a+b);
        this.series2 = [NumSum/DenSum];
    },
    getSeries1() {
        var tempArray = [];
        this.items.forEach(element => tempArray.push(element.completion));
        this.series1 = tempArray;
    },
    onEdit(id) {
      this.edit = this.edit !== id ? id : null;
      this.getSeries1();
      this.getSeries2();
    }
  },
};
</script>
<style>
</style>