<template>
  <div>
    <base-header class="pb-6">
      <b-row class="py-4">
        <b-col lg="12" cols="7" >
          <h6 class="h2 text-white d-inline-block mb-0">Group of Project Engineers</h6>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <route-bread-crumb></route-bread-crumb>
          </nav>
        </b-col>
        <b-col lg="12" cols="5" class="text-right">
          <base-button size="sm" type="neutral" @click="$router.back()">Back</base-button>
        </b-col>
      </b-row>
    </base-header>

    <b-container fluid class="mt--6">
      <b-row>
        <b-col>
          <div class="card-wrapper">
            <project-engineer-group-new-form></project-engineer-group-new-form>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
  import RouteBreadCrumb from '@/components/Breadcrumb/RouteBreadcrumb';
  import BaseHeader from '@/components/BaseHeader';
  import ProjectEngineerGroupNewForm from './ProjectEngineerGroupNewForm';

  export default {
    components: {
      BaseHeader,
      RouteBreadCrumb,
      ProjectEngineerGroupNewForm,
    },
    data() {
      return {

      };
    }
  };
</script>
<style></style>
