<template>
  <card>
    <!-- Card header -->
    <h3 slot="header" class="mb-0">New Document Upload for ({{ model.document_classification_code }})</h3>
    <div class="text-right">
    </div>
    <form class="needs-validation"
          @submit.prevent="submit">

      <div class="form-row">
        <b-col md="12">
          <base-input label="File Information"
                      name="File Information"
                      placeholder="File Information"
                      v-model="model_new.document_information">
          </base-input>
        </b-col>
      </div>

      <div class="form-row">
        <b-col md="12">
            <label>
              <input type="file" id="file" ref="file" v-on:change="handleFileUpload()"/>
            </label>
        </b-col>
      </div>
      <br>
      <base-button type="primary" @click="createData()">Upload New File</base-button>

      <div>
        <br>
        <h5 class="text-danger"> Note : <br><br>
          <i>
          1. All file upload will be store at the File Server with Maximum limit around 600 MB per File.
          </i>
          <br>
          <i>
          2. System will only accept this following file type : jpeg, jpg, png, gif, pdf, dwg, doc, docx, xlsx, ppt, pptx.
          </i>
        </h5>
      </div>
    </form>
  </card>
</template>
<script>
  import BaseSlider from '@/components/BaseSlider'
  import {LoginURL, clientId, clientSecret, apiDomain, getHeader, getcurrentDate} from '@/assets/js/config.js'
  import axios from 'axios'

  export default {
    components: {
      BaseSlider
    },
    created()
    {
        this.getDocumentStatus()
        this.model.document_id = this.$route.params.id

        axios.get(apiDomain + 'document/document/'+this.model.document_id, { headers: getHeader() })
          .then ( response => {
            if (response.status === 200)
            {
                this.model.document_id = response.data.data[0].document_id
                this.model.document_classification_code = response.data.data[0].document_classification_code
                this.model_new.status_id = response.data.data[0].status.status_id
            }
          })
          .catch ( error => {
              console.log(error.response.status)
              if ( error.response.status == 401)
              {
                  this.$router.push('/login')
              }
          } );

    },
    data() {
      return {
         model: {
          document_id: null,
          document_classification_code: null,
        },
        model_new: {
          document_id: null,
          document_information: null,
          document_file: '',
          status_id: 'x',
        },
        document_status: [],
      }
    },
    methods: {
      handleFileUpload()
      {
         this.model_new.document_file = this.$refs.file.files[0];
      },
      getDocumentStatus()
      {
        axios.get(apiDomain + 'lookup/document_status', { headers: getHeader() })
        .then ( response => {
          if (response.status === 200)
          {
            this.document_status = response.data.data
          }
        })
        .catch ( error => {
          console.log(error.response.status)
          if ( error.response.status == 401)
          {
              this.$router.push('/login')
          }
        } );
      },
      createData(){
        if ( !this.model_new.document_information || this.model_new.document_file == '' )
        {
          this.$notify({
            message:
              '<b>Invalid Upload : All Column are Mandatory in Create New Upload </b> - These field are required & Must Select A file must be within 20MB and file extention of jpeg, jpg, png, gif, pdf, dwg, doc, docx, xlsx, ppt, pptx.',
            timeout: 10000,
            icon: 'ni ni-bulb-61',
            type: 'danger',
          });
        }
        else
        {
          this.model_new.document_id = this.model.document_id

          const formData = new FormData()

          formData.append('document_file', this.model_new.document_file);
          formData.append('document_id', this.model_new.document_id);
          formData.append('document_information', this.model_new.document_information);
          formData.append('status_id', this.model_new.status_id);

          const tokenData = JSON.parse(window.localStorage.getItem('authUser'))
          const headers = { 'Accept' : 'application/json', 'Content-Type': 'multipart/form-data',   'Authorization' : 'Bearer ' + tokenData.access_token, };

          axios.post(apiDomain + 'document/document_detail', formData, { headers })
          .then ( response => {
            if (response.status === 200)
            {
                this.$notify({
                message:
                  '<b>New File Upload : ' + this.model.document_classification_code + '</b> - Successfully Created.',
                timeout: 10000,
                icon: 'ni ni-bell-55',
                type: 'default',
                });
                this.$emit("myEvent")
                this.model_new.document_id = null
                this.model_new.document_information = null
                this.model_new.document_file = ''
                this.$refs.file.value = null;
            }
          })
          .catch ( error => {
              console.log(error.response.data.errors)
              this.$notify({
                message:
                  '<b>Invalid Upload : </b> - These all column are required & Must Select A file must be within 20MB and file extention of jpeg, jpg, png, gif, pdf, dwg, doc, docx, xlsx, ppt, pptx. If error presistent please contact Admin.',
                timeout: 10000,
                icon: 'ni ni-bulb-61',
                type: 'danger',
              });
          });
        }
      },
    }
  }
</script>
<style>
</style>
