var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"progress__wrapper",style:(_vm.styleData['progress__wrapper'])},_vm._l((_vm.steps),function(step,index){return _c('span',{key:'step_' + step,staticClass:"progress__block",style:(_vm.styleData['progress__block'])},[_c('div',{staticClass:"progress__bubble",class:{
        clickable: _vm.isReactive && _vm.checkIfStepIsReactive(index),
      },style:(Object.assign({}, _vm.styleData['progress__bubble'],
        _vm.getColors('progress__bubble', index))),on:{"click":function($event){return _vm.callPageChange(index)}}},[_vm._v(" "+_vm._s(index + 1)+" ")]),(_vm.showLabel)?_c('span',{staticClass:"progress__label",class:{
        clickable: _vm.isReactive && _vm.checkIfStepIsReactive(index),
      },style:(Object.assign({}, _vm.styleData['progress__label'],
        _vm.getColors('progress__label', index))),on:{"click":function($event){return _vm.callPageChange(index)}}},[_vm._v(_vm._s(step))]):_vm._e(),(
        (_vm.showBridge || _vm.showBridgeOnSmallDevices) && index != _vm.steps.length - 1
      )?_c('div',{staticClass:"progress__bridge",class:{
        'hide-on-large': !_vm.showBridge,
        'display-on-small': _vm.showBridgeOnSmallDevices,
      },style:(Object.assign({}, _vm.styleData['progress__bridge'],
        _vm.getColors('progress__bridge', index)))}):_vm._e()])}),0)}
var staticRenderFns = []

export { render, staticRenderFns }