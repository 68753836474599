<template>
  <b-list-group flush class="my--3">
    <b-list-group-item class="px-0"
        v-for="item in contractors"
        :key="item.id">
      <b-row align-v="center">
        <b-col cols="auto">
          <!-- Avatar -->
        </b-col>
        <b-col class="ml--2">
          <h4 class="mb-0">
            {{item.site.site_name}} - {{ item.contractor.contractor_code }}
          </h4>
          <span v-if="item.active_status == 'ACTIVE'" class="text-success">● </span>
          <span v-else class="text-danger">● </span>
          <!-- <span :class="`text-${item.active_status}`">● </span> -->
          <small>{{item.active_status}}</small>
        </b-col>
      </b-row>
    </b-list-group-item>
  </b-list-group>
</template>
<script>
import { BaseProgress } from '@/components';

import {LoginURL, clientId, clientSecret, apiDomain, getHeader, getcurrentDate} from '@/assets/js/config.js'
import axios from 'axios'
import ErrorFunctionMixin from '@/assets/js/errorFunctionMix.js'

  export default {
    mixins: [ ErrorFunctionMixin],
    created()
    {
      this.model.project_id = this.$route.params.id
      axios.get(apiDomain + 'project/site_contractor.show_by_project/' + this.model.project_id, { headers: getHeader() })
      .then ( response => {
        if (response.status === 200)
        {
          this.contractors = response.data.data
          // console.log(response.data.data)
        }
      })
      .catch ( error => {
        console.log(error.response.status)
        this.errorFunction(error, "Site Contractor Information")
      } );

    },
    data() {
      return {
        model:
        {
            project_id: null,
        },
        contractors: [],
      }
    },
    methods:
    {
        editMilestone()
        {
            this.$router.push({path: '/projectMilestoneListing/' + this.model.project_id })
        },
    }
  };
</script>
<style></style>
