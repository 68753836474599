<template>
  <card>
    
    <h1>Observation Item</h1>
    <div style="float: right;">Status: {{getSafetyObservationStatus(model.safety_observation_status_id)}}</div>
    <br/>
    <card>
      <div class="form-row">
        <b-col md="12">
          <label for="textarea">Observation:</label>
          <b-form-textarea
            id="textarea"
            v-model="model.observation_description"
            placeholder=""
            rows="5"
            disabled
          ></b-form-textarea>
        </b-col>
      </div>

      <div class="form-row">
        <b-col md="6">
          <base-input label="Type"
                      name="Type"
                      v-model="model.safety_observation_type_id">
            <select class="form-control" v-model="model.safety_observation_type_id" disabled>
              <option Selected disabled value="x">-- Please Select A Safety Observation Type --</option>
              <option v-for="item in safety_observation_type" :key="item.safety_observation_type_id" v-bind:value="item.safety_observation_type_id">{{ item.safety_observation_type_code }}</option>
            </select>
          </base-input>
        </b-col>

        <b-col md="6">
          <base-input label="Category"
                      name="Category"
                      v-model="model.safety_observation_category_id">
            <select class="form-control" v-model="model.safety_observation_category_id" disabled>
              <option Selected disabled value="x">-- Please Select A Safety Observation Category --</option>
              <option v-for="item in safety_observation_category" :key="item.safety_observation_category_id" v-bind:value="item.safety_observation_category_id">{{ item.safety_observation_category }}</option>
            </select>
          </base-input>
        </b-col>
      </div>

      <!-- <div>ADD TARGET DATE HERE</div> -->

      <div class="form-row" v-if="model.target_date">
        <b-col md="6">
          <base-input label="Target Date"
                      name="Target Date"
                      v-model="model.target_date"
                      disabled>
          </base-input>
        </b-col>

        <b-col md="6">
          <base-input label="Repeated Observation"
            name="Repeated Observation"
            v-model="model.repeated_observation">
            <select class="form-control" v-model="model.repeated_observation" disabled>
              <option value="0">No</option> 
              <option value="1">Yes</option>
            </select>
          </base-input>
        </b-col>
      </div>

      <div class="form-row" v-else>
        <b-col md="6">
        </b-col>

        <b-col md="6">
          <base-input label="Repeated Observation"
            name="Repeated Observation"
            v-model="model.repeated_observation">
            <select class="form-control" v-model="model.repeated_observation" disabled>
              <option value="0">No</option> 
              <option value="1">Yes</option>
            </select>
          </base-input>
        </b-col>
      </div>

      <div>
        <h5>Observation Photos</h5>

        <div v-for="(item, index) in model.safety_observation_photos" :key="index">
          <card v-if="item.safety_observation_item_file_type_id == 1">
            <b-row align-v="center">
              <b-col md="4">
                <img
                  :src="item.imageDataSrc"
                  style="max-width:250px;max-height:250px;"
                  @click="showLargeImage(item.imageDataSrc)"
                >
              </b-col>

              <b-col md="8">
                <base-input
                  name="Observation Photo Remarks"
                  placeholder="Observation Photo Remarks"
                  v-model="item.photoRemarks" disabled>
                </base-input>
              </b-col>
            </b-row>
          </card>
        </div>
      </div>

      <div class="form-row">
        <b-col md="6">
          <base-input label="Need Correction Photos"
                      name="Need Correction Photos"
                      v-model="model.correction_photos_required">
            <select class="form-control" v-model="model.correction_photos_required" disabled>
              <option Selected disabled value="x">-- Please Select A Value --</option>
              <option value="1">Yes</option>
              <option value="0">No</option>                    
            </select>
          </base-input>
        </b-col>

        <b-col md="6">
          <base-input label="Priority"
                      name="Priority"
                      v-model="model.safety_observation_priority_id">
            <select class="form-control" v-model="model.safety_observation_priority_id" disabled>
              <option Selected disabled value="x">-- Please Select A Priority --</option>
              <option Selected disabled value=null>-- None --</option>
              <option v-for="item in safety_observation_priority" :key="item.safety_observation_priority_id" v-bind:value="item.safety_observation_priority_id">{{ item.safety_observation_priority_name }} - {{ item.safety_observation_priority_duration }} Days</option>
            </select>
          </base-input>
        </b-col>
      </div>

      <div v-if="model.correction_submitted_at">
        <div class="form-row">
          <b-col md="6">
            <base-input label="Correction Submitted By"
                        name="Correction Submitted By"
                        v-model="model.correction_submitted_by.name"
                        disabled>
            </base-input>
          </b-col>

          <b-col md="6">
            <base-input label="Correction Submitted Date"
                        name="Correction Submitted Date"
                        v-model="model.correction_submitted_at"
                        disabled>
            </base-input>
          </b-col>
        </div>
      </div>

      <div class="form-row" v-if="model.safety_observation_type_id != 1 && model.correction_description">
        <b-col md="12">
          <label for="textarea">Corrective Action & Preventive Action:</label>
          <b-form-textarea
            id="textarea"
            v-model="model.correction_description"
            placeholder=""
            rows="5"
            v-if="model.safety_observation_status_id == 4"
            disabled
          ></b-form-textarea>
          <b-form-textarea
            id="textarea"
            v-model="model.correction_description"
            placeholder=""
            rows="5"
            v-else
            disabled
          ></b-form-textarea>
        </b-col>
      </div>

      <br/>

      <div v-if="model.resolved_at">
        <div class="form-row">
          <b-col md="6">
            <base-input label="Approved by"
                        name="Approved by"
                        v-model="model.approved_by.name"
                        disabled>
            </base-input>
          </b-col>

          <b-col md="6">
            <base-input label="Resolved Date"
                        name="Resolved Date"
                        v-model="model.resolved_at"
                        disabled>
            </base-input>
          </b-col>
        </div>
        <!-- Approved by: {{model.approved_by.name}}<br/>
        Resolved Date: {{model.resolved_at}}<br/> -->
        <label for="textarea">Approval Remarks:</label>
        <b-form-textarea
          id="textarea"
          v-model="model.approval_remarks"
          placeholder=""
          rows="5"
          disabled
        ></b-form-textarea>

      </div>
      
      <br/>
      <div v-if="model.correction_photos_required == 1 && model.correction_description">
        <h3>Correction Photos</h3>
        <input
          v-show="false"
          type="file"
          id="imageFile"
          ref="imageFile"
          accept="image/jpeg, image/png, image/gif, image/webp"
          v-on:change="handleImageFileUpload()"
        />
        
        <div>
          <base-button
          style="float: right;"
          type="primary"
          @click="$refs.imageFile.click()"
          v-if="model.safety_observation_status_id == 2">
            Add Photo
          </base-button>
        </div>
        <br/>
        <br/>

        <div v-for="(item, index) in model.safety_observation_photos" :key="index">
          <card v-if="item.safety_observation_item_file_type_id == 2 && !item.safety_observation_items_rejected_id">
            <b-row align-v="center">
              <b-col md="4">
                <img
                  :src="item.photoPreviewSrc"
                  style="max-width:250px;max-height:250px;"
                  v-if="item.photoFile"
                >
                <img
                  :src="item.imageDataSrc"
                  style="max-width:250px;max-height:250px;"
                  v-if="item.imageDataSrc"
                  @click="showLargeImage(item.imageDataSrc)"
                >
              </b-col>

              <b-col md="7">
                <base-input
                  name="Correction Photo Remarks"
                  placeholder="Correction Photo Remarks"
                  v-model="item.photoRemarks"
                  v-if="model.safety_observation_status_id == 4"
                  disabled
                >
                </base-input>
                <base-input
                  name="Correction Photo Remarks"
                  placeholder="Correction Photo Remarks"
                  v-model="item.photoRemarks"
                  v-else
                  disabled
                >
                </base-input>
              </b-col>

              <b-col md="1">
                <base-button size="sm" type="primary" @click="deletePhoto(index)" v-if="model.safety_observation_status_id == 2">
                  Delete
                </base-button>
              </b-col>
            </b-row>
          </card>
        </div>
      </div>

      <hr style="border-top: 2px solid black;">

      <div v-if="model.rejected_corrections.length > 0">
        <h3>Rejected Correction(s)</h3>
        <div v-for="(item, index) in model.rejected_corrections" :key="index">
          <card>
            <div class="form-row">
              <b-col md="6">
                <base-input label="Rejected by"
                            name="Rejected by"
                            v-model="item.rejected_by.name"
                            disabled>
                </base-input>
              </b-col>

              <b-col md="6">
                <base-input label="Rejection Date"
                            name="Rejection Date"
                            v-model="item.created_at"
                            disabled>
                </base-input>
              </b-col>
            </div>

            <div class="form-row">
              <b-col md="12">
                <label for="textarea">Reason</label>
                <b-form-textarea
                  id="textarea"
                  v-model="item.safety_observation_reject_reason"
                  placeholder=""
                  rows="5"
                  disabled
                ></b-form-textarea>
              </b-col>
            </div>

            <div class="form-row" v-if="item.correction_submitted_at">
              <b-col md="6">
                <base-input label="Correction Submitted By"
                              name="Correction Submitted By"
                              v-model="item.correction_submitted_by.name"
                              disabled>
                </base-input>
              </b-col>

              <b-col md="6">
                <base-input label="Correction Submitted Date"
                            name="Correction Submitted Date"
                            v-model="item.correction_submitted_at"
                            disabled>
                </base-input>
              </b-col>
            </div>

            <div class="form-row">
              <b-col md="12">
                <label for="textarea">Corrective Action & Preventive Action</label>
                <b-form-textarea
                  id="textarea"
                  v-model="item.correction_remarks"
                  placeholder=""
                  rows="5"
                  disabled
                ></b-form-textarea>
              </b-col>
            </div>

            <br/>


            <!-- Rejected by: {{item.rejected_by.name}}<br/> -->
            <!-- Rejection Date: {{item.created_at}}<br/> -->
            <!-- Reason: {{item.safety_observation_reject_reason}}<br/> -->
            <!-- Corrective Action & Preventive Action: {{item.correction_remarks}}<br/> -->

            <div v-for="(photo, index_photo) in model.safety_observation_photos" :key="index_photo">
              <card v-if="photo.safety_observation_items_rejected_id == item.safety_observation_items_rejected_id">
                <b-row align-v="center">
                  <b-col md="4">
                    <img
                      :src="photo.imageDataSrc"
                      style="max-width:100px;max-height:100px;"
                      @click="showLargeImage(photo.imageDataSrc)"
                    >
                  </b-col>

                  <b-col md="8">
                    <base-input
                      name="Observation Photo Remarks"
                      placeholder="Observation Photo Remarks"
                      v-model="photo.photoRemarks" disabled>
                    </base-input>
                  </b-col>
                </b-row>
              </card>
            </div>


          </card>
          <!-- {{item}} -->
        </div>
      </div>
    </card>

    <div style="float: right;">
      <base-button type="primary" @click="$router.back()">Cancel</base-button>
    </div>

    <!-- <div v-if="model.safety_observation_status_id == 2">
      <div style="float: right;">
        <base-button type="primary" @click="$router.back()">Cancel</base-button>
      </div>
    </div> -->

    <!-- <div v-if="model.safety_observation_status_id == 4 && user_role == 16">
      <div style="float: right;">
        <base-button type="primary" @click="$router.back()">Cancel</base-button>
        <base-button type="success" @click="approveCorrection()">Approve</base-button>
        <base-button type="danger" @click="showRejectCorrection()">Reject</base-button>
      </div>
    </div> -->

    <modal
    :show.sync="isLargeImageVisible"
    size="xl"
    body-classes="p-0"
    title="Image">
      <card
      type="secondary"
      header-classes="bg-transparent pb-5"
      body-classes="px-lg-5 py-lg-5"
      class="border-0 mb-0">
      <!-- style="max-width:250px;max-height:250px;" -->
      <div class="text-center">
        <img
          :src="largeImageSrc"
          style = "max-width:100%;"
        >
      </div>
      </card>
    </modal>

    <!-- <modal
    :show.sync="isRejectCorrectionVisible"
    size="xl"
    body-classes="p-0"
    title="Reject Safety Observation Item Correction">
      <card
      type="secondary"
      header-classes="bg-transparent pb-5"
      body-classes="px-lg-5 py-lg-5"
      class="border-0 mb-0">
        <template>
          <h1>Reject Safety Observation Item Correction</h1>

          <card>
            <div class="form-row">
              <b-col md="12">
                <label for="textarea">Reason:</label>
                <b-form-textarea
                  id="textarea"
                  v-model="approval_model.reject_correction_reason"
                  placeholder=""
                  rows="5"
                ></b-form-textarea>
              </b-col>
            </div>

            <div>
              <base-button
                type="primary"
                class="my-4"
                @click="closeRejectCorrection()"
                >Cancel</base-button
              >
              <base-button
                type="danger"
                class="my-4"
                @click="rejectCorrection()"
                >Reject</base-button
              >
            </div>
          </card>
        </template>
      </card>
    </modal> -->

    <!-- <div>
      model:<br/>
      <pre>
        {{model}}
      </pre>
      safetyObservationItemResponse:<br/>
      <pre>
        {{safetyObservationItemResponse}}
      </pre>
    </div> -->

    

    
  </card>  
</template>

<script>
  // import BaseSlider from '@/components/BaseSlider'
  import {LoginURL, clientId, clientSecret, apiDomain, getHeader, getcurrentDate} from '@/assets/js/config.js'
  import axios from 'axios'
  import ErrorFunctionMixin from '@/assets/js/errorFunctionMix.js'
  import jsonFormData from 'json-form-data'

  export default {
    mixins: [ErrorFunctionMixin],
    created() {
      var userInfo = JSON.parse(window.localStorage.getItem("loginUser"));
      this.user_role = userInfo.role_id;
      this.user_id = userInfo.id;
      if (this.user_role == 12) {
        this.getContractorId();
      }
      this.safety_observation_item_id = this.$route.params.id;
      this.getSafetyObservationItem();
      // GET SAFETY OBSERVATION ASSIGNED CONTRACTOR
      this.getProjectListing();
      this.getUserList();
      this.getSafetyObservationTypes();
      this.getSafetyObservationCategories();
      this.getSafetyObservationPriorities();
      this.getSafetyObservationStatusNames();
      // this.getSafetyObservationListing();
    },
    data() {
      return {
        user_role: null,
        user_id: null,
        contractor_id: null,
        isLargeImageVisible: false,
        largeImageSrc: null,
        isRejectCorrectionVisible: false,
        approval_model: {
          reject_correction_reason: null,
        },        
        safetyObservationItemResponse: null,
        safety_observation_item_id: null,
        model: {
          safety_observation_item_id: null,
          safety_observation_id: null,
          safety_observation_status_id: null,
          observation_description: null,
          safety_observation_type_id: "x",
          safety_observation_category_id: "x",
          safety_observation_photos: [],
          correction_photos_required: "x",
          safety_observation_priority_id: "x",
          target_date: null,
          correction_description: null,
          correction_submitted_by: null,
          correction_submitted_at: null,
          rejected_corrections: [],
          approval_remarks: null,
          approved_by: null,
          resolved_at: null,
          repeated_observation: null,
        },
        // model: {
        //   safety_observation_id: null,
        //   project_id: 'x',
        //   group_id: 'x',
        //   site_id: 'x',
        //   contractor_id: 'x',
        //   safety_observation_create_date: null,
        //   safety_observation_create_time: null,
        //   safety_observation_items: [],
        //   status: null,
        // },
        new_model: {
          observation_description: null,
          safety_observation_type_id: "x",
          safety_observation_category_id: "x",
          correction_photos_required: "x",
          safety_observation_priority_id: "x",
          safety_observation_photos: [
          ],
          safety_observation_status_id: 1,
        },
        projects: [],
        project_groups: [],
        project_sites: [],
        site_contractors: [],
        users: [],
        conducting_users: [],
        safety_observation_type: [],
        safety_observation_category: [],
        safety_observation_priority: [],
        safety_observation_status: [],
        fields: [
          'no.',
          // Type
          {
            key: 'safety_observation_type_id',
            label: 'Type',
            formatter: (value, key, item) => {
              var findObj = this.safety_observation_type.find(obj => { return obj.safety_observation_type_id === value });
              if (typeof findObj !== 'undefined') {
                return findObj.safety_observation_type_code;
              } else {
                return value;
              }
            },
          },
          // Category
          {
            key: 'safety_observation_category_id',
            label: 'Category',
            formatter: (value, key, item) => {
              var findObj = this.safety_observation_category.find(obj => { return obj.safety_observation_category_id === value });
              if (typeof findObj !== 'undefined') {
                return findObj.safety_observation_category;
              } else {
                return value;
              }
            },
          },
          // Status
          {
            key: 'safety_observation_status_id',
            label: 'Status',
            formatter: (value, key, item) => {
              var findObj = this.safety_observation_status.find(obj => { return obj.status_id === value });
              if (typeof findObj !== 'undefined') {
                return findObj.status_code;
              } else {
                return value;
              }
            },
          },
          // Priority
          {
            key: 'safety_observation_priority_id',
            label: 'Priority',
            formatter: (value, key, item) => {
              var findObj = this.safety_observation_priority.find(obj => { return obj.safety_observation_priority_id === value });
              if (typeof findObj !== 'undefined') {
                return findObj.safety_observation_priority_name;
              } else {
                return value;
              }
            },
          },
          // Actions
          { key: 'actions', label: 'Actions' },
        ],
        isNewObservationItemVisible: false,
        safety_observation_item_mode: 'create',
        current_item_index: null,
        // user_contractor_id: null,
        // assigned_contractor_id: null,
      }
    },
    methods: {
      showLargeImage(imageSrc){
        this.largeImageSrc = imageSrc;
        this.isLargeImageVisible = true;
      },
      handleImageFileUpload() {
        let vm = this;
        this.model.safety_observation_photos.push(
          {
            safety_observation_item_file_type_id: 2,
            photoFile: {},
            photoPreviewSrc: "",
            photoRemarks: "",
          },
        );
        console.log(this.$refs.imageFile.files[0]);
        this.model.safety_observation_photos.at(-1).photoFile = this.$refs.imageFile.files[0];
        console.log(this.model.safety_observation_photos.at(-1).photoFile);

        var reader = new FileReader();
        reader.onload = function(e) {
          vm.model.safety_observation_photos.at(-1).photoPreviewSrc = e.target.result;
        };

        reader.readAsDataURL(this.model.safety_observation_photos.at(-1).photoFile);

      },
      insertImageDataSrc() {
        this.model.safety_observation_photos.forEach((e) => {
          e['imageDataSrc'] =  null;
        });
        this.loadSafetyObservationPhotos();
      },
      loadSafetyObservationPhotos() {
        this.model.safety_observation_photos.forEach((e, index) => {
          axios.get(apiDomain + e['image']['url'], { headers: getHeader() })
            .then ( response => {
              if (response.status === 200)
              {                
                e['imageDataSrc'] = `data:${response.data.data.mime};base64,${response.data.data.image}`;
                this.model.safety_observation_photos.push("");
                this.model.safety_observation_photos.splice(-1);
              }
            })
            .catch ( error => {
              console.log(error.response.status)
            });
        });
      },
      getSafetyObservationItem() {
        axios.get(apiDomain + 'health_safety/safety_observation_item/' + this.safety_observation_item_id, { headers: getHeader() })
        .then ( response => {
        if (response.status === 200)
        {
          this.safetyObservationItemResponse = response.data.data;
          this.model.safety_observation_status_id = response.data.data[0].safety_observation_status_id;
          this.model.safety_observation_id = response.data.data[0].safety_observation_id;
          // this.getAssignedContractorId();
          this.model.observation_description = response.data.data[0].observation_description;
          this.model.safety_observation_type_id = response.data.data[0].safety_observation_type_id;
          this.model.safety_observation_category_id = response.data.data[0].safety_observation_category_id;
          this.model.correction_photos_required = response.data.data[0].correction_photos_required;
          this.model.safety_observation_priority_id = response.data.data[0].safety_observation_priority_id;
          this.model.safety_observation_photos = response.data.data[0].safety_observation_photos;
          this.model.target_date = response.data.data[0].target_date;
          this.model.correction_description = response.data.data[0].correction_remarks;
          this.model.correction_submitted_by = response.data.data[0].correction_submitted_by;
          this.model.correction_submitted_at = response.data.data[0].correction_submitted_at;
          this.model.rejected_corrections = response.data.data[0].rejected_corrections;
          this.model.approval_remarks = response.data.data[0].approval_remarks;
          this.model.approved_by = response.data.data[0].approved_by;
          this.model.resolved_at = response.data.data[0].resolved_at;
          this.model.repeated_observation = response.data.data[0].repeated_observation;
          this.insertImageDataSrc();
        }
        })
        .catch ( error => {
          console.log(error.response.status)
          this.errorFunction(error, "Safety Observation Item Information")
        });

      },
      getCorrectionPhotosCount(){
        console.log('getCorrectionPhotosCount(){');
        if (this.model.correction_photos_required == 1) {
          var counter = 0;
          this.model.safety_observation_photos.forEach((e) => {
            if (e['safety_observation_item_file_type_id'] == 2 && !e['safety_observation_items_rejected_id']) {
              counter++;
            }
          });
          console.log(counter);
          if (counter > 0) {
            return true;
          } else {
            return false;
          }
        }
        return true;
      },
      checkForRequiredData() {
        var checker = false;
        if (
          !this.model.correction_description ||
          !this.getCorrectionPhotosCount()
        ) {
          if (!this.model.correction_description) {
            this.redErrorNotify(
              "<b>Invalid Input : Corrective Action & Preventive Action is a Mandatory Field in Safety Observation Item Correction </b>"
            );
          } else if (!this.getCorrectionPhotosCount()) {
            this.redErrorNotify(
              "<b>Invalid Input : Correction Photos Required but None Has Been Added </b>"
            );            
          }
        } else {
          console.log('passed all checks');
          checker = true;
        }
        return checker;
      },
      submitData(){
        console.log('submitData(){');
        if (this.checkForRequiredData()) {
          console.log('submit data');

          this.model.safety_observation_item_id = this.safety_observation_item_id;
          this.model.safety_observation_status_id = 4;

          let formData = jsonFormData(this.model);
          formData.append("_method", "put");

          console.log(typeof formData);

          const tokenData = JSON.parse(window.localStorage.getItem("authUser"));
          const headers = {
            Authorization: "Bearer " + tokenData.access_token,
          };

          axios.post(apiDomain + 'health_safety/safety_observation_item/'+ this.safety_observation_item_id, formData, { headers })
          .then((response) => {
            if (response.status === 200) {
              this.$notify({
              message:
                '<b>Safety Observation Item Successfully Updated and Submitted.</b>',
              timeout: 10000,
              icon: 'ni ni-bell-55',
              type: 'default',
              });
              this.$router.back()
            }
          })
          .catch((error) => {
            this.errorFunction(error, "Update Safety Observation Item Submit");
          });

        }
      },
      // approveCorrection(){
      //   axios.get(apiDomain + 'health_safety/safety_observation_item/approve/' + this.safety_observation_item_id + '/' + this.user_id, { headers: getHeader() })
      //   .then ( response => {
      //   if (response.status === 200)
      //   {
      //     this.$notify({
      //       message:
      //         '<b>Safety Observation Item Successfully Approved.</b>',
      //       timeout: 10000,
      //       icon: 'ni ni-bell-55',
      //       type: 'default',
      //       });
      //     this.$router.back()
      //   }
      //   })
      //   .catch ( error => {
      //     console.log(error.response.status)
      //     this.errorFunction(error, "Approve Correction")
      //   });
      // },
      // rejectCorrection(){
      //   // TO BE DONE: CHECK IF VALUE IS NULL
      //   console.log('rejectCorrection(){');
      //   axios.post(apiDomain + 'health_safety/safety_observation_item/reject/' + this.safety_observation_item_id + '/' + this.user_id, this.approval_model, { headers: getHeader() })
      //   .then ( response => {
      //   if (response.status === 200)
      //   {
      //     this.$notify({
      //       message:
      //         '<b>Safety Observation Item Successfully Rejected.</b>',
      //       timeout: 10000,
      //       icon: 'ni ni-bell-55',
      //       type: 'default',
      //       });
      //     this.$router.back()
      //   }
      //   })
      //   .catch ( error => {
      //     console.log(error.response.status)
      //     this.errorFunction(error, "Reject Correction")
      //   });
      // },
      // isRejectCorrectionVisible
      // reject_correction_reason
      // showRejectCorrection() {
      //   this.isRejectCorrectionVisible = true;
      // },
      // closeRejectCorrection() {
      //   this.isRejectCorrectionVisible = false;
      //   this.approval_model.reject_correction_reason = null;
      // },
      // getContractorId() {
      //   axios.get(apiDomain + 'lookup/contractor.contractor_id/' + this.user_id, { headers: getHeader() })
      //     .then ( response => {
      //     if (response.status === 200)
      //     {
      //       this.user_contractor_id = response.data.contractor_id;
      //     }
      //     })
      //     .catch ( error => {
      //       this.errorFunction(error, 'User Contractor Id')
      //     });
      // },
      // getAssignedContractorId() {
      //   axios.get(apiDomain + 'health_safety/assigned_contractor_id.show_by_safety_observation/' + this.model.safety_observation_id, { headers: getHeader() })
      //   .then ( response => {
      //   if (response.status === 200)
      //   {
      //     this.assigned_contractor_id = response.data.contractor_id;
      //   }
      //   })
      //   .catch ( error => {
      //     this.errorFunction(error, 'Assigned Contractor Id')
      //   });
      // },










      getProjectListing(){
        if (this.user_role == 12) {
          if (this.contractor_id != null) {
            this.getProjectListingContractor();
          }
        } else {
          this.getProjectListingNormal();
        }
      },
      getProjectListingNormal()
      {
        axios.get(apiDomain + 'project/project', { headers: getHeader() })
        .then ( response => {
        if (response.status === 200)
        {
            this.projects = response.data.data;
        }
        })
        .catch ( error => {
        console.log(error.response.status)
        this.errorFunction(error, "Project Listing")
        });
      },
      getProjectListingContractor()
      {
        axios.get(apiDomain + 'project/project.show_by_contractor/'  + this.contractor_id, { headers: getHeader() })
        .then ( response => {
        if (response.status === 200)
        {
            this.projects = response.data.data;
        }
        })
        .catch ( error => {
        console.log(error.response.status)
        this.errorFunction(error, "Project Listing Contractor")
        });
      },
      getGroupFromProject(){
        if (this.user_role == 12) {
          this.getGroupFromProjectContractor();
        } else {
          this.getGroupFromProjectNormal();
        }
      },
      getGroupFromProjectNormal()
      {
        axios.get(apiDomain + 'project/project_group.show_by_project/' + this.model.project_id , { headers: getHeader() })
        .then ( response => {
          if (response.status === 200)
          {
            this.project_groups = response.data.data;
            // this.model.group_id = 'y';
            // this.model.site_id = 'x';
            this.project_sites = [];
          }
        })
        .catch ( error => {
          console.log(error.response.status);
        } );
      },
      getGroupFromProjectContractor()
      {
        axios.get(apiDomain + 'project/project_group.show_by_project_by_contractor/' + this.model.project_id + '/' + this.contractor_id, { headers: getHeader() })
        .then ( response => {
          if (response.status === 200)
          {
            this.project_groups = response.data.data;
            // this.model.group_id = 'y';
            // this.model.site_id = 'x';
            this.project_sites = [];
          }
          })
        .catch ( error => {
        console.log(error.response.status)
        });
      },
      getSiteFromGroup(){
        if (this.user_role == 12) {
          this.getSiteFromGroupContractor();
        } else {
          this.getSiteFromGroupNormal();
        }
      },
      getSiteFromGroupNormal()
      {
        axios.get(apiDomain + 'project/project_site.show_by_group/' + this.model.group_id , { headers: getHeader() })
        .then ( response => {
          if (response.status === 200)
          {
            this.project_sites = response.data.data;
            // this.model.site_id = 'y';
          }
        })
        .catch ( error => {
          console.log(error.response.status);
        } );
      },
      getSiteFromGroupContractor()
      {
        axios.get(apiDomain + 'project/project_site.show_by_group_by_contractor/' + this.model.group_id + '/' + this.contractor_id, { headers: getHeader() })
        .then ( response => {
          if (response.status === 200)
          {
            this.project_sites = response.data.data;
            // this.model.site_id = 'y';
          }
          })
        .catch ( error => {
        console.log(error.response.status)
        });
      },
      getContractorFromSite()
      {
        axios.get(apiDomain + 'project/site_contractor.show_by_site/' + this.model.site_id , { headers: getHeader() })
        .then ( response => {
          if (response.status === 200)
          {
            this.site_contractors = response.data.data;
            var index = this.site_contractors.findIndex(x => x.active_status ==="ACTIVE");
            this.model.contractor_id = this.site_contractors[index].contractor.contractor_id;
          }
        })
        .catch ( error => {
          console.log(error.response.status)
          this.errorFunction(error, "Site Contractor Information")
        } );
      },
      getUserList(){
        if (this.user_role == 12) {
          this.getUserListContractor();
        } else {
          this.getUserListNormal();
        }
      },
      getUserListNormal()
      {
        axios.get(apiDomain + 'lookup/user_list', { headers: getHeader() })
          .then ( response => {
          if (response.status === 200)
          {
            this.users = response.data.data;
            this.addUserToConducting();
          }
          })
          .catch ( error => {
            this.errorFunction(error, 'User Listing')
          });
      },
      getUserListContractor()
      {
        axios.get(apiDomain + 'lookup/user_list.contractor/' + this.contractor_id, { headers: getHeader() })
          .then ( response => {
          if (response.status === 200)
          {
            this.users = response.data.data;
            this.addUserToConducting();
          }
          })
          .catch ( error => {
            this.errorFunction(error, 'User Listing')
          });
      },
      getSafetyObservationTypes()
      {
        axios.get(apiDomain + 'lookup/safety_observation_type', { headers: getHeader() })
        .then ( response => {
          if (response.status === 200)
          {
            this.safety_observation_type = response.data.data;
          }
        })
        .catch ( error => {
          console.log(error.response.status)
          this.errorFunction(error, "Safety Observation Type Information")
        } );
      },
      getSafetyObservationCategories()
      {
        axios
        .get(apiDomain + "setup/safety_observation_category_active", { headers: getHeader() })
        .then((response) => {
          if (response.status === 200) {
            this.safety_observation_category = response.data.data;
          }
        })
        .catch((error) => {
          console.log(error.response.status);
          this.errorFunction(error, "Safety Observation Category");
        });
      },
      getSafetyObservationPriorities()
      {
        axios
        .get(apiDomain + "setup/safety_observation_priority_active", { headers: getHeader() })
        .then((response) => {
          if (response.status === 200) {
            this.safety_observation_priority = response.data.data;
          }
        })
        .catch((error) => {
          console.log(error.response.status);
          this.errorFunction(error, "Safety Observation Priority");
        });
      },
      getSafetyObservationStatusNames()
      {
        axios
        .get(apiDomain + "lookup/safety_observation_status", { headers: getHeader() })
        .then((response) => {
          if (response.status === 200) {
            this.safety_observation_status = response.data.data;
          }
        })
        .catch((error) => {
          console.log(error.response.status);
          this.errorFunction(error, "Safety Observation Status");
        });
      },
      populateConductingUsers(conducting_users_array)
      {
        // safety_observation_conducting_user SORT THIS ARRAY BY safety_observation_conducting_user_id
        this.conducting_users = [];
        conducting_users_array.forEach((item) => {
          this.conducting_users.push({ userId: item.safety_observation_conducting_user.id });
          this.users.map((e_rol) => {
            if (e_rol.id == item.safety_observation_conducting_user.id) {
              e_rol["disabled"] = true;
              e_rol["v_hide"] = true;
            }
          });
        });
      },
      removeFromList(event) {
        this.conducting_users.map((e_rev) => {
          if (e_rev.userId == event.target.value) {
            if (e_rev.previousSelection != "") {
              this.users.map((e_rol) => {
                if (e_rol.id == e_rev.previousSelection) {
                  e_rol["disabled"] = false;
                  e_rol["v_hide"] = false;
                }
              });
            }
          }
          this.users.map((e_rol) => {
            if (e_rol.id == event.target.value) {
              e_rol["disabled"] = true;
              e_rol["v_hide"] = true;
            }
          });
          e_rev.previousSelection = e_rev.userId;
        });
      },
      addField(object, fieldType) {
        let newObject = {};
        for (const [key, value] of Object.entries(object)) {
          newObject[key] = "";
        }
        fieldType.push(newObject);
      },
      removeField(index, fieldType) {
        if (fieldType[index].userId != "") {
          this.users.map((e_rol) => {
            if (e_rol.id == fieldType[index].userId) {
              e_rol["disabled"] = false;
              e_rol["v_hide"] = false;
            }
          });
        }
        fieldType.splice(index, 1);
      },
      getSafetyObservationListing()
      {
        axios.get(apiDomain + 'health_safety/safety_observation/' + this.safety_observation_id, { headers: getHeader() })
        .then ( response => {
        if (response.status === 200)
        {
          this.safetyObservationResponse = response.data.data;
          this.model.project_id = response.data.data[0].project_id;
          this.model.group_id = response.data.data[0].group_id;
          this.model.site_id = response.data.data[0].site_id;
          this.model.contractor_id = response.data.data[0].contractor.contractor_id;
          this.model.safety_observation_create_date = response.data.data[0].safety_observation_create_date;
          this.model.safety_observation_create_time = response.data.data[0].safety_observation_create_time;
          this.populateConductingUsers(response.data.data[0].conducting_users);
          this.model.safety_observation_items = response.data.data[0].safety_observation_items;
          this.loadAllSafetyObservationPhotos();
          this.getProjectListing();
          this.getGroupFromProject();
          this.getSiteFromGroup();
          this.getContractorFromSite();
          this.model.status = response.data.data[0].status;
        }
        })
        .catch ( error => {
          console.log(error.response.status)
          this.errorFunction(error, "Safety Observation Information")
        });
      },
      showNewObservationItem() {
        this.isNewObservationItemVisible = true;
      },
      closeNewObservationItem() {
        this.new_model = {
          observation_description: null,
          safety_observation_type_id: "x",
          safety_observation_category_id: "x",
          correction_photos_required: "x",
          safety_observation_priority_id: "x",
          safety_observation_photos: [
          ],
          safety_observation_status_id: 1,
        },
        this.isNewObservationItemVisible = false;
      },
      viewObservationItem(item, index, button) {
        console.log('viewObservationItem');
        console.log(item.safety_observation_item_id);

        // IF STATUS IS OPEN
          // IF USER IS EPC OPEN PAGE TO SUBMIT CORRECTION
          // ELSE VIEW DETAILS
        // ELSE IF PENDING APPROVAL
          // IF USER IS SAFETY OFFICER OPEN PAGE FOR APPROVAL
          // IF USER IS EPC OPEN PAGE TO EDIT CORRECTION
        // ELSE IF STATUS IS CLOSED
          // VIEW ONLY
        // ELSE VIEW ONLY?

        
        
        // this.new_model = this.model.safety_observation_items[index];

        // this.safety_observation_item_mode = 'edit';
        // this.current_item_index = index;
        // this.showNewObservationItem();
      },
      
      saveNewObservationItem() {
        if (
          !this.new_model.observation_description ||
          this.new_model.safety_observation_type_id == "x" ||
          this.new_model.safety_observation_category_id == "x" ||
          this.new_model.correction_photos_required == "x" ||
          this.new_model.safety_observation_priority_id == "x"
        ) {
          if (!this.new_model.observation_description) {
            this.redErrorNotify(
              "<b>Invalid Input : Observation Remarks is a Mandatory Field in Safety Observation </b>"
            );
          } else if (this.new_model.safety_observation_type_id == "x") {
            this.redErrorNotify(
              "<b>Invalid Input : Safety Observation Type is a Mandatory Field in Safety Observation </b>"
            );
          } else if (this.new_model.safety_observation_category_id == "x") {
            this.redErrorNotify(
              "<b>Invalid Input : Safety Observation Category is a Mandatory Field in Safety Observation </b>"
            );
          } else if (this.new_model.correction_photos_required == "x") {
            this.redErrorNotify(
              "<b>Invalid Input : Need Correction Photos is a Mandatory Field in Safety Observation </b>"
            );
          } else if (this.new_model.safety_observation_priority_id == "x") {
            this.redErrorNotify(
              "<b>Invalid Input : Safety Observation Priority is a Mandatory Field in Safety Observation </b>"
            );
          } else {
            this.redErrorNotify(
              "<b>Invalid Input : Please fill in all the Mandatory Fields in Safety Observation </b>"
            );
          }
        } else {
          if (this.safety_observation_item_mode == 'create') {
            this.model.safety_observation_items.push(this.new_model);
          } else if (this.safety_observation_item_mode == 'edit') {
            this.model.safety_observation_items[this.current_item_index] = this.new_model;
            this.current_item_index = null;
          }

          this.safety_observation_item_mode = 'create';

          this.closeNewObservationItem();
        }
      },
      getSafetyObservationStatus(status_id) {
        var findObj = this.safety_observation_status.find(obj => { return obj.status_id === status_id });
        if (typeof findObj !== 'undefined') {
          return findObj.status_code;
        } else {
          return status_id;
        }
      },
      loadAllSafetyObservationPhotos() {
        this.model.safety_observation_items.forEach((e) => {
          e['safety_observation_photos'].forEach((el) => {
            if (el['image']['url']) {
              axios.get(apiDomain + el['image']['url'], { headers: getHeader() })
              .then ( response => {
                if (response.status === 200)
                {
                  if (response.data.data.image && response.data.data.image) {
                    el['imageDataSrc'] = `data:${response.data.data.image};base64,${response.data.data.image}`;
                  } else {
                    el['imageDataSrc'] =  null;
                  }
                }
              })
              .catch ( error => {
                console.log(error.response.status)
              });
            }
          });
        });
      },
      // handleImageFileUpload() {
      //   let vm = this;
      //   this.new_model.safety_observation_photos.push(
      //     {
      //       photoFile: {},
      //       photoPreviewSrc: "",
      //       photoRemarks: "",
      //     },
      //   );
      //   console.log(this.$refs.imageFile.files[0]);
      //   this.new_model.safety_observation_photos.at(-1).photoFile = this.$refs.imageFile.files[0];
      //   console.log(this.new_model.safety_observation_photos.at(-1).photoFile);

      //   var reader = new FileReader();
      //   reader.onload = function(e) {
      //     vm.new_model.safety_observation_photos.at(-1).photoPreviewSrc = e.target.result;
      //   };

      //   reader.readAsDataURL(this.new_model.safety_observation_photos.at(-1).photoFile);

      // },
      deletePhoto(index){
        if (typeof this.new_model.safety_observation_photos[index]['safety_observation_item_file_id'] !== "undefined" ) {
          if (typeof this.new_model.safety_observation_item_files_to_be_deleted == "undefined") {
            this.new_model.safety_observation_item_files_to_be_deleted = [];
          }
          this.new_model.safety_observation_item_files_to_be_deleted.push(this.new_model.safety_observation_photos[index]['safety_observation_item_file_id']);
        }
        this.new_model.safety_observation_photos.splice(index, 1);
      },
      deleteObservationItem(item, index, button) {
        if (typeof this.model.safety_observation_items[index].safety_observation_item_id !== "undefined" ) {
          if (typeof this.model.safety_observation_items_to_be_deleted == "undefined") {
            this.model.safety_observation_items_to_be_deleted = [];
          }
          this.model.safety_observation_items_to_be_deleted.push(this.model.safety_observation_items[index].safety_observation_item_id);
        }
        this.model.safety_observation_items.splice(index, 1);
      },
      checkAndCreateConductingUsers() {
        let tempConductingUsers = [];
        this.conducting_users.forEach((item) => {
          if (item.userId != "") {
            tempConductingUsers.push({ userId: item.userId });
          }
        });
        this.model.conducting_users = tempConductingUsers;
      },
      // checkForRequiredData() {
      //   this.checkAndCreateConductingUsers();
      //   var checker = false;
      //   if (
      //     this.model.project_id == 'x' ||
      //     this.model.group_id == 'x' ||
      //     this.model.site_id == 'x' ||
      //     this.model.contractor_id == 'x' ||
      //     !this.model.safety_observation_create_date ||
      //     !this.model.safety_observation_create_time ||
      //     this.model.safety_observation_items.length < 1
      //   ) {
      //     if (this.model.project_id == 'x') {
      //       this.redErrorNotify(
      //         "<b>Invalid Input : Project is a Mandatory Field in Safety Observation </b>"
      //       );
      //     } else if (this.model.group_id == 'x') {
      //       this.redErrorNotify(
      //         "<b>Invalid Input : Project Group is a Mandatory Field in Safety Observation </b>"
      //       );          
      //     } else if (this.model.site_id == 'x') {
      //       this.redErrorNotify(
      //         "<b>Invalid Input : Project Site is a Mandatory Field in Safety Observation </b>"
      //       );          
      //     } else if (this.model.contractor_id == 'x') {
      //       this.redErrorNotify(
      //         "<b>Invalid Input : EPC (Project Site Contractor) is a Mandatory Field in Safety Observation </b>"
      //       );          
      //     } else if (!this.model.safety_observation_create_date) {
      //       this.redErrorNotify(
      //         "<b>Invalid Input : Date is a Mandatory Field in Safety Observation </b>"
      //       );          
      //     } else if (!this.model.safety_observation_create_time) {
      //       this.redErrorNotify(
      //         "<b>Invalid Input : Time is a Mandatory Field in Safety Observation </b>"
      //       );          
      //     } else if (this.model.safety_observation_items.length < 1) {
      //       this.redErrorNotify(
      //         "<b>Invalid Input : Safety Observation Item is a Mandatory Field in Safety Observation </b>"
      //       );
      //     } else {
      //       this.redErrorNotify(
      //         "<b>Invalid Input : Please fill in all the Mandatory Fields in Safety Observation </b>"
      //       );
      //     }        
      //   } else {
      //     console.log('passed all checks');
      //     checker = true;
      //   }
      //   return checker;
      // },
      saveAsDraft(){
        if (this.checkForRequiredData()) {
          console.log('save as draft');

          this.model.safety_observation_id = this.safety_observation_id;
          this.model.status = 0;

          let formData = jsonFormData(this.model);
          formData.append("_method", "put");

          console.log(typeof formData);

          const tokenData = JSON.parse(window.localStorage.getItem("authUser"));
          const headers = {
            Authorization: "Bearer " + tokenData.access_token,
          };

          axios.post(apiDomain + 'health_safety/safety_observation/'+ this.safety_observation_id, formData, { headers })
          .then((response) => {
            if (response.status === 200) {
              this.$notify({
              message:
                '<b>Safety Observation Successfully Updated and Saved As Draft.</b>',
              timeout: 10000,
              icon: 'ni ni-bell-55',
              type: 'default',
              });
              this.$router.back()
            }
          })
          .catch((error) => {
            this.errorFunction(error, "Update Safety Observation Draft");
          });

        }
      },
      // submitData(){
      //   if (this.checkForRequiredData()) {
      //     console.log('submit data');

      //     this.model.safety_observation_id = this.safety_observation_id;
      //     this.model.status = 1;

      //     let formData = jsonFormData(this.model);
      //     formData.append("_method", "put");

      //     console.log(typeof formData);

      //     const tokenData = JSON.parse(window.localStorage.getItem("authUser"));
      //     const headers = {
      //       Authorization: "Bearer " + tokenData.access_token,
      //     };

      //     axios.post(apiDomain + 'health_safety/safety_observation/'+ this.safety_observation_id, formData, { headers })
      //     .then((response) => {
      //       if (response.status === 200) {
      //         this.$notify({
      //         message:
      //           '<b>Safety Observation Successfully Updated and Submitted.</b>',
      //         timeout: 10000,
      //         icon: 'ni ni-bell-55',
      //         type: 'default',
      //         });
      //         this.$router.back()
      //       }
      //     })
      //     .catch((error) => {
      //       this.errorFunction(error, "Update Safety Observation Submit");
      //     });

      //   }
      // },
      getContractorId(){
        axios.get(apiDomain + 'lookup/contractor.contractor_id/' + this.user_id, { headers: getHeader() })
          .then ( response => {
          if (response.status === 200)
          {
            this.contractor_id = response.data.contractor_id;
            this.getProjectListing();
          }
          })
          .catch ( error => {
            this.errorFunction(error, 'Contractor Id')
          });
      },
    },
  }
</script>

<style>
.form-control {
  color: black;
}
.form-row {
  color: black;
}
select option {
  color: black;
}
textarea {
  color: black;
}
</style>

