<template>
  <b-card no-body>
    <!-- Card header -->
    <b-card-header >
      <!-- Title -->
      <h5 v-if="model.project_code == null" class="h3 mb-0">Milestone Progress - Create / Edit Milestone </h5>
      <h5 v-else class="h3 mb-0">Milestone Progress for ({{ model.project_code }})</h5>
      <div class="text-right">
          <base-button size="sm" type="primary" @click="editMilestone()">Create / Edit Milestone</base-button>
      </div>
    </b-card-header>
    <!-- Card body -->
    <b-card-body >
      <!-- List group -->
      <b-list-group flush class="list my--3">
        <b-list-group-item v-for="(item, index) in project_milestones"
            :key="index"
            class="px-0">
          <b-row align-v="center">
            <b-col md="auto" >
              <!-- Avatar -->
              <span class="completion mr-0 ">{{item.milestone_progress}}%</span>
            </b-col>
            <b-col >
              <h5>{{item.milestone_code}}</h5>
              <base-progress v-if="item.milestone_progress > 0 && item.milestone_progress < 50" min-width="30px" type="danger" :value="item.milestone_progress"/>
              <base-progress v-else-if="item.milestone_progress >= 50 && item.milestone_progress <= 90" min-width="30px" type="info" :value="item.milestone_progress"/>
              <base-progress v-else min-width="30px" type="success" :value="item.milestone_progress"/>
            </b-col>
          </b-row>
        </b-list-group-item>
      </b-list-group>
    </b-card-body>
  </b-card>
</template>
<script>
  import {LoginURL, clientId, clientSecret, apiDomain, getHeader, getcurrentDate} from '@/assets/js/config.js'
  import axios from 'axios'

  export default {
    created()
    {
      this.model.group_id = this.$route.params.id
      axios.get(apiDomain + 'project/project_group/' + this.model.group_id, { headers: getHeader() })
      .then ( response => {
        if (response.status === 200)
        {
          this.model.project_id = response.data.data[0].project.project_id

          axios.get(apiDomain + 'project/project_milestone.show_by_project/' + this.model.project_id, { headers: getHeader() })
          .then ( response => {
            if (response.status === 200)
            {
              this.model.project_code = response.data.data[0].project.project_code
              this.project_milestones = response.data.data
            }
          })
          .catch ( error => {
            console.log(error.response.status)
          } );

        }
      })
      .catch ( error => {
        console.log(error.response.status)
      } );


    },
    data() {
      return {
        model:
        {
            group_id: null,
            project_id: null,
            project_code: null,
            milestone_code: null,
        },
        project_milestones: [],
      }
    },
    methods:
    {
        editMilestone()
        {
            this.$router.push({path: '/projectMilestoneListing/' + this.model.project_id })
        },
    }
  }
</script>
