
export default [
  {
    approve_by: 'Cookie Monster',
    role : 'Project Manager',
    status: 'IFA Status',
    code: 'Pending',
    comment: '',
  },

  {
    approve_by: 'James',
    role : 'Project Engineer',
    status: 'IFA Status',
    code: 'Code 3',
    comment: 'This is the sample of the comment if code 2 is apply for Code and total comment will be 500 character.',
  },

  {
    approve_by: 'Orion',
    role : 'Engineer',
    status: 'IFA Status',
    code: 'Code 1',
    comment: '',
  },

  {
    approve_by: 'Karen',
    role : 'Safety',
    status: 'IFA Status',
    code: 'Code 2',
    comment:  'This is the sample of the comment if code 2 is apply for Code and total comment will be 500 character.',
  },

  {
    approve_by: 'Michelle',
    role : 'QA / QC',
    status: 'IFA Status',
    code: 'Code 1',
    comment: '',
  },
]
