<template>
  <card>
    <h3 slot="header" class="mb-0">Edit Document Template Detail for ({{ document_template_name }}) </h3>
    <h4 slot="header" class="mb-0">(Selected Milestone Template : {{ milestone_template.milestone_template_name }})</h4>
    <div class="text-right">
    </div>
    <form class="needs-validation"
          @submit.prevent="submit">

      <div class="form-row">
        <b-col md="6">
          <base-input label="Document Title"
                      name="Document Title"
                      placeholder="Document Title"
                      v-model="model.document_template_title">
          </base-input>
        </b-col>

        <b-col md="6">
          <base-input label="Document Category"
                      name="Document Category"
                      v-model="model.document_type_id">
            <select class="form-control" v-model="model.document_category_id">
              <option Selected disabled value="x">-- Please Select A Document Category --</option>
              <option v-for="item in document_category" :key="item.document_category_id" v-bind:value="item.document_category_id">{{ item.document_category }}</option>
            </select>
          </base-input>
        </b-col>

        <b-col md="6">
          <base-input label="Document Type (Only ONE-TIME Type for IFI Document) *"
                      name="Document Type"
                      required
                      v-model="model.document_type_id" v-if="model.document_category_id == '2'">
            <select class="form-control" v-model="model.document_type_id" >
              <option Selected disabled value="x">-- Please Select A Document Type --</option>
              <option value="1">ONE-TIME</option>
            </select>
          </base-input>
          <base-input label="Document Type"
                      name="Document Type"
                      v-model="model.document_type_id" v-else>
            <select class="form-control" v-model="model.document_type_id">
              <option Selected disabled value="x">-- Please Select A Document Type --</option>
              <option v-for="item in document_type" :key="item.document_type_id" v-bind:value="item.document_type_id">{{ item.document_type_code }} - {{ item.document_type_info }}</option>
            </select>
          </base-input>
        </b-col>

        <b-col md="5">
          <base-input :label="'Milestone From Template - '+ milestone_template.milestone_template_name"
                      name="Milestone From Template"
                      v-model="model.milestone_template_detail_id">
            <select class="form-control" v-model="model.milestone_template_detail_id">
              <option Selected disabled value="x">-- Please Select A Milestone --</option>
              <option v-for="item in milestone_template_detail" :key="item.milestone_template_detail_id" v-bind:value="item.milestone_template_detail_id">
                 {{ item.milestone_template_title }}</option>
            </select>
          </base-input>
        </b-col>

        <b-col md="1">
          <br>
          <base-button size="m" type="primary" @click="checkMilestoneDetail()">Milestone Details</base-button>
        </b-col>
        </div>

        <div v-if="model.document_type_id == '2'" class="form-row">
        <b-col md="6">
          <base-input label="Recurring Interval"
                      name="Recurring Interval"
                      v-model="model.recurring_interval_id">
            <select class="form-control" v-model="model.recurring_interval_id">
              <option Selected disabled value="x">-- Please Select A Recurring Interval --</option>
              <option value="1">Daily</option>
              <option value="2">Weekly</option>
              <option value="3">Month</option>
            </select>
          </base-input>
        </b-col>
        </div>

        <div class="form-row">
        <b-col md="4">
          <b-form-checkbox
            id="Mandatory"
            v-model="model.document_template_mandatory"
            name="Mandatory"
            value="1"
            unchecked-value="0">
            <b>Document Mandatory</b>
          </b-form-checkbox>
        </b-col>

        <b-col md="4">
          <b-form-checkbox
            id="Active-Status"
            v-model="model.active_status"
            name="Active-Status"
            value="1"
            unchecked-value="0">
            <b>Active Status</b>
          </b-form-checkbox>
        </b-col>

      </div>
      <hr>

      <div class="form-row">
         <b-col md="12">
            <b>Require Approval From</b>
        </b-col>
      </div>
      <br>
      <div class="form-row">
        <b-col md="1.5">
          <b-form-checkbox
            id="req_approval_project_owner"
            v-model="model.req_approval_project_owner"
            name="req_approval_project_owner"
            value="1"
            unchecked-value="0">
            <b>Project Owner</b>
          </b-form-checkbox>
        </b-col>

        <b-col md="1.5">
          <b-form-checkbox
            id="req_approval_project_manager"
            v-model="model.req_approval_project_manager"
            name="req_approval_project_manager"
            value="1"
            unchecked-value="0">
            <b>Project Manager</b>
          </b-form-checkbox>
        </b-col>

        <b-col md="1.5">
          <b-form-checkbox
            id="req_approval_project_engineer"
            v-model="model.req_approval_project_engineer"
            name="req_approval_project_engineer"
            value="1"
            unchecked-value="0">
            <b>Project Engineer</b>
          </b-form-checkbox>
        </b-col>

        <b-col md="1.5">
          <b-form-checkbox
            id="req_approval_engineer"
            v-model="model.req_approval_engineer"
            name="req_approval_engineer"
            value="1"
            unchecked-value="0">
            <b>Engineer</b>
          </b-form-checkbox>
        </b-col>

        <b-col md="1.5">
          <b-form-checkbox
            id="req_approval_qa_qc"
            v-model="model.req_approval_qa_qc"
            name="req_approval_qa_qc"
            value="1"
            unchecked-value="0">
          <b>QA / QC</b>
          </b-form-checkbox>
        </b-col>

        <b-col md="1.5">
          <b-form-checkbox
            id="req_approval_onm"
            v-model="model.req_approval_onm"
            name="req_approval_onm"
            value="1"
            unchecked-value="0">
          <b>O & M</b>
          </b-form-checkbox>
        </b-col>

        <b-col md="1.5">
          <b-form-checkbox
            id="req_approval_safety"
            v-model="model.req_approval_safety"
            name="req_approval_safety"
            value="1"
            unchecked-value="0">
          <b>Safety</b>
          </b-form-checkbox>
        </b-col>

        <b-col md="1.5">
          <b-form-checkbox
            id="req_approval_planner"
            v-model="model.req_approval_planner"
            name="req_approval_planner"
            value="1"
            unchecked-value="0">
          <b>Planner</b>
          </b-form-checkbox>
        </b-col>

        <b-col md="1.5">
          <b-form-checkbox
            id="req_approval_purchasing"
            v-model="model.req_approval_purchasing"
            name="req_approval_purchasing"
            value="1"
            unchecked-value="0">
          <b>Purchasing</b>
          </b-form-checkbox>
        </b-col>

        <b-col md="1.5">
          <b-form-checkbox
            id="req_approval_admin"
            v-model="model.req_approval_admin"
            name="req_approval_admin"
            value="1"
            unchecked-value="0">
          <b>Admin</b>
          </b-form-checkbox>
        </b-col>
      </div>
      <br><br>

      <base-button type="primary" @click="createData()">Update / Save</base-button>
      <base-button type="primary" @click="$router.back()">Cancel</base-button>
    </form>
  </card>
</template>
<script>
  import BaseSlider from '@/components/BaseSlider'
  import {LoginURL, clientId, clientSecret, apiDomain, getHeader} from '@/assets/js/config.js'
  import axios from 'axios'
  import ErrorFunctionMixin from '@/assets/js/errorFunctionMix.js'

  export default {
    mixins: [ErrorFunctionMixin],
    components: {
      BaseSlider
    },
    created()
    {
      this.model.document_template_detail_id = this.$route.params.id

      axios.get(apiDomain + 'template/document_template_details/'+this.model.document_template_detail_id, { headers: getHeader() })
      .then ( response => {
        if (response.status === 200)
        {
          this.model.document_template_detail_id = response.data.data[0].document_template_detail_id
          this.model.document_template_id = response.data.data[0].document_template.document_template_id
          this.model.document_template_classfication_code = response.data.data[0].document_template_classfication_code
          this.model.document_template_title = response.data.data[0].document_template_title
          this.model.document_type_id = response.data.data[0].document_type.document_type_id
          this.model.document_category_id = response.data.data[0].document_category.document_category_id
          this.model.milestone_template_detail_id = response.data.data[0].milestone_template_detail.milestone_template_detail_id
          this.model.req_approval_project_owner = response.data.data[0].req_approval_project_owner
          this.model.req_approval_project_manager = response.data.data[0].req_approval_project_manager
          this.model.req_approval_project_engineer = response.data.data[0].req_approval_project_engineer
          this.model.req_approval_engineer = response.data.data[0].req_approval_engineer
          this.model.req_approval_qa_qc = response.data.data[0].req_approval_qa_qc
          this.model.req_approval_safety = response.data.data[0].req_approval_safety
          this.model.req_approval_planner = response.data.data[0].req_approval_planner
          this.model.req_approval_purchasing = response.data.data[0].req_approval_purchasing
          this.model.req_approval_admin = response.data.data[0].req_approval_admin
          this.model.req_approval_onm = response.data.data[0].req_approval_onm
          this.model.recurring_interval_id = response.data.data[0].project_document_recurring.recurring_interval_id

          if ( response.data.data[0].active_status == 'ACTIVE' )
          {
            this.model.active_status = 1
          }
          else
          {
            this.model.active_status = 0
          }

          if ( response.data.data[0].document_template_mandatory == 'YES' )
          {
            this.model.document_template_mandatory = 1
          }
          else
          {
            this.model.document_template_mandatory = 0
          }
          this.document_template_name = response.data.data[0].document_template.document_template_name

          axios.get(apiDomain + 'template/document_template/'+this.model.document_template_id, { headers: getHeader() })
          .then ( response => {
            if (response.status === 200)
            {
              this.milestone_template.milestone_template_id = response.data.data[0].milestone_template.milestone_template_id

              axios.get(apiDomain + 'template/milestone_template_details.show_by_template/'+this.milestone_template.milestone_template_id, { headers: getHeader() })
              .then ( response => {
                if (response.status === 200)
                {
                  this.milestone_template_detail = response.data.data
                  this.milestone_template.milestone_template_name = response.data.data[0].milestone_template.milestone_template_name
                }
              })
              .catch ( error => {
                  console.log(error.response.status)
                  this.errorFunction(error, "Milestone Template Details Listing")
              });

            }
          })
          .catch ( error => {
              console.log(error.response.status)
              this.errorFunction(error, "Document Template Information")
          });

        }
      })
      .catch ( error => {
        console.log(error.response.status)
        this.errorFunction(error, "Document Template Details Information")
      } );


      axios.get(apiDomain + 'lookup/document_type', { headers: getHeader() })
      .then ( response => {
        if (response.status === 200)
        {
          this.document_type = response.data.data
        }
      })
      .catch ( error => {
          console.log(error.response.status)
      });

      axios.get(apiDomain + 'lookup/document_category', { headers: getHeader() })
      .then ( response => {
        if (response.status === 200)
        {
          this.document_category = response.data.data
        }
      })
      .catch ( error => {
          console.log(error.response.status)
      });

    },
    data() {
      return {
        model: {
          document_template_detail_id: null,
          document_template_id: null,
          document_template_classfication_code: null,
          document_template_title: null,
          document_template_mandatory: 1,
          document_type_id: 'x',
          document_category_id: 'x',
          recurring_interval_id: 'x',
          milestone_template_detail_id: 'x',
          active_status: 1,
          req_approval_project_owner: 1,
          req_approval_project_manager: 1,
          req_approval_project_engineer: 1,
          req_approval_engineer: 1,
          req_approval_qa_qc: 1,
          req_approval_onm: 1,
          req_approval_safety: 1,
          req_approval_planner: 1,
          req_approval_purchasing: 1,
          req_approval_admin: 1,
        },
        milestone_template_detail: [],
        document_type: [],
        document_category: [],
        document_template_name: null,
        milestone_template: {
          milestone_template_id: null,
          milestone_template_name: null,
        },
      }
    },
    methods: {

      checkMilestoneDetail()
      {
        if (this.milestone_template.milestone_template_id == 'x')
        {
          this.$notify({
            message:
              '<b>Invalid Input : Milestone Template </b> - Please select a Milestone Template before dive into the Details',
            timeout: 10000,
            icon: 'ni ni-bulb-61',
            type: 'danger',
          });
        }
        else
        {
          this.$router.push({path: '/templates/milestoneTemplateDetailListing/'+this.milestone_template.milestone_template_id })
        }
      },
      createData(){
        if ( !this.model.document_template_title || this.model.document_type_id == 'x' || this.model.milestone_template_detail_id == 'x' || this.model.document_category_id == 'x')
        {
          this.$notify({
            message:
              '<b>Invalid Input : All Column are Mandatory in Document Template Details </b> - These field are required.',
            timeout: 10000,
            icon: 'ni ni-bulb-61',
            type: 'danger',
          });
        }
        else
        {
          axios.put(apiDomain + 'template/document_template_details/'+this.model.document_template_detail_id, this.model, { headers: getHeader() })
          .then ( response => {
            if (response.status === 200)
            {
                this.$notify({
                message:
                  '<b>Edit Document Template Details : ' + this.model.document_template_title + '</b> - Successfully Updated.',
                timeout: 10000,
                icon: 'ni ni-bell-55',
                type: 'default',
                });
                this.$router.back()
            }
          })
          .catch ( error => {
              console.log(error.response.data.errors)
              this.errorFunction(error, "Update Document Template Details")
          });
        }
      },
    }
  }
</script>
<style>
</style>
