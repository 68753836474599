<template>
  <b-card no-body>
    <!-- Card header -->
    <b-card-header >
      <!-- Title -->
      <h5 class="h5 mb-0">Site Document Status (Total : {{ totalTask }})</h5>
    </b-card-header>
    <!-- Card body -->
    <b-card-body >
      <!-- List group -->
      <b-list-group flush class="list my--3">
        <b-list-group-item v-for="(item, index) in list"
            :key="index"
            class="px-0">
          <b-row align-v="center">
            <b-col md="auto" >
              <!-- Avatar -->
              <span class="completion mr-0 ">{{item.progress}}</span>
            </b-col>
            <b-col >
              <h5>{{item.name}}</h5>
              <base-progress class="progress-xs mb-0"
                             :type="item.color"
                             :value="item.progress">
              </base-progress>
            </b-col>
          </b-row>
        </b-list-group-item>
      </b-list-group>
    </b-card-body>
  </b-card>
</template>
<script>
  export default {
    name: 'site-document-status',
    data() {
      return {
        totalTask: 110,
        list: [
          {
            name: 'Wating For Approval',
            color: 'green',
            progress: 50,
          },
          {
            name: 'Required Revision',
            color: 'teal',
            progress: 30,
          },
          {
            name: 'Approved',
            color: 'red',
            progress: 15
          },
        ]
      }
    }
  }
</script>
