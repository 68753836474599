<style>
a:link {
  color: white;
  background-color: transparent;
  text-decoration: none;
}
a:visited {
  color: white;
  background-color: transparent;
  text-decoration: none;
}
a:hover {
  color: white;
  background-color: transparent;
  text-decoration: underline;
}
a:active {
  color: white;
  background-color: transparent;
  text-decoration: underline;
}
</style>
<template>

  <div class="content">
    <base-header class="pb-6">
      <b-row align-v="center" class="py-4">
        <b-col cols="7" lg="6">
          <h6 class="h2 text-white d-inline-block mb-0">Site Document Details Info</h6>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <route-bread-crumb></route-bread-crumb>
          </nav>
        </b-col>
        <b-col lg="6" cols="5" class="text-right">

          <base-button size="sm" type="neutral" @click="closeWindow()">Back / Close</base-button>
        </b-col>
      </b-row>
    </base-header>
    <b-container fluid class="mt--6">
      <div>
      <b-row>
        <b-col>
          <div class="card-wrapper">
            <document-view-form ref="documentViewFormComponent"></document-view-form>
          </div>
        </b-col>
      </b-row>
      </div>

      <div v-if="model.assign_to_user == login_user_role">
        <b-row>
          <b-col>
            <div class="card-wrapper">
              <site-document-details-new-form @myEvent="refreshData()"></site-document-details-new-form>
            </div>
          </b-col>
        </b-row>
      </div>

      <div>
        <card class="no-border-card" body-classes="px-0 pb-1" footer-classes="pb-2">
          <template slot="header">
            <h3 class="mb-0" v-if="model.document_recurring_date == null">Uploaded Document Details for ({{ document_information }}), &nbsp;Status : {{ model.document_status }} <span v-if="model.completed_flag == 1">(COMPLETED)</span></h3>
            <h3 class="mb-0" v-else>Uploaded Document Details for ({{ document_information }}), &nbsp;Recurring Date : {{ model.document_recurring_date }} <span v-if="model.completed_flag == 1">(COMPLETED)</span></h3>
          </template>
          <div>
            <b-col cols="12" class="d-flex justify-content-center justify-content-sm-between flex-wrap">
              <el-select
                class="select-primary pagination-select"
                v-model="pagination.perPage"
                placeholder="Per page">
                <el-option
                  class="select-primary"
                  v-for="item in pagination.perPageOptions"
                  :key="item"
                  :label="item"
                  :value="item">
                </el-option>
              </el-select>

              <div>
                <base-input v-model="searchQuery"
                            prepend-icon="fas fa-search"
                            placeholder="Search...">
                </base-input>
              </div>
            </b-col>

            <el-table :data="queriedData"
                      row-key="project_id"
                      header-row-class-name="thead-light"
                      @sort-change="sortChange">

              <el-table-column label="Document Information / versioning"
                             min-width="130px"
                             prop="document_version"
                             sortable>
                <template v-slot="{row}">
                    <b-media no-body class="align-items-center">
                        <b-media-body>
                            <span class="font-weight-600 name mb-0 text-sm">{{row.document_version}}</span>
                        </b-media-body>
                    </b-media>
                </template>
              </el-table-column>

              <el-table-column label="Upload By"
                             min-width="120px"
                             prop="Upload By"
                             sortable>
                <template v-slot="{row}">
                    <b-media no-body class="align-items-center">
                        <b-media-body>
                            <span class="font-weight-600 name mb-0 text-sm">{{row.created_by.name}}</span>
                        </b-media-body>
                    </b-media>
                </template>
              </el-table-column>

              <el-table-column label="Upload Date"
                             min-width="120px"
                             prop="upload_time"
                             sortable>
                <template v-slot="{row}">
                    <b-media no-body class="align-items-center">
                        <b-media-body>
                            <span class="font-weight-600 name mb-0 text-sm">{{row.upload_time}}</span>
                        </b-media-body>
                    </b-media>
                </template>
              </el-table-column>

              <el-table-column min-width="100px" align="right" label="Actions">
                <div slot-scope="{$index, row}" class="d-flex">
                  <base-button
                    @click.native="donwloadFile($index, row)" size="sm" type="primary">
                    Download File
                  </base-button>
                  <base-button v-if="row.site_document.document_category.document_category_id == '1'"
                    @click.native="revisionDetails($index, row)" size="sm" type="primary">
                    Approval Details
                  </base-button>
                </div>
              </el-table-column>
            </el-table>
          </div>
          <div
            slot="footer"
            class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap">
            <div class="">
              <p class="card-category">
                Showing {{ from + 1 }} to {{ to }} of {{ total }} entries

                <span v-if="selectedRows.length">
                  &nbsp; &nbsp; {{selectedRows.length}} rows selected
                </span>
              </p>
            </div>
            <base-pagination
              class="pagination-no-border"
              :current="pagination.currentPage"
              :per-page="pagination.perPage"
              :total="total"
              @change="paginationChanged($event)">
            </base-pagination>
             <!-- <div>
              <br>
              <h5 class="text-danger"> Note :
                  <i>
                    During this Demo, we will upload the file to our demo server, not SEMCORP file server and we only accept file size below around 10 MB and these are the file type we accept : jpeg, jpg, png, gif, pdf, dwg, doc, docx, xlsx, ppt, pptx.
                  </i>
              </h5>
            </div> -->
          </div>
        </card>
      </div>

    </b-container>

    <modal :show.sync="modals.approval_reject"
                size="lg"
                body-classes="p-0">
      <card type="secondary"
            header-classes="bg-transparent pb-5"
            body-classes="px-lg-5 py-lg-5"
            class="border-0 mb-0">
        <template>
          <div class="text-center text-muted mb-4">
            <h3>Approval / Reject ( PV Layout ) by Project Manager ( Cookie Monster) </h3>
            <!-- <h3>By Project Manager : Cookie Monster</h3> -->
            <h3>Status : IFA Status</h3>
          </div>
          <b-form role="form">
            <base-input label="Code For IFA/IFC/SBC Status" name="Task Site" v-model="code_type">
              <select class="form-control" v-model="code_type">
                <option value='0' disabled>-- Please Select A Code --</option>
                <option value='1'>Code 1 : Approved</option>
                <option value='2'>Code 2 : Approved With Comment</option>
                <option value='3'>Code 3 : Require Revision With Comment`</option>
                <option value='4'>Code 4 : Reject</option>
              </select>
            </base-input>
            <base-input label="Comment" placeholder="Status AComment" name="Task Template" v-if="code_type == '2' || code_type == '3'" v-model="comment">

            </base-input>

            <h5 class="text-danger"> Note : <br>
              <i>
              1. Only Code 2 & 3 allow to comment on the status.

              </i>
            </h5>
            <div class="text-center">
              <base-button type="primary" class="my-4" @click="notifyVueFormTask()">Update / Save</base-button>
              <base-button type="primary" class="my-4" @click="modals.approval_reject = false">Cancel</base-button>
            </div>
          </b-form>
        </template>
      </card>
    </modal>

    </div>


</template>
<script>
import { Table, TableColumn, Select, Option } from 'element-ui';
import RouteBreadCrumb from '@/components/Breadcrumb/RouteBreadcrumb'
import { BasePagination } from '@/components';
import standardPaginationMix from '@/assets/js/standardPaginationMix.js'
import Fuse from 'fuse.js';
import axios from 'axios'
import DocumentViewForm from './SiteDocumentViewForm.vue';
import {LoginURL, clientId, clientSecret, apiDomain, getHeader, fuseData, getUserInfo, fileServer} from '@/assets/js/config.js'
import SiteDocumentDetailsNewForm from './SiteDocumentDetailsNewForm';
import { Modal } from '@/components';
import ErrorFunctionMixin from '@/assets/js/errorFunctionMix.js'

export default {
  mixins: [standardPaginationMix, ErrorFunctionMixin],
  components: {
    BasePagination,
    Modal,
    RouteBreadCrumb,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    "document-view-form": DocumentViewForm,
    "site-document-details-new-form": SiteDocumentDetailsNewForm,

  },
  created()
  {
      this.getCreateData()
  },
  data() {
    return {
      login_user_role: null,
      model: {
        type: null,
        document_id: null,
        site_document_recurring_id: null,
        document_recurring_date: null,
        document_status: null,
        assign_to_user: null,
        completed_flag: 0,
      },
      propsToSearch:
      [
        'document_classification_code',
        'project.project_code',
        'group.group_code',
        'site.site_code',
        'milestone.milestone_code',
        'document_type.document_type_code',
        'document_version',
        'document_mandatory',
        'status.status_code',
      ],
      code_type: 0,
      tableColumns: [],
      tableData: [],
      selectedRows: [],
      modals: {
          approval_reject: false,
      },
      document_information: null,
      comment: null,
    };
  },
  methods: {
    revisionDetails(index, row)
    {
      this.$router.push({path: '/siteDocumentApprovalStatus/' + row.site_document_upload_id})
    },
    donwloadFile(index, row)
    {
        axios.get(fileServer + 'document/site_document_upload.download/' + row.site_document_upload_id, { headers: getHeader(),  responseType: 'blob'})
        .then((response) => {
          var fileURL = window.URL.createObjectURL(new Blob([response.data]));
          var fileLink = document.createElement('a');

          fileLink.href = fileURL;
          fileLink.setAttribute('download', row.document_file);
          document.body.appendChild(fileLink);

          fileLink.click();
        });
    },
    refreshData()
    {
        this.getCreateData()
        //this.$refs.documentViewFormComponent.getViewData()
    },
    getCreateData()
    {
      var userInfo = getUserInfo();
      this.login_user_role = userInfo.id

      this.model.type = this.$route.params.id2
      if (this.model.type == "RECURRING")
      {
        this.model.site_document_recurring_id = this.$route.params.id
        axios.get(apiDomain + 'document/site_document_upload.show_by_document_recurring/'+this.model.site_document_recurring_id, { headers: getHeader() })
        .then ( response => {
          if (response.status === 200)
          {
              this.tableData = response.data.data
              this.model.document_id = response.data.data[0].site_document.site_document_id
              this.document_information = response.data.data[0].site_document.document_title
              this.fuseData()
          }
        })
        .catch ( error => {
          this.errorFunction(error, "Site Document Upload Information")
        } );

        axios.get(apiDomain + 'document/site_document_recurring/'+this.model.site_document_recurring_id, { headers: getHeader() })
        .then ( response => {
          if (response.status === 200)
          {
            this.model.document_recurring_date = response.data.data[0].show_document_recurring_date
            this.model.document_status = response.data.data[0].current_status.status_code
            this.model.assign_to_user = response.data.data[0].site_document.assign_to_user
            this.model.completed_flag = response.data.data[0].site_document.completed_flag
          }
        })
        .catch ( error => {
          this.errorFunction(error, "Site Document Recurring Information")
        } );
      }
      else
      {
        this.model.document_id = this.$route.params.id

        axios.get(apiDomain + 'document/site_document_upload.show_by_document/' + this.model.document_id, { headers: getHeader() })
        .then ( response => {
        if (response.status === 200)
        {
          this.tableData = response.data.data
          this.document_information = response.data.data[0].site_document.document_title
          this.fuseData()
        }
        })
        .catch ( error => {
          this.errorFunction(error, "Site Document Uploads Information")
        });

        axios.get(apiDomain + 'document/site_document/'+this.model.document_id, { headers: getHeader() })
        .then ( response => {
          if (response.status === 200)
          {
            this.model.document_status = response.data.data[0].document_status.status_code
            this.model.assign_to_user = response.data.data[0].assign_to_user.id
            this.model.completed_flag = response.data.data[0].completed_flag
          }
        })
        .catch ( error => {
          this.errorFunction(error, "Project Document Recurring Information")
        } );
      }
    },
    viewComment(index, row)
    {
      this.$router.push({path: '/documentCommentListing/' + row.document_detail_id })
    },
    newUpload()
    {
      this.$router.push({path: '/documentDetailsNew/' + this.model.document_id })
    },
    fuseData()
    {
        this.fuseSearch = new Fuse(this.tableData, {
        keys: this.propsToSearch,
        threshold: 0.3
        });
    },
    paginationChanged(page)
    {
        this.pagination.currentPage = page
    },
    editDocument(index, row)
    {
        this.$router.push({path: 'documentEdit/' + this.document_id })
    },
    selectionChange(selectedRows)
    {
        this.selectedRows = selectedRows
    }
  }
};
</script>
<style>
  .no-border-card .card-footer{
    border-top: 0;
  }
</style>
