
export default [
  {
    recurring_date: '10-Nov-2021',
    recurring_interval: 'Daily',
    status: 'SBC Status',
    total_file: 6,
  },
  {
    recurring_date: '11-Nov-2021',
    recurring_interval: 'Daily',
    status: 'SBC Status',
    total_file: 2,
  },
  {
    recurring_date: '12-Nov-2021',
    recurring_interval: 'Daily',
    status: 'IFC Status',
    total_file: 1,
  },
  {
    recurring_date: '13-Nov-2021',
    recurring_interval: 'Daily',
    status: 'IFA Status',
    total_file: 0,
  },
]
