<template>
    <div>
        <base-header class="pb-6">
            <b-row class="py-4">
                <b-col cols="6">
                    <h6 class="h2 text-white d-inline-block mb-0">{{ title }}</h6>
                </b-col>
                <b-col cols="6" class="text-right">
                    <base-button size="sm" type="neutral" @click="$router.replace('Listing')">Back</base-button>
                </b-col>
            </b-row>
        </base-header>

        <b-container fluid class="mt--6">
            <div class="card-wrapper">
                <card>
                    <!-- Card header -->
                    <h3 slot="header" class="mb-0">New Template</h3>
                    <validation-observer v-slot="{ handleSubmit }">
                        <form class="needs-validation" @submit.prevent="handleSubmit(saveData)">

                            <b-form-row>
                                <b-col>
                                    <validation-provider name="Name" rules="required" v-slot="{ classes, errors }">
                                        <b-form-group label="Name" :invalid-feedback="errors[0]">
                                            <input type="text" class="form-control" :class="classes" v-model="record.name" maxlength="100">
                                        </b-form-group>
                                    </validation-provider>
                                </b-col>
                            </b-form-row>

                            <b-form-row>
                                <b-col>
                                    <br>
                                    <validation-provider name="Type" rules="required" v-slot="{ classes, errors }">
                                        <b-form-group label="Type *" :invalid-feedback="errors[0]">
                                            <!--<input type="text" class="form-control" :class="classes" v-model="record.type" maxlength="100">-->
                                            <select class="form-control" v-model="record.type">
                                                <option value="goods">Goods</option>
                                                <option value="services">Services</option>
                                            </select>
                                        </b-form-group>
                                    </validation-provider>
                                </b-col>
                            </b-form-row>
                            <br>

                            <button class="btn btn-primary" v-if="access.create">Create</button>
                            <button class="btn btn-primary" type="button" @click="$router.replace('Listing')">Cancel</button>
                        </form>
                    </validation-observer>
                </card>

                <card v-show="addNewCriteria">
                    <CriteriaQuestion
                            @closeCriteria="onCloseCriteria"
                            @addedCriteria="appendCriteria"
                            @updatedCriteria="onUpdatedCriteria"
                    />
                </card>

               <div v-if="showItemError" :value="true" class="alert alert-primary" role="alert">
                    <strong>Error!</strong> * Must have at lease One(1) item.
                </div>
                <div v-if="showLevelError" :value="true" class="alert alert-primary" role="alert">
                    <strong>Error!</strong> * Missing number(s) in order numbers sequence.
                </div>
                <div v-if="showDuplicateError" :value="true" class="alert alert-primary" role="alert">
                    <strong>Error!</strong> * There are duplicates value in criteria list (same Order No).
                </div>

                <card>
                    <div>
                        <h3>
                            Questions
                            <base-button v-show="!addNewCriteria" style="float: right;" type="primary" @click="addCriteria()">
                                Add New Item
                            </base-button>
                        </h3>
                    </div>

                    <div v-if="templateItems.length > 0" class="table-responsive">
                        <table>
                            <tbody>
                                <tr v-for="(item, index) in templateItems" :key="index">
                                    <td width="10%" style="vertical-align: top;">
                                        <base-input
                                            v-model="item.order_no"
                                            label="No."
                                            placeholder=" "
                                            class="border"
                                            readonly
                                        ></base-input>
                                    </td>
                                    <td width="60%">
                                        <base-input
                                                v-model="item.title"
                                                label="Criteria"
                                                placeholder=" "
                                                class="border"
                                                readonly
                                        ></base-input>

                                        <b-form-textarea
                                                id="textarea"
                                                v-model="item.description"
                                                placeholder=""
                                                rows="5"
                                                readonly
                                                class="border"
                                        ></b-form-textarea>
                                    </td>
                                    <td width="15%">
                                        <base-input
                                            v-model="item.type"
                                            label="Input Type"
                                            placeholder=" "
                                            box
                                            readonly
                                        ></base-input>
                                    </td>
                                    <td width="10%">
                                        <base-input
                                            v-model="item.is_required ? 'YES': 'NO'"
                                            label="Is Required?"
                                            placeholder=" "
                                            box
                                            readonly
                                        ></base-input>
                                    </td>
                                    <td width="5%">
                                        <div class="form-row">
                                            <base-button size="sm" type="primary" @click="editCriteria(item)">Edit</base-button>
                                            <base-button size="sm" type="danger" @click="deleteItem(item)">Remove</base-button>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div v-if="newTemplateItems.length > 0" class="table-responsive">
                        <table>
                            <tbody>
                            <tr v-for="(item, index) in newTemplateItems" :key="index">
                                <td width="10%" style="vertical-align: top;">
                                    <base-input
                                            v-model="item.number"
                                            label="No."
                                            placeholder=" "
                                            class="border"
                                            readonly
                                    ></base-input>
                                </td>
                                <td width="50%">
                                    <base-input
                                            v-model="item.title"
                                            label="Criteria"
                                            placeholder=" "
                                            class="border"
                                            readonly
                                    ></base-input>

                                    <b-form-textarea
                                            id="textarea"
                                            v-model="item.description"
                                            placeholder=""
                                            rows="5"
                                            readonly
                                            class="border"
                                    ></b-form-textarea>
                                </td>
                                <td width="15%">
                                    <base-input
                                            v-model="item.type"
                                            label="Input Type"
                                            box
                                            readonly
                                    ></base-input>
                                </td>
                                <td width="10%">
                                    <base-input
                                            v-model="item.is_required ? 'YES': 'NO'"
                                            label="Is Required?"
                                            placeholder=" "
                                            box
                                            readonly
                                    ></base-input>
                                </td>
                                <td width="5%">
                                    <div class="form-row">
                                        <base-button size="sm" type="danger" @click="deleteItem(item)">Remove</base-button>
                                    </div>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </card>
            </div>
        </b-container>

        <modal
                :show.sync="confirmDeleteCriteriaModal"
                :no-close-on-backdrop="true"
                size="xl"
                body-classes="p-0"
                title="Confirm Delete"
        >
            <card
                type="secondary"
                header-classes="bg-transparent pb-5"
                body-classes="px-lg-5 py-lg-5"
                class="border-0 mb-0"
            >
                <template>
                    <div>Title : {{ deleteCriteriaModalData.title }}?</div>
                    <div>Type : {{ deleteCriteriaModalData.type }}</div>
                    <div>Are you sure you want to delete? After deletion, it cannot be reverted back.</div>x`
                    <div v-if="deleteCriteriaModalData.items.length > 0" class="pt-2">
                        <div>Values: </div>
                        <table>
                            <tbody>
                                <tr v-for="i in deleteCriteriaModalData.items" :key="'cri_item_'+i.id">
                                    <td>{{ i.name }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div>
                        <base-button
                                type="primary"
                                class="my-4"
                                @click="confirmDeleteCriteriaModal = false"
                        >Cancel</base-button
                        >
                        <base-button
                                type="danger"
                                class="my-4"
                                @click="confirmDeleteCriteria()"
                        >Confirm</base-button
                        >
                    </div>
                </template>
            </card>
        </modal>

    </div>
</template>

<script>
    import ErrorFunctionMixin from '@/assets/js/errorFunctionMix.js'
    import axios from '@/util/axios'
    import { getAccessByModule } from '@/util/access'
    import BaseInput from "../../../../../components/Inputs/BaseInput.vue";
    import CriteriaQuestion from "./Criteria"
    import BaseButton from "../../../../../components/BaseButton.vue";

    export default {
        components: {
            BaseButton,
            BaseInput,CriteriaQuestion},
        mixins: [ErrorFunctionMixin],

        data() {
            return {
                title: 'Eform Templates',
                model: {
                },
                access: {},
                isNewCriteria: false,
                record: {
                    items: [],
                    criterias: []
                },
                // default
                defaultRecord: {
                    items: []
                },

                newTemplateItems: [],
                templateItems: [],

                entryLookupOptions: [],
                entryLookupTitleOptions: [],

                errorMessages: null,
                selectedType: '',
                typeSelection: '',
                title: '',

                confirmDeleteCriteriaModal: false,
                deleteCriteriaModalData: {
                    items: []
                },
                showLevelError: false,
                showDuplicateError: false,
                showItemError: false,

                addNewCriteria: false,
            }
        },

        created() {
            this.access = getAccessByModule('EFORM_TEMPLATE')
        },

        methods: {
            onSubmit() {
                axios.post('e_forms/template', this.model)
                    .then(response => {
                        this.$notify({
                            message: 'New Template Created',
                            timeout: 10000,
                            icon: 'ni ni-bell-55',
                            type: 'default',
                        });
                        this.$router.replace('Listing')
                    })
                    .catch(error => {
                        this.errorFunction(error, this.title)
                    });
            },
            showNewCriteria() {
                this.showLevelError = false;
                this.showDuplicateError = false;
                this.addNewCriteria = true;
                this.$root.$emit('newCriteria')
            },
            isNumeric(evt) {
                var inp = String.fromCharCode(evt.keyCode);
                if (/[0-9]/.test(inp)) {
                    return true;
                } else {
                    evt.preventDefault();
                }
            },
            changeType(event){
//                this.selectedType = this.typeSelection[0];
//                this.record.type = type
                this.record.input_type = this.typeSelection[0];
            },
            withItems(){
                let arr = ['Single Selection', 'Multiple Selection', 'Dropdown List'];
                if(arr.includes(this.selectedType)){
                    return true;
                }
                else{
                    return false;
                }
            },
            withTables()
            {
                let arr = ['Table'];
                if(arr.includes(this.selectedType))
                {
                    return true;
                }
                else
                {
                    return false;
                }
            },
            addMore() {
                let length = this.templateItems.length;
                let previousIndex = length - 1;
                let nextNumber = '';

                if(previousIndex >= 0)
                {
                    let value = this.templateItems[previousIndex];
                    if(value)
                    {
                        nextNumber = parseInt(value.order_no) + 1;
                    }
                }
                else
                {
                    nextNumber = 1;
                }

                this.templateItems.push(Object.assign({}, {
                    criteria:{
                        title:'',
                        id:'',
                        type:'',
                    },
                    order_no: nextNumber
                }));
            },
            // receive child criteria data and append it to the parent
            appendCriteria(data){
                this.newTemplateItems.push(data);
                this.addNewCriteria = false;
            },

            editCriteria(item){
                //console.log('edit criteria', item)
                this.showLevelError = false;
                this.showDuplicateError = false;
                this.$root.$emit('editCriteria', item)
                this.addNewCriteria = true
            },

            onUpdatedCriteria(data){
                // console.log('criterias', this.record.criterias);

                data._method = "PATCH"
                data.project_id = this.adminScope.projectId
                data.company_id = this.adminScope.companyId

                // console.log('updated question', data)

                let levels = [];
                let missingNumbers = [];
                let duplicatesArr = [];

                let number = parseInt(data.number);
                levels.push(number);
                duplicatesArr.push(number);

                let error = 0;
                this.record.criterias.forEach((item, index) => {
                    if(item.id != data.id)
                    {
                        let getLevel = parseInt(item.order_no);
                        if(getLevel)
                        {
                            duplicatesArr.push(getLevel);
                        }

                        if(!levels.includes(getLevel))
                        {
                            levels.push(getLevel);
                        }
                    }
                });

                // console.log('duplicates', duplicatesArr);
                // console.log('level', levels);

                this.newTemplateItems.forEach((item, index) => {
                    let getLevel = parseInt(item.number);
                    if(getLevel)
                    {
                        duplicatesArr.push(getLevel);
                    }

                    if(!levels.includes(getLevel))
                    {
                        levels.push(getLevel);
                    }
                });

                if(duplicatesArr.length > 0)
                {
                    this.showDuplicateError = new Set(duplicatesArr).size !== duplicatesArr.length;
                }

                const maxNumber = Math.max(...levels);
                const minNumber = 1;

                for (let i = minNumber; i <= maxNumber; i++) {
                    if (!levels.includes(i)) {
                        missingNumbers.push(i);
                    }
                }

                if(missingNumbers.length > 0)
                {
                    this.showLevelError = true;
                }
                else
                {
                    this.showLevelError = false;
                }


                if(this.showLevelError == false && this.showDuplicateError == false)
                {
                    axios.post("e_forms/criteria/"+data.id, data).then(res =>{
                        console.log('res', res.data)
                        this.record = {
                            items: []
                        }
                        this.addNewCriteria = false
                        this.getData()

                        this.$notify({
                            message: 'Criteria Updated',
                            timeout: 10000,
                            icon: 'ni ni-bell-55',
                            type: 'default',
                        });
                    })
                }

            },

            deleteItem(item) {
                this.showLevelError = false;
                this.showDuplicateError = false;
                this.confirmDeleteCriteriaModal = true
                this.deleteCriteriaModalData = item
            },
            onCloseCriteria(){
                this.showLevelError = false;
                this.showDuplicateError = false;
                this.addNewCriteria = false
            },
            addCriteria(){
                this.showLevelError = false;
                this.showDuplicateError = false;
                this.addNewCriteria = true;
                this.mode = 'new';
                this.$root.$emit('newCriteria')

            },
            confirmDeleteCriteria(){
                if(this.deleteCriteriaModalData.id >0)
                {
                    axios.delete("e_forms/criteria/"+this.deleteCriteriaModalData.id).then(res =>{
                        this.$notify({
                            message: 'Criteria Deleted',
                            timeout: 10000,
                            icon: 'ni ni-bell-55',
                            type: 'default',
                        });

                        this.getData()
                    })

                    this.confirmDeleteCriteriaModal = false;
                }
                else
                {
                    let idx = this.newTemplateItems.indexOf(this.deleteCriteriaModalData);

                    if (idx >= 0) {
                        this.newTemplateItems.splice(idx, 1);
                    }
                    this.confirmDeleteCriteriaModal = false;
                }
            },
            saveData() {
                this.record.items = this.newTemplateItems
                // console.log('data', this.record)

                let levels = [];
                let missingNumbers = [];
                let duplicatesArr = [];

                if(this.record.criterias.length > 0)
                {
                    this.showItemError = false;
                    let error = 0;
                    this.record.criterias.forEach((item, index) => {
                        let getLevel = parseInt(item.order_no);
                        if(getLevel)
                        {
                            duplicatesArr.push(getLevel);
                        }

                        if(!levels.includes(getLevel))
                        {
                            levels.push(getLevel);
                        }
                    });
                }
                else
                {
                    if(this.newTemplateItems.length > 0)
                    {
                        this.showItemError = false;
                    }
                    else
                    {
                        this.showItemError = true;
                    }
                }

                this.newTemplateItems.forEach((item, index) => {
                    let getLevel = parseInt(item.number);
                    if(getLevel)
                    {
                        duplicatesArr.push(getLevel);
                    }

                    if(!levels.includes(getLevel))
                    {
                        levels.push(getLevel);
                    }
                });

                if(duplicatesArr.length > 0)
                {
                    this.showDuplicateError = new Set(duplicatesArr).size !== duplicatesArr.length;
                }

                const maxNumber = Math.max(...levels);
                const minNumber = 1;

                for (let i = minNumber; i <= maxNumber; i++) {
                    if (!levels.includes(i)) {
                        missingNumbers.push(i);
                    }
                }

                if(missingNumbers.length > 0)
                {
                    this.showLevelError = true;
                }
                else
                {
                    this.showLevelError = false;
                }

                if(this.showLevelError == false && this.showDuplicateError == false && this.showItemError ==  false)
                {
                    axios.post('e_forms/template', this.record)
                        .then(response => {

                            //clear the new input. since its in the db
                            //console.log('clear new item')
                            this.newTemplateItems = []

                            this.$notify({
                                message: 'New Template Created',
                                timeout: 10000,
                                icon: 'ni ni-bell-55',
                                type: 'default',
                            });
                             this.$router.replace('Listing')
                        })
                        .catch(error => {
                            this.errorFunction(error, this.title)
                        });
                }
            },
        }

    }
</script>
