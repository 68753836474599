<template>
  <div>
    <carousel-3d id="gallery-3d" :autoplay="true" :width="650" :height="350" :display="5">
      <slide v-for="(slide, i) in slides" :index="i" :key="i">
          <template slot-scope="{ index, isCurrent, leftIndex, rightIndex }">
              <img :data-index="index" :class="{ current: isCurrent, onLeft: (leftIndex >= 0), onRight: (rightIndex >= 0) }" :src="slide.image">
          </template>
      </slide>
    </carousel-3d>
</div>
</template>

<script>
import { Table, TableColumn } from "element-ui";
import VueHighcharts from "vue2-highcharts";
import VueFlip from "vue-flip";
import { VueperSlides, VueperSlide } from 'vueperslides'
import 'vueperslides/dist/vueperslides.css'
import VueApexCharts from "vue-apexcharts";
import { Select, Option } from "element-ui";

import { Carousel3d, Slide } from 'vue-carousel-3d';

export default {
  name: "Detail",
  components: {
    VueperSlides,
    VueperSlide,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    // VueEllipseProgress,
    VueHighcharts,
    "apexchart": VueApexCharts,
    'vue-flip': VueFlip,
    Carousel3d,
    Slide
  },

  created() {
    const LoginUser = JSON.parse(window.localStorage.getItem("loginUser"));
    this.myUserID = LoginUser;
  },
  data() {
    return {
      slides: [
        { image: '/img/ikn/01.png' },
        { image: '/img/ikn/02.png' },
        { image: '/img/ikn/03.png' },
        { image: '/img/ikn/04.png' },
        { image: '/img/ikn/05.png' },
        { image: '/img/ikn/06.png' },
        { image: '/img/ikn/07.png' },
        { image: '/img/ikn/08.png' },
        { image: '/img/ikn/09.png' }
      ],
    };
  },
  methods: {

  },
};
</script>

<style scoped>
/deep/ .carousel-3d-container {
  min-height: 350px;
  height: 205px !important;
}

/deep/ .carousel-3d-slider {
  min-height: 350px;
  height: 202px !important;
}
</style>

<style scoped>
/* @import '~carbon/charts-vue/styles.css'; */
/*
  .table thead th {
      padding-top: 0.5rem;
      padding-bottom: 1.5rem;
      font-size: 0.9rem; 
      text-transform: uppercase;
      letter-spacing: 1px;
      border-bottom: 0px solid #e9ecef;
      border-top: 0px solid #e9ecef;
      color: white;
  }
  .table td {
    color: white;
  }*/

.apexcharts-legend-marker {
  width: 12px !important;
  height: 12px !important;
  left: 0px !important;
  top: 0px !important;
  border-radius: 2px !important;
}

/* .apexcharts-series {
    bottom:  30px !important;
   } */
.apexcharts-rangebar-area {
  margin-bottom: 30px !important;
  padding-bottom: 30px !important;
  top: -80px;
}

/* .apexcharts-rangebar-series {
    padding: 30px !important;
  
  }
  .apexcharts-plot-series {
    padding: 30px !important;
  
  } */
select {
  background: url("data:image/svg+xml,<svg width='10' height='7' viewBox='0 0 10 7' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M5.02552 6.35057L0.320068 0.46875H9.73098L5.02552 6.35057Z' fill='white'/></svg>") no-repeat;
  background-position: calc(100% - 0.75rem) center !important;
  -moz-appearance: none !important;
  -webkit-appearance: none !important;
  appearance: none !important;
  padding-right: 2rem !important;
}

.bg-card-dashboard {
  /* background-color: #23454a; */
  background-image: linear-gradient(to bottom right, #23454a, #406B71);
}

.bg-card-dashboard-solid {
  background-color: #23454a;
  border-radius: 30px;
}

.bg-default-dashboard {
  background-color: #082a2f;
  /* background-size: cover; */
  /* position:fixed; */
  padding: 0;
  margin: 0;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.form-control {
  background-color: #2c545a;
  border-color: #2c545a;
  color: white;

  &:focus {
    background-color: #2c545a;
    border-color: #2c545a;
    color: white;
  }

  &::placeholder {
    color: white;
  }
}

.form-control {
  &:focus {
    &::placeholder {
      color: white;
    }
  }
}

.input-group-text {
  background-color: #2c545a;
  color: white;

  &:focus {
    background-color: #2c545a;
    border-color: #1d4147;
    color: white;
  }
}

.focused {
  .input-group-text {
    background-color: #2c545a;
  }
}

.navbar-search-dark .input-group {
  background-color: #23454a;
  color: black;
  border-color: rgba(255, 255, 255, 0.6);
}

.navbar-search .input-group {
  border-radius: 7px;
}

.el-input__inner,
.el-select-dropdown__item.is-disabled:hover {
  background-color: #23454a;
}

.line-custom {
  background-color: #356168;
  margin-top: 15px;
  margin-bottom: 15px;
}

/* .el-table th.el-table__cell {
    
    overflow: hidden;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-color: #23454A;
    color: white;
    padding: 20px;
    border-top: 0px solid #082A2F;
    border-bottom: 0px solid #082A2F;
  }
   */

/* .el-table td.el-table__cell, .el-table th.el-table__cell.is-leaf {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    border-bottom: 20px solid #082A2F;
    border-top: 0px solid #082A2F;
  } */

/* div.el-table .el-table__row {
    background: #3a656b;;
    border-bottom: 0 solid #082A2F;
  
  }
  div.el-table .el-table__row:hover {
    background: #0d2f33;
  }
  
  .el-table--border::after, .el-table--group::after, .el-table::before {
    content: '';
    position: absolute;
    background-color: #ebeef500;
    z-index: 1;
  } */


/* <!--style for flip card--> */
ul {
  padding-left: 0;
  display: flex;
  flex-flow: row wrap;
}

li {
  list-style-type: none;
  padding: 10px 10px;
  transition: all 0.3s ease;
}


.card2 {
  display: block;
  /* width: 150px; */
  height: 500px;
  padding: 80px 50px;
  background-color: #23454a;
  border-radius: 7px;
  margin: 5px;
  text-align: center;
  line-height: 27px;
  cursor: pointer;
  position: relative;
  color: #fff;
  font-weight: 600;
  font-size: 20px;
  -webkit-box-shadow: 9px 10px 22px -8px rgba(209, 193, 209, .5);
  -moz-box-shadow: 9px 10px 22px -8px rgba(209, 193, 209, .5);
  box-shadow: 9px 10px 22px -8px rgba(209, 193, 209, .5);
  will-change: transform;
}


.flip-enter-active {
  transition: all 0.8s ease;
}

.flip-leave-active {
  display: none;
}

.flip-enter,
.flip-leave {
  transform: rotateY(90deg);
  opacity: 0;

}

/* Form */
.flashcard-form {
  position: relative;
}


label {
  font-weight: 400;
  color: #333;
  margin-right: 10px;
}

input {
  border-radius: 5px;
  border: 2px solid #eaeaea;
  padding: 10px;
  outline: none;
}

.front {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #673AB7;
  color: white;
  width: 100%;
  height: 100%;
}

.back {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #FFC107;
  color: white;
  width: 100%;
  height: 100%;
}
</style>

<!-- CSS for image slides -->
<style scoped>
#slider {
  width: 100%;
}

.slider {
  margin: 0 auto;
  padding: 0;
  /* width: 800px; */
  height: 450px;
  position: relative;
  overflow: hidden;

  ul.slides {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    display: table;
    position: absolute;
    top: 0;
    transition: left 800ms;

    li {
      list-style-type: none;
      display: table-cell;

      img {
        width: 800px;
      }
    }
  }

  ul.bullets {
    width: inherit;
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 0;
    margin: 0 0 10px 0;
    text-align: center;
    z-index: 1;

    li {
      list-style-type: none;
      display: inline;
      color: #fff;
      cursor: pointer;
      padding: 0 5px;
      font-size: 20px;
      font-family: sans-serif;
    }
  }

  .prev,
  .next {
    text-decoration: none;
    color: #fff;
    position: absolute;
    z-index: 1;
    font-size: 42px;
    top: 43%;
    text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5);
  }

  .prev {
    left: 20px;
  }

  .next {
    right: 20px;
  }
}

.thumbnails {
  margin: auto;
  max-width: 80%;
}

.thumbnails .vueperslide {
  box-sizing: border-box;
  border: 1px solid #fff;
  transition: 0.3s ease-in-out;
  opacity: 0.7;
  cursor: pointer;
}

.thumbnails .vueperslide--active {
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.3);
  opacity: 1;
  border-color: #000;
}
</style>