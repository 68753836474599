<template>
    <div class="content">
        <base-header class="pb-6">
            <b-row align-v="center" class="py-4">
                <b-col cols="7" lg="6">
                    <h6 class="h2 text-white d-inline-block mb-0">Safety Inspection</h6>
                    <!-- <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
                      <route-bread-crumb></route-bread-crumb>
                    </nav> -->
                </b-col>
                <b-col lg="6" cols="5" class="text-right" v-if="button_access.new_button == 'YES'">
                    <router-link to="/healthSafety/safetyObservationNew">
                        <base-button size="sm" type="neutral" >New Safety Inspection</base-button>
                    </router-link>
                </b-col>
            </b-row>
        </base-header>
        <b-container fluid class="mt--6">
            <div>
                <card class="no-border-card" body-classes="px-0 pb-1" footer-classes="pb-2">
                    <!-- <template slot="header">
                      <h3 class="mb-0">Safety Inspection Listing</h3>
                    </template> -->
                    <div>
                        <b-col cols="12" class="d-flex justify-content-center justify-content-sm-between flex-wrap">
                            <el-select
                                    class="select-primary pagination-select"
                                    v-model="pagination.perPage"
                                    placeholder="Per page">
                                <el-option
                                        class="select-primary"
                                        v-for="item in pagination.perPageOptions"
                                        :key="item"
                                        :label="item"
                                        :value="item">
                                </el-option>
                            </el-select>

                            <div>
                                <base-input v-model="searchQuery"
                                            prepend-icon="fas fa-search"
                                            placeholder="Search...">
                                </base-input>
                            </div>
                        </b-col>
                        <!-- <b-col>
                          <br>
                          <h5 class="text">Note:
                            <br>Filter Month-Year by clicking on the down-arrow
                          </h5>
                          <br>
                        </b-col> -->

                        


                        <card>

                            <div class="form-row">
                                    <b-col cols="4">
                                        <base-input
                                            label="From Date"
                                            name="From Date"
                                            type="date"
                                            v-model="filter.from_date"
                                        >
                                        </base-input>
                                    </b-col>

                                    <b-col cols="4">
                                        <base-input
                                            label="To Date"
                                            name="To Date"
                                            type="date"
                                            v-model="filter.to_date"
                                        >
                                        </base-input>
                                    </b-col>
                                </div>

                            <h3 v-if="user_role !== 12 && user_role !== null" @click="toggleShowFilters">
                                Filters
                                <i v-if="!showFilters" class="ni ni-fat-add"></i>
                                <i v-else class="ni ni-fat-delete"></i>
                            </h3>
                            <card v-if="showFilters">
                            <!-- <card> -->
                                <div class="form-row">
                                    <b-col cols="8">
                                    <label for="developersSelect" class="form-control-label">Developer</label>
                                    <select multiple class="form-control" id="developersSelect" v-model="filter.developers" size="7">
                                        <option 
                                            v-for="item in developers"
                                            :key="item.developer_id"
                                            v-bind:value="item.developer_id">
                                            {{ item.developer_code }}                        
                                        </option>
                                    </select>
                                    <br>
                                    </b-col>
                                </div>

                                <div class="form-row">
                                    <b-col md="8">
                                    <base-input
                                        label="Project Name"
                                        name="Project Name"
                                        placeholder="Project Name"
                                        v-model="filter.project_name"
                                    >
                                    </base-input>
                                    </b-col>
                                </div>

                                <div class="form-row">
                                    <b-col cols="8">
                                    <label for="contractorsSelect" class="form-control-label">EPC</label>
                                    <select multiple class="form-control" id="contractorsSelect" v-model="filter.project_site_contractors" size="7">
                                        <option 
                                            v-for="item in contractors"
                                            :key="item.contractor_id"
                                            v-bind:value="item.contractor_id">
                                            {{ item.contractor_code }}                        
                                        </option>
                                    </select>
                                    <br>
                                    </b-col>
                                </div>

                                <div class="form-row">
                                    <b-col md="8">
                                    <base-input
                                        label="Site Name"
                                        name="Site Name"
                                        placeholder="Site Name"
                                        v-model="filter.site_name"
                                    >
                                    </base-input>
                                    </b-col>
                                </div>

                                <div class="form-row">
                                    <b-col cols="8">
                                    <label for="safetyOfficerSelect" class="form-control-label">Safety Officer</label>
                                    <select multiple class="form-control" id="safetyOfficerSelect" v-model="filter.project_safety" size="7">
                                        <option 
                                            v-for="item in project_safety"
                                            :key="item.id"
                                            v-bind:value="item.id">
                                            {{ item.name }}                        
                                        </option>
                                    </select>
                                    <br>
                                    </b-col>
                                </div>

                                <div class="form-row">
                                    <b-col cols="8">
                                    <label for="safetyInspectionSubmitterSelect" class="form-control-label">Safety Inspection Submitter</label>
                                    <select multiple class="form-control" id="safetyInspectionSubmitterSelect" v-model="filter.safety_inspection_submitter" size="7">
                                        <option 
                                            v-for="item in safety_inspection_submitters"
                                            :key="item.id"
                                            v-bind:value="item.id">
                                            {{ item.name }}                        
                                        </option>
                                    </select>
                                    <br>
                                    </b-col>
                                </div>

                                <!-- <div class="form-row">
                                    <b-col cols="4">
                                        <base-input
                                            label="From Date"
                                            name="From Date"
                                            type="date"
                                            v-model="filter.from_date"
                                        >
                                        </base-input>
                                    </b-col>

                                    <b-col cols="4">
                                        <base-input
                                            label="To Date"
                                            name="To Date"
                                            type="date"
                                            v-model="filter.to_date"
                                        >
                                        </base-input>
                                    </b-col>
                                </div> -->

                                <!-- safety_observation_type -->
                                <div class="form-row">
                                    <b-col cols="8">
                                    <label for="safetyObservationTypeSelect" class="form-control-label">Safety Observation Type</label>
                                    <select multiple class="form-control" id="safetyObservationTypeSelect" v-model="filter.safety_observation_type" size="7">
                                        <option 
                                            v-for="item in safety_observation_type"
                                            :key="item.safety_observation_type_id"
                                            v-bind:value="item.safety_observation_type_id">
                                            {{ item.safety_observation_type_code }}                        
                                        </option>
                                    </select>
                                    <br>
                                    </b-col>
                                </div>

                                <div class="form-row">
                                    <b-col cols="8">
                                    <label for="safetyObservationCategorySelect" class="form-control-label">Safety Observation Category</label>
                                    <select multiple class="form-control" id="safetyObservationCategorySelect" v-model="filter.safety_observation_category" size="7">
                                        <option 
                                            v-for="item in safety_observation_category"
                                            :key="item.safety_observation_category_id"
                                            v-bind:value="item.safety_observation_category_id">
                                            {{ item.safety_observation_category }}                        
                                        </option>
                                    </select>
                                    <br>
                                    </b-col>
                                </div>

                                <div class="form-row">
                                    <b-col cols="4">
                                        <base-input
                                            label="Target Date From Date"
                                            name="Target Date From Date"
                                            type="date"
                                            v-model="filter.target_date_from_date"
                                        >
                                        </base-input>
                                    </b-col>

                                    <b-col cols="4">
                                        <base-input
                                            label="Target Date To Date"
                                            name="Target Date To Date"
                                            type="date"
                                            v-model="filter.target_date_to_date"
                                        >
                                        </base-input>
                                    </b-col>
                                </div>

                                <!-- safety_observation_priority -->
                                <div class="form-row">
                                    <b-col cols="8">
                                    <label for="safetyObservationPrioritySelect" class="form-control-label">Safety Observation Priority</label>
                                    <select multiple class="form-control" id="safetyObservationPrioritySelect" v-model="filter.safety_observation_priority" size="7">
                                        <option 
                                            v-for="item in safety_observation_priority"
                                            :key="item.safety_observation_priority_id"
                                            v-bind:value="item.safety_observation_priority_id">
                                            {{ item.safety_observation_priority_name }} - {{ item.safety_observation_priority_duration }} Days                        
                                        </option>
                                    </select>
                                    <br>
                                    </b-col>
                                </div>

                            </card>

                            <b-row class="justify-content-end">
                                <b-col class="text-right" cols="auto">
                                    <base-button size="sm" type="primary" @click="searchSafetyObservationListingFilter()"
                                        >Search</base-button
                                    >
                                </b-col>
                            </b-row>
                        </card>

                        <!-- <b-col>
                            <div class="form-row">

                                <b-col cols="6">
                                    <base-input
                                            label="From Date"
                                            name="From Date"
                                            type="date"
                                            v-model="filter.from_date"
                                    >
                                    </base-input>
                                </b-col>

                                <b-col cols="6">
                                    <base-input
                                            label="To Date"
                                            name="To Date"
                                            type="date"
                                            v-model="filter.to_date"
                                    >
                                    </base-input>
                                </b-col>

                            </div>

                            <b-row>
                                <b-col class="text-right">
                                    <base-button size="sm" type="primary" @click="getSafetyObservationListingRange()"
                                    >Search</base-button
                                    >
                                </b-col>
                            </b-row>
                        </b-col> -->

                        <b-col><br></b-col>

                        <el-table :data="queriedData"
                                  row-key="safety_observation_id"
                                  header-row-class-name="thead-light"
                                  @sort-change="sortChange">

                            <el-table-column label="PROJECT NAME"
                                             min-width="170px"
                                             prop="project.project_name">
                                <template v-slot="{row}">
                                    <b-media no-body class="align-items-center">
                                        <b-media-body>
                                            <span class="font-weight-600 name mb-0 text-sm">{{row.project.project_name}}</span>
                                        </b-media-body>
                                    </b-media>
                                </template>
                            </el-table-column>

                            <el-table-column label="EPC"
                                             min-width="170px"
                                             prop="contractor.contractor_code">
                                <template v-slot="{row}">
                                    <b-media no-body class="align-items-center">
                                        <b-media-body>
                                            <span class="font-weight-600 name mb-0 text-sm">{{row.contractor.contractor_code}}</span>
                                        </b-media-body>
                                    </b-media>
                                </template>
                            </el-table-column>

                            <el-table-column label="SITE NAME"
                                             min-width="170px"
                                             prop="site_name">
                                <template v-slot="{row}">
                                    <b-media no-body class="align-items-center">
                                        <b-media-body>
                                            <span class="font-weight-600 name mb-0 text-sm">{{row.site_name}}</span>
                                        </b-media-body>
                                    </b-media>
                                </template>
                            </el-table-column>

                            <!-- <el-table-column label="SUBMIT DATE"
                                           min-width="170px"
                                           prop="project_name"
                                           sortable
                                           :filters="filter_month_year_data"
                                           :filter-method="filterMonthYear">
                              <template v-slot="{row}">
                                  <b-media no-body class="align-items-center">
                                      <b-media-body>
                                          <span class="font-weight-600 name mb-0 text-sm">{{row.safety_observation_submitted_at ? row.safety_observation_submitted_at : 'N/A'}}</span>
                                      </b-media-body>
                                  </b-media>
                              </template>
                            </el-table-column> -->

                            <el-table-column label="INSPECTION DATE"
                                             min-width="170px"
                                             prop="safety_observation_create_date_web">
                                <template v-slot="{row}">
                                    <b-media no-body class="align-items-center">
                                        <b-media-body>
                                            <span class="font-weight-600 name mb-0 text-sm">{{row.safety_observation_create_date_web}}</span>
                                        </b-media-body>
                                    </b-media>
                                </template>
                            </el-table-column>

                            <el-table-column label="OPEN"
                                             min-width="170px"
                                             prop="safety_observation_items_open"
                                             sortable>
                                <template v-slot="{row}">
                                    <b-media no-body class="align-items-center">
                                        <b-media-body>
                                            <span class="font-weight-600 name mb-0 text-sm">{{getOpenItems(row.safety_observation_items)}}</span>
                                        </b-media-body>
                                    </b-media>
                                </template>
                            </el-table-column>

                            <el-table-column label="CLOSED"
                                             min-width="170px"
                                             prop="safety_observation_items_closed"
                                             sortable>
                                <template v-slot="{row}">
                                    <b-media no-body class="align-items-center">
                                        <b-media-body>
                                            <span class="font-weight-600 name mb-0 text-sm">{{getClosedItems(row.safety_observation_items)}}</span>
                                        </b-media-body>
                                    </b-media>
                                </template>
                            </el-table-column>

                            <!-- <el-table-column label="TOTAL"
                                           min-width="170px"
                                           prop="safety_observation_items.length"
                                           sortable>
                              <template v-slot="{row}">
                                  <b-media no-body class="align-items-center">
                                      <b-media-body>
                                          <span class="font-weight-600 name mb-0 text-sm">{{row.safety_observation_items.length}}</span>
                                      </b-media-body>
                                  </b-media>
                              </template>
                            </el-table-column> -->

                            <!-- sortable -->
                            <el-table-column label="STATUS"
                                             min-width="170px"
                                             prop="status">
                                <template v-slot="{row}">
                                    <b-media no-body class="align-items-center">
                                        <b-media-body>
                                            <span class="font-weight-600 name mb-0 text-sm" v-if="row.status == 0">Draft</span>
                                            <span class="font-weight-600 name mb-0 text-sm" v-if="row.status == 1">Submitted</span>
                                        </b-media-body>
                                    </b-media>
                                </template>
                            </el-table-column>

                            <el-table-column label="SUBMITTED BY"
                                             min-width="170px"
                                             prop="created_by">
                                <template v-slot="{row}">
                                    <b-media no-body class="align-items-center">
                                        <b-media-body>
                                            <span class="font-weight-600 name mb-0 text-sm">{{row.created_by.name}}</span>
                                        </b-media-body>
                                    </b-media>
                                </template>
                            </el-table-column>

                            <el-table-column min-width="180px" align="right" label="Actions">
                                <div slot-scope="{$index, row}" class="d-flex">
                                    <base-button
                                            @click.native="handleDetails($index, row)" size="sm" type="primary">
                                        View/Edit
                                    </base-button><br />
                                    <base-button
                                            v-if="user_role == 3" @click.native="deleteDetails($index, row)" size="sm" type="danger">
                                        Delete
                                    </base-button>
                                </div>
                            </el-table-column>

                            <!-- <el-table-column min-width="180px" align="right" label="Actions">
                              <div slot-scope="{$index, row}" class="d-flex">
                                <base-button v-if="button_access.project_view == 'YES'"
                                  @click.native="handleDetails($index, row)" size="sm" type="primary">
                                  Dashboard
                                </base-button>
                                <base-button v-if="((button_access.milestone_listing == 'YES') && (myUserID.email == row.project_manager.email))"
                                  @click.native="handleMilestone($index, row)" size="sm" type="primary">
                                  Milestone
                                </base-button>
                              </div>
                            </el-table-column> -->
                        </el-table>
                    </div>

                    <!-- <div>
                      <b-col cols="12" class="d-flex justify-content-center justify-content-sm-between flex-wrap">
                        <el-select
                          class="select-primary pagination-select"
                          v-model="pagination.perPage"
                          placeholder="Per page">
                          <el-option
                            class="select-primary"
                            v-for="item in pagination.perPageOptions"
                            :key="item"
                            :label="item"
                            :value="item">
                          </el-option>
                        </el-select>

                        <div>
                          <base-input v-model="searchQuery"
                                      prepend-icon="fas fa-search"
                                      placeholder="Search...">
                          </base-input>
                        </div>
                      </b-col>
                      <b-col><br></b-col>
                      <el-table :data="queriedData"
                                row-key="project_id"
                                header-row-class-name="thead-light"
                                @sort-change="sortChange">

                        <el-table-column label="Project Name"
                                       min-width="170px"
                                       prop="project_name"
                                       sortable>
                          <template v-slot="{row}">
                              <b-media no-body class="align-items-center">
                                  <b-media-body>
                                      <span class="font-weight-600 name mb-0 text-sm">{{row.project_name}}</span>
                                  </b-media-body>
                              </b-media>
                          </template>
                        </el-table-column>

                        <el-table-column label="Category"
                                       min-width="110px"
                                       prop="project_category"
                                       sortable>
                          <template v-slot="{row}">
                              <b-media no-body class="align-items-center">
                                  <b-media-body>
                                      <span class="font-weight-600 name mb-0 text-sm">{{row.project_category.project_category}}</span>
                                  </b-media-body>
                              </b-media>
                          </template>
                        </el-table-column>

                        <el-table-column label="Type"
                                       min-width="110px"
                                       prop="project_category"
                                       sortable>
                          <template v-slot="{row}">
                              <b-media no-body class="align-items-center">
                                  <b-media-body>
                                      <span class="font-weight-600 name mb-0 text-sm">{{row.project_type.project_type}}</span>
                                  </b-media-body>
                              </b-media>
                          </template>
                        </el-table-column>

                        <el-table-column label="Country"
                                       min-width="160px"
                                       prop="project_country"
                                       sortable>
                          <template v-slot="{row}">
                              <b-media no-body class="align-items-center">
                                  <b-media-body>
                                      <span class="font-weight-600 name mb-0 text-sm">{{row.project_country}}</span>
                                  </b-media-body>
                              </b-media>
                          </template>
                        </el-table-column>

                        <el-table-column label="Manager"
                                       min-width="150px"
                                       prop="project_manager"
                                       sortable>
                          <template v-slot="{row}">
                              <b-media no-body class="align-items-center">
                                  <b-media-body>
                                      <span class="font-weight-600 name mb-0 text-sm">{{row.project_manager.name}}</span>
                                  </b-media-body>
                              </b-media>
                          </template>
                        </el-table-column>

                        <el-table-column label="Progress"
                                     min-width="180px"
                                       prop="project_progress"
                                       sortable>
                          <template v-slot="{row}">
                              <div class="d-flex align-items-center">
                                  <div>
                                    <span class="font-weight-600 name mb-0 text-sm">{{row.project_progress}}% &nbsp; </span>
                                  </div>
                                  <div>
                                      <base-progress v-if="row.project_progress > 0 && row.project_progress < 50" min-width="30px" type="danger" :value="row.project_progress"/>
                                      <base-progress v-else-if="row.project_progress >= 50 && row.project_progress <= 90" min-width="30px" type="info" :value="row.project_progress"/>
                                      <base-progress v-else min-width="30px" type="success" :value="row.project_progress"/>
                                  </div>
                              </div>
                              <b-media no-body class="align-items-center">
                                  <b-media-body>
                                      <badge class="badge-dot mr-4" type="">
                                        <i v-if="row.status.status_code == 'Delayed'" class="bg-danger"></i>
                                        <i v-else-if="row.status.status_code == 'On-Hold'" class="bg-danger"></i>
                                        <i v-else-if="row.status.status_code == 'Reviewed'" class="bg-success"></i>
                                        <i v-else-if="row.status.status_code == 'Completed'" class="bg-success"></i>
                                        <i v-else class="bg-info"></i>
                                        <span class="font-weight-600 name mb-0 text-sm">{{row.status.status_code}}</span>
                                      </badge>
                                  </b-media-body>
                              </b-media>
                          </template>
                        </el-table-column>

                        <el-table-column min-width="180px" align="right" label="Actions">
                          <div slot-scope="{$index, row}" class="d-flex">
                            <base-button v-if="button_access.project_view == 'YES'"
                              @click.native="handleDetails($index, row)" size="sm" type="primary">
                              Dashboard
                            </base-button>
                            <base-button v-if="((button_access.milestone_listing == 'YES') && (myUserID.email == row.project_manager.email))"
                              @click.native="handleMilestone($index, row)" size="sm" type="primary">
                              Milestone
                            </base-button>
                          </div>
                        </el-table-column>
                      </el-table>
                    </div> -->
                    <div
                            slot="footer"
                            class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap">
                        <div class="">
                            <p class="card-category">
                                Showing {{ from + 1 }} to {{ to }} of {{ total }} entries

                                <span v-if="selectedRows.length">
                  &nbsp; &nbsp; {{selectedRows.length}} rows selected
                </span>
                            </p>
                        </div>
                        <base-pagination
                                class="pagination-no-border"
                                :current="pagination.currentPage"
                                :per-page="pagination.perPage"
                                :total="total"
                                @change="paginationChanged($event)">
                        </base-pagination>
                        <!-- <div>
                         <h5 class="text-danger"> Note :
                             <i>
                             Please Create at least ONE Project Template & ONE Project Template Details before you can proceed POPULATE / IMPORT to prevent any Error. Go to NEW Template Then "Detail Listing" >> "New Template Details".
                             </i>
                         </h5>
                       </div> -->
                    </div>
                </card>
            </div>
        </b-container>

        <modal
                :show.sync="isDeleteSafetyObservationModalVisible"
                size="xl"
                body-classes="p-0"
                title="Delete Safety Inspection"
        >
            <card
                    type="secondary"
                    header-classes="bg-transparent pb-5"
                    body-classes="px-lg-5 py-lg-5"
                    class="border-0 mb-0"
            >
                <template>
                    <div class="text-center text-muted mb-4">
                        <h1>Delete Safety Inspection</h1>
                    </div>
                    <div class="text-center">
                        <div>
                            <br />Are you sure you want to permanently delete this safety inspection?
                        </div>
                        <div>
                            <base-button
                                    type="danger"
                                    class="my-4"
                                    @click="showDeleteSafetyObservationModalInner"
                            >Delete</base-button
                            >
                            <base-button
                                    type="primary"
                                    class="my-4"
                                    @click="closeDeleteSafetyObservationModal"
                            >Cancel</base-button
                            >
                        </div>
                    </div>
                </template>
            </card>
        </modal>

        <modal
                :show.sync="isDeleteSafetyObservationModalVisibleInner"
                size="xl"
                body-classes="p-0"
                title="Confirm Delete Safety Inspection"
        >
            <card
                    type="secondary"
                    header-classes="bg-transparent pb-5"
                    body-classes="px-lg-5 py-lg-5"
                    class="border-0 mb-0"
            >
                <template>
                    <div class="text-center text-muted mb-4">
                        <h1>Confirm Delete Safety Inspection</h1>
                    </div>
                    <div class="text-center">
                        <div>
                            <br />Are you REALLY sure you want to permanently delete this
                            safety inspection?
                        </div>
                        <div>
                            <base-button
                                    type="primary"
                                    class="my-4"
                                    @click="closeDeleteSafetyObservationModalInner"
                            >Cancel</base-button
                            >
                            <base-button type="danger" class="my-4" @click="deleteSafetyInspection"
                            >Delete</base-button
                            >
                        </div>
                    </div>
                </template>
            </card>
        </modal>
    </div>
</template>
<script>
    import { Table, TableColumn, Select, Option } from 'element-ui';
    import RouteBreadCrumb from '@/components/Breadcrumb/RouteBreadcrumb'
    import { BasePagination } from '@/components';
    import standardPaginationMix from '@/assets/js/standardPaginationMix.js'
    import Fuse from 'fuse.js';
    import axios from 'axios'
    import {LoginURL, clientId, clientSecret, apiDomain, getHeader, getcurrentDate} from '@/assets/js/config.js'
    import ErrorFunctionMixin from '@/assets/js/errorFunctionMix.js'

    export default {
        mixins: [standardPaginationMix, ErrorFunctionMixin],
        components: {
            BasePagination,
            RouteBreadCrumb,
            [Select.name]: Select,
            [Option.name]: Option,
            [Table.name]: Table,
            [TableColumn.name]: TableColumn
        },
        created()
        {

            // This code is not correct what you see here is for temporary testing purpose
            // var project_access = JSON.parse(window.localStorage.getItem('project_access'))
            // var project_milestone_access = JSON.parse(window.localStorage.getItem('project_milestone_access'))
            // this.button_access.new_button = project_access.access_create
            // this.button_access.milestone_listing = project_milestone_access.access_listing
            this.setDefaultMonthRange();

            var safety_observation_access = JSON.parse(
                window.localStorage.getItem("safety_observation_access")
            );

            this.button_access.safety_observation_delete = safety_observation_access.access_delete;

            const LoginUser = JSON.parse(window.localStorage.getItem('loginUser'))
            this.myUserID = LoginUser
            const userInfo = JSON.parse(window.localStorage.getItem('loginUser'))
            this.user_role = userInfo.role_id;
            this.user_id = userInfo.id;
            if (this.user_role == 12) {
                this.getContractorId();
            }

            var safety_observation_access = JSON.parse(window.localStorage.getItem('safety_observation_access'))
            this.button_access.new_button = safety_observation_access.access_create

            // check if user is a project manager
            if (this.myUserID.role_id == 9) {
                // check if user is group leader
                axios
                    .get(apiDomain + 'setup/project_engineer_group', { headers: getHeader() })
                    .then ( response => {
                        if (response.status === 200)
                        {
                            let group_listing_response = response.data.data;
                            group_listing_response.forEach((e) => {
                                if (e['engineer_group_leader']['id'] == this.myUserID.id) {
                                    this.groupId.push(e['engineer_group_id']);
                                }
                            });
                            if (this.groupId != []) {
                                this.groupMembers.push(this.myUserID.id);
                                this.groupId.forEach((e_inner) => {
                                    axios
                                        .get(apiDomain + 'setup/project_engineer_group.show_by_group/' + e_inner, { headers: getHeader() })
                                        .then ( response => {
                                            if (response.status === 200)
                                            {
                                                let group_member_response = response.data.data;
                                                group_member_response.forEach((e_member) => {
                                                    let checkExist = this.groupMembers.includes(e_member['engineer_group_member']['id']);
                                                    if (!checkExist) {
                                                        this.groupMembers.push(e_member['engineer_group_member']['id']);
                                                    }
                                                });
                                            }
                                        })
                                        .catch ( error => {
                                            console.log(error.response.status)
                                            this.errorFunction(error, "Project Engineer Group Member")
                                        });
                                });
                            } else {
                                this.groupMembers.push(this.myUserID.id);
                            }
                        }
                    })
                    .catch ( error => {
                        this.errorFunction(error, "Project Engineer Groups Listing")
                    });
            }

            if (this.user_role !== 12 && this.user_role !== null) {
                this.getDeveloperList();
                this.getContractorList();
                this.getSafetyOfficerListing();
                this.getSafetyInspectionSubmitterList();

                this.getSafetyObservationTypes();
                this.getSafetyObservationCategories();
                this.getSafetyObservationPriorities();
            }

            // this.getDeveloperList();
            // this.getContractorList();
            // this.getSafetyOfficerListing();
            // this.getSafetyInspectionSubmitterList();

            // this.getSafetyObservationTypes();
            // this.getSafetyObservationCategories();
            // this.getSafetyObservationPriorities();

            // this.getSafetyObservationListing();
            this.getSafetyObservationListingRange();

            // axios.get(apiDomain + 'user_access/role_access.project_access' , { headers: getHeader() })
            // .then ( response => {
            // if (response.status === 200)
            // {
            //     this.button_access.new_button = response.data.data.project_modules[0].access_create
            //     this.button_access.project_view = response.data.data.project_modules[0].access_show
            //     this.button_access.milestone_listing = response.data.data.project_modules[2].access_listing
            //     this.getprojectListing()
            // }
            // })
            // .catch ( error => {
            // console.log(error.response.status)
            // this.errorFunction(error, "Project Information")
            // });

        },
        data() {
            return {
                user_role: null,
                user_id: null,
                contractor_id: null,
                propsToSearch:
                    [
                        // 'project_code',
                        // 'project_name',
                        // 'project_manager.name',
                        // 'project_progress',
                        // 'status.status_code',
                        'project.project_name',
                        'contractor.contractor_code',
                        'site_name',
                        'safety_observation_create_date_web',
                        'created_by.name'
                    ],
                tableColumns: [],
                tableData: [],
                // submittedTableData: [],
                // draftTableData: [],
                selectedRows: [],
                myUserID: null,
                button_access:
                    {
                        new_button: null,
                        project_view: null,
                        milestone_listing: null,
                        safety_observation_delete: null,
                    },
                groupId: [],
                groupMembers: [],
                filter_month_year_data: [
                    // { text: 'CSL', value: 'CSL' }
                    { text: 'N/A', value: '' }
                ],
                filter: {
                    developers: [],
                    project_name: null,
                    project_site_contractors: [],
                    site_name: null,
                    project_safety: [],
                    safety_inspection_submitter: [],
                    
                    from_date: null,
                    to_date: null,

                    safety_observation_type: [],
                    safety_observation_category: [],
                    safety_observation_priority: [],

                    target_date_from_date: null,
                    target_date_to_date: null,
                },
                developers: [],
                contractors: [],
                project_safety: [],
                safety_inspection_submitters: [],

                safety_observation_type: [],
                safety_observation_category: [],
                safety_observation_priority: [],

                isFromDateAfterToDate: false,
                isDeleteSafetyObservationModalVisible: false,
                isDeleteSafetyObservationModalVisibleInner: false,
                safetyInspectionId: null,
                showFilters: false,
            };
        },
        methods: {
            toggleShowFilters() {
                this.showFilters = !this.showFilters;
            },
            getDeveloperList() {
                axios.get(apiDomain + 'setup/developer', { headers: getHeader() })
                .then ( response => {
                    if (response.status === 200)
                    {
                    this.developers = response.data.data
                    }
                })
                .catch ( error => {
                    console.log(error.response.status)
                    this.errorFunction(error, "Developer Listing")
                } );
            },

            getContractorList() {
                axios.get(apiDomain + 'setup/contractor', { headers: getHeader() })
                .then ( response => {
                    if (response.status === 200)
                    {
                    this.contractors = response.data.data
                    }
                })
                .catch ( error => {
                    console.log(error.response.status)
                    this.errorFunction(error, "Contractor Listing")
                } );
            },

            getSafetyOfficerListing() {
                axios.get(apiDomain + 'lookup/user_list/Safety', { headers: getHeader() })
                .then ( response => {
                    if (response.status === 200)
                    {
                    this.project_safety = response.data.data
                    }
                })
                .catch ( error => {
                    console.log(error.response.status)
                    this.errorFunction(error, "Safety Officer Information")
                } );
            },

            // getSafetyObservationCreatorList
            getSafetyInspectionSubmitterList() {
                axios
                .get(apiDomain + "lookup/user_list.safety_observation_creator", { headers: getHeader() })
                .then((response) => {
                    if (response.status === 200) {
                    this.safety_inspection_submitters = response.data.data;
                    }
                })
                .catch((error) => {
                    console.log(error.response.status);
                    this.errorFunction(error, "Safety Inspection Submitter Information");
                });
            },

            getSafetyObservationTypes()
            {
                axios.get(apiDomain + 'lookup/safety_observation_type', { headers: getHeader() })
                .then ( response => {
                    if (response.status === 200)
                    {
                    this.safety_observation_type = response.data.data;
                    }
                })
                .catch ( error => {
                    console.log(error.response.status)
                    this.errorFunction(error, "Safety Observation Type Information")
                } );
            },

            getSafetyObservationCategories()
            {
                axios
                .get(apiDomain + "setup/safety_observation_category_active", { headers: getHeader() })
                .then((response) => {
                    if (response.status === 200) {
                    this.safety_observation_category = response.data.data;
                    }
                })
                .catch((error) => {
                    console.log(error.response.status);
                    this.errorFunction(error, "Safety Observation Category");
                });
            },

             getSafetyObservationPriorities()
            {
                axios
                .get(apiDomain + "setup/safety_observation_priority_active", { headers: getHeader() })
                .then((response) => {
                    if (response.status === 200) {
                    this.safety_observation_priority = response.data.data;
                    }
                })
                .catch((error) => {
                    console.log(error.response.status);
                    this.errorFunction(error, "Safety Observation Priority");
                });
            },

            getOpenItems(items){
                var newRow = items.filter(function(e) {
                    if (e.safety_observation_status_id != 3) {
                        return e
                    }
                })
                return newRow.length;
            },
            getClosedItems(items){
                var newRow = items.filter(function(e) {
                    if (e.safety_observation_status_id == 3) {
                        return e
                    }
                })
                return newRow.length;
            },
            setDefaultMonthRange(){
                var currentDate = getcurrentDate();
                var localCurrentDate = new Date(currentDate);

                // get current date in yyyy-mm-dd format
                const currentYear = localCurrentDate.getFullYear();
                const currentMonth = String(localCurrentDate.getMonth() + 1).padStart(2, '0');
                const currentDay = String(localCurrentDate.getDate()).padStart(2, '0');
                this.filter.to_date = currentYear + '-' + currentMonth + '-' + currentDay;

                // get first day of last month in yyyy-mm-dd format
                var fromDate = new Date(localCurrentDate.setMonth(localCurrentDate.getMonth()));
                fromDate = new Date(fromDate.setDate(1));
                const fromYear = fromDate.getFullYear();
                const fromMonth = String(fromDate.getMonth() + 1).padStart(2, '0');
                const fromDay = String(fromDate.getDate()).padStart(2, '0');
                this.filter.from_date = fromYear + '-' + fromMonth + '-' + fromDay;
            },
            validateDateTime() {
                if (this.filter.from_date == '' || this.filter.to_date == '') {
                    return false;
                } else {
                    const fromDate = new Date(this.filter.from_date);
                    const toDate = new Date(this.filter.to_date);
                    this.isFromDateAfterToDate = fromDate > toDate;
                    return true;
                }

            },

            searchSafetyObservationListingFilter() {
                this.tempButtonStatus = true;
                if (this.user_role == 12) {
                    this.getSafetyObservationListingRange();
                } else {

                axios.post(apiDomain + "health_safety/safety_observation.show_by_filter", this.filter, {
                        headers: getHeader(),
                    })
                    .then ( response => {
                        if (response.status === 200)
                    {
                        this.tableData = response.data.data;
                        this.fuseData();
                    }
                    })
                    .catch ( error => {
                        this.errorFunction(error, "Search Safety Inspection Listing")
                    });
                }
            },

            getSafetyObservationListingRange() {
                if (this.validateDateTime()) {
                    if (!this.isFromDateAfterToDate) {
                        if (this.user_role == 12) {
                            if (this.contractor_id != null) {
                                axios.get(apiDomain + 'health_safety/safety_observation.show_by_contractor/' + this.contractor_id + '?from_date=' + this.filter.from_date + '&to_date=' + this.filter.to_date, { headers: getHeader() })
                                    .then ( response => {
                                        if (response.status === 200)
                                        {
                                            this.tableData = response.data.data
                                            this.computeOpenClosedItems()
                                            this.fuseData()
                                        }
                                    })
                                    .catch ( error => {
                                        console.log(error.response.status)
                                        this.errorFunction(error, "Safety Inspection Listing Information")
                                    });
                            }
                        } else {
                            axios.get(apiDomain + 'health_safety/safety_observation.show_by_dates' + '?from_date=' + this.filter.from_date + '&to_date=' + this.filter.to_date, { headers: getHeader() })
                                .then ( response => {
                                    if (response.status === 200)
                                    {
                                        this.tableData = response.data.data
                                        this.computeOpenClosedItems()
                                        // this.filterByContractor()
                                        // this.filterByONM()
                                        this.fuseData()
                                    }
                                })
                                .catch ( error => {
                                    console.log(error.response.status)
                                    this.errorFunction(error, "Safety Inspection Listing Information")
                                });
                        }

                    } else {
                        this.redErrorNotify(
                            "<b>Invalid Input : From Date is greater than To Date </b>"
                        );
                    }
                } else {
                    this.redErrorNotify(
                        "<b>Invalid Input : From Date or To Date is invalid </b>"
                    );
                }
            },
            getSafetyObservationListing()
            {
                axios.get(apiDomain + 'health_safety/safety_observation', { headers: getHeader() })
                    .then ( response => {
                        if (response.status === 200)
                        {
                            this.tableData = response.data.data
                            this.computeOpenClosedItems();
                            this.filterByContractor();
                            this.filterByONM();
                            this.fuseData()
                            this.updateFilterMonthYear()
                            // this.createNewTable();
                        }
                    })
                    .catch ( error => {
                        console.log(error.response.status)
                        this.errorFunction(error, "Project Information")
                    });
            },
            // getOpenItems(items){
            //   var newRow = items.filter(function(e) {
            //     if (e.safety_observation_status_id != 3) {
            //       return e
            //     }
            //   })
            //   return newRow.length;
            // },
            // getClosedItems(items){
            //   var newRow = items.filter(function(e) {
            //     if (e.safety_observation_status_id == 3) {
            //       return e
            //     }
            //   })
            //   return newRow.length;
            // },
            computeOpenClosedItems()
            {
                this.tableData.forEach((e) => {
                    var openCounter = 0;
                    var closedCounter = 0;
                    e.safety_observation_items.forEach((e_inner) => {
                        if (e_inner.safety_observation_status_id != 3) {
                            openCounter++;
                        } else {
                            closedCounter++;
                        }
                    });
                    e.safety_observation_items_open = openCounter;
                    e.safety_observation_items_closed = closedCounter;
                });
            },
            // createNewTable()
            // {
            //   // this.submittedTableData
            //   // this.draftTableData
            //   this.tableData.forEach((e) => {
            //     var month_year = e['safety_observation_submitted_at'].split(/-(.*)/s);
            //     if (month_year[1]) {
            //       if (this.submittedTableData.length == 0) {
            //         var tempObject = {
            //           month_year: month_year[1],
            //           safety_observations: [e],
            //         };
            //         this.submittedTableData.push(tempObject);
            //       } else {
            //         var index = this.submittedTableData.findIndex(x => x.month_year === month_year[1]);
            //         if (index != -1) {
            //           this.submittedTableData[index].safety_observations.push(e);
            //         } else {
            //             var tempObject = {
            //             month_year: month_year[1],
            //             safety_observations: [e],
            //           };
            //           this.submittedTableData.push(tempObject);
            //         }
            //       }
            //     } else {
            //       this.draftTableData.push(e);
            //     }
            //   });
            // },
            updateFilterMonthYear() {
                this.tableData.forEach((e) => {
                    var month_year = e['safety_observation_submitted_at'].split(/-(.*)/s);
                    if (month_year[1]) {
                        const valueExists = this.filter_month_year_data.find((o) => o.value === month_year[1]);
                        if (!valueExists) {
                            this.filter_month_year_data.push({ text: String(month_year[1]), value: month_year[1] });
                        }
                    }
                })
            },
            filterMonthYear(value, row) {
                var month_year = row['safety_observation_submitted_at'].split(/-(.*)/s);
                if (month_year[1]) {
                    if (month_year[1] == value) {
                        return true;
                    }
                    return false;
                }
                return row['safety_observation_submitted_at'] === value;
            },
            fuseData()
            {
                // this.fuseSearch = new Fuse(this.tableData, {
                // keys: this.propsToSearch,
                // threshold: 0.3
                // });
                if (this.myUserID.role_id == 9) {
                    this.filterByUser();
                }
                this.fuseSearch = new Fuse(this.tableData, {
                    keys: this.propsToSearch,
                    threshold: 0.3
                });
            },
            filterByContractor(){
                if (this.myUserID.role_id == 12) {
                    var tempTable = [];
                    tempTable = this.tableData.filter((e) => {
                        if (e['contractor']['contractor_id'] == this.contractor_id) {
                            return e;
                        }
                    });
                    this.tableData = tempTable;
                }
            },
            filterByONM() {
                if (this.myUserID.role_id == 18) {
                    var tempTable = [];
                    tempTable = this.tableData.filter((e) => {
                        if (e['project_status_id'] == 5 || e['project_status_id'] == 7) {
                            return e;
                        }
                    });
                    this.tableData = tempTable;
                }
            },
            getContractorId(){
                axios.get(apiDomain + 'lookup/contractor.contractor_id/' + this.myUserID.id, { headers: getHeader() })
                    .then ( response => {
                        if (response.status === 200)
                        {
                            this.contractor_id = response.data.contractor_id;
                            this.getSafetyObservationListingRange();
                        }
                    })
                    .catch ( error => {
                        this.errorFunction(error, 'Contractor Id')
                    });
            },
            filterByUser(){
                var tempTableData = [];
                this.groupMembers.forEach((e) => {
                    this.tableData.forEach((e_inner) => {
                        if (e_inner['project_manager']['id'] == e) {
                            tempTableData.push(e_inner);
                        }
                    });
                });
                this.tableData = tempTableData;
            },
            paginationChanged(page)
            {
                this.pagination.currentPage = page
            },
            // handleDetails(index, row)
            // {
            //     this.$router.push({path: 'projectDashboard/'+row.project_id })
            // },
            handleDetails(index, row)
            {
                // if draft and creator redirect to edit
                // else redirect to view items page

                if (row.status == 0 && row.created_by.id == this.myUserID.id) {
                    // this.$router.push({path: 'safetyObservationEdit/'+row.safety_observation_id });
                    // this.$router.push({ path: 'safetyObservationEdit/' + row.safety_observation_id, target: '_blank' });
                    window.open('safetyObservationEdit/' + row.safety_observation_id, '_blank');
                } else {
                    // this.$router.push({path: 'safetyObservationView/'+row.safety_observation_id });
                    // this.$router.push({ path: 'safetyObservationView/' + row.safety_observation_id, target: '_blank' });
                    window.open('safetyObservationView/' + row.safety_observation_id, '_blank');
                }


            },
            deleteDetails(index,row){
                this.safetyInspectionId = row.safety_observation_id;
                this.isDeleteSafetyObservationModalVisible = true;
            },
            deleteSafetyInspection() {
                const empty_request = {
                    empty_request: null
                };
                console.log(this.safetyInspectionId);
                axios.post(apiDomain + 'health_safety/delete_safety_inspection/'+ this.safetyInspectionId, empty_request,{ headers: getHeader() })
                    .then ( response => {
                        console.log(response);
                        if (response.status === 200)
                        {
                            this.$notify({
                                message:
                                '<b> Safety Observation : ' + this.safetyInspectionId + '</b> - Successfully Deleted.',
                                timeout: 10000,
                                icon: 'ni ni-bell-55',
                                type: 'default',
                            });
//                            this.$router.push({path: '/healthSafety/safetyObservationListing'})
                            this.closeDeleteSafetyObservationModalInner();
                            this.getSafetyObservationListingRange();

                        }
                    })
                    .catch ( error => {
                        console.log(error.response.data.errors)
                        this.errorFunction(error, "Delete Safety Inspection Details", this.safetyInspectionId)
                    });
            },
            // handleMilestone(index, row)
            // {
            //     this.$router.push({path: 'projectMilestoneListing/'+row.project_id })
            // },
            selectionChange(selectedRows)
            {
                this.selectedRows = selectedRows
            },
            closeDeleteSafetyObservationModal() {
                this.isDeleteSafetyObservationModalVisible = false;
            },
            showDeleteSafetyObservationModalInner() {
                this.isDeleteSafetyObservationModalVisible = false;
                this.isDeleteSafetyObservationModalVisibleInner = true;
            },
            closeDeleteSafetyObservationModalInner() {
                this.isDeleteSafetyObservationModalVisibleInner = false;
            },
        }
    };
</script>
<style>
    .no-border-card .card-footer{
        border-top: 0;
    }
</style>
