var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',{attrs:{"no-body":""}},[_c('b-card-header',{staticClass:"border-0"},[_c('h3',{staticClass:"mb-0"},[_vm._v("Document Recurring Listing For (Staff Daily Activity Report)")])]),_c('el-table',{staticClass:"table-responsive table-flush",attrs:{"header-row-class-name":"thead-light","data":_vm.projects}},[_c('el-table-column',{attrs:{"label":"Recurring Date","min-width":"110px","prop":"name","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('b-media',{staticClass:"align-items-center",attrs:{"no-body":""}},[_c('b-media-body',[_c('span',{staticClass:"font-weight-600 name mb-0 text-sm"},[_vm._v(_vm._s(row.recurring_date))])])],1)]}}])}),_c('el-table-column',{attrs:{"label":"Interval","min-width":"120px","prop":"name","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('b-media',{staticClass:"align-items-center",attrs:{"no-body":""}},[_c('b-media-body',[_c('span',{staticClass:"font-weight-600 name mb-0 text-sm"},[_vm._v(_vm._s(row.recurring_interval))])])],1)]}}])}),_c('el-table-column',{attrs:{"label":"Total File","min-width":"110px"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('b-media',{staticClass:"align-items-center",attrs:{"no-body":""}},[_c('b-media-body',[_c('span',{staticClass:"font-weight-600 name mb-0 text-sm"},[_vm._v(_vm._s(row.total_file))])])],1)]}}])}),_c('el-table-column',{attrs:{"label":"Current Status","min-width":"110px","prop":"status","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('badge',{staticClass:"badge-dot mr-4",attrs:{"type":""}},[_c('span',{staticClass:"status"},[_vm._v(_vm._s(row.status))])])]}}])}),_c('el-table-column',{attrs:{"label":"Action","min-width":"110px"},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('div',{staticClass:"d-flex align-items-center"},[_c('div',[_c('router-link',{attrs:{"to":"/projectDashboard"}},[_c('base-button',{attrs:{"size":"sm","type":"primary"}},[_vm._v("Doc. Details")])],1)],1)])]},proxy:true}])})],1),_c('b-card-footer',{staticClass:"py-4 d-flex justify-content-end"},[_c('base-pagination',{attrs:{"per-page":3,"total":6},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }