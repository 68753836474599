/**
 * Format a given size in bytes to readable format in units.
 * @param {int} bytes Size in bytes
 * @return {string} Size in units
 */
export function formatBytesInUnits(bytes) {
  if (bytes >= 1073741824)
    return (bytes / 1073741824).toFixed(2) + ' GB'

  if (bytes >= 1048576)
    return (bytes / 1048576).toFixed(2) + ' MB'

  if (bytes >= 1024)
    return (bytes / 1024).toFixed(2) + ' KB'

  if (bytes == 1)
    return '1 byte'

  return bytes + ' bytes'
}

/**
 * Gets descriptive text for a document status value.
 * @param {string} status Document status value
 * @returns {string} Status text
 */
export function documentStatusToText(status) {
  if (status == 'EMPTY')
    return 'Empty'

  return revisionStatusToText(status)
}

/**
 * Gets descriptive text for a revision status value.
 * @param {string} status Revision status value
 * @returns {string} Status text
 */
export function revisionStatusToText(status) {
  switch (status) {
    case 'NEW_REVISION':
      return 'New Revision'

    case 'PENDING_REVIEW':
      return 'Pending Review'

    case 'NEW_REVIEW':
      return 'Being Reviewed'

    case 'ACCEPTED':
      return 'Accepted'

    case 'REJECTED':
      return 'Rejected'

    case 'APPROVED':
      return 'Approved'
    }

    return status
}
