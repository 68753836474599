<template>
  <card>
    <!-- Card header -->
    <div>
       <h2 slot="header" class="mb-3.5">Site Information : SN-NT - Tengeh Reservoir North Site for (TRTP-2398 - Tengeh Reservoir Tender Project) </h2>
       <div class="text-right">
          <router-link to="/siteEdit">
              <base-button size="sm" type="primary">Edit</base-button>
          </router-link>
       </div>
    </div>

    <form class="needs-validation"
          @submit.prevent="submit">
      <div class="form-row">
        <b-col md="2">
          <base-input label="Site Code"
                      name="Site Code"
                      placeholder="Site Code"
                      v-model="model.siteCode" disabled>
          </base-input>
        </b-col>

        <b-col md="7">
          <base-input label="Site Name"
                      name="Site Name"
                      placeholder="Site Name"
                      v-model="model.siteName" disabled>
          </base-input>
        </b-col>

        <b-col md="3">
          <base-input label="Project Manager"
                      name="Project Manager"
                      v-model="model.siteProjectManager" disabled>
          </base-input>
        </b-col>
      </div>

      <div class="form-row">
        <b-col md="2">
          <base-input label="Site Country"
                      name="Site Country"
                      placeholder="Site Country"
                      v-model="model.siteCountry" disabled>
          </base-input>
        </b-col>

        <b-col md="4">
          <base-input label="Address"
                      name="Address"
                      placeholder="Address"
                      v-model="model.siteAddress" disabled>
          </base-input>
        </b-col>

        <b-col md="3">
          <base-input label="Curent Milestone"
                      name="Curent Milestone"
                      v-model="model.siteMilestone" disabled>
          </base-input>
        </b-col>

        <b-col md="3">
          <base-input label="Project Manager Contact"
                      name="Project Manager Contact"
                      v-model="model.siteProjectManagerContact" disabled>
          </base-input>
        </b-col>
      </div>

      <div class="form-row">
        <b-col md="6">
          <base-input label="Contractor List">
              <el-select v-model="selects.multiple"
                  multiple
                  filterable
                  placeholder="Contractor Listt" disabled>
                <el-option v-for="option in selectOptions"
                    :key="option.label"
                    :label="option.label"
                    :value="option.value">
                </el-option>
              </el-select>
          </base-input>
        </b-col>
        <b-col md="3">
          <base-input label="Status"
                      name="Status"
                      v-model="model.siteStatus" disabled>
          </base-input>
        </b-col>
        <b-col md="3">
          <base-input label="Progress (%)"
                      name="Progress"
                      placeholder="Progress"
                      v-model="model.siteProgress" disabled>
          </base-input>
        </b-col>
      </div>

      <div class="form-row">
          <b-col md="3">
          <base-input label="Module Quantity"
                      name="Module Quantity"
                      placeholder="Module Quantity"
                      v-model="model.siteModuleQuantity" disabled>
          </base-input>
          </b-col>
          <b-col md="3">
          <base-input label="Module Capacity (Wp)"
                      name="Module Capacity"
                      placeholder="Module Capacity"
                      v-model="model.siteModuleCapacity" disabled>
          </base-input>
          </b-col>
          <b-col md="3">
          <base-input label="Total Capacity (Kwp>"
                      name="Total Capacity"
                      placeholder="Total Capacity"
                      v-model="model.siteTotalCapacity" disabled>
          </base-input>
          </b-col>
          <b-col md="3">
          <base-input label="PR Capacity (%)"
                      name="PR Capacity"
                      placeholder="PR Capacity"
                      v-model="model.sitePRCapacity" disabled>
          </base-input>
          </b-col>
      </div>

      <div class="form-row">
          <b-col md="3">
          <base-input label="Type of Tension"
                      name="Type of Tension"
                      placeholder="Type of Tension"
                      v-model="model.siteTypeOfTension" disabled>
          </base-input>
          </b-col>
          <b-col md="3">
          <base-input label="Approved Load"
                      name="Approved Load"
                      placeholder="Approved Load"
                      v-model="model.siteApprovedLoad" disabled>
          </base-input>
          </b-col>
          <b-col md="3">
          <base-input label="MSSL Account Number"
                      name="MSSL Account Number"
                      placeholder="MSSL Account Number"
                      v-model="model.siteMsslAccountNumber" disabled>
          </base-input>
          </b-col>
          <b-col md="3">
          <base-input label="Switchroom Tie"
                      name="Switchroom Tie"
                      placeholder="Switchroom Tie"
                      v-model="model.siteSwitchroomTie" disabled>
          </base-input>
          </b-col>
      </div>

      <div class="form-row">
          <b-col md="4">
          <base-input label="Plant Name"
                      name="Plant Name"
                      placeholder="Plant Name"
                      v-model="model.sitePlantName" disabled>
          </base-input>
          </b-col>
          <b-col md="4">
          <base-input label="Folder Name in SFTP Server"
                      name="Folder Name in SFTP Server"
                      placeholder="Folder Name in SFTP Server"
                      v-model="model.siteFolderNameInSFTPServer" disabled>
          </base-input>
          </b-col>
          <b-col md="4">
          <base-input label="Monitoring Application"
                      name="Monitoring Application"
                      placeholder="Monitoring Application"
                      v-model="model.siteMonitoringApplication" disabled>
          </base-input>
          </b-col>
      </div>

      <div class="form-row">
          <b-col md="4">
          <base-input label="Total Inverter"
                      name="Total Inverter"
                      placeholder="Total Inverter"
                      v-model="model.siteTotalInverter" disabled>
          </base-input>
          </b-col>
          <b-col md="4">
          <base-input label="Logger Type"
                      name="Logger Type"
                      placeholder="Logger Type"
                      v-model="model.siteLoggerType" disabled>
          </base-input>
          </b-col>
          <b-col md="4">
          <base-input label="Total Data Logger"
                      name="Total Data Logger"
                      placeholder="Total Data Logger"
                      v-model="model.siteTotalDataLogger" disabled>
          </base-input>
          </b-col>
      </div>



      <div class="form-row">
        <b-col md="3">
          <base-input label="Start Date"
                      name="Start Date"
                      v-model="model.siteStartDate" disabled>
          </base-input>
        </b-col>
        <b-col md="3">
          <base-input label="End Date"
                      name="End Date"
                      v-model="model.siteEndDate" disabled>
          </base-input>
        </b-col>

        <b-col md="3">
          <base-input label="ELU End Date"
                      name="ELU End Date"
                      v-model="model.siteEluEndDate" disabled>
          </base-input>
        </b-col>
        <b-col md="3">
          <base-input label="EI Expiry Date"
                      name="EI Expiry Date"
                      v-model="model.siteEiExpiryDate" disabled>
          </base-input>
        </b-col>
      </div>

      <div class="form-row">
        <b-col md="3">
          <base-input label="Contractor Contractual Start Date"
                      name="Contractor Contractual Start Date"
                      v-model="model.siteContratorStartDate" disabled>
          </base-input>
        </b-col>
        <b-col md="3">
          <base-input label="Contractor Contractual End Date"
                      name="Contractor Contractual End Date"
                      v-model="model.siteContratorEndDate" disabled>
          </base-input>
        </b-col>

        <b-col md="3">
          <base-input label="ELU License Number"
                      name="ELU License Number"
                      v-model="model.siteEluLicenseNumber" disabled>
          </base-input>
        </b-col>
        <b-col md="3">
          <base-input label="ELU Status"
                      name="ELU Status"
                      v-model="model.siteELUStatus" disabled>
          </base-input>
        </b-col>
      </div>
    </form>
  </card>
</template>
<script>
  import { Select, Option } from 'element-ui'
  import BaseSlider from '@/components/BaseSlider'
  export default {
    name: 'site-view-form',
    components: {
      BaseSlider,
      [Select.name]: Select,
      [Option.name]: Option,
    },
    data() {
      return {
        selectOptions: [
          {
            label: 'Solar Ex',
            value: 'Solar Ex'
          },
          {
            label: 'West & Smith (Suspended)',
            value: 'West & Smith (Suspended)'
          },
          {
            label: 'AVIAYA (Suspended)',
            value: 'AVIAYA (Suspended)'
          },
          {
            label: 'Long Seng',
            value: 'Long Seng'
          },
        ],
        selects: {
          simple: 'Badges',
          multiple: ['Solar Ex', 'Long Seng']
        },
        model: {
          siteCode: 'SN-NT',
          siteName: 'Tengeh Reservoir North Site',
          siteProjectManager: 'James',
          siteCountry: 'Singapore',
          siteAddress: '501 Jln. Ahmad Ibrahim, Singapura 639937',
          siteMilestone: 'Design',
          siteProjectManagerContact: '012-11232887',
          siteStatus: 'In Progress',
          siteProgress: 34,
          siteModuleQuantity: '122449',
          siteModuleCapacity: '490',
          siteTotalCapacity: '1213',
          sitePRCapacity: '0',
          siteTypeOfTension: 'None',
          siteApprovedLoad: '',
          siteMsslAccountNumber: '',
          siteSwitchroomTie: '',
          sitePlantName: 'SG-TENGAH',
          siteFolderNameInSFTPServer: 'SG-TENGAH',
          siteMonitoringApplication: 'Solarmon',
          siteTotalInverter: '100',
          siteLoggerType: '10',
          siteTotalDataLogge: 'Huawei Data Logger',
          siteStartDate: '2021-02-01',
          siteEndDate: '2021-07-01',
          siteEluEndDate: '2021-05-05',
          siteEiExpiryDate: '2021-05-05',
          siteContratorStartDate:'2021-01-01',
          siteContratorEndDate: '2021-12-31',
          siteEluLicenseNumber: '213123213',
          siteELUStatus: 'Pending',
          agree: false
        }
      }
    },
    methods: {
      async submit() {
              this.$router.push('project')
      },
      notifyVue(type = 'default') {
        // console.log(type)
        this.$notify({
          message:
            '<b>Update Project : (' + this.model.project_code +' - ' + this.model.project_name + ')</b> - Successfully Updated.',
          timeout: 10000,
          icon: 'ni ni-bell-55',
          type
        });
      },
    }
  }
</script>
<style>
</style>
