<template>
  <div class="bg-default-dashboard">
    <div class="py-lg-8 pt-lg-5">
      <b-container fluid style="width: 90%; position: relative; margin-top: 45px;">
        <!-- <div class="header-body "  > -->
        <span class="text-white display-4 mr-3">{{ model.project_name }}</span>
        <a class="btn btn-sm btn-warning rounded-pill text-default" style="position: relative; top: -10px">
          <i class="fa fa-xs fa-circle mr-1"></i>In Progress
        </a>
        <first-tab-content style="margin-top: 30px; "/>
        <!-- <b-tabs style="margin-top: 20px;" content-class="mt-3" pills>
          <b-tab title="Dashboard" active>
            <first-tab-content />
          </b-tab>
          <b-tab title="Calendar">
            <second-tab-content />
          </b-tab>
          <b-tab title="Gallery">
            <third-tab-content />
          </b-tab>
          <b-tab title="Fourth">
            <fourth-tab-content />
          </b-tab>
          <b-tab title="Live feed">
            <fifth-tab-content />
          </b-tab>
        </b-tabs> -->

      </b-container>
    </div>
  </div>
</template>

<script>
import { Table, TableColumn } from "element-ui";
import { VueEllipseProgress } from "vue-ellipse-progress";
// import VueHighcharts from "vue2-highcharts";
import Highcharts from 'highcharts';
import VueFlip from "vue-flip";
import FirstTabContent from './SiteDetail/TabContent1_main.vue';
// import SecondTabContent from './SiteDetail/TabContent2_calendar.vue';
// import ThirdTabContent from './SiteDetail/TabContent3_gallery.vue';
//import FourthTabContent from './SiteDetail/FourthTabContent.vue';
// import FifthTabContent from './SiteDetail/TabContent5_cctv.vue';
import {
  LoginURL,
  clientId,
  clientSecret,
  apiDomain,
  getHeader,
  getcurrentDate,
} from "@/assets/js/config.js";
// import function to register Swiper custom elements
// import { register } from 'swiper/element/bundle';
// register Swiper custom elements
// register();
import axios from "axios";
import ErrorFunctionMixin from "@/assets/js/errorFunctionMix.js";
import jsonFormData from "json-form-data";
import VueApexCharts from "vue-apexcharts";
import moment from "moment";
import ProjectSiteTemplateViewForm from "@/views/SemCorp/Template/Project/ProjectSiteTemplateViewForm.vue";
import { Select, Option } from "element-ui";
// import '@/assets/css/nucleo/css/tailwind.css';
// import { Swiper, SwiperSlide } from 'swiper/vue';
// import 'swiper/scss';
// import 'swiper/scss/pagination';
// import 'swiper/scss/navigation';
// import 'swiper/scss/a11y';
//  import { EffectCoverflow, Pagination } from 'swiper/modules';
// import 'swiper/modules/effect-fade/effect-fade';
// import { EffectCoverflow, Navigation, Pagination } from 'swiper';
// import 'swiper/swiper-bundle.css';
// import 'swiper/swiper-bundle.min.css';
// import 'swiper/css/effect-coverflow';
// import 'swiper/css/pagination';
export default {
  components: {
    'high-charts': Highcharts,
    FirstTabContent,
    // SecondTabContent,
    // ThirdTabContent,
    //FourthTabContent,
    // FifthTabContent,
    //Drafts,
    //Test,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    // VueEllipseProgress,
    // VueHighcharts,
    "apexchart": VueApexCharts,
    'vue-flip': VueFlip,
    // Swiper,
    //   SwiperSlide,
  },
  setup() {

  },
  created() {
    const LoginUser = JSON.parse(window.localStorage.getItem("loginUser"));
    this.myUserID = LoginUser;
    // this.getDeveloperListing();
    // this.getProjectTypeListing();
    // this.getProjectManagerListing();
    // this.getProjectManagerDetails();
    // this.getProjectEngineerListing();
    // this.getBusinessDevelopmentListing();
    // this.getSafetyOfficerListing();
    // this.getProjectSiteStatusListing();
    this.getDashboardData();
    // this.getGanttChartData();
  },
  // el: '#slider',
  data() {
    const month = new Date().getMonth();
    const year = new Date().getFullYear();
    return {
      seriess: [60],
      chartOptionss: {

        chart: {
          height: 350,
          type: 'radialBar',
          sparkline: {
            enabled: true
          },

        },
        plotOptions: {
          radialBar: {
            startAngle: -90,
            endAngle: 90,
            hollow: {
              margin: 0,
              size: '70%',
              background: '#23454a',
              image: undefined,
              imageOffsetX: 0,
              imageOffsetY: 0,
              position: 'front',
              dropShadow: {
                enabled: true,
                top: 3,
                left: 10,
                blur: 4,
                opacity: 0.24
              }
            },

            track: {
              background: '#23454a',
              strokeWidth: '67%',
              margin: 0, // margin is in pixels
              dropShadow: {
                enabled: true,
                top: -3,
                left: 0,
                blur: 0,
                opacity: 0.35
              }
            },

            dataLabels: {
              show: true,
              name: {
                offsetY: -60,
                show: false,
                color: '#888',
                fontSize: '17px'
              },
              value: {
                formatter: function (val) {
                  return parseInt(val) + ' %';
                },
                fontFamily: 'Open Sans, sans-serif',
                offsetY: -2,
                color: '#fff',
                fontSize: '50px',
                show: true,
              }
            }
          }
        },
        fill: {
          type: 'gradient',
          gradient: {
            shade: 'dark',
            type: 'horizontal',
            shadeIntensity: 0.8,
            gradientToColors: ['#60C2D1'],
            inverseColors: true,
            opacityFrom: 1,
            opacityTo: 1,
            stops: [0, 100]
          }
        },
        stroke: {
          lineCap: 'round'
        },
        labels: ['Percent'],
      },
      cards: [{
        front: 'Front of card 1',
        back: 'Back of card 1',
        flipped: false
      },
      ],
      obj_inspection: {
        intMonthInc: 0,
        siteTaskYear: 0,
        chartKey: 0,
        chartOptions: {
          chart: {
            type: "bar",
            height: 350,
            toolbar: {
              show: false,
            },
          },
          datas: [{
            group: 'value',
            value: 42
          },
          {
            group: 'delta',
            value: -13.37
          }],
          options: {
            title: 'Gauge semicircular -- danger status',
            resizable: true,
            height: '250px',
            gauge: {
              type: 'semi',
              status: 'danger'
            }
          },
          colors: ["#BA5A5A", "#5ABAB9"],
          plotOptions: {
            bar: {
              horizontal: false,
              columnWidth: "55%",
              endingShape: "rounded",
            },
          },
          dataLabels: {
            enabled: false,
          },
          stroke: {
            show: true,
            width: 2,
            colors: ["transparent"],
          },
          xaxis: {
            categories: ["Feb", "Mar", "Apr", "May", "Jun", "Jul"],
            labels: {
              // colors:"#fff",
              style: {
                colors: ["#fff", "#fff", "#fff", "#fff", "#fff", "#fff"],
              },
            },
          },
          yaxis: {
            labels: {
              color: "#fff",
              style: {
                colors: ["#fff"],
              },
            },
          },
          fill: {
            opacity: 1,
          },
          legend: {
            show: true,
            showForSingleSeries: true,
            // position: "bottom",
            // horizontalAlign: "left",
            // verticalAlign: "left",
            // floating: false,
            // fontSize: "20px",
            offsetX: 0,
            offsetY: 10,

            labels: {
              colors: "#fff",
            },
            // colors:["#fff"]
          },
        },
        series: [
          {
            name: "Negative Observation",
            data: [0, 0, 0, 0, 0, 0],
          },
          {
            name: "Positive Observation",
            data: [0, 0, 0, 0, 0, 0],
          },
        ],
        items_cek: [],
        items: [
          {
            id: 0,
            task_name: "PLTS IKN",
            planned_start: "10/07/2024",
            planned_end: "30/07/2024",
            status: "finish",
          },
          {
            id: 0,
            task_name: "PLTS IKN",
            planned_start: "10/07/2024",
            planned_end: "30/07/2024",
            status: "finish",
          },
        ],
        si_site_all: [],
        so_site_all: [],
      },
      obj_observation_items: {
        chartOptions: null,
        // series: [{data: null}],
      },
      obj_gant_chart: {
        chartOptions: null,
      },
      slides: [
        "/img/ikn/01.png",
        "/img/ikn/02.png",
        "/img/ikn/03.png",
        "/img/ikn/04.png",
        "/img/ikn/05.png",
        "/img/ikn/06.png",
        "/img/ikn/07.png",
        "/img/ikn/08.png",
        "/img/ikn/09.png",
      ],
      current: 0,
      width: 800,
      timer: 0,
      model: {
        project_id: null,
        developer_id: "x",
        project_type_id: "x",
        project_manager: "x",
        project_manager_name: "x",
        project_engineer: "x",
        project_engineer_name: "x",
        project_business_development: "x",
        project_business_development_name: "x",
        project_safety: "x",
        project_safety_name: "x",
        project_name: null,
        project_ppa_approved_capacity: null,
        project_ppa_start_date: null,
        project_watt_class: null,
        roofed_handover_date: null,
        project_tag: null,
        site_id: null,
      },
      developers: [],
      project_type: [],
      project_managers: [],
      project_engineers: [],
      project_business_development: [],
      project_safety: [],
      site_status: [],
      siteListingData: [],
      siteTaskProgressData: [],
      siteTaskMonth: "00",
      siteTaskYear: 0,
      siteTaskBase01: "",
      siteTaskBase02: "",
      siteTaskBase03: "",
      siteTaskBase04: "",
      siteTaskBase05: "",
      siteTaskBase06: "",
      siteInc: 0,
      taskDiff: 0,
      isSolarNovaProject: false,
      items: [],
      fields: [
        { key: "key_mil", label: "Key Milestone" },
        { key: "base", label: "Base" },
        { key: "actual", label: "Actual" },
        { key: "diff", label: "Diff" },
        { key: "b1", label: "Base" },
        { key: "b2", label: "Base" },
        { key: "b3", label: "Base" },
        { key: "b4", label: "Base" },
        { key: "b5", label: "Base" },
        { key: "b6", label: "Base" },
      ],
      daymonth: [
        { id: 0, label: "30/4", labelData: "30/4" },
        { id: 1, label: "31/5", labelData: "30/4" },
        { id: 2, label: "30/6", labelData: "30/4" },
        { id: 3, label: "31/7", labelData: "30/4" },
        { id: 4, label: "31/8", labelData: "30/4" },
        { id: 5, label: "30/9", labelData: "30/4" },
      ],
      series_pie: [44, 55, 41, 17, 15, 44, 12, 11],
      chartOptions_pie: {
        chart: {
          type: "donut",
        },
        labels: [
          "Pending Submission",
          "Submit",
          "In Review",
          "RI",
          "AC",
          "Return",
          "A",
          "TR",
        ],
        colors: [
          "#5090DC",
          "#63FFF9",
          "#34BFE1",
          "#E1CC00",
          "#00FFB5",
          "#459D55",
          "#8BD1CF",
          "#FFA03A",
        ],
        dataLabels: {
          enabled: false,
        },
        stroke: {
          show: false,
          width: 0,
        },
        legend: {
          show: true,
          showForSingleSeries: true,
          // position: "bottom",
          // horizontalAlign: "left",
          // verticalAlign: "left",
          // floating: false,
          // fontSize: "20px",
          offsetX: 0,
          offsetY: 10,

          labels: {
            colors: "#fff",
          },
          // colors:["#fff"]
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },

              legend: {
                position: "bottom",
              },
            },
          },
        ],
      },
      ms_upload_task: [],
      series: [],
      chartOptions: {
        chart: {
          height: 390,
          type: "rangeBar",

          zoom: {
            enabled: false,
          },
          toolbar: {
            show: false,
          },
        },
        // colors: ['#00B9E2', '#D05252'],
        plotOptions: {
          bar: {
            borderRadius: 20,
            borderRadiusWhenStacked: "all", // 'all', 'last'
            horizontal: true,
            columnWidth: "50%",
            barHeight: "50%",
          },
        },
        fill: {
          type: "gradient",
          gradient: {
            shade: "light",
            type: "vertical",
            shadeIntensity: 0.25,
            gradientToColors: undefined,
            inverseColors: true,
            opacityFrom: 1,
            opacityTo: 1,
            stops: [50, 0, 100, 100],
          },
        },
        legend: {
          show: false,
          showForSingleSeries: true,
          position: "bottom",
          horizontalAlign: "center",
          customLegendItems: ["Base", "Actual"],
          labels: {
            colors: "#fff",
          },
          // colors:["#fff"]
        },
        xaxis: {
          type: "datetime",
          labels: {
            color: "#fff",
            style: {
              colors: ["#fff"],
            },
          },
        },
        yaxis: {
          labels: {
            color: "#fff",
            style: {
              colors: ["#fff"],
              fontFamily: 'Open Sans, sans-serif',
              fontSize: '1.25rem',
              fontWeight: '300',
            },
          },
        },
        grid: {
          xaxis: {
            lines: {
              show: false,
            },
          },
          yaxis: {
            lines: {
              show: true,
            },
          },
        },
      },
    };
  },
  methods: {
    toggleCard: function (card) {
      card.flipped = !card.flipped;
    },
    getDeveloperListing() {
      axios
        .get(apiDomain + "setup/developer", { headers: getHeader() })
        .then((response) => {
          if (response.status === 200) {
            this.developers = response.data.data;
            // default to sembcorp solar || developer_id = 1
            // this.model.developer_id = 1;
          }
        })
        .catch((error) => {
          console.log(error.response.status);
          this.errorFunction(error, "Developer Information");
        });
    },
    getProjectTypeListing() {
      axios
        .get(apiDomain + "setup/project_type", { headers: getHeader() })
        .then((response) => {
          if (response.status === 200) {
            this.project_type = response.data.data;
          }
        })
        .catch((error) => {
          console.log(error.response.status);
          this.errorFunction(error, "Project Type Information");
        });
    },
    getProjectManagerListing() {
      axios
        .get(apiDomain + "lookup/user_list/Project_Manager", {
          headers: getHeader(),
        })
        .then((response) => {
          if (response.status === 200) {
            this.project_managers = response.data.data;
          }
        })
        .catch((error) => {
          console.log(error.response.status);
          this.errorFunction(error, "Project Manager");
        });
    },
    getProjectManagerDetails() {
      axios
        .get(apiDomain + "lookup/user_list/Project_Manager", {
          headers: getHeader(),
        })
        .then((response) => {
          if (response.status === 200) {
            this.project_managers = response.data.data;
          }
        })
        .catch((error) => {
          console.log(error.response.status);
          this.errorFunction(error, "Project Manager");
        });
    },
    getProjectEngineerListing() {
      axios
        .get(apiDomain + "lookup/user_list/Engineer", { headers: getHeader() })
        .then((response) => {
          if (response.status === 200) {
            this.project_engineers = response.data.data;
          }
        })
        .catch((error) => {
          console.log(error.response.status);
          this.errorFunction(error, "Engineer Information");
        });
    },
    getBusinessDevelopmentListing() {
      axios
        .get(apiDomain + "lookup/user_list/Business_Development", {
          headers: getHeader(),
        })
        .then((response) => {
          if (response.status === 200) {
            this.project_business_development = response.data.data;
          }
        })
        .catch((error) => {
          console.log(error.response.status);
          this.errorFunction(error, "Safety Officer Information");
        });
    },
    getSafetyOfficerListing() {
      axios
        .get(apiDomain + "lookup/user_list/Safety", { headers: getHeader() })
        .then((response) => {
          if (response.status === 200) {
            this.project_safety = response.data.data;
          }
        })
        .catch((error) => {
          console.log(error.response.status);
          this.errorFunction(error, "Safety Officer Information");
        });
    },
    getProjectSiteStatusListing() {
      // axios.get(apiDomain + 'lookup/site_status', { headers: getHeader() })
      axios
        .get(apiDomain + "lookup/site_status.singapore_v2", {
          headers: getHeader(),
        })
        .then((response) => {
          if (response.status === 200) {
            this.site_status = response.data.data;
          }
        })
        .catch((error) => {
          console.log(error.response.status);
          this.errorFunction(error, "Site Status Information");
        });
    },
    getDashboardData() {
      this.model.project_id = this.$route.params.id;
      axios
        .get(apiDomain + "project/project/" + this.model.project_id, {
          headers: getHeader(),
        })
        .then((response) => {
          if (response.status === 200) {
            this.model.project_name = response.data.data[0].project_name;
            document.title = "MOMENS-" + response.data.data[0].project_name;
            this.model.developer_id = response.data.data[0].developer.developer_id;
            this.model.developer_name = response.data.data[0].developer.developer_name;
            this.model.project_type_id = response.data.data[0].project_type.project_type_id;
            if (
              this.model.project_type_id != null &&
              [2, 3, 4, 9, 10, 11, 12, 13, 14].includes(
                this.model.project_type_id
              )
            ) {
              this.isSolarNovaProject = true;
            } else {
              this.isSolarNovaProject = false;
              this.getSiteListing();
            }
            this.model.project_type_name = response.data.data[0].project_type.project_type;
            this.model.project_ppa_counter_party = response.data.data[0].project_ppa_counter_party;
            let this_project_ppa_start_date =
              response.data.data[0].project_ppa_start_date !== null
                ? response.data.data[0].project_ppa_start_date
                : "N/A";
            if (this_project_ppa_start_date !== "N/A") {
              let convertProjectStartDateDate = new Date(this_project_ppa_start_date);
              const year = convertProjectStartDateDate.getFullYear();
              const month = convertProjectStartDateDate.toLocaleString(
                "en-US",
                { month: "short" }
              );
              const day = convertProjectStartDateDate.toLocaleString("en-US", {
                day: "2-digit",
              });
              this_project_ppa_start_date = `${day}-${month}-${year}`;
            }

            this.model.project_ppa_start_date = this_project_ppa_start_date;
            // console.log(response.data.data[0].project_ppa_approved_capacity);
            this.model.project_ppa_approved_capacity = parseFloat(
              response.data.data[0].project_ppa_approved_capacity.replace(
                /,/g,
                ""
              )
            );
            if (response.data.data[0].project_watt_class !== null) {
              this.model.project_watt_class = parseFloat(
                response.data.data[0].project_watt_class.replace(/,/g, "")
              );
            }
            this.model.project_manager = response.data.data[0].project_manager.id;
            this.model.project_manager_name = response.data.data[0].project_manager.name;
            this.model.project_engineer = response.data.data[0].project_engineer.id;
            this.model.project_engineer_name = response.data.data[0].project_engineer.name;
            this.model.project_business_development = response.data.data[0].project_business_development.id;
            this.model.project_business_development_name = response.data.data[0].project_business_development.name;
            this.model.project_safety = response.data.data[0].project_safety.id;
            this.model.project_safety_name = response.data.data[0].project_safety.name;
            this.model.project_tag = response.data.data[0].project_tag;
          }
        })
        .catch((error) => {
          console.log(error.response.status);
          if (error.response.status == 401) {
            this.$router.push("/login");
          }
        });
    },
    customFormatNumber(theData) {
      console.log("the", theData)
      // let retVal = parseFloat(theData.replace(/,/g, ""));
      let retVal = theData;
      return retVal;
    },
    customFormatDate(theData) {
      let checkDate = theData !== null ? theData : "N/A";
      if (checkDate !== "N/A") {
        let convertCheckDate = new Date(checkDate);
        const year = convertCheckDate.getFullYear();
        const month = convertCheckDate.toLocaleString(
          "en-US",
          { month: "short" }
        );
        const day = convertCheckDate.toLocaleString("en-US", {
          day: "2-digit",
        });

        return `${day}-${month}-${year}`;
      }
    },
    getSiteListing() {
      axios
        .get(
          apiDomain +
          "project/project_site.show_by_project/" +
          this.model.project_id,
          { headers: getHeader() }
        )
        .then((response) => {
          // console.log("getSiteListing()");
          // console.log(response.data);
          if (response.status === 200) {
            let data = response.data.data;
            data.forEach((item) => {
              item.visible = false;
              //                    this.getContractorFromSite(item.site_id);
            });

            this.siteListingData = data;

            axios
              .get(
                apiDomain +
                "project/ms_task_main_level/" +
                this.siteListingData[0].site_id,
                { headers: getHeader() }
              )
              .then((response) => {
                if (response.status === 200) {
                  this.ms_upload_task = response.data.data;
                  this.updateGanttChartSeries();
                  this.getSiteTaskProgressData();
                  this.getSafetyInspectionList();
                  // this.updateChartObservationItems();
                }
              })
              .catch((error) => {
                if (typeof error.response !== "undefined") {
                  this.errorFunction(error, "Site Status Information");
                }
              });
          }
        })
        .catch((error) => {
          console.log(error.response.status);
        });
    },
    getSiteTaskProgressData() {
      axios
        .get(
          apiDomain +
          "project/get_dashboard_utilities_data_by_project_site/" +
          this.siteListingData[0].site_id,
          { headers: getHeader() }
        )
        .then((response) => {
          // console.log("getSiteTaskProgressData()");
          // console.log(response.data);
          if (response.status === 200) {
            this.siteTaskProgressData = response.data.data;
            //alert(this.siteTaskProgressData[0].task_name);
            const today = new Date();
            this.siteTaskYear = today.getFullYear();
            this.siteInc = 1;
            for (let key in this.daymonth) {
              this.siteTaskMonth = String(today.getMonth() + this.siteInc).padStart(2, "0");
              this.daymonth[key].label = this.getLabelLastDayOfMonth(this.siteTaskYear, this.siteTaskMonth);
              this.daymonth[key].labelData = this.getLastDayOfMonth(this.siteTaskYear, this.siteTaskMonth);
              this.siteInc = this.siteInc + 1;
            }

            for (let task in this.siteTaskProgressData) {
              let taskColor = "-";
              if (this.siteTaskProgressData[task].diff_in_progress >= 0) {
                taskColor = "+";
              }
              this.taskDiff = this.siteTaskProgressData[task].diff_in_progress;

              this.items.push({
                key_mil: this.siteTaskProgressData[task].task_name,
                base: this.siteTaskProgressData[task].today_progress + "%",
                actual:
                  this.siteTaskProgressData[task].percent_completion + "%",
                diff: this.taskDiff.toFixed(2),
                diff_status: taskColor,
                b1: this.siteTaskProgressData[task].monthly_progress.base0 + "%",
                b2: this.siteTaskProgressData[task].monthly_progress.base1 + "%",
                b3: this.siteTaskProgressData[task].monthly_progress.base2 + "%",
                b4: this.siteTaskProgressData[task].monthly_progress.base3 + "%",
                b5: this.siteTaskProgressData[task].monthly_progress.base4 + "%",
                b6: this.siteTaskProgressData[task].monthly_progress.base5,
              });
            }
          }
        })
        .catch((error) => {
          if (typeof error.response !== "undefined") {
            this.errorFunction(error, "Site Status Information");
          }
        });
    },
    getLastDayOfMonth(year, month) {
      let date = new Date(year, month, 1);
      date.setDate(date.getDate() - 1);

      let yyyy = date.getFullYear();
      let mm = String(date.getMonth() + 1).padStart(2, "0");
      let dd = String(date.getDate()).padStart(2, "0");

      return `${yyyy}-${mm}-${dd}`;
    },
    getLabelLastDayOfMonth(year, month) {
      let date = new Date(year, month, 1);
      date.setDate(date.getDate() - 1);

      let yyyy = date.getFullYear();
      let mm = String(date.getMonth() + 1).padStart(2, "0");
      let dd = String(date.getDate()).padStart(2, "0");

      return `${dd}/${mm}`;
    },
    updateGanttChartSeries() {
      /*
        Jadi kalo dia udah lewat deadline tspu belum kelar dimerahin
        Kalo dia belum lewat deadline tapi udah 7 hari mendekati deadline, jadih orange
        Kalo belum lewat deadline tapi masi lebih dari 7 hari deadline nya, jadih ijo
      */
      // console.log("updateGanttChartSeries");
      // console.log(this.ms_upload_task);

      var tmp_series = [
        {
          name: "Planned",
          data: [],
        },
        {
          name: "Actual",
          data: [],
        },
        // {
        //   name: "Actual",
        //   data: [{
        //     fillColor: "#2bff00",
        //     x: "Mobilization (construction comencement) & Overall Construction",
        //     y: null
        //   },
        //   {
        //     fillColor: "#fcad03",
        //     x: "Engineering",
        //     y: [1707984000000, 1714449033000]
        //   },
        //   {
        //     fillColor: "#fcad03",
        //     x: "Procurement",
        //     y: [1710403200000, 1720151433000]
        //   },
        //   {
        //     fillColor: "#fcad03",
        //     x: "Construction",
        //     y: [1721116800000, 1722311433000]
        //   },
        //   {
        //     fillColor: "#05bdfa",
        //     x: "Commissioning",
        //     y: null     //[1729872000000, 1732294800000]
        //   }
        //   ],
        // },
      ];

      // console.log(tmp_series);

      let keys = Object.keys(this.ms_upload_task);
      keys.forEach((key) => {
        let item = this.ms_upload_task[key];
        // console.log(item);

        let planned_color =
          item.mspt_percent_completion == 100
            ? "#27bd09" //"#003cff"
            : this.getGantChartColor(item.mspt_planned_end_date);
        let actual_color = "#003cff";
        // console.log(planned_color);

        // console.log(item.observation_category);
        // obj_obs_items.chartOptions.series[0].data.push({
        //   name: item.observation_category,
        //   y: item.items_open,
        //   // color: "#3F92D3"
        // });

        tmp_series[0].data.push({
          fillColor: planned_color,
          x: item.mspt_task_name,
          y: [
            item.mspt_planned_start_date
              ? new Date(item.mspt_planned_start_date).getTime()
              : null,
            item.mspt_planned_end_date
              ? new Date(item.mspt_planned_end_date).getTime()
              : null,
          ],
        });

        tmp_series[1].data.push({
          fillColor: actual_color,
          x: item.mspt_task_name,
          y: [
            item.mspt_actual_start_date
              ? new Date(item.mspt_actual_start_date).getTime()
              : null,
            item.mspt_actual_end_date
              ? new Date(item.mspt_actual_end_date).getTime()
              : null,
          ],
        });
      });
      // console.log(tmp_series);

      /*
      const temp_series = [
        {
          name: "Planned",
          data: this.ms_upload_task.map((item) => ({
            x: item.mspt_task_name,
            y: [
              item.mspt_planned_start_date
                ? new Date(item.mspt_planned_start_date).getTime()
                : null,
              item.mspt_planned_end_date
                ? new Date(item.mspt_planned_end_date).getTime()
                : null,
            ],
            fillColor: "#FF4560",
          })),
        },
        {
          name: "Actual",
          data: this.ms_upload_task.map((item) => ({
            x: item.mspt_task_name,
            y: [
              item.mspt_actual_start_date
                ? new Date(item.mspt_actual_start_date).getTime()
                : null,
              item.mspt_actual_end_date
                ? new Date(item.mspt_actual_end_date).getTime()
                : null,
            ],
            fillColor: "#00E396",
          })),
        },
      ];

      console.log("temp_series");
      console.log(temp_series);
      */

      this.series = [];
      this.series = tmp_series;

      // this.chartOptions.colors = [];
      // this.chartOptions.colors = ['#2cd158', '#e4f21d', '#990342', '#a813e8'];
    },
    getGantChartColor(mspt_planned_end_date) {
      // mspt_planned_end_date = "2024-06-15T16:00:00.000000Z";
      // mspt_planned_end_date = "2024-08-17T16:00:00.000000Z";
      // mspt_planned_end_date = "2024-07-28T16:00:00.000000Z";
      // mspt_planned_end_date = "2024-07-29T16:00:00.000000Z";
      // mspt_planned_end_date = "2024-07-30T16:00:00.000000Z";
      // mspt_planned_end_date = "2024-07-31T16:00:00.000000Z";
      // mspt_planned_end_date = "2024-07-27T16:00:00.000000Z";
      // mspt_planned_end_date = "2024-07-26T16:00:00.000000Z";

      let target_end_date = new Date(mspt_planned_end_date).getTime();
      // console.log(mspt_planned_end_date);
      // console.log(new Date(mspt_planned_end_date));
      // console.log(target_end_date);
      // console.log("============================");

      let date_7d_before = new Date(mspt_planned_end_date);
      date_7d_before.setDate(date_7d_before.getDate() - 7);
      // console.log("date_7d_before");
      // // let date_7d_before = new Date();
      // console.log(date_7d_before);
      // console.log(date_7d_before.getTime());
      // console.log("----------------------------");

      let date_today = new Date();
      // console.log("today");
      // console.log(date_today);
      // console.log(date_today.getTime());
      // console.log("----------------------------");
      // todayDate.setDate(todayDate.getDate() + 1);

      var chart_color = "#ff0004"; //"red";
      if (date_today < date_7d_before.getTime()) {
        chart_color = "#2bff00"; //"green";
      } else {
        if (target_end_date > date_7d_before.getTime()) {
          if (target_end_date > date_today) {
            chart_color = "#ffb700"; //"orange";
          }
        }
      }

      chart_color = '#807c7c';
      // console.log(chart_color);
      return chart_color;
    },
    addMonth(date, intDiff) {
      const newDate = new Date(date);
      const month = newDate.getMonth();
      const year = newDate.getFullYear();

      newDate.setMonth(month + intDiff);

      if (newDate.getMonth() !== (month + intDiff) % 12) {
        newDate.setDate(0); // Set to the last day of the previous month
      }

      return newDate;
    },
    formatMonthYear(date) {
      const MYmonth = date.toLocaleString("default", { month: "short" });
      const MYyear = date.getFullYear();
      return `${MYmonth}-${MYyear}`;
    },
    getSafetyInspectionList() {
      var oInspection = this.obj_inspection;
      var oChartOption = oInspection.chartOptions;
      var oSeries = oInspection.series;

      const today = new Date();
      oInspection.siteTaskYear = today.getFullYear();
      oInspection.intMonthInc = 0;
      const Diff = 5;
      let firstDay = new Date(today.setDate(1));
      for (let key in oChartOption.xaxis.categories) {
        let newDay = this.addMonth(firstDay, oInspection.intMonthInc - Diff);
        oChartOption.xaxis.categories[oInspection.intMonthInc] = this.formatMonthYear(newDay);
        oInspection.intMonthInc = oInspection.intMonthInc + 1;
      }

      // console.log("getSafetyInspectionList");
      // console.log(this.siteListingData);
      // console.log(this.siteListingData[0].site_id);
      this.model.site_id = this.siteListingData[0].site_id;
      axios
        .get(
          apiDomain +
          "health_safety/get_safety_observation_by_site_id/" +
          this.model.site_id,
          { headers: getHeader() }
        )
        .then((response) => {
          // console.log('getSafetyInspectionList()');
          // console.log(response.data);
          if (response.status === 200) {
            oInspection.si_site_all = response.data.data;
            for (let i = 0; i < oInspection.si_site_all.length; i++) {
              let create_date = new Date(
                oInspection.si_site_all[i].safety_observation_create_date
              );
              let matchIndex = -1;
              matchIndex = oChartOption.xaxis.categories.indexOf(
                this.formatMonthYear(create_date)
              );
              if (matchIndex != -1) {
                oInspection.so_site_all =
                  oInspection.si_site_all[i].safety_observation_items;
                for (let k = 0; k < oInspection.so_site_all.length; k++) {
                  //alert(this.series[0].name + ':' + this.series[0].data[matchIndex] + ' - ' + this.series[1].name + ':' + this.series[1].data[matchIndex]);
                  if (
                    oInspection.so_site_all[k].safety_observation_type_id > 3
                  ) {
                    oSeries[0].data[matchIndex] =
                      oSeries[0].data[matchIndex] + 1;
                    if (oInspection.items_cek.length == 0) {
                      if (
                        oInspection.so_site_all[k]
                          .safety_observation_status_id == 3
                      ) {
                        oInspection.items_cek.push({
                          observation_category:
                            oInspection.so_site_all[k]
                              .safety_observation_category
                              .safety_observation_category,
                          items_open: 0,
                          items_close: 1,
                        });
                      } else
                        oInspection.items_cek.push({
                          observation_category:
                            oInspection.so_site_all[k]
                              .safety_observation_category
                              .safety_observation_category,
                          items_open: 1,
                          items_close: 0,
                        });
                    } else {
                      let matchCategory = -1;
                      matchCategory = oInspection.items_cek.findIndex(
                        (item) =>
                          item.observation_category ===
                          oInspection.so_site_all[k].safety_observation_category
                            .safety_observation_category
                      );
                      if (matchCategory == -1) {
                        if (
                          oInspection.so_site_all[k]
                            .safety_observation_status_id == 3
                        ) {
                          oInspection.items_cek.push({
                            observation_category:
                              oInspection.so_site_all[k]
                                .safety_observation_category
                                .safety_observation_category,
                            items_open: 0,
                            items_close: 1,
                          });
                        } else
                          oInspection.items_cek.push({
                            observation_category:
                              oInspection.so_site_all[k]
                                .safety_observation_category
                                .safety_observation_category,
                            items_open: 1,
                            items_close: 0,
                          });
                      } else {
                        if (
                          oInspection.so_site_all[k]
                            .safety_observation_status_id == 3
                        ) {
                          oInspection.items_cek[matchCategory].items_close =
                            oInspection.items_cek[matchCategory].items_close +
                            1;
                        } else {
                          oInspection.items_cek[matchCategory].items_open =
                            oInspection.items_cek[matchCategory].items_open + 1;
                        }
                      }
                    }
                  } else
                    oSeries[1].data[matchIndex] =
                      oSeries[1].data[matchIndex] + 1;
                }
              }
            }

            let arrObsItems = oInspection.items_cek;
            this.updateChartObservationItems(arrObsItems);
            oInspection.chartKey += 1;
          }
        })
        .catch((error) => {
          this.errorFunction(error, "Safety Inspection");
        });
    },
    //_ method is trigger in getSafetyInspectionList(), it populate and draw the pie chart
    updateChartObservationItems(obsItems) {
      // console.log('updateChartObservationItems');

      var obj_obs_items = this.obj_observation_items;

      let chart_template = {
        credits: {
          enabled: false,
        },
        series: [
          {
            name: "Total",
            colorByPoint: true,
            dataLabels: {
              enabled: true,
              style: {
                textOutline: 'none',
                fontSize: '14px',
                fontFamily: "Open Sans, sans-serif",
                fontWeight: "bold",
                color: '#ffffff'
              }
            },
            data: [],
          },
        ],
        chart: {
          type: "pie",
          backgroundColor: "#23454A",
        },
        title: {
          text: "",
          style: {
            color: "white",
            fontWeight: "bold",
          },
        },
      };
      obj_obs_items.chartOptions = chart_template;

      obj_obs_items.chartOptions.series[0].data = [];

      let keys = Object.keys(this.obj_inspection.items_cek);
      keys.forEach((key) => {
        let item = this.obj_inspection.items_cek[key];
        // console.log(item.observation_category);
        obj_obs_items.chartOptions.series[0].data.push({
          name: item.observation_category,
          y: item.items_open,
          // color: "#3F92D3"
        });
      });

      // console.log(this.obj_observation_items);
    },
    //_ method for image slides
    nextSlide: function () {
      this.current++;
      if (this.current >= this.slides.length) this.current = 0;
      this.resetPlay();
    },
    prevSlide: function () {
      this.current--;
      if (this.current < 0) this.current = this.slides.length - 1;
      this.resetPlay();
    },
    selectSlide: function (i) {
      this.current = i;
      this.resetPlay();
    },
    resetPlay: function () {
      clearInterval(this.timer);
      this.play();
    },
    play: function () {
      let app = this;
      this.timer = setInterval(function () {
        app.nextSlide();
      }, 5000);
    },
  },
  createData() {
    axios
      .put(apiDomain + "project/project/" + this.model.project_id, this.model, {
        headers: getHeader(),
      })
      .then((response) => {
        if (response.status === 200) {
          this.$notify({
            message:
              "<b>Update Project : " +
              this.model.project_name +
              "</b> - Successfully Updated.",
            timeout: 10000,
            icon: "ni ni-bell-55",
            type: "default",
          });
          //   this.$router.back()
          this.getDashboardData();
        }
      })
      .catch((error) => {
        console.log(error);
        this.errorFunction(error, "Project Information");
      });
  },
}
</script>

<style scoped>
/* @import '~carbon/charts-vue/styles.css'; */
/*
.table thead th {
    padding-top: 0.5rem;
    padding-bottom: 1.5rem;
    font-size: 0.9rem; 
    text-transform: uppercase;
    letter-spacing: 1px;
    border-bottom: 0px solid #e9ecef;
    border-top: 0px solid #e9ecef;
    color: white;
}
.table td {
  color: white;
}*/

.apexcharts-legend-marker {
  width: 12px !important;
  height: 12px !important;
  left: 0px !important;
  top: 0px !important;
  border-radius: 2px !important;
}

/* .apexcharts-series {
  bottom:  30px !important;
 } */
.apexcharts-rangebar-area {
  margin-bottom: 30px !important;
  padding-bottom: 30px !important;
  top: -80px;
}

/* .apexcharts-rangebar-series {
  padding: 30px !important;

}
.apexcharts-plot-series {
  padding: 30px !important;

} */
select {
  background: url("data:image/svg+xml,<svg width='10' height='7' viewBox='0 0 10 7' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M5.02552 6.35057L0.320068 0.46875H9.73098L5.02552 6.35057Z' fill='white'/></svg>") no-repeat;
  background-position: calc(100% - 0.75rem) center !important;
  -moz-appearance: none !important;
  -webkit-appearance: none !important;
  appearance: none !important;
  padding-right: 2rem !important;
}

.bg-card-dashboard {
  /* background-color: #23454a; */
  background-image: linear-gradient(to bottom right, #23454a, #406B71);
}

.bg-card-dashboard-solid {
  background-color: #23454a;
  border-radius: 30px;
}

.bg-default-dashboard {
  background-color: #082a2f;
  /* background-size: cover; */
  /* position:fixed; */
  padding: 0;
  margin: 0;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.form-control {
  background-color: #2c545a;
  border-color: #2c545a;
  color: white;

  &:focus {
    background-color: #2c545a;
    border-color: #2c545a;
    color: white;
  }

  &::placeholder {
    color: white;
  }
}

.form-control {
  &:focus {
    &::placeholder {
      color: white;
    }
  }
}

.input-group-text {
  background-color: #2c545a;
  color: white;

  &:focus {
    background-color: #2c545a;
    border-color: #1d4147;
    color: white;
  }
}

.focused {
  .input-group-text {
    background-color: #2c545a;
  }
}

.navbar-search-dark .input-group {
  background-color: #23454a;
  color: black;
  border-color: rgba(255, 255, 255, 0.6);
}

.navbar-search .input-group {
  border-radius: 7px;
}

.el-input__inner,
.el-select-dropdown__item.is-disabled:hover {
  background-color: #23454a;
}

.line-custom {
  background-color: #356168;
  margin-top: 15px;
  margin-bottom: 15px;
}

/* .el-table th.el-table__cell {
  
  overflow: hidden;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: #23454A;
  color: white;
  padding: 20px;
  border-top: 0px solid #082A2F;
  border-bottom: 0px solid #082A2F;
}
 */

/* .el-table td.el-table__cell, .el-table th.el-table__cell.is-leaf {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  border-bottom: 20px solid #082A2F;
  border-top: 0px solid #082A2F;
} */

/* div.el-table .el-table__row {
  background: #3a656b;;
  border-bottom: 0 solid #082A2F;

}
div.el-table .el-table__row:hover {
  background: #0d2f33;
}

.el-table--border::after, .el-table--group::after, .el-table::before {
  content: '';
  position: absolute;
  background-color: #ebeef500;
  z-index: 1;
} */


/* <!--style for flip card--> */
ul {
  padding-left: 0;
  display: flex;
  flex-flow: row wrap;
}

li {
  list-style-type: none;
  padding: 10px 10px;
  transition: all 0.3s ease;
}


.card2 {
  display: block;
  /* width: 150px; */
  height: 500px;
  padding: 80px 50px;
  background-color: #23454a;
  border-radius: 7px;
  margin: 5px;
  text-align: center;
  line-height: 27px;
  cursor: pointer;
  position: relative;
  color: #fff;
  font-weight: 600;
  font-size: 20px;
  -webkit-box-shadow: 9px 10px 22px -8px rgba(209, 193, 209, .5);
  -moz-box-shadow: 9px 10px 22px -8px rgba(209, 193, 209, .5);
  box-shadow: 9px 10px 22px -8px rgba(209, 193, 209, .5);
  will-change: transform;
}


.flip-enter-active {
  transition: all 0.8s ease;
}

.flip-leave-active {
  display: none;
}

.flip-enter,
.flip-leave {
  transform: rotateY(90deg);
  opacity: 0;

}

/* Form */
.flashcard-form {
  position: relative;
}


label {
  font-weight: 400;
  color: #333;
  margin-right: 10px;
}

input {
  border-radius: 5px;
  border: 2px solid #eaeaea;
  padding: 10px;
  outline: none;
}

.front {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #673AB7;
  color: white;
  width: 100%;
  height: 100%;
}

.back {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #FFC107;
  color: white;
  width: 100%;
  height: 100%;
}
</style>
