<template>
  <div class="content">
    <base-header class="pb-6">
      <b-row align-v="center" class="py-4">
        <b-col cols="12" lg="12">
          <h6 class="h2 text-white d-inline-block mb-0">Project Document Template Listing</h6>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <route-bread-crumb></route-bread-crumb>
          </nav>
        </b-col>
        <b-col lg="12" cols="5" class="text-right">
          <router-link to="/templates/projectDocumentTemplateNew">
            <base-button size="sm" type="neutral"
            v-if="button_access.new_button == 'YES'">New Template</base-button>
          </router-link>
        </b-col>
      </b-row>
    </base-header>
    <b-container fluid class="mt--6">
      <div>
        <card class="no-border-card" body-classes="px-0 pb-1" footer-classes="pb-2">
          <template slot="header">
            <h3 class="mb-0">Project Document Template Listing</h3>
          </template>
          <div>
            <b-col cols="12" class="d-flex justify-content-center justify-content-sm-between flex-wrap">
              <el-select
                class="select-primary pagination-select"
                v-model="pagination.perPage"
                placeholder="Per page">
                <el-option
                  class="select-primary"
                  v-for="item in pagination.perPageOptions"
                  :key="item"
                  :label="item"
                  :value="item">
                </el-option>
              </el-select>
              <div>
              </div>
            </b-col>
            <b-col><br></b-col>
            <el-table :data="queriedData"
                      row-key="document_template_id"
                      header-row-class-name="thead-light"
                      @sort-change="sortChange">
              <el-table-column
                v-for="column in tableColumns"
                :key="column.label"
                v-bind="column">
              </el-table-column>
              <el-table-column min-width="110px" align="right" label="Actions">
                <div slot-scope="{$index, row}" class="d-flex">
                  <base-button v-if="button_access.detail_listing_button == 'YES'"
                    @click.native="handleDetails($index, row)" size="sm" type="primary">
                    Details Listing
                  </base-button>
                </div>
              </el-table-column>
            </el-table>
          </div>
          <div
            slot="footer"
            class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap">
            <div class="">
              <p class="card-category">
                Showing {{ from + 1 }} to {{ to }} of {{ total }} entries
                <span v-if="selectedRows.length">
                  &nbsp; &nbsp; {{selectedRows.length}} rows selected
                </span>
              </p>
            </div>
            <base-pagination
              class="pagination-no-border"
              :current="pagination.currentPage"
              :per-page="pagination.perPage"
              :total="total"
              @change="paginationChanged($event)">
            </base-pagination>
             <div>
              <h5 class="text-danger"> Note :
                  <i>
                  Please Create at least ONE Document Template & ONE Document Template Details before you can proceed POPULATE / IMPORT to prevent any Error. Go to NEW Template Then "Detail Listing" >> "New Template Details".
                  </i>
              </h5>
            </div>
          </div>
        </card>
      </div>
    </b-container>
    </div>
</template>
<script>
import { Table, TableColumn, Select, Option } from 'element-ui';
import RouteBreadCrumb from '@/components/Breadcrumb/RouteBreadcrumb'
import { BasePagination } from '@/components';
import clientPaginationMixin from '@/assets/js/standardPaginationMix.js'
import swal from 'sweetalert2';
import axios from 'axios'
import {LoginURL, clientId, clientSecret, apiDomain, getHeader} from '@/assets/js/config.js'
import ErrorFunctionMixin from '@/assets/js/errorFunctionMix.js'

export default {
  mixins: [clientPaginationMixin, ErrorFunctionMixin],
  components: {
    BasePagination,
    RouteBreadCrumb,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn
  },
  created()
  {
    var project_document_template_access = JSON.parse(window.localStorage.getItem('project_document_template_access'))
    var project_document_template_detail_access = JSON.parse(window.localStorage.getItem('project_document_template_detail_access'))
    this.button_access.new_button = project_document_template_access.access_create
    this.button_access.detail_listing_button = project_document_template_detail_access.access_listing
    this.getListingData()
  },
  data() {
    return {
      propsToSearch: ['document_template_code', 'document_template_name', ],
      tableColumns: [
        {
          prop: 'document_template_name',
          label: 'Name',
          minWidth: 220,
          sortable: true
        },
        {
          prop: 'document_details_no',
          label: 'Total No(s)',
          minWidth: 130,
          sortable: true
        },
        {
          prop: 'created_at',
          label: 'Created Date',
          minWidth: 130,
          sortable: true
        },
      ],
      tableData: [],
      selectedRows: [],
      button_access:{
        new_button: null,
        detail_listing_button: null,
      },
    };
  },
  methods: {
    getListingData()
    {
      axios.get(apiDomain + 'template/project_document_template', { headers: getHeader() })
      .then ( response => {
        if (response.status === 200)
        {
            this.tableData = response.data.data

        }
      })
      .catch ( error => {
          console.log(error.response.status)
          this.errorFunction(error, 'Project Document Template Listing')
      } );
    },
    paginationChanged(page) {
      this.pagination.currentPage = page
    },
    handleDetails(index, row)
    {
     this.$router.push({path: '/templates/projectDocumentTemplateDetailListing/' + row.project_document_template_id })
    },
    handleEdit(index, row) {
      swal({
        title: `You want to edit ${row.name}`,
        buttonsStyling: false,
        confirmButtonClass: 'btn btn-info btn-fill'
      });
    },
    handleDelete(index, row) {
      swal({
        title: 'Are you sure?',
        text: `You won't be able to revert this!`,
        type: 'warning',
        showCancelButton: true,
        confirmButtonClass: 'btn btn-success btn-fill',
        cancelButtonClass: 'btn btn-danger btn-fill',
        confirmButtonText: 'Yes, delete it!',
        buttonsStyling: false
      }).then(result => {
        if (result.value) {
          this.deleteRow(row);
          swal({
            title: 'Deleted!',
            text: `You deleted ${row.name}`,
            type: 'success',
            confirmButtonClass: 'btn btn-success btn-fill',
            buttonsStyling: false
          });
        }
      });
    },
    deleteRow(row) {
      let indexToDelete = this.tableData.findIndex(
        tableRow => tableRow.id === row.id
      );
      if (indexToDelete >= 0) {
        this.tableData.splice(indexToDelete, 1);
      }
    },
    selectionChange(selectedRows) {
      this.selectedRows = selectedRows
    }
  }
};
</script>
<style>
  .no-border-card .card-footer{
    border-top: 0;
  }
</style>
