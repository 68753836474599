<template>
    <div class="bg-default-dashboard" >
        <div class=" py-lg-8 pt-lg-5">
            <b-container fluid style="width: 90%; position: relative;">
                <!-- <div class="header-body "  > -->
                <b-row class="mb-3">
                    <b-col md="8">
                        <span class="text-white display-2 mr-3 " >{{this.projects_model.project_name}}</span></b-col>
                </b-row>

                <b-row class="">
                    <b-col md="6">
                        <b-row class="mb-2">
                            <b-col md="3">
                                <div class="text-white lead mt-1">Total Capacity</div>
                            </b-col>
                            <b-col md="1" style="width: 3px; margin: 0px; top: 10px;" class="text-right text-white">
                                :
                            </b-col>
                            <b-col>
                                <div class="text-white lead  mt-2">
                                    <strong>{{ this.model.site_total_capacity }}</strong>
                                </div>
                            </b-col>
                        </b-row>
                        <b-row class="mb-2">
                            <b-col md="3">
                                <div class="text-white lead mt-1">Project Status</div>
                            </b-col>
                            <b-col md="1" style="width: 3px; margin: 0px; top: 10px;" class="text-right text-white">
                                :
                            </b-col>
                            <b-col>
                                <div class="text-white lead  mt-2">
                                    <strong>In Progress</strong>
                                </div>
                            </b-col>
                        </b-row>
                        <b-row class="mb-2">
                            <b-col md="3">
                                <div class="text-white lead mt-1">Site Name</div>
                            </b-col>
                            <b-col md="1" style="width: 3px; margin: 0px; top: 10px;" class="text-right text-white">
                                :
                            </b-col>
                            <b-col>
                                <div class="text-white lead  mt-2">
                                    <strong>{{this.model.site_name}}</strong>
                                </div>
                            </b-col>
                        </b-row>
                    </b-col>
                    <b-col md="6">
                        <b-row class="mb-2">
                            <b-col md="3">
                                <div class="text-white lead mt-1">Developer</div>
                            </b-col>
                            <b-col md="1" style="width: 3px; margin: 0px; top: 10px;" class="text-right text-white">
                                :
                            </b-col>
                            <b-col>
                                <div class="text-white lead  mt-2">
                                    <strong>{{this.projects_model.project_developer_name}}</strong>
                                </div>
                            </b-col>
                        </b-row>
                        <b-row class="mb-2">
                            <b-col md="3">
                                <div class="text-white lead mt-1">Watt Class</div>
                            </b-col>
                            <b-col md="1" style="width: 3px; margin: 0px; top: 10px;" class="text-right text-white">
                                :
                            </b-col>
                            <b-col>
                                <div class="text-white lead  mt-2">
                                    <strong>{{this.projects_model.project_watt_class}} Wp</strong>
                                </div>
                            </b-col>
                        </b-row>

                    </b-col>

                </b-row>
                <b-row class="mt-4">
                    <b-col md="3" class="mt-1">
                        <base-input >
                            <select v-model="selectedFilter" class="form-control" @change="generateData(selectedFilter)">
                                <option v-for="item in filterOption" :key="item.value" :value="item.value">Rows per page : {{ item.label }}</option>
                            </select>
                        </base-input>
                    </b-col>
                    <b-col md="6" xs="12" sm="12" class="mt-1">
                        <!-- <b-form class="navbar-search navbar-search-dark"> -->
                        <b-form-group class="mb-0">
                            <b-input-group class="input-group-alternative input-group-merge">
                                <div class="input-group-prepend">
                                    <span class="input-group-text"><i class="fas fa-search"></i></span>
                                </div>
                                <b-form-input name="Task Name" placeholder="Search task name" type="text" v-model="filter.mpuh_task_name"> </b-form-input>
                            </b-input-group>
                        </b-form-group>

                        <!-- </b-form> -->
                    </b-col>
                    <b-col md="3" sm="6" class="mt-1">
                        <b-link class="btn btn-dark " style="width: 45%;" type="primary" @click="searchMsProjectHistoricalUpdate()">SEARCH</b-link>
                        <b-link class="btn btn-dark" style="width: 50%;" type="primary" @click="clearMsProjectHistoricalUpdate()">CLEAR SEARCH</b-link>
                    </b-col>
                </b-row>
                <!--
                          <b-row class="mt-4">
                            <b-col md="12">
                                <div style="background-color: transparent;">
                                  <el-table
                                    :data="tableData"
                                    style="width: 100%">
                                    <el-table-column
                                      label="Task Name"
                                      >
                                      <template slot-scope="scope">
                                        <span class="text-white" style="margin-left: 20px">{{ scope.row.mpuh_task_name }}</span>
                                      </template>
                                    </el-table-column>
                                    <el-table-column
                                      label="Task ID">
                                      <template slot-scope="scope">
                                        <span class="text-white"  style="margin-left: 20px" >{{ scope.row.mpuh_task_id }}</span>
                                      </template>
                                    </el-table-column>
                                    <el-table-column
                                      label="Batch ID"
                                      >
                                      <template slot-scope="scope">
                                        <span class="text-white" style="margin-left: 20px">Test 02</span>
                                      </template>
                                    </el-table-column>
                                    <el-table-column
                                      label="Change Date">
                                      <template slot-scope="scope">
                                        <span class="text-white" style="margin-left: 20px">Test 03</span>
                                      </template>
                                    </el-table-column>
                                    <el-table-column
                                      label="Remark"
                                      width="400"
                                      >
                                        <template slot-scope="scope">

                                        </template>

                                    </el-table-column>
                                  </el-table>

                                </div>

                            </b-col>
                          </b-row>
                        -->
                <b-row class="mt-1">
                    <b-col>
                        <table  style="width: 100%;">
                            <thead style="background-color: #23454A; color: white; font-size: small; border-bottom: 20px solid #082A2F;">
                            <th class="th-table-new ">Task Name</th>
                            <th class="th-table-new ">Task ID</th>
                            <th class="th-table-new ">Batch ID</th>
                            <th class="th-table-new ">Change Date</th>
                            <th class="th-table-new ">Change Detail</th>
                            </thead>
                            <tbody>
                            <tr v-for="item in tableData" :key="item.mpuh_task_id" style="background-color: #3a656b; color: white; font-size: smaller; border-bottom:  20px solid #082A2F;">
                                <td class="tr-td-table-new">{{ item.mpuh_task_name }}</td>
                                <td class="tr-td-table-new">{{ item.mpuh_unique_task_id }}</td>
                                <td class="tr-td-table-new">{{ item.mpuh_batch_id }}</td>
                                <td class="tr-td-table-new">{{ item.mpuh_changed_date }}</td>
                                <td class="tr-td-table-new">
                                    <b-media no-body class="align-items-center">
                                        <b-media-body>
                                            <div v-if="item.mpuh_action=='Task Changes'">
                                                <span>{{item.mpuh_action}} :</span><br />
                                                <span v-if="item.mpuh_ori_task_name != item.mpuh_task_name" class="font-weight-600  mb-0 text-sm"><span class="text-danger">Task Name</span> : {{(item.mpuh_ori_task_name != null) ? item.mpuh_ori_task_name : "N/A"}} <i class="ni ni-bold-right"></i> {{(item.mpuh_task_name != null) ? item.mpuh_task_name : "N/A"}}<br /></span>
                                                <span v-if="item.mpuh_ori_planned_start_date != item.mpuh_new_planned_start_date" class="font-weight-600  mb-0 text-sm"><span class="text-danger">Planned Start Date</span> : {{(item.mpuh_ori_planned_start_date != null) ? item.mpuh_ori_planned_start_date : "N/A"}} <i class="ni ni-bold-right"></i> {{(item.mpuh_new_planned_start_date != null) ? item.mpuh_new_planned_start_date : "N/A"}}<br /></span>
                                                <span v-if="item.mpuh_ori_planned_end_date != item.mpuh_new_planned_end_date" class="font-weight-600  mb-0 text-sm"><span class="text-danger">Planned End Date</span> : {{(item.mpuh_ori_planned_end_date != null) ? item.mpuh_ori_planned_end_date : "N/A"}}  <i class="ni ni-bold-right"></i> {{ (item.mpuh_new_planned_end_date != null) ? item.mpuh_new_planned_end_date : "N/A"}}<br /></span>
                                                <span v-if="item.mpuh_ori_actual_start_date != item.mpuh_new_actual_start_date" class="font-weight-600  mb-0 text-sm"><span class="text-danger">Actual Start Date</span> : {{(item.mpuh_ori_actual_start_date != null) ? item.mpuh_ori_actual_start_date : "N/A" }}  <i class="ni ni-bold-right"></i> {{(item.mpuh_new_actual_start_date != null) ? item.mpuh_new_actual_start_date : "N/A"}}<br /></span>
                                                <span v-if="item.mpuh_ori_actual_end_date != item.mpuh_new_actual_end_date" class="font-weight-600  mb-0 text-sm"><span class="text-danger">Actual End Date</span> : {{(item.mpuh_ori_actual_end_date != null) ?  item.mpuh_ori_actual_end_date : "N/A"}}  <i class="ni ni-bold-right"></i> {{(item.mpuh_new_actual_end_date != null) ?  item.mpuh_new_actual_end_date : "N/A"}}<br /></span>
                                                <span v-if="item.mpuh_ori_durations != item.mpuh_new_durations" class="font-weight-600  mb-0 text-sm"><span class="text-danger">Durations</span> : {{(item.mpuh_ori_durations != null) ?  item.mpuh_ori_durations : "N/A" }}  <i class="ni ni-bold-right"></i> {{(item.mpuh_new_durations != null) ?  item.mpuh_new_durations : "N/A"}}<br /></span>
                                                <span v-if="item.mpuh_ori_percent_completion != item.mpuh_new_percent_completion" class="font-weight-600  mb-0 text-sm"><span class="text-danger">Percent Completion</span> : {{(item.mpuh_ori_percent_completion != null) ? item.mpuh_ori_percent_completion : "N/A" }}  <i class="ni ni-bold-right"></i> {{(item.mpuh_new_percent_completion != null) ? item.mpuh_new_percent_completion : "N/A"}}<br /></span>
                                                <span v-if="item.mpuh_ori_critical != item.mpuh_new_critical" class="font-weight-600  mb-0 text-sm"><span class="text-danger">Critical</span> : {{(item.mpuh_ori_critical != null) ? item.mpuh_ori_critical : "N/A" }}  <i class="ni ni-bold-right"></i> {{(item.mpuh_new_critical != null) ? item.mpuh_new_critical : "N/A" }}<br /></span>
                                                <span v-if="item.mpuh_ori_outline_level != item.mpuh_new_outline_level" class="font-weight-600  mb-0 text-sm"><span class="text-danger">Outline Level</span> : {{(item.mpuh_ori_outline_level != null) ? item.mpuh_ori_outline_level : "N/A"}} <i class="ni ni-bold-right"></i> {{(item.mpuh_new_outline_level != null) ? item.mpuh_new_outline_level : "N/A"}}<br /></span>
                                            </div>
                                            <div v-else>
                                                <span v-if="item.mpuh_action=='New Tasks Added'"  style="margin-left: 20px; color: #46D275;">{{ item.mpuh_action }}</span>
                                                <span v-if="item.mpuh_action=='Task Removed'" style="margin-left: 20px; color: #FF9A3D;">{{ item.mpuh_action }}</span>
                                            </div>
                                        </b-media-body>

                                    </b-media>
                                </td>
                            </tr>


                            </tbody>
                        </table>
                        <b-link>
                            <img src="img/theme/general/plus-circle.png" width="35px" alt="">
                        </b-link>
                    </b-col>
                </b-row>
            </b-container>

        </div>


    </div>
</template>
<script>
    import { Select, Option,  Table, TableColumn, } from 'element-ui'
    import {
        LoginURL,
        clientId,
        clientSecret,
        apiDomain,
        getHeader,
        getcurrentDate,
    } from "@/assets/js/config.js";
    import axios from "axios";
    import ErrorFunctionMixin from "@/assets/js/errorFunctionMix.js";
    import jsonFormData from 'json-form-data';
    import VueApexCharts from "vue-apexcharts";
    import moment from "moment";
    import ProjectSiteTemplateViewForm from '@/views/SemCorp/Template/Project/ProjectSiteTemplateViewForm.vue'

    export default {
        name :"HistoryProject",
        components: {
            [Select.name]: Select,
            [Option.name]: Option,
            [Table.name]: Table,
            [TableColumn.name]: TableColumn,

        },
        created() {
            this.currentDate = getcurrentDate();

            const LoginUser = JSON.parse(window.localStorage.getItem("loginUser"));
            this.myUserID = LoginUser;

            //this.getProjectSiteStatusListing();
            //this.getContractorListing();
            //this.getModuleBrandListing();
            //this.getMilestoneTemplateListing();
            this.getDashboardData();
            this.getDashboardAdditionalInfo();
            //this.getProjectSiteMilestoneData();
            //this.getContractorFromSite();
            //this.getSiteAttachmentListing();
            this.getMsProjectHistoricalUpdates();
        },
        data() {
            return {
                site_detail:{
                    total_capacity : "550.000 kWp",
                    project_status : "In Progress",
                    site_name : "Site 01",
                    developer : "Sembcorp Energy Indonesia",
                    watt_class : "550 kWp"
                },
                items: [
                    {
                        task_id:1,
                        task_name : "Sub Statiun",
                        batch_id : "10",
                        updated_at:"21-05-2024",
                        remark:{
                            title : "New Task Added",
                            detail:[]
                        }
                    },
                    {
                        task_id:1,
                        task_name : "Mounting",
                        batch_id : "10",
                        updated_at:"21-05-2024",
                        remark:{
                            title : "Task Removed",
                            detail:[]
                        }
                    },
                    {
                        task_id:1,
                        task_name : "Pilling",
                        batch_id : "10",
                        updated_at:"21-05-2024",
                        remark:{
                            title : "Task Changes",
                            detail:[
                                {
                                    key : "Planned Data",
                                    value:["01-06-2024","15-06-2024"]
                                },
                                {
                                    key : "Criticality",
                                    value:["Yes","No"]
                                }
                            ]
                        }
                    },
                ],
                model: {
                    site_id: null,
                    project_id: null,
                    group_id: null,
                    site_name: null,
                    status_id: 'x',
                    site_address: null,
                    site_postal_code: null,
                    contractor_id: 'x',
                    site_code: null,
                    site_latitude: null,
                    site_longitude: null,
                    roof_handover_date: null, // CHECK WITH ANDREW
                    site_cod_date: null,
                    site_target_turn_on_date: null,
                    site_actual_turn_on_date: null,
                    site_total_capacity: null, // CHECK WITH ANDREW
                    site_as_built_capacity: null,
                    site_drawing_module_brand_id: 'x',
                    site_drawing_module_quantity: 0,
                    site_drawing_module_capacity: 0,
                    site_drawing_total_capacity: 0,
                    site_drawing_ifa_date: null,
                    site_drawing_ifc_date: null,
                    site_module_brand_id: 'x',
                    site_module_quantity: 0,
                    site_module_capacity: 0,
                    site_module_allocation_total_capacity: 0,
                    site_ifc_ifa_module_remarks: null,
                    // site_name: null,
                    // status_code: null,
                    // site_leader: null,
                    // site_total_installation: 0,
                    // site_total_task: 0,
                    // site_completed_task: 0,
                    // site_total_document: 0,
                    // site_completed_document: 0,
                    // site_progress: 0,
                    // site_total_capacity: 0,
                    // site_completed_capacity: 0,
                    // group_engineer: {
                    // name: null,
                    // email: null,
                    // rows: [
                    //     { milestone: "Applicaton to Authorities", startDate: "", endDate: "", remarks: "" },
                    //     { milestone: "Mobilization to Site", startDate: "", endDate: "", remarks: "" },
                    //     { milestone: "Hoisting", startDate: "", endDate: "", remarks: "" },
                    //     // MAKE DYNAMIC BASED ON API CALL
                    // ],
                    milestone_template_id: 'x',
                },
                site_status: [],
                contractors: [],
                module_brands: [],
                milestone_template: [],
                site_attachment: {
                    file: null,
                },
                cif_file: null,
                project_task_file: null,
                tempData: null,
                projectTaskTempData: null,
                converted_temp_data: null,
                converted_temp_project_task_data: null,
                projectTaskSheets:[],
                selected_project_task_sheet:'x',
                selected_project_task_range:'',
                selected_sheet: 'x',
                selected_range: '',
                postResponse: null,
                site_attachments: [],
                additional_model: {
                    project_site_additional_info_id: null,
                    site_id: null,
                    ntp_applicable: 0,
                    ntp_issued: 0,
                    ntp_issue_date: null,
                    internal_project_handover_completed: 0,
                    internal_project_handover_date: null,
                    kick_off_meeting_completed: 0,
                    kick_off_meeting_date: null,
                    roof_handover_completed: 0,
                    roof_handover_date: null,
                    cops_tnc_checked: 0,
                    cops_tnc_issue: 0,
                    cops_tnc_remarks: null,
                    onm_handover_checked: 0,
                    onm_handover_issue: 0,
                    onm_handover_remarks: null,
                    turn_on_checking_approved: 0,
                    turn_on_checking_approval_date: null,
                    turn_on_checking_approver: null,
                    cif_technical_info: null,
                    cif_project_info: null,
                },
                site_milestones: [],
                ms_upload_task: [],
                projects_model: {
                    project_ppa_start_date: null,
                    project_ppa_approved_capacity: null,
                    project_name : 'x',
                    project_developer_id : 0,
                    project_developer_name : 'x',
                    project_watt_class:0,
                },
                propsToSearch:
                    [
                        'mpuh_id',
                        'mpuh_task_name',
                        'mpuh_changed_date',
                        'mpuh_change_by',
                    ],
                tableColumns: [],
                tableData: [],
                selectedRows: [],
                button_access:{
                    new_button: null,
                    show_button: null,
                },
                filter: {
                    from_date: null,
                    to_date: null,
                    mpuh_task_name: null
                },
                tempButtonStatus: false,
                uniqueTable: null,
                uniqueUser: null,
            };
        },
        filters: {
            formatDate(value) {
                if (value) {
                    const dateParts = value.split("-");
                    const formattedDate = `${dateParts[2]}/${dateParts[1]}/${dateParts[0]}`;
                    return formattedDate;
                }
                return "N/A"; // Return an empty string if the date is not available or invalid
            },
        },
        watch: {
            'additional_model.ntp_applicable': function (newVal) {
                if (newVal === "0") {
                    this.additional_model.ntp_issued = "0";
                    this.additional_model.ntp_issue_date = null;
                }
            },
            'additional_model.ntp_issued': function (newVal) {
                if (newVal === "0") {
                    this.additional_model.ntp_issue_date = null;
                } else if (newVal === "1") {
                    this.additional_model.ntp_issue_date = this.currentDate;
                }
            },
            'additional_model.internal_project_handover_completed': function (newVal) {
                if (newVal === "0") {
                    this.additional_model.internal_project_handover_date = null;
                } else if (newVal === "1") {
                    this.additional_model.internal_project_handover_date = this.currentDate;
                }
            },
            'additional_model.kick_off_meeting_completed': function (newVal) {
                if (newVal === "0") {
                    this.additional_model.kick_off_meeting_date = null;
                } else if (newVal === "1") {
                    this.additional_model.kick_off_meeting_date = this.currentDate;
                }
            },
            'additional_model.roof_handover_completed': function (newVal) {
                if (newVal === "0") {
                    this.additional_model.roof_handover_date = null;
                } else if (newVal === "1") {
                    this.additional_model.roof_handover_date = this.currentDate;
                }
            },
            'additional_model.cops_tnc_checked': function (newVal) {
                if (newVal === "0") {
                    this.additional_model.cops_tnc_issue = "0";
                    this.additional_model.cops_tnc_remarks = null;
                }
            },
            'additional_model.onm_handover_checked': function (newVal) {
                if (newVal === "0") {
                    this.additional_model.onm_handover_issue = "0";
                    this.additional_model.onm_handover_remarks = null;
                }
            },
        },
        computed:{

        },
        methods: {
            // onChange(event) {
            //     this.file = event.target.files ? event.target.files[0] : null;
            // },
            // loadSheetNames() {
            //     alert('001');
            //     if (typeof(this.$refs.excelRead._workbook) !== "undefined") {
            //     this.sheets = this.$refs.excelRead._workbook.Workbook.Sheets;
            // }
            // },
            // updateRangeToSelect () {
            //     console.log('updateRangeToSelect () {');
            //     if (typeof(this.$refs.excelRead._workbook) !== "undefined" && this.selected_sheet != 'x') {
            //         const original_range = this.$refs.excelRead._workbook.Sheets[this.selected_sheet]['!ref'];
            //         console.log('original_range', original_range);
            //         this.selected_range = 'A2:' + original_range.substring(3);
            //         this.selected_range = this.selected_range.replace(/:(.*?)(?=\d)/, ':AC');
            //         console.log('selected_range', this.selected_range);
            //     }
            // },
            // showUploadingData () {
            //     this.tempData = this.$refs.excelJson.$data.collection;
            // },
            // updateTempData() {
            //     // this.excelReadData = this.$refs.excelRead._workbook.Workbook.Sheets;
            //     // this.sheets = this.$refs.excelRead._workbook.Workbook.Sheets;
            //     this.tempData = this.$refs.excelJson.$data.collection;
            //     this.tempData = this.tempData.filter((item) => {
            //         const no = item["No."];
            //         return typeof no === "number" && !isNaN(no);
            //     });
            // },
            triggerFileInput() {
                // Trigger a click on the file input element
                this.$refs.file1.click();
            },
            redirectToProjectDashboard(){
                this.$router.push({path: '/projectDashboardV2/' + this.model.project_id})
            },
            scrollToSite() {
                const siteSection = document.getElementById("site-profile");
                siteSection.scrollIntoView({ behavior: "smooth" });
            },
            scrollToIfaIfcModule() {
                const ifaIfacModuleSection = document.getElementById("ifc-ifa-module");
                ifaIfacModuleSection.scrollIntoView({ behavior: "smooth" });
            },
            scrollToProjectPlanning() {
                const projectPlanningSection = document.getElementById("project-planning");
                projectPlanningSection.scrollIntoView({ behavior: "smooth" });
            },
            scrollToGanttChart() {
                const ganttChartSection = document.getElementById("gantt-chart");
                ganttChartSection.scrollIntoView({ behavior: "smooth" });
            },
            scrollToTurnOnPlanning() {
                const turnOnPlanningSection = document.getElementById("turn-on-checking");
                turnOnPlanningSection.scrollIntoView({ behavior: "smooth" });
            },
            scrollToUploadCif() {
                const uploadCifSection = document.getElementById("upload-cif");
                uploadCifSection.scrollIntoView({ behavior: "smooth" });
            },
            scrollToDisplayCif() {
                const displayCifSection = document.getElementById("display-cif");
                displayCifSection.scrollIntoView({ behavior: "smooth" });
            },
            getProjectSiteStatusListing() {
                // axios.get(apiDomain + 'lookup/site_status', { headers: getHeader() })
                axios.get(apiDomain + 'lookup/site_status.singapore_v2', { headers: getHeader() })
                    .then ( response => {
                        if (response.status === 200)
                        {
                            this.site_status = response.data.data;
                            this.updateAvailableProjectSiteStatus();
                        }
                    })
                    .catch ( error => {
                        if (typeof error.response !== "undefined") {
                            this.errorFunction(error, "Site Status Information")
                        }
                    } );
            },
            updateAvailableProjectSiteStatus() {
                // 20240109 andrew request to enable all site status selection
                // // set all to disabled initially, slowly allow selection based on validation
                // this.site_status = this.site_status.map(item => ({
                //     ...item,
                //     disabled: true
                // }));

                // const objIndex = this.site_status.findIndex((obj => obj.status_sequence == 1));
                // this.site_status[objIndex].disabled = false;

                // const conditions = [
                //     {
                //         condition: (this.projects_model.project_ppa_start_date != null
                //             && this.projects_model.project_ppa_approved_capacity != null
                //             && (this.model.contractor_id != null && this.model.contractor_id != "x")),
                //         statusSequence: 2
                //     },
                //     {
                //         condition: ((this.model.site_module_brand_id != null && this.model.site_module_brand_id != "x")
                //             && (this.model.site_module_quantity != null && this.model.site_module_quantity != 0)),
                //         statusSequence: 3
                //     },
                //     {
                //         condition: true,
                //         statusSequence: 4
                //     },
                //     {
                //         condition: (this.site_milestones.length > 0
                //             && this.site_milestones.every(obj => obj.planned_start_date !== null && obj.planned_end_date !== null)),
                //         statusSequence: 5
                //     },
                //     {
                //         condition: this.getSiteMilestoneActualDates("Installation"),
                //         statusSequence: 6
                //     },
                //     {
                //         condition: true,
                //         statusSequence: 7
                //     },
                //     {
                //         condition: true,
                //         statusSequence: 8
                //     },
                //     {
                //         condition: this.getSiteMilestoneActualDates("Turn On"),
                //         statusSequence: 9
                //     },
                //     {
                //         condition: true, // TO BE DONE, HOW TO CHECK IF ALL FIELDS ARE FILLED?
                //         statusSequence: 10
                //     },
                // ];

                // conditions.every(({ condition, statusSequence }) => {
                //     if (!condition) {
                //         return false; // Exit the loop if the condition is false
                //     }

                //     const objIndex = this.site_status.findIndex(obj => obj.status_sequence === statusSequence);
                //     this.site_status[objIndex].disabled = false;

                //     return true; // Continue to the next iteration if the condition is true
                // });
            },
            getSiteMilestoneActualDates(milestone_name) {
                if (this.site_milestones.length == 0) {
                    return false;
                }

                const site_milestone_data = this.site_milestones.find(obj => obj.site_milestone_code === milestone_name);

                if (typeof site_milestone_data !== "undefined") {
                    if (site_milestone_data.actual_start_date != null && site_milestone_data.actual_end_date != null) {
                        return true;
                    } else {
                        return false;
                    }
                } else {
                    return false;
                }

            },
            getContractorListing() {
                axios.get(apiDomain + 'setup/contractor', { headers: getHeader() })
                    .then ( response => {
                        if (response.status === 200)
                        {
                            this.contractors = response.data.data
                        }
                    })
                    .catch ( error => {
                        console.log(error.response.status)
                    } );
            },
            getModuleBrandListing(){
                axios.get(apiDomain + "setup/module_brand", { headers: getHeader() })
                    .then((response) => {
                        if (response.status === 200) {
                            this.module_brands = response.data.data;
                        }
                    })
                    .catch((error) => {
                        this.errorFunction(error, "Module Brand Information");
                    });
            },
            updateDrawingWattClass() {
                const module_brand_data = this.module_brands.find(obj => obj.module_brand_id === this.model.site_drawing_module_brand_id);
                this.model.site_drawing_module_capacity = parseFloat(module_brand_data.watt_class.replace(/,/g, ""));;
                this.calculateDrawingCapacity();
            },
            calculateDrawingCapacity() {
                this.model.site_drawing_total_capacity = ((this.model.site_drawing_module_quantity * this.model.site_drawing_module_capacity) / 1000)
            },
            updateModuleAllocationWattClass() {
                const module_brand_data = this.module_brands.find(obj => obj.module_brand_id === this.model.site_module_brand_id);
                this.model.site_module_capacity = module_brand_data.watt_class;
                this.calculateModuleAllocationCapacity();
            },
            calculateModuleAllocationCapacity() {
                this.model.site_module_allocation_total_capacity = ((this.model.site_module_quantity * this.model.site_module_capacity) / 1000);
            },
            getMilestoneTemplateListing() {
                axios.get(apiDomain + 'template/milestone_template', { headers: getHeader() })
                    .then ( response => {
                        if (response.status === 200)
                        {
                            this.milestone_template = response.data.data;
                        }
                    })
                    .catch ( error => {
                        this.errorFunction(error, "Milestone Template Information")
                    } );
            },
            checkMilestoneDetail()
            {
                if (this.model.milestone_template_id == 'x')
                {
                    this.$notify({
                        message:
                            '<b>Invalid Input : Milestone Template </b> - Please select a Milestone Template before dive into the Details',
                        timeout: 10000,
                        icon: 'ni ni-bulb-61',
                        type: 'danger',
                    });
                }
                else
                {
                    window.open('/templates/milestoneTemplateDetailListing/' + this.model.milestone_template_id, '_blank');
                }
            },
            importSiteMilestones() {
                if (this.model.milestone_template_id == 'x')
                {
                    this.$notify({
                        message:
                            '<b>Invalid Input : Milestone Template </b> - Please select a Milestone Template',
                        timeout: 10000,
                        icon: 'ni ni-bulb-61',
                        type: 'danger',
                    });
                }
                else
                {
                    // project_site.import_site_milestones/{site_id}
                    // call sp
                    const temp_model = {
                        milestone_template_id: this.model.milestone_template_id
                    };

                    const tokenData = JSON.parse(window.localStorage.getItem("authUser"));
                    const headers = {
                        Authorization: "Bearer " + tokenData.access_token,
                    };

                    axios
                        .post(
                            apiDomain + "project/project_site.import_site_milestones/" + this.model.site_id,
                            temp_model,
                            { headers }
                        )
                        .then((response) => {
                            console.log(response);
                            this.getProjectSiteMilestoneData();
                        })
                        .catch((error) => {
                            this.errorFunction(error, "Import Site Milestones");
                        });
                }
            },
            getDashboardData() {
                this.model.site_id = this.$route.params.id;
                axios
                    .get(apiDomain + "project/project_site/" + this.model.site_id, {
                        headers: getHeader(),
                    })
                    .then((response) => {
                        if (response.status === 200) {
                            this.model.project_id = response.data.data[0].project.project_id;
                            this.model.group_id = response.data.data[0].group.group_id;
                            this.model.site_name = response.data.data[0].site_name;
                            this.model.status_id = response.data.data[0].status.status_id;
                            this.model.site_address = response.data.data[0].site_address;
                            this.model.site_postal_code = response.data.data[0].site_postal_code;
                            this.model.site_code = response.data.data[0].site_code;
                            this.model.site_latitude = response.data.data[0].site_latitude;
                            this.model.site_longitude = response.data.data[0].site_longitude;
                            this.model.site_cod_date = response.data.data[0].site_cod_date;
                            this.getProjectData();
                            this.model.site_target_turn_on_date = response.data.data[0].site_target_turn_on_date;
                            this.model.site_actual_turn_on_date = response.data.data[0].site_actual_turn_on_date;
                            // this.model.site_total_capacity = response.data.data[0].site_total_capacity;
                            this.model.site_total_capacity = parseFloat(response.data.data[0].site_total_capacity.replace(/,/g, ""));
                            this.model.site_as_built_capacity = parseFloat(response.data.data[0].site_as_built_capacity.replace(/,/g, ""));

                            this.model.site_drawing_module_brand_id = response.data.data[0].site_drawing_module_brand.module_brand_id;
                            this.model.site_drawing_module_quantity = parseFloat(response.data.data[0].site_drawing_module_quantity.replace(/,/g, ""));
                            this.model.site_drawing_module_capacity = parseFloat(response.data.data[0].site_drawing_module_capacity.replace(/,/g, ""));
                            this.model.site_drawing_total_capacity = parseFloat(response.data.data[0].site_drawing_total_capacity.replace(/,/g, ""));
                            this.model.site_drawing_ifa_date = response.data.data[0].site_drawing_ifa_date;
                            this.model.site_drawing_ifc_date = response.data.data[0].site_drawing_ifc_date;
                            this.model.site_module_brand_id = response.data.data[0].site_module_brand.module_brand_id;
                            this.model.site_module_quantity = parseFloat(response.data.data[0].site_module_quantity.replace(/,/g, ""));
                            this.model.site_module_capacity = parseFloat(response.data.data[0].site_module_capacity.replace(/,/g, ""));
                            this.model.site_module_allocation_total_capacity = parseFloat(response.data.data[0].site_module_allocation_total_capacity.replace(/,/g, ""));
                            this.model.site_ifc_ifa_module_remarks = response.data.data[0].site_ifc_ifa_module_remarks;
                        }
                    })
                    .catch((error) => {
                        this.errorFunction(error, "Site Information");
                    });
            },
            getProjectData() {
                axios
                    .get(apiDomain + "project/project/" + this.model.project_id, {
                        headers: getHeader(),
                    })
                    .then((response) => {
                        if (response.status === 200) {
                            //this.contacts_model.project_manager = response.data.data[0].project_manager.name;
                            //this.contacts_model.project_engineer = response.data.data[0].project_engineer.name;
                            //this.contacts_model.project_business_development = response.data.data[0].project_business_development.name;
                            //this.contacts_model.project_safety = response.data.data[0].project_safety.name;
                            this.projects_model.project_ppa_start_date = response.data.data[0].project_ppa_start_date;
                            this.projects_model.project_ppa_approved_capacity = response.data.data[0].project_ppa_approved_capacity;
                            this.projects_model.project_name = response.data.data[0].project_name;
                            this.projects_model.project_developer_name = response.data.data[0].developer.developer_name;
                            this.projects_model.project_watt_class = response.data.data[0].project_watt_class;
                            // this.updateAvailableProjectSiteStatus();

                            // default cod date = ppa signed date + 180 days
                            const project_ppa_start_date = response.data.data[0].project_ppa_start_date;
                            console.log('this.model.site_cod_date');
                            console.log(this.model.site_cod_date);
                            if (project_ppa_start_date && this.model.site_cod_date == null) {
                                const ppaCod = new Date(project_ppa_start_date);
                                ppaCod.setDate(ppaCod.getDate() + 180);
                                const ppaCodMonth = String((ppaCod.getMonth()+1)).padStart(2, '0');
                                const ppaCodDay = String(ppaCod.getDate()).padStart(2, '0');
                                const ppaCodDate = ppaCod.getFullYear() + '-' + ppaCodMonth + '-' + ppaCodDay;
                                this.model.site_cod_date = ppaCodDate;
                            }

                            this.updateAvailableProjectSiteStatus();
                        }
                    })
                    .catch((error) => {
                        if (typeof error.response !== "undefined") {
                            this.errorFunction(error, "Project Information");
                        }
                    });
            },
            getDashboardAdditionalInfo() {
                axios
                    .get(apiDomain + "project/project_site_additional_info/" + this.model.site_id, {
                        headers: getHeader(),
                    })
                    .then((response) => {
                        if (response.status === 200) {
                            this.additional_model.project_site_additional_info_id = response.data.data[0].project_site_additional_info_id;
                            this.additional_model.site_id = response.data.data[0].site_id;
                            this.additional_model.ntp_applicable = response.data.data[0].ntp_applicable;
                            this.additional_model.ntp_issued = response.data.data[0].ntp_issued;
                            this.additional_model.ntp_issue_date = response.data.data[0].ntp_issue_date;
                            this.additional_model.internal_project_handover_completed = response.data.data[0].internal_project_handover_completed;
                            this.additional_model.internal_project_handover_date = response.data.data[0].internal_project_handover_date;
                            this.additional_model.kick_off_meeting_completed = response.data.data[0].kick_off_meeting_completed;
                            this.additional_model.kick_off_meeting_date = response.data.data[0].kick_off_meeting_date;
                            this.additional_model.roof_handover_completed = response.data.data[0].roof_handover_completed;
                            this.additional_model.roof_handover_date = response.data.data[0].roof_handover_date;
                            this.additional_model.cops_tnc_checked = response.data.data[0].cops_tnc_checked;
                            this.additional_model.cops_tnc_issue = response.data.data[0].cops_tnc_issue;
                            this.additional_model.cops_tnc_remarks = response.data.data[0].cops_tnc_remarks;
                            this.additional_model.onm_handover_checked = response.data.data[0].onm_handover_checked;
                            this.additional_model.onm_handover_issue = response.data.data[0].onm_handover_issue;
                            this.additional_model.onm_handover_remarks = response.data.data[0].onm_handover_remarks;
                            this.additional_model.turn_on_checking_approved = response.data.data[0].turn_on_checking_approved;
                            this.additional_model.turn_on_checking_approval_date = response.data.data[0].turn_on_checking_approval_date;
                            this.additional_model.turn_on_checking_approver = response.data.data[0].turn_on_checking_approver;
                            this.additional_model.cif_technical_info = JSON.parse(response.data.data[0].cif_technical_info);
                            this.additional_model.cif_project_info = JSON.parse(response.data.data[0].cif_project_info);
                            if (this.additional_model.cif_project_info) {
                                this.addIsEmailAttribute(this.additional_model.cif_project_info);
                            }
                        }
                    })
                    .catch((error) => {
                        this.errorFunction(error, "Site Additional Information");
                    });
            },
            addIsEmailAttribute(data) {
                for (let i = 0; i < data.length; i++) {
                    if (data[i].column_4 === 'Email / Phone Number') {
                        for (let j = i + 1; j <= i + 4 && j < data.length; j++) {
                            data[j].isEmail = true;
                        }
                    }
                }
            },
            getProjectSiteMilestoneData() {
                axios
                    .get(
                        apiDomain +
                        "project/project_site_milestone.show_by_site/" +
                        this.model.site_id,
                        { headers: getHeader() }
                    )
                    .then((response) => {
                        if (response.status === 200) {
                            let data = response.data.data;
                            data.forEach(item => {
                                item.total_planned_day = 0;
                                item.total_actual_day = 0;
                                if(item.planned_start_date !== null && item.planned_end_date !== null){
                                    item.total_planned_day  = this.getDaysDifference(item.planned_start_date,item.planned_end_date)
                                }

                                if(item.actual_start_date !== null && item.actual_end_date !== null){
                                    item.total_actual_day  = this.getDaysDifference(item.actual_start_date,item.actual_end_date)
                                }
                            });
                            this.site_milestones = data;
                            this.updateGanttChartSeries();

                            // get and update Planned Turn On (site_target_turn_on_date) based on "Turn On" planned_end_date
                            // get and update Actual Turn On based on "Turn On" actual_end_date if value is null
                            // ONE WAY!!!
                            // changing planned_end_date of "Turn On" will change site_target_turn_on_date
                            // changing site_target_turn_on_date will not change planned_end_date of "Turn On"

                            const turn_on_milestone_data = this.site_milestones.find(obj => obj.site_milestone_code === "Turn On");

                            if (turn_on_milestone_data != undefined) {
                                // update Planned Turn On (site_target_turn_on_date) based on "Turn On" planned_end_date
                                if (turn_on_milestone_data.planned_end_date) {
                                    this.model.site_target_turn_on_date = turn_on_milestone_data.planned_end_date;
                                }
                                // update Actual Turn On based on "Turn On" actual_end_date if value is null
                                if (turn_on_milestone_data.actual_end_date && (this.model.site_actual_turn_on_date == null)) {
                                    this.model.site_actual_turn_on_date = turn_on_milestone_data.actual_end_date;
                                }
                            }
                        }
                    })
                    .catch((error) => {
                        console.log(error.response.status);
                    });
            },
            updateGanttChartSeries() {
                const temp_series = [
                    {
                        name: 'Planned',
                        data: this.site_milestones.map(item => ({
                            x: item.site_milestone_code,
                            y: [
                                item.planned_start_date ? new Date(item.planned_start_date).getTime() : null,
                                item.planned_end_date ? new Date(item.planned_end_date).getTime() : null
                            ]
                        }))
                    },
                    {
                        name: 'Actual',
                        data: this.site_milestones.map(item => ({
                            x: item.site_milestone_code,
                            y: [
                                item.actual_start_date ? new Date(item.actual_start_date).getTime() : null,
                                item.actual_end_date ? new Date(item.actual_end_date).getTime() : null
                            ]
                        }))
                    }
                ];

                this.series = [];
                this.series = temp_series;

                // this.chartOptions.xaxis.min = new Date('2023-07-01').getTime();
                // this.chartOptions.xaxis.max = new Date('2023-12-01').getTime();
            },
            getContractorFromSite()
            {
                axios.get(apiDomain + 'project/site_contractor.show_by_site/' + this.model.site_id + '?client=mobile', { headers: getHeader() })
                    .then ( response => {
                        if (response.status === 200)
                        {
                            this.model.contractor_id = response.data.data[0].contractor.contractor_id;
                            this.updateAvailableProjectSiteStatus();
                        }
                    })
                    .catch ( error => {
                        if (typeof error.response !== "undefined") {
                            this.errorFunction(error, "Site Contractor Information")
                        }
                    } );
            },
            saveSiteData() {
                // CAREFUL WITH PUT METHOD, MIGHT NEED TO CHANGE TO POST WITH _put

                axios.put(apiDomain + 'project/project_site/' + this.model.site_id, this.model, { headers: getHeader() })
                    .then ( response => {
                        if (response.status === 200)
                        {
                            this.$notify({
                                message:
                                '<b>Update Project Site : ' + this.model.site_name + '</b> - Successfully Updated.',
                                timeout: 10000,
                                icon: 'ni ni-bell-55',
                                type: 'default',
                            });
                            // this.$router.back()
                        }
                    })
                    .catch ( error => {
                        this.errorFunction(error, "Update Project Site Information")
                    });
            },
            setActiveSiteContractor() {
                if ( this.model.contractor_id == 'x' ) {
                    this.$notify({
                        message:
                            '<b>Invalid Contractor : </b> - Please Select A Contractor to proceed.',
                        timeout: 10000,
                        icon: 'ni ni-bulb-61',
                        type: 'danger',
                    });
                } else {
                    const site_contractor_model = {
                        site_id: this.model.site_id,
                        contractor_id: this.model.contractor_id,
                        active_status: 1
                    };

                    const tokenData = JSON.parse(window.localStorage.getItem("authUser"));
                    const headers = {
                        Authorization: "Bearer " + tokenData.access_token,
                    };

                    axios
                        .post(
                            apiDomain + "project/site_contractor.set_active_contractor/" + this.model.site_id,
                            site_contractor_model,
                            { headers }
                        )
                        .then((response) => {
                            this.$notify({
                                message:
                                    '<b>Site Contractor Successfully Updated.',
                                timeout: 10000,
                                icon: 'ni ni-bell-55',
                                type: 'default',
                            });
                        })
                        .catch((error) => {
                            this.errorFunction(error, "Set Active Site Contractor")
                        });
                }
            },
            saveSiteAdditionalInfo() {
                // CAREFUL WITH PUT METHOD, MIGHT NEED TO CHANGE TO POST WITH _put
                axios.put(apiDomain + 'project/project_site_additional_info/' + this.model.site_id, this.additional_model, { headers: getHeader() })
                    .then ( response => {
                        if (response.status === 200)
                        {
                            this.$notify({
                                message:
                                '<b>Update Project Site : ' + this.model.site_name + '</b> - Successfully Updated.',
                                timeout: 10000,
                                icon: 'ni ni-bell-55',
                                type: 'default',
                            });
                            this.getDashboardAdditionalInfo();
                            // this.$router.back()
                        }
                    })
                    .catch ( error => {
                        this.errorFunction(error, "Update Project Site Additional Information")
                    });

            },
            approveToCloseTurnOnChecking()
            {
                const dummy_model = {
                    dummy_data: 0
                };

                const tokenData = JSON.parse(window.localStorage.getItem("authUser"));
                const headers = {
                    Authorization: "Bearer " + tokenData.access_token,
                };

                axios
                    .post(
                        apiDomain + "project/project_site_additional_info.approve_to_close/" + this.model.site_id,
                        dummy_model,
                        { headers }
                    )
                    .then((response) => {
                        this.$notify({
                            message:
                                '<b>Turn On Checking Approval To Close Successfully Approved.</b>',
                            timeout: 10000,
                            icon: 'ni ni-bell-55',
                            type: 'default',
                        });
                        //   this.$router.back()
                        this.getDashboardAdditionalInfo();
                    })
                    .catch((error) => {
                        this.errorFunction(error, "Approve To Close Turn On Checking")
                    });
            },
            saveSiteMilestoneData() {
                // this.site_milestones

                const tokenData = JSON.parse(window.localStorage.getItem("authUser"));
                const headers = {
                    Authorization: "Bearer " + tokenData.access_token,
                };

                axios
                    .post(
                        apiDomain + "project/project_site_milestone.update_site_milestones/" + this.model.site_id,
                        this.site_milestones,
                        { headers }
                    )
                    .then((response) => {
                        this.getProjectSiteMilestoneData();
                    })
                    .catch((error) => {
                        this.errorFunction(error, "Update Site Milestones Attachment");
                    });

            },
            dataPointSelectionHandler(e, chartContext, config) {
                //   this.tempPieDataCNI = [config.seriesIndex, config.dataPointIndex];
                this.selectedDataPointIndex = config.dataPointIndex;
                this.total_actual_date = this.site_milestones[config.dataPointIndex].total_actual_day;
                this.isSiteMilestoneVisible = true;
            },
            closeAndUpdateGanttChart() {
                this.updateGanttChartSeries();
                this.saveSiteMilestoneData();
                this.isSiteMilestoneVisible = false;
            },
            getSiteAttachmentListing() {
                axios
                    .get(apiDomain + "project/project_site.site_attachment/" + this.model.site_id, {
                        headers: getHeader(),
                    })
                    .then((response) => {
                        if (response.status === 200) {
                            this.site_attachments = response.data.data;
                        }
                    })
                    .catch((error) => {
                        this.errorFunction(error, "Site Attachment Listing");
                    });
            },
            onChange(event) {
                this.site_attachment.file = event.target.files ? event.target.files[0] : null;
            },
            uploadFile() {
                let formData = jsonFormData(this.site_attachment);

                const tokenData = JSON.parse(window.localStorage.getItem("authUser"));
                const headers = {
                    Authorization: "Bearer " + tokenData.access_token,
                };

                axios
                    .post(
                        apiDomain + "project/project_site.upload_site_attachment/" + this.model.site_id,
                        formData,
                        { headers }
                    )
                    .then((response) => {
                        // NOTIFY SUCCESSFUL UPLOAD HERE
                        this.getSiteAttachmentListing();
                        this.site_attachment.file = null;
                    })
                    .catch((error) => {
                        this.errorFunction(error, "Upload Site Attachment");
                    });
            },
            downloadFile(apiEndpoint, filename) {
                axios.get(apiDomain + apiEndpoint, { headers: getHeader(),  responseType: 'blob'})
                    .then((response) => {
                        var fileURL = window.URL.createObjectURL(new Blob([response.data]));
                        var fileLink = document.createElement('a');

                        fileLink.href = fileURL;
                        fileLink.setAttribute('download', filename);
                        document.body.appendChild(fileLink);

                        fileLink.click();
                    });
            },
            deleteFile(site_file_id) {
                axios.get(apiDomain  + "project/project_site.delete_site_attachment/" + site_file_id, { headers: getHeader()})
                    .then((response) => {
                        this.getSiteAttachmentListing();
                    })
                    .catch((error) => {
                        this.errorFunction(error, "Delete Site Attachment");
                    });
            },
            onChangeCif(event) {
                this.cif_file = event.target.files ? event.target.files[0] : null;
            },
            loadSheetNames() {
                if (typeof(this.$refs.excelRead._workbook) !== "undefined") {
                    // this.sheets = this.$refs.excelRead._workbook.Workbook.Sheets;
                    this.sheets = this.$refs.excelRead._workbook.Workbook.Sheets.filter(item => item.name == "Stage_1 Technical Info (ENG)" || item.name == "Stage_1 Project Info (BD)");
                }
            },
            updateRangeToSelect () {
                if (typeof(this.$refs.excelRead._workbook) !== "undefined" && this.selected_sheet == "Stage_1 Technical Info (ENG)") {
                    this.selected_range = 'B8:D63';
                } else if (typeof(this.$refs.excelRead._workbook) !== "undefined" && this.selected_sheet == "Stage_1 Project Info (BD)") {
                    this.selected_range = 'B7:E66';
                }

            },
            showUploadingData () {
                this.tempData = this.$refs.excelJson.$data.collection;
                if (typeof(this.$refs.excelRead._workbook) !== "undefined" && this.selected_sheet == "Stage_1 Technical Info (ENG)") {
                    this.converted_temp_data = this.$refs.excelJson.$data.collection.map((item) => {
                        return {
                            column_name: item.__EMPTY,
                            unit: item.__EMPTY_1,
                            value: item["Building 1"],
                        };
                    });
                } else if (typeof(this.$refs.excelRead._workbook) !== "undefined" && this.selected_sheet == "Stage_1 Project Info (BD)") {
                    this.converted_temp_data = this.$refs.excelJson.$data.collection.map((item) => {
                        return {
                            column_1: item.Information,
                            column_2: item.Response,
                            column_3: item.__EMPTY,
                            column_4: item.Remarks,
                        };
                    });
                }
            },
            uploadCif() {
                this.updateRangeToSelect();
                this.showUploadingData();
                if (this.selected_sheet == "Stage_1 Technical Info (ENG)") {
                    this.uploadCifTechnicalInfo();
                } else if (this.selected_sheet == "Stage_1 Project Info (BD)") {
                    this.uploadCifProjectInfo();
                }
            },
            uploadCifTechnicalInfo () {
                const post_data = {
                    data: this.converted_temp_data
                }

                const tokenData = JSON.parse(window.localStorage.getItem("authUser"));
                const headers = {
                    Authorization: "Bearer " + tokenData.access_token,
                };

                axios
                    .post(
                        apiDomain + "project/project_site_additional_info.cif_technical_info/" + this.model.site_id,
                        post_data,
                        { headers }
                    )
                    .then((response) => {
                        // NOTIFY SUCCESSFUL UPLOAD HERE
                        this.selected_sheet = 'x';
                        this.selected_range = '';
                        this.getDashboardAdditionalInfo();
                    })
                    .catch((error) => {
                        this.errorFunction(error, "Upload CIF Technical Info");
                    });
            },
            uploadCifProjectInfo () {
                const post_data = {
                    data: this.converted_temp_data
                }

                const tokenData = JSON.parse(window.localStorage.getItem("authUser"));
                const headers = {
                    Authorization: "Bearer " + tokenData.access_token,
                };

                axios
                    .post(
                        apiDomain + "project/project_site_additional_info.cif_project_info/" + this.model.site_id,
                        post_data,
                        { headers }
                    )
                    .then((response) => {
                        // NOTIFY SUCCESSFUL UPLOAD HERE
                        this.selected_sheet = 'x';
                        this.selected_range = '';
                        this.getDashboardAdditionalInfo();
                    })
                    .catch((error) => {
                        this.errorFunction(error, "Upload CIF Project Info");
                    });
            },
            getDaysDifference(startDate, endDate) {

                if(startDate != "N/A" || endDate != "N/A"){
                    // Convert the date strings to Date objects
                    const startDateObj = new Date(startDate);
                    const endDateObj = new Date(endDate);

                    // Calculate the difference in milliseconds between the two dates
                    const differenceInMilliseconds = endDateObj - startDateObj;

                    // Convert milliseconds to days
                    const millisecondsInOneDay = 1000 * 60 * 60 * 24;
                    const differenceInDays = differenceInMilliseconds / millisecondsInOneDay;

                    // Round the difference to remove decimal places
                    return Math.round(differenceInDays)+1;
                }else{
                    return 0;
                }
            },
            calculateTotalPlannedDay(index,field,inputValue = null){

                let plannedStartDate = this.site_milestones[index].planned_start_date;
                let plannedEndDate = this.site_milestones[index].planned_end_date;
                let totalPlannedDate = this.site_milestones[index].total_planned_day;

                let actualStartDate = this.site_milestones[index].actual_start_date;
                let actualEndDate = this.site_milestones[index].actual_end_date;
                let totalActualDate =  (inputValue !== null) ? inputValue : this.total_actual_date;

                let newDate = null;
                let convertDate = null;

                console.log(plannedStartDate)
                console.log(plannedEndDate)
                console.log(totalPlannedDate)

                console.log(actualStartDate)
                console.log(actualEndDate)
                console.log(totalActualDate)

                const millisecondsInOneDay = 1000 * 60 * 60 * 24;

                if(field == 'total_planned_day'){
                    if(plannedStartDate !== null && totalPlannedDate !== null){
                        plannedStartDate  = new Date(plannedStartDate);
                        newDate = new Date(plannedStartDate.getTime() + totalPlannedDate*millisecondsInOneDay);
                        convertDate = this.convertDateTime(newDate);
                        this.site_milestones[index].planned_end_date = convertDate;
                    }else if(plannedStartDate == null && plannedEndDate !== null && totalPlannedDate !== null){
                        plannedEndDate  = new Date(plannedEndDate);
                        newDate = new Date(plannedEndDate.getTime() - totalPlannedDate*millisecondsInOneDay);
                        convertDate = this.convertDateTime(newDate);
                        this.site_milestones[index].planned_start_date = convertDate;
                    }

                }else if(field == 'planned_start_date'){
                    if(plannedStartDate !== null && totalPlannedDate > 0){
                        plannedStartDate  = new Date(plannedStartDate);
                        newDate = new Date(plannedStartDate.getTime() + totalPlannedDate*millisecondsInOneDay);
                        convertDate = this.convertDateTime(newDate);
                        this.site_milestones[index].planned_end_date = convertDate;
                    }else if(plannedStartDate !== null && plannedEndDate !== null){
                        totalPlannedDate = this.getDaysDifference(plannedStartDate,plannedEndDate);
                        this.site_milestones[index].total_planned_day = totalPlannedDate;
                    }
                }else if(field == 'planned_end_date'){
                    if(plannedEndDate !== null && totalPlannedDate > 0 && plannedStartDate == null){
                        plannedEndDate  = new Date(plannedEndDate);
                        newDate = new Date(plannedEndDate.getTime() - totalPlannedDate*millisecondsInOneDay);
                        convertDate = this.convertDateTime(newDate);
                        this.site_milestones[index].planned_start_date = convertDate;
                    }else if(plannedStartDate !== null && plannedEndDate !== null){
                        totalPlannedDate = this.getDaysDifference(plannedStartDate,plannedEndDate);
                        this.site_milestones[index].total_planned_day = totalPlannedDate;
                    }
                }else if(field == 'total_actual_day'){
                    console.log(actualStartDate);
                    console.log(totalActualDate);
                    console.log(actualEndDate);
                    if(actualStartDate  != '<empty string>' && actualStartDate !== null && totalActualDate !== null){
                        console.log('is here')
                        actualStartDate  = new Date(actualStartDate);
                        newDate = new Date(actualStartDate.getTime() + totalActualDate*millisecondsInOneDay);
                        convertDate = this.convertDateTime(newDate);
                        console.log(convertDate);
                        this.site_milestones[index].actual_end_date = convertDate;
                    }else if(actualStartDate  == '<empty string>' && actualStartDate == null && actualEndDate !== null && totalActualDate !== null){
                        actualEndDate  = new Date(actualEndDate);
                        newDate = new Date(actualEndDate.getTime() - totalActualDate*millisecondsInOneDay);
                        convertDate = this.convertDateTime(newDate);
                        this.site_milestones[index].actual_start_date = convertDate;
                    }

                }else if(field == 'actual_start_date'){
                    if(actualStartDate  != '<empty string>' && actualStartDate !== null && totalActualDate > 0){
                        actualStartDate  = new Date(actualStartDate);
                        newDate = new Date(actualStartDate.getTime() + totalActualDate*millisecondsInOneDay);
                        convertDate = this.convertDateTime(newDate);
                        this.site_milestones[index].actual_end_date = convertDate;
                    }else if(actualStartDate !== null && actualEndDate !== null){
                        totalActualDate = this.getDaysDifference(actualStartDate,actualEndDate);
                        this.site_milestones[index].total_actual_day = totalActualDate;
                    }
                }else if(field == 'actual_end_date'){
                    if(actualEndDate !== null && totalActualDate > 0 && (actualStartDate == null  || actualStartDate  == '<empty string>')){
                        actualEndDate  = new Date(actualEndDate);
                        newDate = new Date(actualEndDate.getTime() - totalActualDate*millisecondsInOneDay);
                        convertDate = this.convertDateTime(newDate);
                        this.site_milestones[index].actual_start_date = convertDate;
                    }else if(actualStartDate !== '<empty string>' && actualStartDate !== null && actualEndDate !== null){
                        totalActualDate = this.getDaysDifference(actualStartDate,actualEndDate);
                        this.site_milestones[index].total_actual_day = totalActualDate;
                    }
                }

            },
            specificCalculateActualDate(event){
                this.site_milestones[this.selectedDataPointIndex].total_actual_day = event;
                this.calculateTotalPlannedDay(this.selectedDataPointIndex,'total_actual_day',event);
            },
            convertDateTime(date){
                let convertDate = new Date(date);
                const year = convertDate.getFullYear();
                const month = convertDate.getMonth();
                const day = convertDate.getDate();

                return convertDate.toISOString().slice(0,10);
            },
            onChangeProjectSiteTask(event){
                this.project_task_file = event.target.files ? event.target.files[0] : null;

            },
            loadProjectTaskSheetNames() {
                if (typeof(this.$refs.excelRead1._workbook) !== "undefined") {
                    this.projectTaskSheets = this.$refs.excelRead1._workbook.Workbook.Sheets;
                    //this.projectTaskSheets = this.$refs.excelRead1._workbook.Workbook.Sheets.filter(item => item.name == "Task_Table1");
                }
            },
            updateProjectTaskRangeToSelect () {
                //this.selected_project_task_sheet = "Task_Table1";
                if (typeof(this.$refs.excelRead1._workbook) !== "undefined" && this.selected_project_task_sheet != 'x') {
                    const original_range = this.$refs.excelRead1._workbook.Sheets[this.selected_project_task_sheet]['!ref'];
                    this.selected_project_task_range = 'A1:' + original_range.substring(3);
                    this.selected_project_task_range = this.selected_project_task_range.replace(/:(.*?)(?=\d)/, ':J');
                }

            },
            showUploadingProjectTaskData(){
                this.projectTaskTempData = this.$refs.excelJson1.$data.collection;
            },
            uploadProjectSiteTask() {
                this.updateProjectTaskRangeToSelect();
                this.showUploadingProjectTaskData();

                const post_data = {
                    data: this.projectTaskTempData
                }


                const tokenData = JSON.parse(window.localStorage.getItem("authUser"));
                const headers = {
                    Authorization: "Bearer " + tokenData.access_token,
                };
                axios
                    .post(
                        apiDomain + "project/ms_project_import_from_upload.from_json/"+ this.model.site_id,
                        post_data,
                        { headers }
                    )
                    .then((response) => {
                        if (response.status === 200) {
                            //this.getGanttChartData();

                            this.$notify({
                                message:
                                '<b>Update Project Site Task : ' + this.model.site_name + '</b> - Successfully Updated.',
                                timeout: 10000,
                                icon: 'ni ni-bell-55',
                                type: 'default',
                            });
                        }

                    })
                    .catch((error) => {
                        this.$notify({
                            message: "</b>Import New Project File",
                            timeout: 10000,
                            icon: "ni ni-bulb-61",
                            type: "danger",
                        });
                        this.errorFunction(error);
                    });
            },
            getDaysDifference(startDate, endDate) {

                if(startDate != "N/A" || endDate != "N/A"){
                    // Convert the date strings to Date objects
                    const startDateObj = new Date(startDate);
                    const endDateObj = new Date(endDate);

                    // Calculate the difference in milliseconds between the two dates
                    const differenceInMilliseconds = endDateObj - startDateObj;

                    // Convert milliseconds to days
                    const millisecondsInOneDay = 1000 * 60 * 60 * 24;
                    const differenceInDays = differenceInMilliseconds / millisecondsInOneDay;

                    // Round the difference to remove decimal places
                    return Math.round(differenceInDays)+1;
                }else{
                    return 0;
                }
            },
            calculateTotalPlannedDay(index,field,inputValue = null){

                let plannedStartDate = this.site_milestones[index].planned_start_date;
                let plannedEndDate = this.site_milestones[index].planned_end_date;
                let totalPlannedDate = this.site_milestones[index].total_planned_day;

                let actualStartDate = this.site_milestones[index].actual_start_date;
                let actualEndDate = this.site_milestones[index].actual_end_date;
                let totalActualDate =  (inputValue !== null) ? inputValue : this.total_actual_date;

                let newDate = null;
                let convertDate = null;

                const millisecondsInOneDay = 1000 * 60 * 60 * 24;

                if(field == 'total_planned_day'){
                    if(plannedStartDate !== null && totalPlannedDate !== null){
                        plannedStartDate  = new Date(plannedStartDate);
                        newDate = new Date(plannedStartDate.getTime() + totalPlannedDate*millisecondsInOneDay);
                        convertDate = this.convertDateTime(newDate);
                        this.site_milestones[index].planned_end_date = convertDate;
                    }else if(plannedStartDate == null && plannedEndDate !== null && totalPlannedDate !== null){
                        plannedEndDate  = new Date(plannedEndDate);
                        newDate = new Date(plannedEndDate.getTime() - totalPlannedDate*millisecondsInOneDay);
                        convertDate = this.convertDateTime(newDate);
                        this.site_milestones[index].planned_start_date = convertDate;
                    }

                }else if(field == 'planned_start_date'){
                    if(plannedStartDate !== null && totalPlannedDate > 0){
                        plannedStartDate  = new Date(plannedStartDate);
                        newDate = new Date(plannedStartDate.getTime() + totalPlannedDate*millisecondsInOneDay);
                        convertDate = this.convertDateTime(newDate);
                        this.site_milestones[index].planned_end_date = convertDate;
                    }else if(plannedStartDate !== null && plannedEndDate !== null){
                        totalPlannedDate = this.getDaysDifference(plannedStartDate,plannedEndDate);
                        this.site_milestones[index].total_planned_day = totalPlannedDate;
                    }
                }else if(field == 'planned_end_date'){
                    if(plannedEndDate !== null && totalPlannedDate > 0 && plannedStartDate == null){
                        plannedEndDate  = new Date(plannedEndDate);
                        newDate = new Date(plannedEndDate.getTime() - totalPlannedDate*millisecondsInOneDay);
                        convertDate = this.convertDateTime(newDate);
                        this.site_milestones[index].planned_start_date = convertDate;
                    }else if(plannedStartDate !== null && plannedEndDate !== null){
                        totalPlannedDate = this.getDaysDifference(plannedStartDate,plannedEndDate);
                        this.site_milestones[index].total_planned_day = totalPlannedDate;
                    }
                }else if(field == 'total_actual_day'){
                    console.log(actualStartDate);
                    console.log(totalActualDate);
                    console.log(actualEndDate);
                    if(actualStartDate  != '<empty string>' && actualStartDate !== null && totalActualDate !== null){
                        console.log('is here')
                        actualStartDate  = new Date(actualStartDate);
                        newDate = new Date(actualStartDate.getTime() + totalActualDate*millisecondsInOneDay);
                        convertDate = this.convertDateTime(newDate);
                        console.log(convertDate);
                        this.site_milestones[index].actual_end_date = convertDate;
                    }else if(actualStartDate  == '<empty string>' && actualStartDate == null && actualEndDate !== null && totalActualDate !== null){
                        actualEndDate  = new Date(actualEndDate);
                        newDate = new Date(actualEndDate.getTime() - totalActualDate*millisecondsInOneDay);
                        convertDate = this.convertDateTime(newDate);
                        this.site_milestones[index].actual_start_date = convertDate;
                    }

                }else if(field == 'actual_start_date'){
                    if(actualStartDate  != '<empty string>' && actualStartDate !== null && totalActualDate > 0){
                        actualStartDate  = new Date(actualStartDate);
                        newDate = new Date(actualStartDate.getTime() + totalActualDate*millisecondsInOneDay);
                        convertDate = this.convertDateTime(newDate);
                        this.site_milestones[index].actual_end_date = convertDate;
                    }else if(actualStartDate !== null && actualEndDate !== null){
                        totalActualDate = this.getDaysDifference(actualStartDate,actualEndDate);
                        this.site_milestones[index].total_actual_day = totalActualDate;
                    }
                }else if(field == 'actual_end_date'){
                    if(actualEndDate !== null && totalActualDate > 0 && (actualStartDate == null  || actualStartDate  == '<empty string>')){
                        actualEndDate  = new Date(actualEndDate);
                        newDate = new Date(actualEndDate.getTime() - totalActualDate*millisecondsInOneDay);
                        convertDate = this.convertDateTime(newDate);
                        this.site_milestones[index].actual_start_date = convertDate;
                    }else if(actualStartDate !== '<empty string>' && actualStartDate !== null && actualEndDate !== null){
                        totalActualDate = this.getDaysDifference(actualStartDate,actualEndDate);
                        this.site_milestones[index].total_actual_day = totalActualDate;
                    }
                }

            },
            specificCalculateActualDate(event){
                this.site_milestones[this.selectedDataPointIndex].total_actual_day = event;
                this.calculateTotalPlannedDay(this.selectedDataPointIndex,'total_actual_day',event);
            },
            convertDateTime(date){
                let convertDate = new Date(date);
                const year = convertDate.getFullYear();
                const month = convertDate.getMonth();
                const day = convertDate.getDate();

                return convertDate.toISOString().slice(0,10);
            },
            excelDateToJSDate(serial) {
                var utc_days = Math.floor(serial - 25569);
                var utc_value = utc_days * 86400;
                var date_info = new Date(utc_value * 1000);

                var fractional_day = serial - Math.floor(serial) + 0.0000001;

                var total_seconds = Math.floor(86400 * fractional_day);

                var seconds = total_seconds % 60;

                total_seconds -= seconds;

                var hours = Math.floor(total_seconds / (60 * 60));
                var minutes = Math.floor(total_seconds / 60) % 60;

                return new Date(
                    date_info.getFullYear(),
                    date_info.getMonth(),
                    date_info.getDate(),
                    hours,
                    minutes,
                    seconds
                );
            },
            convertToDateOnly(inputDateTime) {
                const current = inputDateTime;
                const currentMonth = String(current.getMonth() + 1).padStart(2, "0");
                const currentDay = String(current.getDate()).padStart(2, "0");
                const currentDate =
                    current.getFullYear() + "-" + currentMonth + "-" + currentDay;
                return currentDate;
            },
            convertExcelDateTime() {
                this.projectTaskTempData.forEach((e) => {
                    e["Start_Date"] = this.excelDateToJSDate(e["Start_Date"]);
                    e["Start_Date"] = this.convertToDateOnly(e["Start_Date"]);

                    e["Finish_Date"] = this.excelDateToJSDate(e["Finish_Date"]);
                    e["Finish_Date"] = this.convertToDateOnly(e["Finish_Date"]);

                    e["Actual_Start"] = (e["Actual_Start"] != "NA") ? this.excelDateToJSDate(e["Actual_Start"]) : e["Actual_Start"];
                    e["Actual_Start"] = (e["Actual_Start"] != "NA") ? this.convertToDateOnly(e["Actual_Start"]) : e["Actual_Start"];

                    e["Actual_Finish"] = (e["Actual_Finish"] != "NA") ? this.excelDateToJSDate(e["Actual_Finish"]) : e["Actual_Finish"];
                    e["Actual_Finish"] = (e["Actual_Finish"] != "NA") ? this.convertToDateOnly(e["Actual_Finish"]) : e["Actual_Finish"];
                });
            },
            getMsProjectHistoricalUpdates() {
                axios.get(apiDomain + 'project/get_ms_project_history_listing/'+this.$route.params.id, { headers: getHeader() })
                    .then ( response => {
                        if (response.status === 200)
                        {
                            let data = response.data.data;
                            data.forEach(item => {
                                if(item.mpuh_changed_date !== null){
                                    let date =  new Date(item.mpuh_changed_date).toLocaleDateString();
                                    let time =  new Date(item.mpuh_changed_date).toLocaleTimeString();
                                    item.mpuh_changed_date = `${date} ${time}`;
                                }

                            });
                            this.tableData = data;
                            this.fuseData();
                        }
                    })
                    .catch ( error => {
                        this.errorFunction(error, "Ms Project Historical Update Listing")
                    });
            },
            searchMsProjectHistoricalUpdate() {
                this.tempButtonStatus = true;
                axios.post(apiDomain + 'project/search_ms_project_history_listing/'+this.$route.params.id, this.filter, {
                    headers: getHeader(),
                })
                    .then ( response => {
                        if (response.status === 200)
                        {
                            let data = response.data.data;
                            data.forEach(item => {
                                if(item.mpuh_changed_date !== null){
                                    let date =  new Date(item.mpuh_changed_date).toLocaleDateString();
                                    let time =  new Date(item.mpuh_changed_date).toLocaleTimeString();
                                    item.mpuh_changed_date = `${date} ${time}`;
                                }

                            });
                            this.tableData = response.data.data;
                            this.fuseData();
                        }
                    })
                    .catch ( error => {
                        this.errorFunction(error, "Search Ms Project Historical Update Listing")
                    });
            },
            clearMsProjectHistoricalUpdate() {
                this.filter = '';
                this.tempButtonStatus = true;
                axios.post(apiDomain + 'project/search_ms_project_history_listing/'+this.$route.params.id, this.filter, {
                    headers: getHeader(),
                })
                    .then ( response => {
                        if (response.status === 200)
                        {
                            let data = response.data.data;
                            data.forEach(item => {
                                if(item.mpuh_changed_date !== null){
                                    let date =  new Date(item.mpuh_changed_date).toLocaleDateString();
                                    let time =  new Date(item.mpuh_changed_date).toLocaleTimeString();
                                    item.mpuh_changed_date = `${date} ${time}`;
                                }

                            });
                            this.tableData = response.data.data;
                            this.fuseData();
                        }
                    })
                    .catch ( error => {
                        this.errorFunction(error, "Search Ms Project Historical Update Listing")
                    });
            }

        },


        mounted() {

        }
    };
</script>
<style scoped>

    .th-table-new {
        padding-top: 35px;
        padding-bottom: 35px;
        padding-left: 20px;
    }
    .tr-td-table-new{
        padding-top: 30px;
        padding-bottom: 30px;
        padding-left: 20px;
    }

    .table thead th {
        padding-top: 0.5rem;
        padding-bottom: 1.5rem;
        font-size: 0.9rem;
        text-transform: uppercase;
        letter-spacing: 1px;
        border-bottom: 0px solid #e9ecef;
        border-top: 0px solid #e9ecef;
        color: white;
    }
    .table td {
        color: white;
    }
    .apexcharts-legend-marker {
        width: 12px !important;
        height: 12px !important;
        left: 0px !important;
        top:0px !important;
        border-radius: 2px !important;
    }
    /* .apexcharts-series {
      bottom:  30px !important;
     } */
    .apexcharts-rangebar-area{
        margin-bottom: 30px !important;
        padding-bottom: 30px !important;
        top: -80px;
    }
    /* .apexcharts-rangebar-series {
      padding: 30px !important;

    }
    .apexcharts-plot-series {
      padding: 30px !important;

    } */
    select {
        background: url("data:image/svg+xml,<svg width='10' height='7' viewBox='0 0 10 7' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M5.02552 6.35057L0.320068 0.46875H9.73098L5.02552 6.35057Z' fill='white'/></svg>") no-repeat;
        background-position: calc(100% - 0.75rem) center !important;
        -moz-appearance:none !important;
        -webkit-appearance: none !important;
        appearance: none !important;
        padding-right: 2rem !important;
    }

    .bg-card-dashboard {
        background-color: #23454A;
    }
    .bg-default-dashboard {
        background-color: #082A2F;
        /* background-size: cover; */
        /* position:fixed; */
        padding:0;
        margin:0;
        top:0;
        left:0;
        width: 100%;
        height: 100%;
    }
    .form-control {
        background-color: #2C545A;
        border-color: #2C545A;
        color: white;
    &:focus {
         background-color: #2C545A;
         border-color: #2C545A;
         color: white;
     }
    &::placeholder {
         color: white;
     }
    }
    .form-control {
    &:focus {
    &::placeholder {
         color: white;
     }
    }
    }
    .input-group-text {
        background-color: #2C545A;;
        color: white;
    &:focus {
         background-color: #2C545A;;
         border-color: #1D4147;
         color: white;
     }
    }
    .focused {
    .input-group-text {
        background-color: #2C545A;
    }
    }
    .navbar-search-dark .input-group {
        background-color: #23454A;
        color: black;
        border-color: rgba(255, 255, 255, 0.6);
    }
    .navbar-search .input-group {
        border-radius: 7px;
    }
    .el-input__inner, .el-select-dropdown__item.is-disabled:hover {
        background-color: #23454A;
    }
    .line-custom {
        background-color: #356168; margin-top: 15px; margin-bottom: 15px;
    }

    /*
    .el-table th.el-table__cell {

      overflow: hidden;
      -moz-user-select: none;
      -webkit-user-select: none;
      -ms-user-select: none;
      user-select: none;
      background-color: #23454A;
      color: white;
      padding: 20px;
      border-top: 0px solid #082A2F;
      border-bottom: 0px solid #082A2F;

    }

    .el-table td.el-table__cell, .el-table th.el-table__cell.is-leaf {
      padding-top: 1.5rem;
      padding-bottom: 1.5rem;
      border-bottom: 20px solid #082A2F;
      border-top: 0px solid #082A2F;
    }

    div.el-table .el-table__row {
      background: #3a656b;;
      border-bottom: 0 solid #082A2F;

    }
    div.el-table .el-table__row:hover {
      background: #0d2f33;
    }

    .el-table--border::after, .el-table--group::after, .el-table::before {
      content: '';
      position: absolute;
      background-color: #ebeef500;
      z-index: 1;
    }
    */

</style>
