<template>
  <card>
    <!-- Card header -->
    <h3 slot="header" class="mb-0">Import Task & Document from Template for ({{ model.project_code }})</h3>
    <div class="text-right">
    </div>
    <form class="needs-validation"
          @submit.prevent="submit">

      <div class="form-row">
        <b-col md="5">
          <base-input label="Project"
                      name="Project"
                      v-model="model.project_id">
            <select class="form-control" v-model="model.project_id"  disabled>
              <option Selected disabled value="x">-- Please Select A Project --</option>
              <option v-for="item in projects" :key="item.project_id" v-bind:value="item.project_id">{{ item.project_code }} - {{ item.project_name }}</option>
            </select>
          </base-input>
        </b-col>

        <b-col md="1">
          <br>
          <base-button size="sm" type="primary" @click="checkProject()">Project Infomation</base-button>
        </b-col>

        <b-col md="5">
          <base-input label="Group"
                      name="Group"
                      v-model="model.group_id">
            <select class="form-control" v-model="model.group_id" @change="getSiteFromGroup()">
              <option Selected disabled value="x">-- Please Select A Project To Show Group --</option>
              <option Selected disabled value="y">-- Please Select A Group --</option>
              <option v-for="item in project_groups" :key="item.group_id" v-bind:value="item.group_id">{{ item.group_code }} - {{ item.group_name }}</option>
            </select>
          </base-input>
        </b-col>

        <b-col md="1">
          <br>
          <base-button size="sm" type="primary" @click="checkGroup()">Project Group Infomation</base-button>
        </b-col>
      </div>

      <div class="form-row">
          <b-col md="6">
          <base-input label="Site"
                      name="Site"
                      v-model="model.site_id">
            <select class="form-control" v-model="model.site_id" >
              <option Selected disabled value="x">-- Please Select A Group To Show Site --</option>
              <option Selected disabled value="y">-- Please Select A Site --</option>
              <option v-for="item in project_sites" :key="item.site_id" v-bind:value="item.site_id">{{ item.site_code }} - {{ item.site_name }}</option>
            </select>
          </base-input>
        </b-col>

        <b-col md="1">
          <br>
          <base-button size="sm" type="primary" @click="checkSite()">Project Site Infomation</base-button>
        </b-col>

        <b-col md="1">
          <br>
          <base-button size="sm" type="primary" @click="linkSiteContractor()">Site Contractor Listing</base-button>
        </b-col>
      </div>

      <div class="form-row">
        <b-col md="5">
          <base-input label="Task Template"
                      name="Task Template"
                      v-model="model.task_template_id">
            <select class="form-control" v-model="model.task_template_id">
              <option Selected disabled value="x">-- Please Select A Task Template --</option>
              <option v-for="item in task_templates" :key="item.task_template_id" v-bind:value="item.task_template_id">{{ item.task_template_code }} - {{ item.task_template_name }} (No of Task : {{ item.task_details_no }})</option>
            </select>
          </base-input>
        </b-col>

        <b-col md="1">
          <br>
          <base-button size="sm" type="primary" @click="checkTask()">Task Template Details</base-button>
        </b-col>

        <b-col md="5">
           <base-input label="Document Template"
                      name="Document Template"
                      v-model="model.task_template_id">
            <select class="form-control" v-model="model.document_template_id">
              <option Selected disabled value="x">-- Please Select A Document Template --</option>
              <option v-for="item in document_templates" :key="item.document_template_id" v-bind:value="item.document_template_id">{{ item.document_template_code }} - {{ item.document_template_name }} (No of Task : {{ item.document_details_no }})</option>
            </select>
          </base-input>
        </b-col>

        <b-col md="1">
          <br>
          <base-button size="sm" type="primary" @click="checkDocument()">Document Template Details</base-button>
        </b-col>
      </div>


      <base-button type="primary" @click="createData()">Import Task & Document</base-button>
      <base-button type="primary" @click="$router.back()">Cancel</base-button>
      <div>
        <br>
        <h5 class="text-danger"> Note : <br><br>
                <i>
                1. At Least One project & One Group & One Site must be selected for the Import.
                </i>
                <br>
                <i>
                2. All Task & Document Import will directly go to the selected Site.
                </i>
                <br>
                <i>
                3. Milestone template will automatic import into Project that the site belongs to.
                </i>
                <br>
                <i>
                4. At least 1 Contractor must be assign to the Selected Site & 1st Contactor in the List will be assign to the Task & Document during Import.
                </i>
        </h5>
      </div>
    </form>
  </card>
</template>
<script>
  import BaseSlider from '@/components/BaseSlider'
  import {LoginURL, clientId, clientSecret, apiDomain, getHeader, getcurrentDate} from '@/assets/js/config.js'
  import axios from 'axios'

  export default {
    components: {
      BaseSlider
    },
    created()
    {
      var currentDate = getcurrentDate()

      this.model.project_id = this.$route.params.id

        axios.get(apiDomain + 'project/project/' + this.model.project_id , { headers: getHeader() })
        .then ( response => {
          if (response.status === 200)
          {
            this.model.project_code = response.data.data[0].project_code
          }
        })
        .catch ( error => {
          console.log(error.response.status)
          if ( error.response.status == 401)
          {
              this.$router.push('/login')
          }
        });

      this.getTaskList()
      this.getProject()
      this.getDocumentList()
      this.getGroupFromProject()
    },
    data() {
      return {
        model: {
          project_id: 'x',
          project_code: null,
          group_id: 'x',
          site_id: 'x',
          task_template_id: 'x',
          document_template_id: 'x',
        },
        task_templates: [],
        document_templates: [],
        projects: [],
        project_groups: [],
        project_sites: [],
      }
    },
    methods: {
      getProject()
      {
        axios.get(apiDomain + 'project/project', { headers: getHeader() })
        .then ( response => {
          if (response.status === 200)
          {
            this.projects = response.data.data
          }
        })
        .catch ( error => {
          console.log(error.response.status)
          if ( error.response.status == 401)
          {
              this.$router.push('/login')
          }
        });
      },
      getGroupFromProject()
      {
        axios.get(apiDomain + 'project/project_group.show_by_project/' + this.model.project_id , { headers: getHeader() })
        .then ( response => {
          if (response.status === 200)
          {
            this.project_groups = response.data.data
            this.model.group_id = 'y'
          }
        })
        .catch ( error => {
          console.log(error.response.status)
          if ( error.response.status == 401)
          {
              this.$router.push('/login')
          }
        } );

        this.project_sites = []
        this.model.site_id = 'x'
      },
      getSiteFromGroup()
      {
        axios.get(apiDomain + 'project/project_site.show_by_group/' + this.model.group_id , { headers: getHeader() })
        .then ( response => {
          if (response.status === 200)
          {
            this.project_sites = response.data.data
            this.model.site_id = 'y'
          }
        })
        .catch ( error => {
          console.log(error.response.status)
          if ( error.response.status == 401)
          {
              this.$router.push('/login')
          }
        } );
      },  
      checkProject()
      {
        if ( this.model.project_id == 'x' || this.model.project_id == 'y' )
        {
          this.$notify({   
            message:
              '<b>Invalid Input : Project </b> - Please select a Project before dive into the Details',
            timeout: 10000,
            icon: 'ni ni-bulb-61',
            type: 'danger',
          });
        }
        else
        {
          this.$router.push({path: '/projectEdit/'+this.model.project_id })
        }
      },
      checkGroup()
      {
        if ( this.model.group_id == 'x' || this.model.group_id == 'y' )
        {
          this.$notify({
            message:
              '<b>Invalid Input : Group </b> - Please select a Group before dive into the Details',
            timeout: 10000,
            icon: 'ni ni-bulb-61',
            type: 'danger',
          });
        }
        else
        {
          this.$router.push({path: '/projectGroupEdit/'+this.model.group_id })
        }
      },
      checkSite()
      {
        if ( this.model.site_id == 'x' || this.model.site_id == 'y' )
        {
          this.$notify({
            message:
              '<b>Invalid Input : Site </b> - Please select a Site before dive into the Details',
            timeout: 10000,
            icon: 'ni ni-bulb-61',
            type: 'danger',
          });
        }
        else
        {
          this.$router.push({path: '/projectSiteEdit/'+this.model.site_id })
        }
      },
      linkSiteContractor(index, row)
      {
          if ( this.model.site_id == 'x' || this.model.site_id == 'y' )
          {
            this.$notify({
              message:
                '<b>Invalid Input : Site Contractor</b> - Please select a Site before dive into the Contractor Details',
              timeout: 10000,
              icon: 'ni ni-bulb-61',
              type: 'danger',
            });
          }
          else
          {
            this.$router.push({path: '/siteContractorListing/' + this.model.site_id })
          }
      },
      checkTask()
      {
        if ( this.model.task_template_id == 'x' || this.model.task_template_id == 'y' )
        {
          this.$notify({
            message:
              '<b>Invalid Input : Task Template </b> - Please select a Template before dive into the Details',
            timeout: 10000,
            icon: 'ni ni-bulb-61',
            type: 'danger',
          });
        }
        else
        {
          this.$router.push({path: '/taskTemplateDetailListing/'+this.model.task_template_id })
        }
      },
      checkDocument()
      {
        if ( this.model.document_template_id == 'x' || this.model.document_template_id == 'y' )
        {
          this.$notify({
            message:
              '<b>Invalid Input : Document Template </b> - Please select a Template before dive into the Details',
            timeout: 10000,
            icon: 'ni ni-bulb-61',
            type: 'danger',
          });
        }
        else
        {
          this.$router.push({path: '/documentTemplateDetailListing/'+this.model.document_template_id })
        }
      },
      getTaskList()
      {
          axios.get(apiDomain + 'template/task_template', { headers: getHeader() })
          .then ( response => {
            if (response.status === 200)
            {

                this.task_templates = response.data.data
            }
          })
          .catch ( error => {
            console.log(error.response.status)
          } );
      },
      getDocumentList()
      {
          axios.get(apiDomain + 'template/document_template', { headers: getHeader() })
          .then ( response => {
            if (response.status === 200)
            {
                this.document_templates = response.data.data
            }
          })
          .catch ( error => {
            console.log(error.response.status)
          } );
      },
      createData(){
        if ( this.model.site_id == 'x' || this.model.site_id == 'y' )
        {
          this.$notify({
            message:
              '<b>Invalid Input : Site </b> - Destination of the Import Site are required column. Please select a Site.',
            timeout: 10000,
            icon: 'ni ni-bulb-61',
            type: 'danger',
          });
        }
        else if ( ((this.model.task_template_id == 'x' || this.model.task_template_id == 'y' ) || (this.model.document_template_id == 'x' || this.model.document_template_id == 'y' )) )
        {
          this.$notify({
            message:
              '<b>Invalid Input : Task & Document </b> - Both Task & Document are required as Import Source. Please Select A Task & Document.',
            timeout: 10000,
            icon: 'ni ni-bulb-61',
            type: 'danger',
          });
        }
        else
        {
          if ( this.model.task_template_id == 'x' || this.model.task_template_id == 'y')
          {
             this.model.task_template_id = null
          }

          if ( this.model.document_template_id == 'x' || this.model.document_template_id == 'y')
          {
             this.model.document_template_id = null
          }

          console.log(this.model)

          axios.post(apiDomain + 'setup/import', this.model, { headers: getHeader() })
          .then ( response => {
            if (response.status === 200)
            {
                this.$notify({
                message:
                  '<b>New Task & Document : </b> - Successfully Imported into Site (' + response.data.side_code + ')',
                timeout: 10000,
                icon: 'ni ni-bell-55',
                type: 'default',
                });

                this.$router.back()
            }
          })
          .catch ( error => {
              console.log(error.response.data.errors)

              if (error.response.data.errors.site_contractor != null)
              {
                  this.$notify({
                    message:
                      '<b>Invalid Input : Site Contractor </b> - Selected Site Does not have a Contractor Assign, Please assign A contractor before Import Task.',
                    timeout: 10000,
                    icon: 'ni ni-bulb-61',
                    type: 'danger',
                  });
              }
              else
              {
                  this.$notify({
                    message:
                      '<b>Invalid Input : </b> - Import Error. Please make Contact Admin / Support to help verified this Import Problem.',
                    timeout: 10000,
                    icon: 'ni ni-bulb-61',
                    type: 'danger',
                  });
              }

          });
        }
      },
    }
  }
</script>
<style>
</style>
