<template>
  <div class="content">
    <base-header class="pb-6">
      <b-row align-v="center" class="py-4">
        <b-col cols="7" lg="6">
          <h6 class="h2 text-white d-inline-block mb-0">Task Listing</h6>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <route-bread-crumb></route-bread-crumb>
          </nav>
        </b-col>
        <b-col lg="6" cols="5" class="text-right">
          <router-link to="/taskNew">
            <base-button
              size="sm"
              type="neutral"
              v-if="button_access.new_button == 'YES'"
              >New Task</base-button
            >
          </router-link>
          &nbsp;
          <base-button size="sm" type="neutral" @click="getCreateData()"
            >Refresh</base-button
          >
        </b-col>
      </b-row>
    </base-header>
    <b-container fluid class="mt--6">
      <div>
        <card
          class="no-border-card"
          body-classes="px-0 pb-1"
          footer-classes="pb-2"
        >
          <template slot="header">
            <h3 class="mb-0">Task Listing</h3>
            <!-- <div>
              user role: {{ user_role }}<br />user id: {{ user_id }}<br />is
              project manager: {{ projectManager }}<br/>enteredPMD: {{enteredPMD}}
            </div> -->
          </template>
          <div>
            <b-col
              cols="12"
              class="
                d-flex
                justify-content-center justify-content-sm-between
                flex-wrap
              "
            >
              <el-select
                class="select-primary pagination-select"
                v-model="pagination.perPage"
                placeholder="Per page"
              >
                <el-option
                  class="select-primary"
                  v-for="item in pagination.perPageOptions"
                  :key="item"
                  :label="item"
                  :value="item"
                >
                </el-option>
              </el-select>

              <div>
                <base-input
                  v-model="searchQuery"
                  prepend-icon="fas fa-search"
                  placeholder="Search..."
                >
                </base-input>
              </div>
            </b-col>
            <b-col><br /></b-col>
            <el-table
              :data="queriedData"
              row-key="project_id"
              header-row-class-name="thead-light"
              @sort-change="sortChange"
            >
              <!-- <el-table-column label="Code"
                             min-width="130px"
                             prop="task_code"
                             sortable>
                <template v-slot="{row}">
                    <b-media no-body class="align-items-center">
                        <b-media-body>
                            <span class="font-weight-600 name mb-0 text-sm">{{row.task_code}}</span>
                        </b-media-body>
                    </b-media>
                </template>
              </el-table-column> -->

              <el-table-column
                label="Title"
                min-width="190px"
                prop="task_title"
                sortable
              >
                <template v-slot="{ row }">
                  <b-media no-body class="align-items-center">
                    <b-media-body>
                      <span class="font-weight-600 name mb-0 text-sm">{{
                        row.task_title
                      }}</span>
                    </b-media-body>
                  </b-media>
                </template>
              </el-table-column>

              <el-table-column
                label="Site"
                min-width="180px"
                prop="site.site_name"
                sortable
              >
                <template v-slot="{ row }">
                  <b-media no-body class="align-items-center">
                    <b-media-body>
                      <span class="font-weight-600 name mb-0 text-sm"
                        >P: {{ row.project.project_name }}</span
                      >
                    </b-media-body>
                  </b-media>
                  <b-media no-body class="align-items-center">
                    <b-media-body>
                      <span class="font-weight-600 name mb-0 text-sm"
                        >G: {{ row.group.group_name }}</span
                      >
                    </b-media-body>
                  </b-media>
                  <b-media no-body class="align-items-center">
                    <b-media-body>
                      <span class="font-weight-600 name mb-0 text-sm"
                        >S: {{ row.site.site_name }}</span
                      >
                    </b-media-body>
                  </b-media>
                </template>
              </el-table-column>

              <el-table-column
                label="Contractor"
                min-width="140px"
                prop="contractor.contractor_code"
                sortable
              >
                <template v-slot="{ row }">
                  <b-media no-body class="align-items-center">
                    <b-media-body>
                      <span
                        class="font-weight-600 name mb-0 text-sm"
                        v-if="row.contractor.contractor_code !== null"
                        >{{ row.contractor.contractor_code }}</span
                      >
                      <span class="font-weight-600 name mb-0 text-sm" v-else
                        >N/A</span
                      >
                    </b-media-body>
                  </b-media>
                </template>
              </el-table-column>

              <el-table-column
                label="Assign To"
                min-width="140px"
                prop="assign_to_user"
                sortable
              >
                <template v-slot="{ row }">
                  <b-media no-body class="align-items-center">
                    <b-media-body>
                      <span class="font-weight-600 name mb-0 text-sm">{{
                        row.assign_to_user.name
                      }}</span>
                    </b-media-body>
                  </b-media>
                </template>
              </el-table-column>

              <el-table-column
                label="Milestone"
                min-width="120px"
                prop="milestone.milestone_code"
                sortable
              >
                <template v-slot="{ row }">
                  <b-media no-body class="align-items-center">
                    <b-media-body>
                      <span class="font-weight-600 name mb-0 text-sm">{{
                        row.milestone.milestone_code
                      }}</span>
                    </b-media-body>
                  </b-media>
                </template>
              </el-table-column>

              <el-table-column
                label="Progress"
                min-width="180px"
                prop="task_progress"
                sortable
              >
                <template v-slot="{ row }">
                  <div class="d-flex align-items-center">
                    <div>
                      <span class="font-weight-600 name mb-0 text-sm"
                        >{{ row.task_progress }}% &nbsp;
                      </span>
                    </div>
                    <div>
                      <base-progress
                        v-if="row.task_progress > 0 && row.task_progress < 50"
                        min-width="30px"
                        type="danger"
                        :value="row.task_progress"
                      />
                      <base-progress
                        v-else-if="
                          row.task_progress >= 50 && row.task_progress <= 90
                        "
                        min-width="30px"
                        type="info"
                        :value="row.task_progress"
                      />
                      <base-progress
                        v-else
                        min-width="30px"
                        type="success"
                        :value="row.task_progress"
                      />
                    </div>
                  </div>
                  <b-media no-body class="align-items-center">
                    <b-media-body>
                      <badge class="badge-dot mr-4" type="">
                        <i
                          v-if="row.status.status_code == 'Delayed'"
                          class="bg-danger"
                        ></i>
                        <i
                          v-else-if="row.status.status_code == 'On-Hold'"
                          class="bg-danger"
                        ></i>
                        <i
                          v-else-if="row.status.status_code == 'Reviewed'"
                          class="bg-success"
                        ></i>
                        <i
                          v-else-if="row.status.status_code == 'Completed'"
                          class="bg-success"
                        ></i>
                        <i v-else class="bg-info"></i>
                        <span class="font-weight-600 name mb-0 text-sm">{{
                          row.status.status_code
                        }}</span>
                      </badge>
                    </b-media-body>
                  </b-media>
                </template>
              </el-table-column>

              <el-table-column min-width="110px" align="right" label="Actions">
                <div slot-scope="{ $index, row }" class="d-flex">
                  <base-button
                    v-if="button_access.show_button == 'YES'"
                    @click.native="handleDetails($index, row)"
                    size="sm"
                    type="primary"
                  >
                    View / Edit
                  </base-button>
                </div>
              </el-table-column>
            </el-table>
          </div>
          <div
            slot="footer"
            class="
              col-12
              d-flex
              justify-content-center justify-content-sm-between
              flex-wrap
            "
          >
            <div class="">
              <p class="card-category">
                Showing {{ from + 1 }} to {{ to }} of {{ total }} entries

                <span v-if="selectedRows.length">
                  &nbsp; &nbsp; {{ selectedRows.length }} rows selected
                </span>
              </p>
            </div>
            <base-pagination
              class="pagination-no-border"
              :current="pagination.currentPage"
              :per-page="pagination.perPage"
              :total="total"
              @change="paginationChanged($event)"
            >
            </base-pagination>
            <!-- <div>
              <h5 class="text-danger"> Note :
                  <i>
                  Please Create at least ONE Project Template & ONE Project Template Details before you can proceed POPULATE / IMPORT to prevent any Error. Go to NEW Template Then "Detail Listing" >> "New Template Details".
                  </i>
              </h5>
            </div> -->
          </div>
        </card>
      </div>
    </b-container>
  </div>
</template>
<script>
import { Table, TableColumn, Select, Option } from "element-ui";
import RouteBreadCrumb from "@/components/Breadcrumb/RouteBreadcrumb";
import { BasePagination } from "@/components";
import standardPaginationMix from "@/assets/js/standardPaginationMix.js";
import Fuse from "fuse.js";
import axios from "axios";
import {
  LoginURL,
  clientId,
  clientSecret,
  apiDomain,
  getHeader,
} from "@/assets/js/config.js";
import ErrorFunctionMixin from "@/assets/js/errorFunctionMix.js";

export default {
  mixins: [standardPaginationMix, ErrorFunctionMixin],
  components: {
    BasePagination,
    RouteBreadCrumb,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
  },
  created() {
    // var site_task_access = JSON.parse(window.localStorage.getItem('site_task_access'))
    // this.button_access.new_button = site_task_access.access_create
    // this.button_access.show_button = site_task_access.access_show

    var userInfo = JSON.parse(window.localStorage.getItem("loginUser"));
    this.user_role = userInfo.role_id;
    this.user_id = userInfo.id;

    axios
      .get(apiDomain + "user_access/role_access.task_access", {
        headers: getHeader(),
      })
      .then((response) => {
        if (response.status === 200) {
          this.button_access.new_button =
            response.data.data.project_modules[0].access_create;
          this.button_access.show_button =
            response.data.data.project_modules[0].access_show;
        }
      })
      .catch((error) => {
        console.log(error.response.status);
        this.errorFunction(error, "Task Information");
      });

    this.getIfRole();
    this.getSomeData();

    // this.getCreateData();
  },
  data() {
    return {
      user_role: null,
      user_id: null,
      propsToSearch: [
        "task_code",
        "task_title",
        "project.project_name",
        "group.group_name",
        "site.site_name",
        "assign_to_user.name",
        "contractor.contractor_code",
        "milestone.milestone_code",
        "task_progress",
        "status.status_code",
      ],
      tableColumns: [],
      tableData: [],
      selectedRows: [],
      button_access: {
        new_button: null,
        show_button: null,
      },
      indexToBeDeleted: [],
      // projectManager: null,
      enteredPMD: null,
    };
  },
  methods: {
    getIfRole() {
      if (this.user_role == 9) {
        // this.projectManager = true;
        this.getProjectManagerData();
      } else if (this.user_role == 12) {
        this.getContractorData();
      } else {
        // this.projectManager = false;
        this.getCreateData();
      }
    },
    getProjectManagerData() {
      axios
        .get(apiDomain + "task/task.show_by_project_manager/" + this.user_id, {
          headers: getHeader(),
        })
        .then((response) => {
          if (response.status === 200) {
            this.tableData = response.data.data;
            this.fuseData();
            this.deleteApprovedForAll();
            this.enteredPMD = true;
          }
        })
        .catch((error) => {
          console.log(error.response.status);
          if (error.response.status == 401) {
            this.$router.push("/show_by_project_manager");
          }
        });
    },
    getContractorData() {
      axios
        .get(apiDomain + "task/task.show_by_contractor/" + this.user_id, {
          headers: getHeader(),
        })
        .then((response) => {
          if (response.status === 200) {
            this.tableData = response.data.data;
            this.fuseData();
            this.deleteApprovedForAll();
            this.enteredPMD = true;
          }
        })
        .catch((error) => {
          console.log(error.response.status);
          if (error.response.status == 401) {
            this.$router.push("/show_by_contractor");
          }
        });
    },
    getCreateData() {
      axios
        .get(apiDomain + "task/task.show_by_user/" + this.user_id, {
          headers: getHeader(),
        })
        .then((response) => {
          if (response.status === 200) {
            this.tableData = response.data.data;
            this.fuseData();
            this.deleteApprovedForAll();
            // this.deleteApprovedIfContractor();
            this.enteredPMD = false;
          }
        })
        .catch((error) => {
          console.log(error.response.status);
          if (error.response.status == 401) {
            this.$router.push("/login");
          }
        });
    },
    fuseData() {
      this.fuseSearch = new Fuse(this.tableData, {
        keys: this.propsToSearch,
        threshold: 0.3,
      });
    },
    paginationChanged(page) {
      this.pagination.currentPage = page;
    },
    handleDetails(index, row) {
      if (this.user_role == 12) {
        let route = this.$router.resolve({
          path: "taskEditContractor/" + row.task_id,
        });
        window.open(route.href, "_blank");
      } else {
        let route = this.$router.resolve({ path: "taskEdit/" + row.task_id });
        window.open(route.href, "_blank");
      }
    },
    selectionChange(selectedRows) {
      this.selectedRows = selectedRows;
    },
    deleteApprovedForAll() {
      this.getIndexToBeDeleted();
    },
    // deleteApprovedIfContractor() {
    //   if (this.user_role == 12) {
    //     this.getIndexToBeDeleted();
    //   }
    // },
    getIndexToBeDeleted() {
      this.tableData.forEach((item) => {
        if (item.status.status_id == 4) {
          this.indexToBeDeleted.push(this.tableData.indexOf(item));
        }
      });
      this.indexToBeDeleted.reverse();
      this.deleteStatusApproved();
    },
    deleteStatusApproved() {
      this.indexToBeDeleted.forEach((item) => {
        this.tableData.splice(item, 1);
      });
    },
  },
};
</script>
<style>
.no-border-card .card-footer {
  border-top: 0;
}
</style>
