<template>
<div>
  <card>
    <!-- Card header -->
    <h3 slot="header" class="mb-0">Edit Site for ({{ model.project_name }} - {{ model.group_name }}) - General</h3>
    <div class="text-right">
    </div>
    <form class="needs-validation"
          @submit.prevent="submit">

      <div class="form-row">
        <b-col md="5">
          <base-input label="Project"
                      name="Project"
                      v-model="model.project_id">
            <select class="form-control" v-model="model.project_id" @change="getGroupFromProject()" disabled>
              <option Selected disabled value="x">-- Please Select A Project --</option>
              <option v-for="item in projects" :key="item.project_id" v-bind:value="item.project_id">{{ item.project_name }}</option>
            </select>
          </base-input>
        </b-col>

        <b-col md="1">
          <br>
          <base-button size="sm" type="primary" @click="checkProject()">Project Infomation</base-button>
        </b-col>

        <b-col md="5">
          <base-input label="Group"
                      name="Group"
                      v-model="model.group_id">
            <select class="form-control" v-model="model.group_id" @change="getGroupProjectEngineer()" disabled>
              <option Selected disabled value="x">-- Please Select A Project To Show Group --</option>
              <option Selected disabled value="y">-- Please Select A Group --</option>
              <option v-for="item in project_groups" :key="item.group_id" v-bind:value="item.group_id">{{ item.group_name }}</option>
            </select>
          </base-input>
        </b-col>

        <b-col md="1">
          <br>
          <base-button size="sm" type="primary" @click="checkGroup()">Group Infomation</base-button>
        </b-col>
      </div>

      <div class="form-row">
        <b-col md="12">
          <base-input label="Site Name"
                      name="Site Name"
                      placeholder="Site Name"
                      v-model="model.site_name">
          </base-input>
        </b-col>
      </div>

      <div class="form-row">
        <b-col md="6">
          <!-- <base-input label="Project Manager (P.I.C)"
                      name="Project Manager (P.I.C)"
                      v-model="model.site_leader" readonly>
          </base-input> -->
          <base-input label="Project Engineer (P.I.C)"
                      name="Project Engineer (P.I.C)"
                      v-model="model.site_leader" readonly>
          </base-input>
        </b-col>

        <b-col md="6">
          <!-- <base-input label="Target Turn-On Date"
                      name="Target Turn-On Date"
                      type="date"
                      v-model="model.site_target_turn_on_date">
          </base-input> -->
        </b-col>
      </div>

      <div class="form-row">
        <b-col md="6">
          <base-input label="Target Turn-On Date"
                      name="Target Turn-On Date"
                      type="date"
                      v-model="model.site_target_turn_on_date">
          </base-input>
        </b-col>

        <b-col md="6">
          <base-input label="Actual Turn-On Date"
                      name="Actual Turn-On Date"
                      type="date"
                      v-model="model.site_actual_turn_on_date"
                      v-if="model.status_id != 5 && model.status_id != 7"               
                      disabled>
          </base-input>
          <base-input label="Actual Turn-On Date"
                      name="Actual Turn-On Date"
                      type="date"
                      v-model="model.site_actual_turn_on_date"
                      v-else>
          </base-input>
        </b-col>
      </div>

      <div class="form-row">
        <b-col md="5">
          <base-input label="Site Postal Code"
                      name="Site Postal Code"
                      placeholder="Site Postal Code"
                      v-model="model.site_postal_code">
          </base-input>
        </b-col>

         <b-col md="7">
          <base-input label="Site Address"
                      name="Site Address"
                      placeholder="Site Address"
                      v-model="model.site_address">
          </base-input>
        </b-col>
      </div>

      <div class="form-row">
        <b-col md="6">
          <base-input label="Site Status"
                      name="Site Status"
                      v-model="model.status_id">
            <div v-if="model.status_id == '7'">
              <select class="form-control" v-model="model.status_id" disabled>
                <option Selected disabled value="x">-- Please Select Site Status --</option>
                <option v-for="item in site_status_approved" :key="item.status_id" v-bind:value="item.status_id">{{ item.status_code }}</option>
              </select>
            </div>
            <div v-else-if="model.status_id == '5'">
              <select class="form-control" v-model="model.status_id">
                <option Selected disabled value="x">-- Please Select Site Status --</option>
                <option v-for="item in site_status_completed_approved" :key="item.status_id" v-bind:value="item.status_id">{{ item.status_code }}</option>
              </select>
            </div>
            <div v-else>
              <select class="form-control" v-model="model.status_id">
                <option Selected disabled value="x">-- Please Select Site Status --</option>
                <option v-for="item in site_status" :key="item.status_id" v-bind:value="item.status_id">{{ item.status_code }}</option>
              </select>
            </div>

          </base-input>
        </b-col>

        <b-col md="6">
          <base-input label="Site Progress % "
                      name="Site Progress"
                      placeholder="Site Progress"
                      v-model="model.site_progress" type="number" min="0" max="100">
          </base-input>
        </b-col>
      </div>

      <div class="form-row">
        <b-col md="6">
          <base-input label="EPC Site Start Date"
                      name="EPC Site Start Date"
                      type="date"
                      v-model="model.site_start_date">
          </base-input>
        </b-col>

        <b-col md="6">
          <base-input label="EPC Site End Date"
                      name="EPC Site End Date"
                      type="date"
                      v-model="model.site_end_date">
          </base-input>
        </b-col>
      </div>

      <div class="form-row">
        <b-col md="6">
          <base-input label="Client Agreed Site Start Date"
                      name="Client Agreed Site Start Date"
                      type="date"
                      v-model="model.site_contractor_start_date">
          </base-input>
        </b-col>

        <b-col md="6">
          <base-input label="Client Agreed Site End Date"
                      name="Client Agreed Site End Date"
                      type="date"
                      v-model="model.site_contractor_end_date">
          </base-input>
        </b-col>
      </div>
     
    </form>
  </card>

  <card>
    <!-- Card header -->
    <h3 slot="header" class="mb-0">Edit Site for ({{ model.project_name }} - {{ model.group_name }}) - Site Details</h3>
    <div class="text-right">
    </div>
    <form class="needs-validation"
          @submit.prevent="submit">

      <div class="form-row">
        <b-col md="6">
          <base-input label="EL License Number"
                      name="EL License Number"
                      placeholder="EL License Number"
                      v-model="model.site_elu_license_number">
          </base-input>
        </b-col>

        <b-col md="6">
          <base-input label="Site Tension Level"
                      name="Site Tension Level"
                      placeholder="Site Tension Level"
                      v-model="model.site_type_of_tension">
          </base-input>
        </b-col>

        <b-col md="6">
          <base-input label="Site Approved Load"
                      name="Site Approved Load"
                      placeholder="Site Approved Load"
                      v-model="model.site_approved_load">
          </base-input>
        </b-col>

        <b-col md="6">
          <base-input label="Site MSSL Acc Number"
                      name="Site MSSL Acc Number"
                      placeholder="Site MSSL Acc Number"
                      v-model="model.site_mssl_account_number">
          </base-input>
        </b-col>
      </div>

    </form>
  </card>

  <!-- <div>
    Temp Debug:
    <div>model.site_module_quantity: {{model.site_module_quantity}}</div>
    <div>model.site_module_capacity: {{model.site_module_capacity}}</div>
    <div>model.site_total_capacity: {{model.site_total_capacity}}</div>
    <div>site_module_quantity_old: {{site_module_quantity_old}}</div>
    <div>site_module_capacity_old: {{site_module_capacity_old}}</div>
    <div>site_total_capacity_old: {{site_total_capacity_old}}</div>
    <div>project_total_capacity: {{project_total_capacity}}</div>
    <div>project_ppa_approved_capacity: {{project_ppa_approved_capacity}}</div>
    <div>new_project_total_capacity: {{new_project_total_capacity}}</div>
    <div>capacity_exceeded_approved: {{capacity_exceeded_approved}}</div>
  </div> -->

  <card>
    <!-- Card header -->
    <h3 slot="header" class="mb-0">Edit Site for ({{ model.project_name }} - {{ model.group_name }}) - PV System Details</h3>
    <div class="text-right">
    </div>
    <form class="needs-validation"
          @submit.prevent="submit">

      <div class="form-row">
        <b-col md="3">
          <base-input label="Site Module Quantity (Pcs)"
                      name="Site Module Quantity (Pcs)"
                      placeholder="Site Module Quantity (Pcs)"
                      v-model="model.site_module_quantity" @input="calculateSiteTotalCapacity()">
          </base-input>
        </b-col>

        <b-col md="3">
          <base-input label="Site Module Watt Class (Wp)"
                      name="Site Module Watt Class (Wp)"
                      placeholder="Site Module Watt Class (Wp)"
                      v-model="model.site_module_capacity" @input="calculateSiteTotalCapacity()">
          </base-input>
        </b-col>

        <b-col md="3">
          <base-input label="Site Total Capacity (KWp)"
                      name="Site Total Capacity (KWp)"
                      placeholder="Site Total Capacity (KWp)"
                      v-model="model.site_total_capacity" readonly>
          </base-input>
        </b-col>

        <b-col md="3">
          <base-input label="Site Performance Ratio (%)"
                      name=" Site Performance Ratio (%)"
                      placeholder="Site Performance Ratio (%)"
                      v-model="model.site_pr_capacity">
          </base-input>
        </b-col>
      </div>

      <div class="form-row">
        <b-col md="3">
          <base-input
            label="Module Brand Name"
            name="Module Brand Name"
            v-model="model.site_module_brand_id"
          >
            <select class="form-control" v-model="model.site_module_brand_id">
              <option Selected disabled value="x">
                -- Please Select A Module Brand --
              </option>
              <option
                v-for="item in module_brands"
                :key="item.module_brand_id"
                v-bind:value="item.module_brand_id"
              >
                {{ item.module_brand_id_name }}
              </option>
            </select>
          </base-input>
        </b-col>

        <b-col md="3">
          <base-input label="Site Monitoring Application"
                      name="Site Monitoring Application"
                      placeholder="Site Monitoring Application"
                      v-model="model.site_monitoring_application">
          </base-input>
        </b-col>

        <b-col md="3">
          <base-input label="Site Inverter Typ & Pcs"
                      name="Site Inverter Type & Pcs"
                      placeholder="Site Inverter Type & Pcs"
                      v-model="model.site_inverter_type">
          </base-input>
        </b-col>

        <b-col md="3">
          <base-input label="Site Logger Type/Model"
                      name="Site Logger Type/Model"
                      placeholder="Site Logger Type/Model"
                      v-model="model.site_logger_type">
          </base-input>
        </b-col>
      </div>

      <!-- <div class="form-row">
        <b-col>
          <h5 v-if="capacity_exceeded_approved" class="text-danger">
            Warning:<br><br>
            By updating this site, Project PV Capacity (kWp) will exceed PPA Approved Capacity(kWp).<br>
            PPA Approved Capacity(kWp): {{project_ppa_approved_capacity}}<br>
            New Project PV Capacity (kWp): {{new_project_total_capacity}}
          </h5>
        </b-col>
      </div> -->

      <div class="form-row" v-if="showImportSiteTaskTemplate">
        <b-col md="5" v-if="task_drop_list == 'EMPTY'">
          <base-input label="Import From Site Task Template"
                      name="Task Template"
                      v-model="model.task_template_id">
            <select class="form-control" v-model="model.task_template_id">
              <option Selected disabled value="x">-- Please Select A Site Task Template --</option>
              <option v-for="item in task_templates" :key="item.task_template_id" v-bind:value="item.task_template_id">{{ item.task_template_name }} (No of Task : {{ item.task_details_no }})</option>
            </select>
          </base-input>
        </b-col>
        <b-col md="5" v-else>
          <base-input label="Import From Site Task Template"
                      name="Task Template"
                      v-model="model.task_template_id">
            <select class="form-control" v-model="model.task_template_id" disabled>
              <option Selected disabled value="x">-- Please Select A Site Task Template --</option>
              <option v-for="item in task_templates" :key="item.task_template_id" v-bind:value="item.task_template_id">{{ item.task_template_name }} (No of Task : {{ item.task_details_no }})</option>
            </select>
          </base-input>
        </b-col>

        <b-col md="1">
          <br>
          <base-button size="sm" type="primary" @click="checkTask()">Site Task Template</base-button>
        </b-col>

        <!-- <b-col md="5" v-if="document_drop_list == 'EMPTY'">
           <base-input label="Import From Site Document Template"
                      name="Document Template"
                      v-model="model.document_template_id">
            <select class="form-control" v-model="model.document_template_id">
              <option Selected disabled value="x">-- Please Select A Site Document Template --</option>
              <option v-for="item in document_templates" :key="item.document_template_id" v-bind:value="item.document_template_id">{{ item.document_template_name }} (No of Task : {{ item.document_details_no }})</option>
            </select>
          </base-input>
        </b-col>
        <b-col md="5" v-else>
           <base-input label="Import From Site Document Template"
                      name="Document Template"
                      v-model="model.document_template_id">
            <select class="form-control" v-model="model.document_template_id" disabled>
              <option Selected disabled value="x">-- Please Select A Site Document Template --</option>
              <option v-for="item in document_templates" :key="item.document_template_id" v-bind:value="item.document_template_id">{{ item.document_template_name }} (No of Task : {{ item.document_details_no }})</option>
            </select>
          </base-input>
        </b-col>

        <b-col md="1">
          <br>
          <base-button size="sm" type="primary" @click="checkDocument()">Site Doc. Template</base-button>
        </b-col> -->
      </div>

      <base-button type="primary" @click="createData()">Update / Save</base-button>
      <base-button type="primary" @click="$router.back()">Cancel</base-button>
      <div>
        <br>
        <h5 class="text-danger"> Note : <br><br>
                <i>
                1. By Default all Date is set to "TODAY, and Default date format will me DD/MM/YYYY.
                </i>
                <br>
                <i>
                2. Project Site Progress % is set to "0 %" By Default.
                </i>
                <br>
                <i>
                3. No of Task / Document could be different from the actual. (Owner of Template could make CHANGES anytime, Number show current No. of Task / Document).
                </i>
        </h5>
      </div>
    </form>
  </card>

  <!-- <modal
      :show.sync="isProceedUpdateVisible"
      size="xl"
      body-classes="p-0"
      title="Confirm Update Site"
    >
      <card
        type="secondary"
        header-classes="bg-transparent pb-5"
        body-classes="px-lg-5 py-lg-5"
        class="border-0 mb-0"
      >
        <template>
          <div class="text-center text-muted mb-4">
            <h1>Confirm Update Site</h1>
          </div>
          <div class="text-center">
            <div>
              <br>
              By updating this site, Project PV Capacity (kWp) will exceed PPA Approved Capacity(kWp).<br>
              PPA Approved Capacity(kWp): {{project_ppa_approved_capacity}}<br>
              New Project PV Capacity (kWp): {{new_project_total_capacity}}<br><br>
              PMOC document will be raised to increase PPA Approved Capacity
            </div>
            <div>
              <base-button type="danger" class="my-4" @click="putData()"
                >Confirm</base-button
              >
              <base-button
                type="primary"
                class="my-4"
                @click="closeProceedUpdateModal()"
                >Cancel</base-button
              >
            </div>
          </div>
        </template>
      </card>
    </modal> -->
</div>
</template>
<script>
  import BaseSlider from '@/components/BaseSlider'
  import {LoginURL, clientId, clientSecret, apiDomain, getHeader, getcurrentDate} from '@/assets/js/config.js'
  import axios from 'axios'
  import ErrorFunctionMixin from '@/assets/js/errorFunctionMix.js'

  export default {
    mixins: [ErrorFunctionMixin],
    components: {
      BaseSlider
    },
    created()
    {
      this.calculateSiteTotalCapacity()
      var currentDate = getcurrentDate()

      this.model.site_start_date = currentDate
      this.model.site_end_date = currentDate
      this.model.site_contractor_start_date = currentDate
      this.model.site_contractor_end_date = currentDate
      this.model.site_elu_end_date = currentDate
      this.model.site_el_expiry_date = currentDate

      axios.get(apiDomain + 'project/project', { headers: getHeader() })
      .then ( response => {
        if (response.status === 200)
        {
          this.projects = response.data.data
        }
      })
      .catch ( error => {
        console.log(error.response.status)
        this.errorFunction(error, "Project Information")
      } );

      axios.get(apiDomain + 'lookup/site_status', { headers: getHeader() })
      .then ( response => {
        if (response.status === 200)
        {
          this.site_status = response.data.data
          this.site_status_completed_approved = this.site_status.filter(function (el)
          {
            return (el.status_id == 5 || el.status_id == 7);
          });
          this.site_status_approved = this.site_status.filter(function (el)
          {
            return (el.status_id == 7);
          });
        }
      })
      .catch ( error => {
        console.log(error.response.status)
        this.errorFunction(error, "Site Status Information")
      } );

      axios.get(apiDomain + 'lookup/user_list/Project_Lead', { headers: getHeader() })
      .then ( response => {
        if (response.status === 200)
        {
          this.site_leads = response.data.data
        }
      })
      .catch ( error => {
        console.log(error.response.status)
        this.errorFunction(error, "Project User Information")
      } );

      this.model.site_id = this.$route.params.id

      axios.get(apiDomain + 'project/project_site/' + this.model.site_id, { headers: getHeader() })
      .then ( response => {
        if (response.status === 200)
        {
          this.model.site_id            = response.data.data[0].site_id
          this.model.site_name          = response.data.data[0].site_name
          this.model.project_id         = response.data.data[0].project.project_id
          this.model.project_name       = response.data.data[0].project.project_name
          this.model.group_id           = response.data.data[0].group.group_id
          this.model.group_name         = response.data.data[0].group.group_name
          this.model.site_leader        = response.data.data[0].group.group_engineer.name
          this.model.site_postal_code       = response.data.data[0].site_postal_code
          this.model.site_address       = response.data.data[0].site_address
          this.model.status_id          = response.data.data[0].status.status_id
          this.model.site_progress      = response.data.data[0].site_progress
          this.model.site_elu_license_number    = response.data.data[0].site_elu_license_number
          this.model.site_elu_status            = response.data.data[0].site_elu_status
          this.model.site_module_quantity       = response.data.data[0].site_module_quantity
          this.model.site_module_capacity       = response.data.data[0].site_module_capacity
          this.model.site_total_capacity        = response.data.data[0].site_total_capacity
          // this.site_module_quantity_old       = response.data.data[0].site_module_quantity
          // this.site_module_capacity_old       = response.data.data[0].site_module_capacity
          // this.site_total_capacity_old        = response.data.data[0].site_total_capacity
          this.model.site_pr_capacity           = response.data.data[0].site_pr_capacity
          this.model.site_type_of_tension       = response.data.data[0].site_type_of_tension
          this.model.site_approved_load         = response.data.data[0].site_approved_load
          this.model.site_mssl_account_number   = response.data.data[0].site_mssl_account_number

          this.model.site_plant_name              = response.data.data[0].site_plant_name
          this.model.site_folder_in_sftp_server   = response.data.data[0].site_folder_in_sftp_server
          this.model.site_monitoring_application  = response.data.data[0].site_monitoring_application
          this.model.site_inverter_type           = response.data.data[0].site_inverter_type
          this.model.site_inverter_qty            = response.data.data[0].site_inverter_qty

          this.model.site_total_inverter          = response.data.data[0].site_total_inverter
          this.model.site_total_data_logger       = response.data.data[0].site_total_data_logger
          this.model.site_logger_type             = response.data.data[0].site_logger_type

          this.model.site_target_turn_on_date     = response.data.data[0].site_target_turn_on_date
          this.model.site_actual_turn_on_date     = response.data.data[0].site_actual_turn_on_date
          
          this.model.site_start_date              = response.data.data[0].site_start_date
          this.model.site_end_date                = response.data.data[0].site_end_date
          this.model.site_contractor_start_date   = response.data.data[0].site_contractor_start_date
          this.model.site_contractor_end_date     = response.data.data[0].site_contractor_end_date
          this.model.site_elu_end_date            = response.data.data[0].site_elu_end_date
          this.model.site_el_expiry_date          = response.data.data[0].site_el_expiry_date

          this.model.site_module_brand_id         = response.data.data[0].site_module_brand.module_brand_id
          this.model.site_module_brand.module_brand_id_name          = response.data.data[0].site_module_brand.module_brand_id_name

          if ( response.data.data[0].document_template_id == null )
          {
            this.model.document_template_id = 'x'
            this.document_drop_list = 'EMPTY'
          }
          else
          {
            this.model.document_template_id = response.data.data[0].document_template_id
            this.document_drop_list = 'NO'
          }

          if ( response.data.data[0].task_template_id == null )
          {
            this.model.task_template_id = 'x'
            this.task_drop_list = 'EMPTY'
          }
          else
          {
             this.model.task_template_id = response.data.data[0].task_template_id
             this.task_drop_list = 'NO'
          }

          axios.get(apiDomain + 'project/project_group.show_by_project/' + this.model.project_id , { headers: getHeader() })
          .then ( response => {
            if (response.status === 200)
            {
              this.project_groups = response.data.data
            }
          })
          .catch ( error => {
            console.log(error.response.status)
            this.errorFunction(error, "Project Group Information")
          } );
          this.getContractorFromSite()
          this.getProjectTaskList()
          this.getProjectDocumentList()
          this.getModuleBrandList()
          // this.getProjectData()
        }
      })
      .catch ( error => {
        console.log(error.response.status)
      } );

    },
    data() {
      return {
        model: {
          site_id: null,
          project_id: 'x',
          project_name: null,
          group_id: 'x',
          group_name: null,
          site_name: null,
          site_leader: '-- Please Select A Project & Group --',
          site_postal_code: null,
          site_address: null,
          site_progress: 1,
          milestone_id: 'x',
          site_module_quantity: 1,
          site_module_capacity: 1,
          site_total_capacity: 1,
          site_pr_capacity: 1,
          site_type_of_tension: 'None',
          site_approved_load: 1,
          site_mssl_account_number: null,
          site_plant_name: null,
          site_folder_in_sftp_server: null,
          site_monitoring_application: 'Fusion Solar',
          site_inverter_type: null,
          site_inverter_qty: 1,
          site_total_inverter: 1,
          site_total_data_logger: 1,
          site_logger_type: 'Huawei',
          site_start_date: null,
          site_end_date: null,
          site_contractor_start_date: null,
          site_contractor_end_date: null,
          site_target_turn_on_date: null,
          site_actual_turn_on_date: null,
          site_elu_end_date: null,
          site_el_expiry_date: null,
          site_elu_license_number: null,
          site_elu_status: null,
          status_id: 1,
          task_template_id: 'x',
          document_template_id: 'x',
          site_module_brand_id: 'x',
          site_module_brand: {
            module_brand_id_name: null,
          },
        },
        module_brands: [],
        projects: [],
        project_groups: [],
        site_status: [],
        site_status_completed_approved: [],
        site_status_approved: [],
        site_leads: [],
        project_milestones: [],
        task_templates: [],
        document_templates: [],
        task_drop_list: 'EMPTY',
        document_drop_list: 'EMPTY',
        site_contractors: [],
        showImportSiteTaskTemplate: false,
        // project_total_capacity: 0.000,
        // project_ppa_approved_capacity: 0.000,
        // new_project_total_capacity: 0,
        // capacity_exceeded_approved: false,        
        // site_module_quantity_old: 0,
        // site_module_capacity_old: 0.000,
        // site_total_capacity_old: 0.000,
        // isProceedUpdateVisible: false,
      }
    },
    methods: {
      // showProceedUpdateModal() {
      //   this.isProceedUpdateVisible = true;
      // },
      // closeProceedUpdateModal() {
      //   this.isProceedUpdateVisible = false;
      // },
      // getProjectData() {
      //   axios.get(apiDomain + "project/project/" + this.model.project_id, { headers: getHeader(),})
      //   .then((response) => {
      //     if (response.status === 200) {
      //       this.project_total_capacity = response.data.data[0].project_total_capacity;
      //       this.project_ppa_approved_capacity = response.data.data[0].project_ppa_approved_capacity;
      //       this.calculateSiteTotalCapacity();
      //     }
      //   })
      //   .catch((error) => {
      //     console.log(error.response.status);
      //   });
      // },
      getModuleBrandList(){
        axios.get(apiDomain + "setup/module_brand", { headers: getHeader() })
        .then((response) => {
          if (response.status === 200) {
            this.module_brands = response.data.data;
          }
        })
        .catch((error) => {
          console.log(error.response.status);
          this.errorFunction(error, "Module Brand Information");
        });
      },
      getProjectTaskList()
      {
          axios.get(apiDomain + 'template/task_template', { headers: getHeader() })
          .then ( response => {
            if (response.status === 200)
            {

                this.task_templates = response.data.data
            }
          })
          .catch ( error => {
            console.log(error.response.status)
            this.errorFunction(error, "Site Task Template")
          } );
      },
      getProjectDocumentList()
      {
          axios.get(apiDomain + 'template/document_template', { headers: getHeader() })
          .then ( response => {
            if (response.status === 200)
            {
                this.document_templates = response.data.data
            }
          })
          .catch ( error => {
            console.log(error.response.status)
            this.errorFunction(error, "Site Document Template")
          } );
      },
      checkTask()
      {
        if ( this.model.task_template_id == 'x' || this.model.task_template_id == 'y' )
        {
          this.redErrorNotify('<b>Invalid Input : Task Template </b> - Please select a Template before dive into the Details')
        }
        else
        {
          this.$router.push({path: '/templates/taskTemplateDetailListing/'+this.model.task_template_id })
        }
      },
      checkDocument()
      {
        if ( this.model.document_template_id == 'x' || this.model.document_template_id == 'y' )
        {
          this.redErrorNotify('<b>Invalid Input : Document Template </b> - Please select a Template before dive into the Details')
        }
        else
        {
          this.$router.push({path: '/templates/documentTemplateDetailListing/'+this.model.document_template_id })
        }
      },
      calculateSiteTotalCapacity()
      {
          this.model.site_total_capacity = ((this.model.site_module_quantity * this.model.site_module_capacity) / 1000);

          // // check if adding new site_total_capacity to project_total_capacity will exceed project_ppa_approved_capacity
          // this.new_project_total_capacity = parseFloat(this.model.site_total_capacity) + parseFloat(this.project_total_capacity) - parseFloat(this.site_total_capacity_old);
          // // this.new_project_total_capacity = Number(this.model.site_total_capacity) + Number(this.project_total_capacity) - Number(this.site_total_capacity_old);
          // if (this.new_project_total_capacity > this.project_ppa_approved_capacity) {
          //   this.capacity_exceeded_approved = true;
          // } else {
          //   this.capacity_exceeded_approved = false;
          // }
          // this.new_project_total_capacity = this.new_project_total_capacity.toFixed(4);
      },
      getGroupFromProject()
      {
        axios.get(apiDomain + 'project/project_group.show_by_project/' + this.model.project_id , { headers: getHeader() })
        .then ( response => {
          if (response.status === 200)
          {
            this.project_groups = response.data.data
            this.model.group_id = 'y'
          }
        })
        .catch ( error => {
          console.log(error.response.status)
        } );

        axios.get(apiDomain + 'project/project_milestone.show_by_project/' + this.model.project_id , { headers: getHeader() })
        .then ( response => {
          if (response.status === 200)
          {
            this.project_milestones = response.data.data
            this.model.milestone_id = 'y'
          }
        })
        .catch ( error => {
          console.log(error.response.status)
        } );
      },
      checkProject()
      {
        if (this.model.project_id == 'x')
        {
          this.$notify({
            message:
              '<b>Invalid Input : Project </b> - Please select a Project before dive into the Details',
            timeout: 10000,
            icon: 'ni ni-bulb-61',
            type: 'danger',
          });
        }
        else
        {
          this.$router.push({path: '/projectEdit/'+this.model.project_id })
        }
      },
      checkGroup()
      {
        if (this.model.group_id == 'x' || this.model.group_id == 'y')
        {
          this.$notify({
            message:
              '<b>Invalid Input : Project Group </b> - Please select a Project Group before dive into the Details',
            timeout: 10000,
            icon: 'ni ni-bulb-61',
            type: 'danger',
          });
        }
        else
        {
          this.$router.push({path: '/projectGroupEdit/'+this.model.group_id })
        }
      },
      checkMilestone()
      {
        if (this.model.milestone_id == 'x' || this.model.milestone_id == 'y')
        {
          this.$notify({
            message:
              '<b>Invalid Input : Project Milestone </b> - Please select a Project Milestone before dive into the Details',
            timeout: 10000,
            icon: 'ni ni-bulb-61',
            type: 'danger',
          });
        }
        else
        {
          this.$router.push({path: '/projectMilestoneEdit/'+this.model.milestone_id })
        }
      },
      // putData() {
      //   axios.put(apiDomain + 'project/project_site/' + this.model.site_id, this.model, { headers: getHeader() })
      //   .then ( response => {
      //     if (response.status === 200)
      //     {
      //         this.$notify({
      //         message:
      //           '<b>Update Project Site : ' + this.model.site_name + '</b> - Successfully Created.',
      //         timeout: 10000,
      //         icon: 'ni ni-bell-55',
      //         type: 'default',
      //         });
      //         this.$router.back()
      //     }
      //   })
      //   .catch ( error => {
      //       console.log(error.response.data.errors)
      //       this.errorFunction(error, "Update Project Site Information")
      //   });
      // },
      createData(){
        if (
        !this.model.site_name || this.model.project_id == 'x' || this.model.status_id == 'x' || this.model.group_id == 'x' || this.model.group_id == 'y' ||
        !this.model.site_postal_code || !this.model.site_address || !this.model.site_module_quantity || !this.model.site_pr_capacity ||
        !this.model.site_module_quantity || !this.model.site_module_capacity || !this.model.site_total_capacity ||
        !this.model.site_type_of_tension || !this.model.site_approved_load || !this.model.site_mssl_account_number ||
        !this.model.site_monitoring_application || !this.model.site_logger_type || !this.model.site_elu_license_number || !this.model.site_inverter_type ||
        !this.model.site_module_brand_id == 'x'
        )
        {
          if (this.model.project_id == 'x' || this.model.group_id == 'x' || this.model.group_id == 'y')
          {
            this.redErrorNotify( '<b>Invalid Input : Project & Group Column is a Mandatory in Project Site </b>')
          }
          else if (!this.model.site_name)
          {
            this.redErrorNotify( '<b>Invalid Input : Site Name Column is a Mandatory in Project Site </b>' )
          }
          else if (!this.model.site_postal_code)
          {
            this.redErrorNotify( '<b>Invalid Input : Site Postal Column is a Mandatory in Project Site </b>' )
          }
          else if (!this.model.site_address)
          {
            this.redErrorNotify( '<b>Invalid Input : Site Address Column is a Mandatory in Project Site </b>' )
          }
          else if (!this.model.site_elu_license_number)
          {
            this.redErrorNotify( '<b>Invalid Input : EL License Column is a Mandatory in Project Site </b>' )
          }
         
          else if (!this.model.site_type_of_tension)
          {
            this.redErrorNotify( '<b>Invalid Input : Tension Level Column is a Mandatory in Project Site </b>' )
          }
          else if (!this.model.site_mssl_account_number)
          {
            this.redErrorNotify( '<b>Invalid Input : MSSL Account Number Column is a Mandatory in Project Site </b>' )
          }
         
          else if (!this.model.site_monitoring_application)
          {
            this.redErrorNotify( '<b>Invalid Input : Site Monitoring Application Column is a Mandatory in Project Site </b>' )
          }
          else if (!this.model.site_logger_type)
          {
            this.redErrorNotify( '<b>Invalid Input : Site Logger Type / Model Column is a Mandatory in Project Site </b>' )
          }
          else if (!this.model.site_pr_capacity)
          {
            this.redErrorNotify( '<b>Invalid Input : Site PR Capacity Column is a Mandatory in Project Site </b>' )
          }
          else if (!this.model.site_inverter_type)
          {
            this.redErrorNotify( '<b>Invalid Input : Inverter Type Column is a Mandatory in Project Site </b>' )
          }
          else if (!this.model.site_inverter_qty)
          {
            this.redErrorNotify( '<b>Invalid Input : Inverter Quantitu Column is a Mandatory in Project Site </b>' )
          }
          else if (this.model.site_module_brand_id == 'x')
          {
            this.redErrorNotify( '<b>Invalid Input : Module Brand Name is a Mandatory in Project Site </b>' )
          }
          else
          {
            this.redErrorNotify( '<b>Invalid Input : All Column is a Mandatory in Project Site </b>' )
          }

        }
        else if( this.model.site_progress < 0 || this.model.site_progress > 100 )
        {
          this.redErrorNotify( '<b>Invalid Input : Project Site Progress </b> - Input Must be between 0 - 100' )
        }
        else
        {
          if ( this.model.task_template_id == 'x' )
          {
            this.model.task_template_id = null
          }

          if ( this.model.document_template_id == 'x' )
          {
            this.model.document_template_id = null
          }

          // if (this.capacity_exceeded_approved) {
          //   this.showProceedUpdateModal();
          // } else {
          //   this.putData();
          // }

          axios.put(apiDomain + 'project/project_site/' + this.model.site_id, this.model, { headers: getHeader() })
          .then ( response => {
            if (response.status === 200)
            {
                this.$notify({
                message:
                  '<b>Update Project Site : ' + this.model.site_name + '</b> - Successfully Created.',
                timeout: 10000,
                icon: 'ni ni-bell-55',
                type: 'default',
                });
                this.$router.back()
            }
          })
          .catch ( error => {
              console.log(error.response.data.errors)
              this.errorFunction(error, "Update Project Site Information")
          });
        }
      },
      getContractorFromSite()
      {
        axios.get(apiDomain + 'project/site_contractor.show_by_site/' + this.model.site_id , { headers: getHeader() })
        .then ( response => {
          if (response.status === 200)
          {
            this.site_contractors = response.data.data
            let counterActive = 0;
            if (Object.keys(this.site_contractors).length > 0) {
              this.site_contractors.forEach((e) => {
                  if (e['active_status'] == 'ACTIVE') {
                    counterActive++;
                  }
              });              
            }
            if (counterActive > 0) {
              this.showImportSiteTaskTemplate = true;
            }
          }
        })
        .catch ( error => {
          console.log(error.response.status)
          this.errorFunction(error, "Site Contractor Information")
        } );
      },
    }
  }
</script>
<style>
</style>
