<template>
    <div>
        <h1>Experiment Upload Photos</h1>
        <div class="form-row">
            <b-col md="12">
                <label>
                    <input type="file" id="file" ref="file" v-on:change="handleFileUpload()"/>
                </label>
            </b-col>
        </div>
        <base-button type="primary" @click="createData()">Upload New File</base-button>
        <div>model: {{model}}</div>
        <div>model_new: {{model_new}}</div>
        <div>document_name: {{document_name}}</div>
        <div>uploaded_response: {{uploaded_response}}</div>
        <base-button
            size="sm"
            type="primary"
            @click.native="downloadFile()"
            >
            Download
        </base-button>
        <div>
            <div
            class="imagePreviewWrapper"
            :style="{ 'background-image': `url(${previewImage})` }"
            @click="selectImage">
            </div>
        
            <input
            ref="fileInput"
            type="file"
            @input="pickFile">
        </div>
    </div>  
</template>

<script>
import {LoginURL, clientId, clientSecret, apiDomain, getHeader, getcurrentDate, fileServer,} from '@/assets/js/config.js'
import axios from 'axios'

export default {
    data() {
        return {
            model: {
                type: null,
                site_document_recurring_id: null,
                document_id: null,
                document_title: null,
                document_status: null,
                document_category_id: null,
            },
            model_new: {
                document_id: null,
                site_document_recurring_id: null,
                document_recurring_date: null,
                document_version: null,
                document_name: null,
                document_file: '',
                status_id: 'x',
            },
            document_name: {
                project_code: null,
                postal_code: null,
                revision_status: 'x',
                drawing_type: 'x',
                revision_text: 'REV',
                revision_index: null,
            },
            uploaded_response: null,
            experiment_upload_photo_id: null,
            document_file: null,
            previewImage: null
        };
    },
    methods: {
        selectImage () {
          this.$refs.fileInput.click()
        },
        pickFile () {
            let input = this.$refs.fileInput
            let file = input.files
            if (file && file[0]) {
            let reader = new FileReader
            reader.onload = e => {
                this.previewImage = e.target.result
            }
            reader.readAsDataURL(file[0])
            this.$emit('input', file[0])
            }
        },
        handleFileUpload() {
            this.model_new.document_file = this.$refs.file.files[0];
        },
        createData() {
            const formData = new FormData();
            this.model_new.document_id = this.model.document_id;
            this.model_new.document_name = this.document_name.project_code+'-'+this.document_name.postal_code+'-'+this.document_name.revision_status
                                        +'-'+this.document_name.drawing_type+'-'+this.document_name.revision_text+this.document_name.revision_index;
            formData.append('document_file', this.model_new.document_file);
            formData.append('site_document_id', this.model_new.document_id);
            formData.append('document_version', this.model_new.document_version);
            formData.append('document_name', this.model_new.document_name);
            formData.append('status_id', this.model_new.status_id);

            const tokenData = JSON.parse(window.localStorage.getItem('authUser'))
            const headers = { 'Accept' : 'application/json', 'Content-Type': 'multipart/form-data',   'Authorization' : 'Bearer ' + tokenData.access_token, };

            axios.post(apiDomain + 'document/experiment_upload_photo', formData, { headers })
            .then ( response => {
                if (response.status === 200)
                {
                    this.$notify({
                        message:
                        '<b>New File Upload : ' + this.model.document_title + '</b> - Successfully Created.',
                        timeout: 10000,
                        icon: 'ni ni-bell-55',
                        type: 'default',
                    });
                    this.uploaded_response = response.data.data;
                    this.experiment_upload_photo_id = response.data.data[0].experiment_upload_photo_id;
                    this.document_file = response.data.data[0].document_file;
                }
                else {
                this.$notify({
                    message:
                    '<b>Failed to upload new photo</b>',
                    timeout: 10000,
                    icon: 'ni ni-bulb-61',
                    type: 'danger',
                });
                }
            })
            .catch ( error => {
                this.errorFunction(error, "Experiment Upload Photos")
            });
        },
        downloadFile() {
        // console.log(row.site_document_approval_id)
        axios
            .get(
            fileServer +
                "document/experiment_upload_photo.download/" +
                this.experiment_upload_photo_id,
            { headers: getHeader(), responseType: "blob" }
            )
            .then((response) => {
            var fileURL = window.URL.createObjectURL(new Blob([response.data]));
            var fileLink = document.createElement("a");

            fileLink.href = fileURL;
            fileLink.setAttribute("download", this.document_file);
            document.body.appendChild(fileLink);

            fileLink.click();

            var image = new Image();
            image.src = fileURL;
            document.body.appendChild(image);
            });
        },
    },
}
</script>

<style>
.imagePreviewWrapper {
    width: 250px;
    height: 250px;
    display: block;
    cursor: pointer;
    margin: 0 auto 30px;
    background-size: cover;
    background-position: center center;
}
</style>