<template>
    <div>
        <base-header class="pb-6">
            <b-row class="py-4">
                <b-col lg="12" cols="7" >
                    <h6 class="h2 text-white d-inline-block mb-0">{{ title }}</h6>
                </b-col>
                <b-col lg="12" cols="5" class="text-right">
                    <base-button size="sm" type="neutral" @click="$router.back()">Back</base-button>
                </b-col>
            </b-row>
        </base-header>

        <b-container fluid class="mt--6">
            <b-row>
                <b-col>
                    <div class="card-wrapper">
                        <EditForm/>
                    </div>
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>

<script>
    import BaseHeader from '@/components/BaseHeader';
    import EditForm from './ProjectSiteUtilitiesDashboardDataEditForms'

    export default {
        components: {
            BaseHeader,
            EditForm,
        },

        data() {
            return {
                title: 'Edit Data Manipulation',
            }
        },
    };
</script>
