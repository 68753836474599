<template>
  <card>
    <h3 slot="header" v-if="myUserID.role_id == 3 || myUserID.role_id == 2 || myUserID.role_id == 1" class="mb-0">Edit Milestone Template Detail for ( {{ milestone_template_name }} )</h3>
    <h3 slot="header" v-else class="mb-0">View Milestone Template Detail for ( {{ milestone_template_name }} )</h3>
    <div class="text-right">
    </div>
    <form class="needs-validation"
          @submit.prevent="submit">
      <div class="form-row">
        <b-col md="6">
          <base-input label="Milestone Title"
                      name="Milestone Title"
                      placeholder="Milestone Title"
                      v-if="myUserID.role_id == 3 || myUserID.role_id == 2 || myUserID.role_id == 1"
                      v-model="model.milestone_template_title">
          </base-input>
          <base-input label="Milestone Title"
                      name="Milestone Title"
                      placeholder="Milestone Title"
                      v-else
                      v-model="model.milestone_template_title" disabled>
          </base-input>
        </b-col>

        <b-col md="3">
          <base-input label="Weightage (in %)"
                      name="Weightage"
                      placeholder="Weightage (%)"
                      v-if="myUserID.role_id == 3 || myUserID.role_id == 2 || myUserID.role_id == 1"
                      v-model="model.weightage" type="number">
          </base-input>
          <base-input label="Weightage (in %)"
                      name="Weightage"
                      placeholder="Weightage (%)"
                      v-else
                      v-model="model.weightage" type="number" disabled>
          </base-input>
        </b-col>

        <b-col md="3">
          <base-input label="Milestone Sequence"
                      name="Milestone Sequence"
                      placeholder="Milestone Sequence"
                      v-if="myUserID.role_id == 3 || myUserID.role_id == 2 || myUserID.role_id == 1"
                      v-model="model.milestone_template_sequence" type="number">
          </base-input>
          <base-input label="Milestone Sequence"
                      name="Milestone Sequence"
                      placeholder="Milestone Sequence"
                      v-else
                      v-model="model.milestone_template_sequence" type="number" disabled>
          </base-input>
        </b-col>
      </div>

      <div class="form-row">
        <b-col md="4">
          <b-form-checkbox
            id="Active-Status"
            v-if="myUserID.role_id == 3 || myUserID.role_id == 2 || myUserID.role_id == 1"
            v-model="model.active_status"
            name="Active-Status"
            value="1"
            unchecked-value="0">
            <b>Active Status</b>
          </b-form-checkbox>
          <b-form-checkbox
            id="Active-Status"
            v-model="model.active_status"
            v-else
            name="Active-Status"
            value="1"
            unchecked-value="0" disabled>
            <b>Active Status</b>
          </b-form-checkbox>
        </b-col>
      </div>
      <div>
        <br>
      </div>

      <base-button v-if="myUserID.role_id == 3 || myUserID.role_id == 2 || myUserID.role_id == 1" type="primary" @click="createData()">Update / Save</base-button>
      <base-button type="primary" @click="$router.back()">Cancel</base-button>
    </form>
  </card>
</template>
<script>
  import BaseSlider from '@/components/BaseSlider'
  import {LoginURL, clientId, clientSecret, apiDomain, getHeader} from '@/assets/js/config.js'
  import axios from 'axios'
  import ErrorFunctionMixin from '@/assets/js/errorFunctionMix.js'

  export default {
    mixins: [ErrorFunctionMixin],
    components: {
      BaseSlider
    },
    created()
    {
      const LoginUser = JSON.parse(window.localStorage.getItem('loginUser'))
      this.myUserID = LoginUser

      this.model.milestone_template_detail_id = this.$route.params.id
      axios.get(apiDomain + 'template/milestone_template_details/'+this.model.milestone_template_detail_id, { headers: getHeader() })
      .then ( response => {
        if (response.status === 200)
        {
          this.model.milestone_template_detail_code = response.data.data[0].milestone_template_detail_code
          this.model.milestone_template_id = response.data.data[0].milestone_template.milestone_template_id
          this.milestone_template_name = response.data.data[0].milestone_template.milestone_template_name
          this.model.milestone_template_title = response.data.data[0].milestone_template_title
          this.model.milestone_template_sequence = response.data.data[0].milestone_template_sequence
          this.model.weightage = response.data.data[0].weightage
          if ( response.data.data[0].active_status == 'ACTIVE' )
          {
            this.model.active_status = 1
          }
          else
          {
            this.model.active_status = 0
          }
          axios.get(apiDomain + 'template/milestone_template/'+this.model.milestone_template_id, { headers: getHeader() })
          .then ( response => {
            if (response.status === 200)
            {
              this.milestone_template_name = response.data.data[0].milestone_template_name
            }
          })
          .catch ( error => {
            console.log(error.response.status)
            this.errorFunction(error, 'Edit Milestone Template')
          });
        }
      })
      .catch ( error => {
          console.log(error.response.status)
          this.errorFunction(error, 'Edit Milestone Template Details')
      });
    },
    data() {
      return {
        model: {
          milestone_template_detail_id: null,
          milestone_template_id: null,
          milestone_template_detail_code: null,
          milestone_template_title: null,
          milestone_template_sequence: 1,
          weightage: 0,
          active_status: 1,
        },
        milestone_template_name: null,
        myUserID: null,
      }
    },
    methods: {
      createData(){
        if ( !this.model.milestone_template_title )
        {
          this.$notify({
            message:
              '<b>Invalid Input : All Column are Mandatory in Milestone Template Details</b> - These field are required.',
            timeout: 10000,
            icon: 'ni ni-bulb-61',
            type: 'warning',
          });
        }
        else
        {
          axios.put(apiDomain + 'template/milestone_template_details/'+ this.model.milestone_template_detail_id, this.model, { headers: getHeader() })
          .then ( response => {
            if (response.status === 200)
            {
              this.$notify({
              message:
                '<b>Update Milestone Template Details : ' + this.model.milestone_template_title + '</b> - Successfully Updated.',
              timeout: 10000,
              icon: 'ni ni-bell-55',
              type: 'default',
              });
              this.$router.back()
            }
          })
          .catch ( error => {
              console.log(error.response.data.errors)
              this.errorFunction(error, "Update Milestone Template Details")
          });
        }
      },
    }
  }
</script>
<style>
</style>
