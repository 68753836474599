<template>
  <div class="content">
    <base-header class="pb-6">
      <b-row align-v="center" class="py-4">
        <b-col cols="7" lg="6">
          <h6 class="h2 text-white d-inline-block mb-0">Project Group Listing</h6>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <route-bread-crumb></route-bread-crumb>
          </nav>
        </b-col>
        <b-col lg="6" cols="5" class="text-right">
          <!-- <router-link to="/ProjectGroupNew">
            <base-button size="sm" type="neutral"
            v-if="button_access.new_button == 'YES'">New Project Group</base-button>
          </router-link> -->
        </b-col>
      </b-row>
    </base-header>
    <b-container fluid class="mt--6">
      <div>
        <card class="no-border-card" body-classes="px-0 pb-1" footer-classes="pb-2">
          <template slot="header">
            <h3 class="mb-0">Project Group Listing</h3>
          </template>
          <div>
            <b-col cols="12" class="d-flex justify-content-center justify-content-sm-between flex-wrap">
              <el-select
                class="select-primary pagination-select"
                v-model="pagination.perPage"
                placeholder="Per page">
                <el-option
                  class="select-primary"
                  v-for="item in pagination.perPageOptions"
                  :key="item"
                  :label="item"
                  :value="item">
                </el-option>
              </el-select>

              <div>
                <base-input v-model="searchQuery"
                            prepend-icon="fas fa-search"
                            placeholder="Search...">
                </base-input>
              </div>
            </b-col>
            <b-col><br></b-col>
            <el-table :data="queriedData"
                      row-key="group_id"
                      header-row-class-name="thead-light"
                      @sort-change="sortChange">

              <!-- <el-table-column label="Code"
                             min-width="150px"
                             prop="group_code"
                             sortable>
                <template v-slot="{row}">
                    <b-media no-body class="align-items-center">
                        <b-media-body>
                            <span class="font-weight-600 name mb-0 text-sm">{{row.group_code}}</span>
                        </b-media-body>
                    </b-media>
                </template>
              </el-table-column> -->

              <el-table-column label="Group Name"
                             min-width="200px"
                             prop="group_name"
                             sortable>
                <template v-slot="{row}">
                    <b-media no-body class="align-items-center">
                        <b-media-body>
                            <span class="font-weight-600 name mb-0 text-sm">{{row.group_name}}</span>
                        </b-media-body>
                    </b-media>
                </template>
              </el-table-column>

              <el-table-column label="Project"
                             min-width="200px"
                             prop="project.project_name"
                             sortable>
                <template v-slot="{row}">
                    <b-media no-body class="align-items-center">
                        <b-media-body>
                            <span class="font-weight-600 name mb-0 text-sm">{{row.project.project_name}}</span>
                        </b-media-body>
                    </b-media>
                </template>
              </el-table-column>

              <el-table-column label="Project Engineer"
                             min-width="140px"
                             prop="group_engineer"
                             sortable>
                <template v-slot="{row}">
                    <b-media no-body class="align-items-center">
                        <b-media-body>
                            <span class="font-weight-600 name mb-0 text-sm">{{row.group_engineer.name}}</span>
                        </b-media-body>
                    </b-media>
                </template>
              </el-table-column>

              <el-table-column label="Progress"
                           min-width="210px"
                             prop="group_progress"
                             sortable>
                <template v-slot="{row}">
                    <div class="d-flex align-items-center">
                        <div>
                          <span class="font-weight-600 name mb-0 text-sm">{{row.group_progress}}% &nbsp; </span>
                        </div>
                        <div>
                            <base-progress v-if="row.group_progress > 0 && row.group_progress < 50" min-width="30px" type="danger" :value="row.group_progress"/>
                            <base-progress v-else-if="row.group_progress >= 50 && row.group_progress <= 90" min-width="30px" type="info" :value="row.group_progress"/>
                            <base-progress v-else min-width="30px" type="success" :value="row.group_progress"/>
                        </div>
                    </div>
                    <b-media no-body class="align-items-center">
                        <b-media-body>
                            <badge class="badge-dot mr-4" type="">
                              <i v-if="row.status.status_code == 'Delayed'" class="bg-danger"></i>
                              <i v-else-if="row.status.status_code == 'On-Hold'" class="bg-danger"></i>
                              <i v-else-if="row.status.status_code == 'Reviewed'" class="bg-success"></i>
                              <i v-else-if="row.status.status_code == 'Completed'" class="bg-success"></i>
                              <i v-else class="bg-info"></i>
                              <span class="font-weight-600 name mb-0 text-sm">{{row.status.status_code}}</span>
                            </badge>
                        </b-media-body>
                    </b-media>
                </template>
              </el-table-column>

              <el-table-column min-width="110px" align="right" label="Actions">
                <div slot-scope="{$index, row}" class="d-flex">
                  <base-button v-if="button_access.view_button == 'YES'"
                    @click.native="handleDetails($index, row)" size="sm" type="primary">
                    Dashboard
                  </base-button>
                </div>
              </el-table-column>
            </el-table>
          </div>
          <div
            slot="footer"
            class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap">
            <div class="">
              <p class="card-category">
                Showing {{ from + 1 }} to {{ to }} of {{ total }} entries
                <span v-if="selectedRows.length">
                  &nbsp; &nbsp; {{selectedRows.length}} rows selected
                </span>
              </p>
            </div>
            <base-pagination
              class="pagination-no-border"
              :current="pagination.currentPage"
              :per-page="pagination.perPage"
              :total="total"
              @change="paginationChanged($event)">
            </base-pagination>
             <!-- <div>
              <h5 class="text-danger"> Note :
                  <i>
                  Please Create at least ONE Group Template & ONE Group Template Details before you can proceed POPULATE / IMPORT to prevent any Error. Go to NEW Template Then "Detail Listing" >> "New Template Details".
                  </i>
              </h5>
            </div> -->
          </div>
        </card>
      </div>
    </b-container>
    </div>
</template>
<script>
import { Table, TableColumn, Select, Option } from 'element-ui';
import RouteBreadCrumb from '@/components/Breadcrumb/RouteBreadcrumb'
import { BasePagination } from '@/components';
import standardPaginationMix from '@/assets/js/standardPaginationMix.js'
import Fuse from 'fuse.js';
import axios from 'axios'
import {LoginURL, clientId, clientSecret, apiDomain, getHeader} from '@/assets/js/config.js'

export default {
  mixins: [standardPaginationMix],
  components: {
    BasePagination,
    RouteBreadCrumb,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn
  },
  created()
  {
      var project_group_access = JSON.parse(window.localStorage.getItem('project_group_access'))
      this.button_access.new_button = project_group_access.access_create
      this.button_access.view_button = project_group_access.access_show

      this.getStartUpData()
  },
  data() {
    return {
      propsToSearch:
      [
        'group_name',
        'project.project_name',
        'group_engineer.name',
        'group_progress',
        'status.status_code',
      ],
      tableColumns: [],
      tableData: [],
      selectedRows: [],
      button_access: {
          new_button: null,
          view_button: null,
        },
    };
  },
  methods: {
    getStartUpData()
    {
        axios.get(apiDomain + 'project/project_group', { headers: getHeader() })
        .then ( response => {
        if (response.status === 200)
        {
          this.tableData = response.data.data
          this.fuseData()
        }
        })
        .catch ( error => {
            console.log(error.response.status)
        });
    },
    fuseData()
    {
        this.fuseSearch = new Fuse(this.tableData, {
        keys: this.propsToSearch,
        threshold: 0.3
        });
    },
    paginationChanged(page)
    {
        this.pagination.currentPage = page
    },
    handleDetails(index, row)
    {
        this.$router.push({path: '/projectGroupDashboard/'+row.group_id })
    },
    selectionChange(selectedRows)
    {
        this.selectedRows = selectedRows
    }
  }
};
</script>
<style>
  .no-border-card .card-footer{
    border-top: 0;
  }
</style>
