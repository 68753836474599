const dataProposeLayout = [
  {
    desc: "latestSolarLayout",
    coord: [
      { lat: -2.848169488720637, lng: 122.0654772530268 },
      { lat: -2.848215187220286, lng: 122.0660529979641 },
      { lat: -2.848532291167152, lng: 122.0666645108885 },
      { lat: -2.848785656395743, lng: 122.0667632694392 },
      { lat: -2.84920209646118, lng: 122.0671677811494 },
      { lat: -2.849256657239397, lng: 122.0675186047232 },
      { lat: -2.849501664227875, lng: 122.0684630511906 },
      { lat: -2.849855418444923, lng: 122.0696503194908 },
      { lat: -2.850118515765421, lng: 122.0705947529152 },
      { lat: -2.850788502634911, lng: 122.0713229418596 },
      { lat: -2.851594680510669, lng: 122.0726717902309 },
      { lat: -2.852401024726885, lng: 122.0742275634955 },
      { lat: -2.852763471573962, lng: 122.0749829920535 },
      { lat: -2.853063206670876, lng: 122.0764941902569 },
      { lat: -2.853597626173464, lng: 122.077375436494 },
      { lat: -2.853896902322587, lng: 122.0783198476932 },
      { lat: -2.854251025003768, lng: 122.0799749530765 },
      { lat: -2.853971555792523, lng: 122.08116273612 },
      { lat: -2.854126303767583, lng: 122.0823681679177 },
      { lat: -2.853593646831204, lng: 122.0836821050657 },
      { lat: -2.852843813824907, lng: 122.0849062463587 },
      { lat: -2.851695505646283, lng: 122.0855639107567 },
      { lat: -2.851361796779514, lng: 122.0868057142696 },
      { lat: -2.850331298655814, lng: 122.0877331828773 },
      { lat: -2.849680635580319, lng: 122.0885433944743 },
      { lat: -2.849256413086953, lng: 122.089722290293 },
      { lat: -2.847908432346259, lng: 122.0895524125612 },
      { lat: -2.846587110924229, lng: 122.0887797391459 },
      { lat: -2.846406070696119, lng: 122.0886269385785 },
      { lat: -2.846405706160875, lng: 122.0881681105535 },
      { lat: -2.847572631934618, lng: 122.0881671931004 },
      { lat: -2.847173809334724, lng: 122.0871598832827 },
      { lat: -2.846504252754229, lng: 122.0869624843698 },
      { lat: -2.846503923354207, lng: 122.0865486396397 },
      { lat: -2.847544205548563, lng: 122.0865478202926 },
      { lat: -2.847543969061398, lng: 122.0862509314453 },
      { lat: -2.848050369141779, lng: 122.0860346129799 },
      { lat: -2.848294322371536, lng: 122.0856745549781 },
      { lat: -2.848737572925593, lng: 122.0856742053477 },
      { lat: -2.849153326798314, lng: 122.085224044982 },
      { lat: -2.849153032377526, lng: 122.0848551827094 },
      { lat: -2.849035435319963, lng: 122.0848552755705 },
      { lat: -2.848583246649556, lng: 122.0849905822337 },
      { lat: -2.848266639171162, lng: 122.0849908321485 },
      { lat: -2.847217067474229, lng: 122.0846857749791 },
      { lat: -2.846041096903966, lng: 122.0846867029564 },
      { lat: -2.846040759557638, lng: 122.0842638620409 },
      { lat: -2.846592130105543, lng: 122.0837236293553 },
      { lat: -2.846863126922402, lng: 122.0832465941619 },
      { lat: -2.846863026265459, lng: 122.0831206415052 },
      { lat: -2.84620267384993, lng: 122.0831211634735 },
      { lat: -2.846200225648684, lng: 122.0800623163553 },
      { lat: -2.844951889260131, lng: 122.0800633060475 },
      { lat: -2.843801102374707, lng: 122.0776261423093 },
      { lat: -2.840571178166797, lng: 122.0769629612707 },
      { lat: -2.840567441948882, lng: 122.072320742574 },
      { lat: -2.840721185897047, lng: 122.0722756371276 },
      { lat: -2.841552441946784, lng: 122.0710784319175 },
      { lat: -2.842039646446621, lng: 122.0695036454646 },
      { lat: -2.842418910508429, lng: 122.0686846552897 },
      { lat: -2.843159176501017, lng: 122.0668397677128 },
      { lat: -2.844236111557603, lng: 122.0674236787939 },
      { lat: -2.846778004789836, lng: 122.0674216359132 },
      { lat: -2.847463873585872, lng: 122.0654328388458 },
      { lat: -2.848169452059829, lng: 122.0654322701108 },
      { lat: -2.848169474056525, lng: 122.0654592598603 },
    ],
  },
];

export default dataProposeLayout;
