<template>
  <div>
    <base-header class="pb-6">
      <b-row class="py-4">
        <b-col lg="8" cols="7" >
          <h6 class="h2 text-white d-inline-block mb-0">Project Document Recurring Listing</h6>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <route-bread-crumb></route-bread-crumb>
          </nav>
        </b-col>
         <b-col lg="4" cols="5" class="text-right">

          <base-button size="sm" type="neutral" @click="closeWindow()">Back / Close</base-button>
        </b-col>
        <!-- <b-col lg="6" cols="12" class="text-right">
          <router-link to="/projectNew">
            <base-button size="sm" type="neutral">Create New Project</base-button>
          </router-link>
          &nbsp;
          <base-button size="sm" type="neutral">Filters</base-button>
        </b-col> -->
      </b-row>
    </base-header>

    <b-container fluid class="mt--6">
      <b-row>
        <b-col>
          <document-view-form/>
        </b-col>
      </b-row>

      <b-row>
        <b-col>
          <recurring-document-table/>
        </b-col>
      </b-row>
    </b-container>

  </div>
</template>

<script>

  import RouteBreadCrumb from '@/components/Breadcrumb/RouteBreadcrumb';
  import BaseHeader from '@/components/BaseHeader';
  import RecurringDocumentTable from './RecurringDocumentTable';
  import DocumentViewForm from './ProjectDocumentViewForm.vue';

  export default {
    components: {
      BaseHeader,
      RouteBreadCrumb,
      "recurring-document-table" : RecurringDocumentTable,
      "document-view-form": DocumentViewForm,
    },
    data() {
        return {

      };
    },
    methods:
    {
      closeWindow()
      {
        close()
      },
    },
  };
</script>
<style></style>
