var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"content"},[_c('base-header',{staticClass:"pb-6"},[_c('b-row',{staticClass:"py-4",attrs:{"align-v":"center"}},[_c('b-col',{attrs:{"cols":"7","lg":"6"}},[_c('h6',{staticClass:"h2 text-white d-inline-block mb-0"},[_vm._v(" Project Site Listing ")]),_c('nav',{staticClass:"d-none d-md-inline-block ml-md-4",attrs:{"aria-label":"breadcrumb"}},[_c('route-bread-crumb')],1)]),_c('b-col',{staticClass:"text-right",attrs:{"lg":"6","cols":"5"}})],1)],1),_c('b-container',{staticClass:"mt--6",attrs:{"fluid":""}},[_c('div',[_c('card',{staticClass:"no-border-card",attrs:{"body-classes":"px-0 pb-1","footer-classes":"pb-2"}},[_c('template',{slot:"header"},[_c('h3',{staticClass:"mb-0"},[_vm._v("Project Site Listing")])]),_c('div',[_c('b-col',{staticClass:"\n              d-flex\n              justify-content-center justify-content-sm-between\n              flex-wrap\n            ",attrs:{"cols":"12"}},[_c('el-select',{staticClass:"select-primary pagination-select",attrs:{"placeholder":"Per page"},model:{value:(_vm.pagination.perPage),callback:function ($$v) {_vm.$set(_vm.pagination, "perPage", $$v)},expression:"pagination.perPage"}},_vm._l((_vm.pagination.perPageOptions),function(item){return _c('el-option',{key:item,staticClass:"select-primary",attrs:{"label":item,"value":item}})}),1),_c('div',[_c('base-input',{attrs:{"prepend-icon":"fas fa-search","placeholder":"Search..."},model:{value:(_vm.searchQuery),callback:function ($$v) {_vm.searchQuery=$$v},expression:"searchQuery"}})],1)],1),_c('b-col',[_c('br')]),_c('el-table',{attrs:{"data":_vm.queriedData,"row-key":"site_id","header-row-class-name":"thead-light"},on:{"sort-change":_vm.sortChange}},[_c('el-table-column',{attrs:{"label":"Site Name","min-width":"200px","prop":"site_code","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',[_c('b-media',{staticClass:"align-items-center",attrs:{"no-body":""}},[_c('b-media-body',[_c('span',{staticClass:"font-weight-600 name mb-0 text-sm"},[_vm._v(_vm._s(row.site_name))])])],1)],1)]}}])}),_c('el-table-column',{attrs:{"label":"Project","min-width":"140px","prop":"project.project_code","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('b-media',{staticClass:"align-items-center",attrs:{"no-body":""}},[_c('b-media-body',[_c('span',{staticClass:"font-weight-600 name mb-0 text-sm"},[_vm._v(_vm._s(row.project.project_name))])])],1)]}}])}),(!_vm.isSolarNovaProject)?_c('el-table-column',{attrs:{"label":"Group","min-width":"140px","prop":"group.group_code","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('b-media',{staticClass:"align-items-center",attrs:{"no-body":""}},[_c('b-media-body',[_c('span',{staticClass:"font-weight-600 name mb-0 text-sm"},[_vm._v(_vm._s(row.group.group_name))])])],1)]}}],null,false,3455134266)}):_c('el-table-column',{attrs:{"label":"Town Council","min-width":"150px","prop":"group.group_code","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('b-media',{staticClass:"align-items-center",attrs:{"no-body":""}},[_c('b-media-body',[_c('span',{staticClass:"font-weight-600 name mb-0 text-sm"},[_vm._v(_vm._s(row.group.group_name))])])],1)]}}])}),_c('el-table-column',{attrs:{"label":"Information","min-width":"180px","prop":"site_leader","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',[_c('b-media',{staticClass:"align-items-center",attrs:{"no-body":""}},[_c('b-media-body',[_c('span',{staticClass:"font-weight-600 name mb-0 text-sm"},[_vm._v("P.Manager: "+_vm._s(row.group.group_engineer.name))])])],1)],1),_c('div',[_c('b-media',{staticClass:"align-items-center",attrs:{"no-body":""}},[_c('b-media-body',[_c('span',{staticClass:"font-weight-600 name mb-0 text-sm"},[_vm._v("Postal.Code: "+_vm._s(row.site_postal_code))])])],1)],1),_c('div',[_c('b-media',{staticClass:"align-items-center",attrs:{"no-body":""}},[_c('b-media-body',[_c('span',{staticClass:"font-weight-600 name mb-0 text-sm"},[_vm._v("Capacity: "+_vm._s(row.site_total_capacity)+" KWp")])])],1)],1)]}}])}),_c('el-table-column',{attrs:{"label":"Progress","min-width":"210px","prop":"site_progress","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"d-flex align-items-center"},[_c('div',[_c('span',{staticClass:"font-weight-600 name mb-0 text-sm"},[_vm._v(_vm._s(row.site_progress)+"%   ")])]),_c('div',[(row.site_progress > 0 && row.site_progress < 50)?_c('base-progress',{attrs:{"min-width":"30px","type":"danger","value":row.site_progress}}):(
                        row.site_progress >= 50 && row.site_progress <= 90
                      )?_c('base-progress',{attrs:{"min-width":"30px","type":"info","value":row.site_progress}}):_c('base-progress',{attrs:{"min-width":"30px","type":"success","value":row.site_progress}})],1)]),_c('b-media',{staticClass:"align-items-center",attrs:{"no-body":""}},[_c('b-media-body',[_c('badge',{staticClass:"badge-dot mr-4",attrs:{"type":""}},[(row.status.status_code == 'Delayed')?_c('i',{staticClass:"bg-danger"}):(row.status.status_code == 'On-Hold')?_c('i',{staticClass:"bg-danger"}):(row.status.status_code == 'Reviewed')?_c('i',{staticClass:"bg-success"}):(row.status.status_code == 'Completed')?_c('i',{staticClass:"bg-success"}):_c('i',{staticClass:"bg-info"}),_c('span',{staticClass:"font-weight-600 name mb-0 text-sm"},[_vm._v(_vm._s(row.status.status_code))])])],1)],1),_c('div',[_vm._v(" Est Turn-On Date: "),_c('b',[_vm._v(_vm._s(row.site_target_turn_on_date))])])]}}])}),_c('el-table-column',{attrs:{"min-width":"140px","align":"right","label":"Actions"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var $index = ref.$index;
                      var row = ref.row;
return _c('div',{staticClass:"d-flex"},[(_vm.button_access.view_button == 'YES')?_c('base-button',{attrs:{"size":"sm","type":"primary"},nativeOn:{"click":function($event){return _vm.handleDetails($index, row)}}},[_vm._v(" Dashboard ")]):_vm._e()],1)}}])})],1)],1),_c('div',{staticClass:"\n            col-12\n            d-flex\n            justify-content-center justify-content-sm-between\n            flex-wrap\n          ",attrs:{"slot":"footer"},slot:"footer"},[_c('div',{},[_c('p',{staticClass:"card-category"},[_vm._v(" Showing "+_vm._s(_vm.from + 1)+" to "+_vm._s(_vm.to)+" of "+_vm._s(_vm.total)+" entries "),(_vm.selectedRows.length)?_c('span',[_vm._v("     "+_vm._s(_vm.selectedRows.length)+" rows selected ")]):_vm._e()])]),_c('base-pagination',{staticClass:"pagination-no-border",attrs:{"current":_vm.pagination.currentPage,"per-page":_vm.pagination.perPage,"total":_vm.total},on:{"change":function($event){return _vm.paginationChanged($event)}}})],1)],2)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }