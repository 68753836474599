<template>
<div>
  <card>
    <!-- Card header -->
    <h3 slot="header" class="mb-0">New Site for ({{ model.project_name }}) - General</h3>
    <div class="text-right">
    </div>
    <form class="needs-validation"
          @submit.prevent="submit">

      <div v-if="project_groups.length == 0 && model.project_id != 'x'" class="form-row">
        <b-col md="12">
        <h5 class="text-danger"> !!! Warning :
            <i>
            Please Create
            <router-link :to="`/projectGroupNewFrProject/${model.project_id}`">
                New Group
            </router-link>
            at Menu >> Project-Group-Site >> Project Group. System required at least ONE Project & One Group before you can proceed to create New Project Site.
            </i>
        </h5>
        </b-col>
      </div>

      <div class="form-row">
        <b-col md="5">
          <base-input label="Project"
                      name="Project"
                      v-model="model.project_name" disabled>
          </base-input>
        </b-col>

        <b-col md="1">
          <br>
          <base-button size="sm" type="primary" @click="checkProject()">Project Infomation</base-button>
        </b-col>

        <b-col md="5">
          <base-input label="Group"
                      name="Group"
                      v-model="model.group_id">
            <select class="form-control" v-model="model.group_id" @change="getGroupProjectEngineer()">
              <option Selected disabled value="x">-- Please Select A Project To Show Group --</option>
              <option Selected disabled value="y">-- Please Select A Group --</option>
              <option v-for="item in project_groups" :key="item.group_id" v-bind:value="item.group_id">{{ item.group_name }}</option>
            </select>
          </base-input>
        </b-col>

        <b-col md="1">
          <br>
          <base-button size="sm" type="primary" @click="checkGroup()">Group Infomation</base-button>
        </b-col>
      </div>

      <div class="form-row">
        <b-col md="12">
          <base-input label="Site Name"
                      name="Site Name"
                      placeholder="Site Name"
                      v-model="model.site_name" ref="site_name">
          </base-input>
        </b-col>
      </div>

      <div class="form-row">
        <b-col md="6">
          <base-input label="Project Engineer (P.I.C)"
                      name="Project Leader (P.I.C)"
                      v-model="model.site_leader" readonly>
          </base-input>
        </b-col>

        <b-col md="6">
          <base-input label="Target Turn-On Date"
                      name="Target Turn-On Date"
                      type="date"
                      v-model="model.site_target_turn_on_date">
          </base-input>
        </b-col>
      </div>

      <div class="form-row">
        <b-col md="5">
          <base-input label="Site Postal Code"
                      name="Site Postal Code"
                      placeholder="Site Postal Code"
                      v-model="model.site_postal_code">
          </base-input>
        </b-col>

        <b-col md="7">
          <base-input label="Site Address"
                      name="Site Address"
                      placeholder="Site Address"
                      v-model="model.site_address">
          </base-input>
        </b-col>
      </div>

      <div class="form-row">
        <b-col md="6">
          <base-input label="Site Status"
                      name="Site Status"
                      v-model="model.status_id">
            <select class="form-control" v-model="model.status_id">
              <option Selected disabled value="x">-- Please Select Site Status --</option>
              <option v-for="item in site_status" :key="item.status_id" v-bind:value="item.status_id">{{ item.status_code }}</option>
            </select>
          </base-input>
        </b-col>

        <b-col md="6">
          <base-input label="Site Progress % "
                      name="Site Progress"
                      placeholder="Site Progress"
                      v-model="model.site_progress" type="number" min="0" max="100">
          </base-input>
        </b-col>
      </div>

      <div class="form-row">
        <b-col md="6">
          <base-input label="EPC Site Start Date"
                      name="EPC Site Start Date"
                      type="date"
                      v-model="model.site_start_date">
          </base-input>
        </b-col>
        <b-col md="6">
          <base-input label="EPC Site End Date"
                      name="EPC Site End Date"
                      type="date"
                      v-model="model.site_end_date">
          </base-input>
        </b-col>

        <b-col md="6">
          <base-input label="Client Agreed Site Start Date"
                      name="Client Agreed Site Start Date"
                      type="date"
                      v-model="model.site_contractor_start_date">
          </base-input>
        </b-col>
        <b-col md="6">
          <base-input label="Client Agreed Site End Date"
                      name="Client Agreed Site End Date"
                      type="date"
                      v-model="model.site_contractor_end_date">
          </base-input>
        </b-col>
      </div>

    </form>
  </card>
  <card>
    <!-- Card header -->
    <h3 slot="header" class="mb-0">New Site for ({{ model.project_name }}) - Site Details</h3>
    <div class="text-right">
    </div>
    <form class="needs-validation"
          @submit.prevent="submit">

      <div class="form-row">
        <b-col md="6">
          <base-input label="EL License Number"
                      name="EL License Number"
                      placeholder="EL License Number"
                      v-model="model.site_elu_license_number">
          </base-input>
        </b-col>

        <b-col md="6">
          <base-input label="Site Tension Level"
                      name="Site Tension Level"
                      placeholder="Site Tension Level"
                      v-model="model.site_type_of_tension">
          </base-input>
        </b-col>

        <b-col md="6">
          <base-input label="Site Approved Load"
                      name="Site Approved Load"
                      placeholder="Site Approved Load"
                      v-model="model.site_approved_load">
          </base-input>
        </b-col>

        <b-col md="6">
          <base-input label="Site MSSL Acc Number"
                      name="Site MSSL Acc Number"
                      placeholder="Site MSSL Acc Number"
                      v-model="model.site_mssl_account_number">
          </base-input>
        </b-col>
      </div>

    </form>
  </card>

  <card>
    <!-- Card header -->
    <h3 slot="header" class="mb-0">New Site for ({{ model.project_name }}) - PV System Details</h3>
    <div class="text-right">
    </div>
    <form class="needs-validation"
          @submit.prevent="submit">

      <div class="form-row">
        <b-col md="3">
          <base-input label="Site Module Quantity (Pcs)"
                      name="Site Module Quantity (Pcs)"
                      placeholder="Site Module Quantity (Pcs)"
                      v-model="model.site_module_quantity" @input="calculateSiteTotalCapacity()">
          </base-input>
        </b-col>

        <b-col md="3">
          <base-input label="Site Module Watt Class (Wp)"
                      name="Site Module Watt Class (Wp)"
                      placeholder="Site Module Watt Class (Wp)"
                      v-model="model.site_module_capacity" @input="calculateSiteTotalCapacity()">
          </base-input>
        </b-col>

        <b-col md="3">
          <base-input label="Site Total Capacity (KWp)"
                      name="Site Total Capacity (KWp)"
                      placeholder="Site Total Capacity (KWp)"
                      v-model="model.site_total_capacity" readonly>
          </base-input>
        </b-col>

        <b-col md="3">
          <base-input label="Site Performance Ratio (%)"
                      name="Site Performance Ratio (%)"
                      placeholder="Site PR Capacity"
                      v-model="model.site_pr_capacity">
          </base-input>
        </b-col>
      </div>

      <div class="form-row">
         <b-col md="4">
          <base-input label="Site Monitoring Application"
                      name="Site Monitoring Application"
                      placeholder="Site Monitoring Application"
                      v-model="model.site_monitoring_application">
          </base-input>
        </b-col>

        <b-col md="4">
          <base-input label="Site Inverter Type & Pcs"
                      name="Site Inverter Type & Pcs"
                      placeholder="Site Inverter Type & Pcs"
                      v-model="model.site_inverter_type">
          </base-input>
        </b-col>

        <b-col md="4">
          <base-input label="Site Logger Type/Model"
                      name="Site Logger Type/Model"
                      placeholder="Site Logger Type/Model"
                      v-model="model.site_logger_type">
          </base-input>
        </b-col>
      </div>

      <div class="form-row">
        <b-col md="6">
          <base-input label="Import From Site Task Template"
                      name="Task Template"
                      v-model="model.task_template_id">
            <select class="form-control" v-model="model.task_template_id">
              <option Selected disabled value="x">-- Please Select A Site Task Template --</option>
              <option v-for="item in task_templates" :key="item.task_template_id" v-bind:value="item.task_template_id">{{ item.task_template_name }} (No of Task : {{ item.task_details_no }})</option>
            </select>
          </base-input>
        </b-col>

        <!-- <b-col md="1">
          <br>
          <base-button size="sm" type="primary" @click="checkTask()">Site Task Template</base-button>
        </b-col> -->

        <!-- <b-col md="6">
           <base-input label="Import From Site Document Template"
                      name="Document Template"
                      v-model="model.task_template_id">
            <select class="form-control" v-model="model.document_template_id">
              <option Selected disabled value="x">-- Please Select A Site Document Template --</option>
              <option v-for="item in document_templates" :key="item.document_template_id" v-bind:value="item.document_template_id">{{ item.document_template_name }} (No of Task : {{ item.document_details_no }})</option>
            </select>
          </base-input>
        </b-col> -->

        <!-- <b-col md="1">
          <br>
          <base-button size="sm" type="primary" @click="checkDocument()">Site Doc. Template</base-button>
        </b-col> -->
      </div>

      <base-button type="primary" @click="createData()">Create / Save</base-button>
      <base-button type="primary" @click="$router.back()">Cancel</base-button>
      <div>
        <br>
        <h5 class="text-danger"> Note : <br><br>
          <i>
          1. By Default all Date is set to "TODAY, and Default date format will me DD/MM/YYYY.
          </i>
          <br>
          <i>
          2. Project Site Progress % is set to "0 %" By Default.
          </i>
          <br>
          <i>
          3. Please select A Site Task & Document Template to import into the Site. This process will automatically import all the Task & Document into site during the site creation.
          </i>
        </h5>
      </div>
    </form>
  </card>
</div>
</template>
<script>
  import BaseSlider from '@/components/BaseSlider'
  import {LoginURL, clientId, clientSecret, apiDomain, getHeader, getcurrentDate} from '@/assets/js/config.js'
  import axios from 'axios'
  import ErrorFunctionMixin from '@/assets/js/errorFunctionMix.js'

  export default {
    mixins: [ErrorFunctionMixin],
    components: {
      BaseSlider
    },
    created()
    {
      var currentDate = getcurrentDate()

      this.model.site_start_date = currentDate
      this.model.site_end_date = currentDate
      this.model.site_contractor_start_date = currentDate
      this.model.site_contractor_end_date = currentDate
      this.model.site_elu_end_date = currentDate
      this.model.site_el_expiry_date = currentDate
      this.model.site_target_turn_on_date = currentDate
      this.getProjectTaskList()
      this.getProjectDocumentList()

      this.model.project_id = this.$route.params.id

      axios.get(apiDomain + 'project/project/' + this.model.project_id, { headers: getHeader() })
      .then ( response => {
        if (response.status === 200)
        {
          console.log(response.data.data[0])
          this.model.project_name = response.data.data[0].project_name
          this.getGroupFromProject()
        }
      })
      .catch ( error => {
        console.log(error.response.status)
        this.errorFunction(error, "Project Information")
      } );

      axios.get(apiDomain + 'lookup/site_status', { headers: getHeader() })
      .then ( response => {
        if (response.status === 200)
        {
          this.site_status = response.data.data
        }
      })
      .catch ( error => {
        console.log(error.response.status)
        this.errorFunction(error, "Site Status")
      } );

      this.calculateSiteTotalCapacity()

    },
    data() {
      return {
        model: {
          project_id: 'x',
          project_name: null,
          group_id: 'x',
          site_name: null,
          site_leader: '-- Please Select A Project & Group --',
          site_postal_code: null,
          site_address: null,
          site_progress: 1,
          milestone_id: 'x',
          site_module_quantity: 1,
          site_module_capacity: 1,
          site_total_capacity: 1,
          site_pr_capacity: 1,
          site_type_of_tension: 'None',
          site_approved_load: 1,
          site_mssl_account_number: null,
          site_plant_name: null,
          site_folder_in_sftp_server: null,
          site_monitoring_application: 'Fusion Solar',
          site_inverter_type: null,
          site_inverter_qty: 1,
          site_total_inverter: 1,
          site_total_data_logger: 1,
          site_logger_type: 'Huawei',
          site_start_date: null,
          site_end_date: null,
          site_contractor_start_date: null,
          site_contractor_end_date: null,
          site_target_turn_on_date: null,
          site_elu_end_date: null,
          site_el_expiry_date: null,
          site_elu_license_number: null,
          site_elu_status: null,
          status_id: 1,
          task_template_id: 'x',
          document_template_id: 'x',
        },
        projects: [],
        project_groups: [],
        site_status: [],
        site_leads: [],
        project_milestones: [],
        task_templates: [],
        document_templates: [],
      }
    },
    methods: {
      getProjectTaskList()
      {
          axios.get(apiDomain + 'template/task_template', { headers: getHeader() })
          .then ( response => {
            if (response.status === 200)
            {

                this.task_templates = response.data.data
            }
          })
          .catch ( error => {
            console.log(error.response.status)
            this.errorFunction(error, "Site Task Template")
          } );
      },
      getProjectDocumentList()
      {
          axios.get(apiDomain + 'template/document_template', { headers: getHeader() })
          .then ( response => {
            if (response.status === 200)
            {
                this.document_templates = response.data.data
            }
          })
          .catch ( error => {
            console.log(error.response.status)
            this.errorFunction(error, "Site Document Template")
          } );
      },
      checkTask()
      {
        if ( this.model.task_template_id == 'x' || this.model.task_template_id == 'y' )
        {
          this.redErrorNotify('<b>Invalid Input : Task Template </b> - Please select a Template before dive into the Details')
        }
        else
        {
          this.$router.push({path: '/templates/taskTemplateDetailListing/'+this.model.task_template_id })
        }
      },
      checkDocument()
      {
        if ( this.model.document_template_id == 'x' || this.model.document_template_id == 'y' )
        {
          this.redErrorNotify('<b>Invalid Input : Document Template </b> - Please select a Template before dive into the Details')
        }
        else
        {
          this.$router.push({path: '/templates/documentTemplateDetailListing/'+this.model.document_template_id })
        }
      },
      calculateSiteTotalCapacity()
      {
          this.model.site_total_capacity = ((this.model.site_module_quantity * this.model.site_module_capacity) / 1000)
      },
      getGroupFromProject()
      {
        axios.get(apiDomain + 'project/project_group.show_by_project/' + this.model.project_id , { headers: getHeader() })
        .then ( response => {
          if (response.status === 200)
          {
            this.project_groups = response.data.data
            this.model.group_id = 'y'
          }
        })
        .catch ( error => {
          console.log(error.response.status)
          this.errorFunction(error, "Project Group Information")
        } );
      },
      getGroupProjectEngineer()
      {
        axios.get(apiDomain + 'project/project_group/' + this.model.group_id , { headers: getHeader() })
        .then ( response => {
          if (response.status === 200)
          {
            this.model.site_leader = response.data.data[0].group_engineer.name
          }
        })
        .catch ( error => {
          console.log(error.response.status)
          this.errorFunction(error, "Project Group Information")
        } );
      },
      checkProject()
      {
        if (this.model.project_id == 'x')
        {
          this.redErrorNotify('<b>Invalid Input : Project </b> - Please select a Project before dive into the Details')
        }
        else
        {
          this.$router.push({path: '/projectEdit/'+this.model.project_id })
        }
      },
      checkGroup()
      {
        if (this.model.group_id == 'x' || this.model.group_id == 'y')
        {
          this.redErrorNotify('<b>Invalid Input : Project Group </b> - Please select a Project Group before dive into the Details')
        }
        else
        {
          this.$router.push({path: '/projectGroupEdit/'+this.model.group_id })
        }
      },
      checkMilestone()
      {
        if (this.model.milestone_id == 'x' || this.model.milestone_id == 'y')
        {
          this.redErrorNotify('<b>Invalid Input : Project Milestone </b> - Please select a Project Milestone before dive into the Details')
        }
        else
        {
          this.$router.push({path: '/projectMilestoneEdit/'+this.model.milestone_id })
        }
      },

      createData(){
        if (
        !this.model.site_name || this.model.project_id == 'x' || this.model.status_id == 'x' || this.model.group_id == 'x' || this.model.group_id == 'y' ||
        !this.model.site_postal_code || !this.model.site_address || !this.model.site_module_quantity || !this.model.site_pr_capacity ||
        !this.model.site_module_quantity || !this.model.site_module_capacity || !this.model.site_total_capacity ||
        !this.model.site_type_of_tension || !this.model.site_approved_load || !this.model.site_mssl_account_number ||
        !this.model.site_monitoring_application || !this.model.site_logger_type || !this.model.site_elu_license_number || !this.model.site_inverter_type 
        )
        {
          if (this.model.project_id == 'x' || this.model.group_id == 'x' || this.model.group_id == 'y')
          {
            this.redErrorNotify( '<b>Invalid Input : Project & Group Column is a Mandatory in Project Site </b>')
          }
          else if (!this.model.site_name)
          {
            this.redErrorNotify( '<b>Invalid Input : Site Name Column is a Mandatory in Project Site </b>' )
          }
          else if (!this.model.site_postal_code)
          {
            this.redErrorNotify( '<b>Invalid Input : Site Postal Column is a Mandatory in Project Site </b>' )
          }
          else if (!this.model.site_address)
          {
            this.redErrorNotify( '<b>Invalid Input : Site Address Column is a Mandatory in Project Site </b>' )
          }
          else if (!this.model.site_elu_license_number)
          {
            this.redErrorNotify( '<b>Invalid Input : EL License Column is a Mandatory in Project Site </b>' )
          }
         
          else if (!this.model.site_type_of_tension)
          {
            this.redErrorNotify( '<b>Invalid Input : Tension Level Column is a Mandatory in Project Site </b>' )
          }
          else if (!this.model.site_mssl_account_number)
          {
            this.redErrorNotify( '<b>Invalid Input : MSSL Account Number Column is a Mandatory in Project Site </b>' )
          }
         
          else if (!this.model.site_monitoring_application)
          {
            this.redErrorNotify( '<b>Invalid Input : Site Monitoring Application Column is a Mandatory in Project Site </b>' )
          }
          else if (!this.model.site_logger_type)
          {
            this.redErrorNotify( '<b>Invalid Input : Site Logger Type / Model Column is a Mandatory in Project Site </b>' )
          }
          else if (!this.model.site_pr_capacity)
          {
            this.redErrorNotify( '<b>Invalid Input : Site PR Capacity Column is a Mandatory in Project Site </b>' )
          }
          else if (!this.model.site_inverter_type)
          {
            this.redErrorNotify( '<b>Invalid Input : Inverter Type Column is a Mandatory in Project Site </b>' )
          }
          else if (!this.model.site_inverter_qty)
          {
            this.redErrorNotify( '<b>Invalid Input : Inverter Quantity Column is a Mandatory in Project Site </b>' )
          }
          else if (this.model.task_template_id == 'x')
          {
            this.redErrorNotify( '<b>Invalid Input : Task Template Column is a Mandatory in Project Site </b>' )
          }
          else if (this.model.document_template_id == 'x')
          {
            this.redErrorNotify( '<b>Invalid Input : Document Template is a Mandatory in Project Site </b>' )
          }
          else
          {
            this.redErrorNotify( '<b>Invalid Input : All Column is a Mandatory in Project Site </b>' )
          }

        }
        else if( this.model.site_progress < 0 || this.model.site_progress > 100 )
        {
          this.redErrorNotify('<b>Invalid Input : Project Site Progress </b> - Input Must be between 0 - 100')
        }
        else
        {
          if ( this.model.task_template_id == 'x' )
          {
            this.model.task_template_id = null
          }

          if ( this.model.document_template_id == 'x' )
          {
            this.model.document_template_id = null
          }

          axios.post(apiDomain + 'project/project_site', this.model, { headers: getHeader() })
          .then ( response => {
            if (response.status === 200)
            {
                this.$notify({
                message:
                  '<b>New Project Site : ' + this.model.site_name + '</b> - Successfully Created.',
                timeout: 10000,
                icon: 'ni ni-bell-55',
                type: 'default',
                });
                this.$router.back()
            }
          })
          .catch ( error => {
              console.log(error.response.data.errors)
              this.errorFunction(error, "Create Project Site")
          });
        }
      },
    }
  }
</script>
<style>
</style>
