<template>
  <card>
    <!-- Card header -->
    <h3 slot="header" class="mb-0">Document Details Information for {{ model.document_classification_code }} </h3>
    <div class="text-right">
      <router-link :to="'/documentEdit/' + model.document_id">
        <base-button size="sm" type="primary">Edit</base-button>
      </router-link>
    </div>
    <form class="needs-validation"
          @submit.prevent="submit">

      <div class="form-row">
        <b-col md="12">
          <base-input label="Document Information"
                      name="Document Information"
                      placeholder="Document Information"
                      v-model="model.document_information" disabled>
          </base-input>
        </b-col>
      </div>

      <div class="form-row">
        <b-col md="4">
          <base-input label="Project"
                      name="Project"
                      v-model="model.project_name" disabled>
          </base-input>
        </b-col>

        <b-col md="4">
          <base-input label="Group"
                      name="Group"
                      v-model="model.group_name" disabled>
          </base-input>
        </b-col>

        <b-col md="4">
          <base-input label="Site"
                      name="Site"
                      v-model="model.site_name" disabled>
          </base-input>
        </b-col>
      </div>

      <div class="form-row">
        <b-col md="3">
          <base-input label="Document Type"
                      name="Document Type"
                      v-model="model.document_type_code" disabled>
          </base-input>
        </b-col>

        <b-col md="3">
          <base-input label="Document Current Milestone"
                      name="Document Current Milestone"
                      v-model="model.milestone_code" disabled>
          </base-input>
        </b-col>

        <b-col md="3">
          <base-input label="Document Category"
                      name="Document Category"
                      v-model="model.document_category" disabled>
          </base-input>
        </b-col>

        <b-col md="3">
          <base-input label="Document Mandatory"
                      name="Document Mandatory"
                      v-model="model.document_mandatory" disabled>
          </base-input>
        </b-col>
      </div>

      <div v-if="model.document_type_code == 'RECURRING'" class="form-row">
       <b-col md="4">
          <base-input label="Recurring Interval"
                      name="Recurring Interval"
                      v-model="model.recurring_interval" disabled>
          </base-input>
        </b-col>

        <b-col md="4">
          <base-input label="Start Date"
                      name="Start Date"
                      v-model="model.start_date" disabled>
          </base-input>
        </b-col>

        <b-col md="4">
          <base-input label="End Date"
                      name="End Date"
                      v-model="model.end_date" disabled>
          </base-input>
        </b-col>
      </div>
      <div v-if="model.document_category == 'IFA Document'">
        <hr>
        <div class="form-row" >
          <b-col md="12">
              <b>Require Approval From</b>
          </b-col>
        </div>
        <br>
        <div class="form-row">
          <b-col md="1.5">
            <b-form-checkbox
              id="req_approval_project_owner"
              v-model="model.req_approval_project_owner"
              name="req_approval_project_owner"
              value="1"
              unchecked-value="0" disabled>
              <b>Project Owner</b>
            </b-form-checkbox>
          </b-col>

          <b-col md="1.5">
            <b-form-checkbox
              id="req_approval_project_manager"
              v-model="model.req_approval_project_manager"
              name="req_approval_project_manager"
              value="1"
              unchecked-value="0" disabled>
              <b>Project Manager</b>
            </b-form-checkbox>
          </b-col>

          <b-col md="1.5">
            <b-form-checkbox
              id="req_approval_project_engineer"
              v-model="model.req_approval_project_engineer"
              name="req_approval_project_engineer"
              value="1"
              unchecked-value="0" disabled>
              <b>Project Engineer</b>
            </b-form-checkbox>
          </b-col>

          <b-col md="1.5">
            <b-form-checkbox
              id="req_approval_engineer"
              v-model="model.req_approval_engineer"
              name="req_approval_engineer"
              value="1"
              unchecked-value="0" disabled>
              <b>Engineer</b>
            </b-form-checkbox>
          </b-col>

          <b-col md="1.5">
            <b-form-checkbox
              id="req_approval_qa_qc"
              v-model="model.req_approval_qa_qc"
              name="req_approval_qa_qc"
              value="1"
              unchecked-value="0" disabled>
            <b>QA / QC</b>
            </b-form-checkbox>
          </b-col>

          <b-col md="1.5">
            <b-form-checkbox
              id="req_approval_safety"
              v-model="model.req_approval_safety"
              name="req_approval_safety"
              value="1"
              unchecked-value="0" disabled>
            <b>Safety</b>
            </b-form-checkbox>
          </b-col>

          <b-col md="1.5">
            <b-form-checkbox
              id="req_approval_planner"
              v-model="model.req_approval_planner"
              name="req_approval_planner"
              value="1"
              unchecked-value="0" disabled>
            <b>Planner</b>
            </b-form-checkbox>
          </b-col>

          <b-col md="1.5">
            <b-form-checkbox
              id="req_approval_purchasing"
              v-model="model.req_approval_purchasing"
              name="req_approval_purchasing"
              value="1"
              unchecked-value="0" disabled>
            <b>Purchasing</b>
            </b-form-checkbox>
          </b-col>

          <b-col md="1.5">
            <b-form-checkbox
              id="req_approval_admin"
              v-model="model.req_approval_admin"
              name="req_approval_admin"
              value="1"
              unchecked-value="0" disabled>
            <b>Admin</b>
            </b-form-checkbox>
          </b-col>
        </div>
      </div>
      <br><br>

      <!-- <base-button type="primary" native-type="submit" @click="notifyVueNew('default')" >Update Milestone Template</base-button>
      <base-button type="primary" @click="$router.go(-1)">Cancel</base-button> -->
    </form>
  </card>
</template>
<script>
  import BaseSlider from '@/components/BaseSlider'
  import {LoginURL, clientId, clientSecret, apiDomain, getHeader} from '@/assets/js/config.js'
  import axios from 'axios'

  export default {
    components: {
      BaseSlider
    },
    created()
    {
      this.model.document_id = this.$route.params.id

      this.getViewData()

      axios.get(apiDomain + 'template/milestone_template', { headers: getHeader() })
      .then ( response => {
        if (response.status === 200)
        {
          this.milestone_template = response.data.data
        }
      })
      .catch ( error => {
        console.log(error.response.status)
        if ( error.response.status == 401)
        {
          this.$router.push('/login')
        }
      } );

    },
    data() {
      return {
        model: {
          document_id: null,
          document_classification_code: null,
          document_information: null,
          project_name: null,
          group_name: null,
          site_name: null,
          document_type_code: null,
          milestone_code: null,
          status_code: null,
          document_mandatory: null,
          document_category: 'IFA Document',
          req_approval_project_owner: 1,
          req_approval_project_manager: 1,
          req_approval_project_engineer: 1,
          req_approval_engineer: 1,
          req_approval_qa_qc: 1,
          req_approval_safety: 1,
          req_approval_planner: 1,
          req_approval_purchasing: 1,
          req_approval_admin: 1,
          recurring_interval: 'Daily',
          start_date: '11-Nov-2021',
          end_date: '13-Nov-2021',
        },
        milestone_template: [],
      }
    },
    methods: {
      getViewData()
      {
          axios.get(apiDomain + 'document/document/'+this.model.document_id, { headers: getHeader() })
          .then ( response => {
            if (response.status === 200)
            {
                console.log(response.data.data[0])
                this.model.document_id = response.data.data[0].document_id
                this.model.document_classification_code = response.data.data[0].document_classification_code
                this.model.document_information = response.data.data[0].document_information
                this.model.project_name = response.data.data[0].project.project_name
                this.model.group_name = response.data.data[0].group.group_name
                this.model.site_name = response.data.data[0].site.site_name
                this.model.document_type_code = response.data.data[0].document_type.document_type_code
                this.model.milestone_code = response.data.data[0].milestone.milestone_code
                this.model.status_code = response.data.data[0].status.status_code
                this.model.document_mandatory = response.data.data[0].document_mandatory
            }
          })
          .catch ( error => {
              console.log(error.response.status)
              if ( error.response.status == 401)
              {
                  this.$router.push('/login')
              }
          } );
      },
    }
  }
</script>
<style>
</style>
