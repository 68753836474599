<template>
    <div class="content">
        <base-header class="pb-6">
            <b-row align-v="center" class="py-4">
                <b-col cols="7" lg="6">
                    <h6 class="h2 text-white d-inline-block mb-0">Ms Project Historical Update Listing</h6>
                    <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
                        <route-bread-crumb></route-bread-crumb>
                    </nav>
                </b-col>
            </b-row>
        </base-header>

        <b-container fluid class="mt--6">
            <div>
                <card class="no-border-card" body-classes="px-0 pb-1" footer-classes="pb-2">
                    <template slot="header">
                        <b-row>
                            <h3 class="mb-0">Ms Project Historical Update Listing</h3>
                            <b-col class="text-right">
                                <!-- responseTableCount: {{ tableData.length }} <br/> -->
                                <!-- button clicked: {{ tempButtonStatus }} <br/> -->
                                <!-- uniqueTable: {{ uniqueTable }} <br/> -->
                                <!-- uniqueUser: {{ uniqueUser }} <br/> -->
                                <!-- uniqueUserLength: {{ uniqueUser.length }} <br/> -->
                                <base-button size="sm" type="primary" @click="routerBack()"
                                >Back</base-button
                                >
                            </b-col>
                        </b-row>


                    </template>
                    <div>
                        <b-col cols="12" class="d-flex justify-content-center justify-content-sm-between flex-wrap">
                            <el-select
                                    class="select-primary pagination-select"
                                    v-model="pagination.perPage"
                                    placeholder="Per page">
                                <el-option
                                        class="select-primary"
                                        v-for="item in pagination.perPageOptions"
                                        :key="item"
                                        :label="item"
                                        :value="item">
                                </el-option>
                            </el-select>
                            <div>
                                <base-input v-model="searchQuery"
                                            prepend-icon="fas fa-search"
                                            placeholder="Search...">
                                </base-input>
                            </div>
                        </b-col>
                        <b-col cols="12">
                            <h3>Filters</h3>

                            <div class="form-row">

                                <b-col cols="6">
                                    <base-input
                                            label="Task Name"
                                            name="Task Name"
                                            v-model="filter.mpuh_task_name"
                                    >
                                    </base-input>
                                </b-col>

                            </div>

                            <b-row>
                                <b-col class="text-right">
                                    <!-- responseTableCount: {{ tableData.length }} <br/> -->
                                    <!-- button clicked: {{ tempButtonStatus }} <br/> -->
                                    <!-- uniqueTable: {{ uniqueTable }} <br/> -->
                                    <!-- uniqueUser: {{ uniqueUser }} <br/> -->
                                    <!-- uniqueUserLength: {{ uniqueUser.length }} <br/> -->
                                    <base-button size="sm" type="primary" @click="searchMsProjectHistoricalUpdate()"
                                    >Search</base-button
                                    >
                                </b-col>
                            </b-row>

                        </b-col>
                        <b-col><br></b-col>
                        <el-table :data="queriedData"
                                  row-key="developer_template_id"
                                  header-row-class-name="thead-light"
                                  @sort-change="sortChange">

                            <el-table-column label="Task Name"
                                             min-width="140px"
                                             prop="mpuh_task_name"
                                             sortable>
                                <template v-slot="{row}">
                                    <b-media no-body class="align-items-center">
                                        <b-media-body>
                                            <span class="font-weight-600  mb-0 text-sm">{{row.mpuh_task_name}}</span>
                                        </b-media-body>
                                    </b-media>
                                </template>
                            </el-table-column>

                            <el-table-column label="Task ID"
                                             min-width="140px"
                                             prop="mpuh_unique_task_id"
                                             sortable>
                                <template v-slot="{row}">
                                    <b-media no-body class="align-items-center">
                                        <b-media-body>
                                            <span class="font-weight-600  mb-0 text-sm">{{row.mpuh_unique_task_id}}</span>
                                        </b-media-body>
                                    </b-media>
                                </template>
                            </el-table-column>

                            <el-table-column label="Batch ID"
                                             min-width="140px"
                                             prop="mpuh_batch_id"
                                             sortable>
                                <template v-slot="{row}">
                                    <b-media no-body class="align-items-center">
                                        <b-media-body>
                                            <span class="font-weight-600  mb-0 text-sm">{{row.mpuh_batch_id}}</span>
                                        </b-media-body>
                                    </b-media>
                                </template>
                            </el-table-column>

                            <el-table-column label="Changed Date"
                                             min-width="140px"
                                             prop="mpuh_changed_date"
                                             sortable>
                                <template v-slot="{row}">
                                    <b-media no-body class="align-items-center">
                                        <b-media-body>
                                            <span class="font-weight-600  mb-0 text-sm">{{row.mpuh_changed_date}}</span>
                                        </b-media-body>
                                    </b-media>
                                </template>
                            </el-table-column>

                            <el-table-column label="Remarks"
                                             min-width="250px"
                                             prop="remarks"
                                             sortable>
                                <template v-slot="{row}">
                                    <b-media no-body class="align-items-center">
                                        <b-media-body>
                                            <div v-if="row.mpuh_action=='Task Changes'">
                                                <span class="font-weight-900 font-weight-bolder  mb-0 text-sm">{{row.mpuh_action}} :</span><br />
                                                <span v-if="row.mpuh_ori_task_name != row.mpuh_task_name" class="font-weight-600  mb-0 text-sm"><span class="text-danger">Task Name</span> : {{(row.mpuh_ori_task_name != null) ? row.mpuh_ori_task_name : "N/A"}} <i class="ni ni-bold-right"></i> {{(row.mpuh_task_name != null) ? row.mpuh_task_name : "N/A"}}<br /></span>
                                                <span v-if="row.mpuh_ori_planned_start_date != row.mpuh_new_planned_start_date" class="font-weight-600  mb-0 text-sm"><span class="text-danger">Planned Start Date</span> : {{(row.mpuh_ori_planned_start_date != null) ? row.mpuh_ori_planned_start_date : "N/A"}} <i class="ni ni-bold-right"></i> {{(row.mpuh_new_planned_start_date != null) ? row.mpuh_new_planned_start_date : "N/A"}}<br /></span>
                                                <span v-if="row.mpuh_ori_planned_end_date != row.mpuh_new_planned_end_date" class="font-weight-600  mb-0 text-sm"><span class="text-danger">Planned End Date</span> : {{(row.mpuh_ori_planned_end_date != null) ? row.mpuh_ori_planned_end_date : "N/A"}}  <i class="ni ni-bold-right"></i> {{ (row.mpuh_new_planned_end_date != null) ? row.mpuh_new_planned_end_date : "N/A"}}<br /></span>
                                                <span v-if="row.mpuh_ori_actual_start_date != row.mpuh_new_actual_start_date" class="font-weight-600  mb-0 text-sm"><span class="text-danger">Actual Start Date</span> : {{(row.mpuh_ori_actual_start_date != null) ? row.mpuh_ori_actual_start_date : "N/A" }}  <i class="ni ni-bold-right"></i> {{(row.mpuh_new_actual_start_date != null) ? row.mpuh_new_actual_start_date : "N/A"}}<br /></span>
                                                <span v-if="row.mpuh_ori_actual_end_date != row.mpuh_new_actual_end_date" class="font-weight-600  mb-0 text-sm"><span class="text-danger">Actual End Date</span> : {{(row.mpuh_ori_actual_end_date != null) ?  row.mpuh_ori_actual_end_date : "N/A"}}  <i class="ni ni-bold-right"></i> {{(row.mpuh_new_actual_end_date != null) ?  row.mpuh_new_actual_end_date : "N/A"}}<br /></span>
                                                <span v-if="row.mpuh_ori_durations != row.mpuh_new_durations" class="font-weight-600  mb-0 text-sm"><span class="text-danger">Durations</span> : {{(row.mpuh_ori_durations != null) ?  row.mpuh_ori_durations : "N/A" }}  <i class="ni ni-bold-right"></i> {{(row.mpuh_new_durations != null) ?  row.mpuh_new_durations : "N/A"}}<br /></span>
                                                <span v-if="row.mpuh_ori_percent_completion != row.mpuh_new_percent_completion" class="font-weight-600  mb-0 text-sm"><span class="text-danger">Percent Completion</span> : {{(row.mpuh_ori_percent_completion != null) ? row.mpuh_ori_percent_completion : "N/A" }}  <i class="ni ni-bold-right"></i> {{(row.mpuh_new_percent_completion != null) ? row.mpuh_new_percent_completion : "N/A"}}<br /></span>
                                                <span v-if="row.mpuh_ori_critical != row.mpuh_new_critical" class="font-weight-600  mb-0 text-sm"><span class="text-danger">Critical</span> : {{(row.mpuh_ori_critical != null) ? row.mpuh_ori_critical : "N/A" }}  <i class="ni ni-bold-right"></i> {{(row.mpuh_new_critical != null) ? row.mpuh_new_critical : "N/A" }}<br /></span>
                                                <span v-if="row.mpuh_ori_outline_level != row.mpuh_new_outline_level" class="font-weight-600  mb-0 text-sm"><span class="text-danger">Outline Level</span> : {{(row.mpuh_ori_outline_level != null) ? row.mpuh_ori_outline_level : "N/A"}} <i class="ni ni-bold-right"></i> {{(row.mpuh_new_outline_level != null) ? row.mpuh_new_outline_level : "N/A"}}<br /></span>
                                            </div>
                                            <div v-else>
                                                <span class="font-weight-900 font-weight-bold  mb-0 text-sm">{{row.mpuh_action}}</span>
                                            </div>
                                            </b-media-body>

                                    </b-media>
                                </template>
                            </el-table-column>
                        </el-table>
                    </div>
                    <div
                            slot="footer"
                            class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap">
                        <div class="">
                            <p class="card-category">
                                Showing {{ from + 1 }} to {{ to }} of {{ total }} entries

                                <span v-if="selectedRows.length">
                                  &nbsp; &nbsp; {{selectedRows.length}} rows selected
                                </span>
                            </p>
                        </div>
                        <base-pagination
                                class="pagination-no-border"
                                :current="pagination.currentPage"
                                :per-page="pagination.perPage"
                                :total="total"
                                @change="paginationChanged($event)">
                        </base-pagination>
                    </div>
                </card>
            </div>
        </b-container>
    </div>
</template>
<script>
    import { Table, TableColumn, Select, Option } from 'element-ui';
    import RouteBreadCrumb from '@/components/Breadcrumb/RouteBreadcrumb'
    import { BasePagination } from '@/components';
    import standardPaginationMix from '@/assets/js/standardPaginationMix.js'
    import axios from 'axios'
    import Fuse from 'fuse.js';
    import {LoginURL, clientId, clientSecret, apiDomain, getHeader, parseDateToUTC} from '@/assets/js/config.js'
    import ErrorFunctionMixin from '@/assets/js/errorFunctionMix.js'

    export default {
        mixins: [standardPaginationMix, ErrorFunctionMixin],
        components: {
            BasePagination,
            RouteBreadCrumb,
            [Select.name]: Select,
            [Option.name]: Option,
            [Table.name]: Table,
            [TableColumn.name]: TableColumn
        },
        created()
        {
            var developer_access = JSON.parse(window.localStorage.getItem('developer_access'))
            this.button_access.new_button = developer_access.access_create
            this.button_access.show_button = developer_access.access_show
            this.getMsProjectHistoricalUpdates()
        },
        data() {
            return {
                propsToSearch:
                    [
                        'mpuh_id',
                        'mpuh_task_name',
                        'mpuh_changed_date',
                        'mpuh_change_by',
                    ],
                tableColumns: [],
                tableData: [],
                selectedRows: [],
                button_access:{
                    new_button: null,
                    show_button: null,
                },
                filter: {
                    from_date: null,
                    to_date: null,
                    mpuh_task_name: null
                },
                tempButtonStatus: false,
                uniqueTable: null,
                uniqueUser: null,
            };
        },
        methods: {
            routerBack(){
                this.$router.push({path: '/ProjectSiteDashboardUtilities/' + this.$route.params.id})
            },
            fuseData() {
                this.fuseSearch = new Fuse(this.tableData, {
                    keys: this.propsToSearch,
                    threshold: 0.3
                });
            },
            paginationChanged(page) {
                this.pagination.currentPage = page
            },
            getMsProjectHistoricalUpdates() {
                axios.get(apiDomain + 'project/get_ms_project_history_listing/'+this.$route.params.id, { headers: getHeader() })
                    .then ( response => {
                        if (response.status === 200)
                        {
                            let data = response.data.data;
                            data.forEach(item => {
                                if(item.mpuh_changed_date !== null){
                                    let date =  new Date(item.mpuh_changed_date).toLocaleDateString();
                                    let time =  new Date(item.mpuh_changed_date).toLocaleTimeString();
                                    item.mpuh_changed_date = `${date} ${time}`;
                                }

                            });
                            this.tableData = data;
                            this.fuseData();
                        }
                    })
                    .catch ( error => {
                        this.errorFunction(error, "Ms Project Historical Update Listing")
                    });
            },
            searchMsProjectHistoricalUpdate() {
                this.tempButtonStatus = true;
                axios.post(apiDomain + 'project/search_ms_project_history_listing/'+this.$route.params.id, this.filter, {
                    headers: getHeader(),
                })
                    .then ( response => {
                        if (response.status === 200)
                        {
                            this.tableData = response.data.data;
                            this.fuseData();
                        }
                    })
                    .catch ( error => {
                        this.errorFunction(error, "Search Ms Project Historical Update Listing")
                    });
            },
            changeDateFormat(currentDate){
                return new Date.parse(row.mpuh_changed_date).toLocaleString();
            }
        }
    };
</script>
<style>
    .no-border-card .card-footer{
        border-top: 0;
    }
</style>
