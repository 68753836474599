<template>
  <div>
    <base-header class="pb-6">
      <b-row class="py-4">
        <b-col lg="12" cols="7">
          <h6 class="h2 text-white d-inline-block mb-0">Project Dashboard</h6>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <route-bread-crumb></route-bread-crumb>
          </nav>
        </b-col>

        <!-- <div>pmocResponse: {{pmocResponse}}</div> -->

        <b-col lg="6" cols="7"></b-col>

        <b-col lg="6" cols="7">
          <card gradient="default" no-body="">
            <b-card-body>
              <div class="mb-2">
                <div>
                  <span class="h2 text-white"
                    >Project Name : {{ model.project_name }}
                  </span>
                </div>

                <div class="text-light mt-2 text-sm">
                  Current Project Status :
                  <span class="text-success font-weight-600">
                    {{ model.status.status_code }}
                  </span>
                </div>
                <div class="text-light mt-2 text-sm">
                  Project Manager :
                  <span class="text-success font-weight-600">
                    {{ model.project_manager.name }}
                  </span>
                </div>
                <div class="text-light mt-2 text-sm">
                  Developer :
                  <span class="text-success font-weight-600">
                    {{ model.developer.developer_code }}
                  </span>
                </div>
              </div>
            </b-card-body>
          </card>
        </b-col>

        <b-col lg="12" cols="6" class="text-right">
          <!-- <base-button
            size="sm"
            type="neutral"
            @click="newProjectDocument()"
            v-if="
              button_access.project_document_new == 'YES' &&
              myUserID.email == model.project_manager.email
            "
            >Create New Project Document</base-button
          > -->

          <!-- <base-button size="sm" type="neutral" @click="newSiteDocument()"
          v-if="button_access.site_document_new == 'YES'">Create New Site Document</base-button> -->

          <base-button
            size="sm"
            type="danger"
            @click="showDeleteProjectModal()"
            v-if="
              button_access.project_delete == 'YES' && myUserID.role_id == 3
            "
            >Delete Project</base-button
          >

          <!-- BUTTON SHOULD ONLY BE AVAILABLE AFTER ALL TASKS AND MILESTONES ARE COMPLETED AND APPROVED -->

          <!-- <base-button
            size="sm"
            type="neutral"
            @click="downloadPacPdf()"
            >PAC Form</base-button
          > -->

          <!-- BUTTON SHOULD ONLY BE AVAILABLE FOR PLANNER || 19, CUSTOMER MANAGEMENT || 20, PROCUREMENT(Purchasing) || 17-->

          <!-- <base-button
            size="sm"
            type="neutral"
            @click="showApprovePMOCModal()"
            v-if="(myUserID.role_id == 19 || myUserID.role_id == 20 || myUserID.role_id == 17) && (pmocResponse != null)"
            >PMOC Approvals</base-button
          > -->

          <!-- BUTTON SHOULD ONLY BE AVAILABLE FOR PROJECT MANAGER -->

          <base-button
            size="sm"
            type="neutral"
            @click="routeToProjectImportNewProjectSite()"
            v-if="isSolarNovaProject || model.project_type.project_type_id == 8"
          >
            Import New Project Site
          </base-button>

          <base-button
            size="sm"
            type="neutral"
            @click="newTask()"
            v-if="
              button_access.project_task_new == 'YES' &&
              myUserID.email == model.project_manager.email
            "
            >Create New Project Task</base-button
          >

          <!-- <base-button size="sm" type="neutral" @click="newSiteTask()"
          v-if="button_access.site_task_new == 'YES'">Create New Site Task</base-button> -->

          <base-button
            size="sm"
            type="neutral"
            @click="newProjectGroup()"
            v-if="
              button_access.group_new == 'YES' &&
              (myUserID.email == model.project_manager.email ||
                myUserID.role_id == 3)
            "
            >Create New Group</base-button
          >

          <!-- <base-button size="sm" type="neutral" @click="newProjectSite()"
          v-if="((button_access.site_new == 'YES'))">Create New Site</base-button> -->

          <!-- <base-button type="neutral" size="sm" @click="newImport()">Import Task & Document From Template</base-button> -->

          <base-button size="sm" type="neutral" @click="$router.back()"
            >Back</base-button
          >
          <!-- <base-button size="sm" type="neutral" @click="modals.formTask = true">Modal</base-button> -->
        </b-col>
      </b-row>

      <b-row class="justify-content-end">
        <b-col v-if="!isSolarNovaProject" xl="4" md="6">
          <stats-card
            title="Completed / Total Site Task"
            type="gradient-red"
            :sub-title="`${model.project_completed_task} / ${model.project_total_task}`"
            icon="ni ni-pin-3"
          >
            <template slot="footer"> </template>
          </stats-card>
        </b-col>
        <b-col xl="4" md="6">
          <stats-card
            title="Turn On / Total (kWP)"
            type="gradient-orange"
            :sub-title="`${model.project_completed_capacity} / ${model.project_total_capacity} `"
            icon="ni ni-cloud-upload-96"
          >
            <template slot="footer"> </template>
          </stats-card>
        </b-col>
        <!-- <b-col xl="3" md="6">
          <stats-card
            title="Completed / Total Site Doc."
            type="gradient-green"
            :sub-title="`${model.project_completed_document} / ${model.project_total_document}`"
            icon="ni ni-bullet-list-67"
          >
            <template slot="footer"> </template>
          </stats-card>
        </b-col> -->
        <b-col xl="4" md="6">
          <stats-card
            title="Project Progress"
            type="gradient-info"
            :sub-title="`${model.project_progress} %`"
            icon="ni ni-chart-bar-32"
          >
            <template slot="footer"> </template>
          </stats-card>
        </b-col>
      </b-row>
      <b-row>
        <b-col xl="12" md="6">
          <stats-card
            title="Completed
          / Total Project Task"
            type="gradient-red"
            :sub-title="`${model.project_level_completed_task} / ${model.project_level_total_task}`"
            icon="ni ni-pin-3"
          >
            <template slot="footer"> </template>
          </stats-card>
        </b-col>
        <!-- <b-col xl="6" md="6">
          <stats-card
            title="Completed / Total Project Document"
            type="gradient-green"
            :sub-title="`${model.project_level_completed_document} / ${model.project_level_total_document}`"
            icon="ni ni-bullet-list-67"
          >
            <template slot="footer"> </template>
          </stats-card>
        </b-col> -->
      </b-row>
    </base-header>

    <b-container fluid class="mt--6">
      <b-row>
        <!-- <b-col xl="6">
          <card type="default" header-classes="bg-transparent">
            <b-row align-v="center" slot="header">
              <b-col>
                <h5 class="h3 text-white mb-0">Project S-Curve</h5>
              </b-col>
              <b-col>
                <b-nav class="nav-pills justify-content-end">
                  <b-nav-item
                    class="mr-1 mr-md-0"
                    :active="bigLineChart.activeIndex === 0"
                    link-classes="py-2 px-3"
                    @click.prevent="initBigChart(0)"
                  >
                    <span class="d-none d-md-block"><b>Estimated</b></span>
                  </b-nav-item>
                  <b-nav-item
                    class="mr-1 mr-md-0"
                    :active="bigLineChart.activeIndex === 1"
                    link-classes="py-2 px-3"
                    @click.prevent="initBigChart(1)"
                  >
                    <span class="danger"><b>Actual</b></span>
                  </b-nav-item>
                </b-nav>
              </b-col>
            </b-row>
            <line-chart
              :height="610"
              ref="bigChart"
              :chart-data="bigLineChart.chartData"
              :extra-options="bigLineChart.extraOptions"
            >
            </line-chart>
          </card>
        </b-col> -->
        <b-col xl="12">
          <project-view-form></project-view-form>
        </b-col>
      </b-row>

      <!-- <div>Click on data (bar) to show project milestone remarks</div>

      <div id="chart">
        <apexchart
          type="rangeBar"
          height="500"
          :options="chartOptions"
          :series="series"
          @dataPointSelection="dataPointSelectionHandler"
        ></apexchart>
      </div> -->

      <div v-if="!isSolarNovaProject" id="chart">
        <apexchart
          type="rangeBar"
          height="500"
          :options="chartOptions"
          :series="series"
        ></apexchart>
      </div>

      <!-- NEW DASHBOARD GAUGE -->

      <!-- <b-row
        ><b-col><project-progress-sn></project-progress-sn></b-col
      ></b-row> -->

      <b-row
        ><b-col><project-management-remarks></project-management-remarks></b-col
      ></b-row>

      <!-- <b-row>
        <b-col xl="5">
          <milestone-progress-track-list
            ref="milestoneComponent"
          ></milestone-progress-track-list>
        </b-col>
        <b-col xl="4">
          <card>
            <h5 class="h3 mb-0" slot="header">Team Members</h5>
            <user-list></user-list>
          </card>
        </b-col>
        <b-col xl="3">
          <card>
            <h5 class="h3 mb-0" slot="header">
              Project Contractor List for ({{ model.project_name }})
            </h5>
            <contractor-list></contractor-list>
          </card>
        </b-col>
      </b-row> -->

      <b-row>
        <b-col xl="6">
          <card>
            <h5 class="h3 mb-0" slot="header">Team Members</h5>
            <user-list></user-list>
          </card>
        </b-col>
        <!-- <b-col xl="6">
          <card>
            <h5 class="h3 mb-0" slot="header">
              Project Contractor List for ({{ model.project_name }})
            </h5>
            <contractor-list></contractor-list>
          </card>
        </b-col> -->
      </b-row>

      <card>
        <b-row v-if="isSolarNovaProject"
          ><b-col xl="6"
            ><div>Project Turn On / In Progress Chart</div>
            <!-- <div>{{tempTurnOnInProgressKwpResponse}}</div> -->
            <apexchart
              ref="TurnOnInProgressKwp"
              type="pie"
              height="200px"
              :options="chartOptionsTurnOnInProgressKwp"
              :series="seriesTurnOnInProgressKwp"
              @dataPointSelection="dataPointSelectionHandlerTurnOnInProgressKwp"
            ></apexchart
          ></b-col>
          <b-col xl="6"
            ><div>Project In Progress Chart</div>
            <apexchart
              ref="TotalSitesIncompleteKwp"
              type="pie"
              height="200px"
              :options="chartOptionsTotalSitesIncomplete"
              :series="seriesTotalSitesIncomplete"
            ></apexchart></b-col
        ></b-row>

        <!-- <div>{{tempTotalSitesMilestoneResponse}}</div> -->
        <!-- <b-row v-if="isSolarNovaProject"
        ><b-col
          ><div>Project In Progress Chart</div>
          <apexchart
            ref="TotalSitesIncompleteKwp"
            type="pie"
            height="400px"
            :options="chartOptionsTotalSitesIncomplete"
            :series="seriesTotalSitesIncomplete"
          ></apexchart></b-col
      ></b-row> -->

        <b-row v-if="isSolarNovaProject"
          ><b-col>
            <div>
              Past 5 Months & Current Month Turn On | Future 6 Months Targeted
              Turn On
            </div>
            <!-- <div>
            tempTurnOnTargetTurnOnResponse: {{ tempTurnOnTargetTurnOnResponse }}
          </div>
          <div>
            tempTurnOnTargetTurnOnCategories:
            {{ tempTurnOnTargetTurnOnCategories }}
          </div> -->
            <div>{{ tempPrintOut }}</div>
            <div>{{ tempPrintOut2 }}</div>
            <apexchart
              type="bar"
              height="350"
              :options="chartOptionsTurnOnTargetTurnOn"
              :series="seriesTurnOnTargetTurnOn"
              @dataPointSelection="dataPointSelectionHandlerTurnOnTargetTurnOn"
            >
              <!-- @dataPointSelection="dataPointSelectionHandlerBarMonthly" -->
            </apexchart></b-col
          ></b-row
        ></card
      >

      <b-row v-if="button_access.group_listing == 'YES'">
        <b-col lg="12">
          <project-group-listing-table></project-group-listing-table>
        </b-col>
      </b-row>

      <b-row v-if="button_access.site_listing == 'YES' && !isSolarNovaProject">
        <b-col lg="12">
          <project-site-listing-table></project-site-listing-table>
        </b-col>
      </b-row>

      <b-row
        v-if="
          button_access.project_task_listing == 'YES' && !isSolarNovaProject
        "
      >
        <b-col lg="12">
          <task-table-for-project
            @EventTaskListUpdate="afterUpdaterPojectTaskList()"
          ></task-table-for-project>
        </b-col>
      </b-row>

      <!-- <b-row v-if="button_access.project_document_listing == 'YES'">
        <b-col lg="12">
          <document-table-for-project
            @EventDocumentListUpdate="afterUpdateTaskList()"
          ></document-table-for-project>
        </b-col>
      </b-row> -->

      <b-row
        v-if="button_access.site_task_listing == 'YES' && !isSolarNovaProject"
      >
        <b-col lg="12">
          <project-site-task-listing-table
            @EventTaskListUpdate="afterUpdateTaskList()"
          ></project-site-task-listing-table>
        </b-col>
      </b-row>

      <!-- <b-row v-if="button_access.site_document_listing == 'YES'">
        <b-col lg="12">
          <site-project-document-listing-table
            @EventDocumentListUpdate="afterUpdateTaskList()"
          ></site-project-document-listing-table>
        </b-col>
      </b-row> -->
    </b-container>

    <modal :show.sync="modals.formTask" size="lg" body-classes="p-0">
      <card
        type="secondary"
        header-classes="bg-transparent pb-5"
        body-classes="px-lg-5 py-lg-5"
        class="border-0 mb-0"
      >
        <template>
          <div class="text-center text-muted mb-4">
            <h3>
              Populate / Import Task & Milestone Template into ( TRTP-2398 -
              Tengeh Reservoir Tender Project )
            </h3>
          </div>
          <b-form role="form">
            <base-input label="Populate To Target Site" name="Task Site">
              <select class="form-control" v-model="formModal.task_site">
                <option value="" disabled>
                  -- Please Select A Target Site --
                </option>
                <option>SN-NT - Tengeh Reservoir North Site</option>
                <option>SN-SH - Tengeh Reservoir South Site</option>
                <option>SN-ET - Tengeh Reservoir East Site</option>
                <option>SN-WT - Tengeh Reservoir West Site</option>
              </select>
            </base-input>
            <base-input label="Task Template" name="Task Template">
              <select class="form-control" v-model="formModal.task_template">
                <option value="" disabled>
                  -- Please Select A Task Template --
                </option>
                <option value="Solar Panel Project Template">
                  TS-SL-Temp : Solar Panel Project (No. of Task : 40)
                </option>
                <option value="Power Plant Project Template">
                  TS-PL-Temp :Power Plant Project (No. of Task : 120)
                </option>
              </select>
            </base-input>
            <base-input label="Task Template" name="Document Template">
              <select class="form-control">
                <option value="">
                  -- Please Select A Document Template --
                </option>
                <option value="Solar Panel Project Template">
                  DT-SL-Temp : Solar Panel Document Project Template (No. of
                  Classfication : 10)
                </option>
                <option value="Power Plant Project Template">
                  DT-PL-Temp : Power Plant Document Project Template (No. of
                  Classfication : 12)
                </option>
              </select>
            </base-input>
            <h5 class="text-danger">
              Note : <br />
              <i>
                1. Please Create a New Site before Import / Populate Task &
                Milestone into the Site (We Recommend to Import / Populate into
                a New Site).
                <br />
                2. Import / Populate Task into existing site will only create
                new task (All existing TASK will still be remain intact, But
                import twice might cause duplication).
                <br />
                3. All the Milestone will be Import / Populate TOGETHER
                according with the "Task Template".
                <br />
                4. To Change Milestone please proceed to Menu >> Template >>
                Milestone Template.
                <br />
                5. To Change Task please proceed to Menu >> Template >> Task
                Template.
                <br />
                6. Task Details in Template will not have the following column :
                <br />
                &nbsp;&nbsp;&nbsp; a) Project & Site.
                <br />
                &nbsp;&nbsp;&nbsp; b) Contractor.
                <br />
                &nbsp;&nbsp;&nbsp; c) Progress & Status.
                <br />
                &nbsp;&nbsp;&nbsp; d) Est Start , End Date & Start , End Date.
                <br />
                7. Only Column (a) & (b) can be selected during import. Please
                change column of (c) & (d) on each task later.
                <br />
                8. System will auto generate NEW TASK ID for all the TASK import
                / populate from Template. But Milestone CODE will remain the
                same.
              </i>
            </h5>
            <div class="text-center">
              <base-button
                type="primary"
                class="my-4"
                @click="notifyVueFormTask()"
                >Populate / Import Task Template</base-button
              >
              <base-button type="primary" class="my-4" @click="clearModal()"
                >Cancel</base-button
              >
            </div>
          </b-form>
        </template>
      </card>
    </modal>

    <modal :show.sync="modals.formMilestone" size="xl" body-classes="p-0">
      <card
        type="secondary"
        header-classes="bg-transparent pb-5"
        body-classes="px-lg-5 py-lg-5"
        class="border-0 mb-0"
      >
        <template>
          <div class="text-center text-muted mb-4">
            <h3>
              Milestone Template Import For ( TRTP-2398 - Tengeh Reservoir
              Tender Project )
            </h3>
          </div>
          <b-form role="form">
            <base-input label="Milestone Template" name="Milestone Template">
              <select
                class="form-control"
                v-model="formModal.milestone_template"
              >
                <option value="" disabled>
                  -- Please Select A Milestone Template --
                </option>
                <option value="Solar Panel Project Template">
                  MS-SL-Temp : Solar Panel Project (No. of Milestone : 6)
                </option>
                <option value="Power Plant Project Template">
                  MS-PL-Temp : Power Plant Project (No. of Milestone : 14)
                </option>
              </select>
            </base-input>
            <div class="text-center">
              <base-button
                type="primary"
                class="my-4"
                @click="notifyVueFormMilestone()"
                >Import Milestone Template</base-button
              >
              <base-button type="primary" class="my-4" @click="clearModal()"
                >Cancel</base-button
              >
            </div>
          </b-form>
        </template>
      </card>
    </modal>

    <modal
      :show.sync="isProjectMilestoneModalVisible"
      size="xl"
      body-classes="p-0"
    >
      <card
        type="secondary"
        header-classes="bg-transparent pb-5"
        body-classes="px-lg-5 py-lg-5"
        class="border-0 mb-0"
      >
        <template>
          <div class="text-left text-muted mb-4">
            <h3>Remarks for Project Milestone:</h3>
          </div>
          <div>{{ tempRemarks }}</div>
          <div class="text-center">
            <base-button
              type="primary"
              class="my-4"
              @click="closeProjectMilestoneModal()"
              >Cancel</base-button
            >
          </div>
        </template>
      </card>
    </modal>

    <modal
      :show.sync="isDeleteProjectModalVisible"
      size="xl"
      body-classes="p-0"
      title="Delete Project"
    >
      <card
        type="secondary"
        header-classes="bg-transparent pb-5"
        body-classes="px-lg-5 py-lg-5"
        class="border-0 mb-0"
      >
        <template>
          <div class="text-center text-muted mb-4">
            <h1>Delete Project</h1>
          </div>
          <div>{{ tempRemarks }}</div>
          <div class="text-center">
            <div>
              <br />Are you sure you want to permanently delete this project?
            </div>
            <div>
              <base-button
                type="danger"
                class="my-4"
                @click="showDeleteProjectModalInner()"
                >Delete</base-button
              >
              <base-button
                type="primary"
                class="my-4"
                @click="closeDeleteProjectModal()"
                >Cancel</base-button
              >
            </div>
          </div>
        </template>
      </card>
    </modal>

    <modal
      :show.sync="isDeleteProjectModalVisibleInner"
      size="xl"
      body-classes="p-0"
      title="Confirm Delete Project"
    >
      <card
        type="secondary"
        header-classes="bg-transparent pb-5"
        body-classes="px-lg-5 py-lg-5"
        class="border-0 mb-0"
      >
        <template>
          <div class="text-center text-muted mb-4">
            <h1>Confirm Delete Project</h1>
          </div>
          <div>{{ tempRemarks }}</div>
          <div class="text-center">
            <div>
              <br />Are you REALLY sure you want to permanently delete this
              project?
            </div>
            <div>
              <base-button
                type="primary"
                class="my-4"
                @click="closeDeleteProjectModalInner()"
                >Cancel</base-button
              >
              <base-button type="danger" class="my-4" @click="deleteProject()"
                >Delete</base-button
              >
            </div>
          </div>
        </template>
      </card>
    </modal>

    <modal
      :show.sync="isApprovePMOCModalVisible"
      size="xl"
      body-classes="p-0"
      title="PMOC Approvals"
    >
      <card
        type="secondary"
        header-classes="bg-transparent pb-5"
        body-classes="px-lg-5 py-lg-5"
        class="border-0 mb-0"
      >
        <template>
          <div class="text-center text-muted mb-4">
            <h1>PMOC Approvals</h1>
          </div>
          <div>{{ tempRemarks }}</div>
          <!-- <div class="text-center"> -->
          <div>
            <div><br>
              Current PPA Approved Capacity: {{pmoc.current_ppa_capacity}} kWp<br>
              Proposed PPA Approved Capacity: {{pmoc.proposed_ppa_capacity}} kWp<br>
              Planner Approval Status: {{pmoc.approval_pl}}
              <span v-if="pmoc.approval_pl == 'TBD' && myUserID.role_id == 19">
                &nbsp;&nbsp;&nbsp;&nbsp;
                <base-button size="sm" type="success" @click="approvePmocForRole(myUserID.role_id)">Approve</base-button>
                <base-button size="sm" type="danger" @click="rejectPmocForRole(myUserID.role_id)">Reject</base-button>
              </span><br>
              Customer Management Approval Status: {{pmoc.approval_cm}}
              <span v-if="pmoc.approval_cm == 'TBD' && myUserID.role_id == 20">
                &nbsp;&nbsp;&nbsp;&nbsp;
                <base-button size="sm" type="success" @click="approvePmocForRole(myUserID.role_id)">Approve</base-button>
                <base-button size="sm" type="danger" @click="rejectPmocForRole(myUserID.role_id)">Reject</base-button>
              </span><br>
              Procurement Approval Status: {{pmoc.approval_pr}}
              <!-- TBD WHAT ROLE IS PROCUREMENT(Purchasing) || 17 -->
              <span v-if="pmoc.approval_pr == 'TBD' && myUserID.role_id == 17">
                &nbsp;&nbsp;&nbsp;&nbsp;
                <base-button size="sm" type="success" @click="approvePmocForRole(myUserID.role_id)">Approve</base-button>
                <base-button size="sm" type="danger" @click="rejectPmocForRole(myUserID.role_id)">Reject</base-button>
              </span>
              <br>
            </div>
            <div class="text-center">
              <base-button
                type="primary"
                class="my-4"
                @click="closeApprovePMOCModal()"
                >Cancel</base-button
              >
            </div>
          </div>
        </template>
      </card>
    </modal>
  </div>
</template>

<script>
import RouteBreadCrumb from "@/components/Breadcrumb/RouteBreadcrumb";
import BaseHeader from "@/components/BaseHeader";
import ProjectViewForm from "./ProjectViewForm";

import ProjectManagementRemarks from "./ProjectManagementRemarks";
import ProjectProgressSN from "./ProjectProgressSN";

import LineChart from "@/components/Charts/LineChart";
import * as chartConfigs from "@/components/Charts/config";

import UserList from "./AdditionalTable/UserTable";
import ContractorList from "./AdditionalTable/ContractorTable";

import ActivityTable from "./AdditionalTable/ActivityTable";

// import TaskTable from './Task/TaskTable';
import MilestoneProgressTrackList from "./AdditionalTable/MilestoneProgressTrackList";
// import SiteTable from './Site/SiteTable';
import ProjectGroupListingTable from "./ProjectGroupListingTable";
import ProjectSiteListingTable from "./ProjectSiteListingTable";
import ProjectSiteTaskListingTable from "./ProjectSiteTaskListingTable";
import TaskTableForProject from "./../ProjectTask/ProjectTaskListingTable";
import DocumentTableForProject from "./../ProjectDocument/ProjectDocumentListingTable";
import SiteProjectDocumentListingTable from "./ProjectDocumentListingTable";

import {
  LoginURL,
  clientId,
  clientSecret,
  apiDomain,
  getHeader,
  getcurrentDate,
} from "@/assets/js/config.js";
import axios from "axios";
import VueApexCharts from "vue-apexcharts";
import moment from "moment";

import { Modal } from "@/components";
// import ProjectProgressSN from './ProjectProgressSN.vue';

export default {
  components: {
    BaseHeader,
    RouteBreadCrumb,
    ProjectViewForm,
    LineChart,
    UserList,
    ContractorList,
    ActivityTable,
    // TaskTable,
    MilestoneProgressTrackList,
    ProjectGroupListingTable,
    ProjectSiteListingTable,
    "project-site-task-listing-table": ProjectSiteTaskListingTable,
    "task-table-for-project": TaskTableForProject,
    "document-table-for-project": DocumentTableForProject,
    "site-project-document-listing-table": SiteProjectDocumentListingTable,
    "project-management-remarks": ProjectManagementRemarks,
    "project-progress-sn": ProjectProgressSN,
    Modal,
    apexchart: VueApexCharts,
  },
  created() {
    var project_access = JSON.parse(
      window.localStorage.getItem("project_access")
    );
    this.button_access.project_delete = project_access.access_delete;

    var project_task_access = JSON.parse(
      window.localStorage.getItem("project_task_access")
    );
    this.button_access.project_task_listing =
      project_task_access.access_listing;
    this.button_access.project_task_new = project_task_access.access_create;

    var project_document_access = JSON.parse(
      window.localStorage.getItem("project_document_access")
    );
    this.button_access.project_document_listing =
      project_document_access.access_listing;
    this.button_access.project_document_new =
      project_document_access.access_create;

    var site_task_access = JSON.parse(
      window.localStorage.getItem("site_task_access")
    );
    this.button_access.site_task_listing = site_task_access.access_listing;
    this.button_access.site_task_new = site_task_access.access_create;

    var site_document_access = JSON.parse(
      window.localStorage.getItem("site_document_access")
    );
    this.button_access.site_document_listing =
      site_document_access.access_listing;
    this.button_access.site_document_new = site_document_access.access_create;

    var project_group_access = JSON.parse(
      window.localStorage.getItem("project_group_access")
    );
    this.button_access.group_listing = project_group_access.access_listing;
    this.button_access.group_new = project_group_access.access_create;

    var project_site_access = JSON.parse(
      window.localStorage.getItem("project_site_access")
    );
    this.button_access.site_listing = project_site_access.access_listing;
    this.button_access.site_new = project_site_access.access_create;

    const LoginUser = JSON.parse(window.localStorage.getItem("loginUser"));
    this.myUserID = LoginUser;

    const tokenData = JSON.parse(window.localStorage.getItem("authUser"));
    this.tokenDataSaved = tokenData;

    this.getDashboardData();

    this.getTotalSite();

    this.updateSCurve();

    // this.getPmocData();

    if (!this.isSolarNovaProject) {
      this.updateGantt();
    }

    // this.getProjectMilestoneRemarks();
  },
  data() {
    return {
      isSolarNovaProject: false,
      projectMilestoneRemarks: [],
      tempDataPointIndex: null,
      tempRemarks: null,
      tempData: [0, 1, 2, 3],
      isProjectMilestoneModalVisible: false,
      isApprovePMOCModalVisible: false,
      isDeleteProjectModalVisible: false,
      isDeleteProjectModalVisibleInner: false,
      ganttMilestoneTempStorage: [],
      ganttStartEndTempStorage: [],
      ganttMilestoneTempStorageRev: [],
      firstLast: [],
      chartOptions: {
        chart: {
          type: "rangeBar",
        },
        plotOptions: {
          bar: {
            horizontal: true,
            barHeight: "50%",
            rangeBarGroupRows: true,
          },
        },
        dataLabels: {
          enabled: true,
          formatter: function (value, { seriesIndex, dataPointIndex, w }) {
            var progress =
              w.config.series[seriesIndex].data[dataPointIndex].extra[0];
            if (progress != null) {
              return progress + "%";
            } else {
              return "";
            }
          },
          style: {
            colors: ["#f3f4f5", "#fff"],
          },
        },
        colors: ["#00E396", "#FEB019", "#FF4560", "#008FFB"],
        stroke: {
          width: 1,
          colors: ["#fff"],
        },
        fill: {
          type: "solid",
        },
        xaxis: {
          type: "datetime",
          tickAmount: 24,
          tickPlacement: "on",
          min: 1609459200000,
          max: 1672487999000,
          position: "top",
          labels: {
            datetimeFormatter: {
              month: "MMM 'yy",
            },
            offsetX: 0,
          },
          axisTicks: {
            offsetX: 0,
          },
        },
        legend: {
          position: "top",
        },
        tooltip: {
          enabled: true,
          shared: false,
          theme: "light",
          x: {
            show: true,
            formatter: function ({ series, seriesIndex, dataPointIndex, w }) {
              return " ";
            },
          },
          y: {
            formatter: function (
              value,
              { series, seriesIndex, dataPointIndex, w }
            ) {
              var startJsTime = new Date(
                w.config.series[0].data[dataPointIndex].y[0]
              );
              var endJsTime = new Date(
                w.config.series[3].data[dataPointIndex].y[1]
              );

              return (
                '<div class="arrow_box">' +
                "<span>" +
                "Start Date: " +
                moment(startJsTime).format("DD-MM-YYYY") +
                "   End Date: " +
                moment(endJsTime).format("DD-MM-YYYY") +
                "</span>" +
                "</div>"
              );
            },
            title: {
              formatter: function (
                value,
                { series, seriesIndex, dataPointIndex, w }
              ) {
                return w.config.series[seriesIndex].data[dataPointIndex].x;
              },
            },
          },
        },
        theme: {
          mode: "dark",
          monochrome: {
            enabled: false,
            color: "#255aee",
            shadeTo: "light",
            shadeIntensity: 0.65,
          },
        },
      },
      series: [
        {
          name: "COMPLETED",
          data: [
            {
              x: "Design",
              y: [],
              extra: [],
            },
            {
              x: "Authority Submission",
              y: [],
              extra: [],
            },
            {
              x: "Procurement",
              y: [],
              extra: [],
            },
            {
              x: "Hoisting",
              y: [],
              extra: [],
            },
            {
              x: "Installation",
              y: [],
              extra: [],
            },
            {
              x: "Testing & Commissioning",
              y: [],
              extra: [],
            },
            {
              x: "Turn-On",
              y: [],
              extra: [],
            },
            {
              x: "Handover",
              y: [],
              extra: [],
            },
          ],
        },
        {
          name: "ON TRACK",
          data: [
            {
              x: "Design",
              y: [],
              extra: [],
            },
            {
              x: "Authority Submission",
              y: [],
              extra: [],
            },
            {
              x: "Procurement",
              y: [],
              extra: [],
            },
            {
              x: "Hoisting",
              y: [],
              extra: [],
            },
            {
              x: "Installation",
              y: [],
              extra: [],
            },
            {
              x: "Testing & Commissioning",
              y: [],
              extra: [],
            },
            {
              x: "Turn-On",
              y: [],
              extra: [],
            },
            {
              x: "Handover",
              y: [],
              extra: [],
            },
          ],
        },
        {
          name: "AT RISK DELAYED",
          data: [
            {
              x: "Design",
              y: [],
              extra: [],
            },
            {
              x: "Authority Submission",
              y: [],
              extra: [50],
            },
            {
              x: "Procurement",
              y: [],
              extra: [],
            },
            {
              x: "Hoisting",
              y: [],
              extra: [],
            },
            {
              x: "Installation",
              y: [],
              extra: [],
            },
            {
              x: "Testing & Commissioning",
              y: [],
              extra: [],
            },
            {
              x: "Turn-On",
              y: [],
              extra: [],
            },
            {
              x: "Handover",
              y: [],
              extra: [],
            },
          ],
        },
        {
          name: "UNFINISHED SITE DOCUMENTS",
          data: [
            {
              x: "Design",
              y: [],
              extra: [],
            },
            {
              x: "Authority Submission",
              y: [],
              extra: [],
            },
            {
              x: "Procurement",
              y: [],
              extra: [],
            },
            {
              x: "Hoisting",
              y: [],
              extra: [],
            },
            {
              x: "Installation",
              y: [],
              extra: [],
            },
            {
              x: "Testing & Commissioning",
              y: [],
              extra: [],
            },
            {
              x: "Turn-On",
              y: [],
              extra: [],
            },
            {
              x: "Handover",
              y: [],
              extra: [],
            },
          ],
        },
      ],
      myUserID: null,
      button_access: {
        project_delete: null,
        project_task_listing: null,
        project_task_new: null,
        project_document_listing: null,
        project_document_new: null,
        site_task_listing: null,
        site_task_new: null,
        site_document_listing: null,
        site_document_new: null,
        group_listing: null,
        group_new: null,
        site_listing: null,
        site_new: null,
      },
      model: {
        project_id: null,
        project_name: null,
        project_name: null,
        project_total_installation: 0,
        project_total_capacity: 0,
        project_completed_capacity: 0,
        project_total_task: 0,
        project_completed_task: 0,
        project_total_document: 0,
        project_completed_document: 0,
        project_progress: 0,
        project_level_total_task: 0,
        project_level_completed_task: 0,
        project_level_total_document: 0,
        project_level_completed_document: 0,
        project_type: {
          project_type_id: null,
        },
        developer: {
          developer_code: null,
        },
        status: {
          status_id: null,
          status_code: null,
        },
        project_manager: {
          name: null,
          email: null,
        },
      },

      project_sites: [],
      modals: {
        formTask: false,
        formMilestone: false,
      },
      formModal: {
        task_template: "",
        task_site: "",
        milestone_template: "",
      },
      bigLineChart: {
        allData: [
          [0, 20, 10, 30, 15, 40, 20, 60, 60],
          [0, 20, 5, 25, 10, 30, 15, 40, 40],
        ],
        activeIndex: 0,
        chartData: {
          datasets: [
            {
              label: "Estimated",
              data: [],
              borderColor: "#f5365c",
              backgroundColor: "#f5365c",
            },
            {
              label: "Actual",
              data: [],
              borderColor: "#f5365c",
              backgroundColor: "#f5365c",
            },
          ],
        },
        extraOptions: chartConfigs.blueChartOptions,
      },
      tempTotalSitesMilestoneResponse: null,
      chartOptionsTotalSitesIncomplete: {
        chart: {
          type: "pie",
        },
        theme: {
          mode: "dark",
        },
        labels: [],
        responsive: [
          {
            options: {
              chart: {
                width: 200,
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
        colors: [
          "#84acb6",
          "#3494ba",
          "#75bda7",
          "#7a8c8e",
          "#84acb6",
          "#2683c6de",
        ],
        tooltip: {
          y: {
            formatter: function (val) {
              return val + " kWp";
            },
          },
        },
      },
      seriesTotalSitesIncomplete: [],
      chartOptionsTurnOnInProgressKwp: {
        chart: {
          type: "pie",
        },
        theme: {
          mode: "dark",
        },
        labels: ["Turn On", "In Progress"],
        responsive: [
          {
            options: {
              chart: {
                width: 200,
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
        tooltip: {
          y: {
            formatter: function (val) {
              return val + " kWp";
            },
          },
        },
      },
      chartOptionsTurnOnTargetTurnOn: {
        chart: {
          type: "bar",
          // height: 350,
        },
        colors: [
          // "#76801f",
          "#3494ba",
        ],
        theme: {
          mode: "dark",
        },
        plotOptions: {
          // bar: {
          //   horizontal: false,
          //   columnWidth: "55%",
          //   endingShape: "rounded",
          // },
        },
        dataLabels: {
          enabled: false,
        },
        // legend: { labels: { colors: "#FFFFFF" } },
        stroke: {
          show: true,
          width: 1,
          // colors: ["#fff"],
          // width: 2,
          // colors: ["transparent"],
        },
        xaxis: {
          categories: [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec",
          ],
          title: {
            text: "MONTHS",
            style: {
              // color: "#55c8e9",
              fontSize: "16px",
            },
          },
          labels: {
            style: {
              colors: [
                // "#FFFFFF",
                // "#FFFFFF",
                // "#FFFFFF",
                // "#FFFFFF",
                // "#FFFFFF",
                // "#FFFFFF",
                // "#FFFFFF",
                // "#FFFFFF",
                // "#FFFFFF",
                // "#FFFFFF",
                // "#FFFFFF",
                // "#FFFFFF",
              ],
            },
          },
        },
        yaxis: {
          title: {
            text: "CAPACITY (KWP)",
            style: {
              // color: "#55c8e9",
              fontSize: "16px",
            },
          },
          // labels: { style: { colors: ["#FFFFFF"] } },
        },
        fill: {
          opacity: 1,
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return val + " kWp";
            },
            title: {
              formatter: function (val) {
                return "Capacity:";
              },
            },
            // formatter: function (val) {
            //   return "$ " + val + " thousands";
            // },
          },
        },
      },
      seriesTurnOnTargetTurnOn: [
        {
          data: [
            // 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12
          ],
        },
        // {
        //   name: "C&I",
        //   data: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
        // },
        // {
        //   name: "Solar Nova",
        //   data: [12, 11, 10, 9, 8, 7, 6, 5, 4, 3, 2, 1],
        // },
      ],
      seriesTurnOnInProgressKwp: [],
      tempTurnOnInProgressKwpResponse: null,
      tempTurnOnTargetTurnOnResponse: null,
      tempTurnOnTargetTurnOnCategories: null,
      tempPrintOut: null,
      tempPrintOut2: null,
      pmocResponse: null,
      pmoc: {
        project_pmoc_id: null,
        current_ppa_capacity: null,
        proposed_ppa_capacity: null,
        approval_pl: null,
        approval_cm: null,
        approval_pr: null,
      },
      pmoc_model: {
        project_pmoc_id: null,
        approver_role_id: null,
        approval: null,
      },
      tokenDataSaved: null,
    };
  },
  methods: {
    downloadPacPdf() {
      // const tokenData = JSON.parse(window.localStorage.getItem("authUser"));
      // const headers = {
      //   Accept: "application/json",
      //   "Content-Type": "multipart/form-data",
      //   Authorization: "Bearer " + tokenData.access_token,
      // };

      axios.get(apiDomain + "project/project.pac_pdf/" + this.model.project_id, { headers: getHeader(), responseType: 'blob' })
      .then((response) => {
        if (response.status === 200) {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'pac_file.pdf'); //or any other extension
          document.body.appendChild(link);
          link.click();
        }
      })
      .catch((error) => {
        console.log(error.response.status);
      });
    },
    approvePmocForRole(approverRoleId) {
      this.pmoc_model.project_pmoc_id = this.pmoc.project_pmoc_id;
      this.pmoc_model.approver_role_id = approverRoleId;
      this.pmoc_model.approval = 1;

      axios.post(apiDomain + 'project/project.pmoc_update_approval/' + this.model.project_id, this.pmoc_model, { headers: getHeader() })
      .then ( response => {
        if (response.status === 200)
        {
            this.$notify({
            message:
              '<b>Update Project PMOC Approval : ' + this.model.project_name + '</b> - Successfully Updated.',
            timeout: 10000,
            icon: 'ni ni-bell-55',
            type: 'default',
            });
            this.$router.go()
        }
      })
      .catch ( error => {
          console.log(error.response.data.errors)
          this.errorFunction(error, "Update Project PMOC Approval")
      });
    },
    rejectPmocForRole(approverRoleId) {
      this.pmoc_model.project_pmoc_id = this.pmoc.project_pmoc_id;
      this.pmoc_model.approver_role_id = approverRoleId;
      this.pmoc_model.approval = 0;

      axios.post(apiDomain + 'project/project.pmoc_update_approval/' + this.model.project_id, this.pmoc_model, { headers: getHeader() })
      .then ( response => {
        if (response.status === 200)
        {
            this.$notify({
            message:
              '<b>Update Project PMOC Approval : ' + this.model.project_name + '</b> - Successfully Updated.',
            timeout: 10000,
            icon: 'ni ni-bell-55',
            type: 'default',
            });
            this.$router.go()
        }
      })
      .catch ( error => {
          console.log(error.response.data.errors)
          this.errorFunction(error, "Update Project PMOC Approval")
      });
    },
    convertPmocStatus(inputStatusCode) {
      if (inputStatusCode == 1) {
        return "Approved";
      } else if (inputStatusCode == 0) {
        return "Rejected";
      } else {
        return "TBD";
      }
    },
    getPmocData() {
      axios.get(apiDomain + "project/project.pmoc/" + this.model.project_id, { headers: getHeader() })
      .then((response) => {
        if (response.status === 200) {
          this.pmocResponse = response.data.data;
          this.pmoc.project_pmoc_id = response.data.data['project_pmoc_id'];
          this.pmoc.current_ppa_capacity = response.data.data['current_ppa_capacity'];
          this.pmoc.proposed_ppa_capacity = response.data.data['proposed_ppa_capacity'];
          this.pmoc.approval_pl = this.convertPmocStatus(response.data.data['approval_pl']);
          this.pmoc.approval_cm = this.convertPmocStatus(response.data.data['approval_cm']);
          this.pmoc.approval_pr = this.convertPmocStatus(response.data.data['approval_pr']);
        }
      })
      .catch((error) => {
        console.log(error.response.status);
      });
    },
    // dataPointSelectionHandlerTurnOnTargetTurnOn
    // dataPointSelectionHandlerBarMonthly(e, chartContext, config) {
    //   var deductMonth = 12 - (config.dataPointIndex + 1);
    //   var inputDate = new Date(this.model.end_date);
    //   inputDate.setMonth(inputDate.getMonth() - deductMonth);
    //   var queryYear = inputDate.toLocaleString("default", { year: "numeric" });
    //   var queryMonth = inputDate.toLocaleString("default", {
    //     month: "2-digit",
    //   });
    dataPointSelectionHandlerTurnOnTargetTurnOn(e, chartContext, config) {
      var currentDate = getcurrentDate();
      var localTempDate = new Date(currentDate);
      var queryYear = null;
      var queryMonth = null;
      this.tempPrintOut = config.dataPointIndex;
      if (config.dataPointIndex <= 5) {
        // based on status = approved and site_approved_date
        // turn on
        localTempDate.setMonth(
          localTempDate.getMonth() - config.dataPointIndex + 6
        );
        var queryYear = localTempDate.getFullYear().toString();
        var queryMonth = localTempDate.getMonth().toString();
        this.tempPrintOut2 = queryYear + " " + queryMonth;
        // projectSiteListingByProjectMonthlyTurnOn
        let route = this.$router.resolve({
          path:
            "/projectSiteListingByProjectMonthlyTurnOn/" +
            this.model.project_id +
            "/" +
            queryYear +
            "/" +
            queryMonth,
        });
        window.open(route.href, "_blank");
      } else {
        // based on status != approved and site_target_turn_on_date
        // target turn on
        localTempDate.setMonth(
          localTempDate.getMonth() + config.dataPointIndex - 4
        );
        // IF PAST YEAR, FIX LOGIC
        var queryYear = localTempDate.getFullYear().toString();
        var queryMonth = localTempDate.getMonth().toString();
        this.tempPrintOut2 = queryYear + " " + queryMonth;
        let route = this.$router.resolve({
          path:
            "/projectSiteListingByProjectMonthlyTargetTurnOn/" +
            this.model.project_id +
            "/" +
            queryYear +
            "/" +
            queryMonth,
        });
        window.open(route.href, "_blank");
      }
    },
    dataPointSelectionHandlerTurnOnInProgressKwp(e, chartContext, config) {
      if (config.dataPointIndex == 0) {
        // 0 = Turn On
        let route = this.$router.resolve({
          path: "/projectSiteListingByProjectTurnOn/" + this.model.project_id,
        });
        window.open(route.href, "_blank");
      } else if (config.dataPointIndex == 1) {
        // 1 = In Progress
        let route = this.$router.resolve({
          path:
            "/projectSiteListingByProjectInProgress/" + this.model.project_id,
        });
        window.open(route.href, "_blank");
      }
    },
    updateTurnOnTargetTurnOnCategories() {
      var inputDate = getcurrentDate();
      var localTempDate = new Date(inputDate);
      // set six months later
      localTempDate.setMonth(localTempDate.getMonth() + 6);
      var tempArray = [];

      var tempMonth = localTempDate.toLocaleString("default", {
        month: "short",
      });
      var tempYear = localTempDate.toLocaleString("default", {
        year: "2-digit",
      });
      var monthYearString = tempMonth + " '" + tempYear;
      tempArray.push(monthYearString);

      for (let i = 1; i < 12; i++) {
        localTempDate.setMonth(localTempDate.getMonth() - 1);
        var tempMonth = localTempDate.toLocaleString("default", {
          month: "short",
        });
        var tempYear = localTempDate.toLocaleString("default", {
          year: "2-digit",
        });
        var monthYearString = tempMonth + " '" + tempYear;
        tempArray.push(monthYearString);
      }

      // this.tempTurnOnTargetTurnOnCategories = tempArray.reverse();

      this.chartOptionsTurnOnTargetTurnOn = {
        xaxis: {
          categories: tempArray.reverse(),
        },
      };
    },
    getTurnOnTargetTurnOn() {
      this.model.project_id = this.$route.params.id;
      var currentDate = getcurrentDate();

      axios
        .get(
          apiDomain +
            "project/project.turn_on_target_turn_on.show_by_project/" +
            this.model.project_id +
            "/" +
            currentDate,
          { headers: getHeader() }
        )
        .then((response) => {
          if (response.status === 200) {
            this.tempTurnOnTargetTurnOnResponse = response.data.data;
            var tempMonthlyArray = [];
            for (const [key, value] of Object.entries(response.data.data[0])) {
              tempMonthlyArray.push(value);
            }
            this.seriesTurnOnTargetTurnOn = [
              {
                // name: "Turn On / Target Turn On",
                data: tempMonthlyArray,
              },
            ];
            this.updateTurnOnTargetTurnOnCategories();
          }
        })
        .catch((error) => {
          console.log(error.response.status);
        });
    },

    getTurnOnInProgressKwp() {
      this.model.project_id = this.$route.params.id;

      axios
        .get(
          apiDomain +
            "project/project.turn_on_in_progress_kwp/" +
            this.model.project_id,
          { headers: getHeader() }
        )
        .then((response) => {
          if (response.status === 200) {
            var tempSeriesArray = [];
            this.tempTurnOnInProgressKwpResponse = response.data.data;
            tempSeriesArray.push(
              this.tempTurnOnInProgressKwpResponse[0]["turn_on_kwp_in_project"]
            );
            tempSeriesArray.push(
              this.tempTurnOnInProgressKwpResponse[0][
                "in_progress_kwp_in_project"
              ]
            );
            this.seriesTurnOnInProgressKwp = tempSeriesArray;
          }
        })
        .catch((error) => {
          console.log(error.response.status);
        });
    },
    updateIncompleteSitesPieChart() {
      var tempLabelsArray = [];
      var tempSeriesArray = [];
      this.tempTotalSitesMilestoneResponse.forEach((e) => {
        tempLabelsArray.push(e["milestone_template_title"]);
        tempSeriesArray.push(e["site_milestone_kwp_in_progress"]);
      });
      this.chartOptionsTotalSitesIncomplete = {
        labels: tempLabelsArray,
      };
      this.seriesTotalSitesIncomplete = tempSeriesArray;
    },
    getIncompleteSitesDataListing() {
      this.model.project_id = this.$route.params.id;

      axios
        .get(
          apiDomain +
            "project/project_site_milestone.show_by_project/" +
            this.model.project_id,
          { headers: getHeader() }
        )
        .then((response) => {
          if (response.status === 200) {
            this.tempTotalSitesMilestoneResponse = response.data.data;
            this.updateIncompleteSitesPieChart();
            // this.updateSiteIncompletePieChart();
          }
        })
        .catch((error) => {
          console.log(error.response.status);
        });
    },
    routeToProjectImportNewProjectSite() {
      this.$router.push({
        path: "/projectImportProjectSite/" + this.model.project_id,
      });
    },
    checkIfSolarNovaProject() {
      if (
        this.model.project_type.project_type_id != null &&
        ([2, 3, 4, 9, 10, 11, 12, 13, 14].includes(this.model.project_type.project_type_id))
        // (this.model.project_type.project_type_id == 2 ||
        //   this.model.project_type.project_type_id == 3 ||
        //   this.model.project_type.project_type_id == 4)
      ) {
        this.isSolarNovaProject = true;
        this.getTurnOnInProgressKwp();
        this.getIncompleteSitesDataListing();
        this.getTurnOnTargetTurnOn();
      }
    },
    dataPointSelectionHandler(e, chartContext, config) {
      this.tempData = [
        config.seriesIndex,
        config.dataPointIndex,
        this.series[config.seriesIndex].name,
        this.series[config.seriesIndex].data[config.dataPointIndex].x,
      ];
      this.tempDataPointIndex = config.dataPointIndex;
      this.getProjectMilestoneRemarks();
    },
    redirectToProjectListing() {
      this.$router.push({
        path: "/projectListing",
      });
    },
    deleteProject() {
      axios
        .delete(apiDomain + "project/project/" + this.model.project_id, {
          headers: getHeader(),
        })
        .then((response) => {
          if (response.status === 200) {
            this.$notify({
              message:
                "<b>Project: " +
                this.model.project_name +
                "</b> - Successfully Deleted.",
              timeout: 10000,
              icon: "ni ni-bell-55",
              type: "default",
            });
            this.redirectToProjectListing();
          }
        })
        .catch((error) => {
          console.log(error.response.status);
        });
    },
    showApprovePMOCModal() {
      this.isApprovePMOCModalVisible = true;
    },
    closeApprovePMOCModal() {
      this.isApprovePMOCModalVisible = false;
    },
    showDeleteProjectModal() {
      this.isDeleteProjectModalVisible = true;
    },
    closeDeleteProjectModal() {
      this.isDeleteProjectModalVisible = false;
    },
    showDeleteProjectModalInner() {
      this.isDeleteProjectModalVisible = false;
      this.isDeleteProjectModalVisibleInner = true;
    },
    closeDeleteProjectModalInner() {
      this.isDeleteProjectModalVisibleInner = false;
    },
    showProjectMilestoneModal() {
      this.isProjectMilestoneModalVisible = true;
    },
    closeProjectMilestoneModal() {
      this.isProjectMilestoneModalVisible = false;
    },
    getProjectMilestoneRemarks() {
      axios
        .get(
          apiDomain +
            "project/project_milestone_remarks.show_by_project/" +
            this.model.project_id,
          { headers: getHeader() }
        )
        .then((response) => {
          if (response.status === 200) {
            this.projectMilestoneRemarks = response.data.data;
            this.tempRemarks =
              this.projectMilestoneRemarks[
                this.tempDataPointIndex
              ].milestone_remarks;
            this.showProjectMilestoneModal();
          }
        })
        .catch((error) => {
          console.log(error.response.status);
        });
    },
    getFirstDate() {
      var res = null;
      if (
        this.ganttStartEndTempStorage[0].milestone_1_task_est_start_date >
        this.ganttStartEndTempStorage[0].milestone_2_task_est_start_date
      ) {
        res = this.ganttStartEndTempStorage[0].milestone_2_task_est_start_date;
      } else {
        res = this.ganttStartEndTempStorage[0].milestone_1_task_est_start_date;
      }
      return res;
    },
    updateChartMinMax() {
      var firstDate = this.getFirstDate();
      firstDate = firstDate + "000";
      var lastDate =
        this.ganttStartEndTempStorage[0].milestone_8_task_est_end_date;
      lastDate = lastDate + "000";
      var firstMonth = moment(parseInt(firstDate)).format("MM YYYY");
      var lastMonth = moment(parseInt(lastDate)).format("MM YYYY");
      lastMonth = moment(lastMonth, "MM YYYY").add(1, "M").format("MM YYYY");

      this.firstLast = [firstMonth, lastMonth];

      var firstMonthUnix = moment(firstMonth, "MM YYYY").valueOf();
      var lastMonthUnix = moment(lastMonth, "MM YYYY").valueOf();

      var monthDifference = moment(lastMonthUnix).diff(
        firstMonthUnix,
        "months",
        true
      );

      var newTickAmount = parseInt(monthDifference) + 1;

      this.chartOptions = {
        xaxis: {
          tickAmount: newTickAmount,
          min: firstMonthUnix,
          max: lastMonthUnix,
        },
      };
    },
    calculateStops(
      startUnixTime,
      endUnixTime,
      completedRatio,
      onTrackRatio,
      atRiskDelayedRatio,
      unfinishedSiteDocumentsRatio
    ) {
      // var durTotal = parseInt(endUnixTime) - parseInt(startUnixTime);
      // var difference1 = (durTotal * parseInt(completedRatio)) / 100;
      // var firstStop = difference1 + parseInt(startUnixTime);
      // var durRemaining1 = parseInt(endUnixTime) - firstStop;
      // var difference2 = (durRemaining1 * parseInt(onTrackRatio)) / 100;
      // var secondStop = difference2 + parseInt(firstStop);
      // var durRemaining2 = parseInt(endUnixTime) - secondStop;
      // var difference3 = (durRemaining2 * parseInt(atRiskDelayedRatio)) / 100;
      // var thirdStop = difference3 + parseInt(secondStop);

      var durTotal = parseInt(endUnixTime) - parseInt(startUnixTime);
      var difference1 = (durTotal * parseInt(completedRatio)) / 100;
      var firstStop = difference1 + parseInt(startUnixTime);
      // var durRemaining1 = parseInt(endUnixTime) - firstStop;
      var difference2 = (durTotal * parseInt(onTrackRatio)) / 100;
      var secondStop = difference2 + parseInt(firstStop);
      // var durRemaining2 = parseInt(endUnixTime) - secondStop;
      var difference3 = (durTotal * parseInt(atRiskDelayedRatio)) / 100;
      var thirdStop = difference3 + parseInt(secondStop);

      var endUnixTimeChecked = endUnixTime;

      if (unfinishedSiteDocumentsRatio == 0) {
        endUnixTimeChecked = thirdStop;
      }

      return [
        startUnixTime,
        firstStop,
        secondStop,
        thirdStop,
        endUnixTimeChecked,
      ];
    },
    updateThis() {
      var dataDesign = [
        this.ganttStartEndTempStorage[0].milestone_1_task_est_start_date +
          "000",
        this.ganttStartEndTempStorage[0].milestone_1_task_est_end_date + "000",
        this.ganttMilestoneTempStorageRev[0].completed_ratio,
        this.ganttMilestoneTempStorageRev[0].on_track_ratio,
        this.ganttMilestoneTempStorageRev[0].at_risk_delayed_ratio,
        this.ganttMilestoneTempStorageRev[0].unfinished_site_documents_ratio,
      ];
      var stopsDesign = this.calculateStops(
        dataDesign[0],
        dataDesign[1],
        dataDesign[2],
        dataDesign[3],
        dataDesign[4],
        dataDesign[5]
      );

      var dataAuthority = [
        this.ganttStartEndTempStorage[0].milestone_2_task_est_start_date +
          "000",
        this.ganttStartEndTempStorage[0].milestone_2_task_est_end_date + "000",
        this.ganttMilestoneTempStorageRev[1].completed_ratio,
        this.ganttMilestoneTempStorageRev[1].on_track_ratio,
        this.ganttMilestoneTempStorageRev[1].at_risk_delayed_ratio,
        this.ganttMilestoneTempStorageRev[1].unfinished_site_documents_ratio,
      ];
      var stopsAuthority = this.calculateStops(
        dataAuthority[0],
        dataAuthority[1],
        dataAuthority[2],
        dataAuthority[3],
        dataAuthority[4],
        dataAuthority[5]
      );

      var dataProcurement = [
        this.ganttStartEndTempStorage[0].milestone_3_task_est_start_date +
          "000",
        this.ganttStartEndTempStorage[0].milestone_3_task_est_end_date + "000",
        this.ganttMilestoneTempStorageRev[2].completed_ratio,
        this.ganttMilestoneTempStorageRev[2].on_track_ratio,
        this.ganttMilestoneTempStorageRev[2].at_risk_delayed_ratio,
        this.ganttMilestoneTempStorageRev[2].unfinished_site_documents_ratio,
      ];
      var stopsProcurement = this.calculateStops(
        dataProcurement[0],
        dataProcurement[1],
        dataProcurement[2],
        dataProcurement[3],
        dataProcurement[4],
        dataProcurement[5]
      );

      var dataHoisting = [
        this.ganttStartEndTempStorage[0].milestone_4_task_est_start_date +
          "000",
        this.ganttStartEndTempStorage[0].milestone_4_task_est_end_date + "000",
        this.ganttMilestoneTempStorageRev[3].completed_ratio,
        this.ganttMilestoneTempStorageRev[3].on_track_ratio,
        this.ganttMilestoneTempStorageRev[3].at_risk_delayed_ratio,
        this.ganttMilestoneTempStorageRev[3].unfinished_site_documents_ratio,
      ];
      var stopsHoisting = this.calculateStops(
        dataHoisting[0],
        dataHoisting[1],
        dataHoisting[2],
        dataHoisting[3],
        dataHoisting[4],
        dataHoisting[5]
      );

      var dataInstallation = [
        this.ganttStartEndTempStorage[0].milestone_5_task_est_start_date +
          "000",
        this.ganttStartEndTempStorage[0].milestone_5_task_est_end_date + "000",
        this.ganttMilestoneTempStorageRev[4].completed_ratio,
        this.ganttMilestoneTempStorageRev[4].on_track_ratio,
        this.ganttMilestoneTempStorageRev[4].at_risk_delayed_ratio,
        this.ganttMilestoneTempStorageRev[4].unfinished_site_documents_ratio,
      ];
      var stopsInstallation = this.calculateStops(
        dataInstallation[0],
        dataInstallation[1],
        dataInstallation[2],
        dataInstallation[3],
        dataInstallation[4],
        dataInstallation[5]
      );

      var dataTesting = [
        this.ganttStartEndTempStorage[0].milestone_6_task_est_start_date +
          "000",
        this.ganttStartEndTempStorage[0].milestone_6_task_est_end_date + "000",
        this.ganttMilestoneTempStorageRev[5].completed_ratio,
        this.ganttMilestoneTempStorageRev[5].on_track_ratio,
        this.ganttMilestoneTempStorageRev[5].at_risk_delayed_ratio,
        this.ganttMilestoneTempStorageRev[5].unfinished_site_documents_ratio,
      ];
      var stopsTesting = this.calculateStops(
        dataTesting[0],
        dataTesting[1],
        dataTesting[2],
        dataTesting[3],
        dataTesting[4],
        dataTesting[5]
      );

      var dataTurnOn = [
        this.ganttStartEndTempStorage[0].milestone_7_task_est_start_date +
          "000",
        this.ganttStartEndTempStorage[0].milestone_7_task_est_end_date + "000",
        this.ganttMilestoneTempStorageRev[6].completed_ratio,
        this.ganttMilestoneTempStorageRev[6].on_track_ratio,
        this.ganttMilestoneTempStorageRev[6].at_risk_delayed_ratio,
        this.ganttMilestoneTempStorageRev[6].unfinished_site_documents_ratio,
      ];
      var stopsTurnOn = this.calculateStops(
        dataTurnOn[0],
        dataTurnOn[1],
        dataTurnOn[2],
        dataTurnOn[3],
        dataTurnOn[4],
        dataTurnOn[5]
      );

      var dataHandover = [
        this.ganttStartEndTempStorage[0].milestone_8_task_est_start_date +
          "000",
        this.ganttStartEndTempStorage[0].milestone_8_task_est_end_date + "000",
        this.ganttMilestoneTempStorageRev[7].completed_ratio,
        this.ganttMilestoneTempStorageRev[7].on_track_ratio,
        this.ganttMilestoneTempStorageRev[7].at_risk_delayed_ratio,
        this.ganttMilestoneTempStorageRev[7].unfinished_site_documents_ratio,
      ];
      var stopsHandover = this.calculateStops(
        dataHandover[0],
        dataHandover[1],
        dataHandover[2],
        dataHandover[3],
        dataHandover[4],
        dataHandover[5]
      );

      (this.series[0].data[0].y = [
        parseInt(stopsDesign[0]),
        parseInt(stopsDesign[1]),
      ]),
        (this.series[1].data[0].y = [
          parseInt(stopsDesign[1]),
          parseInt(stopsDesign[2]),
        ]),
        (this.series[2].data[0].y = [
          parseInt(stopsDesign[2]),
          parseInt(stopsDesign[3]),
        ]),
        (this.series[3].data[0].y = [
          parseInt(stopsDesign[3]),
          parseInt(stopsDesign[4]),
        ]),
        (this.series[0].data[1].y = [
          parseInt(stopsAuthority[0]),
          parseInt(stopsAuthority[1]),
        ]),
        (this.series[1].data[1].y = [
          parseInt(stopsAuthority[1]),
          parseInt(stopsAuthority[2]),
        ]),
        (this.series[2].data[1].y = [
          parseInt(stopsAuthority[2]),
          parseInt(stopsAuthority[3]),
        ]),
        (this.series[3].data[1].y = [
          parseInt(stopsAuthority[3]),
          parseInt(stopsAuthority[4]),
        ]),
        (this.series[0].data[2].y = [
          parseInt(stopsProcurement[0]),
          parseInt(stopsProcurement[1]),
        ]),
        (this.series[1].data[2].y = [
          parseInt(stopsProcurement[1]),
          parseInt(stopsProcurement[2]),
        ]),
        (this.series[2].data[2].y = [
          parseInt(stopsProcurement[2]),
          parseInt(stopsProcurement[3]),
        ]),
        (this.series[3].data[2].y = [
          parseInt(stopsProcurement[3]),
          parseInt(stopsProcurement[4]),
        ]),
        (this.series[0].data[3].y = [
          parseInt(stopsHoisting[0]),
          parseInt(stopsHoisting[1]),
        ]),
        (this.series[1].data[3].y = [
          parseInt(stopsHoisting[1]),
          parseInt(stopsHoisting[2]),
        ]),
        (this.series[2].data[3].y = [
          parseInt(stopsHoisting[2]),
          parseInt(stopsHoisting[3]),
        ]),
        (this.series[3].data[3].y = [
          parseInt(stopsHoisting[3]),
          parseInt(stopsHoisting[4]),
        ]),
        (this.series[0].data[4].y = [
          parseInt(stopsInstallation[0]),
          parseInt(stopsInstallation[1]),
        ]),
        (this.series[1].data[4].y = [
          parseInt(stopsInstallation[1]),
          parseInt(stopsInstallation[2]),
        ]),
        (this.series[2].data[4].y = [
          parseInt(stopsInstallation[2]),
          parseInt(stopsInstallation[3]),
        ]),
        (this.series[3].data[4].y = [
          parseInt(stopsInstallation[3]),
          parseInt(stopsInstallation[4]),
        ]),
        (this.series[0].data[5].y = [
          parseInt(stopsTesting[0]),
          parseInt(stopsTesting[1]),
        ]),
        (this.series[1].data[5].y = [
          parseInt(stopsTesting[1]),
          parseInt(stopsTesting[2]),
        ]),
        (this.series[2].data[5].y = [
          parseInt(stopsTesting[2]),
          parseInt(stopsTesting[3]),
        ]),
        (this.series[3].data[5].y = [
          parseInt(stopsTesting[3]),
          parseInt(stopsTesting[4]),
        ]),
        (this.series[0].data[6].y = [
          parseInt(stopsTurnOn[0]),
          parseInt(stopsTurnOn[1]),
        ]),
        (this.series[1].data[6].y = [
          parseInt(stopsTurnOn[1]),
          parseInt(stopsTurnOn[2]),
        ]),
        (this.series[2].data[6].y = [
          parseInt(stopsTurnOn[2]),
          parseInt(stopsTurnOn[3]),
        ]),
        (this.series[3].data[6].y = [
          parseInt(stopsTurnOn[3]),
          parseInt(stopsTurnOn[4]),
        ]),
        (this.series[0].data[7].y = [
          parseInt(stopsHandover[0]),
          parseInt(stopsHandover[1]),
        ]),
        (this.series[1].data[7].y = [
          parseInt(stopsHandover[1]),
          parseInt(stopsHandover[2]),
        ]),
        (this.series[2].data[7].y = [
          parseInt(stopsHandover[2]),
          parseInt(stopsHandover[3]),
        ]),
        (this.series[3].data[7].y = [
          parseInt(stopsHandover[3]),
          parseInt(stopsHandover[4]),
        ]),
        (this.series[0].data[0].extra = [
          this.ganttMilestoneTempStorageRev[0].completed_ratio,
        ]);
      this.series[0].data[1].extra = [
        this.ganttMilestoneTempStorageRev[1].completed_ratio,
      ];
      this.series[0].data[2].extra = [
        this.ganttMilestoneTempStorageRev[2].completed_ratio,
      ];
      this.series[0].data[3].extra = [
        this.ganttMilestoneTempStorageRev[3].completed_ratio,
      ];
      this.series[0].data[4].extra = [
        this.ganttMilestoneTempStorageRev[4].completed_ratio,
      ];
      this.series[0].data[5].extra = [
        this.ganttMilestoneTempStorageRev[5].completed_ratio,
      ];
      this.series[0].data[6].extra = [
        this.ganttMilestoneTempStorageRev[6].completed_ratio,
      ];
      this.series[0].data[7].extra = [
        this.ganttMilestoneTempStorageRev[7].completed_ratio,
      ];

      this.series[1].data[0].extra = [
        this.ganttMilestoneTempStorageRev[0].on_track_ratio,
      ];
      this.series[1].data[1].extra = [
        this.ganttMilestoneTempStorageRev[1].on_track_ratio,
      ];
      this.series[1].data[2].extra = [
        this.ganttMilestoneTempStorageRev[2].on_track_ratio,
      ];
      this.series[1].data[3].extra = [
        this.ganttMilestoneTempStorageRev[3].on_track_ratio,
      ];
      this.series[1].data[4].extra = [
        this.ganttMilestoneTempStorageRev[4].on_track_ratio,
      ];
      this.series[1].data[5].extra = [
        this.ganttMilestoneTempStorageRev[5].on_track_ratio,
      ];
      this.series[1].data[6].extra = [
        this.ganttMilestoneTempStorageRev[6].on_track_ratio,
      ];
      this.series[1].data[7].extra = [
        this.ganttMilestoneTempStorageRev[7].on_track_ratio,
      ];

      this.series[2].data[0].extra = [
        this.ganttMilestoneTempStorageRev[0].at_risk_delayed_ratio,
      ];
      this.series[2].data[1].extra = [
        this.ganttMilestoneTempStorageRev[1].at_risk_delayed_ratio,
      ];
      this.series[2].data[2].extra = [
        this.ganttMilestoneTempStorageRev[2].at_risk_delayed_ratio,
      ];
      this.series[2].data[3].extra = [
        this.ganttMilestoneTempStorageRev[3].at_risk_delayed_ratio,
      ];
      this.series[2].data[4].extra = [
        this.ganttMilestoneTempStorageRev[4].at_risk_delayed_ratio,
      ];
      this.series[2].data[5].extra = [
        this.ganttMilestoneTempStorageRev[5].at_risk_delayed_ratio,
      ];
      this.series[2].data[6].extra = [
        this.ganttMilestoneTempStorageRev[6].at_risk_delayed_ratio,
      ];
      this.series[2].data[7].extra = [
        this.ganttMilestoneTempStorageRev[7].at_risk_delayed_ratio,
      ];

      this.series[3].data[0].extra = [
        this.ganttMilestoneTempStorageRev[0].unfinished_site_documents_ratio,
      ];
      this.series[3].data[1].extra = [
        this.ganttMilestoneTempStorageRev[1].unfinished_site_documents_ratio,
      ];
      this.series[3].data[2].extra = [
        this.ganttMilestoneTempStorageRev[2].unfinished_site_documents_ratio,
      ];
      this.series[3].data[3].extra = [
        this.ganttMilestoneTempStorageRev[3].unfinished_site_documents_ratio,
      ];
      this.series[3].data[4].extra = [
        this.ganttMilestoneTempStorageRev[4].unfinished_site_documents_ratio,
      ];
      this.series[3].data[5].extra = [
        this.ganttMilestoneTempStorageRev[5].unfinished_site_documents_ratio,
      ];
      this.series[3].data[6].extra = [
        this.ganttMilestoneTempStorageRev[6].unfinished_site_documents_ratio,
      ];
      this.series[3].data[7].extra = [
        this.ganttMilestoneTempStorageRev[7].unfinished_site_documents_ratio,
      ];

      this.updateChartMinMax();
    },
    getGanttChartMilestoneByProject() {
      this.model.project_id = this.$route.params.id;
      axios
        .get(
          apiDomain +
            "project/project_gantt_chart_milestone.show_by_project/" +
            this.model.project_id,
          {
            headers: getHeader(),
          }
        )
        .then((response) => {
          if (response.status === 200) {
            this.ganttMilestoneTempStorage = response.data.data;
            this.ganttMilestoneTempStorageRev =
              this.ganttMilestoneTempStorage.reverse();
            this.updateThis();
          }
        })
        .catch((error) => {
          console.log(error.response.status);
          if (error.response.status == 401) {
            this.$router.push("Gantt Chart Milestone");
          }
        });
    },
    checkIfFalse() {
      if (
        this.ganttStartEndTempStorage[0].milestone_1_task_est_start_date ==
        false
      ) {
        this.ganttStartEndTempStorage[0].milestone_1_task_est_start_date =
          this.ganttStartEndTempStorage[0].milestone_2_task_est_start_date;
      }
      if (
        this.ganttStartEndTempStorage[0].milestone_1_task_est_end_date == false
      ) {
        this.ganttStartEndTempStorage[0].milestone_1_task_est_end_date =
          this.ganttStartEndTempStorage[0].milestone_8_task_est_end_date;
      }
    },
    getGanttChartMilestoneStartEnd() {
      this.model.project_id = this.$route.params.id;
      axios
        .get(apiDomain + "dashboard/ganttBaseBar/" + this.model.project_id, {
          headers: getHeader(),
        })
        .then((response) => {
          if (response.status === 200) {
            this.ganttStartEndTempStorage = null;
            this.ganttStartEndTempStorage = response.data.data;
            this.checkIfFalse();
            this.updateThis();
          }
        })
        .catch((error) => {
          console.log(error.response.status);
          if (error.response.status == 401) {
            this.$router.push("Gantt Chart Start End");
          }
        });
    },
    updateGantt() {
      this.getGanttChartMilestoneStartEnd();
      this.getGanttChartMilestoneByProject();
      this.updateThis();
    },
    getDashboardData() {
      this.model.project_id = this.$route.params.id;
      axios
        .get(apiDomain + "project/project/" + this.model.project_id, {
          headers: getHeader(),
        })
        .then((response) => {
          if (response.status === 200) {
            this.model.project_total_task =
              response.data.data[0].project_total_task;
            this.model.project_total_installation =
              response.data.data[0].project_total_installation;
            this.model.project_total_document =
              response.data.data[0].project_total_document;
            this.model.project_progress =
              response.data.data[0].project_progress;
            this.model.project_name = response.data.data[0].project_name;
            this.model.project_name = response.data.data[0].project_name;
            this.model.status.status_code =
              response.data.data[0].status.status_code;
            this.model.project_manager.name =
              response.data.data[0].project_manager.name;
            this.model.project_manager.email =
              response.data.data[0].project_manager.email;
            this.model.developer.developer_code =
              response.data.data[0].developer.developer_code;
            this.model.project_total_capacity =
              response.data.data[0].project_total_capacity;
            this.model.project_completed_capacity =
              response.data.data[0].project_completed_capacity;
            this.model.project_completed_task =
              response.data.data[0].project_completed_task;
            this.model.project_completed_document =
              response.data.data[0].project_completed_document;
            this.model.project_level_total_task =
              response.data.data[0].project_level_total_task;
            this.model.project_level_completed_task =
              response.data.data[0].project_level_completed_task;
            this.model.project_level_total_document =
              response.data.data[0].project_level_total_document;
            this.model.project_level_completed_document =
              response.data.data[0].project_level_completed_document;
            this.model.project_type.project_type_id =
              response.data.data[0].project_type.project_type_id;
            this.checkIfSolarNovaProject();
          }
        })
        .catch((error) => {
          console.log(error.response.status);
          if (error.response.status == 401) {
            this.$router.push("/login");
          }
        });
    },
    afterUpdaterPojectTaskList() {
      this.getDashboardData();
    },
    afterUpdateTaskList() {
      this.updateSCurve();
      this.getDashboardData();
      this.$refs.milestoneComponent.getDataListing();
    },
    updateSCurve() {
      axios
        .get(apiDomain + "project/project.s_curve/" + this.model.project_id, {
          headers: getHeader(),
        })
        .then((response) => {
          if (response.status === 200) {
            this.bigLineChart.chartData = {
              datasets: [
                {
                  label: "Estimated",
                  data: response.data.data.task_results_est,
                },
                {
                  label: "Actual",
                  data: response.data.data.task_results_act,
                  borderColor: "#FFFFFF",
                  fontColor: "#FFFFFF",
                },
              ],
              labels: response.data.data.labels,
            };
          }
        })
        .catch((error) => {
          console.log(error.response.status);
          this.errorFunction(error, "Project S-Curve");
        });
    },
    showModal() {
      this.modals.formTask = true;
    },
    getTotalSite() {
      axios
        .get(
          apiDomain +
            "project/project_site.show_by_project/" +
            this.model.project_id,
          { headers: getHeader() }
        )
        .then((response) => {
          if (response.status === 200) {
            this.project_sites = response.data.data;
          }
        })
        .catch((error) => {
          console.log(error.response.status);
          if (error.response.status == 401) {
            this.$router.push("/login");
          }
        });
    },
    newProjectDocument() {
      this.$router.push({
        path: "/projectDocumentNewFrProject/" + this.model.project_id,
      });
    },
    newSiteDocument() {
      this.$router.push({
        path: "/siteDocumentNewFrProject/" + this.model.project_id,
      });
    },
    newTask() {
      this.$router.push({
        path: "/projectTaskNewFrProject/" + this.model.project_id,
      });
    },
    newSiteTask() {
      this.$router.push({ path: "/taskNewFrProject/" + this.model.project_id });
    },
    newProjectGroup() {
      this.$router.push({
        path: "/projectGroupNewFrProject/" + this.model.project_id,
      });
    },
    newProjectSite() {
      this.$router.push({
        path: "/projectSiteNewFrProject/" + this.model.project_id,
      });
    },
    backToProject() {
      this.$router.push({ path: "/projectListing" });
    },
    newImport() {
      this.$router.push({
        path: "/importNewFrProject/" + this.model.project_id,
      });
    },
    notready() {
      this.$notify({
        message:
          "<b>Under Developer : </b> - New Task & Populate / Import From Template function is not ready.",
        timeout: 10000,
        icon: "ni ni-bulb-61",
        type: "danger",
      });
    },
    notifyVueFormTask() {
      if (
        this.formModal.task_template === "" ||
        this.formModal.task_site === ""
      ) {
        this.$notify({
          message:
            "<b>Task Template Input Error : </b> - Please Select A Task Template & Target Site OR press <b>CANCEL</b> to <b>EXIT</b>.",
          timeout: 10000,
          icon: "ni ni-bulb-61",
          type: "danger",
        });
      } else {
        this.$notify({
          message:
            "<b>Task Template (" +
            this.formModal.task_template +
            ") : </b> - Successfully Imported into ( TRTP-2398 - Tengeh Reservoir Tender Project )",
          timeout: 10000,
          icon: "ni ni-bell-55",
          type: "default",
        });
        this.clearModal();
      }
    },
    notifyVueFormMilestone() {
      if (this.formModal.milestone_template === "") {
        this.$notify({
          message:
            "<b>Milestone Template Input Error : </b> - Please Select A Milestone Template Or Cancel to Exit.",
          timeout: 10000,
          icon: "ni ni-bulb-61",
          type: "danger",
        });
      } else {
        this.$notify({
          message:
            "<b>Milestone Template (" +
            this.formModal.milestone_template +
            ") : </b> - Successfully Imported into ( TRTP-2398 - Tengeh Reservoir Tender Project )",
          timeout: 10000,
          icon: "ni ni-bell-55",
          type: "default",
        });
        this.clearModal();
      }
    },
    clearModal() {
      this.modals.formTask = false;
      this.modals.formMilestone = false;
      this.formModal.task_template = "";
      this.formModal.task_site = "";
      this.formModal.milestone_template = "";
    },
  },
};
</script>
<style></style>
