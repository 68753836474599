<template>
    <card>
        <!-- Card header -->
        <h3 slot="header" class="mb-0">New Project Site Daily Progress</h3>
        <div class="text-right">
        </div>
        <form class="needs-validation"
              @submit.prevent="submit">

            <div class="form-row">
                <b-col md="8">
                    <label>Remarks</label>
                    <b-form-textarea
                            id="textarea"
                            v-model="model.remarks"
                            placeholder=""
                            rows="5"
                            class="border"
                    ></b-form-textarea>
                </b-col>
            </div>
            <br>
            <div class="form-row form-group">
                <div class="col-md-8">
                    <label>File/Image Attachment:</label>
                    <br >
                    <div v-for="(file, fileindex) of model.file_attachment" class="alert border-bottom mb-2 p-2 d-flex align-items-center">
                        <div class="flex-fill">
                            <div class="fw-semibold">{{ file.uploaded_file.name }}</div>
                        </div>
                        <button class="btn btn-warning btn-sm" @click="model.file_attachment.splice(fileindex, 1)">&times;</button>
                        <br >
                    </div>

                    <div v-for="(itemAttachment, indexAttachment) of model.attachment" class="alert border-bottom mb-2 p-2 d-flex align-items-center">
                        <div class="flex-fill">
                            <div class="fw-semibold">{{ itemAttachment.attachment.filename }}</div>
                        </div>
                        <button class="btn btn-warning btn-sm" @click="model.attachment.splice(indexAttachment, 1)">&times;</button>
                    </div>
                    <div>

                        <base-button size="md" type="primary" @click.prevent="uploadAttachment('files-upload')">Add Attachment</base-button>
                        <input
                                v-show="false"
                                id="files-upload"
                                type="file"
                                ref="file"
                                style="display:none"
                                @change="fileChange($event)"
                        />
                    </div>
                </div>


            </div>
            <br>

            <base-button type="primary" @click="createData()">Create New Site Daily Progress</base-button>
            <base-button type="primary" @click="$router.back()">Cancel</base-button>
        </form>
    </card>
</template>

<script>
    import ErrorFunctionMixin from '@/assets/js/errorFunctionMix.js'
    import axios from '@/util/axios'
    import jsonFormData from 'json-form-data';

    export default {
        mixins: [ErrorFunctionMixin],

        data() {
            return {
                model: {
                    remarks: null,
                    file_attachment: [],
                },
                documents: [],
            }
        },

        methods: {
            createData(){
                if (!this.model.remarks )
                {
                    this.redErrorNotify( '<b>Invalid Input : Remarks is a Mandatory Column.</b>')
                }
                else
                {
                    this.model.attachment = this.documents;
                    let formData = jsonFormData(this.model);

                    axios.post('project/create_project_site_daily_progress/'+this.$route.params.id, formData)
                        .then ( response => {
                            this.$notify({
                                message: '<b>New Project Site Daily Progress : </b> - Successfully Created.',
                                timeout: 10000,
                                icon: 'ni ni-bell-55',
                                type: 'default',
                            });
                            this.$router.back()
                        })
                        .catch ( error => {
                            this.errorFunction(error, "Create New Project Site Daily Progress")
                        });
                }
            },

            fileChange(event) {
                const data = new FormData();
                let file = event.target.files[0];

                // Remove type validation, allow any file type
                let newArray = {uploaded_file: file};

                // Add file to the model and documents
                this.model.file_attachment.push(newArray);
                this.documents.push(newArray);

            },
            uploadAttachment(id){
                document.getElementById(id).click()
            },
        }
    }
</script>
