var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',{staticClass:"bg-default shadow",attrs:{"no-body":""}},[_c('b-card-header',{staticClass:"bg-transparent border-0"},[_c('h3',{staticClass:"mb-0 text-white"},[_vm._v("Current Sites Listing in (TRTP-2398 - Tengeh Reservoir Tender Project)")]),_c('div',{staticClass:"text-right"},[_c('router-link',{attrs:{"to":"/taskNew"}},[_c('base-button',{attrs:{"size":"sm","type":"neutral"}},[_vm._v("Create New Task")])],1),_vm._v("   "),_c('router-link',{attrs:{"to":"/siteNew"}},[_c('base-button',{attrs:{"size":"sm","type":"neutral"}},[_vm._v("Create New Site")])],1)],1)]),_c('el-table',{staticClass:"table-responsive table-dark",attrs:{"header-row-class-name":"thead-dark","data":_vm.sites}},[_c('el-table-column',{attrs:{"label":"Code","min-width":"90px","prop":"name","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('b-media',{staticClass:"align-items-center",attrs:{"no-body":""}},[_c('b-media-body',[_c('span',{staticClass:"font-weight-600 name mb-0 text-sm text-white"},[_vm._v(_vm._s(row.code))])])],1)]}}])}),_c('el-table-column',{attrs:{"label":"Site Name","min-width":"190px","prop":"name","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('b-media',{staticClass:"align-items-center",attrs:{"no-body":""}},[_c('b-media-body',[_c('span',{staticClass:"font-weight-600 name mb-0 text-sm text-white"},[_vm._v(_vm._s(row.title))])])],1)]}}])}),_c('el-table-column',{attrs:{"label":"Status","min-width":"110px","prop":"status","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('badge',{staticClass:"badge-dot mr-4",attrs:{"type":""}},[_c('i',{class:("bg-" + (row.statusType))}),_c('span',{staticClass:"status text-white"},[_vm._v(_vm._s(row.status))])])]}}])}),_c('el-table-column',{attrs:{"label":"At Milestone","min-width":"130px"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('b-media',{staticClass:"align-items-center",attrs:{"no-body":""}},[_c('b-media-body',[_c('span',{staticClass:"font-weight-600 name mb-0 text-sm text-white"},[_vm._v(_vm._s(row.milestone))])])],1)]}}])}),_c('el-table-column',{attrs:{"label":"Progress","prop":"completion","min-width":"160px","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"d-flex align-items-center"},[_c('span',{staticClass:"completion mr-0 text-white"},[_vm._v(_vm._s(row.completion)+"%")]),_c('div',[_c('base-progress',{attrs:{"type":row.statusType,"value":row.completion}})],1)])]}}])}),_c('el-table-column',{attrs:{"label":"Action","min-width":"150px"},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('div',{staticClass:"d-flex align-items-center"},[_c('div',[_c('router-link',{attrs:{"to":"/siteDashboard"}},[_c('base-button',{attrs:{"size":"sm","type":"primary"}},[_vm._v("Site Dashboard")])],1),_vm._v("   "),_c('router-link',{attrs:{"to":"/siteEdit"}},[_c('base-button',{attrs:{"size":"sm","type":"primary"}},[_vm._v("Edit")])],1)],1)])]},proxy:true}])})],1),_c('b-card-footer',{staticClass:"py-2 d-flex justify-content-end bg-transparent"},[_c('base-pagination',{attrs:{"per-page":10,"total":50},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }