<template>
<div>
  <card>
    <h3 slot="header" class="mb-0">New Toolbox Meeting</h3>
    <div class="text-right"></div>
    <form class="needs-validation" @submit.prevent="submit">

      <card>
        <div class="form-row">
          <b-col md="3">
            <base-input label="Project"
                        name="Project"
                        v-model="model.project_id">
              <select class="form-control" v-model="model.project_id" @change="getGroupFromProject()">
                <option Selected disabled value="x">-- Please Select A Project --</option>
                <option v-for="item in projects" :key="item.project_id" v-bind:value="item.project_id">{{ item.project_name }}</option>
              </select>
            </base-input>
          </b-col>

          <b-col md="3">
            <base-input label="Project Group"
                        name="Project Group"
                        v-model="model.group_id">
              <select class="form-control" v-model="model.group_id" @change="getSiteFromGroup()">
                <option Selected disabled value="x">-- Please Select A Project To Show Group --</option>
                <option Selected disabled value="y">-- Please Select A Group --</option>
                <option v-for="item in project_groups" :key="item.group_id" v-bind:value="item.group_id">{{ item.group_name }}</option>
              </select>
            </base-input>
          </b-col>
        </div>

        <div class="form-row">
          <b-col md="12">
            <div class="form-group">
              <b-row>
                <b-col md="3"><h5>Project Site(s)</h5></b-col>
                <b-col md="3"><h5>EPC</h5></b-col>
              </b-row>
              <div v-for="(input, index) in selected_project_sites" :key="`projectSiteInput-${index}`">
                <div class="form-row">
                  <b-col md="3">
                    <base-input v-model="input.site_id">
                      <select
                        class="form-control"
                        v-model="input.site_id"
                        @change="removeFromList($event); getContractorFromSite($event);">
                        <option Selected disabled value="x">
                          -- Please Select A Site --
                        </option>
                        <option
                          v-for="item in project_sites"
                          :key="item.site_id"
                          v-bind:value="item.site_id"
                          :disabled="item.disabled"
                          v-show="!item.v_hide">
                          {{ item.site_name }}
                        </option>
                      </select>
                    </base-input>
                  </b-col>

                  <b-col md="3">
                    <base-input v-model="input.contractor_info" disabled>
                    </base-input>
                  </b-col>

                  <b-col md="auto" v-if="isSolarNovaProject">
                    <base-button
                      size="sm"
                      type="primary"
                      @click="addField(input, selected_project_sites)">
                      Add
                    </base-button>
                  </b-col>

                  <b-col md="auto" v-if="isSolarNovaProject">
                    <base-button
                      size="sm"
                      type="primary"
                      @click="removeField(index, selected_project_sites)"
                      v-show="selected_project_sites.length > 1 && index != 0">
                      Remove
                    </base-button>
                  </b-col>
                </div>
              </div>
            </div>
          </b-col>
        </div>
      </card>

      <card>
        <div class="form-row">
          <b-col md="3">
            <base-input label="Date"
                        name="Date"
                        type="date"
                        v-model="model.toolbox_meeting_date"
                        @change="validateDateTime">
            </base-input>
          </b-col>

          <b-col md="3">
            <base-input label="Time"
                        name="Time"
                        type="time"
                        v-model="model.toolbox_meeting_time"
                        @change="validateDateTime">
            </base-input>
          </b-col>

          <b-col md="3">
            <base-input label="Conducted By"
                        name="Conducted By"
                        v-model="model.conducted_by.user_name"
                        disabled>
            </base-input>
          </b-col>

          <b-col md="3">
            <base-input label="Shift"
                        name="Shift"
                        v-model="model.shift">
              <select class="form-control" v-model="model.shift">
                <option value="0">Day</option> 
                <option value="1">Night</option>
              </select>
            </base-input>
          </b-col>
        </div>
      </card>

      <card>
        <div><h3>Life Saving Rules</h3></div>

        <br>

        <div class="form-row">
          <b-col sm="6" md="2">
            <b-row>
              <b-col style="">
                <img src="/img/brand/lifesavingrules/rule_1.jpg" style="max-width: 100%; height: auto;">
                <input type="checkbox" true-value="1" false-value="0" v-model="model.life_saving_rules.rule_1" class="checkbox-bottom-right">
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <span>Obtain authorisation before entering a confined space</span>
              </b-col>
            </b-row>
          </b-col>

          <b-col sm="6" md="2">
            <b-row>
              <b-col style="">
                <img src="/img/brand/lifesavingrules/rule_2.jpg" style="max-width: 100%; height: auto;">
                <input type="checkbox" true-value="1" false-value="0" v-model="model.life_saving_rules.rule_2" class="checkbox-bottom-right">
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <span>Protect yourself against a fall when working at height</span>
              </b-col>
            </b-row>
          </b-col>

          <b-col sm="6" md="2">
            <b-row>
              <b-col style="">
                <img src="/img/brand/lifesavingrules/rule_3.jpg" style="max-width: 100%; height: auto;">
                <input type="checkbox" true-value="1" false-value="0" v-model="model.life_saving_rules.rule_3" class="checkbox-bottom-right">
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <span>Conduct a gas test when required</span>
              </b-col>
            </b-row>
          </b-col>

          <b-col sm="6" md="2">
            <b-row>
              <b-col style="">
                <img src="/img/brand/lifesavingrules/rule_4.jpg" style="max-width: 100%; height: auto;">
                <input type="checkbox" true-value="1" false-value="0" v-model="model.life_saving_rules.rule_4" class="checkbox-bottom-right">
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <span>Work with a valid permit when required</span>
              </b-col>
            </b-row>
          </b-col>

          <b-col sm="6" md="2">
            <b-row>
              <b-col style="">
                <img src="/img/brand/lifesavingrules/rule_5.jpg" style="max-width: 100%; height: auto;">
                <input type="checkbox" true-value="1" false-value="0" v-model="model.life_saving_rules.rule_5" class="checkbox-bottom-right">
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <span>Verify isolation before work begins and use specified life protecting equipment</span>
              </b-col>
            </b-row>
          </b-col>

          <b-col sm="6" md="2">
            <b-row>
              <b-col style="">
                <img src="/img/brand/lifesavingrules/rule_6.jpg" style="max-width: 100%; height: auto;">
                <input type="checkbox" true-value="1" false-value="0" v-model="model.life_saving_rules.rule_6" class="checkbox-bottom-right">
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <span>Wear a personal flotation device when required</span>
              </b-col>
            </b-row>
          </b-col>
        </div>

        <div class="form-row">
          <b-col sm="6" md="2">
            <b-row>
              <b-col style="">
                <img src="/img/brand/lifesavingrules/rule_7.jpg" style="max-width: 100%; height: auto;">
                <input type="checkbox" true-value="1" false-value="0" v-model="model.life_saving_rules.rule_7" class="checkbox-bottom-right">
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <span>Obtain authorisation before overriding or disabling a safety critical equipment</span>
              </b-col>
            </b-row>
          </b-col>

          <b-col sm="6" md="2">
            <b-row>
              <b-col style="">
                <img src="/img/brand/lifesavingrules/rule_8.jpg" style="max-width: 100%; height: auto;">
                <input type="checkbox" true-value="1" false-value="0" v-model="model.life_saving_rules.rule_8" class="checkbox-bottom-right">
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <span>Obtain authorisation before excavation</span>
              </b-col>
            </b-row>
          </b-col>

          <b-col sm="6" md="2">
            <b-row>
              <b-col style="">
                <img src="/img/brand/lifesavingrules/rule_9.jpg" style="max-width: 100%; height: auto;">
                <input type="checkbox" true-value="1" false-value="0" v-model="model.life_saving_rules.rule_9" class="checkbox-bottom-right">
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <span>Follow prescribed lifting plan</span>
              </b-col>
            </b-row>
          </b-col>

          <b-col sm="6" md="2">
            <b-row>
              <b-col style="">
                <img src="/img/brand/lifesavingrules/rule_10.jpg" style="max-width: 100%; height: auto;">
                <input type="checkbox" true-value="1" false-value="0" v-model="model.life_saving_rules.rule_10" class="checkbox-bottom-right">
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <span>No alcohol or drugs while working or driving</span>
              </b-col>
            </b-row>
          </b-col>

          <b-col sm="6" md="2">
            <b-row>
              <b-col style="">
                <img src="/img/brand/lifesavingrules/rule_11.jpg" style="max-width: 100%; height: auto;">
                <input type="checkbox" true-value="1" false-value="0" v-model="model.life_saving_rules.rule_11" class="checkbox-bottom-right">
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <span>Do not walk under a suspended load</span>
              </b-col>
            </b-row>
          </b-col>

          <b-col sm="6" md="2">
            <b-row>
              <b-col style="">
                <img src="/img/brand/lifesavingrules/rule_12.jpg" style="max-width: 100%; height: auto;">
                <input type="checkbox" true-value="1" false-value="0" v-model="model.life_saving_rules.rule_12" class="checkbox-bottom-right">
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <span>Do not smoke outside designated smoking areas</span>
              </b-col>
            </b-row>
          </b-col>
        </div>
      </card>

      <card>
        <div class="form-row">
          <b-col md="12">
            <label for="textarea">Topics Discussed Before Work Commence:</label>
            <b-form-textarea
              id="textarea"
              v-model="model.topics_discussed"
              placeholder=""
              rows="5"
            ></b-form-textarea>
          </b-col>
        </div>
      </card>

      <card>
        <div class="form-row">
          <b-col md="12">
            <label for="textarea">Feedback(s) from Work Crew:</label>
            <b-form-textarea
              id="textarea"
              v-model="model.crew_feedback"
              placeholder=""
              rows="5"
            ></b-form-textarea>
          </b-col>
        </div>
      </card>

      <card>
        <div class="form-row">
          <b-col md="3">
            <base-input label="Project Manager"
                        name="Project Manager"
                        placeholder="0"
                        v-model="model.manpower.manpower_project_manager" type="number" min="0" @change="calculateManpowerTotal()">
            </base-input>
          </b-col>

          <b-col md="3">
            <base-input label="Site Manager"
                        name="Site Manager"
                        placeholder="0"
                        v-model="model.manpower.manpower_site_manager" type="number" min="0" @change="calculateManpowerTotal()">
            </base-input>
          </b-col>

          <b-col md="3">
            <base-input label="PV Engineer"
                        name="PV Engineer"
                        placeholder="0"
                        v-model="model.manpower.manpower_pv_engineer" type="number" min="0" @change="calculateManpowerTotal()">
            </base-input>
          </b-col>

          <b-col md="3">
            <base-input label="Site In Charge"
                        name="Site In Charge"
                        placeholder="0"
                        v-model="model.manpower.manpower_site_in_charge" type="number" min="0" @change="calculateManpowerTotal()">
            </base-input>
          </b-col>
        </div>

        <div class="form-row">
          <b-col md="3">
            <base-input label="WSH Coordinator"
                        name="WSH Coordinator"
                        placeholder="0"
                        v-model="model.manpower.manpower_wsh_coordinator" type="number" min="0" @change="calculateManpowerTotal()">
            </base-input>
          </b-col>

          <b-col md="3">
            <base-input label="First Aider"
                        name="First Aider"
                        placeholder="0"
                        v-model="model.manpower.manpower_first_aider" type="number" min="0" @change="calculateManpowerTotal()">
            </base-input>
          </b-col>

          <b-col md="3">
            <base-input label="SMO"
                        name="SMO"
                        placeholder="0"
                        v-model="model.manpower.manpower_smo" type="number" min="0" @change="calculateManpowerTotal()">
            </base-input>
          </b-col>

          <b-col md="3">
            <base-input label="SDO"
                        name="SDO"
                        placeholder="0"
                        v-model="model.manpower.manpower_sdo" type="number" min="0" @change="calculateManpowerTotal()">
            </base-input>
          </b-col>
        </div>

        <div class="form-row">
          <b-col md="3">
            <base-input label="Sembcorp Solar Trained Installer"
                        name="Sembcorp Solar Trained Installer"
                        placeholder="0"
                        v-model="model.manpower.manpower_trained_installer" type="number" min="0" @change="calculateManpowerTotal()">
            </base-input>
          </b-col>

          <b-col md="3">
            <base-input label="WAH Supervisor"
                        name="WAH Supervisor"
                        placeholder="0"
                        v-model="model.manpower.manpower_wah_supervisor" type="number" min="0" @change="calculateManpowerTotal()">
            </base-input>
          </b-col>

          <b-col md="3">
            <base-input label="Fitter"
                        name="Fitter"
                        placeholder="0"
                        v-model="model.manpower.manpower_fitter" type="number" min="0" @change="calculateManpowerTotal()">
            </base-input>
          </b-col>

          <b-col md="3">
            <base-input label="General Worker"
                        name="General Worker"
                        placeholder="0"
                        v-model="model.manpower.manpower_general_worker" type="number" min="0" @change="calculateManpowerTotal()">
            </base-input>
          </b-col>
        </div>

        <div class="form-row">
          <b-col md="3">
            <base-input label="Lifting Supervisor"
                        name="Lifting Supervisor"
                        placeholder="0"
                        v-model="model.manpower.manpower_lifting_supervisor" type="number" min="0" @change="calculateManpowerTotal()">
            </base-input>
          </b-col>

          <b-col md="3">
            <base-input label="Rigger/Signalman"
                        name="Rigger/Signalman"
                        placeholder="0"
                        v-model="model.manpower.manpower_rigger_signalman" type="number" min="0" @change="calculateManpowerTotal()">
            </base-input>
          </b-col>

          <b-col md="3">
            <base-input label="Crane Operator/Lorry Driver"
                        name="Crane Operator/Lorry Driver"
                        placeholder="0"
                        v-model="model.manpower.manpower_crane_lorry_driver" type="number" min="0" @change="calculateManpowerTotal()">
            </base-input>
          </b-col>

          <b-col md="3">
            <base-input label="Crane Attendant"
                        name="Crane Attendant"
                        placeholder="0"
                        v-model="model.manpower.manpower_crane_attendant" type="number" min="0" @change="calculateManpowerTotal()">
            </base-input>
          </b-col>
        </div>

        <div class="form-row">
          <b-col md="6">
            <base-input label="Total Manpower"
                        name="Total Manpower"
                        placeholder="0"
                        v-model="model.manpower.manpower_total" type="number" min="0">
            </base-input>
          </b-col>
        </div>
      </card>

      <card>
        <div class="form-row">
          <b-col md="12">
            <label for="textarea">Work Activity for Today:</label>
            <b-form-textarea
              id="textarea"
              v-model="model.work_activity"
              placeholder=""
              rows="5"
            ></b-form-textarea>
          </b-col>
        </div>
      </card>

      <card>
        <div><h3>Attachment(s)</h3></div>

        <input
          v-show="false"
          type="file"
          id="imageFile"
          ref="imageFile"
          accept="image/jpeg, image/png, image/gif, image/webp"
          v-on:change="handleImageFileUpload()"
        />

        <div>
          <base-button style="float: right;" type="primary" size="sm" @click="$refs.imageFile.click()">Add Image Attachment</base-button>
        </div>

        <br>
        <br>

        <div v-for="(item, index) in model.toolbox_meeting_attachments" :key="index">
          <card>
            <b-row align-v="center">
              <b-col md="4">
                <img
                  :src="item.photoPreviewSrc"
                  style="max-width:250px;max-height:250px;"
                  @click="showLargeImage(item.photoPreviewSrc)"
                >
              </b-col>

              <b-col md="7">{{ item.photoFile.name }}</b-col>

              <b-col md="1">
                <base-button size="sm" type="primary" @click="deletePhoto(index)">
                  Delete
                </base-button>
              </b-col>
            </b-row>
          </card>
        </div>

      </card>

      <div>
        <div style="float: right;">
          <base-button type="danger" @click="$router.back()">Cancel</base-button>
          <base-button v-if="!submit_clicked" type="success" @click="submitData()">Submit</base-button>
          <base-button v-if="submit_clicked" type="success" @click="submitData()" disabled>Submit</base-button>
        </div>
      </div>
    </form>

    <modal
      :show.sync="isLargeImageVisible"
      size="xl"
      body-classes="p-0"
      title="Image">
        <card
        type="secondary"
        header-classes="bg-transparent pb-5"
        body-classes="px-lg-5 py-lg-5"
        class="border-0 mb-0">
        <div class="text-center">
          <img
            :src="largeImageSrc"
            style = "max-width:100%;"
          >
        </div>
        </card>
    </modal>

    <modal
      :show.sync="isSubmissionWarningVisible"
      :no-close-on-backdrop="true"
      size="xl"
      body-classes="p-0"
      title="Submission Warning"
    >
      <card>
        <template>
          <h1 class="text-center">Submission in Progress</h1>
          <div>
            Please be patient while we process your submission.<br>
            Do not navigate away from this page or refresh the page until the submission is complete.<br>
            You will be automatically redirected to another page once the process is finished.
          </div>
        </template>
      </card>
    </modal>
  </card>

</div>
</template>
<script>
import BaseSlider from "@/components/BaseSlider";
import {
  LoginURL,
  clientId,
  clientSecret,
  apiDomain,
  getHeader,
  getcurrentDate,
} from "@/assets/js/config.js";
import axios from "axios";
import ErrorFunctionMixin from "@/assets/js/errorFunctionMix.js";
import jsonFormData from 'json-form-data';

import cloneDeep from 'lodash/cloneDeep';

export default {
  mixins: [ErrorFunctionMixin],
  components: {
    BaseSlider,
  },
  created() {
    var currentDate = getcurrentDate();
    this.model.toolbox_meeting_date = currentDate;

    const today = new Date();
    this.model.toolbox_meeting_time = today.getHours() + ":" + today.getMinutes();

    var userInfo = JSON.parse(window.localStorage.getItem("loginUser"));
    this.user_role = userInfo.role_id;
    this.user_id = userInfo.id;
    this.user_name = userInfo.name;
    if (this.user_role == 12) {
      this.getContractorId();
    }

    this.model.conducted_by.user_id = this.user_id;
    this.model.conducted_by.user_name = this.user_name;

    this.getProjectListing();

  },
  data() {
    return {
      user_role: null,
      user_id: null,
      user_name: null,
      contractor_id: null,
      projects: [],
      project_groups: [],
      project_sites: [],
      model: {
        project_id: 'x',
        group_id: 'x',
        // site_id: 'x',
        toolbox_meeting_date: null,
        toolbox_meeting_time: null,
        conducted_by: {
          user_id: null,
          user_name: null,
        },
        shift: 0, // 0: Day | 1: Night
        life_saving_rules: {
          rule_1: 0,
          rule_2: 0,
          rule_3: 0,
          rule_4: 0,
          rule_5: 0,
          rule_6: 0,
          rule_7: 0,
          rule_8: 0,
          rule_9: 0,
          rule_10: 0,
          rule_11: 0,
          rule_12: 0,
        },
        topics_discussed: null,
        crew_feedback: null,
        manpower: {
          manpower_project_manager: 0,
          manpower_site_manager: 0,
          manpower_pv_engineer: 0,
          manpower_site_in_charge: 0,
          manpower_wsh_coordinator: 0,
          manpower_first_aider: 0,
          manpower_smo: 0,
          manpower_sdo: 0,
          manpower_trained_installer: 0,
          manpower_wah_supervisor: 0,
          manpower_fitter: 0,
          manpower_general_worker: 0,
          manpower_lifting_supervisor: 0,
          manpower_rigger_signalman: 0,
          manpower_crane_lorry_driver: 0,
          manpower_crane_attendant: 0,
          manpower_total: 0,
        },
        work_activity: null,
        toolbox_meeting_attachments: [],
        project_sites: null,
      },
      isSolarNovaProject: false,
      project_type_id: null,
      selected_project_sites: [{ site_id: 'x', previousSelection: '' }],
      isLargeImageVisible: false,
      largeImageSrc: null,
      submit_clicked: false,
      isSubmissionWarningVisible: false,
    };
  },
  methods: {
    getProjectListing()
    {
      if (this.user_role == 12) {
        this.getProjectListingContractor();
      } else {
        this.getProjectListingNormal();
      }
    },
    getProjectListingNormal()
    {
      axios.get(apiDomain + 'project/project.orderByName', { headers: getHeader() })
      .then ( response => {
      if (response.status === 200)
      {
          this.projects = response.data.data;
      }
      })
      .catch ( error => {
      console.log(error.response.status)
      this.errorFunction(error, "Project Listing")
      });
    },
    getProjectListingContractor()
    {
      axios.get(apiDomain + 'project/project.show_by_contractor/'  + this.contractor_id, { headers: getHeader() })
      .then ( response => {
      if (response.status === 200)
      {
          this.projects = response.data.data;
      }
      })
      .catch ( error => {
      console.log(error.response.status)
      this.errorFunction(error, "Project Listing Contractor")
      });
    },
    getGroupFromProject(){
      // check if project is solar nova project
      this.project_type_id = this.projects.find(project => project.project_id == this.model.project_id).project_type.project_type_id;
      if ( this.project_type_id != null &&
          // ( this.project_type_id == 2 || this.project_type_id == 3 || this.project_type_id == 4 )
          ([2, 3, 4, 9, 10, 11, 12, 13, 14].includes(this.project_type_id))
          ) {
        this.isSolarNovaProject = true;
      } else {
        this.isSolarNovaProject = false;
      }

      if (this.user_role == 12) {
        this.getGroupFromProjectContractor();
      } else {
        this.getGroupFromProjectNormal();
      }
    },
    getGroupFromProjectNormal()
    {
      axios.get(apiDomain + 'project/project_group.show_by_project/' + this.model.project_id , { headers: getHeader() })
      .then ( response => {
        if (response.status === 200)
        {
          this.project_groups = response.data.data;
          this.model.group_id = 'y';
          // this.model.site_id = 'x';
          this.project_sites = [];
          this.selected_project_sites = [{ site_id: 'x', previousSelection: '' }];
        }
      })
      .catch ( error => {
        console.log(error.response.status);
      } );
    },
    getGroupFromProjectContractor()
    {
      axios.get(apiDomain + 'project/project_group.show_by_project_by_contractor/' + this.model.project_id + '/' + this.contractor_id, { headers: getHeader() })
      .then ( response => {
        if (response.status === 200)
        {
          this.project_groups = response.data.data;
          this.model.group_id = 'y';
          // this.model.site_id = 'x';
          this.project_sites = [];
        }
        })
      .catch ( error => {
      console.log(error.response.status)
      });
    },
    getSiteFromGroup(){
      if (this.user_role == 12) {
        this.getSiteFromGroupContractor();
      } else {
        this.getSiteFromGroupNormal();
      }
    },
    getSiteFromGroupNormal()
    {
      axios.get(apiDomain + 'project/project_site.show_by_group/' + this.model.group_id , { headers: getHeader() })
      .then ( response => {
        if (response.status === 200)
        {
          this.project_sites = response.data.data;
          // this.model.site_id = 'y';
        }
      })
      .catch ( error => {
        console.log(error.response.status);
      } );
    },
    getSiteFromGroupContractor()
    {
      axios.get(apiDomain + 'project/project_site.show_by_group_by_contractor/' + this.model.group_id + '/' + this.contractor_id, { headers: getHeader() })
      .then ( response => {
        if (response.status === 200)
        {
          this.project_sites = response.data.data;
          // this.model.site_id = 'y';
        }
        })
      .catch ( error => {
      console.log(error.response.status)
      });
    },
    addField(object, fieldType) {
      let newObject = {};
      for (const [key, value] of Object.entries(object)) {
        newObject[key] = "";
      }
      fieldType.push(newObject);
    },
    removeField(index, fieldType) {
      if (fieldType[index].site_id != "") {
        this.project_sites.map((project_site) => {
          if (project_site.site_id == fieldType[index].site_id) {
            project_site["disabled"] = false;
            project_site["v_hide"] = false;
          }
        });
      }
      fieldType.splice(index, 1);
    },
    removeFromList(event)
    {
      this.selected_project_sites.map((selected_project_site) => {
        if (selected_project_site.site_id == event.target.value) {
          if (selected_project_site.previousSelection != "") {
            this.project_sites.map((project_site) => {
              if (project_site.site_id == selected_project_site.previousSelection) {
                project_site["disabled"] = false;
                project_site["v_hide"] = false;
              }
            });
          }
        }
        this.project_sites.map((project_site) => {
          if (project_site.site_id == event.target.value) {
            project_site["disabled"] = true;
            project_site["v_hide"] = true;
          }
        });
        selected_project_site.previousSelection = selected_project_site.site_id;
      });
    },
    getContractorFromSite(event)
    {
      axios.get(apiDomain + 'project/site_contractor.show_by_site/' + event.target.value , { headers: getHeader() })
      .then ( response => {
        if (response.status === 200)
        {
          let site_contractors = response.data.data;
          var index = site_contractors.findIndex(x => x.active_status ==="ACTIVE");

          this.selected_project_sites.map((selected_project_site) => {
            if (selected_project_site.site_id == event.target.value) {
              selected_project_site["contractor_id"] = site_contractors[index].contractor.contractor_id;
              selected_project_site["contractor_info"] = site_contractors[index].contractor.contractor_code;
            }});
          }
          this.selected_project_sites = [...this.selected_project_sites];
      })
      .catch ( error => {
        console.log(error.response.status)
        this.errorFunction(error, "Site Contractor Information")
      } );
    },
    validateDateTime() {
      const currentDate = new Date();
      const enteredDate = new Date(`${this.model.toolbox_meeting_date}T${this.model.toolbox_meeting_time}`);
      this.isFutureDate = enteredDate > currentDate;
    },
    calculateManpowerTotal() {
      this.model.manpower.manpower_total = parseInt(this.model.manpower.manpower_project_manager) +
                                          parseInt(this.model.manpower.manpower_site_manager) +
                                          parseInt(this.model.manpower.manpower_pv_engineer) +
                                          parseInt(this.model.manpower.manpower_site_in_charge) +
                                          parseInt(this.model.manpower.manpower_wsh_coordinator) +
                                          parseInt(this.model.manpower.manpower_first_aider) +
                                          parseInt(this.model.manpower.manpower_smo) +
                                          parseInt(this.model.manpower.manpower_sdo) +
                                          parseInt(this.model.manpower.manpower_trained_installer) +
                                          parseInt(this.model.manpower.manpower_wah_supervisor) +
                                          parseInt(this.model.manpower.manpower_fitter) +
                                          parseInt(this.model.manpower.manpower_general_worker) +
                                          parseInt(this.model.manpower.manpower_lifting_supervisor) +
                                          parseInt(this.model.manpower.manpower_rigger_signalman) +
                                          parseInt(this.model.manpower.manpower_crane_lorry_driver) +
                                          parseInt(this.model.manpower.manpower_crane_attendant);
    },
    handleImageFileUpload() {
      let vm = this;
      this.model.toolbox_meeting_attachments.push(
        {
          photoFile: {},
          photoPreviewSrc: "",
        },
      );
      this.model.toolbox_meeting_attachments.at(-1).photoFile = this.$refs.imageFile.files[0];

      var reader = new FileReader();
      reader.onload = function(e) {
        vm.model.toolbox_meeting_attachments.at(-1).photoPreviewSrc = e.target.result;
      };

      reader.readAsDataURL(this.model.toolbox_meeting_attachments.at(-1).photoFile);
    },
    showLargeImage(imageSrc){
      this.largeImageSrc = imageSrc;
      this.isLargeImageVisible = true;
    },
    deletePhoto(index){
      this.model.toolbox_meeting_attachments.splice(index, 1);
    },
    // selected_project_sites
    checkAndCreateConductingUsers() {
      let tempConductingUsers = [];
      this.conducting_users.forEach((item) => {
        if (item.userId != "") {
          tempConductingUsers.push({ userId: item.userId });
        }
      });
      this.model.conducting_users = tempConductingUsers;
    },
    checkAndCreateProjectSites() {
      let tempProjectSites = [];
      this.selected_project_sites.forEach((item) => {
        console.log('item.site_id: ', item.site_id);
        if (item.site_id != 'x' && item.site_id != "") {
          tempProjectSites.push({ site_id: item.site_id });
        }
      });
      this.model.project_sites = tempProjectSites;
      console.log('this.model.project_sites: ', this.model.project_sites);
    },

        //     this.model.project_id == 'x' ||
        // this.model.group_id == 'x' ||
        // this.model.site_id == 'x' ||
        // this.model.contractor_id == 'x' ||
        // !this.model.safety_observation_create_date ||
        // !this.model.safety_observation_create_time ||
        // this.model.safety_observation_items.length < 1 ||
        // this.isFutureDate
    checkForRequiredData() {
      this.checkAndCreateProjectSites();
      this.validateDateTime();
      var checker = false;
      if (
        this.model.project_id == 'x' ||
        this.model.group_id == 'x' ||
        !this.model.toolbox_meeting_date ||
        !this.model.toolbox_meeting_time ||
        !this.model.conducted_by.user_id ||
        this.model.project_sites.length < 1
      ) {
        if (this.model.project_id == 'x') {
          this.redErrorNotify(
            "<b>Invalid Input : Project is a Mandatory Field in Toolbox Meeting </b>"
          );
        } else if (this.model.group_id == 'x') {
          this.redErrorNotify(
            "<b>Invalid Input : Project Group is a Mandatory Field in Toolbox Meeting </b>"
          );          
        } else if (!this.model.toolbox_meeting_date) {
          this.redErrorNotify(
            "<b>Invalid Input : Date is a Mandatory Field in Toolbox Meeting </b>"
          );          
        } else if (!this.model.toolbox_meeting_time) {
          this.redErrorNotify(
            "<b>Invalid Input : Time is a Mandatory Field in Toolbox Meeting </b>"
          );          
        } else if (!this.model.conducted_by.user_id) {
          this.redErrorNotify(
            "<b>Invalid Input : Conducted By is a Mandatory Field in Toolbox Meeting </b>"
          );          
        } else if (this.model.project_sites.length < 1) {
          this.redErrorNotify(
            "<b>Invalid Input : Project Site is a Mandatory Field in Toolbox Meeting </b>"
          );
        } else if (this.isFutureDate) {
          this.redErrorNotify(
            "<b>Invalid Input : Date and Time cannot be in the future. </b>"
          );
        } else {
          this.redErrorNotify(
            "<b>Invalid Input : Please fill in all the Mandatory Fields in Toolbox Meeting </b>"
          );
        }        
      } else {
        checker = true;
        this.submit_clicked = true;
        this.isSubmissionWarningVisible = true;
      }
      return checker;
    },
    submitData(){
      if (this.checkForRequiredData()) {
        console.log('submit data');

        let formData = jsonFormData(this.model);

        const tokenData = JSON.parse(window.localStorage.getItem("authUser"));
        const headers = {
          Authorization: "Bearer " + tokenData.access_token,
        };

        axios
          .post(
            apiDomain + "health_safety/toolbox_meeting",
            formData,
            { headers }
          )
          .then((response) => {
            if (response.status === 200) {
              this.$notify({
              message:
                '<b>Toolbox Meeting Successfully Created and Submitted.</b>',
              timeout: 10000,
              icon: 'ni ni-bell-55',
              type: 'default',
              });
              this.$router.back()
            }
          })
          .catch((error) => {
            if (error.response) {
              // Handle error with response
              this.errorFunction(error, "Toolbox Meeting Submit");
            } else {
              // Handle error without response
              console.log(error)
              this.$notify({
                message:
                // '<b>System Warning : </b> - The System is Currently Busy or Un-Available.',
                '<b>System Warning : </b> - There is an error with your submission. Please contact Sembcorp staff you know or email to sembsolar.momens@sembcorp.com',
                timeout: 15000,
                icon: 'ni ni-bulb-61',
                type: 'warning',
              });
            }
            this.submit_clicked = false;
            this.isSubmissionWarningVisible = false;
          });
        }
    },
    getContractorId(){
      axios.get(apiDomain + 'lookup/contractor.contractor_id/' + this.user_id, { headers: getHeader() })
        .then ( response => {
        if (response.status === 200)
        {
          this.contractor_id = response.data.contractor_id;
          this.getProjectListing();
        }
        })
        .catch ( error => {
          this.errorFunction(error, 'Contractor Id')
        });
    },
  }
};
</script>
<style>
.form-control {
  color: black;
}
.form-row {
  color: black;
}
select option {
  color: black;
}
textarea {
  color: black;
}
.checkbox-bottom-right {
  position: absolute;
  bottom: 0;
}
</style>
