<template>
  <div class="bg-transparent">
    <base-header class="pb-6 bg-default shadow">
      <b-row aling-v="center" class="py-4">
        <b-col cols="7" lg="6">
          <h6 class="h2 text-white d-inline-block mb-0">
            Production Report
          </h6>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <route-bread-crumb></route-bread-crumb>
          </nav>
        </b-col>
        <b-col cols="5" lg="6" class="text-right"> </b-col>
      </b-row>
      <!-- Card stats -->
    </base-header>

    <b-container fluid class="mt--6 bg-default shadow">
      <div>
        <card class="no-border-card" body-classes="px-0 pb-1" footer-classes="pb-2">
          <template slot="header">
            <h3 class="mb-0">Production Report</h3>
          </template>
          <div>
            <b-col cols="12" class="d-flex justify-content-center justify-content-sm-between flex-wrap">
              <!-- <el-select
                class="select-primary pagination-select"
                v-model="pagination.perPage"
                placeholder="Per page">
                <el-option
                  class="select-primary"
                  v-for="item in pagination.perPageOptions"
                  :key="item"
                  :label="item"
                  :value="item">
                </el-option>
              </el-select> -->
              <!-- <div>
                <base-input v-model="searchQuery"
                            prepend-icon="fas fa-search"
                            placeholder="Search...">
                </base-input>
              </div> -->
            </b-col>
            <b-col cols="12">
              <h3>Filters</h3>

              <div class="form-row">
                <b-col cols="6">
                  <base-input
                    label="Year"
                    name="Year"
                    v-model="filter.year"
                  >
                    <select class="form-control" v-model="filter.year" @change="updateWeeksInYear">
                        <option Selected disabled value="x">
                          -- Year --
                        </option>
                        <option
                          v-for="item in available_weeks"
                          :key="item"
                          v-bind:value="item.for_year"
                        >
                          {{ item.for_year }}
                        </option>
                      </select>
                  </base-input>
                </b-col>

                <b-col cols="6">
                  <base-input
                    label="Week"
                    name="Week"
                    v-model="filter.week"
                  >
                    <select class="form-control" v-model="filter.week">
                        <option Selected disabled value="x">
                          -- Week --
                        </option>
                        <option
                          v-for="item in available_weeks_in_year"
                          :key="item"
                          v-bind:value="item"
                        >
                          {{ item }}
                        </option>
                      </select>
                  </base-input>
                </b-col>
              </div>

              <div style="float: right">
                <base-button size="sm" type="primary" @click="getWeeklyProductionReport()">Submit</base-button>
              </div>

              <div><br/><br/></div>

            </b-col>

            <!-- <div>available_weekly_production_reports: {{available_weekly_production_reports}}</div> -->
            <!-- <div>available_weeks: {{available_weeks}}</div> -->
            <!-- <div>available_weeks_in_year: {{available_weeks_in_year}}</div> -->
            <!-- <div>filter.year: {{filter.year}}</div> -->
            <!-- <div>filter.week: {{filter.week}}</div> -->
            <!-- <div>this.results: {{results}}</div> -->
            <!-- <div>weekly_production_report: {{weekly_production_report}}</div> -->

            <h3 style="text-align: center">Project Production Report - KPI Metrics (WIP)</h3>

            <b-col cols="12">
              <b-table :items="items" :fields="fields" small bordered striped no-border-collapse responsive></b-table>
            </b-col>

            <!-- <div>fields: {{fields}}</div> -->
            <!-- <div>items: {{items}}</div> -->
            
            <!-- <el-table :data="queriedData"
                      row-key="weekly_production_report_id"
                      header-row-class-name="thead-light"
                      @sort-change="sortChange"
                      >

              <el-table-column label="ID"
                             min-width="140px"
                             prop="weekly_production_report_id"
                             sortable>
                <template v-slot="{row}">
                    <b-media no-body class="align-items-center">
                        <b-media-body>
                            <span class="font-weight-600  mb-0 text-sm">{{row.weekly_production_report_id}}</span>
                        </b-media-body>
                    </b-media>
                </template>
              </el-table-column>
            </el-table> -->
          </div>
        </card>
      </div>
      <br/>
    </b-container>
  </div>
</template>

<script>
// // Charts
// import VueApexCharts from "vue-apexcharts";
// import * as chartConfigs from "@/components/Charts/config";
// import LineChart from "@/components/Charts/LineChart";
// import BarChart from "@/components/Charts/BarChart";
// import { Charts } from "@/components/Charts/config";

// // Components
// import BaseProgress from "@/components/BaseProgress";
// import RouteBreadCrumb from "@/components/Breadcrumb/RouteBreadcrumb";
// import StatsCard from "@/components/Cards/StatsCard";

// import DocumentSummary from "./ChartComponent/DocumentSummary.vue";
// import TurnOnSummary from "./ChartComponent/TurnOnSummary.vue";
// import ProjectSummary from "./ChartComponent/ProjectSummary.vue";

// import ProjectOverdue from "./ChartComponent/ProjectOverdue.vue";
// import TurnOnOverdue from "./ChartComponent/TurnOnOverdue.vue";
// import DocumentOverdue from "./ChartComponent/DocumentOverdue.vue";

// import MilestoneProgressBar from "./ChartComponent/MilestoneProgressBar.vue";

import { Table, TableColumn, Select, Option } from 'element-ui';
import Fuse from 'fuse.js';

import {
  LoginURL,
  clientId,
  clientSecret,
  apiDomain,
  getHeader,
  getcurrentDate,
} from "@/assets/js/config.js";
import axios from "axios";
import ErrorFunctionMixin from "@/assets/js/errorFunctionMix.js";
import Card from "../../../components/Cards/Card.vue";

export default {
  mixins: [ErrorFunctionMixin],
  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn
  },
  created() {
    this.getAvailableWeeklyProductionReport();
  },
  computed: {
    fields() {
      //'isRowHeader: true' to render it as a TH instead of TD
      // Formatter to capitalize the first letter, can be removed
      let fields = [{
        key: "work_week",
        stickyColumn: true,
        isRowHeader: true,
        // formatter: this.ucFirstLetter
        formatter: this.headerNames
      },];

      Object.keys(this.results).forEach((key) => {
        // Since the key is just an index, we hide it by setting label to an empty string.
        fields.push({
          key: key,
          // label: ""
          label: "WW" + this.results[key].for_week
        });
      });

      return fields;
    },
    items() {
      const items = [];

      /* map our columuns */
      const cols = Object.keys(this.results);

      /* 
        map our types based on the first element 
        requires all the elements to contain the same properties,
        or at least have the first one contain all of them
      */
      const types = Object.keys(this.results[cols[0]]);

      /* create an item for each type */
      types.forEach((work_week) => {
        const item = {
          work_week: work_week
        };

        /* fill up item based on our columns */
        cols.forEach((col) => {
          item[col] = this.results[col][work_week];
        });

        if (item.work_week != "for_week" && item.work_week != "weekly_production_report_id") {
          items.push(item);
        }

        // items.push(item);
      });

      return items;
    }
  },
  data() {
    return {
      propsToSearch:
      [
        'weekly_production_report_id',
        'for_year',
        'for_week',
        'total_projects',
        'total_project_sites',
        'total_ppa_approved_capacity',
        'total_pending_projects',
        'total_wip_projects',
        'total_wip_project_sites',
        'total_wip_ppa_approved_capacity',
        'total_turn_on_capacity',
        'total_turn_on_project_sites',
      ],
      tableColumns: [],
      tableData: [],
      selectedRows: [],
      available_weekly_production_reports: null,
      available_weeks: null,
      available_weeks_in_year: [],
      filter: {
        year: "x",
        week: "x",
      },
      weekly_production_report: null,
      results:
      [
        {
            "weekly_production_report_id": 1,
            "for_year": 2023,
            "for_week": 2,
            "total_projects": 25,
            "total_project_sites": 72,
            "total_ppa_approved_capacity": 600.51,
            "total_pending_projects": 16,
            "total_wip_projects": 8,
            "total_wip_project_sites": 8,
            "total_wip_ppa_approved_capacity": 200,
            "total_turn_on_capacity": 1130.685,
            "total_turn_on_project_sites": 8
        },
        {
            "weekly_production_report_id": 2,
            "for_year": 2023,
            "for_week": 2,
            "total_projects": 25,
            "total_project_sites": 72,
            "total_ppa_approved_capacity": 600.51,
            "total_pending_projects": 16,
            "total_wip_projects": 8,
            "total_wip_project_sites": 8,
            "total_wip_ppa_approved_capacity": 200,
            "total_turn_on_capacity": 1130.685,
            "total_turn_on_project_sites": 8
        }
      ],
      // [{
      //     correct: 0.007,
      //     errors: 0.081,
      //     missing: 0.912
      //   },
      //   {
      //     correct: 0.008,
      //     errors: 0.098,
      //     missing: 0.894
      //   },
      //   {
      //     correct: 0.004,
      //     errors: 0.089,
      //     missing: 0.91
      //   },
      //   {
      //     correct: 0.074,
      //     errors: 0.07,
      //     missing: 0.911
      //   }
      // ]
      table_name_list: {
        'for_year': "YEAR",
        'for_week': "WEEK",
        'total_projects': "No of Projects (Planned)",
        'total_project_sites': "Number of Sites (Planned)",
        'total_ppa_approved_capacity': "Total Capacity (Planned)",
        'total_pending_projects': "No of Projects not started (Actual after PPA signed)",
        'total_wip_projects': "No of Projects ongoing (Actual after PPA signed)",
        'total_wip_project_sites': "Number of Sites (Actual)",
        'total_wip_ppa_approved_capacity': "Total Capacity Ongoing (Actual)",
        'total_turn_on_capacity': "Turn on Capacity (Actual)",
        'total_turn_on_project_sites': "Turn on Sites (Actual)",
      },
    };
  },
  methods: {
    headerNames(string) {
      if (this.table_name_list.hasOwnProperty(string)) {
        return this.table_name_list[string];
      }
      
      return string;
    },
    ucFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    fuseData() {
        this.fuseSearch = new Fuse(this.tableData, {
        keys: this.propsToSearch,
        threshold: 0.3
        });
    },
    getAvailableWeeklyProductionReport() {
      axios
        .get(
          apiDomain +
            "dashboard/available_weekly_production_report",
          { headers: getHeader() }
        )
        .then((response) => {
          if (response.status === 200) {
            this.available_weekly_production_reports = response.data.data;
            this.fuseAvailableWeeks();
            this.getLatestWeeklyProductionReport();
            this.getWeeklyProductionReport();
          }
        })
        .catch((error) => {
          console.log(error.response.status);
          this.errorFunction(error, "Available Weekly Production Report");
        });
    },
    getWeeklyProductionReport() {
      axios
        .get(
          apiDomain +
            "dashboard/weekly_production_report/" + this.filter.year + "/" + this.filter.week,
          { headers: getHeader() }
        )
        .then((response) => {
          if (response.status === 200) {
            this.weekly_production_report = response.data.data;

            this.results = response.data.data;

            this.tableData = response.data.data;
            this.tableData.reverse();

            // this.tableData.forEach((e) => {
            //   delete e.weekly_production_report_id;
            // });

            this.fuseData();
          }
        })
        .catch((error) => {
          console.log(error.response.status);
          this.errorFunction(error, "Weekly Production Report");
        });
    },
    fuseAvailableWeeks(){
      var temp_available_weeks = [];
      this.available_weekly_production_reports.forEach((e) => {
        var check = temp_available_weeks.some(e_inner => e_inner['for_year'] === e['for_year']);
        if (!check) {
          temp_available_weeks.push(
            {
              "for_year": e['for_year'],
              "for_weeks": []
            }
          );
        }
        var year_index = temp_available_weeks.findIndex(e_inner => e_inner['for_year'] == e['for_year']);
        temp_available_weeks[year_index]['for_weeks'].push(e['for_week']);
      });
      this.available_weeks = temp_available_weeks;
    },
    updateWeeksInYear() {
      var year_index = this.available_weeks.findIndex(element => element['for_year'] == this.filter.year);
      this.available_weeks_in_year = this.available_weeks[year_index]['for_weeks'];
      this.filter.week = Math.max.apply(Math, this.available_weeks_in_year);
    },
    getLatestWeeklyProductionReport() {
      var temp_available_years = [];
      temp_available_years = this.available_weeks;
      temp_available_years.sort((a, b) => (a['for_year'] < b['for_year']) ? 1 : -1);
      this.filter.year = temp_available_years[0]['for_year'];
      this.updateWeeksInYear();
    },
  },
  mounted() {},
};
</script>

<style></style>