<template>
  <div>
    <base-header class="pb-6">
      <b-row class="py-4">
        <b-col>
          <h6 class="h2 text-white d-inline-block mb-0">{{ title }}</h6>
        </b-col>
        <b-col class="text-right">
          <router-link to="../Home" class="btn btn-neutral btn-sm">
            Documents
          </router-link>
        </b-col>
      </b-row>
    </base-header>

    <b-container fluid class="mt--6">
      <div class="card-wrapper">
        <card>
          <!-- Card header -->
          <table slot="header">
            <tr>
              <td class="pr-3">Project</td>
              <td><h4 class="mb-0">{{ setting.project_name }}</h4></td>
            </tr>
            <tr>
              <td class="pr-3">Site</td>
              <td><h4 class="mb-0">{{ setting.site_name }}</h4></td>
            </tr>
          </table>

          <!-- Card body -->
          <h3>Manage Documents</h3>
          <div class="d-flex align-items-baseline justify-content-between">
            <div>
              <!-- Category 1 -->
              <el-select class="ml-2" v-model="selectedCategory1" clearable :placeholder="setting.category1_name">
                <el-option v-for="item of categories1" :key="item.id" :value="item.id" :label="item.code"/>
              </el-select>
              <!-- Category 2 -->
              <el-select class="ml-2" v-model="selectedCategory2" clearable :placeholder="setting.category2_name">
                <el-option v-for="item of categories2" :key="item.id" :value="item.id" :label="item.code"/>
              </el-select>
              <!-- Category 3 -->
              <template v-if="setting.category3_enabled">
                <el-select class="ml-2" v-model="selectedCategory3" clearable :placeholder="setting.category3_name">
                  <el-option v-for="item of categories3" :key="item.id" :value="item.id" :label="item.code"/>
                </el-select>
              </template>
            </div>
            <!-- Document -->
            <router-link to="New" class="btn btn-primary btn-sm ml-3" v-if="access.create">
              New Document
            </router-link>
          </div>
          <el-table v-if="setting.id" :data="documents" header-row-class-name="thead-light" cell-class-name="fw-semibold" class="mt-2">
            <el-table-column :label="setting.category1_name" prop="category1_code" width="220px"/>
            <el-table-column :label="setting.category2_name" prop="category2_code" width="220px"/>
            <el-table-column :label="setting.category3_name" prop="category3_code" width="220px" v-if="setting.category3_enabled"/>
            <el-table-column label="Number" prop="number"/>
            <el-table-column label="Title" prop="title"/>
            <el-table-column label="Alternative Title" prop="alt_title"/>
            <el-table-column label="Status" prop="status"/>
            <el-table-column label="Actions">
              <template v-slot="{ row }">
                <router-link :to="`Edit/${row.id}`" class="btn btn-primary btn-sm" v-if="access.show">
                  View / Edit
                </router-link>
              </template>
            </el-table-column>
          </el-table>
        </card>
      </div>
    </b-container>
  </div>
</template>

<script>
import ErrorFunctionMixin from '@/assets/js/errorFunctionMix.js'
import { Table, TableColumn, Select, Option } from 'element-ui';
import axios from '@/util/axios'
import { getAccessByModule } from '@/util/access'

export default {
  mixins: [ErrorFunctionMixin],
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Select.name]: Select,
    [Option.name]: Option,
  },

  data() {
    return {
      title: 'Document Tracking',
      pid: null,
      sid: null,
      setting: {},
      categories1: [],
      categories2: [],
      categories3: [],
      selectedCategory1: null,
      selectedCategory2: null,
      selectedCategory3: null,
      documents: [],
      access: {},
    }
  },

  watch: {
    selectedCategory1() {
      this.loadDocuments()
    },

    selectedCategory2() {
      this.loadDocuments()
    },

    selectedCategory3() {
      this.loadDocuments()
    },
  },

  created() {
    this.pid = this.$route.params.pid
    this.sid = this.$route.params.sid
    this.access = getAccessByModule('DOC_REPO_REVIEW')

    this.loadSetting()
    this.loadCategories()
    this.loadDocuments()
  },

  methods: {
    loadSetting() {
      axios.get(`doc_repo/p/${this.pid}/s/${this.sid}/setting`)
        .then(response => {
          this.setting = response.data.data
        })
        .catch(error => {
          this.errorFunction(error, this.title)
        })
    },

    loadCategories() {
      axios.get(`doc_repo/p/${this.pid}/s/${this.sid}/category`)
        .then(response => {
          const categories = response.data.data
          this.categories1 = categories.filter(x => x.level == 1)
          this.categories2 = categories.filter(x => x.level == 2)
          this.categories3 = categories.filter(x => x.level == 3)
        })
        .catch(error => {
          this.errorFunction(error, this.title)
        })
    },

    loadDocuments() {
      const options = {
        params: {
          category1_id: this.selectedCategory1,
          category2_id: this.selectedCategory2,
          category3_id: this.selectedCategory3,
        },
      }

      axios.get(`doc_repo/p/${this.pid}/s/${this.sid}/document`, options)
        .then(response => {
          this.documents = response.data.data
        })
        .catch(error => {
          this.errorFunction(error, this.title)
        })
    },
  },
}
</script>
