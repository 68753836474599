
<template>
  <b-card body-class="p-0" header-class="border-0">
    <template v-slot:header>
      <b-row align-v="center">
        <b-col>
          <h5 class="mb-0">SolarNova 2 Project Forcast</h5>
        </b-col>
        <b-col class="text-right" >
          <h5 class="mb-0">Total : 202 Site & 5.96 MWp</h5>
        </b-col>
      </b-row>
    </template>


    <el-table
      class="table-responsive table"
      :data="tableData"
      stripe
      header-row-class-name="thead-light">

      <el-table-column label="Month" min-width="100px" prop="month">
         <template v-slot="{row}">
          <div class="d-flex align-items-center">
              <span style="font-size: 10px; ">{{row.month}}</span>
          </div>
        </template>
      </el-table-column>

      <el-table-column label="Site" min-width="90px" prop="site">
        <template v-slot="{row}">
          <div class="d-flex align-items-center">
              <span style="font-size: 10px;">{{row.site}}</span>
          </div>
        </template>
      </el-table-column>

      <el-table-column label="MWp" min-width="90px" prop="capacity">
        <template v-slot="{row}">
          <div class="d-flex align-items-center">
              <span style="font-size: 10px;">{{row.capacity}}</span>
            <!-- <base-progress :type="row.progressType" :value="row.progress" /> -->
          </div>
        </template>
      </el-table-column>

    </el-table>
  </b-card>
</template>
<script>
  import { BaseProgress } from '@/components';
  import { Table, TableColumn, DropdownMenu, DropdownItem, Dropdown} from 'element-ui'
  export default {
    name: 'solar-nova-2',
    components: {
      BaseProgress,
      [Table.name]: Table,
      [TableColumn.name]: TableColumn,
      [Dropdown.name]: Dropdown,
      [DropdownItem.name]: DropdownItem,
      [DropdownMenu.name]: DropdownMenu,
    },
    data() {
      return {
        dates:
        {
          start_date: '2021-05-21',
          end_date: '2021-08-20',
        },
        tableData: [
          // {
          //   month: 'March',
          //   site: '5',
          //   capacity: 0.2,
          //   progressType: 'gradient-danger',
          // },
          // {
          //   month: 'April',
          //   site: '6',
          //   capacity: 0.6,
          //   progressType: 'gradient-danger',
          // },
          // {
          //   month: 'May',
          //   site: '10',
          //   capacity: 0.9,
          //   progressType: 'gradient-danger',
          // },
          // {
          //   month: 'June',
          //   site: '12',
          //   capacity: 0.7,
          //   progressType: 'gradient-danger',
          // },
          // {
          //   month: 'July',
          //   site: '7',
          //   capacity: 0.8,
          //   progressType: 'gradient-danger',
          // },
          {
            month: 'August',
            site: '5',
            capacity: 0.2,
            progressType: 'gradient-danger',
          },
          {
            month: 'September',
            site: '9',
            capacity: 1.1,
            progressType: 'gradient-danger',
          },
          {
            month: 'October',
            site: '30',
            capacity: 1.46,
            progressType: 'gradient-danger',
          },
        ]
      }
    }

  }
</script>
<style>
</style>
