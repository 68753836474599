<template>
  <div>
    <base-header class="pb-6">
      <b-row class="py-4">
        <b-col cols="6">
          <h6 class="h2 text-white d-inline-block mb-0">{{ title }}</h6>
        </b-col>
        <b-col cols="6" class="text-right">
          <base-button size="sm" type="neutral" @click="$router.replace('../../../Listing')">Back</base-button>
        </b-col>
      </b-row>
    </base-header>

    <b-container fluid class="mt--6">
      <div class="card-wrapper">
        <card>
          <!-- Card header -->
          <h3 slot="header" class="mb-0">{{ model.name }}</h3>
          <!-- Card body -->
          <h3 class="mb-0">Categories</h3>
          <br>
          <div class="px-3">
            <div class="d-flex align-items-baseline justify-content-between">
              <h4>{{ model.category1_name }}</h4>
              <router-link v-if="access.create" :to="getNewCategoryRoute(1)" class="btn btn-primary btn-sm">
                New {{ model.category1_name }}
              </router-link>
            </div>
            <el-table :data="categories1" header-row-class-name="thead-light" cell-class-name="fw-semibold" class="mt-2">
              <el-table-column label="Code" prop="code"></el-table-column>
              <el-table-column label="Name" prop="name"></el-table-column>
              <el-table-column label="Actions">
                <template v-slot="{row}">
                  <router-link v-if="access.show" :to="getEditCategoryRoute(row)" class="btn btn-primary btn-sm">
                    View / Edit
                  </router-link>
                </template>
              </el-table-column>
            </el-table>
            <br><br>

            <div class="d-flex align-items-baseline justify-content-between">
              <h4>{{ model.category2_name }}</h4>
              <router-link v-if="access.create" :to="getNewCategoryRoute(2)" class="btn btn-primary btn-sm">
                New {{ model.category2_name }}
              </router-link>
            </div>
            <el-table :data="categories2" header-row-class-name="thead-light" cell-class-name="fw-semibold" class="mt-2">
              <el-table-column label="Code" prop="code"></el-table-column>
              <el-table-column label="Name" prop="name"></el-table-column>
              <el-table-column label="Actions">
                <template v-slot="{row}">
                  <router-link v-if="access.show" :to="getEditCategoryRoute(row)" class="btn btn-primary btn-sm">
                    View / Edit
                  </router-link>
                </template>
              </el-table-column>
            </el-table>
            <br><br>

            <template v-if="model.category3_enabled">
              <div class="d-flex align-items-baseline justify-content-between">
                <h4>{{ model.category3_name }}</h4>
                <router-link v-if="access.create" :to="getNewCategoryRoute(3)" class="btn btn-primary btn-sm">
                  New {{ model.category3_name }}
                </router-link>
              </div>
              <el-table :data="categories3" header-row-class-name="thead-light" cell-class-name="fw-semibold" class="mt-2">
                <el-table-column label="Code" prop="code"></el-table-column>
                <el-table-column label="Name" prop="name"></el-table-column>
                <el-table-column label="Actions">
                  <template v-slot="{row}">
                    <router-link v-if="access.show" :to="getEditCategoryRoute(row)" class="btn btn-primary btn-sm">
                      View / Edit
                    </router-link>
                  </template>
                </el-table-column>
              </el-table>
              <br><br>
            </template>
          </div>
        </card>
      </div>
    </b-container>
  </div>
</template>

<script>
import ErrorFunctionMixin from '@/assets/js/errorFunctionMix.js'
import { Table, TableColumn } from 'element-ui';
import axios from '@/util/axios'
import { getAccessByModule } from '@/util/access';

export default {
  mixins: [ErrorFunctionMixin],
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
  },

  data() {
    return {
      title: 'Document Tracking Templates',
      tid: null,
      model: {},
      categories1: [],
      categories2: [],
      categories3: [],
      access: {},
    }
  },

  created() {
    this.tid = this.$route.params.tid
    this.access = getAccessByModule('DOC_REPO')

    this.loadRecord()
    this.loadCategories()
  },

  methods: {
    loadRecord() {
      axios.get(`setup/doc_repo/template/${this.tid}`)
        .then(response => {
          this.model = response.data.data
        })
        .catch(error => {
          this.errorFunction(error, this.title)
        });
    },

    loadCategories() {
      axios.get(`setup/doc_repo/template/${this.tid}/category`)
        .then(response => {
          const categories = response.data.data
          this.categories1 = categories.filter(x => x.level == 1)
          this.categories2 = categories.filter(x => x.level == 2)
          this.categories3 = categories.filter(x => x.level == 3)
        })
        .catch(error => {
          this.errorFunction(error, this.title)
        });
    },

    getNewCategoryRoute(level) {
      const key = `category${level}_name`
      const name = this.model[key]

      return {
        path: `New/${level}`,
        query: { name },
      }
    },

    getEditCategoryRoute(category) {
      const key = `category${category.level}_name`
      const name = this.model[key]

      return {
        path: `Edit/${category.id}`,
        query: { name },
      }
    },
  },
}
</script>
