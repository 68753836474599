<template>
  <!-- <div class="scrolling-container"> -->
  <div>
    <highcharts
      :constructorType="'ganttChart'"
      class="hc"
      :options="chartOptions"
      ref="chart"
      style="margin: 0 auto;"
    ></highcharts>
  </div>
</template>

<script>
import axios from "@/util/axios";
import { apiDomain, getHeader } from "@/assets/js/config.js";
import moment from "moment";

import Highcharts from "highcharts";

var today = new Date(),
  day = 1000 * 60 * 60 * 24,

// Utility functions, needed by highchart
dateFormat = Highcharts.dateFormat,
defined = Highcharts.defined,
isObject = Highcharts.isObject;

today.setUTCHours(0);
today.setUTCMinutes(0);
today.setUTCSeconds(0);
today.setUTCMilliseconds(0);
today = today.getTime();

export default {
  props: ['siteId'],
  data() {
    return {
      obj_project: {
        id_project: null,
        tx_project: null,
        id_site: null,
        tx_site: null,
        id_milestone: null,
        id_milestone_task: null,
        data_milestone: null,
      },

      chartNavigatorData: null,
      chartOptions: {
        series: [],
        chart: {
          height: 550,
          scrollablePlotArea: {
            minHeight: 1280
          },
          zoomType: 'x',
          // panning: true,
          // height: 550,
          // scrollablePlotArea: {
          //   minHeight: 450
          // },
        }, 
        // scrollbar: {
        //   enabled: true
        // },
        rangeSelector: {
          enabled: true,
          inputEnabled: true,
        },
        xAxis: {
          //_ buat showing today indicator
          // currentDateIndicator: true,
          currentDateIndicator: {
            // width: 1,
            dashStyle: 'Dash',
            color: 'red',
            label: {
              format: 'Today %d %b %Y',
              // y: -5,
            },
            zIndex: 1,
          },
          min: today - 5 * day,
          max: today + 18 * day,
          // tickPixelInterval: 70
          // tickInterval: 1000 * 60 * 60 * 24 // 1 month
        },
        yAxis: {
          // scrollbar: {
          //   enabled: true,
          //   showFull: false
          // },
          // max: 7,
          // type: 'category',
        },
        navigator: {
            enabled: true,
            liveRedraw: true,
            series: {
                type: 'gantt',
                pointPlacement: 0.5,
                pointPadding: 0.25,
                accessibility: {
                    enabled: false
                },
                data: null,
            },
            yAxis: {
                // min: -1,
                // max: -2,
                min: 0,
                max: 3,
                reversed: true,
                categories: []
            }
        },
        // scrollbar: {
        //   enabled: true
        // },

        /*
        //_ bisa buat formatting tooltip
        tooltip: {
          pointFormatter: function () {
            var point = this,
              format = "%e. %b",
              options = point.options,
              completed = options.completed,
              amount = isObject(completed) ? completed.amount : completed,
              status = (amount || 0) * 100 + "%",
              lines;

            lines = [
              {
                value: point.name,
                style: "font-weight: bold;",
              },
              {
                title: "Start",
                value: dateFormat(format, point.start),
              },
              {
                visible: !options.milestone,
                title: "End",
                value: dateFormat(format, point.end),
              },
              {
                title: "Completed",
                value: status,
              },
            ];

            return lines.reduce(function (str, line) {
              var s = "",
                style = defined(line.style) ? line.style : "font-size: 0.8em;";
              if (line.visible !== false) {
                s =
                  '<span style="' +
                  style +
                  '">' +
                  (defined(line.title) ? line.title + ": " : "") +
                  (defined(line.value) ? line.value : "") +
                  "</span><br/>";
              }
              return str + s;
            }, "");
          },
        },
        */

        title: {
          text: 'site | project',
        },
        // accessibility: {
        //   keyboardNavigation: {
        //     seriesNavigation: {
        //       mode: "serialize",
        //     },
        //   },
        // },
        // lang: {
        //   accessibility: {
        //     axis: {
        //       xAxisDescriptionPlural:
        //         "The chart has a two-part X axis showing time in both week numbers and days.",
        //     },
        //   },
        // },
      },
    };
  },
  created() {
    //_ init dummy data
    this.obj_project.id_site = this.siteId;
    this.get_site_detail_info();
    
    // this.construct_chart_series();
    // this.construct_chart_option();
  },
  beforeMount() {
    this.get_project_site_milestone(this.obj_project.id_site);
  },
  methods: {
    get_site_detail_info() {
      // console.log('get_site_detail_info');
      //_ this one, nothing to do with milestone, just showing info of project / site 
      //  <h1 class="text-white">Project Milestone & Task ( {{ obj_project.tx_site }} )</h1>

      axios
        .get(apiDomain + "project/project_site/" + this.obj_project.id_site, { headers: getHeader(), })
        .then((response) => {
          if (response.status === 200) {
            // console.log(response.data.data);
            this.obj_project.id_project = response.data.data[0].project.project_id
            this.obj_project.tx_project = response.data.data[0].project.project_name
            // this.obj_project.id_site = response.data.data[0].site_id
            this.obj_project.tx_site = response.data.data[0].site_name
          }
        })
        .catch((error) => {
          this.errorFunction(error, "Site Information");
        });
    },
    get_project_site_milestone(site_id = 0) {
      //https://momensfile.sembcorp.com/project/project_site_milestone.show_by_site/7384
      // console.log("get_project_site_milestone");

      axios
        .get(
          apiDomain + "project/project_site_milestone.show_by_site/" + site_id,
          { headers: getHeader() }
        )
        .then((response) => {
          if (response.status === 200) {
            this.obj_project.data_milestone = response.data.data;

            // console.log(this.obj_project.data_milestone);

            //_ all data loaded, now time to construct + populate the chart
            let tmpData = this.construct_chart_series();
            // console.log(tmpData);

            // this.chartOptions.series[0].push(tmpData);
            // this.chartOptions.series = [tmpData];
            this.chartOptions.series = tmpData;
            // this.chartOptions.series_test = tmpData;
            // console.log(this.chartOptions);  

            // this.chartOptions.series.push(tmpData);

            // console.log(this.chartOptions);
          }
        })
        .catch((error) => {
          console.log(error.response.status);
          this.errorFunction(error, "Get Milestones by Site ID");
        })
        .finally(() => {
          //_
        });
    },
    construct_chart_series() {
      // console.log('construct_chart_series');
      // console.log(this.obj_project.data_milestone);

      let tmp_mile_data = [];         // <- this one we use to populate series
      let tmp_navigator_data = [];    // <- this one we use to populate navigator (why not using the same as series, cause navigator only uses 1st array, thus it not displaying all the date from the begining to the end)
      
      //_ populate chart title
      this.chartOptions.title.text = this.obj_project.tx_project;

      //_ populate data series
      let dataMile = this.obj_project.data_milestone;
      if (dataMile.length > 1) {
        dataMile.forEach((itemMile, indexMile) => {
          let tmp_task_data = [];

          tmp_task_data.push({
            name: itemMile.site_milestone_info,
            // id: itemMile.site_milestone_info,
            id: itemMile.site_milestone_id.toString(),
          });

          let dataTask = itemMile.milestone_tasks;
          dataTask.forEach((itemTask, indexTask) => {
            // console.log(itemTask);

            // console.log(itemTask.site_milestone_task_name);
            // console.log(itemTask.site_milestone_task_id);
            // console.log(itemMile.site_milestone_info);

            let dtPoint = {
              name: itemTask.site_milestone_task_name,
              // id: itemTask.site_milestone_task_id,
              id: itemTask.site_milestone_task_id.toString(),
              // parent: itemMile.site_milestone_info,
              parent: itemMile.site_milestone_id.toString(),
              start: this.format_datetime_to_unix_timestamp(itemTask.site_milestone_task_planned_start_date),
              end: this.format_datetime_to_unix_timestamp(itemTask.site_milestone_task_planned_end_date),
              completed: {
                amount: 0,
              },
            };

            tmp_task_data.push(dtPoint);
            tmp_navigator_data.push(dtPoint);
          });

          // let tmp = { 
          //   ...{ name: itemMile.site_milestone_info }, ...{tmp_task_data}
          // };

          let tmp = {
            name: itemMile.site_milestone_info,
            // name: itemMile.site_milestone_id,
            data: tmp_task_data
          }

          // console.log (tmp);
          tmp_mile_data.push(tmp);
        });
        
        // console.log(tmp_mile_data);
        // console.log('tmp_navigator_data');
        // console.log(tmp_navigator_data);
      }
      // this.chartOptions.series.push(tmp_mile_data);

      this.chartOptions.navigator.series.data = tmp_navigator_data;

      return tmp_mile_data;
    },
    format_datetime_to_unix_timestamp(tmp_value) {
      // console.log('format_datetime_to_unix_timestamp');

        // IMPORTANT: highchart using months in this calculation are zero-based - 0 month is January.  <-- I'm so whattttt ???
        // so what we need to do, the month MINUS 1

        if (tmp_value === null) {
            return 0;
        } else {
            let tmpDD = moment(tmp_value).format('DD');
            let tmpMM = moment(tmp_value).format('MM');
            let tmpYY = moment(tmp_value).format('YYYY');

            return Date.UTC(tmpYY, tmpMM-1, tmpDD);
        }

        // Date.UTC()
    },
  },
};
</script>

<style scoped>
/deep/ .scrolling-container {
	overflow-x: auto;
	-webkit-overflow-scrolling: touch;
}
</style>