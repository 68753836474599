<template>
  <card>
    <!-- Card header -->
    <h3 slot="header" class="mb-0" v-if="model.document_recurring_date == null">New Site Document Upload for ({{ model.document_title }}), &nbsp;Status : {{ model.document_status }}</h3>
    <h3 slot="header" class="mb-0" v-else>New Site Document Upload for ({{ model.document_title }}), &nbsp;Recurring Date : {{ model.document_recurring_date }}</h3>
    <div class="text-right">
    </div>

    <form class="needs-validation"
          @submit.prevent="submit" v-if="allow_upload == 'YES'">

      <div class="form-row">
        <b-col md="12">
          <base-input label="File Version Info"
                      name="File Version Info"
                      placeholder="File Version Info"
                      v-model="model_new.document_version">
          </base-input>
        </b-col>
      </div>

      <label class="font-weight-600 text-sm">Document Name</label>
      <a href="/img/brand/document_name.png" target="_blank" class="ml-2 text-blue">(Click to view sample)</a>
      <div class="form-row">
        <b-col md="1">
          <base-input placeholder="Project Code"
                      v-model="document_name.project_code"
                      disabled>
          </base-input>
        </b-col>

        <b-col md="1">
          <base-input placeholder="Postal Code"
                      v-model="document_name.postal_code"
                      disabled>
          </base-input>
        </b-col>

        <b-col md="2">
          <select class="form-control" v-model="document_name.revision_status">
            <option Selected disabled value="x">-- Revision Status --</option>
            <option value="IFA">IFA</option>
            <option value="IFC">IFC</option>
            <option value="IFI">IFI</option>
            <option value="ASB">ASB</option>
          </select>
        </b-col>

        <b-col md="2">
          <select class="form-control" v-model="document_name.drawing_type" >
            <option Selected disabled value="x">-- Drawing Type --</option>
            <option value="LAY">Layout</option>
            <option value="STR">Stringing</option>
            <option value="LPS">Lighting Protection System</option>
            <option value="SLD">SLD</option>
          </select>
        </b-col>

        <b-col md="1">
          <base-input v-model="document_name.revision_text"
                      disabled>
          </base-input>
        </b-col>

        <b-col md="1">
          <base-input placeholder="Index"
                      v-model="document_name.revision_index">
          </base-input>
        </b-col>
      </div>
      <br/>
      <div class="form-row">
        <b-col md="12">
            <label>
              <input type="file" id="file" ref="file" v-on:change="handleFileUpload()"/>
            </label>
        </b-col>
      </div>
      <br>
      <base-button type="primary" @click="createData()">Upload New File</base-button>

      <div>
        <br>
        <h5 class="text-danger"> Note : <br><br>
          <i>
          1. All file upload will be store at the File Server with Maximum limit around 600 MB per File.
          </i>
          <br>
          <i>
          2. System will only accept this following file type : jpeg, jpg, png, gif, pdf, dwg, doc, docx, xlsx, ppt, pptx.
          </i>
        </h5>
      </div>
    </form>

    <form class="needs-validation"
          @submit.prevent="submit" v-else>
      <div>
        <br>
        <h5 class="text-danger"> Note : <br><br>
          <i>
          1. Current Document Revision is being review. No New upload will be allow until the Document review is completed.
          </i>
          <br>
          <i>
          2. OR The Document has Completed it Final Approval Cycle in ASB.
          </i>
          <br>
          <i>
          3. No Status is available for Recurring Status. Please Click "Approval Details" on the Latest Upload to see the Latest Approval Code.
          </i>
          <br>
          <i>
          4. For One-Time Document Code 1 in from all the reviewer in ASB Status only consider (Complete Approval Cycle).
          </i>
          <br>
          <i>
          5. For Recurring Document Code 1 in from all the reviewer in a Single Recurring consider (Complete Approval Cycle).
          </i>
        </h5>
      </div>
    </form>

  </card>
</template>
<script>
  import BaseSlider from '@/components/BaseSlider'
  import {LoginURL, clientId, clientSecret, apiDomain, getHeader, getcurrentDate} from '@/assets/js/config.js'
  import axios from 'axios'
  import ErrorFunctionMixin from '@/assets/js/errorFunctionMix.js'

  export default {
    mixins: [ErrorFunctionMixin],
    components: {
      BaseSlider
    },
    created()
    {
      this.model.type = this.$route.params.id2
      if (this.model.type == "RECURRING")
      {
        this.model.site_document_recurring_id = this.$route.params.id

        axios.get(apiDomain + 'document/site_document_recurring/'+this.model.site_document_recurring_id, { headers: getHeader() })
        .then ( response => {
          if (response.status === 200)
          {
            this.model.document_id = response.data.data[0].site_document.site_document_id
            this.model_new.document_id = response.data.data[0].site_document.site_document_id
            this.model.document_title = response.data.data[0].site_document.document_title
            this.model.document_status = response.data.data[0].current_status.status_code
            this.model.document_recurring_date = response.data.data[0].show_document_recurring_date
            this.checkAllowUpload(this.model.site_document_recurring_id)
          }
        })
        .catch ( error => {
          this.errorFunction(error, "Site Document Information")
        } );
      }
      else
      {
        this.model.document_id = this.$route.params.id
        this.model_new.document_id = this.$route.params.id

        axios.get(apiDomain + 'document/site_document/'+this.model.document_id, { headers: getHeader() })
        .then ( response => {
          if (response.status === 200)
          {
            this.model.document_id = response.data.data[0].site_document_id
            this.model.document_title = response.data.data[0].document_title
            this.model.document_status = response.data.data[0].document_status.status_code
            this.model.document_category_id = response.data.data[0].document_category.document_category_id

            this.document_name.project_code = response.data.data[0].site.project.project_code
            this.document_name.postal_code = response.data.data[0].site.site_postal_code

            if (response.data.data[0].document_category.document_category_id == 1)
            {
              this.checkAllowUpload(this.model.document_id)
            }
            else
            {
              this.allow_upload = 'YES'
            }
          }
        })
        .catch ( error => {
          this.errorFunction(error, "Site Document Information")
        } );
      }
    },
    data() {
      return {
        model: {
          type: null,
          site_document_recurring_id: null,
          document_id: null,
          document_title: null,
          document_status: null,
          document_category_id: null,
        },
        allow_upload: 'YES',
        model_new: {
          document_id: null,
          site_document_recurring_id: null,
          document_recurring_date: null,
          document_version: null,
          document_name: null,
          document_file: '',
          status_id: 'x',
        },
        document_name: {
          project_code: null,
          postal_code: null,
          revision_status: 'x',
          drawing_type: 'x',
          revision_text: 'REV',
          revision_index: null,
        },
        document_status: [],
      }
    },
    methods: {
      checkAllowUpload(id)
      {
        axios.get(apiDomain + 'document/site_document_upload.check_allow_upload/' + id + '/' + this.model.type, { headers: getHeader() })
        .then ( response => {
          if (response.status === 200)
          {
            this.allow_upload = response.data
          }
        })
        .catch ( error => {
          this.errorFunction(error, "Site Document Uploads")
        } );
      },
      handleFileUpload()
      {
         this.model_new.document_file = this.$refs.file.files[0];
      },
      getDocumentStatus()
      {
        axios.get(apiDomain + 'lookup/document_status', { headers: getHeader() })
        .then ( response => {
          if (response.status === 200)
          {
            this.document_status = response.data.data
          }
        })
        .catch ( error => {
          console.log(error.response.status)
          if ( error.response.status == 401)
          {
              this.$router.push('/login')
          }
        } );
      },
      createData(){
        if ( !this.model_new.document_version || this.model_new.document_file == '' || this.document_name.revision_status == 'x' || 
            this.document_name.drawing_type == 'x' || this.document_name.revision_index == null)
        {
          this.$notify({
            message:
              '<b>Invalid Upload : All Column are Mandatory in Create New Upload </b> - These field are required & Must Select A file must be within 20MB and file extention of jpeg, jpg, png, gif, pdf, dwg, doc, docx, xlsx, ppt, pptx.',
            timeout: 10000,
            icon: 'ni ni-bulb-61',
            type: 'danger',
          });
        }
        else
        {
          const formData = new FormData()
          if (this.model.type == "RECURRING")
          {
            this.model_new.site_document_recurring_id = this.model.site_document_recurring_id
            formData.append('site_document_recurring_id', this.model_new.site_document_recurring_id);
          }

          this.model_new.document_id = this.model.document_id

          this.model_new.document_name = this.document_name.project_code+'-'+this.document_name.postal_code+'-'+this.document_name.revision_status
                                        +'-'+this.document_name.drawing_type+'-'+this.document_name.revision_text+this.document_name.revision_index

          formData.append('document_file', this.model_new.document_file);
          formData.append('site_document_id', this.model_new.document_id);
          formData.append('document_version', this.model_new.document_version);
          formData.append('document_name', this.model_new.document_name);
          formData.append('status_id', this.model_new.status_id);

          const tokenData = JSON.parse(window.localStorage.getItem('authUser'))
          const headers = { 'Accept' : 'application/json', 'Content-Type': 'multipart/form-data',   'Authorization' : 'Bearer ' + tokenData.access_token, };

          axios.post(apiDomain + 'document/site_document_upload', formData, { headers })
          .then ( response => {
            if (response.status === 200)
            {
                this.$notify({
                message:
                  '<b>New File Upload : ' + this.model.document_title + '</b> - Successfully Created.',
                timeout: 10000,
                icon: 'ni ni-bell-55',
                type: 'default',
                });
                this.$emit("myEvent")
                this.model_new.document_id = null
                this.model_new.site_document_recurring_id = null
                this.model_new.document_version = null
                this.model_new.document_name = null
                this.model_new.document_file = ''

                this.document_name.revision_status = 'x'
                this.document_name.drawing_type = 'x'
                this.document_name.revision_index = null

                this.$refs.file.value = null;
                if (this.model.type == "RECURRING")
                {
                   this.checkAllowUpload(this.model.site_document_recurring_id)
                }
                else
                {
                   if (this.model.document_category_id == 1)
                   {
                     this.checkAllowUpload(this.model.document_id)
                   }
                   else
                   {
                     this.model.allow_upload = 'YES'
                   }
                }
            }
            else {
              this.$notify({
                message:
                  '<b>Failed to upload new file</b> - Please repair your file and reupload.',
                timeout: 10000,
                icon: 'ni ni-bulb-61',
                type: 'danger',
              });
            }
          })
          .catch ( error => {
            this.errorFunction(error, "Upload File to Site Document")
          });
        }
      },
    }
  }
</script>
<style>
</style>
