<template>
  <card>
    <!-- Card header -->
    <h3 slot="header" class="mb-0">New Project Type</h3>
    <div class="text-right">
    </div>
    <form class="needs-validation"
          @submit.prevent="submit">

      <div class="form-row">
        <b-col md="12">
          <base-input label="Project Type"
                      name="Project Type"
                      placeholder="Project Type"
                      v-model="model.project_type" required>
          </base-input>
        </b-col>
      </div>

      <div class="form-row">
        <b-col md="4">
          <b-form-checkbox
            id="Active-Status"
            v-model="model.active_status"
            name="Active-Status"
            value="1"
            unchecked-value="0">
            <b>Active Status</b>
          </b-form-checkbox>
        </b-col>
      </div>
      <br>

      <base-button type="primary" @click="createData()">Create New Project Type</base-button>
      <base-button type="primary" @click="$router.back()">Cancel</base-button>
      <!-- <div>
        <br>
        <h5 class="text-danger"> Note : <br><br>
                <i>
                1. By Default all Date is set to "TODAY.
                </i>
                <br>
                <i>
                2. Developer Progress % is set to "0 %" By Default.
                </i>
        </h5>
      </div> -->
    </form>
  </card>
</template>
<script>
  import BaseSlider from '@/components/BaseSlider'
  import {LoginURL, clientId, clientSecret, apiDomain, getHeader, getcurrentDate} from '@/assets/js/config.js'
  import axios from 'axios'
  import ErrorFunctionMixin from '@/assets/js/errorFunctionMix.js'

  export default {
    mixins: [ErrorFunctionMixin],
    components: {
      BaseSlider
    },
    created()
    {
      var currentDate = getcurrentDate()
    },
    data() {
      return {
        model: {
          project_type: null,
          active_status: 1,
        },
        abc: '100',
      }
    },
    methods: {
      createData(){
        if ( !this.model.project_type )
        {
          this.$notify({
            message:
              '<b>Invalid Input : All Column are Mandatory in Create New Project Type </b> - These field are required.',
            timeout: 10000,
            icon: 'ni ni-bulb-61',
            type: 'danger',
          });
        }
        else
        {
          axios.post(apiDomain + 'setup/project_type', this.model, { headers: getHeader() })
          .then ( response => {
            if (response.status === 200)
            {
                this.$notify({
                message:
                  '<b>New Project Type: ' + this.model.project_type + '</b> - Successfully Created.',
                timeout: 10000,
                icon: 'ni ni-bell-55',
                type: 'default',
                });
                this.$router.back()
            }
          })
          .catch ( error => {
            console.log(error.response.data.errors)
            this.errorFunction(error, "Create New Project Type")
          });
        }
      },
    }
  }
</script>
<style>
</style>
