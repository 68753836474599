<template>
  <b-card no-body>
    <!-- Card header -->
    <b-card-header>
      <!-- Title -->
      <h5 v-if="model.project_code == null" class="h3 mb-0">
        Milestone Progress - Create / Edit Milestone
      </h5>
      <!-- <h5 v-else class="h3 mb-0">Milestone Progress for ({{ model.project_code }})</h5> -->
      <!-- <h5 v-else class="h3 mb-0">
        Edit Milestone for ({{ model.project_code }})
      </h5> -->
      <!-- <div class="text-right"> -->
        <div class="text-center">
        <base-button
          size="sm"
          type="primary"
          @click="editMilestone()"
          v-if="
            button_access.edit_button == 'YES' &&
            myUserID.email == model.project_manager.email
          "
        >Create / Edit Milestone</base-button
        >
      </div>
    </b-card-header>
    <!-- Card body -->
    <!-- <b-card-body >
      <b-list-group flush class="list my--3">
        <b-list-group-item v-for="(item, index) in project_milestones"
            :key="index"
            class="px-0">
          <b-row align-v="center">
            <b-col md="auto" >
              <span :class="`text-danger`" v-if="item.milestone_progress > 0 && item.milestone_progress < 50" >● </span>
              <span :class="`text-info`" v-else-if="item.milestone_progress >= 50 && item.milestone_progress <= 90" >● </span>
              <span :class="`text-success`" v-else-if="item.milestone_progress >= 91" >● </span>
              <span :class="`text-sucess`" v-else>● </span>
            </b-col>
            <b-col >
              <h5>{{item.milestone_code}} - ({{item.milestone_progress}}%)</h5>
              <base-progress v-if="item.milestone_progress > 0 && item.milestone_progress < 50" min-width="30px" type="danger" :value="item.milestone_progress"/>
              <base-progress v-else-if="item.milestone_progress >= 50 && item.milestone_progress <= 90" min-width="30px" type="info" :value="item.milestone_progress"/>
              <base-progress v-else min-width="30px" type="success" :value="item.milestone_progress"/>
            </b-col>
          </b-row>
        </b-list-group-item>
      </b-list-group>
    </b-card-body> -->
  </b-card>
</template>
<script>
import {
  LoginURL,
  clientId,
  clientSecret,
  apiDomain,
  getHeader,
  getcurrentDate,
} from "@/assets/js/config.js";
import axios from "axios";

export default {
  created() {
    var project_milestone_access = JSON.parse(
      window.localStorage.getItem("project_milestone_access")
    );
    this.button_access.edit_button = project_milestone_access.access_edit;
    const LoginUser = JSON.parse(window.localStorage.getItem("loginUser"));
    this.myUserID = LoginUser;

    this.model.project_id = this.$route.params.id;
    axios
      .get(
        apiDomain +
          "project/project_milestone.show_by_project/" +
          this.model.project_id,
        { headers: getHeader() }
      )
      .then((response) => {
        if (response.status === 200) {
          this.model.project_code = response.data.data[0].project.project_name;
          this.model.project_manager.name =
            response.data.data[0].project.project_manager.name;
          this.model.project_manager.email =
            response.data.data[0].project.project_manager.email;
          this.project_milestones = response.data.data;
        }
      })
      .catch((error) => {
        console.log(error.response.status);
      });
  },
  data() {
    return {
      model: {
        project_id: null,
        project_code: null,
        milestone_code: null,
        project_manager: {
          name: null,
          email: null,
        },
      },
      myUserID: null,
      project_milestones: [],
      button_access: {
        edit_button: null,
      },
    };
  },
  methods: {
    editMilestone() {
      this.$router.push({
        path: "/projectMilestoneListing/" + this.model.project_id,
      });
    },
    getDataListing() {
      this.model.project_id = this.$route.params.id;
      axios
        .get(
          apiDomain +
            "project/project_milestone.show_by_project/" +
            this.model.project_id,
          { headers: getHeader() }
        )
        .then((response) => {
          if (response.status === 200) {
            this.model.project_code =
              response.data.data[0].project.project_name;
            this.project_milestones = response.data.data;
          }
        })
        .catch((error) => {
          console.log(error.response.status);
        });
    },
  },
};
</script>
