var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',{staticClass:"bg-default shadow",attrs:{"no-body":""}},[_c('b-card-header',{staticClass:"bg-transparent border-0"},[(_vm.model.project_name == null )?_c('h3',{staticClass:"mb-0 text-white"},[_vm._v("Group Listing - Create New Group")]):_c('h3',{staticClass:"mb-0 text-white"},[_vm._v("Group Listing for ("+_vm._s(_vm.model.project_name)+")")]),_c('div',{staticClass:"text-right"},[(((_vm.button_access.group_new == 'YES') && (_vm.myUserID.email == _vm.model.project_manager.email)))?_c('base-button',{attrs:{"size":"sm","type":"neutral"},on:{"click":function($event){return _vm.newProjectGroup()}}},[_vm._v("Create New Group")]):_vm._e()],1)]),_c('el-table',{staticClass:"table-responsive table-dark",attrs:{"header-row-class-name":"thead-dark","data":_vm.queriedData}},[_c('el-table-column',{attrs:{"label":"Group Name","min-width":"180px","prop":"group_name","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('b-media',{staticClass:"align-items-center",attrs:{"no-body":""}},[_c('b-media-body',[_c('span',{staticClass:"font-weight-600 name mb-0 text-sm"},[_vm._v(_vm._s(row.group_name))])])],1)]}}])}),_c('el-table-column',{attrs:{"label":"Project","min-width":"140px","prop":"project.project_name","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('b-media',{staticClass:"align-items-center",attrs:{"no-body":""}},[_c('b-media-body',[_c('span',{staticClass:"font-weight-600 name mb-0 text-sm"},[_vm._v(_vm._s(row.project.project_name))])])],1)]}}])}),_c('el-table-column',{attrs:{"label":"Engineer","min-width":"150px","prop":"group_engineer","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('b-media',{staticClass:"align-items-center",attrs:{"no-body":""}},[_c('b-media-body',[_c('span',{staticClass:"font-weight-600 name mb-0 text-sm"},[_vm._v(_vm._s(row.group_engineer.name))])])],1)]}}])}),_c('el-table-column',{attrs:{"label":"Progress","min-width":"210px","prop":"group_progress","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"d-flex align-items-center"},[_c('div',[_c('span',{staticClass:"font-weight-600 name mb-0 text-sm"},[_vm._v(_vm._s(row.group_progress)+"%   ")])]),_c('div',[(row.group_progress > 0 && row.group_progress < 50)?_c('base-progress',{attrs:{"min-width":"30px","type":"danger","value":row.group_progress}}):(row.group_progress >= 50 && row.group_progress <= 90)?_c('base-progress',{attrs:{"min-width":"30px","type":"info","value":row.group_progress}}):_c('base-progress',{attrs:{"min-width":"30px","type":"success","value":row.group_progress}})],1)]),_c('b-media',{staticClass:"align-items-center",attrs:{"no-body":""}},[_c('b-media-body',[_c('badge',{staticClass:"badge-dot mr-4",attrs:{"type":""}},[(row.status.status_code == 'Delayed')?_c('i',{staticClass:"bg-danger"}):(row.status.status_code == 'On-Hold')?_c('i',{staticClass:"bg-danger"}):(row.status.status_code == 'Reviewed')?_c('i',{staticClass:"bg-success"}):(row.status.status_code == 'Completed')?_c('i',{staticClass:"bg-success"}):_c('i',{staticClass:"bg-info"}),_c('span',{staticClass:" font-weight-600 name mb-0 text-white "},[_vm._v(_vm._s(row.status.status_code))])])],1)],1)]}}])}),_c('el-table-column',{attrs:{"min-width":"140px","align":"right","label":"Actions"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var $index = ref.$index;
var row = ref.row;
return _c('div',{staticClass:"d-flex"},[(_vm.button_access.group_show == 'YES')?_c('base-button',{attrs:{"size":"sm","type":"primary"},on:{"click":function($event){return _vm.projectGroupDashboard($index, row)}}},[_vm._v(" Dashboard ")]):_vm._e()],1)}}])})],1),_c('b-card-footer',{staticClass:"py-2 d-flex justify-content-end bg-transparent"},[_c('base-pagination',{staticClass:"pagination-no-border",attrs:{"current":_vm.pagination.currentPage,"per-page":_vm.pagination.perPage,"total":_vm.total},on:{"change":function($event){return _vm.paginationChanged($event)}}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }