<template>
  <card>
    <!-- Card header -->
    <h3 slot="header" class="mb-0" v-if="model.document_recurring_date == null">Project Document Details Information for ({{ model.document_title }}) </h3>
    <h3 slot="header" class="mb-0" v-else>Project Document Details Information for ({{ model.document_title }}), &nbsp;Recurring Date: {{ model.document_recurring_date }} </h3>
    <div class="text-right">
      <!-- <router-link :to="'/projectDocumentEditFrProject/' + model.project_document_id">
        <base-button size="sm" type="primary"
        v-if="button_access.edit_button == 'YES'">Edit</base-button>
      </router-link> -->
    </div>
    <form class="needs-validation"
          @submit.prevent="submit">

      <div class="form-row">
        <b-col md="8">
          <base-input label="Document Title"
                      name="Document Title"
                      placeholder="Document Title"
                      v-model="model.document_title" disabled>
          </base-input>
        </b-col>

        <b-col md="4">
          <base-input label="Project"
                      name="Project"
                      v-model="model.project_name" disabled>
          </base-input>
        </b-col>
      </div>

      <!-- <div class="form-row">
        <b-col md="4">
          <base-input label="Project"
                      name="Project"
                      v-model="model.project_name" disabled>
          </base-input>
        </b-col>

        <b-col md="4">
          <base-input label="Group"
                      name="Group"
                      v-model="model.group_name" disabled>
          </base-input>
        </b-col>

        <b-col md="4">
          <base-input label="Site"
                      name="Site"
                      v-model="model.site_name" disabled>
          </base-input>
        </b-col>
      </div> -->

      <div class="form-row">
        <b-col md="4">
          <base-input label="Document Type"
                      name="Document Type"
                      v-model="model.document_type_code" disabled>
          </base-input>
        </b-col>

        <!-- <b-col md="3">
          <base-input label="Document Current Milestone"
                      name="Document Current Milestone"
                      v-model="model.milestone_code" disabled>
          </base-input>
        </b-col> -->

        <b-col md="4">
          <base-input label="Document Category"
                      name="Document Category"
                      v-model="model.document_category" disabled>
          </base-input>
        </b-col>

        <b-col md="4">
          <base-input label="Assign To"
                      name="Assign To"
                      v-model="model.assign_to_user" disabled>
          </base-input>
        </b-col>

        <!-- <b-col md="3">
          <base-input label="Document Mandatory"
                      name="Document Mandatory"
                      v-model="model.document_mandatory" disabled>
          </base-input>
        </b-col> -->
      </div>

      <div class="form-row">
        <b-col md="4" v-if="model.document_type_code == 'RECURRING'">
          <base-input label="Document Status"
                      name="Document Status"
                      v-model="model.refer_document_status" disabled>
          </base-input>
        </b-col>
        <b-col md="4" v-else>
          <base-input label="Document Status"
                      name="Document Status"
                      v-model="model.document_status" disabled>
          </base-input>
        </b-col>

        <b-col md="4">
          <base-input label="Document Mandatory"
                      name="Document Mandatory"
                      v-model="model.document_mandatory" disabled>
          </base-input>
        </b-col>

        <b-col md="4">
          <base-input label="Required ASB"
                      name="Required ASB"
                      v-model="model.asb_flag_display" disabled>
          </base-input>
        </b-col>
      </div>

      <div v-if="model.document_type_code == 'RECURRING'" class="form-row">
       <b-col md="4">
          <base-input label="Recurring Interval"
                      name="Recurring Interval"
                      v-model="model.recurring_interval" disabled>
          </base-input>
        </b-col>

        <b-col md="4">
          <base-input label="Start Date"
                      name="Start Date"
                      v-model="model.recurring_start_date" disabled>
          </base-input>
        </b-col>

        <b-col md="4">
          <base-input label="End Date"
                      name="End Date"
                      v-model="model.recurring_end_date" disabled>
          </base-input>
        </b-col>
      </div>
      <div v-if="model.document_category == 'IFA Document'">
        <hr>
        <div class="form-row" >
          <b-col md="12">
              <b>Require Approval From</b>
          </b-col>
        </div>
        <br>
        <div class="form-row">
          <b-col md="1.5">
            <b-form-checkbox
              id="req_approval_project_owner"
              v-model="model.req_approval_project_owner"
              name="req_approval_project_owner"
              value="1"
              unchecked-value="0" disabled>
              <b>Project Owner</b>
            </b-form-checkbox>
          </b-col>

          <b-col md="1.5">
            <b-form-checkbox
              id="req_approval_project_manager"
              v-model="model.req_approval_project_manager"
              name="req_approval_project_manager"
              value="1"
              unchecked-value="0" disabled>
              <b>Project Manager</b>
            </b-form-checkbox>
          </b-col>

          <b-col md="1.5">
            <b-form-checkbox
              id="req_approval_project_engineer"
              v-model="model.req_approval_project_engineer"
              name="req_approval_project_engineer"
              value="1"
              unchecked-value="0" disabled>
              <b>Project Engineer</b>
            </b-form-checkbox>
          </b-col>

          <b-col md="1.5">
            <b-form-checkbox
              id="req_approval_engineer"
              v-model="model.req_approval_engineer"
              name="req_approval_engineer"
              value="1"
              unchecked-value="0" disabled>
              <b>Engineer</b>
            </b-form-checkbox>
          </b-col>

          <b-col md="1.5">
            <b-form-checkbox
              id="req_approval_qa_qc"
              v-model="model.req_approval_qa_qc"
              name="req_approval_qa_qc"
              value="1"
              unchecked-value="0" disabled>
            <b>QA / QC</b>
            </b-form-checkbox>
          </b-col>

          <b-col md="1.5">
            <b-form-checkbox
              id="req_approval_safety"
              v-model="model.req_approval_safety"
              name="req_approval_safety"
              value="1"
              unchecked-value="0" disabled>
            <b>Safety</b>
            </b-form-checkbox>
          </b-col>

          <b-col md="1.5">
            <b-form-checkbox
              id="req_approval_planner"
              v-model="model.req_approval_planner"
              name="req_approval_planner"
              value="1"
              unchecked-value="0" disabled>
            <b>Planner</b>
            </b-form-checkbox>
          </b-col>

          <b-col md="1.5">
            <b-form-checkbox
              id="req_approval_purchasing"
              v-model="model.req_approval_purchasing"
              name="req_approval_purchasing"
              value="1"
              unchecked-value="0" disabled>
            <b>Purchasing</b>
            </b-form-checkbox>
          </b-col>

          <b-col md="1.5">
            <b-form-checkbox
              id="req_approval_admin"
              v-model="model.req_approval_admin"
              name="req_approval_admin"
              value="1"
              unchecked-value="0" disabled>
            <b>Admin</b>
            </b-form-checkbox>
          </b-col>
        </div>
      </div>
      <br><br>

      <!-- <base-button type="primary" native-type="submit" @click="notifyVueNew('default')" >Update Milestone Template</base-button>
      <base-button type="primary" @click="$router.go(-1)">Cancel</base-button> -->
    </form>
  </card>
</template>
<script>
  import BaseSlider from '@/components/BaseSlider'
  import {LoginURL, clientId, clientSecret, apiDomain, getHeader} from '@/assets/js/config.js'
  import axios from 'axios'
  import ErrorFunctionMixin from '@/assets/js/errorFunctionMix.js'

  export default {
    mixins: [ ErrorFunctionMixin],
    components: {
      BaseSlider
    },
    created()
    {
      //this.model.document_id = this.$route.params.id
      var project_document_access = JSON.parse(window.localStorage.getItem('project_document_access'))
      this.button_access.edit_button = project_document_access.access_edit

      this.getViewData()

      axios.get(apiDomain + 'template/milestone_template', { headers: getHeader() })
      .then ( response => {
        if (response.status === 200)
        {
          this.milestone_template = response.data.data
        }
      })
      .catch ( error => {
        this.errorFunction(error, "Milestone Template Data")
      } );

    },
    data() {
      return {
        model: {
          type: null,
          project_document_id: null,
          project_document_recurring_id: null,
          document_recurring_date: null,
          document_title: null,
          document_information: null,
          project_name: null,
          group_name: null,
          site_name: null,
          document_type_code: null,
          milestone_code: null,
          status_code: null,
          assign_to_user: null,
          document_mandatory: null,
          document_category: null,
          req_approval_project_owner: 1,
          req_approval_project_manager: 1,
          req_approval_project_engineer: 1,
          req_approval_engineer: 1,
          req_approval_qa_qc: 1,
          req_approval_safety: 1,
          req_approval_onm: 1,
          req_approval_planner: 1,
          req_approval_purchasing: 1,
          req_approval_admin: 1,
          recurring_interval: null,
          recurring_start_date: null,
          recurring_end_date: null,
          refer_document_status: "Please refer to Recurring Date for Status",
          asb_flag_display: null,
        },
        milestone_template: [],
        button_access: {
          edit_button: null,
        },
      }
    },
    methods: {
      getViewData()
      {
        this.model.type = this.$route.params.id2
        if (this.model.type == "RECURRING")
        {
          this.model.project_document_recurring_id = this.$route.params.id
          axios.get(apiDomain + 'document/project_document_recurring/'+this.model.project_document_recurring_id, { headers: getHeader() })
          .then ( response => {
            if (response.status === 200)
            {

              this.model.project_document_id = response.data.data[0].project_document.project_document_id
              this.model.document_recurring_date = response.data.data[0].show_document_recurring_date
              this.getdata()
            }
          })
          .catch ( error => {
            this.errorFunction(error, "Project Document Recurring Information")
          } );
        }
        else
        {
          this.model.project_document_id = this.$route.params.id
          this.getdata()
        }
      },
      getdata()
      {
        axios.get(apiDomain + 'document/project_document/'+this.model.project_document_id, { headers: getHeader() })
        .then ( response => {
          if (response.status === 200)
          {
            this.model.document_title = response.data.data[0].document_title
            this.model.project_name = response.data.data[0].project.project_name
            this.model.document_type_code = response.data.data[0].document_type.document_type_code
            this.model.document_category = response.data.data[0].document_category.document_category
            this.model.document_mandatory = response.data.data[0].show_document_mandatory
            this.model.assign_to_user = response.data.data[0].assign_to_user.name
            this.model.recurring_interval = response.data.data[0].project_document_recurring.document_recurring_interval
            this.model.recurring_start_date = response.data.data[0].show_recurring_start_date
            this.model.recurring_end_date = response.data.data[0].show_recurring_end_date
            this.model.document_status = response.data.data[0].document_status.status_code

            this.model.req_approval_project_owner = response.data.data[0].req_approval_project_owner
            this.model.req_approval_project_manager = response.data.data[0].req_approval_project_manager
            this.model.req_approval_project_engineer = response.data.data[0].req_approval_project_engineer
            this.model.req_approval_engineer = response.data.data[0].req_approval_engineer
            this.model.req_approval_qa_qc = response.data.data[0].req_approval_qa_qc
            this.model.req_approval_safety = response.data.data[0].req_approval_safety
            this.model.req_approval_onm = response.data.data[0].req_approval_onm
            this.model.req_approval_planner = response.data.data[0].req_approval_planner
            this.model.req_approval_purchasing = response.data.data[0].req_approval_purchasing
            this.model.req_approval_admin = response.data.data[0].req_approval_admin
            this.model.asb_flag_display = response.data.data[0].asb_flag_display  
          }
        })
        .catch ( error => {
            this.errorFunction(error, "Project Document Information")
        } );
      }
    }
  }
</script>
<style>
</style>
