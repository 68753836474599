<template>
  <div>
    <v-calendar class="custom-calendar max-w-full" :masks="masks" :attributes="attributes" disable-page-swipe
      is-expanded :is-dark="false">
      <template v-slot:day-content="{ day, attributes }">
        <!-- <a href="#/icons">
              <img src="/img/theme/contractor.png" alt="" />
           -->
        <div class="d-flex flex-column h-100 position-relative overflow-hidden"
          @click="navigateToAdd(day.day, day.month)">
          <span class="display-5 text-dark">{{ day.day }}

            <b-modal ref="firstModal" centered hide-footer title="List of images" class="text-white bg-dark">
              <div>
                <vueper-slides :touchable="false" fade :autoplay="true" :duration="2000" :bullets="false" fixed-height="300px">
                  <vueper-slide v-for="(entry, index) in attributes2" :key="index" :image="entry.customData.img2" v-if="clickedDate === entry.dates.day2 && clickedDate2 === entry.dates.month">
                  </vueper-slide>
                </vueper-slides>                
              </div>
              <div container class="button-container">
                <!-- <b-button @click="redirectToModal('secondModal')">View info on this day</b-button> -->
                <b-button @click="redirectToModal('thirdModal')">Add Info</b-button>
              </div>
            </b-modal>

            <b-modal ref="secondModal" centered hide-footer title="View Info" class="text-white bg-dark">
              <p class="my-4">This is the second modal.</p>
              <b-button @click="redirectToModal('firstModal')">Back to Selection</b-button>
            </b-modal>

            <b-modal ref="thirdModal" centered hide-footer title="Add Info" class="text-white bg-dark">
              <div class="d-block text-center">
                <div class="w-full max-w-sm">
                  <validation-observer v-slot="{ handleSubmit }">
                    <form class="needs-validation pl-4 pr-4" @submit.prevent="handleSubmit(onSubmit)">
                      <!-- Date Picker -->
                      <label class="form-label text-muted fw-bold mb-2" for="date">Select Date <i
                          class="pl-4 fas fa-calendar-alt"></i></label>
                      <b-form-row class="d-flex w-100 mb-4">
                        <validation-provider class="flex-grow-1 pr-3" name="Date" rules="required"
                          v-slot="{ classes, errors }">
                          <b-form-group :invalid-feedback="errors[0]">
                            <v-date-picker v-model="date">
                              <template v-slot="{ inputValue, inputEvents }">
                                <input id="date" class="form-control border-end-0"
                                  :class="{ 'is-invalid': errors.length }" :value="inputValue" v-on="inputEvents" />
                              </template>
                            </v-date-picker>
                          </b-form-group>
                        </validation-provider>

                        <!-- Clear button -->
                        <button type="button" class="btn text-white fw-bold px-4 rounded-end"
                          :class="date ? 'btn-danger' : 'btn-secondary'" :disabled="!date" @click="date = null"
                          style="margin-bottom: auto;">
                          Clear
                        </button>
                      </b-form-row>

                      <!-- Remark Input -->
                      <b-form-row>
                        <b-col>
                          <validation-provider name="Remark" rules="required" v-slot="{ classes, errors }">
                            <b-form-group label="Add Remark" :invalid-feedback="errors[0]">
                              <textarea id="remark" class="form-control mb-4" v-model="remark" rows="3"
                                placeholder="Enter your remarks here..."></textarea>
                            </b-form-group>
                          </validation-provider>
                        </b-col>
                      </b-form-row>

                      <!-- File Upload Section -->
                      <b-form-row>
                        <b-col>
                          <validation-provider name="File" rules="required" v-slot="{ classes, errors }">
                            <b-form-group label="Upload Files Here" :invalid-feedback="errors[0]">
                              <div class="border border-dark p-3"
                                style="background-color: #2c545a; color: white; border-radius:10px">
                                <div class="text-center text-black mt-3 mb-2">
                                  <img src="/img/theme/general/upload.png" alt="">
                                  <div class="mt-1"><small>Files Supported: JPG, PNG, GIF</small></div>
                                  <div class="mt-2">
                                    <input type="file" ref="fileInput" accept="image/*" @change="onFileChange"
                                      style="display: none;">
                                    <base-button type="primary" style="width: 150px;" @click="triggerFileInput">Choose
                                      File</base-button>
                                    <base-button type="primary" style="width: 150px;"
                                      @click="uploadFile">Save</base-button>
                                  </div>
                                  <div class="mt-2" style="font-size: 12px;">Maximum size: 5MB</div>
                                </div>

                                <b-row class="justify-content-md-center" v-if="fileSelected">
                                  <b-col md="6">
                                    <div class="text-white text-center">File Uploaded: {{ selectedFileName }}</div>
                                  </b-col>
                                </b-row>
                                <b-row class="justify-content-md-center" v-else>
                                  <b-col md="6">
                                    <div class="text-white text-center">No File Uploaded</div>
                                  </b-col>
                                </b-row>
                              </div>
                            </b-form-group>
                          </validation-provider>
                        </b-col>
                      </b-form-row>

                      <!-- Buttons for navigation and submission -->
                      <div class="d-flex justify-content-center mt-3">
                        <b-button @click="redirectToModal('firstModal')" class="me-2">Back to Selection</b-button>
                        <button @click="onSubmit" type="submit" class="btn btn-primary">Submit</button>
                      </div>
                    </form>
                  </validation-observer>
                </div>
              </div>
            </b-modal>

          </span>

          <div class="flex-grow-1 overflow-auto">
            <p v-for="attr in attributes" :key="attr.key" class="h2 rounded-sm p-1 my-1" :class="attr.customData.class">
              <img :src="attr.customData.img2" alt="" style="height:60px;width:60px" />
            </p>
          </div>
        </div>



        <!-- </a> -->
      </template>


    </v-calendar>



  </div>
</template>
<script>
import { Table, TableColumn, Select, Option } from "element-ui";
import DemoModal from './New.vue';
import { LoginURL, clientId, clientSecret, apiDomain, getHeader, getcurrentDate } from "@/assets/js/config.js";

import { VueperSlides, VueperSlide } from 'vueperslides'
import 'vueperslides/dist/vueperslides.css'

export default {
  name: "Detail",
  components: {
    DemoModal,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    VueperSlides,
    VueperSlide,
  },

  computed: {

  },

  // el: '#slider',
  data() {
    const month = new Date().getMonth();
    const year = new Date().getFullYear();
    return {
      clickedDate: null,
      clickedDate2: null,
      image: null,
      access: {},
      imageEntries: [], // New property to hold all entries
      remark: "",
      selectedFile: null,  // Store the selected file object
      selectedFileName: '', // Store the selected file name
      fileSelected: false, // Track if a file has been selected
      model: {

      },
      date: getcurrentDate(),
      remark: '', // New property for remarks
      errorMessage: '', // Existing error handling
      masks: {
        weekdays: 'WWW',
      },
      attributes: [
        {
          key: 1,
          customData: {
            img2: '/img/calendar/2024/10/24/1.jpg',
            // img2: '/img/calendar/activity_ex_1.jpg',
            // class: 'bg-card-dashboard-solid text-white', // Changed text to white
          },
          dates: new Date(year, 9, 24),
        },
        {
          key: 1,
          customData: {
            img2: '/img/calendar/2024/10/28/1.jpg',
            // img2: '/img/calendar/activity_ex_2.jpg'
            // class: 'bg-card-dashboard-solid text-white', // Changed text to white
          },
          dates: new Date(year, 9, 28),
        },
        {
          key: 1,
          customData: {
            img2: '/img/calendar/2024/10/29/1.jpg',
            // img2: '/img/calendar/activity_ex_3.jpg'
            // class: 'bg-card-dashboard-solid text-white', // Changed text to white
          },
          dates: new Date(year, 9, 29),
        },
      ],
      attributes2: [
        // key:'x',
        // customData: {
        //     img2:'',

        //   },
        //   dates: null,
      ], // To store image objects
    };
  },
  created() {
    this.loadImages(); // Load images when the component is created
  },
  methods: {
    loadImages() {
      //_ jadi, actually this is dummy data to populate the calendar, simulating there is data

      //const attributes2 = []; // Initialize the array to store objects
      const baseDir = "/img/calendar"; // Base directory for images
      const year = 2024; // Define year (you can make this dynamic if needed)
      const month = 10; // Define month (you can make this dynamic if needed)

      for (let i = 1; i <= 6; i++) {
        // Pad the number to ensure it's two digits (e.g., 01, 02, ...)
        const imgNumber = i.toString().padStart(2, '0') + '.jpg';

        this.attributes2.push({
          key: this.attributes2.length + 1, // Unique key
          customData: {
            img2: `${baseDir}/${year}/${month.toString().padStart(2, '0')}/` + 24 + `/${i}.jpg`, // Full path to image
          },
          dates: {
            year: year,
            month: month,
            day2: 24
          }
        });
      }

      for (let i = 1; i <= 6; i++) {
        // Pad the number to ensure it's two digits (e.g., 01, 02, ...)
        const imgNumber = i.toString().padStart(2, '0') + '.jpg';

        this.attributes2.push({
          key: this.attributes2.length + 1, // Unique key
          customData: {
            img2: `${baseDir}/${year}/${month.toString().padStart(2, '0')}/` + 28 + `/${i}.jpg`, // Full path to image
          },
          dates: {
            year: year,
            month: month,
            day2: 28
          }

        });
      }

      for (let i = 1; i <= 4; i++) {
        // Pad the number to ensure it's two digits (e.g., 01, 02, ...)
        const imgNumber = i.toString().padStart(2, '0') + '.jpg';

        this.attributes2.push({
          key: this.attributes2.length + 1, // Unique key
          customData: {
            img2: `${baseDir}/${year}/${month.toString().padStart(2, '0')}/` + 29 + `/${i}.jpg`, // Full path to image
          },
          dates: {
            year: year,
            month: month,
            day2: 29
          }
        });
      }

      // console.log(this.attributes2);

      // // Loop through each year and month
      // for (let year of years) {
      //   for (let month of months) {
      //     // Loop through the images
      //     for (let img of images) {
      //       attributes2.push({
      //         key: attributes2.length + 1, // Unique key
      //         customData: {
      //           img2: `${baseDir}${year}/${month.toString().padStart(2, '0')}/${img}`, // Full path to image
      //         },
      //         dates: new Date(year, month - 1, 1), // Create date object (month is 0-indexed)
      //       });
      //     }
      //   }
      // }
      // console.log(this.attributes2);
      //       console.log(attributes2); // Log the attributes2 array to see the stored images
    },

    triggerFileInput() {
      this.$refs.fileInput.click(); // Trigger the hidden file input when the button is clicked
    },
    onFileChange(event) {
      const file = event.target.files[0];
      if (file) {
        this.selectedFile = file;
        this.selectedFileName = file.name;
        this.fileSelected = true;

        // Read the file as a data URL
        const reader = new FileReader();
        reader.onload = (e) => {
          const base64Image = e.target.result; // This is the base64 string
          localStorage.setItem('myImage', base64Image); // Save to localStorage
          this.image = base64Image; // Display the image immediately
        };
        reader.readAsDataURL(file); // Read the file as a data URL

        // Validate file size (5MB max)
        if (file.size > 5242880) {
          alert("File size exceeds 5MB");
          this.resetFile();
        }
      }
    },
    onSubmit() {
      let dataEntries = JSON.parse(localStorage.getItem('dataEntries')) || [];

      // Create a new entry
      const newEntry = {
        date: this.date,
        remark: this.remark,
        fileName: this.selectedFileName,
        imageURL: URL.createObjectURL(this.selectedFile), // Create a URL for the uploaded image
      };

      // Add the new entry to the array
      dataEntries.push(newEntry);

      // Save the updated array back to localStorage
      localStorage.setItem('dataEntries', JSON.stringify(dataEntries));

      // alert(newEntry);
      this.retrieveImages(); // Retrieve the images after submission


    },
    uploadFile() {
      if (!this.selectedFile) {
        alert("No file selected");
      } else {
        // Handle the file upload logic here
        alert(`File ${this.selectedFileName} is ready to be uploaded`);
      }
    },
    retrieveImages() {
      const dataEntries = JSON.parse(localStorage.getItem('dataEntries')) || [];
      if (dataEntries.length > 0) {
        this.imageEntries = dataEntries; // Create a data property to hold retrieved entries
      }
    },
    resetFile() {
      this.selectedFile = null;
      this.selectedFileName = '';
      this.fileSelected = false;
      this.$refs.fileInput.value = ''; // Reset the file input

    },
    showModal(modalId) {
      if (modalId === 'firstModal') {
        this.isFirstModalVisible = true;
      }
    },
    redirectToModal(targetModal) {
      // Close all modals first
      // this.isFirstModalVisible = false;
      // this.isSecondModalVisible = false;
      // this.isThirdModalVisible = false;
      this.$refs['firstModal'].hide()
      this.$refs['secondModal'].hide()
      this.$refs['thirdModal'].hide()
      // Then open the target modal after the current modal is closed
      this.$nextTick(() => {
        if (targetModal === 'firstModal') {
          // this.isFirstModalVisible = true;
          this.$refs['firstModal'].show()
        } else if (targetModal === 'secondModal') {
          // this.isSecondModalVisible = true;
          this.$refs['secondModal'].show()
        } else if (targetModal === 'thirdModal') {
          // this.isThirdModalVisible = true;
          this.date = getcurrentDate(),
            this.$refs['thirdModal'].show()

        }
      });
    },
    closeAllModals() {
      this.isFirstModalVisible = false;
      this.isSecondModalVisible = false;
      this.isThirdModalVisible = false;
    },
    hideModal() {
      this.$refs['my-modal'].hide()

    },

    navigateToAdd(date, date2) {
      // console.log('navigateToAdd')
      // console.log(date)

      this.clickedDate = date,
      this.clickedDate2 = date2,

      this.$refs['firstModal'].show()

    },


  },
};
</script>

<style scoped>
/* @import '~carbon/charts-vue/styles.css'; */
/*
  .table thead th {
      padding-top: 0.5rem;
      padding-bottom: 1.5rem;
      font-size: 0.9rem; 
      text-transform: uppercase;
      letter-spacing: 1px;
      border-bottom: 0px solid #e9ecef;
      border-top: 0px solid #e9ecef;
      color: white;
  }
  .table td {
    color: white;
  }*/


.bg-card-dashboard {
  /* background-color: #23454a; */
  background-image: linear-gradient(to bottom right, #23454a, #406B71);
}

.bg-card-dashboard-solid {
  background-color: #23454a;
  border-radius: 30px;
}

.bg-card-dashboard-border {
  border-color: #23454a;
  border-style: solid;
  border-radius: 10px;
  border-width: 2px;
}

.bg-default-dashboard {
  background-color: #082a2f;
  /* background-size: cover; */
  /* position:fixed; */
  padding: 0;
  margin: 0;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.form-control {
  background-color: #2c545a;
  border-color: #2c545a;
  color: white;

  &:focus {
    background-color: #2c545a;
    border-color: #2c545a;
    color: white;
  }

  &::placeholder {
    color: white;
  }
}

.form-control {
  &:focus {
    &::placeholder {
      color: white;
    }
  }
}

.input-group-text {
  background-color: #2c545a;
  color: white;

  &:focus {
    background-color: #2c545a;
    border-color: #1d4147;
    color: white;
  }
}

.focused {
  .input-group-text {
    background-color: #2c545a;
  }
}

.navbar-search-dark .input-group {
  background-color: #23454a;
  color: black;
  border-color: rgba(255, 255, 255, 0.6);
}

.navbar-search .input-group {
  border-radius: 7px;
}

.el-input__inner,
.el-select-dropdown__item.is-disabled:hover {
  background-color: #23454a;
}

.line-custom {
  background-color: #356168;
  margin-top: 15px;
  margin-bottom: 15px;
}

/* <!--style for flip card--> */
ul {
  padding-left: 0;
  display: flex;
  flex-flow: row wrap;
}

li {
  list-style-type: none;
  padding: 10px 10px;
  transition: all 0.3s ease;
}

label {
  font-weight: 400;
  color: #333;
  margin-right: 10px;
}

input {
  border-radius: 5px;
  border: 2px solid #eaeaea;
  padding: 10px;
  outline: none;
}
</style>

<!-- CSS for image slides -->
<style scoped>
/deep/ .custom-calendar.vc-container {
  --day-border: 1px solid #b8c2cc;
  --day-border-highlight: 1px solid #b8c2cc;
  --day-width: 90px;
  --day-height: 150px;
  --weekday-bg: #f8fafc;
  --weekday-border: 1px solid #eaeaea;

  border-radius: 30px;
  width: 100%;

  & .vc-header {
    background-color: #f1f5f8;
    padding: 10px 0;
    border-radius: 30px 30px 0 0;
  }

  & .vc-weeks {
    padding: 0;
    border-radius: 30px;
  }

  & .vc-weekday {
    background-color: var(--weekday-bg);
    border-bottom: var(--weekday-border);
    border-top: var(--weekday-border);
    padding: 5px 0;
    border-radius: 30px;
  }

  & .vc-day {
    /* border-radius:30px; */
    padding: 0 5px 3px 5px;
    text-align: left;
    height: var(--day-height);
    min-width: var(--day-width);
    background-color: white;

    &.weekday-1,
    &.weekday-7 {
      background-color: #eff8ff;
    }

    &:not(.on-bottom) {
      border-bottom: var(--day-border);

      &.weekday-1 {
        border-bottom: var(--day-border-highlight);
      }
    }

    &:not(.on-right) {
      border-right: var(--day-border);
    }
  }

  & .vc-day-dots {
    margin-bottom: 5px;
  }
}

.button-container {
  display: flex;
  justify-content: center;
  gap: 15px;
  /* Optional: space between the buttons */
  margin-top: 20px;
  /* Optional: add space between the text and buttons */
}

.flex {
  display: flex;
}

.image-gallery {
  display: flex;
  flex-wrap: wrap;
  /* gap: 20px; */

}

.image-card {
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 10px;
  margin: 10px;
  width: 150px;
  text-align: center;
}

.uploaded-image {
  width: 80px;
  height: 80px;
  border-radius: 5px;
}
</style>

<!-- custom calendar -->
<style scoped>
/deep/ .vc-day {
  height: 80px !important;
}
</style>