<template>
  <div @click="popUp()">
    <card type="default" header-classes="bg-transparent" class="border border-white;">
      <b-row align-v="center" slot="header">
      &nbsp;&nbsp;<h5 class="h3 mb-0 text-white">CONSOLIDATED OVERDUE DOCUMENT</h5>
      </b-row>
      <b-row>
        <b-col xl="8">
          <apexchart type="donut" width="100%" height="300px" :options="ChartOptionsDocumentOverdue" :series="seriesDocumentOverdue"></apexchart>
        </b-col>
        <b-col xl="4">
          <b-row>
          <div style="color:white;font-size: 12px;"><b>Total Overdue Document</b><br><h1 style="color:white;">203</h1></div>
          </b-row>
          <br>
          <br>
          <b-row>
            <div style="color:white;font-size: 12px;"><b style="color:white;">Highest Overdue : More Than 90 Days</b></div>
          </b-row>
        </b-col>
      </b-row>
    </card>

    <modal :show.sync="modals.formOverdueDocument"
      size="xl"
      body-classes="p-0">
      <card type="secondary"
            header-classes="bg-transparent pb-5"
            body-classes="px-lg-5 py-lg-5"
            class="border-0 mb-0">
        <template>
        <H2 class="text-center"><b>Top 5 Highest Overdue CONSOLIDATED Project Document</b></H2>
        <br>
         <b-row>
           <b-col md="1">
           </b-col>
          <b-col md="2">
            <b-card no-body class="bg-gradient-warning border-0" >
            <b-card-body>
                    <b-card-title title-tag="h5" class="text-uppercase text-muted mb-0 text-white text-center">Xuan with <br><b>60 Documents</b></b-card-title>
              </b-card-body>
            </b-card>
          </b-col>
          <b-col md="2">
            <b-card no-body class="bg-gradient-warning border-0" >
            <b-card-body>
                    <b-card-title title-tag="h5" class="text-uppercase text-muted mb-0 text-white text-center">Orion with <br><b>40 Documents</b></b-card-title>
              </b-card-body>
            </b-card>
          </b-col>
          <b-col md="2">
            <b-card no-body class="bg-gradient-warning border-0" >
            <b-card-body>
                    <b-card-title title-tag="h5" class="text-uppercase text-muted mb-0 text-white text-center">Elvin with <br><b>15 Documents</b></b-card-title>
              </b-card-body>
            </b-card>
          </b-col>
          <b-col md="2">
            <b-card no-body class="bg-gradient-warning border-0" >
            <b-card-body>
                    <b-card-title title-tag="h5" class="text-uppercase text-muted mb-0 text-white text-center">ALEX with <br><b>15 Documents</b></b-card-title>
              </b-card-body>
            </b-card>
          </b-col>
          <b-col md="2">
            <b-card no-body class="bg-gradient-warning border-0" >
            <b-card-body>
                    <b-card-title title-tag="h5" class="text-uppercase text-muted mb-0 text-white text-center">SUHAIDA with <br><b>15 Documents</b></b-card-title>
              </b-card-body>
            </b-card>
          </b-col>
          <b-col md="1">
           </b-col>
        </b-row>
        <b-form role="form">
          <div class="text-center">
            <base-button type="primary" class="my-4" @click="clearModal()">Close</base-button>
          </div>
        </b-form>
        </template>
      </card>
    </modal>

  </div>
</template>
<script>
  import { BaseProgress } from '@/components';
  import VueApexCharts from 'vue-apexcharts';
  import { Table, TableColumn, DropdownMenu, DropdownItem, Dropdown} from 'element-ui';
  import { Modal } from '@/components';

  export default {

    components: {
      Modal,
      BaseProgress,
      "apexchart": VueApexCharts,
      [Table.name]: Table,
      [TableColumn.name]: TableColumn,
      [Dropdown.name]: Dropdown,
      [DropdownItem.name]: DropdownItem,
      [DropdownMenu.name]: DropdownMenu,
    },
    data() {
      return {
        modals: {
          formOverdueProject: false,
          formOverdueDocument: false,
        },
        document_overdue: {
          one: 0,
          two: 0,
          three: 0,
          four: 0,
          message: null,
          total_overdue: 0,
        },
        seriesDocumentOverdue: [56,31,26,90],
        ChartOptionsDocumentOverdue:
        {
            colors: ['#119ee1', '#033639', '#ef8215', '#F43314'],
            chart: {
            width: 380,
            type: 'donut',
            },
            plotOptions: {
              pie: {
              startAngle: -90,
              endAngle: 270,
              },
            },
            labels: ["< 30 Days", "30 - 60 Days", "60 - 90 Days", "> 90 Days"],
            dataLabels: {
            enabled: true
            },
            fill: {
            // type: 'gradient',
            },

            legend: {
            formatter: function(val, opts) {
            return val + " - " + opts.w.globals.series[opts.seriesIndex]
            },
            labels: {
            colors: ['white'],
            useSeriesColors: false
            },
          position: 'bottom'
          },
          title: {
          text: ''
          },
        },
      }
    },
    methods:
    {
      popUp(){
        this.modals.formOverdueDocument = true
      },
      clearModal(){
        this.modals.formOverdueProject = false
        this.modals.formOverdueDocument = false
      },
    },
  }
</script>
<style>
</style>
