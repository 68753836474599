<template>
  <b-card no-body>
    <!-- Card header -->
    <b-card-header>
      <!-- Title -->
      <h5 class="h3 mb-0">Activity List - (Function Work In Progress)</h5>
          <div class="text-right">
            <router-link to="/siteNew">
              <base-button size="sm" type="primary">View all Activity</base-button>
            </router-link>
          </div>
    </b-card-header>
    <!-- Card body -->
    <b-card-body>
      <div class="timeline timeline-one-side" data-timeline-content="axis" data-timeline-axis-style="dashed">
        <div v-for="event in events"
             :key="event.title"
             class="timeline-block">
          <span class="timeline-step" :class="`badge-${event.type}`">
            <i :class="event.icon"></i>
          </span>
          <div class="timeline-content">
            <div class="d-flex justify-content-between pt-1">
              <div>
                <span class="text-muted text-sm font-weight-bold">{{event.title}}</span>
              </div>
              <div class="text-right">
                <small class="text-muted"><i class="fas fa-clock mr-1"></i>{{event.time}}</small>
              </div>
            </div>
            <h6 class="text-sm mt-1 mb-0">{{event.description}}</h6>
          </div>
        </div>
      </div>
    </b-card-body>
  </b-card>
</template>
<script>
  export default {

    data() {
      return {
        events: [

          {
            title: 'Update Project',
            description:  'TRTP-2398 - Tengeh Reservoir Tender Project',
            icon: 'ni ni-building',
            type: 'info',
            time: '2 hrs ago'
          },
          {
            title: 'New Milestone',
            description:  'Milestone Installation at TRTP-2398 - Tengeh Reservoir Tender Project',
            icon: 'ni ni-air-baloon',
            type: 'success',
            time: '3 hrs ago'
          },
          {
            title: 'New Project',
            description:  'TRTP-2398 - Tengeh Reservoir Tender Project',
            icon: 'ni ni-building',
            type: 'success',
            time: '5 hrs ago'
          },
        ]
      }
    }
  }
</script>
<style>
</style>
