<template>
    <div>
        <base-header class="pb-6">
            <b-row class="py-4">
                <b-col cols="6">
                    <h6 class="h2 text-white d-inline-block mb-0">{{ title }}</h6>
                </b-col>
                <b-col cols="6" class="text-right">
                    <base-button size="sm" type="neutral" @click="navigateToListing()">Back</base-button>
                </b-col>
            </b-row>
        </base-header>

        <b-container fluid class="mt--6">
            <div class="card-wrapper">
                <card>
                    <div v-if="showError" :value="true" class="alert alert-primary" role="alert">
                        <strong>Error!</strong>  Please fill in all compulsory fields in Other Information.
                    </div>
                    <!-- Card header -->
                    <h3 slot="header" class="mb-0">Update Eform</h3>

                    <validation-observer v-slot="{ handleSubmit }">
                        <form class="needs-validation" @submit.prevent="handleSubmit(onSubmit)">

                            <div class="table-responsive">
                                <table class="table align-items-center mb-0 text-dark">
                                    <thead>
                                    <th>No.</th>
                                    <th>Title</th>
                                    <th></th>
                                    </thead>
                                    <tbody>
                                    <tr v-for="(item, index) in model.eform_answer" :key="index">
                                        <td width="8%" style="vertical-align: top;">
                                            <base-input
                                                    v-model="item.form_criteria.order_no"
                                                    label="No."
                                                    placeholder=" "
                                                    class="border"
                                                    readonly
                                            ></base-input>
                                        </td>
                                        <td width="42%" style="vertical-align: top;">
                                            <base-input
                                                    v-model="item.form_criteria.title"
                                                    label="No."
                                                    placeholder=" "
                                                    class="border"
                                                    readonly
                                            ></base-input>
                                            <b-form-textarea
                                                    id="textarea"
                                                    v-model="item.form_criteria.description"
                                                    placeholder=""
                                                    rows="5"
                                                    readonly
                                                    class="border"
                                            ></b-form-textarea>
                                        </td>
                                        <td width="50%" align="center">
                                            <span class="text-danger" v-html="showRequired(item.form_criteria)"></span>
                                            <div class="form-group text-dark" v-if="item.form_criteria.input_type=='radio'">
                                                <b-form-radio-group v-model="item.value">
                                                    <template v-for="(itemData, indexData) in item.form_criteria.items">
                                                        <b-form-radio :value="itemData.id" :name="itemData.name" class="mb-3" disabled>
                                                            <b>{{itemData.name}}</b>
                                                        </b-form-radio>
                                                        <br >
                                                    </template>
                                                </b-form-radio-group>
                                            </div>
                                            <div class="form-group text-dark" v-if="item.form_criteria.input_type=='checkbox'">
                                                <template>
                                                    <div>
                                                        <label v-for="(itemData,indexData) in item.form_criteria.items" :key="indexData">
                                                            <input
                                                                    type="checkbox"
                                                                    :value="itemData.id"
                                                                    v-model="item.items"
                                                                    disabled
                                                            />
                                                            {{ itemData.name }}
                                                        </label>
                                                    </div>
                                                </template>

                                            </div>
                                            <div class="form-group text-dark" v-if="item.form_criteria.input_type=='select'">
                                                <select disabled class="form-control text-dark" v-model="item.value">
                                                    <option v-for="itemData in item.form_criteria.items" :key="itemData.id" v-bind:value="itemData.id">{{ itemData.name }}</option>
                                                </select>
                                            </div>
                                            <div class="form-group text-dark" v-if="item.form_criteria.input_type=='text-field'">
                                                <base-input
                                                        v-model="item.value"
                                                        placeholder=" "
                                                        class="border"
                                                        readonly
                                                ></base-input>
                                            </div>
                                            <div class="form-group text-dark" v-if="item.form_criteria.input_type=='textarea'">
                                                <b-form-textarea
                                                        id="textarea"
                                                        v-model="item.value"
                                                        placeholder=""
                                                        rows="5"
                                                        class="border"
                                                        readonly
                                                ></b-form-textarea>
                                            </div>
                                            <div class="form-group text-dark" v-if="item.form_criteria.input_type=='date'">
                                                <base-input
                                                        type="date"
                                                        v-model="item.value_date">
                                                </base-input>
                                            </div>
                                            <div class="form-group text-dark" v-if="item.form_criteria.input_type=='file'">
                                                <div v-for="(file, fileindex) of item.items" class="alert border-bottom mb-2 p-2 d-flex align-items-center">
                                                    <div class="flex-fill">
                                                        <div class="fw-semibold">{{ file.uploaded_file.name }}</div>
                                                    </div>
                                                    <button class="btn btn-warning btn-sm" @click="item.items.splice(indexFile, 1)">&times;</button>
                                                </div>

                                                <div v-for="(itemAttachment, indexAttachment) of item.item_attachments" class="alert border-bottom mb-2 p-2 d-flex align-items-center">
                                                    <div class="flex-fill">
                                                        <a href="#" @click.prevent="downloadFile(itemAttachment.attachment.id)">{{ itemAttachment.attachment.filename }}</a>
                                                        <!--<div class="fw-semibold">{{ itemAttachment.attachment.filename }}</div>-->
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="form-group text-dark" v-if="item.form_criteria.input_type=='table'">
                                                <template v-if="item.form_criteria.table_type == 'simple' && item.form_criteria.tables !=null">
                                                    <table class="table-responsive">
                                                        <thead>
                                                        <tr>
                                                            <th
                                                                    v-for="(column, columnIndex) in item.form_criteria.tables"
                                                                    :key="columnIndex"
                                                            >
                                                                {{ column.label }}
                                                            </th>
                                                        </tr>
                                                        </thead>
                                                        <tbody>
                                                        <tr v-for="(column, columnIndex) in item.form_criteria.table_items"
                                                            :key="columnIndex">
                                                            <td
                                                                    v-for="(valueItem, columnItem) in column"
                                                                    :key="columnItem"
                                                            >

                                                                <base-input
                                                                        v-model="valueItem.label"
                                                                        readonly
                                                                ></base-input>
                                                            </td>
                                                        </tr>
                                                        </tbody>
                                                    </table>
                                                </template>
                                                <template v-if="item.form_criteria.table_type == 'complex' && item.form_criteria.tables != null">
                                                    <table class="table-responsive">
                                                        <thead>
                                                        <tr v-for="(col, colIndex) in item.form_criteria.tables"
                                                            :key="'header-' + colIndex" v-if="colIndex == 0">
                                                            <th v-for="(col1, colIndex1) in col"
                                                                :key="'header1-' + colIndex1"
                                                                class="text-dark"
                                                            >
                                                                {{ col1 }}
                                                            </th>
                                                        </tr>
                                                        </thead>
                                                        <tbody>
                                                        <tr
                                                                v-for="(row, rowIndex) in item.form_criteria.tables"
                                                                :key="rowIndex"
                                                        >
                                                            <template v-if="rowIndex !== 0">
                                                                <td
                                                                        v-for="(col, colIndex) in row"
                                                                        :key="colIndex"
                                                                        class="text-dark"
                                                                >
                                                                    <template v-if="colIndex !== 0">
                                                                        <base-input
                                                                                v-model="item.form_criteria.tables[rowIndex][colIndex]"
                                                                                readonly
                                                                        ></base-input>
                                                                    </template>
                                                                    <template v-else>
                                                                        {{ col }}
                                                                    </template>
                                                                </td>
                                                            </template>
                                                        </tr>
                                                        </tbody>
                                                    </table>
                                                </template>
                                            </div>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                            <br>
                        </form>
                    </validation-observer>
                    <button class="btn btn-primary" v-if="access.edit && is_approver == true && model.status =='PENDING_APPROVAL'" @click="onSubmit()">APPROVE</button>
                    <button class="btn btn-danger" v-if="is_approver == true && model.status =='PENDING_APPROVAL'" @click="showRejectModal()">REJECT</button>
                    <button class="btn btn-primary" type="button" @click="navigateToListing()">Cancel</button>
                </card>

                <card v-show="approverHistory.length>0">
                    <h3 slot="header" class="mb-0">Approver History</h3>
                    <div class="table-responsive">
                        <table class="table align-items-center mb-0 text-dark">
                            <thead>
                                <th>No.</th>
                                <th>Approver</th>
                                <th>Level</th>
                                <th>Reason</th>
                                <th>Time</th>
                                <th>Action</th>
                            </thead>
                            <tbody>
                                <tr v-for="(item, index) in approverHistory" :key="index">
                                    <td class="text-dark">{{index+1}}</td>
                                    <td class="text-dark">{{item.approved_by.name}}</td>
                                    <td class="text-dark">{{item.level}}</td>
                                    <td class="text-dark">{{item.reason}}</td>
                                    <td class="text-dark">{{item.created_at}}</td>
                                    <td class="text-dark">{{item.status}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </card>


                <modal :show.sync="showModals"
                       size="lg"
                       body-classes="p-0">
                    <card type="secondary"
                          body-classes="px-lg-5 py-lg-5"
                          class="border-0 mb-0">
                        <template>
                            <b-form role="form">

                                <div class="form-group text-dark">
                                    <label class="text-dark text-bold" for="">Reason for Reject:</label>
                                    <b-form-textarea
                                            label="Reason for Reject"
                                            id="textarea"
                                            v-model="model.reason"
                                            placeholder=""
                                            rows="5"
                                    ></b-form-textarea>
                                </div>

                                <div class="text-center">
                                    <base-button type="danger" class="my-4" @click="rejectSubmission()">YES</base-button>
                                    <base-button type="default" class="my-4" @click="cancelReject()">NO</base-button>
                                </div>
                            </b-form>
                        </template>
                    </card>
                </modal>
            </div>
        </b-container>
    </div>
</template>

<script>
    import ErrorFunctionMixin from '@/assets/js/errorFunctionMix.js'
    import axios from '@/util/axios'
    import { apiDomain } from '@/assets/js/config'
    import { getAccessByModule } from '@/util/access'
    import BaseInput from "../../../../components/Inputs/BaseInput.vue";
    import jsonFormData from 'json-form-data';

    export default {
        components: {BaseInput},
        mixins: [ErrorFunctionMixin],

        data() {
            return {
                title: 'Eform Submission',
                type: null,
                model: {
                },
                access: {},
                templates: [],
                roles: [],
                criterias: [],
                documents: [],
                showError : false,
                is_approver : false,
                modals:
                    {
                        delete_id: null,
                        delete_title: null,
                        reject_forms: false,
                    },
                showModals : false,
                approverHistory :{},
            }
        },

        created() {
            this.id = this.$route.params.id
            this.type = this.$route.params.type
            this.access = getAccessByModule('EFORM')
            this.getTemplateOptions();
            this.getAllRoles();
            this.getData()
            this.getApproverHistory()

        },

        methods: {
            rejectSubmission(){
                this.model.status = 'REJECTED';
                this.model.type= 'EFORM';
                axios.post('e_forms/eform_reviewer/'+this.type+'/'+this.id , this.model)
                    .then(response => {
                        this.$notify({
                            message: 'Eform Updated',
                            timeout: 10000,
                            icon: 'ni ni-bell-55',
                            type: 'default',
                        });

                        this.navigateToListing();
                    })
                    .catch(error => {
                        this.errorFunction(error, this.title)
                    });
            },
            onSubmit() {
                this.model.status = 'APPROVED';
                this.model.type= 'EFORM';
                axios.post('e_forms/eform_reviewer/'+this.type+'/'+this.id , this.model)
                    .then(response => {
                        this.$notify({
                            message: 'Eform Updated',
                            timeout: 10000,
                            icon: 'ni ni-bell-55',
                            type: 'default',
                        });

                        this.navigateToListing();
                    })
                    .catch(error => {
                        this.errorFunction(error, this.title)
                    });

            },
            getTemplateOptions(){
                axios.get('e_forms/get_type_options')
                    .then ( response => {
                        if (response.status === 200)
                        {
                            this.templates = response.data.data
                        }
                    })
                    .catch ( error => {
                        this.errorFunction(error, this.title)
                    } );
            },
            getData(){
                axios.get('e_forms/eform_reviewer/'+this.type+'/'+this.id)
                    .then ( response => {
                        if (response.status === 200)
                        {
                            this.model = response.data.data
                            this.checkIsApprover();

                        }
                    })
                    .catch ( error => {
                        this.errorFunction(error, this.title)
                    } );
            },
            getAllRoles(){
                axios.get('lookup/role_list')
                    .then ( response => {
                        if (response.status === 200)
                        {
                            this.roles = response.data.data
                        }
                    })
                    .catch ( error => {
                        this.errorFunction(error, "Role Listing")
                    } );
            },
            navigateToListing(){
                this.$router.replace('../../ReviewerListing/'+this.type);
            },
            showRequired(item)
            {
                if(item)
                {
                    if(item.is_required)
                    {
                        return 'field is compulsory (<font color="red">*</font>)';
                    }
                    else
                    {
                        return '';
                    }
                }
            },
            downloadFile(id) {
                axios.post(`e_forms/token?value=${id}`)
                    .then(response => {
                        const token = response.data
                        const url = `${apiDomain}e_forms/download/${id}?token=${token}`
                        console.log(url);
                        window.open(url, '_blank')
                    })
                    .catch(error => {
                        console.log(error)
                        this.errorFunction(error, this.title)
                    })
            },
            checkIsApprover(){
                let formData = {};
                formData.id = this.id
                formData.type = 'EFORM'
                formData.next_approver_level = this.model.next_approver_level;
                console.log(formData);
                axios.post('e_forms/check_is_apporver',formData)
                    .then ( response => {
                        if (response.status === 200)
                        {
                            this.is_approver = response.data.data
                        }
                    })
                    .catch ( error => {
                        this.errorFunction(error, this.title)
                    } );
            },
            cancelReject(){
                this.showModals = false
            },
            showRejectModal(){
                this.showModals = true
                console.log(this.showModals);
            },
            getApproverHistory(){
                axios.get('e_forms/approver_history/'+this.type+'/'+this.id)
                    .then ( response => {
                        if (response.status === 200)
                        {
                            this.approverHistory = response.data.data

                        }
                    })
                    .catch ( error => {
                        this.errorFunction(error, this.title)
                    } );
            }
        },
    }
</script>
<style scoped>
    table {
        border-collapse: collapse; /* Ensures borders between cells are merged */
        width: 100%; /* Optional: Sets the table width */
    }

    th, td {
        border: 1px solid black; /* Applies 1px solid black border to cells */
        padding: 8px; /* Optional: Adds padding for spacing */
        text-align: left; /* Optional: Aligns text to the left */
    }

    th {
        background-color: #f2f2f2; /* Optional: Adds a background color to headers */
    }

    .table thead th {
        color:black;
    }
</style>