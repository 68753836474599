<template>
  <div class="content">
    <base-header class="pb-6">
      <b-row align-v="center" class="py-4">
        <b-col cols="7" lg="6">
          <h6 class="h2 text-white d-inline-block mb-0">Project Milestone Listing </h6>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <route-bread-crumb></route-bread-crumb>
          </nav>
        </b-col>
        <b-col lg="12" cols="5" class="text-right">
          <base-button size="sm" type="neutral" @click="newMilestone()">New Milestone</base-button>
          <base-button size="sm" type="neutral" @click="$router.back()">Back</base-button>
        </b-col>
      </b-row>
    </base-header>
    <b-container fluid class="mt--6">
      <div>
        <card class="no-border-card" body-classes="px-0 pb-1" footer-classes="pb-2">
          <template slot="header">
            <h3 v-if="model.project_name == null" class="mb-0">Project Milestone Listing </h3>
            <h3 v-else class="mb-0">Project Milestone Listing for ({{ model.project_name }})</h3>
          </template>
          <div>
            <b-col cols="12" class="d-flex justify-content-center justify-content-sm-between flex-wrap">
              <el-select
                class="select-primary pagination-select"
                v-model="pagination.perPage"
                placeholder="Per page">
                <el-option
                  class="select-primary"
                  v-for="item in pagination.perPageOptions"
                  :key="item"
                  :label="item"
                  :value="item">
                </el-option>
              </el-select>

              <div>
                <base-input v-model="searchQuery"
                            prepend-icon="fas fa-search"
                            placeholder="Search...">
                </base-input>

              </div>
            </b-col>
            <b-col><br></b-col>
            <el-table :data="queriedData"
                      row-key="milestone_id"
                      header-row-class-name="thead-light"
                      @sort-change="sortChange">

              <el-table-column label="Miletone"
                             min-width="180px"
                             prop="milestone_code"
                             sortable>
                <template v-slot="{row}">
                    <b-media no-body class="align-items-center">
                        <b-media-body>
                            <span class="font-weight-600 name mb-0 text-sm">{{row.milestone_code}}</span>
                        </b-media-body>
                    </b-media>
                </template>
              </el-table-column>

              <!-- <el-table-column label="Project Name"
                             min-width="220px"
                             prop="milestone_info"
                             sortable>
                <template v-slot="{row}">
                    <b-media no-body class="align-items-center">
                        <b-media-body>
                            <span class="font-weight-600 name mb-0 text-sm">{{row.milestone_info}}</span>
                        </b-media-body>
                    </b-media>
                </template>
              </el-table-column> -->

              <el-table-column label="Progress"
                           min-width="180px"
                             prop="milestone_progress"
                             sortable>
                <template v-slot="{row}">
                    <div class="d-flex align-items-center">
                        <div>
                          <span class="font-weight-600 name mb-0 text-sm">{{row.milestone_progress}}% &nbsp; </span>
                        </div>
                        <div>
                            <base-progress v-if="row.milestone_progress > 0 && row.milestone_progress < 50" min-width="30px" type="danger" :value="row.milestone_progress"/>
                            <base-progress v-else-if="row.milestone_progress >= 50 && row.milestone_progress <= 90" min-width="30px" type="info" :value="row.milestone_progress"/>
                            <base-progress v-else min-width="30px" type="success" :value="row.milestone_progress"/>
                        </div>
                    </div>
                </template>
              </el-table-column>

              <el-table-column label="Sequence"
                             min-width="150px"
                             prop="status"
                             sortable>
                <template v-slot="{row}">
                    <b-media no-body class="align-items-center">
                        <b-media-body>
                            <span class="font-weight-600 name mb-0 text-sm">{{row.milestone_sequence}}</span>
                        </b-media-body>
                    </b-media>
                </template>
              </el-table-column>

              <el-table-column label="project"
                             min-width="140px"
                             prop="status"
                             sortable>
                <template v-slot="{row}">
                    <b-media no-body class="align-items-center">
                        <b-media-body>
                            <span class="font-weight-600 name mb-0 text-sm">{{row.project.project_name}}</span>
                        </b-media-body>
                    </b-media>
                </template>
              </el-table-column>

              <el-table-column min-width="110px" align="right" label="Actions">
                <div slot-scope="{$index, row}" class="d-flex">

                  <base-button
                    @click.native="editMilestone($index, row)" size="sm" type="primary">
                    View / Edit
                  </base-button>
                </div>
              </el-table-column>
            </el-table>
          </div>
          <div
            slot="footer"
            class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap">
            <div class="">
              <p class="card-category">
                Showing {{ from + 1 }} to {{ to }} of {{ total }} entries

                <span v-if="selectedRows.length">
                  &nbsp; &nbsp; {{selectedRows.length}} rows selected
                </span>
              </p>
            </div>
            <base-pagination
              class="pagination-no-border"
              :current="pagination.currentPage"
              :per-page="pagination.perPage"
              :total="total"
              @change="paginationChanged($event)">
            </base-pagination>
             <!-- <div>
              <h5 class="text-danger"> Note :
                  <i>
                  Please Create at least ONE Project Template & ONE Project Template Details before you can proceed POPULATE / IMPORT to prevent any Error. Go to NEW Template Then "Detail Listing" >> "New Template Details".
                  </i>
              </h5>
            </div> -->
          </div>
        </card>
      </div>
    </b-container>
    </div>
</template>
<script>
import { Table, TableColumn, Select, Option } from 'element-ui';
import RouteBreadCrumb from '@/components/Breadcrumb/RouteBreadcrumb'
import { BasePagination } from '@/components';
import clientPaginationMixin from './js/ProjectPaginationMix'
import swal from 'sweetalert2';
import axios from 'axios'
import {LoginURL, clientId, clientSecret, apiDomain, getHeader} from '@/assets/js/config.js'
import ErrorFunctionMixin from '@/assets/js/errorFunctionMix.js'

export default {
  mixins: [clientPaginationMixin, ErrorFunctionMixin],
  components: {
    BasePagination,
    RouteBreadCrumb,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn
  },
  created()
  {
      this.model.project_id = this.$route.params.id
      this.getMilestoneData()
      if (this.model.project_name == null)
      {
          axios.get(apiDomain + 'project/project/' + this.model.project_id , { headers: getHeader() })
          .then ( response => {
            if (response.status === 200)
            {
                this.model.project_name = response.data.data[0].project_name
            }
          })
          .catch ( error => {
              console.log(error.response.status)
              this.errorFunction(error, "Project Information")
          } );
      }
  },
  data() {
    return {
      model: {
        project_id: null,
        project_name: null,
      },
      propsToSearch: ['milestone_code', 'project_template_name', ],
      tableColumns: [],
      tableData: [],
      selectedRows: []
    };
  },
  methods: {
    newMilestone()
    {
      this.$router.push({path: '/projectMilestoneNew/' + this.model.project_id })
    },
    getMilestoneData(){

       axios.get(apiDomain + 'project/project_milestone.show_by_project/' + this.model.project_id , { headers: getHeader() })
          .then ( response => {
            if (response.status === 200)
            {
                this.tableData = response.data.data
                this.model.project_name = response.data.data[0].project.project_name
            }
          })
          .catch ( error => {
              console.log(error.response.status)
              this.errorFunction(error, "Project Milestone")
          } );
    },
    paginationChanged(page) {
      this.pagination.currentPage = page
    },
    editMilestone(index, row)
    {
      this.$router.push({path: '/projectMilestoneEdit/'+row.milestone_id })
    },

    handleEdit(index, row) {
      swal({
        title: `You want to edit ${row.name}`,
        buttonsStyling: false,
        confirmButtonClass: 'btn btn-info btn-fill'
      });
    },
    handleDelete(index, row) {
      swal({
        title: 'Are you sure?',
        text: `You won't be able to revert this!`,
        type: 'warning',
        showCancelButton: true,
        confirmButtonClass: 'btn btn-success btn-fill',
        cancelButtonClass: 'btn btn-danger btn-fill',
        confirmButtonText: 'Yes, delete it!',
        buttonsStyling: false
      }).then(result => {
        if (result.value) {
          this.deleteRow(row);
          swal({
            title: 'Deleted!',
            text: `You deleted ${row.name}`,
            type: 'success',
            confirmButtonClass: 'btn btn-success btn-fill',
            buttonsStyling: false
          });
        }
      });
    },
    deleteRow(row) {
      let indexToDelete = this.tableData.findIndex(
        tableRow => tableRow.id === row.id
      );
      if (indexToDelete >= 0) {
        this.tableData.splice(indexToDelete, 1);
      }
    },
    selectionChange(selectedRows) {
      this.selectedRows = selectedRows
    }
  }
};
</script>
<style>
  .no-border-card .card-footer{
    border-top: 0;
  }
</style>
