<template>
  <div>
    <base-header class="pb-6">
      <b-row class="py-4">
        <b-col cols="6">
          <h6 class="h2 text-white d-inline-block mb-0">{{ title }}</h6>
        </b-col>
        <b-col cols="6" class="text-right">
          <base-button size="sm" type="neutral" @click="$router.replace('../../../Listing')">Back</base-button>
        </b-col>
      </b-row>
    </base-header>

    <b-container fluid class="mt--6">
      <div class="card-wrapper">
        <card>
          <!-- Card header -->
          <h3 slot="header" class="mb-0">{{ model.name }}</h3>
          <!-- Card body -->
          <h3>Documents</h3>
          <div class="px-3">
            <div class="d-flex align-items-baseline justify-content-between">
              <div>
                <!-- Category 1 -->
                <el-select v-model="selectedCategory1" clearable :placeholder="model.category1_name">
                  <el-option v-for="item in categories1" :key="item.id" :label="item.code" :value="item.id"/>
                </el-select>
                <!-- Category 2 -->
                <el-select class="ml-3" v-model="selectedCategory2" clearable :placeholder="model.category2_name">
                  <el-option v-for="item in categories2" :key="item.id" :label="item.code" :value="item.id"/>
                </el-select>
                <!-- Category 3 -->
                <template v-if="model.category3_enabled">
                  <el-select class="ml-3" v-model="selectedCategory3" clearable :placeholder="model.category3_name">
                    <el-option v-for="item in categories3" :key="item.id" :label="item.code" :value="item.id"/>
                  </el-select>
                </template>
              </div>
              <!-- New button -->
              <router-link v-if="access.create" to="New" class="btn btn-primary btn-sm">
                New Document
              </router-link>
            </div>
            <el-table v-if="model.id" :data="documents" header-row-class-name="thead-light" cell-class-name="fw-semibold" class="mt-2">
              <el-table-column :label="model.category1_name" prop="category1_code" width="220px"/>
              <el-table-column :label="model.category2_name" prop="category2_code" width="220px"/>
              <el-table-column :label="model.category3_name" prop="category3_code" width="220px" v-if="model.category3_enabled"/>
              <el-table-column label="Title" prop="title"/>
              <el-table-column label="Alternative Title" prop="alt_title"/>
              <el-table-column label="Actions">
                <template v-slot="{row}">
                  <router-link v-if="access.show" :to="`Edit/${row.id}`" class="btn btn-primary btn-sm">
                    View / Edit
                  </router-link>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </card>
      </div>
    </b-container>
  </div>
</template>

<script>
import ErrorFunctionMixin from '@/assets/js/errorFunctionMix.js'
import { Table, TableColumn, Select, Option } from 'element-ui';
import axios from '@/util/axios'
import { getAccessByModule } from '@/util/access';

export default {
  mixins: [ErrorFunctionMixin],
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Select.name]: Select,
    [Option.name]: Option,
  },

  data() {
    return {
      title: 'Document Tracking Templates',
      tid: null,
      model: {},
      categories1: [],
      categories2: [],
      categories3: [],
      selectedCategory1: null,
      selectedCategory2: null,
      selectedCategory3: null,
      documents: [],
      access: {},
    }
  },

  watch: {
    selectedCategory1() {
      this.loadDocuments()
    },

    selectedCategory2() {
      this.loadDocuments()
    },

    selectedCategory3() {
      this.loadDocuments()
    },
  },

  created() {
    this.tid = this.$route.params.tid
    this.access = getAccessByModule('DOC_REPO')

    this.loadRecord()
    this.loadCategories()
    this.loadDocuments()
  },

  methods: {
    loadRecord() {
      axios.get(`setup/doc_repo/template/${this.tid}`)
        .then(response => {
          this.model = response.data.data
        })
        .catch(error => {
          this.errorFunction(error, this.title)
        });
    },

    loadCategories() {
      axios.get(`setup/doc_repo/template/${this.tid}/category`)
        .then(response => {
          const categories = response.data.data
          this.categories1 = categories.filter(x => x.level == 1)
          this.categories2 = categories.filter(x => x.level == 2)
          this.categories3 = categories.filter(x => x.level == 3)
        })
        .catch(error => {
          this.errorFunction(error, this.title)
        });
    },

    loadDocuments() {
      const options = {
        params: {
          category1_id: this.selectedCategory1,
          category2_id: this.selectedCategory2,
          category3_id: this.selectedCategory3,
        },
      }

      axios.get(`setup/doc_repo/template/${this.tid}/document`, options)
        .then(response => {
          this.documents = response.data.data
        })
        .catch(error => {
          this.errorFunction(error, this.title)
        });
    },
  },
}
</script>
