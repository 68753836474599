<template>
  <div>
    <base-header class="pb-6 bg-custom">
      <b-row class="py-4 bg-custom">
        <!-- <b-col lg="12" cols="7">
          <h6 class="h2 text-white d-inline-block mb-0">Project Dashboard</h6>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <route-bread-crumb></route-bread-crumb>
          </nav>
        </b-col> -->

        <b-col lg="12" cols="12">
          <h1 class="text-dark"><strong>CRM Opportunity - {{model.project_opportunity_id}}</strong></h1>
          <br>
          <br>
        </b-col>

        <b-col lg="12" cols="12">
          <h2 class="text-dark"><strong>Opportunity Information</strong></h2>
          <card class="card-border form-custom-css">
            <div class="form-row">
              <b-col md="6">
                <base-input
                  label="Status"
                  name="Status"
                  v-model="model.project_opportunity_state_status"
                  disabled
                >
                </base-input>
              </b-col>

              <b-col md="6">
                <base-input
                  label="Customer Name"
                  name="Customer Name"
                  v-model="model.project_account.project_account_name"
                  disabled
                >
                </base-input>
              </b-col>
            </div>

            <div class="form-row">
              <b-col md="12">
                <base-input
                  label="Opportunity Name"
                  name="Opportunity Name"
                  v-model="model.project_name"
                  disabled
                >
                </base-input>
              </b-col>
            </div>

            <div class="form-row">
              <b-col md="6">
                <base-input
                  label="Target Sign Date"
                  name="Target Sign Date"
                  type="date"
                  v-model="model.project_target_sign_date"
                  disabled
                >
                </base-input>
              </b-col>

              <b-col md="6">
                <base-input
                  label="Target Turn On Date"
                  name="Target Turn On Date"
                  type="date"
                  v-model="model.project_target_turn_on_date"
                  disabled
                >
                </base-input>
              </b-col>
            </div>

            <!-- <div class="form-row">
              <b-col md="12">
                <base-input
                  label="CRM Opportunity ID"
                  name="CRM Opportunity ID"
                  v-model="model.project_opportunity_id"
                  disabled
                >
                </base-input>
              </b-col>
            </div>
            <div class="form-row">
              <b-col md="12">
                <base-input
                  label="Project Name"
                  name="Project Name"
                  v-model="model.project_name"
                  disabled
                >
                </base-input>
              </b-col>
            </div>
            <div class="form-row">
              <b-col md="12">
                <base-input
                  label="Target Sign Date"
                  name="Target Sign Date"
                  type="date"
                  v-model="model.project_target_sign_date"
                  disabled
                >
                </base-input>
              </b-col>
            </div>
            <div class="form-row">
              <b-col md="12">
                <base-input
                  label="Project Name"
                  name="Project Name"
                  v-model="model.project_name"
                  disabled
                >
                </base-input>
              </b-col>
            </div> -->
          </card>
        </b-col>

        <!-- <b-col lg="12" cols="12">
          <h2 class="text-dark"><strong>Customer Information</strong></h2>
          <card class="card-border">
            <div class="form-row">
              <b-col md="12">
                <base-input
                  label="Customer Name"
                  name="Customer Name"
                  v-model="model.project_account.project_account_name"
                  disabled
                >
                </base-input>
              </b-col>
            </div>
            <div class="form-row">
              <b-col md="12">
                <base-input
                  label="Billing Address"
                  name="Billing Address"
                  v-model="model.project_account.project_account_address"
                  disabled
                >
                </base-input>
              </b-col>
            </div>
          </card>
        </b-col> -->

        <b-col lg="12" cols="12">
          <h2 class="text-dark"><strong>Service Location</strong></h2>
          <card class="card-border">
            <el-table class="table-responsive"
                  :data="queriedData"
                  @row-click="rowClicked">

              <el-table-column label="Site Name"
                              min-width="200px"
                              prop="site_name"
                              sortable>
                  <template v-slot="{row}">
                    <div>
                      <b-media no-body class="align-items-center">
                          <b-media-body>
                              <span class="font-weight-600 name mb-0 text-sm">{{row.site_name}}</span>
                          </b-media-body>
                      </b-media>
                    </div>
                  </template>
              </el-table-column>

              <el-table-column label="Address"
                              min-width="200px"
                              prop="site_address"
                              sortable>
                  <template v-slot="{row}">
                    <div>
                      <b-media no-body class="align-items-center">
                          <b-media-body>
                              <span class="font-weight-600 name mb-0 text-sm">{{row.site_address}}</span>
                          </b-media-body>
                      </b-media>
                    </div>
                  </template>
              </el-table-column>

              <el-table-column label="Postal Code"
                              min-width="200px"
                              prop="site_postal_code"
                              sortable>
                  <template v-slot="{row}">
                    <div>
                      <b-media no-body class="align-items-center">
                          <b-media-body>
                              <span class="font-weight-600 name mb-0 text-sm">{{row.site_postal_code}}</span>
                          </b-media-body>
                      </b-media>
                    </div>
                  </template>
              </el-table-column>

              <el-table-column label="Solar PV Capacity (kWp)"
                              min-width="200px"
                              prop="site_ppa_approved_capacity"
                              sortable>
                  <template v-slot="{row}">
                    <div>
                      <b-media no-body class="align-items-center">
                          <b-media-body>
                              <span class="font-weight-600 name mb-0 text-sm">{{row.site_ppa_approved_capacity}}</span>
                          </b-media-body>
                      </b-media>
                    </div>
                  </template>
              </el-table-column>

              <el-table-column label="Lease Expiry Date"
                              min-width="200px"
                              sortable>
                  <template v-slot="{row}">
                    <div>
                      <b-media no-body class="align-items-center">
                          <b-media-body>
                              <span class="font-weight-600 name mb-0 text-sm"></span>
                          </b-media-body>
                      </b-media>
                    </div>
                  </template>
              </el-table-column>
            </el-table>
          </card>
        </b-col>

      <!-- <b-row v-if="button_access.site_listing == 'YES'">
        <b-col lg="12">
          <project-site-listing-table></project-site-listing-table>
        </b-col>
      </b-row> -->
      </b-row>
    </base-header>
  </div>
</template>

<script>
import RouteBreadCrumb from "@/components/Breadcrumb/RouteBreadcrumb";
import BaseHeader from "@/components/BaseHeader";
import ProjectViewForm from "./ProjectViewForm";

import ProjectManagementRemarks from "./ProjectManagementRemarks";
import ProjectProgressSN from "./ProjectProgressSN";

import LineChart from "@/components/Charts/LineChart";
import * as chartConfigs from "@/components/Charts/config";

import UserList from "./AdditionalTable/UserTable";
import ContractorList from "./AdditionalTable/ContractorTable";

import ActivityTable from "./AdditionalTable/ActivityTable";

// import TaskTable from './Task/TaskTable';
import MilestoneProgressTrackList from "./AdditionalTable/MilestoneProgressTrackList";
// import SiteTable from './Site/SiteTable';
import ProjectGroupListingTable from "./ProjectGroupListingTable";
import ProjectSiteListingTable from "./ProjectSiteListingTable";
import ProjectSiteTaskListingTable from "./ProjectSiteTaskListingTable";
import TaskTableForProject from "./../ProjectTask/ProjectTaskListingTable";
import DocumentTableForProject from "./../ProjectDocument/ProjectDocumentListingTable";
import SiteProjectDocumentListingTable from "./ProjectDocumentListingTable";

import {
  LoginURL,
  clientId,
  clientSecret,
  apiDomain,
  getHeader,
  getcurrentDate,
} from "@/assets/js/config.js";
import axios from "axios";
import VueApexCharts from "vue-apexcharts";
import moment from "moment";

import { Modal } from "@/components";
// import ProjectProgressSN from './ProjectProgressSN.vue';

import { Table, TableColumn, DropdownMenu, DropdownItem, Dropdown, Select, Option} from 'element-ui';
import clientPaginationMixin from './js/ProjectSitePaginationMix';

export default {
  mixins: [clientPaginationMixin],
  components: {
    BaseHeader,
    RouteBreadCrumb,
    ProjectViewForm,
    LineChart,
    UserList,
    ContractorList,
    ActivityTable,
    // TaskTable,
    MilestoneProgressTrackList,
    ProjectGroupListingTable,
    ProjectSiteListingTable,
    "project-site-task-listing-table": ProjectSiteTaskListingTable,
    "task-table-for-project": TaskTableForProject,
    "document-table-for-project": DocumentTableForProject,
    "site-project-document-listing-table": SiteProjectDocumentListingTable,
    "project-management-remarks": ProjectManagementRemarks,
    "project-progress-sn": ProjectProgressSN,
    Modal,
    apexchart: VueApexCharts,

    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
  },
  created() {
    var project_access = JSON.parse(
      window.localStorage.getItem("project_access")
    );
    this.button_access.project_delete = project_access.access_delete;

    var project_task_access = JSON.parse(
      window.localStorage.getItem("project_task_access")
    );
    this.button_access.project_task_listing =
      project_task_access.access_listing;
    this.button_access.project_task_new = project_task_access.access_create;

    var project_document_access = JSON.parse(
      window.localStorage.getItem("project_document_access")
    );
    this.button_access.project_document_listing =
      project_document_access.access_listing;
    this.button_access.project_document_new =
      project_document_access.access_create;

    var site_task_access = JSON.parse(
      window.localStorage.getItem("site_task_access")
    );
    this.button_access.site_task_listing = site_task_access.access_listing;
    this.button_access.site_task_new = site_task_access.access_create;

    var site_document_access = JSON.parse(
      window.localStorage.getItem("site_document_access")
    );
    this.button_access.site_document_listing =
      site_document_access.access_listing;
    this.button_access.site_document_new = site_document_access.access_create;

    var project_group_access = JSON.parse(
      window.localStorage.getItem("project_group_access")
    );
    this.button_access.group_listing = project_group_access.access_listing;
    this.button_access.group_new = project_group_access.access_create;

    var project_site_access = JSON.parse(
      window.localStorage.getItem("project_site_access")
    );
    this.button_access.site_listing = project_site_access.access_listing;
    this.button_access.site_new = project_site_access.access_create;

    const LoginUser = JSON.parse(window.localStorage.getItem("loginUser"));
    this.myUserID = LoginUser;

    const tokenData = JSON.parse(window.localStorage.getItem("authUser"));
    this.tokenDataSaved = tokenData;

    this.getDashboardData();

    this.getTotalSite();

    // this.updateSCurve();

    // this.getPmocData();

    this.getSiteListing();

    // this.getProjectMilestoneRemarks();
  },
  data() {
    return {
      myUserID: null,
      button_access: {
        project_delete: null,
        project_task_listing: null,
        project_task_new: null,
        project_document_listing: null,
        project_document_new: null,
        site_task_listing: null,
        site_task_new: null,
        site_document_listing: null,
        site_document_new: null,
        group_listing: null,
        group_new: null,
        site_listing: null,
        site_new: null,
      },
      model: {
        project_id: null,
        project_name: null,
        // project_name: null,
        project_total_installation: 0,
        project_total_capacity: 0,
        project_completed_capacity: 0,
        project_total_task: 0,
        project_completed_task: 0,
        project_total_document: 0,
        project_completed_document: 0,
        project_progress: 0,
        project_level_total_task: 0,
        project_level_completed_task: 0,
        project_level_total_document: 0,
        project_level_completed_document: 0,
        project_type: {
          project_type_id: null,
        },
        developer: {
          developer_code: null,
        },
        status: {
          status_id: null,
          status_code: null,
        },
        project_manager: {
          name: null,
          email: null,
        },
        project_opportunity_state: null,
        project_opportunity_status: null,
        project_opportunity_state_status: null,
        project_opportunity_id: null,
        project_target_sign_date: null,
        project_target_turn_on_date: null,        
        project_account: {
          project_account_name: null,
          project_account_address: null,
        },
      },

      project_sites: [],
      modals: {
        formTask: false,
        formMilestone: false,
      },
      formModal: {
        task_template: "",
        task_site: "",
        milestone_template: "",
      },
      tokenDataSaved: null,
      tableColumns: [],
      tableData: [],
      selectedRows: [],
    };
  },
  methods: {
    getSiteListing() {
      axios.get(apiDomain + 'project/project_site.show_by_project/' + this.model.project_id, { headers: getHeader() })
      .then ( response => {
        if (response.status === 200)
        {
            this.tableData = response.data.data
        }
      })
      .catch ( error => {
          console.log(error.response.status)
      } );
    },
    paginationChanged(page)
    {
      this.pagination.currentPage = page
    },
    // projectSiteDashboard(index, row)
    // {
    //     this.$router.push({path: '/projectSiteDashboard/' + row.site_id })
    // },
    rowClicked(row) {
      this.$router.push({
        path: "/serviceLocationDashboard/" + row.site_id,
      });
    },


    downloadPacPdf() {
      // const tokenData = JSON.parse(window.localStorage.getItem("authUser"));
      // const headers = {
      //   Accept: "application/json",
      //   "Content-Type": "multipart/form-data",
      //   Authorization: "Bearer " + tokenData.access_token,
      // };

      axios.get(apiDomain + "project/project.pac_pdf/" + this.model.project_id, { headers: getHeader(), responseType: 'blob' })
      .then((response) => {
        if (response.status === 200) {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'pac_file.pdf'); //or any other extension
          document.body.appendChild(link);
          link.click();
        }
      })
      .catch((error) => {
        console.log(error.response.status);
      });
    },
    approvePmocForRole(approverRoleId) {
      this.pmoc_model.project_pmoc_id = this.pmoc.project_pmoc_id;
      this.pmoc_model.approver_role_id = approverRoleId;
      this.pmoc_model.approval = 1;

      axios.post(apiDomain + 'project/project.pmoc_update_approval/' + this.model.project_id, this.pmoc_model, { headers: getHeader() })
      .then ( response => {
        if (response.status === 200)
        {
            this.$notify({
            message:
              '<b>Update Project PMOC Approval : ' + this.model.project_name + '</b> - Successfully Updated.',
            timeout: 10000,
            icon: 'ni ni-bell-55',
            type: 'default',
            });
            this.$router.go()
        }
      })
      .catch ( error => {
          console.log(error.response.data.errors)
          this.errorFunction(error, "Update Project PMOC Approval")
      });
    },
    rejectPmocForRole(approverRoleId) {
      this.pmoc_model.project_pmoc_id = this.pmoc.project_pmoc_id;
      this.pmoc_model.approver_role_id = approverRoleId;
      this.pmoc_model.approval = 0;

      axios.post(apiDomain + 'project/project.pmoc_update_approval/' + this.model.project_id, this.pmoc_model, { headers: getHeader() })
      .then ( response => {
        if (response.status === 200)
        {
            this.$notify({
            message:
              '<b>Update Project PMOC Approval : ' + this.model.project_name + '</b> - Successfully Updated.',
            timeout: 10000,
            icon: 'ni ni-bell-55',
            type: 'default',
            });
            this.$router.go()
        }
      })
      .catch ( error => {
          console.log(error.response.data.errors)
          this.errorFunction(error, "Update Project PMOC Approval")
      });
    },
    convertPmocStatus(inputStatusCode) {
      if (inputStatusCode == 1) {
        return "Approved";
      } else if (inputStatusCode == 0) {
        return "Rejected";
      } else {
        return "TBD";
      }
    },
    getPmocData() {
      axios.get(apiDomain + "project/project.pmoc/" + this.model.project_id, { headers: getHeader() })
      .then((response) => {
        if (response.status === 200) {
          this.pmocResponse = response.data.data;
          this.pmoc.project_pmoc_id = response.data.data['project_pmoc_id'];
          this.pmoc.current_ppa_capacity = response.data.data['current_ppa_capacity'];
          this.pmoc.proposed_ppa_capacity = response.data.data['proposed_ppa_capacity'];
          this.pmoc.approval_pl = this.convertPmocStatus(response.data.data['approval_pl']);
          this.pmoc.approval_cm = this.convertPmocStatus(response.data.data['approval_cm']);
          this.pmoc.approval_pr = this.convertPmocStatus(response.data.data['approval_pr']);
        }
      })
      .catch((error) => {
        console.log(error.response.status);
      });
    },
    // dataPointSelectionHandlerTurnOnTargetTurnOn
    // dataPointSelectionHandlerBarMonthly(e, chartContext, config) {
    //   var deductMonth = 12 - (config.dataPointIndex + 1);
    //   var inputDate = new Date(this.model.end_date);
    //   inputDate.setMonth(inputDate.getMonth() - deductMonth);
    //   var queryYear = inputDate.toLocaleString("default", { year: "numeric" });
    //   var queryMonth = inputDate.toLocaleString("default", {
    //     month: "2-digit",
    //   });
    dataPointSelectionHandlerTurnOnTargetTurnOn(e, chartContext, config) {
      var currentDate = getcurrentDate();
      var localTempDate = new Date(currentDate);
      var queryYear = null;
      var queryMonth = null;
      this.tempPrintOut = config.dataPointIndex;
      if (config.dataPointIndex <= 5) {
        // based on status = approved and site_approved_date
        // turn on
        localTempDate.setMonth(
          localTempDate.getMonth() - config.dataPointIndex + 6
        );
        var queryYear = localTempDate.getFullYear().toString();
        var queryMonth = localTempDate.getMonth().toString();
        this.tempPrintOut2 = queryYear + " " + queryMonth;
        // projectSiteListingByProjectMonthlyTurnOn
        let route = this.$router.resolve({
          path:
            "/projectSiteListingByProjectMonthlyTurnOn/" +
            this.model.project_id +
            "/" +
            queryYear +
            "/" +
            queryMonth,
        });
        window.open(route.href, "_blank");
      } else {
        // based on status != approved and site_target_turn_on_date
        // target turn on
        localTempDate.setMonth(
          localTempDate.getMonth() + config.dataPointIndex - 4
        );
        // IF PAST YEAR, FIX LOGIC
        var queryYear = localTempDate.getFullYear().toString();
        var queryMonth = localTempDate.getMonth().toString();
        this.tempPrintOut2 = queryYear + " " + queryMonth;
        let route = this.$router.resolve({
          path:
            "/projectSiteListingByProjectMonthlyTargetTurnOn/" +
            this.model.project_id +
            "/" +
            queryYear +
            "/" +
            queryMonth,
        });
        window.open(route.href, "_blank");
      }
    },
    dataPointSelectionHandlerTurnOnInProgressKwp(e, chartContext, config) {
      if (config.dataPointIndex == 0) {
        // 0 = Turn On
        let route = this.$router.resolve({
          path: "/projectSiteListingByProjectTurnOn/" + this.model.project_id,
        });
        window.open(route.href, "_blank");
      } else if (config.dataPointIndex == 1) {
        // 1 = In Progress
        let route = this.$router.resolve({
          path:
            "/projectSiteListingByProjectInProgress/" + this.model.project_id,
        });
        window.open(route.href, "_blank");
      }
    },
    updateTurnOnTargetTurnOnCategories() {
      var inputDate = getcurrentDate();
      var localTempDate = new Date(inputDate);
      // set six months later
      localTempDate.setMonth(localTempDate.getMonth() + 6);
      var tempArray = [];

      var tempMonth = localTempDate.toLocaleString("default", {
        month: "short",
      });
      var tempYear = localTempDate.toLocaleString("default", {
        year: "2-digit",
      });
      var monthYearString = tempMonth + " '" + tempYear;
      tempArray.push(monthYearString);

      for (let i = 1; i < 12; i++) {
        localTempDate.setMonth(localTempDate.getMonth() - 1);
        var tempMonth = localTempDate.toLocaleString("default", {
          month: "short",
        });
        var tempYear = localTempDate.toLocaleString("default", {
          year: "2-digit",
        });
        var monthYearString = tempMonth + " '" + tempYear;
        tempArray.push(monthYearString);
      }

      // this.tempTurnOnTargetTurnOnCategories = tempArray.reverse();

      this.chartOptionsTurnOnTargetTurnOn = {
        xaxis: {
          categories: tempArray.reverse(),
        },
      };
    },
    getTurnOnTargetTurnOn() {
      this.model.project_id = this.$route.params.id;
      var currentDate = getcurrentDate();

      axios
        .get(
          apiDomain +
            "project/project.turn_on_target_turn_on.show_by_project/" +
            this.model.project_id +
            "/" +
            currentDate,
          { headers: getHeader() }
        )
        .then((response) => {
          if (response.status === 200) {
            this.tempTurnOnTargetTurnOnResponse = response.data.data;
            var tempMonthlyArray = [];
            for (const [key, value] of Object.entries(response.data.data[0])) {
              tempMonthlyArray.push(value);
            }
            this.seriesTurnOnTargetTurnOn = [
              {
                // name: "Turn On / Target Turn On",
                data: tempMonthlyArray,
              },
            ];
            this.updateTurnOnTargetTurnOnCategories();
          }
        })
        .catch((error) => {
          console.log(error.response.status);
        });
    },

    getTurnOnInProgressKwp() {
      this.model.project_id = this.$route.params.id;

      axios
        .get(
          apiDomain +
            "project/project.turn_on_in_progress_kwp/" +
            this.model.project_id,
          { headers: getHeader() }
        )
        .then((response) => {
          if (response.status === 200) {
            var tempSeriesArray = [];
            this.tempTurnOnInProgressKwpResponse = response.data.data;
            tempSeriesArray.push(
              this.tempTurnOnInProgressKwpResponse[0]["turn_on_kwp_in_project"]
            );
            tempSeriesArray.push(
              this.tempTurnOnInProgressKwpResponse[0][
                "in_progress_kwp_in_project"
              ]
            );
            this.seriesTurnOnInProgressKwp = tempSeriesArray;
          }
        })
        .catch((error) => {
          console.log(error.response.status);
        });
    },
    updateIncompleteSitesPieChart() {
      var tempLabelsArray = [];
      var tempSeriesArray = [];
      this.tempTotalSitesMilestoneResponse.forEach((e) => {
        tempLabelsArray.push(e["milestone_template_title"]);
        tempSeriesArray.push(e["site_milestone_kwp_in_progress"]);
      });
      this.chartOptionsTotalSitesIncomplete = {
        labels: tempLabelsArray,
      };
      this.seriesTotalSitesIncomplete = tempSeriesArray;
    },
    getIncompleteSitesDataListing() {
      this.model.project_id = this.$route.params.id;

      axios
        .get(
          apiDomain +
            "project/project_site_milestone.show_by_project/" +
            this.model.project_id,
          { headers: getHeader() }
        )
        .then((response) => {
          if (response.status === 200) {
            this.tempTotalSitesMilestoneResponse = response.data.data;
            this.updateIncompleteSitesPieChart();
            // this.updateSiteIncompletePieChart();
          }
        })
        .catch((error) => {
          console.log(error.response.status);
        });
    },
    routeToProjectImportNewProjectSite() {
      this.$router.push({
        path: "/projectImportProjectSite/" + this.model.project_id,
      });
    },
    dataPointSelectionHandler(e, chartContext, config) {
      this.tempData = [
        config.seriesIndex,
        config.dataPointIndex,
        this.series[config.seriesIndex].name,
        this.series[config.seriesIndex].data[config.dataPointIndex].x,
      ];
      this.tempDataPointIndex = config.dataPointIndex;
      this.getProjectMilestoneRemarks();
    },
    redirectToProjectListing() {
      this.$router.push({
        path: "/projectListing",
      });
    },
    deleteProject() {
      axios
        .delete(apiDomain + "project/project/" + this.model.project_id, {
          headers: getHeader(),
        })
        .then((response) => {
          if (response.status === 200) {
            this.$notify({
              message:
                "<b>Project: " +
                this.model.project_name +
                "</b> - Successfully Deleted.",
              timeout: 10000,
              icon: "ni ni-bell-55",
              type: "default",
            });
            this.redirectToProjectListing();
          }
        })
        .catch((error) => {
          console.log(error.response.status);
        });
    },
    showApprovePMOCModal() {
      this.isApprovePMOCModalVisible = true;
    },
    closeApprovePMOCModal() {
      this.isApprovePMOCModalVisible = false;
    },
    showDeleteProjectModal() {
      this.isDeleteProjectModalVisible = true;
    },
    closeDeleteProjectModal() {
      this.isDeleteProjectModalVisible = false;
    },
    showDeleteProjectModalInner() {
      this.isDeleteProjectModalVisible = false;
      this.isDeleteProjectModalVisibleInner = true;
    },
    closeDeleteProjectModalInner() {
      this.isDeleteProjectModalVisibleInner = false;
    },
    showProjectMilestoneModal() {
      this.isProjectMilestoneModalVisible = true;
    },
    closeProjectMilestoneModal() {
      this.isProjectMilestoneModalVisible = false;
    },
    getDashboardData() {
      this.model.project_id = this.$route.params.id;
      axios
        .get(apiDomain + "project/project/" + this.model.project_id, {
          headers: getHeader(),
        })
        .then((response) => {
          if (response.status === 200) {
            this.model.project_total_task =
              response.data.data[0].project_total_task;
            this.model.project_total_installation =
              response.data.data[0].project_total_installation;
            this.model.project_total_document =
              response.data.data[0].project_total_document;
            this.model.project_progress =
              response.data.data[0].project_progress;
            this.model.project_name = response.data.data[0].project_name;
            this.model.project_name = response.data.data[0].project_name;
            this.model.status.status_code =
              response.data.data[0].status.status_code;
            this.model.project_manager.name =
              response.data.data[0].project_manager.name;
            this.model.project_manager.email =
              response.data.data[0].project_manager.email;
            this.model.developer.developer_code =
              response.data.data[0].developer.developer_code;
            this.model.project_total_capacity =
              response.data.data[0].project_total_capacity;
            this.model.project_completed_capacity =
              response.data.data[0].project_completed_capacity;
            this.model.project_completed_task =
              response.data.data[0].project_completed_task;
            this.model.project_completed_document =
              response.data.data[0].project_completed_document;
            this.model.project_level_total_task =
              response.data.data[0].project_level_total_task;
            this.model.project_level_completed_task =
              response.data.data[0].project_level_completed_task;
            this.model.project_level_total_document =
              response.data.data[0].project_level_total_document;
            this.model.project_level_completed_document =
              response.data.data[0].project_level_completed_document;
            this.model.project_type.project_type_id =
              response.data.data[0].project_type.project_type_id;
            this.model.project_opportunity_state =
              response.data.data[0].project_opportunity_state;
            this.model.project_opportunity_status =
              response.data.data[0].project_opportunity_status;
            this.model.project_opportunity_state_status = (this.model.project_opportunity_state == this.model.project_opportunity_status) ? this.model.project_opportunity_state : this.model.project_opportunity_state + ' - ' + this.model.project_opportunity_status;
            this.model.project_opportunity_id =
              response.data.data[0].project_opportunity_id;
            this.model.project_target_sign_date =
              response.data.data[0].project_target_sign_date;
            this.model.project_target_turn_on_date =
              response.data.data[0].project_target_turn_on_date;
            this.model.project_account.project_account_name =
              response.data.data[0].project_account.project_account_name;
            this.checkIfSolarNovaProject();
          }
        })
        .catch((error) => {
          console.log(error.response.status);
          if (error.response.status == 401) {
            this.$router.push("/login");
          }
        });
    },
    afterUpdaterPojectTaskList() {
      this.getDashboardData();
    },
    afterUpdateTaskList() {
      this.updateSCurve();
      this.getDashboardData();
      this.$refs.milestoneComponent.getDataListing();
    },
    updateSCurve() {
      axios
        .get(apiDomain + "project/project.s_curve/" + this.model.project_id, {
          headers: getHeader(),
        })
        .then((response) => {
          if (response.status === 200) {
            this.bigLineChart.chartData = {
              datasets: [
                {
                  label: "Estimated",
                  data: response.data.data.task_results_est,
                },
                {
                  label: "Actual",
                  data: response.data.data.task_results_act,
                  borderColor: "#FFFFFF",
                  fontColor: "#FFFFFF",
                },
              ],
              labels: response.data.data.labels,
            };
          }
        })
        .catch((error) => {
          console.log(error.response.status);
          this.errorFunction(error, "Project S-Curve");
        });
    },
    showModal() {
      this.modals.formTask = true;
    },
    getTotalSite() {
      axios
        .get(
          apiDomain +
            "project/project_site.show_by_project/" +
            this.model.project_id,
          { headers: getHeader() }
        )
        .then((response) => {
          if (response.status === 200) {
            this.project_sites = response.data.data;
          }
        })
        .catch((error) => {
          console.log(error.response.status);
          if (error.response.status == 401) {
            this.$router.push("/login");
          }
        });
    },
    newProjectDocument() {
      this.$router.push({
        path: "/projectDocumentNewFrProject/" + this.model.project_id,
      });
    },
    newSiteDocument() {
      this.$router.push({
        path: "/siteDocumentNewFrProject/" + this.model.project_id,
      });
    },
    newTask() {
      this.$router.push({
        path: "/projectTaskNewFrProject/" + this.model.project_id,
      });
    },
    newSiteTask() {
      this.$router.push({ path: "/taskNewFrProject/" + this.model.project_id });
    },
    newProjectGroup() {
      this.$router.push({
        path: "/projectGroupNewFrProject/" + this.model.project_id,
      });
    },
    newProjectSite() {
      this.$router.push({
        path: "/projectSiteNewFrProject/" + this.model.project_id,
      });
    },
    backToProject() {
      this.$router.push({ path: "/projectListing" });
    },
    newImport() {
      this.$router.push({
        path: "/importNewFrProject/" + this.model.project_id,
      });
    },
    notready() {
      this.$notify({
        message:
          "<b>Under Developer : </b> - New Task & Populate / Import From Template function is not ready.",
        timeout: 10000,
        icon: "ni ni-bulb-61",
        type: "danger",
      });
    },
    notifyVueFormTask() {
      if (
        this.formModal.task_template === "" ||
        this.formModal.task_site === ""
      ) {
        this.$notify({
          message:
            "<b>Task Template Input Error : </b> - Please Select A Task Template & Target Site OR press <b>CANCEL</b> to <b>EXIT</b>.",
          timeout: 10000,
          icon: "ni ni-bulb-61",
          type: "danger",
        });
      } else {
        this.$notify({
          message:
            "<b>Task Template (" +
            this.formModal.task_template +
            ") : </b> - Successfully Imported into ( TRTP-2398 - Tengeh Reservoir Tender Project )",
          timeout: 10000,
          icon: "ni ni-bell-55",
          type: "default",
        });
        this.clearModal();
      }
    },
    notifyVueFormMilestone() {
      if (this.formModal.milestone_template === "") {
        this.$notify({
          message:
            "<b>Milestone Template Input Error : </b> - Please Select A Milestone Template Or Cancel to Exit.",
          timeout: 10000,
          icon: "ni ni-bulb-61",
          type: "danger",
        });
      } else {
        this.$notify({
          message:
            "<b>Milestone Template (" +
            this.formModal.milestone_template +
            ") : </b> - Successfully Imported into ( TRTP-2398 - Tengeh Reservoir Tender Project )",
          timeout: 10000,
          icon: "ni ni-bell-55",
          type: "default",
        });
        this.clearModal();
      }
    },
    clearModal() {
      this.modals.formTask = false;
      this.modals.formMilestone = false;
      this.formModal.task_template = "";
      this.formModal.task_site = "";
      this.formModal.milestone_template = "";
    },
  },
};
</script>
<style>

.card-border {
  border: 2px solid #BDDBA9;
  border-radius: 100px 0 100px 0;
  /* margin: 20px; */
  padding: 10px;
  /* max-width: 300px; */
}

.bg-custom {
  background-color: #EFF7EA;
}

.card-content {
  padding: 20px;
}

.form-custom-css .form-control:disabled {
  border: 2px solid #FF0000;
  border-radius: 0;
  background-color: white;
}

.form-custom-css .form-control {
  border: 2px solid #BDDBA9;
  border-radius: 0;
  color: black;
}

.form-custom-css .form-control-label {
  font-size: 1rem;
  font-weight: 700;
  color: black;
}

</style>