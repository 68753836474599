<template>
  <card>
    <!-- Card header -->
    <h3 slot="header" class="mb-0">New Group of Project Engineers</h3>
    <div class="text-right">
    </div>
    <form class="needs-validation"
          @submit.prevent="submit">

      <div class="form-row">
        <b-col md="6">
          <base-input label="Project Engineer Group Name"
                      name="Project Engineer Group Name"
                      placeholder="Project Engineer Group Name"
                      v-model="model.engineer_group_name" required>
          </base-input>
        </b-col>

        <b-col md="6">
          <base-input
            label="Project Engineer Group Leader"
            name="Project Engineer Group Leader"
            v-model="model.engineer_group_leader"
          >
            <select class="form-control" v-model="model.engineer_group_leader">
              <option Selected disabled value="x">
                -- Please Select A Project Engineer --
              </option>
              <option
                v-for="item in project_managers"
                :key="item.id"
                v-bind:value="item.id"
              >
                {{ item.name }} - {{ item.email }}
              </option>
            </select>
          </base-input>
        </b-col>
      </div>

      <div class="form-row">
        <b-col md="4">
          <b-form-checkbox
            id="Active-Status"
            v-model="model.active_status"
            name="Active-Status"
            value="1"
            unchecked-value="0">
            <b>Active Status</b>
          </b-form-checkbox>
        </b-col>
      </div>
      <br>

      <base-button type="primary" @click="createData()">Create New Project Engineer Group</base-button>
      <base-button type="primary" @click="$router.back()">Cancel</base-button>
    </form>
  </card>
</template>
<script>
  import BaseSlider from '@/components/BaseSlider'
  import {LoginURL, clientId, clientSecret, apiDomain, getHeader, getcurrentDate} from '@/assets/js/config.js'
  import axios from 'axios'
  import ErrorFunctionMixin from '@/assets/js/errorFunctionMix.js'

  export default {
    mixins: [ErrorFunctionMixin],
    components: {
      BaseSlider
    },
    created()
    {
      var currentDate = getcurrentDate()

      axios
        .get(apiDomain + "lookup/user_list/Project_Manager", {
          headers: getHeader(),
        })
        .then((response) => {
          if (response.status === 200) {
            this.project_managers = response.data.data;
          }
        })
        .catch((error) => {
          console.log(error.response.status);
          this.errorFunction(error, "Project Manager");
        });
    },
    data() {
      return {
        model: {
          engineer_group_name: null,
          engineer_group_leader: "x",
          active_status: 1,
        },
        project_managers: [],
      }
    },
    methods: {
      createData(){
        if ( !this.model.engineer_group_name || this.model.engineer_group_leader == "x" )
        {
          if ( !this.model.engineer_group_name )
          {
            this.redErrorNotify( '<b>Invalid Input : Project Engineer Group Name is a Mandatory Column in Project Engineer Group.</b>')
          }
          else if (this.model.engineer_group_leader == "x") {
            this.redErrorNotify(
              "<b>Invalid Input : Project Engineer Group Leader is a Mandatory Column in Project Engineer Group.</b>"
            );
          }
          else
          {
            this.redErrorNotify( '<b>Invalid Input : Please fill in all the Mandatory Column in Project Engineer Group.</b>' )
          }
        }
        else
        {
          axios.post(apiDomain + 'setup/project_engineer_group', this.model, { headers: getHeader() })
          .then ( response => {
            if (response.status === 200)
            {
                this.$notify({
                message:
                  '<b>New Project Engineer Group : ' + this.model.engineer_group_name + '</b> - Successfully Created.',
                timeout: 10000,
                icon: 'ni ni-bell-55',
                type: 'default',
                });
                this.$router.back()
            }
          })
          .catch ( error => {
            this.errorFunction(error, "Create New Project Engineer Group")
          });
        }
      },
    }
  }
</script>
<style>
</style>
