var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"content"},[_c('base-header',{staticClass:"pb-6"},[_c('b-row',{staticClass:"py-4",attrs:{"align-v":"center"}},[_c('b-col',[_c('h6',{staticClass:"h2 text-white d-inline-block mb-0"},[_vm._v(_vm._s(_vm.title))])])],1)],1),_c('b-container',{staticClass:"mt--6",attrs:{"fluid":""}},[_c('card',{staticClass:"no-border-card",attrs:{"body-classes":"px-0 pb-1","footer-classes":"pb-2"}},[_c('h3',{staticClass:"mb-0",attrs:{"slot":"header"},slot:"header"},[_vm._v("Listing")]),_c('div',{staticClass:"px-3 d-flex align-items-baseline justify-content-between"},[_c('el-select',{staticClass:"select-primary pagination-select",attrs:{"placeholder":"Per page"},model:{value:(_vm.pagination.perPage),callback:function ($$v) {_vm.$set(_vm.pagination, "perPage", $$v)},expression:"pagination.perPage"}},_vm._l((_vm.pagination.perPageOptions),function(item){return _c('el-option',{key:item,staticClass:"select-primary",attrs:{"label":item,"value":item}})}),1),_c('base-input',{attrs:{"prepend-icon":"fas fa-search","placeholder":"Search..."},model:{value:(_vm.searchQuery),callback:function ($$v) {_vm.searchQuery=$$v},expression:"searchQuery"}}),(_vm.access.create)?_c('router-link',{staticClass:"ml-3 btn btn-primary btn-sm",attrs:{"to":"eFormNew"}},[_vm._v(" New Eform ")]):_vm._e()],1),_c('el-table',{attrs:{"data":_vm.queriedData,"row-key":"id","header-row-class-name":"thead-light"},on:{"sort-change":_vm.sortChange}},[_c('el-table-column',{attrs:{"label":"Name","min-width":"150px","prop":"name","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('b-media',{staticClass:"align-items-center",attrs:{"no-body":""}},[_c('b-media-body',[_c('span',{staticClass:"font-weight-600  mb-0 text-sm"},[_vm._v(_vm._s(row.name))])])],1)]}}])}),_c('el-table-column',{attrs:{"label":"Type","min-width":"130px","prop":"type","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('b-media',{staticClass:"align-items-center",attrs:{"no-body":""}},[_c('b-media-body',[_c('b-media-body',[_c('span',{staticClass:"font-weight-600  mb-0 text-sm"},[_vm._v(_vm._s(row.type))])])],1)],1)]}}])}),_c('el-table-column',{attrs:{"label":"Created At","min-width":"150px","prop":"created_at","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('b-media',{staticClass:"align-items-center",attrs:{"no-body":""}},[_c('b-media-body',[_c('span',{staticClass:"font-weight-600  mb-0 text-sm"},[_vm._v(_vm._s(row.created_at))])])],1)]}}])}),_c('el-table-column',{attrs:{"min-width":"120px","label":"Actions"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(_vm.access.show)?_c('router-link',{staticClass:"btn btn-primary btn-sm",attrs:{"to":("edit/" + (row.id))}},[_vm._v(" View / Edit ")]):_vm._e()]}}])})],1),_c('div',{staticClass:"col-12 d-flex justify-content-center justify-content-sm-between flex-wrap",attrs:{"slot":"footer"},slot:"footer"},[_c('div',{},[_c('p',{staticClass:"card-category"},[_vm._v(" Showing "+_vm._s(_vm.from + 1)+" to "+_vm._s(_vm.to)+" of "+_vm._s(_vm.total)+" entries "),(_vm.selectedRows.length)?_c('span',[_vm._v("     "+_vm._s(_vm.selectedRows.length)+" rows selected ")]):_vm._e()])]),_c('base-pagination',{staticClass:"pagination-no-border",attrs:{"current":_vm.pagination.currentPage,"per-page":_vm.pagination.perPage,"total":_vm.total},on:{"change":function($event){return _vm.paginationChanged($event)}}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }