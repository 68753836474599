<template>
    <div>
        <div class="bg-default-dashboard">
            <div class=" py-lg-8 pt-lg-5">
                <b-container fluid style="width: 100%; position: relative;">
                    <b-row>
                        <b-breadcrumb :items="route_items"></b-breadcrumb>
                    </b-row>



                    <validation-observer v-slot="{ handleSubmit }">
                        <form class="needs-validation" @submit.prevent="handleSubmit(onSubmit)">
                            <b-row class="mb-2">
                                <b-col>
                                    <h1 class="text-white">Add New Template</h1>
                                </b-col>
                                <b-col md="6">
                                    <div class="text-right ">
                                        <label class="text-white mr-2 h3 custom-control-label-tmp">Active Status</label>
                                        <base-switch v-model="model.status" :value="true" :unchecked-value="false"
                                            class="mr-2 " on-text="" off-text=""></base-switch>
                                    </div>
                                </b-col>
                            </b-row>
                            <b-row>
                                <b-col>


                                    <b-row class="mb-4">
                                        <b-col>
                                            <div class="text-white h3">Name</div>
                                            <validation-provider name="Name" rules="required"
                                                v-slot="{ classes, errors }">
                                                <b-form-group :invalid-feedback="errors[0]">
                                                    <b-form-input placeholder="..." type="text" class="form-control"
                                                        :class="classes" v-model="model.name"
                                                        maxlength="100"></b-form-input>
                                                </b-form-group>
                                            </validation-provider>
                                        </b-col>
                                    </b-row>

                                    <b-row class="mb-4">
                                        <b-col md="6">
                                            <div class="text-white h3">Main Category Name</div>
                                            <validation-provider name="Main Category Name" rules="required"
                                                v-slot="{ classes, errors }">
                                                <b-form-group :invalid-feedback="errors[0]">
                                                    <b-form-input placeholder="..." type="text" class="form-control"
                                                        :class="classes" v-model="model.category1_name"
                                                        maxlength="100"></b-form-input>
                                                </b-form-group>
                                            </validation-provider>
                                        </b-col>
                                        <b-col md="6">
                                            <div class="text-white h3">Sub Category Name</div>
                                            <validation-provider name="Sub Category Name" rules="required"
                                                v-slot="{ classes, errors }">
                                                <b-form-group :invalid-feedback="errors[0]">
                                                    <b-form-input placeholder="..." type="text" class="form-control"
                                                        :class="classes" v-model="model.category2_name"
                                                        maxlength="100"></b-form-input>
                                                </b-form-group>
                                            </validation-provider>
                                        </b-col>
                                    </b-row>

                                    <b-row class="mb-2">
                                        <b-col>
                                            <validation-provider name="category3_enabled">
                                                <b-form-checkbox v-model="model.category3_enabled" :value="1"
                                                    :unchecked-value="0"><span class="text-white">Enable Third Category
                                                        Name</span></b-form-checkbox>
                                            </validation-provider>
                                        </b-col>
                                    </b-row>

                                    <b-row v-if="model.category3_enabled == 1">
                                        <b-col>
                                            <div class="text-white h3">Third Category Name</div>
                                            <validation-provider name="Third Category Name" v-slot="{ classes, errors }"
                                                v-show="model.category3_enabled">
                                                <b-form-group :invalid-feedback="errors[0]">
                                                    <b-form-input placeholder="..." type="text" class="form-control"
                                                        :class="classes" v-model="model.category3_name"
                                                        maxlength="100"></b-form-input>
                                                </b-form-group>
                                            </validation-provider>
                                        </b-col>
                                    </b-row>
                                    <b-row class="mt-4">
                                        <b-col>
                                            <b-button variant="outline-danger" class="btn btn-primary" type="button"
                                                @click="$router.replace('Tracking')">Cancel</b-button>
                                            <button class="btn btn-primary" v-if="access.create">Create</button>

                                        </b-col>
                                    </b-row>


                                </b-col>
                            </b-row>
                        </form>
                    </validation-observer>
                </b-container>
            </div>
        </div>
    </div>
</template>

<script>
import ErrorFunctionMixin from '@/assets/js/errorFunctionMix.js'
import axios from '@/util/axios'
import { getAccessByModule } from '@/util/access'
import { Select, Option, Table, TableColumn, } from 'element-ui'

export default {
    mixins: [ErrorFunctionMixin],
    components: {
        [Select.name]: Select,
        [Option.name]: Option,
        [Table.name]: Table,
        [TableColumn.name]: TableColumn,

    },
    data() {
        return {
            title: 'Document Tracking Templates',
            route_items: [
                {
                    text: 'Document Tracking Template',
                    href: '#'
                },
                {
                    text: 'Add New Template',
                    active: true
                }
            ],
            model: {
                category3_enabled: 0,
                status: true,
            },
            access: {},
            filterOption: [
                {
                    label: 5,
                    value: 5
                },
                {
                    label: 10,
                    value: 10
                },
                {
                    label: 25,
                    value: 25
                },
                {
                    label: 50,
                    value: 50
                },
            ],
            selectedFilter: 5,
            file_uploads: false,
            rows: 15,
            perPage: 5,
            currentPage: 1
        }
    },

    created() {
        this.access = getAccessByModule('DOC_REPO');
    },

    methods: {
        onSubmit() {

            axios.post('setup/doc_repo/template', this.model)
                .then(response => {
                    this.$notify({
                        message: 'New Template Created',
                        timeout: 10000,
                        icon: 'ni ni-bell-55',
                        type: 'default',
                    });

                    this.$router.replace('Tracking')
                })
                .catch(error => {
                    this.errorFunction(error, this.title)
                });
        },
        showModal() {
      this.$refs['my-modal'].show()
    },
    changePerpage(perPage = 5) {
      this.perPage = perPage;
      console.log(this.currentPage, this.perPage)

    },
    getPagination() {
      this.items = [];

      let from = 0
      if (this.currentPage == 1) {
        from = (this.currentPage - 1)
      } else {
        from = (this.currentPage - 1) * this.perPage;
      }
      this.items = this.all_data.slice(from, this.perPage * this.currentPage)
      // console.log(this.currentPage, this.perPage)
    },
    },
    mounted() {
    this.generateData();
    // this.perPage;
    this.getPagination();
  }
}
</script>
<style scoped>
/* Class For Modal */
.breadcrumb {
    background-color: transparent;
}

.custom-control-label-tmp {
    margin-bottom: 0;
}

.custom-control-label-tmp {
    position: relative;
    margin-bottom: 0;
    vertical-align: top;
}

.modal-content {
    background-color: #306b74;

}

label {
    display: inline-block;
    margin-bottom: 0.5rem;
}

table thead th {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    font-size: 0.65rem;
    text-transform: uppercase;
    letter-spacing: 1px;
    background-color: #23454A;
    color: white;
    padding: 20px;
    border-bottom: 0px solid #082A2F;
    border-top: 0px solid #e9ecef;
}

.th-table-new {
    padding-top: 35px;
    padding-bottom: 35px;
    padding-left: 20px;
}

.tr-td-table-new {
    padding-top: 30px;
    padding-bottom: 30px;
    padding-left: 20px;
}

.form-control {
    background-color: #2C545A;
    border-color: #2C545A;
    color: white;

    &:focus {
        background-color: #2C545A;
        border-color: #2C545A;
        color: white;
    }

    &::placeholder {
        color: white;
    }
}

.form-control {
    &:focus {
        &::placeholder {
            color: white;
        }
    }
}

.input-group-text {
    background-color: #2C545A;
    ;
    color: white;

    &:focus {
        background-color: #2C545A;
        ;
        border-color: #1D4147;
        color: white;
    }
}

.focused {
    .input-group-text {
        background-color: #2C545A;
    }
}

.navbar-search-dark .input-group {
    background-color: #23454A;
    color: black;
    border-color: rgba(255, 255, 255, 0.6);
}

.navbar-search .input-group {
    border-radius: 7px;
}

.el-input__inner,
.el-select-dropdown__item.is-disabled:hover {
    background-color: #23454A;
}
</style>