<template>
<div>
  <card>
    <!-- Card header -->
    <h3 slot="header" class="mb-0">Update Site SN-NT - Tengeh Reservoir North Site for (TRTP-2398 - Tengeh Reservoir Tender Project) </h3>

    <form class="needs-validation"
          @submit.prevent="submit">
      <div class="form-row">
        <b-col md="2">
          <base-input label="Site Code"
                      name="Site Code"
                      placeholder="Site Code"
                      v-model="model.siteCode">
          </base-input>
        </b-col>

        <b-col md="7">
          <base-input label="Site Name"
                      name="Site Name"
                      placeholder="Site Name"
                      v-model="model.siteName">
          </base-input>
        </b-col>

        <b-col md="3">
          <base-input label="Project Manager"
                      name="Project Manager"
                      v-model="model.siteProjectManager">
            <select class="form-control">
              <option  disabled>-- Please Select A Project Manager --</option>
              <option Selected>James</option>
              <option>Alex</option>
              <option>Aida</option>
              <option>Nicole</option>
            </select>
          </base-input>
        </b-col>
      </div>

      <div class="form-row">
        <b-col md="2">
          <base-input label="Site Country"
                      name="Site Country"
                      placeholder="Site Country"
                      v-model="model.siteCountry">
          </base-input>
        </b-col>

        <b-col md="4">
          <base-input label="Address"
                      name="Address"
                      placeholder="Address"
                      v-model="model.siteAddress">
          </base-input>
        </b-col>

        <b-col md="3">
          <base-input label="Curent Milestone"
                      name="Curent Milestone"
                      v-model="model.siteMilestone">
            <select class="form-control">
              <option >-- Please Select A Milestone --</option>
              <option value="Kick-Off">Kick-Off</option>
              <option selected value="Design">Design</option>
              <option value="Installation">Installation</option>
              <option value="Inspection">Inspection</option>
              <option value="Completed">Completed</option>
            </select>
          </base-input>
        </b-col>

        <b-col md="3">
          <base-input label="Project Manager Contact"
                      name="Project Manager Contact"
                      v-model="model.siteProjectManagerContact">
          </base-input>
        </b-col>
      </div>

      <div class="form-row">
        <b-col md="6">
          <base-input label="Contractor List">
              <el-select v-model="selects.multiple"
                  multiple
                  filterable
                  placeholder="Contractor Listt">
                <el-option v-for="option in selectOptions"
                    :key="option.label"
                    :label="option.label"
                    :value="option.value">
                </el-option>
              </el-select>
          </base-input>
        </b-col>
        <b-col md="3">
          <base-input label="Status"
                      name="Status"
                      v-model="model.siteStatus">
          <select class="form-control">
              <option Selected disabled>-- Please Select A Status --</option>
              <option>Pending</option>
              <option selected>In Progress</option>
              <option>On Schedule</option>
              <option>Completed</option>
              <option>Delayed</option>
          </select>
          </base-input>
        </b-col>
        <b-col md="3">
          <base-input label="Progress (%)"
                      name="Progress"
                      placeholder="Progress"
                      v-model="model.siteProgress">
          </base-input>
        </b-col>
      </div>

      <div class="form-row">
          <b-col md="3">
          <base-input label="Module Quantity"
                      name="Module Quantity"
                      placeholder="Module Quantity"
                      v-model="model.siteModuleQuantity">
          </base-input>
          </b-col>
          <b-col md="3">
          <base-input label="Module Capacity (Wp)"
                      name="Module Capacity"
                      placeholder="Module Capacity"
                      v-model="model.siteModuleCapacity">
          </base-input>
          </b-col>
          <b-col md="3">
          <base-input label="Total Capacity (Kwp>"
                      name="Total Capacity"
                      placeholder="Total Capacity"
                      v-model="model.siteTotalCapacity">
          </base-input>
          </b-col>
          <b-col md="3">
          <base-input label="PR Capacity (%)"
                      name="PR Capacity"
                      placeholder="PR Capacity"
                      v-model="model.sitePRCapacity">
          </base-input>
          </b-col>
      </div>

      <div class="form-row">
          <b-col md="3">
          <base-input label="Type of Tension"
                      name="Type of Tension"
                      placeholder="Type of Tension"
                      v-model="model.siteTypeOfTension">
          </base-input>
          </b-col>
          <b-col md="3">
          <base-input label="Approved Load"
                      name="Approved Load"
                      placeholder="Approved Load"
                      v-model="model.siteApprovedLoad">
          </base-input>
          </b-col>
          <b-col md="3">
          <base-input label="MSSL Account Number"
                      name="MSSL Account Number"
                      placeholder="MSSL Account Number"
                      v-model="model.siteMsslAccountNumber">
          </base-input>
          </b-col>
          <b-col md="3">
          <base-input label="Switchroom Tie"
                      name="Switchroom Tie"
                      placeholder="Switchroom Tie"
                      v-model="model.siteSwitchroomTie">
          </base-input>
          </b-col>
      </div>

      <div class="form-row">
          <b-col md="4">
          <base-input label="Plant Name"
                      name="Plant Name"
                      placeholder="Plant Name"
                      v-model="model.sitePlantName">
          </base-input>
          </b-col>
          <b-col md="4">
          <base-input label="Folder Name in SFTP Server"
                      name="Folder Name in SFTP Server"
                      placeholder="Folder Name in SFTP Server"
                      v-model="model.siteFolderNameInSFTPServer">
          </base-input>
          </b-col>
          <b-col md="4">
          <base-input label="Monitoring Application"
                      name="Monitoring Application"
                      placeholder="Monitoring Application"
                      v-model="model.siteMonitoringApplication">
          </base-input>
          </b-col>
      </div>

      <div class="form-row">
          <b-col md="4">
          <base-input label="Total Inverter"
                      name="Total Inverter"
                      placeholder="Total Inverter"
                      v-model="model.siteTotalInverter">
          </base-input>
          </b-col>
          <b-col md="4">
          <base-input label="Logger Type"
                      name="Logger Type"
                      placeholder="Logger Type"
                      v-model="model.siteLoggerType">
          </base-input>
          </b-col>
          <b-col md="4">
          <base-input label="Total Data Logger"
                      name="Total Data Logger"
                      placeholder="Total Data Logger"
                      v-model="model.siteTotalDataLogger">
          </base-input>
          </b-col>
      </div>



      <div class="form-row">
        <b-col md="3">
          <base-input label="Start Date"
                      name="Start Date"
                      type="date"
                      v-model="model.siteStartDate">
          </base-input>
        </b-col>
        <b-col md="3">
          <base-input label="End Date"
                      name="End Date"
                      type="date"
                      v-model="model.siteEndDate">
          </base-input>
        </b-col>

        <b-col md="3">
          <base-input label="ELU End Date"
                      name="ELU End Date"
                      type="date"
                      v-model="model.siteEluEndDate">
          </base-input>
        </b-col>
        <b-col md="3">
          <base-input label="EI Expiry Date"
                      name="EI Expiry Date"
                      type="datetime-local"
                      v-model="model.siteEiExpiryDate">
          </base-input>
        </b-col>
      </div>

      <div class="form-row">
        <b-col md="3">
          <base-input label="Contractor Contractual Start Date"
                      name="Contractor Contractual Start Date"
                      type="date"
                      v-model="model.siteContratorStartDate">
          </base-input>
        </b-col>
        <b-col md="3">
          <base-input label="Contractor Contractual End Date"
                      name="Contractor Contractual End Date"
                      type="date"
                      v-model="model.siteContratorEndDate">
          </base-input>
        </b-col>

        <b-col md="3">
          <base-input label="ELU License Number"
                      name="ELU License Number"
                      type="date"
                      v-model="model.siteEluLicenseNumber">
          </base-input>
        </b-col>
        <b-col md="3">
          <base-input label="ELU Status"
                      name="ELU Status"
                      type="datetime-local"
                      v-model="model.siteELUStatus">
          </base-input>
        </b-col>
      </div>

      <base-button type="primary" native-type="submit" @click="notifyVue('default')">Update Site</base-button>
      <base-button type="primary" @click="$router.go(-1)">Cancel</base-button>
    </form>
  </card>
</div>
</template>
<script>
  import { Select, Option } from 'element-ui'
  import BaseSlider from '@/components/BaseSlider'
  export default {
    name: 'site-edit-form',

    components: {
      BaseSlider,
      [Select.name]: Select,
      [Option.name]: Option,
    },
    data() {
      return {
        selectOptions: [
          {
            label: 'Solar Ex',
            value: 'Solar Ex'
          },
          {
            label: 'West & Smith (Suspended)',
            value: 'West & Smith (Suspended)'
          },
          {
            label: 'AVIAYA (Suspended)',
            value: 'AVIAYA (Suspended)'
          },
          {
            label: 'Long Seng',
            value: 'Long Seng'
          },
        ],
        selects: {
          simple: 'Badges',
          multiple: ['Solar Ex', 'Long Seng']
        },
        model: {
          siteCode: 'SN-NT',
          siteName: 'Tengeh Reservoir North Site',
          siteProjectManager: 'James',
          siteCountry: 'Singapore',
          siteAddress: '501 Jln. Ahmad Ibrahim, Singapura 639937',
          siteMilestone: '',
          siteProjectManagerContact: '012-11232887',
          siteStatus: '',
          siteProgress: 34,
          siteModuleQuantity: '122449',
          siteModuleCapacity: '490',
          siteTotalCapacity: '1213',
          sitePRCapacity: '0',
          siteTypeOfTension: 'None',
          siteApprovedLoad: '',
          siteMsslAccountNumber: '',
          siteSwitchroomTie: '',
          sitePlantName: 'SG-TENGAH',
          siteFolderNameInSFTPServer: 'SG-TENGAH',
          siteMonitoringApplication: 'Solarmon',
          siteTotalInverter: '100',
          siteLoggerType: '10',
          siteTotalDataLogge: 'Huawei Data Logger',
          siteStartDate: '2021-02-01',
          siteEndDate: '2021-07-01',
          siteEluEndDate: '2021-05-05',
          siteEiExpiryDate: '2021-05-05',
          siteContratorStartDate:'2021-01-01',
          siteContratorEndDate: '2021-12-31',
          siteEluLicenseNumber: '213123213',
          siteELUStatus: 'Pending',
          agree: false
        },
      }
    },
    methods: {
      async submit() {
              this.$router.go(-1)
      },
      notifyVue(type = 'default') {
        // console.log(type)
        this.$notify({
          message:
            '<b>Update Site : (' + this.model.siteCode +' - ' + this.model.siteName + ')</b> - Successfully Created for (TRTP-2398 - Tengeh Reservoir Tender Project).',
          timeout: 10000,
          icon: 'ni ni-bell-55',
          type
        });
      },
    }
  }
</script>
<style>
</style>
