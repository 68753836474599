var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',{attrs:{"body-class":"p-0","header-class":"border-0"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('b-row',{attrs:{"align-v":"center"}},[_c('b-col',[_c('h5',{staticClass:"mb-0"},[_vm._v("C & I Project Status")])]),_c('b-col',{staticClass:"text-right"},[_c('h5',{staticClass:"mb-0"},[_vm._v("Total : 52.2 MWp")])])],1)]},proxy:true}])},[_c('el-table',{staticClass:"table-responsive table",attrs:{"data":_vm.tableData,"stripe":"","header-row-class-name":"thead-light"}},[_c('el-table-column',{attrs:{"label":"project","min-width":"100px","prop":"project"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"d-flex align-items-center"},[_c('span',{staticStyle:{"font-size":"11px"}},[_vm._v(_vm._s(row.project))])])]}}])}),_c('el-table-column',{attrs:{"label":"MWp","min-width":"55px","prop":"capacity"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"d-flex align-items-center"},[_c('span',{staticStyle:{"font-size":"11px"}},[_vm._v(_vm._s(row.capacity))])])]}}])}),_c('el-table-column',{attrs:{"label":"Target COD","min-width":"60px","prop":"target_cod"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"d-flex align-items-center"},[_c('span',{staticStyle:{"font-size":"11px"}},[_vm._v(_vm._s(row.target_cod))])])]}}])}),_c('el-table-column',{attrs:{"label":"Forcast COD","min-width":"60px","prop":"forecast_cod"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"d-flex align-items-center"},[_c('span',{staticStyle:{"font-size":"11px"}},[_vm._v(_vm._s(row.forecast_cod))])])]}}])}),_c('el-table-column',{attrs:{"label":"#Days","min-width":"55px","prop":"days"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"d-flex align-items-center"},[_c('badge',{staticClass:"badge-dot mr-4",attrs:{"type":""}},[_c('i',{class:row.progressType}),_c('span',{staticStyle:{"font-size":"11px"}},[_vm._v(_vm._s(row.days))])])],1)]}}])}),_c('el-table-column',{attrs:{"label":"$ IMPACT","min-width":"60px","prop":"impact"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"d-flex align-items-center"},[_c('span',{staticStyle:{"font-size":"11px"}},[_vm._v(_vm._s(row.impact))])])]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }