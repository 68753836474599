<template>
  <div class="content">
    <base-header class="pb-6">
      <b-row align-v="center" class="py-4">
        <b-col cols="7" lg="6">
          <h6 class="h2 text-white d-inline-block mb-0">Audit Log Setup Listing</h6>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <route-bread-crumb></route-bread-crumb>
          </nav>
        </b-col>
      </b-row>
    </base-header>

    <b-container fluid class="mt--6">
      <div>
        <card class="no-border-card" body-classes="px-0 pb-1" footer-classes="pb-2">
          <template slot="header">
            <h3 class="mb-0">Audit Log Setup Listing</h3>
          </template>
          <div>
            <b-col cols="12" class="d-flex justify-content-center justify-content-sm-between flex-wrap">
              <el-select
                class="select-primary pagination-select"
                v-model="pagination.perPage"
                placeholder="Per page">
                <el-option
                  class="select-primary"
                  v-for="item in pagination.perPageOptions"
                  :key="item"
                  :label="item"
                  :value="item">
                </el-option>
              </el-select>
              <div>
                <base-input v-model="searchQuery"
                            prepend-icon="fas fa-search"
                            placeholder="Search...">
                </base-input>
              </div>
            </b-col>
            <b-col cols="12">
              <h3>Filters</h3>

              <div class="form-row">

                <b-col cols="6">
                  <base-input
                    label="Audit Table"
                    name="Audit Table"
                    v-model="filter.audit_table"
                  >
                    <select class="form-control" v-model="filter.audit_table">
                      <option Selected disabled value="x">
                        -- Please Select An Audit Table --
                      </option>
                      <option :value=null>All</option>
                      <option
                        v-for="item in uniqueTable"
                        :key="item"
                        v-bind:value="item"
                      >
                        {{ item }}
                      </option>
                    </select>
                  </base-input>
                </b-col>

                <b-col cols="6">
                  <base-input
                    label="User"
                    name="User"
                    v-model="filter.user_id"
                  >
                    <select class="form-control" v-model="filter.user_id">
                      <option Selected disabled value="x">
                        -- Please Select A User --
                      </option>
                      <option :value=null>All</option>
                      <option
                        v-for="item in uniqueUser"
                        :key="item.id"
                        v-bind:value="item.id"
                      >
                        {{ item.name }} - {{ item.email }}
                      </option>
                    </select>
                  </base-input>
                </b-col>
                
              </div>

              <div class="form-row">

                <b-col cols="6">
                  <base-input
                    label="From Date"
                    name="From Date"
                    type="date"
                    v-model="filter.from_date"
                  >
                  </base-input>
                </b-col>

                <b-col cols="6">
                  <base-input
                    label="To Date"
                    name="To Date"
                    type="date"
                    v-model="filter.to_date"
                  >
                  </base-input>
                </b-col>

              </div>

              <b-row>
                <b-col class="text-right">
                  <!-- responseTableCount: {{ tableData.length }} <br/> -->
                  <!-- button clicked: {{ tempButtonStatus }} <br/> -->
                  <!-- uniqueTable: {{ uniqueTable }} <br/> -->
                  <!-- uniqueUser: {{ uniqueUser }} <br/> -->
                  <!-- uniqueUserLength: {{ uniqueUser.length }} <br/> -->
                  <base-button size="sm" type="primary" @click="searchAuditLogProject()"
                    >Search</base-button
                  >
                </b-col>
              </b-row>

            </b-col>
            <b-col><br></b-col>
            <el-table :data="queriedData"
                      row-key="developer_template_id"
                      header-row-class-name="thead-light"
                      @sort-change="sortChange">

              <el-table-column label="Audit Log ID"
                             min-width="140px"
                             prop="audit_log_id"
                             sortable>
                <template v-slot="{row}">
                    <b-media no-body class="align-items-center">
                        <b-media-body>
                            <span class="font-weight-600  mb-0 text-sm">{{row.audit_log_id}}</span>
                        </b-media-body>
                    </b-media>
                </template>
              </el-table-column>

              <el-table-column label="Audit Table"
                             min-width="140px"
                             prop="audit_table"
                             sortable>
                <template v-slot="{row}">
                    <b-media no-body class="align-items-center">
                        <b-media-body>
                            <span class="font-weight-600  mb-0 text-sm">{{row.audit_table}}</span>
                        </b-media-body>
                    </b-media>
                </template>
              </el-table-column>

              <el-table-column label="Audit Column"
                             min-width="140px"
                             prop="audit_column"
                             sortable>
                <template v-slot="{row}">
                    <b-media no-body class="align-items-center">
                        <b-media-body>
                            <span class="font-weight-600  mb-0 text-sm">{{row.audit_column}}</span>
                        </b-media-body>
                    </b-media>
                </template>
              </el-table-column>

              <el-table-column label="Audit Data ID"
                             min-width="140px"
                             prop="audit_data_id"
                             sortable>
                <template v-slot="{row}">
                    <b-media no-body class="align-items-center">
                        <b-media-body>
                            <span class="font-weight-600  mb-0 text-sm">{{row.audit_data_id}}</span>
                        </b-media-body>
                    </b-media>
                </template>
              </el-table-column>

              <el-table-column label="Audit Changed Date"
                             min-width="140px"
                             prop="audit_changed_date"
                             sortable>
                <template v-slot="{row}">
                    <b-media no-body class="align-items-center">
                        <b-media-body>
                            <span class="font-weight-600  mb-0 text-sm">{{row.audit_changed_date}}</span>
                        </b-media-body>
                    </b-media>
                </template>
              </el-table-column>

              <el-table-column label="Audit Changed By"
                             min-width="140px"
                             prop="audit_change_by"
                             sortable>
                <template v-slot="{row}">
                    <b-media no-body class="align-items-center">
                        <b-media-body>
                            <span class="font-weight-600  mb-0 text-sm">{{row.audit_change_by.name}}</span>
                        </b-media-body>
                    </b-media>
                </template>
              </el-table-column>

              <el-table-column label="Audit Old Value"
                             min-width="140px"
                             prop="audit_old_value"
                             sortable>
                <template v-slot="{row}">
                    <b-media no-body class="align-items-center">
                        <b-media-body>
                            <span class="font-weight-600  mb-0 text-sm">{{row.audit_old_value}}</span>
                        </b-media-body>
                    </b-media>
                </template>
              </el-table-column>

              <el-table-column label="Audit New Value"
                             min-width="140px"
                             prop="audit_new_value"
                             sortable>
                <template v-slot="{row}">
                    <b-media no-body class="align-items-center">
                        <b-media-body>
                            <span class="font-weight-600  mb-0 text-sm">{{row.audit_new_value}}</span>
                        </b-media-body>
                    </b-media>
                </template>
              </el-table-column>
            </el-table>
          </div>
          <div
            slot="footer"
            class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap">
            <div class="">
              <p class="card-category">
                Showing {{ from + 1 }} to {{ to }} of {{ total }} entries

                <span v-if="selectedRows.length">
                  &nbsp; &nbsp; {{selectedRows.length}} rows selected
                </span>
              </p>
            </div>
            <base-pagination
              class="pagination-no-border"
              :current="pagination.currentPage"
              :per-page="pagination.perPage"
              :total="total"
              @change="paginationChanged($event)">
            </base-pagination>
          </div>
        </card>
      </div>
    </b-container>
    </div>
</template>
<script>
import { Table, TableColumn, Select, Option } from 'element-ui';
import RouteBreadCrumb from '@/components/Breadcrumb/RouteBreadcrumb'
import { BasePagination } from '@/components';
import standardPaginationMix from '@/assets/js/standardPaginationMix.js'
import axios from 'axios'
import Fuse from 'fuse.js';
import {LoginURL, clientId, clientSecret, apiDomain, getHeader, parseDateToUTC} from '@/assets/js/config.js'
import ErrorFunctionMixin from '@/assets/js/errorFunctionMix.js'

export default {
  mixins: [standardPaginationMix, ErrorFunctionMixin],
  components: {
    BasePagination,
    RouteBreadCrumb,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn
  },
  created()
  {
    var developer_access = JSON.parse(window.localStorage.getItem('developer_access'))
    this.button_access.new_button = developer_access.access_create
    this.button_access.show_button = developer_access.access_show
    this.getAuditLogProject()
  },
  data() {
    return {
      propsToSearch:
      [
        'audit_log_id',
        'audit_table',
        'audit_column',
        'audit_data_id',
        'audit_changed_date',
        'audit_change_by',
        'audit_old_value',
        'audit_new_value',
      ],
      tableColumns: [],
      tableData: [],
      selectedRows: [],
      button_access:{
        new_button: null,
        show_button: null,
      },
      filter: {
        from_date: null,
        to_date: null,
        audit_table: null,
        user_id: null,
      },
      tempButtonStatus: false,
      uniqueTable: null,
      uniqueUser: null,
    };
  },
  methods: {
    fuseData() {
        this.fuseSearch = new Fuse(this.tableData, {
        keys: this.propsToSearch,
        threshold: 0.3
        });
    },
    paginationChanged(page) {
        this.pagination.currentPage = page
    },
    getAuditLogProject() {
      axios.get(apiDomain + 'audit_log/audit_log_setup', { headers: getHeader() })
        .then ( response => {
        if (response.status === 200)
        {
            this.tableData = response.data.data;
            this.tableData.forEach((e)=>{
                let utcDate = parseDateToUTC(e.audit_changed_date);
                const changedDate = new Date(utcDate);
                const convertedDate = changedDate.toLocaleString('en-US', options);

                const [month, day, year] = convertedDate.split(',')[0].split('/');
                const time = convertedDate.split(', ')[1];
                // Format the date as YYYY-MM-DD
                const formattedDate = `${year}-${month}-${day} ${time}`;

                e.audit_changed_date = formattedDate;
            });
            this.fuseData();
            this.getUniqueTable();
            this.getUniqueUser();
        }
        })
        .catch ( error => {
          this.errorFunction(error, "Audit Log Setup Listing")
        });
    },
    searchAuditLogProject() {
      this.tempButtonStatus = true;
         axios.post(apiDomain + "audit_log/audit_log_setup_search", this.filter, {
          headers: getHeader(),
        })
        .then ( response => {
        if (response.status === 200)
        {
          this.tableData = response.data.data;
          this.fuseData();
        }
        })
        .catch ( error => {
          this.errorFunction(error, "Search Audit Log Setup Listing")
        });
    },
    getUniqueTable() {
      this.uniqueTable = this.tableData
        .map(item => item.audit_table)
        .filter((value, index, self) => self.indexOf(value) === index);
    },
    getUniqueUser() {
      let tempUsers = [];
      // put users for every row of data into tempUsers
      this.tableData.forEach((e) => {
        tempUsers.push(e['audit_change_by']);
      });
      // remove duplicate users from tempUsers
      tempUsers = tempUsers.filter((value, index, self) =>
        index === self.findIndex((t) => (
          t.place === value.place && t.name === value.name
        ))
      );
      this.uniqueUser = tempUsers;
      // sort by name alphabetically
      this.uniqueUser.sort((a, b) => (a.name > b.name) ? 1 : -1);
    },
  }
};
</script>
<style>
  .no-border-card .card-footer{
    border-top: 0;
  }
</style>
