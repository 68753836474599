<template>
  <div>
    <card class="bg-default shadow">
      <div>
        <b-row
          ><b-col class="text-left">
            <h3 slot="header" class="mb-3.5 text-white">
              Project Site Remarks
            </h3></b-col
          ><b-col class="text-right">
            <base-button
              size="sm"
              type="primary"
              @click="showNewRemarkModal()"
              v-if="
                myUserID.role_id == 8 ||
                myUserID.role_id == 19 ||
                (myUserID.role_id == 9 &&
                  myUserID.id == model.project_manager.id) ||
                (myUserID.role_id == 9 && model.group_engineer.id) ||
                (myUserID.role_id == 9 && myUserID.id == model.created_by.id) 
              "
              >New Remark</base-button
            ></b-col
          ></b-row
        >
      </div>
      <!-- <div>{{ projectGroupResponse }}</div> -->
      <br />
      <b-row style="max-height: 400px; overflow-y: scroll"
        ><b-col
          xl="12"
          v-for="item in responseGetMGMGTREM"
          :key="item.project_site_management_remarks_id"
        >
          <card class="bg-transparent border border-white"
            ><b-row align-v="center"
              ><b-col xl="11" class="text-left">
                <div>
                  <pre class="text-white">{{
                    replacePandBr(item.project_site_management_remarks)
                  }}</pre>
                </div>
                <div class="text-right text-white">
                  {{ item.created_by.name }}<br />{{ item.datetime }}
                </div>
              </b-col>
              <b-col class="text-center"
                ><base-button
                  size="sm"
                  type="primary"
                  class="my-1"
                  @click="
                    showEditRemarkModal(item.project_site_management_remarks_id)
                  "
                  v-if="myUserID.id == item.created_by.id"
                  >Edit</base-button
                ><base-button
                  size="sm"
                  type="danger"
                  class="my-1"
                  @click="
                    showDeleteRemarkModal(
                      item.project_site_management_remarks_id
                    )
                  "
                  v-if="myUserID.id == item.created_by.id"
                  >Delete</base-button
                ></b-col
              ></b-row
            ></card
          ></b-col
        ></b-row
      >
    </card>
    <modal
      :show.sync="isNewRemarkModalVisible"
      size="xl"
      body-classes="p-0"
      title="New Project Site Remark"
    >
      <card
        type="secondary"
        header-classes="bg-transparent pb-5"
        body-classes="px-lg-5 py-lg-5"
        class="border-0 mb-0"
      >
        <template>
          <div class="text-center text-muted mb-4">
            <h1>New Project Site Remark</h1>
          </div>
          <br />
          <b-form
            ><div class="form-row">
              <b-form-textarea
                id="textarea"
                v-model="model.project_site_management_remarks"
                placeholder="Project Site Remarks"
                rows="10"
              ></b-form-textarea></div
          ></b-form>
          <div class="text-center">
            <base-button type="primary" class="my-4" @click="submitNewRemark()"
              >Submit</base-button
            ><base-button
              type="primary"
              class="my-4"
              @click="closeNewRemarkModal()"
              >Cancel</base-button
            >
          </div>
        </template>
      </card>
    </modal>

    <modal
      :show.sync="isEditRemarkModalVisible"
      size="xl"
      body-classes="p-0"
      title="Edit Project Site Remark"
    >
      <card
        type="secondary"
        header-classes="bg-transparent pb-5"
        body-classes="px-lg-5 py-lg-5"
        class="border-0 mb-0"
      >
        <template>
          <div class="text-center text-muted mb-4">
            <h1>Edit Project Site Remark</h1>
          </div>
          <br />
          <b-form
            ><div class="form-row">
              <b-form-textarea
                id="textarea"
                v-model="model.project_site_management_remarks"
                placeholder="Project Site Remarks"
                rows="10"
              ></b-form-textarea></div
          ></b-form>
          <div class="text-center">
            <base-button type="primary" class="my-4" @click="updateRemark()"
              >Submit</base-button
            ><base-button
              type="primary"
              class="my-4"
              @click="closeEditRemarkModal()"
              >Cancel</base-button
            >
          </div>
        </template>
      </card>
    </modal>

    <modal
      :show.sync="isDeleteRemarkModalVisible"
      size="xl"
      body-classes="p-0"
      title="Delete Project Remark"
    >
      <card
        type="secondary"
        header-classes="bg-transparent pb-5"
        body-classes="px-lg-5 py-lg-5"
        class="border-0 mb-0"
      >
        <template>
          <div class="text-center text-muted mb-4">
            <h1>Delete Project Remark</h1>
          </div>
          <div class="text-center">
            <div>
              <base-button type="danger" class="my-4" @click="deleteRemark()"
                >Delete</base-button
              >
              <base-button
                type="primary"
                class="my-4"
                @click="closeDeleteRemarkModal()"
                >Cancel</base-button
              >
            </div>
          </div>
        </template></card
      ></modal
    >
  </div>
</template>
<script>
import {
  LoginURL,
  clientId,
  clientSecret,
  apiDomain,
  getHeader,
} from "@/assets/js/config.js";
import axios from "axios";

export default {
  created() {
    this.model.site_id = this.$route.params.id;

    const LoginUser = JSON.parse(window.localStorage.getItem("loginUser"));
    this.myUserID = LoginUser;

    axios
      .get(apiDomain + "project/project_site_short/" + this.model.site_id, {
        headers: getHeader(),
      })
      .then((response) => {
        if (response.status === 200) {
          this.projectGroupResponse = response.data.data;
          this.model.project_manager.id =
            response.data.data[0].project.project_manager.id;
          this.model.group_engineer =
            response.data.data[0].group.group_engineer;
          this.model.created_by = response.data.data[0].created_by;
        }
      })
      .catch((error) => {
        console.log(error.response.status);
        if (error.response.status == 401) {
          this.$router.push("/login");
        }
      });

    this.getProjectRemarks();
  },
  data() {
    return {
      model: {
        site_id: null,
        project_manager: {
          id: null,
        },
        group_engineer: null,
        created_by: null,
        project_site_management_remarks: "",
      },
      myUserID: null,
      text: "",
      buttonClicked: false,
      returnResponse: null,
      responseGetMGMGTREM: [],
      getClicked: false,

      isNewRemarkModalVisible: false,
      isEditRemarkModalVisible: false,
      isDeleteRemarkModalVisible: false,

      clickedEditKey: null,
      projectGroupResponse: null,
    };
  },
  methods: {
    showNewRemarkModal() {
      this.model.project_site_management_remarks = "";
      this.isNewRemarkModalVisible = true;
    },
    closeNewRemarkModal() {
      this.isNewRemarkModalVisible = false;
    },

    replacePandBr(inString) {
      inString = inString.replace(/<p>/g, "");
      inString = inString.replace(/<\/p>/g, "");
      inString = inString.replace(/<br>/g, "\n");

      return inString;
    },

    showEditRemarkModal(key) {
      this.isEditRemarkModalVisible = true;
      this.clickedEditKey = key;

      var tempItem = this.responseGetMGMGTREM.find(
        (item) => item.project_site_management_remarks_id === key
      );
      this.model.project_site_management_remarks = this.replacePandBr(
        tempItem.project_site_management_remarks
      );
    },
    closeEditRemarkModal() {
      this.isEditRemarkModalVisible = false;
    },
    showDeleteRemarkModal(key) {
      this.isDeleteRemarkModalVisible = true;
      this.clickedEditKey = key;
    },
    closeDeleteRemarkModal() {
      this.isDeleteRemarkModalVisible = false;
    },

    convertBackToString() {
      this.responseGetMGMGTREM.forEach(function (i) {
        var tempRemarks = this.replacePandBr(i.project_site_management_remarks);
        i.project_site_management_remarks = tempRemarks;
      });
    },

    // convertUpdatedAtToLocal() {
    //   this.responseGetMGMGTREM.forEach(function (i) {
    //     if (i.created_at != i.updated_at) {
    //       var tempDatetime = new Date(i.updated_at);
    //       tempDatetime.setHours(tempDatetime.getHours() + 8);
    //       i.updated_at = tempDatetime.toLocaleString("default", {
    //         dateStyle: "short",
    //         timeStyle: "short",
    //         hour12: false,
    //       });
    //     } else {
    //       i.updated_at = new Date(i.updated_at).toLocaleString("default", {
    //         dateStyle: "short",
    //         timeStyle: "short",
    //         hour12: false,
    //       });
    //     }
    //   });
    // },

    convertUpdatedAtToLocal() {
      const month = [
        "Janu",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];
      this.responseGetMGMGTREM.forEach(function (i) {
        if (i.created_at != i.updated_at) {
          var tempDatetime = new Date(i.updated_at);
          tempDatetime.setHours(tempDatetime.getHours() + 8);
          var tempDay = tempDatetime.getDate();
          var tempMonth = month[tempDatetime.getMonth()];
          var tempYear = tempDatetime.getFullYear();
          var tempHour = tempDatetime.getHours();
          tempHour = ("0" + tempHour).slice(-2);
          var tempMinute = tempDatetime.getMinutes();
          tempMinute = ("0" + tempMinute).slice(-2);
          i.datetime =
            tempDay +
            " " +
            tempMonth +
            " " +
            tempYear +
            ", " +
            tempHour +
            ":" +
            tempMinute;
        } else {
          var tempDatetime = new Date(i.updated_at);
          var tempDay = tempDatetime.getDate();
          var tempMonth = month[tempDatetime.getMonth()];
          var tempYear = tempDatetime.getFullYear();
          var tempHour = tempDatetime.getHours();
          tempHour = ("0" + tempHour).slice(-2);
          var tempMinute = tempDatetime.getMinutes();
          tempMinute = ("0" + tempMinute).slice(-2);
          i.datetime =
            tempDay +
            " " +
            tempMonth +
            " " +
            tempYear +
            ", " +
            tempHour +
            ":" +
            tempMinute;
        }
      });
    },

    getProjectRemarks() {
      axios
        .get(
          apiDomain +
            "project/project_site_management_remarks.show_by_project_site/" +
            this.model.site_id,
          { headers: getHeader() }
        )
        .then((response) => {
          if (response.status === 200) {
            this.responseGetMGMGTREM = response.data.data;
            this.convertUpdatedAtToLocal();

            this.responseGetMGMGTREM.sort(function (a, b) {
              var keyA = new Date(a.updated_at);
              var keyB = new Date(b.updated_at);
              if (keyA < keyB) return 1;
              if (keyA > keyB) return -1;
              return 0;
            });
          }
        })
        .catch((error) => {
          console.log(error.response.status);
          this.errorFunction(error, "Project Management Remarks");
        });
    },

    deleteRemark() {
      this.closeDeleteRemarkModal();

      axios
        .get(
          apiDomain +
            "project/project_site_management_remarks.delete/" +
            this.clickedEditKey,
          { headers: getHeader() }
        )
        .then((response) => {
          if (response.status === 200) {
            this.$notify({
              message: "Project Remark Successfully Deleted.",
              timeout: 10000,
              icon: "ni ni-bell-55",
              type: "default",
            });
            this.getProjectRemarks();
          }
        })
        .catch((error) => {
          console.log(error.response.data.errors);
          this.errorFunction(error);
        });
    },

    updateRemark() {
      this.closeEditRemarkModal();

      var tempArray = [];
      this.model.project_site_management_remarks
        .split("\n")
        .forEach((item) => tempArray.push(`<p>${item.trim()}</p>`));
      this.model.project_site_management_remarks = tempArray.join("<br>");

      axios
        .post(
          apiDomain +
            "project/project_site_management_remarks.update/" +
            this.clickedEditKey,
          this.model,
          { headers: getHeader() }
        )
        .then((response) => {
          if (response.status === 200) {
            this.$notify({
              message: "Project Remark Successfully Updated.",
              timeout: 10000,
              icon: "ni ni-bell-55",
              type: "default",
            });
            this.model.project_site_management_remarks = "";
            this.getProjectRemarks();
          }
        })
        .catch((error) => {
          console.log(error.response.data.errors);
          this.errorFunction(error);
        });
    },

    submitNewRemark() {
      this.closeNewRemarkModal();

      if (this.model.project_site_management_remarks != null) {
        var tempArray = [];
        this.model.project_site_management_remarks
          .split("\n")
          .forEach((item) => tempArray.push(`<p>${item.trim()}</p>`));
        this.model.project_site_management_remarks = tempArray.join("<br>");
      }

      axios
        .post(
          apiDomain + "project/project_site_management_remarks",
          this.model,
          {
            headers: getHeader(),
          }
        )
        .then((response) => {
          if (response.status === 200) {
            this.$notify({
              message: "New Project Remark Successfully Added.",
              timeout: 10000,
              icon: "ni ni-bell-55",
              type: "default",
            });
          }
          this.model.project_site_management_remarks = "";
          this.getProjectRemarks();
        })
        .catch((error) => {
          console.log(error.response.data.errors);
          this.errorFunction(error);
        });
    },

    getManagementRemarks() {
      this.getClicked = true;
      axios
        .get(
          apiDomain +
            "project/project_site_management_remarks.show_by_project_site/" +
            this.model.project_id,
          { headers: getHeader() }
        )
        .then((response) => {
          if (response.status === 200) {
            this.responseGetMGMGTREM = response.data.data;
          }
        })
        .catch((error) => {
          console.log(error.response.status);
          this.errorFunction(error, "Project Management Remarks");
        });
    },
    createData() {
      this.buttonClicked = true;

      if (this.model.project_site_management_remarks != null) {
        var tempArray = [];
        this.model.project_site_management_remarks
          .split("\n")
          .forEach((item) => tempArray.push(`<p>${item.trim()}</p>`));
        this.model.project_site_management_remarks = tempArray.join("<br>");
      }

      axios
        .post(
          apiDomain + "project/project_site_management_remarks",
          this.model,
          {
            headers: getHeader(),
          }
        )
        .then((response) => {
          if (response.status === 200) {
            this.returnResponse = response.data.data;
          }
        })
        .catch((error) => {
          console.log(error.response.data.errors);
          this.errorFunction(error);
        });
    },
  },
};
</script>
<style>
</style>