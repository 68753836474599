<template>
  <div class="bg-default-dashboard">
    <div class=" py-lg-8 pt-lg-5">
      <b-container fluid style="width: 90%; position: relative;">
        <!-- filters -->
        <!-- maps -->
        <b-row>
          <b-col>
            <b-card no-body class="border-0">
              <div id="map-custom" class="map-canvas" style="height: 800px;" ref="mapRef"></div>
            </b-card>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <div class="text-right ">
              <label class="text-white mr-2 h3 custom-control-label-tmp">Display SKPT</label>
              <base-switch v-model="display_SKPT" :value="true" :unchecked-value="false" class="mr-2 " on-text=""
                off-text="" @change="toggleSKPT(display_SKPT)"></base-switch>
            </div>
          </b-col>
          <b-col>
            <div class="text-right ">
              <label class="text-white mr-2 h3 custom-control-label-tmp">Display Solar Layout</label>
              <base-switch v-model="display_solarlayout" :value="true" :unchecked-value="false" class="mr-2 " on-text=""
                off-text=""></base-switch>
            </div>
          </b-col>
          <b-col>
            <div class="text-right ">
              <label class="text-white mr-2 h3 custom-control-label-tmp">Display Land Owner</label>
              <base-switch v-model="display_owner" :value="true" :unchecked-value="false" class="mr-2 " on-text=""
                off-text=""></base-switch>
            </div>
          </b-col>
          <b-col>
            <div class="text-right ">
              <label class="text-white mr-2 h3 custom-control-label-tmp">Display TL Route</label>
              <base-switch v-model="display_TLRoute" :value="true" :unchecked-value="false" class="mr-2 " on-text=""
                off-text=""></base-switch>
            </div>
          </b-col>
          <b-col>
            <div class="text-right ">
              <label class="text-white mr-2 h3 custom-control-label-tmp">Display Contour Line</label>
              <base-switch v-model="display_contour" :value="true" :unchecked-value="false" class="mr-2 " on-text=""
                off-text=""></base-switch>
            </div>
          </b-col>
        </b-row>
        <!-- filter -->
        <b-row>
          <b-col md="1" class="mt-1">
            <base-input>
              <select class="form-control" v-model="selectedCategory1">
                <option v-for="item of categories1" :key="item.id" :value="item.id" :label="item.code"></option>
              </select>
            </base-input>
          </b-col>
          <b-col md="1" class="mt-1">
            <base-input>
              <select class="form-control" v-model="selectedCategory2">
                <option v-for="item of categories2" :key="item.id" :value="item.id" :label="item.code"></option>
              </select>
            </base-input>
          </b-col>

          <b-col md="1" class="mt-1">
            <template v-if="setting.category3_enabled">
              <base-input>
                <select class="form-control" v-model="selectedCategory3">
                  <option v-for="item of categories3" :key="item.id" :value="item.id" :label="item.code"></option>
                </select>
              </base-input>
            </template>
          </b-col>
        </b-row>
        <!--Filter end here-->

        <!--NEW GRID STARTS HERE-->
        <b-row class="mt-0" style="width: 100%;">
          <b-col md="12">
            <div class="row" style="width: 100%;">
              <!-- <dataset v-slot="{ ds }" :ds-data="documents" :ds-sortby="sortBy" :ds-sort-as="{ birthdate: isoDateToDate }" -->
              <!-- <dataset v-slot="{ ds }" :ds-data="documents"
                :ds-sortby="['category1_code', 'category2_code', 'category3_code', 'number', 'title']" :ds-sort-as="[]"
                :ds-search-in="['category1_code', 'category2_code', 'category3_code', 'number', 'title', 'status']"
                :ds-search-as="[]"> -->
              <dataset style="width: 100%;" v-slot="{ ds }" :ds-data="documents"
                :ds-sortby="['category1_code', 'category2_code', 'category3_code', 'number', 'title']"
                :ds-search-in="['category1_code', 'category2_code', 'category3_code', 'number', 'title', 'status']">
                <!-- :ds-search-as="{ birthdate: searchAsEuroDate }"> -->
                <div class="row" style="padding-bottom: 15px;" :data-page-count="ds.dsPagecount">
                  <div class="col-md-6 mb-2 mb-md-0"
                    style="color: #ffffff; font-family: Open Sans, sans-serif !important; font-size: 0.875rem;">
                    <dataset-show class="dataset-style" />
                  </div>
                  <div class="col-md-6">
                    <dataset-search ds-search-placeholder="Search..." :value="searchValue" @input="updateSearchValue"
                      ref="myTextBox" />
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <div class="table-responsive">
                      <table style="width: 100%;" class="table table-striped d-md-table">
                        <thead
                          style="background-color: #23454A; color: white; font-size: small; border-bottom: 10px solid #082A2F;">
                          <tr>
                            <!-- <th scope="col">#</th> -->
                            <th v-for="(th, index) in obj_pagination.cols" :key="th.field" :class="['sort', th.sort]"
                              @click="click($event, index)">
                              {{ th.name }} <i class="gg-select float-right"></i>
                            </th>
                          </tr>
                        </thead>
                        <dataset-item tag="tbody">
                          <template #default="{ row, rowIndex }">
                            <tr
                              style="background-color: #23454A; color: white; font-size: smaller; border-bottom:  5px solid #082A2F;">
                              <!-- <th scope="row">{{ rowIndex + 1 }}</th> -->
                              <td class="tr-td-table-new" style="min-width: 150px;">{{ row.category1_code }}</td>
                              <td class="tr-td-table-new" style="min-width: 150px;">{{ row.category2_code }}</td>
                              <td class="tr-td-table-new" style="min-width: 150px;">{{ row.category3_code }}</td>
                              <td class="tr-td-table-new" style="min-width: 150px;">{{ row.number }}</td>
                              <td class="tr-td-table-new" style="min-width: 150px;">{{ row.title }}</td>
                              <td class="tr-td-table-new" style="min-width: 100px;">
                                <i class="fa fa-xs fa-circle mr-1" style="color: #46D275;"
                                  v-if="row.status == 'APPROVED'"></i>
                                <i class="fa fa-xs fa-circle mr-1" style="color: #FF9A3D;"
                                  v-if="row.status == 'PENDING_REVIEW'"></i>
                                <i class="fa fa-xs fa-circle mr-1" style="color: #11CDEF;"
                                  v-if="row.status == 'NEW_REVIEW'"></i>
                                <i class="fa fa-xs fa-circle mr-1" style="color: #FF3232;"
                                  v-if="row.status == 'REJECTED'"></i>
                                <i class="fa fa-xs fa-circle mr-1" style="color: #FFD43D;"
                                  v-if="row.status == 'NEW_REVISION'"></i>
                                <i class="fa fa-xs fa-circle mr-1" style="color: white;"
                                  v-if="row.status == 'EMPTY'"></i>
                                <!-- <i class="fa fa-xs fa-circle mr-1"  v-else></i> -->
                                {{ row.status }}
                              </td>
                              <td class="tr-td-table-new" style="min-width: 100px;">
                                <div>
                                  <!--
                          <b-link v-if="role_user=='contractor'" to="tracking" style="color: #FF5C00; font-size:large;" >
                            <svg width="23" height="25" viewBox="0 0 28 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M21.2396 9.71982L21.857 9.10116C22.3485 8.60944 23.0152 8.33313 23.7105 8.33301C24.4058 8.33288 25.0726 8.60895 25.5643 9.10049C26.056 9.59202 26.3323 10.2588 26.3325 10.954C26.3326 11.6493 26.0565 12.3161 25.565 12.8078L24.9476 13.4265M21.2396 9.71982C21.2396 9.71982 21.317 11.0318 22.4756 12.1905C23.6343 13.3492 24.9476 13.4265 24.9476 13.4265M21.2396 9.71982L15.5596 15.3998C15.173 15.7838 14.981 15.9772 14.8156 16.1892C14.621 16.4398 14.4543 16.7092 14.317 16.9972C14.201 17.2398 14.1156 17.4972 13.9436 18.0132L13.393 19.6665L13.2143 20.2012M24.9476 13.4265L19.2676 19.1065C18.881 19.4932 18.689 19.6852 18.477 19.8505C18.2263 20.0452 17.957 20.2118 17.669 20.3492C17.4263 20.4652 17.169 20.5505 16.653 20.7225L14.9996 21.2732L14.465 21.4518M13.2143 20.2012L13.037 20.7372C12.9957 20.8616 12.9898 20.995 13.02 21.1226C13.0502 21.2502 13.1153 21.3668 13.208 21.4595C13.3007 21.5522 13.4173 21.6173 13.5449 21.6475C13.6724 21.6777 13.8059 21.6718 13.9303 21.6305L14.465 21.4518M13.2143 20.2012L14.465 21.4518" stroke="#FF5C00" stroke-width="1.5"/>
                              <path d="M7.66667 16.3332H11M7.66667 10.9998H16.3333M7.66667 21.6665H9.66667M23.4373 3.22917C21.876 1.6665 19.3613 1.6665 14.3333 1.6665H11.6667C6.63867 1.6665 4.124 1.6665 2.56267 3.22917C1 4.7905 1 7.30517 1 12.3332V17.6665C1 22.6945 1 25.2092 2.56267 26.7705C4.124 28.3332 6.63867 28.3332 11.6667 28.3332H14.3333C19.3613 28.3332 21.876 28.3332 23.4373 26.7705C24.696 25.5132 24.94 23.6398 24.988 20.3332" stroke="#FF5C00" stroke-width="1.5" stroke-linecap="round"/>
                            </svg>
                          </b-link>-->
                                  <template v-if="row.status == 'NEW_REVISION' || row.status == 'EMPTY'"><b-link
                                      @click="showModal(row.id, row.status)" style="color: #FF5C00; font-size:large;">
                                      <svg width="23" height="25" viewBox="0 0 28 30" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path
                                          d="M21.2396 9.71982L21.857 9.10116C22.3485 8.60944 23.0152 8.33313 23.7105 8.33301C24.4058 8.33288 25.0726 8.60895 25.5643 9.10049C26.056 9.59202 26.3323 10.2588 26.3325 10.954C26.3326 11.6493 26.0565 12.3161 25.565 12.8078L24.9476 13.4265M21.2396 9.71982C21.2396 9.71982 21.317 11.0318 22.4756 12.1905C23.6343 13.3492 24.9476 13.4265 24.9476 13.4265M21.2396 9.71982L15.5596 15.3998C15.173 15.7838 14.981 15.9772 14.8156 16.1892C14.621 16.4398 14.4543 16.7092 14.317 16.9972C14.201 17.2398 14.1156 17.4972 13.9436 18.0132L13.393 19.6665L13.2143 20.2012M24.9476 13.4265L19.2676 19.1065C18.881 19.4932 18.689 19.6852 18.477 19.8505C18.2263 20.0452 17.957 20.2118 17.669 20.3492C17.4263 20.4652 17.169 20.5505 16.653 20.7225L14.9996 21.2732L14.465 21.4518M13.2143 20.2012L13.037 20.7372C12.9957 20.8616 12.9898 20.995 13.02 21.1226C13.0502 21.2502 13.1153 21.3668 13.208 21.4595C13.3007 21.5522 13.4173 21.6173 13.5449 21.6475C13.6724 21.6777 13.8059 21.6718 13.9303 21.6305L14.465 21.4518M13.2143 20.2012L14.465 21.4518"
                                          stroke="#FF5C00" stroke-width="1.5" />
                                        <path
                                          d="M7.66667 16.3332H11M7.66667 10.9998H16.3333M7.66667 21.6665H9.66667M23.4373 3.22917C21.876 1.6665 19.3613 1.6665 14.3333 1.6665H11.6667C6.63867 1.6665 4.124 1.6665 2.56267 3.22917C1 4.7905 1 7.30517 1 12.3332V17.6665C1 22.6945 1 25.2092 2.56267 26.7705C4.124 28.3332 6.63867 28.3332 11.6667 28.3332H14.3333C19.3613 28.3332 21.876 28.3332 23.4373 26.7705C24.696 25.5132 24.94 23.6398 24.988 20.3332"
                                          stroke="#FF5C00" stroke-width="1.5" stroke-linecap="round" />
                                      </svg>
                                    </b-link></template>
                                  <template v-else-if="row.status == 'REJECTED' || row.status == 'APPROVED'">
                                    <b-link @click="showCompleteModal(row.id, row.status)"
                                      style="color: #FF5C00; font-size:large;">
                                      <svg width="23" height="25" viewBox="0 0 28 30" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path
                                          d="M21.2396 9.71982L21.857 9.10116C22.3485 8.60944 23.0152 8.33313 23.7105 8.33301C24.4058 8.33288 25.0726 8.60895 25.5643 9.10049C26.056 9.59202 26.3323 10.2588 26.3325 10.954C26.3326 11.6493 26.0565 12.3161 25.565 12.8078L24.9476 13.4265M21.2396 9.71982C21.2396 9.71982 21.317 11.0318 22.4756 12.1905C23.6343 13.3492 24.9476 13.4265 24.9476 13.4265M21.2396 9.71982L15.5596 15.3998C15.173 15.7838 14.981 15.9772 14.8156 16.1892C14.621 16.4398 14.4543 16.7092 14.317 16.9972C14.201 17.2398 14.1156 17.4972 13.9436 18.0132L13.393 19.6665L13.2143 20.2012M24.9476 13.4265L19.2676 19.1065C18.881 19.4932 18.689 19.6852 18.477 19.8505C18.2263 20.0452 17.957 20.2118 17.669 20.3492C17.4263 20.4652 17.169 20.5505 16.653 20.7225L14.9996 21.2732L14.465 21.4518M13.2143 20.2012L13.037 20.7372C12.9957 20.8616 12.9898 20.995 13.02 21.1226C13.0502 21.2502 13.1153 21.3668 13.208 21.4595C13.3007 21.5522 13.4173 21.6173 13.5449 21.6475C13.6724 21.6777 13.8059 21.6718 13.9303 21.6305L14.465 21.4518M13.2143 20.2012L14.465 21.4518"
                                          stroke="#FF5C00" stroke-width="1.5" />
                                        <path
                                          d="M7.66667 16.3332H11M7.66667 10.9998H16.3333M7.66667 21.6665H9.66667M23.4373 3.22917C21.876 1.6665 19.3613 1.6665 14.3333 1.6665H11.6667C6.63867 1.6665 4.124 1.6665 2.56267 3.22917C1 4.7905 1 7.30517 1 12.3332V17.6665C1 22.6945 1 25.2092 2.56267 26.7705C4.124 28.3332 6.63867 28.3332 11.6667 28.3332H14.3333C19.3613 28.3332 21.876 28.3332 23.4373 26.7705C24.696 25.5132 24.94 23.6398 24.988 20.3332"
                                          stroke="#FF5C00" stroke-width="1.5" stroke-linecap="round" />
                                      </svg>
                                    </b-link>
                                  </template>
                                  <template v-else><b-link @click="showPasifModal(row.id, row.status)"
                                      style="color: #FF5C00; font-size:large;">
                                      <svg width="23" height="25" viewBox="0 0 28 30" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path
                                          d="M21.2396 9.71982L21.857 9.10116C22.3485 8.60944 23.0152 8.33313 23.7105 8.33301C24.4058 8.33288 25.0726 8.60895 25.5643 9.10049C26.056 9.59202 26.3323 10.2588 26.3325 10.954C26.3326 11.6493 26.0565 12.3161 25.565 12.8078L24.9476 13.4265M21.2396 9.71982C21.2396 9.71982 21.317 11.0318 22.4756 12.1905C23.6343 13.3492 24.9476 13.4265 24.9476 13.4265M21.2396 9.71982L15.5596 15.3998C15.173 15.7838 14.981 15.9772 14.8156 16.1892C14.621 16.4398 14.4543 16.7092 14.317 16.9972C14.201 17.2398 14.1156 17.4972 13.9436 18.0132L13.393 19.6665L13.2143 20.2012M24.9476 13.4265L19.2676 19.1065C18.881 19.4932 18.689 19.6852 18.477 19.8505C18.2263 20.0452 17.957 20.2118 17.669 20.3492C17.4263 20.4652 17.169 20.5505 16.653 20.7225L14.9996 21.2732L14.465 21.4518M13.2143 20.2012L13.037 20.7372C12.9957 20.8616 12.9898 20.995 13.02 21.1226C13.0502 21.2502 13.1153 21.3668 13.208 21.4595C13.3007 21.5522 13.4173 21.6173 13.5449 21.6475C13.6724 21.6777 13.8059 21.6718 13.9303 21.6305L14.465 21.4518M13.2143 20.2012L14.465 21.4518"
                                          stroke="#FF5C00" stroke-width="1.5" />
                                        <path
                                          d="M7.66667 16.3332H11M7.66667 10.9998H16.3333M7.66667 21.6665H9.66667M23.4373 3.22917C21.876 1.6665 19.3613 1.6665 14.3333 1.6665H11.6667C6.63867 1.6665 4.124 1.6665 2.56267 3.22917C1 4.7905 1 7.30517 1 12.3332V17.6665C1 22.6945 1 25.2092 2.56267 26.7705C4.124 28.3332 6.63867 28.3332 11.6667 28.3332H14.3333C19.3613 28.3332 21.876 28.3332 23.4373 26.7705C24.696 25.5132 24.94 23.6398 24.988 20.3332"
                                          stroke="#FF5C00" stroke-width="1.5" stroke-linecap="round" />
                                      </svg>
                                    </b-link></template>
                                </div>
                              </td>
                            </tr>
                          </template>
                        </dataset-item>
                      </table>
                    </div>
                  </div>
                </div>
                <div class="d-flex flex-md-row flex-column justify-content-between align-items-center"
                  style="color: #ffffff; font-family: Open Sans, sans-serif !important; font-size: 0.875rem;">
                  <dataset-info class="mb-2 mb-md-0" />
                  <dataset-pager />
                </div>
              </dataset>
            </div>
          </b-col>
        </b-row>

        <!--MODAL NEW REVISIONS-->
        <b-modal ref="contractor-modal" size="xl" centered hide-footer title="Revision" class="text-white">
          <b-row>
            <b-col>
              <label class="text-white" for="">Comment</label>
              <b-form-textarea v-model="revision_comment" id="textarea-no-resize" placeholder="" rows="3"
                no-resize></b-form-textarea>
            </b-col>
          </b-row>
          <br>
          <b-row>
            <b-col>

              <table style="width: 100%;">
                <template v-if="files.length">
                  <thead
                    style="background-color: #23454A; color: white; font-size: small; border-bottom: 10px solid  #306b74;">
                    <th class="th-table-modal-new ">File to upload </th>
                    <th class="th-table-modal-new ">Size</th>
                    <th class="th-table-modal-new "></th>
                  </thead>
                  <tbody>
                    <tr v-for="(file, index) of files"
                      style="background-color: #3a656b; color: white; font-size: smaller; border-bottom:  5px solid  #306b74;">
                      <td class="tr-td-table-modal-new"> <i class="fa fa-file mr-1"></i> {{ file.name }}</td>
                      <td class="tr-td-table-modal-new"><a href="#" @click.prevent="downloadFile(file.id)">{{
                        formatSize(file.size) }}</a></td>

                      <td class="tr-td-table-modal-new">
                        <div style="">
                          <b-link>
                            <i class="fa fa-trash" style="color: #FF3232; font-size:small;"
                              @click="removeFile(index)"></i>
                          </b-link>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </template>
                <template v-if="uploadedFiles.length">
                  <thead
                    style="background-color: #23454A; color: white; font-size: small; border-bottom: 10px solid  #306b74;">
                    <th class="th-table-modal-new ">Uploaded File </th>
                    <th class="th-table-modal-new ">Size</th>
                    <th class="th-table-modal-new "></th>
                  </thead>
                  <tbody>
                    <tr v-for="(file, index) of uploadedFiles"
                      style="background-color: #3a656b; color: white; font-size: smaller; border-bottom:  5px solid  #306b74;">
                      <td class="tr-td-table-modal-new"><i class="fa fa-file mr-1" @click="downloadFile(file.id)"></i>{{
                        file.filename }}</td>
                      <td class="tr-td-table-modal-new">{{ formatSize(file.size) }}</td>
                      <td class="tr-td-table-modal-new">
                        <div style="">
                          <b-link>
                            <i class="fa fa-trash" style="color: #FF3232; font-size:small;"></i>
                          </b-link>
                        </div>
                      </td>
                    </tr>


                  </tbody>
                </template>
              </table>
            </b-col>
            <b-col>
              <b-row>
                <b-col class="text-right">
                  <!--
                     <span class="text-white "><i class="fa fa-file mr-2" style="font-size: large;"></i>Testing.jpg <sup style="padding-left: 3px;">
                      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0_1457_587)">
                        <path d="M13.6541 2.34121C12.9119 1.59736 12.03 1.00749 11.0591 0.605461C10.0883 0.203434 9.04757 -0.00282109 7.99676 -0.00145813C3.57809 -0.00145813 -0.00390625 3.58054 -0.00390625 7.99921C-0.00390625 10.2085 0.892094 12.2092 2.34009 13.6572C3.08231 14.4011 3.96418 14.9909 4.93504 15.393C5.9059 15.795 6.94662 16.0012 7.99743 15.9999C12.4161 15.9999 15.9981 12.4179 15.9981 7.99921C15.9981 5.78988 15.1021 3.78921 13.6541 2.34121ZM12.6261 12.6259C12.0189 13.2346 11.2974 13.7174 10.5031 14.0464C9.70875 14.3755 8.85722 14.5443 7.99743 14.5432C4.38209 14.5432 1.45143 11.6125 1.45143 7.99721C1.45035 7.13742 1.61918 6.28589 1.94822 5.49155C2.27726 4.69722 2.76002 3.97573 3.36876 3.36854C3.9758 2.75979 4.69717 2.27702 5.4914 1.94798C6.28563 1.61893 7.13707 1.45011 7.99676 1.45121C11.6114 1.45121 14.5421 4.38188 14.5421 7.99654C14.5432 8.85624 14.3744 9.70769 14.0454 10.5019C13.7163 11.2962 13.2335 12.0175 12.6248 12.6245L12.6261 12.6259Z" fill="#FF6060"/>
                        <path d="M9.02503 7.99983L11.595 5.42983C11.7218 5.29165 11.7903 5.10984 11.7862 4.92236C11.7822 4.73488 11.7059 4.55622 11.5732 4.42366C11.4406 4.2911 11.2619 4.2149 11.0744 4.21096C10.8869 4.20701 10.7051 4.27564 10.567 4.4025L10.5677 4.40183L7.99769 6.97183L5.42769 4.40183C5.28951 4.27506 5.10771 4.20656 4.92023 4.21062C4.73275 4.21469 4.55408 4.291 4.42152 4.42365C4.28897 4.55629 4.21276 4.73501 4.20882 4.92249C4.20488 5.10997 4.2735 5.29173 4.40036 5.42983L4.39969 5.42916L6.9697 7.99916L4.39969 10.5692C4.32739 10.6355 4.26925 10.7158 4.22878 10.8052C4.18832 10.8946 4.16636 10.9912 4.16423 11.0893C4.16211 11.1875 4.17985 11.285 4.21641 11.376C4.25296 11.4671 4.30756 11.5498 4.37693 11.6193C4.44629 11.6887 4.52899 11.7433 4.62003 11.7799C4.71107 11.8165 4.80858 11.8344 4.90669 11.8323C5.0048 11.8302 5.10147 11.8083 5.1909 11.7679C5.28032 11.7275 5.36064 11.6694 5.42703 11.5972L5.42769 11.5965L7.99769 9.0265L10.5677 11.5965C10.634 11.6688 10.7143 11.7269 10.8037 11.7674C10.8931 11.8079 10.9898 11.8298 11.0879 11.832C11.186 11.8341 11.2835 11.8163 11.3746 11.7798C11.4656 11.7432 11.5484 11.6886 11.6178 11.6193C11.6872 11.5499 11.7418 11.4672 11.7785 11.3762C11.8151 11.2851 11.8329 11.1876 11.8308 11.0895C11.8288 10.9914 11.8069 10.8947 11.7665 10.8053C11.726 10.7159 11.668 10.6356 11.5957 10.5692L11.595 10.5685L9.02503 7.99983Z" fill="#FF6060"/>
                        </g>
                        <defs>
                        <clipPath id="clip0_1457_587">
                        <rect width="16" height="16" fill="white"/>
                        </clipPath>
                        </defs>
                      </svg>
                      </sup>
                    </span>-->
                </b-col>
                <b-col class="text-right">
                  <br>
                  <input type="file" class="d-none" ref="file_upload" multiple @change="currentFileChanged($event)">
                  <b-button size="sm" variant="primary" @click="showFileDialog()">Choose File</b-button>
                  <!--
                    <b-button size="sm" variant="primary" @click="uploadFiles()">Upload File</b-button>
                    -->
                </b-col>
              </b-row>
            </b-col>
          </b-row>
          <b-row class="mt-3">
            <b-col class="text-right">
              <b-button size="md" variant="outline-danger" @click="hideModal">Cancel</b-button>
              <b-button size="md" variant="primary" @click="uploadFiles()">Submit for Review</b-button>
            </b-col>
          </b-row>
        </b-modal>
        <!--END MODAL NEW REVISIONS-->

        <!--MODAL PASIF PENDING WITH SEMBCORP REVIEW-->
        <b-modal ref="contractor-pasif-modal" size="xl" centered hide-footer title="Revision" class="text-white">
          <b-row>
            <b-col>
              <label class="text-white" for="">Comment</label>
              <b-form-textarea v-model="revision_comment" :disabled="!isActive" id="textarea-no-resize" placeholder=""
                rows="3" no-resize></b-form-textarea>
            </b-col>
          </b-row>
          <br>
          <b-row>
            <b-col>

              <table style="width: 100%;">

                <template v-if="uploadedFiles.length">
                  <thead
                    style="background-color: #23454A; color: white; font-size: small; border-bottom: 10px solid  #306b74;">
                    <th class="th-table-modal-new ">Uploaded File </th>
                    <th class="th-table-modal-new ">Size</th>
                    <th class="th-table-modal-new "></th>
                  </thead>
                  <tbody>
                    <tr v-for="(file, index) of uploadedFiles"
                      style="background-color: #3a656b; color: white; font-size: smaller; border-bottom:  5px solid  #306b74;">
                      <td class="tr-td-table-modal-new"><i class="fa fa-file mr-1"></i> {{ file.filename }}</td>
                      <td class="tr-td-table-modal-new">{{ formatSize(file.size) }}</td>
                      <td class="tr-td-table-modal-new">
                        <div style="">
                          <a href="#" @click.prevent="downloadFile(file.id)">Download</a>
                        </div>
                      </td>
                    </tr>


                  </tbody>
                </template>
              </table>
            </b-col>
            <b-col>
              <b-row>
                <b-col class="text-right">

                </b-col>
                <b-col class="text-right">
                  <br>

                </b-col>
              </b-row>
            </b-col>
          </b-row>
          <b-row class="mt-3">
            <b-col class="text-right">
              <b-button size="md" variant="outline-danger" @click="hidePasifModal">Cancel</b-button>
            </b-col>
          </b-row>
        </b-modal>
        <!--END MODAL PASIF PENDING WITH SEMBCORP REVIEW-->

        <!--MODAL PASIF COMPLETE-->
        <b-modal ref="complete-pasif-modal" size="xl" centered hide-footer title="Revision - Review" class="text-white">
          <b-row>
            <b-col>
              <label class="text-white" for="">{{ this.review_detail }}</label>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <label class="text-white" for="">Comment</label>
              <b-form-textarea v-model="review_comment" :disabled="!isActive" id="textarea-no-resize" placeholder=""
                rows="3" no-resize></b-form-textarea>
            </b-col>
          </b-row>
          <br>
          <b-row>
            <b-col>

              <table style="width: 100%;">

                <template v-if="uploadedFiles.length">
                  <thead
                    style="background-color: #23454A; color: white; font-size: small; border-bottom: 10px solid  #306b74;">
                    <th class="th-table-modal-new ">REVISION File(s) </th>
                    <th class="th-table-modal-new ">Size</th>
                    <th class="th-table-modal-new "></th>
                  </thead>
                  <tbody>
                    <tr v-for="(file, index) of uploadedFiles"
                      style="background-color: #3a656b; color: white; font-size: smaller; border-bottom:  5px solid  #306b74;">
                      <td class="tr-td-table-modal-new"><i class="fa fa-file mr-1"></i> {{ file.filename }}</td>
                      <td class="tr-td-table-modal-new">{{ formatSize(file.size) }}</td>
                      <td class="tr-td-table-modal-new">
                        <div style="">
                          <a href="#" @click.prevent="downloadFile(file.id)">Download</a>
                        </div>
                      </td>
                    </tr>




                  </tbody>
                </template>

                <template v-if="review_uploadedFiles.length">
                  <thead
                    style="background-color: #23454A; color: white; font-size: small; border-bottom: 10px solid  #306b74;">
                    <th class="th-table-modal-new ">REVIEW File(s) </th>
                    <th class="th-table-modal-new ">Size</th>
                    <th class="th-table-modal-new "></th>
                  </thead>
                  <tbody>
                    <tr v-for="(file, index) of review_uploadedFiles"
                      style="background-color: #3a656b; color: white; font-size: smaller; border-bottom:  5px solid  #306b74;">
                      <td class="tr-td-table-modal-new"><i class="fa fa-file mr-1"></i> {{ file.filename }}</td>
                      <td class="tr-td-table-modal-new">{{ formatSize(file.size) }}</td>
                      <td class="tr-td-table-modal-new">
                        <div style="">
                          <a href="#" @click.prevent="downloadFile(file.id)">Download</a>
                        </div>
                      </td>
                    </tr>




                  </tbody>
                </template>

              </table>
            </b-col>
            <b-col>
              <b-row>
                <b-col class="text-right">

                </b-col>
                <b-col class="text-right">
                  <br>

                </b-col>
              </b-row>
            </b-col>
          </b-row>
          <b-row class="mt-3">
            <b-col class="text-right">
              <b-button size="md" variant="primary" @click="showModalAfterComplete()">Create New Revision</b-button>
              <b-button size="md" variant="outline-danger" @click="hideCompleteModal()">Cancel</b-button>
            </b-col>
          </b-row>
        </b-modal>
        <!--END MODAL PASIF COMPLETE-->
      </b-container>
    </div>
  </div>
</template>
<script>
import { Table, TableColumn, Select, Option } from 'element-ui';


import ErrorFunctionMixin from "@/assets/js/errorFunctionMix.js";
// import Card from "../../../components/Cards/Card.vue";

import { API_KEY } from '@/views/Maps/API_KEY';
import GoogleMapsLoader from 'google-maps';
GoogleMapsLoader.KEY = API_KEY;
GoogleMapsLoader.LIBRARIES = ['geometry'];

import VueHighcharts from 'vue2-highcharts'
import proj4 from "proj4"

import {
  LoginURL,
  clientId,
  clientSecret,
  apiDomain,
  getHeader,
  getcurrentDate,
} from "@/assets/js/config.js";

import {
  Dataset,
  DatasetItem,
  DatasetInfo,
  DatasetPager,
  DatasetSearch,
  DatasetShow
} from 'vue-dataset';

//import axios from "axios";
import axios from '@/util/axios';
import { getAccessByModule } from '@/util/access';
import { formatBytesInUnits, documentStatusToText, revisionStatusToText } from './util'

import dataCert from './MapSesnaDataCert.js';
import dataContour from './MapSesnaDataContour.js';
import dataProposeLayout from './MapSesnaDataProposeLayout.js';
import dataSolarLayout from './MapSesnaDataSolarLayout.js';
import dataProposeTLRoute from './MapSesnaDataProposeTLRoute.js';


export default {
  mixins: [ErrorFunctionMixin],
  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    VueHighcharts,
    Dataset,
    DatasetItem,
    DatasetInfo,
    DatasetPager,
    DatasetSearch,
    DatasetShow,
  },

  beforeDestroy() {
    // prevent memory leak
    clearInterval(this.interval);
    clearInterval(this.dataDateInterval);
  },
  created() {
    window.vm = this;
    // update the time every second
    this.interval = setInterval(() => {
      // Concise way to format time according to system locale.
      // In my case this returns "3:48:00 am"
      this.time = Intl.DateTimeFormat('en-GB', {
        day: '2-digit',
        month: 'short',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: false,
      }).format().replace(', ', ' | ');
    }, 1000)

    // Schedule checkAndUpdateDataDate every 10 minutes
    this.dataDateInterval = setInterval(() => {
      this.checkAndUpdateDataDate();
    }, 600000); // 10 minutes in milliseconds

    //this.getDeveloperOptions();
    var currentDate = getcurrentDate();
    this.model.data_date = currentDate;
    //this.changeAllChart();

    const LoginUser = JSON.parse(window.localStorage.getItem("loginUser"));
    this.myUserID = LoginUser;
    this.getDashboardData();
  },
  computed: {
  },
  data() {
    return {
      obj_pagination: {
        cols: [
          {
            name: 'Cat1',
            field: 'category1_code',
            sort: ''
          },
          {
            name: 'Cat2',
            field: 'category2_code',
            sort: ''
          },
          {
            name: 'Cat3',
            field: 'category3_code',
            sort: ''
          },
          {
            name: 'Number',
            field: 'number',
            sort: ''
          },
          {
            name: 'Title',
            field: 'title',
            sort: ''
          },
          {
            name: 'Status',
            field: 'status',
            sort: ''
          },
          {}
        ],
      },
      interval: null,
      time: null,
      dataDateInterval: null,
      developers: [],
      infoWindow: null,
      lastInfoWindow: null,
      model: {
        // developer_id: null,
        developer_id: 1,
        data_date: null,
      },
      // modalTitle: "On-Going C&I Projects Detailed",
      gmapMarkers: [],
      gmapLastInfoWindow: null,
      total_data: {
        total_capacity_mwp: null,
        total_turn_on_capacity_mwp: null,
        total_site: null,
      },
      chartOptionObservations: null,
      chartOptionsFinding: null,
      selectedFilter: 5,
      file_uploads: true,
      polygon: null,
      polygonCoords: [
        { lat: 21.291, lng: -157.821 },
        { lat: 37.772, lng: -122.214 },
        { lat: -18.142, lng: 178.431 },
        { lat: -27.467, lng: 153.027 },
      ],
      contourLinesData: [
        [
          { lat: -2.844615288030678, lng: 122.0808162215605 },
          { lat: -2.843626065557394, lng: 122.0808581604368 },
          { lat: -2.842561916832862, lng: 122.0835809703182 },
          { lat: -2.841683869208673, lng: 122.0870592412189 },
          { lat: -2.842930810426423, lng: 122.0954786352646 },
          { lat: -2.837476413586116, lng: 122.1013152926669 },
          { lat: -2.828609591574998, lng: 122.108910416868 },
          { lat: -2.827088504528595, lng: 122.1202308164921 },
          { lat: -2.829230982549955, lng: 122.1328643341851 },
          { lat: -2.829537642082105, lng: 122.1429807751129 },
          { lat: -2.838550870239782, lng: 122.1429912846695 },
          { lat: -2.842895570101015, lng: 122.1519798356979 },
        ],
      ],
      sesnamap: null,
      polygon_info: '',
      display_SKPT: true,
      display_solarlayout: true,
      display_substation: true,
      display_TLRoute: true,
      display_contour: true,
      display_owner: false,
      contourData: dataContour,
      deedsData: dataCert,
      proposeLayoutData: dataProposeLayout,
      solarLayoutData: dataSolarLayout,
      proposeTLRoute: dataProposeTLRoute,
      deedsPoligon: [],
      contourPoligon: [],
      solarlayoutPoligon: [],
      substationMark: [],
      TLRoute: [],
      ownerLabel: [],
      counter_lines: [],
      inProgressIcon: '/img/theme/general/pin-progress.png',
      completedIcon: '/img/theme/general/pin-complete.png',
      utm51M: "+proj=utm +zone=51 +south +datum=WGS84 +units=m +no_defs",
      wgs84: "+proj=longlat +datum=WGS84 +no_defs",
      searchValue: '',
      filterOption: [
        {
          label: 5,
          value: 5
        },
        {
          label: 10,
          value: 10
        },
        {
          label: 25,
          value: 25
        },
        {
          label: 50,
          value: 50
        },
      ],
      all_data: [],
      selectedFilter: 5,
      file_uploads: false,
      rows: 15,
      perPage: 5,
      currentPage: 1,
      model: {
        project_id: null,
        developer_id: "x",
        project_type_id: "x",
        project_manager: "x",
        project_manager_name: "x",
        project_engineer: "x",
        project_engineer_name: "x",
        project_business_development: "x",
        project_business_development_name: "x",
        project_safety: "x",
        project_safety_name: "x",
        project_name: null,
        project_ppa_approved_capacity: null,
        project_ppa_start_date: null,
        project_watt_class: null,
        roofed_handover_date: null,
        project_tag: null,
        site_id: null,
        developers: [],
        project_type: [],
        project_managers: [],
        project_engineers: [],
        project_business_development: [],
        project_safety: [],
        site_status: [],
        isSolarNovaProject: false,
        items: [],
      },
      siteListingData: null,
      title: 'Document Tracking',
      pid: null,
      sid: null,
      selectedDocID: null,
      setting: {},
      categories1: [],
      categories2: [],
      categories3: [],
      selectedCategory1: null,
      selectedCategory2: null,
      selectedCategory3: null,
      documents: [],
      access: {},
      document: {},
      revisions: [],
      revision: null,
      comment: null,
      files: [],
      uploadedFiles: [],
      revision_comment: null,
      review: null,
      uploadedFiles: [],
      review_uploadedFiles: [],
      revision_comment: null,
      review_comment: null,
      review_detail: null,
      confirmDialogVisible: false,
      confirmDialogMessage: '',
      confirmDialogKind: '',
      intCounter: 0,
      isActive: null,
    };
  },
  methods: {
    handleClick(event, SKPTNo) {
      event.preventDefault();
      //alert(SKPTNo);
      this.searchValue = SKPTNo;
      //this.$refs.myTextBox.value = SKPTNo;
      this.$refs.myTextBox.search(SKPTNo);
    },

    updateSearchValue(newValue) {
      this.searchValue = newValue;
    },

    checkAndUpdateDataDate() {
      const currentDate = getcurrentDate();
      if (this.model.data_date !== currentDate) {
        this.model.data_date = currentDate;
        this.changeAllChart();
      }
    },
    loadGoogleMap() {
      GoogleMapsLoader.load(google => {
        this.initMap(google);
      });
    },

    initMap(google) {


      let map, lat = -2.842371, lng = 122.080706, color = "#5e72e4", zoomLevel = 12;
      if (this.model.developer_id == 1) {
        lat = 1.350037;
        lng = 103.809228;
        zoomLevel = 12;
      } else if (this.model.developer_id == 2) {
        lat = -2.381462;
        lng = 107.231926;
        zoomLevel = 5;
      }


      map = document.getElementById('map-custom');


      let myLatlng = new google.maps.LatLng(-2.842371, 122.080706);
      let mapOptions = {
        zoom: 15,
        center: myLatlng,
        mapTypeId: google.maps.MapTypeId.SATELLITE,
        mapTypeControl: true,
        rotateControl: true,
        styles: [
          {
            "elementType": "geometry",
            "stylers": [
              {
                "color": "#242f3e"
              }
            ]
          },
          {
            "elementType": "labels.text.fill",
            "stylers": [
              {
                "color": "#746855"
              }
            ]
          },
          {
            "elementType": "labels.text.stroke",
            "stylers": [
              {
                "color": "#242f3e"
              }
            ]
          },
          {
            "featureType": "administrative.locality",
            "elementType": "labels.text.fill",
            "stylers": [
              {
                "color": "#d59563"
              }
            ]
          },
          {
            "featureType": "poi",
            "elementType": "labels.text.fill",
            "stylers": [
              {
                "color": "#d59563"
              }
            ]
          },
          {
            "featureType": "poi.park",
            "elementType": "geometry",
            "stylers": [
              {
                "color": "#263c3f"
              }
            ]
          },
          {
            "featureType": "poi.park",
            "elementType": "labels.text.fill",
            "stylers": [
              {
                "color": "#6b9a76"
              }
            ]
          },
          {
            "featureType": "road",
            "elementType": "geometry",
            "stylers": [
              {
                "color": "#38414e"
              }
            ]
          },
          {
            "featureType": "road",
            "elementType": "geometry.stroke",
            "stylers": [
              {
                "color": "#212a37"
              }
            ]
          },
          {
            "featureType": "road",
            "elementType": "labels.text.fill",
            "stylers": [
              {
                "color": "#9ca5b3"
              }
            ]
          },
          {
            "featureType": "road.highway",
            "elementType": "geometry",
            "stylers": [
              {
                "color": "#746855"
              }
            ]
          },
          {
            "featureType": "road.highway",
            "elementType": "geometry.stroke",
            "stylers": [
              {
                "color": "#1f2835"
              }
            ]
          },
          {
            "featureType": "road.highway",
            "elementType": "labels.text.fill",
            "stylers": [
              {
                "color": "#f3d19c"
              }
            ]
          },
          {
            "featureType": "transit",
            "elementType": "geometry",
            "stylers": [
              {
                "color": "#2f3948"
              }
            ]
          },
          {
            "featureType": "transit.station",
            "elementType": "labels.text.fill",
            "stylers": [
              {
                "color": "#d59563"
              }
            ]
          },
          {
            "featureType": "water",
            "elementType": "geometry",
            "stylers": [
              {
                "color": "#17263c"
              }
            ]
          },
          {
            "featureType": "water",
            "elementType": "labels.text.fill",
            "stylers": [
              {
                "color": "#515c6d"
              }
            ]
          },
          {
            "featureType": "water",
            "elementType": "labels.text.stroke",
            "stylers": [
              {
                "color": "#17263c"
              }
            ]
          }
        ],
        disableDefaultUI: true,
        scrollwheel: true,
        zoomControl: false,
        fullscreenControl: false,
        keyboardShortcuts: false,
        // mapId: "SEMBCORP_MAP_DASHBOARD",
      }

      map = new google.maps.Map(map, mapOptions);
      this.sesnamap = map;

      this.drawSKPT();

      this.drawSolarLayout();

      this.drawSubStation();

      this.drawProposedTLRoute();

      this.drawContourLine();

    },



    drawProposedTLRoute() {
      const TLPolyline = new google.maps.Polyline({
        path: this.proposeTLRoute[0].coord,
        strokeColor: '#FFDE59',
        strokeOpacity: 0.8,
        strokeWeight: 5,
        zIndex: 1012,
      });
      TLPolyline.setMap(this.sesnamap);
      this.TLRoute.push(TLPolyline);
    },

    drawSubStation() {
      const cpletedIcon = 'img/theme/general/pin-progress.png';
      const position = { lat: -2.8446539, lng: 122.0805891 };
      let markerIcon = cpletedIcon;

      const marker = new google.maps.Marker({
        position: position,
        title: "PV Substation",
        icon: markerIcon,
        label: {
          text: 'PV Substation',
          color: 'white',
          fontSize: '14px',
          fontWeight: 'bold',
          className: 'custom-marker-label',
          zIndex: 1010,
        },
      });


      marker.setMap(this.sesnamap);
      this.substationMark.push(marker);

      const IMIPposition = { lat: -2.8430658, lng: 122.1523283 };
      let IMIPmarkerIcon = cpletedIcon;
      const IMIPmarker = new google.maps.Marker({
        position: IMIPposition,
        title: "IMIP Substation",
        icon: IMIPmarkerIcon,
        label: {
          text: 'IMIP Substation',
          color: 'white',
          fontSize: '14px',
          fontWeight: 'bold',
          className: 'custom-marker-label',
          zIndex: 1011,
        }
      });
      IMIPmarker.setMap(this.sesnamap);
      this.substationMark.push(IMIPmarker);
    },

    removeContourLine() {
      for (let i = 0; i < this.contourPoligon.length; i++) {
        this.contourPoligon[i].setMap(null);  // Removes the polygon from the map
      }
      this.contourPoligon = [];  // Clear the polygons array
    },

    drawSKPT() {
      let j = 500;
      const infowindow = new google.maps.InfoWindow({
        maxWidth: 500,
      });

      //_ somehow if using this.sesnamap, infoWIndow not working, don't know why
      let map = this.sesnamap;

      this.deedsData.forEach(item => {
        this.infoWindow = new google.maps.InfoWindow();
        for (let i = 0; i < item.NEcoord.length; i++) {
          //console.log("NE Coordinate");            
          //console.log(item.NEcoord[i].N);
          //console.log(item.NEcoord[i].E);   

          const [longitude, latitude] = proj4(this.utm51M, this.wgs84, [item.NEcoord[i].E, item.NEcoord[i].N]);

          item.coord.push({ lat: latitude, lng: longitude });

          //console.log("Coordinate");
          //console.log(item.coord[i].lat + "," + item.coord[i].lng);
        };

        const deedCoords = item.coord;

        let areaColor = (item.isValid) ? '#04d627' : '#FF0000';
        let areaVerified = areaColor;
        //let areaVerified = (item.isVerified) ? '#002fff' : areaColor;


        j = j++;
        const deedArea = new google.maps.Polygon({
          paths: deedCoords,
          strokeColor: areaVerified,
          strokeOpacity: 0.5,
          strokeWeight: 3,
          fillColor: areaColor,
          fillOpacity: 0.5,
          zIndex: j,
        });

        const tempBounds = new google.maps.LatLngBounds();

        for (let j = 0; j < deedCoords.length; j++) {
          const x = {
            lat: deedCoords[j].lat,
            lng: deedCoords[j].lng,
          };
          const BoundLatLng = new google.maps.LatLng({
            lat: parseFloat(x.lat),
            lng: parseFloat(x.lng),
          });
          tempBounds.extend(BoundLatLng);
        }
        const centroid = tempBounds.getCenter();
        let contentString = 
          "<div style='color: #fff'>" +
          " <b><span class='cert_info'> name: " + item.owner + "</span></b>" +
          " <br><span class='cert_info'> cert: " + item.cert_no + "</span>" +
          "</div>" +
          `<div>
              <a href="#" onclick="window.vm.handleClick(event,'`+ item.cert_no + `')">SKPT Document</a>
          </div>
          `;

        let label = new google.maps.InfoWindow({
          content: contentString,
          position: this.getPolygonCenter(deedCoords),
        });

        if (this.display_owner == true) {
          label.open(this.sesnamap);
        }
        else {
          label.close();
        }

        var area = google.maps.geometry.spherical.computeArea(deedArea.getPath());

        //_ so it's simple to debug and track which is which
        google.maps.event.addListener(deedArea, 'click', function (event) {
          // alert(item.cert_no + "-" + item.owner);
          // console.log(item.owner);

          if (this.lastInfoWindow != null) this.lastInfoWindow.close();

          // console.log(item.cert_no + "-" + item.owner + ', area = ' + area);
          // console.log(item.cert_no + "-" + item.owner);

          var latitude = event.latLng.lat();
          var longitude = event.latLng.lng();
          // console.log('coordinate clicked: ' + latitude + ', ' + longitude);

          // infowindow.open(this.sesnamap);
          // " <br><span class='cert_info'> area: " + area + "</span>" +

          let contentString = 
            "<div style='color: #fff'>" +
            " <b><span class='cert_info'> name: " + item.owner + "</span></b>" +
            " <br><span class='cert_info'> cert: " + item.cert_no + "</span>" +
            "</div>" +            
            `<div>
              <a href="#" onclick="window.vm.handleClick(event,'`+ item.cert_no + `')">SKPT Document</a>
            </div>
            `;
          infowindow.setContent(contentString);
          infowindow.setPosition(event.latLng);
          infowindow.open(map);


          this.lastInfoWindow = infowindow;
        });

        //this.polygon_info = item.cert_no + "-" + item.owner;
        //deedArea.addListener('mouseover', this.showInfoWindow);
        //deedArea.addListener('mouseout', this.hideInfoWindow);

        //_  mouse hovers over the polygon.
        google.maps.event.addListener(deedArea, 'mouseover', function (event) {
          // Within the event listener, "this" refers to the polygon which
          // received the event.
          this.setOptions({
            strokeColor: '#f7f7f7',
            fillColor: '#f7f7f7'
          });
        });

        //_ mouse stops hovering over the polygon.
        google.maps.event.addListener(deedArea, 'mouseout', function (event) {
          this.setOptions({
            strokeColor: areaVerified,
            fillColor: areaColor
          });
        });

        // markerLabel.setMap(map);
        deedArea.setMap(this.sesnamap);
        this.deedsPoligon.push({ deed: deedArea, owner: label });
      });
    },

    getPolygonCenter(path) {
      let bounds = new google.maps.LatLngBounds();
      path.forEach(point => {
        bounds.extend(point);
      });
      return bounds.getCenter();
    },

    drawContourLine() {
      let k = 0;
      this.contourData.forEach(item => {
        const contourCoords = item.coord;


        k = k++;

        const contourArea = new google.maps.Polyline({
          path: contourCoords,
          strokeColor: '#bcbcbc',
          strokeOpacity: 1,
          strokeWeight: 2,
          zIndex: k,
        });
        contourArea.setMap(this.sesnamap);
        this.contourPoligon.push(contourArea);
      });
    },

    toggleSKPT(newvalue) {

      if (newvalue == true) {
        console.log('Turn on SKPT');
        this.drawSKPT();

      }
      else {
        console.log('Turn off SKPT');
        this.removeSKPT();
      }

    },

    toggleOwner(newvalue) {

      if (newvalue == true) {
        if (this.display_SKPT == true) {
          this.showOwner();
          console.log('Turn on owner');
        }
        else this.display_owner = false;
      }
      else {
        if (this.display_SKPT == true) {
          this.removeOwner();
          console.log('Turn off owner');
        }
      }

    },


    toggleContour(newvalue) {

      if (newvalue == true) {
        console.log('Turn on Contour');
        this.drawContourLine();

      }
      else {
        console.log('Turn off Contour');
        this.removeContourLine();
      }

    },

    togglesolarLayout(newvalue) {

      if (newvalue == true) {
        console.log('Turn on solar layout');
        this.drawSolarLayout();

      }
      else {
        console.log('Turn off solar layout');
        this.removeSolarLayout();
      }

    },

    toggleSubStation(newvalue) {
      if (newvalue == true) {
        console.log('Turn on sub station');
        this.drawSubStation();

      }
      else {
        console.log('Turn off sub station');
        this.removeSubStation();
      }
    },

    toggleTLRoute(newvalue) {
      if (newvalue == true) {
        console.log('Turn on TL Route');
        this.drawProposedTLRoute();

      }
      else {
        console.log('Turn off TL Route');
        this.removeTLRoute();
      }
    },

    removeSKPT() {
      for (let i = 0; i < this.deedsPoligon.length; i++) {
        this.deedsPoligon[i].deed.setMap(null);
        this.deedsPoligon[i].owner.close();
        // Removes the polygon from the map
      }
      this.deedsPoligon = [];  // Clear the polygons array
    },

    removeOwner() {
      for (let i = 0; i < this.deedsPoligon.length; i++) {
        this.deedsPoligon[i].owner.close();
      }
    },

    showOwner() {
      for (let i = 0; i < this.deedsPoligon.length; i++) {
        this.deedsPoligon[i].owner.open(this.sesnamap);
      }
    },

    removeTLRoute() {
      for (let i = 0; i < this.TLRoute.length; i++) {
        this.TLRoute[i].setMap(null);  // Removes the polygon from the map
      }
      this.TLRoute = [];  // Clear the polygons array
    },

    removeSolarLayout() {
      for (let i = 0; i < this.solarlayoutPoligon.length; i++) {
        this.solarlayoutPoligon[i].setMap(null);  // Removes the polygon from the map
      }
      this.solarlayoutPoligon = [];  // Clear the polygons array
    },

    removeSubStation() {
      for (let i = 0; i < this.substationMark.length; i++) {
        this.substationMark[i].setMap(null);
      }
      this.substationMark = [];

    },

    drawSolarLayout() {
      const solarLayoutCoords = this.solarLayoutData[0].coord;

      let solarLayoutareaColor = '#38b6ff';
      let solarLayoutareaVerified = '#004aad';

      const solarLayoutArea = new google.maps.Polygon({
        paths: solarLayoutCoords,
        strokeColor: solarLayoutareaVerified,
        strokeOpacity: 0.9,
        strokeWeight: 2,
        fillColor: solarLayoutareaColor,
        fillOpacity: 0.5,
        zIndex: 3000,
      });

      /*
      //_ click on map, displaying the lat and lon
      google.maps.event.addListener(solarLayoutArea, 'click', function (event) {
          // alert(item.cert_no + "-" + item.owner);
          // console.log(item.cert_no + "-" + item.owner);

          var latitude = event.latLng.lat();
          var longitude = event.latLng.lng();
          console.log(latitude + ', ' + longitude);
      });
      */

      solarLayoutArea.setMap(this.sesnamap);
      this.solarlayoutPoligon.push(solarLayoutArea);
    },

    showInfoWindow(event) {
      this.infoWindow.setPosition(event.latLng);
      this.infoWindow.setContent(`<div id="infoWindowContent" style="color:white">${this.polygon_info}</div>`);
      this.infoWindow.open(this.sesnamap);
      this.hideInfoWindowCloseButton();
    },

    hideInfoWindow() {
      this.infoWindow.close();
    },

    reloadMap() {
      this.loadGoogleMap();
    },

    hideInfoWindowCloseButton() {
      // Wait for the InfoWindow to be attached to the DOM
      this.$nextTick(() => {
        const closeBtn = document.querySelector('.gm-ui-hover-effect');
        if (closeBtn) {
          closeBtn.style.display = 'none';
        }
      });
    },
    removeFile(index) {
      this.files.splice(index, 1)
    },
    loadSetting() {
      axios.get(`doc_repo/p/${this.pid}/s/${this.sid}/setting`)
        .then(response => {
          this.setting = response.data.data
        })
        .catch(error => {
          this.errorFunction(error, this.title)
        })
    },

    loadCategories() {
      axios.get(`doc_repo/p/${this.pid}/s/${this.sid}/category`)
        .then(response => {
          const categories = response.data.data
          this.categories1 = categories.filter(x => x.level == 1)
          this.categories2 = categories.filter(x => x.level == 2)
          this.categories3 = categories.filter(x => x.level == 3)

          //_ add filter -- ALL --
          this.categories1 = this.setupAllInsideCategories(this.categories1);
          this.categories2 = this.setupAllInsideCategories(this.categories2);
          this.categories3 = this.setupAllInsideCategories(this.categories3);
        })
        .catch(error => {
          this.errorFunction(error, this.title)
        })
    },

    loadDocuments() {
      const options = {
        params: {
          category1_id: this.selectedCategory1,
          category2_id: this.selectedCategory2,
          category3_id: this.selectedCategory3,
        },
      }

      axios.get(`doc_repo/p/${this.pid}/s/${this.sid}/document`, options)
        .then(response => {
          this.documents = response.data.data
        })
        .catch(error => {
          this.errorFunction(error, this.title)
        })
    },

    loadDocument() {
      axios.get(`doc_repo/p/${this.pid}/s/${this.sid}/document/${this.id}`)
        .then(response => {
          this.document = response.data.data
        })
        .catch(error => {
          this.errorFunction(error, this.title)
        })
    },

    showCompleteModal(docID, docStatus) {
      this.$refs['complete-pasif-modal'].show();
      this.id = docID;
      this.loadDocument();
      this.loadRevisions();
      console.log("CURRENT REVISION");
      console.log(this.revision);
      //this.review = this.revision.reviews[0];
      console.log("CURRENT REVIEW");
      console.log(this.review);
      console.log("REVIEW DETAIL");
      console.log(this.review_detail);
      this.files = [];
    },

    confirmDialog(message, kind, callback) {
      console.log("1");
      this.confirmDialogMessage = message
      console.log("2");
      this.confirmDialogKind = kind
      console.log("3");
      this.confirmDialogCallback = callback
      console.log("4");
      this.confirmDialogVisible = true
      console.log("5");
    },

    hideConfirmDialog() {
      this.confirmDialogVisible = false;
    },

    confirmDialogAccept() {
      if (typeof this.confirmDialogCallback === 'function') {
        this.confirmDialogCallback(() => this.hideConfirmDialog())
      }
    },

    loadRevisions() {
      axios.get(`doc_repo/p/${this.pid}/s/${this.sid}/document/${this.id}/revision`)
        .then(response => {

          const revisions = response.data.data;
          //console.log("BEFORE");
          //console.log(revisions);
          // Group reviews into revisions.
          const groups = revisions
            .filter(x => x.version == 0)
            .sort((a, b) => a.revision - b.revision)

          for (const group of groups) {
            group.isNew = group.status == 'NEW_REVISION'
            group.isEditing = false

            // Reviews.
            group.reviews = revisions
              .filter(x => x.revision == group.revision && x.version > 0)
              .map(review => {
                review.isNew = review.status == 'NEW_REVIEW'
                review.isEditing = false

                return review
              })
              .sort((a, b) => a.version - b.version)
          }

          this.revisions = groups;
          //console.log("AFTER");
          //console.log(this.revisions);
          this.revision = this.revisions.at(-1);
          console.log("REVISION");
          console.log(this.revision);
          console.log("UPLOADED FILES");
          this.uploadedFiles = this.revision.uploads;
          console.log(this.uploadFiles);
          this.revision_comment = this.revision.comment;
          this.revision_comment = this.revision.comment;
          if (this.revision.reviews != null) {
            this.review = this.revision.reviews[0];
            this.review_uploadedFiles = this.review.uploads;
            this.review_comment = this.review.comment;
            this.review_detail = this.revision.status + " by " + this.review.created_by.name + " on " + this.review.created_at;
          }
          else this.review = null;
          console.log("current review");
          console.log(this.review);

        })
        .catch(error => {
          this.errorFunction(error, this.title)
        })
    },

    formatSize(size) {
      return formatBytesInUnits(size)
    },


    showFileDialog() {
      //document.getElementById("file_upload").click();
      this.$refs.file_upload.click();
    },

    currentFileChanged(event) {
      const input = event.target
      this.files.push(...input.files)
      input.value = ''
    },

    showModal(docID, docStatus) {
      this.$refs['contractor-modal'].show()
      this.id = docID;
      this.loadDocument();
      if (docStatus == "EMPTY") {
        this.createRevision();
      }
      else this.loadRevisions();
      this.files = [];

    },

    showModalAfterComplete() {
      this.hideCompleteModal();
      this.$refs['contractor-modal'].show()
      this.loadDocument();
      this.createRevision();
      this.loadRevisions();
      this.files = [];
    },

    showPasifModal(docID, docStatus) {
      this.$refs['contractor-pasif-modal'].show()
      this.id = docID;
      this.loadDocument();
      this.loadRevisions();
    },

    hideModal() {
      this.$refs['contractor-modal'].hide()
      this.reload();
    },

    hidePasifModal() {
      this.$refs['contractor-pasif-modal'].hide()
      this.reload();
    },

    hideCompleteModal() {
      this.$refs['complete-pasif-modal'].hide()
      this.reload();
    },

    reload() {
      this.loadDocuments();
      //this.loadRevisions();
    },

    generateData() {
      this.all_data = [];
      var status = ["Approve", "Pending", "New", "Rejected", "Being Reviewed", "Empty"];
      for (let index = 1; index <= this.rows; index++) {
        var status_num = 0;
        if (index % 2 == 0) {
          status_num = 1;
        }
        var name = "Test " + index.toString()
        this.all_data.push({
          id: index,
          cat_a: "Eng",
          cat_b: "DR",
          cat_c: "DR",
          number: "XXX-00-ENG-DR-0001",
          title: name,
          alt_title: "UI/UX",
          status: status[Math.floor(Math.random() * 5)],
          created_at: "24 Juni 2024"
        })
      }
    },
    changePerpage(perPage = 5) {
      this.perPage = perPage;
      console.log(this.currentPage, this.perPage)

    },
    getPagination() {
      this.items = [];

      let from = 0
      if (this.currentPage == 1) {
        from = (this.currentPage - 1)
      } else {
        from = (this.currentPage - 1) * this.perPage;
      }
      this.items = this.all_data.slice(from, this.perPage * this.currentPage)
      // console.log(this.currentPage, this.perPage)
    },
    getDashboardData() {
      this.sid = 7377;
      axios
        .get(apiDomain + "project/project_site/" + this.sid, {
          headers: getHeader(),
        })
        .then((response) => {
          if (response.status === 200) {

            this.siteListingData = response.data.data[0];
            //console.log(this.siteListingData);
            this.access = getAccessByModule('DOC_REPO_REVIEW');

            this.pid = 708;
            this.loadSetting();
            this.loadCategories();
            this.loadDocuments();

          }
        })
        .catch((error) => {
          console.log(error.response.status);
          if (error.response.status == 401) {
            this.$router.push("/login");
          }
        });
    },

    createRevision() {
      axios.post(`doc_repo/p/${this.pid}/s/${this.sid}/document/${this.id}/revision`, {})
        .then(response => {
          this.loadRevisions();
        })
        .catch(error => {
          this.errorFunction(error, this.title)
        })
    },

    updateReview() {

      const body = {
        comment: this.revision_comment,
      }

      axios.put(`doc_repo/p/${this.pid}/s/${this.sid}/document/${this.id}/revision/${this.revision.id}`, body)
        .then(response => {
          //this.loadRevisions()

          this.$notify({
            message: 'Review Updated',
            timeout: 5000,
            icon: 'ni ni-bell-55',
            type: 'default',
          });

          this.submitForReview();
        })
        .catch(error => {
          this.errorFunction(error, this.title)
        })

        .finally(() => {
          // Optionally, execute code that should run regardless of success or failure
          console.log('Request completed.');
        });

    },

    submitForReview() {

      console.log(this.revision_comment);
      const body = {
        comment: "Test comment here",
      }

      axios.put(`doc_repo/p/${this.pid}/s/${this.sid}/document/${this.id}/revision/${this.revision.id}/submit`, body)
        .then(response => {
          this.reload()

          this.$notify({
            message: 'Revision Submitted',
            timeout: 5000,
            icon: 'ni ni-bell-55',
            type: 'default',
          });
        })
        .catch(error => {
          this.errorFunction(error, this.title)
        })
      this.hideModal();
    },


    downloadFile(id) {
      axios.post(`doc_repo/token?value=${id}`)
        .then(response => {
          const token = response.data
          const url = `${apiDomain}doc_repo/download/${id}?token=${token}`

          window.open(url, '_blank')
        })
        .catch(error => {
          this.errorFunction(error, this.title)
        })
    },

    uploadFiles() {
      const data = new FormData();
      console.log("List of files");
      console.log(this.files);
      this.files.forEach(file => data.append('files[]', file))
      console.log("List of data");
      console.log(data);
      axios.post(`doc_repo/p/${this.pid}/s/${this.sid}/document/${this.id}/revision/${this.revision.id}/upload`, data)
        .then(response => {
          this.files.splice(0)
          this.loadRevisions()

          this.$notify({
            message: 'Files Uploaded',
            timeout: 5000,
            icon: 'ni ni-bell-55',
            type: 'default',
          });

          this.updateReview();

        })
        .catch(error => {
          this.errorFunction(error, this.title)
        })

        .finally(() => {
          // Optionally, execute code that should run regardless of success or failure
          console.log('Request completed.');
        });
    },

    //_ custom methods
    //  > de-structure the array and insert at the beginning an -- ALL -- option
    setupAllInsideCategories(theCategory) {
      return [
        {
          id: null,
          code: "-- ALL --",
          name: "-- ALL --"
        },
        ...theCategory
      ];
    },
    customFormatNumber(theData) {
      let retVal = parseFloat(theData.replace(/,/g, ""));

      return retVal;
    },
    customFormatDate(theData) {
      let checkDate = theData !== null ? theData : "N/A";
      if (checkDate !== "N/A") {
        let convertCheckDate = new Date(checkDate);
        const year = convertCheckDate.getFullYear();
        const month = convertCheckDate.toLocaleString(
          "en-US",
          { month: "short" }
        );
        const day = convertCheckDate.toLocaleString("en-US", {
          day: "2-digit",
        });

        return `${day}-${month}-${year}`;
      }
    },


    //_ method that we use to calculate how many data based on the observation type that is given.
  },
  mounted() {
    this.loadGoogleMap();
  },

  watch: {
    display_SKPT(value) {
      this.toggleSKPT(value);
    },

    display_solarlayout(value) {
      this.togglesolarLayout(value);
    },

    display_substation(value) {
      this.toggleSubStation(value);
    },

    display_TLRoute(value) {
      this.toggleTLRoute(value);
    },

    display_contour(value) {
      this.toggleContour(value);
    },

    display_owner(value) {
      this.toggleOwner(value);
    },

    searchValue(value) {
      this.updateSearchValue(value);
    },

  }
};
</script>


<style scoped>
.card {
  background-color: #082a2f;
}
</style>

<style scoped>
.custom-marker-label {
  transform: translateY(22px);
  /* Move label below the marker */
}

.icon-dashboard-cs {
  position: relative;
  height: 18px;
  width: 18px;
  /* margin: 4px; */
  margin-top: 5px;
  margin-left: 5px;
  display: inline-block;
  background-image: url('data:image/svg+xml;utf8,<svg  viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0 9C0 9.5523 0.44772 10 1 10H7C7.5523 10 8 9.5523 8 9V1C8 0.44772 7.5523 0 7 0H1C0.44772 0 0 0.44772 0 1V9ZM0 17C0 17.5523 0.44772 18 1 18H7C7.5523 18 8 17.5523 8 17V13C8 12.4477 7.5523 12 7 12H1C0.44772 12 0 12.4477 0 13V17ZM10 17C10 17.5523 10.4477 18 11 18H17C17.5523 18 18 17.5523 18 17V9C18 8.4477 17.5523 8 17 8H11C10.4477 8 10 8.4477 10 9V17ZM11 0C10.4477 0 10 0.44772 10 1V5C10 5.55228 10.4477 6 11 6H17C17.5523 6 18 5.55228 18 5V1C18 0.44772 17.5523 0 17 0H11Z" fill="white"/></svg>');
}

.small-button-cs {
  font-size: small;
  font-weight: 600;
  top: -4px;
  left: 7px;
  position: relative;
}

.btn-map {
  position: relative;
  text-transform: none;
  -webkit-transition: all 0.15s ease;
  transition: all 0.15s ease;
  letter-spacing: 0.025em;
  font-size: 0.875rem;
  will-change: transform;
}

.rounded-pill-map {
  border-radius: 50rem !important;
}

.gm-style .gm-style-iw-d::-webkit-scrollbar-track,
.gm-style .gm-style-iw-d::-webkit-scrollbar-track-piece,
.gm-style .gm-style-iw-c,
.gm-style .gm-style-iw-t::after {
  background: #2E575D;
  opacity: 0.9;
}

.gm-style .gm-style-iw-tc::after {
  background: #2E575D;
  opacity: 0.9;
}

.cert_info {
  color: white;
}

#__BVID__39___BV_modal_content_ {
  background-color: #306b74;
}
</style>

<style scoped>
/* Class For Modal */
/* .modal-content {
  background-color: #306b74;

} */

.table thead th {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  font-size: 0.65rem;
  text-transform: uppercase;
  letter-spacing: 1px;
  background-color: #3a656b;
  color: white;
  padding: 20px;
  border-bottom: 0px solid #082A2F;
  border-top: 0px solid #e9ecef;
}

.th-table-new {
  padding-top: 35px;
  padding-bottom: 35px;
  padding-left: 20px;
}

.tr-td-table-new {
  padding-top: 30px;
  padding-bottom: 30px;
  padding-left: 20px;
}

.el-table th.el-table__cell {

  overflow: hidden;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: #23454A;
  color: white;
  padding: 20px;
  border-top: 0px solid #082A2F;
  border-bottom: 0px solid #082A2F;
  /* border-bottom: 20px solid #082A2F; */
}

.el-table td.el-table__cell,
.el-table th.el-table__cell.is-leaf {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  border-bottom: 20px solid #082A2F;
  border-top: 0px solid #082A2F;
}

div.el-table .el-table__row {
  background: #3a656b;
  border-bottom: 0 solid #082A2F;


}

div.el-table .el-table__row:hover {
  background: #3a656b;
}

.el-table--border::after,
.el-table--group::after,
.el-table::before {
  content: '';
  position: absolute;
  background-color: #ebeef500;
  z-index: 1;
}

.form-control {
  background-color: #2C545A;
  border-color: #2C545A;
  color: white;

  &:focus {
    background-color: #2C545A;
    border-color: #2C545A;
    /* color: white; */
  }

  &::placeholder {
    color: white;
  }
}

.form-control {
  &:focus {
    &::placeholder {
      color: white;
    }
  }
}

.input-group-text {
  background-color: #2C545A;
  ;
  color: white;

  &:focus {
    background-color: #2C545A;
    ;
    border-color: #1D4147;
    color: white;
  }
}

.focused {
  .input-group-text {
    background-color: #2C545A;
  }
}

.navbar-search-dark .input-group {
  background-color: #23454A;
  color: black;
  border-color: rgba(255, 255, 255, 0.6);
}

.navbar-search .input-group {
  border-radius: 7px;
}

select {
  background: url("data:image/svg+xml,<svg width='10' height='7' viewBox='0 0 10 7' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M5.02552 6.35057L0.320068 0.46875H9.73098L5.02552 6.35057Z' fill='white'/></svg>") no-repeat;
  background-position: calc(100% - 0.75rem) center !important;
  -moz-appearance: none !important;
  -webkit-appearance: none !important;
  appearance: none !important;
  padding-right: 2rem !important;
}
</style>
