var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('base-header',{staticClass:"pb-6"},[_c('b-row',{staticClass:"py-4"},[_c('b-col',{attrs:{"cols":"6"}},[_c('h6',{staticClass:"h2 text-white d-inline-block mb-0"},[_vm._v(_vm._s(_vm.title))])])],1)],1),_c('b-container',{staticClass:"mt--6",attrs:{"fluid":""}},[_c('div',{staticClass:"card-wrapper"},[_c('card',[_c('h3',{staticClass:"mb-0",attrs:{"slot":"header"},slot:"header"},[_vm._v("Approver Settings")]),_c('validation-observer',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{staticClass:"needs-validation",on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.saveData)}}},[_c('div',{staticClass:"table-responsive"},[_c('table',{staticClass:"table align-items-center mb-0 text-dark"},[_c('thead',[_c('td',{staticClass:"text-dark"},[_vm._v("Level")]),_c('td',{staticClass:"text-dark"},[_vm._v("Approver")]),_c('td',{staticClass:"text-dark"},[_c('base-button',{attrs:{"type":"primary"},on:{"click":_vm.addMoreItem}},[_vm._v(" Add More ")])],1)]),_c('tbody',_vm._l((_vm.approvers),function(item,index){return _c('tr',{key:index,staticClass:"align-items-center"},[_c('td',[_c('validation-provider',{attrs:{"name":"Level","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Level","invalid-feedback":errors[0]}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(item.level),expression:"item.level"}],staticClass:"form-control",class:classes,attrs:{"type":"text","maxlength":"100"},domProps:{"value":(item.level)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(item, "level", $event.target.value)}}})])]}}],null,true)})],1),_c('td',[_c('validation-provider',{attrs:{"name":"Approver Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Approver Name","invalid-feedback":errors[0]}},[_c('select',{directives:[{name:"model",rawName:"v-model",value:(item.user_id),expression:"item.user_id"}],staticClass:"form-control",on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(item, "user_id", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},_vm._l((_vm.approverOptions),function(item){return _c('option',{key:item.id,domProps:{"value":item.id}},[_vm._v(_vm._s(item.name)+" - "+_vm._s(item.email))])}),0)])]}}],null,true)})],1),_c('td',[_c('b-form-group',[_c('br'),_c('base-button',{attrs:{"size":"sm","type":"danger"},on:{"click":function($event){return _vm.deleteItems(index)}}},[_vm._v("Remove")])],1)],1)])}),0)])]),_c('br'),(_vm.access.create && _vm.mode == 'new')?_c('button',{staticClass:"btn btn-primary"},[_vm._v("Create Approver")]):_vm._e(),(_vm.access.create && _vm.mode == 'edit')?_c('button',{staticClass:"btn btn-primary"},[_vm._v("Update Approver")]):_vm._e()])]}}])})],1)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }