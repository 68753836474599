<template>
  <div class="d-block text-center">
               <div class="text-center text-white mt-3 mb-2">
                 <div>
                   <validation-observer v-slot="{ handleSubmit }">
           <form class="needs-validation" @submit.prevent="handleSubmit($emit('submitt', newForm))">
 
                       <b-row>
                         <b-col>
                           <b-row class="mb-4">
                             <b-col md="6">
                               <div class="text-white h3 text-left">{{ template.category1_name }}</div>
                               <validation-provider :name="template.category1_name" rules="required"
                                 v-slot="{ classes, errors }">
                                 <b-form-group :invalid-feedback="errors[0]">
                                   <select class="form-control" :class="classes" v-model="newForm.category1_id">
                                     <option v-for="item of categories1" :key="item.id" :value="item.id">{{ item.code }}
                                       - {{
                                         item.name }}</option>
                                   </select>
                                 </b-form-group>
                               </validation-provider>
                             </b-col>
                             <b-col md="6">
                               <div class="ml-2 text-white h3 text-left">{{ template.category2_name }}</div>
                               <validation-provider :name="template.category2_name" rules="required"
                                 v-slot="{ classes, errors }">
                                 <b-form-group :invalid-feedback="errors[0]">
                                   <select class="form-control" :class="classes" v-model="newForm.category2_id">
                                     <option v-for="item of categories2" :key="item.id" :value="item.id">{{ item.code }}
                                       - {{
                                         item.name }}</option>
                                   </select>
                                 </b-form-group>
                               </validation-provider>
                             </b-col>
                           </b-row>
                           <b-row class="mb-4">
                             <b-col md="6" v-if="template.category3_enabled">
                               <div class="ml-2 text-white h3 text-left">{{ template.category3_name }}</div>
                               <validation-provider :name="template.category3_name" rules="required"
                                 v-slot="{ classes, errors }">
                                 <b-form-group :invalid-feedback="errors[0]">
                                   <select class="form-control" :class="classes" v-model="newForm.category3_id">
                                     <option v-for="item of categories3" :key="item.id" :value="item.id">{{ item.code }}
                                       - {{
                                         item.name }}</option>
                                   </select>
                                 </b-form-group>
                               </validation-provider>
                             </b-col>
                             <b-col md="6">
                               <div class="ml-2 text-white h3 text-left">Position</div>
                               <validation-provider name="Position" rules="required" v-slot="{ classes, errors }">
                                 <b-form-group :invalid-feedback="errors[0]">
                                   <select class="form-control" :class="classes" v-model="newForm.position">
                                     <option :value="0">First</option>
                                     <option v-for="item in documents" :key="item.id" :value="item.sequence">After: {{
                                       item.title
                                     }}</option>
                                   </select>
                                 </b-form-group>
                               </validation-provider>
                             </b-col>
                           </b-row>
                           <b-row class="mb-4">
                             <b-col md="6">
                               <div class="ml-2 text-white h3 text-left">Title</div>
                               <validation-provider name="Title" rules="required" v-slot="{ classes, errors }">
                                 <b-form-group :invalid-feedback="errors[0]">
                                   <input type="text" class="form-control" :class="classes" v-model="newForm.title"
                                     maxlength="300">
                                 </b-form-group>
                               </validation-provider>
                             </b-col>
 
                             <b-col md="6">
                               <div class="ml-2 text-white h3 text-left">Alternative Title</div>
                               <validation-provider name="Alternative Title" v-slot="{ classes, errors }">
                                 <b-form-group :invalid-feedback="errors[0]">
                                   <input type="text" class="form-control"local :class="classes" v-model="newForm.alt_title"
                                     maxlength="300">
                                 </b-form-group>
                               </validation-provider>
                             </b-col>
                           </b-row>
                           <b-row class="mb-4">
                             <b-col md="12">
                               <div class="ml-2 text-white h3 text-left">Reviewer/Approver Roles</div>
                               <validation-provider name="Reviewer/Approver Roles" rules="required" v-slot="{ errors }" >
                     <b-form-group :invalid-feedback="errors[0]" >
                       <b-form-checkbox-group v-model="newForm.selectedRoles" :options="roles" stacked :state="errors[0] ? false : null" class="text-left text-white border rounded p-2" style="background-color: #2C545A;"/>
                       <b-form-invalid-feedback :state="errors[0] ? false : null">{{ errors[0] }}</b-form-invalid-feedback>
                     </b-form-group>
                   </validation-provider>
                             </b-col>
                               </b-row>
                           <!-- <b-form-row>
                         <b-col>
                           <validation-provider name="Code" rules="required" v-slot="{ classes, errors }">
                             <b-form-group label="Code" :invalid-feedback="errors[0]">
                               <input type="text" class="form-control" :class="classes" v-model="newForm.code"
                                 maxlength="20">
                             </b-form-group>
                           </validation-provider>
                         </b-col>
                       </b-form-row>
 
                       <b-form-row>
                         <b-col>
                           <validation-provider name="Name" rules="required" v-slot="{ classes, errors }">
                             <b-form-group label="Name" :invalid-feedback="errors[0]">
                               <input type="text" class="form-control" :class="classes" v-model="newForm.name"
                                 maxlength="100">
                             </b-form-group>
                           </validation-provider>
                         </b-col>
                       </b-form-row>
                       
                       <b-button variant="outline-danger" class="btn btn-primary" type="button"
                         @click="hideModal()">Cancel</b-button>
                       <button class="btn btn-primary" v-if="access.create">Create</button>-->
                           <b-button variant="outline-danger" class="btn btn-primary" type="button"
                             @click="close()">Cancel</b-button>
                           <button class="btn btn-primary" v-if="access.create">Create</button>
                         </b-col>
                       </b-row>
                     </form>
                   </validation-observer>
 
                 </div>
               </div>
             </div>
 </template>
 
 <script>
 import { Select, Option, Table, TableColumn, } from 'element-ui'
 import standardPaginationMix from '@/assets/js/standardPaginationMix.js'
 import ErrorFunctionMixin from '@/assets/js/errorFunctionMix.js'
 import { BasePagination } from '@/components';
 import axios from '@/util/axios'
 import { getAccessByModule } from '@/util/access'
 import Fuse from 'fuse.js';
 
 export default {
   // name :"Tracking Template",
   mixins: [standardPaginationMix, ErrorFunctionMixin],
   components: {
     [Select.name]: Select,
     [Option.name]: Option,
     [Table.name]: Table,
     [TableColumn.name]: TableColumn,
 
   },
   data() {
     return {
       route_items: [
         {
           text: 'Document Tracking Template',
           href: '../../../Tracking'
         },
         {
           text: 'Document',
           active: true
         }
       ],
       fields: [
         { key: 'name', label: 'Name' },
         { key: 'status', label: 'Status' },
         { key: 'created_at', label: 'Created At' },
         // { key: 'capacity', label: 'Capacity' },
         { key: 'action', label: '' },
 
       ],
       all_data: [],
       items: [],
       tableData: [],
       access: {},
       newForm: {},
       template: {},
       categories1: [],
       categories2: [],
       categories3: [],
       documents: [],
       roles: [],
       selectedRoles: [],
       tid: null,
       model: {},
       selectedCategory1: null,
       selectedCategory2: null,
       selectedCategory3: null,
 
     };
   },
   watch: {
     selectedCategory1() {
       this.loadDocuments()
     },
 
     selectedCategory2() {
       this.loadDocuments()
     },
 
     selectedCategory3() {
       this.loadDocuments()
     },
     ['model.category1_id']() {
       this.loadDocuments()
     }
   },
   created() {
     this.tid = this.$route.params.tid
     this.access = getAccessByModule('DOC_REPO')
     this.getData()
     this.loadRecord()
     this.loadCategories()
     this.loadDocuments()
     this.loadTemplate()
     this.loadRoles()
   },
 
   methods: {
 
     close() {
 
 this.$emit('close')
 this.loadDocuments()
 
 },
   
     loadRoles() {
       axios.get('lookup/role_list')
         .then(response => {
           this.roles = response.data.data.map(x => {
             return {
               value: x.id,
               text: x.name,
             }
           })
         })
         .catch(error => {
           this.errorFunction(error, this.title)
         });
     },
     loadTemplate() {
       axios.get(`setup/doc_repo/template/${this.tid}`)
         .then(response => {
           this.template = response.data.data
         })
         .catch(error => {
           this.errorFunction(error, this.title)
         });
     },
 
     loadRecord() {
       axios.get(`setup/doc_repo/template/${this.tid}`)
         .then(response => {
           this.model = response.data.data
         })
         .catch(error => {
           this.errorFunction(error, this.title)
         });
     },
 
     loadCategories() {
       axios.get(`setup/doc_repo/template/${this.tid}/category`)
         .then(response => {
           const categories = response.data.data
           this.categories1 = categories.filter(x => x.level == 1)
           this.categories2 = categories.filter(x => x.level == 2)
           this.categories3 = categories.filter(x => x.level == 3)
         })
         .catch(error => {
           this.errorFunction(error, this.title)
         });
     },
 
     loadDocuments() {
       const options = {
         params: {
           category1_id: this.selectedCategory1,
           category2_id: this.selectedCategory2,
           category3_id: this.selectedCategory3,
         },
       }
 
       axios.get(`setup/doc_repo/template/${this.tid}/document`, options)
         .then(response => {
           this.documents = response.data.data
           if (this.documents.length > 0) {
             const length = this.documents.length
             this.model.position = this.documents[length - 1].sequence
           } else {
             this.model.position = 0;
           }
         })
         .catch(error => {
           this.errorFunction(error, this.title)
         });
     },
     getData() {
       axios.get('setup/doc_repo/template')
         .then(response => {
           this.tableData = response.data.data
           this.fuseData()
         })
         .catch(error => {
           this.errorFunction(error, this.title)
         })
     },
     fuseData() {
       this.fuseSearch = new Fuse(this.tableData, {
         keys: this.propsToSearch,
         threshold: 0.3,
       });
     },
 
   },
   mounted() {
     //this.getPagination();
     //this.loadDocuments()
   },
 
 };
 </script>

 <style scoped>


/deep/ .form-control {
  background-color: #2C545A;
  border-color: #2C545A;
  color: white;

  &:focus {
    background-color: #2C545A;
    border-color: #2C545A;
    /* color: white; */
  }

  &::placeholder {
    color: white;
  }
}

/deep/ .form-control {
  &:focus {
    &::placeholder {
      color: white;
    }
  }
}
</style>