<template>
  <div class="d-block text-center">
      <div class="text-center text-black mt-3 mb-2">
        <div class="mt-1"><small>
      
          </small></div>
        <div class="mt-2">
          <!-- <button @click="$emit('updateOrder', 'pp')">Click</button>
       -->
          <validation-observer v-slot="{ handleSubmit }">
            <form class="needs-validation" @submit.prevent="handleSubmit($emit('submitt', newForm))">
  
              <b-form-row>
                <b-col>
                  <div class="ml-2 text-white h3 text-left">Code</div>
                  <validation-provider name="Code" rules="required" v-slot="{ classes, errors }">
                    <b-form-group :invalid-feedback="errors[0]">
                      <input type="text" class="form-control" :class="classes" v-model="newForm.code" maxlength="20">
                    </b-form-group>
                  </validation-provider>
                </b-col>
              </b-form-row>
  
              <b-form-row>
                <b-col>
                  <div class="ml-2 text-white h3 text-left">Name</div>
                  <validation-provider name="Name" rules="required" v-slot="{ classes, errors }">
                    <b-form-group :invalid-feedback="errors[0]">
                      <input type="text" class="form-control" :class="classes" v-model="newForm.name" maxlength="100">
                    </b-form-group>
                  </validation-provider>
                </b-col>
              </b-form-row>
              <b-button variant="outline-danger" class="btn btn-primary" type="button" @click="close()">Cancel</b-button>
              <button class="btn btn-primary" v-if="access.create">Create</button>
            </form>
          </validation-observer>
  
        </div>
      </div>
  
  
    </div>
  </template>
  
  <script>
  import ErrorFunctionMixin from '@/assets/js/errorFunctionMix.js'
  import { Table, TableColumn } from 'element-ui';
  import axios from '@/util/axios'
  import { getAccessByModule } from '@/util/access';
  
  export default {
    mixins: [ErrorFunctionMixin],
    components: {
      [Table.name]: Table,
      [TableColumn.name]: TableColumn,
  
    },
  
    data() {
      return {
        title: 'Document Tracking Templates',
        tid: null,
        route_items: [
          {
            text: 'Document Tracking Template',
            href: '#'
          },
          {
            text: 'Categories',
            active: true
          }
        ],
        model: {},
        newForm: {},
        categories1: [],
        categories2: [],
        categories3: [],
        access: {},
  
      }
    },
  
    created() {
      this.tid = this.$route.params.tid
      this.access = getAccessByModule('DOC_REPO')
      // this.newForm.level = this.$route.params.level
      this.loadRecord()
  
    },
  
    methods: {
    
      loadRecord() {
        axios.get(`setup/doc_repo/template/${this.tid}`)
          .then(response => {
            this.model = response.data.data
          })
          .catch(error => {
            this.errorFunction(error, this.title)
          });
      },
      close() {
        this.$emit("close");
        this.loadCategories();
      },
      loadCategories() {
        axios.get(`setup/doc_repo/template/${this.tid}/category`)
          .then(response => {
            const categories = response.data.data
            this.categories1 = categories.filter(x => x.level == 1)
            this.categories2 = categories.filter(x => x.level == 2)
            this.categories3 = categories.filter(x => x.level == 3)
          })
          .catch(error => {
            this.errorFunction(error, this.title)
          });
      },
  
    },
  };
  </script>
  <style>
  /* .modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: lightseagreen;
  } */
  
  .modal-inner {
    background-color: white;
    border-radius: 0.5em;
    padding: 2em;
    margin: auto;
  }
  </style>
  <style scoped>
  /* Class For Modal */

  .form-control {
  background-color: #2C545A;
  border-color: #2C545A;
  color: white;
}
  .modal-content {
    background-color: #306b74;
  
  }
  
  .breadcrumb {
    background-color: transparent;
  }
  
  .custom-control-label-tmp {
    margin-bottom: 0;
  }
  
  .custom-control-label-tmp {
    position: relative;
    margin-bottom: 0;
    vertical-align: top;
  }
  
  .modal-content {
    background-color: #306b74;
  
  }
  
  label {
    display: inline-block;
    margin-bottom: 0.5rem;
  }
  
  table thead th {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    font-size: 0.65rem;
    text-transform: uppercase;
    letter-spacing: 1px;
    background-color: #23454A;
    color: white;
    padding: 20px;
    border-bottom: 0px solid #082A2F;
    border-top: 0px solid #e9ecef;
  }
  
  .th-table-new {
    padding-top: 35px;
    padding-bottom: 35px;
    padding-left: 20px;
  }
  
  .tr-td-table-new {
    padding-top: 30px;
    padding-bottom: 30px;
    padding-left: 20px;
  }
  

  
    &:focus {
      background-color: #2C545A;
      border-color: #2C545A;
      color: white;
    }
  
    &::placeholder {
      color: white;
    }
  
  .input-group-text {
    background-color: #2C545A;
    ;
    color: white;
  
    &:focus {
      background-color: #2C545A;
      ;
      border-color: #1D4147;
      color: white;
    }
  }
  
  .focused {
    .input-group-text {
      background-color: #2C545A;
    }
  }
  
  .navbar-search-dark .input-group {
    background-color: #23454A;
    color: black;
    border-color: rgba(255, 255, 255, 0.6);
  }
  
  .navbar-search .input-group {
    border-radius: 7px;
  }
  
  .el-input__inner,
  .el-select-dropdown__item.is-disabled:hover {
    background-color: #23454A;
  }
  
  .el-table th.el-table__cell {
  
    overflow: hidden;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-color: #23454A;
    color: white;
    padding: 20px;
    border-top: 0px solid #082A2F;
    border-bottom: 0px solid #082A2F;
  }
  
  .el-table td.el-table__cell,
  .el-table th.el-table__cell.is-leaf {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    border-bottom: 20px solid #082A2F;
    border-top: 0px solid #082A2F;
  }
  
  div.el-table .el-table__row {
    background: #3a656b;
    border-bottom: 0 solid #082A2F;
  
  
  }
  
  div.el-table .el-table__row:hover {
    background: #3a656b;
  }
  
  .el-table--border::after,
  .el-table--group::after,
  .el-table::before {
    content: '';
    position: absolute;
    background-color: #ebeef500;
    z-index: 1;
  }

  /deep/ .form-control {
  background-color: #2C545A;
  border-color: #2C545A;
  color: white;

  &:focus {
    background-color: #2C545A;
    border-color: #2C545A;
    /* color: white; */
  }

  &::placeholder {
    color: white;
  }
}

/deep/ .form-control {
  &:focus {
    &::placeholder {
      color: white;
    }
  }

}
  </style>