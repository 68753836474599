<template>
  <div style="padding-top: 0em; margin-top: 1em;">
    <div class="image-gallery">
      <div v-for='(APIentry, APIindex) in oCCTV_api'>
        <img :src="showImage(APIentry.cam1)" alt="Uploaded Image" class="uploaded-image" style="max-width: 500px; max-height: 250px; margin: 10px;"/>
        <img :src="showImage(APIentry.cam2)" alt="Uploaded Image" class="uploaded-image" style="max-width: 500px; max-height: 250px; margin: 10px;"/>
        <img :src="showImage(APIentry.cam3)" alt="Uploaded Image" class="uploaded-image" style="max-width: 500px; max-height: 250px; margin: 10px;"/>
      </div>
    </div>
  </div>

</template>

<script>
import axios from "@/util/axios";
import { apiDomain, getHeader } from "@/assets/js/config.js";

import cctv_json from './test_5_cctv.json'

export default {
  data() {
    return {
      oCCTV_json: cctv_json,
      // oCCTV_json: null,
      oCCTV_api: null,
    }
  },
  created() {
    // this.construct_chart_series();
    // this.construct_chart_option();
    // console.log(cctv_json);
  },
  beforeMount() {
    this.get_cctv_data();
    // this.get_cctv_data_v2()
    // this.get_cctv_data_v3();
    // this.get_cctv_data_v4();
    // this.get_cctv_data_v5();
    // this.get_cctv_data_v6();
    // this.get_cctv_data_v7();
    // this.get_cctv_data_v8();
  },
  methods: {
    get_cctv_data_v8() {
      fetch('https://integraconnect.sembcorp.com/system/webdev/ams/get_cams')
        .then((response) => {
          return response.json();
        })
    },
    get_cctv_data() {
      // console.log('get_cctv_data');

      var api_config = {
        method: 'GET',
        mode: 'no-cors',
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Content-Type': 'application/json',
          "Access-Control-Allow-Methods": "GET, POST, PATCH, PUT, DELETE, OPTIONS",
          "Access-Control-Allow-Headers": "Origin, Content-Type, X-Auth-Token"
        },
        // crossdomain: true
      };
      axios
        // .get( 'https://integraconnect.sembcorp.com/system/webdev/ams/get_cams', { headers: getHeader() })
        .get('https://integraconnect.sembcorp.com/system/webdev/ams/get_cams')
        .then(response => {
          if (response.status === 200) {
            // console.log(response);
            this.oCCTV_api = response.data;
          }
        })
        .catch(error => {
          console.log(error)
        })
        .finally(() => {

        });
    },
    showImage(imageData) {
      if ( imageData === null ) {
        return '/img/default/cctv_no_snapshot.png'
      } else {
        return imageData;
      }     
      
    }
  },
};
</script>
