var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"bg-default-dashboard"},[_c('div',{staticClass:" py-lg-8 pt-lg-5"},[_c('b-container',{staticStyle:{"width":"100%","position":"relative"},attrs:{"fluid":""}},[_c('b-row',[_c('b-breadcrumb',{attrs:{"items":_vm.route_items}})],1),_c('validation-observer',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{staticClass:"needs-validation",on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)}}},[_c('b-row',{staticClass:"mb-2"},[_c('b-col',[_c('h1',{staticClass:"text-white"},[_vm._v("Edit Document Template")])]),_c('b-col',{attrs:{"md":"6"}},[_c('div',{staticClass:"text-right "},[_c('label',{staticClass:"text-white mr-2 h3 custom-control-label-tmp"},[_vm._v("Active Status")]),_c('base-switch',{staticClass:"mr-2 ",attrs:{"value":true,"unchecked-value":false,"on-text":"","off-text":""},model:{value:(_vm.model.status),callback:function ($$v) {_vm.$set(_vm.model, "status", $$v)},expression:"model.status"}})],1)])],1),_c('b-row',[_c('b-col',[_c('b-row',{staticClass:"mb-4"},[_c('b-col',[_c('div',{staticClass:"text-white h3"},[_vm._v("Name")]),_c('validation-provider',{attrs:{"name":"Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"invalid-feedback":errors[0]}},[_c('b-form-input',{staticClass:"form-control",class:classes,attrs:{"placeholder":"...","type":"text","maxlength":"100"},model:{value:(_vm.model.name),callback:function ($$v) {_vm.$set(_vm.model, "name", $$v)},expression:"model.name"}})],1)]}}],null,true)})],1)],1),_c('b-row',{staticClass:"mb-4"},[_c('b-col',{attrs:{"md":"6"}},[_c('div',{staticClass:"text-white h3"},[_vm._v("Main Category Name")]),_c('validation-provider',{attrs:{"name":"Main Category Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"invalid-feedback":errors[0]}},[_c('b-form-input',{staticClass:"form-control",class:classes,attrs:{"placeholder":"...","type":"text","maxlength":"100"},model:{value:(_vm.model.category1_name),callback:function ($$v) {_vm.$set(_vm.model, "category1_name", $$v)},expression:"model.category1_name"}})],1)]}}],null,true)})],1),_c('b-col',{attrs:{"md":"6"}},[_c('div',{staticClass:"text-white h3"},[_vm._v("Sub Category Name")]),_c('validation-provider',{attrs:{"name":"Sub Category Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"invalid-feedback":errors[0]}},[_c('b-form-input',{staticClass:"form-control",class:classes,attrs:{"placeholder":"...","type":"text","maxlength":"100"},model:{value:(_vm.model.category2_name),callback:function ($$v) {_vm.$set(_vm.model, "category2_name", $$v)},expression:"model.category2_name"}})],1)]}}],null,true)})],1)],1),_c('b-row',{staticClass:"mb-2"},[_c('b-col',[_c('validation-provider',{attrs:{"name":"category3_enabled"}},[_c('b-form-checkbox',{attrs:{"value":1,"unchecked-value":0},model:{value:(_vm.model.category3_enabled),callback:function ($$v) {_vm.$set(_vm.model, "category3_enabled", $$v)},expression:"model.category3_enabled"}},[_c('span',{staticClass:"text-white"},[_vm._v("Enable Third Category Name")])])],1)],1)],1),(_vm.model.category3_enabled == 1)?_c('b-row',[_c('b-col',[_c('div',{staticClass:"text-white h3"},[_vm._v("Third Category Name")]),_c('validation-provider',{directives:[{name:"show",rawName:"v-show",value:(_vm.model.category3_enabled),expression:"model.category3_enabled"}],attrs:{"name":"Third Category Name"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"invalid-feedback":errors[0]}},[_c('b-form-input',{staticClass:"form-control",class:classes,attrs:{"placeholder":"...","type":"text","maxlength":"100"},model:{value:(_vm.model.category3_name),callback:function ($$v) {_vm.$set(_vm.model, "category3_name", $$v)},expression:"model.category3_name"}})],1)]}}],null,true)})],1)],1):_vm._e(),_c('b-row',{staticClass:"mt-4"},[_c('b-col',[_c('b-button',{staticClass:"btn btn-primary",attrs:{"variant":"outline-danger","type":"button"},on:{"click":function($event){return _vm.$router.replace('../Tracking')}}},[_vm._v("Cancel")]),(_vm.access.edit)?_c('button',{staticClass:"btn btn-primary"},[_vm._v("Update")]):_vm._e()],1),(_vm.access.delete)?_c('button',{staticClass:"btn btn-danger",attrs:{"type":"button"},on:{"click":function($event){_vm.showConfirmDelete = true}}},[_vm._v("Delete")]):_vm._e()],1)],1)],1)],1)]}}])})],1),_c('modal',{attrs:{"show":_vm.showConfirmDelete,"size":"sm","body-classes":"p-0"},on:{"update:show":function($event){_vm.showConfirmDelete=$event}}},[_c('card',{staticClass:"border-0 mb-0",attrs:{"type":"secondary","header-classes":"bg-transparent pb-5","body-classes":"px-lg-5 py-lg-5"}},[_c('div',{staticClass:"text-center text-muted mb-4"},[_c('h3',[_vm._v("Delete this Template?")])]),_c('div',{staticClass:"text-center"},[_c('button',{staticClass:"btn btn-danger",on:{"click":_vm.deleteRecord}},[_vm._v("YES")]),_c('button',{staticClass:"btn btn-dark",on:{"click":function($event){_vm.showConfirmDelete = false}}},[_vm._v("NO")])])])],1)],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }