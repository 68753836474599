<template>
  <card>
    <!-- Card header -->
    <h3 slot="header" class="mb-0">New Site Document for ({{ model.project_name }} - {{ model.group_name }} - {{ model.site_name }})</h3>
    <!-- <h3 slot="header" class="mb-0">New Site Document </h3> -->
    <div class="text-right">
    </div>
    <form class="needs-validation"
          @submit.prevent="submit">

      <div class="form-row">
        <b-col md="12">
          <base-input label="Document Title *"
                      name="Document Title"
                      required
                      placeholder="Document Title"
                      v-model="model.document_title">
          </base-input>
        </b-col>
      </div>

      <div class="form-row">
        <b-col md="5">
          <base-input label="Project *"
                      name="Project"
                      required
                      v-model="model.project_id">
            <select class="form-control" v-model="model.project_id" @change="getGroupFromProject()" disabled>
              <option Selected disabled value="x">-- Please Select A Project --</option>
              <option v-for="item in projects" :key="item.project_id" v-bind:value="item.project_id"> {{ item.project_name }}</option>
            </select>
          </base-input>
        </b-col>

        <b-col md="1">
          <br>
          <base-button size="sm" type="primary" @click="checkProject()">Project Infomation</base-button>
        </b-col>

        <b-col md="5">
          <base-input label="Group"
                      name="Group"
                      v-model="model.group_id">
            <select class="form-control" v-model="model.group_id" @change="getSiteFromGroup()" disabled>
              <option Selected disabled value="x">-- Please Select A Project To Show Group --</option>
              <option Selected disabled value="y">-- Please Select A Group --</option>
              <option v-for="item in project_groups" :key="item.group_id" v-bind:value="item.group_id">{{ item.group_name }}</option>
            </select>
          </base-input>
        </b-col>

        <b-col md="1">
          <br>
          <base-button size="sm" type="primary" @click="checkGroup()">Group Infomation</base-button>
        </b-col>
      </div>

      <div class="form-row">
        <b-col md="5">
          <base-input label="Site *"
                      name="Site"
                      required
                      v-model="model.site_id">
            <select class="form-control" v-model="model.site_id" @change="getContractorFromSite()" disabled>
              <option Selected disabled value="x">-- Please Select A Group To Show Site --</option>
              <option Selected disabled value="y">-- Please Select A Site --</option>
              <option v-for="item in project_sites" :key="item.site_id" v-bind:value="item.site_id">{{ item.site_name }}</option>
            </select>
          </base-input>
        </b-col>

        <b-col md="1">
          <br>
          <base-button size="sm" type="primary" @click="checkSite()">Site Infomation</base-button>
        </b-col>

        <b-col md="5" v-if="model.project_id != 'x'">
          <base-input label="Site Contractor"
                      name="Site Contractor"
                      v-model="model.contractor_id" >
            <select class="form-control" v-model="model.contractor_id" @change="getUserListFromProjectContractor()">
              <option Selected disabled value="x">-- Please Select A Site To Show Contrcator --</option>
              <option Selected value="y">-- Assign to Internal User --</option>
              <option v-for="item in site_contractors" :key="item.contractor.contractor_id" v-bind:value="item.contractor.contractor_id">{{ item.contractor.contractor_code }} - {{ item.contractor.contractor_name }}</option>
            </select>
          </base-input>
        </b-col>

        <b-col md="5" v-else>
          <base-input label="Site Contractor *"
                      name="Site Contractor"
                      v-model="model.contractor_id">
            <select class="form-control" v-model="model.contractor_id" @change="getUserListFromProjectContractor()" disabled>
              <option Selected disabled value="x">-- Please Select A Site To Show Contrcator --</option>
              <option Selected value="y">-- Assign to Internal User --</option>
              <option v-for="item in site_contractors" :key="item.contractor.contractor_id" v-bind:value="item.contractor.contractor_id">{{ item.contractor.contractor_code }} - {{ item.contractor.contractor_name }}</option>
            </select>
          </base-input>
        </b-col>

        <b-col md="1">
          <br>
          <base-button size="sm" type="primary" @click="checkContrator()">Contractor Infomation</base-button>
        </b-col>

        <b-col md="5" v-if="model.contractor_id == 'x' || model.contractor_id == 'y'">
          <base-input label="Assign To *"
                      name="Assign to"
                      required
                      v-model="model.assign_to_user">
            <select class="form-control" v-model="model.assign_to_user" >
              <option Selected disabled value="x">-- Please Assign The Task To --</option>
              <option v-for="item in user_list" :key="item.id" v-bind:value="item.id">{{ item.role_name }} - {{ item.name }}</option>
            </select>
          </base-input>
        </b-col>

        <b-col md="5" v-else>
          <base-input label="Assign To (with Contractor) *"
                      name="Assign to"
                      required
                      v-model="model.assign_to_user">
            <select class="form-control" v-model="model.assign_to_user" >
              <option Selected disabled value="x">-- Please Assign The Task To --</option>
              <option v-for="item in user_list" :key="item.id" v-bind:value="item.id">{{ item.role_name }} - {{ item.name }}
                <span v-if='item.contractor.contractor_name != null'>({{ item.contractor.contractor_name }})</span>
                </option>
            </select>
          </base-input>
        </b-col>

        <b-col md="1">
          <br>
          <base-button size="sm" type="primary" @click="assignToMe()">Assign To Me (Default)</base-button>
        </b-col>

        <b-col md="6">
          <base-input label="Document Milestone"
                      name="Document Milestone"
                      v-model="model.milestone_id">
            <select class="form-control" v-model="model.milestone_id">
              <option Selected disabled value="x">-- Please Select A Project To Show Milestone --</option>
              <option Selected disabled value="y">-- Please Select A Milestone --</option>
              <option v-for="item in project_milestones" :key="item.milestone_id" v-bind:value="item.milestone_id">{{ item.milestone_code }} - {{ item.milestone_info }}</option>
            </select>
          </base-input>
        </b-col>
      </div>

      <div class="form-row">
        <b-col md="6">
          <base-input label="Document Category *"
                      name="Document Category"
                      required
                      placeholder="Document Category"
                      v-model="model.document_category_id">
            <select class="form-control" v-model="model.document_category_id" @change="documentCategoryMatch()">
              <option Selected disabled value="x">-- Please Select A Document Category --</option>
              <option v-for="item in document_category" :key="item.document_category_id" v-bind:value="item.document_category_id">{{ item.document_category }}</option>
            </select>
          </base-input>
        </b-col>

        <b-col md="6">
          <base-input label="Document Type (Only ONE-TIME Type for IFI Document) *"
                      name="Document Type"
                      required
                      v-model="model.document_type_id" v-if="model.document_category_id == '2'">
            <select class="form-control" v-model="model.document_type_id" >
              <option Selected disabled value="x">-- Please Select A Document Type --</option>
              <option value="1">ONE-TIME</option>
            </select>
          </base-input>
          <base-input label="Document Type *"
                      name="Document Type"
                      required
                      v-model="model.document_type_id" v-else>
            <select class="form-control" v-model="model.document_type_id">
              <option Selected disabled value="x">-- Please Select A Document Type --</option>
              <option v-for="item in document_types" :key="item.document_type_id" v-bind:value="item.document_type_id">{{ item.document_type_code }}</option>
            </select>
          </base-input>
        </b-col>
      </div>

      <div v-if="model.document_type_id == '2'" class="form-row">
        <b-col md="4">
          <base-input label="Recurring Interval"
                      name="Recurring Interval"
                      v-model="model.recurring_interval_id">
            <select class="form-control" v-model="model.recurring_interval_id">
              <option Selected disabled value="x">-- Please Select A Recurring Interval --</option>
              <option value="1">Daily</option>
              <option value="2">Weekly</option>
              <option value="3">Month</option>
            </select>
          </base-input>
        </b-col>

        <b-col md="4">
          <base-input label="Start Date"
                      name="Start Date"
                      v-model="model.recurring_start_date"
                      type="date">
          </base-input>
        </b-col>

        <b-col md="4">
          <base-input label="End Date"
                      name="End Date"
                      v-model="model.recurring_end_date"
                      type="date">
          </base-input>
        </b-col>
      </div>

       <div class="form-row">
        <b-col md="4">
          <b-form-checkbox
            id="Mandatory"
            v-model="model.document_mandatory"
            name="Mandatory"
            value="1"
            unchecked-value="0">
            <b>Document Mandatory</b>
          </b-form-checkbox>
        </b-col>
        <b-col md="1">

        </b-col>
        <b-col md="4" v-if="model.document_type_id == '1' && model.document_category_id == '1'">
          <b-form-checkbox 
            id="asb_flag"
            v-model="model.asb_flag"
            name="asb_flag"
            value="1"
            unchecked-value="0">
            <b>Required ASB</b>
          </b-form-checkbox>
        </b-col>
      </div>

      <div v-if="model.document_category_id == '1' || model.document_category_id == 'x'">
        <hr>
        <div class="form-row">
          <b-col md="12">
              <b>Require Approval From</b>
          </b-col>
        </div>
        <br>
        <div class="form-row">
          <b-col md="1.5">
            <b-form-checkbox
              id="req_approval_project_owner"
              v-model="model.req_approval_project_owner"
              name="req_approval_project_owner"
              value="1"
              unchecked-value="0">
              <b>Project Owner</b>
            </b-form-checkbox>
          </b-col>

          <b-col md="1.5">
            <b-form-checkbox
              id="req_approval_project_manager"
              v-model="model.req_approval_project_manager"
              name="req_approval_project_manager"
              value="1"
              unchecked-value="0">
              <b>Project Manager</b>
            </b-form-checkbox>
          </b-col>

          <b-col md="1.5">
            <b-form-checkbox
              id="req_approval_project_engineer"
              v-model="model.req_approval_project_engineer"
              name="req_approval_project_engineer"
              value="1"
              unchecked-value="0">
              <b>Project Engineer</b>
            </b-form-checkbox>
          </b-col>

          <b-col md="1.5">
            <b-form-checkbox
              id="req_approval_engineer"
              v-model="model.req_approval_engineer"
              name="req_approval_engineer"
              value="1"
              unchecked-value="0">
              <b>Engineer</b>
            </b-form-checkbox>
          </b-col>

          <b-col md="1.5">
            <b-form-checkbox
              id="req_approval_qa_qc"
              v-model="model.req_approval_qa_qc"
              name="req_approval_qa_qc"
              value="1"
              unchecked-value="0">
            <b>QA / QC</b>
            </b-form-checkbox>
          </b-col>

          <b-col md="1.5">
            <b-form-checkbox
              id="req_approval_safety"
              v-model="model.req_approval_safety"
              name="req_approval_safety"
              value="1"
              unchecked-value="0">
            <b>Safety</b>
            </b-form-checkbox>
          </b-col>

          <b-col md="1.5">
            <b-form-checkbox
              id="req_approval_onm"
              v-model="model.req_approval_onm"
              name="req_approval_onm"
              value="1"
              unchecked-value="0">
            <b>O & M</b>
            </b-form-checkbox>
          </b-col>

          <b-col md="1.5">
            <b-form-checkbox
              id="req_approval_planner"
              v-model="model.req_approval_planner"
              name="req_approval_planner"
              value="1"
              unchecked-value="0">
            <b>Planner</b>
            </b-form-checkbox>
          </b-col>

          <b-col md="1.5">
            <b-form-checkbox
              id="req_approval_purchasing"
              v-model="model.req_approval_purchasing"
              name="req_approval_purchasing"
              value="1"
              unchecked-value="0">
            <b>Purchasing</b>
            </b-form-checkbox>
          </b-col>

          <b-col md="1.5">
            <b-form-checkbox
              id="req_approval_admin"
              v-model="model.req_approval_admin"
              name="req_approval_admin"
              value="1"
              unchecked-value="0">
            <b>Admin</b>
            </b-form-checkbox>
          </b-col>
        </div>
      </div>
      <br><br>

      <base-button type="primary" @click="createData()">Create / Save</base-button>
      <base-button type="primary" @click="$router.back()">Cancel</base-button>
      <div>
        <br>
        <h5 class="text-danger"> Note : <br>
          <i>
          1. By Default Recurring Start & End Date is set to "TODAY.
          </i>
          <br>
          <i>
          2. Only ONE-TIME Document Type is allow if you select IFI Document Category.
          </i>
          <br>
          <i>
          3. All Column with * are Mandatory to fill in.
          </i>
          <br>
          <i>
          4. Recurring End Date must be Greater or same as Start Date.
          </i>
          <br>
          <i>
          5. Document Title, Project & Group & Site, Assign To, Document Category, Document Type are Mandatory columns.
          </i>
        </h5>
      </div>
    </form>
  </card>
</template>
<script>
  import BaseSlider from '@/components/BaseSlider'
  import {LoginURL, clientId, clientSecret, apiDomain, getHeader, getcurrentDate} from '@/assets/js/config.js'
  import axios from 'axios'
  import ErrorFunctionMixin from '@/assets/js/errorFunctionMix.js'

  export default {
    mixins: [ErrorFunctionMixin],
    components: {
      BaseSlider
    },
    created()
    {
        var currentDate = getcurrentDate()
        this.model.recurring_start_date = currentDate
        this.model.recurring_end_date = currentDate

        const LoginUser = JSON.parse(window.localStorage.getItem('loginUser'))
        this.myUserID = LoginUser
        this.assignToMe()

        this.model.site_id = this.$route.params.id
        axios.get(apiDomain + 'project/project_site/' + this.model.site_id, { headers: getHeader() })
        .then ( response => {
          if (response.status === 200)
          {
            this.model.site_name = response.data.data[0].site_name
            this.model.group_id = response.data.data[0].group.group_id
            this.model.group_name = response.data.data[0].group.group_name
            this.model.project_id = response.data.data[0].project.project_id
            this.model.project_name = response.data.data[0].project.project_name
            this.getGroupFromProject()
            this.getSiteFromGroup()
            this.getContractorFromSite()
          }
        })
        .catch ( error => {
          console.log(error.response.status)
          this.errorFunction(error, "Project Information")
        });

        this.getDocumentCategory()
        this.getProject()
        this.getDocumentStatus()
        //this.getUserListFromProject()
        this.getDocumentType()
        this.getAssignToUser()

    },
    data() {
      return {
        model: {
          document_title: null,
          project_id: 'x',
          group_id: 'x',
          group_name: null,
          site_id: 'x',
          site_name: null,
          document_mandatory: 1,
          project_name: null,
          document_category_id: 'x',
          document_type_id: 'x',
          milestone_id: 'x',
          contractor_id: 'x',
          assign_to_user: null,
          recurring_interval_id : 'x',
          recurring_start_date: null,
          recurring_end_date: null,
          status_id: 1,
          req_approval_project_owner: 1,
          req_approval_project_manager: 1,
          req_approval_project_engineer: 1,
          req_approval_engineer: 1,
          req_approval_qa_qc: 1,
          req_approval_safety: 1,
          req_approval_onm: 1,
          req_approval_planner: 1,
          req_approval_purchasing: 1,
          req_approval_admin: 1,
          asb_flag: 1,
        },
        myUserID : null,
        projects: [],
        project_groups: [],
        project_sites: [],
        project_milestones: [],
        document_status: [],
        site_contractors: [],
        user_list: [],
        document_types: [],
        document_category: [],
      }
    },
    methods: {
      documentCategoryMatch()
      {
         if ( this.model.document_category_id == 2 )
         {
           this.model.document_type_id = 1
         }
      },
      getAssignToUser()
      {
        // This is not a complete listing. After we settle logic get list of user for each project tested properly will come back and change this.
        axios.get(apiDomain + 'lookup/user_list', { headers: getHeader() })
        .then ( response => {
          if (response.status === 200)
          {
            this.user_list = response.data.data
          }
        })
        .catch ( error => {
          console.log(error.response.status)
          this.errorFunction(error, "Project User Listing")
        });
      },
      getrecurringInterval()
      {
        axios.get(apiDomain + 'lookup/document_type' , { headers: getHeader() })
        .then ( response => {
          if (response.status === 200)
          {
            this.document_types = response.data.data
          }
        })
        .catch ( error => {
          console.log(error.response.status)
          this.errorFunction(error, "Document Type Information")
        } );
      },
      getDocumentType()
      {
        axios.get(apiDomain + 'lookup/document_type' , { headers: getHeader() })
        .then ( response => {
          if (response.status === 200)
          {
            this.document_types = response.data.data
          }
        })
        .catch ( error => {
          console.log(error.response.status)
          this.errorFunction(error, "Document Type Information")
        } );
      },
      getDocumentCategory()
      {
        axios.get(apiDomain + 'lookup/document_category', { headers: getHeader() })
        .then ( response => {
          if (response.status === 200)
          {
            this.document_category = response.data.data
          }
        })
        .catch ( error => {
          console.log(error.response.status)
          this.errorFunction(error, "Document Category Information")
        });
      },
      updatechange()
      {
          console.log(this.model.assign_to_user)
      },
      assignToMe()
      {
          this.model.assign_to_user = this.myUserID.id
      },
      getAssignToUser()
      {
        // This is not a complete listing. After we settle logic get list of user for each project tested properly will come back and change this.
        axios.get(apiDomain + 'lookup/user_list', { headers: getHeader() })
        .then ( response => {
          if (response.status === 200)
          {
            this.user_list = response.data.data
          }
        })
        .catch ( error => {
          console.log(error.response.status)
          this.errorFunction(error, "Project User Listing")
        });
      },
      getUserListFromProjectContractor()
      {
        if ( this.model.contractor_id == 'y' )
        {
          this.getUserListFromProject()
          this.assignToMe()
        }
        else
        {
          axios.get(apiDomain + 'lookup/user_list_with_project_contractor/' + this.model.project_id + '/' + this.model.contractor_id, { headers: getHeader() })
          .then ( response => {
            if (response.status === 200)
            {
              this.user_list = response.data.data
              this.assignToMe()
            }
          })
          .catch ( error => {
            console.log(error.response.status)
            this.errorFunction(error, "Project User with Contractor Listing")
          });
        }
      },
      getUserListFromProject()
      {
        axios.get(apiDomain + 'lookup/user_list_with_project/' + this.model.project_id, { headers: getHeader() })
        .then ( response => {
          if (response.status === 200)
          {
            this.user_list = response.data.data
            this.assignToMe()
          }
        })
        .catch ( error => {
          console.log(error.response.status)
          this.errorFunction(error, "Project User Listing")
        });
      },
      getProject()
      {
        axios.get(apiDomain + 'project/project', { headers: getHeader() })
        .then ( response => {
          if (response.status === 200)
          {
            this.projects = response.data.data
          }
        })
        .catch ( error => {
          console.log(error.response.status)
          this.errorFunction(error, "Project Information")
        });
      },
      getDocumentStatus()
      {
        axios.get(apiDomain + 'lookup/document_status', { headers: getHeader() })
        .then ( response => {
          if (response.status === 200)
          {
            this.document_status = response.data.data
          }
        })
        .catch ( error => {
          console.log(error.response.status)
          this.errorFunction(error, "Document Status Information")
        } );
      },
      getGroupFromProject()
      {
        axios.get(apiDomain + 'project/project_group.show_by_project/' + this.model.project_id , { headers: getHeader() })
        .then ( response => {
          if (response.status === 200)
          {
            this.project_groups = response.data.data
          }
        })
        .catch ( error => {
          console.log(error.response.status)
          this.errorFunction(error, "Project Group Information")
        } );

        axios.get(apiDomain + 'project/project_milestone.show_by_project/' + this.model.project_id , { headers: getHeader() })
        .then ( response => {
          if (response.status === 200)
          {
            this.project_milestones = response.data.data
            this.model.milestone_id = 'y'
          }
        })
        .catch ( error => {
          console.log(error.response.status)
          this.errorFunction(error, "Project Milestone Information")
        } );

        this.getUserListFromProject()
      },
      getSiteFromGroup()
      {
        axios.get(apiDomain + 'project/project_site.show_by_group/' + this.model.group_id , { headers: getHeader() })
        .then ( response => {
          if (response.status === 200)
          {
            this.project_sites = response.data.data
          }
        })
        .catch ( error => {
          console.log(error.response.status)
          this.errorFunction(error, "Project Site Information")
        } );
      },
      getContractorFromSite()
      {
        axios.get(apiDomain + 'project/site_contractor.show_by_site/' + this.model.site_id , { headers: getHeader() })
        .then ( response => {
          if (response.status === 200)
          {
            this.site_contractors = response.data.data
            this.model.contractor_id = 'y'

          }
        })
        .catch ( error => {
          console.log(error.response.status)
          this.errorFunction(error, "Site Contrator Information")
        } );
      },
      checkProject()
      {
        if ( this.model.project_id == 'x' || this.model.project_id == 'y' )
        {
          this.redErrorNotify('<b>Invalid Input : Project </b> - Please select a Project before dive into the Details')
        }
        else
        {
          this.$router.push({path: '/projectEdit/'+this.model.project_id })
        }
      },
      checkGroup()
      {
        if ( this.model.group_id == 'x' || this.model.group_id == 'y' )
        {
          this.redErrorNotify('<b>Invalid Input : Group </b> - Please select a Group before dive into the Details')
        }
        else
        {
          this.$router.push({path: '/projectGroupEdit/'+this.model.group_id })
        }
      },
      checkSite()
      {
        if ( this.model.site_id == 'x' || this.model.site_id == 'y' )
        {
          this.redErrorNotify('<b>Invalid Input : Site </b> - Please select a Site before dive into the Details')
        }
        else
        {
          this.$router.push({path: '/projectSiteEdit/'+this.model.site_id })
        }
      },
      checkContrator()
      {
        if ( this.model.contractor_id == 'x' || this.model.contractor_id == 'y' )
        {
          this.redErrorNotify('<b>Invalid Input : Contractor </b> - Please select a Contractor before dive into the Details')
        }
        else
        {
          this.$router.push({path: '/contractorEdit/'+this.model.contractor_id })
        }
      },
      createData()
      {
        if ( !this.model.document_title ||  this.model.document_type_id == 'x' || this.model.document_category_id == 'x'
        || this.model.site_id == 'x' ||  this.model.site_id == 'y' || this.model.milestone_id == 'x' || this.model.milestone_id == 'y'
        || this.model.contractor_id == 'x')
        {
          if ( !this.model.document_title )
          {
            this.redErrorNotify( '<b>Invalid Input : Document Title is a Mandatory in Site Document </b>')
          }
          else if (this.model.project_id == 'x' ||  this.model.group_id == 'y' ||  this.model.site_id == 'y')
          {
            this.redErrorNotify( '<b>Invalid Input : Project, Group & Site is a Mandatory in Task. Please Select A Project, Group & Site </b>' )
          }
          else if (this.model.contractor_id == 'x' )
          {
            this.redErrorNotify( '<b>Invalid Input : Contractor a Mandatory in Site Document. Please Select A Project, Group & Site </b>' )
          }
          else if (this.model.document_category_id == 'x')
          {
            this.redErrorNotify( '<b>Invalid Input : Document Category is a Mandatory in Site Document. Please Select A Category </b>' )
          }
           else if (this.model.milestone_id == 'x' || this.model.milestone_id == 'y')
          {
            this.redErrorNotify( '<b>Invalid Input : Document Milestone is a Mandatory in Site Document. Please Select A Milestone </b>' )
          }
          else if (this.model.document_type_id == 'x')
          {
            this.redErrorNotify( '<b>Invalid Input : Document Type is a Mandatory in Site Document. Please Select A Type </b>' )
          }
          else
          {
            this.redErrorNotify( '<b>Invalid Input : Please fill in all the Mandatory Column in Site Document </b>' )
          }
        }
        else if ( this.model.document_type_id == '2')
        {
            if(this.model.recurring_interval_id == 'x')
            {
               this.redErrorNotify( '<b>Invalid Input : Recurring Interval is a Mandatory in Project Document. Please Select A Interval </b>' )
            }
            else if( this.model.recurring_end_date < this.model.recurring_start_date )
            {
              this.redErrorNotify( '<b>Invalid Input : End Date </b> - All End Date must be equal or greater than start date.' )
            }
            else
            {
              this.model.status_id = null
              this.insertIntoAPI()
            }
        }
        else
        {
          if ( this.model.document_type_id == '1')
          {
            this.model.recurring_interval_id = null
            this.model.recurring_end_date = null
            this.model.recurring_start_date = null
          }

          this.insertIntoAPI()

        }
      },

      insertIntoAPI()
      {
        if (this.model.contractor_id == 'y')
        {
          this.model.contractor_id = null
        }
        if (this.model.document_category_id == 2)
        {
          this.model.status_id = null
        }

        axios.post(apiDomain + 'document/site_document', this.model, { headers: getHeader() })
        .then ( response => {
          if (response.status === 200)
          {
              this.$notify({
              message:
                '<b>New Document : ' + this.model.document_title + '</b> - Successfully Created.',
              timeout: 10000,
              icon: 'ni ni-bell-55',
              type: 'default',
              });
              this.$router.back()
          }
        })
        .catch ( error => {
            console.log(error.response.data.errors)
            this.errorFunction(error, "New Site Document Information")
        });
      },
    }
  }
</script>
<style>
</style>
