<template>
    <b-card no-body class="bg-default shadow">
        <b-card-header class="bg-transparent border-0">
            <h3 class="mb-0 text-white">Recurring Document Listing in SN-NT - Tengeh Reservoir North Site for (TRTP-2398 - Tengeh Reservoir Tender Project)</h3>
            <div class="text-right">
                <base-button type="neutral" size="sm">Addtional Document Request</base-button>
            </div>
        </b-card-header>

        <el-table class="table-responsive table-dark"
                  header-row-class-name="thead-dark"
                  :data="documents">
            <el-table-column label="Classification"
                             min-width="170px"
                             prop="name"
                             sortable>
                <template v-slot="{row}">
                    <b-media no-body class="align-items-center">
                        <b-media-body>
                            <span class="font-weight-600 name mb-0 text-sm text-white">{{row.taskId}}</span>
                        </b-media-body>
                    </b-media>
                </template>
            </el-table-column>

            <el-table-column label="Contractor"
                             min-width="100px"
                             prop="name"
                             sortable>
                <template v-slot="{row}">
                    <b-media no-body class="align-items-center">
                        <b-media-body>
                            <span class="font-weight-600 name mb-0 text-sm text-white">{{row.taskName}}</span>
                        </b-media-body>
                    </b-media>
                </template>
            </el-table-column>

            <el-table-column label="Frequency"
                             min-width="100px"
                             prop="status"
                             sortable>
                <template v-slot="{row}">
                    <badge class="badge-dot mr-4" type="">

                        <span class="status text-white">{{row.taskFrequency}}</span>
                    </badge>
                </template>
            </el-table-column>

            <el-table-column label="Total Upload"
                             min-width="110px"
                             prop="status"
                             sortable>
                <template v-slot="{row}">
                    <badge class="badge-dot mr-4" type="">

                        <span class="status text-white">{{row.taskTotal}}</span>
                    </badge>
                </template>
            </el-table-column>

            <el-table-column label="Last Upload Date"
                             min-width="130px"
                             prop="status"
                             sortable>
                <template v-slot="{row}">
                    <badge class="badge-dot mr-4" type="">

                        <span class="status text-white">{{row.taskDate}}</span>
                    </badge>
                </template>
            </el-table-column>

            <el-table-column label="Action" min-width="160px">
               <template v-slot="">
                    <div class="d-flex align-items-center">
                        <div>
                            <base-button type="primary" size="sm" @click="newModal()">Upload Document</base-button>
                        </div>
                        &nbsp;
                        <div>
                            <base-button size="sm" type="primary" @click="editModal()">Details</base-button>
                        </div>
                    </div>
                </template>
            </el-table-column>
        </el-table>

        <b-card-footer class="py-2 d-flex justify-content-end bg-transparent">
            <base-pagination v-model="currentPage" :per-page="10" :total="50"></base-pagination>
        </b-card-footer>

        <modal :show.sync="modals.form"
                   size="lg"
                   body-classes="p-0">
              <card type="secondary"
                    header-classes="bg-transparent pb-5"
                    body-classes="px-lg-5 py-lg-5"
                    class="border-0 mb-0">
                <template>
                  <div class="text-center text-muted mb-4">
                    <h2>{{ formModal.title }} Recurring Document to SN-NT - Tengeh Reservoir North Site for (TRTP-2398 - Tengeh Reservoir Tender Project)</h2>
                  </div>
                  <b-form role="form">
                    <base-input label="Classification" name="Classification" placeholder="Classification" v-model="formModal.classification" disabled>
                    </base-input>
                    <base-input label="Contractor" name="Contractor" placeholder="Contractor" v-model="formModal.contactor" disabled>
                    </base-input>
                    <base-input label="Date" name="Date" placeholder="Date" v-model="formModal.uploadDate" type="date">
                    </base-input>
                    <!-- <base-input label="Contractor" name="Contractor">
                      <select class="form-control" v-model="formModal.contactor">
                        <option value="" disabled>-- Please Select A Contractor --</option>
                        <option value="Solar Ex">Solar Ex</option>
                        <option value="Long Seng">Long Seng</option>
                      </select>
                    </base-input> -->
                    <!-- <base-input label="Status" name="Status">
                      <select class="form-control" v-model="formModal.status">
                        <option value="" disabled>-- Please Select A Status --</option>
                        <option value="Pending">Pending</option>
                        <option value="Waiting For Approval">Waiting For Approval</option>
                        <option value="Required Revision">Required Revision</option>
                      </select>
                    </base-input> -->
                     <h4>Attachment</h4>
                    <dropzone-file-upload v-model="formModal.doc_attachment" multiple></dropzone-file-upload>
                    <!-- <b-form-checkbox v-model="formModal.mandatory"
                    value="Mandatory"
                    unchecked-value="Not_Mandatory">Mandatory</b-form-checkbox> -->
                    <div class="text-center">
                      <base-button type="primary" class="my-4" @click="notifyUpload()">{{ formModal.buttonName }}</base-button>
                      <base-button type="primary" class="my-4" @click="clearModal()">Cancel</base-button>
                    </div>
                  </b-form>
                </template>
              </card>
        </modal>

          <modal :show.sync="modals.formDetails"
                   size="lg"
                   body-classes="p-0">
              <card type="secondary"
                    header-classes="bg-transparent pb-5"
                    body-classes="px-lg-5 py-lg-5"
                    class="border-0 mb-0">
                <template>
                  <div class="text-center text-muted mb-4">
                    <h2>Recurring Document Details in SN-NT - Tengeh Reservoir North Site</h2>
                    <h2>Classification : Daily Manpower Update

                       </h2>
                  </div>

                  <b-form role="form">
                      <b-card no-body class="bg-default shadow">
                        <el-table class="table-responsive table-dark"
                                  header-row-class-name="thead-dark"
                                  :data="documentdetails">
                            <!-- <el-table-column label="Classification"
                                            min-width="170px"
                                            prop="name"
                                            sortable>
                                <template v-slot="{row}">
                                    <b-media no-body class="align-items-center">
                                        <b-media-body>
                                            <span class="font-weight-600 name mb-0 text-sm text-white">{{row.taskId}}</span>
                                        </b-media-body>
                                    </b-media>
                                </template>
                            </el-table-column> -->

                            <el-table-column label="Contractor"
                                            min-width="80px"
                                            prop="name"
                                            sortable>
                                <template v-slot="{row}">
                                    <b-media no-body class="align-items-center">
                                        <b-media-body>
                                            <span class="font-weight-600 name mb-0 text-sm text-white">{{row.taskName}}</span>
                                        </b-media-body>
                                    </b-media>
                                </template>
                            </el-table-column>

                            <!-- <el-table-column label="Frequency"
                                            min-width="110px"
                                            prop="status"
                                            sortable>
                                <template v-slot="{row}">
                                    <badge class="badge-dot mr-4" type="">

                                        <span class="status text-white">{{row.taskFrequency}}</span>
                                    </badge>
                                </template>
                            </el-table-column> -->

                            <!-- <el-table-column label="Total Upload"
                                            min-width="110px"
                                            prop="status"
                                            sortable>
                                <template v-slot="{row}">
                                    <badge class="badge-dot mr-4" type="">

                                        <span class="status text-white">{{row.taskTotal}}</span>
                                    </badge>
                                </template>
                            </el-table-column> -->

                            <el-table-column label="Upload Date"
                                            min-width="90px"
                                            prop="status"
                                            sortable>
                                <template v-slot="{row}">
                                    <badge class="badge-dot mr-4" type="">

                                        <span class="status text-white">{{row.taskDate}}</span>
                                    </badge>
                                </template>
                            </el-table-column>

                            <el-table-column label="Document Action" min-width="160px">
                              <template v-slot="">
                                    <div class="d-flex align-items-center">
                                        <div>
                                            <base-button size="sm" type="primary"><a href="http://www.bournshell.net/customer_bill/customer_log_Jun_2021_15_08_40_110730005.xlsx" class="text-white">Download</a></base-button>
                                        </div>
                                        &nbsp;  &nbsp;
                                        <div>
                                            <base-button size="sm" type="primary" @click="notifyApprove()">Approve Document</base-button>
                                        </div>
                                    </div>
                                </template>
                            </el-table-column>
                        </el-table>
                      </b-card>

                    <div class="text-center">

                      <base-button type="primary" class="my-4" @click="clearModal()">Cancel</base-button>
                    </div>
                  </b-form>
                </template>
              </card>
        </modal>

    </b-card>

</template>

<script>

  import documents from './js/recurringdocuments.js'
  import documentdetails from './js/recurringdocumentdetails.js'
  import { Table, TableColumn, DropdownMenu, DropdownItem, Dropdown} from 'element-ui'
  import {homeLink} from '@/assets/js/config.js'
  import { Modal } from '@/components';
  import DropzoneFileUpload from '@/components/Inputs/DropzoneFileUpload'

  export default {
    name: 'site-recurring-document-table',
    components: {
      Modal,
      [Table.name]: Table,
      [TableColumn.name]: TableColumn,
      [Dropdown.name]: Dropdown,
      [DropdownItem.name]: DropdownItem,
      [DropdownMenu.name]: DropdownMenu,
      DropzoneFileUpload,
    },
    data() {
      return {
        modals: {
          form: false,
          formDetails : false,
        },
        formModal: {
          classification: '',
          contactor: '',
          status: '',
          uploadDate: '',
          doc_attachment: [],
          title: 'Upload New Document',
          buttonName: 'Upload New Document',
        },
        documents,
        documentdetails,
        currentPage: 1,
        img1: homeLink+'/img/theme/team-1.jpg',
      };
    },
    methods: {
       newModal(){

          this.formModal.contactor = 'Solar Ex'
          this.formModal.classification = 'Daily Manpower Update'
          this.formModal.status = ''
          this.formModal.buttonName = 'Upload New Document'
          this.formModal.title = 'Upload Document'
          this.formModal.uploadDate = '2021-01-09'
          this.formModal.doc_attachment = []
          this.modals.form = true
      },
      editModal(){
          this.modals.formDetails = true
      },
      clearModal()
      {
        this.modals.form = false
        this.modals.formDetails= false
        this.formModal.contactor = ''
        this.formModal.classification = ''
        this.formModal.status = ''
        this.formModal.doc_attachment = []
      },
      notifyUpload() {
        if ( this.formModal.doc_attachment.length === 0 )
        {
          this.$notify({
          message:
            '<b>Request Input Error : </b> - Please at least upload an attchment',
          timeout: 10000,
          icon: 'ni ni-bulb-61',
          type: 'danger',
          });
        }
        else
        {
          this.$notify({
          message:
            '<b>New Recurring Document (Daily Manpower Update) : </b> - Succesfully Uploaded.',
          timeout: 10000,
          icon: 'ni ni-bell-55',
          type: 'default',
          });
          this.clearModal()
        }
      },
       notifyApprove() {
          this.$notify({
          message:
            '<b>Document Approval (Daily Mainpower Update - 01-Jan-2021): </b> - Has been Approved.',
          timeout: 10000,
          icon: 'ni ni-bell-55',
          type: 'default',
          });
          this.clearModal()
      },
    }
  }
</script>
