var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('base-header',{staticClass:"pb-6"},[_c('b-row',{staticClass:"py-4"},[_c('b-col',{attrs:{"cols":"6"}},[_c('h6',{staticClass:"h2 text-white d-inline-block mb-0"},[_vm._v(_vm._s(_vm.title))])]),_c('b-col',{staticClass:"text-right",attrs:{"cols":"6"}},[_c('base-button',{attrs:{"size":"sm","type":"neutral"},on:{"click":function($event){return _vm.$router.replace('../Listing')}}},[_vm._v("Back")])],1)],1)],1),_c('b-container',{staticClass:"mt--6",attrs:{"fluid":""}},[_c('div',{staticClass:"card-wrapper"},[_c('card',[_c('h3',{staticClass:"mb-0",attrs:{"slot":"header"},slot:"header"},[_vm._v("Edit Template")]),_c('validation-observer',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{staticClass:"needs-validation",on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)}}},[_c('b-form-row',[_c('b-col',[_c('validation-provider',{attrs:{"name":"Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Name","invalid-feedback":errors[0]}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.model.name),expression:"model.name"}],staticClass:"form-control",class:classes,attrs:{"type":"text","maxlength":"100"},domProps:{"value":(_vm.model.name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.model, "name", $event.target.value)}}})])]}}],null,true)})],1)],1),_c('b-form-row',[_c('b-col',[_c('br'),_c('validation-provider',{attrs:{"name":"Main Category Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Main Category Name","invalid-feedback":errors[0]}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.model.category1_name),expression:"model.category1_name"}],staticClass:"form-control",class:classes,attrs:{"type":"text","maxlength":"100"},domProps:{"value":(_vm.model.category1_name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.model, "category1_name", $event.target.value)}}})])]}}],null,true)})],1),_c('b-col',[_c('br'),_c('validation-provider',{attrs:{"name":"Sub Category Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Sub Category Name","invalid-feedback":errors[0]}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.model.category2_name),expression:"model.category2_name"}],staticClass:"form-control",class:classes,attrs:{"type":"text","maxlength":"100"},domProps:{"value":(_vm.model.category2_name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.model, "category2_name", $event.target.value)}}})])]}}],null,true)})],1),_c('b-col',[_c('validation-provider',{attrs:{"name":"category3_enabled"}},[_c('b-form-checkbox',{attrs:{"value":1,"unchecked-value":0},model:{value:(_vm.model.category3_enabled),callback:function ($$v) {_vm.$set(_vm.model, "category3_enabled", $$v)},expression:"model.category3_enabled"}},[_vm._v("Enable Third Category")])],1),_c('validation-provider',{directives:[{name:"show",rawName:"v-show",value:(_vm.model.category3_enabled),expression:"model.category3_enabled"}],attrs:{"name":"Third Category Name","rules":"required_if:category3_enabled,1"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Third Category Name","invalid-feedback":errors[0]}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.model.category3_name),expression:"model.category3_name"}],staticClass:"form-control",class:classes,attrs:{"type":"text","maxlength":"100"},domProps:{"value":(_vm.model.category3_name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.model, "category3_name", $event.target.value)}}})])]}}],null,true)})],1)],1),_c('br'),_c('b-form-row',[_c('b-col',[_c('b-form-checkbox',{attrs:{"value":1,"unchecked-value":0},model:{value:(_vm.model.status),callback:function ($$v) {_vm.$set(_vm.model, "status", $$v)},expression:"model.status"}},[_c('b',[_vm._v("Active Status")])])],1)],1),_c('br'),_c('div',{staticClass:"d-flex align-items-baseline justify-content-between"},[_c('div',[(_vm.access.edit)?_c('button',{staticClass:"btn btn-primary"},[_vm._v("Update")]):_vm._e(),_c('button',{staticClass:"btn btn-primary",attrs:{"type":"button"},on:{"click":function($event){return _vm.$router.replace('../Listing')}}},[_vm._v("Cancel")])]),(_vm.access.delete)?_c('button',{staticClass:"btn btn-danger",attrs:{"type":"button"},on:{"click":function($event){_vm.showConfirmDelete = true}}},[_vm._v("Delete")]):_vm._e()])],1)]}}])})],1)],1)]),_c('modal',{attrs:{"show":_vm.showConfirmDelete,"size":"sm","body-classes":"p-0"},on:{"update:show":function($event){_vm.showConfirmDelete=$event}}},[_c('card',{staticClass:"border-0 mb-0",attrs:{"type":"secondary","header-classes":"bg-transparent pb-5","body-classes":"px-lg-5 py-lg-5"}},[_c('div',{staticClass:"text-center text-muted mb-4"},[_c('h3',[_vm._v("Delete this Template?")])]),_c('div',{staticClass:"text-center"},[_c('button',{staticClass:"btn btn-danger",on:{"click":_vm.deleteRecord}},[_vm._v("YES")]),_c('button',{staticClass:"btn btn-dark",on:{"click":function($event){_vm.showConfirmDelete = false}}},[_vm._v("NO")])])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }