<style scoped>
    .custom-table-container {
        margin-top: 20px;
    }

    .custom-table {
        width: 100%;
        border-collapse: collapse;
    }

    .table-row {
        border-bottom: 1px solid #ddd;
    }

    .table-cell {
        padding: 5px; /* Reduced padding */
        position: relative;
    }

    .first-column {
        background-color: #f0f0f0;
    }

    .first-row {
        background-color: #e0e0e0;
    }

    .custom-input {
        width: 100%;
        border: 1px solid #ccc;
        border-radius: 5px;
        padding: 8px;
        margin: 0; /* Remove margin */
        box-sizing: border-box;
    }

    .delete-cell {
        text-align: center;
    }

    .delete-icon {
        color: red;
        cursor: pointer;
    }
</style>
<template>
    <div v-if="isShown">
        <card
                type="secondary"
                header-classes="bg-transparent pb-5"
                body-classes="px-lg-5 py-lg-5"
                class="border-0 mb-0"
        >
            <template>
                <h1>New Criteria</h1>
                <br/>
                <card>
                    <div class="form-row">
                        <b-col md="2">
                            <label>No *</label>
                            <input
                                    class="form-control"
                                    v-model="record.number"
                                    @keypress="isNumeric($event)"
                            />
                        </b-col>
                        <b-col md="8">
                            <label>Title *</label>
                            <input
                                    class="form-control"
                                    v-model="record.title"
                            />
                            <br>
                            <label>Descriptions *</label>
                            <b-form-textarea
                                    id="textarea"
                                    v-model="record.description"
                                    placeholder=""
                                    rows="5"
                            ></b-form-textarea>
                        </b-col>
                        <b-col md="2">
                            <br ><br >
                            <b-form-checkbox
                                    v-model="record.is_required"
                                    name="Is-Required"
                                    value="1"
                                    unchecked-value="0">
                                <b>Is Required?</b>
                            </b-form-checkbox>
                        </b-col>
                    </div>
                    <br ><br >
                    <div class="form-row">
                        <b-col cols="4">
                            <label for="inputTypesSelect" class="form-control-label">Type *</label>
                            <select size="8" multiple class="form-control" id="developersSelect" v-model="typeSelection" @change="changeType">
                                <option value="radio">Single Selection</option>
                                <option value="checkbox">Multiple Selection</option>
                                <option value="select">Dropdown List</option>
                                <option value="date">Date</option>
                                <option value="file">File Upload</option>
                                <option value="text-field">Short Answer</option>
                                <option value="textarea">Long Answer</option>
                                <option value="table">Table</option>
                            </select>
                            <br>
                        </b-col>

                        <b-col cols="8">
                            <card v-if="withItems()">
                                <h3 slot="header" class="mb-0">Selection Items</h3>
                                <div class="table-responsive">
                                    <table>
                                        <tbody>
                                            <tr v-for="(item, index) in record.items" :key="index">
                                                <td width="90%">
                                                    <base-input
                                                            v-model="item.name"
                                                            label="Item"
                                                    >
                                                    </base-input>
                                                </td>
                                                <td>
                                                    <base-button
                                                            type="danger"
                                                            size="sm"
                                                            @click="deleteItems(item)"
                                                    >
                                                        Remove
                                                    </base-button>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div align="right">
                                    <base-button
                                            type="primary"
                                            size="md"
                                            @click="addMoreItems"
                                    >Add More</base-button>
                                </div>
                            </card>
                            <card v-if="withTables()">
                                <h3 slot="header" class="mb-0">Table</h3>
                                <label for="tableTypeSelections" class="form-control-label">Table Type</label>
                                <select class="form-control" v-if="selectedType=='Table'" v-model="tableTypeSelection" @change="changeTableType">
                                    <option value="simple">Simple</option>
                                    <option value="complex">Complex</option>
                                </select>

                                <div v-if="record.table_type=='simple'">
                                    <div class="table-responsive">
                                        <table>
                                            <tbody>
                                            <tr v-for="(item, index) in record.table" :key="index">
                                                <td width="90%">
                                                    <base-input
                                                            v-model="item.label"
                                                            :label="'Header ' + (index + 1)"
                                                    >
                                                    </base-input>
                                                </td>
                                                <td>
                                                    <base-button
                                                            type="danger"
                                                            size="sm"
                                                            @click="deleteItems(item)"
                                                    >
                                                        Remove
                                                    </base-button>
                                                </td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </div>

                                    <div align="right">
                                        <base-button
                                                type="primary"
                                                size="md"
                                                @click="addMoreRow"
                                        >Add More</base-button>
                                    </div>
                                </div>
                                <br >
                                <div v-if="record.table_type=='complex'">
                                    <div class="form-row">
                                        <b-col md="6">
                                            <base-input
                                                v-model="record.table_col"
                                                label="Col"
                                                @input="createTable"
                                                type="number"
                                            >
                                            </base-input>
                                        </b-col>
                                        <b-col md="6">
                                            <base-input
                                                v-model="record.table_row"
                                                label="Row"
                                                @input="createTable"
                                                type="number"
                                            ></base-input>
                                        </b-col>
                                    </div>
                                    <div class="form-group">
                                        <template>
                                            <div class="custom-table-container">
                                                <table class="custom-table">
                                                    <tbody>
                                                    <tr v-for="(row, rowIndex) in record.table" :key="rowIndex" class="table-row">
                                                        <td v-for="(col, colIndex) in row" :key="colIndex" class="table-cell" :class="{ 'first-column': colIndex === 0, 'first-row': rowIndex === 0 }">
                                                            <base-input
                                                                    v-model="record.table[rowIndex][colIndex]"
                                                                    outline
                                                                    :readonly="!canEditCell(rowIndex, colIndex)"
                                                                    :background-color="canEditCell(rowIndex, colIndex) ? '' : 'transparent'"
                                                            ></base-input>
                                                        </td>
                                                        <td class="delete-cell">
                                                            <base-button size="sm" type="danger" @click="deleteRow(rowIndex)">close</base-button>
                                                        </td>
                                                    </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </template>
                                    </div>
                                </div>

                            </card>
                        </b-col>
                    </div>


                    <div>
                        <base-button
                                type="primary"
                                class="my-4"
                                @click="cancelAddCriteria()"
                        >Cancel</base-button
                        >
                        <base-button
                                v-if="mode=='new'"
                                type="primary"
                                class="my-4"
                                @click="addCriteria()"
                        >Save</base-button
                        >
                        <base-button
                                v-if="mode=='edit'"
                                type="primary"
                                class="my-4"
                                @click="handleEditCriteria()"
                        >Update</base-button>
                    </div>
                </card>
            </template>
        </card>
    </div>
</template>

<script>
    import ErrorFunctionMixin from '@/assets/js/errorFunctionMix.js'
    import axios from '@/util/axios'
    import { getAccessByModule } from '@/util/access'
    import BaseInput from "../../../../../components/Inputs/BaseInput.vue";
    import BaseButton from "../../../../../components/BaseButton.vue";

    export default {
        components: {
            BaseButton,
            BaseInput},
        mixins: [ErrorFunctionMixin],
        watch: {
            'record.table_type': function(newTableType, oldTableType) {
                // Check if the table_type has been initialized
                if (oldTableType !== '' && newTableType !== oldTableType) {
                    // Reset the 'table' array to an empty array
                    this.$set(this.record, 'table', []);

                    // Set table_row and table_col to 1
                    this.$set(this.record, 'table_row', 1);
                    this.$set(this.record, 'table_col', 1);
                }
            },

        },
        data() {
            return {
                mode: "view",
                isShown: false,
                title: 'Eform Templates',
                model: {
                },
                record: {
                    items: [],
                    table_row: 1,
                    table_col: 1,
                    table_type: '',
                    table: [],
                    criteria: [],
                },
                // default
                defaultRecord: {
                    items: []
                },

                entryLookupOptions: [],
                entryLookupTitleOptions: [],

                errorMessages: null,
                selectedType: '',
                typeSelection: [],
                tableTypeSelection: [],
                title: '',
                fieldType: {
                    0 : 'Single Selection',
                    1 : 'Multiple Selection',
                    2 : 'Dropdown List',
                    3 : 'Date',
                    4 : 'File Upload',
                    5 : 'Short Answer',
                    6 : 'Long Answer',
                    7 : 'Table'
                },
                fieldInputType: {
                    0 : 'radio',
                    1 : 'checkbox',
                    2 : 'select',
                    3 : 'date',
                    4 : 'file',
                    5 : 'text-field',
                    6 : 'textarea',
                    7 : 'table'
                }
            }
        },

        created() {
            this.access = getAccessByModule('EFORM_TEMPLATE')

            // init default title
            this.title = "New Criteria"

            let component = this // because inside this.$root will refer root component instead of current component.
            let editTitle = "Edit Criteria"
            this.$root.$on('editCriteria', function(data,criteriaParent=null,criteriaTitle=null,moduleType='eform'){
                // console.log('edit criteria called', data)
                component.record.id = data.id
                component.record.number = data.order_no
                component.record.title = data.title
                component.record.description = data.description
                component.record.is_required = data.is_required
                component.record.is_linked = data.is_linked
                component.record.alert = data.alert
                component.entryLookupOptions = criteriaParent
                component.entryLookupTitleOptions = criteriaTitle
                component.record.criteria = data.criteria
                component.selectedType = data.type
                component.typeSelection = data.type
                component.record.type = data.type
                component.record.input_type = data.input_type
                if(component.record.type == 'Table' || component.record.type == 'table'){
                    component.record.table = data.tables;
                    component.record.table_type = data.table_type;
                    component.record.table_col = parseInt(data.table_col);
                    component.record.table_row = parseInt(data.table_row);
                    component.tableTypeSelection = data.table_type;
                }else{
                    component.record.items = data.items

                }
                component.mode = "edit"
                component.module = moduleType;
                component.title = editTitle
                component.isShown = true

                // console.log('event record', component.record)
            })

            // trigger when parent emit

            this.$root.$on('newCriteria', function(data=null,title=null,moduleType='eform'){
                component.entryLookupOptions = data;
                component.entryLookupTitleOptions = title;
                component.mode = "new"
                component.module = moduleType;
                component.selectedType = ''
                component.isShown = true
            })

            // trigger when parent emit
            this.$root.$on('cancelEditTemplate', function(){
                component.cancelAddCriteria()
            })
        },

        methods: {
            isNumeric(evt) {
                var inp = String.fromCharCode(evt.keyCode);
                if (/[0-9]/.test(inp)) {
                    return true;
                } else {
                    evt.preventDefault();
                }
            },
            changeType(event){
                this.selectedType = this.getType(this.typeSelection[0]);
                this.record.type = this.getType(this.typeSelection[0]);
                this.record.input_type = this.typeSelection[0];
            },
            withItems(){
                let arr = ['Single Selection', 'Multiple Selection', 'Dropdown List'];
                if(arr.includes(this.selectedType)){
                    return true;
                }
                else{
                    return false;
                }
            },
            addMoreItems() {
                let newItem = { name:''};
                this.record.items.push(newItem);
            },
            deleteItems(item) {
                let idx = this.record.items.indexOf(item);

                if (idx >= 0) {
                    // this.errorMessages = null;
                    this.record.items.splice(idx, 1);
                }
            },
            cancelAddCriteria(){
                //console.log('cancel add criteria', this.$parent.$parent)
                this.record = {
                    items: [],
                    table_row: 1,
                    table_col: 1,
                    table: [],
                }
                this.isShown = false
                this.$emit('closeCriteria')
            },
            withTables()
            {
                let arr = ['Table'];
                if(arr.includes(this.selectedType))
                {
                    return true;
                }
                else
                {
                    return false;
                }
            },
            addCriteria(){
                let hasError = this.validateRequired()
                //console.log('has error', hasError)

                if(!hasError){
                    // console.log('not error')
                    this.$emit('addedCriteria', this.record)
                    this.record = {
                        items: [],
                        table_row: 1,
                        table_col: 1,
                        table: [],
                    }
                    this.isShown = false
                    console.log('is shown', this.isShown)
                }
            },
            validateRequired(){
                let hasError = false
                let err = {}
                if(!this.record.number){
                    err.number = "required"
                    hasError = true
                }

                if(!this.record.title){
                    err.title = "required"
                    hasError = true
                }
                else if(typeof this.record.title === 'object')
                {
                    this.record.title = this.record.title.title;
                }

                if(!this.record.description){
                    err.description = "required"
                    hasError = true
                }

                if(!this.record.input_type){
                    err.type = "required"
                    hasError = true
                }

                let multiOption = ['radio', 'checkbox', 'select', 'checkbox_extra']
                if(multiOption.includes(this.record.input_type)){
                    //has multiple option input type
                    if(this.record.items.length <= 0){
                        err.type = "Multiple option items are empty"
                        hasError = true
                    }
                }
                this.errorMessages = {...err}

                return hasError
            },
            handleEditCriteria(){
                if(this.mode == 'edit'){
                    let hasError = this.validateRequired()
                    if(!hasError){
                        this.$emit('updatedCriteria', this.record)
                        this.record = {
                            items: [],
                            table_row: 1,
                            table_col: 1,
                            table: [],
                        }
                        this.isShown = false
                    }
                }
                else
                {
                    let hasError = this.validateRequired()
                    if(!hasError){
                        this.$emit('updatedCriteria', this.record)
                        this.record = {
                            items: [],
                            table_row: 1,
                            table_col: 1,
                            table: [],
                        }
                        this.isShown = false
                    }
                }

            },
            createTable() {
                const currentRowCount = this.record.table.length; //
                const currentColCount = currentRowCount > 0 ? this.record.table[0].length : 0;

                if (this.record.table_row > currentRowCount) {

                    // Add new rows
                    for (let i = currentRowCount; i < this.record.table_row; i++) {
                        const row = [];
                        for (let j = 0; j < currentColCount; j++) {
                            row.push('');
                        }
                        this.record.table.push(row);
                    }
                } else if (this.record.table_row < currentRowCount) {
                    // Remove extra rows
                    this.record.table.splice(this.record.table_row);
                }

                // Update the column count based on the new row count
                const newColCount = this.record.table_col;
                if (newColCount > currentColCount) {

                    // Add new columns to each row
                    for (let i = 0; i < this.record.table.length; i++) {
                        for (let j = currentColCount; j < newColCount; j++) {
                            this.record.table[i].push('');

                        }
                    }
                } else if (newColCount < currentColCount) {
                    // Remove extra columns from each row
                    for (let i = 0; i < this.record.table.length; i++) {
                        this.record.table[i].splice(newColCount);
                    }
                }
            },

            canEditCell(rowIndex, colIndex) {
                // Check if the cell in the first row or first column
                // Cells in the first row or first column are editable
                return rowIndex === 0 || colIndex === 0;
            },

            deleteRow(rowIndex) {
                if(this.record.table_type=='complex')
                {
                    this.record.table.splice(rowIndex, 1);
                    this.record.table_row--;
                }
                else
                {
                    let column = rowIndex;
                    let idx = this.record.table.indexOf(column);

                    if (idx >= 0) {
                        // this.errorMessages = null;
                        this.record.table.splice(idx, 1);
                    }
                }

            },

            addMoreRow() {
                let newTable = { label:''};
                this.record.table.push(newTable);
            },
            changeTableType(event){
                this.record.table_type = this.tableTypeSelection;
            },
            getType(inputType) {
                // Iterate over the entries of the fieldType object
                for (const [key, value] of Object.entries(this.fieldInputType)) {
                    if (value === inputType) {
                        return this.fieldType[key]; // Return the key if the value matches
                    }
                }
                return null; // Return null or handle the case where the value is not found
            }
        },
    }
</script>