<template>
  <card
    >
    <div>
      <h3 v-if="!isSolarNovaProject" slot="header" class="mb-3.5">
        Project Group Information for ({{ model.group_name }})
      </h3>
      <h3 v-else slot="header" class="mb-3.5">
        Town Council Information for ({{ model.group_name }})
      </h3>
      <div class="text-right">
        <router-link :to="'/projectGroupEdit/' + model.group_id">
          <base-button
            size="sm"
            type="primary"
            v-if="
              button_access.group_edit == 'YES' && ( myUserID.email == model.group_engineer_email || myUserID.role_id == 3 )
            "
            >Edit</base-button
          >
        </router-link>
      </div>
    </div>
    <br />
    <form class="needs-validation" @submit.prevent="submit">
      <div class="form-row">
        <b-col md="6">
          <base-input v-if="!isSolarNovaProject" 
            label="Project Group Name"
            name="Project Group Name"
            placeholder="Project Group Name"
            v-model="model.group_name"
            disabled
          >
          </base-input>
          <base-input v-else
            label="Town Council Name"
            name="Town Council Name"
            placeholder="Town Council Name"
            v-model="model.group_name"
            disabled
          >
          </base-input>
        </b-col>

        <b-col md="6">
          <base-input
            label="Project"
            name="Project"
            v-model="model.project_name"
            disabled
          >
          </base-input>
        </b-col>
      </div>

      <div class="form-row">
        <b-col md="12">
          <base-input v-if="!isSolarNovaProject" 
            label="Project Group Information"
            name="Project Group Information"
            placeholder="Project Group Information"
            v-model="model.group_information"
            disabled
          >
          </base-input>
          <base-input v-else
            label="Town Council Information"
            name="Town Council Information"
            placeholder="Town Council Information"
            v-model="model.group_information"
            disabled
          >
          </base-input>
        </b-col>
      </div>

      <div class="form-row">
        <!-- <b-col md="6">
          <base-input v-if="!isSolarNovaProject" 
            label="Group PV Capacity (KWp)"
            name="Group PV Capacity (KWp"
            placeholder="Group PV Capacity (KWp"
            v-model="model.group_pv_capacity"
            disabled
          >
          </base-input>
          <base-input v-else
            label="Town Council PV Capacity (KWp)"
            name="Town Council PV Capacity (KWp"
            placeholder="Town Council PV Capacity (KWp"
            v-model="model.group_pv_capacity"
            disabled
          >
          </base-input>
        </b-col> -->

        <b-col md="12">
          <!-- <base-input
            label="Project Manager"
            name="Project Manager"
            v-model="model.group_engineer"
            disabled
          > -->
          <base-input
            label="Project Engineer"
            name="Project Engineer"
            v-model="model.group_engineer"
            disabled
          >
          </base-input>
        </b-col>
      </div>

      <div class="form-row">
        <b-col md="12">
          <base-input v-if="!isSolarNovaProject" 
            label="Project Group Status"
            name="Project Group Status"
            v-model="model.status_id"
            disabled
          >
          </base-input>
          <base-input v-else
            label="Town Council Status"
            name="Town Council Status"
            v-model="model.status_id"
            disabled
          >
          </base-input>
        </b-col>

        <!-- <b-col md="6">
          <base-input v-if="!isSolarNovaProject" 
            label="Project Group Progress % "
            name="Project Group Progress"
            placeholder="Project Group Progress"
            v-model="model.group_progress"
            disabled
          >
          </base-input>
          <base-input v-else
            label="Town Council Progress % "
            name="Town Council Progress"
            placeholder="Town Council Progress"
            v-model="model.group_progress"
            disabled
          >
          </base-input>
        </b-col> -->
      </div>
    </form>
  </card>
</template>
<script>
import BaseSlider from "@/components/BaseSlider";
import {
  LoginURL,
  clientId,
  clientSecret,
  apiDomain,
  getHeader,
  getcurrentDate,
} from "@/assets/js/config.js";
import axios from "axios";
import ErrorFunctionMixin from "@/assets/js/errorFunctionMix.js";

export default {
  mixins: [ErrorFunctionMixin],
  components: {
    BaseSlider,
  },
  created() {
    var project_group_access = JSON.parse(
      window.localStorage.getItem("project_group_access")
    );
    this.button_access.group_edit = project_group_access.access_edit;

    const LoginUser = JSON.parse(window.localStorage.getItem("loginUser"));
    this.myUserID = LoginUser;

    this.model.group_id = this.$route.params.id;

    axios
      .get(apiDomain + "project/project_group/" + this.model.group_id, {
        headers: getHeader(),
      })
      .then((response) => {
        if (response.status === 200) {
          this.model.group_name = response.data.data[0].group_name;
          // this.model.project_id = response.data.data[0].project.project_code
          this.model.project_id = response.data.data[0].project.project_id;
          this.model.project_name = response.data.data[0].project.project_name;
          this.model.group_name = response.data.data[0].group_name;
          this.model.group_information =
            response.data.data[0].group_information;
          this.model.group_pv_capacity =
            response.data.data[0].group_pv_capacity;
          this.model.group_progress = response.data.data[0].group_progress;
          this.model.group_engineer = response.data.data[0].group_engineer.name;
          this.model.group_engineer_email =
            response.data.data[0].group_engineer.email;
          this.model.status_id = response.data.data[0].status.status_code;
          this.getProjectTypeCheckSolar();
        }
      })
      .catch((error) => {
        console.log(error.response.status);
        this.errorFunction(error, "Project Group Information");
      });
  },
  data() {
    return {
      button_access: {
        group_edit: null,
      },
      model: {
        group_id: null,
        project_id: "x",
        project_name: null,
        group_name: null,
        group_name: null,
        group_information: null,
        group_pv_capacity: null,
        status_id: "x",
        group_engineer: "x",
        group_engineer_email: null,
        group_progress: 0,
      },
      projects: [],
      group_status: [],
      group_engineerss: [],
      isSolarNovaProject: false,
    };
  },
  methods: {
    // Get Project Type ID and Check if Solar Nova Project
    getProjectTypeCheckSolar() {
      var project_type_id = null;
      axios
        .get(apiDomain + "project/project/" + this.model.project_id, {
          headers: getHeader(),
        })
        .then((response) => {
          if (response.status === 200) {
            project_type_id =
              response.data.data[0].project_type.project_type_id;
            if (
              project_type_id != null &&
              // (project_type_id == 2 ||
              //   project_type_id == 3 ||
              //   project_type_id == 4)
              ([2, 3, 4, 9, 10, 11, 12, 13, 14].includes(project_type_id))
            ) {
              this.isSolarNovaProject = true;
            }
          }
        })
        .catch((error) => {
          console.log(error.response.status);
        });
    },
  },
};
</script>
<style>
</style>
