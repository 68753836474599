<template>
  <div class="bg-default-dashboard">
    <div class=" py-lg-8 pt-lg-5">
      <b-container fluid style="width: 90%; position: relative;">
        <!-- <div class="header-body "  > -->
        <b-row class="mb-2">
          <b-col>
            <h1 class="text-white">My Open Safety Observations</h1>
          </b-col>
        </b-row>

        <b-row class="mt-4">
          <b-col>
            <div class="row">
              <dataset style="width: 100%" v-slot="{ ds }" :ds-data="obj_data_grid.data"
                :ds-sortby="[ '-target_date', '-inspection_date', 'site_name', 'item_type', 'item_category', ]"
                :ds-search-in="['site_name', 'item_type', 'item_category', 'created_by',]">
                <div class="row" style="padding-bottom: 15px" :data-page-count="ds.dsPagecount">
                  <!-- data grid - data per-page -->
                  <div class="col-md-7" style="color: #ffffff; font-family: Open Sans, sans-serif !important; font-size: 0.875rem;">
                    <dataset-show class="dataset-style" />
                  </div>
                  <!-- data grid - search-->
                  <div class="col-md-5">
                    <dataset-search ds-search-placeholder="Search..." />
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <div class="table-responsive">
                      <table style="width: 100%" class="table table-striped d-md-table">
                        <thead
                          style="background-color: #3a656b; color: white; font-size: small; border-bottom: 20px solid #082A2F;">
                          <tr>
                            <!-- <th scope="col">#</th> -->
                            <!-- <th v-for="(th, index) in obj_pagination.cols" :key="th.field" :class="['sort', th.sort]"
                              @click="click($event, index)">
                              {{ th.name }} <i class="gg-select float-right"></i>
                            </th> -->
                            <th v-for="(th, index) in obj_data_grid.cols" :key="th.field"
                              :class="['th-table-new', th.sort]">
                              {{ th.name }}
                              <i class="gg-select float-right"></i>
                            </th>
                          </tr>
                        </thead>
                        <dataset-item tag="tbody">
                          <template #default="{ row, rowIndex }">
                            <tr
                              style="background-color: #23454A; color: white; font-size: smaller; border-bottom:  20px solid #082A2F;">
                              <!-- <th scope="row">{{ rowIndex + 1 }}</th> -->
                              <td class="tr-td-table-new" style="min-width: 150px">
                                {{ row.contractor_code }}
                              </td>
                              <td class="tr-td-table-new" style="min-width: 150px">
                                {{ row.site_name }}
                              </td>
                              <td class="tr-td-table-new" style="min-width: 150px">
                                {{ row.inspection_date }}
                              </td>
                              <td class="tr-td-table-new" style="min-width: 150px">
                                {{ row.item_type }}
                              </td>
                              <td class="tr-td-table-new" style="min-width: 150px">
                                {{ row.item_category }}
                              </td>
                              <td class="tr-td-table-new" style="min-width: 150px">
                                {{ row.item_status }}
                              </td>
                              <td class="tr-td-table-new" style="min-width: 150px">
                                {{ row.target_date }}
                              </td>
                              <td class="tr-td-table-new" style="min-width: 150px">
                                <b-link @click="handleDetails(rowIndex, row)"
                                  class="btn btn-sm btn-secondary rounded-pill text-white" target="_blank">
                                  <div class="icon-pencil-cs ml-1 mr-1"></div>
                                </b-link>                                
                                <b-link v-if="user_role == 3" @click="deleteDetails(rowIndex, row)" 
                                  style="color: rgb(255, 92, 0); font-size: large;" 
                                  class="btn btn-sm btn-secondary rounded-pill" target="_blank">
                                  <!-- <div class="fa fa-trash"></div> -->
                                  <div class="icon-trash-bin-cs ml-1 mr-1"></div>
                                </b-link>
                              </td>
                            </tr>
                          </template>
                        </dataset-item>
                      </table>
                    </div>
                  </div>
                </div>
                <div class="d-flex flex-md-row flex-column justify-content-between align-items-center" style="
                    color: #ffffff;
                    font-family: Open Sans, sans-serif !important;
                    font-size: 0.875rem;
                  ">
                  <dataset-info class="mb-2 mb-md-0" />
                  <dataset-pager />
                </div>
              </dataset>
            </div>

          </b-col>
        </b-row>

        <!-- Modal loading / in progress -->
        <!--  body-classes="in-progress-modal-lg"  no-close-on-backdrop=false -->
        <modal :show.sync="isLoadingInProgress" ref="modal-loading" size="lg" modal-classes="in-progress-modal-lg" :no-close-on-backdrop="true" body-classes="p-0" >
          <card type="secondary" header-classes="bg-transparent pb-5" body-classes="px-lg-5 py-lg-5" class="border-0 mb-0">
            <template>
              <div class="text-center text-muted mb-4">
                <h1 class="text-white"><b>Loading in progress</b></h1>
              </div>
              <div class="text-center">
                <div style="color: #ffffff;">
                  <!-- <span class="fa fa-spinner fa-spin fa-3x"></span> -->
                  <span class="icon-spinner-cs"></span>
                </div>
              </div>
            </template>
          </card>
        </modal>

      </b-container>     
    </div>


  </div>
</template>

<script>
import { Table, TableColumn, Select, Option } from "element-ui";
// import Fuse from "fuse.js";
import axios from "axios";
import { LoginURL, clientId, clientSecret, apiDomain, getHeader, getcurrentDate } from "@/assets/js/config.js";
import ErrorFunctionMixin from "@/assets/js/errorFunctionMix.js";
import Dataset from '@/assets/js/customDataset.js';
import { DatasetItem, DatasetInfo, DatasetPager, DatasetSearch, DatasetShow } from "vue-dataset";
import DropzoneFileUpload from '@/components/Inputs/DropzoneFileUploadObs'
import { ToggleButton } from 'vue-js-toggle-button'
import jsonFormData from 'json-form-data';
// import {cloneDeep, pick } from 'lodash/cloneDeep';
// import cloneDeep from 'lodash/cloneDeep';
import { cloneDeep, pick } from 'lodash';

export default {
  mixins: [ErrorFunctionMixin],
  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    Dataset,
    DatasetItem,
    DatasetInfo,
    DatasetPager,
    DatasetSearch,
    DatasetShow,
    DropzoneFileUpload,
    ToggleButton,
  },
  data() {
    return {
      user_role: null,
      user_id: null,
      contractor_id: null,
      propsToSearch: [
        'contractor.contractor_code',
        'project_site.site_name',
      ],
      tableColumns: [],
      tableData: [],
      selectedRows: [],
      myUserID: null,
      obj_data_grid: {
        data: [],
        cols: [
          //_ EPC | SITE NAME | INSPECTION DATE | TYPE | CATEGORY | STATUS | TARGET DATE | ACTION
          //  type: unsafe act / unsafe condition ...
          {
            name: "EPC",
            field: "contractor_code",
          },
          {
            name: "Site Name",
            field: "site_name",
          },
          {
            name: "Inspection Date",
            field: "inspection_date",
          },
          {
            name: "Type",
            field: "item_type",
          },
          {
            name: "Category",
            field: "item_category",
          },
          {
            name: "Status",
            field: "item_status",
          },
          {
            name: "Target Date",
            field: "target_date",
          },
          {},
          // {
          //   name: 'Actions',
          //   field: '',
          // },
        ],
      },


      inputs: {
        tags: [],
        fileSingle: [],
        fileMultiple: [],
      },
      groupId: [],
      groupMembers: [],
      filter_month_year_data: [
        // { text: 'CSL', value: 'CSL' }
        { text: "N/A", value: "" },
      ],
      developers: [],
      contractors: [],
      project_safety: [],
      safety_inspection_submitters: [],
      safety_observation_type: [],
      safety_observation_category: [],
      safety_observation_priority: [],
      safety_observation_status: [],
      isFromDateAfterToDate: false,
      isDeleteSafetyObservationModalVisible: false,
      isDeleteSafetyObservationModalVisibleInner: false,
      isLoadingInProgress: false,

      obj_list: {
        sites_all: [],
        site_info: [],
        site_loading: false,
        site_selected: null,
      },
      filter_site: {
        developers: [3],
        project_name: null,
        project_ppa_sign_date_from_date: null,
        project_ppa_sign_date_to_date: null,
        project_ppa_capacity_greater_equal: null,
        project_ppa_capacity_lesser_equal: null,

        project_type: [],
        project_managers: [],
        project_engineers: [],
        project_business_development: [],
        project_safety: [],

        site_name: null,
        project_site_contractors: [],
        project_site_statuses: [],
        site_postal_code: null,
        site_code: null,
        roof_handover_date_from_date: null,
        roof_handover_date_to_date: null,
        site_cod_date_from_date: null,
        site_cod_date_to_date: null,
        site_target_turn_on_date_from_date: null,
        site_target_turn_on_date_to_date: null,
        site_actual_turn_on_date_from_date: null,
        site_actual_turn_on_date_to_date: null,
        site_total_capacity_greater_equal: null,
        site_total_capacity_lesser_equal: null,
        site_as_built_capacity_greater_equal: null,
        site_as_built_capacity_lesser_equal: null,
        site_module_brand_ids: [],
        site_module_quantity_greater_equal: null,
        site_module_quantity_lesser_equal: null,
        site_module_capacity_greater_equal: null,
        site_module_capacity_lesser_equal: null,
        site_module_allocation_total_capacity_greater_equal: null,
        site_module_allocation_total_capacity_lesser_equal: null,
        site_drawing_module_brand_ids: [],
        site_drawing_module_quantity_greater_equal: null,
        site_drawing_module_quantity_lesser_equal: null,
        site_drawing_module_capacity_greater_equal: null,
        site_drawing_module_capacity_lesser_equal: null,
        site_drawing_total_capacity_greater_equal: null,
        site_drawing_total_capacity_lesser_equal: null,
        site_drawing_ifa_date_from_date: null,
        site_drawing_ifa_date_to_date: null,
        site_drawing_ifc_date_from_date: null,
        site_drawing_ifc_date_to_date: null,
        ntp_applicable: null,
        ntp_issued: null,
        ntp_issue_date_from_date: null,
        ntp_issue_date_to_date: null,
        internal_project_handover_completed: null,
        internal_project_handover_date_from_date: null,
        internal_project_handover_date_to_date: null,
        kick_off_meeting_completed: null,
        kick_off_meeting_date_from_date: null,
        kick_off_meeting_date_to_date: null,
      },
      obj_inspection: {
        is_editing_inspection: false,
        safety_observation_id: null,
        previous_status: null,
        obs_item_fields: [
          // 'no.',
          // {
          //   key: index,
          //   label: 'no.',
          //   class: "th-table-new-mdl "
          // },
          // Type
          {
            key: 'safety_observation_type_id',
            label: 'Type',
            formatter: (value, key, item) => {
              var findObj = this.safety_observation_type.find(obj => { return obj.safety_observation_type_id === value });
              if (typeof findObj !== 'undefined') {
                return findObj.safety_observation_type_code;
              } else {
                return value;
              }
            },
            class: "th-table-new-mdl"
          },
          // Category
          {
            key: 'safety_observation_category_id',
            label: 'Category',
            formatter: (value, key, item) => {
              var findObj = this.safety_observation_category.find(obj => { return obj.safety_observation_category_id === value });
              if (typeof findObj !== 'undefined') {
                return findObj.safety_observation_category;
              } else {
                return value;
              }
            },
            class: "th-table-new-mdl"
          },
          // Status
          {
            key: 'safety_observation_status_id',
            label: 'Status',
            formatter: (value, key, item) => {
              var findObj = this.safety_observation_status.find(obj => { return obj.status_id === value });
              if (typeof findObj !== 'undefined') {
                return findObj.status_code;
              } else {
                return value;
              }
            },
            class: "th-table-new-mdl",
          },
          // Priority
          {
            key: 'safety_observation_priority_id',
            label: 'Priority',
            formatter: (value, key, item) => {
              var findObj = this.safety_observation_priority.find(obj => { return obj.safety_observation_priority_id === value });
              if (typeof findObj !== 'undefined') {
                return findObj.safety_observation_priority_name;
              } else {
                return value;
              }
            },
            class: "th-table-new-mdl",
          },
          // Actions
          { key: 'actions', label: 'Actions', class: "th-table-new-mdl", },
        ],
      },
      obj_obs_main: {
        users: [],
        conducting_users: [],
        contractor_info: null,
        isFutureDate: false,
        inspector_users: [],
        submit_clicked: false,
        isSubmissionWarningVisible: false,
      },      
      obj_obs_item: {
        isNewObservationItemVisible: false,
        safety_observation_item_mode: 'create',
        current_item_index: null,
        max_uploaded_photos: 5,     // maxFiles in src\components\Inputs\DropzoneFileUploadObs.vue also need to be adjust
        signal_reset_file_upload: false,
        showUploadArea: true,
      },
      model_to_db: {
        project_id: 'x',
        group_id: 'x',
        site_id: 'x',
        contractor_id: 'x',
        project_status: 'ongoing',
        safety_observation_create_date: null,
        safety_observation_create_time: null,
        time_spent_hours: 1,
        safety_observation_items: [],
        conducting_users: null,
        status: null,
      },
      new_observation: {
        observation_description: null,
        safety_observation_type_id: "x",
        safety_observation_category_id: "x",
        // correction_photos_required: "x",
        correction_photos_required: true,
        safety_observation_priority_id: "x",
        safety_observation_photos: [],
        safety_observation_status_id: 1,
        // repeated_observation: 0,
        repeated_observation: false,
      },

      //_ below might not be use, need to be check before deleting it
      valuePMN: '',
      tableColumns: [],
      obs_items: [
        {
          id: 1,
          type: "Good Practice",
          category: "12 Life Save Rules",
          status: "On Going",
          proruty: "30 Days"
        }
      ],
      items: [
        {
          id: 0,
          project_name: "PLTS IKN",
          epc: "EHP",
          site_name: "Site 01",
          date: "19-July-2024",
          submitted_by: "Robaan",
        },
        {
          id: 1,
          project_name: "PLTS IKN",
          epc: "EHP",
          site_name: "Site 01",
          date: "19-July-2024",
          submitted_by: "Robaan",
        }
      ],
      filterOption: [
        {
          label: 5,
          value: 5
        },
        {
          label: 10,
          value: 10
        },
        {
          label: 25,
          value: 25
        },
        {
          label: 50,
          value: 50
        },
      ],
      // submittedTableData: [],
      // draftTableData: [],
      selectedRows: [],
      button_access: {
        new_button: null,
        project_view: null,
        milestone_listing: null,
        safety_observation_delete: null,
      },
      showFilters: false,
    };
  },
  created() {
    const LoginUser = JSON.parse(window.localStorage.getItem('loginUser'))
    this.myUserID = LoginUser
    var userInfo = JSON.parse(window.localStorage.getItem("loginUser"));
    this.user_role = userInfo.role_id;
    this.user_id = userInfo.id;

    if (this.myUserID.role_id == 12) {
      this.getContractorId();
    } else {
      this.getSafetyObservationItemListing();
    }
  },
  methods: {
    rowClass(item, type) {
      return "grid-tr-new";
    },

    getContractorId() {
      axios.get(apiDomain + 'lookup/contractor.contractor_id/' + this.user_id, { headers: getHeader() })
        .then ( response => {
        if (response.status === 200)
        {
          this.contractor_id = response.data.contractor_id;
          this.getSafetyObservationItemListingContractor();
        }
        })
        .catch ( error => {
          this.errorFunction(error, 'Contractor Id')
        });
    },
    getSafetyObservationItemListingContractor() {
      axios.get(apiDomain + 'health_safety/safety_observation_item.open_pending.show_by_contractor/' + this.contractor_id, { headers: getHeader() })
      .then ( response => {
      if (response.status === 200) {
          this.tableData = response.data.data
          this.populateDSSafety();
          // this.fuseData()
      }
      })
      .catch ( error => {
        console.log(error.response.status)
        this.errorFunction(error, "Project Information")
      })
      .finally (() => {
        this.isLoadingInProgress = false;
      });
    },
    getSafetyObservationItemListing() {
      axios
      .get(apiDomain + 'health_safety/safety_observation_item.open_pending/' + this.myUserID.id, { headers: getHeader() })
      .then ( response => {
        if (response.status === 200) {
            this.tableData = response.data.data
            this.populateDSSafety();
            // this.fuseData()
        }
      })
      .catch ( error => {
        console.log(error.response.status)
        this.errorFunction(error, "Project Information")
      })
      .finally (() => {
        this.isLoadingInProgress = false;
      });
    },
    populateDSSafety() {
      //_ populate data grid
      //  why not just use this.tableData ? because the search not working if the data is nested, e.x project.project_name
      //_ EPC | SITE NAME | INSPECTION DATE | TYPE | CATEGORY | STATUS | TARGET DATE | ACTION
      this.obj_data_grid.data = this.tableData.reduce((res, item) => {
        res.push({
          safety_observation_item_id: item.safety_observation_item_id,
          contractor_code: item.contractor.contractor_code,
          site_name: item.project_site.site_name,
          inspection_date: item.safety_observation_create_date,
          item_type: item.safety_observation_type.safety_observation_type_code,
          item_category: item.safety_observation_category.safety_observation_category,
          item_status: item.safety_observation_status.status_code,
          target_date: item.target_date,
        });
        return res;
      }, []);
    },
    handleDetails(index, row) {
      this.$router.push({path: '/UAM/HSEItemCorrection/'+row.safety_observation_item_id });
    },
  },
  mounted() {
    this.isLoadingInProgress = true;
  },
  computed: {

  },
  watch: {

  }
};
</script>

<style scoped>
/deep/ .dz-error {
  display: none;
}

/deep/ .el-popper .popper__arrow,
.el-popper .popper__arrow::after {
  border-style: hidden;
}

/deep/ .list-group-item {
  border: 1px solid #e9ecef00;
}

/deep/ select option {
  margin: 40px;
  background-color: #306b74 !important;
  color: #fff;
  text-shadow: 0 1px 0 rgba(0, 0, 0, 0.4);
}

/deep/ select option:disabled {
  color: #fff;
}

/deep/ .btn {
  color: white;

  &:hover {
    &::placeholder {
      color: white;
    }
  }
}

/deep/ .btn-outline-danger {
  background-color: #f5365c;
}

/deep/ .card-header {
  background-color: #3d6971d9;
  padding: 20px 0 20px 0;

}

</style>

<style scoped>
/deep/ input::-webkit-input-placeholder {
  color: #fff;
}

/deep/ input::-moz-input-placeholder {
  color: #fff;
}

/deep/ input::-ms-input-placeholder {
  color: #fff;
}

/deep/ .el-input .el-input__inner {
  background-color: #2C545A;
  color: #fff;
  border: 0px;
  border-radius: 10px;
  padding-top: 3px;
  padding-bottom: 2px;

  /* &::placeholder {
    color: white;
  } */
}

/deep/ .el-select-dropdown__item.hover,
.el-select-dropdown__item:hover {
  background-color: #23504e;
}

/deep/ .el-select-dropdown {
  background-color: #306b74;
  margin: 0px;
}

/deep/ .el-select-dropdown__item {
  color: white;
  background-color: #183034;
  
  ::hover {
    background-color: #2C545A
  }

}

/deep/ .el-select {
  .el-input .el-input__inner {
    &::placeholder {
      color: white;
    }
  }

}

/deep/ .el-select__input {
  color: white;
  background-color: #2C545A;
}

/deep/ .el-select__input.hover,
.el-select__input:hover {
  background-color: #23504e;
}


/deep/ .nav-pills .nav-link {
  /* padding: 0.75rem 1rem; */
  color: #cdcfdb;
  font-weight: 500;
  font-size: 0.875rem;
  -webkit-box-shadow: 0 0px 0px rgba(50, 50, 93, 0.11), 0 0px 0px rgba(0, 0, 0, 0.08);
  box-shadow: 0 0px 0px rgba(50, 50, 93, 0.11), 0 0px 0px rgba(0, 0, 0, 0.08);
  background-color: #3d697100;
  -webkit-transition: all 0.15s ease;
  transition: all 0.15s ease;

  &:hover {
    color: white;
  }
}

/deep/ .nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
  color: #fff;
  background-color: #175f6be3;
  border-left: 3px solid #85BCCE;
  width: 100%;

}

/deep/ .nav-pills .nav-item:not(:last-child) {
  padding-right: 0rem;
}

/deep/ .grid-tr-new {
  background-color: #23454A; color: white; font-size: smaller; border-bottom:  20px solid #306b74;
}
</style>

<style scoped>
/* Class For Modal */

.th-table-new {
  padding-top: 35px;
  padding-bottom: 35px;
  padding-left: 20px;
  font-size: small; 
  /*0.65rem;*/
}

.tr-td-table-new {
  padding-top: 30px;
  padding-bottom: 30px;
  padding-left: 20px;
}
/* 
.th-table-new-mdl {
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 20px;
  font-size: small;
} */

.tr-td-table-new-mdl {
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 20px;
}

/deep/ .form-control {
  background-color: #2C545A;
  border-color: #2C545A;
  color: white;

  &:focus {
    background-color: #2C545A;
    border-color: #2C545A;
    /* color: white; */
  }

  &::placeholder {
    color: rgb(163, 163, 163);
  }
}

/deep/ .form-control {
  &:focus {
    &::placeholder {
      color: rgb(163, 163, 163);
    }
  }
}

.input-group-text {
  background-color: #2C545A;
  ;
  color: white;

  &:focus {
    background-color: #2C545A;
    ;
    border-color: #1D4147;
    color: white;
  }
}

.focused {
  .input-group-text {
    background-color: #2C545A;
  }
}

.navbar-search-dark .input-group {
  background-color: #23454A;
  color: black;
  border-color: rgba(255, 255, 255, 0.6);
}

.navbar-search .input-group {
  border-radius: 7px;
}

select {
  background: url("data:image/svg+xml,<svg width='10' height='7' viewBox='0 0 10 7' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M5.02552 6.35057L0.320068 0.46875H9.73098L5.02552 6.35057Z' fill='white'/></svg>") no-repeat;
  background-position: calc(100% - 0.75rem) center !important;
  -moz-appearance: none !important;
  -webkit-appearance: none !important;
  appearance: none !important;
  padding-right: 2rem !important;
}

option {
  width: 100px !important;
}

/deep/ #__BVID__46___BV_modal_content_ {
  background-color: #306b74;
}

/deep/ .modal-content {
  background-color: #306b74 !important;
}

/deep/ .modal-body {
  background-color: #306b74 !important;
}
</style>

<!-- scoped css not working  -->
<!-- <style>
.modal-content {
  background-color: #306b74 !important;
}
</style> -->

<!-- el-select custom -->
<style scoped>
/deep/ .el-select .el-input.is-focus .el-input__inner {
  border-color: transparent !important;
}

/deep/ .el-select .el-input .el-input__inner:focus {
  border-color: transparent !important;
}

/deep/ .el-select .el-select__tags .el-tag {
  background-color: #183034;
}

/deep/ .el-select-dropdown.is-multiple .el-select-dropdown__item.selected {
  background-color: #183034;
  color: #ffffff;
}

/deep/ .el-select-dropdown.el-popper .el-select-dropdown__item.selected, .el-select-dropdown.el-popper.is-multiple .el-select-dropdown__item.selected {
  background-color: #183034;
  color: #ffffff;
}
</style>

<!-- dataset -->
<style scoped>
/deep/ div.dataset-style select {
  color: #ffffff;
  background-color: #2c545a;
  border-color: #2c545a;
}

/deep/ div.dataset-style select.form-control {
  color: #ffffff;
  background-color: #2c545a;
  border-color: #2c545a;
}

/deep/ div.dataset-style select>option {
  color: #ffffff;
  background-color: #2c545a;
  border-color: #2c545a;
}
</style>

<!-- grid observation item -->
<style scoped>
/deep/ .grid-obs-item thead {
  background-color: rgb(82 146 155); color: white; font-size: small; border-bottom: 20px solid #306b74;
}

/deep/ .th-table-new-mdl {
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 20px;
  font-size: small;
}

.list-group-item {
  background-color: #23454A;
  border-bottom: 1px solid black;
}
</style>

<!-- pagination style -->
<style scoped>
/deep/ .pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.25rem;
  color: black;
}

/deep/ .page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.65rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #345f66;
  background-color: #fff;
  border: 1px solid #dee2e6;
}

/deep/ .page-link:hover {
  z-index: 2;
  color: #345f66;
  text-decoration: none;
  background-color: #e9ecef;
  border-color: #dee2e6;
}

/deep/ .page-link:focus {
  z-index: 3;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(62, 175, 124, 0.25);
}

/deep/ .page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

/deep/ .page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}

/deep/ .page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #345f66;
  border-color: #3a656b;
}

/deep/ .page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #dee2e6;
}

/deep/ .pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.5;
}

/deep/ .pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem;
  width: 60px;
}

/deep/ .pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
}

/deep/ .pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
}

/deep/ .pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem;
}

/deep/ .pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem;
}

/deep/ .b-pagination-pills .page-item .page-link {
  border-radius: 50rem !important;
  margin-left: 0.25rem;
  line-height: 1;
}

/deep/ .b-pagination-pills .page-item:first-child .page-link {
  margin-left: 0;
}
</style>

<!-- modal - loading in progress -->
<style scoped>
/deep/ .in-progress-modal-lg .modal-dialog {
    width: 100% !important;
    max-width: none !important;
    height: 100% !important;
    margin: 0 !important;
  }
  
/deep/ .in-progress-modal-lg .modal-dialog .modal-content {
    height: 100% !important;
    border: 0 !important;
    border-radius: 0 !important;
    /* background-color: transparent !important; */
    background: rgba(0,0,0,0.5);
    border: none !important;
  }
</style>
